import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import { RecordTemplate, RecordType, sanitiseInternalFieldName } from "enada-common";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/hooks";
import { updateIndividualTemplateProperty } from "../../../store/slices/templatesSlice";
import { useGetModulesQuery } from "services/api";

type TemplateDetailsProps = {
  template: RecordTemplate | Partial<RecordTemplate> | null;
  isEdit: boolean;
};

export const TemplateDetails: FC<TemplateDetailsProps> = ({ template, isEdit }) => {
  const { t } = useTranslation(["common"]);
  const [nameError, setNameError] = useState(false);
  const dispatch = useAppDispatch();
  const { modules } = useGetModulesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      modules: {
        ...result.data,
        challenges: result.data?.ideas
      }
    })
  });
  const onChangeType = (e: SelectChangeEvent<RecordType>) => {
    dispatch(
      updateIndividualTemplateProperty({
        key: "recordType",
        value: sanitiseInternalFieldName(e.target.value)
      })
    );
  };

  const typeOptions = useMemo(() => {
    return Object.keys(RecordType)
      .filter(m => {
        if (m === RecordType.BusinessCase) return true;
        return modules?.[m.toLowerCase()] && m !== RecordType.Ideas;
      })
      .map(module => (
        <MenuItem key={module} value={module} data-testid={"template-type-option"}>
          {t(module.toLowerCase())}
        </MenuItem>
      ));
  }, [modules, t]);

  return (
    <Stack direction={"row"} className="type-config-top-container" spacing={4}>
      <TextField
        label={t("templateName")}
        required
        variant="standard"
        value={template?.displayName ?? ""}
        fullWidth={true}
        data-testid={"template-name-input"}
        helperText={nameError ? t("internalNameError", { field: "Field" }) : ""}
        error={nameError}
        onChange={e => {
          dispatch(
            updateIndividualTemplateProperty({
              key: "displayName",
              value: e.target.value
            })
          );

          if (!isEdit) {
            const sanitised = sanitiseInternalFieldName(e.target.value);
            if (sanitised !== "") {
              dispatch(
                updateIndividualTemplateProperty({
                  key: "name",
                  value: sanitised
                })
              );
              setNameError(false);
            } else {
              setNameError(true);
            }
          }
        }}
      />
      <TextField
        variant="standard"
        label={t("internalName")}
        value={template?.name ?? ""}
        fullWidth={true}
        disabled
      />
      <TextField
        variant="standard"
        label={t("description")}
        value={template?.description ?? ""}
        fullWidth={true}
        onChange={e =>
          dispatch(
            updateIndividualTemplateProperty({
              key: "description",
              value: e.target.value
            })
          )
        }
      />

      <FormControl variant="standard" fullWidth={true} required disabled={isEdit}>
        <InputLabel id="select-label">{t("type")}</InputLabel>
        <Select
          labelId="select-label"
          variant="standard"
          value={template?.recordType ?? RecordType.Projects}
          fullWidth={true}
          onChange={onChangeType}
          data-testid={"template-type-input"}
        >
          {typeOptions}
        </Select>
      </FormControl>
    </Stack>
  );
};
