import "@bryntum/taskboard-thin/taskboard.material.css";
import "./userboard.scss";
import { ProjectModel, ColumnModel } from "@bryntum/taskboard-thin";
import { BryntumTaskBoard } from "@bryntum/taskboard-react-thin";
import { BoardColumn, Choice, Field, TableDataType } from "enada-common";
import { FC, useCallback, useMemo, useRef } from "react";
import { v4 } from "uuid";
import { userTableBoardConfig } from "./UserTableBoardConfig";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

export interface UserTableBoardCardItem {
  location: "header" | "body" | "footer";
  field: string;
  label?: string;
}
export interface UserTableBoardProps {
  readOnly: boolean | undefined;
  tableColumns: Field[];
  boardColumns: Choice[];
  columnField: string;
  project: ProjectModel;
  source: TableDataType;
  getDefaultRow?: (id: string) => any;
  cardItems?: Field[];
}
const UserTableBoard: FC<UserTableBoardProps> = ({
  readOnly,
  tableColumns,
  boardColumns,
  columnField,
  project,
  getDefaultRow,
  cardItems,
  source
}) => {
  const { t } = useTranslation("common");
  const boardRef = useRef<any>();
  const theme = useTheme();

  project.taskStore?.allRecords.forEach(record => {
    if (
      record[columnField] === null ||
      record[columnField] === undefined ||
      (Array.isArray(record[columnField]) && record[columnField].length === 0)
    ) {
      record["e365-boardColumnField"] = "unassigned";
    } else {
      record["e365-boardColumnField"] = record[columnField]?.id;
    }
  });

  const onTaskDrop = ({ taskRecords, targetColumn }) => {
    const task = taskRecords[0];
    if (targetColumn) {
      task["e365-boardColumnField"] = targetColumn.id;
      task[columnField] = targetColumn.data;
      if (targetColumn.id === "unassigned") {
        task[columnField] = [];
      }
    }
  };

  const addTaskToTable = (columnData: BoardColumn) => {
    const newId = v4();
    const newRow = tableColumns
      .filter(column => column.name.endsWith("-e365"))
      .reduce(
        (next, current) => ({
          ...next,
          [current.name]: null
        }),
        {}
      );
    const defaultRow = getDefaultRow ? getDefaultRow(newId) : {};
    project.taskStore?.add({
      ...defaultRow,
      ...newRow,
      id: newId,
      [columnField]: columnData,
      "e365-boardColumnField": columnData.id
    });
  };

  const createTaskBoardColumns = useCallback(() => {
    const columns = boardColumns.map((choice: Choice) => {
      const model = new ColumnModel({
        id: choice.id,
        name: choice.id,
        text: choice.label,
        label: choice.label,
        wbs: choice.wbs,
        parentId: choice.parentId,
        color: choice.colour
      });

      return model;
    });

    columns.splice(
      0,
      0,
      new ColumnModel({
        id: "unassigned",
        name: "unassigned",
        text: "Unassigned",
        label: "Unassigned",
        color: ""
      })
    );

    return columns;
  }, [boardColumns]);

  const key = useMemo(() => v4(), [cardItems]);

  return (
    <div
      className={`standard-table-board-view taskBoard ${
        theme.palette.mode === "dark" ? "dark" : ""
      }`}
    >
      <BryntumTaskBoard
        key={key}
        {...userTableBoardConfig(t, source, readOnly, addTaskToTable, cardItems)}
        ref={boardRef}
        columnField="e365-boardColumnField"
        columns={createTaskBoardColumns()}
        project={project}
        onTaskDrop={onTaskDrop}
      />
    </div>
  );
};

export default UserTableBoard;
