import { Button, Tooltip } from "@mui/material";
import React, { FC, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addWorkflowNode, selectWorkflowType } from "../../../store/slices/workflowSlice";

import { getNewNode } from "../utils/getNewNode";
import { getNodeIcon } from "../utils/getNodeIcon";

import "./workflowoption.scss";
import { ReactFlowInstance } from "reactflow";
import { WorkflowNodeConfiguration } from "enada-common";
import { useAppSelector } from "../../../store/hooks";

export interface WorkflowOptionProps {
  option: WorkflowNodeConfiguration;
  reactFlowWrapper: RefObject<HTMLDivElement> | null;
  reactFlowInstance: ReactFlowInstance | null;
  index: number;
  enabled: boolean;
}

const WorkflowOption: FC<WorkflowOptionProps> = ({
  option,
  reactFlowInstance,
  reactFlowWrapper,
  index,
  enabled
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const recordType = useAppSelector(selectWorkflowType);

  const onClick = () => {
    if (reactFlowWrapper && reactFlowWrapper?.current && reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper?.current.getBoundingClientRect();

      const position = reactFlowInstance.project({
        x: reactFlowBounds.left,
        y: reactFlowBounds.top
      });

      const { color, type } = option;
      const newNode = getNewNode({ color, type, recordType }, position, index);
      dispatch(addWorkflowNode(newNode));
    }
  };

  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const { color, type } = option;
    event.dataTransfer.setData("application/reactflow", JSON.stringify({ color, type }));
    event.dataTransfer.effectAllowed = "move";
  };

  if (!enabled) {
    return undefined;
  }

  return (
    <Tooltip title={t(option.type)} placement="right">
      <div
        className={`workflow-option-container `}
        onDragStart={event => onDragStart(event)}
        onClick={onClick}
        data-testid={"workflow-option-button-background"}
        draggable
      >
        <div className="option-button-background">
          <Button
            variant="contained"
            draggable={true}
            size="small"
            className="option-button"
            sx={theme => ({
              color: theme.palette.primary.main,
              transform: "translate3d(0, 0, 0)"
            })}
          >
            {getNodeIcon(option.type)}
          </Button>
        </div>
      </div>
    </Tooltip>
  );
};

export default WorkflowOption;
