import zipcelx from "zipcelx";
import { PeriodicModel } from "../models/PeriodicModel";
import { BryntumGridProps } from "@bryntum/grid-react-thin";
import SharedConfig from "../../config/SharedConfig";

const PeriodicTableGridConfig: Partial<BryntumGridProps> = {
  ...SharedConfig,

  treeFeature: true,
  cellMenuFeature: {
    menu: {
      cls: "enada-bryntum-popup-menu"
    }
  },
  cellEditFeature: {
    triggerEvent: "cellclick",
    addNewAtEnd: false
  },
  subGridConfigs: {
    details: {
      flex: 1
    },
    periodic: {
      flex: 3
    },
    totals: {
      maxWidth: 200
    }
  },

  width: "100%",
  regionResizeFeature: true,
  summaryFeature: true,
  searchFeature: true,
  excelExporterFeature: { zipcelx },
  filterFeature: true,
  store: {
    modelClass: PeriodicModel
  }
};

export default PeriodicTableGridConfig;
