import React from "react";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useTranslation } from "react-i18next";
import "./share.scss";
import { EdisonTypography } from "enada-components";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { useAppSelector } from "../../store/hooks";
import { selectRecord } from "../../store/slices/recordSlice";
import { RecordType } from "enada-common";
import { Theme } from "@mui/system";
import { getNameForRecordType } from "../../utils/getNameForModule";

const shareMenu = [
  {
    id: "email",
    image: <EmailOutlinedIcon />
  },
  {
    id: "copyLink",
    image: <LinkOutlinedIcon />,
    datatestid: "share-link-menu-item"
  }
];

const Share = () => {
  const { t } = useTranslation(["common"]);

  const rawLink = window.location.toString();

  const linkToProject = rawLink;

  const project = useAppSelector(selectRecord);

  const [shareAnchorEl, setShareAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setShareAnchorEl(shareAnchorEl === null ? event.currentTarget : null);
  };

  const selectShare = async (id: string) => {
    if (id === "email") {
      window.location.href = t("emailSubject", {
        linkToProject,
        name: project?.displayName,
        type: getNameForRecordType(project?.recordType ?? RecordType.Projects, t)
      });
    } else {
      handleClick();
    }
    setShareAnchorEl(null);
  };

  const handleClose = () => {
    setShareAnchorEl(null);
  };

  const handleClick = () => navigator.clipboard.writeText(window.location.toString());

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleShareClick}
      >
        <ShareOutlinedIcon fontSize="small" color="action" />
      </IconButton>
      <Menu
        className={"language-menu"}
        id="simple-menu"
        anchorEl={shareAnchorEl}
        PaperProps={{
          sx: {
            borderRadius: "4px"
          }
        }}
        keepMounted
        open={Boolean(shareAnchorEl)}
        onClose={handleClose}
      >
        {shareMenu.map(share => {
          return (
            <div key={share.id}>
              <MenuItem
                data-testid={share.datatestid}
                key={share.id}
                onClick={() => selectShare(share.id)}
              >
                <ListItemIcon>{share.image}</ListItemIcon>
                <ListItemText>
                  <EdisonTypography
                    variant="data"
                    title={t(share.id)}
                    sx={(theme: Theme) => ({
                      color: share.id === "delete" ? theme.palette.error.main : "inherit"
                    })}
                  />
                </ListItemText>
              </MenuItem>
            </div>
          );
        })}
      </Menu>
    </>
  );
};

export default Share;
