import { UserURLFieldProps } from "enada-common";
import { InsertLinkOutlined } from "@mui/icons-material";
import { Box, FormLabel, Grid, IconButton, Link, Popover, Stack, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import EdisonTypography from "../../edison/typography/EdisonTypography";

import "./userurlfield.scss";

export interface URLValue {
  title?: string;
  url?: string;
}

const UserURLField: FC<UserURLFieldProps> = ({
  value,
  readOnly,
  URLInputPlaceholder,
  titleInputPlaceholder,
  disabled,
  isInTable,
  label,
  onChange,
  error,
  setReadOnly,
  autoFocus,
  required
}) => {
  const urlOnBlurId = v4();
  const titleonBlurId = v4();
  const [internalState, setInternalState] = useState<URLValue>(value || { title: "", url: "" });
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const rootDivRef = useRef<any>();

  useEffect(() => {
    setInternalState(value ?? { title: "", url: "" });
  }, [value]);

  useEffect(() => {
    if (!isInTable) return;
    if (readOnly) return;
    setAnchorEl(rootDivRef.current);
  }, [readOnly]);

  const renderTextFields = (
    value: any,
    disabled: boolean | undefined,
    titleInputPlaceholder?: string,
    URLInputPlaceholder?: string,
    error?: boolean
  ) => {
    return (
      <Grid container direction="column" spacing={2} padding="1em">
        <Grid item>
          <TextField
            onBlur={e => {
              if (e.relatedTarget?.id === titleonBlurId) return;
              if (setReadOnly) setReadOnly(true);
              if (!isInTable) return;
              if (!onChange) return;
              onChange(internalState);
            }}
            id={urlOnBlurId}
            required
            autoFocus={!isInTable}
            error={error}
            fullWidth
            disabled={disabled}
            label={URLInputPlaceholder ? URLInputPlaceholder : "URL"}
            value={value.url}
            onChange={e => {
              setInternalState({ ...value, url: e.target.value });
              if (isInTable) return;
              if (!onChange) return;
              onChange({ ...value, url: e.target.value });
            }}
            variant="standard"
          />
        </Grid>
        <Grid item>
          {/* The text field below is the content of the url field. The variand can't be changed according to the designs because EdysonTypography is not being used. */}
          <TextField
            onBlur={e => {
              if (urlOnBlurId === e.relatedTarget?.id) return;
              if (setReadOnly) setReadOnly(true);
              if (!isInTable) return;
              if (!onChange) return;
              onChange(internalState);
            }}
            id={titleonBlurId}
            disabled={disabled}
            fullWidth
            label={titleInputPlaceholder ? titleInputPlaceholder : "Title"}
            value={value.title}
            onChange={e => {
              setInternalState({ ...value, title: e.target.value });
              if (isInTable) return;
              if (!onChange) return;
              onChange({ ...value, title: e.target.value });
            }}
            variant="standard"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="user-url-field-root" ref={rootDivRef}>
      {readOnly ? (
        <Box>
          <Stack direction="column">
            {!isInTable && (
              <Stack direction="row">
                <EdisonTypography
                  title={label}
                  variant="fieldtitle"
                  sx={{
                    color: required && !Boolean(internalState.url) ? "red" : undefined
                  }}
                />
                {required && <div style={{ color: "red" }}> &nbsp; *</div>}
              </Stack>
            )}
            {internalState.url && (
              <Link
                sx={{ fontSize: 14, fontWeight: 400, letterSpacing: 0.17 }}
                href={internalState.url}
                target="_blank"
                rel="noopener"
              >
                {internalState.title?.length ? internalState.title : internalState.url}
              </Link>
            )}
          </Stack>
        </Box>
      ) : !readOnly && isInTable ? (
        <>
          {/* TODO: Avoid duplication of TextField. When FIGMA designs have been updated,
          update JSX. */}
          <Box sx={{ width: "100%" }}>
            {internalState.url && (
              <Link sx={{ fontSize: 16, fontWeight: 400, letterSpacing: 0.15 }}>
                {internalState.title?.length ? internalState.title : internalState.url}
              </Link>
            )}
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            onClose={() => {
              setAnchorEl(null);
              if (!setReadOnly) return;
              setReadOnly(true);
            }}
          >
            {renderTextFields(
              internalState,
              disabled,
              titleInputPlaceholder,
              URLInputPlaceholder,
              error
            )}
          </Popover>
        </>
      ) : (
        <Grid>
          <FormLabel component="legend">
            <EdisonTypography title={label} variant="data" />
          </FormLabel>
          {renderTextFields(
            internalState,
            disabled,
            titleInputPlaceholder,
            URLInputPlaceholder,
            error
          )}
        </Grid>
      )}
    </div>
  );
};

export default UserURLField;
export { UserURLField };
