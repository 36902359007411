import { Alert, Button, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "../../../utils/hooks/useDisclosure";

const AssociatedRecordsAlert: FC = () => {
  const { isOpen, onClose } = useDisclosure(true);
  const { t } = useTranslation(["common"]);
  return (
    <Collapse in={isOpen}>
      <Alert
        severity="info"
        action={
          <Button color="inherit" size="small" onClick={onClose}>
            <CloseIcon />
          </Button>
        }
      >
        {t("programsAssociatedRecordsVisibilityMessage")}
      </Alert>
    </Collapse>
  );
};

export default AssociatedRecordsAlert;
