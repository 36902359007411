import {
  CalculatedExpressionUnit,
  ConditionalExpression,
  ExpressionEntity,
  parseExpressionToFrontend
} from "enada-common";

export const recurseGetAllFieldsInConditionBlock = (
  currentFields: Set<number>,
  expression?: ConditionalExpression
) => {
  if (expression === undefined) return currentFields;
  const ifExpression = parseExpressionToFrontend(expression.conditionExpression);
  getFieldIdsFromFlatExpression(ifExpression).forEach(id => currentFields.add(id));

  if (expression.successExpression) {
    const successExpression = parseExpressionToFrontend(expression.successExpression);
    getFieldIdsFromFlatExpression(successExpression).forEach(id => currentFields.add(id));
  }
  if (expression.failureExpression) {
    const failureExpression = parseExpressionToFrontend(expression.failureExpression);
    getFieldIdsFromFlatExpression(failureExpression).forEach(id => currentFields.add(id));
  }
  if (expression.successConditionalExpression) {
    recurseGetAllFieldsInConditionBlock(currentFields, expression.successConditionalExpression);
  }
  if (expression.failureConditionalExpression) {
    recurseGetAllFieldsInConditionBlock(currentFields, expression.failureConditionalExpression);
  }
  return currentFields;
};

export const getFieldIdsFromFlatExpression = (expression: CalculatedExpressionUnit[]) => {
  return expression
    .filter(({ value }) => typeof value === "object" && value.entityType === "Field")
    .map(({ value }) => (value as ExpressionEntity).entityId as number);
};
