import { useEffect, useRef, useCallback, useState } from 'react';

const useRecordPageSessionChecker = (
  timeout = 15 * 60 * 1000,
  expiresOnIso = "",
  hasEdit = false
) => {
  const [isExpired, setIsExpired] = useState(false);
  const [tokenNeedsRenewing, setTokenNeedsRenewing] = useState(false);
  const [sessionNeedsRenewing, setSessionNeedsRenewing] = useState(false);
  const inactivityTimer = useRef<null | NodeJS.Timeout>(null);
  const tokenExpiryTimer = useRef<null | NodeJS.Timeout>(null);
  const lastActivity = useRef(Date.now());
  const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

  const cleanUp = () => {
    events.forEach(event => window.removeEventListener(event, updateActivity));
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    if (tokenExpiryTimer.current) clearTimeout(tokenExpiryTimer.current);
  };

  const resetInactivityTimer = useCallback(() => {
    if (!hasEdit) return; // Skip inactivity checks if hasEdit is false

    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }

    const timeSinceLastActivity = Date.now() - lastActivity.current;
    if (timeSinceLastActivity < timeout) {
      inactivityTimer.current = setTimeout(() => {
        setIsExpired(true);
      }, timeout - timeSinceLastActivity);
    } else {
      setIsExpired(true);
    }
  }, [timeout, hasEdit]);

  const resetTokenExpiryTimer = useCallback(() => {
    if (tokenExpiryTimer.current) {
      clearTimeout(tokenExpiryTimer.current);
    }

    if (expiresOnIso) {
      const expiryTime = new Date(expiresOnIso).getTime();
      const timeUntilExpiry = expiryTime - Date.now();

      if (timeUntilExpiry > 0) {
        tokenExpiryTimer.current = setTimeout(() => {
          setTokenNeedsRenewing(true);
        }, timeUntilExpiry);
      } else {
        setTokenNeedsRenewing(true);
      }
    }
  }, [expiresOnIso]);

  const updateActivity = useCallback(() => {
    if (!hasEdit) return; // Ignore activity events if hasEdit is false

    lastActivity.current = Date.now();
    setSessionNeedsRenewing(true);
    resetInactivityTimer();
  }, [resetInactivityTimer, hasEdit]);

  useEffect(() => {
    if (hasEdit) {
      events.forEach(event => window.addEventListener(event, updateActivity));
      resetInactivityTimer();
    }
    resetTokenExpiryTimer();

    return () => {
      cleanUp();
    };
  }, [resetInactivityTimer, resetTokenExpiryTimer, updateActivity, hasEdit]);

  if (isExpired) {
    cleanUp();
  }

  return { isExpired, tokenNeedsRenewing, sessionNeedsRenewing };
};

export default useRecordPageSessionChecker;
