import { RecordTableRow, Table, TableRowValue } from "enada-common";
import { SystemFieldType } from "../pages/admin/tableconfig/TableConfig";

export const getRowIdentifier = (fieldValues?: TableRowValue[] | null) => {
  const name = fieldValues?.find(value => value.fieldId === SystemFieldType.Name)?.stringValue;
  const uniqueId = fieldValues?.find(
    value => value.fieldId === SystemFieldType.UniqueId
  )?.stringValue;
  return uniqueId ?? name ?? "Unknown Identifier";
};
export const generateApprovedRowsNotification = (
  approved: RecordTableRow[],
  tables: Table[],
  t: (value: string) => string
) => {
  const result = approved.reduce((acc, curr) => {
    const foundTable = tables.find(table => table.id === curr.tableId);

    return `${acc}\r\n-${t("row")} ${getRowIdentifier(curr.tableRowFieldValues)} ${t("inTable")} ${
      foundTable?.displayName ?? foundTable?.name
    } ${t("updated")}.`;
  }, "");
  return result;
};
