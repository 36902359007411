import { Avatar, Stack } from "@mui/material";
import {
  RecordRole,
  RecordTemplate,
  RecordType,
  recordTypeConfiguration,
  Workflow
} from "enada-common";
import { EdisonPeoplePickerField } from "enada-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WorkflowSelect } from "./WorkflowSelect";
import { useGetWorkflowsQuery } from "../../../../services/api";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectAssignedOwners,
  selectAssignedManagers,
  selectAssignedReaders,
  selectAssignedIdeators,
  updateAssignedRoles,
  updateIndividualTemplateProperty
} from "../../../../store/slices/templatesSlice";
import { selectThemeName } from "../../../../store/slices/userSlice";
import { selectInData } from "../../../../utils/rtkQuery";

type ChallengeConfigProps = {
  template: RecordTemplate | Partial<RecordTemplate> | null;
  templateIsLoading: boolean;
};

const selectChallengeWorkflows = selectInData<Workflow[]>(data =>
  data
    ?.filter(workflow => workflow.type === RecordType.Challenges)
    .sort(
      (a: Workflow, b: Workflow) => a?.displayName?.localeCompare(b?.displayName as string) ?? 0
    )
);

const selectIdeaWorkflows = selectInData<Workflow[]>(data =>
  data
    ?.filter(workflow => workflow.type === RecordType.Ideas)
    .sort(
      (a: Workflow, b: Workflow) => a?.displayName?.localeCompare(b?.displayName as string) ?? 0
    )
);

export const ChallengeConfig: FC<ChallengeConfigProps> = ({ template, templateIsLoading }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const assignedOwners = useAppSelector(selectAssignedOwners);
  const assignedManagers = useAppSelector(selectAssignedManagers);
  const assignedReaders = useAppSelector(selectAssignedReaders);
  const assignedIdeators = useAppSelector(selectAssignedIdeators);

  const {
    challengeWorkflows = [],
    ideaWorkflows = [],
    isLoading: workflowsLoading
  } = useGetWorkflowsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      challengeWorkflows: selectChallengeWorkflows(result),
      ideaWorkflows: selectIdeaWorkflows(result)
    })
  });

  const isLoading = workflowsLoading || templateIsLoading;

  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  const challengeModule = recordTypeConfiguration(RecordType.Challenges);
  const ideaModule = recordTypeConfiguration(RecordType.Ideas);

  return (
    // 70vh is for mid-sized screens to stop the page from flickering the scrollbar on and off.
    <Stack direction="column" spacing={3} width="50%" minHeight="70vh">
      <Stack spacing={2} direction="row">
        <Avatar
          sx={theme => ({
            width: 40,
            height: 40,
            bgcolor: isDarkTheme ? challengeModule.color : challengeModule.secondaryColor,
            color: isDarkTheme ? theme.palette.text.primary : challengeModule.color,
            alignContent: "center"
          })}
        >
          {challengeModule.icon}
        </Avatar>

        <Stack direction="column" spacing={3}>
          <EdisonPeoplePickerField
            label={t("processManagers")}
            onChange={personas => {
              dispatch(
                updateAssignedRoles({
                  role: RecordRole.Manager,
                  personas
                })
              );
            }}
            multiple
            value={assignedManagers}
            useInternalState={false}
            helperText={{ i18nKey: "challengeTemplate.processManagers" }}
          />

          <EdisonPeoplePickerField
            label={t("owners")}
            onChange={personas => {
              dispatch(updateAssignedRoles({ role: RecordRole.Owner, personas }));
            }}
            multiple
            value={assignedOwners}
            useInternalState={false}
            helperText={{ i18nKey: "challengeTemplate.owners", fontWeight: "600" }}
          />

          <EdisonPeoplePickerField
            label={t("readers")}
            onChange={personas => {
              dispatch(updateAssignedRoles({ role: RecordRole.Reader, personas }));
            }}
            multiple
            value={assignedReaders}
            useInternalState={false}
            helperText={{ i18nKey: "challengeTemplate.readers", fontWeight: "600" }}
          />

          <WorkflowSelect
            template={template}
            workflowsList={challengeWorkflows}
            onChange={e => {
              dispatch(
                updateIndividualTemplateProperty({
                  key: "workflow",
                  value: e.target.value
                })
              );
            }}
            label={t("challengeWorkflow")}
            helperText="challengeTemplate.challengeWorkflow"
            value={template?.workflow}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>

      <Stack spacing={2} direction="row">
        <Avatar
          sx={theme => ({
            width: 40,
            height: 40,
            bgcolor: isDarkTheme ? ideaModule.color : ideaModule.secondaryColor,
            color: isDarkTheme ? theme.palette.text.primary : ideaModule.color,
            alignContent: "center"
          })}
        >
          {ideaModule.icon}
        </Avatar>

        <Stack direction="column" spacing={3}>
          <EdisonPeoplePickerField
            label={t("ideators")}
            onChange={personas => {
              dispatch(updateAssignedRoles({ role: RecordRole.Ideator, personas }));
            }}
            multiple
            value={assignedIdeators}
            useInternalState={false}
            helperText={{ i18nKey: "challengeTemplate.ideators" }}
          />

          <WorkflowSelect
            template={template}
            workflowsList={ideaWorkflows}
            onChange={e => {
              dispatch(
                updateIndividualTemplateProperty({
                  key: "secondaryWorkflow",
                  value: e.target.value
                })
              );
            }}
            label={t("ideaWorkflow")}
            helperText="challengeTemplate.ideaWorkflow"
            value={template?.secondaryWorkflow}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
