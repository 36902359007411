import { BryntumTaskBoardProps } from "@bryntum/taskboard-react-thin";
import { Field, TableDataType } from "enada-common";
import { UserTableBoardCardItem } from "./UserTableBoard";
import TypeToComponentMapper from "../tableutils/useTypeToComponentMapper";

export const userTableBoardConfig = (
  t: (value: string) => string,
  source: TableDataType,
  readOnly: boolean | undefined,
  addTaskAction: (columnRecord: any) => void,
  cardItems?: Field[]
): BryntumTaskBoardProps => {
  const config = {
    headerItems: generateBryntumCardRegion(source, "header", cardItems),
    bodyItems: generateBryntumCardRegion(source, "body", cardItems),
    footerItems: { resourceAvatars: { hidden: true } },
    columnDragFeature: false, // Disable dragging columns
    showCollapseInHeader: false, // Hide column collapse tool
    showCountInHeader: false, // Hide task count from column headers
    columnHeaderMenuFeature: {
      disabled: true
    },
    columnToolbarsFeature: {
      disabled: true,
      bottomItems: {
        newTask: {
          text: "Add Row",
          icon: "b-fa-fw b-fa-plus",
          onClick({ source }: any) {
            addTaskAction(source.columnRecord.originalData);
          },
          cls: "taskBoardToolbar"
        },
        addTask: false
      }
    },
    taskDragFeature: {
      disabled: readOnly
    },
    taskEditFeature: false,
    taskMenuFeature: {
      items: {
        column: null,
        removeTask: null,
        resources: null
      }
    }
  };

  return config;
};

const createBryntumTaskEditItem = (
  t: (value: string) => string,
  readOnly: boolean | undefined,
  cardItems?: UserTableBoardCardItem[]
) => {
  const config = {
    disabled: readOnly,
    items: {
      name: null,
      description: null,
      color: null
    }
  };

  cardItems?.forEach(cardItem => {
    config.items[cardItem.field] = {
      name: cardItem.field,
      label: cardItem.label
    };
  });

  return config;
};

const generateBryntumCardRegion = (
  source: TableDataType,
  region: "header" | "footer" | "body",
  cardItems?: Field[]
) => {
  if (!cardItems) return;

  const cardRegion = cardItems
    .filter(item => item?.location === region)
    .reduce((prev, curr) => {
      const cardItem = {
        ...prev,
        [region === "header" ? "text" : curr.name]: {
          type: "jsx",
          field: curr.name,
          jsx: ({ value }: any) => {
            return TypeToComponentMapper(
              curr,
              true,
              true,
              false,
              cardItems,
              undefined,
              value,
              null,
              true,
              source,
              undefined,
              undefined
            );
          }
        }
      };
      return cardItem;
    }, {});

  return cardRegion;
};
