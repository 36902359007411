const columnMapper: { edisonColumnName: string; column: any }[] = [
  {
    edisonColumnName: "e365-RowId",
    column: {
      setField: true,
      definition: {
        fieldReadOnly: true,
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-UniqueId",
    column: {
      setField: true,
      definition: {
        fieldReadOnly: true,
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-ParentUniqueId",
    column: {
      setField: true,
      definition: {
        fieldReadOnly: true,
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-Name",
    column: {
      definition: {
        type: "name"
      }
    }
  },
  {
    edisonColumnName: "e365-Description",
    column: {
      definition: {
        type: "note"
      }
    }
  },
  {
    edisonColumnName: "e365-RollUp",
    column: {
      definition: {
        type: "rollup"
      }
    }
  },
  {
    edisonColumnName: "e365-AssignedResource",
    column: {
      definition: {
        type: "resourceassignment",
        showAvatars: true,
        editor: {
          type: "assignmentfield",
          // Uncomment in order to make assigned resource picker not be transient
          // autoClose: false,
          picker: {
            height: 350,
            width: 450,
            features: {
              filterBar: true,
              headerMenu: false,
              cellMenu: false
            }
          }
        }
      }
    }
  },
  {
    edisonColumnName: "e365-IgnoreResourceCalendar",
    column: {
      definition: {
        type: "ignoreresourcecalendar"
      }
    }
  },
  {
    edisonColumnName: "e365-ReadOnly",
    column: {
      setField: true,
      definition: {
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-IsMilestone",
    column: {
      definition: {
        type: "milestone"
      }
    }
  },
  {
    edisonColumnName: "e365-ShowInTimeline",
    column: {
      definition: {
        type: "showintimeline"
      }
    }
  },
  {
    edisonColumnName: "e365-ConstraintDate",
    column: {
      definition: {
        type: "constraintdate"
      }
    }
  },
  {
    edisonColumnName: "e365-ConstraintType",
    column: {
      definition: {
        type: "constrainttype"
      }
    }
  },
  {
    edisonColumnName: "e365-Duration",
    column: {
      definition: {
        type: "duration",
        instantUpdate: false
      }
    }
  },
  {
    edisonColumnName: "e365-DurationUnit",
    column: {
      setField: true,
      definition: {
        editor: false
      }
    }
  },

  {
    edisonColumnName: "e365-DeadlineDate",
    column: {
      definition: {
        type: "deadlinedate"
      }
    }
  },
  {
    edisonColumnName: "e365-StartDate",
    column: {
      definition: {
        width: 210, // temp until we can upgrade to v5.5.2 using autoWidth introuduces a bug.
        type: "startdate",
        format: "LLL",
        editor: "datetimefield",
        keepTime: true
      }
    }
  },
  {
    edisonColumnName: "e365-EndDate",
    column: {
      definition: {
        width: 210, // temp until we can upgrade to v5.5.2 using autoWidth introuduces a bug.
        type: "enddate",
        format: "LLL",
        editor: "datetimefield",
        keepTime: true
      }
    }
  },
  {
    edisonColumnName: "e365-EarlyStartDate",
    column: {
      definition: {
        type: "earlystartdate"
      }
    }
  },
  {
    edisonColumnName: "e365-EarlyEndDate",
    column: {
      definition: {
        type: "earlyenddate"
      }
    }
  },
  {
    edisonColumnName: "e365-LateStartDate",
    column: {
      definition: {
        type: "latestartdate"
      }
    }
  },
  {
    edisonColumnName: "e365-LateEndDate",
    column: {
      definition: {
        type: "lateenddate"
      }
    }
  },
  {
    edisonColumnName: "e365-Effort",
    column: {
      definition: {
        type: "effort",
        instantUpdate: false
      }
    }
  },
  {
    edisonColumnName: "e365-EffortUnit",
    column: {
      setField: true,
      definition: {
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-EffortDriven",
    column: {
      definition: {
        type: "check",
        field: "effortDriven"
      }
    }
  },
  {
    edisonColumnName: "e365-SlackUnit",
    column: {
      definition: {
        field: "slackUnit"
      }
    }
  },
  {
    edisonColumnName: "e365-Inactive",
    column: {
      definition: {
        type: "inactive"
      }
    }
  },
  {
    edisonColumnName: "e365-ManuallyScheduled",
    column: {
      definition: {
        type: "manuallyscheduled"
      }
    }
  },
  {
    edisonColumnName: "e365-PercentDone",
    column: {
      definition: {
        type: "percentdone",
        mode: "circle"
      }
    }
  },
  {
    edisonColumnName: "e365-Critical",
    column: {
      setField: true,
      definition: {
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-SchedulingMode",
    column: {
      definition: {
        type: "schedulingmodecolumn"
      }
    }
  },
  {
    edisonColumnName: "e365-TotalSlack",
    column: {
      definition: {
        type: "totalslack"
      }
    }
  },
  {
    edisonColumnName: "e365-WBS",
    column: {
      definition: {
        type: "wbs"
      }
    }
  },
  {
    edisonColumnName: "e365-Successors",
    column: {
      definition: {
        type: "successor"
      }
    }
  },
  {
    edisonColumnName: "e365-Predecessors",
    column: {
      definition: {
        type: "predecessor"
      }
    }
  },
  {
    edisonColumnName: "e365-CalendarId",
    column: {
      definition: {
        type: "calendar"
      }
    }
  },
  {
    edisonColumnName: "e365-Unscheduled",
    column: {
      setField: true,
      definition: {
        editor: false
      }
    }
  },
  {
    edisonColumnName: "e365-EffectiveCalendarId",
    column: {
      setField: true,
      definition: {
        type: "template",
        template: ({ record, value }) => {
          const calendarId = value ?? record["calendar"];
          return calendarId
            ? record.project.getCalendarById(calendarId)?.name
            : record.project.calendar?.name;
        },
        editor: false
      }
    }
  },
  {
    // we need to handle the rendering here to ensure the value is proper case.
    // this is different to Constraint Type as CT has it's own field that handles this
    edisonColumnName: "e365-ProjectConstraintResolution",
    column: {
      setField: true,
      definition: {
        type: "template",
        template: ({ value }) => {
          if (value) {
            return (value as string).charAt(0).toUpperCase() + (value as string).slice(1);
          }
        },
        editor: false
      }
    }
  }
];

const getBryntumColumn = (columnName: string) =>
  columnMapper.find((s: any) => s.edisonColumnName === columnName)?.column;

export default getBryntumColumn;
