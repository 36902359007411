import {
  getEnumValues,
  toCamelCase,
  WorkflowEventAction,
  WorkflowStage,
  WorkflowStageEvent
} from "enada-common";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  Tooltip
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Node } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

import {
  selectCurrentElement,
  selectIsWorkflowDefault,
  setWorkflowDesignerView,
  updateElement,
  WorkflowDesignerView
} from "../../../../store/slices/workflowSlice";
import { EdisonExpandableMenu } from "enada-components";
import "./stageevents.scss";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";
import WorkflowFlag from "../workflowflag/WorkflowFlag";
import { useGetFormsQuery } from "services/api";

const StageEvents = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const currentNode = useAppSelector(selectCurrentElement);
  const events = (currentNode?.data as WorkflowStage).events ?? [];
  const dataPopulation = events.find(
    event => event.action === WorkflowEventAction.PopulateData
  )?.workflowDataPopulation;

  const { data: forms = [] } = useGetFormsQuery();
  const isDefault = useAppSelector(selectIsWorkflowDefault);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const updateNodeEvents = (eventsUpdate: WorkflowStageEvent[]) =>
    dispatch(updateElement(updateNodeCustomData("events", eventsUpdate, currentNode as Node)));

  return (
    <EdisonExpandableMenu
      name={t("events")}
      icon={<EmojiEventsOutlinedIcon />}
      noSidePadding
      sticky={false}
    >
      <Stack spacing={2} className="stage-events-root">
        <Button variant="text" startIcon={<AddIcon />} onClick={e => setAnchorEl(e.currentTarget)}>
          {t("addEvent")}
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        >
          <FormGroup className="stage-events-popover-root">
            {getEnumValues(WorkflowEventAction).map((action, index) => (
              <FormControlLabel
                key={index}
                label={t(toCamelCase(action as string))}
                control={
                  <Checkbox
                    color="primary"
                    checked={
                      (currentNode?.data as WorkflowStage).events?.some(
                        event => event.action === action
                      ) ?? false
                    }
                    disabled={action === WorkflowEventAction.PopulateData && isDefault}
                    onChange={e => {
                      setAnchorEl(null);
                      updateNodeEvents(
                        e.target.checked
                          ? [
                              ...events,
                              {
                                action: action as WorkflowEventAction,
                                workflowDataPopulation:
                                  action === WorkflowEventAction.PopulateData
                                    ? { overrideData: false }
                                    : undefined
                              }
                            ]
                          : events.filter(event => event.action !== (action as WorkflowEventAction))
                      );
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </Popover>

        {events.some(event => event.action === WorkflowEventAction.CreateVersion) && (
          <Stack spacing={1}>
            <Divider flexItem />
            <Stack direction="row" className="expandable-menu-container">
              <EdisonExpandableMenu name={t("createVersion")} noSidePadding sticky={false}>
                <Stack className="version-options-container" direction="row">
                  <WorkflowFlag
                    primaryLabel={t("alwaysTriggerCreateVersion.label")}
                    secondaryLabel={t("alwaysTriggerCreateVersion.message")}
                    checked={Boolean(
                      events.find(event => event.action === WorkflowEventAction.CreateVersion)
                        ?.triggerOnStageRepeat
                    )}
                    onChange={e => {
                      updateNodeEvents(
                        events.map(event =>
                          event.action === WorkflowEventAction.CreateVersion
                            ? {
                                ...event,
                                triggerOnStageRepeat: e.target.checked
                              }
                            : event
                        )
                      );
                    }}
                  />
                </Stack>
              </EdisonExpandableMenu>
              <IconButton
                onClick={() =>
                  updateNodeEvents(
                    events.filter(event => event.action !== WorkflowEventAction.CreateVersion)
                  )
                }
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </Stack>
          </Stack>
        )}

        {events.some(event => event.action === WorkflowEventAction.PopulateData) && (
          <Stack spacing={1}>
            <Divider flexItem />
            <Stack direction="row" className="expandable-menu-container">
              <EdisonExpandableMenu name={t("addPrepopulatedData")} noSidePadding sticky={false}>
                <Stack className="prepopulated-options-container" spacing={1}>
                  <Tooltip title={t("workflowConfigureDataMessage")} arrow placement="right">
                    <div>
                      <Button
                        className="configure-button"
                        startIcon={<EditOutlinedIcon />}
                        variant="text"
                        disabled={dataPopulation?.id === undefined || isDefault}
                        onClick={() => {
                          if (!dataPopulation) return;
                          dispatch(setWorkflowDesignerView(WorkflowDesignerView.PopulationView));
                        }}
                      >
                        {t("configureData")}
                      </Button>
                    </div>
                  </Tooltip>
                  {/* {dataPopulation?.id !== undefined && ( // We need dataPopulationId to have been generated by the backend before setting the populationvalues
                    <PrepopulationModal
                      isOpen={prepopulationModalOpen}
                      setIsOpen={setPrepopulationModalOpen}
                      population={dataPopulation as WorkflowDataPopulation}
                    />
                  )} */}
                  <FormGroup>
                    <Stack className="always-occur-container" direction="row">
                      <WorkflowFlag
                        primaryLabel={t("overwriteExistingData.label")}
                        secondaryLabel={t("overwriteExistingData.message")}
                        disabled={dataPopulation?.id === undefined || isDefault}
                        checked={
                          events.find(event => event.action === WorkflowEventAction.PopulateData)
                            ?.workflowDataPopulation?.overrideData ?? false
                        }
                        onChange={e => {
                          updateNodeEvents(
                            events.map(event =>
                              event.action === WorkflowEventAction.PopulateData
                                ? {
                                    ...event,
                                    workflowDataPopulation: {
                                      ...event.workflowDataPopulation,
                                      overrideData: e.target.checked
                                    }
                                  }
                                : event
                            )
                          );
                        }}
                      />
                    </Stack>
                    <WorkflowFlag
                      primaryLabel={t("alwaysTriggerPrepopulated.label")}
                      secondaryLabel={t("alwaysTriggerPrepopulated.message")}
                      disabled={dataPopulation?.id === undefined || isDefault}
                      checked={Boolean(
                        events.find(event => event.action === WorkflowEventAction.PopulateData)
                          ?.triggerOnStageRepeat
                      )}
                      onChange={e => {
                        updateNodeEvents(
                          events.map(event =>
                            event.action === WorkflowEventAction.PopulateData
                              ? {
                                  ...event,
                                  triggerOnStageRepeat: e.target.checked
                                }
                              : event
                          )
                        );
                      }}
                    />
                  </FormGroup>
                </Stack>
              </EdisonExpandableMenu>
              <IconButton
                disabled={isDefault}
                onClick={() =>
                  updateNodeEvents(
                    events.filter(event => event.action !== WorkflowEventAction.PopulateData)
                  )
                }
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </EdisonExpandableMenu>
  );
};

export default StageEvents;
