import React, { FC } from "react";
import "./searchbar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { SearchbarProps } from "enada-common";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const Searchbar: FC<SearchbarProps> = ({
  searchFilter,
  setSearchFilter,
  onSearchPerformed,
  placeholder
}) => {
  return (
    <TextField
      data-testid={"edison-searchbar"}
      onKeyPress={ev => {
        if (ev.key === "Enter" && onSearchPerformed) {
          onSearchPerformed();
          ev.preventDefault();
        }
      }}
      variant="outlined"
      fullWidth
      placeholder={placeholder + ".."}
      size="small"
      className="searchbar-root search-placeholder"
      value={searchFilter}
      onChange={e => setSearchFilter(e.target.value)}
      InputProps={{
        className: "input",
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};

export default Searchbar;
export { Searchbar };
