import { Column } from "@bryntum/grid-thin";
import { Field, FieldDataType } from "enada-common";

export const dynamicColumns: (FieldDataType | undefined)[] = [
  FieldDataType.MultiChoice,
  FieldDataType.MultiLevelChoice,
  FieldDataType.RichText,
  FieldDataType.TextBox,
  FieldDataType.Email
];

export const isColumnDynamic = (column: Column, columns: Field[]) => {
  return (
    !column.hidden &&
    dynamicColumns.includes(
      columns.find(field => field.name === column.field)?.dataType as FieldDataType
    )
  );
};
//Left to do : Url, numbers, phone, email,
