import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../store/hooks";
import { selectRecord, selectWorkflowStage } from "../../store/slices/recordSlice";
import CreateRecordModal from "../createrecordmodal/CreateRecordModal";
import { useDisclosure } from "../../utils/hooks/useDisclosure";
import { RecordAccessRole, RecordRole, RecordType } from "enada-common";
import useHasRecordAccessRole from "../../utils/hooks/useHasRecordAccessRole";

const IdeaFromChallengeButton = () => {
  const { t } = useTranslation(["common"]);
  const record = useAppSelector(selectRecord);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const accessRoles = useHasRecordAccessRole();

  const { isOpen, onClose, onOpen } = useDisclosure(false);

  const ideators =
    record?.recordPermissions.filter(permission => permission.role === RecordRole.Ideator) ?? [];

  const canAddIdea =
    ideators.length > 0 &&
    workflowStage?.allowRecordCreation &&
    accessRoles.hasRecordAccessRole([RecordAccessRole.CreateAssociation]);

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant={"contained"}
        disabled={!canAddIdea}
        color="success"
        onClick={onOpen}
      >
        {t("addIdea")}
      </Button>
      {isOpen && (
        <CreateRecordModal
          sourceRecord={record}
          open={isOpen}
          onClose={onClose}
          recordTypeToCreate={RecordType.Ideas}
        />
      )}
    </>
  );
};

export default IdeaFromChallengeButton;
