import { createTransform } from "redux-persist";
import { DiscoverFiltersState } from "./slices/discoverFiltersSlice";

export const omitSkipTransform = createTransform(
  (inboundState): DiscoverFiltersState => {
    const transformedState = {
      ...inboundState,
      records: inboundState.records.map(record => ({
        ...record,
        odataOperation: {
          ...record.odataOperation,
          skip: 0
        } // Default if odataOperation is undefined
      }))
    };
    return transformedState;
  },
  (outboundState: DiscoverFiltersState) => outboundState,
  { whitelist: ["discoverFilters"] }
);
