import { Box, Stack, Switch } from "@mui/material";
import { FC, useState } from "react";
import { BaseUserFieldProps } from "enada-common";
import EdisonTypography from "../../edison/typography/EdisonTypography";
export interface UserSwitchFieldProps extends BaseUserFieldProps {
  onLabel?: string;
  offLabel?: string;
  defaultValue?: boolean;
}
const UserSwitchField: FC<UserSwitchFieldProps> = ({
  onLabel,
  offLabel,
  readOnly,
  value,
  onChange,
  useInternalState,
  isInTable,
  label,
  disabled,
  defaultValue,
  setReadOnly,
  autoFocus,
  required
}) => {
  const getInitialState = (): boolean => {
    if (value !== undefined && value !== null) {
      if (typeof value === "string") {
        return value === "true";
      }

      return value as boolean;
    }
    if (defaultValue !== undefined && defaultValue !== null) {
      return defaultValue;
    }
    return false;
  };

  const [internalState, setInternalState] = useState(getInitialState());

  return (
    <Stack>
      {!isInTable && (
        <Stack direction="row">
          <EdisonTypography variant={"fieldtitle"} title={label} />
        </Stack>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        {!isInTable && (
          <EdisonTypography variant={readOnly ? "data2" : "data"} title={offLabel || "No"} />
        )}
        <Box
          onBlur={() => {
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState);
          }}
        >
          <Switch
            required={required}
            autoFocus={autoFocus}
            disabled={disabled || readOnly}
            color="primary"
            checked={internalState}
            onChange={(_, changeValue: boolean) => {
              setInternalState(changeValue);
              if (isInTable) return;
              if (!onChange) return;
              onChange(changeValue);
            }}
          />
        </Box>
        {!isInTable && (
          <EdisonTypography variant={readOnly ? "data2" : "data"} title={onLabel || "Yes"} />
        )}
      </Stack>
    </Stack>
  );
};

export default UserSwitchField;
export { UserSwitchField };
