import { Box, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import "./cardlivepreview.scss";
import CardLivePreviewRow from "./row/CardLivePreviewRow";
import {
  addNewRow,
  getAllRowsPath,
  CardField,
  incrementRowsPath,
  ItemDNDType,
  removeFromRow,
  SIDEBAR_ITEM,
  sortRowsAndSetPath
} from "enada-common";
import { EdisonContentCard } from "enada-components";
import DropZone from "../formdesigner/DropZone";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import {
  selectCardDesignerIsEdit,
  selectCardScope,
  selectLivePreviewCurrentCard,
  setCardFieldsData
} from "../../store/slices/cardDesignerSlice";
import { selectAllFields } from "../../store/slices/fieldsSlice";
import { useGetFieldsQuery } from "services/api";

const CardLivePreview = () => {
  const [rows, setRows] = useState<CardField[]>([]);
  const scope = useAppSelector(selectCardScope);
  const currentCard = useAppSelector(selectLivePreviewCurrentCard);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const isEdit = useAppSelector(selectCardDesignerIsEdit);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (isEdit && currentCard?.fields)
      if (currentCard.fields.length > 0) {
        setRows(currentCard?.fields);
      }
  }, [currentCard]);

  const handleDrop = (data: any, value: any) => {
    if (value.type === SIDEBAR_ITEM) {
      // dropping from sidebar
      if (rows.length === 0) {
        setRows([addNewRow(value, 0, 0)]);
      } else {
        let totalRows;
        setRows(prev => {
          totalRows = parseInt(prev[prev.length - 1].configuration.path) + 1;
          return [...prev, addNewRow(value, totalRows, 0)];
        });
      }
    } else {
      if (value.children) {
        //dropping from within card
        const removed = removeFromRow(rows, value.children.configuration.item_path);
        const itemToAdd = { fieldId: value.children.fieldId, id: value.id, type: value.type };
        const updated = sortRowsAndSetPath(removed);
        const totalRows = getAllRowsPath(updated).length;
        const newRows = [...updated, addNewRow(itemToAdd, totalRows, 0)];
        setRows(newRows);
      }
    }
  };

  useEffect(() => {
    dispatch(setCardFieldsData(rows));
  }, [rows]);

  const reorderRows = (_data, value: any, rowPath: number) => {
    if (value.type === SIDEBAR_ITEM) {
      // dropping from sidebar
      const newRow = addNewRow(value, rowPath, 0);
      const beforeInsert = rows.filter(el => parseInt(el.configuration.path) < rowPath);
      const afterInsert = rows.filter(el => parseInt(el.configuration.path) >= rowPath);
      const updated = [...beforeInsert, newRow, ...incrementRowsPath(afterInsert, 1)];
      setRows(updated);
    } else {
      if (value.children) {
        //dropping from within card
        const removed = removeFromRow(rows, value.children.configuration.item_path);
        const itemToAdd = { fieldId: value.children.fieldId, id: value.id, type: value.type };
        const beforeInsert = removed.filter(el => parseInt(el.configuration.path) < rowPath);
        const afterInsert = removed.filter(el => parseInt(el.configuration.path) >= rowPath);
        const rowsToOrder = [
          ...beforeInsert,
          addNewRow(itemToAdd, rowPath, 0),
          ...incrementRowsPath(afterInsert, 1)
        ];
        const rowsOrdered = sortRowsAndSetPath(rowsToOrder);
        setRows(rowsOrdered);
      }
    }
  };
  const canDrop = (item: ItemDNDType) => {
    const existingItem = item.children;
    if (!existingItem) {
      const totalRows = getAllRowsPath(rows).length;
      return totalRows < 10;
    }
    return true;
  };

  const placeholders = useMemo(() => {
    switch (scope) {
      case "projects": {
        return {
          title: t("cardTemplates.projectPreviewTitle"),
          description: t("cardTemplates.projectPreviewDecription")
        };
      }
      case "businessCase": {
        return {
          title: t("cardTemplates.bcPreviewTitle"),
          description: t("cardTemplates.bcPreviewDecription")
        };
      }
      case "programs": {
        return {
          title: t("cardTemplates.programPreviewTitle"),
          description: t("cardTemplates.programPreviewDecription")
        };
      }
    }
  }, [scope, t]);
  return (
    <Box className="edison-card">
      <EdisonContentCard
        title={placeholders?.title ? placeholders.title : ""}
        description={placeholders?.description ? placeholders.description : ""}
        isCompact={false}
        type={scope}
        isExpanded={true}
        imageBadge={"Stage 1"}
        imageBadgeColour={"#FF0000"}
        t={t}
        cardPersonas={[]}
      >
        {fields && (
          <Stack spacing={1}>
            {rows.map((row: CardField, index: number) =>
              // Check if the element is of a different row from the previous one
              (index > 0 && row.configuration.path !== rows[index - 1].configuration.path) ||
              index === 0 ? (
                <Stack key={row.configuration.path} spacing={1}>
                  <DropZone
                    data={rows}
                    onDrop={(data: any, value: any) =>
                      reorderRows(data, value, parseInt(row.configuration.path))
                    }
                    checkDrop={canDrop}
                    className="dropzone-visible"
                  />
                  <CardLivePreviewRow
                    row={row}
                    allFieldsData={fields || []}
                    updateRow={setRows}
                    rows={rows}
                  />
                </Stack>
              ) : null
            )}
            <DropZone data={rows} onDrop={handleDrop} checkDrop={canDrop} />
          </Stack>
        )}
      </EdisonContentCard>
    </Box>
  );
};

export default CardLivePreview;
