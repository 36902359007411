import React, { FC } from "react";
import "./resourceutilization.scss";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import "@bryntum/gantt-thin/gantt.material.css";
import "@bryntum/scheduler-thin/scheduler.material.css";
import "@bryntum/schedulerpro-thin/schedulerpro.material.css";
import { BryntumResourceUtilization } from "@bryntum/schedulerpro-react-thin";
import { ProjectModel } from "@bryntum/schedulerpro-thin";

export interface EdisonResourceComponentProps {
  disabled?: boolean;
  readOnly?: boolean;
  showTooltip?: boolean;
  project: ProjectModel;
  columns?: any[];
  partner?: any;
  startDate?: string;
  endDate?: string;
  onInstanceReady?: (instanceRef: any) => void;
  assignments?: any[];
  dialogTitleDisplayName?: string;
  recordDisplayName?: string;
  taskDisplayName?: string;
  durationDisplayName?: string;
  ownersDisplayName?: string;
  fromDisplayName?: string;
  toDisplayName?: string;
  recordOwners?: any[];
}

const ResourceUtilization: FC<EdisonResourceComponentProps> = ({
  project,
  partner,
  disabled,
  readOnly,
  startDate,
  endDate,
  columns,
  showTooltip,
  recordOwners
}) => {
  return (
    <div className="resource-utilization-container enada-bryntum-grid">
      <BryntumResourceUtilization
        data-testid={"edison-resource-utilization"}
        project={project}
        columns={columns}
        startDate={startDate}
        endDate={endDate}
        partner={partner}
        rowHeight={70}
        showBarTip={showTooltip}
        disabled={disabled}
        readOnly={readOnly}
        autoHeight={true}
        // infiniteScroll={false}
        // height={(project.resourceStore.count ?? 1) * 70 + 70}
      />
    </div>
  );
};

export default ResourceUtilization;
