import { reducerStatus } from "enada-common";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProjects, postRecord } from "../../services/APIService";
import { RootState } from "../store";

export interface enadaState {
  isRecordPage: boolean;
  status: reducerStatus;
}

const initialState: enadaState = {
  isRecordPage: false,
  status: "idle"
};

const enadaSlice = createSlice({
  name: "enada",
  initialState,
  reducers: {
    changeIsRecordPage: (state, action: PayloadAction<boolean>) => {
      state.isRecordPage = action.payload;
    }
  }
});

export const { changeIsRecordPage } = enadaSlice.actions;

const inputSelectEnada = (state: RootState) => state.enada;

export const selectIsRecordPage = createSelector(
  [inputSelectEnada],
  (enada): boolean => enada.isRecordPage
);

export const selectEnadaStatus = createSelector([inputSelectEnada], enada => enada.status);

export default enadaSlice.reducer;
