import {
  BaseRecord,
  Field,
  FieldDataType,
  Form,
  PersonaEntity,
  RecordAccessRole,
  RecordFieldValue,
  RecordPermission,
  RecordRole,
  RecordTableConfiguration,
  RecordTableRow,
  RecordTemplate,
  RecordType,
  TableRowPeriodicGrandTotal,
  Workflow,
  WorkflowReview,
  WorkflowReviewer,
  WorkflowStage,
  WorkflowUserReview,
  reducerStatus,
  SaveStatusType,
  RecordSaveStatusType
} from "enada-common";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { getMissingRequiredValues } from "../../components/stageapproval/stageapprovaltabs/normalstageapproval/NormalStageApproval";
import { RecordDataFlags, clearMultipleBitwiseFlags, setBitwiseFlag } from "../../utils/bitwiseops";
import { RootState } from "../store";
import { parseFormFieldsToFrontend } from "./parseFormData";
import { selectHasTableConfigurationChanges, selectTableOperations } from "./recordTableSlice";
import { RecordAssociation } from "../../types/createRecordModal";

/* START - To Change to Common */
export interface RecordAuth {
  permissionToken: string;
  sessionId: string;
  details: {
    AccessRoles: RecordAccessRole[];
    RecordId: number;
    RecordRoles: RecordRole[];
    Views: number[];
    Workflow: number;
    WorkflowStage: number;
    WorkflowStep: number;
  };
  expiresOnISO?: string;
  expiresIn?: number;
}

export const getHeadersFromAuth = (recordAuth: RecordAuth): Headers => {
  const headers = new Headers();
  headers.append("edison365-sessionid", "frontend");
  return headers;
};

export interface FrontendRecordValue {
  fieldId: number;
  type?: string;
  value?: any;
  changeMade: boolean;
  id?: number;
}

export interface ExtendedValue {
  value: any;
}

export enum SaveSlot {
  Slot1,
  Slot2
}

export enum RecordSettings {
  externalLinks = "externalLinks",
  editCardContent = "editCardContent",
  gamification = "gamification",
  history = "history",
  integrations = "integrations",
  manageAccess = "manageAccess",
  notifications = "notifications",
  properties = "properties",
  historyEvents = "historyEvents",
  delete = "delete",
  stageApproval = "stageApproval",
  ediRowGeneration = "ediRowGeneration",
  tableRowReview = "tableRowReview",
  associatedRecords = "associatedRecords",
  programAssociations = "programAssociations",
  versionHistory = "versionHistory"
}

export enum RecordValueType {
  boolValue = "boolValue",
  dateTimeValue = "dateTimeValue",
  decimalValue = "decimalValue",
  stringValue = "stringValue",
  extendedValue = "extendedValue"
}

export interface RecordValueTypeToFieldType {
  recordValueType: RecordValueType;
  fieldType: FieldDataType;
}

export interface RecordVersion extends BaseRecord {
  recordId: string;
  version: string;
  versionCreated: string;
  versionCreatedBy: string;
  versionName: string;
  versionDescription: string;
}

export enum RecordEvents {
  Create = "historyEventCreate",
  Update = "historyEventUpdate",
  UpdatePermission = "historyEventUpdatePermission",
  Delete = "historyEventDelete",
  Restore = "historyEventRestore",
  VersionCreate = "historyEventVersionCreate",
  StageChange = "historyEventStageChange",
  ApproveReview = "historyEventApproveReview",
  RejectReview = "historyEventRejectReview",
  EditSession = "historyEventEditSession",
  CreateAssociation = "historyEventCreateAssociation"
}

export interface HistoryEventsType {
  id: number;
  recordId: number;
  modified: string;
  modifiedBy: string;
  recordEvent: string;
  recordType: string;
}

export const fieldTypesMap: RecordValueTypeToFieldType[] = [
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.TextBox
  },
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.Email
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.RichText
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Number
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Currency
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Percentage
  },
  // {
  //   projectValueType: RecordValueType.stringValue,
  //   fieldType: FieldDataType.Image,
  // },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiLevelChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiMultiLevelChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Choice
  },
  {
    recordValueType: RecordValueType.dateTimeValue,
    fieldType: FieldDataType.Date
  },
  {
    recordValueType: RecordValueType.dateTimeValue,
    fieldType: FieldDataType.DateTime
  },
  {
    recordValueType: RecordValueType.boolValue,
    fieldType: FieldDataType.Switch
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.People
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiPeople
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Url
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Calculated
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Location
  },
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.Phone
  }
];

export interface CoAuthUser {
  controlRequested?: boolean;
  expiresOn?: Date;
  hasMadeChanges?: boolean;
  isConnected: boolean;
  isEditor: boolean;
  isTokenValid: boolean;
  name: string;
  oid: string;
  recordId: number;
  sessionStartTime?: Date;
}

/* END - To Change to Common */

export interface RecordState {
  record: BaseRecord | null;
  status: reducerStatus;
  authentication: RecordAuth | null;
  workflow: Workflow | null;
  form: Form | null;
  fields: Field[];
  // Normalized property to improve lookup and update performance
  //see https://redux.js.org/tutorials/essentials/part-6-performance-normalization
  frontendRecordFieldValues: Record<number, FrontendRecordValue>;
  backendRecordFieldValues: RecordFieldValue[];
  readOnly: boolean;
  userRating: number;
  historyEvents: HistoryEventsType[] | null;
  isFlyoutOpen: boolean;
  flyoutId: string;
  currentVersion: RecordVersion | null;
  recordTemplate: RecordTemplate | null;
  currentUserReview: WorkflowUserReview | null;
  stageReviewerList: WorkflowReviewer[];
  refreshRecord: boolean;
  workflowStage: WorkflowStage | null;
  hasRollupTableChanged: boolean;
  hasRecordChanges: boolean;
  recordPermissions: RecordPermission[];
  saveStatus: RecordSaveStatusType;
  editor: CoAuthUser | undefined;
  viewers: CoAuthUser[];
  loadedData: number;
  associations: RecordAssociation[];
  isSaving: boolean;
  canEditRecord: boolean;

  //only populated when viewing a version
  recordVersionTableConfigurations?: RecordTableConfiguration[];
  recordVersionPeriodicGrandTotals?: TableRowPeriodicGrandTotal[];
}

export const RecordDataInitialised =
  RecordDataFlags.recordWorkflowStage |
  RecordDataFlags.recordAuth |
  RecordDataFlags.record |
  RecordDataFlags.recordFieldValues |
  RecordDataFlags.recordForm |
  RecordDataFlags.calendars |
  RecordDataFlags.fields |
  RecordDataFlags.resources;

const initialState: RecordState = {
  record: null,
  status: "idle",
  authentication: null,
  workflow: null,
  form: null,
  fields: [],
  frontendRecordFieldValues: {},
  backendRecordFieldValues: [],
  readOnly: false,
  userRating: 0,
  historyEvents: null,
  isFlyoutOpen: false,
  flyoutId: "",
  currentVersion: null,
  recordTemplate: null,
  currentUserReview: null,
  stageReviewerList: [],
  refreshRecord: false,
  workflowStage: null,
  hasRollupTableChanged: false,
  hasRecordChanges: false,
  recordPermissions: [],
  saveStatus: "idle",
  editor: undefined,
  viewers: [],
  loadedData: 0,
  associations: [],
  isSaving: false,
  canEditRecord: false
};

const recordSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    setRecordInfo: (state, action: PayloadAction<BaseRecord>) => {
      state.record = action.payload;
      state.recordPermissions = action.payload.recordPermissions ?? [];
    },
    setWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.workflow = action.payload;
    },
    setWorkflowStage: (state, action: PayloadAction<WorkflowStage>) => {
      state.workflowStage = action.payload;
      state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordWorkflowStage);
    },
    setForm: (state, action: PayloadAction<Form>) => {
      state.form = action.payload;
      const parsed = parseFormFieldsToFrontend(
        action.payload,
        state.fields,
        state.backendRecordFieldValues
      );

      state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
        return { ...acc, [curr.fieldId]: curr };
      }, {});
      state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordForm);
    },
    setFields: (state, action: PayloadAction<Field[]>) => {
      state.fields = action.payload;
      state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.fields);
    },
    setUserRating: (state, action: PayloadAction<number>) => {
      state.userRating = action.payload;
    },
    setRecordViewValues: (state, action: PayloadAction<RecordFieldValue[]>) => {
      if (!action.payload) return;
      state.status = "idle";

      state.backendRecordFieldValues = action.payload.filter(
        row => !row.recordId || row.recordId === state.authentication?.details.RecordId
      );

      if (state.form) {
        const parsed = parseFormFieldsToFrontend(
          state.form,
          state.fields,
          state.backendRecordFieldValues
        );

        state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
          return { ...acc, [curr.fieldId]: curr };
        }, {});
      }
      state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordFieldValues);
    },
    setAuthentication: (state, action: PayloadAction<RecordAuth>) => {
      state.authentication = {
        permissionToken: action.payload.permissionToken,
        sessionId: "frontend",
        details: action.payload.details,
        expiresIn: action.payload.expiresIn,
        expiresOnISO: action.payload.expiresOnISO
      };
      state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordAuth);
    },
    setCurrentUserReview: (state, action: PayloadAction<WorkflowReview>) => {
      state.currentUserReview = {
        ...state.currentUserReview,
        workflowReview: action.payload
      };
    },
    setStageReviewerList: (state, action: PayloadAction<WorkflowReviewer[]>) => {
      state.stageReviewerList = action.payload;
    },
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
    setIsFlyoutOpen: (state, action: PayloadAction<boolean>) => {
      state.isFlyoutOpen = action.payload;
    },
    setFlyoutId: (state, action: PayloadAction<string>) => {
      state.flyoutId = action.payload;
    },
    setRecordReadOnly: (state, action: PayloadAction<boolean>) => {
      state.readOnly = action.payload;
    },
    setRecordEditor: (state, action: PayloadAction<CoAuthUser | undefined>) => {
      state.editor = action.payload;
    },
    addViewers: (state, action) => {
      state.viewers = action.payload;
    },
    addViewer: (state, action) => {
      state.viewers = [...state.viewers, action.payload];
    },
    removeViewer: (state, action: PayloadAction<CoAuthUser>) => {
      const index = state.viewers.findIndex(viewer => viewer.oid === action.payload.oid);
      if (index !== -1) state.viewers.splice(index, 1);
    },
    resetCoAuth: state => {
      state.editor = undefined;
      state.viewers = [];
    },
    resetReviewers: state => {
      state.stageReviewerList = [];
      state.currentUserReview = null;
    },
    updateSystemFields: (
      state,
      action: PayloadAction<{ key: keyof BaseRecord; changeValue: any }>
    ) => {
      const { key, changeValue } = action.payload;
      if (!state.record) return; // record should always have a value if we are updating it.
      state.record = { ...state.record, [key]: changeValue };
      state.hasRecordChanges = true;
    },
    updateRecordFieldValue: (state, action: PayloadAction<{ id?: number; changeValue: any }>) => {
      const isRefetching = state.refreshRecord;
      if (isRefetching) return; // Do not update values if we are refetching data
      const fieldValueToChange = state.frontendRecordFieldValues[action.payload.id as number];

      state.frontendRecordFieldValues[action.payload.id as number] = {
        ...fieldValueToChange,
        value: action.payload.changeValue,
        changeMade: true
      };
    },
    resetRecordSlice: state => {
      state.record = null;
      state.status = "idle";
      state.authentication = null;
      state.workflow = null;
      state.form = null;
      state.fields = [];
      state.frontendRecordFieldValues = {};
      state.backendRecordFieldValues = [];
      state.readOnly = false;
      state.userRating = 0;
      state.historyEvents = null;
      state.isFlyoutOpen = false;
      state.flyoutId = "";
      state.currentVersion = null;
      state.recordTemplate = null;
      state.currentUserReview = null;
      state.stageReviewerList = [];
      state.refreshRecord = false;
      state.workflowStage = null;
      state.hasRollupTableChanged = false;
      state.hasRecordChanges = false;
      state.recordPermissions = [];
      state.saveStatus = "idle";
      state.editor = undefined;
      state.viewers = [];
      state.loadedData = 0;
      state.associations = [];
    },
    clearRecordAuth: state => {
      state.authentication = null;
    },
    setRefreshRecord: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        const newAuth = {
          ...state.authentication,
          expiresOnISO: "0"
        } as RecordAuth;
        state.authentication = newAuth;
      }
      if (action.payload) {
        state.loadedData = 0;
      }
      state.refreshRecord = action.payload;
    },
    updateRecordRoles: (
      state,
      action: PayloadAction<{
        role: RecordRole;
        entities?: PersonaEntity[];
      }>
    ) => {
      const filteredPermissions = state.recordPermissions.filter(
        permission => permission.role !== action.payload.role
      );

      const updatedPermissions = action.payload.entities
        ? action.payload.entities.map(
            entity =>
              ({
                entityId: entity.entityId,
                permissionType: entity.type,
                role: action.payload.role
              } as RecordPermission)
          )
        : [];

      if (filteredPermissions) {
        state.recordPermissions = filteredPermissions.concat(updatedPermissions);
      }
    },

    updateSaveStatus: (state, action: PayloadAction<SaveStatusType>) => {
      switch (action.payload) {
        case "record":
          state.hasRecordChanges = false;
          break;
        case "fields":
          state.frontendRecordFieldValues = Object.values(state.frontendRecordFieldValues)
            .map(fieldValue => {
              return { ...fieldValue, changeMade: false };
            })
            .reduce((acc, curr) => ({ ...acc, [curr.fieldId]: curr }), {});
          break;
        default:
          break;
      }

      state.saveStatus = getUpdatedSaveStatus(state.saveStatus, action.payload, undefined);
    },
    setHasRollupTableChanged: (state, action: PayloadAction<boolean>) => {
      state.hasRollupTableChanged = action.payload;
    },
    addRecordDataLoaded: (state, action: PayloadAction<RecordDataFlags>) => {
      state.loadedData = setBitwiseFlag(state.loadedData, action.payload);
    },
    clearRecordDataLoadedBits: (state, action: PayloadAction<RecordDataFlags[]>) => {
      state.loadedData = clearMultipleBitwiseFlags(state.loadedData, action.payload);
    },
    setHasRecordChanges: (state, action: PayloadAction<boolean>) => {
      state.hasRecordChanges = action.payload;
    },
    setCurrentVersion: (state, action: PayloadAction<RecordVersion | null>) => {
      state.currentVersion = action.payload;
    },
    setRecordVersion: (state, action: PayloadAction<any>) => {
      if (!action.payload) return;
      state.fields = action.payload.versionData.fields;
      state.userRating = action.payload.versionData.record.averageRating;
      state.record = {
        ...action.payload.versionData.record,
        id: action.payload.recordId
      };
      state.recordTemplate = action.payload.versionData.record.recordTemplate;
      state.recordPermissions = action.payload.versionData.record.recordPermissions || [];
      state.recordVersionTableConfigurations =
        action.payload.versionData.record.recordTableConfigurations || [];

      // grab the periodic grand totals from the version data and group them by tableId
      state.recordVersionPeriodicGrandTotals = action.payload.versionData.record.tableRows
        .filter((row: any) => Object.prototype.hasOwnProperty.call(row, "grandTotal"))
        .reduce((acc: any[], row: any) => {
          const tableId = row.tableId;
          const existingTable = acc.find(table => table.tableId === tableId);
          if (existingTable) {
            existingTable.totals[row.id] = row.grandTotal;
          } else {
            acc.push({ tableId, totals: { [row.id]: row.grandTotal } });
          }
          return acc;
        }, []);

      state.workflow = action.payload.versionData.record.workflow;
      state.workflowStage = action.payload.versionData.record.workflowStage;
      state.form = action.payload.versionData.record.workflowStage.form;
      state.backendRecordFieldValues = action.payload.versionData.record.fieldValues.filter(
        (row: RecordTableRow) =>
          !row.recordId || row.recordId === state.authentication?.details.RecordId
      );

      if (state.form) {
        const parsed = parseFormFieldsToFrontend(
          state.form,
          state.fields,
          state.backendRecordFieldValues
        );
        state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
          return { ...acc, [curr.fieldId]: curr };
        }, {});
      }
    },

    setClearChangedFields: state => {
      state.frontendRecordFieldValues = Object.values(state.frontendRecordFieldValues)
        .map(fieldValue => {
          return { ...fieldValue, changeMade: false };
        })
        .reduce((acc, curr) => ({ ...acc, [curr.fieldId]: curr }), {});
    },

    setRecordAssociations: (state, action: PayloadAction<RecordAssociation[]>) => {
      state.associations = action.payload;
    },
    setCanEditRecord: (state, action: PayloadAction<boolean>) => {
      state.canEditRecord = action.payload;
    }
  }
});

export const {
  addRecordDataLoaded,
  addViewer,
  addViewers,
  clearRecordAuth,
  clearRecordDataLoadedBits,
  removeViewer,
  resetCoAuth,
  resetRecordSlice,
  resetReviewers,
  setAuthentication,
  setClearChangedFields,
  setCurrentUserReview,
  setCurrentVersion,
  setFields,
  setFlyoutId,
  setForm,
  setHasRecordChanges,
  setHasRollupTableChanged,
  setIsFlyoutOpen,
  setIsSaving,
  setRecordAssociations,
  setRecordEditor,
  setRecordInfo,
  setRecordReadOnly,
  setRecordVersion,
  setRecordViewValues,
  setRefreshRecord,
  setStageReviewerList,
  setUserRating,
  setWorkflow,
  setWorkflowStage,
  updateRecordFieldValue,
  updateRecordRoles,
  updateSaveStatus,
  updateSystemFields,
  setCanEditRecord
} = recordSlice.actions;

const inputSelectRecord = (state: RootState) => state.record;

// All data required for full record functionality loaded
export const selectRecordFullyInitialised = createSelector([inputSelectRecord], record => {
  return RecordDataInitialised === record.loadedData;
});

export const selectRecord = createSelector([inputSelectRecord], record => record.record);

export const selectRecordType = (state: RootState) =>
  state.record.record?.recordType ?? RecordType.Projects;

export const selectHistoryEvents = createSelector(
  [inputSelectRecord],
  record => record.historyEvents
);

export const selectEditor = createSelector([inputSelectRecord], record => record.editor);
export const selectViewers = createSelector([inputSelectRecord], record =>
  record.viewers.filter((viewer: any) => !record.editor || viewer.oid !== record.editor.oid)
);

export const selectUserRating = createSelector([inputSelectRecord], record => record.userRating);

export const selectCurrentVersion = createSelector(
  [inputSelectRecord],
  record => record.currentVersion
);

export const selectRecordAuth = createSelector(
  [inputSelectRecord],
  record => record.authentication
);

export const selectRecordWorkflow = createSelector([inputSelectRecord], record => record.workflow);

export const selectRecordForm = createSelector([inputSelectRecord], record => record.form);

export const selectRecordFields = createSelector([inputSelectRecord], record => record.fields);

export const selectRecordReadOnly = createSelector([inputSelectRecord], record => record.readOnly);

export const selectWorkflowStage = createSelector(
  [inputSelectRecord],
  record => record.workflowStage
);

export const selectIsRecordFlyoutOpen = createSelector(
  [inputSelectRecord],
  record => record.isFlyoutOpen
);

export const selectCanEditRecord = createSelector(
  [inputSelectRecord],
  record => record.canEditRecord
);

export const selectFlyoutId = createSelector([inputSelectRecord], record => record.flyoutId);

export const selectRecordFieldValues = createSelector(
  [inputSelectRecord],
  (record): FrontendRecordValue[] => Object.values(record.frontendRecordFieldValues)
);
export const selectFieldValueById = createSelector(
  [inputSelectRecord, (_, fieldId) => fieldId],
  (record, fieldId) => record.frontendRecordFieldValues[fieldId]
);

export const selectWorkflowUserReviews = createSelector(
  [inputSelectRecord],
  record => record.currentUserReview
);

export const selectRecordStageReviewerList = createSelector(
  [inputSelectRecord],
  record => record.stageReviewerList
);

export const selectRefreshRecord = createSelector(
  [inputSelectRecord],
  record => record.refreshRecord
);

export const selectRecordStatus = createSelector([inputSelectRecord], record => record.status);

export const selectRecordPermissions = createSelector(
  [inputSelectRecord],
  record => record.recordPermissions
);

export const selectRecordTypePermissions = (state: RootState) =>
  state.record.recordTemplate?.templatePermissions;

export const selectRecordSaveStatus = createSelector(
  [inputSelectRecord],
  record => record.saveStatus
);

export const selectHasRecordChanges = createSelector(
  [inputSelectRecord],
  record => record.hasRecordChanges
);

export const selectHasRollupTableChanged = createSelector(
  [inputSelectRecord],
  record => record.hasRollupTableChanged
);

export const selectHasChanges = createSelector(
  [
    selectRecordFieldValues,
    selectTableOperations,
    selectHasRecordChanges,
    selectHasTableConfigurationChanges,
    selectCurrentVersion
  ],
  (
    frontendValues,
    tableOperations,
    hasRecordChanges,
    hasTableConfigurationChanges,
    currentVersion
  ) => {
    if (currentVersion && currentVersion?.id && currentVersion?.id > 0) {
      return false;
    }

    const hasFieldChanges = frontendValues.some(field => {
      return field.changeMade === true;
    });
    const hasTableChanges = tableOperations.length > 0;

    const result =
      hasFieldChanges || hasTableChanges || hasRecordChanges || hasTableConfigurationChanges;

    return result;
  }
);

export const selectUserAccessRoles = createSelector(
  [inputSelectRecord],
  record => record.authentication?.details?.AccessRoles
);

export const selectUserRecordRoles = createSelector(
  [inputSelectRecord],
  (
    record //[RecordRole.Manager, RecordRole.Owner]
  ) => record.authentication?.details.RecordRoles
);
// Pulls state from both recordSlice and recordTableSlice
export const selectRecordTabsMissingValues = createSelector(
  [inputSelectRecord, (state: RootState) => state.recordTable.backendRecordRowValues],
  (record, backendTableRowValues) => {
    const stageView =
      record.workflowStage &&
      record.workflowStage?.views &&
      record.workflowStage?.views?.length !== 0
        ? record.workflowStage.views[0] // TODO : review this when we can add multiple views to the a workflow
        : undefined;
    const { missingFields, missingTables } = getMissingRequiredValues(
      Object.values(record.frontendRecordFieldValues),
      backendTableRowValues,
      stageView
    );
    return record.form?.containers
      ?.filter(tab =>
        tab?.zones?.some(zone =>
          zone.columns?.some(column => {
            return (
              column.fields?.some(columnField =>
                missingFields.some(missingField => missingField.fieldId === columnField.fieldId)
              ) ||
              column.tables?.some(columnTable =>
                missingTables.some(missingTable => missingTable.tableId === columnTable.tableId)
              )
            );
          })
        )
      )
      .map(container => container.id);
  }
);

export const selectRecordAssociations = createSelector(
  [inputSelectRecord],
  record => record.associations
);
export const selectRecordTaskTableId = createSelector(
  [inputSelectRecord],
  record => record.record?.taskTableId
);
export const selectRecordUseChildDates = createSelector(
  [inputSelectRecord],
  record => record.record?.useChildDateRange
);
export const selectRecordVersionTableConfigurations = (state: RootState) =>
  state.record.recordVersionTableConfigurations;

export const selectRecordVersionPeriodicGrandTotals = (state: RootState) =>
  state.record.recordVersionPeriodicGrandTotals;

export const selectIsRecordSaving = createSelector([inputSelectRecord], record => record.isSaving);

export default recordSlice.reducer;

const getUpdatedSaveStatus = (
  status: RecordSaveStatusType,
  pending: SaveStatusType | undefined,
  fulfilled: SaveStatusType | undefined
) => {
  if (pending) {
    const pendingStatus = Array.isArray(status) ? [...status, pending] : [pending];
    return pendingStatus;
  }

  if (fulfilled && Array.isArray(status)) {
    const fulfilledStatus = status.filter(x => x !== fulfilled);
    if (fulfilledStatus.length) return fulfilledStatus;
  }

  return "idle";
};
