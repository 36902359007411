import { FC } from "react";
import { useUniqueGradientIds } from "./useUniqueGradientIds";

const EdiIconLarge: FC = () => {
  const gradientIds = useUniqueGradientIds(6);
  return (
    <svg
      width="150"
      height="144"
      viewBox="0 0 150 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.364 122.463C105.173 133.26 88.2171 139.52 69.8978 138.966C48.238 138.308 29.0515 128.262 16.0713 112.816"
        stroke={`url(#${gradientIds.gradient1})`}
        strokeWidth="10"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.0088 12.3547C47.6526 7.6408 58.4756 5 69.9113 5C93.7518 5 114.931 16.483 128.312 34.2644"
        stroke={`url(#${gradientIds.gradient2})`}
        strokeWidth="10"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M124.415 58.3514C118.958 71.5 116.45 85.8872 117.399 100.115C107.931 114.457 90.0345 122.855 72.9517 122.708C41.43 122.901 15.4218 93.0354 16.3369 62.0335C16.3595 58.6242 16.9018 55.8286 18.1107 52.4874C26.5843 30.6452 51.1125 18.6899 73.5732 19.4059C96.2146 19.9286 120.189 35.0205 124.403 58.3628L124.415 58.3514Z"
        fill={`url(#${gradientIds.gradient3})`}
      />
      <path
        d="M73.2792 112.309C74.0588 112.196 74.7254 112.434 74.9852 113.252C75.2677 114.15 74.6011 114.889 73.7424 115.003C71.5958 115.309 69.4039 115.389 67.1669 115.264C64.1729 115.093 61.3032 114.366 58.5803 113.082C57.4392 112.548 56.1625 111.866 54.7503 111.036C54.3661 110.809 54.1176 110.548 54.0159 110.264C53.6431 109.207 54.4791 108.275 55.5637 108.559C55.8462 108.639 56.1174 108.741 56.3772 108.9C57.202 109.377 58.0154 109.809 58.8176 110.207C61.2354 111.377 63.6758 112.116 66.1388 112.412C68.534 112.696 70.9066 112.662 73.2792 112.332V112.309Z"
        fill="#080020"
      />
      <path
        d="M73.2792 112.309C74.0588 112.196 74.7254 112.434 74.9852 113.252C75.2677 114.15 74.6011 114.889 73.7424 115.003C71.5958 115.309 69.4039 115.389 67.1669 115.264C64.1729 115.093 61.3032 114.366 58.5803 113.082C57.4392 112.548 56.1625 111.866 54.7503 111.036C54.3661 110.809 54.1176 110.548 54.0159 110.264C53.6431 109.207 54.4791 108.275 55.5637 108.559C55.8462 108.639 56.1174 108.741 56.3772 108.9C57.202 109.377 58.0154 109.809 58.8176 110.207C61.2354 111.377 63.6758 112.116 66.1388 112.412C68.534 112.696 70.9066 112.662 73.2792 112.332V112.309Z"
        stroke="black"
      />
      <path
        d="M102.96 97.8538C100.067 99.8767 96.7794 101.013 93.2996 101.013C81.6513 101.013 72.2061 88.251 72.2061 72.5C72.2061 56.7491 81.6513 43.9869 93.2996 43.9869C96.7794 43.9869 100.067 45.1234 102.96 47.1462C94.3052 49.3509 87.7522 59.8629 87.7522 72.5C87.7522 85.1372 94.3052 95.6378 102.96 97.8538Z"
        fill="white"
      />
      <path
        d="M114.394 72.5113C114.394 83.5461 109.75 93.1262 102.96 97.8651C94.3059 95.6605 87.7529 85.1485 87.7529 72.5113C87.7529 59.8742 94.3059 49.3736 102.96 47.1575C109.75 51.8965 114.394 61.4652 114.394 72.5113Z"
        fill="#080020"
      />
      <path
        d="M48.1523 90.842C45.7458 92.5694 43.023 93.5467 40.1307 93.5467C30.4595 93.5467 22.6299 82.6256 22.6299 69.1475C22.6299 55.6694 30.4708 44.7483 40.1307 44.7483C43.023 44.7483 45.7458 45.7256 48.141 47.453C40.9554 49.3395 35.521 58.34 35.521 69.1475C35.521 79.955 40.9667 88.9555 48.141 90.842H48.1523Z"
        fill="white"
      />
      <path
        d="M20.0429 91.9216C8.23636 87.1486 8.36064 77.3525 8.36064 66.4201C8.36064 58.6014 6.57552 54.1693 16.6421 43.3959C14.2582 48.4985 10.27 59.4765 10.8575 67.9997C11.5467 78.0344 16.1337 87.5691 20.0429 91.9216Z"
        fill="#23E4F9"
      />
      <path
        d="M20.0428 91.9216C19.8734 91.9557 19.7039 91.9898 19.5344 92.0125C18.8678 92.1262 18.19 92.1716 17.5008 92.1716C7.82958 92.1716 0 81.2505 0 67.7724C0 55.1126 6.91443 44.7142 15.7608 43.4868C16.0546 43.4528 16.3371 43.4187 16.6308 43.3959C15.6479 44.3392 14.7214 45.4301 13.8741 46.6461C10.4734 51.5555 8.34929 58.6014 8.34929 66.4201C8.34929 77.3525 12.507 86.7508 18.4837 90.967C18.9921 91.3193 19.5118 91.6375 20.0428 91.9216Z"
        fill={`url(#${gradientIds.gradient4})`}
      />
      <path
        d="M57.6426 69.1475C57.6426 78.5913 53.79 86.7963 48.1522 90.842C40.9666 88.9555 35.5322 79.955 35.5322 69.1475C35.5322 58.34 40.9779 49.3395 48.1522 47.453C53.79 51.5101 57.6426 59.7037 57.6426 69.1475Z"
        fill="#080020"
      />
      <path
        d="M145.653 85.1421C143.681 92.4298 139.942 98.4334 135.564 102.143C134.47 103.065 133.354 103.844 132.205 104.459H132.194C128.802 103.921 127.729 102.78 126.375 100.53C124.684 97.731 120.599 70.8519 134.491 60.7654C137.363 58.6801 139.834 58.3399 141.275 58.3947C141.698 58.7899 142.088 59.2289 142.467 59.6898C143.367 60.7874 144.147 62.0715 144.786 63.4874C144.938 63.8057 145.079 64.124 145.209 64.4642C147.398 70.0068 147.723 77.4702 145.653 85.1421Z"
        fill={`url(#${gradientIds.gradient5})`}
      />
      <path
        d="M149.999 75.5384C150.02 78.4579 149.695 81.531 148.915 84.5822C148.883 84.7469 148.839 84.9005 148.796 85.0651C146.704 92.8029 143.063 98.8614 139.162 102.066C137.222 103.669 135.207 104.569 133.3 104.602C132.909 104.558 132.541 104.514 132.205 104.459C133.354 103.844 134.47 103.065 135.564 102.143C139.942 98.4333 143.681 92.4297 145.653 85.142C147.723 77.4701 147.398 70.0067 145.209 64.4641C145.079 64.1239 144.938 63.8056 144.786 63.4873C144.147 62.0714 143.367 60.7873 142.467 59.6898C142.088 59.2288 141.698 58.7898 141.275 58.3947C141.438 58.4056 141.6 58.4276 141.763 58.4386C142.077 58.4715 142.381 58.5483 142.684 58.6142C143.009 58.691 143.345 58.7678 143.681 58.8117C146.704 59.152 149.945 66.5824 149.999 75.5384Z"
        fill={`url(#${gradientIds.gradient6})`}
      />
      <defs>
        <linearGradient
          id={gradientIds.gradient1}
          x1="10.8464"
          y1="125.907"
          x2="123.592"
          y2="125.907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBA00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={gradientIds.gradient2}
          x1="32.7932"
          y1="19.634"
          x2="133.531"
          y2="19.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBA00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={gradientIds.gradient3}
          x1="124.415"
          y1="71.0425"
          x2="16.3135"
          y2="71.0425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FF" />
          <stop offset="0.724" stopColor="#12C9F2" />
        </linearGradient>
        <linearGradient
          id={gradientIds.gradient4}
          x1="10.0214"
          y1="92.183"
          x2="10.0214"
          y2="43.4073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF369A" />
          <stop offset="1" stopColor="#FFBB00" />
        </linearGradient>
        <linearGradient
          id={gradientIds.gradient5}
          x1="124.489"
          y1="81.4323"
          x2="147.04"
          y2="81.4323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={gradientIds.gradient6}
          x1="132.205"
          y1="81.4981"
          x2="149.999"
          y2="81.4981"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2B59" />
          <stop offset="1" stopColor="#11C9F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EdiIconLarge;
