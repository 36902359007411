import { Configuration, RedirectRequest, LogLevel } from "@azure/msal-browser";

///////
// TODO: get settings from az app settings
///////

const clientId = process.env.NX_PUBLIC_AAD_CLIENTID as string;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: "/",
    postLogoutRedirectUri: ""
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Warning
    }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["api://" + clientId + "/edison365.default", "offline_access", "openid", "profile"]
};

export const graphRequest = {
  scopes: ["https://graph.microsoft.com/.default"]
};

// // Direct endpoint
// const contentHubEndpoint = "https://api-edison365-contenthub-dev-westeu-001.azurewebsites.net/";

export const apiGateway = process.env.NX_PUBLIC_API_GATEWAY as string;

const cdnPath = process.env.NX_PUBLIC_CDN_PATH as string;

const tempBC = `https://api-edison365-businesscase-dev-westeu-001.edison365.com`;

const apiPaths = {
  management: "/management/",
  contentHub: "/contenthub/",
  common: "/common/",
  records: "/records/",
  // resourceManager: "/resource-man-mockup/",
  resourceManager: "/resourcemanagement/"
};

export const apiConfig = {
  // contentHubFieldsEndpoint: `${contentHubEndpoint}api/fields`,
  commonApi: `${apiGateway}${apiPaths.common}api/`,
  commonExpressionEndpoint: `${apiGateway}${apiPaths.common}api/evaluate/expression`,
  commonTagsRoleEndpoint: `${apiGateway}${apiPaths.common}api/tags/role`,
  commonTagsSkillEndpoint: `${apiGateway}${apiPaths.common}api/tags/skill`,
  commonOdataEndpoint: `${apiGateway}${apiPaths.common}api/odata`,
  commonGenerateTasksEndpoint: `${apiGateway}${apiPaths.common}api/ai/GenerateTasks`,
  commonConvertMppEndpoint: `${apiGateway}${apiPaths.common}api/convertmpp`,
  //commonConvertMppEndpoint: `https://localhost:4001/api/convertmpp`,

  contentHubApi: `${apiGateway}${apiPaths.contentHub}api`,
  contentHubNewsEndpoint: `${apiGateway}${apiPaths.contentHub}api/news`,
  contentHubODataApiCards: `${apiGateway}${apiPaths.contentHub}api/odata/cards`,
  contentHubCardsEndpoint: `${apiGateway}${apiPaths.contentHub}api/cards`,
  contentHubFieldsEndpoint: `${apiGateway}${apiPaths.contentHub}api/fields`,
  contentHubTablesEndpoint: `${apiGateway}${apiPaths.contentHub}api/tables`,
  contentHubCalendarsEndpoint: `${apiGateway}${apiPaths.contentHub}api/calendars`,
  contentHubFormsEndpoint: `${apiGateway}${apiPaths.contentHub}api/forms`,
  contentHubWorkflowEndpoint: `${apiGateway}${apiPaths.contentHub}api/workflows`,
  contentHubUserDetailsEndpoint: `${apiGateway}${apiPaths.contentHub}api/userdetails`,

  // records api
  recordsApi: `${apiGateway}${apiPaths.records}api/records`,
  recordsTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/recordtemplates`,
  recordsODataEndpoint: `${apiGateway}${apiPaths.records}api/odata/records`,
  oDataEndpoint: `${apiGateway}${apiPaths.records}api/odata`,
  recordsODataTasksEndpoint: `${apiGateway}${apiPaths.records}api/odata/tasks`,
  recordsMyWorkEndpoint: `${apiGateway}${apiPaths.records}api/mywork`,
  contentHubUserDetailsODataEndpoint: `${apiGateway}${apiPaths.contentHub}api/odata/userdetails`,
  deletedRecordsODataEndpoint: `${apiPaths.records}api/odata/deletedrecords`,

  // records api - projects
  projectsEndpoint: `${apiGateway}${apiPaths.records}api/projects`,
  // projectCoauthoringEndpoint: `https://api-edison365-projects-dev-westeu-001.edison365.com/api/projectcoauthoring`,
  projectTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/projecttemplates`,
  projectTablesEndpoint: `${apiGateway}${apiPaths.records}api/projecttables`,
  projectPermissionsEndpoint: `${apiGateway}${apiPaths.records}api/projectpermissions`,

  // records coauthoring endpoints
  recordsCoauthoringEndpoint: `${apiGateway}${apiPaths.records}hub/coauthoring`,

  projectReviewsEndpoint: `${apiGateway}${apiPaths.records}api/projectreviews`,

  projectAssociationsEndpoint: `${apiGateway}${apiPaths.records}api/projectassociations`,
  projectVersionsEndpoint: `${apiGateway}${apiPaths.records}api/projectversions`,

  // records api - business case
  businessCasesEndpoint: `${apiGateway}${apiPaths.records}api/businesscases`,
  businessCaseTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/businesscasetemplates`,
  businessCaseTablesEndpoint: `${apiGateway}${apiPaths.records}api/businesscasetables`,
  businessCasePermissionsEndpoint: `${apiGateway}${apiPaths.records}api/businesscasepermissions`,
  businessCaseReviewsEndpoint: `${apiGateway}${apiPaths.records}api/businesscasereviews`,
  businessCaseAssociationsEnpoint: `${apiGateway}${apiPaths.records}api/businesscaseassociations`,
  businessCaseVersionsEndpoint: `${apiGateway}${apiPaths.records}api/businesscaseversions`,

  // records api - programs
  programsEndpoint: `${apiGateway}${apiPaths.records}api/programs`,
  programTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/programtemplates`,
  programTablesEndpoint: `${apiGateway}${apiPaths.records}api/programtables`,
  programPermissionsEndpoint: `${apiGateway}${apiPaths.records}api/programpermissions`,
  programReviewsEndpoint: `${apiGateway}${apiPaths.records}api/programreviews`,
  programAssociationsEndpoint: `${apiGateway}${apiPaths.records}api/programassociations`,
  programConfigurationODataEndpoint: `${apiGateway}${apiPaths.records}api/odata/programconfiguration`,
  programVersionsEndpoint: `${apiGateway}${apiPaths.records}api/programversions`,

  // records api - Challenges
  challengesEndpoint: `${apiGateway}${apiPaths.records}api/challenges`,
  challengeTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/challengetemplates`,
  challengeTablesEndpoint: `${apiGateway}${apiPaths.records}api/challengetables`,
  challengePermissionsEndpoint: `${apiGateway}${apiPaths.records}api/challengepermissions`,
  challengeReviewsEndpoint: `${apiGateway}${apiPaths.records}api/challengereviews`,
  challengeConfigurationODataEndpoint: `${apiGateway}${apiPaths.records}api/odata/challengeconfiguration`,
  challengeAssociationsEndpoint: `${apiGateway}${apiPaths.records}api/challengeassociations`,
  challengeVersionsEndpoint: `${apiGateway}${apiPaths.records}api/challengeversions`,

  //records api - ideas
  ideasEndpoint: `${apiGateway}${apiPaths.records}api/ideas`,
  ideaTemplatesEndpoint: `${apiGateway}${apiPaths.records}api/ideatemplates`,
  ideaTablesEndpoint: `${apiGateway}${apiPaths.records}api/ideatables`,
  ideaPermissionsEndpoint: `${apiGateway}${apiPaths.records}api/ideapermissions`,
  ideaReviewsEndpoint: `${apiGateway}${apiPaths.records}api/ideareviews`,
  ideaConfigurationODataEndpoint: `${apiGateway}${apiPaths.records}api/odata/ideaconfiguration`,
  ideaAssociationsEndpoint: `${apiGateway}${apiPaths.records}api/ideaassociations`,
  ideaVersionsEndpoint: `${apiGateway}${apiPaths.records}api/ideaversions`,

  // records api - not implemented

  managementApi: `${apiGateway}${apiPaths.management}api`,
  managementLicensesEndpoint: `${apiGateway}${apiPaths.management}api/licenses`,
  licenseSettingsEndpoint: `${apiGateway}${apiPaths.management}api/licensesettings`,

  resourcesFieldsEndpoint: `${apiGateway}${apiPaths.resourceManager}api/resourcefields`,
  resourcesEndpoint: `${apiGateway}${apiPaths.resourceManager}api/resources`,
  // Temporary, for demo, use all resources
  myResourcesEndpoint: `${apiGateway}${apiPaths.resourceManager}api/odata/resources`,
  //myResourcesEndpoint: `${apiGateway}${apiPaths.resourceManager}api/odata/myresources`,

  rolesEndpoint: `${apiGateway}${apiPaths.management}api/roles`,
  permissionsEndpoint: `${apiGateway}${apiPaths.management}api/permissions`,

  apiGatewayApi: `${apiGateway}/api/`,
  supportEndpoint: "https://support.edison365.com/",

  cdnImagePath: `${cdnPath}/images`
};
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphEndpoint: "https://graph.microsoft.com/v1.0"
};

export const SYSTEM_FIELD_ID_LIMIT = 1000;
export const SYSTEM_FILE_SIZE_LIMIT = 3670016;
// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//   graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
// };
