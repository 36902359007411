import { Button, Divider, Drawer, IconButton, MenuItem, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EdisonEnumSelect, EdisonPeoplePickerField, EdisonTypography } from "enada-components";
import CloseIcon from "@mui/icons-material/Close";
import "./managelicenses.scss";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ApiError,
  License,
  LicenseType,
  NotificationLevel,
  PermissionType,
  PersonaEntity
} from "enada-common";
import { inputSelectTenant } from "../../../store/slices/userSlice";
import { getRequestMsGraph } from "../../../services/APIService";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { FrontendLicenseType, LicenseSettings } from "../../../types/licenses";
import {
  useGetLicenseSettingsQuery,
  useGetLicensesQuery,
  useUpdateLicensesMutation
} from "services/api";

enum LicenceSelectEnum {
  None = "None",
  Full = "Full",
  Lite = "Lite",
  readOnly = "readOnly"
}

export interface ManageLicensesProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  autoAssignLicenseType: FrontendLicenseType;
}
const ManageLicenses: FC<ManageLicensesProps> = ({ open, setOpen, autoAssignLicenseType }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const tenant = useAppSelector(inputSelectTenant);
  const { data: licenses = [] } = useGetLicensesQuery(tenant, { skip: !tenant });
  const { data: licenseSettings } = useGetLicenseSettingsQuery(tenant, { skip: !tenant });
  const [updateLicense] = useUpdateLicensesMutation();

  const [currentLicenseType, setCurrentLicenseType] =
    useState<FrontendLicenseType>(autoAssignLicenseType);
  const [newUsers, setNewUsers] = useState<PersonaEntity[]>([]);

  useEffect(() => {
    setCurrentLicenseType(autoAssignLicenseType);
  }, [autoAssignLicenseType]);

  const remainingFullLicenseCount =
    (licenseSettings?.creatorLicenses ?? 0) -
    licenses.filter(license => (license?.licenseType ?? 0) >= LicenseType.Base).length;

  const remainingLiteLicenseCount =
    (licenseSettings?.liteLicenses ?? 0) -
    licenses.filter(
      ({ licenseType }) => licenseType !== undefined && licenseType === LicenseType.Lite
    ).length;

  const onClose = () => {
    setNewUsers([]);
    setCurrentLicenseType(autoAssignLicenseType);
    setOpen(false);
  };

  const onUpdateLicense = async (licenses: License[]) => {
    try {
      const response = await updateLicense(licenses).unwrap();

      if (response)
        dispatch(
          setCurrentNotification({
            level: NotificationLevel.Success,
            title: "licensesUpdated",
            message: ""
          })
        );
    } catch (e) {
      const error = e as { data: ApiError };

      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Error,
          message: error.data.detail ?? "licensesUpdatedError",
          title: "Error updating Licenses."
        })
      );
    }
  };
  return (
    <Drawer
      anchor={"right"}
      open={open}
      ModalProps={{
        slotProps: { backdrop: { invisible: true } }
      }}
      PaperProps={{
        sx: {
          minWidth: "33em",
          maxWidth: "540px",
          width: "100%"
        }
      }}
      onClose={() => setOpen(false)}
    >
      <Stack spacing={2} className="manage-licenses-root">
        <Stack direction="row" className="top-container">
          <EdisonTypography title={t("addUsers")} variant="h2" />
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Divider flexItem />
        <EdisonTypography
          title={t("licenseAccessMessage")}
          variant="helpertext"
          sx={{ textWrap: "wrap" }}
        />
        <Stack spacing={2}>
          <Stack>
            <EdisonPeoplePickerField
              multiple
              onChange={async (members: any[]) => {
                let currentExpandedGroups: string[] = [];

                const expanded = await members.reduce(async (acc: PersonaEntity[], member: any) => {
                  if (member.type === PermissionType.User) {
                    //The 2 awaits below are needed for the people picker
                    return [...(await acc), member.entityId];
                  }
                  const { expandedGroups, userIds } = await recurseExpandGroups(
                    currentExpandedGroups,
                    member.entityId
                  );
                  currentExpandedGroups = [...currentExpandedGroups, ...expandedGroups];
                  return [...(await acc), ...userIds];
                }, Promise.resolve([]));

                const merged = [...new Set(expanded)].map(id => ({
                  entityId: id,
                  type: PermissionType.User
                })) as PersonaEntity[];
                setNewUsers(merged);
              }}
              label={t("users")}
            />
            <EdisonTypography
              title={t("selectUsersOrGroups")}
              variant="helpertext"
              sx={{ textWrap: "wrap", paddingLeft: "5px" }}
            />
          </Stack>
          <Stack>
            <EdisonEnumSelect
              label={t("license")}
              t={t}
              value={currentLicenseType as unknown as LicenceSelectEnum}
              enumDef={LicenceSelectEnum}
              onChange={changeValue => setCurrentLicenseType(changeValue as FrontendLicenseType)}
              menuItemRenderer={(value: any) => {
                switch (value) {
                  case FrontendLicenseType.Lite:
                    return (
                      <MenuItem value={value}>
                        <Stack spacing={1} direction={"row"} className="license-menu-item">
                          <div>{value}</div>
                          <div>
                            {remainingLiteLicenseCount} &nbsp; {t("licensesLeft")}
                          </div>
                        </Stack>
                      </MenuItem>
                    );

                  case FrontendLicenseType.Full:
                    return (
                      <MenuItem value={value}>
                        <Stack spacing={1} direction={"row"} className="license-menu-item">
                          <div>{value}</div>{" "}
                          <div>
                            {remainingFullLicenseCount} &nbsp; {t("licensesLeft")}
                          </div>
                        </Stack>
                      </MenuItem>
                    );
                  default:
                    return <MenuItem value={value}>{value}</MenuItem>;
                }
              }}
            />
            <EdisonTypography
              title={t("licenseAssigned")}
              variant="helpertext"
              sx={{ textWrap: "wrap" }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            disabled={newUsers.length === 0}
            variant="contained"
            onClick={() => {
              const updatedLicenses: License[] = newUsers.map(user => ({
                licenseType: getBackendLicense(currentLicenseType, licenseSettings),
                id: user.entityId,
                tenantId: tenant
              }));
              const maxLicenseCount =
                currentLicenseType === FrontendLicenseType.Full
                  ? remainingFullLicenseCount
                  : currentLicenseType === FrontendLicenseType.Lite
                  ? remainingLiteLicenseCount
                  : "infinite";
              if (
                maxLicenseCount === "infinite" ||
                (maxLicenseCount as number) >= updatedLicenses.length
              ) {
                onUpdateLicense(updatedLicenses);
              } else {
                dispatch(
                  setCurrentNotification({
                    title: t("maxLicensesHit"),
                    message: t("maxLicensesHitMessage"),
                    level: NotificationLevel.Error
                  })
                );
              }
              onClose();
            }}
          >
            <EdisonTypography variant="h5" title={t("confirm")} />
          </Button>
          <Button variant="outlined" onClick={onClose}>
            <EdisonTypography variant="h5" title={t("cancel")} />
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export const getBackendLicense = (
  frontendLicense: FrontendLicenseType,
  licenseSettings?: LicenseSettings
) => {
  switch (frontendLicense) {
    case FrontendLicenseType.Lite:
      return LicenseType.Lite;
    case FrontendLicenseType.Full:
      return licenseSettings?.licenseType as LicenseType;

    case FrontendLicenseType.ReadOnly:
      return LicenseType.ReadOnly;
    default:
      return LicenseType.None;
  }
};

const recurseExpandGroups = async (expandedGroups: string[], groupId?: string) => {
  const runningCount: { userIds: string[]; expandedGroups: string[] } = {
    userIds: [],
    expandedGroups
  };

  if (!groupId) return runningCount;
  if (expandedGroups.some(expandedGroupId => expandedGroupId === groupId)) return runningCount;

  const result = await getRequestMsGraph(`groups/${groupId}/members`);
  if (result.status !== 200) return runningCount;
  const members = (await result.json()).value;

  const groups = members.filter(
    (member: any) => member["@odata.type"] === "#microsoft.graph.group"
  );
  const users = members.filter((member: any) => member["@odata.type"] === "#microsoft.graph.user");

  runningCount.userIds = [...runningCount.userIds, ...users.map((user: any) => user.id)];
  runningCount.expandedGroups = [...runningCount.expandedGroups, groupId];

  const nonExpandedGroups = groups.filter((group: any) => !expandedGroups.includes(group.id));

  // Expand groups within current group that have not been expanded
  for (const nonExpanded of nonExpandedGroups) {
    const result = await recurseExpandGroups(runningCount.expandedGroups, nonExpanded.id);
    runningCount.userIds = [...runningCount.userIds, ...result.userIds];
    runningCount.expandedGroups = result.expandedGroups;
  }

  return runningCount;
};
export default ManageLicenses;
