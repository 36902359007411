/* eslint-disable react/display-name */
import { Person } from "@microsoft/mgt-react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { FilterOperation, OdataOperations, RecordType, Workflow } from "enada-common";
import { EdisonFilterSelector, EdisonTypography } from "enada-components";
import buildQuery, { QueryOptions } from "odata-query";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TableVirtuoso } from "react-virtuoso";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import CreateRecordModal from "../../createrecordmodal/CreateRecordModal";
import ModuleLabel from "../../moduleLabel/ModuleLabel";
import { BorderedTableCell } from "../../personal/approvalstable/ApprovalsTable";
import "../../projects/associatedrecords/associatedrecords.scss";
import ProjectRecordFlyout from "../../projects/projectrecordflyout/ProjectRecordFlyout";
import WorkflowStageChip from "../../workflowStageChip/WorkflowStageChip";
import UnsavedChangesModal from "../unsavedChangesModal/UnsavedChangesModal";
import { getQuickFilters } from "./quickFilters";
import {
  useGetTemplatesQuery,
  useGetUserQuery,
  useGetWorkflowsQuery,
  useGetWorkflowStagesQuery,
  useMergeProgramAssociationsMutation
} from "../../../services/api";
import { getProgramsConfiguration } from "../../../services/APIService";
import {
  selectRecord,
  selectRecordAssociations,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import { selectDistinctProperties } from "../../../store/slices/recordsSlice";
import { RecordAssociation, RecordAssociationMerge } from "../../../types/createRecordModal";
import { RecordProgramConfiguration } from "../../../types/programs";
import {
  getAdvancedFilters,
  extractOdata,
  toggleOdataOperations,
  compareFilters
} from "../../../utils/filterUtils";

interface SimpleRecord {
  recordId: number;
  recordType?: RecordType;
  associatedWorkflowStageId?: number;
}

const TableComponents = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Stack} {...props} ref={ref} />
  )),
  Table: (props: any) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow: TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />)
};

const COLUMN_WIDTH = {
  name: "35%",
  type: "20%",
  createdBy: "15%",
  status: "15%",
  addToProgram: "15%"
};

const defaultOdataQuery: Partial<QueryOptions<any>> = {
  orderBy: "modified desc"
};

const AssociatedRecords = () => {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { data: templates = [] } = useGetTemplatesQuery();
  const { data: workflowsList = [] } = useGetWorkflowsQuery();
  const { data: stages = [] } = useGetWorkflowStagesQuery();
  const { data: user } = useGetUserQuery();

  const [initialLoad, setInitialLoad] = useState(true);
  const currentRecord = useAppSelector(selectRecord);
  const [odataQuery, setOdataQuery] = useState(buildQuery(defaultOdataQuery));
  const [odataOperation, setOdataOperation] = useState<OdataOperations>(
    getQuickFilters(user?.id ?? "", t)
  );
  const associations = useAppSelector(selectRecordAssociations);
  const [mergeAssociations] = useMergeProgramAssociationsMutation();

  const [createProjectModalOpen, setCreateRecordModalOpen] = useState(false);
  const [items, setItems] = useState<RecordProgramConfiguration[]>([]);
  const [resetItems, setResetItems] = useState<boolean>(false);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [recordsToAssociate, setRecordsToAssociate] = useState<SimpleRecord[]>([]);
  const [recordsAssociated, setRecordsAssociated] = useState<SimpleRecord[]>([]);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);

  const distinctCreatedBy = useAppSelector(selectDistinctProperties);
  const [activeFilters, setActiveFilters] = useState<FilterOperation[]>([]);

  const filters = useMemo(() => {
    const filteredWFs = workflowsList.filter((w: Workflow) => w.type !== RecordType.Ideas);
    return getAdvancedFilters(t, filteredWFs, distinctCreatedBy, templates);
  }, [workflowsList, distinctCreatedBy, templates, t]);

  const populateRecordsStates = useCallback(() => {
    if (associations && associations.length > 0) {
      const myArr = associations.map((association: RecordAssociation) => {
        return {
          recordId: association.recordId,
          recordType: association.recordType,
          associatedWorkflowStageId: association.associatedWorkflowStageId
        };
      });

      setRecordsToAssociate(myArr);
      setRecordsAssociated(myArr);
    } else {
      setRecordsToAssociate([]);
      setRecordsAssociated([]);
    }
  }, [associations]);

  useEffect(() => {
    if (associations && associations.length > 0) {
      populateRecordsStates();
    }
  }, [associations, populateRecordsStates]);

  const fetchData = useCallback(
    async (query: string) => {
      if (!currentRecord || !currentRecord.id) return;
      setLoading(true);

      const response = await getProgramsConfiguration(query);
      if (resetItems) {
        setItems(response.value ?? []);
      } else {
        setItems(items => [...items, ...(response.value ?? [])]);
      }

      setResetItems(false);
      setLoading(false);

      if (response?.value && response?.value?.length % 20 !== 0) {
        setHasMoreProjects(false);
      }
    },
    [currentRecord, resetItems]
  );

  const loadMore = () => {
    if (!hasMoreProjects) {
      return;
    }

    setOdataOperation((prevState: any) => {
      return {
        ...prevState,
        skip: prevState.skip + 20
      };
    });
  };

  useEffect(() => {
    if (!currentRecord) return;
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    let query = odataQuery;
    query += `&programId=${currentRecord.id}`;
    fetchData(query);
  }, [currentRecord, fetchData, initialLoad, odataQuery]);

  useEffect(() => {
    const extractedOdata = extractOdata(odataOperation);
    const odata = { ...extractedOdata, ...defaultOdataQuery };

    if (defaultOdataQuery.filter) {
      odata.filter = {
        ...(extractedOdata.filter as any),
        ...(defaultOdataQuery.filter as any)
      };
    }

    setOdataQuery(buildQuery(odata));
  }, [odataOperation]);

  const filterClicked = (filterId: number) => {
    setResetItems(true);
    setOdataOperation((prevState: any) => {
      const newOp = {
        ...prevState,
        skip: 0,
        filters: [...(toggleOdataOperations(filterId, prevState.filters) ?? [])]
      };

      return newOp;
    });
  };

  const advancedFilterSelectorChanged = (advancedFilters: FilterOperation[]) => {
    if (compareFilters(advancedFilters, activeFilters)) {
      return;
    }

    setResetItems(true);
    setActiveFilters(advancedFilters);

    // Clear unselected filters
    let quickFilters: any[] = odataOperation.filters?.filter(f => f.isQuickFilter) ?? [];

    // Add new filters
    quickFilters = [...quickFilters, ...advancedFilters];

    setOdataOperation((prevState: any) => {
      return {
        ...prevState,
        skip: 0,
        filters: [...quickFilters]
      };
    });
  };

  const handleToggle = (
    recordId: number,
    recordType: string,
    associatedWorkflowStageId: number,
    checked: boolean
  ) => {
    if (checked) {
      setRecordsToAssociate(recordsToAssociate => [
        ...recordsToAssociate,
        { recordId, recordType: recordType as RecordType, associatedWorkflowStageId }
      ]);
    } else {
      setRecordsToAssociate(recordsToAssociate.filter(a => a.recordId !== recordId));
    }
  };

  const saveNewAssociations = async () => {
    if (!currentRecord || !currentRecord.id) return;
    const recordsToSave = [...recordsToAssociate];
    setRecordsAssociated(recordsToSave);
    const newAssociations = recordsToAssociate.map(record => ({
      recordId: record.recordId,
      recordType: record.recordType,
      associatedWorkflowStageId: record.associatedWorkflowStageId
    })) as RecordAssociationMerge[];

    await mergeAssociations({
      recordId: currentRecord.id,
      body: newAssociations
    });

    dispatch(setIsFlyoutOpen(false));
  };

  const getWorkflowStage = useCallback(
    (workflowStageId?: number) => {
      return stages.find(stage => stage.id === workflowStageId);
    },
    [stages]
  );

  return (
    <>
      <ProjectRecordFlyout
        sx={{ width: "70em", maxWidth: "none" }}
        title={
          <Stack direction="column">
            <EdisonTypography title={t("programsSection.flyout.title")} variant="h2" />
            <EdisonTypography
              title={t("programsSection.flyout.recordCount", { records: associations.length })}
              variant="data"
            />
          </Stack>
        }
        topBarModule={
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              dispatch(setIsFlyoutOpen(false));
              setCreateRecordModalOpen(true);
            }}
          >
            {t("create")}
          </Button>
        }
      >
        <Grid container paddingBottom={2} direction="row" justifyContent="space-between">
          <Grid container width="auto" direction="row" spacing={1}>
            {odataOperation.filters
              ?.filter(f => f.isQuickFilter)
              ?.map((f: any) => {
                return (
                  <Grid item key={f.id}>
                    <Chip
                      className="filter-chip"
                      size="medium"
                      color={f.isActive ? "primary" : undefined}
                      id={f.id}
                      label={f.displayName}
                      onClick={() => filterClicked(f.id)}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Grid item>
            <div className="filterSelector">
              <EdisonFilterSelector
                filterOperations={filters}
                onChangeFilters={advancedFilterSelectorChanged}
                activeFilterOperations={activeFilters}
                addFilterDisplayName={t("addFilter")}
                applyDisplayName={t("apply")}
                clearDisplayName={t("clearAll").toLocaleUpperCase()}
              />
            </div>
          </Grid>
        </Grid>
        <Stack paddingBottom={4} height={"100%"}>
          <TableVirtuoso
            data={items}
            endReached={loadMore}
            className="associated-records-table-root"
            components={{
              ...TableComponents,
              EmptyPlaceholder: () => {
                return (
                  <tbody>
                    <tr>
                      <td colSpan={5}>{loading ? t("loadingMaskLabel") : t("noResultsMessage")}</td>
                    </tr>
                  </tbody>
                );
              }
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={theme => ({
                  backgroundColor: theme.palette.background.default
                })}
              >
                <BorderedTableCell width={COLUMN_WIDTH.name} sx={{ position: "sticky" }}>
                  {t("name")}
                </BorderedTableCell>
                <BorderedTableCell width={COLUMN_WIDTH.type}>{t("type")}</BorderedTableCell>
                <BorderedTableCell width={COLUMN_WIDTH.createdBy}>
                  {t("createdBy")}
                </BorderedTableCell>
                <BorderedTableCell width={COLUMN_WIDTH.status}>{t("status")}</BorderedTableCell>
                <BorderedTableCell width={COLUMN_WIDTH.addToProgram}>
                  {t("addToProgram")}
                </BorderedTableCell>
              </TableRow>
            )}
            itemContent={(_index: number, record: RecordProgramConfiguration) => {
              const workflowStage = getWorkflowStage(record.workflowStage);
              return (
                <>
                  <BorderedTableCell
                    width={COLUMN_WIDTH.name}
                    sx={{ wordWrap: "break-word", position: "sticky" }}
                  >
                    <Link
                      className="tasks-link"
                      to={`../../${record.recordType?.toLowerCase()}/${record.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {record.displayName ?? record.id}
                    </Link>
                  </BorderedTableCell>
                  <BorderedTableCell width={COLUMN_WIDTH.type}>
                    <ModuleLabel recordType={record.recordType as RecordType} />
                  </BorderedTableCell>
                  <BorderedTableCell width={COLUMN_WIDTH.createdBy}>
                    <Box width="24px">
                      <Person showPresence={true} userId={record.createdBy ?? ""} />
                    </Box>
                  </BorderedTableCell>
                  <BorderedTableCell width={COLUMN_WIDTH.status}>
                    <WorkflowStageChip
                      displayName={workflowStage?.displayName}
                      color={workflowStage?.configuration.color}
                      width="100px"
                    />
                  </BorderedTableCell>
                  <BorderedTableCell align="center" width={COLUMN_WIDTH.addToProgram}>
                    <Switch
                      onChange={event => {
                        handleToggle(
                          record.id,
                          record.recordType,
                          record.associatedWorkflowStageId,
                          event.currentTarget.checked
                        );
                      }}
                      checked={recordsToAssociate.some(a => a.recordId === record.id)}
                      disabled={record.canAddToProgram === "AddedToAnotherProgram"}
                    />
                  </BorderedTableCell>
                </>
              );
            }}
          />
        </Stack>
        <Stack direction="row" marginTop={2} spacing={1} justifyContent={"flex-end"}>
          <Button variant="contained" onClick={saveNewAssociations}>
            {t("save")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              if (JSON.stringify(recordsAssociated) !== JSON.stringify(recordsToAssociate))
                setWarningOpen(true);
              else dispatch(setIsFlyoutOpen(false));
            }}
          >
            {t("cancel")}
          </Button>
        </Stack>
      </ProjectRecordFlyout>
      <UnsavedChangesModal
        onCancel={() => {
          setWarningOpen(false);
        }}
        onConfirm={() => {
          populateRecordsStates();
          setWarningOpen(false);
          dispatch(setIsFlyoutOpen(false));
        }}
        open={warningOpen}
      />
      {createProjectModalOpen && (
        <CreateRecordModal
          open={createProjectModalOpen}
          onClose={() => setCreateRecordModalOpen(false)}
          sourceRecord={currentRecord}
        />
      )}
    </>
  );
};

// const BorderedTableCell = styled(TableCell)(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   textOverflow: "ellipsis"
// }));

export default AssociatedRecords;
