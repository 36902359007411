import { BryntumGantt } from "@bryntum/gantt-react-thin";
import {
  BaseRecord,
  FilterOperation,
  RecordType,
  OdataOperations,
  OrderOperation,
  toCamelCase
} from "enada-common";
import { Chip, ListItemIcon, MenuItem, Paper, Select, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import buildQuery, { QueryOptions } from "odata-query";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import RecordContentCard from "../recordcontentcard/RecordContentCard";

import GridOnIcon from "@mui/icons-material/GridOn";
import TimelineIcon from "@mui/icons-material/Timeline";
import DiscoverTable from "./DiscoverTable";
import DiscoverTableToolbar from "./DiscoverTableToolbar";
import "./discover.scss";
import { getQuickFilters } from "./quickFilters";

import { EdisonFilterSelector, Loading } from "enada-components";
import { TFunction } from "i18next";
import {
  resetODataSkip,
  selectDiscoverFilters,
  setDiscoverFilters,
  setODataOperations
} from "../../store/slices/discoverFiltersSlice";
import { useSelectTemplatesByRecordType } from "../../utils/hooks/useSelectTemplatesByRecordType";
import { useInfiniteScrollIntersection } from "../../utils/hooks/useInfiniteScrollIntersection";
import {
  compareFilters,
  extractOdata,
  getAdvancedFilters,
  toggleOdataOperations
} from "../../utils/filterUtils";
import { getRecords } from "../../services/APIService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectDistinctProperties,
  selectRefreshRecords,
  getDistinctValuesAsync,
  setRefreshRecords
} from "../../store/slices/recordsSlice";
import { useGetUserQuery, useGetWorkflowsQuery, useGetWorkflowStagesQuery } from "services/api";

export interface DiscoverProps {
  hideFilters?: boolean;
  // PropQuery  should allways be  applied to the root entity
  propQuery?: Partial<QueryOptions<any>>;
  recordType?: RecordType;
  recordId?: string;
  messageComponent?: React.ReactNode;
  getQuickFilterOverrides?: (currentUserId: string, t: TFunction) => OdataOperations;
  getQuickOrderOverrides?: (t: TFunction) => OrderOperation[];
  expandedEntityForFilters?: ExpandedEntityForFilters;
  projectClickOverride?: (project: BaseRecord) => void;
  hideViewSelector?: boolean;
  openRecordInNewTab?: boolean;
}

export interface ExpandedEntityForFilters {
  key: string;
  query?: Partial<QueryOptions<any>>;
}
const Discover: FC<DiscoverProps> = ({
  propQuery,
  hideFilters,
  recordType,
  recordId,
  messageComponent,
  getQuickFilterOverrides,
  getQuickOrderOverrides,
  projectClickOverride,
  hideViewSelector,
  expandedEntityForFilters,
  openRecordInNewTab = false
}) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const defaultOdataQuery: Partial<QueryOptions<any>> = useMemo(
    () => ({
      orderBy: "modified desc",
      expand: "owners",
      ...propQuery
    }),
    [propQuery]
  );
  const { data: workflowsList = [] } = useGetWorkflowsQuery();
  const { data: stages = [] } = useGetWorkflowStagesQuery();

  const [odataQuery, setOdataQuery] = useState(buildQuery(defaultOdataQuery));
  const [initialLoad, setInitialLoad] = useState(true);

  const [view, setView] = useState<string>("grid");

  const discoverFilters = useAppSelector(selectDiscoverFilters);

  const { data: user } = useGetUserQuery();

  const templates = useSelectTemplatesByRecordType(recordType);
  const distinctCreatedBy = useAppSelector(selectDistinctProperties);

  const [loading, setLoading] = useState(false);
  const [appendResults, setAppendResults] = useState(true);
  const [items, setItems] = useState<BaseRecord[]>([]);
  const refresh = useAppSelector(selectRefreshRecords);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [expandedProject, setExpandedProject] = useState<number | undefined>(undefined);

  const ganttRef = useRef<BryntumGantt>();

  const filters = useMemo(
    () =>
      getAdvancedFilters(
        t,
        workflowsList.filter(workflow => recordType === undefined || workflow.type === recordType),
        distinctCreatedBy,
        templates
      ),
    [workflowsList, distinctCreatedBy, templates, t, recordType]
  );

  const activeFilters = useMemo(() => {
    if (!recordType) return [];

    return discoverFilters.find(f => f.recordType === recordType)?.filters ?? [];
  }, [discoverFilters, recordType]);

  const odataOperation = useMemo(() => {
    const foundOperation = discoverFilters.find(f => f.recordType === recordType)?.odataOperation;

    return foundOperation?.filters && foundOperation.filters.length > 0
      ? foundOperation
      : {
        filters: (getQuickFilterOverrides
          ? getQuickFilterOverrides(user?.id ?? "", t)
          : getQuickFilters(user?.id ?? "", t)
        ).filters,
        orderOperations: getQuickOrderOverrides ? getQuickOrderOverrides(t) : [],
        skip: 0
      };
  }, [discoverFilters, getQuickFilterOverrides, getQuickOrderOverrides, recordType, t, user?.id]);

  useEffect(() => {
    // This is just incase the Owned by me value is set to "", which would then cause future issues as the filters are persisted
    const ownedByMeFilter = odataOperation?.filters?.find(f => f.property === "owners");

    if (!ownedByMeFilter) return;

    if (!ownedByMeFilter?.value && user?.id) {
      dispatch(
        setODataOperations({
          recordType,
          odataOperation: {
            ...odataOperation,
            skip: 0,
            filters: [...(odataOperation?.filters ?? []), { ...ownedByMeFilter, value: user.id }]
          }
        })
      );
    }
  }, [dispatch, odataOperation, recordType, user?.id]);

  const infiniteScrollNext = useCallback(() => {
    if (!hasMoreProjects) {
      return;
    }
    setAppendResults(true);
    dispatch(
      setODataOperations({
        recordType,
        odataOperation: { ...odataOperation, skip: odataOperation.skip + 20 }
      })
    );
  }, [dispatch, hasMoreProjects, odataOperation, recordType]);

  const sentinalFetchMore = useCallback(() => {
    // this is for when fetching data on larger screens or heavily zoomed out
    // before, InfiniteScroll would just hang and not fetch more data
    if (odataOperation.skip >= 60 && !hasMoreProjects && window.devicePixelRatio > 0.7) return;

    dispatch(
      setODataOperations({
        recordType,
        odataOperation: { ...odataOperation, skip: odataOperation.skip + 20 }
      })
    );
  }, [dispatch, hasMoreProjects, odataOperation, recordType]);

  const { sentinelRef } = useInfiniteScrollIntersection({
    fetchMore: sentinalFetchMore,
    hasMore: items.length > 0 && hasMoreProjects && odataOperation.skip <= 60
  });

  const fetchData = useCallback(
    async (query: string, appendResults: boolean) => {
      setLoading(true);
      const response = await getRecords(query);
      let records: BaseRecord[] = [];
      if (recordId) {
        // Retrieve associated records of the program that returns data in a different shape.
        if (response?.value?.length) records = response?.value?.[0].recordAssociations ?? [];
      } else {
        records = response?.value ?? [];
      }
      if (appendResults) {
        setItems(prev => [...prev, ...records]);
      } else {
        setItems(records);
      }

      setLoading(false);
      if (records.length < 20) {
        setHasMoreProjects(false);
      }
    },
    [recordId]
  );

  useEffect(() => {
    dispatch(getDistinctValuesAsync("createdBy"));
    dispatch(resetODataSkip());
    return () => {
      resetItems();
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    fetchData(odataQuery, appendResults);
  }, [appendResults, fetchData, initialLoad, odataQuery]);

  useEffect(() => {
    if (refresh) {
      setItems([]);
      dispatch(setDiscoverFilters({ recordType, filters: [] }));
      dispatch(
        setODataOperations({
          recordType,
          odataOperation: getQuickFilters(user?.id ?? "", t)
        })
      );
      dispatch(setRefreshRecords(false));
    }
  }, [dispatch, recordType, refresh, t, user?.id]);

  useEffect(() => {
    const extractedOdata = extractOdata(odataOperation);
    const activeOrder = odataOperation.orderOperations?.find(f => f.isActive);

    const orderBy = activeOrder
      ? `${activeOrder?.property} ${activeOrder?.orderType}`
      : "modified desc";

    // If we have an expanded entity for filters, we apply the filters and ordering (from chips and advanced filters),
    //  to the expanded entity rather than the root entity.
    // The propQuery parameter is allways applied to the root entity.
    if (expandedEntityForFilters !== undefined) {
      const result = {
        filter: { ...(defaultOdataQuery.filter as any) },
        ...(propQuery?.select !== undefined && { select: propQuery.select as string }),
        expand: {
          [expandedEntityForFilters.key as any]: {
            ...expandedEntityForFilters.query,
            ...extractedOdata,
            orderBy
          }
        }
      };
      setOdataQuery(buildQuery(result));
      return;
    }

    const odata = { ...extractedOdata, ...defaultOdataQuery, orderBy };
    if (defaultOdataQuery.filter) {
      odata.filter = {
        ...(extractedOdata.filter as any),
        ...(defaultOdataQuery.filter as any)
      };
    }
    setOdataQuery(buildQuery(odata));
  }, [defaultOdataQuery, expandedEntityForFilters, odataOperation, propQuery?.select]);

  const isAnyFilterSelected = useMemo(
    () =>
      (odataOperation.filters?.filter((f: FilterOperation) => f.isActive || !f.isQuickFilter)
        ?.length ?? 0) > 0,
    [odataOperation]
  );

  const advancedFilterSelectorChanged = (advancedFilters: FilterOperation[]) => {
    if (compareFilters(advancedFilters, activeFilters)) {
      return;
    }

    resetItems();

    dispatch(setDiscoverFilters({ recordType, filters: advancedFilters }));

    let quickFilters: any[] = odataOperation.filters?.filter(f => f.isQuickFilter) ?? [];
    quickFilters = [...quickFilters, ...advancedFilters];

    dispatch(
      setODataOperations({
        recordType,
        odataOperation: { ...odataOperation, filters: quickFilters, skip: 0 }
      })
    );
  };

  useEffect(() => {
    if (items.length % 20 !== 0) {
      setHasMoreProjects(false);
    }
  }, [items]);

  const resetItems = () => {
    setItems([]);
    setHasMoreProjects(true);
  };

  const filterClicked = (filterId: number) => {
    resetItems();
    setAppendResults(false);
    dispatch(
      setODataOperations({
        recordType,
        odataOperation: {
          ...odataOperation,
          filters: [...(toggleOdataOperations(filterId, odataOperation.filters) ?? [])],
          skip: 0
        }
      })
    );
  };
  const orderClicked = (orderId: number) => {
    resetItems();
    setAppendResults(false);
    dispatch(
      setODataOperations({
        recordType,
        odataOperation: {
          ...odataOperation,
          orderOperations: [
            ...(toggleOdataOperations(orderId, odataOperation.orderOperations) ?? [])
          ],
          skip: 0
        }
      })
    );
  };

  const resetOdataClicked = () => {
    resetItems();
    dispatch(setDiscoverFilters({ recordType, filters: [] }));

    dispatch(
      setODataOperations({
        recordType,
        odataOperation: {
          ...odataOperation,
          filters: [
            ...(odataOperation.filters
              ?.filter((f: FilterOperation) => f.isQuickFilter)
              .map((op: FilterOperation) => {
                return {
                  ...op,
                  isActive: false
                };
              }) ?? [])
          ],
          skip: 0
        }
      })
    );
  };

  const projectClick = useCallback(
    (project: BaseRecord) => {
      let initialPath = "../";
      if (recordId) initialPath += "../";
      if (openRecordInNewTab) {
        const tenant = localStorage.getItem("tenant");
        const instance = localStorage.getItem("instance");
        window.open(`/${tenant}/${instance}/${project.recordType.toLocaleLowerCase()}/${project.id}`, '_blank');
      } else {
        navigate(`${initialPath}${project.recordType.toLocaleLowerCase()}/${project.id}`, {
          state: { record: project, title: toCamelCase(project.recordType) }
        });
      }
    },
    [navigate, recordId]
  );

  return (
    <Stack spacing={1} className="projects-discover-root">
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Grid container direction={"row"} spacing={2} className="toolbar-container">
              <Grid item>
                <Chip
                  className="filter-chip"
                  label="All"
                  size="medium"
                  color={!isAnyFilterSelected ? "primary" : undefined}
                  variant={isAnyFilterSelected ? "outlined" : "filled"}
                  onClick={() => {
                    const isAllActive = !odataOperation.filters?.some(filter => filter.isActive);
                    if (isAllActive) return;
                    resetOdataClicked();
                  }}
                />
              </Grid>
              {odataOperation.filters?.map((f: any) => {
                return (
                  <Grid item key={f.id}>
                    <Chip
                      className="filter-chip"
                      size="medium"
                      color={f.isActive ? "primary" : undefined}
                      id={f.id}
                      label={f.displayName}
                      onClick={() => filterClicked(f.id)}
                    />
                  </Grid>
                );
              })}
              {odataOperation.orderOperations?.map((f: any) => {
                return (
                  <Grid item key={f.id}>
                    <Chip
                      className="filter-chip"
                      size="medium"
                      color={f.isActive ? "primary" : undefined}
                      id={f.id}
                      label={f.displayName}
                      onClick={() => orderClicked(f.id)}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Stack direction="row" spacing={2}>
              {!hideViewSelector && (
                <Select
                  size="small"
                  className="view-selector"
                  sx={theme => ({
                    ".MuiSvgIcon-root ": {
                      color: theme.palette.primary.main
                    },
                    ".MuiOutlinedInput-notchedOutline ": {
                      border: "0px"
                    },
                    border: "1px solid",
                    borderRadius: "10px",
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    height: "36px"
                  })}
                  value={view}
                  onChange={e => {
                    setView(e.target.value);
                  }}
                >
                  {["grid", "timeline"].map(view => (
                    <MenuItem key={view} value={view}>
                      <ListItemIcon>
                        {view === "grid" ? <GridOnIcon /> : <TimelineIcon />}
                      </ListItemIcon>
                      {t(view)}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {view === "timeline" && <DiscoverTableToolbar ganttRef={ganttRef} />}
              {!hideFilters && (
                <div className="filterSelector">
                  <EdisonFilterSelector
                    filterOperations={filters}
                    onChangeFilters={advancedFilterSelectorChanged}
                    activeFilterOperations={activeFilters}
                    addFilterDisplayName={t("addFilter")}
                    applyDisplayName={t("apply")}
                    clearDisplayName={t("clearAll").toLocaleUpperCase()}
                  />
                </div>
              )}
            </Stack>
          </Stack>
          {messageComponent}
          {items && items.length > 0 && (
            <div>
              {view === "grid" ? (
                <InfiniteScroll
                  className="infinite-scroll"
                  dataLength={items.length}
                  next={infiniteScrollNext}
                  hasMore={hasMoreProjects}
                  loader={
                    <Loading
                      size={70}
                      sx={{ margin: "auto", marginTop: "10px" }}
                      ref={sentinelRef}
                    />
                  }
                >
                  <Grid container spacing={1} className="card-container">
                    {items.map(project => {
                      return (
                        <RecordContentCard
                          key={project.id}
                          stages={stages}
                          record={project}
                          setExpandedRecord={setExpandedProject}
                          isExpanded={expandedProject ? expandedProject === project.id : false}
                          onRecordClick={projectClickOverride || projectClick}
                        />
                      );
                    })}
                  </Grid>
                </InfiniteScroll>
              ) : (
                <DiscoverTable
                  recordId={recordId}
                  projects={items}
                  getMore={infiniteScrollNext}
                  hasMore={hasMoreProjects}
                  ganttRef={ganttRef}
                  loading={loading}
                  expandable={recordType === RecordType.Programs}
                />
              )}
            </div>
          )}
          {(!items || items.length === 0) && !hasMoreProjects && (
            <div className="noResultMessage">{t("noResultsMessage")}</div>
          )}

          <Stack className="loading-container" ref={sentinelRef}>
            {hasMoreProjects && items.length === 0 && <Loading size={70} />}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Discover;
