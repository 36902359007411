import {
  ApiError,
  Category,
  Field,
  fieldConfiguration,
  NotificationLevel,
  RollupTableRowModel
} from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { useAppDispatch } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { selectRollupRowsFromData } from "utils/rtkQuery";
import {
  useDeleteFieldsMutation,
  useGetFieldsQuery,
  useRecycleFieldsMutation,
  useRestoreFieldsMutation
} from "services/api";

const selectFieldsRollupRows = selectRollupRowsFromData<Field[]>(data => {
  return data?.map(field => ({
    id: field.id ?? 0,
    displayName: field.displayName,
    modified: field.modified,
    modifiedBy: field.modifiedBy,
    type: field.dataType,
    isDeleted: field.isDeleted,
    category: field.category,
    no_delete_e365: field.category === Category.System || field.category === Category.Default,
    disabled_e365: field.category === Category.System
  }));
});

const Fields: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const { rollupRows = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectFieldsRollupRows(result)
    })
  });
  const [deleteFields] = useDeleteFieldsMutation();
  const [recycleFields] = useRecycleFieldsMutation();
  const [restoreFields] = useRestoreFieldsMutation();

  const navigate = useNavigate();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteFields(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "fieldDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e: any) {
      const error = e?.data as ApiError;
      if (error?.detail) {
        const errorArr = JSON.parse(error.detail);
        const errorMessage = errorArr.reduce((acc: string, curr: any) => {
          let formatted = "";
          if (error.title === "FieldUsedInWorkflowCondition") {
            const workflowNames = curr.Workflows.map((workflow: any) => workflow.WorkflowName).join(
              ", "
            );
            formatted = `${t("fieldInUseWorkflowError", {
              fields: curr.FieldName,
              workflows: workflowNames
            })}\n`;
          } else if (error.title === "FieldUsedInCalculatedField") {
            const calculateFieldNames = curr.CalculatedFields.map(
              (field: any) => field.CalculatedFieldName
            ).join(", ");
            formatted = `${t("fieldInUseCalculatedFieldError", {
              fields: curr.FieldName,
              calculatedFields: calculateFieldNames
            })}\n`;
          }

          return acc + formatted + "\n";
        }, "");
        dispatch(
          setCurrentNotification({
            title: error.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      }
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleFields(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "fieldRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "fieldRecycleError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreFields(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "fieldRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "fieldRestoreError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`editfield`, {
          state: {
            id,
            title: "fields"
          }
        });
      }}
      addAction={() => {
        navigate("newfield", {
          state: {
            title: "fields"
          }
        });
      }}
      columns={rollupTableColumnsWithType(t, (value: any) => fieldConfiguration(value.type).color)}
      rows={rollupRows.filter(field => !field.isDeleted) as unknown as RollupTableRowModel[]}
      deletedRows={rollupRows.filter(field => field.isDeleted) as unknown as RollupTableRowModel[]}
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Fields;
