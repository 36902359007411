import React, { FC } from "react";
import "./calculateddivider.scss";
export interface CalculatedDividerProps {
  noPadding?: boolean;
}
const CalculatedDivider: FC<CalculatedDividerProps> = ({ noPadding }) => {
  return (
    <div className={`calculated-divider-root ${Boolean(noPadding) && "no-padding"}`}>
      <div className="divider-body" />
    </div>
  );
};

export default CalculatedDivider;
