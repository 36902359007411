import { Stack } from "@mui/material";
import React from "react";
import { selectCurrentElement, selectWorkflowType } from "../../../store/slices/workflowSlice";
import { useAppSelector } from "../../../store/hooks";
import WorkflowInfo from "./workflowinfo/WorkflowInfo";
import NodeOptions from "./nodeoptions/NodeOptions";
import StageReviewers from "./stagereviewers/StageReviewers";
import StageEvents from "./stageevents/StageEvents";
import ConditionOptions from "./conditionoptions/ConditionOptions";
import StageOptions from "./stageoptions/StageOptions";
import StageAdvancedOptions from "./stageadvancedoptions/StageAdvancedOptions";
import StageScopeOptions from "./stageScopeOptions/StageScopeOptions";
import { RecordType } from "enada-common";

const LeftPanel = () => {
  const currentNode = useAppSelector(selectCurrentElement);
  const workflowType = useAppSelector(selectWorkflowType);
  return (
    <Stack spacing={2}>
      {!currentNode && <WorkflowInfo />}
      {["Stage", "StageReview", "Condition"].includes(currentNode?.data.configuration.type) && (
        <NodeOptions />
      )}
      {currentNode?.data.configuration.type === "Condition" && <ConditionOptions />}
      {currentNode?.data.configuration.type.includes("Stage") && <StageOptions />}
      {currentNode?.data.configuration.type === "StageReview" && <StageReviewers />}
      {currentNode &&
        [RecordType.Challenges, RecordType.Ideas, RecordType.BusinessCase].includes(
          workflowType
        ) && <StageScopeOptions />}
      <Stack spacing={1}>
        {currentNode?.data.configuration.type.includes("Stage") && (
          <>
            <StageAdvancedOptions />
            <StageEvents />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default LeftPanel;
