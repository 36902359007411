import {
  WorkflowStageViewField,
  WorkflowStageViewFieldState,
  WorkflowStageViewTable,
  Zone
} from "enada-common";

export const getRowVisibility = (
  row: Zone,
  viewFields?: WorkflowStageViewField[] | null,
  viewTables?: WorkflowStageViewTable[] | null
) => {
  if (!row.columns) return;
  if (!viewFields) return; // should  only occur with live preview for data prepopulation
  if (!viewTables) return; // should  only occur with live preview for data prepopulation

  //Go through each field and table in each column in the row,  and add the visibility of each to the visibilities array.
  const visibilities = row.columns?.reduce<(WorkflowStageViewFieldState | undefined)[]>(
    (prev, column) => {
      const fields = (column.fields ?? [])
        .filter(field => field.fieldId !== undefined)
        .map(field => ({
          isTable: false,
          id: field.fieldId
        }));
      const mappedTables = (column.tables ?? []).map(table => ({
        isTable: true,
        id: table.tableId
      }));
      return [
        ...prev,
        ...fields
          .concat(mappedTables)
          .map(component =>
            getComponentVisibility(component.id, component.isTable, viewFields, viewTables)
          )
      ];
    },
    []
  );
  if (visibilities?.every(visibility => visibilities[0] === visibility)) {
    return visibilities[0];
  }
  // returning undefined will lead the row visibility being set to visible
  return undefined;
};

// Returns a list of viewTables and viewFields representing the row with their  visibilites set to the newVisibility.
export const updateRowVisibility = (
  newVisibility: WorkflowStageViewFieldState | undefined,
  row: Zone,
  viewFields: WorkflowStageViewField[],
  viewTables: WorkflowStageViewTable[]
) => {
  let updatedViewFields: WorkflowStageViewField[] = [];
  let updatedViewTables: WorkflowStageViewTable[] = [];
  row.columns?.forEach(column => {
    updatedViewFields = [
      ...updatedViewFields,
      ...(column?.fields?.map(field => ({
        ...viewFields.find(viewField => viewField.fieldId === field.fieldId),
        state: newVisibility
      })) ?? [])
    ];
    updatedViewTables = [
      ...updatedViewTables,
      ...(column?.tables?.map(table => ({
        ...viewTables.find(viewTable => viewTable.tableId === table.tableId),
        state: newVisibility
      })) ?? [])
    ];
  });
  return {
    updatedViewFields: updatedViewFields.filter(field => field.fieldId !== undefined),
    updatedViewTables
  };
};

export const getComponentVisibility = (
  id: number | undefined,
  isTable: boolean,
  viewFields?: WorkflowStageViewField[],
  viewTables?: WorkflowStageViewTable[]
) =>
  isTable
    ? viewTables?.find(table => table.tableId === id)?.state
    : viewFields?.find(field => field.fieldId === id)?.state;
