import { apiConfig } from "config/authConfig";
import { Permission, RoleType } from "enada-common";
import { baseAPI } from "services/baseAPI";

export const manageUsersAPI = baseAPI.injectEndpoints({
  endpoints: build => ({
    getTenantAdmins: build.query<Permission[], void>({
      query: () => `${apiConfig.managementApi}/tenant-admins`,
      transformResponse: (response: { data: Permission[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(res => ({ type: "ManageUsers" as const, id: res.oid })), "ManageUsers"]
          : ["ManageUsers"]
    }),

    getEdisonAccessToInstance: build.query<boolean, void>({
      query: () => `${apiConfig.managementApi}/tenants/instances/edison365-users-allowed`,
      transformResponse: (response: { data: boolean }) => response.data,
      providesTags: ["ManageUsers"]
    }),

    updateEdisonAllowedOnInstance: build.mutation<void, boolean>({
      query: allowed => ({
        url: `${apiConfig.managementApi}/tenants/instances/toggle-allowed-edison365-users`,
        method: "POST",
        body: allowed
      }),
      invalidatesTags: ["ManageUsers"]
    }),

    getAIEnabled: build.query<boolean, void>({
      query: () => `${apiConfig.managementApi}/tenants/instances/ai-enabled`,
      transformResponse: (response: { data: boolean }) => response.data,
      providesTags: ["ManageUsers"]
    }),

    updateAIEnabledOnInstance: build.mutation<void, boolean>({
      query: enabled => ({
        url: `${apiConfig.managementApi}/tenants/instances/toggle-ai-enabled`,
        method: "POST",
        body: enabled
      }),
      invalidatesTags: ["ManageUsers"]
    }),

    getPermissionsByRole: build.query<Permission[], RoleType[]>({
      query: roles =>
        `${apiConfig.permissionsEndpoint}/by-role${decodeURIComponent(
          `?${roles.map(role => `roles=${encodeURIComponent(role)}`).join("&")}`
        )}`,
      transformResponse: (response: { data: Permission[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(res => ({ type: "ManageUsers" as const, id: res.oid })), "ManageUsers"]
          : ["ManageUsers"]
    }),

    updateTenantAdmins: build.mutation<void, Permission[]>({
      query: permissions => ({
        url: `${apiConfig.managementApi}/tenant-admins`,
        method: "POST",
        body: permissions
      }),
      invalidatesTags: ["ManageUsers"]
    }),

    updatePermissions: build.mutation<Permission[], Permission[]>({
      query: permissions => ({
        url: `${apiConfig.permissionsEndpoint}`,
        method: "POST",
        body: permissions
      }),
      invalidatesTags: ["ManageUsers"]
    })
  })
});
