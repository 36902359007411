import { CellCopyPasteConfig, RowCopyPasteConfig } from "@bryntum/grid-thin";

const CopyPasteSharedConfig = {
  cellCopyPasteFeature: {
    toCopyString({ currentValue, column, record }) {
      // Return currentValue for non edison columns
      if (!column.field.includes("-e365")) {
        return currentValue;
      }

      // For edison columns, construct a JSON object with the field and value to pass to the toPasteValue function
      const copiedString = JSON.stringify({
        field: column.field,
        value: record.getData(column.field)
      });

      return copiedString;
    },
    toPasteValue({ currentValue, column }) {
      // Do not paste into read only columns
      if ((column as any)?.data?.fieldReadOnly) {
        return;
      }

      // Paste calendar object as is
      if (column.type === "calendar") {
        return currentValue;
      }

      // Return currentValue for non edison columns
      if (!column.field.includes("-e365")) {
        return currentValue;
      }

      // Don't paste into an edison switch column as it currently doesn't support pasting
      const fieldDataType = (column as any).data.fieldDataType.toLowerCase();
      if (fieldDataType === "switch") {
        return;
      }

      if (fieldDataType === "richtext") {
        // If the currentValue is not a JSON object then wrap it in a div
        const wrappedCurrentValue = [
          {
            type: "div",
            children: [{ text: currentValue }]
          }
        ];

        try {
          JSON.parse(currentValue);
        } catch (e) {
          return wrappedCurrentValue;
        }

        if (
          !(
            currentValue.includes("{") &&
            currentValue.includes("}") &&
            currentValue.includes("[") &&
            currentValue.includes("]")
          )
        ) {
          return wrappedCurrentValue;
        }
      }

      // If the currentValue is not a JSON object (i.e. an custom edison column), return currentValue
      try {
        JSON.parse(currentValue);
      } catch (e) {
        return currentValue;
      }

      // If the currentValue is not a JSON object (i.e. an custom edison column), return currentValue
      if (!currentValue.includes("{")) {
        return currentValue;
      }

      const fieldAndValue = JSON.parse(currentValue);
      // Only paste the value when the columns match
      if (fieldAndValue.field === column.field) {
        return fieldAndValue.value;
      }

      // Don't paste if the columns don't match
    },
    disabled: false
  } as CellCopyPasteConfig,
  rowCopyPasteFeature: {
    rowOptionsOnCellContextMenu: true // Needs to have cellCopyPasteFeature: true and selectionMode: { cell: true } to work
  } as RowCopyPasteConfig,
  onBeforeCopy({ source, data }) {
    // Prevent copying if any of the selected cells are read only
    if (source.selectedCells.some(cell => cell.column.data.fieldReadOnly)) {
      return false;
    }

    return data;
  },
  onBeforePaste({ source, clipboardData }) {
    // Prevent pasting if no cells are selected (i.e. no target cell)
    if (source.selectedCells.length === 0) {
      return false;
    }
    // Prevent pasting if any of the selected cells are read only
    if (source.selectedCells.some(cell => cell.column.data.fieldReadOnly)) {
      return false;
    }
    return clipboardData;
  }
};

export default CopyPasteSharedConfig;
