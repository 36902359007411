import {
  FilterOperation,
  FilterPropertyType,
  OdataOperations,
  RecordType,
  OrderOperation
} from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { PURGE } from "redux-persist";

interface Filters {
  recordType: RecordType | undefined;
  filters: FilterOperation[];
  odataOperation: OdataOperations | undefined;
  odataOrder: OrderOperation | undefined;
}

export type HomePageFilterKey = "recentlyViewed" | "recentRecords";
// Increment this version number any time we want to purge the discover filters after a release
export const DISCOVER_SLICE_VERSION = 1;
export interface DiscoverFiltersState {
  records: Filters[];
  homePageFilters: Record<HomePageFilterKey, RecordType[]>;
  version: number;
}

export const defaultHomePageFilterOrder = [
  RecordType.Programs,
  RecordType.Projects,
  RecordType.BusinessCase,
  RecordType.Challenges,
  RecordType.Ideas
];
const initialState: DiscoverFiltersState = {
  records: [
    {
      recordType: undefined,
      filters: [],
      odataOperation: undefined,
      odataOrder: undefined
    }
  ],
  homePageFilters: {
    recentlyViewed: defaultHomePageFilterOrder,
    recentRecords: defaultHomePageFilterOrder
  },
  version: 0
};

const recordTypeKeys = Object.keys(RecordType);
recordTypeKeys.forEach((key, index) => {
  initialState.records.push({
    recordType: RecordType[key as RecordType],
    filters: [],
    odataOperation: undefined,
    odataOrder: undefined
  });
});

const serializeFilters = (filters: FilterOperation[]): FilterOperation[] => {
  return filters.map(filter => {
    if (filter.propertyType === FilterPropertyType.Date && filter.value instanceof Date) {
      return {
        ...filter,
        value: filter.value.toISOString()
      };
    }
    return filter;
  });
};

const deserializeFilters = (filters: FilterOperation[]): FilterOperation[] => {
  return filters.map(filter => {
    if (filter.propertyType === FilterPropertyType.Date && typeof filter.value === "string") {
      return {
        ...filter,
        value: new Date(filter.value)
      };
    }
    return filter;
  });
};

const discoverFiltersSlice = createSlice({
  name: "discoverFilters",
  initialState,
  reducers: {
    setDiscoverFilters: (
      state,
      action: PayloadAction<Omit<Filters, "odataOperation" | "odataOrder">>
    ) => {
      const { recordType, filters } = action.payload;
      const record = state.records.find(record => record.recordType === recordType);
      if (record) {
        record.filters = serializeFilters(filters); // Serialize before storing
      } else {
        state.records.push({
          recordType,
          filters: serializeFilters(filters),
          odataOperation: undefined,
          odataOrder: undefined
        });
      }
    },
    setODataOperations: (state, action: PayloadAction<Omit<Filters, "filters" | "odataOrder">>) => {
      const { recordType, odataOperation } = action.payload;
      const record = state.records.find(record => record.recordType === recordType);
      if (record) {
        record.odataOperation = odataOperation;
      } else {
        state.records.push({
          recordType,
          filters: [],
          odataOperation,
          odataOrder: undefined
        });
      }
    },
    setODataOrder: (state, action: PayloadAction<Omit<Filters, "filters" | "odataOperation">>) => {
      const { recordType, odataOrder } = action.payload;
      const record = state.records.find(record => record.recordType === recordType);
      if (record) {
        record.odataOrder = odataOrder;
      } else {
        state.records.push({
          recordType,
          filters: [],
          odataOperation: undefined,
          odataOrder
        });
      }
    },
    resetODataSkip: state => {
      state.records.forEach(record => {
        record.odataOperation = {
          ...record.odataOperation,
          skip: 0
        };
      });
    },
    toggleHomePageFilter: (
      state,
      action: PayloadAction<{ key: HomePageFilterKey; value: RecordType }>
    ) => {
      const currentFilter = state.homePageFilters[action.payload.key];
      state.homePageFilters[action.payload.key] = currentFilter.includes(action.payload.value)
        ? currentFilter.filter(filter => filter !== action.payload.value)
        : [...currentFilter, action.payload.value];
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, state => {
      state.records = initialState.records;
      state.homePageFilters = initialState.homePageFilters;
      state.version = DISCOVER_SLICE_VERSION;
    });
  }
});

export const {
  setDiscoverFilters,
  setODataOperations,
  setODataOrder,
  resetODataSkip,
  toggleHomePageFilter
} = discoverFiltersSlice.actions;

export const selectDiscoverFilters = createSelector(
  (state: RootState) => state.discoverFilters.records,
  records =>
    records.map(record => ({
      ...record,
      filters: deserializeFilters(record.filters) // Deserialize when accessing
    }))
);
export const selectHomePageFiltersbyKey = createSelector(
  [
    (state: RootState) => state.discoverFilters.homePageFilters,
    (_: RootState, key: HomePageFilterKey) => key
  ],
  (filters, key) => filters[key]
);

export const selectDiscoverFilterVersion = createSelector(
  (state: RootState) => state.discoverFilters.version,
  version => version
);

export default discoverFiltersSlice.reducer;
