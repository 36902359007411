import React, { FC, useEffect } from "react";

import { RecordType, recordTypeConfiguration } from "enada-common";
import { Chip, darken, useMediaQuery } from "@mui/material";
import "./recordtypechip.scss";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";

export interface RecordTypeChipProps {
  recordType: RecordType;
  active: boolean;
  onClick: () => void;
}

const RecordTypeChip: FC<RecordTypeChipProps> = ({ recordType, active, onClick }) => {
  const themeName = useAppSelector(selectThemeName);
  const config = recordTypeConfiguration(recordType, {
    color: active ? recordTypeConfiguration(recordType).color : ""
  });


  const disabledColors =
    themeName === "dark"
      ? { color: "#9D9FA3", backgroundColor: "#3A3E46" }
      : { color: "#989898", backgroundColor: "#F5F5F5" };

  const backgroundColor = themeName === "dark" ? config.color : config.secondaryColor;
  const color = themeName === "dark" ? config.secondaryColor : config.color;
  return (
    <Chip
      className="record-type-chip-root"
      icon={config.icon as any}
      onClick={onClick}
      label={recordType}
      sx={{
        color: active ? color : disabledColors.color,
        backgroundColor: active ? backgroundColor :disabledColors.backgroundColor,
        ":hover": {
          backgroundColor: active ? darken(backgroundColor ?? "", 0.2) : ""
        }
      }}
    />
  );
};

export default RecordTypeChip;
