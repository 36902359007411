import { Grid, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  clearConditionBlock,
  ExpressionLocation,
  selectBarLocation,
  setBarLocation,
  selectCalculatedSelectedUnit
} from "../../../../../store/slices/calculatedFieldSlice";
import ExpressionUnit from "../../expressionunit/ExpressionUnit";
import { CalculatedMoveAction } from "../../utils/calculated.model";
import useConditionDrop from "../../utils/hooks/useConditionDrop";
import "./conditionexpression.scss";
import CalculatedDivider from "../../divider/CalculatedDivider";
import { CalculatedExpressionUnit } from "enada-common";
export interface ConditionExpressionProps {
  expressionList: CalculatedExpressionUnit[];
  path: ExpressionLocation;
}
//TODO: review implementation details when starting nested conditions
const ConditionExpression: FC<ConditionExpressionProps> = ({ expressionList, path }) => {
  const { t } = useTranslation(["common"]);
  const barLocation = useAppSelector(selectBarLocation);
  const selectedUnit = useAppSelector(selectCalculatedSelectedUnit);
  const conditionDrop = useConditionDrop({ index: "end", ...path });
  const dispatch = useAppDispatch();
  const onDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const action = event.dataTransfer.getData("application/calculatedField");
    const parsed: CalculatedMoveAction = JSON.parse(action);
    conditionDrop(parsed);
  };

  const isBarInExpression = (index: number | "end") =>
    path.path === barLocation?.path &&
    path.target === barLocation?.target &&
    index === barLocation.index;

  return (
    <Stack direction="row" className="condition-expression-root" spacing={2}>
      <Stack direction="row" spacing={1}>
        <div className="capitalized">{t(path.target)}</div>
      </Stack>
      <Stack direction="row" className={`expression-container`}>
        <Grid container className={`expression `} direction="row">
          {expressionList.map((unit, index) => (
            <Grid key={unit.id} item>
              <Stack direction="row" style={{ height: "100%" }}>
                {isBarInExpression(index) && <CalculatedDivider noPadding />}
                <ExpressionUnit
                  unit={unit}
                  index={index}
                  path={path}
                  selected={
                    index === selectedUnit?.index &&
                    path.path === selectedUnit.path &&
                    path.target === selectedUnit.target
                  }
                />
              </Stack>
            </Grid>
          ))}
          {isBarInExpression("end") && (
            <Grid item>
              <CalculatedDivider noPadding />
            </Grid>
          )}
          <div
            onClick={() => {
              dispatch(
                setBarLocation({
                  index: "end",
                  path: path.path,
                  target: path.target
                })
              );
            }}
            className="end-dropzone"
            onDragOver={onDragOver}
            onDrop={onDrop}
          ></div>
        </Grid>

        <IconButton
          className="delete-button"
          onClick={() => dispatch(clearConditionBlock({ target: path.target, path: path.path }))}
        >
          <CancelIcon className="cancel-icon" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ConditionExpression;
