import { FieldDataType } from "enada-common";

const formatFieldValueForFrontend = (fieldType: FieldDataType, value: any) => {
  let fieldValue = value;
  switch (fieldType) {
    case FieldDataType.TextBox:
    case FieldDataType.Email:
      fieldValue = value.stringValue;
      break;
    case FieldDataType.Number:
    case FieldDataType.Currency:
    case FieldDataType.Percentage:
      fieldValue = value.decimalValue;
      break;
    case FieldDataType.Switch:
      fieldValue = value.boolValue;
      break;
    case FieldDataType.Date:
    case FieldDataType.DateTime:
      fieldValue = value.dateTimeValue;
      break;
    case FieldDataType.Calculated:
      fieldValue = value.decimalValue;
      break;
    case FieldDataType.People:
    case FieldDataType.MultiPeople:
    case FieldDataType.MultiChoice:
    case FieldDataType.Choice:
    case FieldDataType.Url:
    case FieldDataType.RichText:
    case FieldDataType.MultiLevelChoice:
    case FieldDataType.MultiMultiLevelChoice:
    case FieldDataType.Phone:
    case FieldDataType.Location:
      fieldValue = value.extendedValue?.value;
      break;
    default:
      fieldValue = value.extendedValue?.value;
      break;
  }
  return fieldValue;
};

const formatFieldValueForBackend = (fieldType: FieldDataType, value: any) => {
  let fieldValue = value;
  switch (fieldType) {
    case FieldDataType.TextBox:
    case FieldDataType.Email:
      fieldValue = { stringValue: value };
      break;
    case FieldDataType.Number:
    case FieldDataType.Currency:
    case FieldDataType.Percentage:
      fieldValue = { decimalValue: value ? parseFloat(value) : value };
      break;
    case FieldDataType.Switch:
      fieldValue = { boolValue: value };
      break;
    case FieldDataType.Date:
    case FieldDataType.DateTime:
      fieldValue = {
        dateTimeValue: value ? (value as Date).toUTCString() : value
      };
      break;
    case FieldDataType.Calculated:
      fieldValue = { decimalValue: value };
      break;
    case FieldDataType.People:
    case FieldDataType.MultiPeople:
    case FieldDataType.MultiChoice:
    case FieldDataType.Choice:
    case FieldDataType.Url:
    case FieldDataType.RichText:
    case FieldDataType.MultiLevelChoice:
    case FieldDataType.MultiMultiLevelChoice:
    case FieldDataType.Phone:
    case FieldDataType.Location:
      fieldValue = {
        extendedValue: {
          value: value ?? []
        }
      };
      break;
    default:
      fieldValue = { extendedValue: value };
      break;
  }
  return fieldValue;
};

export { formatFieldValueForBackend, formatFieldValueForFrontend };
