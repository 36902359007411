import { fieldConfiguration, tablesConfiguration } from "enada-common";
import {
  Checkbox,
  Chip,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTemplateBCAssociationMappings } from "../../../../services/APIService";
import { EdisonSearchBar, EdisonTypography } from "enada-components";
import { BorderedTableCell } from "../../../personal/approvalstable/ApprovalsTable";
import {
  AssociationField,
  AssociationTable,
  RecordAssociationMapping
} from "../../../../types/createRecordModal";

export interface CreateRecordCopyDataProps {
  templateId: number;
  recordId: number;
  baseAssociation: RecordAssociationMapping;
  setFields: (fields: AssociationField[]) => void;
  setTables: (tables: AssociationTable[]) => void;
}
const CreateRecordCopyData: FC<CreateRecordCopyDataProps> = ({
  recordId,
  templateId,
  baseAssociation,
  setTables,
  setFields
}) => {
  const { t } = useTranslation(["common"]);
  const [association, setAssociation] = useState<RecordAssociationMapping | undefined>();
  const [search, setSearch] = useState("");

  const selectedFields = baseAssociation?.fields ?? [];
  const selectedTables = baseAssociation?.tables ?? [];

  useEffect(() => {
    const getAssociations = async () => {
      const response = await getTemplateBCAssociationMappings(recordId, templateId);
      setAssociation(response.data);
    };
    getAssociations();
  }, []);
  const renderNoResultsMessage = () => {
    if (association?.fields?.length || association?.tables?.length) return undefined;
    return (
      <TableRow>
        <BorderedTableCell colSpan={3}>
          {t(association === undefined ? "loading" : "noResultsMessage")}
        </BorderedTableCell>
      </TableRow>
    );
  };

  return (
    <Stack spacing={1}>
      <EdisonTypography title={t("businessCaseAssociationMessage")} variant="data" />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <BorderedTableCell colSpan={3}>
                <EdisonSearchBar
                  searchFilter={search}
                  setSearchFilter={setSearch}
                  placeholder={t("search")}
                />
              </BorderedTableCell>
            </TableRow>
            <TableRow>
              <BorderedTableCell>
                <Checkbox
                  checked={
                    selectedFields.length === (association?.fields ?? []).length &&
                    selectedTables.length === (association?.tables ?? []).length
                  }
                  onChange={e => {
                    setFields(e.target.checked ? association?.fields ?? [] : []);
                    setTables(e.target.checked ? association?.tables ?? [] : []);
                  }}
                />
                {t("copyData")}
              </BorderedTableCell>
              <BorderedTableCell>{t("name")}</BorderedTableCell>
              <BorderedTableCell>{t("type")}</BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderNoResultsMessage() ?? (
              <>
                {association?.fields
                  ?.filter(
                    field =>
                      search === "" ||
                      field.displayName?.toLowerCase().includes(search.toLowerCase()) ||
                      field.dataType.toLowerCase().includes(search.toLowerCase())
                  )
                  .map(field => (
                    <TableRow key={field.id}>
                      <BorderedTableCell>
                        <Checkbox
                          checked={selectedFields.some(selected => selected.id === field.id)}
                          onChange={e =>
                            setFields(
                              e.target.checked
                                ? [...selectedFields, field]
                                : selectedFields.filter(prevField => field.id !== prevField.id)
                            )
                          }
                        />
                      </BorderedTableCell>
                      <BorderedTableCell>{field.displayName}</BorderedTableCell>
                      <BorderedTableCell>
                        <Chip
                          style={{
                            backgroundColor: fieldConfiguration(field.dataType).color,
                            color: "white"
                          }}
                          label={t(field.dataType)}
                        />
                      </BorderedTableCell>
                    </TableRow>
                  ))}
                {association?.tables
                  ?.filter(
                    table =>
                      search === "" ||
                      table.displayName?.toLowerCase().includes(search.toLowerCase()) ||
                      table.dataType.toLowerCase().includes(search.toLowerCase())
                  )
                  .map(table => (
                    <TableRow key={table.id}>
                      <BorderedTableCell>
                        <Checkbox
                          checked={selectedTables.some(selected => selected.id === table.id)}
                          onChange={e =>
                            setTables(
                              e.target.checked
                                ? [...selectedTables, table]
                                : selectedTables.filter(prevTable => table.id !== prevTable.id)
                            )
                          }
                        />
                      </BorderedTableCell>
                      <BorderedTableCell>{table.displayName}</BorderedTableCell>
                      <BorderedTableCell>
                        <Chip
                          style={{
                            backgroundColor: tablesConfiguration(table.dataType).color,
                            color: "white"
                          }}
                          label={t(table.dataType)}
                        />
                      </BorderedTableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default CreateRecordCopyData;
