import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paper, Stack, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import {
  ApiError,
  Image,
  News as NewsModel,
  NotificationLevel,
  useLocalisation
} from "enada-common";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ConfigLayout, EdisonImageField, EdisonRichText, EdisonTypography } from "enada-components";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import "./newsconfig.scss";
import {
  clearIndividualNews,
  selectIndividualNews,
  setIndividualNews,
  updateIndividualNewsProperty,
  selectNextNewsOrder
} from "../../../store/slices/newsSlice";
import { getImagePath } from "../../../utils/imagePathManager";
import { selectRegion } from "../../../store/slices/configSlice";
import { SYSTEM_FILE_SIZE_LIMIT } from "../../../config/authConfig";
import { sanitizeHtml } from "../../../utils/sanitizeHtml";
import { useGetCdnTokenQuery } from "services/api";
import {
  useCreateNewsImageMutation,
  useCreateNewsMutation,
  useGetDetailsNewsQuery,
  useUpdateNewsMutation
} from "../../../services/api";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";

/* eslint-disable no-useless-escape */
const urlRegex =
  /(http(s)?:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
/* eslint-enable no-useless-escape */

const NewsConfig = () => {
  const location = useLocation();
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const individualNews = useAppSelector(selectIndividualNews);
  const { data: cdnToken } = useGetCdnTokenQuery();
  const { locale } = useLocalisation();

  const region = useAppSelector(selectRegion);
  const [newsImage, setNewsImage] = useState<Image>();
  const [titleErrorText, setTitleErrorText] = useState<string | undefined>();
  const [readMoreErrorText, setReadMoreErrorText] = useState<string | undefined>();
  const [readMoreUrlErrorText, setReadMoreUrlErrorText] = useState<string | undefined>();
  const [startDateErrorText, setStartDateErrorText] = useState<string | undefined>();

  const newsToEdit: NewsModel = location.state?.news;
  const nextNewsItemOrder = useAppSelector(selectNextNewsOrder);

  const { data: currentNews, isLoading } = useGetDetailsNewsQuery(parseInt(newsToEdit?.id ?? "0"), {
    skip: !newsToEdit
  });

  const [updateNews] = useUpdateNewsMutation();
  const [createNews] = useCreateNewsMutation();
  const [createNewsImage] = useCreateNewsImageMutation();

  useEffect(() => {
    dayjs.extend(utc);
    //Checking if there are saved data or if is a new news
    if (newsToEdit && currentNews && !isLoading) {
      dispatch(setIndividualNews({ newsData: currentNews }));
    } else {
      dispatch(clearIndividualNews());
    }
  }, [newsToEdit, currentNews, isLoading, dispatch]);

  const onSaveImage = async (image: Image, newsId: string) => {
    try {
      const response = await createNewsImage({ image, newsId }).unwrap();
      if (!(response.status as number).toString().startsWith("2")) throw new Error(response);

      dispatch(
        setCurrentNotification({
          title: "newNewsImageCreated",
          message: "",
          level: NotificationLevel.Success
        })
      );
      dispatch(clearIndividualNews());
    } catch (e: any) {
      dispatch(
        setCurrentNotification({
          title: "newsImageCreationError",
          message: `\n ${e?.data?.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onCreateNews = async (news: Partial<NewsModel>) => {
    try {
      dispatch(
        updateIndividualNewsProperty({
          key: "order",
          value: nextNewsItemOrder
        })
      );
      const response = await createNews(news).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newNewsCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );

        if (newsImage?.preview) {
          onSaveImage(newsImage, response.id ?? "");
        } else {
          dispatch(clearIndividualNews());
        }
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "newsCreationError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateNews = async (news: Partial<NewsModel>) => {
    try {
      const response = await updateNews(news).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newsUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        if (newsImage?.preview) {
          onSaveImage(newsImage, individualNews?.id ?? "");
        } else {
          dispatch(clearIndividualNews());
        }
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "newsEditError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const getImage = useMemo(() => {
    let image;
    if (newsImage?.preview) {
      image = newsImage;
    } else if (individualNews?.imageUrl) {
      image = {
        preview: getImagePath(individualNews.imageUrl, region, cdnToken)
      };
    }
    return image;
  }, [newsImage, individualNews.imageUrl, region, cdnToken]);

  const save = () => {
    if ((individualNews?.title?.length ?? 0) < 1) {
      setTitleErrorText(t("TitleRequiredMessage"));
      return;
    } else {
      setTitleErrorText(undefined);
    }

    if ((individualNews.startDate ?? "").length < 1) {
      setStartDateErrorText(t("StartDateRequiredMessage"));
      return;
    } else {
      setStartDateErrorText(undefined);
    }

    if ((individualNews?.readMoreText?.length ?? 0) < 1) {
      setReadMoreErrorText(t("ReadMoreRequiredMessage"));
      return;
    } else {
      setReadMoreErrorText(undefined);
    }
    if ((individualNews?.readMoreUrl?.length ?? 0) > 0) {
      if (!individualNews?.readMoreUrl?.match(urlRegex)) {
        setReadMoreUrlErrorText(t("linkUrlInvalidMessage"));
        return;
      }
    } else {
      setReadMoreUrlErrorText(t("linkUrlRequiredMessage"));
      return;
    }

    setReadMoreUrlErrorText(undefined);

    if (newsToEdit) onUpdateNews(individualNews);
    else {
      onCreateNews(individualNews);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <ConfigLayout
        t={t}
        title={newsToEdit ? t("updateNews") : t("newNews")}
        onSaveCallback={save}
        onCancelCallback={() => navigate(-1)}
        topPanelContent={
          <Stack direction="row" spacing={4} className="news-top-panel-container">
            <TextField
              className="input"
              label={t("heading")}
              variant="standard"
              required={true}
              helperText={titleErrorText}
              error={!!titleErrorText}
              value={sanitizeHtml(individualNews?.title ?? "")}
              inputProps={{ maxLength: 320 }}
              onChange={e =>
                dispatch(
                  updateIndividualNewsProperty({
                    key: "title",
                    value: e.target.value
                  })
                )
              }
            />
            <DesktopDateTimePicker
              className="input"
              label={t("startDateRequired")}
              value={
                individualNews?.startDate &&
                individualNews?.startDate !== "0001-01-01T00:00:00+00:00"
                  ? individualNews?.startDate
                  : null
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText={startDateErrorText}
                  error={!!startDateErrorText}
                />
              )}
              onChange={(newValue: Dayjs | null) =>
                dispatch(
                  updateIndividualNewsProperty({
                    key: "startDate",
                    value: newValue?.utc().format()
                  })
                )
              }
            />
            <DesktopDateTimePicker
              className="input"
              label={t("expiryDate")}
              value={individualNews?.endDate ?? null}
              renderInput={(params: any) => <TextField {...params} variant="standard" />}
              onChange={(newValue: Dayjs | null) =>
                dispatch(
                  updateIndividualNewsProperty({
                    key: "endDate",
                    value: newValue?.utc().format()
                  })
                )
              }
            />
          </Stack>
        }
        saveDisabled={false}
      >
        <Stack spacing={2} className="news-config-root">
          <Paper className="card-configuration">
            <Stack width="100%" spacing={4} className="news-config-content">
              <EdisonTypography variant="h2" title={t("cardConfiguration")} />
              <Stack direction="row" spacing={3}>
                <TextField
                  multiline
                  className="content"
                  label={t("description")}
                  variant="standard"
                  value={sanitizeHtml(individualNews?.description ?? "")}
                  inputProps={{ maxLength: 2048 }}
                  onChange={e =>
                    dispatch(
                      updateIndividualNewsProperty({
                        key: "description",
                        value: e.target.value
                      })
                    )
                  }
                />
                <div className="content">
                  <EdisonImageField
                    accept="image/png, image/jpg, image/jpeg"
                    label=""
                    invalidMediaTypeMessage={t("invalidMediaType")}
                    invalidFileSizeMessage={t("invalidFileSize")}
                    maxFileSize={SYSTEM_FILE_SIZE_LIMIT}
                    useInternalState={false}
                    value={getImage}
                    onChange={(image: any) => {
                      setNewsImage(image);
                      if (!image && individualNews?.imageUrl) {
                        dispatch(
                          updateIndividualNewsProperty({
                            key: "imageUrl",
                            value: null
                          })
                        );
                      }
                    }}
                  />
                </div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="article-configuration">
            <Stack spacing={2}>
              <EdisonTypography variant="h2" title={t("articleConfiguration")} />
              <Stack direction="row" spacing={2}>
                <TextField
                  size="small"
                  label={t("readMoreText")}
                  variant="outlined"
                  required={true}
                  fullWidth={true}
                  helperText={readMoreErrorText}
                  error={!!readMoreErrorText}
                  value={sanitizeHtml(individualNews?.readMoreText ?? "")}
                  inputProps={{ maxLength: 100 }}
                  onChange={e =>
                    dispatch(
                      updateIndividualNewsProperty({
                        key: "readMoreText",
                        value: e.target.value
                      })
                    )
                  }
                />
                <TextField
                  size="small"
                  label={t("readMoreLink")}
                  variant="outlined"
                  type="url"
                  required={true}
                  fullWidth={true}
                  helperText={readMoreUrlErrorText}
                  error={!!readMoreUrlErrorText}
                  value={individualNews?.readMoreUrl ?? ""}
                  inputProps={{ maxLength: 2048 }}
                  onChange={e =>
                    dispatch(
                      updateIndividualNewsProperty({
                        key: "readMoreUrl",
                        value: e.target.value || null
                      })
                    )
                  }
                />

                <Tooltip sx={{ height: "auto" }} arrow title={t("linkUrlInvalidMessage")}>
                  <InfoOutlined color="primary" />
                </Tooltip>
              </Stack>
              {!isLoading && (
                <EdisonRichText
                  value={individualNews?.content ? JSON.parse(individualNews.content) : undefined}
                  readOnly={false}
                  autoFocus={false}
                  placeholder="Enter text here..."
                  onChange={(changeValue: any) => {
                    dispatch(
                      updateIndividualNewsProperty({
                        key: "content",
                        value: JSON.stringify(changeValue)
                      })
                    );
                  }}
                  initialValue={[]}
                  label={""}
                  useInternalState={false}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </ConfigLayout>
    </LocalizationProvider>
  );
};

export default NewsConfig;
