import { Box } from "@mui/material";
import { FC } from "react";

interface InfoFieldProps {
  title: string;
  data: string | number | boolean | null | undefined | React.ReactNode;
  additionalClass?: string;
}

export const InfoField: FC<InfoFieldProps> = ({ title, data, additionalClass }) => (
  <Box sx={{ padding: "10px 0px" }} className={additionalClass}>
    {title}:
    <Box
      sx={{
        fontWeight: "bold",
        paddingLeft: "5px",
        display: "inline-flex"
      }}
    >
      {data}
    </Box>
  </Box>
);
