import {
  currencies,
  PersonaEntity,
  ResourceType,
  Resource,
  RoleType,
  toCamelCase,
  PermissionType,
  NotificationLevel,
  ApiError,
  FieldValue,
  useLocalisation
} from "enada-common";
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ConfigLayout, EdisonPeopleGroup, EdisonTypography, Loading } from "enada-components";
import { sanitiseInternalFieldName } from "../../../utils/sanitiseInternalFieldName";
import "./resourceconfig.scss";
import {
  clearIndividualResource,
  selectIndividualResource,
  selectIndividualResourceValues,
  selectIndividualResourceValuesChanges,
  setIndividualResource,
  setIndividualResourceValues,
  updateIndividualResourceProperty,
  updateIndividualResourceType,
  updateResourceField
} from "../../../store/slices/resourcesSlice";
import { Get, IDynamicPerson, MgtTemplateProps } from "@microsoft/mgt-react";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { selectAllFields } from "../../../store/slices/fieldsSlice";
import {
  selectResourceFields,
  updateResourceFields
} from "../../../store/slices/resourceFieldsSlice";
import ComponentMapper from "../../../utils/componentMapper";
import { formatFieldValueForFrontend } from "../../../utils/fieldHelpers";
import { getGraphPeopleByUserPermission } from "../../../utils/graph/getGraphPeopleByUserPermission";
import { SYSTEM_FIELD_ID_LIMIT } from "../../../config/authConfig";
import {
  useCreateResourceMutation,
  useGetCalendarsQuery,
  useGetFieldsQuery,
  useGetResourceFieldsQuery,
  useGetResourceManagersQuery,
  useGetResourceQuery,
  useGetResourceValuesQuery,
  useUpdateResourceFieldValuesMutation,
  useUpdateResourceMutation
} from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";
import PeoplePickerWith from "../../../components/peoplePickerWith/PeoplePickerWith";

const ResourceConfig = () => {
  const { t } = useTranslation(["common"]);
  const { state } = useLocation();
  const isEdit = Boolean(state?.resourceId);

  const { data: currentResource, isLoading: resourceIsLoading } = useGetResourceQuery(
    state?.resourceId,
    { skip: !isEdit }
  );
  const { data: currentResourceFieldValues, isLoading: resourceFieldValuesIsLoading } =
    useGetResourceValuesQuery(state?.resourceId, {
      skip: !isEdit
    });
  const { data: currentResourceFields, isLoading: resourceFieldsIsLoading } =
    useGetResourceFieldsQuery();
  const { data: resourceManagers = [], isLoading: resourceManagersIsLoading } =
    useGetResourceManagersQuery();
  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const { data: calendars = [] } = useGetCalendarsQuery();
  const [createResource, { isLoading: createIsLoading }] = useCreateResourceMutation();
  const [updateResource, { isLoading: updateIsLoading }] = useUpdateResourceMutation();
  const [createResourceValues, { isLoading: createResourceValuesIsLoading }] =
    useUpdateResourceFieldValuesMutation();

  const { locale } = useLocalisation();

  const isLoading =
    resourceIsLoading ||
    resourceFieldsIsLoading ||
    resourceFieldValuesIsLoading ||
    resourceManagersIsLoading ||
    createIsLoading ||
    updateIsLoading ||
    createResourceValuesIsLoading;

  const resource = useAppSelector(selectIndividualResource);
  const resourceFields = useAppSelector(selectResourceFields);
  const resourceFieldValues = useAppSelector(selectIndividualResourceValues);
  const resourceFieldValuesChanges = useAppSelector(selectIndividualResourceValuesChanges);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");

  const [filteredManagers, setFilteredManagers] = useState<IDynamicPerson[] | undefined>();

  const getFilteredManagers = useCallback(async () => {
    resourceManagers &&
      setFilteredManagers(
        await getGraphPeopleByUserPermission(
          RoleType.InstanceResourceManager,
          resourceManagers.map((resource: any) => ({
            ...resource,
            roles: [{ value: RoleType.InstanceResourceManager }]
          }))
        )
      );
  }, [resourceManagers]);

  useEffect(() => {
    getFilteredManagers();
  }, [getFilteredManagers, resourceManagers]);

  useEffect(() => {
    if (isEdit && currentResource && currentResourceFieldValues) {
      dispatch(setIndividualResource(currentResource));
      dispatch(setIndividualResourceValues(currentResourceFieldValues));
    } else {
      dispatch(clearIndividualResource());
    }
  }, [currentResource, currentResourceFieldValues, dispatch, isEdit]);

  useEffect(() => {
    if (currentResourceFields) {
      dispatch(updateResourceFields(currentResourceFields));
    }
  }, [currentResourceFields, dispatch]);

  const EmailTemplate = (props: MgtTemplateProps) => {
    setEmail(props.dataContext.mail);
    setDepartment(props.dataContext.department);
    return null;
  };

  const DetailsTooltipSection: FC<{
    title: string;
    value: string;
  }> = ({ title, value }) => (
    <Stack direction="row">
      <Box whiteSpace="nowrap">{title}:</Box>
      <Box fontWeight="bold" whiteSpace="nowrap" sx={{ paddingLeft: "5px" }}>
        {value}
      </Box>
    </Stack>
  );

  const DetailsTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none"
    }
  });

  const currenciesListKeys = Object.keys(currencies).sort((a, b) => a.localeCompare(b));

  const formValid =
    resource?.type !== ResourceType.AAD
      ? !!resource?.displayName &&
        !!resource?.resourceManagers &&
        !!resource?.resourceManagers.length &&
        !!resource?.calendarId
      : !!resource?.userId &&
        !!resource?.resourceManagers &&
        !!resource?.resourceManagers.length &&
        !!resource?.calendarId;

  const adResourceValue = useMemo(
    () =>
      resource?.userId
        ? [
            {
              entityId: resource?.userId,
              type: PermissionType.User,
              presence: "",
              userName: ""
            }
          ]
        : undefined,
    [resource?.userId]
  );

  const calendarTooltipComponent = useMemo(
    () => (
      <Grid item xs={1}>
        <Stack direction="row" height="100%" alignItems="end">
          <DetailsTooltip
            placement="right-end"
            arrow
            title={calendars
              .filter(c => c.id === resource?.calendarId)
              .map(c => (
                <React.Fragment key={c.id}>
                  {c.configuration?.hoursPerWeek && (
                    <DetailsTooltipSection
                      title={t("hoursPerWeek")}
                      value={c.configuration?.hoursPerWeek}
                    />
                  )}
                  {c.configuration?.hoursPerDay && (
                    <DetailsTooltipSection
                      title={t("hoursPerDay")}
                      value={c.configuration?.hoursPerDay}
                    />
                  )}
                  {c.configuration?.days && (
                    <DetailsTooltipSection
                      title={t("workingDays")}
                      value={c.configuration?.days.map((d: any) => d.label).join(", ")}
                    />
                  )}
                </React.Fragment>
              ))}
          >
            <InfoOutlined color="primary" />
          </DetailsTooltip>
          <Box sx={{ textIndent: "5px" }}>
            <EdisonTypography
              title={t("details")}
              variant="dropdownsubheader"
              enableUppercase
              sx={(theme: Theme) => ({
                color: theme.palette.primary.main,
                paddingBottom: "2px"
              })}
            />
          </Box>
        </Stack>
      </Grid>
    ),
    [DetailsTooltip, calendars, resource?.calendarId, t]
  );

  const getCustomFieldComponents = (): React.ReactNode => {
    return resourceFields?.length && fields?.length ? customFieldComponents : null;
  };

  const customFieldComponents = useMemo(
    () =>
      //Temporrily filtering out system fields for release: 20/02/2024 as they are not currently being saved by the backend
      resourceFields
        .filter(field => field.fieldId > SYSTEM_FIELD_ID_LIMIT)
        .map(rf => {
          const field = fields.find(f => f.id === rf.fieldId);

          if (!field) return null;

          const value = resourceFieldValues.find(value => value.fieldId === field?.id);

          const formattedValue = value
            ? formatFieldValueForFrontend(field.dataType, value)
            : undefined;
          return (
            <Grid item xs={12} key={rf.id}>
              <FormControl fullWidth>
                <ComponentMapper
                  value={formattedValue}
                  readOnly={false}
                  isIconOn={false}
                  component={field}
                  isInTable={false}
                  useInternalState={false}
                  onChange={(value: any) =>
                    dispatch(
                      updateResourceField({
                        field: field,
                        value
                      })
                    )
                  }
                />
              </FormControl>
            </Grid>
          );
        }),
    [dispatch, fields, resourceFieldValues, resourceFields]
  );
  const resourceManagersComponent = useMemo(
    () => (
      <PeoplePickerWith
        label={t("resourceManagers")}
        multiple
        required
        disabled={!filteredManagers}
        selectedPeople={filteredManagers?.filter(manager => {
          return (
            manager.id !== undefined &&
            resource?.resourceManagers?.map(x => x.entityId)?.includes(manager.id)
          );
        })}
        filteredPeople={filteredManagers}
        useInternalState={false}
        onChange={(managers: PersonaEntity[]) => {
          dispatch(
            updateIndividualResourceProperty({
              key: "resourceManagers",
              value: managers
                ? managers.map(entity => ({
                    ...entity,
                    entityType: entity.type
                  }))
                : []
            })
          );
        }}
      />
    ),
    [filteredManagers, resource, dispatch, t]
  );

  const onCreateResource = async (resource: Partial<Resource>) => {
    try {
      const response = await createResource(resource).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newResourceCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        return response;
      }
    } catch (e) {
      const error = e as { data: ApiError };

      dispatch(
        setCurrentNotification({
          title: "resourceCreationError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateResource = async (resource: Partial<Resource>) => {
    try {
      const response = await updateResource(resource).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "resourceUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );

        return response;
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "resourceEditError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onSave = async () => {
    try {
      const resourceToSave: Partial<Resource> = { ...resource };

      const saveRequest = isEdit
        ? await onUpdateResource(resourceToSave)
        : await onCreateResource(resourceToSave);

      if (saveRequest) {
        const values = resourceFieldValuesChanges.map(value => ({
          ...value,
          resourceId: saveRequest.id
        })) as FieldValue[];

        if (values?.length) {
          await createResourceValues({
            id: saveRequest.id as number,
            body: values
          });
        }

        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="resource-config-root">
      <div className={!isLoading ? "visible" : "hidden"}>
        <ConfigLayout
          t={(param: string) => {
            return param;
          }}
          saveDisabled={!formValid || isLoading}
          title={!isEdit ? t("newResource") : t("editResource")}
          onSaveCallback={onSave}
          onCancelCallback={() => {
            dispatch(clearIndividualResource());
            navigate(-1);
          }}
          topPanelContent={
            <Grid container spacing={4}>
              <Grid item xs={3}>
                {resource?.userId && (
                  <Get hidden={true} resource={`users/${resource?.userId}?$select=mail,department`}>
                    <EmailTemplate template="default" />
                  </Get>
                )}

                <FormControl fullWidth variant="standard" required disabled={isEdit}>
                  <InputLabel id="type-select">{t("type")}</InputLabel>
                  <Select
                    labelId="type-select"
                    data-testid="resource-type-select"
                    autoFocus
                    variant="standard"
                    value={resource?.type ?? ResourceType.AAD}
                    onChange={e => {
                      setEmail("");
                      setDepartment("");
                      dispatch(updateIndividualResourceType(e.target.value as ResourceType));
                    }}
                  >
                    {Object.keys(ResourceType).map(key => (
                      <MenuItem key={key} value={key}>
                        {t(toCamelCase(key))}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3} className="resource-people-picker">
                {resource?.type === ResourceType.AAD || !resource?.type ? (
                  <FormControl
                    fullWidth
                    variant="standard"
                    required
                    className="resource-name-container"
                  >
                    {isLoading ? (
                      <div> loading</div>
                    ) : isEdit ? (
                      <Stack className="people-group-container">
                        <EdisonPeopleGroup
                          value={adResourceValue ? adResourceValue : ([] as PersonaEntity[])}
                          avatarSize="small"
                          viewType={"oneline"}
                        />
                      </Stack>
                    ) : (
                      <PeoplePickerWith
                        label={t("name")}
                        required
                        disableGroups
                        value={adResourceValue}
                        useInternalState={false}
                        onChange={(users: PersonaEntity[]) => {
                          setEmail("");
                          setDepartment("");
                          dispatch(
                            updateIndividualResourceProperty({
                              key: "userId",
                              value: users && users.length > 0 ? users[0].entityId : null
                            })
                          );
                        }}
                      />
                    )}
                  </FormControl>
                ) : (
                  <Box data-testid="resource-name-container">
                    <FormControl fullWidth>
                      <TextField
                        variant="standard"
                        label={t("name")}
                        value={resource?.displayName ?? ""}
                        required
                        onChange={e => {
                          dispatch(
                            updateIndividualResourceProperty({
                              key: "displayName",
                              value: e.target.value
                            })
                          );
                          if (!isEdit)
                            dispatch(
                              updateIndividualResourceProperty({
                                key: "name",
                                value: sanitiseInternalFieldName(e.target.value)
                              })
                            );
                        }}
                      />
                    </FormControl>
                  </Box>
                )}
              </Grid>
              <Grid item xs={3}>
                <Box data-testid="resource-name-email">
                  <FormControl fullWidth>
                    <TextField
                      variant="standard"
                      type="email"
                      value={resource?.email ? resource?.email : email}
                      label={t("email")}
                      disabled={resource?.type === ResourceType.AAD}
                      onChange={
                        resource?.type !== ResourceType.AAD
                          ? e => {
                              dispatch(
                                updateIndividualResourceProperty({
                                  key: "email",
                                  value: e.target.value
                                })
                              );
                            }
                          : undefined
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="status-select">{t("status")}</InputLabel>
                  <Select
                    labelId="type-select"
                    variant="standard"
                    data-testid="resource-status-select"
                    value={resource?.isActive ? 1 : 0}
                    onChange={e => {
                      dispatch(
                        updateIndividualResourceProperty({
                          key: "isActive",
                          value: Boolean(e.target.value)
                        })
                      );
                    }}
                  >
                    <MenuItem value={1}>{t("isActive")}</MenuItem>
                    <MenuItem value={0}>{t("isInactive")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          }
          secondaryTitle={t("resourceConfiguration")}
        >
          <Grid container spacing={4}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">{t("resourceManagerNotice")}</Alert>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  {isLoading ? <div> loading</div> : resourceManagersComponent}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={resource?.calendarId ? 11 : 12}>
              <FormControl fullWidth variant="standard" required>
                <InputLabel id="calendar-select-label">{t("calendar")}</InputLabel>

                <Select
                  label={t("calendar")}
                  labelId="calendar-select-label"
                  data-testid="resource-calendar-select"
                  value={resource?.calendarId ?? ""}
                  onChange={e =>
                    dispatch(
                      updateIndividualResourceProperty({
                        key: "calendarId",
                        value: e.target.value
                      })
                    )
                  }
                >
                  {[...calendars]
                    .sort((a, b) => (a.displayName ?? "").localeCompare(b.displayName ?? ""))
                    .map(calendar => (
                      <MenuItem key={calendar.id} value={calendar.id}>
                        {calendar.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {resource?.calendarId && calendarTooltipComponent}
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  value={resource?.department ? resource?.department : department}
                  label={t("department")}
                  disabled={resource?.type === ResourceType.AAD}
                  onChange={
                    resource?.type !== ResourceType.AAD
                      ? e => {
                          dispatch(
                            updateIndividualResourceProperty({
                              key: "department",
                              value: e.target.value
                            })
                          );
                        }
                      : undefined
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="currency-select-label">{t("currency")}</InputLabel>
                <Select
                  label={t("currency")}
                  labelId="currency-select-label"
                  data-testid="resource-currency-select"
                  value={resource?.costRateCurrency ?? ""}
                  onChange={e =>
                    dispatch(
                      updateIndividualResourceProperty({
                        key: "costRateCurrency",
                        value: e.target.value
                      })
                    )
                  }
                >
                  {currenciesListKeys.map(currencyKey => {
                    const idontknow = currencies[currencyKey as keyof typeof currencies];
                    return (
                      <MenuItem key={currencyKey} value={currencyKey}>
                        {`${currencyKey} (${idontknow.currencyName} - ${idontknow.currencySymbol})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Box data-testid="resource-cost-rate">
                <FormControl fullWidth>
                  <TextField
                    label={t("costRatePerDay")}
                    variant="standard"
                    type="number"
                    value={resource?.costRate ?? ""}
                    onChange={e =>
                      dispatch(
                        updateIndividualResourceProperty({
                          key: "costRate",
                          value: e.target.value
                        })
                      )
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              {resourceFieldValuesIsLoading ? null : getCustomFieldComponents()}
            </LocalizationProvider>
          </Grid>
        </ConfigLayout>
      </div>
      <div className={isLoading ? "visible" : "hidden"}>
        <Loading size={150} sx={{ marginTop: "100px" }} />
      </div>
    </div>
  );
};

export default ResourceConfig;
