import {
  ApiError,
  Field,
  FieldDataType,
  NotificationLevel,
  parseConditionBlockToFrontend,
  parseExpressionToFrontend,
  toCamelCase
} from "enada-common";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CalculatedConfig from "../../../components/adminconfig/calculated/CalculatedConfig";
import ChoiceConfig from "../../../components/adminconfig/choice/ChoiceConfig";
import CurrencyConfig from "../../../components/adminconfig/currency/CurrencyConfig";
import NumberConfig from "../../../components/adminconfig/number/NumberConfig";
import PeopleConfig from "../../../components/adminconfig/people/PeopleConfig";
import PercentageConfig from "../../../components/adminconfig/percentage/PercentageConfig";
import RichTextConfig from "../../../components/adminconfig/richtext/RichTextConfig";
import SwitchConfig from "../../../components/adminconfig/switch/SwitchConfig";
import TextBoxConfig from "../../../components/adminconfig/textbox/TextBoxConfig";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectCalculatedConditionBlock,
  selectCalculatedResultType,
  selectCalculatedSimpleExpression,
  selectIsCondition,
  setConditionExpression,
  setIsCondition,
  setSimpleExpression
} from "../../../store/slices/calculatedFieldSlice";
import {
  clearIndividualField,
  selectAllNonDeletedFields,
  selectAllNonDeletedSystemFields,
  selectIndividualField,
  updateIndividualField,
  updateIndividualFieldDataType,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import { ConfigLayout, Loading } from "enada-components";
import { sanitiseInternalFieldName } from "../../../utils/sanitiseInternalFieldName";
import "./fieldconfig.scss";
import {
  parseConditionBlockToBackend,
  parseExpressionToBackend
} from "../../../components/adminconfig/calculated/utils/parsing/parseCalculationToBackend";

import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { validateFieldConfig } from "../../../utils/validateFieldConfig";
import {
  useCreateFieldMutation,
  useGetFieldQuery,
  useGetFieldsQuery,
  useUpdateFieldMutation
} from "services/api";

const FieldConfig = () => {
  const { t } = useTranslation(["common"]);
  const [nameError, setNameError] = useState(false);
  const [createField, { isLoading: createIsLoading }] = useCreateFieldMutation();
  const [updateField, { isLoading: updateIsLoading }] = useUpdateFieldMutation();

  const isLoading = createIsLoading || updateIsLoading;

  const { state } = useLocation();
  const isEdit = Boolean(state.id);

  const { data: currentField } = useGetFieldQuery(state.id, { skip: !isEdit });

  const { nonDeletedFields = [], nonDeletedSystemFields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      nonDeletedFields: selectAllNonDeletedFields(result),
      nonDeletedSystemFields: selectAllNonDeletedSystemFields(result)
    })
  });

  const field = useAppSelector(selectIndividualField);
  const expressionList = useAppSelector(selectCalculatedSimpleExpression);
  const isCalculatedConditional = useAppSelector(selectIsCondition);
  const conditionBlock = useAppSelector(selectCalculatedConditionBlock);
  const calculatedResultType = useAppSelector(selectCalculatedResultType);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentField && isEdit) {
      if (currentField.expression) {
        dispatch(setSimpleExpression(parseExpressionToFrontend(currentField.expression)));
      } else if (currentField.calculatedExpression) {
        dispatch(
          setConditionExpression(parseConditionBlockToFrontend(currentField.calculatedExpression))
        );
        dispatch(setIsCondition(true));
      }
      dispatch(updateIndividualField(currentField));
    } else {
      dispatch(clearIndividualField());
    }
  }, [currentField, dispatch, isEdit]);

  const getConfigTitle = (dataType?: string) => {
    switch (dataType) {
      case FieldDataType.TextBox:
        return t("textFieldConfiguration");
      case FieldDataType.RichText:
        return t("richTextConfiguration");
      case FieldDataType.Currency:
        return t("currencyFieldConfiguration");
      case FieldDataType.Percentage:
        return t("percentageFieldConfiguration");
      case FieldDataType.Number:
        return t("numberFieldConfiguration");
      case FieldDataType.People:
        return t("peopleFieldConfiguration");
      case FieldDataType.Switch:
        return t("switchFieldConfiguration");
      case FieldDataType.MultiPeople:
        return t("multiPeopleFieldConfiguration");
      case FieldDataType.Calculated:
        return t("calculatedFieldConfiguration");
      case FieldDataType.MultiChoice:
        return t("multiChoiceFieldConfiguration");
      case FieldDataType.MultiLevelChoice:
        return t("multiLevelChoiceFieldConfiguration");
      case FieldDataType.Choice:
        return t("choiceFieldConfiguration");
      default:
        break;
    }
  };

  const renderFieldType = (fieldType: FieldDataType) => {
    switch (fieldType) {
      case FieldDataType.TextBox:
        return <TextBoxConfig />;
      case FieldDataType.Number:
        return <NumberConfig />;
      case FieldDataType.RichText:
        return <RichTextConfig />;
      case FieldDataType.People:
      case FieldDataType.MultiPeople:
        return <PeopleConfig />;
      case FieldDataType.Choice:
      case FieldDataType.MultiChoice:
        return <ChoiceConfig />;
      case FieldDataType.MultiLevelChoice:
        return <ChoiceConfig multiLevel={true} />;
      case FieldDataType.Switch:
        return <SwitchConfig />;
      case FieldDataType.Percentage:
        return <PercentageConfig />;
      case FieldDataType.Currency:
        return <CurrencyConfig />;
      case FieldDataType.Calculated:
        return <CalculatedConfig />;
      default:
        return null;
    }
  };

  const onCreateField = async (field: Partial<Field>) => {
    try {
      const response = await createField(field).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newFieldCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );

        navigate(-1);
      }
    } catch (e: any) {
      const error = e?.data as ApiError;
      dispatch(
        setCurrentNotification({
          title: "fieldCreationError",
          message: Array.isArray(error.errors) ? error.errors.join("\n") : error.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateField = async (field: Partial<Field>) => {
    try {
      const response = await updateField(field).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "fieldUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );

        navigate(-1);
      }
    } catch (e: any) {
      const error = e?.data as ApiError;
      dispatch(
        setCurrentNotification({
          title: "fieldEditError",
          message: Array.isArray(error.errors) ? error.errors.join("\n") : error.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onSave = () => {
    try {
      const fieldToSave: Partial<Field> = { ...field };
      if (field?.dataType === FieldDataType.Calculated) {
        if (isCalculatedConditional) {
          if (!conditionBlock) {
            return;
          }
          fieldToSave.calculatedExpression = parseConditionBlockToBackend(
            conditionBlock,
            calculatedResultType,
            nonDeletedFields.concat(nonDeletedSystemFields)
          );
        } else {
          const parsed = parseExpressionToBackend(
            expressionList,
            nonDeletedFields.concat(nonDeletedSystemFields),
            calculatedResultType
          );
          fieldToSave.expression = parsed;
        }
      }
      validateFieldConfig(fieldToSave as Field);

      isEdit ? onUpdateField(fieldToSave) : onCreateField(fieldToSave);
    } catch (e: any) {
      dispatch(
        setCurrentNotification({
          title: "Invalid Field Config.",
          message: e as string,
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <div>
      {!isLoading ? (
        <ConfigLayout
          t={(param: string) => {
            return param;
          }}
          saveDisabled={!field?.displayName}
          title={!isEdit ? t("newField") : t("editField")}
          onSaveCallback={onSave}
          onCancelCallback={() => {
            dispatch(clearIndividualField());
            navigate(-1);
          }}
          topPanelContent={
            <>
              <TextField
                className="input"
                variant="standard"
                label={t("fieldName")}
                value={field?.displayName ?? ""}
                helperText={nameError ? t("internalNameError", { field: "Field" }) : ""}
                error={nameError}
                onChange={e => {
                  dispatch(
                    updateIndividualFieldProperty({
                      key: "displayName",
                      value: e.target.value
                    })
                  );
                  if (!isEdit) {
                    const sanitised = sanitiseInternalFieldName(e.target.value);
                    if (sanitised !== "") {
                      dispatch(
                        updateIndividualFieldProperty({
                          key: "name",
                          value: sanitised
                        })
                      );
                      setNameError(false);
                    } else {
                      setNameError(true);
                    }
                  }
                }}
              />
              <TextField
                className="input"
                label={t("internalName")}
                variant="standard"
                value={field?.name ?? ""}
                disabled
              />
              <TextField
                className="input"
                variant="standard"
                label={t("description")}
                value={field?.description ?? ""}
                onChange={e =>
                  dispatch(
                    updateIndividualFieldProperty({
                      key: "description",
                      value: e.target.value
                    })
                  )
                }
              />

              <FormControl className="input" variant="standard" required disabled={isEdit}>
                <InputLabel id="type-select">{t("type")}</InputLabel>
                <Select
                  labelId="type-select"
                  variant="standard"
                  value={field?.dataType ?? FieldDataType.TextBox}
                  onChange={e => {
                    dispatch(updateIndividualFieldDataType(e.target.value as FieldDataType));
                  }}
                >
                  {(Object.keys(FieldDataType) as FieldDataType[])
                    .filter(
                      type =>
                        type !== FieldDataType.MultiMultiLevelChoice &&
                        type !== FieldDataType.Button
                    )
                    .map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {t(toCamelCase(key))}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          }
          secondaryTitle={getConfigTitle(field?.dataType || FieldDataType.TextBox)}
        >
          {renderFieldType(field?.dataType || FieldDataType.TextBox)}
        </ConfigLayout>
      ) : (
        <Loading size={150} sx={{ marginTop: "100px" }} />
      )}
    </div>
  );
};

export default FieldConfig;
