import { EdisonExpandableMenu } from "enada-components";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import { useTranslation } from "react-i18next";
import WorkflowFlag from "../workflowflag/WorkflowFlag";
import { RecordType, WorkflowStage } from "enada-common";
import { Node } from "reactflow";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectCurrentElement,
  selectWorkflowType,
  updateElement
} from "../../../../store/slices/workflowSlice";

const StageScopeOptions = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const workflowType = useAppSelector(selectWorkflowType);

  return (
    <EdisonExpandableMenu
      name={t("scopeOptions")}
      noSidePadding
      sticky={false}
      icon={<ConstructionOutlinedIcon />}
    >
      {workflowType === RecordType.Challenges && (
        <WorkflowFlag
          primaryLabel={t("allowIdeaSubmission")}
          secondaryLabel={t("allowIdeaSubmissionDescription")}
          checked={Boolean((currentNode?.data as WorkflowStage)?.allowRecordCreation)}
          onChange={e => {
            if (!currentNode) return;

            dispatch(
              updateElement(
                updateNodeCustomData("allowRecordCreation", e.target.checked, currentNode as Node)
              )
            );
          }}
        />
      )}
      {workflowType === RecordType.BusinessCase && (
        <WorkflowFlag
          primaryLabel={t("allowProjectCreation")}
          secondaryLabel={t("allowProjectCreationDescription")}
          checked={Boolean((currentNode?.data as WorkflowStage)?.allowRecordCreation)}
          onChange={e => {
            if (!currentNode) return;

            dispatch(
              updateElement(
                updateNodeCustomData("allowRecordCreation", e.target.checked, currentNode as Node)
              )
            );
          }}
        />
      )}
      {workflowType === RecordType.Ideas && (
        <>
          <WorkflowFlag
            primaryLabel={t("allowProjectCreation")}
            secondaryLabel={t("allowProjectCreationDescription")}
            checked={Boolean((currentNode?.data as WorkflowStage)?.allowProjectCreation)}
            onChange={e => {
              if (!currentNode) return;

              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "allowProjectCreation",
                    e.target.checked,
                    currentNode as Node
                  )
                )
              );
            }}
          />
          <WorkflowFlag
            primaryLabel={t("allowBCCreation")}
            secondaryLabel={t("allowBCCreationDescription")}
            checked={Boolean((currentNode?.data as WorkflowStage)?.allowBusinessCaseCreation)}
            onChange={e => {
              if (!currentNode) return;

              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "allowBusinessCaseCreation",
                    e.target.checked,
                    currentNode as Node
                  )
                )
              );
            }}
          />
        </>
      )}
    </EdisonExpandableMenu>
  );
};

export default StageScopeOptions;
