import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { EdisonExpandableMenu, EdisonPeoplePickerField } from "enada-components";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { Stack, TextField } from "@mui/material";
import {
  selectCurrentElement,
  updateElement,
  selectWorklowValidationErrorsByNode,
  clearWorkflowError,
  selectIsWorkflowDefault
} from "../../../../store/slices/workflowSlice";
import {
  updateMultipleNodeProperties,
  updateNodeCustomData
} from "../../utils/updateNodeCustomData";
import { Node } from "reactflow";
import "./stagereviewers.scss";
import { PersonaEntity, PermissionType } from "enada-common";
import StageTasks from "../stagetasks/StageTasks";
import { getRequestMsGraph } from "../../../../services/APIService";
import { useEffect, useState } from "react";

const StageReviewers = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const isDefault = useAppSelector(selectIsWorkflowDefault);
  const errors = useAppSelector(state =>
    selectWorklowValidationErrorsByNode(state, currentNode?.data?.name)
  );

  const [reviewers, setReviewers] = useState<PersonaEntity[] | undefined>(undefined);
  useEffect(() => {
    if (currentNode?.data.reviewers) {
      const reviewersPersonaEntity = currentNode?.data.reviewers.map(
        reviewer =>
          ({
            entityId: reviewer.entityId,
            type: reviewer.permissionType
          } as PersonaEntity)
      );
      if (reviewersPersonaEntity !== currentNode?.data.reviewers) {
        setReviewers(reviewersPersonaEntity);
      }
    }
  }, [currentNode?.data.reviewers]);

  const maxReviewersNumberError = errors.some(error => error.property === "maxReviewsCount");

  const requiredApprovalsError = errors.some(error => error.property === "requiredApproversCount");
  const reviewerError = errors.find(error => error.property === "stageApprovers");

  const getReviewersCount = async (entities: PersonaEntity[]) => {
    const newCount = await entities.reduce(async (acc, entity) => {
      if (entity.type === PermissionType.User) {
        return (await acc) + 1;
      }
      const headers = new Headers();
      headers.append("ConsistencyLevel", "eventual");
      const response = await getRequestMsGraph(`groups/${entity.entityId}/members/$count`, headers);
      if (response.status !== 200) {
        return (await acc) + 1;
      }
      const groupCount = await response.json();
      return (await acc) + groupCount;
    }, Promise.resolve(0));

    return newCount;
  };

  return (
    <EdisonExpandableMenu
      name={t("review")}
      icon={<AddTaskOutlinedIcon />}
      noSidePadding
      sticky={false}
    >
      <Stack spacing={2} className="stage-reviewers-root">
        <EdisonPeoplePickerField
          disabled={isDefault}
          multiple={true}
          label={t("approvers")}
          value={reviewers}
          onChange={async changeValue => {
            if (!currentNode) return;
            const reviewersCount = await getReviewersCount(changeValue);
            dispatch(
              updateElement(
                updateMultipleNodeProperties(
                  {
                    reviewers: changeValue.map(change => ({
                      entityId: change.entityId,
                      permissionType: change.type
                    })),
                    configuration: {
                      ...currentNode.data.configuration,
                      reviewersCount: reviewersCount
                    }
                  },
                  currentNode as Node
                )
              )
            );

            if (reviewerError === undefined) return;
            dispatch(clearWorkflowError(reviewerError));
          }}
          useInternalState={false}
          error={reviewerError !== undefined}
        />

        <TextField
          required
          type={"number"}
          variant="outlined"
          label={t("requiredNumberOfApprovals")}
          disabled={isDefault}
          value={
            currentNode?.data && currentNode.data.requiredApproversCount
              ? currentNode.data.requiredApproversCount
              : ""
          }
          onChange={e => {
            if (!currentNode) return;
            dispatch(
              updateElement(
                updateNodeCustomData("requiredApproversCount", e.target.value, currentNode as Node)
              )
            );
            if (requiredApprovalsError) {
              const approvalsError = errors.find(
                error => error.property === "requiredApproversCount"
              );
              if (approvalsError === undefined) return;
              dispatch(clearWorkflowError(approvalsError));
            }
          }}
          helperText={t("reviewersCountMessage")}
          error={requiredApprovalsError}
          data-testid={"required-num-approvals"}
        />
        <TextField
          type={"number"}
          variant="outlined"
          label={t("maxNumberOfReviews")}
          disabled={isDefault}
          required
          value={
            currentNode?.data && currentNode.data.maxReviewersCount
              ? currentNode.data.maxReviewersCount
              : ""
          }
          onChange={e => {
            if (!currentNode) return;
            dispatch(
              updateElement(
                updateNodeCustomData("maxReviewersCount", e.target.value, currentNode as Node)
              )
            );
          }}
          helperText={t("maxReviewersCountMessage")}
          error={maxReviewersNumberError}
          data-testid={"max-num-reviewers"}
        />
        <StageTasks />
      </Stack>
    </EdisonExpandableMenu>
  );
};

export default StageReviewers;
