import { RecordType } from "enada-common";
import Discover from "../../discover/Discover";
import { useGetUserQuery } from "services/api";

const CardsTab = () => {
  const { data: user } = useGetUserQuery();
  return (
    <Discover
      hideFilters={true}
      propQuery={{
        expand: "owners",
        orderBy: "created desc",
        filter: {
          recordType: { in: Object.values(RecordType) },
          owners: { any: [{ entityId: user?.id }] }
        }
      }}
    />
  );
};

export default CardsTab;
