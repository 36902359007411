import { DateHelper, StringHelper } from "@bryntum/core-thin";
import { BryntumGanttProps } from "@bryntum/gantt-react-thin";
import { BaselinesConfig } from "@bryntum/gantt-thin";
import SharedConfig from "../../config/SharedConfig";

const taskRenderer = (data: any) => {
  const { taskRecord } = data;
  if (taskRecord.isMilestone) return;
  return [
    {
      tag: "div",
      class: "taskName",
      html: StringHelper.encodeHtml(taskRecord.name)
    }
  ];
};
// This is needed due to the fact that the task (dates/ durations) have miliseconds but the baseline do not
const removeMiliseconds = (date: any) => {
  return Math.floor(new Date(date).getTime() / 1000);
};
const baseLineFeatures: Partial<BaselinesConfig> = {
  // Taken from
  //https://bryntum.com/products/gantt/examples/baselines/
  disabled: true,
  template: (data: any) => {
    const { baseline } = data;
    const { task } = baseline;
    const delayed = removeMiliseconds(task.startDate) > removeMiliseconds(baseline.startDate);
    const overrun = Math.floor(task.durationMS / 1000) > Math.floor(baseline.durationMS / 1000);

    return `
    <div class="b-gantt-task-title">${StringHelper.encodeHtml(task.name)} (baseline ${
      baseline.parentIndex + 1
    })</div>
    <table>
    <tr><td>Start:</td><td>${data.startClockHtml}</td></tr>
    ${
      baseline.milestone
        ? ""
        : `
        <tr><td>End:</td><td>${data.endClockHtml}</td></tr>
        <tr><td>Duration:</td><td class="b-right">${
          Math.round(baseline.duration * 10) / 10 +
          " " +
          DateHelper.getLocalizedNameOfUnit(baseline.durationUnit, baseline.duration !== 1)
        }</td></tr>
    `
    }
    </table>
    ${
      delayed
        ? `
        <h4 class="statusmessage b-baseline-delay"><i class="statusicon b-fa b-fa-exclamation-triangle"></i>
          Delayed start by ${DateHelper.formatDelta(task.startDate - baseline.startDate)}</h4>
    `
        : ""
    }
    ${
      overrun
        ? `
        <h4 class="statusmessage b-baseline-overrun"><i class="statusicon b-fa b-fa-exclamation-triangle"></i>
          Overrun by
       ${DateHelper.formatDelta(task.durationMS - baseline.durationMS, {
         precision: "h"
       })}</h4>
    `
        : ""
    }
    `;
  }
};
const scheduleTableGanttConfig: Partial<BryntumGanttProps> = {
  ...SharedConfig,
  dependencyIdField: "wbsCode",
  showLagInTooltip: true,
  viewPreset: "weekAndDayLetter",
  barMargin: 10,
  taskRenderer: taskRenderer as any,
  columnLines: true,
  subGridConfigs: {
    locked: {
      flex: 10
    },
    normal: {
      flex: 1
    }
  },
  resizeToFitIncludesHeader: true,
  scrollTaskIntoViewOnCellClick: true,
  fixedRowHeight: false,
  // Features
  taskCopyPasteFeature: true, // Not shared because it doesn't exist in BryntumGridProps
  taskEditFeature: {
    editorConfig: {
      cls: "enada-bryntum-editor",
      itemCls: "fieldclass"
    },
    items: {
      generalTab: {
        items: {
          startDate: {
            type: "datetimefield",
            flex: "1 0 100%",
            cls: ""
          },
          endDate: {
            type: "datetimefield",
            flex: "1 0 100%",
            cls: ""
          }
        }
      }
    }
  },
  baselinesFeature: baseLineFeatures,
  sortFeature: false,
  timeRangesFeature: {
    enableResizing: true,
    showCurrentTimeLine: false,
    showHeaderElements: true,
    disabled: true
  },
  taskMenuFeature: {
    menu: {
      cls: "enada-bryntum-popup-menu"
    }
  },
  cellMenuFeature: false,
  cellEditFeature: {
    triggerEvent: "cellclick",
    addNewAtEnd: true
  },
  indicatorsFeature: {
    items: {
      deadlineDate: true,
      constraintDate: true
    }
  },
  progressLineFeature: {
    statusDate: new Date(),
    disabled: true
  }
};

export default scheduleTableGanttConfig;
