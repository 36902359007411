import { apiConfig } from "config/authConfig";
import { Form } from "enada-common";
import { baseAPI } from "services/baseAPI";

export const formsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getForms: builder.query<Form[], void>({
      query: () => `${apiConfig.contentHubFormsEndpoint}`,
      transformResponse: (response: { data: Form[] }) => response.data,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: "Forms" as const, id })), "Forms"] : ["Forms"]
    }),

    getForm: builder.query<Form, number>({
      query: id => `${apiConfig.contentHubFormsEndpoint}/${id}`,
      transformResponse: (response: { data: Form }) => response.data,
      providesTags: (result, error, id) => [{ type: "Forms", id }]
    }),

    createForm: builder.mutation<Form, Partial<Form>>({
      query: body => ({
        url: apiConfig.contentHubFormsEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Form }) => response.data,
      invalidatesTags: ["Forms"]
    }),

    updateForm: builder.mutation<Form, Partial<Form>>({
      query: body => ({
        url: apiConfig.contentHubFormsEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Form }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Forms", id: body.id }]
    }),

    deleteForms: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFormsEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Forms", id }))
    }),

    restoreForms: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFormsEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Forms", id }))
    }),

    recycleForms: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFormsEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Forms", id }))
    })
  })
});
