import { nanoid } from "@reduxjs/toolkit";

import {
  Category,
  Column,
  ColumnField,
  ColumnTable,
  Container,
  Field,
  Form,
  ItemDNDType,
  OperationType,
  RecordFieldValue,
  Table,
  WorkflowStageView,
  isTableType
} from "enada-common";
import { ExtendedValue, FrontendRecordValue, RecordValueType, fieldTypesMap } from "./recordSlice";

// import WorkflowStageView from "../../models/workflow/WorkflowStageView.model";
// import {
//   WorkflowStageViewField,
//   WorkflowStageViewTable,
// } from "../../models/workflow/WorkflowStageViewItem.model";
export const sortFormElements = (elements: any[] | undefined): any[] => {
  if (elements) {
    return elements.sort((first, second) => {
      if ((first.order || first.order === 0) && (second.order || second.order === 0)) {
        // If order values exist we compare them
        if (first.order < second.order) return -1;
        else return 1;
      } else {
        // If one of the order parameters doesn't exists we ensure to put it at the end
        if (first.order || first.order === 0) return -1;
        if (second.order || second.order === 0) return 1;
      }
      return 0;
    });
  } else {
    return [];
  }
};

export const parseFormDataToFrontend = (
  form: Form | null | Partial<Form>,
  fields: Field[],
  tables: Table[],
  views?: WorkflowStageView
): { formData: ItemDNDType[]; tableIds: number[] } => {
  const tableIds: number[] = [];
  const formData: ItemDNDType[] = sortContainers(form?.containers || []).map((tab, index) => ({
    id: `tab${index}`,
    title: tab.displayName ?? null,
    type: "tab",
    dataId: tab.id,
    children: sortFormElements([...(tab.zones ?? [])]).map(row => {
      row.columns?.map(column => {
        column.tables?.forEach(table => tableIds.push(table.tableId as number));
      });
      return {
        id: nanoid(),
        dataId: row.id,
        title: row.displayName ?? null,
        type: "row",
        children: getSortedColumns(row.columns || [], fields, tables, views)
      };
    })
  }));
  return { formData, tableIds };
};

export const getSortedColumns = (
  columns: any,
  fields: Field[],
  tables: Table[],
  views?: WorkflowStageView
) => {
  let orderedColumns = sortFormElements([...columns]);
  orderedColumns = orderedColumns?.map(column => {
    return {
      id: nanoid(),
      dataId: column.id,
      title: column.displayName ?? null,
      type: "column",
      children: getOrderedFieldsAndTables(
        column.fields || [],
        column.tables || [],
        fields,
        tables,
        views
      )
    };
  });
  return orderedColumns;
};

export const getOrderedFieldsAndTables = (
  columnFields: ColumnField[] | [],
  columnTables: ColumnTable[] | [],
  fields: Field[],
  tables: Table[],
  views?: WorkflowStageView
): any[] => {
  const mappedFields = columnFields?.map(columnField => {
    return {
      item: {
        id: nanoid(),
        title: nanoid(),
        component:
          fields.find(field => field.id === columnField.fieldId) || columnField.configuration,
        type: "component",
        visibility: views?.viewFields?.find(viewField => viewField.fieldId === columnField.fieldId)
          ?.state
      },
      order: columnField?.order
    };
  });
  const mappedTables = columnTables?.map(columnTable => ({
    item: {
      id: nanoid(),
      title: nanoid(),
      component: tables.find(table => table.id === columnTable.tableId),
      type: "component",
      visibility: views?.viewTables?.find(viewTable => viewTable.tableId === columnTable.tableId)
        ?.state
    },
    order: columnTable.order
  }));
  const ordered = sortFormElements([...mappedFields, ...mappedTables]);
  return ordered.map(item => item.item);
};

export const getOrderedColumn = (frontendColumn: ItemDNDType, order: number): Column => {
  const orderedChildren = frontendColumn.children?.map((child: ItemDNDType, index) => ({
    ...child,
    order: index
  }));
  return {
    displayName: frontendColumn.title,
    id: frontendColumn.dataId,
    order: order,
    fields:
      orderedChildren
        ?.filter((child: any) => !isTableType(child.component?.dataType))
        .map(child => {
          return {
            fieldId: child.component?.id,
            order: child.order,
            configuration: child.component?.id === null ? child.component : {}
          };
        }) ?? [],
    tables:
      orderedChildren
        ?.filter((child: any) => isTableType(child.component?.dataType))
        .map(child => ({
          tableId: child.component?.id,
          order: child.order
        })) ?? []
  };
};

// export const updateStageViewFromFormData = (
//   formData: ItemDNDType[],
//   currentView: WorkflowStageView | null
// ): WorkflowStageView => {
//   const fieldViews: WorkflowStageViewField[] = [];
//   const tableViews: WorkflowStageViewTable[] = [];
//   formData.forEach((tab) => {
//     tab.children?.forEach((row) => {
//       row.children?.forEach((column) => {
//         column.children?.forEach((child) => {
//           if (isTableType(child.component?.dataType)) {
//             tableViews.push({
//               state: child.visibility,
//               tableId: child.component?.id,
//             });
//             return;
//           }
//           fieldViews.push({
//             state: child.visibility,
//             fieldId: child.component?.id,
//           });
//         });
//       });
//     });
//   });

//   return { ...currentView, viewFields: fieldViews, viewTables: tableViews };
// };

export const sortContainers = (containers: Container[] | []): Container[] => {
  return [...containers].sort((first, second) => {
    if (first.order === undefined || second.order === undefined) return 0;
    if (first.order > second.order) {
      return 1;
    }
    return -1;
  });
};

export const parseFormFieldsToFrontend = (
  form: Form,
  fieldsList: Field[],
  projectFieldValueList: RecordFieldValue[]
): FrontendRecordValue[] => {
  let frontendProjectValueList: FrontendRecordValue[] = [];
  form &&
    form.containers?.forEach(container => {
      container.zones?.forEach(zone => {
        zone.columns?.forEach(column => {
          frontendProjectValueList = frontendProjectValueList.concat(
            column.fields?.map(field => ({
              fieldId: field.fieldId as number,
              changeMade: false
            })) || []
          );
        });
      });
    });

  const fieldIds = frontendProjectValueList.map(x => x.fieldId);
  const systemFields = fieldsList.filter(
    field => field.category === Category.System && !fieldIds.some(fieldId => fieldId === field.id)
  );

  frontendProjectValueList = frontendProjectValueList.concat(
    systemFields.map(field => ({
      fieldId: field.id as number,
      changeMade: false
    })) || []
  );

  frontendProjectValueList = frontendProjectValueList.map(projectValue => {
    const field = fieldsList.find(fieldToFind => fieldToFind.id === projectValue.fieldId);

    const type = fieldTypesMap.find(
      fieldType => fieldType.fieldType === field?.dataType
    )?.recordValueType;

    const fieldValue = projectFieldValueList.find(value => value.fieldId === projectValue.fieldId);

    if (!fieldValue)
      return {
        ...projectValue,
        type: type
      };

    const possibleValues = (({
      extendedValue,
      decimalValue,
      stringValue,
      boolValue,
      dateTimeValue
    }) => ({
      extendedValue,
      decimalValue,
      stringValue,
      boolValue,
      dateTimeValue
    }))(fieldValue);

    const keyValue = Object.entries(possibleValues).find(
      ([_, value]) => value !== undefined && value !== null && value !== ""
    ) as any;

    if (!keyValue)
      return {
        ...projectValue,
        type: type
      };
    return keyValue[0] === "extendedValue"
      ? {
          ...projectValue,
          type: type,
          value: keyValue[1].value
        }
      : {
          ...projectValue,
          type: type,
          value: keyValue[1]
        };
  });

  return frontendProjectValueList;
};

export const parseFormFieldsToBackend = (
  frontendProjectFieldValues: FrontendRecordValue[],
  projectId: number,
  identifier: string
) => {
  const parsed = frontendProjectFieldValues
    .filter(value => value.changeMade)
    .map(value => {
      const baseValue = {
        fieldId: value.fieldId,
        [identifier]: projectId,
        id: value.id
      };
      switch (value.type) {
        case RecordValueType.boolValue:
          return {
            ...baseValue,
            boolValue: value.value
          };
        case RecordValueType.stringValue:
          return {
            ...baseValue,
            stringValue: value.value
          };
        case RecordValueType.decimalValue:
          return {
            ...baseValue,
            decimalValue: value.value
          };
        case RecordValueType.dateTimeValue:
          return {
            ...baseValue,
            dateTimeValue: value.value
          };

        case RecordValueType.extendedValue: {
          const extended: ExtendedValue = { value: value.value };
          return {
            ...baseValue,
            extendedValue: extended
          };
        }
      }
    });
  const uniqueFields: any[] = [];
  parsed.forEach(field => {
    const found = uniqueFields.find(x => x?.fieldId === field?.fieldId);
    if (!found) {
      uniqueFields.push(field);
    }
  });

  if (!uniqueFields || uniqueFields.length === 0) {
    return [];
  }

  return [{ operationType: OperationType.AddOrUpdate, fieldValues: uniqueFields }];
};
