import { EdisonTabsProps, TabPanelProps } from "enada-common";
import { Box, Tab, Tabs } from "@mui/material";
import React, { FC, useEffect } from "react";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisontabs.scss";

export function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, id, styles, ...other } = props;

  return (
    <div
      style={styles}
      data-testid={`edison-tabs-panel-${id}`}
      role="tabpanel"
      hidden={value !== id}
      id={`simple-tabpanel-${id}`}
      aria-labelledby={`simple-tab-${id}`}
      {...other}
    >
      {/* Removed conditional rendering of tabs, as the reloading of each tabs was causing the tables on the tabs to re initialise, setting the div to hidden handles the tab visibility */}
      {/* TODO: Revisit if performance of tabs degrades */}
      <Box sx={{ p: 0, height: "100%" }}>
        <div style={{ height: "100%" }}>{children}</div>
      </Box>
    </div>
  );
}

export function TabProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const EdisonTabs: FC<EdisonTabsProps> = ({
  tabs,
  indicatorColour,
  height,
  scrollable,
  tabTitleBoxStyling,
  tabPanelStyles,
  activeTabId,
  onActiveTabChange,
  wideHeaders = false
}) => {
  const [value, setValue] = React.useState<string>(
    activeTabId ? activeTabId : !tabs[0] ? "0" : tabs[0].id
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (!onActiveTabChange) return;
    onActiveTabChange(newValue);
  };

  useEffect(() => {
    if (!activeTabId) return;
    setValue(activeTabId);
  }, [activeTabId]);

  return (
    <Box sx={{ height: height }}>
      <Box sx={tabTitleBoxStyling}>
        <Tabs
          data-testid={"edison-tabs"}
          className={`edison-tabs ${wideHeaders ? "edison-tabs-wide" : ""}`}
          value={value}
          onChange={handleChange}
          variant={scrollable ? "scrollable" : "standard"}
          scrollButtons={scrollable}
          TabIndicatorProps={{ style: { background: indicatorColour } }}
        >
          {tabs.map(tab => (
            <Tab
              data-testid={"edison-tabs-tab"}
              className="edison-tabs-tab"
              key={tab.id}
              value={tab.id}
              label={
                typeof tab.label === "string" || tab.label instanceof String ? (
                  <EdisonTypography title={tab.label as string} variant="data" enableUppercase />
                ) : (
                  tab.label
                )
              }
              sx={{ textTransform: "none" }}
              {...TabProps(tab.id)}
              title={
                typeof tab.label === "string" || tab.label instanceof String
                  ? (tab.label as string).toLowerCase()
                  : ""
              }
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map(tab => (
        <TabPanel styles={tabPanelStyles} key={tab.id} id={tab.id} value={value}>
          {tab.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default EdisonTabs;
export { EdisonTabs };
