import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../config/authConfig";

import { store } from "store/store";
import { onOpen } from "store/slices/firewallSlice";

export async function execute<T>(
  instance: IPublicClientApplication,
  httpRequest: any,
  dataType: string,
  data?: any,
  id?: number | null,
  scopes?: any,
  headers?: Headers
): Promise<T> {
  const currentAccount = instance.getActiveAccount();

  const requestedScope = scopes ?? loginRequest;
  const request = {
    ...requestedScope,
    account: currentAccount
  };

  return new Promise((resolve, reject) => {
    instance
      .acquireTokenSilent(request)
      .then(async (response: AuthenticationResult) => {
        if (response === null) {
          await instance.acquireTokenRedirect(request);
        }
        resolve(call(httpRequest, response.accessToken, dataType, data, id, headers));
      })
      .catch(async () => {
        await instance.acquireTokenRedirect(request).catch(error => {
          reject(new Error(error));
        });
      });
  });
}

function call(
  httpRequest: any,
  accessToken: string,
  dataType: string,
  data?: any,
  id?: any,
  headers?: Headers
) {
  if (!!data && !!id) {
    return httpRequest(accessToken, dataType, data, id, headers).then((response: any) =>
      getCallResponse(response)
    );
  }
  if (data) {
    return httpRequest(accessToken, dataType, data, headers).then((response: any) =>
      getCallResponse(response)
    );
  }
  if (id) {
    return httpRequest(accessToken, dataType, id, headers).then((response: any) =>
      getCallResponse(response)
    );
  }
  return httpRequest(accessToken, dataType, headers).then((response: any) =>
    getCallResponse(response)
  );
}

const getCallResponse = (response: any) => {
  if (
    response.status === 403 &&
    response.detail ===
      "Access has been blocked by the edison365 firewall, please contact support if needed."
  ) {
    store.dispatch(onOpen());
    return response;
  } else return response;
};
