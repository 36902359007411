import {
  CardConfigurationField,
  addNewRow,
  CardField,
  COMPONENT,
  sortRowsAndSetPath,
  removeFromRow,
  ROW,
  SIDEBAR_ITEM,
  Field
} from "enada-common";
import { Stack } from "@mui/material";
import { nanoid } from "nanoid";
import React, { FC } from "react";
import DropZone from "../../formdesigner/DropZone";
import UserComponent from "./UserComponent";
import "./cardlivepreviewrow.scss";

export interface CardLivePreviewRowProps {
  row: CardField;
  updateRow: React.Dispatch<React.SetStateAction<CardField[]>>;
  rows: CardField[];
  allFieldsData: Field[];
}
const CardLivePreviewRow: FC<CardLivePreviewRowProps> = ({
  row,
  updateRow,
  rows,
  allFieldsData
}) => {
  const handleDrop = (data: any, value: any) => {
    if (value.type == SIDEBAR_ITEM) {
      // dropping from sidebar
      updateRow(prev => {
        const updatedRows: CardField[] = [];
        prev.forEach((map: CardField) => {
          updatedRows.push(map);
          if (map.configuration.path === row.configuration.path) {
            const childId = nanoid();
            const configurationObj: CardConfigurationField = {
              id: map.configuration.id,
              title: map.configuration.title,
              type: ROW,
              path: map.configuration.path,
              item_id: childId,
              item_title: childId,
              item_type: COMPONENT,
              item_path: `${map.configuration.path}-1`
            };
            const rowObj: CardField = {
              configuration: configurationObj,
              fieldId: value.component.id
            };
            updatedRows.push(rowObj);
          }
        });
        return updatedRows;
      });
    } else {
      if (value.children) {
        //dropping from within card
        const removed = removeFromRow(rows, value.children.configuration.item_path);
        const itemToAdd = { fieldId: value.children.fieldId, id: value.id, type: value.type };
        const allRows: CardField[] = [];
        removed.forEach((map: CardField) => {
          allRows.push(map);
          if (map.configuration.path === row.configuration.path) {
            allRows.push(
              addNewRow(itemToAdd, parseInt(map.configuration.path), 1, map.configuration.id)
            );
          }
        });
        const updated = sortRowsAndSetPath(allRows);
        updateRow(updated);
      }
    }
  };

  const canDrop = (item: any, monitor: any) => {
    return true;
  };

  const removeComponent = (childPath: string) => {
    const updated = removeFromRow(rows, childPath);
    updateRow(sortRowsAndSetPath(updated));
  };
  const rowsElements = rows.filter(el => el.configuration.path == row.configuration.path);
  return (
    <Stack direction={"row"} className={"card-live-preview-row-root"}>
      {rowsElements.length > 0 &&
        allFieldsData.length > 0 &&
        (rowsElements.length === 1 && rowsElements[0].configuration.item_path ? (
          <Stack direction="row" className="component-dropzone-container">
            <UserComponent
              data={rowsElements[0]}
              fieldData={
                allFieldsData.filter(field => field.id === rowsElements[0]?.fieldId)?.[0] || []
              }
              readOnly={true}
              removeComponent={() => {
                rowsElements[0].configuration.item_path &&
                  removeComponent(rowsElements[0].configuration.item_path);
              }}
            />
            <DropZone
              data={rows}
              onDrop={handleDrop}
              checkDrop={canDrop}
              className="dropzone-visible"
            />
          </Stack>
        ) : (
          <Stack direction="row" spacing={1} sx={{ minWidth: 0 }}>
            {rowsElements.map(
              (child: CardField, index: number) =>
                child.configuration.item_path && (
                  <UserComponent
                    key={index}
                    data={child}
                    fieldData={
                      allFieldsData.filter(field => field.id === child?.fieldId)?.[0] || []
                    }
                    readOnly={true}
                    removeComponent={() =>
                      child.configuration.item_path &&
                      removeComponent(child.configuration.item_path)
                    }
                  />
                )
            )}
          </Stack>
        ))}
      {/* {row.children && (
        <CancelOutlinedIcon
          className="cancel-icon"
          fontSize="small"
          onClick={() => {
            const index = parseInt(row.path as string);
            const afterIndex = rows.slice(index + 1);
            const updated = [
              ...rows.slice(0, index),
              ...incrementRowsPath(afterIndex, -1),
            ];
            updateRow(updated);
          }}
        />
      )} */}
    </Stack>
  );
};

export default CardLivePreviewRow;
