import { FilterType, FilterPropertyType } from "../models/frontend/shared/FilterOperation";

export function getDefaultFilterTypeDisplayName(filterType: FilterType): string {
  switch (filterType) {
    case "eq":
      return "equals (=)";
    case "ne":
      return "not equals to (!=)";
    case "gt":
      return "greater than (>)";
    case "ge":
      return "greater than or equals (>=)";
    case "lt":
      return "less than (<)";
    case "le":
      return "less than or equals (<=)";
    case "in":
      return "in";
    case "contains":
      return "contains";
  }
}

export function getFilterTypes(propertyType: FilterPropertyType): FilterType[] {
  switch (propertyType) {
    case FilterPropertyType.String:
      return ["eq", "ne", "contains"];
    case FilterPropertyType.Number:
    case FilterPropertyType.Date:
      return ["eq", "ne", "gt", "ge", "lt", "le"];
    case FilterPropertyType.MultiChoice:
    case FilterPropertyType.PeoplePicker:
    case FilterPropertyType.ResourcePicker:
      return ["in"];
    default:
      return ["eq", "ne"];
  }
}
