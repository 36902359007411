import { IconButton, Stack } from "@mui/material";
import React, { FC, useRef } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { fieldConfiguration, COMPONENT, Field } from "enada-common";

import "./usercomponent.scss";
import ComponentMapper from "../../../utils/componentMapper";
import UserComponentMenu from "../../formdesigner/UserComponentMenu";
export interface UserComponentProps {
  data: any;
  removeComponent?: any;
  readOnly: boolean;
  fieldData: Field;
}

const UserComponent: FC<UserComponentProps> = ({ data, removeComponent, readOnly, fieldData }) => {
  const ref = useRef(null);
  const { t } = useTranslation(["common"]);
  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, data: data.path, children: data },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const componentIcon = fieldConfiguration(fieldData?.dataType).icon;

  return (
    <Stack
      ref={ref}
      sx={{
        opacity,
        bgcolor: "background.default",
        padding: "0px 5px 10px 10px",
        borderRadius: "8px",

        width: "100%",
        overflowX: "auto",
        minWidth: "50%"
      }}
      className="usercomponent draggable"
      direction={"row"}
      spacing={1}
    >
      <Stack direction="row" spacing={1}>
        <UserComponentMenu
          removeObject={{
            name: t("remove"),
            color: "error",
            icon: <DeleteOutlineOutlinedIcon fontSize="small" color="error" />,
            function: () => removeComponent(data.path, data.id)
          }}
        />
        <Stack direction="column" justifyContent="center" alignItems="center">
          <IconButton
            sx={{
              padding: "5px",
              margin: "0px",
              cursor: "inherit",
              "&:hover": { backgroundColor: "transparent" }
            }}
          >
            {componentIcon as React.ReactElement}
          </IconButton>
        </Stack>
      </Stack>
      <ComponentMapper
        readOnly={true}
        isIconOn={false}
        component={fieldData}
        isInTable={false}
        useInternalState={true}
        useReadOnlyWrapper={true}
        t={t}
      />
    </Stack>
  );
};

export default UserComponent;
