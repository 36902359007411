/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseUserFieldProps } from "enada-common";
import { FC, useEffect, useRef } from "react";

const center = { lat: 51.50088895238906, lng: -0.12196540832519531 };
const options: google.maps.MapOptions = { center, zoom: 8, mapId: process.env.NX_PUBLIC_MAPS_KEY };

const GoogleMap: FC<Partial<BaseUserFieldProps>> = ({ value, onChange, useInternalState }) => {
  const autoCompleteService = useRef(new google.maps.places.AutocompleteService());
  const geoCoder = useRef(new google.maps.Geocoder());

  const ref = useRef<HTMLDivElement | null>(null);
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null | undefined>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  useEffect(() => {
    mapRef.current = new google.maps.Map(ref.current as HTMLDivElement, options);
    markerRef.current = new google.maps.marker.AdvancedMarkerElement({
      position: options.center,
      map: mapRef.current as google.maps.Map
    });
    placesService.current = new google.maps.places.PlacesService(mapRef.current as google.maps.Map);
    const clickListener = google.maps.event.addListener(
      mapRef.current,
      "click",
      async (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
        event.stop();
        if (markerRef.current) markerRef.current.position = event.latLng;
        if (isIconMouseEvent(event)) {
          placesService.current?.getDetails({ placeId: event.placeId as string }, result => {
            handleChange(result?.name ?? "home", event.latLng as google.maps.LatLng);
          });
        } else {
          const geoCodeResult = await geoCoder.current.geocode({
            location: event.latLng
          });
          handleChange(
            geoCodeResult.results[0].formatted_address,
            event.latLng as google.maps.LatLng
          );
        }
      }
    );
    return () => {
      google.maps.event.removeListener(clickListener);
    };
  }, []);

  useEffect(() => {
    if (!value) return;
    const updateMap = async () => {
      const request = {
        placeId: (value as google.maps.places.AutocompletePrediction).place_id
      };
      const result = await geoCoder.current.geocode(request);
      const geometry = result.results[0].geometry;
      const newCenter = {
        lat: geometry.location.lat(),
        lng: geometry.location.lng()
      };

      mapRef.current?.setCenter(newCenter);
      mapRef.current?.setZoom(14);
      if (markerRef.current) markerRef.current.position = newCenter;
    };
    updateMap();
  }, [value]);

  const handleChange = async (search: string, location: google.maps.LatLng) => {
    // Define the offset for the square area
    const offset = 0.1;

    // Calculate the northeast and southwest corners of the square
    const northeast = new google.maps.LatLng(location.lat() + offset, location.lng() + offset);
    const southwest = new google.maps.LatLng(location.lat() - offset, location.lng() - offset);

    // Create a LatLngBounds object representing the square area
    const bounds = new google.maps.LatLngBounds(southwest, northeast);

    const placePredictions = await autoCompleteService.current.getPlacePredictions({
      input: search,
      locationBias: location,
      locationRestriction: bounds
    });
    if (!onChange) return;
    if (useInternalState) return;
    onChange(placePredictions.predictions[0]);
  };
  return <div style={{ height: "400px", width: "100%" }} ref={ref} />;
};
const isIconMouseEvent = (
  e: google.maps.MapMouseEvent | google.maps.IconMouseEvent
): e is google.maps.IconMouseEvent => {
  return "placeId" in e;
};
export default GoogleMap;
