import {
  Workflow,
  WorkflowCondition,
  WorkflowNodeConfiguration,
  WorkflowStageEditAccess,
  WorkflowStep
} from "enada-common";
import { nanoid } from "@reduxjs/toolkit";
import { addEdge, Edge, Node } from "reactflow";
import { defaultWorkflowConnection } from "../FrontendWorkflow.constants";
import { WorkflowConditionConfiguration, WorkFlowNode } from "../FrontendWorkflow.models";
import { getNodeStyles } from "../getNewNode";

const parseToFrontend = (workflow: Workflow): { nodes: Node[]; edges: Edge[] } => {
  const nodes: Node[] = [];
  let edges: Edge[] = [];

  const mappedStages: Partial<WorkFlowNode>[] =
    workflow.stages?.map(stage => ({
      name: stage.name,
      displayName: stage.displayName ?? "",
      id: stage.id?.toString()
    })) ?? [];
  const mappedConditions: Partial<WorkFlowNode>[] =
    workflow.conditions?.map(condition => ({
      name: condition.name,
      displayName: condition.displayName ?? "",
      id: condition.id?.toString()
    })) ?? [];
  const workflowNodes = mappedStages.concat(mappedConditions);
  workflow.stages?.forEach(stage => {
    const config = stage.configuration as WorkflowNodeConfiguration;
    const node: Node = {
      id: stage.id ? stage.id.toString() : "",
      type: config.type,
      position: config.position,
      data: {
        ...stage,
        editAccess: getNumericalEditAccess(stage.editAccess?.toString())
      },
      style: getNodeStyles()
    };
    nodes.push(node);
  });

  workflow.conditions?.forEach((condition: WorkflowCondition) => {
    const config = condition.configuration as WorkflowConditionConfiguration;
    const node: Node = {
      id: condition.id ? condition.id.toString() : "",
      type: "Condition",
      position: config.position,
      data: {
        ...condition
      },
      style: getNodeStyles()
    };
    nodes.push(node);
  });

  workflow.steps?.forEach((step: WorkflowStep) => {
    step.nextSteps?.forEach(nextStep => {
      if (nextStep.isConditionResult1) {
        const edge = getEdge(
          { ...defaultWorkflowConnection, label: "true" },
          workflowNodes as WorkFlowNode[],
          step.name,
          nextStep.stepName ?? ""
        );
        edges = addEdge(edge, edges);
        return;
      }
      if (nextStep.isConditionResult2) {
        const edge = getEdge(
          { ...defaultWorkflowConnection, label: "false" },
          workflowNodes as WorkFlowNode[],
          step.name,
          nextStep.stepName ?? ""
        );
        edges = addEdge(edge, edges);
        return;
      }
      const edge = getEdge(
        { ...defaultWorkflowConnection },
        workflowNodes as WorkFlowNode[],
        step.name,
        nextStep.stepName ?? ""
      );
      edges = addEdge(edge, edges);
    });
  });
  return { nodes, edges };
};

const getEdge = (
  baseEdge: Partial<Edge>,
  workflowNodes: WorkFlowNode[],
  sourceName: string,
  targetName: string
): Edge => {
  const sourceId = workflowNodes?.find(stage => stage.name === sourceName)?.id;
  const targetId = workflowNodes?.find(stage => stage.name === targetName)?.id;
  return {
    ...baseEdge,
    source: sourceId?.toString() as string,
    target: targetId?.toString() as string,
    id: nanoid()
  };
};
const getNumericalEditAccess = (stringArray?: string) => {
  if (stringArray === undefined) return WorkflowStageEditAccess.None;
  const values = stringArray.split(",");
  const numericalValue = values.reduce((acc, stringValue) => {
    return (acc + WorkflowStageEditAccess[stringValue.trim() as any]) as any;
  }, 0);
  return numericalValue;
};
export default parseToFrontend;
