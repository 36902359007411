import { CollapsibleListProps, ListSection, ListSectionItem } from "enada-common";
import { FC, useEffect, useState } from "react";
import EdisonExpandableMenu from "../expandablemenu/EdisonExpandableMenu";
import Searchbar from "../searchbar/Searchbar";
import "./collapsibleList.scss";

const CollapsibleList: FC<CollapsibleListProps> = ({ data, onItemClick, showSearch }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    //TODO: Make this set the state directly
    const fData: ListSection[] = [];
    data.forEach((d: ListSection) => {
      const filteredChildren = d.data?.filter((c: ListSectionItem) => {
        return c.label.toLowerCase().includes(searchFilter.toLowerCase());
      });
      if (filteredChildren && filteredChildren.length > 0) {
        fData.push({ ...d, data: filteredChildren });
      }
    });
    setFilteredData(fData);
  }, [data, searchFilter]);

  return (
    <div className="collapsible-list-root" data-testid={"edison-collapsible-list"}>
      {showSearch && (
        <div className="searchbar-container">
          <Searchbar
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            placeholder={""}
          />
        </div>
      )}
      {filteredData.map((section: ListSection) => (
        <EdisonExpandableMenu
          sticky={false}
          key={section.name}
          {...section}
          onItemClick={onItemClick}
        />
      ))}
    </div>
  );
};

export default CollapsibleList;
export { CollapsibleList };
