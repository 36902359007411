import { Calendar } from "enada-common";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getCalendars } from "services/APIService";

const defaultCalendar: Calendar = {
  id: 0,
  name: "",
  displayName: ""
};

export interface calendarsState {
  value: number;
  calendars: Array<Calendar>;
  individualCalendar: Calendar | Partial<Calendar>;
  status: "idle" | "loading" | "failed";
  lastModifiedUpdated: boolean;
}

const initialState: calendarsState = {
  value: 0,
  calendars: [],
  status: "idle",
  individualCalendar: defaultCalendar,
  lastModifiedUpdated: false
};

// TODO: remove when we refactor record slice and page
export const getCalendarsAsync: any = createAsyncThunk(
  "calendars/getCalendars",
  async (_, { rejectWithValue }) => {
    const response: any = await getCalendars();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);

export const calendarsSlice = createSlice({
  name: "calendars",
  initialState,

  reducers: {
    clearIndividualCalendar: state => {
      state.individualCalendar = defaultCalendar;
    },

    updateIndividualCalendar: (state, action) => {
      state.individualCalendar = action.payload;
    },
    updateIndividualCalendarProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Calendar }>
    ) => {
      state.individualCalendar = {
        ...state.individualCalendar,
        [action.payload.key]: action.payload.value
      };
    },
    setRecordVersionCalendars: (state, action: PayloadAction<Calendar[]>) => {
      if (!action.payload) {
        state.calendars = [];
        return;
      }
      state.calendars = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCalendarsAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(getCalendarsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getCalendarsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) {
          state.calendars = [];
          return;
        }
        state.calendars = action.payload;
      });
  }
});

const inputSelectCalendars = (state: RootState) => state.calendars;

export const selectAllCalendars = createSelector(
  [inputSelectCalendars],
  (calendar): Calendar[] => calendar.calendars
);

export const selectIndividualCalendar = createSelector(
  [inputSelectCalendars],
  calendar => calendar.individualCalendar
);

export const selectCalendarStatus = createSelector(
  [inputSelectCalendars],
  calendar => calendar.status
);

export const {
  clearIndividualCalendar,
  updateIndividualCalendar,
  updateIndividualCalendarProperty,
  setRecordVersionCalendars
} = calendarsSlice.actions;

export default calendarsSlice.reducer;
