import { Expression } from "./Workflow.model";

import { Category } from "./Category.model";
export interface Field {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  name: string;
  displayName?: string | null;
  dataType: FieldDataType;
  min?: number | null;
  max?: number | null;
  regex?: string | null;
  description?: string | null;
  isDeleted?: boolean;
  showEditableOnPersonalPage?: boolean;
  fieldDependencies?: FieldDependency[] | null;
  currency?: string | null;
  dataStructure?: unknown;
  configuration?: { [key: string]: unknown } | null;
  dataFormat?: FieldDataFormat;
  calculatedExpression?: ConditionalExpression;
  expression?: Expression;
  systemFieldType?: string;
  requiredIn?: RequiredInType;
  appliesTo?: RequiredInType;
  category?: Category;
}

export interface FieldDependency {
  id?: number;
  fieldId?: number;
  parentFieldId?: number;
}
export interface ConditionalExpression {
  conditionExpression?: Expression;
  successExpression?: Expression;
  failureExpression?: Expression;
  successConditionalExpression?: ConditionalExpression;
  failureConditionalExpression?: ConditionalExpression;
}

export enum RequiredInType {
  None = "None",
  Projects = "Projects",
  Ideas = "Ideas",
  BusinessCase = "BusinessCase",
  TaskTable = "TaskTable",
  StandardTable = "StandardTable",
  PeriodicTable = "PeriodicTable",
  AllRecords = "AllRecords",
  AllTables = "AllTables",
  All = "All"
}

export enum FieldDataType {
  Calculated = "Calculated",
  Currency = "Currency",
  Date = "Date",
  DateTime = "DateTime",
  Email = "Email",
  // Image = "Image",
  Location = "Location",
  MultiChoice = "MultiChoice",
  MultiLevelChoice = "MultiLevelChoice",
  MultiMultiLevelChoice = "MultiMultiLevelChoice",
  MultiPeople = "MultiPeople",
  Number = "Number",
  People = "People",
  Percentage = "Percentage",
  Phone = "Phone",
  Choice = "Choice",
  RichText = "RichText",
  TextBox = "TextBox",
  Url = "Url",
  Switch = "Switch",
  Button = "Button"
}

export enum FieldDataFormat {
  None = "None",
  WBS_01 = "WBS_01"
}
