import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getAIEnabledOnInstance } from "../../services/APIService";

// Define the initial state
export interface TenantState {
  aiEnabled: boolean;
}

const initialState: TenantState = {
  aiEnabled: false
};

//create a thunk to get ai enabled from getAIEnabledOnInstance
export const getAIEnabledAsync = createAsyncThunk(
  "tenant/getAIEnabled",
  async (_, { rejectWithValue }) => {
    const response: any = await getAIEnabledOnInstance();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAIEnabledAsync.fulfilled, (state, action) => {
      state.aiEnabled = action.payload;
    });
  }
});

export const selectAiEnabled = createSelector(
  (state: { tenant: TenantState }) => state.tenant,
  tenant => tenant.aiEnabled
);
export default tenantSlice.reducer;
