import React, { FC, useState } from "react";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const EdiInstructionalTextPrompt: FC = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation(["common"]);

  return (
    <Alert
      data-testid="edi-instructionaltext-prompt"
      icon={false}
      action={
        <IconButton
          aria-label="toggle"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <ArrowDropDownOutlined />
        </IconButton>
      }
    >
      <AlertTitle>{t("ediGenerateRows.instructionalText.title")}</AlertTitle>
      <Collapse in={open}>{t("ediGenerateRows.instructionalText.body")}</Collapse>
    </Alert>
  );
};

export default EdiInstructionalTextPrompt;
