import React from "react";
import { RoleType } from "enada-common";
import useHasRole from "../../utils/hooks/useHasRole";

const HasRoles = ({
  children,
  roles,
  noMatchElement = <></>
}: {
  children: JSX.Element;
  roles: RoleType[];
  noMatchElement?: JSX.Element;
}) => {
  const { hasRole } = useHasRole();
  if (!hasRole(roles)) {
    return noMatchElement;
  }
  return children;
};

export default HasRoles;
