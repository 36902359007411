import {
  RecordType,
  sanitiseInternalFieldName,
  Workflow,
  WorkflowCondition,
  WorkflowStage,
  WorkflowStageConditionType,
  WorkflowStageViewState,
  WorkflowStep,
  WorkflowStepType
} from "enada-common";
import { nanoid } from "@reduxjs/toolkit";
import { Node, Edge, getIncomers, getOutgoers } from "reactflow";
import {
  WorkflowConditionConfiguration,
  WorkFlowNode,
  workflowOrientation
} from "../FrontendWorkflow.models";

export type WorkflowErrorType =
  | "workflowName"
  | "nodeName"
  | "stageVisibility"
  | "stageCard"
  | "stageForm"
  | "stageApprovers"
  | "stageApproversCount"
  | "stageItems"
  | "stageEditors"
  | "maxReviewsCount"
  | "requiredApproversCount";

export interface WorkflowError {
  nodeName?: string;
  property?: WorkflowErrorType;
  error: string;
  displayName?: string;
  options?: { [key: string]: string };
}
const parseToBackend = (
  nodes: Node[],
  edges: Edge[],
  partialWorkflow: Workflow,
  isEdit: boolean,
  orientation: workflowOrientation
): Workflow | WorkflowError[] => {
  const steps: WorkflowStep[] = [];
  const stages: WorkflowStage[] = [];
  const conditions: WorkflowCondition[] = [];
  let errors: WorkflowError[] = [];
  if (partialWorkflow.name === "") {
    errors.push({
      property: "workflowName",
      error: "emptyWorkflowNameErrorMessage"
    });
  } else if (sanitiseInternalFieldName(partialWorkflow.name) === "") {
    errors.push({
      property: "workflowName",
      error: "invalidWorkflowNameErrorMessage"
    });
  }

  const root = nodes.find(node => getIncomers(node, nodes, edges).length === 0);
  if (!root || nodes.filter(node => getIncomers(node, nodes, edges).length === 0).length !== 1) {
    errors.push({ error: "rootNodeErrorMessage" });
  }
  if (root?.type !== "Stage") {
    errors.push({ error: "rootNodeTypeErrorMessage" });
  }
  const endNodes = nodes.filter(node => getOutgoers(node, nodes, edges).length === 0);
  if (endNodes.find(node => node.type !== "Stage")) {
    errors.push({ error: "endNodeTypeErrorMessage" });
  }

  nodes.forEach((node: Node, index: number) => {
    if (node.data.configuration.type === "Condition") {
      if (
        partialWorkflow.type === RecordType.Ideas ||
        partialWorkflow.type === RecordType.Challenges
      ) {
        errors.push({
          error: "contionNotAllowedForWorkflowType",
          options: {
            workflowType: partialWorkflow.type
          }
        });
        return;
      }

      // Node type => condition
      const condition = getConditionData(node, partialWorkflow, edges);

      const step = getStep(
        partialWorkflow,
        edges,
        node,
        nodes,
        isEdit,
        WorkflowStepType.ConditionIf
      );
      conditions.push(condition);
      steps.push(step);
    } else {
      // Node type => stage or stage review
      const stageName = getNodeName(partialWorkflow, isEdit, node);
      const stageSteps = getStep(
        partialWorkflow,
        edges,
        node,
        nodes,
        isEdit,
        node.data.configuration.type
      );
      steps.push(stageSteps);

      const workflowStage = partialWorkflow.stages?.find(stage => stage.id?.toString() === node.id);
      const parsedStage = getStageData(node, stageName);
      if (Array.isArray(parsedStage)) {
        errors = [...errors, ...parsedStage];
      } else {
        stages.push({
          ...parsedStage,
          id: isEdit ? workflowStage?.id : undefined
        });
      }
    }
  });
  if (errors.length !== 0) return errors;
  return {
    ...partialWorkflow,
    conditions: conditions,
    stages: stages,
    steps: steps,
    configuration: { ...partialWorkflow.configuration, orientation }
  };
};

const getStep = (
  workflow: Workflow,
  edges: Edge[],
  node: Node,
  nodes: Node[],
  isEdit: boolean,
  stepType: WorkflowStepType
): WorkflowStep => {
  const nodeName = getNodeName(workflow, isEdit, node);
  const step: WorkflowStep = {
    name: nodeName,
    displayName: node.data.displayName,
    type: stepType
  };
  const connection = edges.find((edge: Edge) => edge.source === node.id);
  const nextConnections = edges.filter(edge => edge.source === node.id);
  const previousConnections = edges.filter(edge => edge.target === node.id);
  return {
    ...step,
    nextSteps:
      nextConnections.length > 0 // This check is only needed temporarily while backend fix bug
        ? nextConnections.map((connection, index) => ({
            stepName: getNodeName(
              workflow,
              isEdit,
              nodes.find(node => node.id === connection.target)
            ),
            isConditionResult1: stepType === WorkflowStepType.ConditionIf && index === 0,
            isConditionResult2: stepType === WorkflowStepType.ConditionIf && index === 1
          }))
        : undefined,
    previousSteps:
      previousConnections.length > 0 // This check is only needed temporarily while backend fix bug
        ? previousConnections.map(connection => ({
            stepName: getNodeName(
              workflow,
              isEdit,
              nodes.find(node => node.id === connection.source)
            )
          }))
        : undefined,
    configuration: {
      sourceHandle: connection?.sourceHandle,
      targetHandle: connection?.targetHandle
    }
  };
};

const getConditionData = (node: Node, workflow: Workflow, edges: Edge[]): WorkflowCondition => {
  // TODO: validation to make sure a condition has two edges.
  const condition: WorkflowCondition = {
    name: getNodeName(workflow, false, node),
    displayName: node.data.displayName,
    type: WorkflowStageConditionType.If,
    expression1: node.data.expression1,
    version: 1,
    configuration: getConditionConfiguration(edges, node)
  };
  return condition;
};

const getConditionConfiguration = (edges: Edge[], node: Node): WorkflowConditionConfiguration => {
  const connections = edges.filter((edge: Edge) => edge.source === node.id);

  if (connections) {
    return {
      ...node.data.configuration,
      position: node.position
    };
  }
  //  throw "Condition valid connections not found"; /// TODO deal with validation
  throw "noConditionConnectionErrorMessage";
};

const getNodeName = (workflow: Workflow, isEdit: boolean, node?: Node): string => {
  if (node === undefined) {
    throw "undefined node found";
  }
  if (isEdit) {
    const mappedStages: Partial<WorkFlowNode>[] =
      workflow.stages?.map(stage => ({
        name: stage.name,
        displayName: stage.displayName ?? "",
        id: stage.id?.toString()
      })) ?? [];
    const mappedConditions: Partial<WorkFlowNode>[] =
      workflow.conditions?.map(condition => ({
        name: condition.name,
        displayName: condition.displayName ?? "",
        id: condition.id?.toString()
      })) ?? [];

    const workflowNodes = mappedStages.concat(mappedConditions);
    const workflowNode = workflowNodes.find(stage => stage.id?.toString() === node.id);
    return workflowNode?.name ? workflowNode.name : node.data.name;
  } else {
    return node.data.name;
  }
};
const getStageData = (node: Node, stageName: string): WorkflowStage | WorkflowError[] => {
  let errors: WorkflowError[] = [];
  if (isNaN(node.data.formId))
    errors.push({
      property: "stageForm",
      nodeName: stageName,
      error: "missingFormError"
    });
  if (isNaN(node.data.cardId))
    errors.push({
      property: "stageCard",
      nodeName: stageName,
      error: "missingCardError"
    });
  const baseStage = {
    name: stageName,
    configuration: {
      ...(node.data as WorkflowStage).configuration,
      position: node.position
    },
    formId: (node.data as WorkflowStage).formId,
    displayName: node.data.displayName,
    views: node.data.views ?? [
      {
        name: nanoid(),
        viewTables: [],
        viewFields: []
      }
    ],
    events: node.data.events ?? [],
    viewState: node.data.viewState ?? WorkflowStageViewState.Visible,
    readOnly: node.data.readOnly,
    editAccess: node.data.editAccess,
    targetDuration: node.data.targetDuration,
    cardId: node.data.cardId,
    allowReadOnlyOverride: node.data.allowReadOnlyOverride,
    description: node.data.description,
    allowRecordCreation: node.data.allowRecordCreation,
    allowProjectCreation: node.data.allowProjectCreation,
    allowBusinessCaseCreation: node.data.allowBusinessCaseCreation,
    editors: node.data.editors
      ? (node.data.editors as any[]).map(editor => ({
          entityId: editor.entityId as string,
          permissionType: editor.permissionType
        }))
      : []
  };
  if (node.type === "StageReview") {
    errors = [...errors, ...getReviewStageErrors(stageName, node)];
  }
  if (errors.length !== 0) return errors;

  return node.type === "StageReview"
    ? {
        ...(baseStage as any),
        hasReview: true,
        tasks: node.data.tasks,
        reviewers: node.data.reviewers
          ? (node.data.reviewers as any[]).map(reviewer => ({
              entityId: reviewer.entityId as string,
              permissionType: reviewer.permissionType
            }))
          : [],

        requiredApproversCount: Number(node.data.requiredApproversCount),
        maxReviewersCount: Number(node.data.maxReviewersCount)
      }
    : baseStage;
};

const getReviewStageErrors = (stageName: string, node: Node) => {
  const errors: WorkflowError[] = [];
  if (!node.data.reviewers || node.data.reviewers.length === 0) {
    errors.push({
      property: "stageApproversCount",
      error: "reviewStageReviewersError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  if (node.data.requiredApproversCount === undefined) {
    errors.push({
      property: "requiredApproversCount",
      error: "requiredApprovalsError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  if (node.data.maxReviewersCount === undefined) {
    errors.push({
      property: "maxReviewsCount",
      error: "maxReviewsCountError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  const reviewersCount = node.data.configuration.reviewersCount ?? 0;

  if (reviewersCount < Number(node.data.maxReviewersCount)) {
    errors.push({
      property: "stageApproversCount",
      error: "reviewersLessThanMaxReviewsError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  if (reviewersCount < Number(node.data.requiredApproversCount)) {
    errors.push({
      property: "stageApproversCount",
      error: "reviewersLessThanRequiredReviewsError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  if (node.data.requiredApproversCount > Number(node.data.maxReviewersCount)) {
    errors.push({
      property: "requiredApproversCount",
      error: "requiredApprovalsLessThanMaxReviewsError",
      displayName: node.data.displayName,
      nodeName: stageName
    });
  }
  return errors;
};

export default parseToBackend;
