import { ExpressionDataType, getEnumValues } from "enada-common";
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectCalculatedResultType,
  setCalculatedResultType
} from "../../../store/slices/calculatedFieldSlice";
import { EdisonTypography } from "enada-components";
import "./calculatedconfig.scss";
import ExpressionBuilder from "./expressionbuilder/ExpressionBuilder";

const CalculatedConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const resultType = useAppSelector(selectCalculatedResultType);

  return (
    <Paper className="calculated-config-root">
      <Stack spacing={2}>
        <ExpressionBuilder allowConditions={true} />
        <Stack direction="row" className="format-container" spacing={2}>
          <EdisonTypography title={t("returnedDataFormat")} variant="h3" />
          <FormControl>
            <RadioGroup
              row
              value={resultType}
              onChange={(_, value) => {
                dispatch(setCalculatedResultType(value as ExpressionDataType));
              }}
            >
              {getEnumValues(ExpressionDataType).map(type => (
                <FormControlLabel key={type} control={<Radio />} value={type} label={t(type)} />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CalculatedConfig;
