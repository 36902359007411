import {
  Column,
  ColumnField,
  ColumnTable,
  WorkflowStageView,
  WorkflowStageViewFieldState
} from "enada-common";
import { Box, Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { selectWorkflowStage } from "../../../store/slices/recordSlice";
import ProjectField from "./projectfield/ProjectField";
import ProjectTable from "./projecttable/ProjectTable";
import { sortFormElements } from "../../../store/slices/parseFormData";

export interface ColumnProps {
  column: Column;
}
const FormColumn: FC<ColumnProps> = ({ column }) => {
  const workflowStage = useAppSelector(selectWorkflowStage);
  const [orderedItems, setOrderedItems] = useState<(ColumnField | ColumnTable)[] | null>(null);
  const stageView =
    workflowStage?.views && workflowStage?.views?.length !== 0
      ? (workflowStage?.views as WorkflowStageView[])[0] // TODO : review this when we can add multiple views to the a workflow
      : null;
  const removeHiddenFields = (columnFields?: ColumnField[] | null) => {
    if (!stageView?.viewFields) return columnFields;
    return columnFields?.filter(
      columnField =>
        !stageView.viewFields?.some(
          viewField =>
            viewField.fieldId === columnField.fieldId &&
            viewField.state === WorkflowStageViewFieldState.Hidden
        )
    );
  };
  const removeHiddenTables = (columnTables?: ColumnTable[] | null) => {
    if (!stageView?.viewTables) return columnTables;
    return columnTables?.filter(
      columnField =>
        !stageView.viewTables?.some(
          tableField =>
            tableField.tableId === columnField.tableId &&
            tableField.state === WorkflowStageViewFieldState.Hidden
        )
    );
  };
  useEffect(() => {
    const fields = removeHiddenFields(column.fields);
    const tables = removeHiddenTables(column.tables);
    //Order vertically by Form designer order fields and tables
    const ordered = sortFormElements([
      ...(fields as ColumnField[] | []),
      ...(tables as ColumnTable[] | [])
    ]);
    setOrderedItems(ordered);
  }, [column]);

  if (orderedItems === null) return <></>;

  return (
    <Stack spacing={1} width={{ width: column.tables?.length === 0 ? "auto" : "100%" }}>
      {orderedItems?.map((columnEl: ColumnField | ColumnTable) =>
        columnEl.hasOwnProperty("fieldId") ? (
          <Box width="100%" key={columnEl.id}>
            <ProjectField columnField={columnEl} />
          </Box>
        ) : (
          <ProjectTable columnTable={columnEl} key={columnEl.id} />
        )
      )}
    </Stack>
  );
};

export default FormColumn;
