import DoneIcon from "@mui/icons-material/Done";
import { Avatar, Button, Divider, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setRefreshRecords } from "../../../../store/slices/recordsSlice";
import { EdisonTypography } from "enada-components";
import "./createrecordcreated.scss";
import { RecordAssociationMapping } from "../../../../types/createRecordModal";

export interface CreatedRecordCreatedProps {
  association?: RecordAssociationMapping;
  type: string;
  openAction: () => void;
  createAnotherAction: () => void;
}
export const CreateRecordCreated: FC<CreatedRecordCreatedProps> = ({
  association,
  type,
  createAnotherAction,
  openAction
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);

  const location = useLocation();
  useEffect(() => {
    //refresh discover records if we are not in admin or resources admin
    if (location.pathname.includes("admin") || location.pathname.includes("resourceadmin")) return;
    dispatch(setRefreshRecords(true));
  }, []);

  return (
    <Stack className="create-record-created-root" spacing={2}>
      <Stack className="created-container" spacing={1}>
        <Avatar sx={{ background: "#eaf2ea" }}>
          <DoneIcon color="success" />
        </Avatar>
        <EdisonTypography title={`${type} ${t("createdLetsTakeALook")}`} variant="h4" />
      </Stack>

      <Divider flexItem />
      {association && (
        <Stack spacing={1} className="copied-values-container">
          <EdisonTypography title={t("copiedValues")} variant="h3" sx={{ fontWeight: "bold" }} />
          {[...(association?.tables ?? []), ...(association?.fields ?? [])].map(value => (
            <Stack key={value.id} direction={"row"} className="copied-value-row">
              <Stack direction="row">
                <DoneIcon color="success" /> {value.displayName}
              </Stack>
              <div> {value.dataType}</div>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack direction="row" className="button-container">
        <Button onClick={createAnotherAction} data-testid="create-record-created-create-another">
          {t("createAnother")}
        </Button>
        <Button onClick={openAction} variant="contained" data-testid="create-record-created-open">
          {t("open")}
        </Button>
      </Stack>
    </Stack>
  );
};
