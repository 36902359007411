import {
  FilterOperation,
  FilterPropertyType,
  FilterSiblingOperation,
  FilterSubOperation,
  OdataOperations,
  RecordTemplate,
  Workflow,
  OrderOperation
} from "enada-common";
import { QueryOptions } from "odata-query";
import { TFunction } from "i18next";

export const getStartAndEndOfMonth = () => {
  const date = new Date();
  const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 2);
  const start = new Date(date.getFullYear(), date.getMonth(), 1, 2);
  return { start, end };
};

export const getStartAndEndOfWeek = () => {
  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  const start = new Date(date.setDate(diff));
  const end = new Date(date.setDate(start.getDate() + 6));
  return { start, end };
};

export const getAdvancedFilters = (
  t: TFunction,
  workflows: Workflow[],
  createdBy: string[],
  templates?: RecordTemplate[]
): FilterOperation[] => {
  const filters: FilterOperation[] = [
    {
      property: "displayName",
      displayName: t("name"),
      propertyType: FilterPropertyType.String
    },
    {
      property: "shortDescription",
      displayName: t("description"),
      propertyType: FilterPropertyType.String
    },
    // {
    //   property: "isDeleted",
    //   displayName: t("deleted"),
    //   propertyType: FilterPropertyType.Bool,
    //   options: [
    //     { displayName: t("yes"), value: true },
    //     { displayName: t("no"), value: false }
    //   ],
    //   value: false
    // },
    {
      property: "percentDone",
      displayName: t("percentageComplete"),
      propertyType: FilterPropertyType.Number
    },
    {
      property: "readOnly",
      displayName: t("readOnly"),
      propertyType: FilterPropertyType.Bool,
      options: [
        { displayName: t("yes"), value: true },
        { displayName: t("no"), value: false }
      ],
      value: false
    },
    // {
    //   property: "workflowStageState",
    //   displayName: t("workflowStageState"),
    //   propertyType: FilterPropertyType.MultiChoice,
    //   options: [
    //     { displayName: t("none"), value: "None" },
    //     { displayName: t("submitted"), value: "Submitted" },
    //     { displayName: t("approved"), value: "Approved" }
    //   ]
    // },
    {
      property: "workflowViewState",
      displayName: t("workflowViewState"),
      propertyType: FilterPropertyType.MultiChoice,
      options: [
        { displayName: t("draft"), value: "Draft" },
        { displayName: t("visible"), value: "Visible" },
        { displayName: t("hidden"), value: "Hidden" }
      ]
    },
    {
      property: "created",
      displayName: t("created"),
      propertyType: FilterPropertyType.Date
    },
    {
      property: "modified",
      displayName: t("modified"),
      propertyType: FilterPropertyType.Date
    },
    {
      property: "startDate",
      displayName: t("startDate"),
      propertyType: FilterPropertyType.Date
    },
    {
      property: "endDate",
      displayName: t("endDate"),
      propertyType: FilterPropertyType.Date
    },
    {
      property: "WorkflowStageHasReview",
      displayName: t("stageType"),
      propertyType: FilterPropertyType.Bool,
      options: [
        { displayName: t("stageReview"), value: true },
        { displayName: t("stage"), value: false }
      ],
      value: false
    }
  ];
  if (createdBy && createdBy.length > 0) {
    filters.push({
      property: "createdBy",
      displayName: t("createdBy"),
      propertyType: FilterPropertyType.PeoplePicker,
      options: createdBy.map((c: string) => {
        return { displayName: "", value: c };
      })
    });
  }
  if (workflows && workflows.length > 0) {
    const sortedWorkflows = [...workflows].sort((a: Workflow, b: Workflow) =>
      (a.displayName ?? "").toLowerCase().localeCompare((b.displayName ?? "").toLowerCase())
    );
    filters.push({
      property: "workflow",
      displayName: t("workflow"),
      propertyType: FilterPropertyType.MultiChoice,
      options: sortedWorkflows.map((w: Workflow) => {
        return { displayName: w.displayName ?? "", value: w.id as number };
      })
    });
  }

  if (templates && templates.length > 0) {
    const sortedTemplates = [...templates].sort((a: RecordTemplate, b: RecordTemplate) =>
      (a.displayName ?? "").toLowerCase().localeCompare((b.displayName ?? "").toLowerCase())
    );
    filters.push({
      property: "recordTemplateId",
      displayName: t("template"),
      propertyType: FilterPropertyType.MultiChoice,
      options: sortedTemplates.map(template => {
        return { displayName: template.displayName ?? "", value: template.id };
      })
    });
  }

  [...filters].sort((a, b) => a.displayName.localeCompare(b.displayName));

  return filters;
};

/// QuickFilterSiblingOps  allow for the creation of quick filters that combine different properties eg: percentDone ge 0 && dueDate le today
/// QuickFilterExtraOps allow for the creation of quick filters that evaluate the same property multiple times eg : percentDone ge 0 && percentDone le 100
const parseFilter = (filter: FilterOperation | FilterSiblingOperation) => {
  let parsed = { [filter.property]: getFilterValue(filter) };

  // add sibling ops to filter
  if ("quickFilterSiblingOps" in filter) {
    if ((filter as FilterOperation).quickFilterSiblingOps) {
      const parsedSiblingOps = filter.quickFilterSiblingOps?.reduce((acc, curr) => {
        return { ...acc, ...parseFilter(curr) };
      }, {});
      parsed = { ...parsed, ...parsedSiblingOps };
    }
  }

  //add extraOps to filter
  if (filter.quickFilterExtraOps) {
    parsed = {
      ...parsed,
      [filter.property]: {
        ...parsed[filter.property],
        ...filter.quickFilterExtraOps.reduce((acc, filter) => {
          return { ...acc, ...getFilterValue(filter) };
        }, {})
      }
    };
  }
  return parsed;
};

export const extractOdata = (odataOperation: OdataOperations) => {
  let filter: any = {};
  const activeFilters = odataOperation.filters
    ?.filter((f: FilterOperation) => f.isActive ?? !f.isQuickFilter)
    .map((f: FilterOperation) => parseFilter(f));

  if (activeFilters && activeFilters.length > 0) {
    // 'Or' used as a default operator as per requirements
    filter = { ...filter, and: activeFilters };
  }
  if (Object.keys(filter).length === 0) return { skip: odataOperation.skip } as QueryOptions<any>;

  return { filter, skip: odataOperation.skip } as QueryOptions<any>;
};

export const toggleOdataOperations = (
  operationId: number,
  ops?: FilterOperation[] | OrderOperation[]
) => {
  return ops?.map(op => {
    return {
      ...op,
      isActive: op.id === operationId ? !op.isActive : false
    };
  });
};

export function compareFilters(filters1: FilterOperation[], filters2: FilterOperation[]): boolean {
  if (filters1.length === filters2.length) {
    let same = true;
    for (let i = 0; i < filters1.length; i++) {
      const f1 = filters1[i];
      const f2 = filters2[i];
      if (
        f1.id !== f2.id ||
        f1.operator !== f2.operator ||
        f1.property !== f2.property ||
        f1.value !== f2.value
      ) {
        same = false;
      }
    }
    return same;
  }
  return false;
}

const getFilterValue = (filter: FilterOperation | FilterSubOperation | FilterSiblingOperation) => {
  if (filter.propertyType === FilterPropertyType.Date) {
    // Create a new value based on the current filter value
    let value = filter.value as Date;

    if (!value) value = new Date();
    if (typeof value === "string") value = new Date(value);

    return {
      [filter.operator ?? "eq"]: {
        type: "raw",
        value: `${value.getUTCFullYear()}-${value.getUTCMonth() + 1}-${value.getUTCDate()}`
      }
    };
  }

  if (
    filter.propertyType === FilterPropertyType.MultiChoice ||
    filter.propertyType === FilterPropertyType.PeoplePicker ||
    filter.propertyType === FilterPropertyType.ResourcePicker
  ) {
    return {
      [filter.operator ?? "in"]: filter.value?.map((v: any) => v.value) ?? []
    };
  }

  if (filter.propertyType === FilterPropertyType.ObjectArray) {
    return {
      any: [
        {
          [(filter as FilterOperation).objectArrayProperty as string]: filter.value
        }
      ]
    };
  }

  return { [filter.operator ?? "eq"]: filter.value };
};
