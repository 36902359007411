import { PermissionType, RecordPermission, RecordRole, TemplatePermission } from "enada-common";
import { batchGetSingleEntitiesById, GraphQueryType } from "../../services/graphService";

export const getGraphPeopleByTypePermission = async (
  projectRole: RecordRole,
  typePermissions: TemplatePermission[] | RecordPermission[] | null | undefined
) => {
  const users = await getPeopleByTypePermission(projectRole, PermissionType.User, typePermissions);
  const groups = await getPeopleByTypePermission(
    projectRole,
    PermissionType.Group,
    typePermissions
  );

  if (users && groups) return [...users, ...groups];
  else if (users) return users;
  else if (groups) return groups;
};

const getPeopleByTypePermission = async (
  projectRole: RecordRole,
  permissionType: PermissionType,
  typePermissions: TemplatePermission[] | RecordPermission[] | null | undefined
) => {
  if (!typePermissions) return;

  const ids = typePermissions
    .filter(
      permission => permission.permissionType === permissionType && permission.role === projectRole
    )
    .map(permission => `${permission.entityId}`);

  const usersList = await batchGetSingleEntitiesById(ids, GraphQueryType.Users);
  return usersList || [];
};
