import { Grid, Paper, Stack } from "@mui/material";
import { dateDifferenceInDays, formatModifiedDate, News, useLocalisation } from "enada-common";
import { EdisonCarousel, EdisonTypography } from "enada-components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./home.scss";

import NewsItemCard from "../../components/carouselCards/NewsItemCard";
import HomeRecordCarousel from "./homerecordcarousel/HomeRecordCarousel";
import { useGetLiveNewsQuery } from "../../services/api";
import { selectInData } from "../../utils/rtkQuery";

const Home = () => {
  const { t } = useTranslation(["common"]);

  const { formatDate } = useLocalisation();

  const selectFormsByRecordType = useMemo(
    () =>
      selectInData<News[]>(data => {
        return data
          ?.map((dataToMap: News) => {
            let expiresIn: string | undefined = "Permanent";
            if (dataToMap.endDate) {
              const dateTime = formatDate(dataToMap.endDate);
              expiresIn = `${dateTime} (${dateDifferenceInDays(new Date(dataToMap.endDate))} days)`;
            }
            return {
              ...dataToMap,
              modified: formatModifiedDate(new Date(dataToMap.modified ?? "")),
              expiresIn: expiresIn
            };
          })
          .sort((n1: News, n2: News) => (n1?.order ?? 0) - (n2?.order ?? 0));
      }),
    [formatDate]
  );
  const { data: newsList = [], isLoading } = useGetLiveNewsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      data: selectFormsByRecordType(result)
    })
  });

  const noNews = isLoading || newsList.length === 0;

  const [newsCarouselExpanded, _setNewsCarouselExpanded] = useState(false);

  const newsCard = (newsItem: News) => {
    return <NewsItemCard newsItem={newsItem} />;
  };

  return (
    <Stack spacing={0} className="home-page-root">
      <Paper>
        <Stack className="events-container" spacing={0}>
          <Stack direction="row" className="title-container" spacing={0}>
            <EdisonTypography title={t("news&Events")} variant="h1" />
          </Stack>
          {noNews ? (
            <EdisonTypography
              title={t("noNewsMessage")}
              variant="data2"
              sx={{ marginTop: 5, marginBottom: 2 }}
            />
          ) : newsCarouselExpanded ? (
            <Grid container spacing={2}>
              {newsList.map(newsItem => (
                <Grid item key={newsItem.id}>
                  {newsCard(newsItem)}
                </Grid>
              ))}
            </Grid>
          ) : (
            <EdisonCarousel
              isMoreActive={false}
              data={newsList}
              getCard={newsCard}
              noDataModule={<div> {t("noData")}</div>}
            />
          )}
        </Stack>
      </Paper>
      <HomeRecordCarousel orderBy="created desc" filterKey="recentRecords" />
      <HomeRecordCarousel orderBy="lastViewed desc" filterKey="recentlyViewed" />
    </Stack>
  );
};
export default Home;
