import { Field } from "enada-common";
import { RowOrder } from "../../components/tableviewmodal/TableViewModal";

export const orderColumns = (columns: Field[] = [], rowOrder?: RowOrder[]) => {
  return columns
    .map(column => ({
      ...column,
      order: rowOrder?.find(order => order.fieldId === column.id)?.order ?? 0
    }))
    .sort((a, b) => a.order - b.order) as Field[];
};
