import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../config/authConfig";

export async function execute<T>(
  instance: IPublicClientApplication,
  httpRequest: any,
  dataType: string,
  data?: any,
  id?: number | null,
  scopes?: any,
  headers?: Headers
): Promise<T> {
  const currentAccount = instance.getActiveAccount();

  const requestedScope = scopes ?? loginRequest;
  const request = {
    ...requestedScope,
    account: currentAccount
  };

  return new Promise((resolve, reject) => {
    instance
      .acquireTokenSilent(request)
      .then(async (response: AuthenticationResult) => {
        if (response === null) {
          await instance.acquireTokenRedirect(request);
        }
        resolve(call(httpRequest, response.accessToken, dataType, data, id, headers));
      })
      .catch(async () => {
        await instance.acquireTokenRedirect(request).catch(error => {
          reject(new Error(error));
        });
      });
  });
}

function call(
  httpRequest: any,
  accessToken: string,
  dataType: string,
  data?: any,
  id?: any,
  headers?: Headers
) {
  if (!!data && !!id) {
    return httpRequest(accessToken, dataType, data, id, headers).then(
      (response: unknown) => response
    );
  }
  if (data) {
    return httpRequest(accessToken, dataType, data, headers).then((response: unknown) => response);
  }
  if (id) {
    return httpRequest(accessToken, dataType, id, headers).then((response: unknown) => response);
  }
  return httpRequest(accessToken, dataType, headers).then((response: unknown) => response);
}
