import React, { FC, ReactNode } from "react";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../config/authConfig";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Providers } from "@microsoft/mgt-react";

const msalInstance = new PublicClientApplication(msalConfig);

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance
}) as any;

function ErrorComponent({ error }: any) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p></p>;
}

interface Props {
  children: ReactNode;
}

const AuthenticationService: FC<Props> = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        {children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default AuthenticationService;
