import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { FC } from "react";
import "./tabcontainer.scss";
import Tab from "./Tab";
import { useAppDispatch } from "../../store/hooks";
import { addTab, setCurrentTab } from "../../store/slices/formDesignerSlice";
import { Box, Stack } from "@mui/material";

export interface TabContainerProps {
  data: any;
}

const TabContainer: FC<TabContainerProps> = ({ data }) => {
  const dispatch = useAppDispatch();

  const renderTab = (tab: any, index: number) => {
    return <Tab key={tab.id} data={tab} index={index} id={index.toString()} />;
  };

  return (
    <div className={`tab-container draggable`} data-testid={`tab-container`}>
      <Stack direction="row" spacing={2}>
        <AddCircleIcon
          color="primary"
          style={{ cursor: "pointer", margin: "auto 0px" }}
          onClick={
            () => dispatch(addTab())
            // handleNewColumn(`${path}-${data.children.length}`, data)
          }
        />
        {data.map((tab: any, index: number) => {
          return (
            <Box
              data-testid={`tab-box-${tab.id}`}
              key={tab.id}
              onKeyDown={event => {
                if (event.key === "Tab") {
                  if (data.length > index + 1) dispatch(setCurrentTab(data[index + 1].id));
                }
              }}
            >
              {renderTab(tab, index)}
            </Box>
          );
        })}
      </Stack>
    </div>
  );
};
export default TabContainer;
