import { PersonalPageState } from "enada-common";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  hiddenPersonalSystemFields,
  nonEditablePersonalSystemFields
} from "../../../pages/admin/tableconfig/TableConfig";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualTable,
  updateIndividualTableProperty
} from "../../../store/slices/tablesSlice";
import { EdisonEnumSelect } from "enada-components";

export const TablePersonalBehaviourRenderer = (_: any, row: any) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualTable = useAppSelector(selectIndividualTable);

  return (
    <EdisonEnumSelect
      key={`table-personal-behaviour-enum-select-${row.id}`}
      disabled={
        hiddenPersonalSystemFields.some(id => id === row.id) ||
        nonEditablePersonalSystemFields.includes(row.id)
      }
      // className comes from TableConfig
      className="table-personal-behaviour-enum-select"
      t={t}
      enumDef={PersonalPageState}
      value={row.personalPageState ?? PersonalPageState.Hidden}
      onChange={changeValue => {
        dispatch(
          updateIndividualTableProperty({
            key: "fields",
            value: individualTable?.fields?.map(tableField =>
              tableField.fieldId === row.id
                ? {
                    ...tableField,
                    personalPageState: changeValue
                  }
                : tableField
            )
          })
        );
      }}
      menuItemRenderer={option => (
        <MenuItem
          key={option as unknown as string}
          disabled={
            nonEditablePersonalSystemFields.includes(row.id) &&
            (option as any) === PersonalPageState.Editable
          }
          value={option as any as string}
        >
          {option as any}
        </MenuItem>
      )}
    />
  );
};
