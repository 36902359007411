import { FC, useState } from "react";

import { LicenseType, RecordType, RoleType, recordTypeConfiguration } from "enada-common";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Box, Theme, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  default as edison365LogoDarkSmall,
  default as small365Logo
} from "../assets/edison365Small.png";
import edison365Logo from "../assets/edison365logo.png";
import edison365LogoDark from "../assets/edison365logodark.png";
import { RouteItem, mainRoutes, moduleRoutes } from "../config/routes";
import { useAppSelector } from "../store/hooks";
import { selectIsDarkTheme } from "../store/slices/userSlice";
import { EdisonTypography } from "enada-components";
import colors from "./../theme/styleVariables.module.scss";
import CreateRecordModal from "./createrecordmodal/CreateRecordModal";
import HasMinimumLicense from "./hasMinimumLicense/HasMinimumLicense";
import HasRoles from "./hasRoles/HasRoles";
import HasModule from "./hasModule/HasModule";
import { apiConfig } from "../config/authConfig";
import AvatarMenu from "./avatarMenu/AvatarMenu";
import "./navigation.scss";
import { imageMapping } from "../utils/mappers/imageMapping";
import useHasRole from "../utils/hooks/useHasRole";
import { useGetUserPhotoQuery, useGetUserQuery } from "services/api";

interface Props {
  open: boolean;
  handleMenuClose?: () => void;
}

const NavDivider = () => (
  <Divider sx={{ width: "90%", alignSelf: "center", marginTop: 2, marginBottom: 2 }} />
);

const Navigation: FC<Props> = ({ open, handleMenuClose }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { data: userPhoto } = useGetUserPhotoQuery();
  const { data: user } = useGetUserQuery();
  const isDarkTheme = useAppSelector(selectIsDarkTheme);
  const { hasRole } = useHasRole();
  const expandedLogo = isDarkTheme ? edison365LogoDark : edison365Logo;
  const smallLogo = isDarkTheme ? edison365LogoDarkSmall : small365Logo;
  const canCreateRecord =
    hasRole([RoleType.TemplateOwner]) ||
    hasRole([RoleType.TemplateIdeator]) ||
    hasRole([RoleType.RecordIdeator]);

  return (
    <>
      {isCreateModalOpen && (
        <CreateRecordModal open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
      )}
      <Stack
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden"
          // "&::-webkit-scrollbar": { display: "none" },
        }}
        direction="column"
        justifyContent="space-between"
        className={clsx("navigation-drawer", {
          "drawer-open": open,
          "drawer-close": !open
        })}
      >
        <Stack direction="column" height={"100%"}>
          <Stack direction="column" alignItems={"center"} spacing={4} className="nav-logo-spacing">
            <Box paddingTop={4} onClick={() => navigate(`./home`)} sx={{ cursor: "pointer" }}>
              <img
                src={open ? expandedLogo : smallLogo}
                className={open ? "logo" : "small-logo"}
                alt={t("edison365Logo")}
              />
            </Box>
            <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
              {canCreateRecord && (
                <Box className={open ? "nav-create-box-open" : "nav-create-box-closed"}>
                  <Button
                    variant="contained"
                    onClick={() => setIsCreateModalOpen(true)}
                    startIcon={
                      open ? (
                        <AddIcon
                          sx={{
                            "&.MuiSvgIcon-fontSizeMedium": {
                              fontSize: "1.5rem"
                            }
                          }}
                        />
                      ) : null
                    }
                    className={open ? "nav-create-btn-open" : "nav-create-btn-closed"}
                    data-test-id={`navigation-button-create`}
                  >
                    {open ? (
                      t("create")
                    ) : (
                      <Tooltip title={t("create")} arrow placement="right">
                        <AddIcon />
                      </Tooltip>
                    )}
                  </Button>
                </Box>
              )}
            </HasMinimumLicense>
          </Stack>
          <NavDivider />
          <Stack
            paddingTop="0"
            paddingBottom="0"
            sx={{ overflowY: "auto", overflowX: "hidden" }}
            flex={1}
            display="flex"
            alignItems={open ? "flex-start" : "center"}
          >
            {moduleRoutes
              .filter(route => route.enabled)
              .map(route => {
                const title = route?.key === RecordType.BusinessCase ? "businessCases" : route?.key;

                const selected = location.pathname.split("/").pop() === route.path;
                const module = recordTypeConfiguration(route.key as RecordType);
                return (
                  <HasModule recordType={route.key as RecordType} key={route.key}>
                    <Link
                      key={route.key}
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={route.path}
                      state={{ title: route.key }}
                      className={clsx("module-route-menu-item-link", {
                        open: open,
                        close: !open
                      })}
                    >
                      <MenuItem
                        className="module-route-menu-item"
                        sx={theme => ({
                          color: selected
                            ? isDarkTheme
                              ? module.secondaryColor
                              : module.color
                            : theme.palette.text.primary,
                          backgroundColor: selected
                            ? isDarkTheme
                              ? module.color
                              : module.secondaryColor
                            : undefined,
                          "&:hover": {
                            backgroundColor: selected
                              ? isDarkTheme
                                ? module.color
                                : module.secondaryColor
                              : undefined
                          }
                        })}
                        data-test-id={`navigation-link-${route.key}`}
                      >
                        <Stack direction={"row"} alignItems="center">
                          <Tooltip title={t(title)} arrow placement="right">
                            <Avatar
                              sx={theme => ({
                                width: 40,
                                height: 40,
                                bgcolor: isDarkTheme ? module.color : module.secondaryColor,
                                color: isDarkTheme ? theme.palette.text.primary : module.color,
                                alignContent: "center"
                              })}
                            >
                              {module.icon}
                            </Avatar>
                          </Tooltip>
                          {open ? (
                            <ListItemText sx={{ marginLeft: 3 }}>
                              <EdisonTypography title={t(title)} variant={"h4"} />
                            </ListItemText>
                          ) : null}
                        </Stack>
                      </MenuItem>
                    </Link>
                  </HasModule>
                );
              })}
            <NavDivider />
            {mainRoutes
              .filter(route => !route.notNavigation)
              .map(route =>
                route.requiredRoles ? (
                  <HasRoles key={route.key} roles={route.requiredRoles}>
                    <NavLink route={route} open={open} t={t} />
                  </HasRoles>
                ) : (
                  <NavLink key={route.key} route={route} open={open} t={t} />
                )
              )}
          </Stack>
          <Stack direction="column" paddingBottom={2} alignItems={open ? "flex-start" : "center"}>
            <NavDivider />
            <Stack
              direction="column"
              width="100%"
              alignItems={open ? "flex-start" : "center"}
              spacing={1}
            >
              <NavLink
                open={open}
                t={t}
                route={{ key: "support", path: apiConfig.supportEndpoint, enabled: true }}
                target="_blank"
              />
              <Stack
                className="icon-container"
                direction="row"
                alignItems={open ? "flex-start" : "center"}
                paddingX={2}
              >
                <AvatarMenu userPhoto={userPhoto} user={user} />
                {open && (
                  <ListItemText sx={{ marginLeft: 3 }} className="open">
                    <EdisonTypography title={t(user?.displayName ?? "")} variant={"h5"} />
                  </ListItemText>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <IconButton
          onClick={handleMenuClose}
          size="medium"
          sx={{ position: "absolute", bottom: 0, right: 0 }}
          className="drawer-btn"
          color="primary"
        >
          {open ? (
            <ChevronLeftIcon className="drawer-btn-chevron" />
          ) : (
            <ChevronRightIcon className="drawer-btn-chevron" />
          )}
        </IconButton>
      </Stack>
    </>
  );
};

const NavLink: FC<{
  route: RouteItem;
  open: boolean;
  target?: string;
  t: (value: string) => string;
}> = ({ route, open, target, t }) => {
  const location = useLocation();
  const selected =
    location.pathname.split("/").pop() === route.path ||
    (route.path === "home" && location.pathname.split("/").pop()?.length === 0);
  const styles = (theme: Theme) => ({
    color: selected ? colors.NavigationSelectedColor : theme.palette.text.primary,
    backgroundColor: selected ? colors.NavigationSelectedBgColor : undefined,
    "&:hover": {
      backgroundColor: selected ? colors.NavigationSelectedBgColor : undefined
    }
  });
  return (
    <Link
      key={route.key}
      to={route.path}
      className={clsx("route-menu-item-link", {
        open: open,
        close: !open
      })}
      state={{ title: route.key }}
      data-test-id={`navigation-link-${route.key}`}
      target={target}
    >
      {open ? (
        <MenuItem className={`route-menu-item open`} sx={styles}>
          <Stack
            className="icon-container"
            sx={theme => ({
              color: selected ? colors.NavigationSelectedColor : theme.palette.ActionActive.main
            })}
          >
            {imageMapping(route.key || "home", "inherit")}
          </Stack>
          <ListItemText sx={{ marginLeft: 3 }}>
            <EdisonTypography title={t(route.key)} variant={"h5"} />
          </ListItemText>
        </MenuItem>
      ) : (
        <Tooltip title={t(route.key)} arrow placement="right">
          <MenuItem className={`route-menu-item closed`} sx={styles}>
            <Stack
              className="icon-container"
              sx={theme => ({
                color: selected ? colors.NavigationSelectedColor : theme.palette.ActionActive.main
              })}
            >
              {imageMapping(route.key || "home", "inherit")}
            </Stack>
          </MenuItem>
        </Tooltip>
      )}
    </Link>
  );
};

export default Navigation;
