import {
  Field,
  FieldDataType,
  RecordTableRow,
  TableRowPeriodicValues,
  TableRowValue,
  TableRowSchedulingMode,
  WorkflowPopulationTableRow,
  WorkflowDataPopulationTableRowFieldValue,
  WorkflowDataPopulationTableRowPeriodicValue,
  RowOperationType,
  OperationType,
  PopulationOperationType
} from "enada-common";
import { SystemFieldType } from "../pages/admin/tableconfig/TableConfig";
import { StringHelper } from "@bryntum/core-thin";

enum TableRowConstraintType {
  finishnoearlierthan,
  finishnolaterthan,
  mustfinishon,
  muststarton,
  startnoearlierthan,
  startnolaterthan
}

enum TableRowDurationType {
  millisecond,
  second,
  minute,
  hour,
  day,
  week,
  month,
  quarter,
  year
}

function updateFieldValue(field: Field, rowValue: TableRowValue, change: any) {
  switch (field.dataType) {
    case FieldDataType.TextBox:
    case FieldDataType.Url:
    case FieldDataType.Email:
      rowValue.stringValue = change.value.value;
      break;
    case FieldDataType.Number:
    case FieldDataType.Currency:
    case FieldDataType.Percentage:
      rowValue.decimalValue = change.value.value
        ? parseFloat(change.value.value)
        : change.value.value;
      break;
    case FieldDataType.Switch:
      rowValue.boolValue = change.value.value;
      break;
    case FieldDataType.Date:
    case FieldDataType.DateTime:
      rowValue.dateTimeValue = change.value.value
        ? (change.value.value as Date).toUTCString()
        : change.value.value;
      break;
    case FieldDataType.Calculated:
      rowValue.decimalValue = change.value.value;
      break;
    case FieldDataType.People:
    case FieldDataType.MultiPeople:
    case FieldDataType.MultiChoice:
    case FieldDataType.Choice:
      rowValue.extendedValue = {
        value: change.value?.value ?? []
      };
      break;
    default:
      rowValue.extendedValue = change.value;
      break;
  }
}

const getBackendMappedCellValue = (field: Field, value: any, rowId: number): TableRowValue => {
  const baseMappedValue: TableRowValue = {
    fieldId: field.id as number,
    recordTableRowId: rowId
  };
  switch (field.dataType) {
    case FieldDataType.TextBox:
    case FieldDataType.Email:
      return { ...baseMappedValue, stringValue: value };
    case FieldDataType.Number:
    case FieldDataType.Currency:
    case FieldDataType.Percentage:
      return {
        ...baseMappedValue,
        decimalValue: value ? parseFloat(value) : value
      };
    case FieldDataType.Switch:
      return { ...baseMappedValue, boolValue: value };
    case FieldDataType.Date:
    case FieldDataType.DateTime:
      return {
        ...baseMappedValue,
        dateTimeValue: value ? new Date(value).toUTCString() : value
      };
    case FieldDataType.Calculated:
      return { ...baseMappedValue, decimalValue: value };
    case FieldDataType.MultiChoice:
    case FieldDataType.Choice:
      return {
        ...baseMappedValue,
        extendedValue: {
          value: value ?? []
        }
      };
    case FieldDataType.Url:
      return {
        ...baseMappedValue,
        extendedValue: { value: value ?? { title: "", url: "" } }
      };
    default:
      return { ...baseMappedValue, extendedValue: { value } };
  }
};

const getFieldByKey = (key: string, fields: Field[]): Field | undefined => {
  return fields.find(field => {
    return field.name === key;
  });
};

const getRow = (currentTableRows: RecordTableRow[], record: any) => {
  return currentTableRows.find(r => getUniqueIdFromRow(r) === record.id);
};

const getValueByFieldType = (field: Field, cellValue: TableRowValue) => {
  switch (field.dataType) {
    case FieldDataType.TextBox:
    case FieldDataType.Email:
      return cellValue.stringValue ? StringHelper.decodeHtml(cellValue.stringValue) : null;
    case FieldDataType.Currency:
    case FieldDataType.Number:
    case FieldDataType.Percentage:
      return cellValue.decimalValue;
    case FieldDataType.Calculated:
      return cellValue.decimalValue;
    case FieldDataType.Switch:
      return cellValue.boolValue;
    case FieldDataType.Date:
    case FieldDataType.DateTime:
      return cellValue.dateTimeValue ? new Date(cellValue.dateTimeValue) : null;
    case FieldDataType.MultiChoice:
    case FieldDataType.Choice:
      return cellValue?.extendedValue?.value;
    default:
      return cellValue.extendedValue?.value;
  }
};

const getBryntumValue = (record: any, key: string) => {
  switch (key) {
    case "constraintType":
      return TableRowConstraintType[record[key]];
    case "schedulingMode":
      return TableRowSchedulingMode[record[key]];
    case "durationUnit":
    case "effortUnit":
    case "slackUnit":
      return TableRowDurationType[record[key]];
    // case "predecessors":
    //   return record[key].map((dependency: any) => dependency.from);
    // case "successors":
    //   return record[key].map((dependency: any) => dependency.to);
    case "totalSlack":
      return record[key] !== undefined
        ? parseFloat((record[key] as number).toFixed(4))
        : record[key];
    case "effort":
      return record[key] !== undefined ? Math.round(record[key] as number) : record[key];
    default:
      return record[key];
  }
};

/**
 * Merges current table operations with new table operations and returns the merged array of RowOperationType objects.
 *
 * @param {RowOperationType[]} currentOps - The array of current table operations (already grouped by operationType).
 * @param {RowOperationType[] | null} newOps - The array of new table operations or null (already grouped by operationType).
 * @returns {RowOperationType[]} The merged array of table operations grouped by operationType.
 */
const mergeTableOperationTypes = (
  currentOps: RowOperationType[],
  newOps: RowOperationType[] | null
): RowOperationType[] => {
  if (!newOps || newOps.length === 0) {
    return [...currentOps];
  }

  const ops: RowOperationType[] = [];

  // Add currentOps to the merged array
  ops.push(...currentOps);
  newOps.forEach(newOp => {
    if (newOp.operationType === OperationType.AddOrPatch) {
      newOp.tableRows.forEach(newOpRow => {
        const uniqueId = getUniqueIdFromRow(newOpRow);
        let found = false;

        ops.forEach(currentOp => {
          const currentOpRows = currentOp.tableRows;

          if (currentOpRows) {
            const currentOpRowIndex = currentOpRows.findIndex(
              currentRow => getUniqueIdFromRow(currentRow) === uniqueId
            );

            if (currentOpRowIndex !== -1) {
              found = true;
              const currentRow = currentOpRows[currentOpRowIndex];

              // When updating a row that allready exists  in the tableOperations lists, update  it's values
              if (newOpRow.tableRowFieldValues)
                updateTableRowFieldValues(newOpRow.tableRowFieldValues, currentRow);
              updateRecordTableRowPeriodicValues(newOpRow.tableRowPeriodicValues, currentRow);
              //update existing rows dependencies
              currentRow.dependencies = newOpRow.dependencies;
              //update existing rows configuration
              currentRow.configuration = newOpRow.configuration;
            }
          }
        });

        if (!found) {
          ops.push({ ...newOp, tableRows: [newOpRow] });
        }
      });
    } else {
      ops.push(newOp);
    }
  });

  // Group the merged operations by operationType
  const groupedOps: { [key: number]: RowOperationType[] } = ops.reduce((result: any, operation) => {
    const operationType = operation.operationType;

    if (!result[operationType]) {
      result[operationType] = [];
    }

    result[operationType].push(operation);
    return result;
  }, {});

  // Flatten the grouped operations into a single array
  const mergedOps: RowOperationType[] = Object.values(groupedOps).reduce((result, opsByType) => {
    result.push(...opsByType);
    return result;
  }, []);
  return mergedOps;
};

const mergePopulationTableOperationTypes = (
  currentOps: PopulationOperationType[],
  newOps: PopulationOperationType[] | null
): PopulationOperationType[] => {
  if (!newOps || newOps.length === 0) {
    return [...currentOps];
  }

  const ops: PopulationOperationType[] = [];

  // Add currentOps to the merged array
  ops.push(...currentOps);
  newOps.forEach(newOp => {
    if (newOp.operationType === OperationType.AddOrPatch) {
      newOp.tableRows.forEach(newOpRow => {
        const uniqueId = getUniqueIdFromRow(newOpRow);
        let found = false;

        ops.forEach(currentOp => {
          const currentOpRows = currentOp.tableRows;

          if (currentOpRows) {
            const currentOpRowIndex = currentOpRows.findIndex(
              currentRow => getUniqueIdFromRow(currentRow) === uniqueId
            );

            if (currentOpRowIndex !== -1) {
              found = true;
              const currentRow = currentOpRows[currentOpRowIndex];

              // When updating a row that allready exists in the tableOperations lists, update it's values
              if (newOpRow.tableRowFieldValues) {
                updateTableRowFieldValues(newOpRow.tableRowFieldValues, currentRow);
              }
              updateRecordTableRowPeriodicValues(newOpRow.tableRowPeriodicValues, currentRow);
              //update existing rows dependencies
              currentRow.dependencies = newOpRow.dependencies;
              //update existing rows configuration
              currentRow.configuration = newOpRow.configuration;
            }
          }
        });

        if (!found) {
          ops.push({ ...newOp, tableRows: [newOpRow] });
        }
      });
    } else {
      ops.push(newOp);
    }
  });

  // Group the merged operations by operationType
  const groupedOps: { [key: number]: PopulationOperationType[] } = ops.reduce(
    (result: any, operation) => {
      const operationType = operation.operationType;

      if (!result[operationType]) {
        result[operationType] = [];
      }

      result[operationType].push(operation);
      return result;
    },
    {}
  );

  // Flatten the grouped operations into a single array
  const mergedOps: PopulationOperationType[] = Object.values(groupedOps).reduce(
    (result, opsByType) => {
      result.push(...opsByType);
      return result;
    },
    []
  );
  return mergedOps;
};

/**
 * Updates TableRowFieldValues with new values.
 *
 * @param {TableRowValue[] | undefined} newValues - The array of new TableRowFieldValues.
 * @param {RecordTableRow} currentRow - The current projectTableRow to update.
 */
const updateTableRowFieldValues = (
  newValues: TableRowValue[] | WorkflowDataPopulationTableRowFieldValue[] | undefined,
  currentRow: RecordTableRow | WorkflowPopulationTableRow
): void => {
  if (newValues) {
    newValues.forEach(newValue => {
      const matchingChangeIndex = currentRow.tableRowFieldValues?.findIndex(
        (currentRowValue: TableRowValue | WorkflowDataPopulationTableRowFieldValue) =>
          currentRowValue.fieldId === newValue.fieldId
      );

      if (matchingChangeIndex !== undefined && matchingChangeIndex !== -1) {
        currentRow.tableRowFieldValues?.splice(matchingChangeIndex, 1, newValue);
      } else {
        currentRow.tableRowFieldValues?.push(newValue);
      }
    });
  }
};

/**
 * Updates projectTableRowPeriodicValues with new values.
 *
 * @param {TableRowPeriodicValues[] | WorkflowDataPopulationTableRowPeriodicValue[] | undefined} newValues - The array of new projectTableRowPeriodicValues.
 * @param {RecordTableRow | WorkflowPopulationTableRow} currentRow - The current projectTableRow to update.
 */
const updateRecordTableRowPeriodicValues = (
  newValues: TableRowPeriodicValues[] | WorkflowDataPopulationTableRowPeriodicValue[] | undefined,
  currentRow: RecordTableRow | WorkflowPopulationTableRow
): void => {
  if (newValues) {
    newValues.forEach(newValue => {
      const matchingChangeIndex = currentRow.tableRowPeriodicValues?.findIndex(
        (currentRowValue: any) =>
          currentRowValue.period === newValue.period && currentRowValue.year === newValue.year
      );

      if (matchingChangeIndex !== undefined && matchingChangeIndex !== -1) {
        currentRow.tableRowPeriodicValues?.splice(matchingChangeIndex, 1, newValue);
      } else {
        currentRow.tableRowPeriodicValues?.push(newValue);
      }
    });
  }
};

// Needed because the unique Id has been move from the root level of the ProjectTableRow to the tableRowFieldValues as a system field
export const getUniqueIdFromRow = (row: RecordTableRow | WorkflowPopulationTableRow) => {
  const uniqueId = row.tableRowFieldValues?.find(
    (value: TableRowValue | WorkflowDataPopulationTableRowFieldValue) =>
      value.fieldId === SystemFieldType.UniqueId
  )?.stringValue;
  return uniqueId;
};

export {
  getBackendMappedCellValue,
  updateFieldValue,
  getFieldByKey,
  getRow,
  getValueByFieldType,
  getBryntumValue,
  mergeTableOperationTypes,
  mergePopulationTableOperationTypes
};
