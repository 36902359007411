import { Alert, Button, Dialog, Divider, Paper, Stack } from "@mui/material";
import React, { FC } from "react";
import EdisonTypography from "../../../../edison/typography/EdisonTypography";
export interface MppImportDialogProps {
  open: boolean;
  onClose: () => void;
  onRetry: () => void;
  t: (value: string) => string;
}

const MppImportDialog: FC<MppImportDialogProps> = ({ open, onClose, onRetry, t }) => {
  return (
    <Dialog open={open}>
      <Paper>
        <Stack>
          <Stack padding={"1em"}>
            <EdisonTypography variant="h3" title={t("mppImportTaskLimitExceededTitle")} />
          </Stack>
          <Divider flexItem />
          <Stack padding={"1em"}>
            <Alert severity="warning">{t("mppImportTaskLimitExceededDescription")}</Alert>
          </Stack>
          <Divider flexItem />
          <Stack direction={"row"} padding={"1em"} spacing={1} justifyContent={"end"}>
            <Button variant="contained" color="primary" onClick={onRetry}>
              {t("retry")}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default MppImportDialog;
