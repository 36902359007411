import { Chip, ChipProps, useTheme } from "@mui/material";
import React, { FC } from "react";
import { common } from "@mui/material/colors";

interface ChoiceChipProps {
  label: string;
  colour?: string;
  readOnly?: boolean;
  disabled?: boolean;
  additionalProps?: ChipProps;
  tooltip?: string;
}

const ChoiceChip: FC<ChoiceChipProps> = ({
  label,
  colour,
  readOnly,
  disabled,
  additionalProps,
  tooltip
}) => {
  const theme = useTheme();

  return (
    <Chip
      {...additionalProps}
      key={additionalProps?.key ?? label}
      sx={{
        backgroundColor: colour ?? common.white,
        color: theme.palette.getContrastText(colour ?? common.white),
        marginBottom: "5px",
        marginTop: "5px",
        maxWidth: "100%"
      }}
      label={label}
      variant="filled"
      // Set disable explicitly after getTagProps
      disabled={disabled}
      onDelete={undefined}
      title={tooltip ?? label}
      data-testid={`choicechip`}
    />
  );
};

export default ChoiceChip;
