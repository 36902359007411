import DOMPurify from "dompurify";
import parse from "html-react-parser";

export const sanitizeHtml = (htmlString: string) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlString);

  return parse(sanitizedHtml);
};

export const createCleanCopy = (initialValue: any[]): any[] => {
  const newValue = JSON.parse(JSON.stringify(initialValue));
  return sanitizeSlateValue(newValue);
};

const sanitizeSlateValue = (slateValue: any[]): any[] => {
  const mapped = slateValue.map(child => {
    if (child.text) {
      child.text = sanitizeHtml(child.text)[0];
    }

    if (child.children) {
      child.children = sanitizeSlateValue(child.children);
    }

    return child;
  });

  return mapped;
};
