import { GridSelectionMode, RowReorderConfig } from "@bryntum/grid-thin";
import FillHandleSharedConfig from "./FillHandleSharedConfig";
import CopyPasteSharedConfig from "./CopyPasteSharedConfig";

const rowReorderConfig: RowReorderConfig = {
  allowCrossGridDrag: true,
  showGrip: false,
  gripOnly: true,
  disabled: true
};
const selectionMode: GridSelectionMode = {
  cell: true,
  checkbox: true,
  showCheckAll: true,
  dragSelect: true,
  multiSelect: true
};

const SharedConfig = {
  ...FillHandleSharedConfig,
  ...CopyPasteSharedConfig,
  rowReorderFeature: rowReorderConfig,
  selectionMode: selectionMode
};

export default SharedConfig;
