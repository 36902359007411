import { CalendarModel } from "@bryntum/gantt-thin";

export class TaskCalendarModel extends CalendarModel {
  static get fields() {
    return [
      {
        name: "name",
        dataSource: "displayName"
      }
    ];
  }
}
