// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { HasPercentDoneMixin } from "@bryntum/engine-thin";
import { ProjectModel, ProjectModelConfig } from "@bryntum/gantt-thin";
import { v4 as uuidv4 } from "uuid";

// PercentMixinProjectModel is used to get average percentDone from task table.
// This is required to populate the Project rollup table percentDone property.
export class PercentMixinProjectModel extends HasPercentDoneMixin.derive(ProjectModel) {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(config?: Partial<ProjectModelConfig>) {
    super(config);
  }
  generateId(): string {
    return uuidv4();
  }
}
