import { Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Node } from "reactflow";
import {
  clearWorkflowError,
  selectCurrentElement,
  selectIsWorkflowDefault,
  selectWorklowValidationErrorsByNode,
  updateElement
} from "../../../../store/slices/workflowSlice";
import { EdisonColourPicker, EdisonTypography } from "enada-components";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";

const NodeOptions = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const isDefault = useAppSelector(selectIsWorkflowDefault);

  const errors = useAppSelector(state =>
    selectWorklowValidationErrorsByNode(state, currentNode?.data?.name)
  );

  const nodeNameError = errors.find(error => error.property === "nodeName");

  return (
    <Stack direction="column" spacing={2}>
      <EdisonTypography title={t("editYourStage")} variant="menuitem" />
      <TextField
        disabled={isDefault}
        size="small"
        variant="standard"
        label={t("stageName")}
        value={currentNode ? currentNode.data.displayName : ""}
        onChange={e => {
          if (!currentNode) return;
          dispatch(
            updateElement({
              ...currentNode,
              data: { ...currentNode.data, displayName: e.target.value }
            })
          );
          if (nodeNameError === undefined) return;
          dispatch(clearWorkflowError(nodeNameError));
        }}
        error={nodeNameError !== undefined}
        helperText={nodeNameError?.error}
      />
      <TextField
        size="small"
        disabled={true}
        variant="standard"
        label={t("internalName")}
        value={currentNode?.data.name ?? ""}
      />

      <TextField
        disabled={isDefault}
        size="small"
        variant="standard"
        label={t("description")}
        value={currentNode?.data.description ?? ""}
        onChange={e => {
          if (!currentNode) return;
          dispatch(
            updateElement({
              ...currentNode,
              data: { ...currentNode.data, description: e.target.value }
            })
          );
        }}
      />
      <EdisonColourPicker
        colour={currentNode?.data.configuration.color}
        disabled={isDefault}
        onChange={changeValue =>
          dispatch(
            updateElement(
              updateNodeCustomData(
                "configuration",
                { ...currentNode?.data.configuration, color: changeValue },
                currentNode as Node
              )
            )
          )
        }
        label={t("stageColour")}
        t={t}
      />
    </Stack>
  );
};

export default NodeOptions;
