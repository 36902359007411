import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlined from "@mui/icons-material/KeyboardArrowRightOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { FC, useEffect, useState } from "react";
import "./timerangesdropdown.scss";
import { Gantt } from "@bryntum/gantt-thin";
import { v4 } from "uuid";
import { BryntumGrid } from "@bryntum/grid-react-thin";
//TODO: Resolve issue with prop drilling for gantt and taskStore objects=> maybe use Redux
export interface TimeRangesDropdownProps {
  t: (key: string) => string;
  gantt: Gantt;
}
export interface TimeRangeRow {
  id: string;
  name: string;
  duration: number;
  startDate: Date;
  show: boolean;
}
const TimeRangesDropdown: FC<TimeRangesDropdownProps> = ({ t, gantt }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeRangesDisabled, setTimeRangesDisabled] = useState(gantt.features.timeRanges.disabled);
  const timeRangeStore = gantt.features.timeRanges.store;
  return (
    <Stack className="timeranges-dropdown-root">
      <Stack direction="row" className="dropdown-container">
        <Stack direction="row" className="title-container">
          <FormControlLabel
            control={
              <Checkbox
                checked={!timeRangesDisabled}
                onChange={_ => {
                  const newValue = !gantt.features.timeRanges.disabled;
                  setTimeRangesDisabled(newValue);
                  gantt.features.timeRanges.disabled = newValue;
                }}
              />
            }
            label={t("showTimeRanges")}
          />
          <Tooltip title={t("timeRangesMessage")} arrow placement="right">
            <InfoOutlinedIcon />
          </Tooltip>
        </Stack>
        <IconButton onClick={() => setDropdownOpen(prev => !prev)}>
          {dropdownOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRightOutlined />}
        </IconButton>
      </Stack>
      {dropdownOpen && (
        <Stack className="enada-bryntum-grid">
          <BryntumGrid
            {...config}
            store={timeRangeStore}
            sortFeature={false}
            columns={[
              {
                text: "Time Ranges",
                flex: 1,
                field: "name",
                instantUpdate: true
              },
              {
                type: "date",
                text: "Start Date",
                width: 120,
                field: "startDate",
                instantUpdate: true
              },
              {
                type: "number",
                text: "Duration",
                width: 78,
                field: "duration",
                min: 0,
                instantUpdate: true,
                renderer: (data: any) => `${data.record.duration} d`
              },
              {
                text: "Delete",
                width: 65,
                renderer: (data: any) => (
                  <IconButton onClick={() => timeRangeStore.remove(data.row.id)}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            ]}
          />

          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              timeRangeStore;
              timeRangeStore.add({
                name: "New Range",
                startDate: new Date(),
                duration: 5,
                id: v4()
              });
            }}
          >
            add Time Range
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
const config = {
  autoHeight: true,
  width: "100%",
  cellEdit: {
    triggerEvent: "cellclick"
  }
};
export default TimeRangesDropdown;
