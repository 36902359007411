import { Field } from "enada-common";
import { isColumnDynamic } from "./isColumnDynamic";
import { ColumnStore } from "@bryntum/grid-thin";
import { getClassToSearchFor } from "./generateBaseBryntumColumn";
import { useLocalStorage } from "usehooks-ts";

export const useToggleAutoHeight = (columns: Field[], gantt: any, label: string) => {
  const [autoHeight, setAutoHeight] = useLocalStorage(
    `tableAutoHeight-${label.replace(" ", "-")}`,
    false
  );
  const bryntumColumns = gantt?.columns as ColumnStore;

  const toggleAutoHeight = (changeValue: boolean) => {
    const columnNames: string[] = [];

    bryntumColumns?.forEach((column: any) => {
      if (!isColumnDynamic(column, columns)) return;
      column.data["dynamicHeight"] = changeValue;
      columnNames.push(column.data.field);

      setAutoHeight(changeValue);
    });
    const ganttElement = gantt.currentElement as HTMLElement;
    const dynamicClassNames = columnNames.map(columnName =>
      getClassToSearchFor(columns.find(column => column.name === columnName)?.dataType)
    );

    const callback = (mutationsList: any, observer: any) => {
      const els = ganttElement.querySelector(dynamicClassNames[0]);

      if (els !== null) {
        gantt.refreshRows();
        observer.disconnect();
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    const config = { childList: true, subtree: true };
    const toFind = ganttElement.querySelector(dynamicClassNames[0]);

    if (toFind !== null) {
      //Needed to apply dynamic height change as setting a value in the data property deos not trigger a refresh
      gantt.refreshRows();
    } else {
      observer.observe(ganttElement, config);
    }
  };

  return { toggleAutoHeight, autoHeight: Boolean(autoHeight) };
};
