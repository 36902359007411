import { Node } from "reactflow";

export const updateNodeCustomData = (
  propertyToUpdate: string,
  newValue: unknown,
  node: Node
): Node => {
  return {
    ...node,
    data: {
      ...node.data,
      [propertyToUpdate]: newValue
    }
  };
};

export const updateMultipleNodeProperties = (keyValue: { [key: string]: unknown }, node: Node) => {
  return { ...node, data: { ...node.data, ...keyValue } };
};
