import { InputAdornment, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  UserPercentageFieldProps,
  fieldConfiguration,
  FieldDataType,
  useDebounce
} from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import { formatDecimals } from "../../utils/formatDecimals";

const UserPercentageField: FC<UserPercentageFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  maxDecimalPlaces,
  min,
  max,
  setReadOnly,
  autoFocus,
  required
}) => {
  const [internalState, setInternalState] = useState(value || "");

  const isLessThanMin = (value: string) => {
    return value && min && Number(value) < min;
  };
  const isMoreThanMax = (value: string) => {
    return value && max && Number(value) > max;
  };
  const getIsValid = (value: string) => {
    return !(isLessThanMin(value) || isMoreThanMax(value));
  };

  const [isValid, setIsValid] = useState<boolean>(getIsValid(value));

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  useDebounce(
    () => {
      if (!isValid) return;
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState, isValid);
    },
    1000,
    [internalState]
  );

  const handleChange = (value: string) => {
    setInternalState(formatDecimals(value.toString(), maxDecimalPlaces));
    setIsValid(getIsValid(value));
  };

  const placeholder = () => {
    if (min && max) return `${min}-${max}`;
    return "";
  };

  return readOnly ? (
    <EdisonFieldIcon
      title={label}
      subtitle={!isNaN(internalState) ? internalState + " %" : ""}
      icon={fieldConfiguration(FieldDataType.Percentage).icon}
      color={fieldConfiguration(FieldDataType.Percentage).color}
      isIconOn={isIconOn}
      isTableModeOn={!!isInTable}
      additionalStyles={isInTable ? { textAlign: "right" } : undefined}
      required={required}
      error={required && internalState === ""}
    />
  ) : (
    <TextField
      required={required}
      placeholder={placeholder()}
      variant="standard"
      type="number"
      label={!isInTable && label}
      fullWidth
      autoFocus={autoFocus}
      onBlur={() => {
        if (!isValid) return;
        if (!setReadOnly) return;
        setReadOnly(true);
        if (!isInTable) return;
        if (!onChange) return;
        onChange(internalState, isValid);
      }}
      onFocus={
        isInTable
          ? event => {
              event.target.select();
            }
          : undefined
      }
      value={internalState}
      onChange={e => {
        handleChange(e.target.value);
      }}
      inputProps={{
        min,
        max
      }}
      helperText=""
      size="small"
      error={error || !isValid}
      InputProps={{
        endAdornment: <InputAdornment position="end"> %</InputAdornment>
      }}
    />
  );
};

export default UserPercentageField;
export { UserPercentageField };
