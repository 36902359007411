import { Field, RecordTableRow, TableDataType } from "enada-common";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { getUniqueIdFromRow, getValueByFieldType } from "../tableHelpers";
import { getTaskTableSystemFieldsBryntumMap } from "./parseTaskTableChangeToBackend";
import { getTaskReviewResult } from "../../components/projects/projecttablerowreview/rowreviewchangestable/RowReviewChangesTable";

// For each row to live update get the changed values between the update and the current row
export const parseRowsToUpdateToBryntum = (
  rowsInTable: RecordTableRow[],
  fields: Field[],
  tableType: TableDataType,
  rowsToUpdate?: RecordTableRow[]
) => {
  if (rowsToUpdate === undefined) return [] as Record<string, any>[];
  const result = rowsToUpdate.map(row => {
    const currentRow = rowsInTable.find(
      rowInTable => getUniqueIdFromRow(rowInTable) === getUniqueIdFromRow(row)
    );

    if (currentRow === undefined) return undefined;

    const changedValues = getTaskReviewResult(fields, currentRow, row);

    const change = changedValues?.reduce((acc, curr) => {
      const pair =
        tableType === TableDataType.Schedule
          ? getTaskTableSystemFieldsBryntumMap(false).find(pair => pair.edison === curr?.field?.id)
          : undefined;
      return {
        ...acc,
        [pair ? pair.bryntum : `${curr?.field?.name}-e365`]: curr.after
      } as Record<string, any>[];
    }, {});

    return {
      ...change,
      rowId: getUniqueIdFromRow(row),
      "pendingUpdate-e365": true
    } as Record<string, any>;
  });
  return result as Record<string, any>[];
};

export const parseTaskTableDataToFrontend = (rows: RecordTableRow[], fields: any[]) => {
  let data = rows?.map(row => {
    const rowData = parseRowToBryntumRow(row, fields);

    return updateBryntumEnumValue(rowData);
  });

  const subTasks = data.filter(row => Boolean(row.parentId));
  if (subTasks.length > 0) {
    // see https://bryntum.com/products/gantt/docs/api/Gantt/data/TaskStore
    // Bryntum stores subtasks as children of root tasks
    const rootTasks = data.filter(row => !Boolean(row.parentId));
    data = rootTasks.map(rootTask => addSubTaskToRoot(rootTask, subTasks));
  }
  const dependencies = rows
    ?.flatMap(row => newParseRowToBryntumDependencies(row))
    ?.filter(row => row !== undefined);
  const assignments = rows
    ?.flatMap(row => parseRowToBryntumAssigments(row))
    ?.filter(row => row !== undefined);

  return { data, dependencies, assignments, totalRows: rows.length };
};

const updateBryntumEnumValue = (task: { [key: string]: any }) => ({
  ...task,
  ...(task.constraintType && {
    constraintType: task.constraintType.toLowerCase()
  }),
  ...(task.projectConstraintResolution && {
    projectConstraintResolution: task.projectConstraintResolution.toLowerCase()
  })
});

const addSubTaskToRoot = (root: any, subTasks: any[]): any => {
  const children = subTasks.filter(subtask => subtask.parentId === root.id);
  if (children.length === 0) return root;
  return {
    ...root,
    children: children.map(child => addSubTaskToRoot(child, subTasks))
  };
};
const parseRowToBryntumRow = (row: RecordTableRow, fields: Field[]) => {
  const data: { [key: string]: any } = {};

  row.tableRowFieldValues?.forEach((value: any) => {
    const linkedField = fields.find(field => field.id === value.fieldId);

    if (!linkedField) return;
    const pair = getTaskTableSystemFieldsBryntumMap(false).find(
      pair => pair.edison === linkedField.id
    );
    data[pair ? pair.bryntum : `${linkedField.name}-e365`] = getValueByFieldType(
      linkedField,
      value
    );
  });

  // Task board fields
  if (data.percentDone === 100) {
    data["status"] = "done";
  } else if ((row.percentDone as number) > 0) {
    data["status"] = "inprogress";
  } else {
    data["status"] = "todo";
  }
  // TODO : Maybe remove this later
  data["theme"] = "Theme1";

  data["segments"] = row?.configuration?.segments ?? null;

  //Needed to prepare table to accept live updates
  data["pendingUpdate-e365"] = false;

  // Removing assignements from the bryntum task data as we parsed the assignemements into a seperate assignment store
  const { assignments, ...rest } = data;
  return rest;
};

const newParseRowToBryntumDependencies = (row: RecordTableRow) => {
  return row.dependencies?.successors ?? null;
};

const parseRowToBryntumAssigments = (row: RecordTableRow) => {
  const assignments = row.tableRowFieldValues?.find(
    value => value.fieldId === SystemFieldType.AssignedResource
  );
  if (!assignments?.extendedValue?.value) return;

  return (assignments?.extendedValue?.value as any[])?.map((a, i) => {
    return {
      id: a.id,
      event: getUniqueIdFromRow(row),
      resource: a.resourceId,
      units: a.units
    };
  });
};
