import { Container, RecordType, WorkflowStageViewFieldState } from "enada-common";
import { Box, Chip, Divider, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { sortContainers } from "../../../store/slices/parseFormData";
import {
  selectRecordAssociations,
  selectRecordForm,
  selectRecordTabsMissingValues,
  selectWorkflowStage
} from "../../../store/slices/recordSlice";
import { EdisonProjectTabs, EdisonTypography } from "enada-components";
import { getRowVisibility } from "../../workflowdesigner/workflowlivepreview/utils/handleFormVisibility";
import SingleTab from "./SingleTab";
import WebStoriesOutlinedIcon from "@mui/icons-material/WebStoriesOutlined";
import { useEffect } from "react";
import "./projectTabs.scss";
import Discover from "../../discover/Discover";
import { useTranslation } from "react-i18next";
import AssociatedRecordsAlert from "../../programs/associatedrecords/AssociatedRecordsAlert";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { TFunction } from "i18next";
import {
  getIdeasQuickFilters,
  getIdeasQuickOrderOps,
  getProgramQuickFilters
} from "../../discover/quickFilters";
import {
  getRecordsAsync,
  selectRecordById,
  selectRecordsStatus
} from "../../../store/slices/recordsSlice";
import buildQuery from "odata-query";

type ProjectTabProps = {
  scope: RecordType;
  recordId?: string;
};
// interface
const ProjectTabs = ({ scope, recordId }: ProjectTabProps) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentForm = useAppSelector(selectRecordForm);
  const currentStage = useAppSelector(selectWorkflowStage);
  const associations = useAppSelector(selectRecordAssociations);
  const record = useAppSelector(state => selectRecordById(state, recordId ?? ""));
  const recordsStatus = useAppSelector(selectRecordsStatus);

  //Added custom equality fn because by default redux compares by reference which causes unecessary rerenders
  // using string version of array improves performance.
  const tabsMissingValues = useAppSelector(
    selectRecordTabsMissingValues,
    (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)
  );

  const getTabVisibility = (tab: Container) => {
    const view = currentStage?.views ? currentStage.views[0] : undefined;
    const rowVisibilities = tab.zones?.map(row =>
      getRowVisibility(row, view?.viewFields ?? [], view?.viewTables ?? [])
    );
    if (rowVisibilities?.every(visibility => rowVisibilities[0] === visibility)) {
      return rowVisibilities[0];
    }
    return undefined;
  };

  useEffect(() => {
    //If we are in a challenge get the number of ideas for that challenge
    if (scope === RecordType.Challenges && !record && recordId && recordsStatus !== "loading") {
      const propQuery = {
        orderBy: "modified desc",
        filter: {
          id: { eq: parseInt(recordId) }
        },
        select: "id,canCreateAssociations,childRecordCount"
      };
      // Storing this in state to use canCreateAssociations in ideaFromChallengeButton.
      dispatch(
        getRecordsAsync({
          queryString: buildQuery(propQuery),
          recordType: RecordType.Challenges
        })
      );
    }
  }, [scope, recordId, dispatch, recordsStatus, record]);

  const getEndTab = () => {
    switch (scope) {
      case RecordType.Challenges:
        return getChallengesEndTab(recordId ?? "", t, record?.childRecordCount ?? 0);
      case RecordType.Programs:
        return getProgramsEndTab(recordId ?? "", t, associations);

      default:
        return undefined;
    }
  };

  return (
    <EdisonProjectTabs
      tabs={
        currentForm
          ? sortContainers(currentForm.containers || [])
              .filter(tab => getTabVisibility(tab) !== WorkflowStageViewFieldState.Hidden)
              .map((container: any) => ({
                id: container.id,
                required: tabsMissingValues?.some(missingId => missingId === container.id),
                label: container.displayName,
                children: <SingleTab container={container} />,
                includesTable: container.zones?.some(zone =>
                  zone.columns?.some(column => column?.tables?.length && column?.tables?.length > 0)
                )
              }))
          : []
      }
      height="100%"
      indicatorColour={"primary"}
      scrollable={true}
      tabTitleBoxStyling={{
        marginBottom: "20px"
      }}
      tabPanelStyles={{
        height: "100%"
      }}
      endTab={getEndTab()}
    />
  );
};

const getChallengesEndTab = (recordId: string, t: TFunction, ideas: number) => {
  return {
    id: "end",
    label: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Divider flexItem orientation="vertical" />
        <LightbulbOutlinedIcon />
        <Box>
          <EdisonTypography title={t("ideas")} variant="h4" />
        </Box>
        <Chip label={ideas} />
      </Stack>
    ),
    children: (
      <div className="project-tabs-no-shadow-discover">
        <Discover
          hideFilters={false}
          propQuery={{
            filter: {
              id: { eq: parseInt(recordId) }
            },
            select: "id,recordAssociations"
          }}
          recordId={recordId}
          getQuickFilterOverrides={getIdeasQuickFilters}
          getQuickOrderOverrides={getIdeasQuickOrderOps}
          expandedEntityForFilters={{ key: "RecordAssociations", query: { expand: "owners" } }}
          hideViewSelector={true}
          openRecordInNewTab
        />
      </div>
    )
  };
};
const getProgramsEndTab = (recordId: string, t: TFunction, associations: any) => {
  return {
    id: "end",
    label: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Divider flexItem orientation="vertical" />
        <WebStoriesOutlinedIcon />
        <Box>
          <EdisonTypography title={t("records")} variant="h4" />
        </Box>
        <Chip label={associations.length} size="small" />
      </Stack>
    ),
    children: (
      <div className="project-tabs-no-shadow-discover">
        <Discover
          hideFilters={false}
          propQuery={{
            filter: {
              id: { eq: parseInt(recordId) }
            },
            select: "id,recordAssociations"
          }}
          getQuickFilterOverrides={getProgramQuickFilters}
          expandedEntityForFilters={{ key: "RecordAssociations", query: { expand: "owners" } }}
          recordId={recordId}
          messageComponent={<AssociatedRecordsAlert />}
          openRecordInNewTab
        />
      </div>
    )
  };
};

export default ProjectTabs;
