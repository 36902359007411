import { Grid, IconButton, LinearProgress, Stack, SxProps, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/hooks";

import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { EdisonEdiIcon, EdisonTypography } from "enada-components";
import SendIcon from "@mui/icons-material/Send";
import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  StopCircleOutlined,
  LoopOutlined
} from "@mui/icons-material";
import { useDebounce } from "enada-common";
import EdiInstructionalTextPrompt from "./EdiInstructionalTextPrompt";
import { v4 as uuidv4 } from "uuid";
import EdiErrorPrompt from "./EdiErrorPrompt";
import { setEdiGeneratedRows } from "../../store/slices/recordTableSlice";
import { useGenerateTasksMutation } from "services/api";

export interface EdiResponse {
  data: EdiTask[];
  status: number;
}
export interface EdiTask {
  id: string;
  name: string;
  note: string;
  duration: number;
  isSummary: boolean;
  wbsValue: number;
  parentId?: string;
}

const EdiRowGeneration: FC<{ tableId: number }> = ({ tableId }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [tasksDescription, setTasksDescription] = useState("");
  const [tasks, setTasks] = useState<EdiTask[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<EdiTask[]>([]);
  const [ediTaskstoUpdate, setEdiTaskstoUpdate] = useState<{ add?: EdiTask[]; remove?: EdiTask[] }>(
    {}
  );

  const [generateTasks, { isLoading, isSuccess, isError }] = useGenerateTasksMutation();

  const fetchTasks = async () => {
    const tasksRequest = await generateTasks(tasksDescription).unwrap();

    if (tasksRequest.data && tasksRequest.status === 200) {
      const tasks = transformData(tasksRequest.data);
      setTasks(tasks);
    }
  };

  useEffect(() => {
    setTasks([]);
    setSelectedTasks([]);
    setTasksDescription("");
  }, [tableId]);

  const transformData = (tasks: EdiTask[]) => {
    return tasks.map((task: EdiTask) => {
      const newId = uuidv4();
      const childTasks = tasks.filter(t => t.parentId === task.id);

      childTasks.forEach(t => (t.parentId = newId));

      return { ...task, id: newId };
    });
  };

  const updateSelectedTasks = (task: EdiTask) => {
    let parentTasks: EdiTask[] = [];
    if (task?.parentId) {
      parentTasks = getAllParentTasks(task.parentId);
    }

    setSelectedTasks(prev => {
      const isTaskAlreadySelected = prev.find(t => t.id === task.id);
      if (!isTaskAlreadySelected) {
        const parentTasksToAdd = parentTasks.filter(t => !prev.includes(t));
        // we need to add the newly selected task plus all it's parents/ancestors
        // as Bryntum is expecting to find those. Without the parents Bryntum wil throw an expection.
        setEdiTaskstoUpdate({ add: [...parentTasksToAdd, task] });
        return [...prev, ...[...parentTasksToAdd, task]];
      } else {
        // we need to remove the deselected task plus all it's child tasks
        // if it's a summary/parent task

        setEdiTaskstoUpdate({ remove: [task, ...tasks.filter(t => t.parentId === task.id)] });
        return prev.filter(t => !(t.id === task.id || (task.isSummary && t.parentId === task.id)));
      }
    });
  };

  const addAllTasks = () => {
    setSelectedTasks(prev => {
      const updatedTasks = tasks.filter(task => !prev.find(t => t.id === task.id));
      const newTasks = updatedTasks.filter(task => !prev.find(t => t.id === task.id));
      setEdiTaskstoUpdate({ add: newTasks });
      return [...prev, ...newTasks];
    });
  };

  // recursively get all parents up the hierachy
  const getAllParentTasks = (parentId: string): EdiTask[] => {
    const parentTasks: EdiTask[] = [];
    const parentTask = tasks.find(t => t.id === parentId);
    if (parentTask) {
      parentTasks.push(parentTask);
      if (parentTask.parentId) {
        parentTasks.push(...getAllParentTasks(parentTask.parentId));
      }
    }
    return parentTasks;
  };

  useDebounce(
    () => {
      dispatch(setEdiGeneratedRows(ediTaskstoUpdate));
    },
    500,
    [ediTaskstoUpdate]
  );

  const getFetchStatusIcon = () => {
    if (isLoading) return <StopCircleOutlined sx={{ fill: "url(#edi-action-gradient)" }} />;
    if (isSuccess) return <LoopOutlined sx={{ fill: "url(#edi-action-gradient)" }} />;

    return <SendIcon sx={{ fill: "url(#edi-action-gradient)" }} />;
  };

  return (
    <ProjectRecordFlyout title="edi" titleIcon={<EdisonEdiIcon />}>
      <EdiInstructionalTextPrompt />
      <Grid
        container
        paddingTop="20px"
        height={"100%"}
        alignContent={tasks.length > 0 ? "baseline" : "end"}
      >
        {tasks.map(task => (
          <React.Fragment key={task.id}>
            <Grid
              item
              xs={11}
              padding={!task.isSummary && task.parentId ? "12px 16px 12px 30px" : "12px 12px"}
            >
              <Stack direction="row">
                <EdisonTypography
                  variant="data2"
                  title={task.name as string}
                  sx={{
                    textDecoration: task.isSummary ? "underline" : ""
                  }}
                />
              </Stack>
              {task.duration && !task.isSummary && (
                <Stack direction="row">
                  <EdisonTypography
                    sx={
                      {
                        opacity: 0.7
                      } as SxProps
                    }
                    title={`${t("ediGenerateRows.duration")}:
                  ${t("ediGenerateRows.days", { count: task.duration })}`}
                    variant="data2"
                  />
                </Stack>
              )}
            </Grid>

            <Grid item xs={1} columnSpacing={1} rowSpacing={3} alignContent="center">
              <IconButton
                data-testid={"edi-flyout-toggletask"}
                onClick={() => updateSelectedTasks(task)}
              >
                {!selectedTasks.find(t => t.id === task.id) ? (
                  <AddCircleOutlineOutlined color="action" />
                ) : (
                  <CheckCircleOutlineOutlined color="primary" />
                )}
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
        {tasks.length > 0 && (
          <Grid item xs={12} alignContent="end" textAlign={"right"}>
            <IconButton
              data-testid={"edi-flyout-addalltasks"}
              onClick={() => {
                addAllTasks();
              }}
              size="medium"
            >
              <AddCircleOutlineOutlined color="action" />
              <EdisonTypography
                title={t("ediGenerateRows.addAllButton")}
                variant="data2"
                sx={
                  {
                    textTransform: "uppercase",
                    marginLeft: ".225em"
                  } as SxProps
                }
              />
            </IconButton>
          </Grid>
        )}

        {isLoading && (
          <Grid item xs={12} paddingBottom={"20px"}>
            <LinearProgress
              variant="indeterminate"
              sx={{
                background: "linear-gradient(45deg, #005DB3, #005DB3, #00CCC0, #B9DE6B)"
              }}
              data-testid="edi-flyout-progress"
            />
          </Grid>
        )}
        {isError && <EdiErrorPrompt />}
        <Grid
          item
          xs={11}
          alignItems={"center"}
          justifySelf={"space-between"}
          columnSpacing={1}
          rowSpacing={3}
          margin={"20px 0px"}
        >
          <TextField
            fullWidth
            multiline
            sx={{ marginBottom: "0" }}
            label={t("ediGenerateRows.projectDescriptionField.label")}
            value={tasksDescription}
            onChange={event => {
              setTasksDescription(event.target.value);
            }}
            placeholder={t("ediGenerateRows.projectDescriptionField.helper")}
            disabled={isLoading}
            data-testid="edi-flyout-descriptionfield"
            onKeyDown={e => {
              if (e.key === "Enter") {
                fetchTasks();
              }
            }}
          />
        </Grid>
        <Grid item xs={1} alignContent={"center"}>
          <IconButton
            data-testid={"edi-flyout-fetchtasks"}
            sx={{ width: "48px", height: "48px" }}
            disabled={tasksDescription.length < 1}
            onClick={() => fetchTasks()}
          >
            {getFetchStatusIcon()}
          </IconButton>
        </Grid>
      </Grid>

      <svg width="0" height="0">
        <defs>
          <linearGradient
            id="edi-action-gradient"
            x1="1.85131"
            y1="20.9996"
            x2="19.6391"
            y2="0.247114"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#005DB3" />
            <stop offset="0.0001" stopColor="#005DB3" />
            <stop offset="0.489583" stopColor="#00CCC0" />
            <stop offset="1" stopColor="#B9DE6B" />
          </linearGradient>
        </defs>
      </svg>
    </ProjectRecordFlyout>
  );
};

export default EdiRowGeneration;
