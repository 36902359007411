import { useCallback, useEffect, useState } from "react";
import { Button, Divider, IconButton, Stack, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ArrowForwardIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import useEmblaCarousel from "embla-carousel-react";
import clsx from "clsx";

import { CarouselProps } from "enada-common";
import "./edisonCarousel.scss";

const EdisonCarousel = <T,>({
  data,
  getCard,
  clickMore,
  isMoreActive,
  moreText,
  topBarModule,
  noDataModule,
  showScroll
}: CarouselProps<T>) => {
  const theme = useTheme();
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const [emblaRef, embla] = useEmblaCarousel({
    dragFree: true,
    slidesToScroll: 2,
    containScroll: "trimSnaps"
  });

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    embla.on("reInit", onSelect);
    onSelect();
  }, [embla, onSelect]);

  const renderTopBar = () => (
    <Stack
      direction="row"
      className="navigation-container"
      spacing={1}
      data-testid={"edison-carousel-buttons"}
    >
      {!showScroll && (
        <>
          <IconButton
            data-testid={"edison-carousel-buttons-previous"}
            color="primary"
            size="large"
            disabled={!prevBtnEnabled}
            aria-label={"previous"}
            sx={{ padding: "0px" }}
            onClick={() => scrollPrev()}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            data-testid={"edison-carousel-buttons-next"}
            color="primary"
            size="large"
            disabled={!nextBtnEnabled}
            sx={{ padding: "0px" }}
            aria-label={"previous"}
            onClick={() => scrollNext()}
          >
            <ArrowForwardIcon />
          </IconButton>
        </>
      )}
      {isMoreActive && (
        <>
          <Divider orientation="vertical" flexItem />
          <Button
            color="primary"
            onClick={() => clickMore?.()}
            data-testid={"edison-carousel-buttons-more"}
          >
            {moreText}
          </Button>
        </>
      )}
    </Stack>
  );
  return (
    <>
      {topBarModule ? (
        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
          {topBarModule} {renderTopBar()}
        </Stack>
      ) : (
        renderTopBar()
      )}
      {!data.length ? (
        noDataModule
      ) : (
        <Stack
          className={clsx({
            "carousel-container": true,
            dark: theme.palette.mode === "dark"
          })}
          ref={emblaRef}
          data-testid={"edison-carousel"}
          sx={{ overflowX: showScroll ? "auto" : "hidden" }}
        >
          <Stack className="entry-container" direction="row" spacing={2}>
            {data.map((card, index) => (
              <div data-testid={"edison-carousel-slide"} key={index} className={`embla-styles`}>
                {getCard(card)}
              </div>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default EdisonCarousel;
export { EdisonCarousel };
