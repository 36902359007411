import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordAuth,
  RecordSettings,
  selectCurrentVersion,
  selectRecordWorkflow,
  selectWorkflowStage,
  setFlyoutId,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import useHasRecordRole from "../../../utils/hooks/useHasRecordRole";
import useHasRecordAccessRole from "../../../utils/hooks/useHasRecordAccessRole";
import useHasRole from "../../../utils/hooks/useHasRole";
import { BaseRecord, RecordAccessRole, RecordRole, RecordType, RoleType } from "enada-common";
import { Divider, ListItemIcon, MenuItem } from "@mui/material";
import { EdisonTypography } from "enada-components";
import LinkIcon from "@mui/icons-material/Link";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import HasAccessRoles from "../../hasAccessRoles/HasAccessRoles";
import AddIcon from "@mui/icons-material/Add";
import { selectRecordPendingRecordRows } from "../../../store/slices/recordTableSlice";
import { getAllowedRecordTypes } from "../../createrecordmodal/getAllowedRecordTypes";

export interface RecordActionMenuItemsProps {
  record: BaseRecord | null;
  recordAuth: RecordAuth | null;
  onClose: () => void;
  onOpenCreateProjectModal: () => void;
}

export const RecordActionMenuItems: FC<RecordActionMenuItemsProps> = ({
  record,
  onClose,
  onOpenCreateProjectModal
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const projectWorkflow = useAppSelector(selectRecordWorkflow);
  const pendingRecordTableRows = useAppSelector(selectRecordPendingRecordRows);
  const currentWorkflow = useAppSelector(selectRecordWorkflow);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const workflowStage = useAppSelector(selectWorkflowStage);

  const { hasRecordRole } = useHasRecordRole();
  const { hasRecordAccessRole } = useHasRecordAccessRole();
  const { hasRole } = useHasRole();

  const isIdea = record?.recordType === RecordType.Ideas;
  const isProject = record?.recordType === RecordType.Projects;
  const isBusinessCase = record?.recordType === RecordType.BusinessCase;

  const isLastStage = useCallback(() => {
    const lastStep = currentWorkflow?.steps?.find(
      s => !Object.prototype.hasOwnProperty.call(s, "nextSteps")
    );
    const lastStage = currentWorkflow?.stages?.find(stage => stage.name === lastStep?.name);
    return workflowStage?.id === lastStage?.id;
  }, [currentWorkflow, workflowStage]);

  const hasRolesToRenderAssociatedRecords = useMemo(
    () =>
      hasRecordAccessRole([RecordAccessRole.CreateAssociation]) ||
      hasRole([RoleType.InstanceAdmin]),
    [hasRecordAccessRole, hasRole]
  );

  const canRenderAssociatedRecords = useMemo(
    () => isProject || isBusinessCase || isIdea,
    [isProject, isBusinessCase, isIdea]
  );

  const canRenderCreateAssociatedRecord = useMemo(
    () =>
      hasRolesToRenderAssociatedRecords &&
      (isBusinessCase || isIdea) &&
      getAllowedRecordTypes(
        record?.recordType,
        workflowStage?.allowBusinessCaseCreation,
        workflowStage?.allowProjectCreation,
        workflowStage?.allowRecordCreation
      ).length > 0,
    [
      hasRolesToRenderAssociatedRecords,
      isBusinessCase,
      isIdea,
      record?.recordType,
      workflowStage?.allowBusinessCaseCreation,
      workflowStage?.allowProjectCreation,
      workflowStage?.allowRecordCreation
    ]
  );

  const canRenderRowApproval = useMemo(
    () =>
      hasRecordRole([RecordRole.Manager, RecordRole.Owner]) && pendingRecordTableRows.length > 0,
    [hasRecordRole, pendingRecordTableRows]
  );

  const canRenderStageApproval = useMemo(() => {
    if (projectWorkflow?.steps?.length === 1) return false;

    if (
      !hasRecordRole([RecordRole.Manager, RecordRole.Owner]) &&
      !hasRecordAccessRole([RecordAccessRole.Review]) &&
      !hasRole([RoleType.InstanceAdmin])
    ) {
      return false;
    }

    if (isLastStage() && !workflowStage?.hasReview) {
      if (hasRecordAccessRole([RecordAccessRole.SkipStep]) && currentWorkflow?.allowStageSkip) {
        return true;
      }
      if (hasRole([RoleType.InstanceAdmin, RoleType.RecordOwner])) {
        return true;
      }
      return false;
    }
    return true;
  }, [
    projectWorkflow?.steps?.length,
    hasRecordRole,
    hasRecordAccessRole,
    hasRole,
    isLastStage,
    workflowStage?.hasReview,
    currentWorkflow?.allowStageSkip
  ]);

  const shouldRenderMenuItems = useMemo(
    () =>
      canRenderAssociatedRecords ||
      canRenderCreateAssociatedRecord ||
      canRenderStageApproval ||
      canRenderRowApproval,
    [
      canRenderAssociatedRecords,
      canRenderCreateAssociatedRecord,
      canRenderStageApproval,
      canRenderRowApproval
    ]
  );

  if (!shouldRenderMenuItems || (currentVersion && currentVersion?.id && currentVersion.id > 0))
    return null;

  return (
    <>
      {canRenderAssociatedRecords && (!currentVersion || currentVersion.id === 0) && (
        <MenuItem
          onClick={() => {
            dispatch(setIsFlyoutOpen(true));
            dispatch(setFlyoutId(RecordSettings.associatedRecords));
            onClose();
          }}
          data-testid={`record-menu-settings-associatedRecords`}
        >
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <EdisonTypography title={t("associatedRecords")} variant="data" />
        </MenuItem>
      )}
      {canRenderCreateAssociatedRecord && (!currentVersion || currentVersion.id === 0) && (
        <MenuItem
          onClick={() => {
            onOpenCreateProjectModal();
            onClose();
          }}
        >
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <EdisonTypography title={t("createAssociatedRecord")} variant="data" />
        </MenuItem>
      )}
      <HasAccessRoles
        roles={
          workflowStage?.hasReview
            ? [RecordAccessRole.Continue, RecordAccessRole.SkipStep, RecordAccessRole.Review]
            : [RecordAccessRole.Continue, RecordAccessRole.SkipStep]
        }
      >
        {canRenderStageApproval && (!currentVersion || currentVersion.id === 0) && (
          <MenuItem
            onClick={() => {
              dispatch(setIsFlyoutOpen(true));
              dispatch(setFlyoutId(RecordSettings.stageApproval));
              onClose();
            }}
            data-testid={`record-menu-settings-stageReview`}
          >
            <ListItemIcon>
              <RateReviewOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <EdisonTypography title={t("stageReview")} variant="data" />
          </MenuItem>
        )}
      </HasAccessRoles>
      {canRenderRowApproval && (!currentVersion || currentVersion.id === 0) && (
        <MenuItem
          onClick={() => {
            dispatch(setIsFlyoutOpen(true));
            dispatch(setFlyoutId(RecordSettings.tableRowReview));
            onClose();
          }}
        >
          {t("reviewChanges")}
        </MenuItem>
      )}
      <Divider />
    </>
  );
};

export default RecordActionMenuItems;
