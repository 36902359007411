import { getHeaders } from "../utils/getHeaders";

export function fetchFields(
  accessToken: string,
  endpoint: string,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "GET",
    headers: getHeaders(accessToken, endpoint, headers)
  };

  return fetch(`${endpoint}`, options)
    .then(response => {
      return response.json();
    })
    .catch(error => console.log(error));
}

export function saveFields(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };
  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function restoreEndpoint(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };

  return fetch(`${endpoint}/restore`, options)
    .then(response => {
      if (response.ok) return response[[200, 204].includes(response.status) ? "text" : "json"]();
    })
    .catch(error => console.log(error));
}

export function recycleEndpoint(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "DELETE",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };

  return fetch(`${endpoint}/recycle`, options)
    .then(response => {
      if (response.ok) return response[response.status == 204 ? "text" : "json"]();
      return response.json();
    })
    .catch(error => console.log(error));
}

export function deleteEndpoint(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "DELETE",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };

  return fetch(`${endpoint}`, options)
    .then(response => {
      if (response.ok) {
        return response[response.status == 204 ? "text" : "json"]();
      } else {
        return response.json();
      }
    })
    .catch(error => console.error(error));
}
export function deleteSingleElement(
  accessToken: string,
  endpoint: string,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "DELETE",
    headers: getHeaders(accessToken, endpoint, headers)
  };

  return fetch(`${endpoint}`, options)
    .then(response => {
      if (response.ok) return response[response.status == 204 ? "text" : "json"]();
    })
    .catch(error => console.log(error));
}

export function updateFields(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "PUT",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };

  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function fetchSingleElement(
  accessToken: string,
  endpoint: string,
  id: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "GET",
    headers: getHeaders(accessToken, endpoint, headers)
  };
  return fetch(`${endpoint}/${id}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function fetchBoolean(
  accessToken: string,
  endpoint: string,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "GET",
    headers: getHeaders(accessToken, endpoint, headers)
  };
  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function postHttpRequest(
  accessToken: string,
  endpoint: string,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers)
  };
  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function recycleModuleEndpoint(
  accessToken: string,
  endpoint: string,
  id: any,
  headers: Headers
): Promise<unknown> {
  const options = {
    method: "DELETE",
    headers: getHeaders(accessToken, endpoint, headers)
  };

  return fetch(`${endpoint}/${id}/recycle`, options)
    .then(response => {
      if (response.ok) return response[response.status == 204 ? "text" : "json"]();
      return response.json();
    })
    .catch(error => console.log(error));
}

export function fetchResourcesTemp(
  accessToken: string,
  endpoint: string,
  headers?: Headers
): Promise<unknown> {
  const headersUpdated = getHeaders(accessToken, endpoint, headers);
  const options = {
    method: "GET",
    headers: headersUpdated
  };

  return fetch(`${endpoint}`, options)
    .then(response => {
      return response.json();
    })
    .catch(error => console.log(error));
}

export function saveRoles(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };
  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export function savePermissionSettings(
  accessToken: string,
  endpoint: string,
  data: any,
  headers?: Headers
): Promise<unknown> {
  const options = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers),
    body: JSON.stringify(data)
  };
  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
