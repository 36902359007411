import { Choice } from "enada-common";
import { Autocomplete, Box, TextField } from "@mui/material";
import { FC, useState } from "react";
import EdisonChip from "../chip/EdisonChip";

export interface EdisonChoiceFieldProps {
  value: Choice[];
  onChange: (changeValue: Choice[]) => void;
  readOnly: boolean;
  disabled: boolean;
  label: string;
  choices: Choice[];
}
const EdisonChoiceField: FC<EdisonChoiceFieldProps> = ({
  value,
  onChange,
  readOnly,
  disabled,
  label,
  choices
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleDelete = (id: string | number) => {
    onChange(value.filter(valueOption => valueOption.id !== id));
  };

  return (
    <Autocomplete
      data-testid={"edison-choice-field"}
      multiple
      size={"small"}
      options={choices}
      value={value}
      onChange={(_event, changeValue) => {
        onChange(changeValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={params => (
        <TextField
          data-testid={"edison-choice-field-input"}
          {...params}
          label={label}
          variant="standard"
        />
      )}
      renderTags={tagValue =>
        tagValue.map((option, index) => (
          <Box key={option.id + index.toString()} marginRight="3px">
            <EdisonChip
              data-testid={`edison-choice-field-chip-${index}`}
              color="primary"
              label={option.label}
              key={"edison-chip" + option.id + index.toString()}
              id={option.id}
              onDelete={handleDelete}
            />
          </Box>
        ))
      }
    />
  );
};

export default EdisonChoiceField;
export { EdisonChoiceField };
