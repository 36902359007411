import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UnpluggedImgLight from "../../assets/unpluggedImgLight.png";
import UnpluggedImgDark from "../../assets/unpluggedImgDark.png";
import E365Logo from "../../assets/edison365logo.png";
import E365LogoDark from "../../assets/edison365logodark.png";
import { selectThemeName } from "../../store/slices/userSlice";
import "./error.scss";
import { useAppSelector } from "../../store/hooks";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    const path = `/${tenantStorage}/${instanceStorage}/`;
    navigate(path);
  };

  const tenantStorage = localStorage.getItem("tenant");
  const instanceStorage = localStorage.getItem("instance");

  const themeName = useAppSelector(selectThemeName);

  const somethingWrongImg = themeName === "dark" ? UnpluggedImgDark : UnpluggedImgLight;

  const edison365Logo = themeName === "dark" ? E365LogoDark : E365Logo;

  const { t } = useTranslation(["common"]);

  return (
    <>
      <img className="e365-logo" src={edison365Logo} alt={t("edison365Logo")} />
      <Stack
        className="error-page"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "80vh" }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img
            className="something-wrong-logo"
            src={somethingWrongImg}
            alt={t("weCouldntFindThisPage")}
          />
          <Box className="description">
            <Typography sx={{ fontSize: 20, fontWeight: 600, letterSpacing: 0.15 }} variant="h1">
              {t("weCouldntFindThisPage")}
            </Typography>
          </Box>
          <Box className="description">
            <Typography sx={{ fontSize: 16, fontWeight: 400, letterSpacing: 0.15 }}>
              {t("theseThingsHappen")}
            </Typography>
          </Box>
          <Box className="homepage-button">
            <Button variant="contained" onClick={goHome}>
              {t("goToHomepage")}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
export default PageNotFound;
