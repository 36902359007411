import { FilterPropertyType, OdataOperations, RecordType } from "enada-common";
import { TFunction } from "i18next";

export const getQuickFilters = (currentUserId: string, t: TFunction): OdataOperations => {
  return {
    skip: 0,
    filters: [
      {
        id: 1001,
        displayName: t("projects"),
        property: "recordType",
        propertyType: FilterPropertyType.String,
        value: RecordType.Projects,
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1002,
        displayName: t("businessCases"),
        property: "recordType",
        propertyType: FilterPropertyType.String,
        value: RecordType.BusinessCase,
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1003,
        displayName: t("challenges"),
        property: "recordType",
        propertyType: FilterPropertyType.String,
        value: RecordType.Challenges,
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1004,
        displayName: t("addedToProgram"),
        property: "canAddToProgram",
        propertyType: FilterPropertyType.String,
        value: "Added",
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1005,
        displayName: t("availableToAdd"),
        property: "canAddToProgram",
        propertyType: FilterPropertyType.String,
        value: "NotAdded",
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1006,
        displayName: t("ownByMe"),
        property: "createdBy",
        propertyType: FilterPropertyType.String,
        value: currentUserId,
        operator: "eq",
        isQuickFilter: true,
        objectArrayProperty: "entityId"
      },
      {
        id: 1007,
        displayName: t("favourited"),
        property: "favourited",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true
      }
    ]
  };
};
