import { WorkflowReviewState, WorkflowStageView, WorkflowTaskState } from "enada-common";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Theme,
  Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getRecordWorkflowReviewAsync,
  postRecordWorkflowReviewAsync,
  postRecordWorkflowTaskAsync,
  selectRecordAuth,
  selectRecordFieldValues,
  selectRecordType,
  selectWorkflowStage,
  selectWorkflowUserReviews
} from "../../../../store/slices/recordSlice";
import { EdisonAlert, EdisonTypography } from "enada-components";
import "./stagereviewapproval.scss";
import { getMissingRequiredValues } from "../normalstageapproval/NormalStageApproval";
import ProgressionBlockAlert from "../progressionblockalert/ProgressionBlockAlert";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { selectRecordTableBackendRowValues } from "../../../../store/slices/recordTableSlice";

const StageReviewApproval = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const review = useAppSelector(selectWorkflowUserReviews);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const currentStage = useAppSelector(selectWorkflowStage);
  const fieldValues = useAppSelector(selectRecordFieldValues);
  const rowValues = useAppSelector(selectRecordTableBackendRowValues);
  const [comments, setComments] = useState(review?.workflowReview?.comments);

  const stageView =
    currentStage?.views && currentStage?.views?.length !== 0
      ? (currentStage?.views as WorkflowStageView[])[0] // TODO : review this when we can add multiple views to the a workflow
      : undefined;

  const { missingFields, missingTables } = getMissingRequiredValues(
    fieldValues,
    rowValues,
    stageView
  );
  const blockProgression = missingFields.length !== 0 || missingTables.length !== 0;

  useEffect(() => {
    if (!recordAuth) return;
    dispatch(getRecordWorkflowReviewAsync({ recordAuth, recordType }));
  }, []);

  useEffect(() => {
    setComments(review?.workflowReview?.comments ?? "");
  }, [review?.workflowReview?.comments]);

  const isTaskCompleted = (index?: number) => {
    if (!review) return false;
    if (review.workflowTasks === undefined) return false;
    return review.workflowTasks.some(
      workflowTask =>
        workflowTask?.task === index && workflowTask.taskState === WorkflowTaskState.Completed
    );
  };
  const submitReview = async (reviewState: WorkflowReviewState) => {
    if (!recordAuth) return;
    await dispatch(
      postRecordWorkflowReviewAsync({
        recordAuth: recordAuth,
        review: { ...review?.workflowReview, comments, reviewState },
        recordType
      })
    );
  };

  const completedTasks = workflowStage?.tasks?.filter(
    task =>
      review?.workflowTasks?.find(reviewTask => reviewTask.task === task.id)?.taskState ===
        WorkflowTaskState.Completed ?? []
  );
  const tasksNotCompleted = completedTasks?.length !== workflowStage?.tasks?.length;

  const isDisabled = tasksNotCompleted || blockProgression;
  return (
    <Stack className="review-stage-approval-root" spacing={2}>
      {tasksNotCompleted && (
        <EdisonAlert
          severity="info"
          heading={t("submittingReview")}
          subHeading={t("submittingReviewMessage")}
        />
      )}
      {!tasksNotCompleted && blockProgression && (
        <Stack>
          <ProgressionBlockAlert
            missingFields={missingFields}
            missingTables={missingTables}
            type={"Review"}
          />
          <br />
        </Stack>
      )}
      {review?.workflowReview?.reviewState === WorkflowReviewState.Approved ||
      review?.workflowReview?.reviewState === WorkflowReviewState.Rejected ? (
        <Stack spacing={2}>
          <EdisonTypography title={t("checklist")} variant="h3" />

          <EdisonTypography
            title={`${completedTasks?.length} / ${workflowStage?.tasks?.length} ${t("items")}`}
            variant="helpertext"
          />
          <EdisonTypography title={t("decision")} variant="h3" />

          <EdisonTypography
            title={review.workflowReview.comments ?? ""}
            variant="helpertext"
            sx={{ textWrap: "wrap" }}
          />
          <Stack direction="row" spacing={1}>
            {review.workflowReview.reviewState === WorkflowReviewState.Approved ? (
              <CheckCircleRoundedIcon color="success" />
            ) : (
              <CancelOutlinedIcon color="error" />
            )}
            <EdisonTypography
              title={
                review.workflowReview.reviewState === WorkflowReviewState.Approved
                  ? t("approved")
                  : t("rejected")
              }
              variant="data"
              sx={(theme: Theme) => ({
                color:
                  review?.workflowReview?.reviewState === WorkflowReviewState.Approved
                    ? theme.palette.success.main
                    : theme.palette.error.main
              })}
            />
          </Stack>
          <Divider flexItem />
          <Alert severity="info">
            <AlertTitle>{t("thanksForReview")}</AlertTitle>
            <p> {t("approvalMessage")}</p>
          </Alert>
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Stack spacing={1}>
            <Stack>
              <EdisonTypography title={`${t("currentStage")}:`} variant="data" />
              <Stack className="current-stage">
                <EdisonTypography
                  title={workflowStage?.displayName ?? (workflowStage?.name as string)}
                  variant="data"
                />
                <EdisonTypography title={workflowStage?.description ?? ""} variant="data2" />
              </Stack>
            </Stack>
            {Boolean(currentStage?.tasks?.length) && (
              <EdisonTypography title={t("checklist")} variant="h3" />
            )}
            <FormGroup>
              {workflowStage?.tasks?.map((task, index) => (
                <FormControlLabel
                  key={index}
                  label={task.description}
                  control={
                    <Checkbox
                      color="success"
                      checked={isTaskCompleted(task.id)}
                      onChange={e => {
                        if (!recordAuth) return;
                        const currentTask = review?.workflowTasks?.find(
                          workflowTask => workflowTask.task === task.id
                        );
                        dispatch(
                          postRecordWorkflowTaskAsync({
                            recordAuth,
                            task: {
                              ...currentTask,
                              task: task.id,
                              taskState: e.target.checked
                                ? WorkflowTaskState.Completed
                                : WorkflowTaskState.InProgress
                            },
                            recordType
                          })
                        );
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Stack>
          <Divider flexItem />
          <EdisonTypography title={t("decision")} variant="h3" />
          <EdisonTypography
            title={t("decisionMessage")}
            variant="helpertext"
            sx={{ "text-wrap": "revert" }}
          />
          {/* Removed while we wait for the BE to accept comments */}
          {/* <TextField
            multiline
            label={t("comments")}
            value={comments}
            onChange={e => {
              setComments(e.target.value);
            }}
          /> */}
          <Alert severity="warning">{t("correctDecisionMessage")}</Alert>
          <Stack direction="row" spacing={2} className="button-container">
            <Tooltip
              disableFocusListener={isDisabled}
              disableHoverListener={isDisabled}
              disableInteractive={isDisabled}
              title={t("completedItemsMessage")}
            >
              <div>
                <Button
                  variant="contained"
                  color="success"
                  disabled={isDisabled}
                  onClick={() => submitReview(WorkflowReviewState.Approved)}
                >
                  {t("approve")}
                </Button>
              </div>
            </Tooltip>

            <div>
              <Button
                variant="outlined"
                color="error"
                onClick={() => submitReview(WorkflowReviewState.Rejected)}
              >
                {t("reject")}
              </Button>
            </div>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default StageReviewApproval;
