import { ReactNode } from "react";
import {
  Field,
  FieldDataType,
  TableDataType,
  UserCurrencyFieldProps,
  UserNumberFieldProps,
  UserPercentageFieldProps
} from "enada-common";
import UserRichTextField from "../../richtext/UserRichTextField";
import UserMultiChoiceField from "../../choice/UserMultiChoiceField";
import UserDatePickerField from "../../datepicker/UserDatePickerField";
import UserPeoplePickerField from "../../peoplepicker/UserPeoplePickerField";
import UserSwitchField from "../../switch/UserSwitchField";
import UserTextField from "../../text/UserTextField";
import UserMultiLevelChoiceField from "../../multilevelchoice/UserMultiLevelChoiceField";
import UserURLField from "../../url/UserURLField";
import UserDateTimePickerField from "../../datetime/UserDateTimeField";
import UserCurrencyField from "../../currency/UserCurrencyField";
import UserEmailField, { UserEmailFieldProps } from "../../email/UserEmailField";
import UserPhoneField from "../../phone/UserPhoneField";
import ReadOnlyWrapper from "../../../utils/readonlywrapper/ReadOnlyWrapper";
import UserSingleChoiceField from "../../choice/UserSingleChoiceField";
import UserPercentageField from "../../percentage/UserPercentageField";
import UserNumberField from "../../number/UserNumberField";
import UserCalculatedField from "./calculated/UserCalculatedField";

const TypeToComponentMapper = (
  component: Field,
  readOnly: boolean,
  isInTable: boolean,
  useInternalState: boolean,
  // Need all fields in table to map between the name of the field in bryntum and the fieldId in calculation for calculated field.
  fieldsInTable: Field[],
  record: any, // Record is needed in order to get values for calculated field
  value?: any,
  onChange?: any,
  keepReadOnly = false,
  source?: TableDataType,
  dynamicHeightLabel?: string, // Local storage key that holds the tables current datawrapping value
  maximumLength: number | undefined = undefined
): ReactNode => {
  // TODO :  Revisit reading of dynamic readOnly value for custom fields after release 22/06/2023

  switch (component.dataType) {
    case FieldDataType.Switch:
      return (
        <ReadOnlyWrapper
          Component={UserSwitchField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            offLabel: component.configuration?.noLabel as string,
            onLabel: component.configuration?.yesLabel as string,
            defaultValue: component.configuration?.defaultToYes as boolean
          }}
        />
      );
    case FieldDataType.TextBox:
      return (
        <ReadOnlyWrapper
          Component={UserTextField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            isIconOn: false,
            maximumLength
          }}
        />
      );
    case FieldDataType.Choice:
      return (
        <ReadOnlyWrapper
          Component={UserSingleChoiceField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            choices: (component.dataStructure as any).choices,
            helperText: "",
            isMultiChoice: false
          }}
        />
      );
    case FieldDataType.MultiChoice:
      return (
        <ReadOnlyWrapper
          Component={UserMultiChoiceField}
          useDynamicHeightWrapper={true}
          dynamicHeightLabel={dynamicHeightLabel}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: "",
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            choices: (component.dataStructure as any).choices,
            isMultiChoice: component.dataType === FieldDataType.MultiChoice,
            helperText: ""
          }}
        />
      );
    case FieldDataType.MultiLevelChoice:
    case FieldDataType.MultiMultiLevelChoice:
      return (
        <ReadOnlyWrapper
          Component={UserMultiLevelChoiceField}
          useDynamicHeightWrapper={true}
          dynamicHeightLabel={dynamicHeightLabel}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: "",
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            choices: (component.dataStructure as any).choices,
            helperText: ""
          }}
        />
      );

    case FieldDataType.Date:
      return (
        <ReadOnlyWrapper
          Component={UserDatePickerField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            isIconOn: false
          }}
        />
      );
    case FieldDataType.DateTime:
      return (
        <ReadOnlyWrapper
          Component={UserDateTimePickerField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            isIconOn: false
          }}
        />
      );
    // case FieldDataType.Image:
    //   return (
    //     <ReadOnlyWrapper
    //       Component={UserImage}
    //       initKeepReadOnly={keepReadOnly}
    //       source={source}
    //       props={{
    //         ...(component.configuration as any),
    //         label: component.displayName ?? "",
    //         useInternalState,
    //         value,
    //         onChange,
    //         isInTable,
    //         readOnly,
    //         accept: "image/png, image/jpg, image/jpeg",
    //       }}
    //     />
    //   );
    case FieldDataType.People:
    case FieldDataType.MultiPeople:
      return (
        <ReadOnlyWrapper
          Component={UserPeoplePickerField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            disabled: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            multiple: component.dataType === FieldDataType.MultiPeople,
            allowGroups: Boolean(component.configuration?.allowGroups),
            maxAvatars: 5
          }}
        />
      );
    case FieldDataType.Url:
      return (
        <ReadOnlyWrapper
          Component={UserURLField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable,
            autoFocus: false
          }}
        />
      );
    case FieldDataType.RichText:
      return (
        <ReadOnlyWrapper
          Component={UserRichTextField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={{
            ...component.configuration,
            label: component.displayName as string,
            useInternalState: useInternalState,
            readOnly: readOnly,
            value: value,
            onChange: onChange,
            isInTable: isInTable
            //initialValue: []
          }}
        />
      );
    case FieldDataType.Currency:
      return (
        <ReadOnlyWrapper
          Component={UserCurrencyField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={
            {
              ...component.configuration,
              label: component.displayName as string,
              useInternalState: useInternalState,
              readOnly: readOnly,
              value: value,
              onChange: onChange,
              isInTable: isInTable,
              currency: component.currency,
              min: component.min,
              max: component.max,
              maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
            } as UserCurrencyFieldProps
          }
        />
      );
    case FieldDataType.Email:
      return (
        <ReadOnlyWrapper
          Component={UserEmailField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={
            {
              ...component.configuration,
              label: component.displayName as string,
              useInternalState: useInternalState,
              readOnly: readOnly,
              value: value,
              onChange: onChange,
              isInTable: isInTable
            } as UserEmailFieldProps
          }
        />
      );
    case FieldDataType.Percentage:
      return (
        <ReadOnlyWrapper
          Component={UserPercentageField}
          source={source}
          initKeepReadOnly={keepReadOnly}
          props={
            {
              ...component.configuration,
              label: component.displayName as string,
              useInternalState: useInternalState,
              readOnly: readOnly,
              value: value,
              onChange: onChange,
              isInTable: isInTable,
              autoFocus: true,
              min: component.min,
              max: component.max,
              maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
            } as UserPercentageFieldProps
          }
        />
      );
    case FieldDataType.Number:
      return (
        <ReadOnlyWrapper
          Component={UserNumberField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={
            {
              ...component.configuration,
              label: component.displayName as string,
              useInternalState: useInternalState,
              readOnly: readOnly,
              value: !isNaN(value) ? Number(value).toLocaleString() : "",
              onChange: onChange,
              isInTable: isInTable,
              min: component?.configuration?.min,
              max: component?.configuration?.max,
              maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
            } as UserNumberFieldProps
          }
        />
      );
    case FieldDataType.Phone:
      return (
        <ReadOnlyWrapper
          Component={UserPhoneField}
          initKeepReadOnly={keepReadOnly}
          source={source}
          props={{
            ...component.configuration,
            label: component.displayName ?? "",
            readOnly,
            value,
            onChange,
            useInternalState,
            enableSearch: false,
            isInTable,
            autoFocus: true
          }}
        />
      );
    case FieldDataType.Calculated:
      return (
        <ReadOnlyWrapper
          Component={UserCalculatedField}
          initKeepReadOnly={true}
          source={source}
          props={{
            ...component.configuration,
            label: component.displayName ?? "",
            readOnly,
            value,
            onChange,
            useInternalState,
            isInTable,
            autoFocus: true,
            record: record,
            fieldsInTable: fieldsInTable,
            expression: component.expression,
            calculatedExpression: component.calculatedExpression
          }}
        />
      );
    default:
      return <div> Not Implemented</div>;
  }
};

export default TypeToComponentMapper;
