import { TypographyTypesList, TypographyVariant } from "enada-common";
import { useMemo } from "react";

export const useTypographyFromVariant = (variant: TypographyVariant) => {
  const variantData = useMemo(
    () =>
      TypographyTypesList.find(typography => typography.variantName === variant) ??
      TypographyTypesList[0],
    [variant]
  );

  return { variantData };
};
