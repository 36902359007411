import { Divider, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../userprofile/UserProfile";
import DashboardItemsTable from "./dashboarditemstable/DashboardItemsTable";
import "./dashboardtab.scss";
import MyFavourites from "./myfavourites/MyFavourites";
import { WorkItemType } from "enada-common";
import { isDateInNext7Days } from "../../../utils/timeManipulation";
import { useGetDashboardItemsQuery, useGetUserQuery } from "services/api";

const DashboardTab = ({ onActionClick }: { onActionClick: (itemType: WorkItemType) => void }) => {
  const { t } = useTranslation(["common"]);

  const { data: user } = useGetUserQuery();

  const { data: dashboardItems = [] } = useGetDashboardItemsQuery();
  const thisWeekItems = useMemo(
    () => dashboardItems.filter(item => isDateInNext7Days(item.dueDate)),
    [dashboardItems]
  );
  const overDueItems = useMemo(
    () =>
      dashboardItems.filter(
        item =>
          item.dueDate &&
          dayjs(item.dueDate).isBefore(dayjs(), "day") &&
          item.itemType !== WorkItemType.ItemList &&
          item.itemType !== WorkItemType.ItemPeriodic
      ),
    [dashboardItems]
  );

  return (
    <Stack className="dashboard-tab-root">
      <Divider flexItem />
      <Stack direction="row">
        <Stack className="table-container">
          <DashboardItemsTable
            overdue={true}
            items={overDueItems}
            title={t("overdueItems")}
            subtitle={t("overdueItemsDashboardMessage")}
            onActionClick={onActionClick}
          />
        </Stack>
        <Divider flexItem orientation="vertical" />
        <Stack className="table-container">
          <DashboardItemsTable
            overdue={false}
            items={thisWeekItems}
            title={t("thisWeeksItems")}
            subtitle={t("thisWeeksItemsDashboardMessage")}
            onActionClick={onActionClick}
          />
        </Stack>
      </Stack>
      <Divider flexItem />
      <MyFavourites />
      <Divider flexItem />
      {user?.id && <UserProfile userId={user.id} />}
    </Stack>
  );
};

export default DashboardTab;
