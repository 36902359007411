import { TypographyType } from "../models/frontend/shared/EdisonTypographyProps.model";

export const TypographyTypesList: TypographyType[] = [
  {
    variantName: "h1",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.15
  },
  {
    variantName: "h2",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.15
  },
  {
    variantName: "h3",
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "h4",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "h5",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.17
  },
  {
    variantName: "h6",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.15
  },
  {
    variantName: "tabtitle",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.08
  },
  {
    variantName: "data",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "data2",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.17
  },
  {
    variantName: "fieldtitle",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "menuitem",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "dropdownsubheader",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.1
  },
  {
    variantName: "admintile",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0
  },
  {
    variantName: "avatarinitials",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0.14
  },
  {
    variantName: "tableheader",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.17
  },
  {
    variantName: "subtitle1",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "subtitle2",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.1
  },
  {
    variantName: "overline",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.1
  },
  {
    variantName: "alerttitle",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.15
  },
  {
    variantName: "badgelabel",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.14
  },
  {
    variantName: "buttonlarge",
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: 0.46
  },
  {
    variantName: "buttonmedium",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.4
  },
  {
    variantName: "buttonsmall",
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: 0.46
  },
  {
    variantName: "inputlabel",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "helpertext",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4
  },
  {
    variantName: "inputtext",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.15
  },
  {
    variantName: "chip",
    fontSize: 13,
    fontWeight: 400,
    letterSpacing: 0.16
  },
  {
    variantName: "tooltip",
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0
  },
  {
    variantName: "menuitemdense",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.17
  },
  {
    variantName: "bottomnavigationactivelabel",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4
  }
];
