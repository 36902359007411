import { Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualField,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import "./richtextconfig.scss";

const RichTextConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualField = useAppSelector(selectIndividualField);

  return (
    <Stack spacing={1}>
      <Stack direction="row" className="bounds-container">
        <TextField
          className="input"
          size="small"
          label={t("minimumLength")}
          type="number"
          variant="outlined"
          value={individualField?.min ?? null}
          onChange={e =>
            dispatch(
              updateIndividualFieldProperty({
                key: "min",
                value: e.target.value
              })
            )
          }
        />
      </Stack>
    </Stack>
  );
};

export default RichTextConfig;
