import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  ListItemText,
  Popover,
  Popper,
  Stack,
  TextField
} from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { Choice, UserMultiLevelChoiceFieldProps } from "enada-common";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./usermultilevelchoicefield.scss";
import ChoiceChip from "../choicechip/ChoiceChip";

const UserMultiLevelChoiceField: FC<UserMultiLevelChoiceFieldProps> = ({
  choices: options,
  disabled,
  error,
  helperText,
  isInTable,
  label,
  limit,
  onChange,
  readOnly,
  required,
  value,
  setReadOnly,
  autoFocus,
  dynamicHeight
}) => {
  const [inputValue, setInputValue] = useState("");
  const [internalState, setInternalState] = useState<Choice[]>(value || []);
  const [disableInput, setDisableInput] = useState<boolean>(
    limit ? internalState.length >= limit : false
  );

  useEffect(() => {
    setInternalState(value || []);
  }, [value]);

  const wbsSort = (a: Choice, b: Choice) => {
    return a.wbs.localeCompare(b.wbs, undefined, {
      numeric: true,
      sensitivity: "base"
    });
  };

  const currentInternalState = options.filter(option =>
    internalState.map(x => x.id).includes(option.id)
  );

  return (
    <div className={`multi-level-choice-field-root ${isInTable && "in-table"}`}>
      {readOnly ? (
        <Stack maxWidth={"100%"}>
          {Boolean(label) && !isInTable && (
            <EdisonTypography
              title={label}
              variant="fieldtitle"
              noWrap={false}
              sx={{
                display: "block"
              }}
            />
          )}
          {/* If Dynamic height is not set we need to render the minimized version of the choices eg. 1 choice +(num of remaining choices)
            We also render a hidden version the full listof choices in order to be able to measure the height of the container when managing
            data wrapping in tables.
           */}

          {dynamicHeight || !isInTable ? (
            renderChipsList(currentInternalState, options, readOnly, disabled)
          ) : (
            <Stack>
              <div style={{ visibility: "hidden", position: "absolute" }}>
                {renderChipsList(currentInternalState, options, readOnly, disabled)}{" "}
              </div>
              <div>
                {currentInternalState.slice(0, 1).map((choice, index) => (
                  <Box key={index} maxWidth="100%" marginRight="3px">
                    <ChoiceChip
                      label={getChoiceFullName(choice, options)}
                      colour={choice.colour}
                      readOnly={readOnly}
                      disabled={disabled}
                      tooltip={currentInternalState
                        .map(c => getChoiceFullName(c, options))
                        .join(", ")}
                    />
                    {currentInternalState.length > 1 && `\u00A0+${currentInternalState.length - 1}`}
                  </Box>
                ))}
              </div>
            </Stack>
          )}
        </Stack>
      ) : (
        <Autocomplete
          onBlur={() => {
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState);
          }}
          multiple
          ListboxProps={{ style: { paddingRight: "25px" } }}
          componentsProps={
            isInTable
              ? {
                  popper: {
                    placement: "bottom-start",
                    sx: { width: "inherit!important" }
                  }
                }
              : undefined
          }
          className={isInTable ? "in-table" : ""}
          openOnFocus
          disableCloseOnSelect={true}
          filterOptions={(options, state) => {
            const filteredChoices = options.filter(choice =>
              choice.label.toLowerCase().startsWith(state.inputValue.toLowerCase())
            );
            const filteredChoicesAndChildren: Choice[] = [];
            filteredChoices.forEach(filteredChoice => {
              const fChoice = options.filter(choice => choice.wbs.startsWith(filteredChoice.wbs));
              fChoice.forEach(choice => {
                if (!filteredChoicesAndChildren.includes(choice)) {
                  filteredChoicesAndChildren?.push(choice);
                }
              });
            });
            return filteredChoicesAndChildren.sort(wbsSort);
          }}
          options={options}
          readOnly={readOnly}
          disabled={disabled || disableInput}
          getOptionLabel={option => getChoiceFullName(option, options) ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          size={"small"}
          inputValue={inputValue}
          value={currentInternalState}
          renderTags={(tagValue, getTagProps) =>
            isInTable ? (
              renderChipsList(tagValue, options, readOnly, disabled)
            ) : (
              <>
                {tagValue.map((option, index) => (
                  <Box key={index} width="100%" marginRight="3px">
                    <ChoiceChip
                      label={getChoiceFullName(option, options)}
                      colour={option.colour}
                      readOnly={readOnly}
                      disabled={disabled}
                      additionalProps={getTagProps({ index })}
                      tooltip={currentInternalState
                        .map(c => getChoiceFullName(c, options))
                        .join(", ")}
                    />
                  </Box>
                ))}
              </>
            )
          }
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_event, changeValue: Choice[]) => {
            setDisableInput(limit ? changeValue.length >= limit : false);
            setInternalState(changeValue);
            if (isInTable) return;
            if (!onChange) return;

            onChange(changeValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              label={!isInTable && label}
              helperText={error ? helperText : ""}
              required={required}
              variant="standard"
              autoFocus={autoFocus}
              sx={{ fontSize: 12, fontWeight: 400, letterSpacing: 0.15, width: "100%" }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              className="list-item"
              style={{
                paddingLeft: `${option.wbs.split(".").length * 25 + "px"}`
              }}
              key={option.id}
            >
              <Stack flexDirection={"row"} className="list-stack">
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  className="list-checkbox"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 24 }
                  }}
                  checked={selected}
                />
                <Box>
                  <EdisonTypography variant={readOnly ? "data2" : "data"} title={option.label} />
                </Box>
              </Stack>
            </li>
          )}
        />
      )}
    </div>
  );
};

export const getChoiceFullName = (choice: Choice, options: Choice[]) => {
  let result = choice.label;
  let currentChoice: Choice | undefined = choice;
  while (currentChoice?.parentId) {
    const parent = options.find(c => c.id === currentChoice?.parentId);
    if (parent) {
      result = `${parent.label}.${result}`;
    }
    currentChoice = parent;
  }
  return result;
};

export const renderChipsList = (
  choices: Choice[],
  options: Choice[],
  readOnly?: boolean,
  disabled?: boolean
) => {
  return (
    <Stack
      // This classname is needed for the table to find the multi choice chips in order to measure their height
      className="multichoice-in-table-tags"
      direction="row"
      sx={{
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      {choices.map((choice, index) => (
        <Box key={index} maxWidth="100%" marginRight="3px">
          <ChoiceChip
            label={getChoiceFullName(choice, options)}
            colour={choice.colour}
            readOnly={readOnly}
            disabled={disabled}
            tooltip={choices.map(c => getChoiceFullName(c, options)).join(", ")}
          />
        </Box>
      ))}
    </Stack>
  );
};
export default UserMultiLevelChoiceField;
export { UserMultiLevelChoiceField };
