import { RecordType } from "enada-common";
import { getApiForVersions } from "services/APIService";
import { baseAPI } from "services/baseAPI";
import { RecordVersion } from "store/slices/recordSlice";

export const versionsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getVersions: builder.query<RecordVersion[], { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForVersions(recordType)}/record/${recordId}`
      }),
      transformResponse: (response: { data: RecordVersion[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Versions" as const, id })), "Versions"]
          : ["Versions"]
    }),

    createVersion: builder.mutation<
      RecordVersion,
      {
        recordId: number;
        recordType: RecordType;
        body: { versionName: string; versionDescription?: string };
      }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForVersions(recordType)}/${recordId}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Versions"]
    })
  })
});
