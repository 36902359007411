import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Stack
} from "@mui/material";
import { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BaseUserFieldProps } from "enada-common";
import "./userimagefield.scss";
import ImageCropper from "./ImageCropper";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import { Image } from "enada-common";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
export interface UserImageFieldProps extends BaseUserFieldProps {
  t: (key: string) => string;
  invalidMediaTypeMessage: string;
  invalidFileSizeMessage: string;
  accept: string;
  saveImage: (image: Image) => Promise<any>;
  maxFileSize?: number;
}
const UserImageField: FC<UserImageFieldProps> = ({
  t,
  label,
  disabled,
  invalidMediaTypeMessage,
  invalidFileSizeMessage,
  readOnly,
  accept,
  value,
  onChange,
  isInTable,
  setReadOnly,
  maxFileSize
}) => {
  const [internalState, setInternalState] = useState(value || { preview: "" });
  const [invalidMediaType, setInvalidMediaType] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(isInTable ?? false);

  useEffect(() => {
    setInternalState(value ?? { preview: "" });
  }, [value]);

  const setFile = (image: any) => {
    if (image && image.preview) {
      URL.revokeObjectURL(image.preview);
    }
    if (image) {
      //adding image
      const newState = Object.assign(image, {
        preview: URL.createObjectURL(image)
      });
      setInternalState(newState);
      if (onChange) onChange(newState);
    } else {
      // clearing image
      setInternalState({ preview: "" });
      if (onChange) onChange(null);
    }
  };

  function setUploadError(e: any) {
    if (e[0]?.errors[0]?.code === "file-too-large") {
      setInvalidFileSize(true);
      setInvalidMediaType(false);
    } else {
      setInvalidMediaType(true);
      setInvalidFileSize(false);
    }
    setFile(null);
  }

  const onDrop = useCallback(
    (droppedFiles: any) => {
      if (droppedFiles && droppedFiles.length > 0) {
        setInvalidMediaType(false);
        const img = droppedFiles[0];
        setFile(img);
        setCropperOpen(true);
      }
    },
    [label]
  );
  const onSave = (file: File) => {
    setFile(file);
    setCropperOpen(false);
  };
  const editImage = (e: MouseEvent) => {
    e.preventDefault();
    setCropperOpen(true);
  };
  const deleteImage = (e: MouseEvent) => {
    e.preventDefault();
    setFile(undefined);
  };
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
    disabled: disabled || readOnly,
    accept,
    maxSize: maxFileSize,
    onDropRejected: e => setUploadError(e)
  });
  const thumb = (imageUrl: string) => (
    <div className="thumb" key={internalState.name}>
      <div className="thumbInner">
        <img src={imageUrl} className="img" />
      </div>
    </div>
  );

  const getImageEditor = () => {
    return (
      <>
        <div className={!internalState?.preview ? "root" : "root no-border"}>
          {internalState?.preview && (
            <aside className="thumbContainer">{thumb(internalState?.preview)}</aside>
          )}
          {!internalState?.preview && (
            <>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Stack className="fileInputContainer" spacing={1}>
                  <FileUploadOutlinedIcon fontSize="large" color="primary" />
                  {!internalState?.preview ? (
                    <EdisonTypography
                      title={t("chooseImgFile")}
                      variant="admintile"
                    ></EdisonTypography>
                  ) : (
                    <EdisonTypography
                      title={t("chooseAlternativeImgFile")}
                      variant="admintile"
                    ></EdisonTypography>
                  )}
                  <EdisonTypography title={t("pngOrJpeg")} variant="data" />
                </Stack>
              </div>
              {invalidMediaType && (
                <div className="form-helper-styling" hidden={!invalidMediaType && !invalidFileSize}>
                  <FormHelperText error={invalidMediaType}>
                    {invalidMediaType && invalidMediaTypeMessage}
                  </FormHelperText>
                </div>
              )}
              {invalidFileSize && !invalidMediaType && (
                <div className="form-helper-styling" hidden={!invalidMediaType && !invalidFileSize}>
                  <FormHelperText error={invalidFileSize}>
                    {invalidFileSize && invalidFileSizeMessage}
                  </FormHelperText>
                </div>
              )}
            </>
          )}
        </div>

        <Stack className="divider-container">
          <Divider />
        </Stack>
        <Stack direction="row" className="toolbar" spacing={1}>
          <Button
            variant="contained"
            size="small"
            disabled={!internalState?.preview}
            onClick={e => editImage(e)}
            color="primary"
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={!internalState?.preview}
            onClick={e => deleteImage(e)}
            color="primary"
          >
            Remove
          </Button>
        </Stack>
      </>
    );
  };

  return (
    <div className="imageField">
      {readOnly ? (
        isInTable ? (
          <Box
            width="100%"
            height="100%"
            textAlign="center"
            onClick={e => {
              setUploadDialogOpen(true);
              setReadOnly && setReadOnly(false);
            }}
          >
            {internalState.preview && (
              <img
                src={internalState.preview}
                alt={label}
                className={disabled ? "imgDisabled" : "display-image"}
              />
            )}
          </Box>
        ) : (
          <img
            src={internalState.preview}
            alt={label}
            className={disabled ? "imgDisabled" : "display-image"}
          />
        )
      ) : (
        <>
          {!isInTable && (
            <EdisonTypography title={label} variant={readOnly ? "data" : "fieldtitle"} />
          )}
          {isInTable ? (
            <>
              <Box height="100%" width="100%" textAlign="center">
                {internalState.preview && (
                  <img
                    src={internalState.preview}
                    alt={label}
                    className={disabled ? "imgDisabled" : "display-image"}
                  />
                )}
              </Box>
              <Dialog
                className="imageField"
                fullWidth
                maxWidth="md"
                open={uploadDialogOpen}
                onClose={() => {
                  setUploadDialogOpen(false);
                  setReadOnly && setReadOnly(true);
                }}
                aria-labelledby="max-width-dialog-title"
              >
                <DialogTitle id="max-width-dialog-title">Upload Image</DialogTitle>
                <DialogContent>{getImageEditor()}</DialogContent>
              </Dialog>
            </>
          ) : (
            getImageEditor()
          )}
          {!readOnly && !disabled && internalState.preview && (
            <ImageCropper
              image={internalState}
              isOpen={cropperOpen}
              title="Edit Image"
              onClose={(savedImage: boolean) => {
                if (savedImage) {
                  setUploadDialogOpen(false);
                }
                setCropperOpen(false);
              }}
              onSave={onSave}
            />
          )}
        </>
      )}
    </div>
  );
};
export default UserImageField;
export { UserImageField };
