import { RecordType } from "enada-common";
import React, { FC } from "react";
import Discover from "../../components/discover/Discover";

const ProgramsDiscover: FC<object> = () => {
  return (
    <Discover
      hideFilters={false}
      propQuery={{
        select:
          "id,favourited,workflowStage,recordType,startDate,endDate,displayName,shortDescription,imageUrl,owners,averageRating,childRecordCount,percentDone,workflowStageChanged,created,approvedCount,rejectedCount,requiredApproversCount",
        expand:
          "RecordAssociations($select=id,displayName,recordType,percentDone,owners,endDate,startDate,workflowStage,workflowStageChanged,description,shortDescription,averageRating),owners",
        orderBy: "modified desc",
        filter: {
          recordType: { in: [RecordType.Programs] }
        }
      }}
      recordType={RecordType.Programs}
    />
  );
};

export default ProgramsDiscover;
