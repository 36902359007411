import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import React, { FC } from "react";
import "./skeletoncard.scss";

const EdisonSkeletonCard: FC = () => {
  return (
    <Card className="skeleton-card" data-testid={"edison-skeletons-card"}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={10} width="80%" className="skeleton" />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />

      <Skeleton animation="wave" variant="rectangular" height={170} />

      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} className="skeleton" />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
};

export default EdisonSkeletonCard;
export { EdisonSkeletonCard };
