import { Alert, Button, Snackbar } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";

export interface RecordExpiredSnackbarProps {
  open: boolean;
  onClose: () => void;
}

const RecordExpiredSnackbar: FC<RecordExpiredSnackbarProps> = ({ open, onClose }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
      open={open}
      data-testid="record-expired-snackbar"
    >
      <Alert
        severity="error"
        action={
          <Button
            onClick={() => window.location.reload()}
            endIcon={<RefreshIcon />}
            data-testid={"record-expired-snackbar-refresh-btn"}
          >
            {t("refresh")}
          </Button>
        }
      >
        {t("tokenExpiredMessage")}
      </Alert>
    </Snackbar>
  );
};

export default RecordExpiredSnackbar;
