import { Button, Divider, IconButton, Stack } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import { FilterOperation, FilterPropertyType } from "enada-common";
import { EdisonFilterSelector } from "enada-components";
import { useGetDistinctValuesQuery, useGetTemplatesQuery } from "../../../services/api";
import { useAppSelector } from "../../../store/hooks";
import { selectThemeName } from "../../../store/slices/userSlice";
import darkPallette from "../../../theme/pallettes/darkpallette.json";

type RecycleBinHeaderProps = {
  onToggleDelete: () => void;
  onToggleRestore: () => void;
  onChangeFilters: (filters: FilterOperation[]) => void;
  disableActionButton: boolean;
  activeFilters: FilterOperation[];
};

export const RecycleBinHeader: FC<RecycleBinHeaderProps> = ({
  onToggleDelete,
  onToggleRestore,
  onChangeFilters,
  disableActionButton,
  activeFilters
}) => {
  const { t } = useTranslation(["common"]);
  const [fullScreen, setFullScreen] = useState(false);

  // The following can be uncommented once the backend allows us to filter deleted records

  const { data: createdBy = [] } = useGetDistinctValuesQuery({ propertyName: "createdBy" });
  const { data: templates = [] } = useGetTemplatesQuery();

  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  const filters = useMemo(
    () => [
      {
        property: "displayName",
        displayName: t("name"),
        propertyType: FilterPropertyType.String
      },
      {
        property: "modified",
        displayName: t("modified"),
        propertyType: FilterPropertyType.Date
      },
      {
        property: "startDate",
        displayName: t("startDate"),
        propertyType: FilterPropertyType.Date
      },
      {
        property: "createdBy",
        displayName: t("createdBy"),
        propertyType: FilterPropertyType.PeoplePicker,
        options: createdBy.map((c: string) => {
          return { displayName: "", value: c };
        })
      },
      {
        property: "recordTemplateId",
        displayName: t("template"),
        propertyType: FilterPropertyType.MultiChoice,
        options: templates.map(template => {
          return { displayName: template.displayName ?? "", value: template.id };
        })
      }
    ],
    [createdBy, t, templates]
  );

  const handleFullScreen = () => {
    setFullScreen(prev => !prev);
    window.scrollTo(0, 0);
    fullScreen ? document.exitFullscreen() : document.documentElement.requestFullscreen();
  };

  return (
    <Stack
      spacing={0}
      width="full"
      bgcolor={isDarkTheme ? darkPallette.tableRowBackgroundColorOdd : "white"}
      direction="row"
      py={1}
      px={2}
      justifyContent="end"
      alignItems="center"
      sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
    >
      <EdisonFilterSelector
        filterOperations={filters}
        activeFilterOperations={activeFilters}
        onChangeFilters={onChangeFilters}
        addFilterDisplayName={t("addFilter")}
        applyDisplayName={t("apply")}
        clearDisplayName={t("clearAll").toLocaleUpperCase()}
        customTrigger={
          <IconButton>
            <FilterListOutlinedIcon />
          </IconButton>
        }
        disabled={false}
        propertyDisplayName={""}
        operatorDisplayName={""}
        valueDisplayName={""}
      />
      <IconButton onClick={handleFullScreen}>
        <FullscreenOutlinedIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem sx={{ mx: "5px" }} />
      <IconButton onClick={onToggleDelete} disabled={disableActionButton}>
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <Button
        onClick={onToggleRestore}
        disabled={disableActionButton}
        variant="contained"
        sx={{ fontSize: "12px" }}
      >
        {t("restore")}
      </Button>
    </Stack>
  );
};
