import { RecordType } from "enada-common";

export enum Steps {
  IdeaSelect, // Select Idea to associate record to
  Type, // Select Type
  Challenge, // Select Challenge if creating an Idea
  Name, // Select Record Name
  Description, // Select Record Description
  Template, // Select Record Template
  Owners, // Select Record owners
  Ideators, // Select ideators for challenges
  CopyData, // Copy data when creating a Project from Business Case
  Loading, // Loading screen while creating record
  Created, // Created Record Page
  Error // Error page
}

export const standardSteps = [
  Steps.Type,
  Steps.Name,
  Steps.Description,
  Steps.Template,
  Steps.Owners
];

export const finishingSteps = [Steps.Loading, Steps.Created, Steps.Error];

export const stepsFactory = (
  newRecordType: RecordType | undefined,
  parentRecordType: RecordType | undefined
) => {
  type stepsHandlerType = {
    stepType: { newRecordType: RecordType | undefined; parentRecordType: RecordType | undefined };
    steps: Steps[];
  };

  const stepsHandler: stepsHandlerType[] = [
    // unknown new record types
    {
      stepType: { newRecordType: undefined, parentRecordType: undefined },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: undefined, parentRecordType: RecordType.BusinessCase },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: undefined, parentRecordType: RecordType.Ideas },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: undefined, parentRecordType: RecordType.Programs },
      steps: standardSteps
    },
    // New business cases
    {
      stepType: { newRecordType: RecordType.BusinessCase, parentRecordType: undefined },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: RecordType.BusinessCase, parentRecordType: RecordType.Challenges },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: RecordType.BusinessCase, parentRecordType: RecordType.Ideas },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: RecordType.BusinessCase, parentRecordType: RecordType.Programs },
      steps: standardSteps
    },
    // New challenges
    {
      stepType: { newRecordType: RecordType.Challenges, parentRecordType: undefined },
      steps: [...standardSteps, Steps.Ideators]
    },
    {
      stepType: { newRecordType: RecordType.Challenges, parentRecordType: RecordType.Programs },
      steps: standardSteps
    },
    // New ideas
    {
      stepType: { newRecordType: RecordType.Ideas, parentRecordType: undefined },
      steps: [Steps.Type, Steps.Challenge, Steps.Name, Steps.Description, Steps.Owners]
    },
    {
      stepType: { newRecordType: RecordType.Ideas, parentRecordType: RecordType.Challenges },
      steps: [Steps.Name, Steps.Description, Steps.Owners]
    },
    // New programs
    {
      stepType: { newRecordType: RecordType.Programs, parentRecordType: undefined },
      steps: standardSteps
    },
    // New projects
    {
      stepType: { newRecordType: RecordType.Projects, parentRecordType: undefined },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: RecordType.Projects, parentRecordType: RecordType.BusinessCase },
      steps: [Steps.Name, Steps.Description, Steps.Template, Steps.Owners, Steps.CopyData]
    },
    {
      stepType: { newRecordType: RecordType.Projects, parentRecordType: RecordType.Challenges },
      steps: [Steps.IdeaSelect, Steps.Name, Steps.Description, Steps.Template, Steps.Owners]
    },
    {
      stepType: { newRecordType: RecordType.Projects, parentRecordType: RecordType.Ideas },
      steps: standardSteps
    },
    {
      stepType: { newRecordType: RecordType.Projects, parentRecordType: RecordType.Programs },
      steps: standardSteps
    }
  ];

  const steps = stepsHandler.find(
    x =>
      x.stepType.newRecordType === newRecordType && x.stepType.parentRecordType === parentRecordType
  );

  if (!steps) return [];

  return [...steps.steps, ...finishingSteps];
};
