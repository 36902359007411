import { Grid } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  selectAllNonDeletedFields,
  selectAllNonDeletedRecordSystemFields
} from "../../store/slices/fieldsSlice";
import { useAppSelector } from "../../store/hooks";
import FeatureList from "../featurelist/FeatureList";
import SearchableTabs from "../searchableTabs/SearchableTabs";
import "./features.scss";
import {
  fieldConfiguration,
  FieldDataType,
  LicenseType,
  TableDataType,
  tablesConfiguration
} from "enada-common";
import { NonSelectedItem } from "enada-common";

import { useGetTablesQuery, useGetTenantLicenseQuery } from "services/api";
import { useGetFieldsQuery } from "services/api";
import { inputSelectTenant } from "store/slices/userSlice";

export interface FeaturesProps {
  disabledItemList?: NonSelectedItem[];
  noTable?: boolean;
  fromCardDesigner?: boolean;
  hiddenTabs?: ("fields" | "tables" | "systemfields")[];
}

const Features: FC<FeaturesProps> = ({
  disabledItemList,
  noTable,
  fromCardDesigner,
  hiddenTabs
}) => {
  const { t } = useTranslation(["common"]);
  const { data: tables = [] } = useGetTablesQuery();

  const { nonDeletedFields = [], nonDeletedRecordSystemFields = [] } = useGetFieldsQuery(
    undefined,
    {
      selectFromResult: result => ({
        ...result,
        nonDeletedFields: selectAllNonDeletedFields(result),
        nonDeletedRecordSystemFields: selectAllNonDeletedRecordSystemFields(result)
      })
    }
  );
  const tenant = useAppSelector(inputSelectTenant);
  const { data: tenantLicense } = useGetTenantLicenseQuery(tenant);

  const hasProfessionalLicense = useMemo(() => {
    if (!tenantLicense?.licenseType) return;
    return tenantLicense?.licenseType >= LicenseType.Professional;
  }, [tenantLicense?.licenseType]);

  const customTableFields = useMemo(
    () => (hasProfessionalLicense ? tables : tables.filter(table => table.dataType !== "Schedule")),
    [hasProfessionalLicense, tables]
  );

  const filteredFieldsSelector = useMemo(
    () =>
      fromCardDesigner
        ? nonDeletedFields.filter(field => {
            return field.dataType !== FieldDataType.RichText;
          })
        : nonDeletedFields,
    [nonDeletedFields, fromCardDesigner]
  );

  const tabsContent = useMemo(
    () =>
      [
        {
          id: "fields",
          label: t("customFields"),
          children: (
            <FeatureList
              featureType={FieldDataType}
              getFeatures={fieldConfiguration}
              data={filteredFieldsSelector}
              disabledItemList={disabledItemList}
            />
          )
        },
        {
          id: "tables",
          label: t("customTables"),
          children: (
            <FeatureList
              featureType={TableDataType}
              getFeatures={tablesConfiguration}
              data={customTableFields}
              disabledItemList={disabledItemList}
            />
          )
        },
        {
          id: "systemfields",
          label: t("systemFields"),
          children: (
            <FeatureList
              featureType={FieldDataType}
              getFeatures={fieldConfiguration}
              data={nonDeletedRecordSystemFields}
              disabledItemList={disabledItemList}
            />
          )
        }
        // Will be added in the future
        // {
        //   id: "systemtables",
        //   label: t("systemTables"),
        //   children: (
        //     <FeatureList
        //       featureType={FieldDataType}
        //       getFeatures={fieldConfiguration}
        //       data={systemTables}
        //       disabledItemList={disabledItemList}
        //     />
        //   ),
        // },
      ].filter(tab => !(hiddenTabs as string[])?.includes(tab.id)),
    [
      disabledItemList,
      filteredFieldsSelector,
      nonDeletedRecordSystemFields,
      t,
      customTableFields,
      hiddenTabs
    ]
  );

  const filteredTabs = useMemo(
    () => (noTable ? tabsContent.filter(tab => tab.id !== "tables") : tabsContent),
    [noTable, tabsContent]
  );

  return (
    <Grid container className="features-container">
      <div className="tab-container">
        <SearchableTabs
          scrollable={true}
          tabs={filteredTabs}
          tabsToSearch={["tables", "fields", "systemfields"]}
        />
      </div>
    </Grid>
  );
};

export default Features;
