import { BaseRecord, RecordType } from "enada-common";
import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EdisonCarousel, EdisonSearchBar, EdisonTypography } from "enada-components";
import RecordContentCard from "../../../recordcontentcard/RecordContentCard";
import HasModule from "../../../hasModule/HasModule";
import buildQuery from "odata-query";
import "./createrecordselectchallenge.scss";
import { getRecords, getAccessToken, getIndividualRecord } from "../../../../services/APIService";
import { getHeadersFromAuth } from "../../../../store/slices/recordTableSlice";
import { useGetWorkflowStagesQuery } from "services/api";
import { useEffectOnce } from "react-use";

export interface CreateRecordSelectChallengeProps {
  setChallenge: (value?: BaseRecord) => void;
}

const CreateRecordSelectChallenge: FC<CreateRecordSelectChallengeProps> = ({ setChallenge }) => {
  const { t } = useTranslation(["common"]);
  const { data: stages = [] } = useGetWorkflowStagesQuery();
  const [challenges, setChallenges] = useState<BaseRecord[]>([]);
  const [skip, setSkip] = useState(0);

  const [search, setSearch] = useState("");

  const filteredRecords = challenges.filter(record => {
    const isChallengeAndCanCreate =
      record.recordType === RecordType.Challenges &&
      stages.find(stage => stage.id === record.workflowStage)?.allowRecordCreation;

    if (!isChallengeAndCanCreate) return false;

    if (search)
      return (
        record.displayName?.toLowerCase().includes(search.toLowerCase()) ||
        record.description?.toLowerCase().includes(search.toLowerCase())
      );

    return record;
  });

  const [expandedRecord, setExpandedRecord] = useState<number | undefined>(undefined);
  const [selectedChallengeId, setSelectedChallengeId] = useState<number>();

  const fetchChallenges = async () => {
    const odataQuery = {
      expand: "owners",
      orderBy: "modified desc",
      filter: {
        recordType: { in: [RecordType.Challenges] },
        canCreateAssociations: { eq: true }
      },
      skip: skip
    };
    const response = await getRecords(buildQuery(odataQuery));
    const records = response?.value;
    if (records && records.length > 0) {
      setChallenges(prev => [...prev, ...(response.value as BaseRecord[])]);

      //if there are no more records to fetch , set Skip to -1
      setSkip(prev => (records.length === 20 ? prev + 20 : -1));
    }
  };
  useEffectOnce(() => {
    fetchChallenges();
  });

  const onRecordClick = async (record: BaseRecord) => {
    setSelectedChallengeId(record.id);

    const additionalHeaders = new Headers();

    additionalHeaders.append("edison365-sessionid", "frontend");
    const tokenResult = (
      await getAccessToken(record?.id as number, record.recordType, additionalHeaders)
    ).data;
    const projectAuth = {
      permissionToken: tokenResult.permissionToken,
      sessionId: "frontend",
      details: tokenResult.details
    };

    const fullRecord = await getIndividualRecord(
      record.id as number,
      record.recordType,
      getHeadersFromAuth(projectAuth)
    );
    setChallenge({
      ...record,
      recordPermissions: fullRecord.data.recordPermissions
    });
  };

  const recordCard = (record: BaseRecord) => {
    return (
      <RecordContentCard
        stages={stages}
        record={record}
        isExpanded={expandedRecord ? expandedRecord === record.id : false}
        setExpandedRecord={setExpandedRecord}
        onRecordClick={onRecordClick}
        isSelected={selectedChallengeId === record.id}
        hideFooterFeatures={true}
      />
    );
  };

  return (
    <HasModule recordType={RecordType.Challenges as RecordType} key={RecordType.Challenges}>
      <Stack className="create-record-select-challenge-root">
        <EdisonCarousel
          data={filteredRecords}
          isMoreActive={skip !== -1}
          moreText={t("more")}
          clickMore={() => {
            fetchChallenges();
          }}
          getCard={recordCard}
          topBarModule={
            <Stack direction={"row"} className="title-container">
              <EdisonTypography
                title={`${t("latest")} ${t(RecordType.Challenges)}`}
                variant="h5"
                sx={{ fontWeight: "bold" }}
              />
              <Stack direction="row" spacing={1}>
                <EdisonSearchBar
                  placeholder={t("search")}
                  searchFilter={search}
                  setSearchFilter={setSearch}
                />
              </Stack>
            </Stack>
          }
          noDataModule={<div style={{ height: "15em" }}> {t("noData")}</div>}
          showScroll={true}
        />
      </Stack>
    </HasModule>
  );
};
export default CreateRecordSelectChallenge;
