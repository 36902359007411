import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react-thin";

const schedulerProConfig: BryntumSchedulerProProps = {
  viewPreset: "weekAndDayLetter",
  barMargin: 10,
  autoHeight: true,
  nonWorkingTimeFeature: true,
  resizeToFitIncludesHeader: true,
  subGridConfigs: {
    locked: {
      flex: 0.4
    }
  },
  treeFeature: true
};

export { schedulerProConfig };
