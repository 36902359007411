import { BaseUserFieldProps, fieldConfiguration, FieldDataType } from "enada-common";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Menu, Stack, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import EditableLocationField from "./EditableLocationField";

export interface UserLocationFieldProps extends BaseUserFieldProps {
  isIconOn?: boolean;
}
const UserLocationField: FC<UserLocationFieldProps> = ({
  readOnly,
  value,
  onChange,
  useInternalState,
  label,
  isInTable,
  isIconOn,
  setReadOnly,
  required
}) => {
  const [internalState, setInternalState] =
    useState<google.maps.places.AutocompletePrediction | null>(value);
  useEffect(() => {
    setInternalState(value);
  }, [value]);

  const getZoomLevel = () => {
    if (internalState === null) return 14;
    if (internalState.terms.length === 1) return 4;
    if (internalState.types.includes("administrative_area_level_2")) return 8;
    if (internalState.terms.length === 2) return 10;
    return 14;
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {readOnly ? (
        <Stack>
          <EdisonFieldIcon
            title={label}
            subtitle={internalState ? internalState.description : ""}
            icon={fieldConfiguration(FieldDataType.Location).icon}
            color={fieldConfiguration(FieldDataType.Location).color}
            isIconOn={Boolean(isIconOn)}
            isTableModeOn={!!isInTable}
            required={required}
            error={required && !value}
          />
          {internalState && (
            <img
              style={{ width: "100%" }}
              src={`https://maps.googleapis.com/maps/api/staticmap?size=1800x120&markers=%7C${internalState.description
                .replace(", ", ",")
                .replace(" ", "+")}&zoom=${getZoomLevel()}&scale=2&key=${
                process.env.NX_PUBLIC_MAPS_KEY
              }`}
              alt=""
            />
          )}
        </Stack>
      ) : (
        <EditableLocationField
          value={internalState}
          onChange={(value: google.maps.places.AutocompletePrediction | null) => {
            setInternalState(value);
            if (useInternalState) return;
            if (!onChange) return;
            if (value === null) return;
            onChange(value);
            if (!setReadOnly) return;
            setReadOnly(true);
          }}
          label={""}
          setReadOnly={setReadOnly}
          useInternalState={useInternalState}
        />
      )}
    </div>
  );
};
//Useful links for working with google maps api
/*
https://developers.google.com/maps/documentation/places/web-service/autocomplete
https://developers.google.com/maps/documentation/javascript/reference/marker
https://developers.google.com/maps/documentation/javascript/examples/event-poi
https://stackoverflow.com/questions/5984179/javascript-geocoding-from-address-to-latitude-and-longitude-numbers-not-working
https://developers.google.com/maps/documentation/javascript/markers#remove
https://developers.google.com/maps/documentation/javascript/place-autocomplete
https://github.com/googlemaps/react-wrapper
https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
*/

export default UserLocationField;
export { UserLocationField };
