///Used for reference https://timdeschryver.dev/blog/flagged-enum-what-why-and-how#how

export const convertBitwiseEnumValueToArray = <
  T extends { [key: string | number]: string | number }
>(
  enumToMatch: T,
  value: number
) =>
  Object.values(enumToMatch)
    .filter(v => typeof v === "number" && (value & v) !== 0)
    .map(v => enumToMatch[v as number]);

export const convertArrayToBitwiseEnumValue = <
  T extends { [key: string | number]: string | number }
>(
  enumToMatch: T,
  list: string[]
) => list.reduce((total, current) => total + (enumToMatch[current] as number), 0);
