import React, { useState } from "react";
import { Stack } from "@mui/material";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import CreateVersion from "./CreateVersion";
import VersionsTable from "./VersionsTable";

const RecordVersions: React.FC = () => {
  const { t } = useTranslation(["common"]);

  const [showNew, setShowNew] = useState<boolean>(false);

  const toggleNewVersion = () => {
    setTimeout(() => {
      setShowNew(prev => !prev);
    }, 300);
  };

  return (
    <ProjectRecordFlyout
      sx={{ width: "70em", maxWidth: "none" }}
      title={
        <Stack direction="column">
          <EdisonTypography title={t("recordVersions.title")} variant="h2" />
        </Stack>
      }
    >
      {showNew ? (
        <CreateVersion toggleNewVersion={toggleNewVersion} />
      ) : (
        <VersionsTable toggleNewVersion={toggleNewVersion} />
      )}
    </ProjectRecordFlyout>
  );
};

export default RecordVersions;
