import React, { useEffect, useMemo } from "react";
import RecordPage from "./RecordPage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  clearRecordAuth,
  getRecordAccessTokenAsync,
  selectRecordAuth
} from "../../../store/slices/recordSlice";
import { useLocation, useParams } from "react-router-dom";
import { RecordAccessRole, RecordType, Role, RoleType } from "enada-common";
import AccessDenied from "../../error/AccessDenied";
import { Loading } from "enada-components";
import { getMyRolesApi } from "../../../services/APIService";

const RecordAccess = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const scope = getRecordType(location);
  const recordsAuth = useAppSelector(selectRecordAuth);
  const hasAccess = recordsAuth?.details?.AccessRoles?.includes(RecordAccessRole.Read);
  const getToken = async () => {
    let retries = 0;
    while (retries < 10) {
      const rolesResponse = (await getMyRolesApi()) as any;
      if (rolesResponse.status === 200) {
        const roles = rolesResponse.data as Role[];
        if (roles.length === 1 && roles[0].value === RoleType.TemplateOwner) {
          await new Promise(r => setTimeout(r, 100));
          retries++;
        } else {
          retries = 100;
        }
      }
    }
    dispatch(
      getRecordAccessTokenAsync({
        recordId: params.recordId as any,
        recordType: scope
      })
    );
  };
  useEffect(() => {
    dispatch(clearRecordAuth());
    getToken();
  }, [params.recordId]);

  const recordPage = useMemo(() => {
    return <RecordPage scope={scope} />;
  }, [params.recordId]);

  if (recordsAuth) {
    if (hasAccess) {
      return recordPage;
    } else {
      return <AccessDenied />;
    }
  } else {
    return <Loading size={70} />;
  }
};

export default RecordAccess;

const getRecordType = (location: any) => {
  let recordType = RecordType.Projects;
  if (location.pathname.indexOf("businesscase") > -1) {
    recordType = RecordType.BusinessCase;
  } else if (location.pathname.indexOf("programs") > -1) {
    recordType = RecordType.Programs;
  } else if (location.pathname.indexOf("challenges") > -1) {
    recordType = RecordType.Challenges;
  } else if (location.pathname.indexOf("ideas") > -1) {
    recordType = RecordType.Ideas;
  }
  return recordType;
};
