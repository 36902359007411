import { Container, RecordRole, Zone } from "enada-common";
import { Alert, Button, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import Row from "./Row";
import DeferredRender from "../../../utils/DeferredRender";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordSettings,
  selectCurrentVersion,
  setFlyoutId,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import { useTranslation } from "react-i18next";
import useHasRecordRole from "../../../utils/hooks/useHasRecordRole";
import { selectRecordPendingRecordRows } from "../../../store/slices/recordTableSlice";
import { sortFormElements } from "../../../store/slices/parseFormData";

export interface SingleTabProps {
  container: Container;
}
const SingleTab: FC<SingleTabProps> = ({ container }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const pendingRows = useAppSelector(selectRecordPendingRecordRows);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const { hasRecordRole } = useHasRecordRole();
  const orderedRows = useMemo(() => {
    const ordered = sortFormElements([...(container?.zones ?? [])]);
    return ordered;
  }, [container]);

  return (
    <Stack>
      {!!pendingRows.length && (!currentVersion || currentVersion.id === 0) && (
        <Alert
          severity="info"
          action={
            hasRecordRole([RecordRole.Owner]) ? (
              <Button
                onClick={() => {
                  dispatch(setIsFlyoutOpen(true));
                  dispatch(setFlyoutId(RecordSettings.tableRowReview));
                }}
              >
                {" "}
                {t("reviewChanges")}
              </Button>
            ) : (
              <></>
            )
          }
        >
          {t("dataPendingApprovalMessage")}{" "}
        </Alert>
      )}
      <Stack spacing={3}>
        {orderedRows.map((zone: Zone) => (
          <DeferredRender key={zone.id} idleTimeout={1000}>
            <Row zone={zone} />
          </DeferredRender>
        ))}
      </Stack>
    </Stack>
  );
};

export default SingleTab;
