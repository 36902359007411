import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EdisonPeoplePickerField, Loading } from "enada-components";
import {
  PersonaEntity,
  RecordPermission,
  RecordRole,
  RecordTemplate,
  RecordType
} from "enada-common";
import { FormHelperText, Stack } from "@mui/material";
import { useGetDynamicPersons } from "../../../../utils/hooks/useGetDynamicPersons";

export interface CreateRecordOwnersProps {
  recordPermissions?: RecordPermission[];
  recordType?: RecordType;
  ownerRoleType?: RecordRole;
  onChange: (updatedPermissions?: RecordPermission[]) => void;
  currentTemplate: RecordTemplate | undefined;
  isLoading: boolean;
}

const CreateRecordOwners: FC<CreateRecordOwnersProps> = ({
  recordPermissions,
  recordType,
  ownerRoleType = RecordRole.Owner,
  onChange,
  currentTemplate,
  isLoading
}) => {
  const { t } = useTranslation(["common"]);

  const {
    filteredPersons: filteredOwners,
    selectedPeople,
    peoplePickerValue
  } = useGetDynamicPersons(
    ownerRoleType,
    currentTemplate,
    recordPermissions,
    recordType,
    isLoading,
    recordType === RecordType.Ideas //ideaCreation prop
  );

  const noOwnersInTemplate = useMemo(() => {
    if (filteredOwners?.length === undefined) return false;
    return !filteredOwners?.length && !isLoading;
  }, [filteredOwners?.length, isLoading]);

  return isLoading ? (
    <Loading size={30} />
  ) : (
    <Stack>
      <EdisonPeoplePickerField
        multiple
        label={t("assignOwners")}
        value={peoplePickerValue}
        selectedPeople={selectedPeople}
        filteredPeople={filteredOwners ?? []}
        onChange={(entities: PersonaEntity[]) => {
          onChange(
            entities.map(
              entity =>
                ({
                  entityId: entity.entityId,
                  permissionType: entity.type,
                  role: RecordRole.Owner
                } as RecordPermission)
            )
          );
        }}
        useInternalState={false}
      />
      {noOwnersInTemplate && (
        <FormHelperText error>
          {t("pleaseAssignOwnerToTemplateMessage", {
            template: currentTemplate?.displayName ?? currentTemplate?.name
          })}
        </FormHelperText>
      )}
      {!noOwnersInTemplate && !recordPermissions?.length && (
        <FormHelperText> {t("pleaseAssignOwnerMessage")} </FormHelperText>
      )}
    </Stack>
  );
};

export default CreateRecordOwners;
