import { BaseFieldValue } from "./FieldValue.model";
import {
  ApprovalState,
  RecordType,
  TableRowType,
  WorkflowReviewState,
  WorkflowTaskState
} from "./BaseRecord.model";
export interface BaseWorkItem {
  itemId?: number;
  itemName?: string;
  itemType?: WorkItemType;
  recordId?: number;
  recordName?: string;
  recordType: RecordType;
  dueDate?: string;
  tableId?: number;
}

export enum WorkItemType {
  Review = "Review",
  ReviewTask = "ReviewTask",
  StageApproval = "StageApproval",
  Task = "Task",
  ItemList = "ItemList",
  ItemPeriodic = "ItemPeriodic"
}

export interface WorkItemApproval extends BaseWorkItem {
  stageId?: number;
  stageName?: string;
  comments?: string;
  reviewState?: WorkflowReviewState;
  reviewTasks?: WorkItemReviewTask[];
}
export interface WorkItemReviewTask {
  taskId?: number;
  taskDescription?: string;
  taskState?: WorkflowTaskState;
}
export interface WorkItemTask extends BaseWorkItem {
  description?: string;
  percentComplete?: number;
  effort?: number;
  approvalState?: ApprovalState;
}

export interface WorkItem extends BaseWorkItem {
  rowType?: TableRowType;
  fieldValues?: BaseFieldValue[];
  approvalState?: ApprovalState;
}
