import React, { FC, useState } from "react";
import "./fieldicon.scss";

import EdisonTypography from "../typography/EdisonTypography";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Stack, SxProps, Theme } from "@mui/material";

export interface EdisonFieldIconProps {
  children?: any;
  title: string;
  subtitle: any;
  icon?: any;
  color: any;
  isIconOn: boolean;
  isTableModeOn: boolean;
  additionalStyles?: SxProps<Theme>;
  required?: boolean;
  error?: boolean;
}

const EdisonFieldIcon: FC<EdisonFieldIconProps> = ({
  children,
  title,
  subtitle,
  icon,
  color,
  isIconOn,
  isTableModeOn,
  additionalStyles,
  required,
  error
}) => {
  return (
    // <List sx={{ width: "100%", p: 0 }}>
    <ListItem alignItems="flex-start" sx={{ p: 0, minWidth: 0 }}>
      {isTableModeOn ? (
        <ListItemText
          sx={additionalStyles}
          secondary={
            <React.Fragment>
              {children || (
                <EdisonTypography title={subtitle} variant="data2" sx={{ textWrap: "balance" }} />
              )}
            </React.Fragment>
          }
        />
      ) : (
        <>
          {isIconOn && (
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: color }}>{icon}</Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            disableTypography
            sx={{
              ...additionalStyles,
              textOverflow: "ellipsis",
              minWidth: 0,
              overflow: "hidden"
            }}
            primary={
              <Stack direction="row">
                <EdisonTypography
                  title={title}
                  variant="fieldtitle"
                  noWrap={false}
                  sx={{
                    display: "block"
                  }}
                />
              </Stack>
            }
            secondary={
              <React.Fragment>
                {children || (
                  <EdisonTypography title={subtitle} variant="data2" sx={{ display: "inline" }} />
                )}
              </React.Fragment>
            }
          />
        </>
      )}
    </ListItem>
    // </List>
  );
};

export default EdisonFieldIcon;
export { EdisonFieldIcon };
