import { useEffect, useRef, useState, useCallback, useMemo } from "react";

// Custom hook to manage session and token expiry for a page.
// This is only for editors, non-editors expiry automatically refreshes in RecordPage

const useRecordPageSessionChecker = (timeoutInMinutes = 5, expiresOnIso = "", isEditor = false) => {
  const [isExpired, setIsExpired] = useState(false);
  const [sessionNeedsRenewing, setSessionNeedsRenewing] = useState(false);

  // Refs to store timers for inactivity and token expiry
  const inactivityTimer = useRef<null | NodeJS.Timeout>(null);
  const tokenExpiryTimer = useRef<null | NodeJS.Timeout>(null);
  const isMountedRef = useRef(true); // Ref to track if the component is still mounted
  const lastActivityTime = useRef(Date.now());
  const sessionExpiryTime = useRef(lastActivityTime.current + timeoutInMinutes * 60 * 1000);

  const userActivityEvents = useMemo(
    () => ["mousemove", "keydown", "click", "scroll", "touchstart"],
    []
  );
  const timeoutInMilliseconds = useMemo(() => timeoutInMinutes * 60 * 1000, [timeoutInMinutes]);

  // Resets the inactivity timer whenever user activity is detected.

  const resetInactivityTimer = useCallback(() => {
    if (!isEditor) return;

    // Update the last activity time and session expiry time
    lastActivityTime.current = Date.now();
    sessionExpiryTime.current = lastActivityTime.current + timeoutInMilliseconds;

    // Clear the existing inactivity timer
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);

    // Set a new inactivity timer
    inactivityTimer.current = setTimeout(() => {
      if (!isMountedRef.current) return; // Prevent running after unmount

      // Mark the session as expired and clear timers
      setIsExpired(true);
      if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
      if (tokenExpiryTimer.current) clearTimeout(tokenExpiryTimer.current);
    }, timeoutInMilliseconds);
  }, [timeoutInMilliseconds, isEditor]);

  // Handles user activity events and resets the inactivity timer.
  const handleUserActivity = useCallback(() => {
    if (!isEditor) return;
    resetInactivityTimer();
  }, [isEditor, resetInactivityTimer]);

  const cleanUpSessionExpiry = useCallback(() => {
    // Remove all user activity event listeners
    userActivityEvents.forEach(event => window.removeEventListener(event, handleUserActivity));
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
  }, [userActivityEvents, handleUserActivity]);

  const cleanUpTokenExpiry = useCallback(() => {
    if (tokenExpiryTimer.current) clearTimeout(tokenExpiryTimer.current);
  }, []);

  const checkTokenExpiry = useCallback(() => {
    if (!expiresOnIso || isExpired || !isMountedRef.current) return;

    // Calculate token expiry time and time remaining
    const tokenExpiryTime = new Date(expiresOnIso).getTime();
    const currentTime = Date.now();
    const timeUntilTokenExpiry = tokenExpiryTime - currentTime;

    // Check if the token is about to expire (within 1 minute) and the user is active
    const isTokenAboutToExpire = timeUntilTokenExpiry <= 60 * 1000;
    const isUserActiveRecently = currentTime - lastActivityTime.current <= timeoutInMilliseconds;

    if (isTokenAboutToExpire && isUserActiveRecently) {
      setSessionNeedsRenewing(true); // Notify that the session needs renewing
    } else {
      setSessionNeedsRenewing(false);
    }

    // Check if the token has expired and the user is inactive
    const isTokenExpired = timeUntilTokenExpiry <= 0;
    const isUserInactive = currentTime - lastActivityTime.current > timeoutInMilliseconds;

    if (isTokenExpired && isUserInactive) {
      setIsExpired(true);
      if (tokenExpiryTimer.current) clearTimeout(tokenExpiryTimer.current);
    }

    // Clean up if the user is inactive or the component is unmounted
    if (isUserInactive || !isMountedRef.current) {
      cleanUpTokenExpiry();
      cleanUpSessionExpiry();
      return;
    }

    // Schedule the next token expiry check
    if (timeUntilTokenExpiry > 0) {
      tokenExpiryTimer.current = setTimeout(() => {
        if (isMountedRef.current) {
          checkTokenExpiry();
        }
      }, Math.min(timeUntilTokenExpiry, 1000)); // Check every second or sooner if needed
    }
  }, [expiresOnIso, isExpired, timeoutInMilliseconds, cleanUpTokenExpiry, cleanUpSessionExpiry]);

  useEffect(() => {
    isMountedRef.current = true;

    if (isEditor) {
      userActivityEvents.forEach(event => window.addEventListener(event, handleUserActivity));
      resetInactivityTimer(); // Start the inactivity timer
    }

    return () => {
      isMountedRef.current = false;
      cleanUpSessionExpiry();
    };
  }, [
    isEditor,
    resetInactivityTimer,
    userActivityEvents,
    handleUserActivity,
    cleanUpSessionExpiry
  ]);

  useEffect(() => {
    isMountedRef.current = true;

    if (isEditor) {
      setSessionNeedsRenewing(false);
      checkTokenExpiry();
    }

    return () => {
      isMountedRef.current = false;
      cleanUpTokenExpiry();
    };
  }, [isEditor, checkTokenExpiry, cleanUpTokenExpiry]);

  return {
    isExpired,
    sessionNeedsRenewing
  };
};

export default useRecordPageSessionChecker;
