import { Role, RoleType } from "enada-common";
import { useGetMyRolesQuery } from "services/api";

const useHasRole = () => {
  const { data: userRoles = [] } = useGetMyRolesQuery();
  const userRolesValues = userRoles.map((role: Role) => role.value);

  const hasRole = (roles: RoleType[]) => roles.some(r => userRolesValues.includes(r));

  return { hasRole };
};

export default useHasRole;
