import { RecordType, recordTypeConfiguration } from "enada-common";
import { Avatar, Box, Grid } from "@mui/material";
import { FC } from "react";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import "./createrecordtype.scss";
import HasModule from "../../../hasModule/HasModule";
import { useAppSelector } from "../../../../store/hooks";
import { selectThemeName } from "../../../../store/slices/userSlice";
import HasTemplates from "../../../hasTemplates/HasTemplates";

export interface CreateRecordTypeProps {
  onRecordSelection: (type: RecordType) => void;
  allowedRecordTypes?: RecordType[];
}

const CreateRecordType: FC<CreateRecordTypeProps> = ({ onRecordSelection, allowedRecordTypes }) => {
  const { t } = useTranslation(["common"]);
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1} className="create-record-type-root">
        {Object.keys(RecordType)
          .filter(
            type =>
              allowedRecordTypes?.length === 0 || allowedRecordTypes?.includes(RecordType[type])
          )
          .map(type => {
            const route = recordTypeConfiguration(type as RecordType);
            return (
              <HasModule key={type} recordType={type as RecordType}>
                <HasTemplates
                  key={type}
                  typeName={type as RecordType}
                  excludeRecordTypes={[RecordType.Ideas]}
                >
                  <Grid item key={type}>
                    <Box
                      data-testid={`create-record-type-${route.fieldName}`}
                      onClick={() => onRecordSelection(type as RecordType)}
                      className="type-item"
                      sx={theme => ({ background: theme.palette.background.paper })}
                    >
                      <Avatar
                        sx={theme => ({
                          width: 40,
                          height: 40,
                          bgcolor: isDarkTheme ? route.color : route.secondaryColor,
                          color: isDarkTheme ? theme.palette.text.primary : route.color,
                          alignContent: "center"
                        })}
                      >
                        <>{route.icon}</>
                      </Avatar>
                      <EdisonTypography
                        title={t(route.displayName)}
                        variant="h3"
                        sx={{ paddingLeft: "15px" }}
                      />
                    </Box>
                  </Grid>
                </HasTemplates>
              </HasModule>
            );
          })}
      </Grid>
      <Box className="msg-no-templates">{t("noTemplatesPermission")}</Box>
    </>
  );
};

export default CreateRecordType;
