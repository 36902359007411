import { Box, Button, Dialog, Paper, Stack, Tab, Tabs } from "@mui/material";
import React, { FC, useState } from "react";
import Searchbar from "../searchbar/Searchbar";
import { TabPanel, TabProps } from "../tabs/EdisonTabs";
import SingleTable from "./singletable/SingleTable";
import "./rolluptable.scss";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FolderOffOutlinedIcon from "@mui/icons-material/FolderOffOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { RollupTableProps, SingleTab } from "enada-common";
import DeleteDialog from "./deletedialog/DeleteDialog";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import EdisonIconButton from "../iconbutton/EdisonIconButton";

export interface ExtendedRollupTableProps extends RollupTableProps {
  fullScreen: boolean;
  setFullScreen: () => void;
  adminType?: string;
}

const RollupTable: FC<ExtendedRollupTableProps> = ({
  rows,
  deletedRows,
  columns,
  t,
  editAction,
  addAction,
  deleteAction,
  recycleAction,
  restoreAction,
  hideTopSection,
  rowActionsName,
  rowActions,
  onRowOrderChange,
  hideSearch,
  disableColumnSort,
  order,
  orderBy,
  renderEmptyTable,
  fullScreen,
  setFullScreen,
  hideRecycleRestore,
  buttonsToHide,
  deletedTabDifferentColumns,
  deletedTabColumns,
  additionalButtonsLeft,
  customTabIcons = [],
  adminType
}) => {
  const [value, setValue] = useState("active");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSearchFilter("");
    setSelectedRowIds([]);
    setValue(newValue);
  };
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const rowIds = selectedRowIds.map((r: number) => r.toString());
  const tabs: SingleTab[] = [
    {
      id: "active",
      label: (
        <Stack direction="row" spacing={1} className="rollup-table-tab-title">
          {customTabIcons.find(icon => icon.tab === "active")?.icon ?? <FolderOpenOutlinedIcon />}
          <div className="text-container">{t("active")}</div>
        </Stack>
      ),
      children: (
        <SingleTable
          rows={rows}
          columns={columns}
          searchFilter={searchFilter}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          rowActionsName={rowActionsName}
          rowActions={rowActions}
          onRowOrderChange={onRowOrderChange}
          order={order}
          orderBy={orderBy}
          disableColumnSort={disableColumnSort}
          renderEmptyTable={renderEmptyTable}
          t={t}
          fullScreen={fullScreen}
        />
      )
    },
    {
      id: "deleted",
      label: (
        <Stack direction="row" spacing={1} className="rollup-table-tab-title">
          {customTabIcons.find(icon => icon.tab === "deleted")?.icon ?? <FolderOffOutlinedIcon />}
          <div className="text-container">{t("deleted")}</div>
        </Stack>
      ),
      children: (
        <SingleTable
          rows={deletedRows}
          columns={deletedTabDifferentColumns && deletedTabColumns ? deletedTabColumns : columns}
          searchFilter={searchFilter}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          rowActionsName={rowActionsName}
          rowActions={rowActions}
          order={order}
          orderBy={orderBy}
          disableColumnSort={disableColumnSort}
          renderEmptyTable={renderEmptyTable}
          t={t}
          fullScreen={fullScreen}
        />
      )
    }
  ];
  const isDeleteDisabled = () => {
    if (selectedRowIds.length === 0) return true;
    return selectedRowIds.some(id => rows.find(row => row.id === id)?.no_delete_e365);
  };
  return (
    <Paper className="rollup-table-root" data-testid={"edison-rollup-table"}>
      {!hideTopSection && (
        <Box
          className={`${fullScreen && "sticky-header"}`}
          sx={() => ({
            display: "flex",
            flexDirection: "row",
            borderBottom: 2,
            borderColor: "divider",
            alignItems: "center",
            paddingLeft: "1em",
            opacity: 1
          })}
        >
          <Box>
            <Tabs value={value} onChange={handleTabChange} data-testid={"edison-rollup-table-tabs"}>
              {tabs.map((tab, index: number) => (
                <Tab
                  data-testid={`edison-rollup-table-tab-${index}`}
                  key={tab.id}
                  value={tab.id}
                  label={tab.label}
                  className="tab"
                  {...TabProps(tab.id)}
                />
              ))}
            </Tabs>
          </Box>
          <Stack direction="row" className="toolbar" spacing={1}>
            {additionalButtonsLeft}
            {!hideSearch && (
              <div className="searchbar-container">
                <Searchbar
                  searchFilter={searchFilter}
                  setSearchFilter={search => {
                    setSearchFilter(search);
                    setSelectedRowIds([]);
                  }}
                  placeholder={t("search")}
                />
              </div>
            )}

            {!hideRecycleRestore &&
              !buttonsToHide?.some(button => button.tab === value && button.button === "icon") && (
                <>
                  {openDeleteDialog && (
                    <DeleteDialog
                      data-testid={"edison-rollup-table-deletedialog"}
                      title={t(`delete${adminType}`)}
                      content={t("deleteDialogContent")}
                      onClose={function (agreed: boolean): void {
                        if (agreed) {
                          deleteAction?.(rowIds);
                          setSelectedRowIds([]);
                        }
                        setOpenDeleteDialog(false);
                      }}
                    />
                  )}

                  <EdisonIconButton
                    variant="outlined"
                    testId="rollup-table-delete-button"
                    disabled={isDeleteDisabled()}
                    onClick={() => {
                      if (value === "deleted" && selectedRowIds.length >= 1) {
                        setOpenDeleteDialog(true);
                      } else {
                        recycleAction?.(rowIds);
                        setSelectedRowIds([]);
                      }
                    }}
                    icon={<DeleteOutlineIcon fontSize="small" />}
                  />
                </>
              )}

            {!buttonsToHide?.some(button => button.tab === value && button.button === "main") && (
              <Button
                variant="contained"
                size="small"
                data-testid="rollup-table-create-button"
                className="create-button"
                disabled={value === "deleted" && selectedRowIds.length === 0 ? true : false}
                onClick={() => {
                  value === "deleted" && selectedRowIds.length >= 1
                    ? restoreAction?.(rowIds)
                    : value === "active" && selectedRowIds.length === 1
                    ? editAction(selectedRowIds[0].toString())
                    : addAction();

                  setSelectedRowIds([]);
                }}
              >
                {value === "deleted"
                  ? t("restore")
                  : selectedRowIds.length === 1
                  ? t("edit")
                  : t("create")}
              </Button>
            )}
            <EdisonIconButton
              variant="outlined"
              testId="rollup-table-fullscreen-button"
              icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              onClick={setFullScreen}
            />
          </Stack>
        </Box>
      )}
      {tabs.map(tab => (
        <TabPanel key={tab.id} id={tab.id} value={value}>
          {tab.children}
        </TabPanel>
      ))}
    </Paper>
  );
};

export const RollupTableFullScreenWrapper: FC<RollupTableProps> = args => {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <>
      {!fullScreen && (
        <RollupTable
          {...args}
          fullScreen={fullScreen}
          setFullScreen={() => {
            setFullScreen(true);
            document.documentElement.requestFullscreen();
          }}
        />
      )}
      <Dialog className="fullscreen-table-dialog" open={fullScreen} fullScreen>
        <RollupTable
          {...args}
          fullScreen={fullScreen}
          setFullScreen={() => {
            setFullScreen(false);
            document.exitFullscreen();
          }}
        />
      </Dialog>
    </>
  );
};

export default RollupTableFullScreenWrapper;
export { RollupTable };
