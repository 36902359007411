import React, { FC } from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LicenseSettings } from "../../../types/licenses";

export interface LicenseStatusAlertProps {
  licenseSettings?: LicenseSettings;
}
const LicenseStatusAlert: FC<LicenseStatusAlertProps> = ({ licenseSettings }) => {
  const { t } = useTranslation(["common"]);
  const isExpired =
    licenseSettings?.subscriptionEndDate &&
    new Date(licenseSettings?.subscriptionEndDate) < new Date();

  const isWithin90DaysOfExpiring = () => {
    if (licenseSettings?.subscriptionEndDate === undefined) return false;
    const expiryPeriodStart = new Date(licenseSettings.subscriptionEndDate);
    expiryPeriodStart.setDate(expiryPeriodStart.getDate() - 90);
    return expiryPeriodStart < new Date();
  };
  const getRemainingDays = () => {
    if (licenseSettings?.subscriptionEndDate === undefined) return "90";
    //Taken from https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
    const expiryDate = new Date(licenseSettings.subscriptionEndDate);
    const diffTime = Math.abs(expiryDate.getTime() - new Date().getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  if (isExpired) {
    return <Alert severity="error">{t("licenseExpiredErrorMessage")}</Alert>;
  }
  if (isWithin90DaysOfExpiring()) {
    return (
      <Alert severity="warning">
        {t("licenseAutoRenewWarningPartOne") +
          getRemainingDays() +
          t("licenseAutoRenewWarningPartTwo")}
      </Alert>
    );
  }

  return <></>;
};

export default LicenseStatusAlert;
