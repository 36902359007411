import { Get, MgtTemplateProps, Person } from "@microsoft/mgt-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Avatar, Box, Link, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { FC, useEffect, useState } from "react";
import "./userprofile.scss";
import { recordTypeConfiguration, RecordType } from "enada-common";
import { useTranslation } from "react-i18next";
import HasModule from "../hasModule/HasModule";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";
import { getRecords } from "../../services/APIService";

export interface UserProfileProps {
  userId: string;
}

const UserProfile: FC<UserProfileProps> = ({ userId }) => {
  const [phone, setPhone] = useState<string | null>(null);
  const [mail, setMail] = useState<string | null>(null);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [recordCounts, setRecordCounts] = useState<Record<RecordType, number>>({
    [RecordType.Projects]: 0,
    [RecordType.BusinessCase]: 0,
    [RecordType.Programs]: 0,
    [RecordType.Ideas]: 0,
    [RecordType.Challenges]: 0
  });
  const { t } = useTranslation(["common"]);
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  const BlankTemplate = (props: MgtTemplateProps) => {
    setTimeZone(props.dataContext.value);
    return null;
  };

  const getRecordCounts = async () => {
    setRecordCounts({
      [RecordType.Projects]: await getCountByRecordType("Projects"),
      [RecordType.BusinessCase]: await getCountByRecordType("BusinessCase"),
      [RecordType.Programs]: await getCountByRecordType("Programs"),
      [RecordType.Ideas]: await getCountByRecordType("Ideas"),
      [RecordType.Challenges]: await getCountByRecordType("Challenges")
    });
  };
  const getCountByRecordType = async (recordType: string) => {
    const response = await getRecords(
      `?$filter=owners/any(owners:owners/entityId eq '${userId}') and recordType eq '${recordType}'&$count=true&$top=0`
    );
    const count = response["@odata.count"];

    return count ?? 0;
  };

  useEffect(() => {
    getRecordCounts();
  }, [userId]);

  const PersonTemplate = (props: MgtTemplateProps) => {
    setPhone(() => {
      if (props.dataContext.mobilePhone) {
        return props.dataContext.mobilePhone;
      }
      if (
        props.dataContext.businessPhones &&
        props.dataContext.businessPhones.length > 0 &&
        props.dataContext.businessPhones[0]
      ) {
        return props.dataContext.businessPhones[0];
      }
      return null;
    });

    setMail(props.dataContext.mail);

    return (
      <Person
        personDetails={{ ...props.dataContext, mail: null }}
        avatarType="photo"
        fetchImage
        view={"twolines"}
        line1Property="displayName"
        line2Property="jobTitle"
        avatarSize="large"
      />
    );
  };

  return (
    <Box className="user-profile-wrapper">
      <Stack className="user-profile-header">
        <Get
          resource={`me/mailboxSettings/timezone`}
          hidden={true}
          cacheEnabled
          cacheInvalidationPeriod={3600000}
        >
          <BlankTemplate template="default" />
        </Get>
        <Get
          resource={`me?$select=id,displayName,mail,jobTitle,mobilePhone,businessPhones`}
          cacheEnabled
          cacheInvalidationPeriod={3600000}
        >
          <PersonTemplate template="default" />
        </Get>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" className="user-profile-details">
          {Object.values(RecordType).map((m, index) => {
            const recordType = recordTypeConfiguration(m);
            const title =
              recordType.fieldName === RecordType.BusinessCase
                ? "businessCases"
                : recordType.displayName;
            return (
              <HasModule recordType={m} key={m}>
                <div
                  key={`${recordType.displayName}-${index}`}
                  className="user-profile-single-details"
                >
                  <Avatar
                    sx={theme => ({
                      width: 40,
                      height: 40,
                      bgcolor: isDarkTheme ? recordType.color : recordType.secondaryColor,
                      color: isDarkTheme ? theme.palette.text.primary : recordType.color,
                      alignContent: "center"
                    })}
                  >
                    {recordType.icon}
                  </Avatar>
                  <Stack>
                    <div className="user-profile-single-details-title">{t(title)}</div>
                    <div className="user-profile-single-details-subtitle">{recordCounts[m]}</div>
                  </Stack>
                </div>
              </HasModule>
            );
          })}
        </Stack>
      </Stack>
      <Stack>
        <Divider />
        <Stack direction="row" className="user-profile-contact">
          <div className="user-profile-single-contact">
            <LocalPhoneIcon />
            {phone && (
              <Link style={{ textDecoration: "none" }} color="inherit" href={"tel:" + phone}>
                {phone}
              </Link>
            )}
            {!phone && "-"}
          </div>
          <Divider orientation="vertical" />
          <div className="user-profile-single-contact">
            <AccessTimeIcon /> {timeZone ?? "-"}
          </div>
          <Divider orientation="vertical" />
          <div className="user-profile-single-contact">
            <MailOutlineIcon />
            {mail && <Link href={"mailto:" + mail}>{mail}</Link>}
            {!mail && "-"}
          </div>
        </Stack>
      </Stack>
    </Box>
  );
};

export default UserProfile;
