import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { selectRecord } from "../../store/slices/recordSlice";
import { Loading } from "enada-components";
import { useCreateVersionMutation } from "services/api";

interface Props {
  toggleNewVersion: () => void;
}

const CreateVersion: React.FC<Props> = ({ toggleNewVersion }) => {
  const { t } = useTranslation(["common"]);

  const currentRecord = useAppSelector(selectRecord);
  const [createVersion, { isLoading }] = useCreateVersionMutation();

  const [versionName, setVersionName] = useState<string>("");

  const [description, setDescription] = useState<string>("");

  const createNewVersion = async () => {
    if (!currentRecord) return;
    await createVersion({
      recordId: currentRecord.id ?? 0,
      recordType: currentRecord.recordType,
      body: {
        versionName: versionName,
        versionDescription: description || ""
      }
    });

    toggleNewVersion();
  };

  return (
    <Stack direction="column" height={"100%"} justifyContent={"space-between"}>
      {isLoading ? (
        <Box className="circular-progress-container">
          <Loading size={70} />
        </Box>
      ) : (
        <>
          <Stack direction="column" spacing={1}>
            <TextField
              variant="standard"
              type="text"
              fullWidth
              value={versionName}
              placeholder={t("recordVersions.createVersion.versionName")}
              onChange={e => {
                setVersionName(e.target.value);
              }}
              label={t("recordVersions.createVersion.versionName")}
              data-testid={"record-version-name-input"}
              required
            />
            <TextField
              variant="standard"
              type="text"
              value={description}
              fullWidth
              multiline
              rows={4}
              placeholder={t("recordVersions.createVersion.description")}
              onChange={e => {
                setDescription(e.target.value);
              }}
              label={t("recordVersions.createVersion.description")}
            />
          </Stack>
          <Stack direction="column" spacing={2}>
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={createNewVersion}
                disabled={!versionName}
                data-testid={"record-save-version-btn"}
              >
                {t("recordVersions.createVersion.save")}
              </Button>
              <Button variant="outlined" onClick={toggleNewVersion}>
                {t("recordVersions.createVersion.back")}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CreateVersion;
