import AddIcon from "@mui/icons-material/Add";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { Button, IconButton, Stack } from "@mui/material";
import { Background } from "@reactflow/background";
import { Controls } from "@reactflow/controls";
import { MiniMap } from "@reactflow/minimap";
import { DragEvent, useCallback, useRef, useState } from "react";
import ReactFlow, {
  Connection,
  Edge,
  Node,
  NodeChange,
  Position,
  ReactFlowInstance,
  ReactFlowProvider,
  applyNodeChanges
} from "reactflow";
import "reactflow/dist/style.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { WorkflowNodeConfiguration, NotificationLevel, ApiError } from "enada-common";
import ClearIcon from "@mui/icons-material/Clear";
import dagre from "dagre";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import {
  addWorkflowEdge,
  addWorkflowNode,
  selectFormWorkflow,
  selectWorkflowChangeMade,
  selectWorkflowEdges,
  selectWorkflowIsEdit,
  selectWorkflowNodes,
  selectWorkflowOrientation,
  setEdges,
  setNodes,
  setSelectedElement,
  setWorkflowChangeMade,
  setWorkflowErrors,
  setWorkflowOrientation,
  updateEdges
} from "../../../store/slices/workflowSlice";
import { defaultWorkFlowNodeTypes, nodeTypes } from "../utils/FrontendWorkflow.constants";
import { getNewNode } from "../utils/getNewNode";
import getValidConnection from "../utils/getValidConnection";
import parseToBackend from "../utils/parsing/parseToBackend";
import WorkflowOption from "../workflowoption/WorkflowOption";
import "./workflowzone.scss";
import { useCreateWorkflowMutation, useUpdateWorkflowMutation } from "services/api";

const WorkflowZone = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [createWorkflow] = useCreateWorkflowMutation();
  const [updateWorkflow] = useUpdateWorkflowMutation();

  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const edgeUpdateSuccessful = useRef(true);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [selectorOpen, setSelectorOpen] = useState(true);

  const nodes = useAppSelector(selectWorkflowNodes);
  const edges = useAppSelector(selectWorkflowEdges);
  const workflow = useAppSelector(selectFormWorkflow);
  const changeMade = useAppSelector(selectWorkflowChangeMade);
  const orientation = useAppSelector(selectWorkflowOrientation);
  const isEdit = useAppSelector(selectWorkflowIsEdit);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (reactFlowWrapper && reactFlowWrapper.current && reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      if (type) {
        const config: WorkflowNodeConfiguration = JSON.parse(type);
        const newNode = getNewNode(
          { ...config, recordType: workflow.type },
          position,
          nodes.length + 1
        );
        dispatch(addWorkflowNode(newNode));
      }
    }
  };

  const onNodesChange = (changes: NodeChange[]) => {
    dispatch(setNodes(applyNodeChanges(changes, nodes)));
  };

  const onPaneClick = () => {
    dispatch(setSelectedElement(null));
  };

  const onConnect = (params: Connection) => {
    const connection = getValidConnection(nodes, edges, params);
    if (connection.edge) {
      dispatch(addWorkflowEdge(connection.edge));
    } else {
      dispatch(
        setCurrentNotification({
          title: t("workflowError"),
          message: t(connection.errorMessage ?? ""),
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onNodeClick = (clickedNode: Node) => {
    dispatch(setSelectedElement(clickedNode));
  };

  // Taken from https://reactflow.dev/docs/examples/edges/delete-edge-on-drop/
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      dispatch(updateEdges({ oldEdge, newConnection }));
    },
    [dispatch]
  );

  const onEdgeUpdateEnd = useCallback(
    (_event: MouseEvent | TouchEvent, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        dispatch(setEdges(edges.filter(e => e.id !== edge.id)));
      }

      edgeUpdateSuccessful.current = true;
    },
    [dispatch, edges]
  );

  const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });
    nodes.forEach(node => {
      dagreGraph.setNode(node.id, {
        width: node.width,
        height: node.height
      });
    });

    edges.forEach(edge => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map(node => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        position: {
          x: nodeWithPosition.x - nodeWithPosition.width / 2,
          y: nodeWithPosition.y - nodeWithPosition.height / 2
        }
      };
    });
    dispatch(setNodes(layoutedNodes));
    isHorizontal
      ? dispatch(setWorkflowOrientation("horizontal"))
      : dispatch(setWorkflowOrientation("vertical"));
  };

  const onSave = async (navigateBack?: boolean) => {
    dispatch(setSelectedElement(null));

    try {
      const parsed = parseToBackend(nodes, edges, workflow, isEdit, orientation);

      if (Array.isArray(parsed)) {
        const parsedErrors = parsed.reduce((acc, curr, index) => {
          const formatted = `${index + 1}. ${
            curr?.displayName ? t("errorInStage") + ": " + curr.displayName : t("workflowError")
          }, ${t(curr.error, { ...curr.options })}`;
          return acc + formatted + "\n";
        }, "");
        dispatch(setWorkflowErrors(parsed));
        throw parsedErrors;
      }
      const response = isEdit
        ? await updateWorkflow(parsed).unwrap()
        : await createWorkflow(parsed).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: t("savedWorkflow"),
            message: t("success"),
            level: NotificationLevel.Success
          })
        );
        if (navigateBack) navigate(-1);
      }

      dispatch(setWorkflowChangeMade(false));
    } catch (e) {
      const error = e as { data: ApiError };
      if (error?.data?.title === "WorkflowStageUsedInRecord") {
        const errorArr = JSON.parse(error?.data?.detail);
        const errorMessage = errorArr.reduce((acc: string, curr) => {
          const recordNames = curr.Records.map(record => record.RecordName).join(", ");
          const formatted = `${t("workflowStageInUseError", {
            workflowStage: curr.WorkflowStageName,
            records: recordNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");

        dispatch(
          setCurrentNotification({
            title: error?.data?.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      } else {
        dispatch(
          setCurrentNotification({
            title: t("workflowError"),
            message: t(error?.data?.detail ?? error ?? ""),
            level: NotificationLevel.Error
          })
        );
      }
      return false;
    }
  };

  return (
    <div className="workflowzone-root">
      <ReactFlowProvider>
        <div ref={reactFlowWrapper} className="react-flow-container">
          <ReactFlow
            onInit={setReactFlowInstance}
            nodes={nodes}
            edges={edges}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            onPaneClick={onPaneClick}
            onConnect={onConnect}
            onNodeClick={(_e, node) => onNodeClick(node)}
            deleteKeyCode={null}
            proOptions={{
              hideAttribution: true
            }}
          >
            <Stack direction="row" className="custom-controls">
              <Stack className="buttons-container">
                <Stack className="option-buttons-container" spacing={4}>
                  <Button
                    variant="contained"
                    className="react-flow-button"
                    onClick={() => {
                      setSelectorOpen(prev => !prev);
                    }}
                  >
                    {selectorOpen ? <ClearIcon /> : <AddIcon />}
                  </Button>
                  <Stack className={`nodes-container ${selectorOpen ? "" : "hidden"}`} spacing={2}>
                    {defaultWorkFlowNodeTypes.map((option: WorkflowNodeConfiguration) => (
                      <WorkflowOption
                        key={option.type}
                        option={option}
                        reactFlowWrapper={reactFlowWrapper}
                        reactFlowInstance={reactFlowInstance}
                        index={nodes.length + 1}
                        enabled={option.enabled ? option.enabled(workflow.type) : true}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Stack spacing={2}>
                  <IconButton
                    size="large"
                    className="layout-button"
                    onClick={() => getLayoutedElements(nodes, edges, "LR")}
                  >
                    <SwapHorizontalCircleIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    size="large"
                    className="layout-button"
                    onClick={() => getLayoutedElements(nodes, edges, "TB")}
                  >
                    <SwapVerticalCircleIcon fontSize="large" />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack direction="row" className="save-button-container" spacing={1}>
                <Button
                  disabled={!changeMade}
                  className="save-button"
                  size="small"
                  variant="contained"
                  onClick={() => onSave(false)}
                >
                  {t("save")}
                </Button>
                <Button
                  disabled={!changeMade}
                  className="save-button"
                  size="small"
                  variant="contained"
                  data-testid={"workflow-saveandclose-button"}
                  onClick={() => onSave(true)}
                >
                  {t("save&close")}
                </Button>
              </Stack>
            </Stack>
            <Controls className="react-flow-controls" />
            <MiniMap />
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

export default WorkflowZone;
