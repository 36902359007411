import ExpandCircleDownOutlined from "@mui/icons-material/ExpandCircleDownOutlined";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  useTheme
} from "@mui/material";
import React, { FC, PropsWithChildren, ReactElement, ReactNode, useEffect, useRef } from "react";
import cardMedia from "../../assets/edison365logo.png";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisoncontentcard.scss";
import { recordTypeConfiguration, PersonaEntity, RecordType } from "enada-common";
import WebStoriesOutlinedIcon from "@mui/icons-material/WebStoriesOutlined";
import EdisonPeopleGroup from "../peoplegroup/EdisonPeopleGroup";
import { common } from "@mui/material/colors";
import clsx from "clsx";
export interface EdisonContentCardProps {
  title: string;
  description: string;
  isCompact: boolean;
  type: RecordType;
  associatedRecords?: string | null;
  t: (key: string) => string;
  isExpanded?: boolean;
  onExpandClick?: (value: boolean) => void;
  cardImageSource?: string;
  cardPersonas: PersonaEntity[];
  onClick?: () => void;
  HeaderModule?: FC;
  imageBadge?: string | number;
  imageBadgeColour?: string;
  secondaryImageBadgeColour?: string;
  chipIcon?: ReactElement;
  getGroupDetailsAsync?: (groupId: string) => Promise<PersonaEntity[]>;
  isSelected?: boolean;
  FooterModule?: ReactNode;
}

const EdisonContentCard: FC<PropsWithChildren<EdisonContentCardProps>> = ({
  title,
  description,
  type,
  associatedRecords,
  t,
  isExpanded,
  isCompact,
  cardImageSource,
  cardPersonas,
  children,
  onClick,
  HeaderModule,
  onExpandClick,
  imageBadge,
  imageBadgeColour,
  secondaryImageBadgeColour,
  chipIcon,
  getGroupDetailsAsync,
  isSelected,
  FooterModule
}) => {
  const theme = useTheme();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const moduleProperties = recordTypeConfiguration(type, {
    fontSize: "1.2em"
  });

  useEffect(() => {
    if (isExpanded !== Boolean(anchorEl)) {
      setAnchorEl(isExpanded ? cardRef.current : null);
    }
  }, [anchorEl, isExpanded]);

  return (
    <Stack>
      <Card
        data-testid={"edison-content-card"}
        ref={cardRef}
        onClick={onClick}
        className={clsx("content-card", {
          clickable: onClick,
          expanded: Boolean(anchorEl),
          selected: isSelected
        })}
        elevation={0}
        variant="outlined"
      >
        <CardHeader
          data-testid={"edison-content-card-header"}
          title={
            <Tooltip title={title}>
              <Box>
                <EdisonTypography title={title} variant={"data"} sx={{ paddingBottom: "6px" }} />
              </Box>
            </Tooltip>
          }
          sx={{ paddingTop: "8px" }}
          subheader={
            <Stack
              data-testid={"edison-content-card-subheader"}
              direction="row"
              className="subheader-container"
              spacing={3}
              justifyContent="space-between"
            >
              <Stack direction={"row"} alignItems="center" spacing={1}>
                <SvgIcon
                  sx={{
                    color: moduleProperties.color
                  }}
                >
                  {moduleProperties.icon}
                </SvgIcon>
                <EdisonTypography
                  sx={{
                    color: moduleProperties.color
                  }}
                  title={`${t(moduleProperties.displayName)}`}
                  variant="h5"
                />
              </Stack>
              {HeaderModule && <HeaderModule />}
            </Stack>
          }
          className="card-title-container"
        />
        {imageBadge ? (
          <>
            <CardMedia
              data-testid={"edison-content-card-media"}
              component="img"
              height="112"
              style={{ objectFit: cardImageSource ? "cover" : "contain" }}
              image={cardImageSource ?? cardMedia}
              alt="Card Image"
            />

            <Chip
              data-testid={"edison-content-card-chip"}
              icon={chipIcon}
              sx={{
                marginTop: "-10px",
                marginLeft: "10px",
                padding: "10px 20px",
                maxWidth: "fit-content",
                backgroundColor: imageBadgeColour ?? common.white,
                color:
                  secondaryImageBadgeColour ??
                  theme.palette.getContrastText(imageBadgeColour ?? common.white)
              }}
              label={imageBadge}
              size="small"
              color="primary"
            />
          </>
        ) : (
          <CardMedia
            data-testid={"edison-content-card-media"}
            component="img"
            height="112"
            image={cardImageSource ?? cardMedia}
            alt="Card Image"
          />
        )}
        <CardContent className="description-box" data-testid={"edison-content-card-content"}>
          <EdisonTypography
            variant="h5"
            sx={{
              display: "-webkit-box",
              height: "4.6em",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
              lineClamp: "3",
              whiteSpace: "normal",
              wordWrap: "break-word",
              paddingRight: "25px",
              color: theme.palette.text.secondary
            }}
            title={t(description)}
          />
        </CardContent>
        <CardActions sx={{ padding: 0 }} data-testid={"edison-content-card-actions"}>
          <Grid item container direction="row" className="icons-row">
            <EdisonPeopleGroup
              value={cardPersonas}
              maxAvatars={type === RecordType.Programs ? 3 : 4}
              expandGroups={false}
              avatarSize="large"
              getGroupDetailsAsync={getGroupDetailsAsync}
              isInCard={true}
            />
            <Grid display="flex" alignItems="center" pt={!cardPersonas.length ? "10px" : 0}>
              {type === RecordType.Programs && associatedRecords && (
                <Box className="associated-records">
                  <WebStoriesOutlinedIcon sx={{ color: theme.palette.text.secondary }} />
                  <EdisonTypography
                    variant="h5"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "11px",
                      color: theme.palette.text.secondary
                    }}
                    title={associatedRecords}
                  />
                </Box>
              )}
              {FooterModule && FooterModule}
              {!isCompact && (
                <IconButton
                  data-testid={"edison-content-card-buttons-expand"}
                  aria-label="expand row"
                  size="small"
                  onClick={e => {
                    setAnchorEl(anchorEl ? null : cardRef.current);
                    onExpandClick && onExpandClick(!isExpanded);
                    e.stopPropagation();
                  }}
                >
                  {anchorEl ? (
                    <ExpandCircleDownOutlined sx={{ transform: "rotate(-180deg)" }} />
                  ) : (
                    <ExpandCircleDownOutlined />
                  )}
                </IconButton>
              )}
            </Grid>
          </Grid>
        </CardActions>
        {isExpanded && (
          <Stack>
            <Paper variant="outlined" className="expanded-content">
              {" "}
              {children}
            </Paper>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

export default EdisonContentCard;
export { EdisonContentCard };
