import {
  Box,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Switch
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  selectRecord,
  selectRecordWorkflow,
  selectRecordForm,
  selectWorkflowStage,
  selectRecordFields,
  selectRecordFieldValues,
  updateSystemFields,
  updateRecordFieldValue,
  RecordSettings,
  selectEditor,
  setHasRollupTableChanged
} from "../../store/slices/recordSlice";
import { EdisonTypography, UserDatePicker } from "enada-components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Field, RecordRole, RecordType, TableDataType, toCamelCase } from "enada-common";
import { Person } from "@microsoft/mgt-react";
import "./properties.scss";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import useHasRecordRole from "../../utils/hooks/useHasRecordRole";
import { selectRecordTables } from "../../store/slices/recordTableSlice";
import { RecordFlag } from "../recordFlag/RecordFlag";
import { InfoField } from "./InfoField";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { useGetTemplateQuery, useGetUserQuery } from "../../services/api";

type PropertiesProps = {
  readOnly: boolean;
  scope: RecordType;
};

const Properties: FC<PropertiesProps> = ({ readOnly, scope }) => {
  const { t } = useTranslation(["common"]);
  const record = useAppSelector(selectRecord);
  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery();

  const { hasRecordRole } = useHasRecordRole();
  const workflow = useAppSelector(selectRecordWorkflow);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const form = useAppSelector(selectRecordForm);
  const projectFields = useAppSelector(selectRecordFields);
  const projectFieldValues = useAppSelector(selectRecordFieldValues);
  const tables = useAppSelector(selectRecordTables);
  const projectEditor = useAppSelector(selectEditor);

  const { data: template } = useGetTemplateQuery(record?.recordTemplateId ?? 0, {
    skip: !record?.recordTemplateId
  });

  const [shouldUseProjectRollupTable, setShouldUseProjectRollupTable] = useState(
    Boolean(record?.taskTableId)
  );
  const [projectRollupTable, setProjectRollupTable] = useState<number | undefined>(
    record?.taskTableId ? record?.taskTableId : undefined
  );
  const [percentDone, setPercentDone] = useState<number>();
  const [startDate, setStartDate] = useState<string | undefined>("");
  const [endDate, setEndDate] = useState<string | null | undefined>("");

  const isEditor = user?.id === projectEditor?.oid;

  const manualDates = !shouldUseProjectRollupTable && !record?.useChildDateRange;

  const updateDateFieldValue = useCallback(
    (
      fieldId: SystemFieldType.StartDate | SystemFieldType.EndDate,
      date: string | null | undefined
    ) => {
      const recordDateField = projectFields.find((f: Field) => f.id === fieldId);

      if (date && recordDateField) {
        dispatch(
          updateRecordFieldValue({
            id: fieldId,
            changeValue: dayjs(date)?.utc().format()
          })
        );
      }
    },
    [dispatch, projectFields]
  );

  useEffect(() => {
    dayjs.extend(utc);

    if (
      (shouldUseProjectRollupTable &&
        projectRollupTable &&
        record?.taskTableId !== projectRollupTable) ||
      !shouldUseProjectRollupTable
    ) {
      const taskTableId = shouldUseProjectRollupTable ? projectRollupTable : undefined;

      if (taskTableId !== record?.taskTableId) {
        dispatch(
          updateSystemFields({
            key: "taskTableId",
            changeValue: taskTableId
          })
        );
        dispatch(setHasRollupTableChanged(true));
      }
    }

    if (shouldUseProjectRollupTable && record?.recordType === RecordType.Programs) {
      // if we are pulling the dates from the table then we cannot also pull them from the programs child records
      dispatch(updateSystemFields({ key: "useChildDateRange", changeValue: false }));
    }
  }, [
    dispatch,
    projectRollupTable,
    record?.recordType,
    record?.taskTableId,
    shouldUseProjectRollupTable
  ]);

  const isNullOrUndefined = useCallback(value => {
    return value === null || value === undefined;
  }, []);

  const hasChanged = (date1: string | undefined | null, date2: string | undefined | null) => {
    return (
      date1 !== null &&
      date1 !== undefined &&
      date2 !== null &&
      date2 !== undefined &&
      dayjs(date1)?.utc().format() !== dayjs(date2)?.utc().format()
    );
  };

  const taskTables = tables.filter(table => table.dataType === TableDataType.Schedule);

  const getSystemField = useCallback(
    (systemFieldType: string) => {
      const systemField = projectFields.find(x => x.systemFieldType === systemFieldType);

      if (systemField?.id) {
        const systemFieldValue = projectFieldValues.find(x => x.fieldId === systemField.id);
        if (systemFieldValue) return systemFieldValue.value;
      }

      return;
    },
    [projectFields, projectFieldValues]
  );

  const getPercentDone = useCallback(() => {
    const systemFieldPercentDone = getSystemField("PercentDone");

    if (!isNullOrUndefined(systemFieldPercentDone)) return systemFieldPercentDone;

    if (!isNullOrUndefined(record?.percentDone)) return record?.percentDone;

    return "";
  }, [getSystemField, isNullOrUndefined, record?.percentDone]);

  const getStartDate = useCallback(() => {
    const sysStartDate = getSystemField("StartDate");
    return sysStartDate ? sysStartDate : record?.startDate ?? "";
  }, [getSystemField, record?.startDate]);

  const getEndDate = useCallback(() => {
    const sysEndDate = getSystemField("EndDate");
    return sysEndDate ? sysEndDate : record?.endDate ?? "";
  }, [getSystemField, record?.endDate]);

  const isBusinessCase = scope === RecordType.BusinessCase;

  const canEditAutoApproveSetting = useMemo(
    () =>
      (record?.recordType === RecordType.Projects || record?.recordType === RecordType.Programs) &&
      hasRecordRole([RecordRole.Owner]),
    [hasRecordRole, record?.recordType]
  );

  useEffect(() => {
    if (record?.useChildDateRange) {
      // This effect is needed in the scenario where the user has changed the useChildDateRange and the backend returns the updated record date ranges
      // update the field values with the new dates
      if (getEndDate() !== record.endDate) {
        updateDateFieldValue(SystemFieldType.EndDate, record.endDate);
      }
      if (getStartDate() !== record.startDate) {
        updateDateFieldValue(SystemFieldType.StartDate, record.startDate);
      }
    }
  }, [
    record?.startDate,
    record?.endDate,
    record?.useChildDateRange,
    getEndDate,
    getStartDate,
    updateDateFieldValue
  ]);

  useEffect(() => {
    if (record?.taskTableId) {
      hasChanged(record.endDate, endDate) && setEndDate(record.endDate);
      hasChanged(record.startDate, startDate) && setStartDate(record.startDate);
      !isNullOrUndefined(record?.percentDone) && setPercentDone(record.percentDone);
    } else {
      if (getStartDate() !== startDate) setStartDate(getStartDate());
      if (getEndDate() !== endDate) setEndDate(getEndDate());
      if (percentDone === undefined) setPercentDone(getPercentDone());
    }
  }, [
    record?.endDate,
    record?.startDate,
    record?.percentDone,
    projectFieldValues,
    record?.taskTableId,
    endDate,
    startDate,
    getStartDate,
    getEndDate,
    getPercentDone,
    percentDone,
    isNullOrUndefined
  ]);

  const camelScope = toCamelCase(String(scope));

  const rollupCheckboxDropdown = (
    <Box sx={{ padding: "10px 10px" }}>
      {readOnly || !isEditor ? (
        shouldUseProjectRollupTable && (
          <InfoField
            title={t(`use${scope}RollupTable`)}
            data={shouldUseProjectRollupTable}
            data-testid={"properties-use-rollup-table"}
          />
        )
      ) : (
        <FormControlLabel
          sx={{ marginLeft: 0 }}
          label={t(`use${scope}RollupTable`)}
          labelPlacement="start"
          control={
            <Checkbox
              size="small"
              disabled={taskTables.length === 0}
              color="primary"
              checked={shouldUseProjectRollupTable}
              onChange={() => {
                setShouldUseProjectRollupTable(!shouldUseProjectRollupTable);
              }}
              data-testid={"properties-use-rollup-table"}
            />
          }
        />
      )}

      {shouldUseProjectRollupTable &&
        (readOnly ? (
          projectRollupTable && <InfoField title={t("rollupTable")} data={projectRollupTable} />
        ) : (
          <FormControl fullWidth={true} size="small" sx={{ minWidth: 240 }}>
            <InputLabel size="small" id="rollup-table-label">
              {t("rollupTable")}
            </InputLabel>
            <Select
              fullWidth={true}
              value={projectRollupTable}
              label={t("rollupTable")}
              labelId="rollup-table-label"
              size="small"
              disabled={taskTables.length === 0}
              onChange={e => {
                setProjectRollupTable(e.target.value as number);
              }}
            >
              {taskTables.map((table, index) => (
                <MenuItem key={index} value={table.id}>
                  {table.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
    </Box>
  );

  // left side padding needs to be added individually by class because of the dividers
  return (
    <ProjectRecordFlyout title={t(RecordSettings.properties)}>
      <Stack direction="column">
        <RecordFlag recordType={scope} hideComponent={!canEditAutoApproveSetting}>
          <Stack
            padding="10px 10px"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <EdisonTypography title={t("autoApproveTeamMemberUpdates")} variant="h4" />
            <Switch
              disabled={readOnly || !isEditor}
              checked={Boolean(record?.autoApproveUpdates)}
              onChange={e => {
                dispatch(
                  updateSystemFields({
                    key: "autoApproveUpdates",
                    changeValue: e.target.checked
                  })
                );
              }}
              data-testid={"properties-auto-approve-updates"}
            />
          </Stack>
        </RecordFlag>

        <RecordFlag
          recordType={scope}
          hiddenRecordTypes={[RecordType.BusinessCase, RecordType.Challenges, RecordType.Ideas]}
        >
          <>
            <Box sx={{ padding: "10px 10px" }}>
              {readOnly || !isEditor ? (
                <InfoField
                  title={t("timeZone")}
                  data={
                    record?.timeZone
                      ? record?.timeZone
                      : new Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                />
              ) : (
                <FormControl fullWidth size="small" sx={{ minWidth: 240 }}>
                  <InputLabel size="small" id="timeZone-select">
                    {t("timeZone")}
                  </InputLabel>
                  <Select
                    sx={{ marginTop: "10px" }}
                    labelId="timeZone-select"
                    size="small"
                    value={
                      record?.timeZone
                        ? record?.timeZone
                        : new Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                    placeholder={t("timeZone")}
                    onChange={e => {
                      dispatch(
                        updateSystemFields({
                          key: "timeZone",
                          changeValue: e.target.value
                        })
                      );
                    }}
                    MenuProps={{
                      PaperProps: {
                        "data-testid": "properties-time-zone-select-menu"
                      }
                    }}
                    data-testid={"properties-time-zone"}
                  >
                    {Intl.supportedValuesOf?.("timeZone").map((zone: string, index: number) => (
                      <MenuItem key={index} value={zone} data-testid={"timezone-select-item"}>
                        {zone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Divider />
          </>
        </RecordFlag>

        <Box sx={{ padding: "10px 10px" }}>
          <RecordFlag recordType={scope} hiddenRecordTypes={[RecordType.Ideas]}>
            {manualDates && !readOnly && isEditor ? (
              <>
                <Box sx={{ padding: "10px 0px" }}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <UserDatePicker
                        key="startDate"
                        label={
                          !isBusinessCase
                            ? t(`${camelScope}Start`)
                            : `${t(`${camelScope}Start`)} (${t("optional")})`
                        }
                        value={startDate}
                        maxDate={endDate ?? ""}
                        onChange={(date: Dayjs | null) => {
                          if (date) {
                            const newDate = dayjs(date)?.utc().format();
                            updateDateFieldValue(SystemFieldType.StartDate, newDate);
                            dispatch(
                              updateSystemFields({
                                key: "startDate",
                                changeValue: newDate
                              })
                            );
                            setStartDate(newDate);
                          }
                        }}
                        isIconOn={false}
                        useInternalState={false}
                        outlineTextField
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>

                <Box sx={{ padding: "10px 0px" }}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <UserDatePicker
                        key="endDate"
                        label={
                          !isBusinessCase
                            ? t(`${camelScope}End`)
                            : `${t(`${camelScope}End`)} (${t("optional")})`
                        }
                        value={endDate}
                        minDate={startDate ?? ""}
                        onChange={(date: Dayjs | null) => {
                          if (date) {
                            const newDate = dayjs(date)?.utc().format();
                            updateDateFieldValue(SystemFieldType.EndDate, newDate);
                            dispatch(
                              updateSystemFields({
                                key: "endDate",
                                changeValue: newDate
                              })
                            );
                            setEndDate(newDate);
                          }
                        }}
                        isIconOn={false}
                        useInternalState={false}
                        outlineTextField
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>
              </>
            ) : (
              <>
                <InfoField
                  title={t(`${camelScope}Start`)}
                  data={startDate ? dayjs(startDate).format("DD/MM/YYYY") : ""}
                />
                <InfoField
                  title={t(`${camelScope}End`)}
                  data={endDate ? dayjs(endDate).format("DD/MM/YYYY") : ""}
                />
              </>
            )}
          </RecordFlag>
          <RecordFlag
            recordType={scope}
            hiddenRecordTypes={[RecordType.BusinessCase, RecordType.Challenges, RecordType.Ideas]}
          >
            {readOnly || !isEditor || shouldUseProjectRollupTable ? (
              <InfoField title={t(`${camelScope}PercentComplete`)} data={percentDone} />
            ) : (
              <Box sx={{ padding: "10px 0px" }}>
                <TextField
                  className="input"
                  variant="standard"
                  type="number"
                  fullWidth
                  value={percentDone !== undefined ? percentDone.toString() : ""}
                  placeholder={t("enterPercent")}
                  inputProps={{ min: 0, max: 100 }}
                  onBlur={() => {
                    let percentValue = percentDone;

                    if (percentDone !== undefined) {
                      if (percentDone < 0) {
                        percentValue = 0;
                      } else if (percentDone > 100) {
                        percentValue = 100;
                      }
                    }

                    setPercentDone(percentValue);
                    dispatch(
                      updateSystemFields({
                        key: "percentDone",
                        changeValue: percentValue
                      })
                    );
                    const percentDoneField = projectFields.find(
                      (f: Field) => f.systemFieldType === "PercentDone"
                    );
                    if (percentDoneField)
                      dispatch(
                        updateRecordFieldValue({
                          id: percentDoneField.id,
                          changeValue: percentValue
                        })
                      );
                  }}
                  onChange={e => setPercentDone(Number(e.target.value) ?? 0)}
                  label={t(`${camelScope}PercentComplete`)}
                  required
                  data-testid={"properties-percent-done"}
                />
              </Box>
            )}
          </RecordFlag>
        </Box>

        <RecordFlag recordType={scope} hideComponent={record?.recordType !== RecordType.Programs}>
          <Stack
            padding={"10px 10px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <EdisonTypography title={t("useChildRecordDates")} variant="h4" />
            <Switch
              disabled={readOnly || !isEditor || !hasRecordRole([RecordRole.Owner])}
              checked={record?.useChildDateRange}
              onChange={e => {
                dispatch(
                  updateSystemFields({
                    key: "useChildDateRange",
                    changeValue: e.target.checked
                  })
                );
                if (e.target.checked) {
                  setShouldUseProjectRollupTable(false);
                }
              }}
            />
          </Stack>
        </RecordFlag>

        <RecordFlag
          recordType={scope}
          hiddenRecordTypes={[RecordType.BusinessCase, RecordType.Challenges, RecordType.Ideas]}
        >
          {rollupCheckboxDropdown}
        </RecordFlag>

        {scope !== RecordType.Ideas && <Divider />}
        <Box sx={{ padding: "10px 10px" }}>
          <InfoField title={t("id")} data={record?.id} />
          <InfoField
            title={t("createdBy")}
            data={record?.createdBy ? <Person userId={record?.createdBy} view={"oneline"} /> : ""}
            additionalClass="created-by-person"
          />
          <InfoField
            title={t("createdDate")}
            data={dayjs(record?.created).format("DD MMMM YYYY")}
          />
          <InfoField
            title={t("modifiedDate")}
            data={dayjs(record?.modified).format("DD MMMM YYYY")}
          />
          <Box data-testid="properties-views">
            <InfoField title={t("views")} data={record?.viewCount || 0} />
          </Box>
          <InfoField title={t("formName")} data={form?.displayName} />
          <InfoField title={t("templateName")} data={template?.displayName} />
          <InfoField title={t("workflowName")} data={workflow?.displayName} />
          <InfoField title={t("state")} data={workflowStage?.viewState} />
          <InfoField title={t("readOnly")} data={String(record?.readOnly)} />
        </Box>
      </Stack>
    </ProjectRecordFlyout>
  );
};

export default Properties;
