import { BryntumGantt } from "@bryntum/gantt-react-thin";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import BaselinesDropdown from "./baselinesdropdown/BaselinesDropdown";
import "./tasktablesettingsmodal.scss";
import TimeRangesDropdown from "./timerangesdropdown/TimeRangesDropdown";
import { Field, RecordTableConfiguration, TableRowSchedulingMode } from "enada-common";
import { ProjectModel, TaskStore } from "@bryntum/gantt-thin";
import EdisonTypography from "../../../../../edison/typography/EdisonTypography";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import Loading from "../../../../../edison/loading/Loading";
import MppImportDialog from "../MppImportDialog";

//TODO: Resolve issue with prop drilling for gantt and taskStore objects=> maybe use Redux
export interface TaskTableSettingsModalProps {
  t: (key: string) => string;
  gantt: BryntumGantt;
  taskStore: TaskStore;
  project: ProjectModel;
  showTimeline: boolean;
  setShowTimeline: (value: boolean) => void;
  onChange?: (event: any) => void;
  onImportMpp?: (fileList: FileList) => FileList | undefined;
  convertingMpp: boolean;
  mppResponseType: string;
  tableConfig?: RecordTableConfiguration;
  columns: Field[];
  label: string;
  onDataChange: (event: any) => Promise<void>;
  hasChanges: boolean;
}
const TaskTableSettingsModal: FC<TaskTableSettingsModalProps> = ({
  t,
  gantt,
  taskStore,
  project,
  showTimeline,
  setShowTimeline,
  onChange,
  onImportMpp,
  convertingMpp,
  mppResponseType,
  tableConfig,
  onDataChange,
  hasChanges
}) => {
  const acceptedFileExtension = ".mpp";

  const [criticalPathsDisabled, setCriticalPathsDisabled] = useState(
    gantt.instance.features.criticalPaths.disabled
  );
  const [invalidMediaType, setInvalidMediaType] = useState(false);
  const [mppImportDialogOpen, setMppImportDialogOpen] = useState(false);
  const [mppResponseTypeState, setMppResponseTypeState] = useState<string>();

  useEffect(() => {
    if (mppResponseType === "taskLimitExceeded" && mppResponseTypeState === "running") {
      // Only open the dialog when the mpp import has finished processing and exceeded task limit
      setMppImportDialogOpen(true);
    } else {
      setMppImportDialogOpen(false);
      setMppResponseTypeState(mppResponseType);
    }
  }, [mppResponseType, mppResponseTypeState]);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const onButtonClick = () => {
    setInvalidMediaType(false);

    if (inputFile.current?.value) {
      inputFile.current.value = "";
    }

    inputFile.current?.click();
  };

  async function handleFileChanged(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!event.currentTarget.files) {
      return;
    }

    const fileList: File[] = Array.from(event.currentTarget.files);
    fileList.forEach((file: File) => {
      if (!file.name.includes(acceptedFileExtension)) {
        setInvalidMediaType(true);
      }
    });

    onImportMpp?.(event.currentTarget.files);
  }

  const onChangeSchedulingMode = (e: SelectChangeEvent<TableRowSchedulingMode>) => {
    onDataChange({
      action: "update",
      record: project,
      changes: { schedulingMode: e.target.value },
      type: "datachange"
    });
  };

  const scheduleModeOptions = useMemo(
    () => [
      { value: TableRowSchedulingMode.FixedDuration, label: t("fixedDurationDefault") },
      { value: TableRowSchedulingMode.Normal, label: t("normal") },
      { value: TableRowSchedulingMode.FixedUnits, label: t("fixedUnits") },
      { value: TableRowSchedulingMode.FixedEffort, label: t("fixedEffort") }
    ],
    [t]
  );

  return (
    <Stack className="task-table-settings-modal-root" spacing={1} divider={<Divider flexItem />}>
      <BaselinesDropdown
        gantt={gantt.instance}
        taskStore={taskStore}
        onChange={onChange}
        baselines={tableConfig?.baselines}
        t={t}
      />

      <Stack direction="row" className="align-center">
        <FormControlLabel
          control={
            <Checkbox
              checked={!criticalPathsDisabled}
              onChange={() => {
                const newValue = !gantt.instance.features.criticalPaths.disabled;
                setCriticalPathsDisabled(newValue);
                gantt.instance.features.criticalPaths.disabled = newValue;
              }}
            />
          }
          label={t("showCriticalPaths")}
        />

        <Tooltip title={t("criticalPathsMessage")} arrow placement="right">
          <InfoOutlinedIcon />
        </Tooltip>
      </Stack>

      <TimeRangesDropdown gantt={gantt.instance} t={t} />

      <Stack direction="row" className="align-center">
        <FormControlLabel
          control={
            <Checkbox
              checked={showTimeline}
              onChange={e => {
                setShowTimeline(e.target.checked);
              }}
            />
          }
          label={t("showTimeline")}
        />
        <Tooltip title={t("timelineMessage")} arrow placement="right">
          <InfoOutlinedIcon />
        </Tooltip>
      </Stack>
      <Box width="full" py="10px">
        <FormControl fullWidth>
          <InputLabel id="scheduling-mode-label">{t("setScheduleMode")}</InputLabel>
          <Select
            fullWidth={true}
            labelId="scheduling-mode-label"
            label={t("setScheduleMode")}
            value={tableConfig?.schedulingMode || TableRowSchedulingMode.FixedDuration}
            onChange={onChangeSchedulingMode}
            sx={{ borderRadius: "8px" }}
          >
            {scheduleModeOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Removed data wrapping in schedule table for the v1.4.0 release  */}
      {/* <Stack direction={"row"} className="align-center">
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(autoHeight)}
              onChange={e => {
                toggleAutoHeight(!autoHeight);
              }}
            />
          }
          label={t("dataWrapping")}
        />
        <Tooltip title={t("dataWrappingRowMessage")} arrow placement="right">
          <InfoOutlinedIcon />
        </Tooltip>
      </Stack> */}

      {/* <Divider flexItem /> */}

      {/* <Stack direction="row" spacing={1} justifyContent="space-between">
        <Stack direction="row" spacing={1} className="align-center">
          <EdisonTypography title="Export to MSP" variant="data" />
          <Tooltip title={"MSP message"} arrow placement="right">
            <InfoOutlinedIcon />
          </Tooltip>
        </Stack>
        <Button variant="contained" startIcon={<DownloadOutlinedIcon />}>
          Export
        </Button>
      </Stack>
      <Divider flexItem />
 */}

      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Stack direction="row" spacing={1} className="align-center">
          <EdisonTypography title={t("importProjectFile")} variant="data" />
          <Tooltip title={t("importProjectFile")} arrow placement="right">
            <InfoOutlinedIcon />
          </Tooltip>
          {convertingMpp && <Loading size={30} />}
        </Stack>
        <input
          type="file"
          id="file"
          accept={acceptedFileExtension}
          onChange={handleFileChanged}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <Button
          onClick={onButtonClick}
          variant="outlined"
          disabled={hasChanges}
          startIcon={<FolderOpenOutlinedIcon />}
        >
          {t("import")}
        </Button>
      </Stack>
      {invalidMediaType && (
        <EdisonTypography
          title={t("invalidMediaType")}
          sx={{
            color: "red"
          }}
          variant="data"
        />
      )}

      <MppImportDialog
        open={mppImportDialogOpen}
        onRetry={() => {
          onButtonClick();
          setMppImportDialogOpen(false);
        }}
        onClose={() => setMppImportDialogOpen(false)}
        t={t}
      />
    </Stack>
  );
};

export default TaskTableSettingsModal;
