import { IconButton, Stack, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import "./nodetitle.scss";
import {
  deleteNode,
  selectIsWorkflowDefault,
  selectWorkflowReadOnly
} from "../../../../store/slices/workflowSlice";
import { hexToRgba } from "enada-common";
import { getNodeIcon } from "../../utils/getNodeIcon";
import { useAppSelector } from "../../../../store/hooks";

export interface NodeTitleProps {
  data: any;
}
const NodeTitle: FC<NodeTitleProps> = ({ data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const workflowReadOnly = useAppSelector(selectWorkflowReadOnly);
  const isDefault = useAppSelector(selectIsWorkflowDefault);
  const textColor = data.configuration.color
    ? theme.palette.getContrastText(data.configuration.color)
    : "";
  return (
    <div
      className="node-title-root"
      style={{
        backgroundColor: data.configuration.color,
        borderBottom: `1px solid ${hexToRgba(
          (data.configuration.color as string).toLowerCase(),
          1
        )}`
      }}
    >
      <Stack direction="row">
        <div className="node-icon" style={{ color: textColor }}>
          {getNodeIcon(data.configuration.type)}
        </div>

        <div className="node-title-container">
          <div className="node-title" style={{ color: textColor }}>
            {data.displayName}
          </div>
          {!workflowReadOnly && !isDefault && (
            <IconButton onClick={() => dispatch(deleteNode(data.id))} className="delete-button">
              <HighlightOffOutlinedIcon fontSize="small" style={{ color: textColor }} />
            </IconButton>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default NodeTitle;
