import { SkeletonProps } from "enada-common";
import { Avatar, Skeleton } from "@mui/material";
import { FC } from "react";
import "./skeletonactivity.scss";

const EdisonSkeletonActivity: FC<SkeletonProps> = ({ className, rows }) => {
  const skeletonRows = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className={` ${className}`} data-testid={"edison-skeletons-activity"}>
      {skeletonRows.map(row => (
        <div key={row} className="skeleton-activity">
          <Skeleton>
            <Avatar />
          </Skeleton>
          <Skeleton animation="wave" />
        </div>
      ))}
    </div>
  );
};

export default EdisonSkeletonActivity;
export { EdisonSkeletonActivity };
