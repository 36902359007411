import { Box, Stack } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, withReact } from "slate-react";
import { BaseEdisonFieldProps } from "enada-common";
import Block, { BlockProps } from "./nodes/block/Block";
import Leaf, { LeafProps } from "./nodes/leaf/Leaf";
import RichTextFieldToolbar from "./toolbar/EdisonRichTextFieldToolbar";
import { indent, outdent, toggleMark } from "../../utils/richtext/richTextUtils";
import withImages from "../../utils/richtext/withImages";
import withLinks from "../../utils/richtext/withLink";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import { useDebounceCallback } from "usehooks-ts";
import "./edisonrichtextfield.scss";
import { v4 } from "uuid";
import { createCleanCopy } from "../../utils/sanitizeHtml";

export interface EdisonRichTextFieldProps extends BaseEdisonFieldProps {
  initialValue: Descendant[];
  placeholder?: string;
}
const EdisonRichTextField: FC<EdisonRichTextFieldProps> = ({
  value,
  label,
  onChange,
  readOnly,
  autoFocus,
  placeholder
}) => {
  const [blurId, setBlurId] = useState(v4());
  const renderElement = useCallback((props: BlockProps) => <Block {...props} />, []);
  const renderLeaf = useCallback((props: LeafProps) => <Leaf {...props} />, []);
  const editor = useMemo(() => withLinks(withImages(withHistory(withReact(createEditor())))), []);
  let newValue = value;

  if (value && value.length) {
    newValue = createCleanCopy(newValue);
  }

  const initialState =
    newValue && newValue.length
      ? newValue
      : [
          {
            type: "div",
            children: [{ text: " " }]
          }
        ];

  const [internalState, setInternalState] = useState<Descendant[]>(initialState);
  const debounced = useDebounceCallback(onChange, 1000);
  const editorRef = useRef<any>();

  useEffect(() => {
    let newValue = value;

    if (value && value.length) {
      newValue = createCleanCopy(newValue);
    }

    setInternalState(
      newValue && newValue.length
        ? newValue
        : [
            {
              type: "div",
              children: [{ text: " " }]
            }
          ]
    );
    setBlurId(v4());
  }, [value]);

  const getEditor = () => {
    return (
      <Stack ref={editorRef}>
        <RichTextFieldToolbar blurId={blurId} />
        <EdisonTypography title={label} variant={"fieldtitle"} />
        <Editable
          id={blurId}
          placeholder={placeholder}
          autoFocus={autoFocus}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          className="slate-editor"
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault();
              e.shiftKey ? outdent(editor) : indent(editor, "indented");
              return;
            }
            if (e.key === "Escape") {
              e.preventDefault();
            }
            if (!e.ctrlKey) {
              return;
            }
            switch (e.key) {
              case "b":
                e.preventDefault();
                toggleMark(editor, "bold");
                break;
              case "i":
                e.preventDefault();
                toggleMark(editor, "italic");
                break;
              case "u":
                e.preventDefault();
                toggleMark(editor, "underline");
                break;
              default:
                break;
            }
          }}
          onPaste={e => {
            e.preventDefault();
            editor.insertText(e.clipboardData.getData("Text"));
          }}
        />
      </Stack>
    );
  };

  return (
    <Slate
      data-testid={"edison-richtext-slate"}
      key={blurId}
      editor={editor}
      initialValue={internalState}
      onValueChange={newValue => {
        const isAstChange = editor.operations.some(op => "set_selection" !== op.type);

        if (isAstChange) {
          setInternalState(newValue);
          debounced(newValue);
        }
      }}
    >
      <div />
      {!readOnly ? (
        getEditor()
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Stack direction="row">
            <EdisonTypography title={label} variant="fieldtitle" />
          </Stack>
          <Editable
            data-testid="edison-richtext-editable"
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            className="slate-editor"
            onPaste={e => {
              e.preventDefault();
              editor.insertText(e.clipboardData.getData("Text"));
            }}
          />
        </Box>
      )}
    </Slate>
  );
};

export default EdisonRichTextField;
export { EdisonRichTextField };
