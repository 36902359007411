import { RecordType, recordTypeConfiguration } from "enada-common";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";
import { imageMapping } from "../mappers/imageMapping";
import { unCapitalize } from "../unCapitalize";

export const useGetRecordTypeDetails = () => {
  const { t } = useTranslation(["common"]);
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  const getColor = useCallback(
    (type: RecordType) =>
      isDarkTheme
        ? recordTypeConfiguration(type).secondaryColor
        : recordTypeConfiguration(type).color,
    [isDarkTheme]
  );

  const typeMap = useMemo(() => {
    return new Map([
      [RecordType.Projects, { color: getColor(RecordType.Projects), label: t("project") }],
      [RecordType.Challenges, { color: getColor(RecordType.Challenges), label: t("challenge") }],
      [
        RecordType.BusinessCase,
        { color: getColor(RecordType.BusinessCase), label: t("businessCase") }
      ],
      [RecordType.Programs, { color: getColor(RecordType.Programs), label: t("program") }],
      [RecordType.Ideas, { color: getColor(RecordType.Ideas), label: t("idea") }]
    ]);
  }, [getColor, t]);

  const getRecordIcon = useCallback(
    (type: RecordType) => {
      return imageMapping(unCapitalize(type), undefined, undefined, typeMap.get(type));
    },
    [typeMap]
  );

  return { typeMap, getRecordIcon };
};
