import { ExpressionOperator, fullOperatorList } from "enada-common";

export interface FrontendToBackendOperatorMap {
  id?: string;
  backend: ExpressionOperator | "(" | ")";
  frontend: string;
}

export const NumericalOperators: FrontendToBackendOperatorMap[] = [
  {
    id: "1",
    backend: ExpressionOperator.Sum,
    frontend: "+"
  },
  {
    id: "2",
    backend: ExpressionOperator.Subtract,
    frontend: "-"
  },
  {
    id: "3",
    backend: ExpressionOperator.Multiply,
    frontend: "*"
  },
  {
    id: "4",
    backend: ExpressionOperator.Divide,
    frontend: "/"
  }
];

export const CompareOperators: FrontendToBackendOperatorMap[] = [
  {
    id: "1",
    backend: ExpressionOperator.Equals,
    frontend: "=="
  },
  {
    id: "2",
    backend: ExpressionOperator.NotEquals,
    frontend: "!="
  },
  {
    id: "3",
    backend: ExpressionOperator.GreaterThan,
    frontend: ">"
  },
  {
    id: "4",
    backend: ExpressionOperator.LessThan,
    frontend: "<"
  }
];

export const StringComparators: FrontendToBackendOperatorMap[] = [
  {
    id: "1",
    backend: ExpressionOperator.Equals,
    frontend: "=="
  },
  { id: "2", backend: ExpressionOperator.NotEquals, frontend: "!=" },
  { id: "3", backend: ExpressionOperator.StartsWith, frontend: "StartsWith" },
  { id: "4", backend: ExpressionOperator.EndsWith, frontend: "EndsWith" },
  { id: "5", backend: ExpressionOperator.Contains, frontend: "Contains" }
];

export const getFrontendOperator = (
  backendOperator: ExpressionOperator | "(" | ")"
): string | undefined => fullOperatorList.find(pair => pair.backend === backendOperator)?.frontend;

export const getBackendOperator = (
  frontendOperator: string
): ExpressionOperator | "(" | ")" | undefined =>
  fullOperatorList.find(pair => pair.frontend === frontendOperator)?.backend;
