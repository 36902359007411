import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemIcon, ListItemText, Stack } from "@mui/material";

const UserComponentMenu = ({ removeObject }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [{ ...removeObject }];

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack>
        <IconButton
          sx={{ p: "5px" }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.name}
            onClick={() => {
              option.function();
              handleClose();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: option.color
              }}
            >
              {option.name}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default UserComponentMenu;
