import CustomNode from "../customnode/CustomNode";
import { ConnectionLineType, Edge, MarkerType } from "reactflow";
import { WorkflowNodeConfiguration, RecordType } from "enada-common";

export const defaultWorkFlowNodeTypes: WorkflowNodeConfiguration[] = [
  {
    color: "#c62828",
    type: "Stage",
    enabled: () => true
  },
  { color: "#1565c0", type: "StageReview", enabled: () => true },
  {
    color: "#a5d6a7",
    type: "Condition",
    enabled: (recordType: RecordType) =>
      recordType !== RecordType.Ideas && recordType !== RecordType.Challenges
  }
];

export const minNodeWidth = 200;
export const maxNodeWidth = 270;
export const nodeHeight = 42;

let nodeIndex = 0;
export const getId = () => `dndnode_${nodeIndex++}`;
export const getNodeIndex = () => nodeIndex;
export const setNodeIndex = (newIndex: number) => {
  nodeIndex = newIndex;
};

export const nodeTypes = {
  Stage: CustomNode,
  Condition: CustomNode,
  StageReview: CustomNode
};

export const defaultWorkflowConnection: Partial<Edge<any>> = {
  type: ConnectionLineType.SmoothStep,
  markerEnd: {
    type: MarkerType.ArrowClosed
  },
  style: { strokeWidth: 3 }
};
