import { AssignmentStore, DependencyStore, TaskStore } from "@bryntum/gantt-thin";
import {
  Field,
  BaseRecord,
  RecordTableRow,
  TableRowValue,
  Table,
  TableRowType,
  RequiredInType,
  RowOperationType,
  OperationType
} from "enada-common";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import {
  getBackendMappedCellValue,
  getFieldByKey,
  getRow,
  getUniqueIdFromRow
} from "../tableHelpers";
import { isNotRequiredInTable } from "../systemFIeldScope";
import { Store } from "@bryntum/core-thin";

export const parseTaskTableChangeToBackend = (
  changeEvent: any,
  table: Table,
  project: BaseRecord,
  fields: Field[],
  tableRows: RecordTableRow[],
  columns?: Field[]
): RowOperationType[] | null => {
  const baseTableRow: RecordTableRow = {
    recordId: project.id as number,
    tableId: table.id,
    rowType: TableRowType.Schedule
  };

  switch (changeEvent.action) {
    case "add": {
      let record;
      if (changeEvent.store instanceof AssignmentStore) {
        record = changeEvent.records[0].event;
      } else {
        record = changeEvent.records[0];
      }

      if (changeEvent.isMove !== undefined && changeEvent.isMove[record.id] === true) {
        return null;
      }

      if (changeEvent.store instanceof DependencyStore) {
        const record = changeEvent.records[0];
        if (record.fromTask && record.toTask) {
          return [
            {
              operationType: OperationType.AddOrPatch,
              tableRows: getTableRowsFromRecords(
                [record.fromEvent],
                baseTableRow,
                fields,
                tableRows,
                columns
              )
            }
          ];
        }

        return null;
      }
      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: getTableRowsFromRecords([record], baseTableRow, fields, tableRows, columns)
        }
      ];
    }

    case "remove": {
      if (changeEvent.store instanceof DependencyStore) {
        return [
          {
            operationType: OperationType.AddOrPatch,
            tableRows: getTableRowsFromRecords(
              changeEvent.records.map((r: any) => r.fromTask),
              baseTableRow,
              fields,
              tableRows,
              columns
            )
          }
        ];
      }
      if (changeEvent.store instanceof AssignmentStore) {
        return [
          {
            operationType: OperationType.AddOrPatch,
            tableRows: getTableRowsFromRecords(
              [changeEvent.records[0].event],
              baseTableRow,
              fields,
              tableRows,
              columns
            )
          }
        ];
      }

      if (
        !(changeEvent.store instanceof TaskStore) ||
        (changeEvent.isMove !== undefined && changeEvent.isMove === true)
      ) {
        return null;
      }

      return [
        {
          operationType: OperationType.Delete,
          tableRows: removeWithChildren(changeEvent.records, baseTableRow, tableRows)
        }
      ];
    }

    case "update":
    case "indent":
    case "outdent": {
      if (changeEvent.store instanceof DependencyStore) {
        const record = changeEvent.records[0];
        // If the change event changes the dependencies between tasks  and the record event has a "from" and a "to"
        // then we are connecting two tasks ==> add the successors to the row
        if (record.fromTask && record.toTask && changeEvent.action === "update") {
          return [
            {
              operationType: OperationType.AddOrPatch,
              tableRows: getTableRowsFromRecords(
                [record.fromEvent],
                baseTableRow,
                fields,
                tableRows,
                columns
              )
            }
          ];
        }

        return null;
      }
      let records;
      if (changeEvent.store instanceof AssignmentStore) {
        records = [changeEvent.records[0].event];
      } else {
        records = changeEvent.records;
      }
      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: getTableRowsFromRecords(records, baseTableRow, fields, tableRows, columns)
        }
      ];
    }
    default:
      break;
  }

  return null;
};

const removeWithChildren = (
  records: any,
  baseTableRow: RecordTableRow,
  tableRows: RecordTableRow[]
) => {
  let rowsToRemove: any[] = [];
  if (!records) {
    return rowsToRemove;
  }
  records.forEach((record: any) => {
    const rowId = getRow(tableRows, record)?.id;

    rowsToRemove = [
      ...rowsToRemove,
      {
        ...(rowId && { id: rowId }),
        ...baseTableRow,
        tableRowFieldValues: [{ fieldId: SystemFieldType.UniqueId, stringValue: record.id }]
      }
    ];

    rowsToRemove = [
      ...rowsToRemove,
      ...removeWithChildren(record.children, baseTableRow, tableRows)
    ];
  });
  return rowsToRemove;
};

const getTableRowsFromRecords = (
  records: any,
  baseRow: RecordTableRow,
  fields: Field[],
  tableRows: RecordTableRow[],
  columns?: Field[]
) => {
  const rows: RecordTableRow[] = [];
  records.forEach((record: any) => {
    const rowId = getRow(tableRows, record)?.id;

    const row: RecordTableRow = {
      ...baseRow,
      ...(rowId && { id: rowId }),
      dependencies: {
        successors: (record["successors"] ?? []).map((d: any) => ({
          fromEvent: d.from,
          toEvent: d.to,
          type: d.type,
          lag: d.lag,
          lagUnit: d.lagUnit,
          fromSide: d.fromSide,
          toSide: d.toSide
        }))
      },
      tableRowFieldValues: [
        ...getCustomFieldsFromBryntumRecord(record, fields, tableRows, columns),
        ...getBryntumSystemsFieldValues(record, fields, tableRows)
      ],
      configuration: { segments: getTaskSegments(record.segments) }
    };
    rows.push(row);
  });
  return rows;
};

const getTaskSegments = (segments: any[] | undefined | null) => {
  if (!segments) return null;
  return segments.map(segment => ({
    ...segment.data,
    endDate: new Date(segment.endDate).toISOString(),
    startDate: new Date(segment.startDate).toISOString()
  }));
};

export const getTaskBaseLineData = (record: any) => {
  if (record?.baselines === undefined) return [];
  return (record.baselines as Store).map((b: any) => {
    const baseline: any = {
      duration: b.duration,
      durationUnit: b.durationUnit,
      endDate: b.endDate,
      id: b.id,
      name: b.name,
      startDate: b.startDate,
      creationDate: b.creationDate
    };
    return baseline;
  });
};
export const getAssignmentsData = (assignments?: any[]) => {
  if (assignments === undefined) return [];
  return assignments.map((a: any) => ({
    // if the assigned Resource id is not a number then it is a new resource and there is no need to pass the id to the backend
    id: isNaN(a.id) ? undefined : a.id,
    entityId: a.resource?.userId,
    resourceId: a.resourceId,
    units: a.units
  }));
};

const getBryntumSystemsFieldValues = (
  record: any,
  fields: Field[],
  tableRows: RecordTableRow[]
): TableRowValue[] => {
  const fromRow = getRow(tableRows, record);
  const result = getTaskTableSystemFieldsBryntumMap()
    .map(pair => {
      const field = fields.find(field => field.id === pair.edison);
      if (!field) return undefined;
      let value;
      switch (pair.edison) {
        case SystemFieldType.Baselines:
          value = getTaskBaseLineData(record);
          break;
        case SystemFieldType.AssignedResource:
          value = getAssignmentsData(record.assignments);
          break;
        case SystemFieldType.CalendarId:
          value = record.calendar ? record.calendar.id : undefined;
          break;
        case SystemFieldType.ExtendedCalendarId:
          value = record.effectiveCalendar ? record.effectiveCalendar.id : undefined;
          break;
        default:
          value = record[pair.bryntum];
          break;
      }

      return getBackendMappedCellValue(field, value, fromRow?.id ?? 0);
    })
    .filter(value => value !== undefined);
  return result;
};

const getCustomFieldsFromBryntumRecord = (
  record: any,
  fields: Field[],
  tableRows: RecordTableRow[],
  columns?: Field[]
) => {
  // custom fields and system fields that aren't required on the task table
  const customFields = columns?.filter(
    column =>
      column.name.endsWith("-e365") ||
      isNotRequiredInTable(column.requiredIn, RequiredInType.TaskTable)
  );
  const tableValues: TableRowValue[] = [];
  const fromRow = getRow(tableRows, record);
  // Custom fields
  (customFields as Field[])?.forEach(field => {
    const key = field?.name;
    if (record[key] !== undefined) {
      let val = record[key];
      if (val instanceof Date) {
        val = val?.toISOString();
      }
      // Substring needed to remove -e365 identifier from custom field key
      const field = getFieldByKey(key.substring(0, key.length - 5), fields);
      if (!field) return;
      tableValues.push(getBackendMappedCellValue(field, val, fromRow?.id ?? 0));
    }
  });
  return tableValues;
};
export interface EdisonToBryntum {
  bryntum: string;
  edison: SystemFieldType;
}

/**
 * Retrieves the mapping of Edison system fields to Bryntum fields for the task table.
 * We only want to include bryntum calculated fields on save. Mapping the calculatd fields on load
 * will cause them to triger an update.
 *
 * @param saveModel - A boolean indicating whether to include additional fields for saving the model to backend.
 * @returns An array containing the mapping of Edison system fields to Bryntum fields.
 */
export const getTaskTableSystemFieldsBryntumMap = (saveModel = true) => {
  const edisonToBryntumMap = [
    { edison: SystemFieldType.Name, bryntum: "name" },
    { edison: SystemFieldType.StartDate, bryntum: "startDate" },
    { edison: SystemFieldType.EndDate, bryntum: "endDate" },
    { edison: SystemFieldType.RowId, bryntum: "rowId" },
    { edison: SystemFieldType.UniqueId, bryntum: "id" },
    { edison: SystemFieldType.ParentUniqueId, bryntum: "parentId" },
    { edison: SystemFieldType.ReadOnly, bryntum: "readOnly" },
    { edison: SystemFieldType.PercentDone, bryntum: "percentDone" },
    { edison: SystemFieldType.Description, bryntum: "note" },
    {
      edison: SystemFieldType.AssignedResource,
      bryntum: "assignments"
    },
    { edison: SystemFieldType.RollUp, bryntum: "rollup" },
    { edison: SystemFieldType.ShowInTimeline, bryntum: "showInTimeline" },
    { edison: SystemFieldType.IsMilestone, bryntum: "isMilestone" },
    { edison: SystemFieldType.ConstraintDate, bryntum: "constraintDate" },
    { edison: SystemFieldType.ConstraintType, bryntum: "constraintType" },
    { edison: SystemFieldType.Deadline, bryntum: "deadlineDate" },
    { edison: SystemFieldType.Duration, bryntum: "duration" },
    { edison: SystemFieldType.DurationUnit, bryntum: "durationUnit" },

    { edison: SystemFieldType.Effort, bryntum: "effort" },
    { edison: SystemFieldType.EffortDriven, bryntum: "effortDriven" },
    { edison: SystemFieldType.EffortUnit, bryntum: "effortUnit" },
    { edison: SystemFieldType.Inactive, bryntum: "inactive" },
    { edison: SystemFieldType.ManuallyScheduled, bryntum: "manuallyScheduled" },
    { edison: SystemFieldType.SchedulingMode, bryntum: "schedulingMode" },

    { edison: SystemFieldType.SlackUnit, bryntum: "slackUnit" },
    { edison: SystemFieldType.WBS, bryntum: "wbsCode" },
    { edison: SystemFieldType.Baselines, bryntum: "baselines" },
    { edison: SystemFieldType.CalendarId, bryntum: "calendar" },
    {
      edison: SystemFieldType.ExtendedCalendarId,
      bryntum: "effectiveCalendar"
    },
    {
      edison: SystemFieldType.IgnoreResourceCalendar,
      bryntum: "ignoreResourceCalendar"
    },
    {
      edison: SystemFieldType.ProjectConstraintResolution,
      bryntum: "projectConstraintResolution"
    }
  ];

  if (saveModel) {
    edisonToBryntumMap.push(
      { edison: SystemFieldType.TotalSlack, bryntum: "totalSlack" },
      { edison: SystemFieldType.Critical, bryntum: "critical" },
      { edison: SystemFieldType.EarlyStartDate, bryntum: "earlyStartDate" },
      { edison: SystemFieldType.EarlyEndDate, bryntum: "earlyEndDate" },
      { edison: SystemFieldType.LateStartDate, bryntum: "lateStartDate" },
      { edison: SystemFieldType.LateEndDate, bryntum: "lateEndDate" },
      { edison: SystemFieldType.Unscheduled, bryntum: "unscheduled" }
    );
  }
  return edisonToBryntumMap;
};

export const checkForPendingRowUpdates = (changeEvent: any, rowsToUpdate?: RecordTableRow[]) => {
  if (!rowsToUpdate) return;
  if (changeEvent.record === undefined) return;
  if (changeEvent?.record["pendingUpdate-e365"] !== true) return;
  const updatedRow = rowsToUpdate.find(
    row => getUniqueIdFromRow(row) === changeEvent.record["e365-UniqueId"]
  );
  return updatedRow;
};
