import { ColumnTable, Form } from "enada-common";

export const enumerateTables = (form: Form, callback: (table: ColumnTable) => void) => {
  form?.containers?.forEach(c => {
    c.zones?.forEach(z => {
      z.columns?.forEach(col => {
        col.tables?.forEach(columnTable => {
          callback(columnTable);
        });
      });
    });
  });
};
