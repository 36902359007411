import { BryntumGanttProps } from "@bryntum/gantt-react-thin";
import "@bryntum/grid-thin/lib/feature/CellCopyPaste.js";

const gridConfig: Partial<BryntumGanttProps> = {
  //https://bryntum.com/products/gantt/docs/api/Grid/view/SubGrid
  subGridConfigs: {
    normal: { collapsed: true }
  },
  selectionMode: {
    cell: true,
    checkbox: true,
    showCheckAll: true
  },
  cellMenuFeature: {
    menu: {
      cls: "enada-bryntum-popup-menu"
    }
  },
  cellEditFeature: {
    triggerEvent: "cellclick",
    addNewAtEnd: false
  },
  cellCopyPasteFeature: {
    copyOnly: true
  },
  filterFeature: true,
  taskCopyPasteFeature: false,
  taskMenuFeature: false,
  rowCopyPasteFeature: false,
  regionResizeFeature: false,
  fixedRowHeight: false
};
export { gridConfig };
