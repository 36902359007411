import { Field, FieldDataType } from "enada-common";

export const validateFieldConfig = (field: Field): boolean => {
  switch (field.dataType) {
    case FieldDataType.MultiLevelChoice:
      if (
        (field.dataStructure as any)?.choices === undefined ||
        (field.dataStructure as any)?.choices?.length === 0
      ) {
        throw "multiLevelChoiceOptionError";
      }
      return true;
    case FieldDataType.MultiChoice:
      if (
        (field.dataStructure as any)?.choices === undefined ||
        (field.dataStructure as any)?.choices?.length === 0
      ) {
        throw "multiChoiceOptionError";
      }
      return true;
    case FieldDataType.Choice:
      if (
        (field.dataStructure as any)?.choices === undefined ||
        (field.dataStructure as any)?.choices?.length === 0
      ) {
        throw "choiceOptionError";
      }
      return true;

    default:
      return true;
  }
};
