import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import { PublicClientApplication, AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { msalConfig, loginRequest, apiGateway } from "../config/authConfig";
import { getHeaders } from "../utils/getHeaders";

import { ApiError } from "enada-common";
import { onOpen } from "store/slices/firewallSlice";
import { msalInstance } from "./AuthenticationService";

type AuthenticatedBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const authenticatedBaseQuery: AuthenticatedBaseQuery = async (args, api, extraOptions) => {
  const currentAccount = msalInstance.getActiveAccount();
  const request = {
    ...loginRequest,
    account: currentAccount as AccountInfo
  };
  try {
    const response: AuthenticationResult = await msalInstance.acquireTokenSilent(request);

    if (!response) {
      await msalInstance.acquireTokenRedirect(request);
      return { error: { status: 401, data: "Token acquisition failed" } };
    }

    const accessToken = response.accessToken;

    const modifiedArgs = typeof args === "string" ? { url: args } : { ...args };

    const excludeContentTypeHeader =
      typeof modifiedArgs.body !== "undefined" && modifiedArgs.body instanceof FormData;

    const additionalHeaders = new Headers();
    if (modifiedArgs.headers) {
      if (modifiedArgs.headers instanceof Headers) {
        modifiedArgs.headers.forEach((value, key) => {
          additionalHeaders.append(key, value);
        });
      } else if (typeof modifiedArgs.headers === "object") {
        Object.entries(modifiedArgs.headers).forEach(([key, value]) => {
          if (typeof value === "string") {
            additionalHeaders.append(key, value);
          }
        });
      }
    }

    modifiedArgs.headers = getHeaders(
      accessToken,
      modifiedArgs.url,
      additionalHeaders,
      excludeContentTypeHeader
    );

    const result = await fetchBaseQuery({
      baseUrl: apiGateway
    })(modifiedArgs, api, extraOptions);

    const error = result.error?.data as ApiError;

    if (
      error &&
      error.status === 403 &&
      error.detail ===
        "Access has been blocked by the edison365 firewall, please contact support if needed."
    ) {
      api.dispatch(onOpen());
    }

    return result;
  } catch (error) {
    return { error: { status: 500, data: "Request failed" } };
  }
};
