import { Alert, AlertProps, AlertTitle } from "@mui/material";
import { forwardRef } from "react";

type EdisonAlertProps = AlertProps & {
  severity: AlertProps["severity"];
  subHeading: string;
  heading?: string;
};

export const EdisonAlert = forwardRef<HTMLDivElement, EdisonAlertProps>(
  ({ severity, heading, subHeading, ...props }, ref) => {
    return (
      <Alert severity={severity} ref={ref} {...props}>
        {heading && <AlertTitle>{heading}</AlertTitle>}
        <div>{subHeading}</div>
      </Alert>
    );
  }
);
