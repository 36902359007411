import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import "./periodictabletoolbar.scss";
import { Field } from "enada-common";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import EdisonIconButton from "../../../../edison/iconbutton/EdisonIconButton";
import { DomClassList } from "@bryntum/core-thin";
import TableRowDeletionDialog from "../../tableutils/deletiondialog/TableRowDeletionDialog";
import UserTableSettingsModal from "../../tableutils/settingsmodal/UserTableSettingsModal";
import PeriodicToolbarSettings from "./settingsmodal/PeriodicToolbarSettings";

export interface PeriodicTableToolbarProps {
  grid: any;
  fields: Field[];
  columns: any;
  toolbarModule?: ReactNode;
  readOnly?: boolean;
  onEdiClick?: () => void;
  hideEdi?: boolean;
  hasSelectedData: boolean;
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  titleModule?: ReactNode;
  t: (value: string) => string;
  label: string;
  setDeletedRows?: Dispatch<SetStateAction<any[]>>;
}

const PeriodicTableToolbar: FC<PeriodicTableToolbarProps> = ({
  grid,
  columns,
  fields,
  toolbarModule,
  readOnly,
  onEdiClick,
  hideEdi,
  hasSelectedData,
  fullScreen,
  setFullScreen,
  titleModule,
  t,
  label,
  setDeletedRows
}) => {
  const [rowDeleteOpen, setRowDeleteOpen] = useState(false);
  useEffect(() => {
    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (!grid.current.instance) {
      return;
    }
    const clsList = new DomClassList(grid.current.instance.cls);
    fullScreen ? clsList.add("grid-fullscreen") : clsList.remove("grid-fullscreen");
    grid.current.instance.cls = clsList.value;
  }, [fullScreen]);

  const handleAddRow = useCallback(() => {
    const newRow = fields.reduce(
      (next, current) => ({
        ...next,
        [current.name]: null
      }),
      {}
    );
    grid.current.instance.store.add({ ...newRow, total: 0, grandTotal: 0 });
  }, []);

  return (
    <div className="periodic-toolbar-container">
      <div className="toolbarContainer">
        <div className="leftContainer"> {titleModule} </div>
        <div className="rightContainer">
          {toolbarModule}
          <EdisonIconButton
            testId=""
            variant="outlined"
            icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            onClick={e => {
              setFullScreen(prev => !prev);
              window.scrollTo(0, 0);
              fullScreen ? document.exitFullscreen() : document.documentElement.requestFullscreen();
            }}
          />
          {!readOnly && (
            <UserTableSettingsModal readOnly={Boolean(readOnly)}>
              <PeriodicToolbarSettings grid={grid} label={label} columns={columns} t={t} />
            </UserTableSettingsModal>
          )}
          {!readOnly && (
            <EdisonIconButton
              testId=""
              variant="outlined"
              icon={<DeleteOutlineIcon color={!hasSelectedData ? "disabled" : "primary"} />}
              disabled={!hasSelectedData}
              onClick={() => setRowDeleteOpen(true)}
            />
          )}
          {/* {!Boolean(hideEdi) && (
            <EdisonIconButton
              testId=""
              variant="text"
              icon={<EdiIcon />}
              onClick={() => (onEdiClick ? onEdiClick() : undefined)}
            />
          )} */}
          {!readOnly && (
            <EdisonIconButton
              testId=""
              variant="contained"
              icon={<AddIcon />}
              onClick={handleAddRow}
            />
          )}
        </div>
      </div>
      <TableRowDeletionDialog
        open={rowDeleteOpen}
        onDelete={() => {
          setDeletedRows?.(rows => [
            ...rows,
            ...grid.current.instance.selectedRecords.map(record => record.data)
          ]);
          grid.current.instance.store.remove(grid.current.instance.selectedRecords);
          setRowDeleteOpen(false);
        }}
        onClose={() => setRowDeleteOpen(false)}
        t={t}
      />
    </div>
  );
};

export default PeriodicTableToolbar;
export { PeriodicTableToolbar };
