import { BaseUserFieldProps, TableDataType } from "enada-common";
import { FC, useContext, useState } from "react";
import PeriodicTableContext from "../../user/tables/periodic/context/PeriodicTableContext";
import DynamicHeightWrapper from "../../user/tables/tableutils/dynamicheightwrapper/DynamicHeightWrapper";
import ScheduleTableContext from "../../user/tables/schedule/context/ScheduleTableContext";
import ListTableContext from "../../user/tables/list/context/ListTableContext";
export interface ReadOnlyWrapperProps<T extends BaseUserFieldProps> {
  Component: FC<T>;
  props: T;
  initKeepReadOnly: boolean;
  source?: TableDataType;
  useDynamicHeightWrapper?: boolean;
  dynamicHeightLabel?: string;
}

const ReadOnlyWrapper = <T extends BaseUserFieldProps>({
  Component,
  props,
  initKeepReadOnly,
  source,
  useDynamicHeightWrapper,
  dynamicHeightLabel
}: ReadOnlyWrapperProps<T>) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  // TODO :  Revisit reading of dynamic readOnly value for custom fields after release 22/06/2023
  // Investigate further if Bryntum has a manner or passing readonnly for custom (maybe removing and adding columns)
  // At the very least investigate moving reading of context up to the component mapper
  //-------------------
  const listTableReadOnly = useContext(ListTableContext);
  const scheduleTableReadOnly = useContext(ScheduleTableContext);
  const periodicTableReadOnly = useContext(PeriodicTableContext);
  const keepReadOnly = getReadOnlyValue(
    initKeepReadOnly,
    scheduleTableReadOnly,
    listTableReadOnly,
    periodicTableReadOnly,
    source
  );
  ///---------------------
  return (
    <div
      style={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        alignItems: "center"
      }}
      onClick={() => {
        if (isReadOnly && !keepReadOnly) setIsReadOnly(false);
      }}
    >
      {useDynamicHeightWrapper ? (
        <DynamicHeightWrapper
          props={{ ...props, readOnly: isReadOnly, setReadOnly: setIsReadOnly, autoFocus: true }}
          Component={Component}
          labelToSearchFor={dynamicHeightLabel as string}
        />
      ) : (
        <Component {...props} autoFocus readOnly={isReadOnly} setReadOnly={setIsReadOnly} />
      )}
    </div>
  );
};
const getReadOnlyValue = (
  initKeepReadOnly: boolean,
  taskReadOnly: boolean,
  standardReadOnly: boolean,
  periodicReadOnly: boolean,
  source?: TableDataType
) => {
  switch (source) {
    case TableDataType.List:
      return initKeepReadOnly === false ? standardReadOnly : true;
    case TableDataType.Schedule:
      return initKeepReadOnly === false ? taskReadOnly : true;
    case TableDataType.Periodic:
      return initKeepReadOnly === false ? periodicReadOnly : true;
  }
};

export default ReadOnlyWrapper;
