import {
  recordTypeConfiguration,
  NotificationLevel,
  Workflow,
  Category,
  RecordType,
  ApiError
} from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { useAppDispatch } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { useHasModule } from "../../../utils/hooks/useHasModule";
import { selectRollupRowsFromData } from "utils/rtkQuery";
import {
  useDeleteWorkflowsMutation,
  useGetWorkflowsQuery,
  useRecycleWorkflowsMutation,
  useRestoreWorkflowsMutation
} from "services/api";

const Workflows: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const [deleteWorkflow] = useDeleteWorkflowsMutation();
  const [recycleWorkflow] = useRecycleWorkflowsMutation();
  const [restoreWorkflow] = useRestoreWorkflowsMutation();

  const selectWorkflowRollupRows = selectRollupRowsFromData<Workflow[]>(data => {
    return data?.map(workflow => ({
      id: workflow.id ?? 0,
      displayName: workflow.displayName,
      modified: workflow.modified,
      modifiedBy: workflow.modifiedBy,
      type: workflow.type,
      isDeleted: workflow.isDeleted,
      category: workflow.category,
      no_delete_e365: workflow.category === Category.Default
    }));
  });

  const { rollupRows = [] } = useGetWorkflowsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectWorkflowRollupRows(result)
    })
  });

  const navigate = useNavigate();
  const { isModuleEnabled } = useHasModule();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteWorkflow(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "workflowDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e) {
      const error = e as ApiError;
      if (error?.detail) {
        const errorArr = JSON.parse(error.detail);
        const errorMessage = errorArr.reduce((acc: string, curr: any) => {
          const templateNames = curr.RecordTemplates.map(
            (template: any) => template.RecordTemplateName
          ).join(", ");
          const formatted = `${t("workflowInUseError", {
            workflows: curr.WorkflowName,
            templates: templateNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");
        dispatch(
          setCurrentNotification({
            title: error.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      }
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleWorkflow(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "workflowRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "workflowRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreWorkflow(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "workflowRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "workflowRestoreError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate("workflowdesigner", {
          state: { title: "workflowdesigner", workflowId: id }
        });
      }}
      addAction={() => {
        navigate("workflowdesigner", {
          state: { title: "workflowdesigner" }
        });
      }}
      columns={rollupTableColumnsWithType(t, value => recordTypeConfiguration(value.type).color)}
      rows={rollupRows.filter(row => !row.isDeleted && isModuleEnabled(row.type as RecordType))}
      deletedRows={rollupRows.filter(row => row.isDeleted)}
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Workflows;
