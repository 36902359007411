import { Alert, Paper, Stack } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftPanel from "../../../components/workflowdesigner/leftpanel/LeftPanel";

import WorkflowZone from "../../../components/workflowdesigner/workflowzone/WorkflowZone";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  WorkflowDesignerView,
  resetWorkflowSlice,
  selectWorkflowDesignerView,
  setEdges,
  setNodes,
  setFormWorkflow,
  setWorkflowIsEdit
} from "../../../store/slices/workflowSlice";
import "./workflowdesigner.scss";
import { Category } from "enada-common";
import { useTranslation } from "react-i18next";
import WorkflowPopulationView from "../../../components/workflowdesigner/workflowpopulationview/WorkflowPopulationView";
import parseToFrontend from "../../../components/workflowdesigner/utils/parsing/parseToFrontend";
import WorkflowManageFormView from "../../../components/workflowdesigner/workflowmanageformview/WorkflowManageFormView";
import { useGetWorkflowQuery } from "services/api";

const WorkflowDesigner = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const currentView = useAppSelector(selectWorkflowDesignerView);

  const { state } = useLocation();
  const isEdit = state?.workflowId;

  const { data: workflow } = useGetWorkflowQuery(state.workflowId, { skip: !isEdit });

  useEffect(() => {
    //initialise workflow if we are editing
    if (isEdit && workflow) {
      const { nodes, edges } = parseToFrontend(workflow);
      dispatch(setFormWorkflow(workflow));
      dispatch(setNodes(nodes));
      dispatch(setEdges(edges));
      dispatch(setWorkflowIsEdit(true));
    } else {
      dispatch(resetWorkflowSlice());
    }
  }, [dispatch, isEdit, workflow]);

  return (
    <Stack spacing={1}>
      {workflow?.category === Category.Default && (
        <Alert severity="info">{t("viewingDefaultWorkflow")}</Alert>
      )}
      <Stack className="workflow-designer-page-root" direction="row" spacing={3}>
        <Paper className="left-panel">
          <LeftPanel />
        </Paper>
        <div className="right-panel">{renderCurrentView(currentView)}</div>
      </Stack>
    </Stack>
  );
};

const renderCurrentView = (currentView: WorkflowDesignerView) => {
  switch (currentView) {
    case WorkflowDesignerView.StageView:
      return <WorkflowZone />;
    case WorkflowDesignerView.ManageFormView:
      return <WorkflowManageFormView />;
    case WorkflowDesignerView.PopulationView:
      return <WorkflowPopulationView />;
  }
};

export default WorkflowDesigner;
