import { FC } from "react";
import { Calendar, Category, RollupTableRowModel } from "enada-common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RollupTable } from "enada-components";
import { calendarRollupTableColumns } from "../../../config/rollupTableColumns";
import { selectRollupRowsFromData } from "utils/rtkQuery";
import { useGetCalendarsQuery } from "services/api";

const roundTo = function (num: number, places: number) {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};

const selectCalendarRollupRows = selectRollupRowsFromData<Calendar[]>(data => {
  return data?.map(calendar => ({
    id: calendar.id ?? 0,
    displayName: calendar.displayName,
    workingDays: calendar.configuration?.days ?? [],
    hoursPerDay: roundTo(calendar.configuration?.hoursPerDay, 1) ?? "",
    hoursPerWeek: calendar.configuration?.hoursPerWeek ?? "",
    modified: calendar.modified,
    modifiedBy: calendar.modifiedBy,
    isDeleted: calendar.isDeleted,
    category: calendar.category,
    disabled_e365: calendar.category === Category.Default
  }));
});

const Calendars: FC = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const { rollupRows = [] } = useGetCalendarsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectCalendarRollupRows(result)
    })
  });

  return (
    <RollupTable
      order="desc"
      orderBy="modified"
      hideRecycleRestore={true}
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`editcalendar`, {
          state: {
            calendarId: id,
            title: "calendars"
          }
        });
      }}
      addAction={() => {
        navigate("newcalendar", {
          state: {
            title: "calendars"
          }
        });
      }}
      columns={calendarRollupTableColumns(t, (value: any) => {
        return value.workingDays;
      })}
      rows={rollupRows.filter(calendar => !calendar.isDeleted) as unknown as RollupTableRowModel[]}
      deletedRows={
        rollupRows.filter(calendar => calendar.isDeleted) as unknown as RollupTableRowModel[]
      }
      t={t}
      fullScreen={false}
      setFullScreen={function (): void {
        // mark fullScreen as optional??
      }}
    />
  );
};

export default Calendars;
