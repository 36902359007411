import { Box, Stack } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import { EdisonPeoplePickerFieldProps, PersonaEntity, PermissionType } from "enada-common";
import "./edisonpeoplepickerfield.scss";
import EdisonTypography from "../typography/EdisonTypography";
import { PeoplePicker } from "@microsoft/mgt-react";
import BoldTranslation from "../boldtranslation/BoldTranslation";

const EdisonPeoplePickerField: FC<EdisonPeoplePickerFieldProps> = ({
  label,
  multiple,
  maxAvatars = 10,
  value,
  onChange,
  disabled,
  disableGroups,
  required,
  filteredPeople,
  selectedPeople,
  helperText
}) => {
  //This is a temporary hack until microsoft fix their mgt-react people picker

  const [tempHack, setTempHack] = useState(false);

  const handleSelectionChanged = (e: any) => {
    if (e.detail.length !== value?.length) {
      const invisibleUsers = value?.filter(user => {
        return !e.detail.some((p: any) => p.id === user.entityId);
      });

      const mgtData = e.detail.map((p: any) => {
        return {
          entityId: p.id,
          type: Object.prototype.hasOwnProperty.call(p, "groupTypes")
            ? PermissionType.Group
            : PermissionType.User
        } as PersonaEntity;
      });

      onChange(mgtData, invisibleUsers);
    }
  };

  const selectedUsers = useMemo(
    () =>
      value
        ?.filter(p => p.entityId !== undefined && p.type === PermissionType.User)
        .map(persona => {
          return persona.entityId;
        }),
    [value]
  );

  const selectedGroups = useMemo(
    () =>
      value
        ?.filter(p => p.entityId !== undefined && p.type === PermissionType.Group)
        .map(persona => {
          return persona.entityId;
        }),
    [value]
  );

  useEffect(() => {
    setTempHack(true);

    setTimeout(() => setTempHack(false), 10);
  }, [value]);

  return (
    <Box className="edison-people-picker-root" data-testid="people-picker">
      <EdisonTypography title={required ? label + " *" : label} variant={"fieldtitle"} />

      {tempHack && null}

      <Stack width="full" spacing={1}>
        {!tempHack && (filteredPeople !== undefined ? filteredPeoplePicker() : peoplePicker())}
        {helperText && (
          <BoldTranslation i18nKey={helperText.i18nKey} fontWeight={helperText.fontWeight} />
        )}
      </Stack>
    </Box>
  );

  function filteredPeoplePicker(): React.ReactNode {
    return (
      <PeoplePicker
        data-testid="people-picker"
        className={`${disabled ? "disabled" : ""}`}
        disabled={disabled}
        type={disableGroups ? "person" : "any"}
        userType={"any"}
        selectionChanged={!disabled ? handleSelectionChanged : undefined}
        selectionMode={multiple ? "multiple" : "single"}
        showMax={maxAvatars}
        people={filteredPeople}
        selectedPeople={selectedPeople}
      />
    );
  }

  function peoplePicker(): React.ReactNode {
    return (
      <PeoplePicker
        disabled={disabled}
        className={`${disabled ? "disabled" : ""}`}
        data-testid="people-picker"
        type={disableGroups ? "person" : "any"}
        userType={"user"}
        selectionChanged={!disabled ? handleSelectionChanged : undefined}
        selectionMode={multiple ? "multiple" : "single"}
        showMax={maxAvatars}
        defaultSelectedUserIds={selectedUsers ?? []}
        defaultSelectedGroupIds={selectedGroups ?? []}
      />
    );
  }
};

export default EdisonPeoplePickerField;
export { EdisonPeoplePickerField };
