import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

//TODO: move to common
export interface EdisonModules {
  contentHub: boolean;
  projects: boolean;
  ideas: boolean;
  challenges: boolean;
  businessCase: boolean;
  resourceManagement: boolean;
  programs: boolean;
}

export interface userState {
  tenant: string;
  instance: string;
  themeName: string;
  userHasRequiredRole: boolean;
}

export enum RequiredUserData {
  user = 1 << 0,
  license = 1 << 1,
  roles = 1 << 2
}

const initialState: userState = {
  tenant: "",
  instance: "",
  themeName: "system",
  userHasRequiredRole: true
};

const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentTenant: (state, action: PayloadAction<string>) => {
      state.tenant = action.payload;
    },
    setCurrentInstance: (state, action: PayloadAction<string>) => {
      state.instance = action.payload;
    },
    setThemeName: (state, action: PayloadAction<string>) => {
      state.themeName = action.payload;
    },
    setUserHasRequiredRole: (state, action: PayloadAction<boolean>) => {
      state.userHasRequiredRole = action.payload;
    }
  }
});

export const { setCurrentTenant, setCurrentInstance, setThemeName, setUserHasRequiredRole } =
  userSlice.actions;

const inputSelectUser = (state: RootState) => state.user;
export const inputSelectTenant = createSelector([inputSelectUser], user => user.tenant);
export const inputSelectInstance = createSelector([inputSelectUser], user => user.instance);

export const selectUserHasRequiredRole = createSelector(
  [inputSelectUser],
  user => user.userHasRequiredRole
);
export const selectThemeName = createSelector([inputSelectUser], user => user.themeName);
export const selectIsDarkTheme = createSelector(
  [inputSelectUser],
  user => user.themeName === "dark"
);
export default userSlice.reducer;
