import { BasePermission, RecordType } from "./BaseRecord.model";
import { Category } from "./Category.model";
export interface RecordTemplate {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  name: string;
  recordType: RecordType;
  displayName?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  isDeleted?: boolean;
  workflow: number;
  secondaryWorkflow?: number;
  configuration?: { [key: string]: unknown } | null;
  templatePermissions?: TemplatePermission[] | null;
  category?: Category;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TemplatePermission extends BasePermission {}

export enum RecordRole {
  Reader = "Reader",
  Editor = "Editor",
  Reviewer = "Reviewer",
  Manager = "Manager",
  Owner = "Owner",
  Ideator = "Ideator",
  ReadOnly = "ReadOnly"
}

export enum RecordAccessRole {
  Read = "Read",
  Update = "Update",
  Create = "Create",
  UpdatePermissions = "UpdatePermissions",
  Delete = "Delete",
  Review = "Review",
  Rollback = "Rollback",
  Continue = "Continue",
  SkipStep = "SkipStep",
  ReadVersion = "ReadVersion",
  CreateVersion = "CreateVersion",
  RestoreVersion = "RestoreVersion",
  ReadReviewers = "ReadReviewers",
  UpdateRows = "UpdateRows",
  CreateAssociation = "CreateAssociation",
  UpdateReviewers = "UpdateReviewers"
}
