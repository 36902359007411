import {
  BaseRecord,
  Field,
  FieldDataType,
  Form,
  NotificationLevel,
  PersonaEntity,
  RecordAccessRole,
  RecordFieldValue,
  RecordPermission,
  RecordRole,
  RecordTableConfiguration,
  RecordTableRow,
  RecordTemplate,
  RecordType,
  TableRowPeriodicGrandTotal,
  Workflow,
  WorkflowReview,
  WorkflowReviewer,
  WorkflowStage,
  WorkflowTask,
  WorkflowUserReview,
  reducerStatus,
  SaveStatusType,
  RecordSaveStatusType
} from "enada-common";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getMissingRequiredValues } from "../../components/stageapproval/stageapprovaltabs/normalstageapproval/NormalStageApproval";
import {
  deleteRecordUserLike,
  getAccessToken,
  getBCAssociations,
  getChallengeAssociations,
  getFields,
  getHistoryEvents,
  getIdeaAssociations,
  getIndividualForm,
  getIndividualRecord,
  getIndividualWorkflow,
  getProgramAssociations,
  getProjectAssociations,
  getRecordUserLiked,
  getRecordUserRating,
  getRecordViewValues,
  getRecordWorkflowReview,
  getRecordWorkflowReviewers,
  getRecordWorkflowStage,
  mergeProgramAssociations,
  postRecordGoToStep,
  postRecordPermissions,
  postRecordUserLike,
  postRecordUserRating,
  postRecordVersion,
  postRecordViewValues,
  postRecordViews,
  postRecordWorkflowContinue,
  postRecordWorkflowReview,
  postRecordWorkflowRollback,
  postRecordWorkflowTask,
  putRecord
} from "../../services/APIService";
import {
  RecordDataFlags,
  clearMultipleBitwiseFlags,
  isBitwiseFlagSet,
  setBitwiseFlag
} from "../../utils/bitwiseops";
import { RootState } from "../store";

import { savePopulationRowsAsync } from "./dataPopulationSlice";
import { setCurrentNotification } from "./notificationSlice";
import { parseFormFieldsToFrontend } from "./parseFormData";
import {
  saveRecordTableRowValuesAsync,
  saveRecordTablesConfigurationAsync,
  selectHasTableConfigurationChanges,
  selectTableOperations
} from "./recordTableSlice";
import { getMyResourcesAsync } from "./resourcesSlice";
import { RecordAssociation, RecordAssociationMerge } from "../../types/createRecordModal";
import { getCalendarsAsync } from "./calendarsSlice";

/* START - To Change to Common */
export interface RecordAuth {
  permissionToken: string;
  sessionId: string;
  details: {
    AccessRoles: RecordAccessRole[];
    RecordId: number;
    RecordRoles: RecordRole[];
    Views: number[];
    Workflow: number;
    WorkflowStage: number;
    WorkflowStep: number;
  };
  expiresOnISO?: string;
  expiresIn?: number;
}

export const getHeadersFromAuth = (recordAuth: RecordAuth): Headers => {
  const headers = new Headers();
  headers.append("edison365-sessionid", "frontend");
  return headers;
};

export interface FrontendRecordValue {
  fieldId: number;
  type?: string;
  value?: any;
  changeMade: boolean;
  id?: number;
}

export interface ExtendedValue {
  value: any;
}

export enum OperationType {
  AddOrUpdate,
  AddOrPatch,
  Delete,
  Submit,
  SaveForLater
}

export enum ApprovalOperationType {
  Approve,
  Reject
}

export enum SaveSlot {
  Slot1,
  Slot2
}

export enum RecordSettings {
  externalLinks = "externalLinks",
  editCardContent = "editCardContent",
  gamification = "gamification",
  history = "history",
  integrations = "integrations",
  manageAccess = "manageAccess",
  notifications = "notifications",
  properties = "properties",
  historyEvents = "historyEvents",
  delete = "delete",
  stageApproval = "stageApproval",
  ediRowGeneration = "ediRowGeneration",
  tableRowReview = "tableRowReview",
  associatedRecords = "associatedRecords",
  programAssociations = "programAssociations",
  versionHistory = "versionHistory"
}

export enum RecordValueType {
  boolValue = "boolValue",
  dateTimeValue = "dateTimeValue",
  decimalValue = "decimalValue",
  stringValue = "stringValue",
  extendedValue = "extendedValue"
}

export interface RecordValueTypeToFieldType {
  recordValueType: RecordValueType;
  fieldType: FieldDataType;
}

export interface RecordVersion extends BaseRecord {
  recordId: string;
  version: string;
  versionCreated: string;
  versionCreatedBy: string;
  versionName: string;
  versionDescription: string;
}

export enum RecordEvents {
  Create = "historyEventCreate",
  Update = "historyEventUpdate",
  UpdatePermission = "historyEventUpdatePermission",
  Delete = "historyEventDelete",
  Restore = "historyEventRestore",
  VersionCreate = "historyEventVersionCreate",
  StageChange = "historyEventStageChange",
  ApproveReview = "historyEventApproveReview",
  RejectReview = "historyEventRejectReview",
  EditSession = "historyEventEditSession",
  CreateAssociation = "historyEventCreateAssociation"
}

export interface HistoryEventsType {
  id: number;
  recordId: number;
  modified: string;
  modifiedBy: string;
  recordEvent: string;
  recordType: string;
}

export const fieldTypesMap: RecordValueTypeToFieldType[] = [
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.TextBox
  },
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.Email
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.RichText
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Number
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Currency
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Percentage
  },
  // {
  //   projectValueType: RecordValueType.stringValue,
  //   fieldType: FieldDataType.Image,
  // },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiLevelChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiMultiLevelChoice
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Choice
  },
  {
    recordValueType: RecordValueType.dateTimeValue,
    fieldType: FieldDataType.Date
  },
  {
    recordValueType: RecordValueType.dateTimeValue,
    fieldType: FieldDataType.DateTime
  },
  {
    recordValueType: RecordValueType.boolValue,
    fieldType: FieldDataType.Switch
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.People
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.MultiPeople
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Url
  },
  {
    recordValueType: RecordValueType.decimalValue,
    fieldType: FieldDataType.Calculated
  },
  {
    recordValueType: RecordValueType.extendedValue,
    fieldType: FieldDataType.Location
  },
  {
    recordValueType: RecordValueType.stringValue,
    fieldType: FieldDataType.Phone
  }
];

export interface CoAuthUser {
  controlRequested?: boolean;
  expiresOn?: Date;
  hasMadeChanges?: boolean;
  isConnected: boolean;
  isEditor: boolean;
  isTokenValid: boolean;
  name: string;
  oid: string;
  recordId: number;
  sessionStartTime?: Date;
}

/* END - To Change to Common */

export interface RecordState {
  record: BaseRecord | null;
  status: reducerStatus;
  authentication: RecordAuth | null;
  workflow: Workflow | null;
  form: Form | null;
  fields: Field[];
  // Normalized property to improve lookup and update performance
  //see https://redux.js.org/tutorials/essentials/part-6-performance-normalization
  frontendRecordFieldValues: Record<number, FrontendRecordValue>;
  backendRecordFieldValues: RecordFieldValue[];
  readOnly: boolean;
  hasUserLike: boolean;
  userRating: number;
  historyEvents: HistoryEventsType[] | null;
  isFlyoutOpen: boolean;
  flyoutId: string;
  currentVersion: RecordVersion | null;
  recordTemplate: RecordTemplate | null;
  currentUserReview: WorkflowUserReview | null;
  stageReviewerList: WorkflowReviewer[];
  refreshRecord: boolean;
  workflowStage: WorkflowStage | null;
  hasRollupTableChanged: boolean;
  hasRecordChanges: boolean;
  recordPermissions: RecordPermission[];
  saveStatus: RecordSaveStatusType;
  editor: CoAuthUser | undefined;
  viewers: CoAuthUser[];
  loadedData: number;
  associations: RecordAssociation[];

  //only populated when viewing a version
  recordVersionTableConfigurations?: RecordTableConfiguration[];
  recordVersionPeriodicGrandTotals?: TableRowPeriodicGrandTotal[];
}

export const RecordDataInitialised =
  RecordDataFlags.recordWorkflowStage |
  RecordDataFlags.recordAuth |
  RecordDataFlags.record |
  RecordDataFlags.recordFieldValues |
  RecordDataFlags.recordForm |
  RecordDataFlags.calendars |
  RecordDataFlags.fields |
  RecordDataFlags.resources;

const initialState: RecordState = {
  record: null,
  status: "idle",
  authentication: null,
  workflow: null,
  form: null,
  fields: [],
  frontendRecordFieldValues: {},
  backendRecordFieldValues: [],
  readOnly: false,
  hasUserLike: false,
  userRating: 0,
  historyEvents: null,
  isFlyoutOpen: false,
  flyoutId: "",
  currentVersion: null,
  recordTemplate: null,
  currentUserReview: null,
  stageReviewerList: [],
  refreshRecord: false,
  workflowStage: null,
  hasRollupTableChanged: false,
  hasRecordChanges: false,
  recordPermissions: [],
  saveStatus: "idle",
  editor: undefined,
  viewers: [],
  loadedData: 0,
  associations: []
};

export const getRecordAccessTokenAsync = createAsyncThunk(
  "records/getAccessToken",
  async (data: { recordId: number; recordType: RecordType }, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (
      state.record.authentication?.expiresOnISO &&
      state.record.authentication?.details.RecordId === data.recordId
    ) {
      if (
        new Date() < new Date(state.record.authentication.expiresOnISO) // check token expiration
      ) {
        return state.record.authentication;
      }
    }

    const headers = new Headers();
    headers.append("edison365-sessionid", "frontend");
    const response = await getAccessToken(data.recordId, data.recordType, headers);

    if (!(response.status as number).toString().startsWith("2")) {
      if (response?.errors?.length > 0) {
        return rejectWithValue(response.errors[0]);
      }
      return rejectWithValue(response?.detail);
    }
    return response.data as RecordAuth;
  }
);

export const getIndividualRecordAsync: any = createAsyncThunk(
  "records/getIndividualRecord",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getIndividualRecord(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const updateRecordAsync = createAsyncThunk(
  "record/updateRecord",
  async ({ recordAuth, record }: { recordAuth: RecordAuth; record: BaseRecord }) => {
    const response = await putRecord(record, record.recordType, getHeadersFromAuth(recordAuth));
    return (response as any).data;
  }
);

export const createRecordVersionAsync: any = createAsyncThunk(
  "record/createRecordVersion",
  async ({
    recordAuth,
    recordType,
    data
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    data: any;
  }) => {
    return await postRecordVersion(recordAuth.details.RecordId, recordType, data);
  }
);

export const getRecordUserLikedAsync: any = createAsyncThunk(
  "record/hasUserLiked",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordUserLiked(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const getHistoryEventsAsync: any = createAsyncThunk(
  "record/getHistoryEvents",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getHistoryEvents(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const getRecordUserRatingAsync: any = createAsyncThunk(
  "record/userRating",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordUserRating(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);
export const getRecordWorkflowStageAsync: any = createAsyncThunk(
  "record/workflowStage",
  async (recordAuth: RecordAuth) => {
    const response = await getRecordWorkflowStage(
      recordAuth.details.WorkflowStage,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const postRecordViewsAsync: any = createAsyncThunk(
  "record/recordViews",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await postRecordViews(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const postRecordUserLikeAsync: any = createAsyncThunk(
  "record/saveLike",
  async ({
    recordAuth,
    recordType,
    hasLiked
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    hasLiked: boolean;
  }) => {
    const response = hasLiked
      ? await postRecordUserLike(
          recordAuth.details.RecordId,
          recordType,
          getHeadersFromAuth(recordAuth)
        )
      : await deleteRecordUserLike(
          recordAuth.details.RecordId,
          recordType,
          getHeadersFromAuth(recordAuth)
        );
    return response;
  }
);

export const postRecordUserRatingAsync: any = createAsyncThunk(
  "record/saveRating",
  async ({
    recordAuth,
    recordType,
    rating
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    rating: number;
  }) => {
    const response = await postRecordUserRating(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth),
      rating
    );
    return response;
  }
);

export const getRecordWorkflowAsync: any = createAsyncThunk(
  "record/getRecordWorkflow",
  async (workflowId: number) => {
    const response = await getIndividualWorkflow(workflowId);
    return response.data;
  }
);

export const getRecordFormAsync: any = createAsyncThunk(
  "record/getRecordForm",
  async (formId: number) => {
    const response = await getIndividualForm(formId);
    return response.data;
  }
);

export const getRecordFieldsAsync: any = createAsyncThunk("record/getRecordFields", async () => {
  const response = (await getFields()) as any;
  return response.data;
});

export const getRecordViewValuesAsync = createAsyncThunk(
  "record/getRecordViewValues",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordViewValues(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const saveRecordFieldValuesAsync = createAsyncThunk(
  "record/saveRecordFieldValues",
  async ({
    recordAuth,
    recordType,
    recordFieldValues
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    recordFieldValues: any;
  }) => {
    await postRecordViewValues(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      recordFieldValues,
      getHeadersFromAuth(recordAuth)
    );
  }
);

export const postRecordWorkflowContinueAsync = createAsyncThunk(
  "record/workflowContinue",
  async (
    {
      recordAuth,
      recordType,
      comment
    }: {
      recordAuth: RecordAuth;
      recordType: RecordType;
      comment?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    const response = (await postRecordWorkflowContinue(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth),
      { comment: comment ?? "" }
    )) as any;
    if (!(response.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: "Failed to Progress Project",
          message: response.detail,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(response.data);
    }
    return response;
  }
);
export const postRecordWorkflowRollbackAsync = createAsyncThunk(
  "record/workflowRollback",
  async (
    {
      recordAuth,
      recordType,
      comment
    }: {
      recordAuth: RecordAuth;
      recordType: RecordType;
      comment?: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    const response = (await postRecordWorkflowRollback(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth),
      { comment: comment ?? "" }
    )) as any;
    if (!(response.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: "Failed to Rollback Project",
          message: response.detail,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(response.data);
    }
    return response;
  }
);

export const postRecordGoToStepAsync = createAsyncThunk(
  "record/recordGoToStep",
  async ({
    recordAuth,
    recordType,
    stepId,
    comment
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    stepId: number;
    comment: string;
  }) => {
    const response = await postRecordGoToStep(
      recordAuth.details.RecordId,
      recordType,
      stepId,
      getHeadersFromAuth(recordAuth),
      { comment }
    );
    return response;
  }
);

export const postRecordWorkflowReviewAsync = createAsyncThunk(
  "record/recordWorkflowReview",
  async ({
    recordAuth,
    recordType,
    review
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    review: WorkflowReview;
  }) => {
    const response = await postRecordWorkflowReview(
      recordAuth.details.RecordId,
      recordType,
      review,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);
export const postRecordWorkflowTaskAsync = createAsyncThunk(
  "record/recordWorkflowTasks",
  async ({
    recordAuth,
    recordType,
    task
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    task: WorkflowTask;
  }) => {
    const response = await postRecordWorkflowTask(
      recordAuth.details.RecordId,
      recordType,
      task,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const getRecordWorkflowReviewAsync = createAsyncThunk(
  "record/getRecordWorkflowReview",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordWorkflowReview(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const getRecordWorkflowReviewersAsync = createAsyncThunk(
  "record/getRecordWorkflowReviewers",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordWorkflowReviewers(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const postRecordPermissionsAsync = createAsyncThunk(
  "record/recordApprove",
  async ({
    recordAuth,
    recordType,
    recordPermissions
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    recordPermissions: RecordPermission[];
  }) => {
    const response = await postRecordPermissions(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth),
      recordPermissions
    );
    return response;
  }
);

export const getBCAssociationsAsync = createAsyncThunk(
  "record/getCBAssociations",
  async (recordAuth: RecordAuth) => {
    const response = await getBCAssociations(recordAuth.details.RecordId);
    return response.data;
  }
);

export const getProjectAssociationsAsync = createAsyncThunk(
  "record/getProjectAssociations",
  async (recordAuth: RecordAuth) => {
    const response = await getProjectAssociations(recordAuth.details.RecordId);
    return response.data;
  }
);
export const getProgramAssociationsAsync = createAsyncThunk(
  "record/getProgramAssociations",
  async (recordAuth: RecordAuth) => {
    const response = await getProgramAssociations(recordAuth.details.RecordId);
    return response.data;
  }
);
export const getIdeaAssociationsAsync = createAsyncThunk(
  "record/getIdeaAssociations",
  async (recordAuth: RecordAuth) => {
    const response = await getIdeaAssociations(recordAuth.details.RecordId);

    return response.data;
  }
);

export const getChallengeAssociationsAsync = createAsyncThunk(
  "record/getChallengeAssociations",
  async (recordAuth: RecordAuth) => {
    const response = await getChallengeAssociations(recordAuth.details.RecordId);
    return response.data;
  }
);

export const mergeAndGetProgramAssociationsAsync = createAsyncThunk(
  "record/mergeProgramAssociations",
  async ({
    recordId,
    newAssociations
  }: {
    recordId: number;
    newAssociations: RecordAssociationMerge[];
  }) => {
    const response = await mergeProgramAssociations(recordId, newAssociations);
    if (response.status === 200) {
      const updatedAssociations = await getProgramAssociations(recordId);
      return updatedAssociations.data;
    }
  }
);

const recordSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    setRecordInfo: (state, action: PayloadAction<BaseRecord>) => {
      state.record = action.payload;
    },
    setIsFlyoutOpen: (state, action: PayloadAction<boolean>) => {
      state.isFlyoutOpen = action.payload;
    },
    setFlyoutId: (state, action: PayloadAction<string>) => {
      state.flyoutId = action.payload;
    },
    setRecordReadOnly: (state, action: PayloadAction<boolean>) => {
      state.readOnly = action.payload;
    },
    setRecordEditor: (state, action: PayloadAction<CoAuthUser | undefined>) => {
      state.editor = action.payload;
    },
    removeRecordEditor: (state, action: PayloadAction<CoAuthUser | undefined>) => {
      state.editor = state.editor?.oid === action.payload?.oid ? undefined : state.editor;
    },
    addViewers: (state, action) => {
      state.viewers = action.payload.filter((viewer: any) => viewer.oid !== state.editor?.oid);
    },
    addViewer: (state, action) => {
      state.viewers =
        state.editor?.oid === action.payload?.oid
          ? state.viewers
          : [...state.viewers, action.payload];
    },
    removeViewer: (state, action: PayloadAction<CoAuthUser>) => {
      state.viewers = state.viewers.filter(viewer => viewer.oid !== action.payload.oid);
    },
    resetCoAuth: state => {
      state.editor = undefined;
      state.viewers = [];
    },
    resetReviewers: state => {
      state.stageReviewerList = [];
      state.currentUserReview = null;
    },
    updateSystemFields: (
      state,
      action: PayloadAction<{ key: keyof BaseRecord; changeValue: any }>
    ) => {
      const { key, changeValue } = action.payload;
      if (!state.record) return; // record should always have a value if we are updating it.
      state.record = { ...state.record, [key]: changeValue };
      state.hasRecordChanges = true;
    },
    updateRecordFieldValue: (state, action: PayloadAction<{ id?: number; changeValue: any }>) => {
      const fieldValueToChange = state.frontendRecordFieldValues[action.payload.id as number];
      state.frontendRecordFieldValues[action.payload.id as number] = {
        ...fieldValueToChange,
        value: action.payload.changeValue,
        changeMade: true
      };
    },
    resetRecordSlice: state => {
      state.record = null;
      state.status = "idle";
      //state.authentication = null;
      state.workflow = null;
      state.form = null;
      state.fields = [];
      state.frontendRecordFieldValues = {};
      state.backendRecordFieldValues = [];
      state.readOnly = false;
      state.hasUserLike = false;
      state.userRating = 0;
      state.historyEvents = null;
      state.isFlyoutOpen = false;
      state.flyoutId = "";
      state.currentVersion = null;
      state.recordTemplate = null;
      state.currentUserReview = null;
      state.stageReviewerList = [];
      state.refreshRecord = false;
      state.workflowStage = null;
      state.hasRollupTableChanged = false;
      state.hasRecordChanges = false;
      state.recordPermissions = [];
      state.saveStatus = "idle";
      state.editor = undefined;
      state.viewers = [];
      state.loadedData = 0;
      state.associations = [];
    },
    clearRecordAuth: state => {
      state.authentication = null;
    },
    setRefreshRecord: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        const newAuth = {
          ...state.authentication,
          expiresOnISO: "0"
        } as RecordAuth;
        state.authentication = newAuth;
      }
      if (action.payload) {
        state.loadedData = 0;
      }
      state.refreshRecord = action.payload;
    },
    updateRecordRoles: (
      state,
      action: PayloadAction<{
        role: RecordRole;
        entities?: PersonaEntity[];
      }>
    ) => {
      const filteredPermissions = state.recordPermissions.filter(
        permission => permission.role !== action.payload.role
      );

      const updatedPermissions = action.payload.entities
        ? action.payload.entities.map(
            entity =>
              ({
                entityId: entity.entityId,
                permissionType: entity.type,
                role: action.payload.role
              } as RecordPermission)
          )
        : [];

      if (filteredPermissions) {
        state.recordPermissions = filteredPermissions.concat(updatedPermissions);
      }
    },

    updateSaveStatus: (state, action: PayloadAction<SaveStatusType>) => {
      switch (action.payload) {
        case "record":
          state.hasRecordChanges = false;
          break;
        case "fields":
          state.frontendRecordFieldValues = Object.values(state.frontendRecordFieldValues)
            .map(fieldValue => {
              return { ...fieldValue, changeMade: false };
            })
            .reduce((acc, curr) => ({ ...acc, [curr.fieldId]: curr }), {});
          break;
        default:
          break;
      }

      state.saveStatus = getUpdatedSaveStatus(state.saveStatus, action.payload, undefined);
    },
    setHasRollupTableChanged: (state, action: PayloadAction<boolean>) => {
      state.hasRollupTableChanged = action.payload;
    },
    addRecordDataLoaded: (state, action: PayloadAction<RecordDataFlags>) => {
      state.loadedData = setBitwiseFlag(state.loadedData, action.payload);
    },
    clearRecordDataLoadedBits: (state, action: PayloadAction<RecordDataFlags[]>) => {
      state.loadedData = clearMultipleBitwiseFlags(state.loadedData, action.payload);
    },
    setCurrentVersion: (state, action: PayloadAction<RecordVersion | null>) => {
      state.currentVersion = action.payload;
    },
    setRecordVersion: (state, action: PayloadAction<any>) => {
      if (!action.payload) return;

      state.fields = action.payload.versionData.fields;
      state.userRating = action.payload.versionData.record.averageRating;
      state.record = {
        ...action.payload.versionData.record,
        id: action.payload.recordId
      };
      state.recordTemplate = action.payload.versionData.record.recordTemplate;
      state.recordPermissions = action.payload.versionData.record.recordPermissions || [];
      state.recordVersionTableConfigurations =
        action.payload.versionData.record.recordTableConfigurations || [];

      // grab the periodic grand totals from the version data and group them by tableId
      state.recordVersionPeriodicGrandTotals = action.payload.versionData.record.tableRows
        .filter((row: any) => Object.prototype.hasOwnProperty.call(row, "grandTotal"))
        .reduce((acc: any[], row: any) => {
          const tableId = row.tableId;
          const existingTable = acc.find(table => table.tableId === tableId);
          if (existingTable) {
            existingTable.totals[row.id] = row.grandTotal;
          } else {
            acc.push({ tableId, totals: { [row.id]: row.grandTotal } });
          }
          return acc;
        }, []);

      state.workflow = action.payload.versionData.record.workflow;
      state.workflowStage = action.payload.versionData.record.workflowStage;
      state.form = action.payload.versionData.record.workflowStage.form;
      state.backendRecordFieldValues = action.payload.versionData.record.fieldValues.filter(
        (row: RecordTableRow) =>
          !row.recordId || row.recordId === state.authentication?.details.RecordId
      );

      if (state.form) {
        const parsed = parseFormFieldsToFrontend(
          state.form,
          state.fields,
          state.backendRecordFieldValues
        );
        state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
          return { ...acc, [curr.fieldId]: curr };
        }, {});
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getRecordAccessTokenAsync.fulfilled, (state, action) => {
        state.authentication = {
          permissionToken: action.payload.permissionToken,
          sessionId: "frontend",
          details: action.payload.details,
          expiresIn: action.payload.expiresIn,
          expiresOnISO: action.payload.expiresOnISO
        };
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordAuth);
      })
      .addCase(getRecordAccessTokenAsync.rejected, state => {
        //setting access token to empty object if rejected so the RecordAccess returns access denied instead of hanging
        state.authentication = {} as any;
      })
      .addCase(saveRecordFieldValuesAsync.fulfilled, state => {
        state.saveStatus = getUpdatedSaveStatus(state.saveStatus, undefined, "fields");
      })
      .addCase(getIndividualRecordAsync.fulfilled, (state, action) => {
        state.record = action.payload;
        state.recordPermissions = action.payload.recordPermissions;
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.record);
      })
      .addCase(createRecordVersionAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
      })
      .addCase(getRecordUserLikedAsync.fulfilled, (state, action) => {
        state.hasUserLike = action.payload;
      })
      .addCase(getRecordUserRatingAsync.fulfilled, (state, action) => {
        state.userRating = action.payload;
      })
      .addCase(getHistoryEventsAsync.fulfilled, (state, action) => {
        state.historyEvents = action.payload;
      })
      .addCase(getRecordWorkflowStageAsync.fulfilled, (state, action) => {
        state.workflowStage = action.payload;
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordWorkflowStage);
      })
      .addCase(postRecordViewsAsync.fulfilled, state => {
        state.status = "idle";
      })
      .addCase(postRecordUserLikeAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(postRecordUserLikeAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(postRecordUserLikeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.hasUserLike = action.payload;
      })
      .addCase(getRecordWorkflowAsync.fulfilled, (state, action) => {
        state.workflow = action.payload;
      })
      .addCase(getRecordFormAsync.fulfilled, (state, action) => {
        state.form = action.payload;
        const parsed = parseFormFieldsToFrontend(
          action.payload,
          state.fields,
          state.backendRecordFieldValues
        );
        state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
          return { ...acc, [curr.fieldId]: curr };
        }, {});
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordForm);
      })

      .addCase(updateRecordAsync.fulfilled, (state, action) => {
        state.saveStatus = getUpdatedSaveStatus(state.saveStatus, undefined, "record");

        if (action.payload) {
          // When receiving the updated record from the backend they send the recordTemplate which is not needed
          delete action.payload.recordTemplate;

          state.record = action.payload;
        }
        //state.hasRecordChanges = false;
      })
      .addCase(getRecordViewValuesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getRecordViewValuesAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.status = "idle";

        state.backendRecordFieldValues = action.payload.filter(
          (row: RecordTableRow) =>
            !row.recordId || row.recordId === state.authentication?.details.RecordId
        );

        if (state.form) {
          const parsed = parseFormFieldsToFrontend(
            state.form,
            state.fields,
            state.backendRecordFieldValues
          );
          state.frontendRecordFieldValues = parsed.reduce((acc, curr) => {
            return { ...acc, [curr.fieldId]: curr };
          }, {});
        }
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordFieldValues);
      })
      .addCase(getRecordFieldsAsync.fulfilled, (state, action) => {
        state.fields = action.payload;
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.fields);
      })
      .addCase(saveRecordTableRowValuesAsync.fulfilled, state => {
        state.saveStatus = getUpdatedSaveStatus(state.saveStatus, undefined, "rows");
      })
      .addCase(postRecordWorkflowContinueAsync.fulfilled, state => {
        state.loadedData = 0;
        state.refreshRecord = true;
        state.isFlyoutOpen = false;
      })
      .addCase(getRecordWorkflowReviewAsync.fulfilled, (state, action) => {
        state.currentUserReview = action.payload;
      })
      .addCase(postRecordWorkflowTaskAsync.fulfilled, (state, action) => {
        let tasks = state.currentUserReview?.workflowTasks ?? [];

        if (tasks.some(task => task.id === action.payload.id)) {
          tasks = tasks.map(task => (task.id === action.payload.id ? action.payload : task));
        } else {
          tasks.push(action.payload);
        }
        state.currentUserReview = {
          ...state.currentUserReview,
          workflowTasks: tasks
        };
      })
      .addCase(getRecordWorkflowReviewersAsync.fulfilled, (state, action) => {
        state.stageReviewerList = action.payload;
      })
      .addCase(postRecordWorkflowReviewAsync.fulfilled, (state, action) => {
        state.currentUserReview = {
          ...state.currentUserReview,
          workflowReview: action.payload
        };
      })
      .addCase(postRecordGoToStepAsync.fulfilled, state => {
        state.isFlyoutOpen = false;
      })
      .addCase(saveRecordTablesConfigurationAsync.fulfilled, state => {
        state.saveStatus = getUpdatedSaveStatus(state.saveStatus, undefined, "table-config");
      })
      .addCase(getMyResourcesAsync.fulfilled, state => {
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.resources);
      })
      .addCase(getCalendarsAsync.fulfilled, state => {
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.calendars);
      })
      .addCase(getBCAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload;
      })
      .addCase(getProjectAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload === undefined ? [] : action.payload;
      })
      .addCase(getProgramAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload === undefined ? [] : action.payload;
      })
      .addCase(mergeAndGetProgramAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload === undefined ? [] : action.payload;
      })
      .addCase(getIdeaAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload === undefined ? [] : action.payload;
      })
      .addCase(getChallengeAssociationsAsync.fulfilled, (state, action) => {
        state.associations = action.payload === undefined ? [] : action.payload;
      })
      ///Workflow data population case////
      // Temporarily moved the workflow data population table row data to the record slice
      // to use the recordsPage row management system(e.g. mergeRows process), this is needed atm
      // becasue the TableMapper (which is used by both the record page and the population) is currently coupled to
      // the recordSlice.
      //TODO: Move these cases back to the population slice once we have decoupled the table mapper from the recordSlice
      // Save status is stored in the record slice as it stores the save status of all data related to records
      .addCase(savePopulationRowsAsync.fulfilled, state => {
        state.saveStatus = getUpdatedSaveStatus(state.saveStatus, undefined, "rows");
      });
  }
});

export const {
  setRecordInfo,
  setRecordReadOnly,
  setIsFlyoutOpen,
  setFlyoutId,
  updateRecordFieldValue,
  updateSystemFields,
  resetRecordSlice,
  setRefreshRecord,
  updateRecordRoles,
  updateSaveStatus,
  setRecordEditor,
  removeRecordEditor,
  addViewers,
  addViewer,
  removeViewer,
  resetCoAuth,
  setHasRollupTableChanged,
  resetReviewers,
  addRecordDataLoaded,
  clearRecordDataLoadedBits,
  clearRecordAuth,
  setCurrentVersion,
  setRecordVersion
} = recordSlice.actions;

const inputSelectRecord = (state: RootState) => state.record;

// Minimum data that needs to have loaded to render the project
export const selectRecordBaseDataLoaded = createSelector([inputSelectRecord], record => {
  return (
    isBitwiseFlagSet(record.loadedData, RecordDataFlags.record) &&
    isBitwiseFlagSet(record.loadedData, RecordDataFlags.recordForm) &&
    isBitwiseFlagSet(record.loadedData, RecordDataFlags.recordAuth) &&
    isBitwiseFlagSet(record.loadedData, RecordDataFlags.fields) &&
    isBitwiseFlagSet(record.loadedData, RecordDataFlags.recordWorkflowStage)
  );
});
export const selectRecordLoadedData = createSelector(
  [inputSelectRecord],
  record => record.loadedData
);
// All data required for full record functionality loaded
export const selectRecordFullyInitialised = createSelector([inputSelectRecord], record => {
  return RecordDataInitialised === record.loadedData;
});

export const selectRecord = createSelector([inputSelectRecord], record => record.record);

export const selectRecordType = (state: RootState) =>
  state.record.record?.recordType ?? RecordType.Projects;

export const selectHasUserLiked = createSelector([inputSelectRecord], record => record.hasUserLike);

export const selectHistoryEvents = createSelector(
  [inputSelectRecord],
  record => record.historyEvents
);

export const selectEditor = createSelector([inputSelectRecord], record => record.editor);
export const selectViewers = createSelector([inputSelectRecord], record => record.viewers);

export const selectUserRating = createSelector([inputSelectRecord], record => record.userRating);

export const selectCurrentVersion = createSelector(
  [inputSelectRecord],
  record => record.currentVersion
);

export const selectRecordAuth = createSelector(
  [inputSelectRecord],
  record => record.authentication
);

export const selectRecordWorkflow = createSelector([inputSelectRecord], record => record.workflow);

export const selectRecordForm = createSelector([inputSelectRecord], record => record.form);

export const selectRecordFields = createSelector([inputSelectRecord], record => record.fields);

export const selectRecordReadOnly = createSelector([inputSelectRecord], record => record.readOnly);

export const selectWorkflowStage = createSelector(
  [inputSelectRecord],
  record => record.workflowStage
);

export const selectIsRecordFlyoutOpen = createSelector(
  [inputSelectRecord],
  record => record.isFlyoutOpen
);

export const selectFlyoutId = createSelector([inputSelectRecord], record => record.flyoutId);

export const selectRecordFieldValues = createSelector(
  [inputSelectRecord],
  (record): FrontendRecordValue[] => Object.values(record.frontendRecordFieldValues)
);
export const selectFieldValueById = createSelector(
  [inputSelectRecord, (_, fieldId) => fieldId],
  (record, fieldId) => record.frontendRecordFieldValues[fieldId]
);

export const selectWorkflowUserReviews = createSelector(
  [inputSelectRecord],
  record => record.currentUserReview
);

export const selectRecordStageReviewerList = createSelector(
  [inputSelectRecord],
  record => record.stageReviewerList
);

export const selectRefreshRecord = createSelector(
  [inputSelectRecord],
  record => record.refreshRecord
);

export const selectRecordStatus = createSelector([inputSelectRecord], record => record.status);

export const selectRecordPermissions = createSelector(
  [inputSelectRecord],
  record => record.recordPermissions
);

export const selectRecordTypePermissions = (state: RootState) =>
  state.record.recordTemplate?.templatePermissions;

export const selectRecordSaveStatus = createSelector(
  [inputSelectRecord],
  record => record.saveStatus
);

export const selectHasRecordChanges = createSelector(
  [inputSelectRecord],
  record => record.hasRecordChanges
);

export const selectHasRollupTableChanged = createSelector(
  [inputSelectRecord],
  record => record.hasRollupTableChanged
);

export const selectHasChanges = createSelector(
  [
    selectRecordFieldValues,
    selectTableOperations,
    selectHasRecordChanges,
    selectHasTableConfigurationChanges,
    selectCurrentVersion
  ],
  (
    frontendValues,
    tableOperations,
    hasRecordChanges,
    hasTableConfigurationChanges,
    currentVersion
  ) => {
    if (currentVersion) {
      return false;
    }

    const hasFieldChanges = frontendValues.some(field => {
      return field.changeMade === true;
    });
    const hasTableChanges = tableOperations.length > 0;

    const result =
      hasFieldChanges || hasTableChanges || hasRecordChanges || hasTableConfigurationChanges;

    return result;
  }
);

export const selectUserAccessRoles = createSelector(
  [inputSelectRecord],
  record => record.authentication?.details.AccessRoles
);

export const selectUserRecordRoles = createSelector(
  [inputSelectRecord],
  (
    record //[RecordRole.Manager, RecordRole.Owner]
  ) => record.authentication?.details.RecordRoles
);
// Pulls state from both recordSlice and recordTableSlice
export const selectRecordTabsMissingValues = createSelector(
  [inputSelectRecord, (state: RootState) => state.recordTable.backendRecordRowValues],
  (record, backendTableRowValues) => {
    const stageView =
      record.workflowStage &&
      record.workflowStage?.views &&
      record.workflowStage?.views?.length !== 0
        ? record.workflowStage.views[0] // TODO : review this when we can add multiple views to the a workflow
        : undefined;
    const { missingFields, missingTables } = getMissingRequiredValues(
      Object.values(record.frontendRecordFieldValues),
      backendTableRowValues,
      stageView
    );
    return record.form?.containers
      ?.filter(tab =>
        tab?.zones?.some(zone =>
          zone.columns?.some(column => {
            return (
              column.fields?.some(columnField =>
                missingFields.some(missingField => missingField.fieldId === columnField.fieldId)
              ) ||
              column.tables?.some(columnTable =>
                missingTables.some(missingTable => missingTable.tableId === columnTable.tableId)
              )
            );
          })
        )
      )
      .map(container => container.id);
  }
);
export const selectRecordAssociations = createSelector(
  [inputSelectRecord],
  record => record.associations
);
export const selectRecordTaskTableId = createSelector(
  [inputSelectRecord],
  record => record.record?.taskTableId
);
export const selectRecordUseChildDates = createSelector(
  [inputSelectRecord],
  record => record.record?.useChildDateRange
);
export const selectRecordVersionTableConfigurations = (state: RootState) =>
  state.record.recordVersionTableConfigurations;

export const selectRecordVersionPeriodicGrandTotals = (state: RootState) =>
  state.record.recordVersionPeriodicGrandTotals;

export default recordSlice.reducer;

const getUpdatedSaveStatus = (
  status: RecordSaveStatusType,
  pending: SaveStatusType | undefined,
  fulfilled: SaveStatusType | undefined
) => {
  if (pending) {
    const pendingStatus = Array.isArray(status) ? [...status, pending] : [pending];
    return pendingStatus;
  }

  if (fulfilled && Array.isArray(status)) {
    const fulfilledStatus = status.filter(x => x !== fulfilled);
    if (fulfilledStatus.length) return fulfilledStatus;
  }

  return "idle";
};
