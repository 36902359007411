import { Box, BoxProps } from "@mui/material";
import { FC } from "react";
import { Trans, TransProps } from "react-i18next";
import { ParseKeys } from "i18next";
import { TypographyVariant } from "enada-common";
import { useTypographyFromVariant } from "../../utils/hooks/useTypographyFromVariant";

type BoldTranslationProps = TransProps<ParseKeys> &
  BoxProps & {
    variant?: TypographyVariant;
  };

const BoldTranslation: FC<BoldTranslationProps> = ({
  i18nKey,
  values,
  color = "inherit",
  fontWeight = "900",
  variant = "helpertext",
  ...props
}) => {
  const { variantData } = useTypographyFromVariant(variant);
  return (
    <Box
      as="span"
      display="inline-block"
      width="full"
      letterSpacing={variantData.letterSpacing}
      fontSize={variantData.fontSize}
      fontWeight={variantData.fontWeight}
      {...props}
    >
      <Trans
        i18nKey={i18nKey}
        values={values}
        components={{
          bold: <Box component="span" color={color} fontWeight={fontWeight} />
        }}
      />
    </Box>
  );
};

export default BoldTranslation;
export { BoldTranslation };
