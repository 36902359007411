import { FC, useEffect, useMemo } from "react";
import { Alert, Dialog, DialogTitle, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectRecordTableBackendRowValues,
  setRecordTableRowValues
} from "store/slices/recordTableSlice";
import { useDisclosure } from "utils/hooks/useDisclosure";
import { BoldTranslation, EdisonTypography } from "enada-components";
import { selectAllCalendars } from "store/slices/calendarsSlice";
import { SystemFieldType } from "pages/admin/tableconfig/TableConfig";
import {
  useGetResourcesQuery,
  useGetTablesQuery,
  useUpdateTableRowValuesMutation
} from "services/api";
import { selectCanEditRecord, selectRecord, selectRecordAuth } from "store/slices/recordSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { OperationType, ResourceType } from "enada-common";
import { useGetAADResources } from "utils/hooks/useGetAADResources";

export const EffectiveCalendarChangedDialog: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const calendars = useAppSelector(selectAllCalendars);
  const canEditRecord = useAppSelector(selectCanEditRecord);
  const recordTableRows = useAppSelector(selectRecordTableBackendRowValues);

  const { data: tables = [] } = useGetTablesQuery();
  const { data: resources = [] } = useGetResourcesQuery();
  const { aadResources } = useGetAADResources();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateEffectiveCalendarChangedValue, { isLoading }] = useUpdateTableRowValuesMutation();

  // Rows that have effective calendar working time changed
  const effectiveCalendarChangedRows = useMemo(() => {
    return recordTableRows.filter(row => row.effectiveCalendarWorkingTimeChanged);
  }, [recordTableRows]);

  // Rows that have resource calendar changed
  const resourceCalendarChangedRows = useMemo(() => {
    return recordTableRows.filter(row => row.resourceCalendarChanged);
  }, [recordTableRows]);

  const groupedChangedCalendars = useMemo(() => {
    const calendarMap = new Map();

    effectiveCalendarChangedRows.forEach(row => {
      const calendarId = row.tableRowFieldValues?.find(
        field => field.fieldId === SystemFieldType.ExtendedCalendarId
      )?.decimalValue;

      if (calendarId) {
        if (!calendarMap.has(calendarId)) {
          calendarMap.set(calendarId, { tables: new Set(), resources: new Set() });
        }
        calendarMap
          .get(calendarId)
          .tables.add(tables.find(table => table.id === row.tableId)?.displayName);
      }
    });

    resourceCalendarChangedRows.forEach(row => {
      const changedResources = row.tableRowFieldValues
        ?.find(field => field.fieldId === SystemFieldType.AssignedResource)
        ?.extendedValue?.value?.filter(resource => resource.calendarChanged);

      if (changedResources) {
        changedResources.forEach(resource => {
          const resourceData = resources.find(res => res.id === resource.resourceId);
          if (!resourceData) return;

          const calendarId = resourceData.calendarId;
          if (!calendarId) return;

          const resourceName =
            resourceData.type === ResourceType.AAD
              ? aadResources[resourceData.userId ?? ""]?.displayName
              : resourceData.displayName;

          if (!calendarMap.has(calendarId)) {
            calendarMap.set(calendarId, { tables: new Set(), resources: new Set() });
          }

          calendarMap.get(calendarId).resources.add(resourceName);
          calendarMap
            .get(calendarId)
            .tables.add(tables.find(table => table.id === row.tableId)?.displayName);
        });
      }
    });

    return Array.from(calendarMap.entries()).map(([calendarId, { tables, resources }]) => ({
      calendarName:
        calendars.find(calendar => calendar.id === calendarId)?.displayName || "Unknown",
      changedItems: {
        tables: Array.from(tables).filter(Boolean) as string[],
        resources: Array.from(resources).filter(Boolean) as string[]
      }
    }));
  }, [
    calendars,
    effectiveCalendarChangedRows,
    aadResources,
    resourceCalendarChangedRows,
    resources,
    tables
  ]);

  useEffect(() => {
    if (canEditRecord && groupedChangedCalendars.length) onOpen();
    else onClose();
  }, [onOpen, groupedChangedCalendars, canEditRecord, onClose]);

  const onUpdateChangedValue = async () => {
    if (!recordAuth || !record) return;
    try {
      await updateEffectiveCalendarChangedValue({
        recordAuth,
        recordType: record?.recordType,
        body: [
          {
            operationType: OperationType.AddOrPatch,
            tableRows: [...effectiveCalendarChangedRows, ...resourceCalendarChangedRows].map(
              row => ({
                ...row,
                effectiveCalendarWorkingTimeChanged: false,
                resourceCalendarChanged: false
              })
            )
          }
        ],
        invalidateTags: "RecordTableRows"
      }).unwrap();

      dispatch(
        setRecordTableRowValues({
          rows: recordTableRows.map(row => ({
            ...row,
            effectiveCalendarWorkingTimeChanged: false
          })),
          isRefetching: false
        })
      );

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {t("effectiveCalendars.updated", { count: groupedChangedCalendars.length })}
      </DialogTitle>
      <Stack spacing={2} padding="1em">
        <Alert severity="warning">
          <Stack spacing={1} direction="column" minWidth="450px">
            <>
              <EdisonTypography
                title={t("effectiveCalendars.resourceCalendarMessage")}
                variant="data2"
              />
              {groupedChangedCalendars.map(({ calendarName, changedItems }) => (
                <Stack key={calendarName} spacing={1} direction="column" width="full">
                  <ul>
                    <li>
                      <EdisonTypography title={calendarName} variant="tableheader" />

                      <ul>
                        {changedItems.tables.length > 0 && (
                          <li>
                            <BoldTranslation
                              i18nKey="effectiveCalendars.affectedTables"
                              values={{
                                tables: changedItems.tables.join(", "),
                                count: changedItems.tables.length
                              }}
                              variant="data2"
                              display="block"
                            />
                          </li>
                        )}

                        {changedItems.resources.length > 0 && (
                          <li>
                            <BoldTranslation
                              i18nKey="effectiveCalendars.affectedResources"
                              values={{
                                resources: changedItems.resources.join(", "),
                                count: changedItems.resources.length
                              }}
                              variant="data2"
                              display="block"
                            />
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </Stack>
              ))}
            </>

            <EdisonTypography title={t("effectiveCalendars.reviewTasks")} variant="data2" />
          </Stack>
        </Alert>
        <Stack direction="row" spacing={1} justifyContent="end">
          <LoadingButton onClick={onUpdateChangedValue} loading={isLoading} variant="outlined">
            {t("close")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
