import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualField,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import { EdisonTypography } from "enada-components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./percentageconfig.scss";

const PercentageConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualField = useAppSelector(selectIndividualField);

  const updateConfiguration = (key: string, value: number | string | boolean) => {
    dispatch(
      updateIndividualFieldProperty({
        key: "configuration",
        value: {
          ...individualField?.configuration,
          [key]: value
        }
      })
    );
  };
  return (
    <Stack spacing={3} className="percentage-config-root">
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} className="format-container">
          <EdisonTypography variant="h4" title={t("decimalPlaces")} />
          <RadioGroup
            row
            value={individualField?.configuration?.decimalPlaces ?? 1}
            onChange={e => updateConfiguration("decimalPlaces", e.target.value)}
          >
            {["0", "1", "2", "3", "4", "5"].map(key => (
              <FormControlLabel value={key} control={<Radio />} label={key} key={key} />
            ))}
          </RadioGroup>
        </Stack>
        <Stack direction="row" className="bounds-container">
          <TextField
            className="input"
            size="small"
            label={t("minimumValue")}
            type="number"
            variant="outlined"
            value={individualField?.min ?? null}
            onChange={e =>
              dispatch(
                updateIndividualFieldProperty({
                  key: "min",
                  value: e.target.value
                })
              )
            }
          />
          <TextField
            className="input"
            size="small"
            label={t("maximumValue")}
            type="number"
            variant="outlined"
            value={individualField?.max ?? null}
            onChange={e =>
              dispatch(
                updateIndividualFieldProperty({
                  key: "max",
                  value: e.target.value
                })
              )
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PercentageConfig;
