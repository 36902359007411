import { FieldDataType, reducerStatus } from "enada-common";
import React, { FC } from "react";
import {
  UserDatePicker,
  UserPeoplePickerField,
  UserRichTextField,
  UserTextField,
  UserNumberField,
  UserEmailField,
  UserPercentageField,
  UserCurrencyField,
  UserURLField,
  UserMultiLevelChoiceField,
  UserSwitchField,
  UserDateTimePicker,
  UserSingleChoiceField,
  UserMultiChoiceField,
  UserLocationField,
  Loading,
  UserPhoneField
} from "enada-components";

import ReadOnlyWrapper from "../components/readonlywrapper/ReadOnlyWrapper";
import UserCalculatedField from "../components/adminconfig/calculated/userfield/UserCalculatedField";
import { SystemFieldType } from "../pages/admin/tableconfig/TableConfig";
import { FrontendRecordValue, updateSystemFields } from "../store/slices/recordSlice";
import { useAppDispatch } from "../store/hooks";
import dayjs from "dayjs";

interface MapperProps {
  component: any;
  readOnly: boolean;
  isInTable?: boolean;
  isInRecord?: boolean;
  isIconOn?: boolean;
  value?: any;
  onChange?: any;
  t?: any;
  useReadOnlyWrapper?: boolean;
  useInternalState: boolean;
  status?: reducerStatus;
  fieldValues?: FrontendRecordValue[];
  required?: boolean;
}

const ComponentMapper: FC<MapperProps> = ({
  component,
  readOnly,
  isIconOn,
  isInTable,
  isInRecord,
  value,
  onChange,
  fieldValues,
  status,
  required,
  useReadOnlyWrapper = false,
  useInternalState = false ///TODO Add logic depending on if we want to use internal state or not
}) => {
  const dispatch = useAppDispatch();
  let props: any = {};
  const commonProps: any = {
    dataTestidValue: `data-testid-form-field-${component.name}`,
    label: component.displayName as string,
    readOnly,
    required,
    onChange,
    isIconOn,
    isInTable,
    useInternalState,
    name: component.displayName,
    description: component.description,
    ...component.configuration
  };

  switch (component?.dataType) {
    case FieldDataType.Switch:
      props = {
        ...commonProps,
        value: value === undefined ? Boolean(component?.configuration?.defaultToYes) : value,
        onLabel: component.configuration?.yesLabel,
        offLabel: component.configuration?.noLabel
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserSwitchField} props={props} />
      ) : (
        <UserSwitchField {...props} />
      );
    case FieldDataType.Date: {
      const updatedOnChange = (changeValue: any) => {
        if (component.id === SystemFieldType.StartDate) {
          dispatch(
            updateSystemFields({
              key: "startDate",
              changeValue: changeValue ? dayjs(changeValue)?.utc().format() : changeValue
            })
          );
        }
        if (component.id === SystemFieldType.EndDate) {
          dispatch(
            updateSystemFields({
              key: "endDate",
              changeValue: changeValue ? dayjs(changeValue)?.utc().format() : changeValue
            })
          );
        }
        onChange(changeValue);
      };
      props = {
        ...commonProps,
        onChange: updatedOnChange,
        value: value ? value : "", //Change this to work for the record page
        maxDate:
          component.id === SystemFieldType.StartDate
            ? fieldValues?.find(value => value.fieldId === SystemFieldType.EndDate)?.value ?? ""
            : "",
        minDate:
          component.id === SystemFieldType.EndDate
            ? fieldValues?.find(value => value.fieldId === SystemFieldType.StartDate)?.value ?? ""
            : ""
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserDatePicker} props={props} />
      ) : (
        <UserDatePicker {...props} />
      );
    }
    case FieldDataType.DateTime:
      props = {
        ...commonProps,
        readOnly: ["e365-Created", "e365-Modified"].includes(component.name) ? true : readOnly,
        value: value ? value : "" //Change this to work for the record page
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper
          keepReadOnly={
            ["e365-Created", "e365-Modified"].includes(component.name) ? true : readOnly
          }
          Component={UserDateTimePicker}
          props={props}
        />
      ) : (
        <UserDateTimePicker {...props} />
      );
    case FieldDataType.TextBox:
      props = {
        ...commonProps,
        value: value ? value : "",
        requiredMessage: "Text Field value required",
        multiline: false,
        disabled: false,
        maxLength: 20
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserTextField} props={props} />
      ) : (
        <UserTextField {...props} />
      );

    case FieldDataType.Email:
      props = {
        ...commonProps,
        value: value ? value : "",
        disabled: false
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserEmailField} props={props} />
      ) : (
        <UserEmailField {...props} />
      );
    case FieldDataType.Url:
      props = {
        ...commonProps,
        titleInputPlaceholder: "URL Title",
        URLInputPlaceholder: "URL Input",
        value: value ? value : { title: "", url: "" }
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserURLField} props={props} />
      ) : (
        <UserURLField {...props} />
      );
    case FieldDataType.Number:
      props = {
        ...commonProps,
        value: !isNaN(value) ? value : "", //Change this to work for the record page
        min: component?.configuration?.min,
        max: component?.configuration?.max,
        maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserNumberField} props={props} />
      ) : (
        <UserNumberField {...props} />
      );
    case FieldDataType.Percentage:
      props = {
        ...commonProps,
        value: !isNaN(value) ? value : "", //Change this to work for the record page
        min: component.min,
        max: component.max,
        maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserPercentageField} props={props} />
      ) : (
        <UserPercentageField {...props} />
      );

    case FieldDataType.Currency:
      props = {
        ...commonProps,
        icon: undefined,
        color: "",
        currency: component.currency,
        disabled: false,
        value: !isNaN(value) ? value : "",
        min: component.min,
        max: component.max,
        maxDecimalPlaces: (component?.configuration?.decimalPlaces as number) ?? 0
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserCurrencyField} props={props} />
      ) : (
        <UserCurrencyField {...props} />
      );

    case FieldDataType.People:
    case FieldDataType.MultiPeople: {
      const nonEditable = ["e365-CreatedBy", "e365-ModifiedBy"].includes(component.name);

      props = {
        ...commonProps,
        readOnly: nonEditable ? true : readOnly,
        multiple: component?.dataType === FieldDataType.MultiPeople,
        value
      };
      //Needed because the new people pickers require the default values to be available to it when it first loads
      // therefore we are waiting for the data to load before rendering the component
      if (status === "loading") return <Loading size={30} />;

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper
          keepReadOnly={nonEditable ? true : readOnly}
          Component={UserPeoplePickerField}
          props={props}
        />
      ) : (
        <UserPeoplePickerField {...props} />
      );
    }
    case FieldDataType.MultiChoice:
      props = {
        ...commonProps,
        helperText: "",
        value: typeof value === "object" ? value : [],
        disabled: false,
        isMultiChoice: component.dataType === FieldDataType.MultiChoice,
        choices:
          (component as any).dataStructure?.choices ?? component.configuration?.choices ?? [],
        isInRecord: isInRecord
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserMultiChoiceField} props={props} />
      ) : (
        <UserMultiChoiceField {...props} />
      );
    case FieldDataType.Choice:
      props = {
        ...commonProps,
        helperText: "",
        value: value ?? null,
        disabled: false,
        isMultiChoice: false,
        choices: (component as any).dataStructure?.choices ?? component.configuration?.choices ?? []
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserSingleChoiceField} props={props} />
      ) : (
        <UserSingleChoiceField {...props} />
      );

    case FieldDataType.MultiLevelChoice:
      props = {
        ...commonProps,
        disabled: false,
        label: component.displayName || "",
        helperText: "",
        useInternalState: true,
        value: typeof value === "object" ? value : [],
        choices: (component as any).dataStructure?.choices ?? component.configuration?.choices ?? []
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper
          keepReadOnly={readOnly}
          Component={UserMultiLevelChoiceField}
          props={props}
        />
      ) : (
        <UserMultiLevelChoiceField {...props} />
      );

    case FieldDataType.RichText:
      props = {
        ...commonProps,
        initialValue: value ? value : [],
        value: value ? value : []
      };

      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserRichTextField} props={props} />
      ) : (
        <UserRichTextField {...props} />
      );

    case FieldDataType.Location:
      props = {
        ...commonProps,
        initialValue: value ? value : null,
        value: value ? value : null
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper keepReadOnly={readOnly} Component={UserLocationField} props={props} />
      ) : (
        <UserLocationField {...props} />
      );

    case FieldDataType.Calculated:
      props = {
        ...commonProps,
        initialValue: value ? value : null,
        value: value ? value : null,
        fieldValues: fieldValues,
        expression: component.expression,
        calculatedExpression: component.calculatedExpression
      };
      return <UserCalculatedField {...props} />;

    case FieldDataType.Phone:
      props = {
        ...commonProps,
        initialValue: value ? value : null,
        value: value ? value : null
      };
      return useReadOnlyWrapper ? (
        <ReadOnlyWrapper Component={UserPhoneField} keepReadOnly={readOnly} props={props} />
      ) : (
        <UserPhoneField {...props} />
      );

    default:
      return;
  }
};

export default ComponentMapper;
