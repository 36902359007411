import {
  ApiError,
  License,
  LicenseType,
  Notification,
  NotificationLevel,
  PersonaEntity,
  RollupTableColumnModel
} from "enada-common";
import { MgtTemplateProps, People } from "@microsoft/mgt-react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LicenseInfo from "../../../components/licenses/licenseinfo/LicenseInfo";
import ManageLicenses, {
  getBackendLicense
} from "../../../components/licenses/managelicenses/ManageLicenses";
import { AvatarsTemplate } from "../../../config/rollupTableColumns";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { inputSelectTenant } from "../../../store/slices/userSlice";
import {
  EdisonEnumSelect,
  EdisonPeoplePickerField,
  EdisonTypography,
  SingleTable
} from "enada-components";
import "./licenses.scss";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import DeleteLicenseModal from "../../../components/licenses/deletelicensemodal/DeleteLicenseModal";
import LicenseStatusAlert from "../../../components/licenses/licensestatusalert/LicenseStatusAlert";
import { FrontendLicenseType, LicenseSettings } from "../../../types/licenses";
import {
  useGetLicenseSettingsQuery,
  useGetLicensesQuery,
  useUpdateLicensesMutation
} from "services/api";

export enum LicenseBoltOn {
  None = 0,
  AdditionalInstances = 1 << 0,
  Auditing = 1 << 1,
  BringYourOwnDB = 1 << 2,
  ReducedRateLimit = 1 << 3,
  ReducedQuota = 1 << 4,
  AiFeatures = 1 << 5
}

const Licenses = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const tenant = useAppSelector(inputSelectTenant);
  const { data: licenses = [] } = useGetLicensesQuery(tenant, { skip: !tenant });
  const { data: licenseSettings } = useGetLicenseSettingsQuery(tenant, { skip: !tenant });
  const [updateLicense] = useUpdateLicensesMutation();

  const [autoAssignLicenseType, setAutoAssignLicenseType] = useState<FrontendLicenseType>(
    FrontendLicenseType.ReadOnly
  );

  const [personId, setPersonId] = useState("");
  const [manageLicenseOpen, setManageLicenseOpen] = useState(false);
  const [licenseToDelete, setLicenseToDelete] = useState<License | undefined>(undefined);

  const remainingManagers =
    (licenseSettings?.creatorLicenses ?? 0) -
    licenses.filter(license => (license?.licenseType ?? 0) >= LicenseType.Professional).length;

  const remainingTeamMembers =
    (licenseSettings?.liteLicenses ?? 0) -
    licenses.filter(
      ({ licenseType }) =>
        licenseType !== undefined &&
        licenseType > LicenseType.ReadOnly &&
        licenseType < LicenseType.Professional
    ).length;

  const getFilteredLicenses = () => {
    if (personId === "") return licenses;
    return licenses.filter(license => personId === license.id);
  };

  const onUpdateLicense = async (licenseId: string, type: FrontendLicenseType) => {
    try {
      const payload: License = {
        licenseType: getBackendLicense(type, licenseSettings),
        id: licenseId,
        tenantId: tenant
      };

      const response = await updateLicense([payload]).unwrap();

      if (response)
        dispatch(
          setCurrentNotification({
            level: NotificationLevel.Success,
            title: "licensesUpdated",
            message: ""
          })
        );
    } catch (e) {
      const error = e as { data: ApiError };

      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Error,
          message: error.data.detail ?? "licensesUpdatedError",
          title: "Error updating Licenses."
        })
      );
    }
  };
  return (
    <Stack>
      <Paper className="licenses-root">
        <Stack spacing={2}>
          <LicenseStatusAlert licenseSettings={licenseSettings} />
          <Stack direction="row" justifyContent={"space-between"} className="top-container">
            <EdisonTypography title={t("manageLicensesMessage")} variant="data" />
            <FormControl size="small">
              <InputLabel id={`licenses-select-label`}>
                {t("autoAssignLicenseToNewUsers")}
              </InputLabel>
              <Select
                sx={{ m: 1, width: 200 }}
                labelId={`licenses-select-label`}
                value={autoAssignLicenseType}
                onChange={e => {
                  setAutoAssignLicenseType(e.target.value as FrontendLicenseType);
                }}
              >
                {[FrontendLicenseType.None, FrontendLicenseType.ReadOnly].map(
                  (licenseType, index) => (
                    <MenuItem key={index} value={licenseType}>
                      {t(licenseType)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Stack>
          <Stack>
            <Paper elevation={0} variant="outlined">
              <Stack className="info-panel" spacing={2}>
                <Stack direction="row" className="top-container" spacing={1}>
                  <Stack className={`license-type-container ${licenseSettings?.licenseType}`}>
                    {licenseSettings?.licenseType}
                  </Stack>
                  {licenseSettings?.subscriptionEndDate && (
                    <div>
                      <EdisonTypography
                        variant="helpertext"
                        title={`${t("subscriptionExpiresOn")}  ${new Date(
                          licenseSettings?.subscriptionEndDate
                        ).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        })}`}
                      />
                    </div>
                  )}
                </Stack>
                <Stack
                  direction={"row"}
                  // spacing={10}
                  justifyContent={"space-evenly"}
                  className="licenses-container"
                >
                  <LicenseInfo
                    title={t("fullLicenses")}
                    tooltipText={t("fullLicensesTooltip")}
                    buyLicenseClick={() =>
                      window.open(`mailto:sales@edison365.com?subject=New licenses`)
                    }
                    totalLicenses={licenseSettings?.creatorLicenses ?? 0}
                    usedLicences={
                      licenses.filter(
                        license => (license?.licenseType ?? 0) >= LicenseType.Professional
                      ).length
                    }
                  />
                  <LicenseInfo
                    title={t("liteLicenses")}
                    tooltipText={t("liteLicenseTooltip")}
                    buyLicenseClick={() =>
                      window.open(`mailto:sales@edison365.com?subject=New licenses`)
                    }
                    totalLicenses={licenseSettings?.liteLicenses ?? 0}
                    usedLicences={
                      licenses.filter(
                        ({ licenseType }) =>
                          licenseType !== undefined &&
                          licenseType > LicenseType.ReadOnly &&
                          licenseType < LicenseType.Professional
                      ).length
                    }
                  />
                  <LicenseInfo
                    title={t("readOnlyLicenses")}
                    tooltipText={t("readOnlyLicenseTooltip")}
                    buyLicenseClick={() => console.log("click")}
                    totalLicenses={"infinite"}
                    usedLicences={
                      licenses.filter(license => license?.licenseType === LicenseType.ReadOnly)
                        .length
                    }
                    noBuyOption={true}
                  />
                </Stack>
              </Stack>
            </Paper>
          </Stack>
          <Stack sx={{}} spacing={1}>
            <Stack direction={"row"} spacing={1}>
              <div className="search-container">
                <EdisonPeoplePickerField
                  useInternalState={false}
                  label=""
                  onChange={(person: PersonaEntity[]) => {
                    if (person?.length > 0) {
                      setPersonId(person[0].entityId);
                    } else {
                      // clear search when no users selected
                      setPersonId("");
                    }
                  }}
                />
              </div>
              <Button
                className="manage-license-button"
                variant="contained"
                onClick={() => setManageLicenseOpen(true)}
              >
                {t("addUsers")}
              </Button>
            </Stack>
            <SingleTable
              renderEmptyTable={true}
              disableColumnSort
              selectedRowIds={[]}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              setSelectedRowIds={() => {}}
              searchFilter=""
              columns={getColumns(
                t,
                (notification: Notification) => dispatch(setCurrentNotification(notification)),
                onUpdateLicense,
                remainingManagers,
                remainingTeamMembers,
                (id: string) => {
                  setLicenseToDelete(licenses.find(license => license.id === id));
                },
                licenseSettings
              )}
              rows={getFilteredLicenses().map((license, index) => ({
                userId: license.id,
                licenseType: license.licenseType,
                id: index
              }))}
              t={t}
              fullScreen={false}
            />
          </Stack>
        </Stack>
        <ManageLicenses
          open={manageLicenseOpen}
          setOpen={setManageLicenseOpen}
          autoAssignLicenseType={autoAssignLicenseType}
        />
      </Paper>
      <DeleteLicenseModal
        licenseToDelete={licenseToDelete}
        onClose={() => setLicenseToDelete(undefined)}
        tenantId={tenant}
      />
    </Stack>
  );
};
const getColumns = (
  t: (value: string) => string,
  setNotification: (notification: Notification) => void,
  updateAction: (id: string, type: FrontendLicenseType) => void,
  remainingManagers: number,
  remainingTeamMembers: number,
  setLicenseToDelete: (id: string) => void,
  licenseSettings?: LicenseSettings
): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("displayName"),
    width: "25%",
    componentRenderer: (cellValue, row) => {
      return row.userId ? (
        <People key={row.userId} showMax={1} userIds={[row.userId]}>
          <AvatarsTemplate template="default" />
        </People>
      ) : null;
    }
  },
  {
    name: "email",
    width: "40%",
    displayName: t("email"),
    componentRenderer: (cellValue, row) => {
      return row.userId ? (
        <People key={row.userId} userIds={[row.userId]}>
          <EmailTemplate template="default" />
        </People>
      ) : (
        row.userId
      );
    }
  },
  {
    name: "licenseType",
    displayName: t("license"),
    componentRenderer: (cellValue, row) => {
      return (
        <EdisonEnumSelect
          t={t}
          enumDef={FrontendLicenseType}
          onChange={changeValue => {
            if (
              (remainingManagers === 0 && changeValue === FrontendLicenseType.Full) ||
              (remainingTeamMembers === 0 && changeValue === FrontendLicenseType.Lite)
            ) {
              setNotification({
                title: t("maxLicensesHit"),
                message: t("maxLicensesHitMessage"),
                level: NotificationLevel.Error
              });
            } else {
              updateAction(row.userId, changeValue as FrontendLicenseType);
            }
          }}
          className="license-enum-select"
          value={getFrontendLicenseType(cellValue, licenseSettings)}
        />
      );
    },
    width: "25%"
  },
  {
    name: "remove",
    displayName: t("remove"),
    componentRenderer: (cellValue, row) => {
      return (
        <IconButton color="error" onClick={() => setLicenseToDelete(row.userId)}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      );
    }
  }
];

const getFrontendLicenseType = (
  licenseType?: LicenseType,
  licenseSettings?: LicenseSettings
): FrontendLicenseType => {
  if (licenseType === undefined || licenseType === LicenseType.None) {
    return FrontendLicenseType.None;
  }

  if (licenseType === LicenseType.ReadOnly) {
    return FrontendLicenseType.ReadOnly;
  }

  if (
    licenseSettings?.licenseType !== undefined &&
    (licenseType as any) >= LicenseType[licenseSettings.licenseType]
  ) {
    return FrontendLicenseType.Full;
  }

  return FrontendLicenseType.Lite;
};

const EmailTemplate = (props: MgtTemplateProps) => {
  const { people } = props.dataContext;
  return people.map((person: any, index: any) => {
    if (person === null) return null;
    return <div key={index}> {person?.mail ?? ""} </div>;
  });
};

export default Licenses;
