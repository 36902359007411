import React from "react";
import NotificationTab from "./NotificationTab";
import { useAppSelector } from "../../../store/hooks";
import { selectBackendNotifications } from "../../../store/slices/notificationSlice";

const NewNotificationTab = () => {
  return (
    <NotificationTab
      notifications={useAppSelector(selectBackendNotifications).filter(
        notification => !notification.isRead
      )}
    />
  );
};
export default NewNotificationTab;
