import { Box, Popover } from "@mui/material";
import React, { FC, useState } from "react";
import { PersonaEntity, PermissionType } from "enada-common";
import "./peoplegroup.scss";
import {
  AvatarSize,
  Get,
  MgtTemplateProps,
  People,
  Person,
  PersonCard
} from "@microsoft/mgt-react";
import EdisonTypography from "../../edison/typography/EdisonTypography";

export interface UserPeopleGroupProps {
  value: PersonaEntity[];
  expandGroups?: boolean;
  avatarSize?: AvatarSize;
  maxAvatars?: number;
  isInTable?: boolean;
  internalState?: PersonaEntity[];
}

const UserPeopleGroup: FC<UserPeopleGroupProps> = ({
  avatarSize,
  maxAvatars,
  expandGroups,
  isInTable,
  internalState,
  value
}) => {
  const maximumAvatars = maxAvatars ? (maxAvatars < 2 ? 2 : maxAvatars) : 2;

  const [avatarCount, setAvatarCount] = useState<number>(0);
  const [allResultsCount, setAllResultsCount] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const open = Boolean(anchorEl);

  const PeopleTemplate = (props: MgtTemplateProps) => {
    const users: [] = props.dataContext.userPrincipalName
      ? [props.dataContext.id]
      : props.dataContext.value.map(v => v.id);

    const remainingAvatars = maximumAvatars - avatarCount;

    setAllResultsCount(allResultsCount + users.length);

    if (remainingAvatars <= 0) return <></>;

    const userIds = remainingAvatars < users.length ? users.slice(0, remainingAvatars) : users;

    setAvatarCount(avatarCount + userIds.length);

    return (
      <People showMax={userIds.length} userIds={userIds}>
        <AvatarsTemplate template="default" />
      </People>
    );
  };

  const AvatarsTemplate = (props: MgtTemplateProps) => {
    const { people } = props.dataContext;
    return people.map((person, index) => (
      <Person
        className={`avatar-${avatarSize}`}
        key={index}
        avatarType="photo"
        userId={person.id}
        showPresence
        fetchImage
        personCardInteraction={"click"}
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
          const temporaryFix = () => {
            // TODO / WARNING: The below should be changed once MUI fix the issue where popovers don't update the position if the height is based on async data
            window.dispatchEvent(new CustomEvent("resize"));
          };
          setTimeout(temporaryFix, 0);
        }}
        avatarSize={avatarSize ? avatarSize : "large"}
      ></Person>
    ));
  };

  return value ? (
    <Box className="edison-peoplegroup-root">
      {expandGroups ? (
        value.map((persona, index) => (
          <Get
            key={index}
            resource={
              persona.type === PermissionType.Group
                ? `/groups/${persona.entityId}/members`
                : `/users/${persona.entityId}`
            }
          >
            <PeopleTemplate template="default" />
          </Get>
        ))
      ) : (
        <People showMax={maxAvatars} userIds={value.map(p => p.entityId)}>
          <AvatarsTemplate template="default" />
        </People>
      )}
      {allResultsCount > maximumAvatars && (
        <EdisonTypography
          variant="data"
          title={"+ " + (allResultsCount - maximumAvatars)}
          sx={{ paddingTop: "3px" }}
        ></EdisonTypography>
      )}
      {anchorEl && value.length === 1 && (
        <Box>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClick={e => {
              e.stopPropagation();
              setAnchorEl(null);
              setExpanded(false);
            }}
            slotProps={{
              paper: {
                className: expanded ? "popover-paper" : ""
              }
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <PersonCard
              userId={value[0].entityId}
              showPresence
              fetchImage
              onClick={e => {
                e.stopPropagation();
              }}
              expanded={() => {
                setExpanded(true);
              }}
            />
          </Popover>
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default UserPeopleGroup;
