import { Field } from "enada-common";
import { isColumnDynamic } from "./isColumnDynamic";
import { ColumnStore } from "@bryntum/grid-thin";
import { getClassToSearchFor } from "./generateBaseBryntumColumn";
import { useLocalStorage } from "usehooks-ts";
import { useState, useEffect, useMemo } from "react";
import { HORIZONTAL_SCROLL_HEIGHT, ROW_HEIGHT, TOOLBAR_HEIGHT } from "../config/Constants";
import { mean } from "mathjs";

export const useToggleAutoHeight = (
  columns: Field[],
  gantt: any,
  label: string,
  totalRows?: number,
  fullScreen?: boolean
) => {
  const [autoHeight, setAutoHeight] = useLocalStorage(
    `tableAutoHeight-${label.replace(" ", "-")}`,
    false
  );
  const [averageRowHeight, setAverageRowHeight] = useState<number>(ROW_HEIGHT);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const bryntumColumns = gantt?.columns as ColumnStore;

  const toggleAutoHeight = (changeValue: boolean) => {
    const columnNames: string[] = [];

    bryntumColumns?.forEach((column: any) => {
      if (!isColumnDynamic(column, columns)) return;
      column.data["dynamicHeight"] = changeValue;
      columnNames.push(column.data.field);

      setAutoHeight(changeValue);
    });
    const ganttElement = gantt.currentElement as HTMLElement;
    const dynamicClassNames = columnNames.map(columnName =>
      getClassToSearchFor(columns.find(column => column.name === columnName)?.dataType)
    );

    const callback = (mutationsList: any, observer: any) => {
      const els = ganttElement.querySelector(dynamicClassNames[0]);

      if (els !== null) {
        gantt.refreshRows();
        observer.disconnect();
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    const config = { childList: true, subtree: true };
    const toFind = ganttElement.querySelector(dynamicClassNames[0]);

    if (toFind !== null) {
      gantt.refreshRows();
    } else {
      observer.observe(ganttElement, config);
    }
  };

  useEffect(() => {
    const calculateAverageRowHeight = () => {
      const rows = gantt?.rowManager?.rows;
      if (rows && rows.length > 0) {
        const rowHeights = Array.from(rows).map((row: any) => row.height) as number[];
        // If there are row heights, calculate the average
        if (rowHeights.length > 0) {
          const meanHeight = mean(rowHeights);

          setAverageRowHeight(meanHeight);
        }
      }
    };

    if (totalRows && totalRows > 0) {
      if (autoHeight) {
        // If autoHeight is enabled, wait 400ms to allow rows to finish rendering
        const timeout = initialLoad ? 300 : 750;
        const timeoutId = setTimeout(() => {
          calculateAverageRowHeight();
          setInitialLoad(true);
        }, timeout);

        return () => clearTimeout(timeoutId);
      } else {
        calculateAverageRowHeight();
      }
    }
  }, [gantt, autoHeight, totalRows, initialLoad]);

  const maxHeight = useMemo(() => {
    // Check if we are in fullscreen mode or have 20 or fewer rows
    if (fullScreen || (totalRows && totalRows <= 20)) {
      // Calculate full height without scrolling
      return `${
        (totalRows ?? 1) * averageRowHeight + TOOLBAR_HEIGHT + HORIZONTAL_SCROLL_HEIGHT + 10
      }px`;
    } else if (totalRows && totalRows > 20) {
      // Calculate height for the first 20 rows with scrolling
      return `${20 * averageRowHeight + TOOLBAR_HEIGHT + HORIZONTAL_SCROLL_HEIGHT + 10}px`;
    } else {
      // Default fallback for when there's no data or other edge cases
      return `0px`;
    }
  }, [fullScreen, totalRows, averageRowHeight]);

  return { toggleAutoHeight, autoHeight: Boolean(autoHeight), averageRowHeight, maxHeight };
};
