import { Box, Card, CardContent, CardHeader, CardMedia, Tooltip } from "@mui/material";
import React, { FC } from "react";
import TypeCardMedia from "../../assets/typecardmedia.png";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisoncontentcard.scss";

export interface TypeCardProps {
  title: string;
  description: string;
  t: (key: string) => string;
  cardImageSource?: string;
  onClick?: any;
  sx?: any;
  className?: string;
}
const TypeCard: FC<TypeCardProps> = ({
  title,
  description,
  t,
  cardImageSource,
  onClick,
  sx,
  className
}) => {
  return (
    <Card
      data-testid={"edison-content-type-card"}
      className={`content-card ${className ?? ""}`}
      elevation={0}
      sx={sx}
      variant="outlined"
      onClick={onClick}
    >
      <CardHeader
        data-testid={"edison-content-type-card-header"}
        title={
          <Tooltip title={title}>
            <Box>
              <EdisonTypography title={title} variant={"data2"} />
            </Box>
          </Tooltip>
        }
        className="card-title-container"
      />
      <CardMedia
        data-testid={"edison-content-type-card-media"}
        component="img"
        height="112"
        image={!!cardImageSource ? cardImageSource : TypeCardMedia}
        alt="Card Image"
      />
      <CardContent className="description-box" data-testid={"edison-content-type-card-content"}>
        <Box>
          <EdisonTypography
            variant="data2"
            sx={{
              display: "-webkit-box",
              height: "4.6em",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical",
              whiteSpace: "normal",
              paddingRight: "25px"
            }}
            title={t(description)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TypeCard;
export { TypeCard };
