import { Alert, Avatar, Button, Divider, Stack } from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { EdisonTypography } from "enada-components";
import "./createrecorderror.scss";
export interface CreateRecordErrorProps {
  error: any;
  type: string;
  onRetry: () => void;
}

const CreateRecordError: FC<CreateRecordErrorProps> = ({ error, onRetry, type }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Stack className="create-record-error-root" spacing={2}>
      <Stack className="error-container" spacing={1}>
        <Avatar sx={theme => ({ background: "#fdf0e6" })}>
          <CloseIcon color="error" />
        </Avatar>
        <EdisonTypography title={`${type} ${t("notCreated")}`} variant="h4" />
      </Stack>

      <Divider flexItem />
      <Alert severity="error">{error ?? t("somethingWentWrongPleaseContactAdmin")}</Alert>
      <Divider />
      <Stack className="button-container">
        <div>
          <Button onClick={onRetry} variant="contained">
            {t("retry")}
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default CreateRecordError;
