import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import "./listtabletoolbar.scss";
import { Field } from "enada-common";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import EdisonIconButton from "../../../../edison/iconbutton/EdisonIconButton";
import { DomClassList } from "@bryntum/core-thin";
import TableRowDeletionDialog from "../../tableutils/deletiondialog/TableRowDeletionDialog";
import ListTableToolbarSettings from "./settings/ListTableToolbarSettings";
import UserTableSettingsModal from "../../tableutils/settingsmodal/UserTableSettingsModal";

export interface IToolbarProps {
  grid: any;
  columns: Field[];
  hideExportBtn: boolean;
  hideAddBtn: boolean;
  hideDeleteBtn: boolean;
  taskAddCallback: () => void;
  toolbarModule?: ReactNode;
  readOnly?: boolean;
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
  titleModule?: ReactNode;
  t: (value: string) => string;
  label: string;
  onEdiClick?: () => void;
  hideEdi?: boolean;
  hasSelectedData: boolean;
  setDeletedRows?: Dispatch<SetStateAction<any[]>>;
}

const ListTableToolbar: FC<IToolbarProps> = ({
  grid,
  columns,
  hideExportBtn,
  hideAddBtn,
  hideDeleteBtn,
  taskAddCallback,
  hasSelectedData,
  toolbarModule,
  readOnly,
  fullScreen,
  setFullScreen,
  titleModule,
  t,
  label,
  setDeletedRows
}) => {
  const [rowDeleteOpen, setRowDeleteOpen] = useState(false);

  useEffect(() => {
    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (!grid.current.instance) {
      return;
    }
    const clsList = new DomClassList(grid.current.instance.cls);
    fullScreen ? clsList.add("grid-fullscreen") : clsList.remove("grid-fullscreen");
    grid.current.instance.cls = clsList.value;
  }, [fullScreen]);

  const handleExportExcel = useCallback(() => {
    grid.current.instance.features.excelExporter.export();
  }, []);

  return (
    <div className="standard-table-toolbar-root">
      <div className="toolbarContainer">
        <div className="leftContainer">{titleModule}</div>
        <div className="rightContainer">
          {toolbarModule}
          {!hideExportBtn && (
            <EdisonIconButton
              testId=""
              variant="outlined"
              icon={<ImportExportIcon />}
              onClick={handleExportExcel}
            />
          )}
          <EdisonIconButton
            testId=""
            variant="outlined"
            icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            onClick={e => {
              setFullScreen(prev => !prev);
              window.scrollTo(0, 0);
              fullScreen ? document.exitFullscreen() : document.documentElement.requestFullscreen();
            }}
          />

          <UserTableSettingsModal>
            <ListTableToolbarSettings grid={grid} label={label} columns={columns} t={t} />
          </UserTableSettingsModal>

          {!hideDeleteBtn && !readOnly && (
            <EdisonIconButton
              testId=""
              variant="outlined"
              icon={<DeleteOutlineIcon color={!hasSelectedData ? "disabled" : "primary"} />}
              disabled={!hasSelectedData}
              onClick={() => setRowDeleteOpen(true)}
            />
          )}
          {/* {!Boolean(hideEdi) && (
            <EdisonIconButton
              testId=""
              variant="text"
              icon={<EdiIcon />}
              onClick={() => (onEdiClick ? onEdiClick() : undefined)}
            />
          )} */}
          {!hideAddBtn && !readOnly && (
            <EdisonIconButton
              testId=""
              id={"standard-table-add"}
              variant="contained"
              icon={<AddIcon />}
              onClick={taskAddCallback}
            />
          )}
        </div>
      </div>
      <TableRowDeletionDialog
        open={rowDeleteOpen}
        onDelete={() => {
          setDeletedRows?.(rows => [
            ...rows,
            ...grid.current.instance.selectedRecords.map(record => record.data)
          ]);
          grid.current.instance.store.remove(grid.current.instance.selectedRecords);

          setRowDeleteOpen(false);
        }}
        onClose={() => setRowDeleteOpen(false)}
        t={t}
      />
    </div>
  );
};

export default ListTableToolbar;
export { ListTableToolbar as StandardTableToolbar };
