import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { WorkflowNodeType } from "enada-common";

export const getNodeIcon = (nodeType: WorkflowNodeType) => {
  switch (nodeType) {
    case "Stage":
      return <PostAddOutlinedIcon />;
    case "StageReview":
      return <RateReviewOutlinedIcon />;
    case "Condition":
      return <CallSplitOutlinedIcon />;
    default:
      return <MicNoneOutlinedIcon />;
  }
};
