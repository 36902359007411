import { FC } from "react";
import EdisonTypography from "../../typography/EdisonTypography";
import { Alert, CardActions, CardContent, CardHeader, Dialog, Button } from "@mui/material";
import { DeleteDialogProps } from "enada-common";

const DeleteDialog: FC<DeleteDialogProps> = ({ onClose, title, content }) => {
  return (
    <Dialog open={true} onClose={() => onClose(false)} maxWidth="xs" data-testid="dialog">
      <CardHeader
        title={title}
        titleTypographyProps={{ component: () => <EdisonTypography variant="h1" title={title} /> }}
      ></CardHeader>
      <CardContent sx={{ minWidth: "400px" }}>
        <Alert severity="error">{content}</Alert>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button onClick={() => onClose(true)} autoFocus variant="contained" color="error">
          Delete
        </Button>
        <Button onClick={() => onClose(false)} variant="outlined" color="info">
          Cancel
        </Button>
      </CardActions>
    </Dialog>
  );
};

export default DeleteDialog;
export { DeleteDialog };
