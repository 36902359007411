import {
  LicenseType,
  RecordType,
  recordTypeConfiguration,
  WorkflowReviewState,
  WorkflowTaskState,
  WorkItemApproval,
  WorkItemType
} from "enada-common";
import { Avatar, Button, Chip, TableRow, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./approvalsrow.scss";
import ApprovalsTaskSubTable from "../approvalstasksubtable/ApprovalsTaskSubTable";
import SubmitApprovalDialog from "../submitapprovaldialog/SubmitApprovalDialog";
import { BorderedTableCell } from "../ApprovalsTable";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { nanoid } from "nanoid";
import { selectRecordType, setIsFlyoutOpen } from "../../../../store/slices/recordSlice";
import { getAccessToken } from "../../../../services/APIService";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import HasMinimumLicense from "../../../hasMinimumLicense/HasMinimumLicense";
import { getNameForModule } from "../../../../utils/getNameForModule";
import { imageMapping } from "../../../../utils/mappers/imageMapping";
import { useContinueRecordWorkflowMutation } from "services/api";

export interface ApprovalsRowProps {
  approval: WorkItemApproval;
  isOverDue: boolean;
}

const ApprovalsRow: FC<ApprovalsRowProps> = ({ approval, isOverDue }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [continueRecordWorkflow] = useContinueRecordWorkflowMutation();

  const recordType = useAppSelector(selectRecordType);
  const locationPath = useLocation().pathname;
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const currentModule = recordTypeConfiguration(approval.recordType);
  const isStageApproval = approval.itemType === WorkItemType.StageApproval;

  const checkListString = isStageApproval
    ? t("noItems")
    : `${
        approval.reviewTasks?.filter(task => task.taskState === WorkflowTaskState.Completed).length
      } ${t("of")} ${approval.reviewTasks?.length}`;

  const isCompleted = ![WorkflowReviewState.None, undefined].includes(approval.reviewState);

  const submitNormalStageApproval = async () => {
    if (!approval.recordId) return;
    const additionalHeaders = new Headers();
    const sessionId = nanoid();

    additionalHeaders.append("edison365-sessionid", sessionId);

    const response = await getAccessToken(approval.recordId, recordType, additionalHeaders);
    if (response.status !== 200) {
      return;
    }

    await continueRecordWorkflow({
      recordId: approval.recordId,
      recordType
    });

    dispatch(setIsFlyoutOpen(false));
  };

  const isRowApprovalDisabled =
    isCompleted ||
    approval.reviewTasks?.some(task => task.taskState !== WorkflowTaskState.Completed);

  return (
    <>
      <TableRow
        className="approval-table-row-root clickable"
        selected={expanded}
        hover
        onClick={e => {
          e.stopPropagation();
          setExpanded(prev => !prev);
        }}
      >
        <BorderedTableCell className="name cell">
          <Link
            className="approval-link"
            to={`${locationPath.substring(
              0,
              locationPath.lastIndexOf("/")
            )}/${approval.recordType?.toLocaleLowerCase()}/${approval.recordId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {approval.recordName ?? approval.recordId}
          </Link>
        </BorderedTableCell>
        <BorderedTableCell className="type cell">
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: currentModule.color }}>
                {imageMapping(currentModule.fieldName, "inherit", "small")}
              </Avatar>
            }
            label={getNameForModule(currentModule.displayName, t)}
            variant="outlined"
            sx={{ borderColor: currentModule.color }}
          />
        </BorderedTableCell>
        <BorderedTableCell className="checklist cell">
          <Stack direction="row" className="checklist-container">
            {checkListString}
            {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Stack>
        </BorderedTableCell>
        <BorderedTableCell className="stage cell">
          <Chip label={approval.stageName} />
        </BorderedTableCell>
        <BorderedTableCell className="status cell" align="center">
          {approval.reviewState === WorkflowReviewState.Approved ? (
            <Chip color="success" label={t("approved")} variant="outlined" />
          ) : approval.reviewState === WorkflowReviewState.Rejected ? (
            <Chip color="error" label={t("rejected")} variant="outlined" />
          ) : isOverDue ? (
            <Chip
              sx={{ color: "#ED6C02", borderColor: "#ED6C02" }}
              label={t("overdue")}
              variant="outlined"
            />
          ) : (
            <Chip color="info" label={t("incomplete")} variant="outlined" />
          )}
        </BorderedTableCell>
        <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
          <BorderedTableCell className="action cell">
            <Tooltip title={t("checklistItemsCompletedMessage")}>
              <span>
                {isStageApproval ? (
                  <Button
                    color={isStageApproval ? "success" : "info"}
                    variant={isStageApproval ? "contained" : "text"}
                    disabled={
                      isCompleted ||
                      approval.reviewTasks?.some(
                        task => task.taskState !== WorkflowTaskState.Completed
                      )
                    }
                    onClick={e => {
                      e.stopPropagation();
                      if (isStageApproval) {
                        submitNormalStageApproval();
                        return;
                      }
                      // setDialogOpen(true);
                    }}
                  >
                    {t("approve")}
                  </Button>
                ) : (
                  <Stack direction="row">
                    <CheckOutlinedIcon
                      color={isRowApprovalDisabled ? "disabled" : "success"}
                      onClick={e => {
                        if (!isRowApprovalDisabled) {
                          e.stopPropagation();
                          setIsApprove(true);
                          setDialogOpen(true);
                        }
                      }}
                    />
                    <CloseOutlinedIcon
                      color={isRowApprovalDisabled ? "disabled" : "error"}
                      onClick={e => {
                        if (!isRowApprovalDisabled) {
                          e.stopPropagation();
                          setIsApprove(false);
                          setDialogOpen(true);
                        }
                      }}
                    />
                  </Stack>
                )}
              </span>
            </Tooltip>
          </BorderedTableCell>
        </HasMinimumLicense>
      </TableRow>
      {expanded && (
        <TableRow>
          <BorderedTableCell colSpan={7} style={{ padding: 0 }}>
            <ApprovalsTaskSubTable
              tasks={approval.reviewTasks ?? []}
              projectId={approval.recordId}
              completed={isCompleted}
              recordType={approval.recordType ?? RecordType.Projects}
            />
          </BorderedTableCell>
        </TableRow>
      )}
      <SubmitApprovalDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        isApproved={isApprove}
        projectId={approval.recordId}
        recordType={approval.recordType ?? RecordType.Projects}
      />
    </>
  );
};

export default ApprovalsRow;
