import { apiConfig } from "config/authConfig";
import { FieldValue, FieldValueOperationType, Resource, ResourceField, Task } from "enada-common";
import { baseAPI } from "services/baseAPI";
import { ODataApiResponse } from "types/ApiResponse";

export const resourcesAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getResources: builder.query<Resource[], void>({
      query: () => apiConfig.resourcesEndpoint,
      transformResponse: (response: { data: Resource[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(res => ({ type: "Resources" as const, id: res.id })), "Resources"]
          : ["Resources"]
    }),

    getMyResources: builder.query<ODataApiResponse<Resource[]>, string>({
      query: queryString => `${apiConfig.myResourcesEndpoint}${decodeURIComponent(queryString)}`,
      providesTags: result =>
        result && result.value
          ? [...result.value.map(res => ({ type: "Resources" as const, id: res.id })), "Resources"]
          : ["Resources"]
    }),

    getTasks: builder.query<ODataApiResponse<Task[]>, string>({
      query: queryString =>
        `${apiConfig.recordsODataTasksEndpoint}${decodeURIComponent(queryString)}`,
      providesTags: result =>
        result && result.value
          ? [...result.value.map(res => ({ type: "Resources" as const, id: res.id })), "Resources"]
          : ["Resources"]
    }),

    getResource: builder.query<Resource, string>({
      query: id => `${apiConfig.resourcesEndpoint}/${id}`,
      transformResponse: (response: { data: Resource }) => response.data,
      providesTags: (result, error, id) => [{ type: "Resources", id }]
    }),

    getResourceValues: builder.query<FieldValue[], string>({
      query: id => `${apiConfig.resourcesEndpoint}/${id}/values`,
      transformResponse: (response: { data: FieldValue[] }) => response.data,
      providesTags: (result, error, id) => [{ type: "Resources", id }]
    }),

    getResourceFields: builder.query<ResourceField[], void>({
      query: () => apiConfig.resourcesFieldsEndpoint,
      transformResponse: (response: { data: ResourceField[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(res => ({ type: "Resources" as const, id: res.id })), "Resources"]
          : ["Resources"]
    }),

    updateResourceFields: builder.mutation<ResourceField[], ResourceField[]>({
      query: data => ({
        url: apiConfig.resourcesFieldsEndpoint,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Resources"]
    }),

    getResourceManagers: builder.query<any[], void>({
      // TODO - replace any with actual type
      query: () => `${apiConfig.permissionsEndpoint}/resource-managers`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(res => ({ type: "Resources" as const, id: res.id })), "Resources"]
          : ["Resources"]
    }),

    createResource: builder.mutation<Resource, Partial<Resource>>({
      query: data => ({
        url: apiConfig.resourcesEndpoint,
        method: "POST",
        body: data
      }),
      transformResponse: (response: { data: Resource }) => response.data,
      invalidatesTags: ["Resources"]
    }),

    updateResource: builder.mutation<Resource, Partial<Resource>>({
      query: data => ({
        url: `${apiConfig.resourcesEndpoint}`,
        method: "PUT",
        body: data
      }),
      transformResponse: (response: { data: Resource }) => response.data,
      invalidatesTags: (result, error, { id }) => [{ type: "Resources", id }]
    }),

    updateResourceFieldValues: builder.mutation<FieldValue[], { body: FieldValue[]; id: number }>({
      query: ({ body, id }) => ({
        url: `${apiConfig.resourcesEndpoint}/${id}/values`,
        method: "POST",
        body: [
          {
            operationType: FieldValueOperationType.addOrUpdate,
            fieldValues: body
          }
        ]
      }),
      invalidatesTags: ["Resources"]
    }),

    deleteResources: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.resourcesEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Resources", id }))
    }),

    restoreResources: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.resourcesEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Resources", id }))
    }),

    recycleResources: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.resourcesEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Resources", id }))
    })
  })
});
