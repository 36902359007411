import { BryntumGrid, BryntumGridProps } from "@bryntum/grid-react-thin";
import { ColumnStore } from "@bryntum/grid-thin";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TableDataType, TablePeriodicUnits, UserPeriodicTableProps } from "enada-common";
import dayjs from "dayjs";
import * as dayOfYear from "dayjs/plugin/dayOfYear";
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as quarterOfYear from "dayjs/plugin/quarterOfYear";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Grid, Divider, Stack, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import EdisonTypography from "../../../edison/typography/EdisonTypography";
import "./userperiodictable.scss";

import PeriodicTableToolbar from "./toolbar/PeriodicTableToolbar";
import { CalendarPickerView } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import zipcelx from "zipcelx";
import { generateBaseBryntumColumn } from "../tableutils/generateBaseBryntumColumn";
import hideColumns from "../tableutils/hideColumns";
import { PeriodicModel } from "./PeriodicModel";
import PeriodicReadOnlyContext from "./PeriodicContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "@bryntum/grid-thin/lib/feature/CellCopyPaste.js";
import { Store } from "@bryntum/core-thin";
import { useToggleAutoHeight } from "../tableutils/useToggleAutoHeight";
import {
  FOOTER_HEIGHT,
  HORIZONTAL_SCROLL_HEIGHT,
  ROW_HEIGHT,
  TOOLBAR_HEIGHT
} from "../tableutils/taskTableUtils";

const UserPeriodicTable: FC<UserPeriodicTableProps> = ({
  periodicUnit,
  rows,
  grandTotals,
  detailFields,
  label,
  periodicStartDate,
  onEdiClick,
  onDataChanged,
  readOnly,
  hideEdi,
  toolbarModule,
  hiddenColumns,
  required,
  description,
  t,
  rowsToUpdate,
  refreshRequired
}) => {
  const gridRef = useRef<BryntumGrid | null>(null);

  dayjs.extend(dayOfYear as any);
  dayjs.extend(weekOfYear as any);
  dayjs.extend(advancedFormat as any);
  dayjs.extend(quarterOfYear as any);
  dayjs.extend(localizedFormat as any);

  const [startDate, setStartDate] = useState<Date>(periodicStartDate ?? new Date());
  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);
  const { toggleAutoHeight, autoHeight } = useToggleAutoHeight(
    detailFields,
    gridRef.current?.instance,
    label
  );

  const periodProperties: {
    period: string;
    numberOfPeriodColumns: number;
    displayNameFormat: string;
    datePickerFormat: string;
    datePickerViews?: CalendarPickerView[];
    fieldNameFormat?: string;
  } = useMemo(() => {
    switch (periodicUnit) {
      case TablePeriodicUnits.Weeks:
        return {
          period: "week",
          fieldNameFormat: "w-YYYY",
          displayNameFormat: "DD MMM",
          datePickerFormat: "LL",
          numberOfPeriodColumns: 16
        };
      case TablePeriodicUnits.Months:
        return {
          period: "month",
          fieldNameFormat: "M-YYYY",
          displayNameFormat: "MMM",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["month", "year"]
        };
      case TablePeriodicUnits.Quarters:
        return {
          period: "quarter",
          fieldNameFormat: "Q-YYYY",
          displayNameFormat: "[Q]Q YYYY",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["month", "year"]
        };
      case TablePeriodicUnits.Years:
        return {
          period: "year",
          fieldNameFormat: "YYYY",
          displayNameFormat: "YYYY",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["year"]
        };
      default:
        return {
          period: "day",
          displayNameFormat: "DD MMM",
          datePickerFormat: "LL",
          numberOfPeriodColumns: 16
        };
    }
  }, []);

  useEffect(() => {
    if (rows?.length) {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (refreshRequired) {
      const store = gridRef.current?.instance.store as Store;
      if (store.records.length) {
        store.removeAll();
      }
      loadData();
    }
  }, [refreshRequired]);

  useEffect(() => {
    const store = gridRef.current?.instance.store as Store;
    rowsToUpdate?.forEach(row => {
      const { rowId, ...rest } = row;
      const toUpdate = store.getById(rowId);
      toUpdate.set({ ...rest });
    });
  }, [rowsToUpdate]);

  useEffect(() => {
    if (!grandTotals) return;
    Object.keys(grandTotals).forEach((totalId: any) => {
      const row = (gridRef.current?.instance.store as Store).find(
        (d: any) => d.dbId.toString() === totalId
      );
      if (row) {
        row.set("_grandTotal", grandTotals[totalId]);
      }
    });
  }, [grandTotals]);

  useEffect(() => {
    const columnStore = gridRef.current?.instance.columns as ColumnStore;
    const cols = columnStore.query((col: any) => col.data.isPeriodic);
    if (cols) {
      columnStore.remove(cols as any);
    }
    columnStore.add(getPeriodicColumns());
  }, [startDate, periodProperties]);

  const loadData = () => {
    const formatData = () => {
      if (!rows?.length) {
        return [];
      }
      return rows?.map(row => {
        const formattedRow: any = {
          id: row.id,
          dbId: row.dbId // temporarily assign the grand total a value
        };

        row.detailValues.forEach(value => {
          formattedRow[value.name] = value.value;
        });

        row.periodicValues.forEach(value => {
          formattedRow[
            periodicUnit !== TablePeriodicUnits.Years
              ? `${value.period}-${value.year}`
              : `${value.year}`
          ] = value.value;
        });
        return formattedRow;
      });
    };
    (gridRef.current?.instance.store as Store).add(formatData());
  };

  const setNextDate = (increment: boolean) => {
    setStartDate(
      dayjs(startDate)
        .add(
          increment
            ? periodProperties.numberOfPeriodColumns
            : -periodProperties.numberOfPeriodColumns,
          periodProperties.period as any
        )
        .toDate()
    );
  };

  const dateRangeDisplayName = () => {
    let displayDateFormat = dayjs(startDate).format(periodProperties.datePickerFormat);
    const endDateFormat = dayjs(startDate)
      .add(periodProperties.numberOfPeriodColumns - 1, periodProperties.period as any)
      .format(periodProperties.datePickerFormat);

    if (periodicUnit === TablePeriodicUnits.Quarters || periodicUnit === TablePeriodicUnits.Years) {
      displayDateFormat = `${displayDateFormat} - ${endDateFormat}`;
    }
    return displayDateFormat;
  };

  useEffect(() => {
    hideColumns(gridRef.current?.instance?.columns as ColumnStore, hiddenColumns);
  }, [hiddenColumns]);

  const getDetailColumns = () => {
    const columns = detailFields.map(column => {
      return generateBaseBryntumColumn(
        column,
        detailFields,
        { region: "details", isDetail: true },
        TableDataType.Periodic,
        `tableAutoHeight-${label.replace(" ", "-")}`
      );
    });

    return columns;
  };

  const getPeriodicColumns = () => {
    const columns: any[] = [];

    for (let index = 0; index < periodProperties.numberOfPeriodColumns; index++) {
      const nextPeriod = dayjs(startDate).add(index, periodProperties.period as any);

      let fieldName = `${nextPeriod.format(periodProperties.fieldNameFormat)}`;

      if (periodicUnit === TablePeriodicUnits.Years) {
        fieldName = `${nextPeriod.year()}`;
      }

      columns.push({
        field: fieldName,
        width: `${100 / periodProperties.numberOfPeriodColumns}%`,
        type: "number",
        text: nextPeriod.format(periodProperties.displayNameFormat).toUpperCase(),
        hideable: false,
        searchable: false,
        filterable: false,
        sum: true,
        isPeriodic: true,
        region: "periodic",
        summaryRenderer: ({ sum }: any) => {
          return sum.toLocaleString();
        }
      });
    }

    return columns;
  };

  const config: Partial<BryntumGridProps> = useMemo(() => {
    return {
      // autoHeight: true,
      width: "100%",
      subGridConfigs: {
        details: {
          flex: 1
        },
        periodic: {
          flex: 3
        },
        totals: {
          maxWidth: 200
        }
      },
      selectionMode: {
        cell: true,
        checkbox: true,
        showCheckAll: true
      },
      cellMenuFeature: {
        menu: {
          cls: "enada-bryntum-popup-menu"
        }
      },
      cellEditFeature: {
        triggerEvent: "cellclick",
        addNewAtEnd: false
      },
      cellCopyPasteFeature: {
        copyOnly: true
      },
      taskCopyPasteFeature: false,
      taskMenuFeature: false,
      rowCopyPasteFeature: false,
      regionResizeFeature: true,
      summaryFeature: true,
      searchFeature: true,
      excelExporterFeature: { zipcelx },
      filterFeature: true,
      store: {
        modelClass: PeriodicModel
      },
      columns: {
        autoAddField: true,
        data: [
          ...getDetailColumns(),
          ...getPeriodicColumns(),
          {
            type: "template",
            text: "Total",
            field: "total",
            region: "totals",
            align: "right",
            sortable: false,
            sum: (total: any, current: any) => {
              const columnStore = gridRef.current?.instance.columns as ColumnStore;
              const cols = columnStore.query((col: any) => col.data.isPeriodic);
              cols.forEach((col: any) => {
                if (current.data[col.field]) {
                  total = total + current.data[col.field];
                }
              });

              return total;
            },
            hideable: false,
            editor: false,
            template: (cell: any) => {
              const columnStore = gridRef.current?.instance.columns as ColumnStore;
              const cols = columnStore.query((col: any) => col.data.isPeriodic);
              let total = 0;

              cols.forEach((col: any) => {
                if (cell.record[col.field]) {
                  total = total + cell.record[col.field];
                }
              });
              return total.toLocaleString();
            },
            summaryRenderer: ({ sum }: any) => {
              return sum.toLocaleString();
            }
          },
          {
            type: "template",
            text: "Grand total",
            field: "_grandTotal",
            region: "totals",
            align: "right",
            sortable: false,
            sum: true,
            hideable: false,
            editor: false,
            template: (cell: any) => cell.record._grandTotal.toLocaleString(),
            summaryRenderer: ({ sum }: any) => {
              return sum.toLocaleString();
            }
          }
        ]
      },
      keyboardNavigationFeature: true, // Enable keyboard navigation
      focusable: true, // Ensure the grid is focusable
      aria: {
        role: "grid", // ARIA role for grid
        label: "Data Grid" // ARIA label for screen readers
      }
    };
  }, [periodProperties]);

  const onFocusOut = (e: any) => {
    if (!e.backwards) return;
    const selected = gridRef.current?.instance.selectedCell as any;
    if (!selected) return;
    gridRef.current?.instance.deselectCell(selected);
  };

  useEffect(() => {
    // Once the grid is loaded, synchronize the tables autoheight with the one in local storage
    if (!loaded) return;
    if (!autoHeight) return;

    toggleAutoHeight(autoHeight);
  }, [loaded]);
  return (
    <Grid container className={`periodic-table-root ${fullScreen && "fullscreen"}`}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={`${fullScreen && "sticky-toolbar"}`}>
            <PeriodicTableToolbar
              t={t}
              toolbarModule={toolbarModule}
              fields={detailFields}
              grid={gridRef}
              columns={detailFields}
              readOnly={readOnly}
              hideEdi={hideEdi}
              onEdiClick={onEdiClick}
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              label={label}
              hasSelectedData={hasSelectedData}
              titleModule={
                <Stack direction="row" spacing={1}>
                  <EdisonTypography
                    title={label}
                    sx={{
                      color:
                        required && (rows === undefined || rows.length === 0) ? "red" : undefined
                    }}
                    variant={"data"}
                  />
                  {description && (
                    <Tooltip
                      title={description}
                      arrow
                      placement="right"
                      className="table-info-icon"
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                  {readOnly && (
                    <LockOutlinedIcon fontSize="small" className="table-readonly-icon" />
                  )}
                  {required && (
                    <Stack style={{ color: "red", alignItems: "center" }}>&nbsp; *</Stack>
                  )}
                </Stack>
              }
            />
          </div>
          <Divider />
          <Grid container justifyContent="center">
            <IconButton onClick={() => setNextDate(false)}>
              <ArrowBack />
            </IconButton>
            <DatePicker
              value={startDate}
              views={periodProperties.datePickerViews}
              onChange={date => {
                if (date) setStartDate(date);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box ref={inputRef} sx={{ display: "flex", alignItems: "center" }}>
                  <EdisonTypography title={dateRangeDisplayName()} variant="fieldtitle" />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
            <IconButton onClick={() => setNextDate(true)}>
              <ArrowForward />
            </IconButton>
          </Grid>
          <div className="periodic-table-container enada-bryntum-grid">
            <PeriodicReadOnlyContext.Provider value={Boolean(readOnly)}>
              <BryntumGrid
                ref={gridRef}
                {...config}
                rowHeight={ROW_HEIGHT}
                maxHeight={"calc(100vh - 65px)"}
                height={`${
                  (rows?.length ? rows?.length * ROW_HEIGHT : 0) +
                  TOOLBAR_HEIGHT +
                  FOOTER_HEIGHT +
                  HORIZONTAL_SCROLL_HEIGHT
                }px`}
                readOnly={readOnly}
                onSelectionChange={e => setHasSelectedData(e.selection.length > 0)}
                onRenderRow={() => {
                  if (loaded) return;
                  setLoaded(true);
                }}
                onFocusOut={onFocusOut}
                onDataChange={(data: any) => {
                  let key = "";
                  if (data.changes) {
                    key = Object.entries(data.changes)[0][0];
                  }

                  if (key !== "_grandTotal") {
                    if (
                      onDataChanged &&
                      (data.action === "add" ||
                        data.action === "update" ||
                        data.action === "remove")
                    ) {
                      onDataChanged(data);
                    }

                    if (data.action === "update") {
                      const columnStore = gridRef.current?.instance.columns as ColumnStore;
                      const periodicCols = columnStore.query((col: any) => col.data.isPeriodic);
                      const periodicKeys = Object.entries<{
                        value: number;
                        oldValue: number;
                      }>(data.changes).filter(
                        entry => periodicCols.filter((c: any) => c.field === entry[0]).length
                      );
                      periodicKeys.forEach(entry => {
                        data.record._grandTotal =
                          entry[1].value -
                          (entry[1].oldValue ? entry[1].oldValue : 0) +
                          data.record._grandTotal;
                      });
                    }
                  }
                }}
              />
            </PeriodicReadOnlyContext.Provider>
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default UserPeriodicTable;
export { UserPeriodicTable };
