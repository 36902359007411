import dayjs from "dayjs";

export const convertDecimalToTime = (decimal: number) => {
  // Separate the int from the decimal part
  const hour = Math.floor(decimal);
  const decpart = decimal - hour;

  const min = 1 / 60;
  // Round to nearest minute
  const decimalPart = min * Math.round(decpart / min);

  const minute = Math.floor(decimalPart * 60);

  return { hour: hour, minute: minute };
};

export const addTimeToHour = (initialHour: number, initialMinute: number, time: number) => {
  // Change decimal to proper hour/minute object
  // Eg: 9.5 should give us 9 hours and 30 minutes to work with
  const timeObject = convertDecimalToTime(time);
  const initialTime = dayjs().hour(initialHour).minute(initialMinute).second(0);
  const finalTime = initialTime.add(timeObject.hour, "hour").add(timeObject.minute, "minute");
  return `${finalTime.hour()}:${finalTime.minute().toString().padStart(2, "0")}`;
};

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const isDateInWeek = (dateString?: string, weekOffset?: number) => {
  if (!dateString) return false;
  const date = removeTimeFromDate(new Date(dateString));
  // taken from https://stackoverflow.com/questions/4156434/javascript-get-the-first-day-of-the-week-from-current-date
  const compare = new Date();
  if (weekOffset) {
    // if we have provided a week offset add seven days times the number of offset weeks to the compare date
    compare.setDate(new Date().getDate() + 7 * weekOffset);
  }
  const day = compare.getDay();
  const diff = compare.getDate() - day + (day == 0 ? -6 : 1);
  const monday = removeTimeFromDate(new Date(compare.setDate(diff)));
  const sunday = removeTimeFromDate(new Date(compare.setDate(monday.getDate() + 6)));
  if (date.getTime() < monday.getTime()) return false;
  if (date.getTime() > sunday.getTime()) return false;
  return true;
};
export const isDateInNext7Days = (dateString?: string, weekOffset?: number) => {
  if (!dateString) return false;
  const date = removeTimeFromDate(new Date(dateString));
  const compare = new Date();
  const daysToAdd = weekOffset ? 7 * weekOffset : 7;
  compare.setDate(new Date().getDate() + daysToAdd);

  if (date.getTime() < removeTimeFromDate(new Date()).getTime()) return false;
  if (date.getTime() > removeTimeFromDate(compare).getTime()) return false;
  return true;
};
export const removeTimeFromDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
