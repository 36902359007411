import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { BaseUserFieldProps } from "enada-common";

// taken from https://mui.com/material-ui/react-autocomplete/
const PlacesAutoComplete: FC<Partial<BaseUserFieldProps>> = ({
  useInternalState,
  value,
  onChange
}) => {
  const autoCompleteService = useRef(new google.maps.places.AutocompleteService());
  const [internalState, setInternalState] =
    useState<google.maps.places.AutocompletePrediction | null>(value ?? null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: google.maps.places.AutocompletePrediction[] | null) => void
        ) => {
          autoCompleteService.current.getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(internalState ? [internalState] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: google.maps.places.AutocompletePrediction[] = [];

        if (internalState) {
          newOptions = [internalState];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [internalState, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      getOptionLabel={option => (typeof option === "string" ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={internalState}
      noOptionsText="No locations"
      onChange={(event: any, newValue: google.maps.places.AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setInternalState(newValue);
        if (!onChange) return;
        if (useInternalState) return;
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => <TextField {...params} autoFocus label="Add a location" fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                <Box component="span">{option.description}</Box>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
export default PlacesAutoComplete;
