import { ConditionalExpression } from "../../models/backend/Field.model";

import { CalculatedExpressionUnit, ConditionBlock } from "../../models/frontend/Calculated.model";
import { Expression, ExpressionPart } from "../../models/backend/Workflow.model";

export const parseConditionBlockToFrontend = (
  expression: ConditionalExpression
): ConditionBlock => {
  const baseConditionBlock: ConditionBlock = {
    if: parseExpressionToFrontend(expression.conditionExpression)
  };
  if (expression.successExpression) {
    baseConditionBlock.then = parseExpressionToFrontend(expression.successExpression);
  }
  if (expression.failureExpression) {
    baseConditionBlock.else = parseExpressionToFrontend(expression.failureExpression);
  }
  if (expression.successConditionalExpression) {
    baseConditionBlock.conditionalThen = parseConditionBlockToFrontend(
      expression.successConditionalExpression
    );
  }
  if (expression.failureConditionalExpression) {
    baseConditionBlock.conditionalElse = parseConditionBlockToFrontend(
      expression.failureConditionalExpression
    );
  }

  return baseConditionBlock;
};

export const parseExpressionToFrontend = (expression?: Expression) => {
  if (!expression || !expression.expressionBlocks || expression.expressionBlocks?.length === 0) {
    throw new Error("Expression array empty or undefined");
  }
  const parts: ExpressionPart[] = expression.expressionBlocks[0].expressionParts ?? [];
  if (parts.length === 0) {
    throw new Error("Expression part array empty");
  }
  return recursivelyParseExpressionParts(parts);
};

const recursivelyParseExpressionParts = (parts: ExpressionPart[]) => {
  let unitList: CalculatedExpressionUnit[] = [];

  parts.forEach(part => {
    if (part.joinOperator) {
      unitList.push({
        type: "operator",
        value: part.joinOperator
      });
    }
    if (part.compareOperator) {
      if (part.compareFromEntity) {
        unitList.push({ type: "entity", value: part.compareFromEntity });
      } else {
        unitList.push(parsePartFixedValue(part.fixedValue));
      }
      unitList.push({ type: "operator", value: part.compareOperator });
      if (part.compareToEntity) {
        unitList.push({ type: "entity", value: part.compareToEntity });
      } else {
        unitList.push(parsePartFixedValue(part.fixedValue));
      }
    }
    if (part.fixedValue !== undefined && part.fixedValue !== null && !part.compareOperator) {
      unitList.push(parsePartFixedValue(part.fixedValue));
    }
    if (part.singleEntity) {
      unitList.push({ type: "entity", value: part.singleEntity });
    }
    if (part.expressionBlock) {
      if (part.expressionBlock.joinOperator) {
        unitList.push({
          type: "operator",
          value: part.expressionBlock.joinOperator
        });
      }
      unitList.push({ type: "operator", value: "(" });
      unitList = unitList.concat(
        recursivelyParseExpressionParts(part.expressionBlock.expressionParts ?? [])
      );
      unitList.push({ type: "operator", value: ")" });
    }
  });
  return unitList;
};

const parsePartFixedValue = (fixedValue: unknown): CalculatedExpressionUnit => {
  const isValueNumerical = !isNaN(fixedValue as any);
  return {
    type: isValueNumerical ? "number" : "text",
    value: isValueNumerical ? (fixedValue as number) : (fixedValue as string)
  };
};
