import { FillHandleConfig } from "@bryntum/grid-thin";

const FillHandleSharedConfig = {
  listeners: {
    fillHandleBeforeDragFinalize({ from, to }) {
      const fromBryntumType = from?.column?.data?.type; // If the column has a type, it is a bryntum column
      const fromEdisonType = from?.column?.data?.fieldDataType; // If the column has a fieldDataType, it is an edison column
      const toBryntumType = to?.column?.data?.type; // If the column has a type, it is a bryntum column
      const toEdisonType = to?.column?.data?.fieldDataType; // If the column has a fieldDataType, it is an edison column

      // If filling from one multi/choice column to another multi/choice column, return false and do not paste
      // Ensure that the fill handle pastes values in the same column i.e. dragging down a column
      if (
        from?.column?.data?.field !== to?.column?.data?.field &&
        to?.column?.data?.fieldDataType &&
        ["choice", "multichoice"].includes(to?.column?.data?.fieldDataType?.toLowerCase())
      ) {
        return false;
      }

      return isFillHandleValid(fromBryntumType, toBryntumType, fromEdisonType, toEdisonType);
    }
  },
  fillHandleFeature: {
    calculateFillValue({ column, range, record }) {
      const to = (column as any).data;
      // Do not overwrite the value of read only columns
      if (to?.fieldReadOnly) {
        return record.getData(column.field);
      }

      const overrides = range
        .map((gridLocation: any) => {
          const fromBryntumType = gridLocation?.column?.data?.type; // If the column has a type, it is a bryntum column
          const fromEdisonType = gridLocation?.column?.data?.fieldDataType; // If the column has a fieldDataType, it is an edison column
          const toBryntumType = to?.data?.type; // If the column has a type, it is a bryntum column
          const toEdisonType = to?.data?.fieldDataType; // If the column has a fieldDataType, it is an edison column

          if (isFillHandleValid(fromBryntumType, toBryntumType, fromEdisonType, toEdisonType)) {
            return null; // returning null will not override the default fill handle behaviour
          }

          // Return the value of the destination column i.e. do not paste
          return record.getData(to.data.field);
        })
        .filter(value => value !== null);

      if (overrides?.length > 0) {
        if (overrides.includes(undefined)) {
          return null; // Components like People Picker can return undefined, in which case return null to prevent pasting
        }

        return overrides[0];
      }
    },

    allowCropping: true
  } as FillHandleConfig
};

const isFillHandleValid = (fromBryntumType, toBryntumType, fromEdisonType, toEdisonType) => {
  // If the source and destination columns are a missmatch between bryntum and edison columns, return the destination column value
  if ((fromBryntumType && toEdisonType) || (fromEdisonType && toBryntumType)) {
    return false; // Don't paste, just retur the same value that is currently in the cell therefore no change is made to the cell
  }

  // If the source and destination columns are bryntum columns and are not the same type, return the destination column value
  if (fromBryntumType && toBryntumType && fromBryntumType !== toBryntumType) {
    return false; // Don't paste, just retur the same value that is currently in the cell therefore no change is made to the cell
  }

  // If the source and destination columns are edison columns and not the same type, return the destination column value
  if (fromEdisonType && toEdisonType && fromEdisonType !== toEdisonType) {
    return false; // Don't paste, just retur the same value that is currently in the cell therefore no change is made to the cell
  }

  return true;
};

export default FillHandleSharedConfig;
