import {
  CalculatedExpressionUnit,
  ConditionBlock,
  ExpressionEntity,
  Field,
  fullOperatorList
} from "enada-common";
import { evaluate } from "mathjs";

export const calculateExpressionValue = (
  record: any,
  fieldsInTable: Field[],
  expression?: CalculatedExpressionUnit[]
) => {
  if (!expression) return 0;
  try {
    const stringToEval = parseFlatExpressionToEvalString(expression, record, fieldsInTable);
    return evaluate(stringToEval);
  } catch (error) {
    /* empty */
  }
};

export const calculateConditionValue = (
  record: any,
  fieldsInTable: Field[],
  conditionBlock?: ConditionBlock
) => {
  if (!conditionBlock) return 0;
  try {
    const stringToEval = parseConditionBlockToEvalString(conditionBlock, record, fieldsInTable);
    return evaluate(stringToEval);
  } catch (error) {
    /* empty */
  }
};

const parseFlatExpressionToEvalString = (
  expression: CalculatedExpressionUnit[],
  record: any,
  fieldsInTable: Field[]
) => {
  const mappedExpression = mapExpressionDataIdsToColumnNames(expression, fieldsInTable);
  const allValuesSet = expressionHasAllValuesSet(
    mappedExpression as CalculatedExpressionUnit[],
    record
  );

  if (!allValuesSet) throw "Not all Values in expression set";

  const frontendExpression = mappedExpression.map(unit => {
    const t = record[(unit.value as ExpressionEntity).entityId ?? ""];

    return unit.type === "entity"
      ? {
          ...unit,
          value: t
        }
      : unit;
  });

  const stringToEval = frontendExpression.reduce((acc, current) => {
    let unitString =
      current.type === "operator"
        ? fullOperatorList.find(pair => pair.backend === current.value)?.frontend
        : current.value;

    if (unitString && typeof unitString === "string") {
      unitString = unitString.replace("||", " or ");
    }

    return `${acc}${unitString}`;
  }, "");

  return stringToEval;
};

const parseConditionBlockToEvalString = (
  block: ConditionBlock,
  record: any,
  fieldsInTable: Field[]
) => {
  let stringToEval = "";
  if (block.if) {
    stringToEval += `(${evaluate(
      parseFlatExpressionToEvalString(block.if, record, fieldsInTable)
    )})`;
  }
  if (block.then && (block.else || block.conditionalElse)) {
    stringToEval += ` ? ${evaluate(
      parseFlatExpressionToEvalString(block.then, record, fieldsInTable)
    )}`;
  }
  if (block.then && !block.else && !block.conditionalElse) {
    stringToEval += ` ?? ${evaluate(
      parseFlatExpressionToEvalString(block.then, record, fieldsInTable)
    )}`;
  }
  if (block.conditionalThen) {
    stringToEval += ` ? (${evaluate(
      parseConditionBlockToEvalString(block.conditionalThen, record, fieldsInTable)
    )})`;
  }
  if (block.else) {
    stringToEval += ` : ${evaluate(
      parseFlatExpressionToEvalString(block.else, record, fieldsInTable)
    )}`;
  }
  if (block.conditionalElse) {
    stringToEval += ` : (${evaluate(
      parseConditionBlockToEvalString(block.conditionalElse, record, fieldsInTable)
    )})`;
  }

  return stringToEval;
};

const expressionHasAllValuesSet = (expression: CalculatedExpressionUnit[], record: any) => {
  return !expression
    .filter(({ value }) => typeof value === "object" && value.entityType === "Field")
    .some(({ value }) =>
      [null, undefined].includes(record[(value as ExpressionEntity).entityId ?? ""])
    );
};
const mapExpressionDataIdsToColumnNames = (
  expression: CalculatedExpressionUnit[],
  fieldsInTable: Field[]
) => {
  return expression.map(unit => {
    if (typeof unit.value === "object" && unit.value.entityType === "Field") {
      const foundField = fieldsInTable.find(
        field => field.id === (unit.value as ExpressionEntity).entityId
      );

      return {
        ...unit,
        value: { ...unit.value, entityId: foundField?.name ?? "" }
      };
    }
    return unit;
  });
};
