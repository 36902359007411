import { FC } from "react";
import EdisonTypography from "../../typography/EdisonTypography";
import {
  Alert,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  Button
} from "@mui/material";

const RestoreDialog: FC<any> = ({ onClose, title, content }) => {
  return (
    <Dialog open={true} onClose={() => onClose(false)} maxWidth="xs">
      <CardHeader
        title={title}
        titleTypographyProps={{ component: () => <EdisonTypography variant="h1" title={title} /> }}
      ></CardHeader>
      <Divider />
      <CardContent>
        <Alert severity="info" sx={{ alignItems: "center" }}>
          {content}
        </Alert>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button onClick={() => onClose(true)} autoFocus variant="contained">
          Restore
        </Button>
        <Button onClick={() => onClose(false)} variant="outlined" color="info">
          Cancel
        </Button>
      </CardActions>
    </Dialog>
  );
};

export default RestoreDialog;
export { RestoreDialog };
