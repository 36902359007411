import { PublicClientApplication } from "@azure/msal-browser";
import { apiConfig, graphRequest, msalConfig } from "../config/authConfig";
import { callMsGraphGet, callMsGraphPost } from "./graphService";
import { execute } from "../utils/requestHandler";
import {
  deleteEndpoint,
  fetchBoolean,
  fetchFields,
  fetchSingleElement,
  recycleEndpoint,
  recycleModuleEndpoint,
  restoreEndpoint,
  saveFields,
  saveRoles,
  postHttpRequest,
  updateFields,
  fetchResourcesTemp
} from "./fieldsService";
import { saveFile } from "./FileService";
import { BaseRecord, Card, FieldValueOperation, RecordPermission, RecordType } from "enada-common";

import { ODataApiResponse } from "../types/ApiResponse";
import { RecordAssociationMapping } from "../types/createRecordModal";
import { PersonalData } from "../types/personalData";
import { RecordProgramConfiguration } from "../types/programs";

const msalInstance = new PublicClientApplication(msalConfig);

export const getApiForRecord = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCasesEndpoint;
    case RecordType.Programs:
      return apiConfig.programsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengesEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideasEndpoint;
    default:
      return apiConfig.projectsEndpoint;
  }
};

export const getApiForTables = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectTablesEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseTablesEndpoint;
    case RecordType.Programs:
      return apiConfig.programTablesEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeTablesEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaTablesEndpoint;
    default:
      return apiConfig.projectTablesEndpoint;
  }
};
const getApiForReviews = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectReviewsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseReviewsEndpoint;
    case RecordType.Programs:
      return apiConfig.programReviewsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeReviewsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaReviewsEndpoint;
    default:
      return apiConfig.projectReviewsEndpoint;
  }
};

const getApiForVersions = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectVersionsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseVersionsEndpoint;
    case RecordType.Programs:
      return apiConfig.programVersionsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeVersionsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaVersionsEndpoint;
    default:
      return apiConfig.projectVersionsEndpoint;
  }
};

export const getApiForRecordPermissions = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectPermissionsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCasePermissionsEndpoint;
    case RecordType.Programs:
      return apiConfig.programPermissionsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengePermissionsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaPermissionsEndpoint;
    default:
      return apiConfig.projectPermissionsEndpoint;
  }
};

// We might need to move this out to requestHandler.js when we read user attributes from API
export function getIndividualForm(id: number): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubFormsEndpoint}`,
    null,
    id
  );
}

export function getForms(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.contentHubFormsEndpoint}`);
}

export function postForm(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveFields, `${apiConfig.contentHubFormsEndpoint}`, data);
}

export function putForms(data: unknown): Promise<unknown> {
  return execute(msalInstance, updateFields, `${apiConfig.contentHubFormsEndpoint}`, data);
}
export function getCards(): Promise<ODataApiResponse<Card[]>> {
  return execute(msalInstance, fetchFields, `${apiConfig.contentHubODataApiCards}`);
}

export function getIndividualCard(id: number): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubCardsEndpoint}`,
    null,
    id
  );
}

export function postCard(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveFields, `${apiConfig.contentHubCardsEndpoint}`, data);
}

export function putCard(data: unknown): Promise<unknown> {
  return execute(msalInstance, updateFields, `${apiConfig.contentHubCardsEndpoint}`, data);
}

export function getFields(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.contentHubFieldsEndpoint}`);
}

export function restoreCards(ids: number[]): Promise<unknown> {
  return execute(msalInstance, restoreEndpoint, `${apiConfig.contentHubCardsEndpoint}`, ids);
}

export function restoreForms(ids: number[]): Promise<unknown> {
  return execute(msalInstance, restoreEndpoint, `${apiConfig.contentHubFormsEndpoint}`, ids);
}

export function restoreTables(ids: number[]): Promise<unknown> {
  return execute(msalInstance, restoreEndpoint, `${apiConfig.contentHubTablesEndpoint}`, ids);
}

export function restoreCalendars(ids: number[]): Promise<unknown> {
  return execute(msalInstance, restoreEndpoint, `${apiConfig.contentHubCalendarsEndpoint}`, ids);
}

export function restoreUserDetails(queryString: string): Promise<unknown> {
  return execute(
    msalInstance,
    postHttpRequest,
    `${apiConfig.contentHubUserDetailsEndpoint}/restore?${decodeURIComponent(queryString)}`,
    null
  );
}

export function deleteFields(ids: number[]): Promise<unknown> {
  return execute(msalInstance, deleteEndpoint, `${apiConfig.contentHubFieldsEndpoint}`, ids);
}

export function recycleFields(ids: number[]): Promise<unknown> {
  return execute(msalInstance, recycleEndpoint, `${apiConfig.contentHubFieldsEndpoint}`, ids);
}

export function deleteForms(ids: number[]): Promise<unknown> {
  return execute(msalInstance, deleteEndpoint, `${apiConfig.contentHubFormsEndpoint}`, ids);
}

export function recycleForms(ids: number[]): Promise<unknown> {
  return execute(msalInstance, recycleEndpoint, `${apiConfig.contentHubFormsEndpoint}`, ids);
}

export function deleteCards(ids: number[]): Promise<unknown> {
  return execute(msalInstance, deleteEndpoint, `${apiConfig.contentHubCardsEndpoint}`, ids);
}

export function recycleCards(ids: number[]): Promise<unknown> {
  return execute(msalInstance, recycleEndpoint, `${apiConfig.contentHubCardsEndpoint}`, ids);
}

export function putFields(data: unknown): Promise<unknown> {
  return execute(msalInstance, updateFields, `${apiConfig.contentHubFieldsEndpoint}`, data);
}

export function getCalendars(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.contentHubCalendarsEndpoint}`);
}

// TODO: remove this endpoint when my work/personal endpoints are moved over to RTK
export function getIndividualTable(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubTablesEndpoint}`,
    null,
    id
  );
}

export function getIndividualCalendar(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubCalendarsEndpoint}`,
    null,
    id
  );
}

export function postCalendars(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveFields, `${apiConfig.contentHubCalendarsEndpoint}`, data);
}

export function deleteCalendars(ids: number[]): Promise<unknown> {
  return execute(msalInstance, deleteEndpoint, `${apiConfig.contentHubCalendarsEndpoint}`, ids);
}

export function recycleCalendars(ids: number[]): Promise<unknown> {
  return execute(msalInstance, recycleEndpoint, `${apiConfig.contentHubCalendarsEndpoint}`, ids);
}

export function putCalendars(data: unknown): Promise<unknown> {
  return execute(msalInstance, updateFields, `${apiConfig.contentHubCalendarsEndpoint}`, data);
}

export function getIndividualWorkflow(id: number): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubWorkflowEndpoint}`,
    null,
    id
  );
}

export function postWorkflowPopulationValues(
  data: unknown,
  populationId: number
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/values`,
    data
  );
}

export function getWorkflowPopulationValues(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/values`
  );
}

export function postWorkflowPopulationRows(data: unknown, populationId: number): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/rows`,
    data
  );
}

export function getWorkflowPopulationRows(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/rows?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`
  );
}

export function postWorkflowPopulationTableConfigs(
  data: unknown,
  populationId: number
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/configurations`,
    data
  );
}

export function getWorkflowPopulationTableConfigs(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/configurations`
  );
}

export function getWorkflowPopulationTableTotals(
  populationId: number,
  tableId: number
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/table/${tableId}/totals`
  );
}

export function getRecords(queryString: string): Promise<ODataApiResponse<BaseRecord[]>> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.recordsODataEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getProjects(queryString: string): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.projectsEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getBusinessCase(queryString: string): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.businessCasesEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getPrograms(queryString: string): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.programsEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getAccessToken(id: number, recordType: RecordType, headers: Headers): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForRecordPermissions(recordType)}/${id}/access-token`,
    null,
    undefined,
    null,
    headers
  );
}

export function getRecordViewValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForRecord(recordType)}/${recordId}/values/${viewId}`,
    null,
    undefined,
    null,
    headers
  );
}

export function postRecordViewValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForRecord(recordType)}/${recordId}/values/${viewId}`,
    data,
    undefined,
    null,
    headers
  );
}

export function getRecordViewTableRowValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    // TEMP FOR DEMO!
    `${getApiForTables(
      recordType
    )}/${recordId}/rows/${viewId}?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    // `${getApiForRecord(
    //   recordType
    // )}/${recordId}/view/${viewId}/rows?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    null,
    undefined,
    null,
    headers
  );
}
export function getRecordViewPendingTableRowValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    // TEMP FOR DEMO!
    `${getApiForTables(
      recordType
    )}/${recordId}/pending/rows/${viewId}?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    // `${getApiForRecord(
    //   recordType
    // )}/${recordId}/view/${viewId}/rows?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    null,
    undefined,
    null,
    headers
  );
}
export function getRecordViewSavedPendingTableRowValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    // TEMP FOR DEMO!
    `${getApiForTables(
      recordType
    )}/${recordId}/pending/rows/saved/${viewId}?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    // `${getApiForRecord(
    //   recordType
    // )}/${recordId}/view/${viewId}/rows?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
    null,
    undefined,
    null,
    headers
  );
}

export function getRecordViewTablePeriodicGrandTotals(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  tableId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForTables(recordType)}/${recordId}/table/${tableId}/totals/${viewId}`,
    null,
    undefined,
    null,
    headers
  );
}

export function postPendingRecordTableRowApproval(
  recordId: number,
  recordType: RecordType,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/pending/rows/approval`,
    data,
    undefined,
    null,
    headers
  );
}
export function postRecordViewTableRowValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/rows/${viewId}`,
    data,
    undefined,
    null,
    headers
  );
}
export function postPendingRecordViewTableRowValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/pending/rows/${viewId}`,
    data,
    undefined,
    null,
    headers
  );
}
export function postRecallPendingRow(
  recordId: number,
  recordType: RecordType,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/pending/rows/recall`,
    data,
    undefined,
    null,
    headers
  );
}

export function postViewTableRowValuesPersonalPage(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/view/${viewId}/rows/personal-page`,
    data,
    undefined,
    null,
    headers
  );
}

export function getRecordTablesConfiguration(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForTables(recordType)}/${recordId}/configurations`,
    null,
    undefined,
    null,
    headers
  );
}

export function postRecordTablesConfiguration(
  recordId: number,
  recordType: RecordType,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForTables(recordType)}/${recordId}/configurations`,
    data,
    undefined,
    null,
    headers
  );
}

export function getIndividualRecord(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    getApiForRecord(recordType),
    null,
    recordId,
    null,
    headers
  );
}

export function getRecordVersions(recordId: number, recordType: RecordType): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForVersions(recordType)}/record/${recordId}`,
    null,
    undefined,
    null
  );
}

export function postRecordVersion(
  recordId: number,
  recordType: RecordType,
  data: any
): Promise<any> {
  return execute(msalInstance, saveFields, `${getApiForVersions(recordType)}/${recordId}`, data);
}

export function getRecordVersion(versionId: number, recordType: RecordType): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForVersions(recordType)}/${versionId}`,
    null,
    undefined,
    null
  );
}

export function getHistoryEvents(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchBoolean,
    `${getApiForRecord(recordType)}/${recordId}/history`,
    headers
  );
}

export function getRecordUserLiked(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchBoolean,
    `${getApiForRecord(recordType)}/${recordId}/has-user-favourited`,
    headers
  );
}
export function getRecordWorkflowStage(workflowStageId: number, headers: Headers): Promise<any> {
  return execute(
    msalInstance,
    fetchBoolean,
    `${apiConfig.contentHubWorkflowEndpoint}/stage/${workflowStageId}`,
    headers
  );
}

export function getRecordUserRating(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchBoolean,
    `${getApiForRecord(recordType)}/${recordId}/user-rating`,
    headers
  );
}

export function postRecordUserLike(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<unknown> {
  return execute(
    msalInstance,
    postHttpRequest,
    `${getApiForRecord(recordType)}/${recordId}/favourite`,
    headers
  );
}

export function postRecordViews(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    postHttpRequest,
    `${getApiForRecord(recordType)}/${recordId}/view`,
    headers
  );
}

export function deleteRecordUserLike(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<unknown> {
  return execute(
    msalInstance,
    deleteEndpoint,
    `${getApiForRecord(recordType)}/${recordId}/favourite`,
    headers
  );
}

export function postRecordUserRating(
  recordId: number,
  recordType: RecordType,
  headers: Headers,
  rating: number
): Promise<unknown> {
  return execute(
    msalInstance,
    postHttpRequest,
    `${getApiForRecord(recordType)}/${recordId}/rating/${rating}`,
    headers
  );
}

export function postRecord(data: unknown, recordType: RecordType): Promise<unknown> {
  return execute(msalInstance, saveFields, getApiForRecord(recordType), data);
}

export function postProgramAssociation(data: unknown, recordId: number): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.programAssociationsEndpoint}/${recordId}`,
    data
  );
}

export function postRecordImage(
  recordId: number,
  recordType: RecordType,
  headers: Headers,
  image: unknown
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFile,
    `${getApiForRecord(recordType)}/${recordId}/image`,
    image,
    null,
    null,
    headers
  );
}

export function recycleRecord(id: number, recordType: RecordType, headers: Headers): Promise<any> {
  return execute(
    msalInstance,
    recycleModuleEndpoint,
    getApiForRecord(recordType),
    null,
    id,
    null,
    headers
  );
}

export function postRecordWorkflowContinue(
  recordId: number,
  recordType: RecordType,
  headers: Headers,
  data?: { comment: string }
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForRecord(recordType)}/${recordId}/workflow/continue`,
    data,
    undefined,
    null,
    headers
  );
}

export function postRecordWorkflowRollback(
  recordId: number,
  recordType: RecordType,
  headers: Headers,
  data: { comment: string }
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForRecord(recordType)}/${recordId}/workflow/rollback`,
    data,
    undefined,
    null,
    headers
  );
}

export function postRecordPermissions(
  recordId: number,
  recordType: RecordType,
  headers: Headers,
  recordPermissions: RecordPermission[]
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForRecordPermissions(recordType)}/${recordId}`,
    recordPermissions,
    null,
    null,
    headers
  );
}

export function postRecordGoToStep(
  recordId: number,
  recordType: RecordType,
  stepId: number,
  headers: Headers,
  data: { comment: string }
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForRecord(recordType)}/${recordId}/workflow/go-to-step/${stepId}`,
    data,
    undefined,
    null,
    headers
  );
}

export function getRecordWorkflowReview(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForReviews(recordType)}/${recordId}`,
    null,
    undefined,
    null,
    headers
  );
}

export function getRecordWorkflowReviewers(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForReviews(recordType)}/${recordId}/reviewers`,
    null,
    undefined,
    null,
    headers
  );
}

export function postRecordWorkflowReview(
  recordId: number,
  recordType: RecordType,
  data: unknown,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForReviews(recordType)}/${recordId}`,
    data,
    undefined,
    null,
    headers
  );
}
export function postRecordWorkflowTask(
  recordId: number,
  recordType: RecordType,
  data: any,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${getApiForReviews(recordType)}/${recordId}/task`,
    { ...data, comment: data.comments },
    undefined,
    null,
    headers
  );
}

export function putRecord(
  data: unknown,
  recordType: RecordType,
  headers: Headers
): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    getApiForRecord(recordType),
    data,
    null,
    null,
    headers
  );
}

export function getTemplateBCAssociationMappings(
  recordId: number,
  templateId: number
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.businessCaseAssociationsEnpoint}/${recordId}/mapping/${templateId}`,
    null,
    undefined,
    null
  );
}
export function getBCAssociations(recordId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.businessCaseAssociationsEnpoint}/${recordId}`,
    null,
    undefined,
    null
  );
}
export function getProjectAssociations(recordId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.projectAssociationsEndpoint}/${recordId}`,
    null,
    undefined,
    null
  );
}

export function getProgramAssociations(recordId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.programAssociationsEndpoint}/${recordId}`,
    null,
    undefined,
    null
  );
}

export function mergeProgramAssociations(recordId: number, data: any): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.programAssociationsEndpoint}/${recordId}/merge`,
    data
  );
}

export function getProgramsConfiguration(
  queryString: string
): Promise<ODataApiResponse<RecordProgramConfiguration[]>> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.programConfigurationODataEndpoint}${decodeURIComponent(queryString)}`,
    null,
    undefined,
    null
  );
}

export function postChallengeAssociation(data: unknown, recordId: number): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.challengeAssociationsEndpoint}/${recordId}`,
    data
  );
}

// export function postChallengeAssociation(
//   recordId: number,
//   data: RecordAssociationMapping
// ): Promise<any> {
//   return execute(
//     msalInstance,
//     saveFields,
//     `${apiConfig.challengeAssociationsEndpoint}/${recordId}`,
//     data,
//     undefined,
//     null
//   );
// }

export function postBusinessCaseAssociation(
  recordId: number,
  data: RecordAssociationMapping
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.businessCaseAssociationsEnpoint}/${recordId}`,
    data,
    undefined,
    null
  );
}
export function getIdeaAssociations(recordId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.ideaAssociationsEndpoint}/${recordId}`,
    null,
    undefined,
    null
  );
}
export function postIdeaAssociation(recordId: number, data: BaseRecord): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.ideaAssociationsEndpoint}/${recordId}`,
    data,
    undefined,
    null
  );
}

export function getChallengeAssociations(recordId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.challengeAssociationsEndpoint}/${recordId}`,
    null,
    undefined,
    null
  );
}

export function deleteRecord(recordId: number, recordType: RecordType): Promise<unknown> {
  return execute(msalInstance, deleteEndpoint, getApiForRecord(recordType), null, recordId);
}

export function postGenerateTasks(data: any): Promise<unknown> {
  return execute(msalInstance, saveFields, apiConfig.commonGenerateTasksEndpoint, data);
}

export function getRequestMsGraph(queryString: string, headers?: Headers): Promise<any> {
  return execute(msalInstance, callMsGraphGet, queryString, null, undefined, graphRequest, headers);
}

export function postRequestMsGraph(queryString: string, body: string): Promise<any> {
  return execute(msalInstance, callMsGraphPost, queryString, body, undefined, graphRequest);
}

export function getNotifications(): Promise<any> {
  return execute(msalInstance, fetchFields, `${apiConfig.commonOdataEndpoint}/notifications`);
}

export function getSkills(): Promise<unknown> {
  return execute(msalInstance, fetchFields, apiConfig.commonTagsSkillEndpoint);
}

export function getRoles(): Promise<unknown> {
  return execute(msalInstance, fetchFields, apiConfig.commonTagsRoleEndpoint);
}

/* Resource Management */
export function getResources(): Promise<unknown> {
  return execute(msalInstance, fetchFields, apiConfig.resourcesEndpoint);
}

export function getMyResources(queryString: string): Promise<any> {
  return execute(
    msalInstance,
    fetchResourcesTemp,
    `${apiConfig.myResourcesEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function recycleUserDetails(id: string): Promise<unknown> {
  return execute(
    msalInstance,
    deleteEndpoint,
    `${apiConfig.contentHubUserDetailsEndpoint}/${id}/recycle`
  );
}

export function syncUserDetails(): Promise<unknown> {
  return execute(msalInstance, postHttpRequest, `${apiConfig.contentHubUserDetailsEndpoint}/all`);
}

export function getUserDetails(queryString: string): Promise<ODataApiResponse<PersonalData[]>> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubUserDetailsODataEndpoint}/${decodeURIComponent(queryString)}`
  );
}

export function getTasks(queryString: string): Promise<any> {
  return execute(
    msalInstance,
    fetchResourcesTemp,
    `${apiConfig.recordsODataTasksEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getMyRolesApi(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.rolesEndpoint}/my-roles`);
}

export function getInstanceProcesses(): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.managementApi}/tenants/instances/processes`
  );
}

export function getRegion(): Promise<any> {
  return execute(msalInstance, fetchFields, `${apiConfig.managementApi}/tenants/instances/region`);
}

export function getPermissionsByRole(queryString: string): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.permissionsEndpoint}/by-role${decodeURIComponent(queryString)}`
  );
}

export function postPermissions(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveRoles, apiConfig.permissionsEndpoint, data);
}

export function postRoles(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveRoles, `${apiConfig.permissionsEndpoint}/roles/add`, data);
}

export function removeRoles(data: unknown): Promise<unknown> {
  return execute(msalInstance, saveRoles, `${apiConfig.permissionsEndpoint}/roles/remove`, data);
}

export function convertMpp(data: { file: File; recordId: number; tableId: number }): Promise<any> {
  return execute(
    msalInstance,
    saveFile,
    `${apiConfig.commonConvertMppEndpoint}/${data.recordId}/${data.tableId}`,
    data.file
  );
}

export function getDefaultEntities(name: string): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.commonApi}migrationsTemplate/default-entities/${name}`
  );
}

export function getAIEnabledOnInstance(): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.managementApi}/tenants/instances/ai-enabled`
  );
}
