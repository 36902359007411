import { Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConditionExpression from "./conditionexpression/ConditionExpression";
import "./conditionzone.scss";
import { ConditionBlock } from "enada-common";
export interface ConditionZoneProps {
  block: ConditionBlock;
  path: string | null;
}
const ConditionZone: FC<ConditionZoneProps> = ({ block, path }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Stack className="condition-zone-root" spacing={2}>
      <ConditionExpression path={{ target: "if", path: path }} expressionList={block.if} />
      {block.then && (
        <div className="result-block">
          <ConditionExpression path={{ target: "then", path: path }} expressionList={block.then} />
        </div>
      )}
      {block.conditionalThen && (
        <Stack className="result-block nested" direction="row" spacing={2}>
          <div className="capitalized"> {t("then")}</div>
          <ConditionZone
            path={path ? `${path}-conditionalThen` : "conditionalThen"}
            block={block.conditionalThen}
          />
        </Stack>
      )}
      {block.else && (
        <div className="result-block">
          <ConditionExpression path={{ target: "else", path: path }} expressionList={block.else} />
        </div>
      )}

      {block.conditionalElse && (
        <Stack className="result-block nested" direction="row" spacing={2}>
          <div className="capitalized"> {t("else")}</div>
          <ConditionZone
            path={path ? `${path}-conditionalElse` : "conditionalElse"}
            block={block.conditionalElse}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ConditionZone;
