import { FC } from "react";
import {
  CalculatedExpressionUnit,
  ExpressionEntity,
  ExpressionOperator,
  WorkflowCondition,
  parseExpressionToFrontend
} from "enada-common";
import { Chip, Stack } from "@mui/material";
import "./conditionnodeinfo.scss";
import { selectAllFields } from "../../../../store/slices/fieldsSlice";
import { getFrontendOperator } from "../../../adminconfig/calculated/utils/FrontendToBackendOperatorMap";
import { useGetFieldsQuery } from "services/api";

export interface ConditionNodeInfoProps {
  conditionData: WorkflowCondition;
}

const ConditionNodeInfo: FC<ConditionNodeInfoProps> = ({ conditionData }) => {
  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const frontendExpression = conditionData.expression1
    ? parseExpressionToFrontend(conditionData.expression1)
    : [];

  const getUnitValue = (unit: CalculatedExpressionUnit): string | number => {
    switch (unit.type) {
      case "entity":
        return (
          fields.find(field => field.id === (unit.value as ExpressionEntity).entityId)
            ?.displayName ?? ""
        );
      case "operator":
        return getFrontendOperator(unit.value as ExpressionOperator) ?? "";
      case "number":
        return unit.value as number;
      default:
        return unit.value as string;
    }
  };

  return (
    <Stack
      direction="row"
      style={{
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100%",
        alignItems: "center"
      }}
    >
      {frontendExpression.slice(0, 3).map((part, index) =>
        index === 1 ? (
          <Stack className="condition-node-info-cell" direction="column" key={index}>
            {getUnitValue(part)}
          </Stack>
        ) : (
          <Chip
            className="condition-node-info-cell"
            color="primary"
            key={index}
            label={getUnitValue(part)}
          />
        )
      )}
    </Stack>
  );
};

export default ConditionNodeInfo;
