import {
  Field,
  BaseRecord,
  RecordTableRow,
  TableRowValue,
  Table,
  TableRowType,
  RowOperationType,
  OperationType
} from "enada-common";
import { v4 as uuidv4 } from "uuid";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { getBackendMappedCellValue, getFieldByKey, getRow } from "../tableHelpers";

const parseTableChangeToBackend = (
  changeEvent: any,
  table: Table,
  project: BaseRecord,
  fields: Field[],
  tableRows: RecordTableRow[]
): RowOperationType[] | null => {
  const tableValues: TableRowValue[] = [];
  const baseTableRow: RecordTableRow = {
    recordId: project.id as number,
    tableId: table.id,
    rowType: TableRowType.List
  };

  switch (changeEvent.action) {
    case "add": {
      const newId = uuidv4();
      changeEvent.records[0].id = newId;
      const recordToAdd = Object.entries(changeEvent.records[0].data).filter(([key, _]) =>
        key.endsWith("-e365")
      );
      recordToAdd.forEach(([key, value]) => {
        // Substring needed to remove -e365 identifier from custom field key
        const found = getFieldByKey(key.substring(0, key.length - 5), fields);
        if (!found) return;
        tableValues.push({
          ...getBackendMappedCellValue(found, value, 0),
          fieldId: found.id as number,
          recordTableRowId: 0
        });
      });
      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: [
            {
              ...baseTableRow,
              tableRowFieldValues: [
                ...tableValues,
                {
                  fieldId: SystemFieldType.UniqueId,
                  stringValue: newId,
                  recordTableRowId: 0
                }
              ],
              id: 0
            }
          ]
        }
      ];
    }
    case "update": {
      const [key, change]: any[] = Object.entries(changeEvent.changes)[0];
      const found = getFieldByKey(
        key.endsWith("-e365") ? key.substring(0, key.length - 5) : key,
        fields
      );

      const currentRow = getRow(tableRows, changeEvent.record);

      if (!found) break;
      if (!currentRow) break;
      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: [
            {
              ...baseTableRow,
              id: currentRow.id,
              uniqueId: changeEvent.record.id,
              tableRowFieldValues: [
                getBackendMappedCellValue(found, change.value, currentRow.id as number),
                {
                  fieldId: SystemFieldType.UniqueId,
                  stringValue: changeEvent.record.id,
                  recordTableRowId: currentRow.id as number
                }
              ]
            }
          ]
        }
      ];
    }
    case "remove":
      return [
        {
          operationType: OperationType.Delete,
          tableRows: changeEvent.records.map((record: any) => ({
            ...baseTableRow,
            id: getRow(tableRows, record)?.id,
            uniqueId: record.id,
            tableRowFieldValues: [
              {
                fieldId: SystemFieldType.UniqueId,
                stringValue: record.id,
                recordTableRowId: getRow(tableRows, record)?.id
              }
            ]
          }))
        }
      ];
    default:
      break;
  }

  return null;
};

export default parseTableChangeToBackend;
