import { apiConfig } from "../config/authConfig";

export const getCdnPath = (region: string) => {
  // Example CDN Image Path "https://cdn-dev{region}.edison365.com/images";
  const cdnImagePath = apiConfig.cdnImagePath;

  if (region) {
    return cdnImagePath.replace("{region}", "-" + region);
  }

  return cdnImagePath.replace("{region}", "");
};

export const getImagePath = (
  imageUrl: string | null | undefined,
  region: string,
  cdnToken?: string
) => {
  if (!imageUrl) return;

  let cdnTokenString = "";
  if (cdnToken && !imageUrl.includes("?tk=")) {
    cdnTokenString = `?tk=${cdnToken}`;
  }

  if (imageUrl.startsWith("http")) {
    return `${imageUrl}${cdnTokenString}`;
  }

  return `${getCdnPath(region)}/${imageUrl}${cdnTokenString}`;
};
