import { createApi } from "@reduxjs/toolkit/query/react";
import { authenticatedBaseQuery } from "./authenticatedBaseQuery";

const recordTags = ["RecordAccessToken"];

export const baseAPI = createApi({
  reducerPath: "baseAPI",
  baseQuery: authenticatedBaseQuery,
  tagTypes: [
    "Calendars",
    "Cards",
    "Fields",
    "Forms",
    "Licenses",
    "ManageUsers",
    "News",
    "Personal",
    "PersonalData",
    "Records",
    "Resources",
    "RecycledRecords",
    "Tables",
    "Templates",
    "User",
    "Workflows",
    ...recordTags
  ],
  // need to keep this as is for TS to not complain and endpoints to inject correctly
  endpoints: () => ({})
});
