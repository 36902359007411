import { RecordType } from "enada-common";

export const getAllowedRecordTypes = (
  parentRecordType?: RecordType | undefined,
  allowBusinessCaseCreation?: boolean | undefined,
  allowProjectCreation?: boolean | undefined,
  allowRecordCreation?: boolean | undefined
) => {
  let result = [] as RecordType[];
  switch (parentRecordType) {
    case RecordType.Programs:
      result = [RecordType.Projects, RecordType.BusinessCase, RecordType.Challenges];
      break;
    case RecordType.BusinessCase:
      if (allowRecordCreation) {
        result = [RecordType.Projects];
      }
      break;
    case RecordType.Challenges:
      if (allowRecordCreation) {
        result = [RecordType.Ideas];
      }
      break;
    case RecordType.Ideas:
      if (allowBusinessCaseCreation && allowProjectCreation) {
        result = [RecordType.BusinessCase, RecordType.Projects];
      } else if (allowBusinessCaseCreation) {
        result = [RecordType.BusinessCase];
      } else if (allowProjectCreation) {
        result = [RecordType.Projects];
      }
      break;
    default: // Creating a new record from a project is not possible.
  }
  return result;
};
