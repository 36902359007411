import { Button } from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  insertUnitIntoConditionBlock,
  insertUnitIntoSimpleExpression,
  selectIsCondition
} from "../../../../store/slices/calculatedFieldSlice";
import { CalculatedMoveAction } from "../utils/calculated.model";

import { FrontendToBackendOperatorMap } from "../utils/FrontendToBackendOperatorMap";
import "./operator.scss";
export interface OperatorProps {
  pair: FrontendToBackendOperatorMap;
}
const Operator: FC<OperatorProps> = ({ pair }) => {
  const dispatch = useAppDispatch();
  const isCondition = useAppSelector(selectIsCondition);
  return (
    <Button
      size="small"
      variant="outlined"
      className="operator-root"
      onClick={() => {
        isCondition
          ? dispatch(
              insertUnitIntoConditionBlock({
                unit: { type: "operator", value: pair.backend }
              })
            )
          : dispatch(
              insertUnitIntoSimpleExpression({
                type: "operator",
                value: pair.backend
              })
            );
      }}
    >
      {pair.frontend}
    </Button>
  );
};

export default Operator;
