import {
  ApiError,
  Category,
  currencies,
  Field,
  fieldConfiguration,
  FieldDataType,
  NotificationLevel,
  parseExpressionToFrontend,
  PersonalPageState,
  sanitiseInternalFieldName,
  Table,
  TableDataType,
  TableField,
  TablePeriodicUnits
} from "enada-common";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFieldIdsFromFlatExpression,
  recurseGetAllFieldsInConditionBlock
} from "../../../components/adminconfig/calculated/utils/parsing/parseCalculationToFrontend";
import FeatureList from "../../../components/featurelist/FeatureList";
import SearchableTabs from "../../../components/searchableTabs/SearchableTabs";
import TableViewModal, { RowOrder } from "../../../components/tableviewmodal/TableViewModal";
import TableViewsModal from "../../../components/tableviewsmodal/TableViewsModal";
import { SYSTEM_FIELD_ID_LIMIT } from "../../../config/authConfig";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectAllNonDeletedFields,
  selectAllNonDeletedSystemFields,
  selectAllNonDeletedTableRequiredSystemFields,
  selectAllNonDeletedTableSystemFields,
  selectAllNonDeletedTaskTableRequiredSystemFields
} from "../../../store/slices/fieldsSlice";
import {
  CalculatedFieldAlert,
  clearIndividualTable,
  removeTableCalculatedAlert,
  selectIndividualTable,
  selectTableCalculatedFieldAlerts,
  setIndividualTable,
  updateIndividualTableProperty,
  updateIndividualTableType,
  updateTableCalculatedAlert
} from "../../../store/slices/tablesSlice";
import { ConfigLayout, EdisonEnumSelect, EdisonTypography, RollupTable } from "enada-components";
import "./tableconfig.scss";
import { TablePersonalBehaviourRenderer } from "../../../components/adminconfig/table/TablePersonalPageBehaviourRenderer";
import { useCreateTableMutation, useGetTableQuery, useUpdateTableMutation } from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";
import { useGetFieldsQuery } from "services/api";

export enum SystemFieldType {
  None = 0,
  Name = 1,
  Description = 2,
  ShortDescription = 3,
  StartDate = 4,
  EndDate = 5,
  UniqueId = 6,
  ParentUniqueId = 7,
  Owner = 8,
  ReadOnly = 9,
  Owners = 10,
  PercentDone = 11,
  Note = 12,
  DueDate = 13,
  Completed = 14,
  AssignedResource = 15,
  RollUp = 16,
  ShowInTimeline = 17,
  IsMilestone = 18,
  ConstraintDate = 19,
  ConstraintType = 20,
  Critical = 21,
  Deadline = 22,
  Duration = 23,
  DurationUnit = 24,
  EarlyStartDate = 25,
  EarlyEndDate = 26,
  LateStartDate = 27,
  LateEndDate = 28,
  Effort = 29,
  EffortUnit = 30,
  EffortDriven = 31,
  Inactive = 32,
  ManuallyScheduled = 33,
  SchedulingMode = 34,
  TotalSlack = 35,
  SlackUnit = 36,
  WBS = 37,
  Baselines = 38,
  CalendarId = 39,
  ExtendedCalendarId = 40,
  IgnoreResourceCalendar = 41,
  Unscheduled = 42,
  RowId = 43,
  Created = 46,
  CreatedBy = 47,
  Modified = 48,
  ModifiedBy = 49,
  ProjectConstraintResolution = 50
}

export const scheduleTableSystemFields: TableField[] = [
  // { fieldId: SystemFieldType.Name },
  { fieldId: SystemFieldType.Description },
  { fieldId: SystemFieldType.StartDate },
  { fieldId: SystemFieldType.EndDate },
  { fieldId: SystemFieldType.UniqueId },
  { fieldId: SystemFieldType.ParentUniqueId },
  { fieldId: SystemFieldType.ReadOnly },
  { fieldId: SystemFieldType.PercentDone },
  // { fieldId: SystemFieldType.Note },
  { fieldId: SystemFieldType.AssignedResource },
  { fieldId: SystemFieldType.RollUp },
  { fieldId: SystemFieldType.ShowInTimeline },
  { fieldId: SystemFieldType.IsMilestone },
  { fieldId: SystemFieldType.ConstraintDate },
  { fieldId: SystemFieldType.ConstraintType },
  { fieldId: SystemFieldType.Critical },
  { fieldId: SystemFieldType.Deadline },
  { fieldId: SystemFieldType.Duration },
  { fieldId: SystemFieldType.DurationUnit },
  { fieldId: SystemFieldType.EarlyStartDate },
  { fieldId: SystemFieldType.EarlyEndDate },
  { fieldId: SystemFieldType.LateStartDate },
  { fieldId: SystemFieldType.LateEndDate },
  { fieldId: SystemFieldType.Effort },
  { fieldId: SystemFieldType.EffortUnit },
  { fieldId: SystemFieldType.EffortDriven },
  { fieldId: SystemFieldType.Inactive },
  { fieldId: SystemFieldType.ManuallyScheduled },
  { fieldId: SystemFieldType.SchedulingMode },
  { fieldId: SystemFieldType.TotalSlack },
  { fieldId: SystemFieldType.SlackUnit },
  { fieldId: SystemFieldType.WBS },
  { fieldId: SystemFieldType.Baselines },
  { fieldId: SystemFieldType.CalendarId },
  { fieldId: SystemFieldType.ExtendedCalendarId },
  { fieldId: SystemFieldType.IgnoreResourceCalendar },
  { fieldId: SystemFieldType.Unscheduled },
  { fieldId: SystemFieldType.RowId },
  { fieldId: SystemFieldType.ProjectConstraintResolution }
];
export const hiddenPersonalSystemFields = [
  // SystemFieldType.UniqueId,
  SystemFieldType.ParentUniqueId,
  SystemFieldType.ReadOnly,
  SystemFieldType.RollUp,
  SystemFieldType.ShowInTimeline,
  SystemFieldType.IsMilestone,
  SystemFieldType.ConstraintDate,
  SystemFieldType.ConstraintType,
  SystemFieldType.DurationUnit,
  SystemFieldType.EarlyStartDate,
  SystemFieldType.EarlyEndDate,
  SystemFieldType.LateStartDate,
  SystemFieldType.LateEndDate,
  SystemFieldType.EffortUnit,
  SystemFieldType.EffortDriven,
  SystemFieldType.Inactive,
  SystemFieldType.ManuallyScheduled,
  SystemFieldType.SchedulingMode,
  SystemFieldType.TotalSlack,
  SystemFieldType.SlackUnit,
  SystemFieldType.WBS,
  SystemFieldType.CalendarId,
  SystemFieldType.ExtendedCalendarId,
  SystemFieldType.IgnoreResourceCalendar,
  SystemFieldType.Unscheduled,
  SystemFieldType.Critical,
  SystemFieldType.Baselines,
  // SystemFieldType.AssignedResource,
  SystemFieldType.Owner,
  SystemFieldType.Owners,
  SystemFieldType.ProjectConstraintResolution
];
export const nonEditablePersonalSystemFields: SystemFieldType[] = [
  SystemFieldType.UniqueId,
  SystemFieldType.RowId,
  SystemFieldType.Created,
  SystemFieldType.CreatedBy,
  SystemFieldType.Modified,
  SystemFieldType.ModifiedBy
];
export const listTableSystemFields: TableField[] = [
  { fieldId: SystemFieldType.Name },
  { fieldId: SystemFieldType.UniqueId },
  { fieldId: SystemFieldType.Owner },
  { fieldId: SystemFieldType.DueDate }
];

const TableConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const isEdit = state?.id;

  const { data: currentTable } = useGetTableQuery(state.id, { skip: !isEdit });
  const [createTable] = useCreateTableMutation();
  const [updateTable] = useUpdateTableMutation();

  const [tableViewModalOpen, setTableViewModalOpen] = useState(false);
  const [tableViewsModalOpen, setTableViewsModalOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [viewId, setViewId] = useState(0);

  const individualTable = useAppSelector(selectIndividualTable);

  const {
    nonDeletedFields = [],
    nonDeletedSystemFields = [],
    nonDeletedTaskTableRequiredSystemFields = [],
    nonDeletedTableSystemFields = [],
    nonDeletedTableRequiredSystemFields = []
  } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      nonDeletedFields: selectAllNonDeletedFields(result),
      nonDeletedSystemFields: selectAllNonDeletedSystemFields(result),
      nonDeletedTaskTableRequiredSystemFields:
        selectAllNonDeletedTaskTableRequiredSystemFields(result),
      nonDeletedTableSystemFields: selectAllNonDeletedTableSystemFields(result),
      nonDeletedTableRequiredSystemFields: selectAllNonDeletedTableRequiredSystemFields(result)
    })
  });

  const calculatedFieldAlerts = useAppSelector(selectTableCalculatedFieldAlerts);

  const disabledItemList = individualTable.fields?.map((field: TableField) => {
    return { id: field.fieldId };
  });

  useEffect(() => {
    if (isEdit && currentTable) {
      dispatch(setIndividualTable(currentTable));
    }

    return () => {
      dispatch(clearIndividualTable());
    };
  }, [currentTable, dispatch, isEdit]);

  useEffect(() => {
    if (individualTable.dataType !== TableDataType.Periodic) return;
    dispatch(
      updateIndividualTableProperty({
        key: "periodicUnits",
        value:
          individualTable.dataType === TableDataType.Periodic
            ? individualTable.periodicUnits
              ? individualTable.periodicUnits
              : TablePeriodicUnits.Weeks
            : undefined
      })
    );
  }, [dispatch, individualTable.dataType, individualTable.periodicUnits]);

  const onItemClick = useCallback(
    (field: Field) => {
      dispatch(
        updateIndividualTableProperty({
          key: "fields",
          value: [
            ...(individualTable?.fields ?? []),
            {
              fieldId: field.id,
              tableId: individualTable?.id ?? 0,
              personal: false,
              personalPageState: PersonalPageState.Hidden,
              boardField: false,
              configuration: {}
            }
          ]
        })
      );
    },
    [dispatch, individualTable]
  );

  useEffect(() => {
    //Temp Fix to add Effective calendar id field to existing schedule tables that do not have it.
    if (individualTable.dataType !== TableDataType.Schedule) return;
    if (
      individualTable.fields?.some(
        tableField => tableField.fieldId === SystemFieldType.ExtendedCalendarId
      )
    )
      return;
    onItemClick({
      id: SystemFieldType.ExtendedCalendarId,
      dataType: FieldDataType.TextBox
    } as Field);
  }, [individualTable.dataType, individualTable.fields, onItemClick]);

  const onCreateTable = async (table: Partial<Table>) => {
    try {
      const response = await createTable(table).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newTableCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "tableCreationError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateTable = async (table: Partial<Table>) => {
    try {
      const response = await updateTable(table).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "tableUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "tableEditError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onSave = () => {
    const table = {
      ...individualTable,
      views: individualTable.views?.map((view: any) => {
        return {
          ...view,
          id: view.id && view.id < 0 ? 0 : view.id
        };
      })
    };

    if (isEdit) {
      onUpdateTable(table);
    } else {
      if (table?.dataType === TableDataType.Schedule) {
        createTable({
          ...table,
          configuration: {
            ...table.configuration,
            rowOrder:
              table?.configuration?.rowOrder ??
              table.fields?.map((field, index) => ({
                fieldId: field.id,
                order: index
              }))
          },
          fields:
            table?.fields?.map(field => ({
              ...field,
              personalPageState: field.personalPageState ?? PersonalPageState.Hidden
            })) ?? [],
          views: table.views?.map((v: any) => {
            return {
              ...v,
              fields: v.fields,
              taskViewType: "Gantt"
            };
          })
        });
      } else {
        onCreateTable(table);
      }
    }
  };

  const getRowOrder = (index: number, fieldId?: number): number => {
    if (!individualTable?.configuration?.rowOrder) return index;
    const rowOrder: RowOrder[] = individualTable.configuration.rowOrder as RowOrder[];
    return rowOrder.find(row => row.fieldId === fieldId)?.order ?? 0;
  };
  const onRowOrderChange = (activeItemId: string | number, overItemId?: string | number) => {
    let rowOrder: RowOrder[] = [
      ...((individualTable?.configuration?.rowOrder ??
        individualTable.fields?.map((field, index) => ({
          fieldId: field.fieldId,
          order: index
        }))) as RowOrder[])
    ];
    rowOrder = [...rowOrder];
    const indexToInsertAt = rowOrder.findIndex(row => row.fieldId === overItemId);
    const indexToRemoveAt = rowOrder.findIndex(row => row.fieldId === activeItemId);
    const [removed] = rowOrder.splice(indexToRemoveAt, 1);

    rowOrder.splice(indexToInsertAt, 0, removed);
    const updated = rowOrder.map((row, index) => ({ ...row, order: index }));
    dispatch(
      updateIndividualTableProperty({
        key: "configuration",
        value: { ...individualTable.configuration, rowOrder: updated }
      })
    );
  };

  const tabsContent = [
    {
      id: "fields",
      label: t("customFields"),
      children: (
        <FeatureList
          featureType={FieldDataType}
          getFeatures={fieldConfiguration}
          data={nonDeletedFields.filter(field => field.dataType !== FieldDataType.Location)}
          disabledItemList={disabledItemList}
          isDraggable={false}
          onItemClick={onItemClick}
        />
      )
    },
    {
      id: "systemfields",
      label: t("systemFields"),
      children: (
        <FeatureList
          featureType={FieldDataType}
          getFeatures={fieldConfiguration}
          data={nonDeletedTableSystemFields}
          disabledItemList={disabledItemList}
          isDraggable={false}
          onItemClick={onItemClick}
        />
      )
    }
  ];

  useEffect(() => {
    generateAlertsForCalculatedFields();
  }, [individualTable.fields?.length]);

  const generateAlertsForCalculatedFields = () => {
    const fieldsInTable = (individualTable?.fields ?? [])?.map(tableField =>
      nonDeletedFields.find(field => field.id === tableField.fieldId)
    );
    const calculatedFields = fieldsInTable.filter(
      field => field?.dataType === FieldDataType.Calculated
    );
    calculatedFields.forEach(field => {
      if (field === undefined) return;

      const fieldsInExpression = field.calculatedExpression
        ? [...recurseGetAllFieldsInConditionBlock(new Set<number>(), field?.calculatedExpression)]
        : getFieldIdsFromFlatExpression(parseExpressionToFrontend(field?.expression));
      const missingFieldsFromExpression = fieldsInExpression.filter(
        id => !individualTable.fields?.map(tableField => tableField.fieldId).includes(id)
      );
      const matchingAlert = calculatedFieldAlerts[field?.id as number];

      if (
        Boolean(matchingAlert) &&
        matchingAlert?.missingFields.toString() === missingFieldsFromExpression.toString()
      ) {
        return;
      }
      const newAlert: CalculatedFieldAlert = {
        missingFields: missingFieldsFromExpression,
        closed: false,
        calculatedField: field
      };

      missingFieldsFromExpression.length
        ? dispatch(
            updateTableCalculatedAlert({
              id: field?.id,
              alert: newAlert
            })
          )
        : dispatch(removeTableCalculatedAlert(field.id as number));
    });
  };

  const renderCalculatedAlert = (field: Field) => {
    const matchingAlert = calculatedFieldAlerts[field?.id as number];
    if (matchingAlert === undefined) return undefined;
    if (matchingAlert.closed) return undefined;
    const calculatedWarningString = `${field.displayName ?? field.name} ${t(
      "calculatedInTableWarning"
    )}`;
    const missingFieldNames = matchingAlert.missingFields
      .map(missingId => nonDeletedFields.find(field => field.id === missingId))
      .map(field => field?.displayName ?? field?.name)
      .toString();
    return (
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              dispatch(
                updateTableCalculatedAlert({
                  id: field.id,
                  alert: { ...matchingAlert, closed: true }
                })
              );
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <Stack>
          <EdisonTypography title={calculatedWarningString} variant="data" />
          <EdisonTypography title={missingFieldNames} variant="data" />
          <EdisonTypography title={t("calculatedInTableAdvise")} variant="data" />
        </Stack>
      </Alert>
    );
  };

  const isDisabled = (field: Field) => {
    if (
      individualTable.fields?.some(
        tableField => tableField.fieldId === field.id && tableField.category === Category.Default
      )
    ) {
      return true;
    }

    if ((field?.id ?? 0) > SYSTEM_FIELD_ID_LIMIT) return false;

    if (individualTable.dataType === TableDataType.Schedule) {
      return (
        field.id === SystemFieldType.RowId ||
        nonDeletedTaskTableRequiredSystemFields.some(systemField => systemField.id === field.id)
      );
    }
    return nonDeletedTableRequiredSystemFields.some(systemField => systemField.id === field.id);
  };

  return (
    <ConfigLayout
      t={t}
      title={isEdit ? t("editTable") : t("newTable")}
      saveDisabled={!individualTable?.displayName}
      onSaveCallback={onSave}
      onCancelCallback={() => navigate(-1)}
      topPanelContent={
        <>
          <TextField
            className="input"
            label={t("tableName")}
            variant="standard"
            required={true}
            value={individualTable?.displayName ?? ""}
            onChange={e => {
              dispatch(
                updateIndividualTableProperty({
                  key: "displayName",
                  value: e.target.value
                })
              );
              if (!isEdit) {
                dispatch(
                  updateIndividualTableProperty({
                    key: "name",
                    value: sanitiseInternalFieldName(e.target.value)
                  })
                );
              }
            }}
          />
          <TextField
            className="input"
            variant="standard"
            label={t("internalName")}
            value={individualTable.name ?? ""}
            disabled={true}
          />
          <TextField
            className="input"
            variant="standard"
            label={t("description")}
            value={individualTable?.description ?? ""}
            onChange={e =>
              dispatch(
                updateIndividualTableProperty({
                  key: "description",
                  value: e.target.value
                })
              )
            }
          />
          <EdisonEnumSelect
            disabled={!!isEdit}
            t={t}
            value={individualTable?.dataType ?? TableDataType.List}
            enumDef={TableDataType}
            label={t("type")}
            className="input"
            onChange={value => dispatch(updateIndividualTableType(value as TableDataType))}
          />
        </>
      }
    >
      <Paper className="table-config-root">
        <Stack spacing={1}>
          <Stack
            direction="row"
            className="title-container"
            alignItems="center"
            sx={{ height: "25px" }}
          >
            <Stack direction="row">
              <EdisonTypography
                sx={{ flexShrink: 0 }}
                variant="h2"
                title={t("tableConfiguration")}
              />
              <IconButton
                size="small"
                color="inherit"
                className={`alert ${alertOpen ? "open" : "closed"}`}
                onClick={() => {
                  setAlertOpen(prev => !prev);
                }}
              >
                <InfoOutlinedIcon fontSize="small" color="primary" />
              </IconButton>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                data-testid="view-settings-button"
                variant="contained"
                disabled={!individualTable?.displayName}
                startIcon={<SettingsIcon />}
                sx={{ flexShrink: 0 }}
                onClick={() => setTableViewsModalOpen(true)}
              >
                {t("viewSettings")}
              </Button>
            </Stack>

            <TableViewsModal
              defaultTableViews={(individualTable?.views ?? []).filter(
                view => view.category === Category.Default
              )}
              isOpen={tableViewsModalOpen}
              toggleModal={setTableViewsModalOpen}
              table={individualTable}
              onDelete={function (ids: number[]): void {
                const views = individualTable.views?.filter(view => {
                  return view.id && !ids.includes(view.id);
                });
                dispatch(
                  updateIndividualTableProperty({
                    key: "views",
                    value: views
                  })
                );
              }}
              onAdd={function (): void {
                setViewId(0);
                setTableViewsModalOpen(false);
                setTableViewModalOpen(true);
              }}
              onEdit={function (id: number): void {
                setViewId(id);
                setTableViewsModalOpen(false);
                setTableViewModalOpen(true);
              }}
            />

            <TableViewModal
              isDefault={Boolean(
                individualTable.views?.some(
                  view => view.id === viewId && view.category === Category.Default
                )
              )}
              isOpen={tableViewModalOpen}
              onClose={() => {
                setTableViewsModalOpen(true);
                setTableViewModalOpen(false);
              }}
              selectedView={individualTable?.views?.find(view => view.id === viewId)}
              tableFields={individualTable.fields?.filter(
                field => field.fieldId !== SystemFieldType.Critical
              )} // filter out critical field https://dev.azure.com/edison365devops/edison365%20ENADA/_workitems/edit/7150
              onSave={view => {
                if (!view.tableId) {
                  view.tableId = individualTable?.id ?? 0;
                }

                if (view.id === undefined && individualTable?.views) {
                  view.name = sanitiseInternalFieldName(view.name);

                  const minId = Math.min(
                    ...individualTable.views.map(item => (item.id ? item.id : 0))
                  );
                  // New views have an id = 0, unsaved views have an id < 0, saved views have an id > 0
                  view.id = minId > 0 ? -1 : minId - 1;
                }

                let views = individualTable.views ? individualTable.views : [];

                views = views.filter(v => v.id !== view.id);
                views.push(view);
                dispatch(
                  updateIndividualTableProperty({
                    key: "views",
                    value: views
                  })
                );

                setTableViewsModalOpen(true);
                setTableViewModalOpen(false);
              }}
            />
          </Stack>
          {alertOpen && (
            <Stack direction="column">
              <Box sx={{ margin: "20px 0px" }}>
                <Alert severity="info">
                  <EdisonTypography
                    sx={{ whiteSpace: "normal" }}
                    title={t("tableConfigMessage")}
                    variant="data2"
                  />
                </Alert>
              </Box>
            </Stack>
          )}

          {individualTable.dataType === TableDataType.Periodic && (
            <Stack spacing={2}>
              <EdisonEnumSelect
                disabled={!!isEdit}
                t={t}
                className="period-select"
                enumDef={TablePeriodicUnits}
                value={individualTable.periodicUnits ?? TablePeriodicUnits.Weeks}
                label={t("period")}
                onChange={value =>
                  dispatch(
                    updateIndividualTableProperty({
                      key: "periodicUnits",
                      value: value
                    })
                  )
                }
              />
              <Stack direction="row" spacing={2} className="currency-container">
                <EdisonTypography title={t("dataType")} variant="h2" />
                <FormControl>
                  <RadioGroup
                    row
                    value={individualTable?.configuration?.dataType ?? "number"}
                    onChange={(_, value) =>
                      dispatch(
                        updateIndividualTableProperty({
                          key: "configuration",
                          value: {
                            ...individualTable.configuration,
                            dataType: value
                          }
                        })
                      )
                    }
                  >
                    <FormControlLabel value="number" label={t("number")} control={<Radio />} />
                    <FormControlLabel value="currency" label={t("currency")} control={<Radio />} />
                  </RadioGroup>
                </FormControl>
                {individualTable?.configuration?.dataType === "currency" && (
                  <FormControl>
                    <InputLabel id="currency-select">{t("Currency")}</InputLabel>
                    <Select
                      size="small"
                      labelId="currency-select"
                      label={t("currency")}
                      className="currency-select"
                      style={{ width: "20em" }}
                      value={individualTable?.configuration?.currency ?? "AZN"}
                      onChange={e =>
                        dispatch(
                          updateIndividualTableProperty({
                            key: "configuration",
                            value: {
                              ...individualTable.configuration,
                              currency: e.target.value
                            }
                          })
                        )
                      }
                    >
                      {Object.values(currencies).map((currency, index) => (
                        <MenuItem key={index} value={currency.currencySymbol}>
                          {currency.currencySymbol}- {currency.currencyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </Stack>
          )}
          <Stack direction="row" spacing={1}>
            <Stack className="field-selector">
              <Stack className="fields-list">
                <SearchableTabs
                  scrollable={true}
                  tabs={tabsContent}
                  tabsToSearch={["fields", "systemfields"]}
                />
              </Stack>
            </Stack>
            <Stack className="selected-fields">
              <RollupTable
                fullScreen={false}
                setFullScreen={() => {
                  /*nothing*/
                }}
                order="asc"
                renderEmptyTable={true}
                editAction={() => {
                  return;
                }}
                addAction={() => {
                  return;
                }}
                deleteAction={() => {
                  return;
                }}
                columns={[
                  { name: "id", displayName: t("id") },
                  { name: "displayName", displayName: t("fieldName") },
                  { name: "dataType", displayName: t("fieldType") },
                  {
                    name: "personalPageBehaviour",
                    displayName: t("personalPageBehaviour"),
                    componentRenderer: TablePersonalBehaviourRenderer
                  }
                ]}
                rows={
                  nonDeletedFields
                    .concat(nonDeletedSystemFields)
                    .filter(field =>
                      individualTable.fields?.find(
                        tableField =>
                          tableField.fieldId === field.id &&
                          tableField.fieldId !== SystemFieldType.Critical // hide critical field https://dev.azure.com/edison365devops/edison365%20ENADA/_workitems/edit/7150
                      )
                    )
                    .map((field, index) => ({
                      ...field,
                      row_alert_e365: renderCalculatedAlert(field),
                      order: getRowOrder(index, field.id),
                      disabled_e365: isDisabled(field),
                      personalPageState: individualTable?.fields?.find(
                        tableField => tableField.fieldId === field.id
                      )?.personalPageState
                      // disabled_e365: (field.id ?? 0) < SYSTEM_FIELD_ID_LIMIT,
                    }))
                    .sort((a, b) => a.order - b.order) as any
                }
                deletedRows={[]}
                recycleAction={() => {
                  return;
                }}
                restoreAction={() => {
                  return;
                }}
                t={t}
                hideTopSection
                rowActionsName="Actions"
                rowActions={[
                  {
                    onClick: row => {
                      if (row.id === SystemFieldType.PercentDone) {
                        return;
                      }
                      dispatch(
                        updateIndividualTableProperty({
                          key: "fields",
                          value: [
                            ...(individualTable?.fields ?? []).filter(
                              (tf: TableField) => tf.fieldId !== row.id
                            )
                          ]
                        })
                      );
                    },
                    getIcon: row => (
                      <DeleteOutlineOutlinedIcon
                        className={`pointer`}
                        fontSize="small"
                        color={row.id === SystemFieldType.PercentDone ? "disabled" : "error"}
                      />
                    )
                  }
                ]}
                onRowOrderChange={onRowOrderChange}
                orderBy="order"
              />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </ConfigLayout>
  );
};

export default TableConfig;
