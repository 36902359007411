import { CalculatedExpressionUnit } from "enada-common";
import { UnitLocation } from "../../../../store/slices/calculatedFieldSlice";

export type ExpressionUnitType = "entity" | "operator" | "number" | "text";

export type Operator = "+" | "-" | "*" | "/" | "(" | ")" | "<" | ">" | "=" | "!=" | "&&" | "||";

export type BlockOperators = "(" | ")";

export interface Operand {
  id: number;
  name: string;
  displayName: string;
}

export interface CalculatedMoveAction {
  unit: CalculatedExpressionUnit;
  sourceLocation?: UnitLocation;
}

export type CalculatedFunctions = "BASIC" | "IF";
export const CalculatedFunctionList: CalculatedFunctions[] = ["IF", "BASIC"];
