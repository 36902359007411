import { FieldValue } from "./FieldValue.model";
export interface FieldValueOperation {
  operationType: FieldValueOperationType;
  fieldValues: FieldValue[];
}

export enum FieldValueOperationType {
  addOrUpdate = "AddOrUpdate",
  delete = "Delete"
}
