import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useMemo } from "react";
import EdisonPeopleGroup from "../../peoplegroup/EdisonPeopleGroup";
import { PermissionType, useLocalisation } from "enada-common";

export interface EventDialogProps {
  open: boolean;
  close: () => void;
  activeEvent?: any;
  assignments?: any[];
  dialogTitleDisplayName?: string;
  recordDisplayName?: string;
  taskDisplayName?: string;
  durationDisplayName?: string;
  ownersDisplayName?: string;
  fromDisplayName?: string;
  toDisplayName?: string;
  recordOwners?: any[];
}

const EventDialog: FC<EventDialogProps> = ({
  open,
  close,
  activeEvent,
  assignments,
  dialogTitleDisplayName,
  recordDisplayName,
  taskDisplayName,
  durationDisplayName,
  ownersDisplayName,
  fromDisplayName,
  toDisplayName,
  recordOwners
}) => {
  const { formatDate } = useLocalisation();

  const owners = useMemo(() => {
    if (!activeEvent) {
      return [];
    }
    return (recordOwners ?? [])
      .filter((owner: any) => owner.id && owner.entityId && owner.event === activeEvent.id)
      .map((owner: any) => {
        return {
          id: owner.id,
          entityId: owner.entityId,
          type: PermissionType.User
        };
      });
  }, [recordOwners, activeEvent]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          backgroundColor: "#DCE8FF",
          minWidth: 250
        }}
      >
        {dialogTitleDisplayName ?? "Information"}
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="event-dialog-content"
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "20px"
        }}
      >
        <div
          style={{
            paddingTop: "10px"
          }}
        >
          <hr
            style={{
              margin: "0 -20px 20px -20px",
              backgroundColor: "#E0E0E0",
              height: "1px",
              border: "none"
            }}
          />
          <TextField
            id="record-name"
            fullWidth
            label={recordDisplayName ?? "Record Name"}
            variant="outlined"
            value={activeEvent?.recordName ?? ""}
            InputProps={{
              readOnly: true
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            columnGap: 12,
            alignItems: "center"
          }}
        >
          <Typography variant="body1">{ownersDisplayName ?? "Owner(s): "}</Typography>

          <EdisonPeopleGroup value={owners as any[]} maxAvatars={3} avatarSize="large" />
        </div>

        <div>
          <hr
            style={{
              margin: "0 -20px 20px -20px",
              backgroundColor: "#E0E0E0",
              height: "1px",
              border: "none"
            }}
          />
          <TextField
            id="task-name"
            fullWidth
            label={taskDisplayName ?? "Task Name"}
            variant="outlined"
            value={activeEvent?.name ?? ""}
            InputProps={{
              readOnly: true
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            columnGap: 12,
            alignItems: "center"
          }}
        >
          <Typography variant="body1">{durationDisplayName ?? "Duration"}</Typography>

          <TextField
            id="duration"
            variant="outlined"
            style={{ flexShrink: 3 }}
            value={`${(activeEvent?.duration ?? 0).toFixed(2)} ${
              activeEvent?.durationUnit ?? "day"
            }`}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            id="start-day"
            label={fromDisplayName ?? "From"}
            variant="outlined"
            value={formatDate(activeEvent?.startDate)}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            id="end-day"
            label={toDisplayName ?? "To"}
            variant="outlined"
            value={formatDate(activeEvent?.endDate)}
            InputProps={{
              readOnly: true
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventDialog;
