import { RecordType } from "enada-common";
import { getApiForRecordPermissions } from "services/APIService";
import { baseAPI } from "services/baseAPI";
import { RecordAuth } from "store/slices/recordSlice";

export const recordAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getRecordAccessToken: builder.query<RecordAuth, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecordPermissions(recordType)}/${recordId}/access-token`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: RecordAuth }) => response.data,
      providesTags: ["RecordAccessToken"]
    })
  })
});
