export const systemFieldsMap = [
  {
    bryntum: "name",
    bryntumColumn: "name",
    edison: "name"
  },
  {
    bryntum: "note",
    bryntumColumn: "note",
    edison: "note"
  },
  {
    bryntum: "rollup",
    bryntumColumn: "rollup",
    edison: "rollup"
  },
  {
    bryntum: "constraintDate",
    bryntumColumn: "constraintDate",
    edison: "constraintDate"
  },
  {
    bryntum: "constraintType",
    bryntumColumn: "constrainttype",
    edison: "constraintType"
  },
  {
    bryntum: "critical",
    edison: "critical"
  },
  {
    bryntum: "deadlineDate",
    bryntumColumn: "deadlinedate",
    edison: "deadline"
  },
  {
    bryntum: "duration",
    bryntumColumn: "duration",
    edison: "duration"
  },
  {
    bryntum: "durationUnit",
    edison: "durationUnit"
  },
  {
    bryntum: "startDate",
    bryntumColumn: "startdate",
    edison: "startDate"
  },
  {
    bryntum: "endDate",
    bryntumColumn: "enddate",
    edison: "endDate"
  },
  {
    bryntum: "earlyStartDate",
    edison: "earlyStartDate"
  },
  {
    bryntum: "earlyEndDate",
    edison: "earlyEndDate"
  },
  {
    bryntum: "lateStartDate",
    edison: "lateStartDate"
  },
  {
    bryntum: "lateEndDate",
    edison: "lateEndDate"
  },
  {
    bryntum: "effort",
    bryntumColumn: "effort",
    edison: "effort"
  },
  {
    bryntum: "effortUnit",
    edison: "effortUnit"
  },
  {
    bryntum: "effortDriven",
    edison: "effortDriven"
  },
  {
    bryntum: "inactive",
    bryntumColumn: "inactive",
    edison: "inactive"
  },
  {
    bryntum: "manuallyScheduled",
    bryntumColumn: "manuallyscheduled",
    edison: "manuallyScheduled"
  },
  {
    bryntum: "percentDone",
    bryntumColumn: "percentdone",
    edison: "percentDone"
  },
  {
    bryntum: "schedulingMode",
    bryntumColumn: "schedulingmodecolumn",
    edison: "schedulingMode"
  },
  {
    bryntum: "totalSlack",
    bryntumColumn: "totalslack",
    edison: "totalSlack"
  },
  {
    bryntum: "slackUnit",
    edison: "slackUnit"
  },
  {
    bryntum: "wbsCode",
    bryntumColumn: "wbs",
    edison: "WBS"
  },
  {
    bryntum: "successors",
    bryntumColumn: "successor",
    edison: "successors"
  },
  {
    bryntum: "predecessors",
    bryntumColumn: "predecessor",
    edison: "predecessors"
  }
];
