export enum NotificationLevel {
  Information = "info",
  Success = "success",
  Warning = "warning",
  Error = "error"
}

export interface Notification {
  title: string;
  message: string;
  level: NotificationLevel;
}
