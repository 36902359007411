import {
  BaseUserFieldProps,
  fieldConfiguration,
  FieldDataType,
  useLocalisation
} from "enada-common";
import { TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 } from "uuid";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";

export interface UserDatePickerFieldProps extends BaseUserFieldProps {
  invalidDateMessage?: string;
  isIconOn: boolean;
}

const UserDateTimePicker: FC<UserDatePickerFieldProps> = ({
  label,
  disabled,
  readOnly,
  value,
  onChange,
  isInTable,
  isIconOn,
  setReadOnly,
  autoFocus,
  required,
  dataTestidValue,
  outlineTextField = false
}) => {
  const onBlurId = v4();
  const [internalState, setInternalState] = React.useState<Date | null>(value || null);
  const [readyToClose, setReadyToClose] = useState(false);

  const { formatDate, locale } = useLocalisation();

  useEffect(() => {
    if (!readyToClose) return;

    setReadOnly && setReadOnly(true);
    setReadyToClose(false);
    if (!isInTable) return;
    if (!onChange) return;
    onChange(internalState ? new Date(internalState) : internalState);
  }, [readyToClose]);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  return outlineTextField || !readOnly ? (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DesktopDateTimePicker
        data-testid={dataTestidValue}
        label={!isInTable && label}
        onChange={changedValue => {
          setInternalState(changedValue);
          if (!onChange) return;
          if (isInTable) return;
          onChange(changedValue ? new Date(changedValue) : changedValue);
        }}
        value={internalState}
        disabled={disabled || readOnly}
        onClose={() => {
          setReadyToClose(true);
        }}
        renderInput={(params: any) => {
          return (
            <TextField
              required={required}
              onBlur={e => {
                if (e.relatedTarget?.parentElement?.id === onBlurId) return;
                if (!setReadOnly) return;
                setReadOnly(true);
                if (!isInTable) return;
                if (!onChange) return;
                onChange(internalState);
              }}
              variant={outlineTextField ? "outlined" : "standard"}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps?.endAdornment,
                sx: { borderRadius: outlineTextField ? "8px" : "0px" }
              }}
              autoFocus={autoFocus}
              onFocus={
                isInTable
                  ? event => {
                      event.target.select();
                    }
                  : undefined
              }
              disabled={readOnly && (disabled || outlineTextField)}
            />
          );
        }}
      />
    </LocalizationProvider>
  ) : (
    <EdisonFieldIcon
      title={label}
      subtitle={internalState ? formatDate(internalState, "LL LT") : ""}
      icon={fieldConfiguration(FieldDataType.DateTime).icon}
      color={fieldConfiguration(FieldDataType.DateTime).color}
      isIconOn={isIconOn}
      isTableModeOn={!!isInTable}
      required={required}
      error={required && Boolean(value)}
    />
  );
};

export default UserDateTimePicker;
export { UserDateTimePicker };
