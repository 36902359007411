import { Box, Stack } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { EdisonPeoplePickerFieldProps, PersonaEntity, PermissionType } from "enada-common";
import "./edisonpeoplepickerfield.scss";
import EdisonTypography from "../typography/EdisonTypography";
import { PeoplePicker } from "@microsoft/mgt-react";
import BoldTranslation from "../boldtranslation/BoldTranslation";

const EdisonPeoplePickerField: FC<EdisonPeoplePickerFieldProps> = ({
  label,
  multiple,
  maxAvatars = 10,
  value,
  onChange,
  disabled,
  disableGroups,
  required,
  filteredPeople,
  selectedPeople, // does nothing, remove when we can
  helperText
}) => {
  //This is a temporary hack until microsoft fix their mgt-react people picker
  const [tempHack, setTempHack] = useState(false);

  const handleSelectionChanged = (e: any) => {
    // usersFromAnotherTenant are users that aren't from the tenant the user is authenticated against

    const mgtData = e.detail.map((p: any) => {
      return {
        entityId: p.id,
        type: Object.prototype.hasOwnProperty.call(p, "groupTypes")
          ? PermissionType.Group
          : PermissionType.User
      } as PersonaEntity;
    });

    onChange(mgtData);
  };

  const selectedUsers = useMemo(
    () =>
      value
        ?.filter(p => p.entityId !== undefined && p.type === PermissionType.User)
        .map(persona => {
          return persona.entityId;
        }),
    [value]
  );

  const selectedGroups = useMemo(
    () =>
      value
        ?.filter(p => p.entityId !== undefined && p.type === PermissionType.Group)
        .map(persona => {
          return persona.entityId;
        }),
    [value]
  );

  const filteredUserIds = useMemo(() => {
    if (filteredPeople && filteredPeople.length > 0) {
      return filteredPeople
        ?.filter(p => p.id !== undefined)
        .map(persona => {
          if (persona.id === undefined) {
            return "";
            // continue;
          }
          return persona.id;
        });
    } else {
      return [];
    }
  }, [filteredPeople]);

  return (
    <Box className="edison-people-picker-root" data-testid="people-picker">
      <EdisonTypography title={required ? label + " *" : label} variant={"fieldtitle"} />

      <Stack width="full" spacing={1}>
        <PeoplePicker
          data-testid="people-picker"
          className={`${disabled ? "disabled" : ""}`}
          disabled={disabled}
          type={disableGroups ? "person" : "any"}
          userType={"any"}
          selectionChanged={!disabled ? handleSelectionChanged : undefined}
          selectionMode={multiple ? "multiple" : "single"}
          showMax={maxAvatars}
          // people={filteredPeople}
          userIds={filteredUserIds}
          defaultSelectedUserIds={selectedUsers ?? []}
          defaultSelectedGroupIds={selectedGroups ?? []}
        />

        {helperText && (
          <BoldTranslation i18nKey={helperText.i18nKey} fontWeight={helperText.fontWeight} />
        )}
      </Stack>
    </Box>
  );
};

export default EdisonPeoplePickerField;
export { EdisonPeoplePickerField };
