import { useCallback, useEffect, useMemo, useState } from "react";
import { inputSelectInstance, inputSelectTenant } from "../../../store/slices/userSlice";
import { apiConfig, loginRequest } from "../../../config/authConfig";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  CoAuthUser,
  addViewer,
  addViewers,
  removeViewer,
  resetCoAuth,
  selectRecordAuth,
  selectUserAccessRoles,
  setCanEditRecord,
  setRecordEditor,
  setRecordInfo,
  setRecordViewValues
} from "../../../store/slices/recordSlice";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { NotificationLevel, RecordAccessRole, RecordTableRow, RecordType } from "enada-common";
import { selectRegion } from "../../../store/slices/configSlice";
import { addRowsToUpdate, setRecordTableRowValues } from "../../../store/slices/recordTableSlice";
import {
  useGetUserQuery,
  useLazyGetRecordQuery,
  useLazyGetRecordViewTableRowValuesQuery,
  useLazyGetRecordViewValuesQuery
} from "services/api";
import { sleep } from "utils/sleep";
import { useNavigate, useParams } from "react-router-dom";
import { hasAccessRole } from "utils/hasAccessRole";
import { useMsal } from "@azure/msal-react";

export const useRecordSignalRConnection = (scope: RecordType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [connection, setConnection] = useState<HubConnection>();

  const { data: user } = useGetUserQuery();

  const [getRecord] = useLazyGetRecordQuery();
  const [getRecordViewValues] = useLazyGetRecordViewValuesQuery();
  const [getRecordViewTableRowValues] = useLazyGetRecordViewTableRowValuesQuery();

  const accessRoles = useAppSelector(selectUserAccessRoles);
  const tenant = useAppSelector(inputSelectTenant);
  const instance = useAppSelector(inputSelectInstance);
  const region = useAppSelector(selectRegion);
  const recordAuth = useAppSelector(selectRecordAuth);

  const { instance: msalInstance } = useMsal();
  const currentAccount = msalInstance.getAllAccounts();
  const requestedScope = loginRequest;
  const request = useMemo(
    () => ({
      ...requestedScope,
      account: currentAccount[0]
    }),
    [currentAccount, requestedScope]
  );

  const connectToSignalR = useCallback(async () => {
    const newUrl = `${apiConfig.recordsCoauthoringEndpoint}?edison365-tenant=${tenant}&edison365-instance=${instance}&edison365-sessionid=frontend`;
    const newConnection = new HubConnectionBuilder()
      .withUrl(newUrl, {
        accessTokenFactory: async () => {
          const tokenResponse = await msalInstance.acquireTokenSilent(request);
          return tokenResponse.accessToken;
        },
        headers: {
          "edison365-tenant": tenant,
          "edison365-instance": instance,
          "edison365-region": region,
          "edison365-sessionid": "frontend"
        }
      })
      .withServerTimeout(60000)
      // Warning: Keep alive interval must match the the server setting do not change.
      .withKeepAliveInterval(30000)
      .withStatefulReconnect()
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [instance, msalInstance, region, request, tenant]);

  const setupEditorCallbacks = useCallback(async () => {
    if (!connection) return;

    dispatch(setCanEditRecord(true));

    connection.on("ReceiveCurrentRecordState", (message: any) => {
      if (message.connectedUsers.length > 0) {
        const connectedUsers: Array<CoAuthUser> = message.connectedUsers;
        const activeEditor = connectedUsers.find(
          (user: CoAuthUser) => user.isEditor && user.isConnected && user.isTokenValid
        );
        const activeViewers = connectedUsers.filter(
          (user: CoAuthUser) => user.isConnected && !user.isEditor && user.isTokenValid
        );

        const currentUser: CoAuthUser | undefined = {
          oid: user?.id ?? "",
          name: user?.displayName ?? "",
          recordId: message,
          isConnected: true,
          isEditor: true,
          isTokenValid: true
        };
        const activeViewers1 = [...activeViewers.reverse(), currentUser].filter(
          viewer => viewer?.oid !== activeEditor?.oid
        );

        if (activeEditor) dispatch(setRecordEditor(activeEditor));
        dispatch(addViewers(activeViewers1));
      }
    });

    connection.on("GiveRecordControl", async (message: any) => {
      if (!user) return;

      dispatch(
        setRecordEditor({
          oid: user?.id ?? "",
          name: user?.displayName ?? "",
          recordId: message,
          isConnected: true,
          isEditor: true,
          isTokenValid: true
        })
      );

      dispatch(
        setCurrentNotification({
          title: `youAreNow${scope}Editor`,
          message: "",
          level: NotificationLevel.Success
        })
      );
      if (recordAuth) {
        dispatch(
          setCurrentNotification({
            title: `youAreNow${scope}Editor`,
            message: "",
            level: NotificationLevel.Success
          })
        );

        const viewValuesRespone = await getRecordViewValues({
          recordId: recordAuth.details.RecordId,
          recordType: scope,
          viewId: recordAuth.details.Views[0]
        }).unwrap();
        if (viewValuesRespone) dispatch(setRecordViewValues(viewValuesRespone));

        const tableRowsResponse = await getRecordViewTableRowValues({
          recordId: recordAuth.details.RecordId,
          recordType: scope,
          viewId: recordAuth.details.Views[0]
        }).unwrap();
        if (tableRowsResponse)
          dispatch(setRecordTableRowValues({ rows: tableRowsResponse, isRefetching: false }));
      }
    });

    connection.on("ReceiveNewRecordAuthor", (author: any) => {
      if (author.isEditor) {
        dispatch(setRecordEditor(author));

        dispatch(
          setCurrentNotification({
            title: `youAreNow${scope}Editor`,
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
      dispatch(addViewer(author));
    });

    connection.on("ReceiveNewRecordEditor", (editor: any) => {
      if (editor !== null) {
        dispatch(setRecordEditor(editor));

        if (editor.oid === user?.id) {
          dispatch(
            setCurrentNotification({
              title: `youAreNow${scope}Editor`,
              message: "",
              level: NotificationLevel.Success
            })
          );
        }
      }
    });

    connection.on("ReceiveRecordAuthorsWhoLeft", (authors: any) => {
      const author = authors[0]; //We know only one user comes at a time

      dispatch(removeViewer(author));
    });

    connection.on("TableRowsApproved", (approvedRows: RecordTableRow[]) => {
      dispatch(addRowsToUpdate(approvedRows));
    });
  }, [
    connection,
    user,
    dispatch,
    recordAuth,
    scope,
    getRecordViewValues,
    getRecordViewTableRowValues
  ]);

  const setupReaderCallbacks = useCallback(async () => {
    if (!connection) return;
    connection.on("StepChange", async () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "manualStageChange"
        })
      );
      await sleep(500);
    });

    connection.on("ContinueWorkflow", async () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: scope + "ProgressedToNextStage"
        })
      );

      const response = await getRecord({
        recordId: parseInt(params.recordId ?? "") ?? 0,
        recordType: scope
      });

      if (response.data) {
        dispatch(setRecordInfo(response.data));
      }
    });

    connection.on("RollBackWorkflow", () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "projectRolledBackToPreviousStage"
        })
      );
    });

    connection.on("ProjectRecycled", () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "projectDeleted"
        })
      );
      navigate("../../");
    });
  }, [connection, dispatch, navigate]);

  const startConnection = useCallback(
    async (canEdit: boolean) => {
      if (!recordAuth || !connection) return;
      connection
        .start()
        .then(async () => {
          const coAuthoringAccessDto = {
            recordId: params.recordId,
            recordType: scope
          };
          if (canEdit) {
            dispatch(resetCoAuth());
            try {
              connection.invoke("StartRecordEdit", coAuthoringAccessDto);
            } catch (e) {
              console.log("error", e);
            }
          } else {
            try {
              connection.invoke("StartRecordReadOnly", coAuthoringAccessDto);
            } catch (e) {
              console.log("error", e);
            }
          }
        })
        .catch((e: any) => {
          console.log("Connection failed: ", e);
        });
    },
    [dispatch, connection, recordAuth, params.recordId, scope]
  );

  const closeConnection = useCallback(() => {
    if (connection) {
      connection.invoke("CleanUp").then(() => connection.stop());
    }
  }, [connection]);

  useEffect(() => {
    if (!connection) connectToSignalR();
  }, [connectToSignalR, connection]);

  useEffect(() => {
    if (!user) return;
    if (connection) {
      const canConnect = !!accessRoles && hasAccessRole(RecordAccessRole.Read, accessRoles);
      const canEdit =
        canConnect && !!accessRoles && hasAccessRole(RecordAccessRole.Update, accessRoles);

      if (canConnect) {
        if (canEdit) {
          setupEditorCallbacks();
        }
        setupReaderCallbacks();
        startConnection(canEdit);
      }
    }
    return () => {
      if (!connection) return;
      closeConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, user]);

  return { connection };
};
