import { Link, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { BaseUserFieldProps, fieldConfiguration, FieldDataType } from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";

export interface UserEmailFieldProps extends BaseUserFieldProps {
  isIconOn: boolean;
  regex?: string | null;
  setReadOnly: (value: boolean) => void;
}

const UserEmailField: FC<UserEmailFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  regex,
  setReadOnly,
  autoFocus,
  required
}) => {
  const [internalState, setInternalState] = useState<string>(value || "");
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  const handleChange = (value: string) => {
    setInternalState(value);
    let valid = true;
    if (regex) {
      valid = new RegExp(regex).test(value);
      setIsValid(valid);
    }
    if (isInTable) return;
    if (!onChange) return;
    onChange(value, valid);
  };

  return (
    <div className="user-email-field-root">
      {!readOnly ? (
        <TextField
          sx={{ fontSize: 14, fontWeight: 400, letterSpacing: 0.17 }}
          required={required}
          onBlur={() => {
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState, isValid);
          }}
          variant="standard"
          multiline={isInTable}
          type="email"
          label={!isInTable && label}
          fullWidth
          autoFocus={autoFocus}
          onFocus={
            isInTable
              ? event => {
                  event.target.select();
                }
              : undefined
          }
          value={internalState}
          onChange={e => {
            handleChange(e.target.value);
          }}
          helperText=""
          size="small"
          error={error ?? !isValid}
        />
      ) : (
        <EdisonFieldIcon
          title={label}
          subtitle={value}
          icon={fieldConfiguration(FieldDataType.Email).icon}
          color={fieldConfiguration(FieldDataType.Email).color}
          isIconOn={isIconOn}
          required={required}
          error={required && !value}
          isTableModeOn={!!isInTable}
        >
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 400,
              letterSpacing: 0.17,
              overflowWrap: isInTable ? "anywhere" : "normal",
              textWrap: isInTable ? "balance" : "nowrap"
            }}
            href={"mailto:" + value}
          >
            {value}
          </Link>
        </EdisonFieldIcon>
      )}
    </div>
  );
};

export default UserEmailField;
export { UserEmailField };
