import { createSelector, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  reducerStatus,
  Form,
  Table,
  WorkflowStageViewFieldState,
  ItemDNDType,
  NonSelectedItem,
  COLUMN,
  ROW,
  TAB,
  splitPath,
  moveDNDObjectInArray,
  RecordType
} from "enada-common";

export interface livepreviewState {
  formData: ItemDNDType[];
  formName: string;
  formInternalName: string;
  formDescription: string;
  formScope: string;
  isEdit: boolean;
  isVisibilityMode: boolean;
  currentTab: string;
  usedComponents: string[];
  tablesList: Table[];
  currentForm: Form | null | Partial<Form>;
  status: reducerStatus;
  readOnly: boolean;
  fromWorkflow: boolean;
  tableIds: number[];
}

const getDefaultColumn = (id: string): ItemDNDType => ({
  type: COLUMN,
  title: null,
  id: id,
  children: []
});

const getDefaultRow = (id: string): ItemDNDType => ({
  type: ROW,
  title: null,
  id: id,
  children: [getDefaultColumn(nanoid())]
});

const getDefaultTab = (id: string): ItemDNDType => ({
  type: TAB,
  title: null,
  id: id,
  children: [getDefaultRow(nanoid())]
});

const initialState: livepreviewState = {
  formName: "",
  formInternalName: "",
  formDescription: "",
  formScope: RecordType.Projects,
  formData: [
    {
      type: TAB,
      id: "tab0",
      title: null,
      children: [getDefaultRow(nanoid())]
    }
  ],
  usedComponents: [],
  currentTab: "tab0",
  isEdit: false,
  isVisibilityMode: false,
  tablesList: [],
  currentForm: null,
  status: "idle",
  readOnly: false,
  fromWorkflow: false,
  tableIds: []
};

export const formDesignerSlice: any = createSlice({
  name: "formdesigner",
  initialState,
  reducers: {
    resetLiveForm: state => {
      state.formData = initialState.formData;
      state.formInternalName = initialState.formInternalName;
      state.formName = initialState.formName;
      state.formDescription = initialState.formDescription;
      state.formScope = initialState.formScope;
      state.isEdit = initialState.isEdit;
      state.isVisibilityMode = initialState.isVisibilityMode;
      state.currentTab = initialState.currentTab;
      state.usedComponents = initialState.usedComponents;
      state.formScope = initialState.formScope;
      state.currentForm = initialState.currentForm;
      state.tablesList = initialState.tablesList;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },

    updateIndividualFormProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Form }>
    ) => {
      state.currentForm = {
        ...state.currentForm,
        [action.payload.key]: action.payload.value
      };
    },
    setFormName: (state, action: PayloadAction<string>) => {
      state.formName = action.payload;
    },
    setInternalFormName: (state, action: PayloadAction<string>) => {
      state.formInternalName = action.payload;
    },
    setFormDescription: (state, action: PayloadAction<string>) => {
      state.formDescription = action.payload;
    },
    setFormScope: (state, action: PayloadAction<string>) => {
      state.formScope = action.payload;
    },
    setCurrentTabTitle: (state, action: PayloadAction<string>) => {
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab ? { ...tab, title: action.payload } : tab
      );
    },
    setRowTitle: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() === splitItemPath[0] ? { ...row, title: action.payload.title } : row
              )
            }
          : tab
      );
    },
    setColumnTitle: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() === splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.map((column, i) =>
                        i.toString() === splitItemPath[1]
                          ? { ...column, title: action.payload.title }
                          : column
                      )
                    }
                  : row
              )
            }
          : tab
      );
    },
    setTableIds: (state, action: PayloadAction<number[] | number>) => {
      if (typeof action.payload === "number") state.tableIds = [...state.tableIds, action.payload];
      else state.tableIds = [...state.tableIds, ...action.payload];
    },
    moveTabIndex: (state, action: PayloadAction<any>) => {
      state.formData = moveDNDObjectInArray(
        state.formData,
        action.payload.dragIndex,
        action.payload.hoverIndex
      );
    },
    addTab: state => {
      state.formData.push(getDefaultTab(nanoid()));
    },
    removeTab: (state, action: PayloadAction<string>) => {
      state.formData = state.formData.filter(tab => tab.id !== action.payload);
      state.currentTab = state.formData[0].id;
    },
    updateLayout: (state, action: PayloadAction<ItemDNDType[]>) => {
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? { ...tab, children: removeEmptyColumnsFromTab(action.payload) }
          : tab
      );
    },
    addNewRow: state => {
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: [...(tab.children || []), getDefaultRow(nanoid())]
            }
          : tab
      );
    },
    addNewColumn: (state, action: PayloadAction<any>) => {
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i === action.payload
                  ? {
                      ...row,
                      children: [...(row.children || []), getDefaultColumn(nanoid())]
                    }
                  : row
              )
            }
          : tab
      );
    },
    removeRow: (state, action: PayloadAction<string>) => {
      const splitItemPath = splitPath(action.payload);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.filter((row, i) => i.toString() !== splitItemPath[0])
            }
          : tab
      );
    },
    removeColumn: (state, action: PayloadAction<string>) => {
      const splitItemPath = splitPath(action.payload);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() === splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.filter(
                        (column, i) => i.toString() !== splitItemPath[1]
                      )
                    }
                  : row
              )
            }
          : tab
      );
    },
    removeComponent: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: removeEmptyColumnsFromTab(
                tab.children?.map((row, i) =>
                  i.toString() === splitItemPath[0]
                    ? {
                        ...row,
                        children: row.children?.map((column, i) =>
                          i.toString() === splitItemPath[1]
                            ? {
                                ...column,
                                children: column.children?.filter(
                                  (component, i) => i.toString() !== splitItemPath[2]
                                )
                              }
                            : column
                        )
                      }
                    : row
                )
              )
            }
          : tab
      );

      state.usedComponents = state.usedComponents.filter(
        componentID => componentID !== action.payload.id
      );
    },

    updateComponentVisibility: (
      state,
      action: PayloadAction<{
        path: string;
        visibility: WorkflowStageViewFieldState;
      }>
    ) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map(tab =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() === splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.map((column, i) =>
                        i.toString() === splitItemPath[1]
                          ? {
                              ...column,
                              children: column.children?.map((component, i) => {
                                return i.toString() === splitItemPath[2]
                                  ? {
                                      ...component,
                                      visibility: action.payload.visibility
                                    }
                                  : component;
                              })
                            }
                          : column
                      )
                    }
                  : row
              )
            }
          : tab
      );
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setFormData: (state, action: PayloadAction<ItemDNDType[]>) => {
      state.formData = action.payload;
    },
    setFromWorkflow: (state, action: PayloadAction<boolean>) => {
      state.fromWorkflow = action.payload;
    }
  }
});

export const {
  resetLiveForm,
  updateIndividualFormProperty,
  setFormName,
  setInternalFormName,
  setFormDescription,
  setFormScope,
  setCurrentTab,
  setCurrentTabTitle,
  setRowTitle,
  setColumnTitle,
  setTableIds,
  moveTabIndex,
  updateLayout,
  addTab,
  removeTab,
  addNewRow,
  addNewColumn,
  removeRow,
  removeColumn,
  removeComponent,
  setIsEdit,
  setFormData,
  setFromWorkflow,
  updateComponentVisibility
} = formDesignerSlice.actions;

const inputSelectFormDesigner = (state: RootState) => state.formdesigner;

export const selectFormData = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): ItemDNDType[] => formdesigner.formData
);

export const selectFormName = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): string => formdesigner.formName
);

export const selectFormInternalName = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): string => formdesigner.formInternalName
);

export const selectFormDescription = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): string => formdesigner.formDescription
);

export const selectFormScope = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): string => formdesigner.formScope
);

export const selectLayout = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): ItemDNDType[] =>
    formdesigner.formData.find((tab: ItemDNDType) => tab.id === formdesigner.currentTab)
      ?.children || []
);

export const selectCurrentTab = createSelector(
  [selectFormData, inputSelectFormDesigner],
  (formData, formdesigner): ItemDNDType =>
    formData.find((tab: ItemDNDType) => tab.id === formdesigner.currentTab) || formData[0]
);
export const selectFormDesignerIsEdit = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): boolean => formdesigner.isEdit
);

export const selectFormDesignerIsReadOnly = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): boolean => formdesigner.readOnly
);

export const selectFormsDisabledItemList = createSelector(
  [selectFormData],
  (formData): NonSelectedItem[] => {
    const result: NonSelectedItem[] = [];
    formData.forEach(tab => {
      tab.children?.forEach(row => {
        row.children?.forEach(column => {
          column.children?.forEach(item => {
            result.push({
              id: item.component?.id,
              type: item.component?.dataType
            });
          });
        });
      });
    });
    return result;
  }
);

export const selectTableIds = createSelector(
  [inputSelectFormDesigner],
  (formdesigner): number[] => formdesigner.tableIds
);

export const removeEmptyColumnsFromTab = (rows?: ItemDNDType[]) => {
  return rows?.map(row => ({
    ...row,
    children: row.children?.filter(column => column.children && column.children.length > 0)
  }));
};
export default formDesignerSlice.reducer;
