import { Field, FieldDataType, TableView, TableViewType } from "enada-common";

// TODO : Move models to shared once feature is stable
export type BryntumBoardItemType =
  | "image"
  | "progress"
  | "rating"
  | "resourcesAvatar"
  | "seperator"
  | "tags"
  | "text"
  | "todoList"
  | "date";
export interface BoardCardItem {
  location: "header" | "body" | "footer";
  type: BryntumBoardItemType;
  field: string;
}
interface boardToEdisonMap {
  edison: FieldDataType;
  bryntum: BryntumBoardItemType;
}

const map: boardToEdisonMap[] = [
  { edison: FieldDataType.TextBox, bryntum: "text" },
  { edison: FieldDataType.Email, bryntum: "text" },
  { edison: FieldDataType.Date, bryntum: "date" },
  { edison: FieldDataType.DateTime, bryntum: "date" },
  { edison: FieldDataType.Choice, bryntum: "tags" },
  { edison: FieldDataType.MultiChoice, bryntum: "tags" },
  { edison: FieldDataType.MultiLevelChoice, bryntum: "tags" },
  { edison: FieldDataType.Percentage, bryntum: "progress" },
  { edison: FieldDataType.Number, bryntum: "text" },
  { edison: FieldDataType.Currency, bryntum: "text" }
];

export const getTableBoardCardItems = (
  fields: Field[],
  view: TableView | null
): BoardCardItem[] => {
  if (view === null) return [];
  if (view.viewType !== TableViewType.Board) return [];

  const titleField = fields.find(field => field.id === view.boardTitleFieldId);
  const cardTitle: BoardCardItem = {
    type: "text",
    field: `${titleField?.name}-e365`,
    location: "header"
  };

  const bodyItems = view.fields
    ?.filter(
      viewField => ![view.boardColumnFieldId, view.boardTitleFieldId].includes(viewField.fieldId)
    )
    ?.map(viewField => {
      const field = fields.find(field => field.id === viewField.fieldId);
      if (!field) return undefined;
      const bryntumField = map.find(tuple => tuple.edison === field.dataType)?.bryntum;
      if (!bryntumField) return undefined;
      return {
        type: bryntumField,
        field: `${field.name}-e365`,
        location: "body"
      };
    })
    .filter(item => item !== undefined);

  const result = [cardTitle, ...(bodyItems as BoardCardItem[])];
  return result;
};
