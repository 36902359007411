import { memo } from "react";
import { Chip } from "@mui/material";
import { RecordType, recordTypeConfiguration } from "enada-common";
import { selectThemeName } from "../../store/slices/userSlice";
import { useAppSelector } from "../../store/hooks";
import "./parentChip.scss";

const ParentChip = memo(function ParentChip({
  displayName,
  type
}: {
  displayName?: string | null;
  type: RecordType;
}) {
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";
  const moduleProperties = recordTypeConfiguration(type, {
    fontSize: "1.2em"
  });
  return (
    <Chip
      size="small"
      label={displayName}
      icon={moduleProperties.icon as any}
      className="parentchip-container"
      sx={{
        lineHeight: "20px",
        color: isDarkTheme ? moduleProperties.secondaryColor : moduleProperties.color,
        backgroundColor: isDarkTheme ? moduleProperties.color : moduleProperties.secondaryColor
      }}
    />
  );
});

export default ParentChip;
