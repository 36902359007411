import { Autocomplete, Box, Checkbox, Stack, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FC, useEffect, useMemo, useState } from "react";
import { Choice, UserChoiceFieldProps } from "enada-common";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import ChoiceChip from "../choicechip/ChoiceChip";
import "./userchoicefield.scss";

import { getChoiceFullName, renderChipsList } from "../multilevelchoice/UserMultiLevelChoiceField";

const UserMultiChoiceField: FC<UserChoiceFieldProps> = ({
  choices,
  disabled,
  error,
  helperText,
  isInTable,
  isInRecord,
  isMultiChoice,
  label,
  onChange,
  readOnly,
  required,
  value,
  setReadOnly,
  autoFocus,
  dynamicHeight
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [internalState, setInternalState] = useState<Choice[]>(value || []);
  useEffect(() => {
    setInternalState(value || []);
  }, [value]);

  const intState = useMemo(() => {
    if (Array.isArray(internalState)) {
      return [...internalState];
    }
    return [internalState];
  }, [internalState]);

  const currentInternalState = choices.filter(choice =>
    intState.map(x => x.id).includes(choice.id)
  );

  return readOnly ? (
    <Stack maxWidth={"100%"}>
      {Boolean(label) && !isInTable && (
        <EdisonTypography
          title={label}
          variant="fieldtitle"
          noWrap={false}
          sx={{
            display: "block"
          }}
        />
      )}
      {/* If Dynamic height is not set, we need to render the minimized version of the choices eg. 1 choice +(num of remaining choices)
            We also render a hidden version the full listof choices in order to be able to measure the height of the container when managing
            data wrapping in tables.

           */}
      {dynamicHeight || !isInTable ? (
        renderChipsList(currentInternalState, choices, readOnly, disabled)
      ) : (
        <Stack>
          <div style={{ visibility: "hidden", position: "absolute" }}>
            {renderChipsList(currentInternalState, choices, readOnly, disabled)}
          </div>
          <div>
            {currentInternalState.slice(0, 1).map((choice, index) => (
              <Box key={index} maxWidth="100%" marginRight="3px">
                <ChoiceChip
                  label={getChoiceFullName(choice, choices)}
                  colour={choice.colour}
                  readOnly={readOnly}
                  disabled={disabled}
                  tooltip={currentInternalState.map(c => getChoiceFullName(c, choices)).join(", ")}
                />
                {currentInternalState.length > 1 && `\u00A0+${currentInternalState.length - 1}`}
              </Box>
            ))}
          </div>
        </Stack>
      )}
    </Stack>
  ) : (
    <Autocomplete
      onBlur={() => {
        if (!setReadOnly) return;
        setReadOnly(true);
        if (!isInTable) return;
        if (!onChange) return;
        onChange(currentInternalState);
      }}
      multiple={true}
      fullWidth
      openOnFocus
      ListboxProps={{ style: { padding: 0 } }}
      componentsProps={
        isInTable
          ? {
              popper: {
                placement: "bottom-start",
                sx: { width: "inherit!important" }
              }
            }
          : undefined
      }
      className={isInTable ? "in-table" : ""}
      size={"small"}
      disableCloseOnSelect
      options={choices}
      disabled={disabled}
      getOptionLabel={option => option?.label ?? ""}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      inputValue={inputValue}
      value={currentInternalState}
      renderTags={(tagValue, getTagProps) =>
        isInTable
          ? renderChipsList(tagValue, choices, readOnly, disabled)
          : tagValue.map((option, index) => (
              <Box key={option.id} width="100%" marginRight="3px">
                <ChoiceChip
                  label={option.label}
                  colour={option.colour}
                  readOnly={readOnly}
                  disabled={disabled}
                  additionalProps={getTagProps({ index })}
                  tooltip={currentInternalState.map(c => c.label).join(", ")}
                />
              </Box>
            ))
      }
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(_event, changeValue: Choice[]) => {
        setInternalState(changeValue);
        if (isInTable) return;
        if (onChange) onChange(changeValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          label={!isInTable && label}
          helperText={error ? helperText : ""}
          required={required && !isInTable}
          variant="standard"
          autoFocus={autoFocus}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Stack flexDirection={"row"} className="list-stack">
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              className="list-checkbox"
              sx={{
                marginRight: "8px",
                marginLeft: "-2px",
                padding: "0px",
                "& .MuiSvgIcon-root": { fontSize: 24 }
              }}
              checked={selected}
            />
            <Box>
              <EdisonTypography variant={readOnly ? "data2" : "data"} title={option.label} />
            </Box>
          </Stack>
        </li>
      )}
    />
  );
};
export default UserMultiChoiceField;
export { UserMultiChoiceField };
