import { RecordType, WorkflowTask, WorkflowTaskState, WorkItemReviewTask } from "enada-common";
import { Checkbox, Chip, Stack, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { nanoid } from "nanoid";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { postRecordWorkflowTask } from "../../../../services/APIService";
import { useAppDispatch } from "../../../../store/hooks";
import { updateApprovalTask } from "../../../../store/slices/personalSlice";
import { Loading } from "enada-components";
import { BorderedTableCell } from "../ApprovalsTable";
import "./approvalstasksubtable.scss";
export interface ApprovalsTaskSubTableProps {
  tasks: WorkItemReviewTask[];
  projectId?: number;
  completed: boolean;
  recordType: RecordType;
}
const ApprovalsTaskSubTable: FC<ApprovalsTaskSubTableProps> = ({
  tasks,
  projectId,
  completed,
  recordType
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [taskLoading, setTaskLoading] = useState<number | undefined>();

  const updateTask = async (checked: boolean, taskId?: number) => {
    if (!projectId) return;
    setTaskLoading(taskId);
    const additionalHeaders = new Headers();
    const sessionId = nanoid();
    additionalHeaders.append("edison365-sessionid", sessionId);

    console.log(checked, taskId);

    const task: WorkflowTask = {
      task: taskId,
      taskState: checked ? WorkflowTaskState.Completed : WorkflowTaskState.InProgress
    };

    const taskUpdateResponse = await postRecordWorkflowTask(
      projectId,
      recordType,
      task,
      additionalHeaders
      // getHeadersFromAuth({ ...auth, sessionId })
    );

    if (taskUpdateResponse.status !== 200) {
      setTaskLoading(undefined);
      return;
    }
    dispatch(
      updateApprovalTask({
        recordId: projectId,
        taskId: taskId,
        newState: taskUpdateResponse.data.taskState
      })
    );
    setTaskLoading(undefined);
  };
  return (
    <Stack className="approvals-task-subtable-root">
      <Table size="small">
        <TableHead>
          <TableRow>
            <BorderedTableCell className="complete">{t("complete?")}</BorderedTableCell>
            <BorderedTableCell className="items">{t("items")}</BorderedTableCell>
            <BorderedTableCell className="status">{t("status")}</BorderedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task, index) => (
            <TableRow key={index}>
              <BorderedTableCell className="complete">
                {taskLoading === task.taskId ? (
                  <Loading size={27} />
                ) : (
                  <Checkbox
                    disabled={completed}
                    checked={task.taskState === WorkflowTaskState.Completed}
                    onChange={(e, checked) => {
                      updateTask(checked, task.taskId);
                    }}
                  />
                )}
              </BorderedTableCell>
              <BorderedTableCell className="items">{task.taskDescription ?? ""}</BorderedTableCell>

              <BorderedTableCell className="status">
                <Chip
                  color={task.taskState === WorkflowTaskState.Completed ? "success" : "error"}
                  variant="outlined"
                  label={
                    task.taskState === WorkflowTaskState.Completed ? t("complete") : t("incomplete")
                  }
                />
              </BorderedTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default ApprovalsTaskSubTable;
