import { Image, RecordTemplate } from "enada-common";
import { apiConfig } from "../../config/authConfig";
import { baseAPI } from "../baseAPI";

export const templatesAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getTemplates: builder.query<RecordTemplate[], void>({
      query: () => `${apiConfig.recordsTemplatesEndpoint}`,
      transformResponse: (response: { data: RecordTemplate[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Templates" as const, id })), "Templates"]
          : ["Templates"]
    }),

    getTemplate: builder.query<RecordTemplate, number>({
      query: id => `${apiConfig.recordsTemplatesEndpoint}/${id}`,
      transformResponse: (response: { data: RecordTemplate }) => response.data,
      providesTags: (result, error, id) => [{ type: "Templates", id }]
    }),

    createTemplate: builder.mutation<RecordTemplate, Partial<RecordTemplate>>({
      query: body => ({
        url: apiConfig.recordsTemplatesEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: RecordTemplate }) => response.data,
      invalidatesTags: ["Templates"]
    }),

    updateTemplate: builder.mutation<RecordTemplate, Partial<RecordTemplate>>({
      query: body => ({
        url: apiConfig.recordsTemplatesEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: RecordTemplate }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Templates", id: body.id }]
    }),

    deleteTemplates: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.recordsTemplatesEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Templates", id }))
    }),

    restoreTemplates: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.recordsTemplatesEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Templates", id }))
    }),

    recycleTemplates: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.recordsTemplatesEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Templates", id }))
    }),

    createTemplateImage: builder.mutation<void, { image: Image; typeId: number }>({
      query: ({ image, typeId }) => {
        const formData = new FormData();
        formData.append("file", image as File);

        return {
          url: `${apiConfig.recordsTemplatesEndpoint}/${typeId}/image`,
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: (result, error, { typeId }) => [{ type: "Templates", id: typeId }]
    })
  })
});
