import { Field, TableDataType } from "enada-common";
import { generateBaseBryntumColumn } from "../../tableutils/generateBaseBryntumColumn";

const ListTableColumns = (columns: Field[], labelToSearchFor: string) => {
  const baseColumns = columns.map(column => {
    return {
      ...generateBaseBryntumColumn(
        column,
        columns,
        undefined,
        TableDataType.List,
        labelToSearchFor
      ),
      cls: "gridHeader",
      htmlEncodeHeaderText: false
    };
  });
  // For Gantt the name column is mandatory but for the standard table it is not required so set it to hidden
  // references:
  // https://bryntum.com/products/gantt/docs/api/Gantt/column/NameColumn
  // https://forum.bryntum.com/viewtopic.php?p=84863
  const updatedNameColumn = {
    type: "name",
    hidden: true
  };
  return [updatedNameColumn, ...baseColumns];
};
export default ListTableColumns;
