import {
  Box,
  CircularProgress,
  CircularProgressProps,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import React, { FC } from "react";
export interface ReviewsMetProps {
  total?: number | null;
  currentMet?: number;
  primaryText: string;
  secondaryText: string;
}
const ReviewsMet: FC<ReviewsMetProps> = ({ total, currentMet, primaryText, secondaryText }) => {
  const value =
    !Boolean(total) || !Boolean(currentMet) ? 0 : (Number(currentMet) / Number(total)) * 100;
  return (
    <ListItem sx={{ paddingLeft: 0 }}>
      <CircularProgressWithLabel value={value} />
      <ListItemText
        sx={{ paddingLeft: "2em" }}
        primary={`${primaryText} (${currentMet}/${total})`}
        secondary={secondaryText}
      />
    </ListItem>
  );
};

export function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default ReviewsMet;
