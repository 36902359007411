import { LicenseType } from "enada-common";
import { Box, IconButton, TableRow, capitalize, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import { BorderedTableCell } from "../../approvalstable/ApprovalsTable";
import { EdisonTypography } from "enada-components";
import { getStatusChip } from "../../tasksTable/tasksRow/TasksRow";
import HasMinimumLicense from "../../../hasMinimumLicense/HasMinimumLicense";
import { useTranslation } from "react-i18next";
import TasksRowDialog from "../../tasksTable/tasksRow/tasksrowdialog/TasksRowDialog";
import { WorkItem } from "../OwnedItems";
import { SystemFieldType } from "../../../../pages/admin/tableconfig/TableConfig";
import SendIcon from "@mui/icons-material/Send";
import { useGetMyWorkRows } from "../../../../utils/hooks/useGetMyWorkRows";
import { useDisclosure } from "utils/hooks/useDisclosure";

export interface OwnedItemsRowProps {
  ownedItem: WorkItem;
}
const OwnedItemsRow: FC<OwnedItemsRowProps> = ({ ownedItem }) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { recordTableRow, table, isLoading } = useGetMyWorkRows(ownedItem, isOpen);

  const rowIdentifier = useMemo(() => {
    const name = ownedItem?.fieldValues?.find(
      value => value.fieldId === SystemFieldType.Name
    )?.stringValue;

    const uniqueId = ownedItem?.fieldValues?.find(
      value => value.fieldId === SystemFieldType.UniqueId
    )?.stringValue;

    return name ?? uniqueId ?? "";
  }, [ownedItem?.fieldValues]);

  return (
    <TableRow hover>
      <BorderedTableCell>
        <Box onClick={onOpen}>
          <EdisonTypography
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            variant="data2"
            title={rowIdentifier}
          />
        </Box>
      </BorderedTableCell>
      <BorderedTableCell>
        <EdisonTypography variant="data2" title={table?.displayName ?? table?.name ?? ""} />
      </BorderedTableCell>
      <BorderedTableCell>{getStatusChip(theme, ownedItem.approvalState)}</BorderedTableCell>

      <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
        <>
          <BorderedTableCell className="action">
            <IconButton data-test-id={"personal-page-owned-items-submit-button"} onClick={onOpen}>
              <SendIcon />
            </IconButton>
          </BorderedTableCell>
          <TasksRowDialog
            onClose={onClose}
            isOpen={isOpen}
            approvalState={ownedItem?.approvalState}
            identifier={capitalize(t("item"))}
            recordTableRow={recordTableRow}
            table={table}
            recordType={ownedItem.recordType}
            recordId={ownedItem.recordId}
            baseRowId={ownedItem.itemId}
            dataLoading={isLoading}
          />
        </>
      </HasMinimumLicense>
    </TableRow>
  );
};

export default OwnedItemsRow;
