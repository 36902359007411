import {
  ApprovalState,
  BaseWorkItem,
  Field,
  LicenseType,
  PersonalPageState,
  RecordTableRow,
  RecordType,
  Table,
  TableField,
  TableRowValue
} from "enada-common";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SystemFieldType } from "../../../../../pages/admin/tableconfig/TableConfig";
import { getAccessToken } from "../../../../../services/APIService";
import { selectAllFields } from "../../../../../store/slices/fieldsSlice";
import { OperationType, RecordAuth } from "../../../../../store/slices/recordSlice";
import { EdisonTypography, Loading } from "enada-components";
import ComponentMapper from "../../../../../utils/componentMapper";
import { RowOperationType } from "../../../../../utils/parsing/parseTableChangeToBackend";
import { getBackendMappedCellValue, getValueByFieldType } from "../../../../../utils/tableHelpers";
import "./tasksrowdialog.scss";
import { StringHelper } from "@bryntum/core-thin";
import {
  useGetFieldsQuery,
  useGetMyLicenseQuery,
  useRecallPendingRowMutation,
  useSavePendingRecordViewTableValuesMutation
} from "services/api";

interface TasksRowDialogProps {
  recordId: BaseWorkItem["recordId"];
  recordType: RecordType;
  isOpen: boolean;
  onClose: () => void;
  approvalState?: ApprovalState;
  identifier: string;
  recordTableRow: RecordTableRow | undefined;
  table: Table | undefined;
  baseRowId?: number;
  dataLoading?: boolean;
}

const TasksRowDialog: FC<TasksRowDialogProps> = ({
  recordId,
  recordType,
  isOpen = false,
  onClose,
  approvalState,
  identifier,
  recordTableRow,
  table,
  baseRowId,
  dataLoading
}) => {
  const { t } = useTranslation(["common"]);

  const [savePendingRecordViewTableValues, { isLoading: tableUpdateIsLoading }] =
    useSavePendingRecordViewTableValuesMutation();
  const [recallRow, { isLoading: recallIsLoading }] = useRecallPendingRowMutation();

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const { data: myLicense } = useGetMyLicenseQuery();

  const isLoading = dataLoading || tableUpdateIsLoading || recallIsLoading;

  const [auth, setAuth] = useState<RecordAuth | undefined>();
  const [fullRow, setFullRow] = useState<RecordTableRow | undefined>(recordTableRow);
  const [changeMade, setChangeMade] = useState(false);

  const recallable = approvalState === ApprovalState.Submitted;

  const onDialogClose = useCallback(() => {
    setChangeMade(false);
    onClose();
  }, [onClose]);

  const resetData = useCallback(() => {
    setAuth(undefined);
  }, []);

  const saveRow = useCallback(
    async (operationType: OperationType) => {
      if (!fullRow || !recordId || !auth) return;
      const operations: RowOperationType[] = [
        {
          operationType: operationType,
          tableRows: [
            {
              ...fullRow,
              tableRowFieldValues: fullRow.tableRowFieldValues?.filter(
                fieldValue =>
                  fieldValue.fieldId === SystemFieldType.UniqueId ||
                  table?.fields?.some(
                    field =>
                      field.personalPageState === PersonalPageState.Editable &&
                      field.fieldId === fieldValue.fieldId
                  )
              )
            }
          ]
        }
      ];

      const response = await savePendingRecordViewTableValues({
        body: operations,
        recordId,
        recordType,
        viewId: auth.details.Views[0],
        auth
      });

      if (response) {
        resetData();
        onDialogClose();
      }
    },
    [
      auth,
      fullRow,
      onDialogClose,
      recordId,
      recordType,
      resetData,
      savePendingRecordViewTableValues,
      table?.fields
    ]
  );

  const getPermissions = useCallback(async () => {
    if (!recordId) return;
    const additionalHeaders = new Headers();
    additionalHeaders.append("edison365-sessionid", "frontend");

    const response = await getAccessToken(recordId, recordType, additionalHeaders);
    if (response.status !== 200) {
      return;
    }

    const auth = response.data as RecordAuth;
    // Not passing permission token because it is now generated on the backend

    setAuth({ ...auth, sessionId: "frontend" });
  }, [recordId, recordType]);

  useEffect(() => {
    const getData = async () => {
      await getPermissions();
    };
    if (recordTableRow) {
      setFullRow(recordTableRow);
      resetData();
      getData();
    }
  }, [getPermissions, recordTableRow, resetData]);

  const onChangeField = useCallback(
    (changeValue: unknown, value: TableRowValue, foundField: Field, column: TableField) => {
      if (
        !changeMade &&
        (value === undefined || getValueByFieldType(foundField, value) !== changeValue)
      ) {
        setChangeMade(true);
      }
      setFullRow(
        prev =>
          ({
            ...prev,
            tableRowFieldValues: prev?.tableRowFieldValues?.some(
              rowValue => rowValue.fieldId === column.fieldId
            )
              ? // if  rowValue exists in init list then update entry in list
                prev?.tableRowFieldValues?.map(rowValue =>
                  rowValue.fieldId === column.fieldId
                    ? getBackendMappedCellValue(foundField, changeValue, fullRow?.id as number)
                    : rowValue
                )
              : // if rowValue does not exists in init list add entry into list
                [
                  ...(prev?.tableRowFieldValues ?? []),
                  getBackendMappedCellValue(foundField, changeValue, fullRow?.id as number)
                ]
          } as RecordTableRow)
      );
    },
    [changeMade, fullRow?.id]
  );

  const isFieldReadOnly = useMemo(
    () => (fieldId?: number) =>
      table?.fields?.find(tableField => tableField.fieldId === fieldId)?.personalPageState !==
        PersonalPageState.Editable || approvalState === ApprovalState.Submitted,

    [table?.fields, approvalState]
  );

  const onRecallRow = async () => {
    if (recordId === undefined) return;
    if (auth === undefined) return;

    const response = await recallRow({
      body: { recordTableRowId: baseRowId },
      recordId,
      recordType,
      auth
    });

    if (response) {
      resetData();
      onDialogClose();
    }
  };

  const getAlert = useMemo(() => {
    switch (approvalState) {
      case ApprovalState.Submitted:
        return {
          title: t("submittedTaskAlertTitle"),
          message: t("recallTaskAlertMessage"),
          severity: "info"
        };

      case ApprovalState.Approved:
        return {
          title: t("approvedTaskAlertTitle"),
          message: t("approvedTaskAlertMessage"),
          severity: "success"
        };
      case ApprovalState.Rejected:
        return {
          title: t("rejectedTaskAlertTitle"),
          message: t("rejectedTaskAlertMessage"),
          severity: "error"
        };
      case undefined:
      case ApprovalState.Saved:
        return { title: null, message: t("editTaskAlertMessage"), severity: "info" };
      default:
        return { title: null, message: null, severity: "info" };
    }
  }, [approvalState, t]);

  return (
    <Dialog open={isOpen} scroll="paper" className="tasks-row-dialog-root">
      {isLoading ? (
        <Loading size={30} />
      ) : (
        <>
          <DialogTitle>
            <Stack spacing={1}>
              <Stack direction="row" className="title-container">
                <EdisonTypography
                  title={`${t("edit")} ${StringHelper.decodeHtml(identifier ?? "")}`}
                  variant="h2"
                />
                <IconButton
                  onClick={() => {
                    onDialogClose();
                    resetData();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider flexItem />
              <Alert severity={getAlert.severity as any}>
                <AlertTitle>{getAlert.title}</AlertTitle>
                {getAlert.message}
              </Alert>
              {approvalState === ApprovalState.Saved && (
                <Alert severity="info" className="dialog-alert">
                  {t("usingSavedData")}
                </Alert>
              )}
              <Divider flexItem />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={1}>
              {table?.fields
                ?.filter(
                  field =>
                    field.fieldId &&
                    ![undefined, PersonalPageState.Hidden].includes(field.personalPageState)
                )
                .map((column, index) => {
                  const foundField = fields.find(field => field.id === column.fieldId);
                  const value = fullRow?.tableRowFieldValues?.find(
                    rowValue => rowValue.fieldId === column.fieldId
                  ) as TableRowValue;

                  return foundField ? (
                    <ComponentMapper
                      key={index}
                      readOnly={
                        myLicense?.licenseType === undefined ||
                        myLicense?.licenseType < LicenseType.Lite ||
                        isFieldReadOnly(column.fieldId)
                      }
                      isInTable={false}
                      useInternalState={false}
                      t={t}
                      value={value ? getValueByFieldType(foundField, value) : value}
                      useReadOnlyWrapper={true}
                      component={foundField}
                      onChange={(changeValue: unknown) =>
                        onChangeField(changeValue, value, foundField, column)
                      }
                    />
                  ) : (
                    ""
                  );
                })}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} className="save-button-container">
              <Button
                disabled={!recallable && !changeMade}
                onClick={() => (recallable ? onRecallRow() : saveRow(OperationType.SaveForLater))}
              >
                {t(recallable ? "recall" : "saveAndClose")}
              </Button>
              <Stack direction={"row"} spacing={1}>
                <Button
                  disabled={!changeMade && approvalState !== ApprovalState.Saved}
                  variant="contained"
                  onClick={() => saveRow(OperationType.Submit)}
                >
                  {t("submit")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    resetData();
                    onDialogClose();
                  }}
                >
                  {t("cancel")}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default TasksRowDialog;
