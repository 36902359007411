import {
  getRecordAccessTokenAsync,
  saveRecordFieldValuesAsync,
  selectHasChanges,
  selectHasRecordChanges,
  selectRecordFieldValues,
  selectRecord,
  updateRecordAsync,
  updateSaveStatus,
  setLastRecordChange
} from "../../store/slices/recordSlice";
import { parseFormFieldsToBackend } from "../../store/slices/parseFormData";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { hasAccessRole } from "../hasAccessRole";
import { BaseRecord, RecordAccessRole, RecordType, useDebounce } from "enada-common";
import { getImagePath } from "../imagePathManager";
import { selectRegion } from "../../store/slices/configSlice";
import {
  saveRecordTableRowValuesAsync,
  saveRecordTablesConfigurationAsync,
  selectActiveSaveSlot,
  selectHasTableConfigurationChanges,
  selectTableConfigs,
  selectTableOperations,
  setHasTableConfigurationChanges,
  swapActiveSaveSlot
} from "../../store/slices/recordTableSlice";
import { useGetCdnTokenQuery } from "services/api";

function useSaveRecord() {
  const dispatch = useAppDispatch();
  const region = useAppSelector(selectRegion);
  const { data: cdnToken } = useGetCdnTokenQuery(undefined, { skip: !region });

  const record = useAppSelector(selectRecord);
  const hasChanges = useAppSelector(selectHasChanges);
  const hasTableConfigurationChanges = useAppSelector(selectHasTableConfigurationChanges);
  const hasProjectChanges = useAppSelector(selectHasRecordChanges);
  const projectFieldValues = useAppSelector(selectRecordFieldValues);
  const tableOperations = useAppSelector(selectTableOperations);
  const tableConfigs = useAppSelector(selectTableConfigs);
  const activeSaveSlot = useAppSelector(selectActiveSaveSlot);

  const usePreviousLocation = (location: any) => {
    const prevLocRef = useRef(location);
    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);
    return prevLocRef.current;
  };
  const location = useLocation();
  const previousLocation = usePreviousLocation(location);

  const params = useParams();

  const getAuthObject = useCallback(
    async (record: BaseRecord | null) => {
      return await dispatch(
        getRecordAccessTokenAsync({
          recordId: params.recordId as any,
          recordType: record?.recordType as RecordType
        })
      ).unwrap();
    },
    [dispatch, params.recordId]
  );

  const getImageUrl = useCallback(() => {
    return getImagePath(record?.imageUrl, region, cdnToken);
  }, [cdnToken, record?.imageUrl, region]);

  const saveProject = useCallback(async () => {
    const authObject = await getAuthObject(record);
    if (!hasAccessRole(RecordAccessRole.Update, authObject.details.AccessRoles)) return;

    const parsed = parseFormFieldsToBackend(
      projectFieldValues,
      authObject.details.RecordId,
      "recordId"
    );

    if (parsed?.length) {
      dispatch(updateSaveStatus("fields"));
      dispatch(
        saveRecordFieldValuesAsync({
          recordAuth: authObject,
          recordType: record?.recordType as RecordType,
          recordFieldValues: parsed
        })
      );
    }

    if (tableOperations?.length) {
      dispatch(updateSaveStatus("rows"));

      // Active Save Slot: The table operations that will be saved
      // Inactive Save Slot: The set of table operations that is used to store while the save is running
      // Switch the active slot so any subsequent table operations will be stored in the inactive save slot.
      dispatch(swapActiveSaveSlot());

      // Use the active save slot to work out which table operations to save. activeSaveSlot has not been affected by the swap.
      dispatch(
        saveRecordTableRowValuesAsync({
          recordAuth: authObject,
          recordType: record?.recordType as RecordType,
          activeSaveSlot: activeSaveSlot
        })
      );
    }

    if (hasTableConfigurationChanges) {
      dispatch(updateSaveStatus("table-config"));
      dispatch(setHasTableConfigurationChanges(false));
      dispatch(
        saveRecordTablesConfigurationAsync({
          recordAuth: authObject,
          recordType: record?.recordType as RecordType,
          tableConfigs
        })
      );
    }

    if (hasProjectChanges) {
      dispatch(updateSaveStatus("record"));
      dispatch(
        updateRecordAsync({
          recordAuth: authObject,
          record: {
            ...(record as BaseRecord),
            timeZone: record?.timeZone || new Intl.DateTimeFormat().resolvedOptions().timeZone,
            imageUrl: getImageUrl()
          }
        })
      );
    }
    dispatch(setLastRecordChange(new Date().toString()));
    // disabled while we integrate rtk
    //eslint-disable-next-line
  }, [
    dispatch,
    getImageUrl,
    projectFieldValues,
    tableOperations,
    hasProjectChanges,
    hasTableConfigurationChanges
  ]);

  useEffect(() => {
    if (hasChanges && previousLocation.state?.record && !location.state.record) {
      saveProject();
    }
    // disabled while we integrate rtk
    //eslint-disable-next-line
  }, [location.pathname]);

  useDebounce(
    () => {
      if (hasChanges && location.state?.record) {
        saveProject();
      }
    },
    5000,
    [projectFieldValues, tableOperations, record, tableConfigs]
  );
}

export default useSaveRecord;
