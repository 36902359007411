import {
  removeWhitespaces,
  Workflow,
  Category,
  WorkflowStage,
  WorkflowEventAction,
  RecordType,
  WorkflowNodeType
} from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge, isNode, Node, addEdge, updateEdge } from "reactflow";
import { workflowOrientation } from "../../components/workflowdesigner/utils/FrontendWorkflow.models";
import { RootState } from "../store";
import { WorkflowError } from "../../components/workflowdesigner/utils/parsing/parseToBackend";
import { getEditAccess } from "../../components/workflowdesigner/utils/getNewNode";

export interface BaseWorkflowStage {
  id: number;
  displayName: string;
  cardId: number;
  colour?: string;
  targetDuration?: number;
  configuration?: any;
  allowRecordCreation?: boolean;
}
export enum WorkflowDesignerView {
  StageView = "StageView",
  PopulationView = "PopulationView",
  ManageFormView = "ManageFormView"
}
export interface WorkflowState {
  nodes: Node[];
  edges: Edge[];
  changeMade: boolean;
  orientation: workflowOrientation;
  formWorkflow: Workflow;
  workflowStages: BaseWorkflowStage[];
  validationErrors: WorkflowError[];
  isEdit: boolean;
  workflowDesignerView: WorkflowDesignerView;
  readOnly: boolean;
}

const defaultWorkflow: Workflow = {
  name: "",
  type: RecordType.Projects,
  allowStageSkip: true
};

const initialState: WorkflowState = {
  nodes: [],
  edges: [],
  changeMade: false,
  orientation: "vertical",
  formWorkflow: defaultWorkflow,
  workflowStages: [],
  validationErrors: [],
  isEdit: false,
  workflowDesignerView: WorkflowDesignerView.StageView,
  readOnly: false
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    addWorkflowNode: (state, action: PayloadAction<Node>) => {
      state.nodes = state.nodes.concat(action.payload);
      state.changeMade = true;
    },
    addWorkflowEdge: (state, action: PayloadAction<Edge>) => {
      state.edges = addEdge(action.payload, state.edges);
      state.changeMade = true;
    },
    setNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action: PayloadAction<Edge[]>) => {
      state.edges = action.payload;
    },
    updateEdges: (state, action: PayloadAction<any>) => {
      const { oldEdge, newConnection } = action.payload;
      state.edges = updateEdge(oldEdge, newConnection, state.edges);
    },
    setSelectedElement: (state, action: PayloadAction<Node | Edge | null>) => {
      if (action.payload === null) {
        state.nodes = state.nodes.map(node => ({ ...node, selected: false }));
        state.edges = state.edges.map(edge => ({ ...edge, selected: false }));
        return;
      }
      if (isNode(action.payload)) {
        state.nodes = state.nodes.map(node =>
          node.id === action?.payload?.id
            ? { ...node, selected: true }
            : { ...node, selected: false }
        );
      } else {
        state.edges = state.edges.map(edge =>
          edge.id === action?.payload?.id
            ? { ...edge, selected: true }
            : { ...edge, selected: false }
        );
      }
    },
    updateElement: (state, action: PayloadAction<Node | Edge>) => {
      if (isNode(action.payload)) {
        state.nodes = state.nodes.map(node =>
          node.id === action.payload.id ? (action.payload as Node) : node
        );
      } else {
        state.edges = state.edges.map(edge =>
          edge.id === action.payload.id ? (action.payload as Edge) : edge
        );
      }
      state.changeMade = true;
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      state.nodes = state.nodes.filter(node => node.id.toString() !== action.payload.toString());
      state.edges = state.edges.filter(
        edge =>
          edge.source !== action.payload.toString() && edge.target !== action.payload.toString()
      );
      state.changeMade = true;
    },
    setFormWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.formWorkflow = action.payload;
    },
    updateWorkflowDescription: (state, action: PayloadAction<string>) => {
      state.formWorkflow.description = action.payload;
      state.changeMade = true;
    },
    updateWorkflowName: (state, action: PayloadAction<string>) => {
      state.formWorkflow.displayName = action.payload;
      if (!state.isEdit) {
        state.formWorkflow.name = removeWhitespaces(action.payload);
      }
      state.changeMade = true;
    },
    updateWorkflowScope: (state, action: PayloadAction<RecordType>) => {
      state.changeMade = true;
      state.formWorkflow.type = action.payload;
    },
    updateWorkflowStageSkip: (state, action: PayloadAction<boolean>) => {
      state.formWorkflow.allowStageSkip = action.payload;
      state.changeMade = true;
    },
    setWorkflowErrors: (state, action: PayloadAction<WorkflowError[]>) => {
      state.validationErrors = action.payload;
    },
    clearWorkflowError: (state, action: PayloadAction<WorkflowError>) => {
      const { nodeName, property } = action.payload;
      state.validationErrors = state.validationErrors.filter(error =>
        error.nodeName === nodeName ? error.property !== property : true
      );
    },
    setWorkflowIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    resetWorkflowSlice: state => {
      state.nodes = [];
      state.edges = [];
      state.orientation = "vertical";
      state.formWorkflow = defaultWorkflow;
      state.isEdit = false;
      state.changeMade = false;
      state.readOnly = false;
    },
    setWorkflowChangeMade: (state, action: PayloadAction<boolean>) => {
      state.changeMade = action.payload;
    },
    setWorkflowOrientation: (state, action: PayloadAction<workflowOrientation>) => {
      state.orientation = action.payload;
      state.changeMade = true;
    },
    updateWorkflowStage: (state, action: PayloadAction<WorkflowStage>) => {
      state.nodes = state.nodes.map(node => {
        if (node.data.id === action.payload.id) {
          return {
            ...node,
            data: action.payload
          };
        } else return node;
      });
    },
    resetNodesFormsAndCards: (state, action: PayloadAction<RecordType>) => {
      state.nodes = state.nodes.map(node => ({
        ...node,
        data: {
          ...node.data,
          configuration: {
            ...node.data.configuration,
            recordType: action.payload
          },
          cardId: undefined,
          formId: undefined,
          views: undefined,
          editAccess: getEditAccess({
            // updates the edit access based on the record type
            type: node.type as WorkflowNodeType,
            recordType: action.payload
          })
        }
      }));
    },
    toggleAllStagesAllowRecordCreation: (state, action: PayloadAction<boolean>) => {
      state.nodes = state.nodes.map(node => ({
        ...node,
        data: { ...node.data, allowRecordCreation: action.payload }
      }));
    },

    setWorkflowDesignerView: (state, action: PayloadAction<WorkflowDesignerView>) => {
      state.workflowDesignerView = action.payload;
    },
    setWorkflowReadOnly: (state, action: PayloadAction<boolean>) => {
      state.readOnly = action.payload;
    }
  }
});

export const {
  addWorkflowNode,
  setNodes,
  setEdges,
  setSelectedElement,
  updateElement,
  addWorkflowEdge,
  deleteNode,
  updateWorkflowDescription,
  updateWorkflowName,
  setWorkflowErrors,
  setWorkflowIsEdit,
  resetWorkflowSlice,
  setWorkflowChangeMade,
  updateWorkflowStageSkip,
  updateEdges,
  setWorkflowOrientation,
  updateWorkflowScope,
  clearWorkflowError,
  resetNodesFormsAndCards,
  setWorkflowDesignerView,
  setWorkflowReadOnly,
  updateWorkflowStage,
  toggleAllStagesAllowRecordCreation,
  setFormWorkflow
} = workflowSlice.actions;

const inputSelectWorkflow = (state: RootState) => state.workflow;

export const selectWorkflowNodes = createSelector(
  [inputSelectWorkflow],
  (workflow): Node[] => workflow.nodes
);

export const selectWorkflowEdges = createSelector(
  [inputSelectWorkflow],
  (workflow): Edge[] => workflow.edges
);

export const selectCurrentElement = createSelector(
  [inputSelectWorkflow],
  (workflow): Node | Edge | null => {
    const foundNode = workflow.nodes.find(node => node.selected);
    if (foundNode) return foundNode;
    const foundEdge = workflow.edges.find(edge => edge.selected);
    if (foundEdge) return foundEdge;
    return null;
  }
);

export const selectWorkflowOrientation = createSelector(
  [inputSelectWorkflow],
  (workflow): workflowOrientation => workflow.orientation
);

export const selectFormWorkflow = createSelector(
  [inputSelectWorkflow],
  (workflow): Workflow => workflow.formWorkflow
);
export const selectWorkflowType = createSelector(
  [inputSelectWorkflow],
  (workflow): RecordType => workflow.formWorkflow.type
);

export const selectWorklowValidationErrorsByNode = createSelector(
  [inputSelectWorkflow, (state, nodeName) => nodeName],
  (workflow, nodeName) => {
    const result = workflow.validationErrors.filter(
      validationError => validationError?.nodeName === nodeName
    );
    return result;
  }
);

export const selectWorkflowLevelValidationErrors = createSelector([inputSelectWorkflow], workflow =>
  workflow.validationErrors.filter(error => !error.nodeName)
);

export const selectWorkflowIsEdit = createSelector(
  [inputSelectWorkflow],
  (workflow): boolean => workflow.isEdit
);

export const selectWorkflowScope = createSelector(
  [inputSelectWorkflow],
  (workflow): RecordType => workflow.formWorkflow.type
);

export const selectWorkflowChangeMade = createSelector(
  [inputSelectWorkflow],
  workflow => workflow.changeMade
);

export const selectWorkflowDesignerView = createSelector(
  [inputSelectWorkflow],
  workflow => workflow.workflowDesignerView
);

export const selectWorkflowCurrentNodePopulation = createSelector(
  [inputSelectWorkflow],
  workflow => {
    const foundNode = workflow.nodes.find(node => node.selected);
    if (!foundNode) return undefined;
    const currentStageEvents = (foundNode.data as WorkflowStage).events ?? [];
    return currentStageEvents.find(event => event.action === WorkflowEventAction.PopulateData)
      ?.workflowDataPopulation;
  }
);
export const selectWorkflowReadOnly = createSelector(
  [inputSelectWorkflow],
  workflow => workflow.readOnly
);
export const selectIsWorkflowDefault = createSelector(
  [inputSelectWorkflow],
  workflow => workflow.formWorkflow.category === Category.Default
);

export default workflowSlice.reducer;
