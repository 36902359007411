import { Box, ListItemButton, ListItemText } from "@mui/material";
import React, { FC } from "react";
import FeatureItem from "../formdesigner/FeatureItem";
import "./featurelist.scss";
import { Field, ListSectionItem, NonSelectedItem, SIDEBAR_ITEM, Table } from "enada-common";
import { EdisonExpandableMenu, EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";

interface FeatureListProps {
  getFeatures?: any;
  featureType?: any;
  data: any;
  disabledItemList?: NonSelectedItem[];
  isDraggable?: boolean;
  onItemClick?: (field: any) => any;
}

const FeatureList: FC<FeatureListProps> = ({
  getFeatures,
  featureType,
  data,
  disabledItemList,
  isDraggable = true,
  onItemClick
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      {Object.keys(featureType).map((type, i) => {
        const featureObject = getFeatures(type);
        const featureData: [] = data
          .filter((feature: Field | Table) => feature.dataType === type)
          .sort((a: Field | Table, b: Field | Table) => {
            if (a.displayName && b.displayName) {
              return a.displayName.localeCompare(b.displayName);
            }
            return 0;
          });

        return (
          <div key={i}>
            {featureData.length > 0 && (
              <EdisonExpandableMenu
                sticky={true}
                key={i}
                name={t(featureObject.fieldName)}
                icon={featureObject.icon}
                onItemClick={function (item: ListSectionItem): void {
                  // TODO: Implement function
                }}
              >
                {featureData.map((item: Field | Table) => {
                  const isDisabled = isItemDisabled(item, disabledItemList);
                  return !isDraggable ? (
                    <Box key={item.id} className="sideBarItem">
                      <FeatureItemComponent
                        isDisabled={isDisabled}
                        item={item}
                        onItemClick={onItemClick}
                      />
                    </Box>
                  ) : (
                    <FeatureItem
                      key={item.id}
                      data={{
                        id: item.id,
                        type: SIDEBAR_ITEM,
                        component: item
                      }}
                      type={SIDEBAR_ITEM}
                      disabled={isDisabled}
                    >
                      <FeatureItemComponent isDisabled={isDisabled} item={item} />
                    </FeatureItem>
                  );
                })}
              </EdisonExpandableMenu>
            )}
          </div>
        );
      })}
    </>
  );
};

const isItemDisabled = (item: Field | Table, disabledList?: NonSelectedItem[]): boolean => {
  if (!disabledList) return false;
  const found = disabledList.find(disabledItem =>
    !disabledItem.type
      ? disabledItem.id === item.id
      : disabledItem.id === item.id && disabledItem.type === item.dataType
  );
  return !!found;
};

interface FeatureItemComponentProps {
  isDisabled: boolean;
  item: Field | Table;
  onItemClick?: (item: any) => any;
}

const FeatureItemComponent: FC<FeatureItemComponentProps> = ({ isDisabled, item, onItemClick }) => {
  return (
    <ListItemButton
      disableGutters
      onClick={() => onItemClick && onItemClick(item)}
      disabled={isDisabled}
      sx={{ p: "5px 30px" }}
    >
      <ListItemText
        data-testid={`feature-item-${item.name}`}
        className="list-item"
        primary={
          <EdisonTypography
            variant="data2"
            sx={{ whiteSpace: "normal" }}
            title={item.displayName || ""}
          />
        }
      />
    </ListItemButton>
  );
};
export default FeatureList;
