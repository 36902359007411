import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Form,
  PopulationOperationType,
  reducerStatus,
  RowOperationType,
  TableRowPeriodicGrandTotal,
  WorkflowDataPopulation,
  WorkflowDataPopulationFieldValue,
  WorkflowPopulationTableRow
} from "enada-common";
import {
  getWorkflowPopulationRows,
  getWorkflowPopulationTableConfigs,
  getWorkflowPopulationTableTotals,
  getWorkflowPopulationValues,
  postWorkflowPopulationRows,
  postWorkflowPopulationTableConfigs,
  postWorkflowPopulationValues
} from "../../services/APIService";
import { mergeTableOperations } from "../../utils/mappers/populationToRecordMappers";
import { RootState } from "../store";
import { FrontendRecordValue, RecordValueType } from "./recordSlice";

export interface DataPopulationState {
  form?: Form;
  backendPopulationFieldValues?: WorkflowDataPopulationFieldValue[];
  frontendPopulationFieldValues: FrontendRecordValue[]; //reused from Projects would be better to move it to common
  dataPopulation?: WorkflowDataPopulation;
  backendPopulationTableRows: WorkflowPopulationTableRow[];
  tableOperations: PopulationOperationType[];
  status: reducerStatus;
  changeMade: boolean;
  fieldValuesLoaded: boolean;
  periodicGrandTotals: TableRowPeriodicGrandTotal[];
}

const initialState: DataPopulationState = {
  form: undefined,
  backendPopulationFieldValues: undefined,
  frontendPopulationFieldValues: [],
  dataPopulation: undefined,
  backendPopulationTableRows: [],
  tableOperations: [],
  status: "idle",
  changeMade: false,
  fieldValuesLoaded: false,
  periodicGrandTotals: []
};

export const getPopulationFieldValuesAsync = createAsyncThunk(
  "dataPopulation/getPopulationFieldValues",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationValues(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const savePopulationFieldValuesAsync = createAsyncThunk(
  "dataPopulation/savePopulationFieldValues",
  async ({ id, values }: { id: number; values: unknown }) => {
    await postWorkflowPopulationValues(values, id);
  }
);
export const getPopulationRowsAsync = createAsyncThunk(
  "dataPopulation/getWorkflowPopulationRows",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationRows(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const savePopulationRowsAsync = createAsyncThunk(
  "dataPopulation/savePopulationRows",
  async (
    {
      tableOperations,
      populationId
    }: { tableOperations: PopulationOperationType[]; populationId: number },
    { rejectWithValue }
  ) => {
    const response = (await postWorkflowPopulationRows(tableOperations, populationId)) as any;
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getPopulationTableConfigsAsync = createAsyncThunk(
  "dataPopulation/getWorkflowPopulationTableConfigsAsync",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationTableConfigs(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const savePopulationTableConfigsAsync = createAsyncThunk(
  "dataPopulation/savePopulationTableConfigsAsync",
  async (
    { populationId, tableConfigs }: { populationId: number; tableConfigs: any[] },
    { rejectWithValue }
  ) => {
    const response = await postWorkflowPopulationTableConfigs(tableConfigs, populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const getPopulationTableTotalsAsync = createAsyncThunk(
  "dataPopulation/getPopulationTableTotalsAsync",
  async (
    { tableId, populationId }: { tableId: number; populationId: number },
    { rejectWithValue }
  ) => {
    const response = await getWorkflowPopulationTableTotals(populationId, tableId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return { tableId: tableId, totals: response.data };
  }
);
const dataPopulationSlice = createSlice({
  name: "dataPopulation",
  initialState,
  reducers: {
    setPopulationTableOperations: (state, action: PayloadAction<RowOperationType[]>) => {
      state.tableOperations = mergeTableOperations(state.tableOperations, action.payload);
    },
    setDataPopulation: (state, action: PayloadAction<WorkflowDataPopulation>) => {
      state.dataPopulation = action.payload;
    },
    updateDataPopulation: (
      state,
      action: PayloadAction<{
        key: keyof WorkflowDataPopulation;
        changeValue: unknown;
      }>
    ) => {
      const { key, changeValue } = action.payload;
      state.dataPopulation = { ...state.dataPopulation, [key]: changeValue };
      state.changeMade = true;
    },
    setPopulationFrontendValues: (state, action: PayloadAction<FrontendRecordValue[]>) => {
      state.frontendPopulationFieldValues = action.payload;
      state.fieldValuesLoaded = true;
    },
    updatePopulationFrontendValues: (
      state,
      action: PayloadAction<{
        id?: number;
        changeValue: unknown;
        type?: RecordValueType;
      }>
    ) => {
      const { id, changeValue, type } = action.payload;
      if (id === undefined) return;
      if (state.frontendPopulationFieldValues.some(fieldValue => fieldValue.fieldId === id)) {
        state.frontendPopulationFieldValues = state.frontendPopulationFieldValues.map(fieldValue =>
          fieldValue.fieldId === id
            ? { ...fieldValue, value: changeValue, changeMade: true }
            : fieldValue
        );
        state.changeMade = true;
        return;
      }
      state.frontendPopulationFieldValues = [
        ...state.frontendPopulationFieldValues,
        { fieldId: id, value: changeValue, changeMade: true, type: type }
      ];
      state.changeMade = true;
    },
    resetPopulationSlice: state => {
      state.tableOperations = [];
      state.backendPopulationFieldValues = undefined;
      state.backendPopulationTableRows = [];
      state.dataPopulation = undefined;
      state.frontendPopulationFieldValues = [];
      state.frontendPopulationFieldValues = [];
      state.changeMade = false;
      state.fieldValuesLoaded = false;
      state.periodicGrandTotals = [];
    },
    addNewDataPopulationRows: (
      state,
      action: PayloadAction<{ rows: WorkflowPopulationTableRow[] }>
    ) => {
      state.backendPopulationTableRows.push(...action.payload.rows);
    },
    setPopulationChangeMade: (state, action: PayloadAction<boolean>) => {
      state.changeMade = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPopulationFieldValuesAsync.fulfilled, (state, action) => {
        state.backendPopulationFieldValues = action.payload;
      })
      .addCase(getPopulationRowsAsync.fulfilled, (state, action) => {
        state.backendPopulationTableRows = action.payload;
        state.status = "idle";
      })
      .addCase(getPopulationRowsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getPopulationTableTotalsAsync.fulfilled, (state, action) => {
        const existingTotal = state.periodicGrandTotals.find(
          t => t.tableId === action.payload.tableId
        );
        if (!existingTotal) {
          state.periodicGrandTotals.push(action.payload);
        }
      });
  }
});
export const {
  updateDataPopulation,
  updatePopulationFrontendValues,
  setDataPopulation,
  setPopulationTableOperations,
  resetPopulationSlice,
  addNewDataPopulationRows,
  setPopulationFrontendValues
} = dataPopulationSlice.actions;

const inputSelectDataPopulation = (state: RootState) => state.dataPopulation;

export const selectPopulationFrontendFieldValues = createSelector(
  [inputSelectDataPopulation],
  data => data.frontendPopulationFieldValues
);

export const selectPopulationObject = createSelector(
  [inputSelectDataPopulation],
  data => data.dataPopulation
);

export const selectPopulationBackendValues = createSelector(
  [inputSelectDataPopulation],
  data => data.backendPopulationFieldValues
);

export const selectBackendPopulationTableRows = createSelector(
  [inputSelectDataPopulation],
  data => data.backendPopulationTableRows
);

export const selectPopulationSliceStatus = createSelector(
  [inputSelectDataPopulation],
  data => data.status
);

export const selectPopulationChangeMade = createSelector(
  [inputSelectDataPopulation],
  data => data.changeMade
);
export const selectPopulationFieldValuesLoaded = createSelector(
  [inputSelectDataPopulation],
  data => data.fieldValuesLoaded
);
export const selectPopulationPeriodicGrandTotals = createSelector(
  [inputSelectDataPopulation],
  data => data.periodicGrandTotals
);
export const selectPopulationTableOperations = createSelector(
  [inputSelectDataPopulation],
  data => data.tableOperations
);

export default dataPopulationSlice.reducer;
