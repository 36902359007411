import React, { memo } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@mui/material";
import { useTranslation } from "react-i18next";

const UnsavedChangesModal = memo(function UnsavedChangesModal({
  open,
  onConfirm,
  onCancel
}: {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation(["common"]);

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>{t("programsSection.unsavedChanges.title")}</DialogTitle>
      <Divider />
      <DialogContent>
        <Alert severity="warning" sx={{ boxShadow: "none" }}>
          <p>{t("programsSection.unsavedChanges.warning1")}</p>
          <p>{t("programsSection.unsavedChanges.warning2")}</p>
        </Alert>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onConfirm}>
          {t("yes")}
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default UnsavedChangesModal;
