import { Box, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { PersonaEntity, RecordRole, RecordTemplate, RecordType, Workflow } from "enada-common";

import { useTranslation } from "react-i18next";

import { WorkflowSelect } from "./WorkflowSelect";
import { useGetWorkflowsQuery } from "../../../../services/api";
import { useAppDispatch } from "../../../../store/hooks";
import {
  updateAssignedRoles,
  updateIndividualTemplateProperty
} from "../../../../store/slices/templatesSlice";
import { selectInData } from "../../../../utils/rtkQuery";
import PeoplePickerWith from "../../../../components/peoplePickerWith/PeoplePickerWith";

type RecordConfigProps = {
  template: RecordTemplate | Partial<RecordTemplate> | null;
  templateIsLoading: boolean;
  owners: PersonaEntity[];
  managers: PersonaEntity[];
  readers: PersonaEntity[];
};

export const RecordConfig: FC<RecordConfigProps> = ({
  template,
  templateIsLoading,
  owners,
  managers,
  readers
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const selectWorkflows = useMemo(() => {
    return selectInData<Workflow[]>(data =>
      data
        ?.filter(workflow => workflow.type === (template?.recordType ?? RecordType.Projects))
        .sort(
          (a: Workflow, b: Workflow) => a?.displayName?.localeCompare(b?.displayName as string) ?? 0
        )
    );
  }, [template?.recordType]);

  const { workflows = [], isLoading: workflowsLoading } = useGetWorkflowsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      workflows: selectWorkflows(result)
    })
  });

  const isLoading = workflowsLoading || templateIsLoading;

  return (
    <Stack direction="column" spacing={4} className="right-container">
      <Box data-testid={"templates-owners-people-picker"}>
        <PeoplePickerWith
          label={t("owners")}
          onChange={personas => {
            dispatch(updateAssignedRoles({ role: RecordRole.Owner, personas }));
          }}
          multiple={true}
          value={owners}
          helperText={{ i18nKey: "recordTemplate.owners", fontWeight: "600" }}
        />
      </Box>

      <Box data-testid={"templates-readers-people-picker"}>
        <PeoplePickerWith
          label={t("readers")}
          onChange={personas => {
            dispatch(updateAssignedRoles({ role: RecordRole.Reader, personas }));
          }}
          multiple={true}
          value={readers}
          helperText={{ i18nKey: "recordTemplate.readers", fontWeight: "600" }}
        />
      </Box>

      <WorkflowSelect
        template={template}
        workflowsList={workflows}
        onChange={e => {
          dispatch(
            updateIndividualTemplateProperty({
              key: "workflow",
              value: e.target.value
            })
          );
        }}
        label={t("workflow")}
        helperText="recordTemplate.workflow"
        value={template?.workflow}
        isLoading={isLoading}
      />

      <Box data-testid={"templates-managers-people-picker"}>
        <PeoplePickerWith
          label={t("processManagers")}
          onChange={personas => {
            dispatch(
              updateAssignedRoles({
                role: RecordRole.Manager,
                personas
              })
            );
          }}
          multiple={true}
          value={managers}
          helperText={{ i18nKey: "processManagersDescription" }}
        />
      </Box>
    </Stack>
  );
};
