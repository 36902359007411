import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Features from "../../../components/features/Features";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";

import { Card, Category, Form, RecordType, sanitiseInternalFieldName } from "enada-common";
import { ConfigLayout } from "enada-components";
import "./carddesigner.scss";

import CardLivePreview from "../../../components/carddesigner/CardLivePreview";
import {
  resetLiveCard,
  selectCardDescription,
  selectCardDesignerIsEdit,
  selectCardDisabledItemList,
  selectCardFieldsData,
  selectCardInternalName,
  selectCardLivePreviewStatus,
  selectCardName,
  selectCardScope,
  selectLivePreviewCurrentCard,
  setCardDescription,
  setCardFieldsData,
  setCardName,
  setCardScope,
  setCurrentCard,
  setInternalCardName,
  setIsEdit
} from "../../../store/slices/cardDesignerSlice";
import { useHasModule } from "../../../utils/hooks/useHasModule";
import {
  useCreateCardMutation,
  useGetCardQuery,
  useUpdateCardMutation
} from "../../../services/api";

const CardDesigner: FC = () => {
  const { t } = useTranslation(["common"]);

  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState = location.state as any;
  const { isModuleEnabled } = useHasModule();

  const cardFieldsData = useAppSelector(selectCardFieldsData);
  const cardName = useAppSelector(selectCardName);
  const cardDescription = useAppSelector(selectCardDescription);
  const cardInternalName = useAppSelector(selectCardInternalName);
  const currentCard = useAppSelector(selectLivePreviewCurrentCard);
  const cardScope = useAppSelector(selectCardScope);
  const disabledItemList = useAppSelector(selectCardDisabledItemList);

  const livePreviewStatus = useAppSelector(selectCardLivePreviewStatus);
  const isEdit = useAppSelector(selectCardDesignerIsEdit);
  const { isLoading: cardIsLoading, data: card } = useGetCardQuery(locationState?.item?.id, {
    skip: !locationState?.item?.id
  });
  const [createCard] = useCreateCardMutation();
  const [updateCard] = useUpdateCardMutation();

  useEffect(() => {
    if (locationState?.item) {
      dispatch(setCurrentCard(card));
      dispatch(setIsEdit(true));
    }
  }, [card]);

  const mapCardResult = (): Card | Partial<Card> => {
    return {
      name: cardInternalName,
      displayName: cardName,
      description: cardDescription,
      cardType: cardScope as RecordType,
      isDeleted: false,
      fields: cardFieldsData
    };
  };


  useEffect(() => {
    if (livePreviewStatus === "idle" && currentCard && isEdit) {
      dispatch(setCardFieldsData(currentCard.fields || []));
      dispatch(setCardName(currentCard.displayName || ""));
      setInternalCardName(currentCard.name || "");
      setDescription(currentCard.description || "");
      dispatch(setInternalCardName(currentCard.name || ""));
      dispatch(setCardDescription(currentCard.description || ""));
      dispatch(setCardScope(currentCard.cardType || RecordType.Projects));
    }
  }, [currentCard, isEdit]);

  return (
    <Stack spacing={1}>
      {currentCard?.category === Category.Default && (
        <Alert severity="info">{t("viewingDefaultConfigMessage")}</Alert>
      )}
      <ConfigLayout
        t={(param: string) => {
          return param;
        }}
        saveDisabled={!cardName || !cardScope || currentCard?.category === Category.Default}
        title={!isEdit ? t("newCard") : t("editCard")}
        onSaveCallback={async () => {
          const formToSave: Form | null | Partial<Form> = mapCardResult();
          const response = isEdit
            ? await updateCard({ ...currentCard, ...formToSave })
            : await createCard(formToSave);

          dispatch(resetLiveCard());
          navigate(-1);
        }}
        onCancelCallback={() => {
          dispatch(resetLiveCard());
          navigate(-1);
        }}
        topPanelContent={
          <>
            <TextField
              className="input"
              variant="standard"
              label={t("cardName")}
              value={cardName ?? ""}
              onChange={e => {
                dispatch(setCardName(e.target.value));
                if (!isEdit)
                  dispatch(setInternalCardName(sanitiseInternalFieldName(e.target.value)));
              }}
            />
            <TextField
              className="input"
              label={t("internalName")}
              variant="standard"
              value={sanitiseInternalFieldName(cardName) ?? ""}
              disabled
            />
            <TextField
              className="input"
              variant="standard"
              label={t("description")}
              value={description ?? ""}
              onBlur={e => dispatch(setCardDescription(e.target.value))}
              onChange={e => setDescription(e.target.value)}
            />

            <FormControl className="input" variant="standard" required disabled={isEdit}>
              <InputLabel id="type-select">{t("scope")}</InputLabel>
              <Select
                labelId="type-select"
                variant="standard"
                value={cardScope ?? RecordType.Projects}
                onChange={e => dispatch(setCardScope(e.target.value))}
              >
                {Object.keys(RecordType)
                  .filter(type => isModuleEnabled(type as RecordType))
                  .map((key, index) => (
                    <MenuItem key={index} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Grid className="form-designer-page-root" container spacing={3}>
            <Grid item xs={3} className="form-designer-page-root__item">
              <Paper>
                <Box>
                  <Features disabledItemList={disabledItemList} noTable fromCardDesigner />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={9} className="form-designer-page-root__item live-preview-container">
              <CardLivePreview />
            </Grid>
          </Grid>
        </DndProvider>
      </ConfigLayout>
    </Stack>
  );
};

export default CardDesigner;
