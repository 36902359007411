import {
  Divider,
  MenuItem,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useTheme,
  TextField
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CodeIcon from "@mui/icons-material/Code";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import ImageIcon from "@mui/icons-material/Image";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { mdiFormatFontSizeDecrease, mdiFormatFontSizeIncrease } from "@mdi/js";
import Icon from "@mdi/react";
import React, { FC, useEffect, useState } from "react";
import { BaseRange, Editor, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { CustomEditor } from "../../../utils/richtext/custom-types";
import { fontFamilies } from "../../../utils/richtext/fontFamilies";
import {
  indent,
  outdent,
  setFontSizeUtil,
  toggleBlock,
  toggleMark,
  updateFontColour,
  updateFontFamily,
  updateFontSize
} from "../../../utils/richtext/richTextUtils";
import { insertImage } from "../../../utils/richtext/withImages";
import { insertLink, isLinkActive, unwrapLink } from "../../../utils/richtext/withLink";
import "./richtextfieldtoolbar.scss";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius
    }
  }
}));
export interface RichTextFieldToolbarProps {
  blurId: string;
}
const RichTextFieldToolbar: FC<RichTextFieldToolbarProps> = ({ blurId }) => {
  const editor = useSlate();
  const [color, setColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [fontSize, setFontSize] = useState(14);
  useEffect(() => {
    const marks = Editor.marks(editor);
    if (marks) {
      const formatted = marks as unknown as Record<string, any>;

      formatted["fontColour"] ? setColor(formatted["fontColour"]) : setColor("#000000");
      formatted["fontFamily"]
        ? setFontFamily(formatted["fontFamily"])
        : setFontFamily(fontFamilies[0]);
      formatted["fontSize"] && setFontSize(formatted["fontSize"]);
    }
  }, [Editor.marks(editor)]);

  const checkSelected = (value: string): boolean => {
    const marks = Editor.marks(editor);
    if (marks) {
      const formatted = marks as unknown as Record<string, any>;
      return Object.keys(formatted).includes(value);
    }
    return false;
  };
  const theme = useTheme();

  return (
    <Paper
      data-testid={"edison-richtext-toolbar"}
      elevation={1}
      className="rich-text-field-toolbar-root"
      style={{
        display: "flex",
        marginBottom: "7px",
        flexWrap: "wrap",
        width: "fit-content",
        borderRadius: 0,
        border: `1px solid ${theme.palette.divider}`
      }}
    >
      <StyledToggleButtonGroup size="small" value="left" id={`${blurId}-0`}>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-bold"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "bold");
          }}
          value="bold"
          selected={checkSelected("bold")}
          color="primary"
        >
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-italic"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "italic");
          }}
          value="italic"
          selected={checkSelected("italic")}
          color="primary"
        >
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-underline"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "underline");
          }}
          value="underline"
          selected={checkSelected("underline")}
          color="primary"
        >
          <FormatUnderlined />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-code"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "code");
          }}
          value="code"
          selected={checkSelected("code")}
          color="primary"
        >
          <CodeIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <StyledToggleButtonGroup size="small" value="left" id={`${blurId}-1`}>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-indent"}
          onMouseDown={e => {
            e.preventDefault();
            indent(editor, "indented");
          }}
          value="indented"
          color="primary"
        >
          <FormatIndentIncreaseIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-outdent"}
          onMouseDown={e => {
            e.preventDefault();
            outdent(editor);
          }}
          value="decrease-indent"
          color="primary"
        >
          <FormatIndentDecreaseIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-quote"}
          onMouseDown={e => {
            e.preventDefault();
            toggleBlock(editor, "block-quote");
          }}
          value="block-quote"
        >
          <FormatQuoteIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-bulletedlist"}
          onMouseDown={e => {
            e.preventDefault();
            toggleBlock(editor, "bulleted-list");
          }}
          value="bulleted-list"
        >
          <FormatListBulletedIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-numberedlist"}
          onMouseDown={e => {
            e.preventDefault();
            toggleBlock(editor, "numbered-list");
          }}
          value="numbered-list"
        >
          <FormatListNumberedIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <TextField
        data-testid={"edison-richtext-toolbar-fontsize"}
        id={`${blurId}-2`}
        className="font-size-picker"
        type="number"
        value={fontSize}
        size="small"
        onChange={e => {
          e.preventDefault();
          let parsed = parseFloat(e.target.value);
          if (isNaN(parsed) || parsed < 0.1) {
            parsed = 1;
          }
          setFontSizeUtil(editor, parsed);
        }}
      />
      <StyledToggleButtonGroup size="small" value="left" id={`${blurId}-3`}>
        <ToggleButton value="color-picker" data-testid={"edison-richtext-toolbar-colourpicker"}>
          <FormatColorFillIcon></FormatColorFillIcon>
          <ArrowDropDownIcon />
          <input
            id={`${blurId}-3-0`}
            type="color"
            value={color}
            onChange={e => updateFontColour(editor, e.target.value)}
          />
        </ToggleButton>
        <ToggleButton value="font-pickerÓ" data-testid={"edison-richtext-toolbar-fontpicker"}>
          <Select
            id={`${blurId}-3-1`}
            color="primary"
            value={fontFamily}
            variant="standard"
            onChange={e => {
              updateFontFamily(editor, e.target.value);
            }}
          >
            {fontFamilies.map((font, index) => (
              <MenuItem key={index} value={font} id={`${blurId}-3-1-${index}`}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </ToggleButton>
      </StyledToggleButtonGroup>
      <Divider flexItem orientation="vertical" />
      <StyledToggleButtonGroup size="small" value="left" id={`${blurId}-4`}>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-uploadimage"}
          onClick={() => {
            uploadImage(editor);
          }}
          value="insert-image"
        >
          <ImageIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-link"}
          onClick={e => {
            e.preventDefault();
            const url = window.prompt("Enter the URL of the link:");
            const currentLocations = editor.selection;
            if (!url) return;
            insertLink(editor, url);
            if (currentLocations === null) return;
            const { path, offset } = currentLocations.anchor;
            const copy = [...path];
            const leaf = copy.pop();
            if (leaf === undefined) return;
            const newPath = [...copy, leaf + 2];
            Transforms.insertNodes(editor, { text: " " }, { at: { path: newPath, offset: 0 } });
          }}
          value="insert-link"
          selected={isLinkActive(editor)}
        >
          <InsertLinkIcon />
        </ToggleButton>
        <ToggleButton
          data-testid={"edison-richtext-toolbar-removelink"}
          onMouseDown={e => {
            e.preventDefault();
            if (isLinkActive(editor)) {
              unwrapLink(editor);
            }
          }}
          value="remove-link"
          selected={false}
        >
          <LinkOffIcon />
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
};
const uploadImage = (editor: CustomEditor) => {
  const url = window.prompt("Enter the URL of the image:");
  if (!url) return false;
  insertImage(editor, url);
};

export default RichTextFieldToolbar;
