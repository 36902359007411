import {
  NotificationLevel,
  PersonaEntity,
  RecordType,
  WorkflowStageEditAccess
} from "enada-common";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Node } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCurrentNotification } from "../../../../store/slices/notificationSlice";
import {
  selectCurrentElement,
  selectIsWorkflowDefault,
  selectFormWorkflow,
  selectWorklowValidationErrorsByNode,
  updateElement
} from "../../../../store/slices/workflowSlice";
import { EdisonExpandableMenu, EdisonPeoplePickerField } from "enada-components";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";
import WorkflowFlag from "../workflowflag/WorkflowFlag";
import "./stageadvancedoptions.scss";

const StageAdvancedOptions = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const workflow = useAppSelector(selectFormWorkflow);
  const isDefault = useAppSelector(selectIsWorkflowDefault);
  const errors = useAppSelector(state =>
    selectWorklowValidationErrorsByNode(state, currentNode?.data?.name)
  );
  const isIdeaOrChallenge = [RecordType.Ideas, RecordType.Challenges].includes(workflow.type);

  const updateEditAccess = useCallback(
    (type: WorkflowStageEditAccess) => {
      if (!currentNode) return;
      // see https://stackoverflow.com/questions/1436438/how-do-you-set-clear-and-toggle-a-single-bit-in-javascript
      // for bitwise ops
      let updated = currentNode?.data.editAccess ?? 0;
      updated ^= type;
      dispatch(updateElement(updateNodeCustomData("editAccess", updated, currentNode as Node)));
    },
    [currentNode, dispatch]
  );

  useEffect(() => {
    if (
      currentNode?.data.configuration.recordType === RecordType.Ideas ||
      currentNode?.data.configuration.recordType === RecordType.Challenges
    )
      return;
    if ((currentNode?.data?.editAccess ?? 0) === 0) {
      dispatch(
        setCurrentNotification({
          title: t("workflowWarning"),
          message: t("workflowAdminEditMessage"),
          level: NotificationLevel.Warning
        })
      );
    }
  }, [currentNode?.data.configuration.recordType, currentNode?.data?.editAccess, dispatch, t]);

  return (
    <EdisonExpandableMenu
      name={t("advancedOptions")}
      icon={<CheckBoxOutlinedIcon />}
      noSidePadding
      sticky={false}
    >
      <Stack spacing={1} className="stage-advanced-options-root">
        {/* <WorkflowFlag
          checked={
            currentNode?.data?.readOnly ? currentNode.data.readOnly : false
          }
          primaryLabel={t("readOnly")}
          secondaryLabel={t("readOnlyMessage")}
          onChange={(e) => {
            if (currentNode) {
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "readOnly",
                    e.target.checked,
                    currentNode as Node
                  )
                )
              );
            }
          }}
        />*/}
        <WorkflowFlag
          disabled={isDefault}
          checked={Boolean(currentNode?.data.allowReadOnlyOverride)}
          primaryLabel={t("readOnlyOverride")}
          secondaryLabel={t("readOnlyOverrideMessage")}
          onChange={e => {
            if (!currentNode) return;

            dispatch(
              updateElement(
                updateNodeCustomData("allowReadOnlyOverride", e.target.checked, currentNode as Node)
              )
            );
          }}
        />

        {currentNode?.type === "StageReview" && (
          <WorkflowFlag
            checked={(WorkflowStageEditAccess.Reviewers & currentNode?.data?.editAccess) !== 0}
            onChange={() => updateEditAccess(WorkflowStageEditAccess.Reviewers)}
            primaryLabel={t("reviewersCanEdit")}
            secondaryLabel={t("reviewersCanEditMessage")}
            disabled={isDefault}
          />
        )}
        {!isIdeaOrChallenge && (
          <>
            <WorkflowFlag
              checked={(WorkflowStageEditAccess.Owners & currentNode?.data?.editAccess) !== 0}
              onChange={() => updateEditAccess(WorkflowStageEditAccess.Owners)}
              primaryLabel={t("ownersCanEdit")}
              secondaryLabel={t("ownersCanEditMessage")}
              disabled={isDefault}
            />
            <WorkflowFlag
              checked={(WorkflowStageEditAccess.Editors & currentNode?.data?.editAccess) !== 0}
              onChange={() => updateEditAccess(WorkflowStageEditAccess.Editors)}
              primaryLabel={t("editorsCanEdit")}
              secondaryLabel={t("editorsCanEditMessage")}
              disabled={isDefault}
            />
          </>
        )}
        {!isIdeaOrChallenge && (
          <EdisonPeoplePickerField
            multiple={true}
            label={t("editors")}
            disabled={isDefault}
            value={
              currentNode?.data?.editors
                ? currentNode?.data?.editors.map(
                    editor =>
                      ({
                        entityId: editor.entityId,
                        type: editor.permissionType
                      } as PersonaEntity)
                  )
                : undefined
            }
            onChange={changeValue => {
              if (!currentNode) return;
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "editors",
                    changeValue.map(change => ({
                      entityId: change.entityId,
                      permissionType: change.type
                    })),
                    currentNode as Node
                  )
                )
              );
            }}
            useInternalState={false}
            error={!!errors?.find(error => error.property === "stageEditors")}
          />
        )}
      </Stack>
    </EdisonExpandableMenu>
  );
};

export default StageAdvancedOptions;
