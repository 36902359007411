import React from "react";
import { LicenseType } from "enada-common";
import { inputSelectTenant } from "../../store/slices/userSlice";
import { useAppSelector } from "../../store/hooks";
import { useGetMyLicenseQuery, useGetTenantLicenseQuery } from "services/api";

interface HasMinimumLicense {
  children: JSX.Element | React.ReactNode;
  noMatchElement?: JSX.Element;
}

interface HasMinimumUserLicense extends HasMinimumLicense {
  minimumUserLicenseType: LicenseType;
  minimumTenantLicenseType?: LicenseType;
}

interface HasMinimumTenantLicense extends HasMinimumLicense {
  minimumUserLicenseType?: LicenseType;
  minimumTenantLicenseType: LicenseType;
}

const HasMinimumLicense = ({
  children,
  minimumUserLicenseType,
  minimumTenantLicenseType,
  noMatchElement
}: HasMinimumUserLicense | HasMinimumTenantLicense) => {
  const { data: myLicense } = useGetMyLicenseQuery();
  const tenant = useAppSelector(inputSelectTenant);
  const { data: tenantLicense } = useGetTenantLicenseQuery(tenant);

  const hasUserMinimumLicense = minimumUserLicenseType
    ? myLicense?.licenseType && myLicense.licenseType >= minimumUserLicenseType
    : true;
  const hasTenantMinimumLicense = minimumTenantLicenseType
    ? tenantLicense?.licenseType && tenantLicense.licenseType >= minimumTenantLicenseType
    : true;
  if (!hasUserMinimumLicense || !hasTenantMinimumLicense) {
    return noMatchElement;
  }
  return children;
};

export default HasMinimumLicense;
