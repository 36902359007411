import { InputAdornment, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import {
  fieldConfiguration,
  currencies,
  FieldDataType,
  UserCurrencyFieldProps as UserCurrencyFieldPropsCommon,
  useDebounce
} from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import { formatDecimals } from "../../utils/formatDecimals";

export interface UserCurrencyFieldProps extends UserCurrencyFieldPropsCommon {
  min?: number;
  max?: number;
  required?: boolean;
}

const UserCurrencyField: FC<UserCurrencyFieldProps> = ({
  label,
  currency,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  maxDecimalPlaces,
  min,
  max,
  setReadOnly,
  autoFocus,
  required
}) => {
  const [internalState, setInternalState] = useState(value || "");

  const isLessThanMin = (value: string) => {
    return value && min && Number(value) < min;
  };
  const isMoreThanMax = (value: string) => {
    return value && max && Number(value) > max;
  };
  const getIsValid = (value: string) => {
    return !(isLessThanMin(value) ?? isMoreThanMax(value));
  };

  const [isValid, setIsValid] = useState<boolean>(getIsValid(value));

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  useDebounce(
    () => {
      if (!isValid) return;
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState, isValid);
    },
    1000,
    [internalState]
  );

  const handleChange = (value: string) => {
    setInternalState(formatDecimals(value.toString(), maxDecimalPlaces));
    setIsValid(getIsValid(value));
  };

  const currencySymbol = currencies[currency as keyof typeof currencies]
    ? currencies[currency as keyof typeof currencies].currencySymbol
    : "nothing";

  const placeholder = () => {
    if (min && max) return `${min}-${max}`;
    return "";
  };

  return (
    <>
      {!readOnly ? (
        <TextField
          required={required}
          placeholder={placeholder()}
          variant="standard"
          type="number"
          label={!isInTable && label}
          fullWidth
          autoFocus={autoFocus}
          onBlur={() => {
            if (!isValid) return;
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState, true);
          }}
          onFocus={
            isInTable
              ? event => {
                  event.target.select();
                }
              : undefined
          }
          value={internalState}
          onChange={e => {
            handleChange(e.target.value);
          }}
          inputProps={{
            min,
            max
          }}
          helperText=""
          size="small"
          error={error ?? !isValid}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
          }}
        />
      ) : (
        <EdisonFieldIcon
          title={label}
          subtitle={
            !isNaN(internalState)
              ? `${currencySymbol} ${Number(internalState).toLocaleString()}`
              : ""
          }
          icon={currencySymbol}
          color={fieldConfiguration(FieldDataType.Currency).color}
          isIconOn={isIconOn}
          isTableModeOn={!!isInTable}
          additionalStyles={isInTable ? { textAlign: "right" } : undefined}
          required={required}
          error={required && internalState === ""}
        />
      )}
    </>
  );
};

export default UserCurrencyField;
export { UserCurrencyField };
