import React, { FC, ReactNode } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Fields from "../pages/admin/fields/Fields";
import Tables from "../pages/admin/tables/Tables";
import Cards from "../pages/admin/cards/Cards";
import Home from "../pages/home/Home";
import Layout from "../components/Layout";
import Admin from "../pages/admin/Admin";
import PageNotFound from "../pages/error/PageNotFound";
import FieldConfig from "../pages/admin/fieldconfig/FieldConfig";
import TableConfig from "../pages/admin/tableconfig/TableConfig";
import Templates from "../pages/admin/templates/Templates";
import Workflows from "../pages/admin/workflows/Workflows";
import WorkflowDesigner from "../pages/admin/workflowdesigner/WorkflowDesigner";
import Forms from "../pages/admin/forms/Forms";
import TemplateConfig from "../pages/admin/templates/templateConfig/TemplateConfig";
import FormDesigner from "../pages/admin/formdesigner/FormDesigner";
import News from "../pages/admin/news/News";
import NewsConfig from "../pages/admin/newsconfig/NewsConfig";
import CardDesigner from "../pages/admin/carddesigner/CardDesigner";
import FullNews from "../pages/home/fullnews/FullNews";
import ResourceReport from "../pages/resourcemanagement/resourcereport/ResourceReport";
import Calendars from "../pages/admin/calendars/Calendars";
import CalendarConfig from "../pages/admin/calendarconfig/CalendarConfig";
import ResourceManagement from "../pages/resourcemanagement/ResourceManagement";
import ResourceFields from "../pages/resourcemanagement/resourcefields/ResourceFields";
import ResourceConfig from "../pages/resourcemanagement/resourceconfig/ResourceConfig";
import Resources from "../pages/resourcemanagement/resources/Resources";
import TenantHome from "../pages/error/TenantHome";

import ErrorPage from "../pages/error/ErrorPage";
import Personal from "../pages/personal/Personal";
import ManageUsers from "../pages/admin/manageusers/ManageUsers";
import { LicenseType, RecordType, RoleType } from "enada-common";
import PrivateRoute from "../utils/PrivateRoute";
import Licenses from "../pages/admin/licenses/Licenses";
import RecordAccess from "../pages/projects/projectrecord/RecordAccess";
import PersonalData from "../pages/admin/personaldata/PersonalData";
import ProgramsDiscover from "../pages/programs/ProgramsDiscover";
import { RecycleBin } from "pages/admin/recycleBin/RecycleBin";
import ProjectsDiscover from "pages/discover/ProjectsDiscover";
import IdeasDiscover from "pages/discover/IdeasDiscover";
import BCDiscover from "pages/discover/BCDiscover";
import ChallengesDiscover from "pages/discover/ChallengesDiscover";
export interface RouteItem {
  key: string | RecordType;
  title?: string;
  tooltip?: string;
  path: string;
  component?: ReactNode | string;
  enabled: boolean;
  icon?: string;
  subRoutes?: Array<RouteItem>;
  isApp?: boolean;
  isIndex?: boolean;
  themeName?: string;
  isAdminOption?: boolean;
  description?: string;
  color?: string;
  notNavigation?: boolean;
  requiredRoles?: RoleType[];
}

const ComingSoon: React.FC = () => <div>Coming Soon</div>;

export const ContainerRouter = () => (
  <Route errorElement={<ErrorPage />}>
    <Route path="/:tenant/:instance/" element={<Layout />} errorElement={<TenantHome />}>
      <Route path="*" element={<PageNotFound />} />
      <Route path="home" element={<Navigate to="../" />} errorElement={<ErrorPage />} />
      <Route index element={<Home />} errorElement={<ErrorPage />} />
      {mainRoutes
        .filter(r => !r.isIndex)
        .map(route => (
          <Route
            key={route.key}
            path={`${route.path}/*`}
            element={route.component}
            errorElement={<ErrorPage />}
          />
        ))}
      {moduleRoutes.map(route => (
        <Route key={route.key} path={`${route.path}/*`} errorElement={<ErrorPage />}>
          <Route index element={route.component} errorElement={<ErrorPage />} />
          {route.subRoutes &&
            route.subRoutes.map(subroute => (
              <Route
                key={subroute.key}
                path={subroute.path}
                element={subroute.component}
                errorElement={<ErrorPage />}
              />
            ))}
        </Route>
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Route>
    <Route path="*" element={<TenantHome />} />
  </Route>
);

export const AdminRouter = () => (
  <Routes>
    <Route index element={<Admin />} errorElement={<ErrorPage />} />

    {adminRoutes.map(route => (
      <Route errorElement={<ErrorPage />} key={route.key} path={`${route.path}/*`}>
        <Route index element={route.component} />

        {route.subRoutes &&
          route.subRoutes.map(subroute => (
            <Route
              key={subroute.key}
              path={subroute.path}
              element={subroute.component}
              errorElement={<ErrorPage />}
            />
          ))}
      </Route>
    ))}
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export const PersonalRouter = () => (
  <Routes>
    <Route index element={<Personal />} errorElement={<ErrorPage />} />
    {personalRoutes.map(route => (
      <Route errorElement={<ErrorPage />} key={route.key} path={`${route.path}/*`}>
        <Route index element={route.component} errorElement={<ErrorPage />} />
        {route.subRoutes &&
          route.subRoutes.map(subroute => (
            <Route
              key={subroute.key}
              path={subroute.path}
              element={subroute.component}
              errorElement={<ErrorPage />}
            />
          ))}
      </Route>
    ))}
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export const ResourcesRouter = () => (
  <Routes>
    <Route index element={<ResourceManagement />} errorElement={<ErrorPage />} />
    {resourceRoutes.map(route => (
      <Route
        errorElement={<ErrorPage />}
        key={route.key}
        id={`${route.path}/*`}
        path={`${route.path}/*`}
      >
        <Route index element={route.component} errorElement={<ErrorPage />} />
        {route.subRoutes &&
          route.subRoutes.map(subroute => (
            <Route
              key={subroute.key}
              path={subroute.path}
              element={subroute.component}
              errorElement={<ErrorPage />}
            />
          ))}
      </Route>
    ))}
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export const mainRoutes: Array<RouteItem> = [
  {
    key: "home",
    path: "home",
    enabled: true,
    component: <Home />,
    isAdminOption: false,
    isIndex: false,
    description: "homeDescription"
  },
  {
    key: "tenanthome",
    path: "/",
    enabled: true,
    component: <TenantHome />,
    isAdminOption: false,
    notNavigation: true,
    isIndex: true,
    description: "homeDescription"
  },
  {
    key: "news",
    path: "news",
    enabled: true,
    component: <FullNews />,
    isAdminOption: false,
    isIndex: false,
    notNavigation: true,
    description: "fullNewsDesctription"
  },

  // {
  //   key: "discover",
  //   path: "discover",
  //   enabled: true,
  //   component: <ComingSoon />,
  //   description: "discoverDescription",
  // },
  {
    key: "personal",
    path: "personal",
    enabled: true,
    component: <PersonalRouter />,
    description: "personalDescription"
  },
  {
    key: "resources",
    path: "resourcesadmin",
    requiredRoles: [RoleType.TenantAdmin, RoleType.InstanceResourceManager, RoleType.InstanceAdmin],
    component: (
      <PrivateRoute
        roles={[RoleType.TenantAdmin, RoleType.InstanceResourceManager, RoleType.InstanceAdmin]}
        minimumUserLicenseType={LicenseType.Professional}
      >
        <ResourcesRouter />
      </PrivateRoute>
    ),
    enabled: true
  },
  {
    key: "admin",
    path: "admin",
    enabled: true,
    requiredRoles: [RoleType.InstanceAdmin, RoleType.TenantAdmin],
    component: (
      <PrivateRoute
        roles={[RoleType.InstanceAdmin, RoleType.TenantAdmin]}
        minimumUserLicenseType={LicenseType.Base}
      >
        <AdminRouter />
      </PrivateRoute>
    ),
    description: "adminDescription"
  }
];

export const moduleRoutes: Array<RouteItem> = [
  {
    key: RecordType.Programs,
    path: "programs",
    enabled: true,
    component: <ProgramsDiscover />,
    description: "programsDescription",
    subRoutes: [
      {
        key: "recordId",
        path: ":recordId",
        enabled: true,
        component: <RecordAccess />,
        description: "fieldsDescription"
      }
    ]
  },

  {
    key: RecordType.Projects,
    path: "projects",
    enabled: true,
    component: <ProjectsDiscover />,
    description: "homeDescription",
    subRoutes: [
      {
        key: "recordId",
        path: ":recordId",
        enabled: true,
        component: <RecordAccess />,
        description: "fieldsDescription"
      }
    ]
  },
  {
    key: RecordType.BusinessCase,
    path: "businesscase",
    enabled: true,
    component: <BCDiscover />,
    description: "homeDescription",
    subRoutes: [
      {
        key: "recordId",
        path: ":recordId",
        enabled: true,
        component: <RecordAccess />,
        description: "fieldsDescription"
      }
    ]
  },
  {
    key: RecordType.Challenges,
    path: "challenges",
    enabled: true,
    component: <ChallengesDiscover />,
    subRoutes: [
      {
        key: "recordId",
        path: ":recordId",
        enabled: true,
        component: <RecordAccess />,
        description: "fieldsDescription"
      }
    ],

    description: "homeDescription"
  },
  {
    key: RecordType.Ideas,
    path: "ideas",
    enabled: true,
    component: <IdeasDiscover />,
    subRoutes: [
      {
        key: "recordId",
        path: ":recordId",
        enabled: true,
        component: <RecordAccess />,
        description: "fieldsDescription"
      }
    ],

    description: "homeDescription"
  }
];

export const personalRoutes: Array<RouteItem> = [
  {
    key: "personal",
    path: "personal",
    component: <Personal />,
    enabled: true,
    color: "#C5724F",
    description: "personalDescription",
    subRoutes: [
      {
        key: "personal",
        path: "dashboard",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesDashboardDescription"
      },
      {
        key: "personal",
        path: "mywork",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesMyWorkDescription"
      },
      {
        key: "personal",
        path: "mycards",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesMyCardsDescription"
      },
      {
        key: "personal",
        path: "approvals",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesApprovalsDescription"
      },
      {
        key: "personal",
        path: "changerequests",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesChangeRequestsDescription"
      },
      {
        key: "personal",
        path: "owneditems",
        enabled: true,
        component: <ComingSoon />,
        isAdminOption: false,
        description: "resourcesOwedItemsDescription"
      }
    ]
  }
];

export const resourceRoutes: Array<RouteItem> = [
  {
    key: "resources",
    path: "resources",
    component: <Resources />,
    enabled: true,
    color: "#C5724F",
    description: "resourcesDescription",
    subRoutes: [
      {
        key: "resources",
        path: "newresource",
        enabled: true,
        component: <ResourceConfig />,
        isAdminOption: true,
        description: "resourcesReportsDescription"
      },
      {
        key: "resources",
        path: "editresource",
        enabled: true,
        component: <ResourceConfig />,
        isAdminOption: true,
        description: "resourcesReportsDescription"
      }
    ]
  },
  {
    key: "reports",
    path: "reports",
    component: <ResourceReport />,
    enabled: true,
    color: "#4B69D1",
    description: "resourcesReportsDescription"
  },
  {
    key: "resourceFields",
    path: "resourceFields",
    requiredRoles: [RoleType.InstanceAdmin, RoleType.TenantAdmin],
    component: (
      <PrivateRoute roles={[RoleType.InstanceAdmin, RoleType.TenantAdmin]}>
        <ResourceFields />
      </PrivateRoute>
    ),
    enabled: true,
    color: "#418F48",
    description: "resourcesFieldsDescription"
  }
];

export const adminRoutes: Array<RouteItem> = [
  {
    key: "fields",
    path: "fields",
    enabled: true,
    component: <Fields />,
    isAdminOption: true,
    description: "fieldsDescription",
    color: "#C78506",
    subRoutes: [
      {
        key: "newfield",
        path: "newfield",
        enabled: true,
        component: <FieldConfig />,
        isAdminOption: true,
        description: "fieldsDescription"
      },
      {
        key: "editfield",
        path: "editfield",
        enabled: true,
        component: <FieldConfig />,
        isAdminOption: true,
        description: "fieldsDescription"
      }
    ]
  },
  {
    key: "tables",
    path: "tables",
    enabled: true,
    component: <Tables />,
    color: "#2499D6",
    isAdminOption: true,
    description: "tablesDescription",
    subRoutes: [
      {
        key: "newtable",
        path: "newtable",
        enabled: true,
        component: <TableConfig />,
        isAdminOption: true,
        description: "tablesDescription"
      },
      {
        key: "edittable",
        path: "edittable",
        enabled: true,
        component: <TableConfig />,
        isAdminOption: true,
        description: "tablesDescription"
      }
    ]
  },
  {
    key: "forms",
    path: "forms",
    enabled: true,
    component: <Forms />,
    color: "#717EEE",
    isAdminOption: true,
    description: "formsDescription",
    subRoutes: [
      {
        key: "newform",
        path: "newform",
        enabled: true,
        component: <FormDesigner />,
        isAdminOption: false,
        description: "formDesignerDescription"
      },
      {
        key: "editform",
        path: "editform",
        enabled: true,
        component: <FormDesigner />,
        isAdminOption: false,
        description: "formDesignerDescription"
      }
    ]
  },
  {
    key: "cards",
    path: "cards",
    enabled: true,
    component: <Cards />,
    isAdminOption: true,
    color: "#EC6737",
    description: "cardsDescription",
    subRoutes: [
      {
        key: "newcard",
        path: "newcard",
        enabled: true,
        component: <CardDesigner />,
        isAdminOption: false,
        description: "cardDesignerDescription"
      },
      {
        key: "editcard",
        path: "editcard",
        enabled: true,
        component: <CardDesigner />,
        isAdminOption: false,
        description: "cardDesignerDescription"
      }
    ]
  },
  {
    key: "workflows",
    path: "workflows",
    enabled: true,
    component: <Workflows />,
    isAdminOption: true,
    color: "#1BAB3B",
    description: "workflowsDescription",
    subRoutes: [
      {
        key: "workflowdesigner",
        path: "workflowdesigner",
        enabled: true,
        component: <WorkflowDesigner />,
        isAdminOption: false,
        description: "workflowDesignerDescription"
      }
    ]
  },
  {
    key: "templates",
    path: "templates",
    enabled: true,
    component: <Templates />,
    isAdminOption: true,
    color: "#0AA4A4",
    description: "templatesDescription",
    subRoutes: [
      {
        key: "newtemplate",
        path: "newtemplate",
        enabled: true,
        component: <TemplateConfig />,
        isAdminOption: true,
        description: "newTemplateDescription"
      },
      {
        key: "edittemplate",
        path: "edittemplate",
        enabled: true,
        component: <TemplateConfig />,
        isAdminOption: true,
        description: "editTemplateDescription"
      }
    ]
  },
  {
    key: "news",
    path: "news",
    enabled: true,
    component: <News />,
    isAdminOption: true,
    description: "newsDescription",
    color: "#EC479A",
    subRoutes: [
      {
        key: "newnews",
        path: "newnews",
        enabled: true,
        component: <NewsConfig />,
        isAdminOption: true,
        description: "newsDescription"
      },
      {
        key: "editnews",
        path: "editnews",
        enabled: true,
        component: <NewsConfig />,
        isAdminOption: true,
        description: "newsDescription"
      }
    ]
  },
  // {
  //   key: "reports",
  //   path: "reports",
  //   enabled: false,
  //   component: <ComingSoon />,
  //   isAdminOption: true,
  //   color: "#0047FF",
  //   description: "reportsDescription",
  // },
  {
    key: "recycleBin",
    path: "recyclebin",
    enabled: true,
    component: <RecycleBin />,
    isAdminOption: true,
    color: "#F44336",
    description: "recyclebinDescription"
  },
  {
    key: "calendars",
    path: "calendars",
    enabled: true,
    component: <Calendars />,
    isAdminOption: true,
    description: "calendarsDescription",
    color: "#D02D37",
    subRoutes: [
      {
        key: "newcalendar",
        path: "newcalendar",
        enabled: true,
        component: <CalendarConfig />,
        isAdminOption: true,
        description: "calendarDescription"
      },
      {
        key: "editcalendar",
        path: "editcalendar",
        enabled: true,
        component: <CalendarConfig />,
        isAdminOption: true,
        description: "calendarDescription"
      }
    ]
  },
  // {
  //   key: "languages",
  //   path: "languages",
  //   enabled: false,
  //   component: <ComingSoon />,
  //   isAdminOption: true,
  //   color: "#DB36F4",
  //   description: "languagesDescription",
  // },
  {
    key: "manageAccess",
    path: "manageAccess",
    enabled: false,
    component: <ManageUsers />,
    isAdminOption: true,
    color: "#525252",
    description: "manageAccessDescription"
  },
  {
    key: "licenses",
    path: "licenses",
    enabled: false,
    component: <Licenses />,
    isAdminOption: true,
    color: "#525252",
    description: "manageLicensesDescription"
  },
  {
    key: "personaldata",
    path: "personaldata",
    enabled: false,
    component: <PersonalData />,
    isAdminOption: true,
    color: "#525252",
    description: "managePersonalDataDescription"
  }
];
