import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react-thin";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { EdisonResourceComponentProps } from "../resourceutilization/ResourceUtilization";
import { schedulerProConfig } from "./SchedulerProConfig";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import ReactDOMServer from "react-dom/server";
import "./schedulerpro.scss";
import EventDialog from "./dialog/EventDialog";
import { ProjectModel } from "@bryntum/schedulerpro-thin";

const SchedulerPro: FC<EdisonResourceComponentProps> = ({
  project,
  columns,
  onInstanceReady,
  assignments,
  disabled,
  readOnly,
  startDate,
  endDate,
  showTooltip,
  dialogTitleDisplayName,
  recordDisplayName,
  taskDisplayName,
  durationDisplayName,
  ownersDisplayName,
  fromDisplayName,
  toDisplayName,
  recordOwners
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any>(null);
  const schedulerRef = useRef<any>();

  useEffect(() => {
    if (onInstanceReady && schedulerRef?.current) {
      onInstanceReady(schedulerRef.current);
    }
  }, [schedulerRef.current]);

  const projectAvatar = (text: string) => {
    return (
      <>
        <RocketLaunchOutlinedIcon />
        {text}
      </>
    );
  };

  const handleDialogClose = () => {
    setActiveEvent(null);
    setDialogOpen(false);
  };

  const onEventRenderer = useCallback(
    ({ eventRecord }: any) => {
      return {
        children: [
          {
            className: "project-name",
            html: ReactDOMServer.renderToString(projectAvatar(eventRecord.projectName ?? ""))
          },
          {
            className: "event-name",
            text: eventRecord.name
          }
        ]
      };
    },
    [project]
  );

  const onEventClick = ({ eventRecord }: any) => {
    setActiveEvent(eventRecord);
    setDialogOpen(true);
  };

  return (
    <div className="schedulerpro-root enada-bryntum-grid">
      <BryntumSchedulerPro
        data-testid={"edison-scheduler"}
        ref={schedulerRef}
        {...schedulerProConfig}
        project={project as unknown as ProjectModel}
        rowHeight={70}
        columns={columns}
        startDate={startDate}
        endDate={endDate}
        disabled={disabled}
        readOnly={readOnly}
        showTooltip={showTooltip}
        cellTooltipFeature={showTooltip}
        taskEditFeature={true}
        scheduleTooltipFeature={showTooltip}
        onBeforeEventEdit={() => {
          return false;
        }}
        onEventClick={onEventClick}
        eventRenderer={onEventRenderer}
      />
      <EventDialog
        data-testid={"edison-scheduler-event-dialog"}
        open={dialogOpen}
        close={handleDialogClose}
        activeEvent={activeEvent}
        assignments={assignments}
        dialogTitleDisplayName={dialogTitleDisplayName}
        recordDisplayName={recordDisplayName}
        taskDisplayName={taskDisplayName}
        durationDisplayName={durationDisplayName}
        ownersDisplayName={ownersDisplayName}
        fromDisplayName={fromDisplayName}
        toDisplayName={toDisplayName}
        recordOwners={recordOwners}
      />
    </div>
  );
};

export default SchedulerPro;
