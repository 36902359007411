import { apiConfig } from "../config/authConfig";
import { store } from "../store/store";
import { getTenantAndInstanceFromURL } from "./urlHelpers";

export const getHeaders = (
  accessToken: string,
  endpoint: string,
  additionalHeaders?: Headers,
  excludeContentTypeHeader?: boolean
): Headers => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  const appState = store.getState();
  const configState = appState.config;

  const { tenant, instance } = getTenantAndInstanceFromURL();

  if (!excludeContentTypeHeader) {
    headers.append("Content-Type", "application/json");
  }

  headers.append("Access-Control-Max-Age", "86400");
  headers.append("Authorization", bearer);
  headers.append("edison365-tenant", tenant.toLowerCase()); // ###### ensure always lower case ######
  headers.append("edison365-instance", instance.toLowerCase()); // ###### ensure always lower case ######
  headers.append("Cache-Control", "no-cache");

  // Add region for all endpoints except management
  if (!endpoint.includes(apiConfig.managementApi) && configState.region) {
    headers.append("edison365-region", configState.region);
  }

  if (additionalHeaders) {
    additionalHeaders.forEach((value, key) => {
      headers.append(key, value);
    });
  }

  return headers;
};
