import { IDynamicPerson } from "@microsoft/mgt-react";
import {
  PermissionType,
  PersonaEntity,
  RecordPermission,
  RecordRole,
  RecordTemplate,
  RecordType
} from "enada-common";
import { useEffect, useMemo, useState } from "react";
import { getGraphPeopleByTypePermission } from "../graph/getGraphPeopleByTypePermission";

export const useGetDynamicPersons = (
  recordRole: RecordRole,
  currentTemplate: RecordTemplate | Partial<RecordTemplate> | null | undefined,
  recordPermissions: RecordPermission[] | undefined,
  recordType?: RecordType,
  templateIsLoading?: boolean,
  ideaCreation?: boolean,
  parentPermissions?: RecordPermission[] | undefined
) => {
  const [filteredPersons, setFilteredPersons] = useState<IDynamicPerson[]>();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (templateIsLoading) return;
    const getFilteredPersons = async () => {
      let permissions = ideaCreation ? recordPermissions : currentTemplate?.templatePermissions;
      if (recordType === RecordType.Ideas && !ideaCreation) {
        //Ensuring to pass the actual owner, or manager (depending on recordRole passed) if the record is an idea
        permissions = [...(parentPermissions || []), ...(recordPermissions || [])];
      }
      let peopleList = (await getGraphPeopleByTypePermission(recordRole, permissions)) ?? []; // returns an empty array if no users are found, it means we know if filteredPersons has been updated as it will know longer be undefined

      if (ideaCreation) {
        //If we are creating an idea the owner list contains the owners and initially the ideators
        const ownerList =
          (await getGraphPeopleByTypePermission(RecordRole.Owner, recordPermissions)) ?? [];
        peopleList = [...ownerList, ...peopleList];
        //Remove duplicates
        peopleList = [...peopleList.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()];
      }
      setFilteredPersons(peopleList);
      setHasLoaded(true);
    };

    getFilteredPersons();
  }, [currentTemplate, recordRole, parentPermissions]);

  const selectedPeople = useMemo(() => {
    let flatRecordPermissions =
      recordPermissions?.filter(el => el.role === recordRole).map(r => r.entityId) ?? [];
    if (ideaCreation)
      flatRecordPermissions =
        recordPermissions?.filter(el => el.role === RecordRole.Owner).map(r => r.entityId) ?? [];

    return filteredPersons?.filter(el => flatRecordPermissions?.includes(el.id?.toString() ?? ""));
  }, [filteredPersons, recordPermissions, recordRole]);

  const peoplePickerValue: PersonaEntity[] = useMemo(
    () =>
      selectedPeople?.map(person => ({
        entityId: person.id ?? "",
        type: PermissionType.User,
        userName: person.displayName ?? "",
        presence: ""
      })) ?? [],
    [selectedPeople]
  );

  return { filteredPersons, selectedPeople, peoplePickerValue, hasLoaded };
};
