import React, { FC, ReactElement } from "react";
import { ListItemIcon, MenuItem, useMediaQuery } from "@mui/material";
import SettingsBrightnessOutlinedIcon from "@mui/icons-material/SettingsBrightnessOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useTranslation } from "react-i18next";
import { EdisonTypography } from "enada-components";
import { useAppDispatch } from "../../store/hooks";
import { setThemeName } from "../../store/slices/userSlice";
import { getThemeName } from "../../utils/getThemeName";

interface ThemeSelectMenuProps {
  closeMenu: any;
}

const ThemeSelectMenu: FC<ThemeSelectMenuProps> = ({ closeMenu }): ReactElement => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation("common");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const availableThemes = [
    {
      id: "system",
      name: t("system"),
      icon: <SettingsBrightnessOutlinedIcon />
    },
    { id: "dark", name: t("dark"), icon: <DarkModeOutlinedIcon /> },
    { id: "light", name: t("light"), icon: <LightModeOutlinedIcon /> }
  ];

  const changeTheme = (theme: string) => {
    const themeName = getThemeName(theme, prefersDarkMode);
    dispatch(setThemeName(themeName));
    localStorage.setItem("themeName", themeName);
  };

  return (
    <>
      {availableThemes.map(theme => (
        <MenuItem
          key={theme.id}
          onClick={() => {
            changeTheme(theme.id);
            closeMenu();
          }}
        >
          <ListItemIcon>{theme.icon}</ListItemIcon>
          <EdisonTypography variant="h4" title={theme.name} />
        </MenuItem>
      ))}
    </>
  );
};

export default ThemeSelectMenu;
