import {
  Form,
  reducerStatus,
  TableRowPeriodicGrandTotal,
  WorkflowDataPopulation,
  WorkflowDataPopulationFieldValue,
  WorkflowPopulationTableRow
} from "enada-common";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getWorkflowPopulationRows,
  getWorkflowPopulationTableConfigs,
  getWorkflowPopulationTableTotals,
  getWorkflowPopulationValues,
  postWorkflowPopulationRows,
  postWorkflowPopulationTableConfigs,
  postWorkflowPopulationValues
} from "../../services/APIService";
import {
  mapRecordToPopulationTableOperations,
  PopulationOperationType
} from "../../utils/mappers/populationToRecordMappers";
import { RowOperationType } from "../../utils/parsing/parseTableChangeToBackend";
import { RootState } from "../store";
import { FrontendRecordValue, RecordValueType, SaveSlot } from "./recordSlice";

export interface DataPopulationState {
  form?: Form;
  backendPopulationFieldValues?: WorkflowDataPopulationFieldValue[];
  frontendPopulationFieldValues: FrontendRecordValue[]; //reused from Projects would be better to move it to common
  dataPopulation?: WorkflowDataPopulation;
  backendTableRows: WorkflowPopulationTableRow[];
  tableOperations: PopulationOperationType[];
  status: reducerStatus;
  changeMade: boolean;
  fieldValuesLoaded: boolean;
  periodicGrandTotals: TableRowPeriodicGrandTotal[];
}

const initialState: DataPopulationState = {
  form: undefined,
  backendPopulationFieldValues: undefined,
  frontendPopulationFieldValues: [],
  dataPopulation: undefined,
  backendTableRows: [],
  tableOperations: [],
  status: "idle",
  changeMade: false,
  fieldValuesLoaded: false,
  periodicGrandTotals: []
};

export const getPopulationFieldValuesAsync = createAsyncThunk(
  "dataPopulation/getPopulationFieldValues",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationValues(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const savePopulationFieldValuesAsync = createAsyncThunk(
  "dataPopulation/savePopulationFieldValues",
  async ({ id, values }: { id: number; values: unknown }) => {
    await postWorkflowPopulationValues(values, id);
  }
);

export const getPopulationRowsAsync = createAsyncThunk(
  "dataPopulation/getWorkflowPopulationRows",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationRows(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
//This Thunk as been consumed temporarily by the record in order to use the record page row merging,
// as the Table Mapper is currently coupled to the recordSlice
export const savePopulationRowsAsync = createAsyncThunk(
  "dataPopulation/savePopulationRows",
  async (
    { activeSaveSlot, populationId }: { activeSaveSlot: SaveSlot; populationId: number },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;

    let activeTableOperations: RowOperationType[] = [];

    // Work out which table operation array to save using the activeSaveSlot variable
    // (which hasn't been updated by the swapActiveSaveSlot method called in useSaveProject)
    switch (activeSaveSlot) {
      case SaveSlot.Slot1:
        activeTableOperations = state.recordTable.tableOperations1;
        break;
      case SaveSlot.Slot2:
        activeTableOperations = state.recordTable.tableOperations2;
        break;
    }

    const response = (await postWorkflowPopulationRows(
      mapRecordToPopulationTableOperations(
        activeTableOperations,
        populationId,
        state.recordTable.backendRecordRowValues
      ),
      populationId
    )) as any;
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getPopulationTableConfigsAsync = createAsyncThunk(
  "dataPopulation/getWorkflowPopulationTableConfigsAsync",
  async (populationId: number, { rejectWithValue }) => {
    const response = await getWorkflowPopulationTableConfigs(populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const savePopulationTableConfigsAsync = createAsyncThunk(
  "dataPopulation/savePopulationTableConfigsAsync",
  async (
    { populationId, tableConfigs }: { populationId: number; tableConfigs: any[] },
    { rejectWithValue }
  ) => {
    const response = await postWorkflowPopulationTableConfigs(tableConfigs, populationId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const getPopulationTableTotalsAsync = createAsyncThunk(
  "dataPopulation/getPopulationTableTotalsAsync",
  async (
    { tableId, populationId }: { tableId: number; populationId: number },
    { rejectWithValue }
  ) => {
    const response = await getWorkflowPopulationTableTotals(populationId, tableId);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return { tableId: tableId, totals: response.data };
  }
);
export const dataPopulationSlice = createSlice({
  name: "dataPopulation",
  initialState,
  reducers: {
    setDataPopulation: (state, action: PayloadAction<WorkflowDataPopulation>) => {
      state.dataPopulation = action.payload;
    },
    updateDataPopulation: (
      state,
      action: PayloadAction<{
        key: keyof WorkflowDataPopulation;
        changeValue: unknown;
      }>
    ) => {
      const { key, changeValue } = action.payload;
      state.dataPopulation = { ...state.dataPopulation, [key]: changeValue };
      state.changeMade = true;
    },
    setPopulationFrontendValues: (state, action: PayloadAction<FrontendRecordValue[]>) => {
      state.frontendPopulationFieldValues = action.payload;
      state.fieldValuesLoaded = true;
    },
    updatePopulationFrontendValues: (
      state,
      action: PayloadAction<{
        id?: number;
        changeValue: unknown;
        type?: RecordValueType;
      }>
    ) => {
      const { id, changeValue, type } = action.payload;
      if (id === undefined) return;
      if (state.frontendPopulationFieldValues.some(fieldValue => fieldValue.fieldId === id)) {
        state.frontendPopulationFieldValues = state.frontendPopulationFieldValues.map(fieldValue =>
          fieldValue.fieldId === id
            ? { ...fieldValue, value: changeValue, changeMade: true }
            : fieldValue
        );
        state.changeMade = true;
        return;
      }
      state.frontendPopulationFieldValues = [
        ...state.frontendPopulationFieldValues,
        { fieldId: id, value: changeValue, changeMade: true, type: type }
      ];
      state.changeMade = true;
    },
    resetPopulationSlice: state => {
      state.backendPopulationFieldValues = undefined;
      state.dataPopulation = undefined;
      state.frontendPopulationFieldValues = [];
      state.frontendPopulationFieldValues = [];
      state.changeMade = false;
      state.fieldValuesLoaded = false;
      state.periodicGrandTotals = [];
    },
    setPopulationChangeMade: (state, action: PayloadAction<boolean>) => {
      state.changeMade = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPopulationFieldValuesAsync.fulfilled, (state, action) => {
        state.backendPopulationFieldValues = action.payload;
      })
      .addCase(getPopulationRowsAsync.fulfilled, (state, action) => {
        state.backendTableRows = action.payload;
        state.status = "idle";
      })
      .addCase(getPopulationRowsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getPopulationTableTotalsAsync.fulfilled, (state, action) => {
        const existingTotal = state.periodicGrandTotals.find(
          t => t.tableId === action.payload.tableId
        );
        if (!existingTotal) {
          state.periodicGrandTotals.push(action.payload);
        }
      });
  }
});
export const {
  updateDataPopulation,
  updatePopulationFrontendValues,
  setDataPopulation,
  resetPopulationSlice,
  setPopulationFrontendValues,
  setPopulationChangeMade
} = dataPopulationSlice.actions;

const inputSelectDataPopulation = (state: RootState) => state.dataPopulation;

export const selectPopulationFrontendFieldValues = createSelector(
  [inputSelectDataPopulation],
  data => data.frontendPopulationFieldValues
);

export const selectPopulationObject = createSelector(
  [inputSelectDataPopulation],
  data => data.dataPopulation
);

export const selectPopulationBackendValues = createSelector(
  [inputSelectDataPopulation],
  data => data.backendPopulationFieldValues
);

export const selectPopulationSliceStatus = createSelector(
  [inputSelectDataPopulation],
  data => data.status
);

export const selectPopulationChangeMade = createSelector(
  [inputSelectDataPopulation],
  data => data.changeMade
);
export const selectPopulationFieldValuesLoaded = createSelector(
  [inputSelectDataPopulation],
  data => data.fieldValuesLoaded
);
export const selectPopulationPeriodicGrandTotals = createSelector(
  [inputSelectDataPopulation],
  data => data.periodicGrandTotals
);

export default dataPopulationSlice.reducer;
