import {
  Form,
  WorkflowStageViewField,
  WorkflowStageViewFieldState,
  WorkflowStageViewTable,
  getEnumValues,
  toCamelCase
} from "enada-common";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, ListItemButton, Popover, Stack, Tooltip } from "@mui/material";
import React, { Dispatch, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateRowVisibility } from "../../workflowlivepreview/utils/handleFormVisibility";
import { VisibilityIcon } from "../../workflowlivepreview/visibilitytoggle/VisibilityIcon";
import WorkflowLivePreviewConfirmation from "../../workflowlivepreviewconfirmation/WorkflowLivePreviewConfirmation";

export interface ManageFormQuickActionsProps {
  viewFields: WorkflowStageViewField[];
  viewTables: WorkflowStageViewTable[];
  setViewFields: Dispatch<React.SetStateAction<WorkflowStageViewField[]>>;
  setViewTables: Dispatch<React.SetStateAction<WorkflowStageViewTable[]>>;
  form?: Form;
}
const ManageFormQuickActions: FC<ManageFormQuickActionsProps> = ({
  viewFields,
  viewTables,
  setViewFields,
  setViewTables,
  form
}) => {
  const { t } = useTranslation(["common"]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedQuickAction, setSelectedQuickAction] = useState<
    WorkflowStageViewFieldState | undefined
  >();
  const onQuickActionClose = () => setAnchorEl(null);

  const updateFormVisibility = (visibility: WorkflowStageViewFieldState | undefined) => {
    let fields: WorkflowStageViewField[] = [];
    let tables: WorkflowStageViewTable[] = [];

    form?.containers?.forEach(tab =>
      tab?.zones?.forEach(row => {
        const updated = updateRowVisibility(visibility, row, viewFields, viewTables);
        fields = [...fields, ...updated.updatedViewFields];
        tables = [...tables, ...updated.updatedViewTables];
      })
    );
    setViewFields(prev =>
      prev.map(prevField => fields.find(field => field.fieldId === prevField.fieldId) ?? prevField)
    );
    setViewTables(prev =>
      prev.map(prevTable => tables.find(table => table.tableId === prevTable.tableId) ?? prevTable)
    );
  };
  return (
    <>
      <Button
        endIcon={<ArrowDropDownIcon />}
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {t("quickActions")}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onQuickActionClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
      >
        <Stack>
          {[...getEnumValues(WorkflowStageViewFieldState), "Visible"].map((fieldState, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedQuickAction(
                  (fieldState === "Visible" ? undefined : fieldState) as WorkflowStageViewFieldState
                );
                setConfirmOpen(true);
                onQuickActionClose();
              }}
            >
              <Stack direction="row" width={"10em"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={1}>
                  {VisibilityIcon(fieldState)}
                  <div>{t(toCamelCase(fieldState))}</div>
                </Stack>
                <Tooltip title={t(`manageForm${fieldState}AllSet`)}>
                  <InfoOutlinedIcon />
                </Tooltip>
              </Stack>
            </ListItemButton>
          ))}
        </Stack>
      </Popover>
      <WorkflowLivePreviewConfirmation
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title={`${t("setAllDataTo")} ${selectedQuickAction ?? t("visible")}`}
        alertMessage={t("manageFormChangeAllDataMessage")}
        actionText={t("confirm")}
        action={() => {
          updateFormVisibility(selectedQuickAction);
          setConfirmOpen(false);
        }}
      />
    </>
  );
};

export default ManageFormQuickActions;
