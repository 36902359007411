import { FC } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Dialog, DialogTitle, Button, Stack, Alert } from "@mui/material";
import { onClose } from "store/slices/firewallSlice";
import { useTranslation } from "react-i18next";

export const FirewallErrorDialog: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector(state => state.firewall);

  const handleClose = () => {
    dispatch(onClose());
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t("error")}</DialogTitle>
      <Stack spacing={2} padding="1em">
        <Alert severity="error">{t("firewallWarning")}</Alert>
        <Stack direction="row" spacing={1} justifyContent="end">
          <Button variant="outlined" onClick={handleClose}>
            {t("ok")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
