import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { Category, RecordTemplate, Workflow } from "enada-common";
import { BoldTranslation, Loading } from "enada-components";
import { FC } from "react";

type WorkFlowSelectProps = {
  template: RecordTemplate | Partial<RecordTemplate> | null;
  workflowsList: Workflow[];
  onChange: (event: SelectChangeEvent<number>) => void;
  label: string;
  helperText: string;
  value: number | undefined;
  isLoading: boolean;
};

export const WorkflowSelect: FC<WorkFlowSelectProps> = ({
  template,
  workflowsList,
  onChange,
  label,
  helperText,
  value,
  isLoading
}) => {
  return isLoading ? (
    <Loading size={30} />
  ) : (
    <Stack spacing={1}>
      <FormControl
        required
        variant="standard"
        disabled={!template?.recordType || template?.category === Category.Default}
        fullWidth
        data-testid={"workflow-select"}
      >
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          label={label}
          fullWidth
          value={value ?? 0}
          onChange={onChange}
          sx={{ borderRadius: "8px" }}
          MenuProps={{
            PaperProps: {
              "data-testid": "workflow-select-menu"
            }
          }}
        >
          {workflowsList.length > 0 &&
            workflowsList.map(
              workflow =>
                // If the workflow marked as isDeleted or the workflow is the current workflow, hide it.
                // Also, if the workflow isDeleted we still need to add it to the list, so it can be displayed as the current value
                (!workflow.isDeleted || workflow.id === template?.workflow) && (
                  <MenuItem
                    sx={workflow.id === template?.workflow ? { display: "none" } : {}}
                    key={workflow.id}
                    value={workflow.id}
                    data-testid={"workflow-select-item"}
                  >
                    {workflow.displayName}
                  </MenuItem>
                )
            )}
        </Select>
      </FormControl>
      <BoldTranslation i18nKey={helperText} fontWeight="600" />
    </Stack>
  );
};
