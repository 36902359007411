import { useEffect, useState } from "react";
import {
  ApiError,
  Field,
  fieldConfiguration,
  FieldDataType,
  getEnumValues,
  ListSectionItem,
  NotificationLevel,
  ResourceField
} from "enada-common";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  resetResourceFields,
  selectResourceFields,
  updateResourceFields
} from "../../../store/slices/resourceFieldsSlice";
import { ListItemButton, ListItemText, Stack } from "@mui/material";
import {
  ConfigLayout,
  EdisonExpandableMenu,
  EdisonSearchBar,
  EdisonTypography,
  RollupTable
} from "enada-components";
import { selectAllFields } from "../../../store/slices/fieldsSlice";
import { t } from "i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import "./resourcefields.scss";
import { SYSTEM_FIELD_ID_LIMIT } from "../../../config/authConfig";
import {
  useGetFieldsQuery,
  useGetResourceFieldsQuery,
  useUpdateResourceFieldsMutation
} from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

const ResourceFields = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState("");
  const resourceFields = useAppSelector(selectResourceFields);

  const { data: currentResourceFields = [] } = useGetResourceFieldsQuery();
  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const [postResourceFields] = useUpdateResourceFieldsMutation();

  useEffect(() => {
    if (currentResourceFields.length > 0) dispatch(updateResourceFields(currentResourceFields));
  }, [currentResourceFields, dispatch]);

  const onItemClick = (field: Field) => {
    dispatch(
      updateResourceFields([
        ...resourceFields,
        { fieldId: field.id as number, order: resourceFields.length + 1 }
      ])
    );
  };

  const onClose = () => {
    dispatch(resetResourceFields());
    navigate(-1);
  };

  const onSave = async () => {
    try {
      const response = await postResourceFields(resourceFields).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "resourceFieldsUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        onClose();
      }
    } catch (e) {
      const error = e as ApiError;
      dispatch(
        setCurrentNotification({
          title: "resourceFieldsUpdateError",
          message: `\n ${error.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <ConfigLayout
      t={t}
      title={t("resourceFieldsConfiguration")}
      onSaveCallback={onSave}
      onCancelCallback={onClose}
      topPanelContent={
        <Stack className="resource-fields-root" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Stack className="field-selector">
              <EdisonSearchBar
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                placeholder={t("search")}
              />
              <Stack className="fields-list">
                {getEnumValues(FieldDataType).map((type, index) => {
                  const config = fieldConfiguration(type as FieldDataType);
                  return (
                    fields &&
                    resourceFields && (
                      <EdisonExpandableMenu
                        key={index}
                        name={t(config.displayName)}
                        icon={config.icon}
                        sticky={false}
                        onItemClick={function (item: ListSectionItem): void {
                          // TODO - implement
                        }}
                      >
                        {fields
                          .filter(
                            field =>
                              field.dataType === type &&
                              (field.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                                field.displayName
                                  ?.toLowerCase()
                                  .includes(searchFilter.toLowerCase()))
                          )
                          .filter(
                            field => field?.id !== undefined && field?.id > SYSTEM_FIELD_ID_LIMIT
                          )
                          .map((field, index) => (
                            <ListItemButton
                              key={index}
                              disableGutters
                              disabled={resourceFields?.some(
                                fieldTofind => field.id === fieldTofind.fieldId
                              )}
                              onClick={() => {
                                onItemClick(field);
                              }}
                            >
                              <ListItemText
                                primary={
                                  <EdisonTypography
                                    variant="data2"
                                    title={field.displayName || ""}
                                  />
                                }
                              ></ListItemText>
                            </ListItemButton>
                          ))}
                      </EdisonExpandableMenu>
                    )
                  );
                })}
              </Stack>
            </Stack>
            <Stack className="selected-fields">
              <RollupTable
                fullScreen={false}
                setFullScreen={() => {
                  /*nothing*/
                }}
                order="asc"
                renderEmptyTable={true}
                editAction={() => {
                  return;
                }}
                addAction={() => {
                  return;
                }}
                deleteAction={() => {
                  return;
                }}
                columns={[
                  { name: "id", displayName: t("id") },
                  { name: "displayName", displayName: t("fieldName") },
                  {
                    name: "dataType",
                    displayName: t("fieldType")
                  }
                ]}
                rows={
                  fields
                    .filter(field =>
                      resourceFields.find(resourceField => resourceField.fieldId === field.id)
                    )
                    .map((field, index) => ({
                      ...field,
                      order: resourceFields.find(r => r.fieldId === field.id)?.order
                    })) as any
                }
                deletedRows={[]}
                recycleAction={() => {
                  return;
                }}
                restoreAction={() => {
                  return;
                }}
                t={t}
                hideTopSection
                rowActionsName="Actions"
                rowActions={[
                  {
                    onClick: row => {
                      dispatch(
                        updateResourceFields(
                          resourceFields
                            .filter((rf: ResourceField) => rf.fieldId !== row.id)
                            .map((field, index) => ({
                              ...field,
                              order: index
                            }))
                        )
                      );
                    },
                    getIcon: () => (
                      <DeleteOutlineOutlinedIcon
                        className={`pointer`}
                        fontSize="small"
                        color="error"
                      />
                    )
                  }
                ]}
                orderBy="order"
              />
            </Stack>
          </Stack>
        </Stack>
      }
    ></ConfigLayout>
  );
};

export default ResourceFields;
