import {
  FilterOperation,
  FilterOperationOptions,
  FilterPropertyType,
  getFilterTypes
} from "enada-common";
import { FilterOptionsState } from "@mui/material";

export const getDefaultValueFromOptions = (filterOp?: FilterOperation) => {
  if (filterOp && Array.isArray(filterOp?.options)) {
    return filterOp?.propertyType !== FilterPropertyType.MultiChoice &&
      filterOp?.propertyType !== FilterPropertyType.PeoplePicker
      ? filterOp?.options[0]?.value
      : [];
  }
  switch (filterOp?.propertyType) {
    case FilterPropertyType.Number:
      return 0;
    case FilterPropertyType.Bool:
      return false;
    default:
      return "";
  }
};

export const addFilterValueChange = (
  newValue: any,
  filterOp: FilterOperation,
  propName: string,
  filterOperations: FilterOperation[],
  selectedFilters: FilterOperation[] | undefined,
  setSelectedFilters: (value: React.SetStateAction<FilterOperation[] | undefined>) => void
) => {
  let newFilter = { ...filterOp, [propName]: newValue };
  if (propName === "property") {
    const defaultFilter = filterOperations?.find(f => f.property === newValue);
    newFilter = {
      ...newFilter,
      operator: getFilterTypes(defaultFilter?.propertyType ?? FilterPropertyType.String)[0],
      options: defaultFilter?.options,
      displayName: defaultFilter?.displayName ?? propName,
      value: getDefaultValueFromOptions(defaultFilter),
      propertyType: defaultFilter?.propertyType ?? FilterPropertyType.String
    };
  }

  const newSelectedFilters = [...(selectedFilters ?? [])];
  newSelectedFilters.splice(
    newSelectedFilters?.findIndex((se: FilterOperation) => se.id === filterOp.id),
    1,
    newFilter
  );
  setSelectedFilters([...newSelectedFilters]);
};

export const filterPeopleOptions = (
  options: FilterOperationOptions[],
  state: FilterOptionsState<any>,
  users: FilterOperationOptions[]
) => {
  if (state.inputValue.length === 0) {
    return options;
  }
  const found = users.filter(u =>
    u.displayName.toLowerCase().includes(state.inputValue.toLowerCase())
  );
  return options.filter(o => found.findIndex(v => v.value === o.value) > -1);
};
