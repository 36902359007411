import { ProjectModel as SchedulerProProjectModel } from "@bryntum/schedulerpro-thin";
import { ProjectModel, ProjectModelConfig } from "@bryntum/gantt-thin";
import ResourceUtilization from "../resourceutilization/ResourceUtilization";
import SchedulerPro from "../schedulerpro/SchedulerPro";
import { FC, useEffect, useRef, useState } from "react";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react-thin";
import ResourceHistogram from "../resourcehistogram/ResourceHistogram";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import "./resourcereport.scss";
import { PersonCard } from "@microsoft/mgt-react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent
} from "@mui/material";

export interface EdisonResourceReportProps {
  events: any[];
  resources: any[];
  assignments: any[];
  columns: any[];
  calendar: string;
  calendars: any[];
  startDate: string;
  endDate: string;
  showTooltip?: boolean;
  readOnly?: boolean;
  viewDataDisplayName: string;
  histogramDisplayName: string;
  utilizationDisplayName: string;
  dialogTitleDisplayName?: string;
  recordDisplayName?: string;
  taskDisplayName?: string;
  durationDisplayName?: string;
  fromDisplayName?: string;
  toDisplayName?: string;
  recordOwners?: any[];
  GetPersonComponent: (record: any) => JSX.Element;
}

const ResourceReport: FC<EdisonResourceReportProps> = ({
  events,
  resources,
  assignments,
  columns,
  calendar,
  calendars,
  startDate,
  endDate,
  showTooltip,
  readOnly,
  viewDataDisplayName,
  histogramDisplayName,
  utilizationDisplayName,
  dialogTitleDisplayName,
  recordDisplayName,
  taskDisplayName,
  durationDisplayName,
  fromDisplayName,
  toDisplayName,
  recordOwners,
  GetPersonComponent
}) => {
  const [scheduler, setScheduler] = useState<any | undefined>(undefined);
  const [showHistogram, setShowHistogram] = useState(true);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [clickedPersona, setClickedPersona] = useState<any>(null);
  const open = Boolean(anchorEl);

  const histogramCols = [
    {
      type: "tree",
      text: "Name",
      field: "name",
      renderer: ({ record }: any) => {
        return GetPersonComponent(record);
      }
    },
    { type: "scale" }
  ];
  const histogramColumns = useRef(histogramCols);
  const utilizationCols = [
    {
      type: "tree",
      text: "Name",
      field: "name",
      renderer: ({ record }: any) => {
        return record.origin.userId === undefined ? record.name : GetPersonComponent(record.origin);
      }
    }
  ];
  const utilizationColumns = useRef(utilizationCols);

  const project = useRef<Partial<ProjectModelConfig>>(
    new ProjectModel({
      taskStore: {
        autoTree: true,
        transformFlatData: true
      },
      assignmentStore: {
        transformFlatData: true
      },
      calendarManagerStore: {
        transformFlatData: true
      },
      calendar,
      startDate,
      endDate
    }) as Partial<ProjectModelConfig>
  );
  useEffect(() => {
    // Adding a 1 second timeout to ensure the data is rendered on the scheduler
    const timeoutId = setTimeout(() => {
      if (events) {
        (project.current as ProjectModel).taskStore.data = events;
      }

      if (assignments) {
        (project.current as ProjectModel).assignmentStore.data = assignments;
      }

      if (resources) {
        (project.current as ProjectModel).resourceStore.data = resources;
      }

      if (calendars) {
        (project.current as ProjectModel).calendarManagerStore.data = calendars;
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [resources, events, assignments, calendars]);

  const onSchedulerProReady = (schedulerPro: BryntumSchedulerPro) => {
    setScheduler(schedulerPro);
  };

  return (
    <div className="report-root" data-testid={"edison-resource-report-container"}>
      <div className="toolbar">
        <FormControl className="view-data-select">
          <InputLabel id="view-data-select-label">{viewDataDisplayName}</InputLabel>
          <Select
            size="small"
            labelId="view-data-select-label"
            id="view-data-select"
            value={showHistogram ? "histogram" : "utilization"}
            label={viewDataDisplayName}
            onChange={(event: SelectChangeEvent) => {
              setShowHistogram(event.target.value === "histogram");
            }}
          >
            <MenuItem value="histogram">{histogramDisplayName}</MenuItem>
            <MenuItem value="utilization">{utilizationDisplayName}</MenuItem>
          </Select>
        </FormControl>
        <div className="actionButton">
          <IconButton
            data-testid={"edison-resource-report-buttons-previous"}
            size="small"
            color="primary"
            className="icon-button"
            onClick={() => scheduler.instance.shiftPrevious()}
          >
            <KeyboardArrowLeftOutlinedIcon />
          </IconButton>
          <IconButton
            data-testid={"edison-resource-report-buttons-next"}
            size="small"
            color="primary"
            className="icon-button"
            onClick={() => scheduler.instance.shiftNext()}
          >
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>
          <IconButton
            data-testid={"edison-resource-report-buttons-zoomin"}
            size="small"
            color="primary"
            className="icon-button"
            onClick={() => scheduler.instance.zoomIn()}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            data-testid={"edison-resource-report-buttons-zoomout"}
            size="small"
            color="primary"
            className="icon-button"
            onClick={() => scheduler.instance.zoomOut()}
          >
            <ZoomOutIcon />
          </IconButton>
        </div>
      </div>
      <SchedulerPro
        data-testid={"edison-resource-report-scheduler"}
        project={project.current as SchedulerProProjectModel}
        columns={columns}
        onInstanceReady={onSchedulerProReady}
        assignments={assignments}
        startDate={(project.current as ProjectModel).startDate as string}
        endDate={(project.current as ProjectModel).endDate as string}
        showTooltip={showTooltip}
        readOnly={readOnly}
        dialogTitleDisplayName={dialogTitleDisplayName}
        recordDisplayName={recordDisplayName}
        taskDisplayName={taskDisplayName}
        durationDisplayName={durationDisplayName}
        fromDisplayName={fromDisplayName}
        toDisplayName={toDisplayName}
        recordOwners={recordOwners}
      />

      {scheduler?.instance && !showHistogram && (
        <>
          {/* <BryntumWidget type="splitter" /> */}
          <ResourceUtilization
            project={project.current as any}
            columns={utilizationColumns.current}
            partner={scheduler.instance}
            showTooltip={showTooltip}
            readOnly={readOnly}
          />
        </>
      )}

      {scheduler?.instance && showHistogram && (
        <>
          {/* <BryntumWidget type="splitter" /> */}
          <ResourceHistogram
            project={project.current as SchedulerProProjectModel}
            columns={histogramColumns.current}
            partner={scheduler.instance}
            showTooltip={showTooltip}
            readOnly={readOnly}
          />
        </>
      )}
      {anchorEl && clickedPersona && (
        <Popover
          data-testid={"edison-resource-report-popover"}
          open={open}
          sx={{ overflow: "hidden" }}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={() => {
            setAnchorEl(null);
            setClickedPersona(null);
          }}
        >
          <PersonCard
            data-testid={"edison-resource-report-personcard"}
            userId={clickedPersona}
            showPresence
            fetchImage
            isExpanded={true}
          />
        </Popover>
      )}
    </div>
  );
};

export default ResourceReport;
export { ResourceReport };
