import { reducerStatus, News, Scope } from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const defaultNews: News = {
  id: undefined,
  startDate: "",
  title: "",
  isDeleted: false,
  scope: Scope.Instance
};

export interface newsState {
  value: any;
  news: News[];
  individualNews: News | Partial<News>;
  savedNews: News | Partial<News> | null;
  status: reducerStatus;
}

const initialState: newsState = {
  value: 0,
  news: [],
  individualNews: defaultNews,
  savedNews: null,
  status: "idle"
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    clearIndividualNews: state => {
      state.individualNews = defaultNews;
      state.savedNews = null;
    },
    setIndividualNews: (state, action: PayloadAction<{ newsData: News }>) => {
      state.individualNews = action.payload.newsData;
    },
    updateIndividualNewsProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof News }>
    ) => {
      state.individualNews = {
        ...state.individualNews,
        [action.payload.key]: action.payload.value
      };
    }
  }
});

const inputSelectNews = (state: RootState) => state.news;
const selectNews = createSelector([inputSelectNews], news => news.news);

export const selectNextNewsOrder = createSelector(
  [selectNews],
  (news): number => (news.filter((n: News) => !n.isDeleted).length ?? 0) + 1
);

export const selectIndividualNews = createSelector([inputSelectNews], news => news.individualNews);

export const { clearIndividualNews, setIndividualNews, updateIndividualNewsProperty } =
  newsSlice.actions;

export default newsSlice.reducer;
