import { BaseRecord } from "enada-common";
import { apiConfig } from "../../config/authConfig";
import { ODataApiResponse } from "../../types/ApiResponse";
import { baseAPI } from "../baseAPI";

export const recycledRecordsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getRecycledRecords: builder.query<ODataApiResponse<BaseRecord[]>, string>({
      query: queryString =>
        `${apiConfig.deletedRecordsODataEndpoint}${decodeURIComponent(queryString)}`,
      providesTags: result => {
        return result && result?.value
          ? [
              ...result.value.map(item => ({ type: "RecycledRecords" as const, id: item.id })),
              "RecycledRecords"
            ]
          : ["RecycledRecords"];
      }
    }),

    getRecycledRecordsCount: builder.query<number, string>({
      query: queryString =>
        `${apiConfig.deletedRecordsODataEndpoint}/$count/${decodeURIComponent(queryString)}`,
      providesTags: (result, error, id) => [{ type: "RecycledRecords", id }]
    }),

    restoreRecyledRecords: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.recordsApi}/restore`,
        method: "POST",
        body: ids
      }),

      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "RecycledRecords", id }))
    }),

    deleteRecycledRecords: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.recordsApi}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "RecycledRecords", id }))
    })
  })
});
