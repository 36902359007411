import { Field, RequiredInType, TableDataType, TableView, TableViewType } from "enada-common";
import { SystemFieldType } from "pages/admin/tableconfig/TableConfig";

// TODO : Move models to shared once feature is stable
export type BryntumBoardItemType =
  | "number"
  | "percent"
  | "combo"
  | "image"
  | "progress"
  | "rating"
  | "resourcesAvatar"
  | "seperator"
  | "tags"
  | "text"
  | "todoList"
  | "date";

export interface BoardCardItem {
  location: "header" | "body" | "footer";
  type: BryntumBoardItemType;
  field: string;
  label?: string;
}

const createBoardCardItem = (
  fields: Field[],
  tableType: TableDataType,
  searchFieldId: number | undefined,
  location: "header" | "body" | "footer"
): Field | undefined => {
  const foundField = fields?.find(field => field.id === searchFieldId);
  if (!foundField) return undefined;

  let newName = `${foundField.name}-e365`;
  if (tableType === TableDataType.List) {
    if (foundField.id === SystemFieldType.UniqueId || foundField.id === SystemFieldType.RowId) {
      newName = foundField.name;
    }
  } else if (tableType === TableDataType.Schedule) {
    if (
      foundField.requiredIn &&
      (foundField.requiredIn.indexOf(RequiredInType.AllTables) > -1 ||
        foundField.requiredIn.indexOf(RequiredInType.TaskTable) > -1)
    ) {
      newName = foundField.name;
    }
  }

  return {
    ...foundField,
    name: newName,
    location
  };
};

export const getTableBoardCardItems = (
  fields: Field[],
  view: TableView | null,
  tableType: TableDataType
): Field[] => {
  if (view === null) return [];
  if (view.viewType !== TableViewType.Board) return [];

  const headerItems = [
    createBoardCardItem(fields, tableType, view.boardPrimaryCardFieldId, "header")
  ];
  const bodyItems = [
    createBoardCardItem(fields, tableType, view.boardSecondaryCardFieldId, "body")
  ];

  const result = [...(headerItems as Field[]), ...(bodyItems as Field[])];
  return result;
};
