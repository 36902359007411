// Set the flag passed on the passed value
export const setBitwiseFlag = (value: number, flagToSet: number) => {
  let updatedValue = value;
  updatedValue |= flagToSet;
  return updatedValue;
};

//Check if a specific flag has been set in the passed value
export const isBitwiseFlagSet = (value: number, flag: number) => {
  const index = Math.log2(flag);
  return Math.floor(value / Math.pow(2, index)) % 2;
};

// Unset/ clear the flags passed in the flagsToClear array from the passed value
export const clearMultipleBitwiseFlags = (value: number, flagsToClear: number[]) => {
  let updatedValue = value;
  flagsToClear.forEach(flag => (updatedValue &= ~flag));
  return updatedValue;
};

//See url for explanation of bitwise operations
//https://blog.logrocket.com/guide-javascript-bitwise-operators/
// https://stackoverflow.com/questions/1436438/how-do-you-set-clear-and-toggle-a-single-bit-in-javascript
//https://www.w3schools.com/js/js_bitwise.asp

/// TO Move to common package

// A set of flags(e.g bits) that represent the data needed for a fully functioning record page.
// If a flag has been set that means the data for that flag has been initialised

// const isBitSet=(n, mask)=>{return (n & mask) != 0}
export enum RecordDataFlags {
  recordWorkflowStage = 1 << 0,
  recordAuth = 1 << 1,
  record = 1 << 2,
  recordFieldValues = 1 << 3,
  recordTableValues = 1 << 4,
  recordTableConfig = 1 << 5,
  recordCoAuthor = 1 << 6,
  recordForm = 1 << 7,
  calendars = 1 << 8,
  resources = 1 << 9,
  fields = 1 << 10
}
