import { Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { selectRecordAssociations } from "../../../../store/slices/recordSlice";
import { BaseRecord, RecordType } from "enada-common";
import { EdisonCarousel, EdisonSearchBar, EdisonTypography } from "enada-components";

import { useTranslation } from "react-i18next";
import { getRecords } from "../../../../services/APIService";
import RecordContentCard from "../../../recordcontentcard/RecordContentCard";
import "./createrecordideaselect.scss";
import { useGetWorkflowStagesQuery } from "services/api";

export interface CreateRecordIdeaSelectProps {
  setIdea: (idea: BaseRecord) => void;
  parentRecordId?: number;
}

const CreateRecordIdeaSelect: FC<CreateRecordIdeaSelectProps> = ({ setIdea, parentRecordId }) => {
  const { t } = useTranslation(["common"]);

  const { data: stages = [] } = useGetWorkflowStagesQuery();

  // We should be guaranteed that the associations are loaded as the only way to get to this step,
  // is through creating an assocation from a challenge on the record page.
  const associations = useAppSelector(selectRecordAssociations);
  const [fullRecords, setFullRecords] = useState<BaseRecord[]>([]);
  const [expandedProject, setExpandedProject] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchFullRecords = async () => {
      const ideaIds = associations
        .filter(association => association.recordType === RecordType.Ideas)
        .map(association => association.recordId);

      let skip = 0;
      const records: BaseRecord[] = [];

      while (records.length < ideaIds.length) {
        const response = await getRecords(`?filter=id in (${ideaIds.join(",")})&$skip=${skip}`);
        if (response.value === undefined) break;
        if (response.value.length === 0) break;
        records.push(...response.value);
        skip += 20;
      }

      setFullRecords(records);
    };
    fetchFullRecords();
  }, [associations]);

  const getIdeaCard = (record: BaseRecord) => {
    return (
      <RecordContentCard
        isSelected={record.id === parentRecordId}
        record={record}
        stages={stages}
        setExpandedRecord={setExpandedProject}
        isExpanded={expandedProject ? expandedProject === record.id : false}
        onRecordClick={() => setIdea(record)}
      />
    );
  };

  return (
    <Stack className="create-record-idea-select-root">
      <EdisonCarousel
        data={fullRecords.filter(record =>
          record.displayName?.toLowerCase().includes(search.toLowerCase())
        )}
        isMoreActive={false}
        noDataModule={<div style={{ height: "15em" }}> {t("noData")}</div>}
        getCard={getIdeaCard}
        topBarModule={
          <Stack direction={"row"} className="title-container">
            <EdisonTypography title={t("ideas")} variant="h5" sx={{ fontWeight: "bold" }} />
            <Stack direction="row" spacing={1}>
              <EdisonSearchBar
                placeholder={t("search")}
                searchFilter={search}
                setSearchFilter={setSearch}
              />
            </Stack>
          </Stack>
        }
      ></EdisonCarousel>
    </Stack>
  );
};

export default CreateRecordIdeaSelect;
