import { Wrapper } from "@googlemaps/react-wrapper";
import { Stack } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import GoogleMap from "./GoogleMap";
import PlacesAutoComplete from "./PlacesAutoComplete";
import { UserLocationFieldProps } from "./UserLocationField";

const EditableLocationField: FC<UserLocationFieldProps> = ({
  value,
  onChange,
  useInternalState,
  setReadOnly
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [mousePos, setMousePos] = useState<any>({});

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div
      ref={ref}
      onBlur={() => {
        const rect = ref.current?.getBoundingClientRect() as DOMRect;
        const horizontalIn = mousePos.x > rect?.left && mousePos.x < rect?.right;
        const verticalIn = mousePos.y > rect.top && mousePos.y < rect.bottom;
        if (horizontalIn && verticalIn) return;
        if (!setReadOnly) return;
        setReadOnly(true);
      }}
    >
      <Wrapper apiKey={process.env.NX_PUBLIC_MAPS_KEY as string} libraries={["places", "marker"]}>
        <Stack style={{ height: "100%", width: "100%" }} spacing={2}>
          <PlacesAutoComplete
            value={value}
            useInternalState={useInternalState}
            onChange={(value: google.maps.places.AutocompletePrediction) => {
              onChange(value);
            }}
          />
          <GoogleMap
            value={value}
            onChange={(value: any) => {
              onChange(value);
            }}
            useInternalState={useInternalState}
          />
        </Stack>
      </Wrapper>
    </div>
  );
};

export default EditableLocationField;
