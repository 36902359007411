import { FC, PropsWithChildren, useMemo } from "react";
import { RecordType } from "enada-common";
import { useGetTemplatesQuery } from "../../services/api";

type HasTemplatesProps = {
  typeName: RecordType;
  excludeRecordTypes?: RecordType[];
  noMatchElement?: JSX.Element;
};

const HasTemplates: FC<PropsWithChildren<HasTemplatesProps>> = ({
  children,
  typeName,
  excludeRecordTypes,
  noMatchElement
}) => {
  const { data: templates } = useGetTemplatesQuery();

  const recordTypeHasTemplate = useMemo(() => {
    if (!templates) return false;

    return templates.some(template => template.recordType === typeName && !template.isDeleted);
  }, [templates, typeName]);

  if (recordTypeHasTemplate || excludeRecordTypes?.includes(typeName)) return children;

  return noMatchElement;
};

export default HasTemplates;
