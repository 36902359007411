import { BackendNotification, useLocalisation } from "enada-common";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Badge, Card, CardActionArea, CardContent, Grid, Stack } from "@mui/material";
import { EdisonTypography } from "enada-components";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { imageMapping } from "../../../utils/mappers/imageMapping";

export interface NotificationTabProps {
  notifications: BackendNotification[];
}

const NotificationTab: FC<NotificationTabProps> = ({ notifications }) => {
  const { t } = useTranslation(["common"]);
  const { formatDate } = useLocalisation();

  return (
    <Stack marginTop={3} spacing={1}>
      {(!notifications || !notifications.length) && (
        <Stack
          direction={"column"}
          spacing={1}
          padding={10}
          justifyItems={"center"}
          alignItems={"center"}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
              bgcolor: "#FAFAFA",
              alignContent: "center"
            }}
          >
            <NotificationsNoneIcon fontSize="small" color="primary" />
          </Avatar>
          <EdisonTypography title={t("notificationFlyoutEmpty")} variant={"subtitle2"} />
          <EdisonTypography
            title={t("notificationFlyoutEmptyDescription")}
            variant={"fieldtitle"}
          />
        </Stack>
      )}
      {notifications &&
        !!notifications.length &&
        notifications.map((notification, index) => (
          <Card key={index}>
            <CardActionArea>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  textAlign={"center"}
                  justifyItems={"stretch"}
                  alignItems="center"
                  spacing={3}
                >
                  {/* TODO: Style the notifications when the backend have finished coding the notifications apis */}
                  <Grid item xs zeroMinWidth>
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: "#FAFAFA",
                        alignContent: "center"
                      }}
                    >
                      {imageMapping(notification.isRead ? "projects" : "ideas", "primary")}
                    </Avatar>
                  </Grid>

                  <Grid container zeroMinWidth xs={6}>
                    <EdisonTypography title={"Notification Header Text"} variant={"data"} />
                    <EdisonTypography title={JSON.stringify(notification)} variant={"h4"} />
                  </Grid>

                  <Grid item xs>
                    <EdisonTypography title={formatDate(new Date())} variant={"data"} />
                  </Grid>

                  <Grid item xs>
                    {notification.isRead ? (
                      imageMapping("doneall", "primary")
                    ) : (
                      <Badge color={"error"} variant="dot"></Badge>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
    </Stack>
  );
};

export default NotificationTab;
