import { apiConfig } from "config/authConfig";
import { License } from "enada-common";
import { baseAPI } from "services/baseAPI";
import { LicenseSettings } from "types/licenses";

export const licensesAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getLicenses: builder.query<License[], string>({
      query: tenantId => `${apiConfig.managementLicensesEndpoint}/${tenantId}/list`,
      transformResponse: (response: { data: License[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Licenses" as const, id })), "Licenses"]
          : ["Licenses"]
    }),

    getLicenseSettings: builder.query<LicenseSettings, string>({
      query: tenantId => `${apiConfig.licenseSettingsEndpoint}/${tenantId}`,
      transformResponse: (response: { data: LicenseSettings }) => response.data,
      providesTags: result =>
        result ? [{ type: "Licenses" as const, id: "settings" }, "Licenses"] : ["Licenses"]
    }),

    getTenantLicense: builder.query<License, string>({
      query: tenantId => `${apiConfig.managementLicensesEndpoint}/${tenantId}`,
      transformResponse: (response: { data: License }) => response.data,
      providesTags: ["Licenses"]
    }),

    updateLicenses: builder.mutation<License[], License[]>({
      query: license => ({
        url: apiConfig.managementLicensesEndpoint,
        method: "PUT",
        body: license
      }),
      invalidatesTags: ["Licenses"]
    }),

    deleteLicense: builder.mutation<License, { tenantId: string; licenseId: string }>({
      query: ({ tenantId, licenseId }) => ({
        url: `${apiConfig.managementLicensesEndpoint}/${tenantId}/${licenseId}`,
        method: "DELETE"
      }),
      invalidatesTags: (result, error, body) => [{ type: "Licenses", id: body.licenseId }]
    })
  })
});
