import { Field } from "enada-common";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import { EdisonSearchBar, EdisonTabs } from "enada-components";
import { Box } from "@mui/material";

export interface SearchableTabsProps {
  tabs: SingleTab[];
  tabsToSearch: string[];
  scrollable: boolean;
}

export interface SingleTab {
  id: string;
  label: string;
  children: any;
}

const SearchableTabs: FC<SearchableTabsProps> = ({ tabs, tabsToSearch, scrollable }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [searchTabs, setSearchTabs] = useState(tabs);

  useEffect(() => {
    setSearchTabs(
      tabs.map(item =>
        tabsToSearch.includes(item.id)
          ? {
              ...item,
              children: {
                ...item.children,
                props: {
                  ...item.children.props,
                  data: (item.children.props.data as Field[]).filter(
                    toFilter =>
                      toFilter.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                      toFilter?.displayName?.toLowerCase().includes(searchFilter.toLowerCase())
                  )
                }
              }
            }
          : item
      )
    );
  }, [tabs, searchFilter]);
  return (
    <div style={{ height: "100%", marginTop: "1em" }}>
      <EdisonSearchBar
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        placeholder={t("search")}
      />
      <Box height="1em" />
      <EdisonTabs
        tabs={searchTabs.filter(tab => tab.children.props.data.length !== 0)}
        height="90%"
        scrollable={scrollable}
        indicatorColour={"primary"}
        tabTitleBoxStyling={{
          borderBottom: 1,
          borderColor: "divider",
          padding: "0px"
        }}
      />
    </div>
  );
};

export default SearchableTabs;
