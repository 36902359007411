import { FC, ReactNode } from "react";
import { Alert, AlertProps, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

type AlertDialogProps = {
  content: ReactNode | string;
  label: string;
  severity: AlertProps["severity"];
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
};

export const AlertDialog: FC<AlertDialogProps> = ({
  content,
  label,
  severity,
  isLoading,
  isOpen,
  onClose,
  onClick
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{label}</DialogTitle>
      <Stack spacing={2} padding="1em">
        <Alert severity={severity}>{content}</Alert>
        <Stack direction="row" spacing={1} justifyContent="end">
          <LoadingButton variant="contained" loading={isLoading} color={severity} onClick={onClick}>
            {label}
          </LoadingButton>
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
