import { DataFieldConfig, Store } from "@bryntum/core-thin";
import { TaskModel } from "@bryntum/gantt-thin";
import { v4 as uuidv4 } from "uuid";
export class AllFieldsTaskModel extends TaskModel {
  setBaseline(index: number) {
    if (index <= 0) {
      return;
    }

    const newBaseline = {
      duration: this.duration,
      durationUnit: this.durationUnit,
      endDate: this.endDate,
      id: index,
      startDate: this.startDate,
      task: this
    };
    let foundIndex = -1;
    const existing = this.baselines.find((b, idx) => {
      if (b.id === index) {
        foundIndex = idx;
        return true;
      }
    });
    if (existing) {
      (this.baselines as Store).remove(existing as any);
      (this.baselines as Store).insert(foundIndex, newBaseline);
    } else {
      let indexToInsert = 0;
      this.baselines.forEach((b, idx) => {
        if ((b.id as number) < index) {
          indexToInsert = idx + 1;
        }
      });
      (this.baselines as Store).insert(indexToInsert, newBaseline);
    }
  }

  // avoid adding calculated(bryntum calculated) fields and using "convert" here - both will trigger data updates and saves
  static get fields() {
    return [
      { name: "e365-Name", dataSource: "name" },
      { name: "e365-StartDate", dataSource: "startDate" },
      { name: "e365-EndDate", dataSource: "endDate" },
      { name: "e365-UniqueId", dataSource: "id" },
      { name: "e365-ParentUniqueId", dataSource: "parentId" },
      { name: "e365-ReadOnly", dataSource: "readOnly" },
      { name: "e365-PercentDone", dataSource: "percentDone" },
      { name: "e365-Description", dataSource: "note" },
      { name: "e365-AssignedResource", dataSource: "assigned" },
      { name: "e365-RollUp", dataSource: "rollup" },
      { name: "e365-ShowInTimeline", dataSource: "showInTimeline" },
      { name: "e365-IsMilestone", dataSource: "isMilestone" },
      { name: "e365-ConstraintDate", dataSource: "constraintDate" },
      { name: "e365-ConstraintType", dataSource: "constraintType" },
      { name: "e365-DeadlineDate", dataSource: "deadlineDate" },
      { name: "e365-Duration", dataSource: "duration" },
      { name: "e365-DurationUnit", dataSource: "durationUnit" },
      { name: "e365-Effort", dataSource: "effort" },
      { name: "e365-EffortUnit", dataSource: "effortUnit" },
      { name: "e365-EffortDriven", dataSource: "effortDriven" },
      { name: "e365-Inactive", dataSource: "inactive" },
      { name: "e365-ManuallyScheduled", dataSource: "manuallyScheduled" },
      { name: "e365-SchedulingMode", dataSource: "schedulingMode" },
      { name: "e365-SlackUnit", dataSource: "slackUnit" },
      { name: "e365-WBS", dataSource: "wbsValue" },
      { name: "e365-Baselines", dataSource: "baselines" },
      { name: "e365-CalendarId", dataSource: "calendar" },
      { name: "e365-EffectiveCalendar", dataSource: "effectiveCalendar" },
      {
        name: "e365-IgnoreResourceCalendar",
        dataSource: "ignoreResourceCalendar"
      },
      { name: "e365-RowId", dataSource: "rowId" },
      {
        name: "e365-ProjectConstraintResolution",
        dataSource: "projectConstraintResolution",
        defaultValue: "conflict"
      },
      { name: "e365-Unscheduled", dataSource: "unscheduled" },

      // Expand all tasks by default
      { name: "expanded", defaultValue: true }
    ] as DataFieldConfig[];
  }
  generateId(): string {
    return uuidv4();
  }

  // get cls() {
  //   //ignore typecript errors
  //   // adds 'b-critical' CSS class to critical tasks{ 'b-critical' : this.critical }
  //   return Object.assign(super.cls, { "b-critical": this.critical });
  // }
}
