import { useEffect, useState } from "react";
import { Outlet, useParams, useRouteError } from "react-router-dom";
import Navigation from "./Navigation";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./layout.scss";
import Header from "./header/Header";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setCurrentInstance, setCurrentTenant } from "../store/slices/userSlice";
import { Loading } from "enada-components";
import useSaveRecord from "../utils/hooks/useSaveProject";
import { LicenseType } from "enada-common";
import UnlicensedUser from "../pages/error/UnlicensedUser";
import ErrorPage from "../pages/error/ErrorPage";
import WrongURL from "../pages/error/WrongURL";
import NoInstanceAccess from "pages/error/NoInstanceAccess";
import {
  useGetModulesQuery,
  useGetMyLicenseQuery,
  useGetMyRolesQuery,
  useGetRegionQuery
} from "services/api";
import { selectRegion, setRegion } from "store/slices/configSlice";

const Layout = () => {
  const dispatch = useAppDispatch();

  const region = useAppSelector(selectRegion);
  const { data: fetchedRegion } = useGetRegionQuery(undefined, {
    skip: Boolean(region)
  });

  useEffect(() => {
    if (fetchedRegion) {
      dispatch(setRegion(fetchedRegion));
    }
  }, [dispatch, fetchedRegion]);

  const {
    data: userRoles = [],
    isLoading: userRolesIsLoading,
    isError: userRolesIsError
  } = useGetMyRolesQuery();
  const { data: myLicense, isLoading: myLicensesIsLoading } = useGetMyLicenseQuery();
  const { isLoading: modulesIsLoading } = useGetModulesQuery();

  const [open, setOpen] = useState<boolean>(localStorage.getItem("navigationExpanded") === "true");
  const [loadingState, setLoadingState] = useState(0);
  const error = useRouteError();

  const { tenant, instance } = useParams();

  const setParameters = async () => {
    try {
      dispatch(setCurrentTenant(tenant?.toLowerCase() ?? "")); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point
      dispatch(setCurrentInstance(instance?.toLowerCase() ?? "")); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point
      dispatch(setCurrentTenant(tenant?.toLowerCase() ?? "")); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point
      localStorage.setItem("tenant", tenant?.toLowerCase() ?? "");

      dispatch(setCurrentInstance(instance?.toLowerCase() ?? "")); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point
      localStorage.setItem("instance", instance?.toLowerCase() ?? "");

      setLoadingState(1);
    } catch (e) {
      setLoadingState(2);
    }
  };

  useSaveRecord();

  useEffect(() => {
    setParameters();
  });

  const toggle = () => {
    localStorage.setItem("navigationExpanded", (!open).toString());
    setOpen(prev => !prev);
  };
  if (error) return <ErrorPage />;

  if (userRolesIsError) {
    if (myLicense && myLicense?.licenseType !== LicenseType.None) return <WrongURL />;
    else return <UnlicensedUser />;
  }

  if (loadingState === 0 || userRolesIsLoading || modulesIsLoading || myLicensesIsLoading)
    return <Loading size={150} isFullPage />;

  if (loadingState === 1 && myLicense?.licenseType === LicenseType.None) return <UnlicensedUser />;
  if (!(userRoles?.length > 0)) return <NoInstanceAccess />;

  if (loadingState === 1 && myLicense?.licenseType !== LicenseType.None) {
    return (
      <Box className="main-grid-root">
        <Grid container className="main-grid-padding">
          <Grid item>
            <Paper
              sx={{
                zIndex: "0"
              }}
              className={open ? "main-grid-nav-paper" : "main-grid-nav-paper-closed"}
            >
              <Navigation open={open} handleMenuClose={toggle} />
            </Paper>
          </Grid>
          <Grid
            item
            xs
            className={open ? "main-grid-item-spacing" : "main-grid-item-spacing-nav-closed"}
          >
            <Header />
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return <ErrorPage />;
};

export default Layout;
