import { FC } from "react";
import { useUniqueGradientIds } from "./useUniqueGradientIds";

const EdiIconSmall: FC = () => {
  const gradientIds = useUniqueGradientIds(6);

  return (
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.5091 37.1934C31.5518 40.4443 26.465 42.329 20.9693 42.1623C14.4713 41.9642 8.71534 38.9396 4.82129 34.289"
        stroke={`url(#${gradientIds.gradient1})`}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.4023 4.042C14.2955 2.62273 17.5424 1.82764 20.9731 1.82764C28.1252 1.82764 34.479 5.28494 38.4933 10.6386"
        stroke={`url(#${gradientIds.gradient2})`}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.3249 17.8907C35.6878 21.8495 34.9353 26.1812 35.2201 30.465C32.3797 34.7831 27.0108 37.3116 21.886 37.2671C12.4295 37.3253 4.62702 28.3334 4.90156 18.9993C4.90834 17.9728 5.07104 17.1311 5.43371 16.1252C7.97578 9.54892 15.3342 5.94942 22.0724 6.16498C28.8649 6.32237 36.0572 10.8662 37.3215 17.8942L37.3249 17.8907Z"
        fill={`url(#${gradientIds.gradient3})`}
      />
      <path
        d="M21.9838 34.1364C22.2176 34.1022 22.4176 34.174 22.4956 34.4204C22.5803 34.6907 22.3803 34.9131 22.1227 34.9473C21.4787 35.0397 20.8212 35.0636 20.1501 35.026C19.2519 34.9747 18.391 34.7557 17.5741 34.369C17.2318 34.2082 16.8488 34.0029 16.4251 33.7532C16.3098 33.6847 16.2353 33.606 16.2048 33.5205C16.0929 33.2023 16.3437 32.9217 16.6691 33.0073C16.7539 33.0312 16.8352 33.062 16.9132 33.1099C17.1606 33.2536 17.4046 33.3836 17.6453 33.5034C18.3706 33.8558 19.1027 34.0782 19.8416 34.1672C20.5602 34.2527 21.272 34.2424 21.9838 34.1432V34.1364Z"
        fill="#080020"
      />
      <path
        d="M21.9838 34.1364C22.2176 34.1022 22.4176 34.174 22.4956 34.4204C22.5803 34.6907 22.3803 34.9131 22.1227 34.9473C21.4787 35.0397 20.8212 35.0636 20.1501 35.026C19.2519 34.9747 18.391 34.7557 17.5741 34.369C17.2318 34.2082 16.8488 34.0029 16.4251 33.7532C16.3098 33.6847 16.2353 33.606 16.2048 33.5205C16.0929 33.2023 16.3437 32.9217 16.6691 33.0073C16.7539 33.0312 16.8352 33.062 16.9132 33.1099C17.1606 33.2536 17.4046 33.3836 17.6453 33.5034C18.3706 33.8558 19.1027 34.0782 19.8416 34.1672C20.5602 34.2527 21.272 34.2424 21.9838 34.1432V34.1364Z"
        stroke="black"
      />
      <path
        d="M30.8881 29.7841C30.0205 30.3932 29.0341 30.7353 27.9902 30.7353C24.4957 30.7353 21.6621 26.8929 21.6621 22.1506C21.6621 17.4083 24.4957 13.5659 27.9902 13.5659C29.0341 13.5659 30.0205 13.908 30.8881 14.5171C28.2918 15.1808 26.326 18.3458 26.326 22.1506C26.326 25.9554 28.2918 29.1169 30.8881 29.7841Z"
        fill="white"
      />
      <path
        d="M34.3185 22.154C34.3185 25.4763 32.9254 28.3607 30.8884 29.7875C28.2921 29.1237 26.3262 25.9588 26.3262 22.154C26.3262 18.3492 28.2921 15.1877 30.8884 14.5204C32.9254 15.9472 34.3185 18.8282 34.3185 22.154Z"
        fill="#080020"
      />
      <path
        d="M14.4458 27.673C13.7238 28.1931 12.907 28.4874 12.0393 28.4874C9.13794 28.4874 6.78906 25.1992 6.78906 21.1412C6.78906 17.0832 9.14133 13.7951 12.0393 13.7951C12.907 13.7951 13.7238 14.0894 14.4424 14.6094C12.2867 15.1774 10.6564 17.8873 10.6564 21.1412C10.6564 24.3952 12.2901 27.1051 14.4424 27.673H14.4458Z"
        fill="white"
      />
      <path
        d="M6.01306 27.9981C2.4711 26.561 2.50839 23.6116 2.50839 20.3201C2.50839 17.966 1.97285 16.6316 4.99284 13.3879C4.27767 14.9242 3.0812 18.2295 3.25745 20.7957C3.4642 23.8169 4.84032 26.6876 6.01306 27.9981Z"
        fill="#23E4F9"
      />
      <path
        d="M6.01285 27.9981C5.96201 28.0084 5.91117 28.0186 5.86032 28.0255C5.66035 28.0597 5.45699 28.0734 5.25023 28.0734C2.34887 28.0734 0 24.7852 0 20.7272C0 16.9156 2.07433 13.7848 4.72825 13.4153C4.81638 13.405 4.90112 13.3948 4.98925 13.3879C4.69437 13.6719 4.41643 14.0004 4.16222 14.3665C3.14201 15.8446 2.50479 17.966 2.50479 20.3201C2.50479 23.6116 3.7521 26.4413 5.54511 27.7107C5.69763 27.8167 5.85355 27.9125 6.01285 27.9981Z"
        fill={`url(#${gradientIds.gradient4})`}
      />
      <path
        d="M17.2933 21.1412C17.2933 23.9846 16.1375 26.4549 14.4462 27.673C12.2905 27.105 10.6602 24.3952 10.6602 21.1412C10.6602 17.8873 12.2939 15.1774 14.4462 14.6094C16.1375 15.8309 17.2933 18.2979 17.2933 21.1412Z"
        fill="#080020"
      />
      <path
        d="M43.696 25.9569C43.1044 28.1511 41.9828 29.9586 40.6694 31.0756C40.341 31.3531 40.0062 31.5878 39.6616 31.7728H39.6583C38.6408 31.6109 38.3189 31.2672 37.9125 30.5898C37.4054 29.7471 36.1798 21.6544 40.3475 18.6175C41.209 17.9897 41.9503 17.8872 42.3826 17.9038C42.5094 18.0227 42.6265 18.1549 42.7402 18.2937C43.0101 18.6241 43.2441 19.0108 43.436 19.4371C43.4815 19.5329 43.5237 19.6287 43.5627 19.7312C44.2194 21.3999 44.317 23.647 43.696 25.9569Z"
        fill={`url(#${gradientIds.gradient5})`}
      />
      <path
        d="M44.9992 23.0654C45.0057 23.9444 44.9082 24.8697 44.6741 25.7883C44.6644 25.8379 44.6514 25.8842 44.6384 25.9337C44.0109 28.2634 42.9186 30.0875 41.7483 31.0524C41.1663 31.5349 40.5617 31.8058 39.9895 31.8158C39.8724 31.8025 39.7619 31.7893 39.6611 31.7728C40.0057 31.5877 40.3406 31.3531 40.6689 31.0755C41.9823 29.9586 43.1039 28.151 43.6956 25.9569C44.3165 23.647 44.219 21.3999 43.5623 19.7311C43.5233 19.6287 43.481 19.5329 43.4355 19.437C43.2437 19.0108 43.0096 18.6241 42.7398 18.2937C42.626 18.1549 42.509 18.0227 42.3822 17.9037C42.431 17.9071 42.4797 17.9137 42.5285 17.917C42.6228 17.9269 42.7138 17.95 42.8048 17.9698C42.9024 17.993 43.0031 18.0161 43.1039 18.0293C44.0109 18.1318 44.983 20.3689 44.9992 23.0654Z"
        fill={`url(#${gradientIds.gradient6})`}
      />
      <defs>
        <linearGradient
          id={`${gradientIds.gradient1}`}
          x1="3.25382"
          y1="38.2305"
          x2="37.0776"
          y2="38.2305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBA00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={`${gradientIds.gradient2}`}
          x1="9.83768"
          y1="6.23365"
          x2="40.059"
          y2="6.23365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBA00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={`${gradientIds.gradient3}`}
          x1="37.3249"
          y1="21.7118"
          x2="4.89453"
          y2="21.7118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FF" />
          <stop offset="0.724" stopColor="#12C9F2" />
        </linearGradient>
        <linearGradient
          id={`${gradientIds.gradient4}`}
          x1="3.00643"
          y1="28.0768"
          x2="3.00643"
          y2="13.3914"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF369A" />
          <stop offset="1" stopColor="#FFBB00" />
        </linearGradient>
        <linearGradient
          id={`${gradientIds.gradient5}`}
          x1="37.3469"
          y1="24.8399"
          x2="44.1122"
          y2="24.8399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBB00" />
          <stop offset="1" stopColor="#FF369A" />
        </linearGradient>
        <linearGradient
          id={`${gradientIds.gradient6}`}
          x1="39.6611"
          y1="24.8598"
          x2="44.9992"
          y2="24.8598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2B59" />
          <stop offset="1" stopColor="#11C9F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EdiIconSmall;
