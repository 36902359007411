import { useCallback, useState } from "react";
import ProjectRecordFlyout from "../projectrecordflyout/ProjectRecordFlyout";
import { useTranslation } from "react-i18next";
import { Button, Stack, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectRecord,
  selectRecordAssociations,
  selectWorkflowStage,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import AddIcon from "@mui/icons-material/Add";
import CreateRecordModal from "../../createrecordmodal/CreateRecordModal";
import { RecordAccessRole, RecordType, formatModifiedDate } from "enada-common";
import { BorderedTableCell } from "../../personal/approvalstable/ApprovalsTable";
import { Link } from "react-router-dom";
import { EdisonTypography } from "enada-components";
import { Person } from "@microsoft/mgt-react";
import "./associatedrecords.scss";
import WorkflowStageChip from "../../workflowStageChip/WorkflowStageChip";
import ModuleLabel from "../../moduleLabel/ModuleLabel";
import HasAccessRoles from "../../hasAccessRoles/HasAccessRoles";
import { getAllowedRecordTypes } from "../../createrecordmodal/getAllowedRecordTypes";
import { useGetWorkflowStagesQuery } from "services/api";

const AssociatedRecords = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { data: stages = [] } = useGetWorkflowStagesQuery();

  const currentStage = useAppSelector(selectWorkflowStage);
  const record = useAppSelector(selectRecord);
  const associations = useAppSelector(selectRecordAssociations);

  let validAssociations = associations;
  if (record?.recordType === RecordType.Challenges) {
    validAssociations = associations.filter(
      association => association.recordType !== RecordType.Ideas
    );
  } else if (record?.recordType === RecordType.Ideas) {
    validAssociations = associations.filter(
      association => association.recordType !== RecordType.Challenges
    );
  }

  const [createProjectModalOpen, setCreateRecordModalOpen] = useState(false);

  const getWorkflowStage = useCallback(
    (workflowStageId?: number) => {
      return stages.find(stage => stage.id === workflowStageId);
    },
    [stages]
  );

  return (
    <>
      <ProjectRecordFlyout
        sx={{ width: "70em", maxWidth: "none" }}
        title={
          <Stack>
            <EdisonTypography title={t("associatedRecords")} variant="h2" />
            <EdisonTypography title={record?.displayName ?? ""} variant="data" />
          </Stack>
        }
        topBarModule={
          getAllowedRecordTypes(
            record?.recordType,
            currentStage?.allowBusinessCaseCreation,
            currentStage?.allowProjectCreation,
            currentStage?.allowRecordCreation
          )?.length ? (
            <HasAccessRoles roles={[RecordAccessRole.CreateAssociation]}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(setIsFlyoutOpen(false));
                  setCreateRecordModalOpen(true);
                }}
              >
                {t("create")}
              </Button>
            </HasAccessRoles>
          ) : null
        }
      >
        <Table stickyHeader className="associated-records-table-root">
          <TableHead>
            <TableRow>
              <BorderedTableCell>{t("name")}</BorderedTableCell>
              <BorderedTableCell>{t("type")}</BorderedTableCell>
              <BorderedTableCell>{t("stage")}</BorderedTableCell>
              <BorderedTableCell>{t("associatedInStage")}</BorderedTableCell>
              <BorderedTableCell>{t("associatedDate")}</BorderedTableCell>
              <BorderedTableCell>{t("associatedBy")}</BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {validAssociations.length === 0 ? (
              <TableRow>
                <BorderedTableCell colSpan={6}> {t("noResultsMessage")}</BorderedTableCell>
              </TableRow>
            ) : (
              validAssociations.map(association => {
                const workflowStage = getWorkflowStage(association.workflowStageId);
                const asscWorkflowStage = getWorkflowStage(association.associatedWorkflowStageId);

                return (
                  <TableRow key={association.id}>
                    <BorderedTableCell>
                      <Link
                        className="tasks-link"
                        to={`../../${association.recordType?.toLowerCase()}/${
                          association.recordId
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {association.displayName ?? association.recordId}
                      </Link>
                    </BorderedTableCell>
                    <BorderedTableCell>
                      <ModuleLabel recordType={association.recordType as RecordType} />
                    </BorderedTableCell>
                    <BorderedTableCell>
                      <WorkflowStageChip
                        color={workflowStage?.configuration.color}
                        displayName={workflowStage?.displayName}
                      />
                    </BorderedTableCell>
                    <BorderedTableCell>
                      {stages && !asscWorkflowStage ? (
                        <>{t("stageNoLongerExists")}</>
                      ) : (
                        <WorkflowStageChip
                          color={asscWorkflowStage?.configuration.color}
                          displayName={asscWorkflowStage?.displayName}
                        />
                      )}
                    </BorderedTableCell>
                    <BorderedTableCell>
                      {formatModifiedDate(new Date(association?.associated ?? ""))}
                    </BorderedTableCell>
                    <BorderedTableCell>
                      <Person showPresence={true} userId={association.associatedBy} />
                    </BorderedTableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </ProjectRecordFlyout>
      {createProjectModalOpen && (
        <CreateRecordModal
          sourceRecord={record}
          open={createProjectModalOpen}
          onClose={() => setCreateRecordModalOpen(false)}
        />
      )}
    </>
  );
};

export default AssociatedRecords;
