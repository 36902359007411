import { ConfigLayoutProps } from "enada-common";
import { Button, Paper, Stack } from "@mui/material";
import { FC } from "react";
import EdisonTypography from "../edison/typography/EdisonTypography";
import "./configlayout.scss";

const ConfigLayout: FC<ConfigLayoutProps> = ({
  t,
  onCancelCallback,
  onSaveCallback,
  title,
  secondaryTitle,
  topPanelContent,
  saveDisabled,
  children,
  topPanelDirection = "row"
}) => {
  return (
    <Stack spacing={2} className="config-layout-root" data-testid={"config-layout"}>
      <Paper>
        <Stack className="top-panel-container" spacing={1}>
          <Stack direction="row" className="title">
            <EdisonTypography variant="h2" title={title} />
            <Stack direction="row" spacing={1}>
              <Button
                data-testid={"config-layout-button-save"}
                variant="contained"
                onClick={onSaveCallback}
                disabled={saveDisabled}
              >
                <EdisonTypography variant="h5" title={t("save")} />
              </Button>
              <Button
                data-testid={"config-layout-button-cancel"}
                variant="outlined"
                onClick={onCancelCallback}
              >
                <EdisonTypography variant="h5" title={t("cancel")} />
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction={topPanelDirection as "row" | "row-reverse" | "column" | "column-reverse"}
            spacing={2}
            className="content"
            data-testid={"config-layout-content"}
          >
            {topPanelContent}
          </Stack>
        </Stack>
      </Paper>
      {children &&
        (secondaryTitle ? (
          <Paper>
            <Stack
              className="bottom-panel-container"
              spacing={1}
              data-testid={"config-layout-secondarycontent"}
            >
              <Stack direction="row" className="title">
                <EdisonTypography variant="h2" title={secondaryTitle} />
              </Stack>
              <Stack direction="row" spacing={2} className="content">
                {children}
              </Stack>
            </Stack>
          </Paper>
        ) : (
          children
        ))}
    </Stack>
  );
};

export default ConfigLayout;
export { ConfigLayout };
