import {
  BaseRecord,
  FilterOperation,
  NotificationLevel,
  OdataOperations,
  RecordType
} from "enada-common";
import { Stack } from "@mui/material";
import buildQuery from "odata-query";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EdisonTypography, SingleTable, BoldTranslation } from "enada-components";
import {
  AlertDialog as DeleteAlertDialog,
  AlertDialog as RestoreAlertDialog
} from "../../../components/alertdialog/AlertDialog";
import { RecycleBinHeader } from "./RecycleBinHeader";
import { recycleBinRollupRows } from "../../../config/rollupTableColumns";
import {
  useRestoreRecyledRecordsMutation,
  useDeleteRecycledRecordsMutation,
  useGetTemplatesQuery,
  useGetRecycledRecordsQuery,
  useGetRecycledRecordsCountQuery
} from "../../../services/api";
import { useAppDispatch } from "../../../store/hooks";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { ODataApiResponse } from "../../../types/ApiResponse";
import { extractOdata } from "../../../utils/filterUtils";
import { useDisclosure } from "../../../utils/hooks/useDisclosure";
import { useGetRecordTypeDetails } from "../../../utils/hooks/useGetRecordTypeDetails";
import { selectRollupRowsFromData } from "../../../utils/rtkQuery";

export type RecycleBinRow = {
  modified: string;
  modifiedBy: string;
  name: string;
  template: string;
  type: RecordType;
};

const defaultOdataQuery = {
  orderBy: "modified desc",
  expand: "owners",
  skip: 0,
  top: 100,
  filters: []
};

export const RecycleBin: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [singleRow, setSingleRow] = useState<number>();
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [odataOperation, setOdataOperation] = useState<OdataOperations>(defaultOdataQuery);

  const [restoreRecords, { isLoading: restoreIsLoading }] = useRestoreRecyledRecordsMutation();
  const [deleteRecords, { isLoading: deleteIsLoading }] = useDeleteRecycledRecordsMutation();
  const { data: templates = [] } = useGetTemplatesQuery();

  const { isOpen: deleteIsOpen, onToggle: onToggleDelete } = useDisclosure();
  const { isOpen: restoreIsOpen, onToggle: onToggleRestore } = useDisclosure();

  const selectRollupRows = useMemo(() => {
    return selectRollupRowsFromData<ODataApiResponse<BaseRecord[]>>(data => {
      return data?.value?.map(row => {
        return {
          id: row.id ?? 0,
          name: row.displayName,
          template: templates.find(template => template.id === row.recordTemplateId)?.displayName,
          type: row.recordType,
          modifiedBy: row.modifiedBy,
          modified: row.modified,
          actions: row.id
        };
      });
    });
  }, [templates]);

  const builtQuery = useMemo(
    () =>
      buildQuery({
        ...defaultOdataQuery,
        ...extractOdata(odataOperation)
      }),
    [odataOperation]
  );

  const { rows = [] } = useGetRecycledRecordsQuery(builtQuery, {
    selectFromResult: result => ({
      ...result,
      rows: selectRollupRows(result ?? [])
    })
  });

  const { data: recordsCount = 0 } = useGetRecycledRecordsCountQuery(builtQuery);

  const { typeMap, getRecordIcon } = useGetRecordTypeDetails();

  const getDisplayNameFromId = useCallback(
    (id: number | undefined) => {
      const foundName = rows.find(row => row.id === id)?.name as string;

      if (!foundName) return id?.toString();

      return foundName;
    },
    [rows]
  );

  const onDeleteRecords = async () => {
    try {
      if (singleRow) {
        await deleteRecords([singleRow]);
        setSingleRow(undefined);
      } else {
        await deleteRecords(selectedRowIds);
        setSelectedRowIds([]);
      }

      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Success,
          title: "deleteRecordsSuccess",
          message: ""
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Error,
          message: "",
          title: "errorDeletingRecords"
        })
      );
    } finally {
      onToggleDelete();
    }
  };

  const onRestoreRecords = async () => {
    try {
      if (singleRow) {
        await restoreRecords([singleRow]);
        setSingleRow(undefined);
      } else {
        await restoreRecords(selectedRowIds);
        setSelectedRowIds([]);
      }
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Success,
          title: "restoreRecordsSuccess",
          message: ""
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Error,
          message: "",
          title: "errorRestoringRecords"
        })
      );
    } finally {
      onToggleRestore();
    }
  };

  const onChangeFilters = useCallback((filters: FilterOperation[]) => {
    setOdataOperation(prevState => {
      return {
        ...prevState,
        filters,
        skip: 0
      };
    });
  }, []);

  const loadMoreRows = (skip: number) => {
    if (odataOperation?.skip >= recordsCount) return;

    setOdataOperation(prev => ({
      ...prev,
      skip: skip
    }));
  };

  return (
    <Stack width="full">
      <RecycleBinHeader
        onToggleDelete={onToggleDelete}
        onToggleRestore={onToggleRestore}
        onChangeFilters={onChangeFilters}
        disableActionButton={!selectedRowIds.length}
        activeFilters={odataOperation?.filters ?? []}
      />

      <SingleTable
        columns={recycleBinRollupRows(
          t,
          typeMap,
          setSingleRow,
          onToggleRestore,
          onToggleDelete,
          getRecordIcon
        )}
        orderBy="modified"
        rows={rows}
        searchFilter=""
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        currentSkip={odataOperation.skip}
        itemsCount={recordsCount}
        rowsPerPageCount={20}
        onPageChange={loadMoreRows}
        fullScreen={false}
      />

      <DeleteAlertDialog
        title={t("delete")}
        buttonText={t("delete")}
        severity="error"
        isOpen={deleteIsOpen && (Boolean(singleRow) || selectedRowIds.length > 0)}
        onClose={() => {
          onToggleDelete();
          setSingleRow(undefined);
        }}
        onClick={onDeleteRecords}
        isLoading={deleteIsLoading}
        content={
          <Stack spacing={1} direction="column">
            <BoldTranslation i18nKey="permanentlyDeleteWarning" />
            <Stack spacing={1} direction="row" width="full" flexWrap="wrap">
              {singleRow ? (
                <EdisonTypography
                  title={getDisplayNameFromId(singleRow) ?? ""}
                  variant="data2"
                  sx={{ fontWeight: 700 }}
                />
              ) : (
                selectedRowIds.map((row, index, arr) => (
                  <EdisonTypography
                    key={row}
                    title={`${getDisplayNameFromId(row)}${index !== arr.length - 1 ? "," : ""}`}
                    variant="data2"
                    sx={{ fontWeight: 700 }}
                  />
                ))
              )}
            </Stack>
            <EdisonTypography title={t("proceedMessage")} variant="data2" />
          </Stack>
        }
      />

      <RestoreAlertDialog
        title={t("restore")}
        buttonText={t("restore")}
        severity="info"
        isOpen={restoreIsOpen && (Boolean(singleRow) || selectedRowIds.length > 0)}
        onClose={() => {
          onToggleRestore();
          setSingleRow(undefined);
        }}
        onClick={onRestoreRecords}
        isLoading={restoreIsLoading}
        content={
          <Stack spacing={1} direction="column" minWidth="450px">
            <EdisonTypography title={t("aboutToRestoreTheseItems")} variant="data2" />
            <Stack spacing={1} direction="row" width="full" flexWrap="wrap">
              {singleRow ? (
                <EdisonTypography
                  title={getDisplayNameFromId(singleRow) ?? ""}
                  variant="data2"
                  sx={{ fontWeight: 700 }}
                />
              ) : (
                selectedRowIds.map((row, index, arr) => (
                  <EdisonTypography
                    key={row}
                    title={`${getDisplayNameFromId(row)}${index !== arr.length - 1 ? "," : ""}`}
                    variant="data2"
                    sx={{ fontWeight: 700 }}
                  />
                ))
              )}
            </Stack>
            <EdisonTypography title={t("proceedMessage")} variant="data2" />
          </Stack>
        }
      />
    </Stack>
  );
};
