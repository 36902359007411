import { Field, PendingRecordTableRow, RecordTableRow } from "enada-common";
import "./rowreviewchangestables.scss";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SystemFieldType } from "../../../../pages/admin/tableconfig/TableConfig";
import { useAppSelector } from "../../../../store/hooks";
import ComponentMapper from "../../../../utils/componentMapper";
import { getValueByFieldType } from "../../../../utils/tableHelpers";
import { BorderedTableCell } from "../../../personal/approvalstable/ApprovalsTable";
import { selectRecordTableBackendRowValues } from "../../../../store/slices/recordTableSlice";
import { useGetFieldsQuery } from "services/api";

export interface RowReviewChangesTableProps {
  pendingRow: PendingRecordTableRow;
}

const RowReviewChangesTable: FC<RowReviewChangesTableProps> = ({ pendingRow }) => {
  const { t } = useTranslation(["common"]);
  const { data: fields = [] } = useGetFieldsQuery();

  const projectTableRows = useAppSelector(selectRecordTableBackendRowValues);

  const currentRow = projectTableRows.find(row => row.id === pendingRow.recordTableRowId);
  const changedValues = getTaskReviewResult(
    fields,
    currentRow,
    pendingRow.pendingRecordTableRowValue
  );

  return (
    <Table className="row-review-changes-table-root" stickyHeader>
      <TableHead className="table-header">
        <TableRow>
          <BorderedTableCell>{t("field")}</BorderedTableCell>
          <BorderedTableCell>{t("before")}</BorderedTableCell>
          <BorderedTableCell>{t("after")}</BorderedTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {changedValues?.map((changed, index) => (
          <TableRow key={index}>
            <BorderedTableCell>
              {changed?.field?.displayName ?? changed?.field?.name}
            </BorderedTableCell>
            <BorderedTableCell>
              <ComponentMapper
                value={changed.before}
                component={changed.field}
                readOnly={true}
                useInternalState={true}
                isInTable={true}
              />
            </BorderedTableCell>
            <BorderedTableCell>
              <ComponentMapper
                value={changed.after}
                component={changed.field}
                readOnly={true}
                useInternalState={true}
                isInTable={true}
              />
            </BorderedTableCell>
          </TableRow>
        ))}
        {!changedValues?.length && <TableRow>{t("noValidChangesMessage")}</TableRow>}
      </TableBody>
    </Table>
  );
};
interface RowReviewResult {
  field?: Field;
  before: any;
  after: any;
}
const changedFieldValuesToIgnore = [
  SystemFieldType.AssignedResource,
  SystemFieldType.Owner,
  SystemFieldType.Owners,
  SystemFieldType.RowId,
  46,
  47, //Created-By
  48
];
export const getTaskReviewResult = (
  fields: Field[],
  currentRow?: RecordTableRow,
  pendingRow?: RecordTableRow
) => {
  if (!currentRow?.tableRowFieldValues) return;
  if (!pendingRow?.tableRowFieldValues) return;

  const currentValues: Record<number, any> = currentRow.tableRowFieldValues?.reduce((acc, curr) => {
    const field = fields.find(field => field.id === curr.fieldId);
    if (field === undefined) return acc;
    return {
      ...acc,
      [curr.fieldId]: { value: getValueByFieldType(field, curr), field }
    };
  }, {});
  const pendingValues: Record<number, any> = pendingRow.tableRowFieldValues?.reduce((acc, curr) => {
    const field = fields.find(field => field.id === curr.fieldId);
    if (field === undefined) return acc;
    return {
      ...acc,
      [curr.fieldId]: { value: getValueByFieldType(field, curr), field }
    };
  }, {});
  const changedValues: RowReviewResult[] = [];

  Object.entries(pendingValues).forEach(([key, value]) => {
    const currentValue = currentValues[parseInt(key)];
    if (changedFieldValuesToIgnore.includes(parseInt(key))) {
      return;
    }
    if (JSON.stringify(value) !== JSON.stringify(currentValue)) {
      changedValues.push({
        field: value.field,
        before: currentValue?.value,
        after: value.value
      });
    }
  });
  return changedValues;
};

export default RowReviewChangesTable;
