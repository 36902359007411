import { User } from "@microsoft/microsoft-graph-types";
import { ResourceType } from "enada-common";
import { useEffect, useState } from "react";
import { useGetResourcesQuery } from "services/api";
import { useMsGraphRequests } from "./useMsGraphRequests";
import { GraphQueryType } from "services/graphService";

export const useGetEntities = () => {
  const [entities, setEntities] = useState<Record<string, User>>({});
  const { data: resources = [] } = useGetResourcesQuery();
  const { batchGetEntitiesById } = useMsGraphRequests();

  useEffect(() => {
    if (!resources.length) return;
    const newIds = resources
      .filter(row => row.type === ResourceType.AAD && entities[row?.userId as string] === undefined)
      .map(row => row.userId);

    const fetchEntities = async () => {
      const result = await batchGetEntitiesById(newIds as string[], GraphQueryType.Users);
      setEntities(result as Record<string, User>);
    };

    fetchEntities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  return { entities };
};
