import { RecordAccessRole, RecordRole, RoleType, WorkflowReviewState } from "enada-common";
import { Button, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getRecordWorkflowReviewersAsync,
  resetReviewers,
  selectIsRecordFlyoutOpen,
  selectRecordAuth,
  selectRecordStageReviewerList,
  selectRecordType,
  selectWorkflowStage,
  selectWorkflowUserReviews
} from "../../../store/slices/recordSlice";
import { EdisonTabs } from "enada-components";
import useHasRecordAccessRole from "../../../utils/hooks/useHasRecordAccessRole";
import useHasRecordRole from "../../../utils/hooks/useHasRecordRole";
import HasAccessRoles from "../../hasAccessRoles/HasAccessRoles";
import ProjectRecordFlyout from "../../projects/projectrecordflyout/ProjectRecordFlyout";
import NormalStageApproval from "./normalstageapproval/NormalStageApproval";
import StageChange from "./stagechange/StageChange";
import StageReviewApproval from "./stagereviewapproval/StageReviewApproval";
import StageReviewStatus from "./stagereviewstatus/StageReviewStatus";
import useHasRole from "../../../utils/hooks/useHasRole";
import { ReviewUserType } from "../../../types/stageReview";
import { useGetWorkflowQuery } from "services/api";

const StageApprovalTabs = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const { hasRecordRole } = useHasRecordRole();
  const { hasRecordAccessRole } = useHasRecordAccessRole();
  const { hasRole } = useHasRole();
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);

  const currentStage = useAppSelector(selectWorkflowStage);
  const review = useAppSelector(selectWorkflowUserReviews);

  const stageReviewerList = useAppSelector(selectRecordStageReviewerList);
  const isManager = hasRecordRole([RecordRole.Manager]) || hasRole([RoleType.InstanceAdmin]);

  const { data: workflow } = useGetWorkflowQuery(currentStage?.workflowId as number, {
    skip: !currentStage?.workflowId
  });

  const [reviewCurrentView, setReviewCurrentView] = useState<ReviewUserType>(
    hasRecordAccessRole([RecordAccessRole.Review])
      ? ReviewUserType.Reviewer
      : isManager
      ? ReviewUserType.Manager
      : ReviewUserType.Owner
  );
  const isFlyoutOpen = useAppSelector(selectIsRecordFlyoutOpen);

  const ViewChangeButton = () => {
    // If we are an owner/ manager and we do not have review capabilities then we should not be able to swith to the reviewers view
    if (
      (isManager || hasRecordRole([RecordRole.Owner])) &&
      !hasRecordAccessRole([RecordAccessRole.Review])
    )
      return null;

    const typeToChangeTo =
      reviewCurrentView === ReviewUserType.Reviewer
        ? isManager
          ? ReviewUserType.Manager
          : ReviewUserType.Owner
        : ReviewUserType.Reviewer;

    return (
      <Button onClick={() => setReviewCurrentView(typeToChangeTo)}>
        {t(`viewAs${typeToChangeTo}`)}
      </Button>
    );
  };

  const approvedCount = useMemo(
    () =>
      stageReviewerList?.filter(
        ({ workflowReview }) => workflowReview?.reviewState === WorkflowReviewState.Approved
      ).length,
    [stageReviewerList]
  );

  const canContinue = useMemo(
    () => approvedCount === currentStage?.requiredApproversCount,
    [approvedCount, currentStage?.requiredApproversCount]
  );

  useEffect(() => {
    if (
      !isFlyoutOpen ||
      !recordAuth ||
      !currentStage?.hasReview ||
      canContinue ||
      stageReviewerList?.length
    )
      return;

    dispatch(getRecordWorkflowReviewersAsync({ recordAuth, recordType }));
  }, [
    canContinue,
    currentStage?.hasReview,
    dispatch,
    isFlyoutOpen,
    recordAuth,
    recordType,
    stageReviewerList?.length
  ]);

  const workflowAllowsStageChanges = useMemo(
    () => workflow?.allowStageSkip && reviewCurrentView !== ReviewUserType.Owner,
    [reviewCurrentView, workflow?.allowStageSkip]
  );

  const stageReview = useMemo(
    () => (
      <>
        {reviewCurrentView === ReviewUserType.Reviewer && (
          <HasAccessRoles roles={[RecordAccessRole.Review]}>
            <StageReviewApproval />
          </HasAccessRoles>
        )}

        {[ReviewUserType.Owner, ReviewUserType.Manager].includes(reviewCurrentView) && (
          <Stack>
            <HasAccessRoles
              roles={[RecordAccessRole.SkipStep]}
              noMatchElement={
                <HasAccessRoles roles={[RecordAccessRole.ReadReviewers]} id="2">
                  <StageReviewStatus />
                </HasAccessRoles>
              }
              id="1"
            >
              <HasAccessRoles
                roles={[RecordAccessRole.ReadReviewers]}
                noMatchElement={<StageChange reviewCurrentView={reviewCurrentView} />}
                id="3"
              >
                <EdisonTabs
                  height="100%"
                  tabPanelStyles={{ height: "94%", width: "100%" }}
                  tabs={[
                    {
                      id: "reviews",
                      label: t("reviews"),
                      children: <StageReviewStatus />
                    },
                    {
                      id: "stagechange",
                      label: t("stageChange"),
                      children: <StageChange reviewCurrentView={reviewCurrentView} />
                    }
                  ]}
                />
              </HasAccessRoles>
            </HasAccessRoles>
          </Stack>
        )}
      </>
    ),
    [reviewCurrentView, t]
  );

  const stageTabs = useMemo(() => {
    const tabs = [
      {
        id: "nextStage",
        label: t("nextStage"),
        children: <NormalStageApproval />
      }
    ];

    if (workflowAllowsStageChanges) {
      tabs.push({
        id: "manualStageChange",
        label: t("manualStageChangeTitle"),
        children: <StageChange reviewCurrentView={reviewCurrentView} />
      });
    }

    return tabs;
  }, [reviewCurrentView, t, workflowAllowsStageChanges]);

  const stage = useMemo(() => {
    if (currentStage?.hasReview) return null;

    if (
      hasRecordAccessRole([RecordAccessRole.Continue]) &&
      hasRecordAccessRole([RecordAccessRole.SkipStep])
    ) {
      return (
        <EdisonTabs
          height="100%"
          tabPanelStyles={{ height: "94%", width: "100%" }}
          tabs={stageTabs}
        />
      );
    }
    if (hasRecordAccessRole([RecordAccessRole.Continue])) {
      return <NormalStageApproval />;
    }
    if (hasRecordAccessRole([RecordAccessRole.SkipStep])) {
      return <StageChange reviewCurrentView={reviewCurrentView} />;
    }
    return null;
  }, [currentStage?.hasReview, hasRecordAccessRole, reviewCurrentView, stageTabs]);

  const getTitle = () => {
    if (
      review?.workflowReview?.reviewState === WorkflowReviewState.Approved ||
      review?.workflowReview?.reviewState === WorkflowReviewState.Rejected
    ) {
      return t("reviewSubmitted");
    }
    return t("stageReview");
  };

  return (
    <ProjectRecordFlyout
      sx={{ height: "100%" }}
      title={getTitle()}
      onClose={() => {
        dispatch(resetReviewers());
      }}
      topBarModule={
        currentStage?.hasReview &&
        (hasRecordRole([RecordRole.Owner, RecordRole.Manager, RecordRole.Reviewer]) ||
          hasRole([RoleType.InstanceAdmin])) && (
          <HasAccessRoles
            roles={[
              RecordAccessRole.Continue,
              RecordAccessRole.SkipStep,
              RecordAccessRole.ReadReviewers
            ]}
          >
            <ViewChangeButton />
          </HasAccessRoles>
        )
      }
    >
      <Stack height={"100%"}>{currentStage?.hasReview ? stageReview : stage}</Stack>
    </ProjectRecordFlyout>
  );
};

export default StageApprovalTabs;
