import { RecordRole, Workflow, WorkflowStage } from "enada-common";
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  Background,
  Edge,
  Node,
  NodeChange,
  ReactFlowInstance,
  ReactFlowProvider,
  applyNodeChanges
} from "reactflow";
import parseToFrontend from "../utils/parsing/parseToFrontend";
import "./readonlyworkflowzone.scss";
import dagre from "dagre";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetWorkflowSlice, setWorkflowReadOnly } from "../../../store/slices/workflowSlice";
import { nodeTypes } from "../utils/FrontendWorkflow.constants";
import { getStructuredLayout } from "../utils/getStructuredLayout";
import { selectRecordPermissions, selectWorkflowStage } from "store/slices/recordSlice";

export interface ReadOnlyWorkflowZoneProps {
  workflow: Workflow | null;
  onStageClick?: (newStage: string) => void;
  selectedStage?: WorkflowStage["name"];
  selectableStages: WorkflowStage[];
  //ID needed when rendering mulitple readonly workflow zones on the same page
  //https://github.com/xyflow/xyflow/issues/2546
  id: string;
}

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const ReadOnlyWorkflowZone: FC<ReadOnlyWorkflowZoneProps> = ({
  workflow,
  onStageClick,
  selectedStage,
  selectableStages,
  id
}) => {
  const dispatch = useAppDispatch();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [layoutSet, setLayoutSet] = useState(false);
  const recordPermissions = useAppSelector(selectRecordPermissions);
  const workflowStage = useAppSelector(selectWorkflowStage);

  // This is a temp fix to show the reviewers on the stage review node. The BE currently does not save reviewers that are added via manage access
  // to the workflow so we need to manually add them to the workflow object if we are in a stage review
  // It will only ever show the reviewers for the current stage review
  const workflowWithUpdatedReviewers = useMemo(() => {
    if (!workflowStage?.hasReview) return workflow;
    const reviewers = recordPermissions.filter(
      permission => permission.role === RecordRole.Reviewer
    );

    const updatedWorkflow = {
      ...workflow,
      stages: workflow?.stages?.map(stage => {
        if (stage.id !== workflowStage.id) return stage;
        return {
          ...stage,
          reviewers
        };
      })
    } as Workflow;

    return updatedWorkflow;
  }, [recordPermissions, workflow, workflowStage]);

  const selectNode = (selected: Node) => {
    setNodes(
      nodes.map(node =>
        node.id === selected.id ? { ...node, selected: true } : { ...node, selected: false }
      )
    );
  };
  const onNodeClick = (event: MouseEvent, clickedNode: Node) => {
    event.preventDefault();
    if (!onStageClick) return;
    if (!clickedNode.selectable) return;
    onStageClick(clickedNode.data.name);
    selectNode(clickedNode);
  };

  const onNodesChange = (changes: NodeChange[]) => {
    // We need to wait for the first nodes update by react flow as it adds the width to each node,
    // which is needed by dagre to layout the nodes correctly
    if (layoutSet) return;
    const updatedNodes = applyNodeChanges(changes, nodes);
    const layoutedNodes = getStructuredLayout(updatedNodes, edges);
    setNodes(layoutedNodes);
    setLayoutSet(true);
  };
  useEffect(() => {
    dispatch(setWorkflowReadOnly(true));
    return () => {
      dispatch(resetWorkflowSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!workflowWithUpdatedReviewers) return;
    const result = parseToFrontend(workflowWithUpdatedReviewers);
    setEdges(
      result.edges.map((edge: Edge) => ({
        ...edge,
        elementSelectable: false,
        focusable: false,
        updatable: false
      }))
    );

    setNodes(
      result.nodes.map(node =>
        selectableStages.some(stage => stage.name === node.data.name)
          ? { ...node, selectable: true }
          : { ...node, selectable: false }
      )
    );
  }, [selectableStages, workflowWithUpdatedReviewers]);

  useEffect(() => {
    if (!layoutSet) return;

    //Center the nodes in the viewPort once we have layouted the nodes
    reactFlowInstance?.setViewport({
      x: 150,
      y: 10,
      zoom: 1
    });
  }, [layoutSet, reactFlowInstance]);

  useEffect(() => {
    if (!layoutSet) return;
    const selectedNode = nodes.find(node => node.data.name === selectedStage);
    if (!selectedNode) return;
    selectNode(selectedNode);
  }, [selectedStage, layoutSet]);

  return (
    <div className="read-only-workflowzone-root">
      <ReactFlowProvider>
        <div ref={reactFlowWrapper} className="react-flow-container">
          <ReactFlow
            id={id}
            onInit={setReactFlowInstance}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
            proOptions={{
              hideAttribution: true
            }}
          >
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

export default ReadOnlyWorkflowZone;
