import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BackendNotification } from "enada-common";
import { Notification } from "enada-common";
import { getNotifications } from "../../services/APIService";
import { RootState } from "../../store/store";

export interface notificationState {
  backendNotificationList: BackendNotification[];
  currentNotification: Notification | null;
  backendNotificationsPanelOpen: boolean;
}

const initialState: notificationState = {
  currentNotification: null,
  backendNotificationList: [],
  backendNotificationsPanelOpen: false
};

export const getNotificationsAsync: any = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    // WAITING FOR BACK END TO COMPLETE NOTIFICATIONS!!!
    // const response = await getNotifications();
    // return response.value;
    return;
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCurrentNotification: (state, action: PayloadAction<Notification>) => {
      state.currentNotification = action.payload;
    },
    clearCurrentNotification: state => {
      state.currentNotification = null;
    },
    setBackendNotificationsPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.backendNotificationsPanelOpen = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getNotificationsAsync.fulfilled, (state, action) => {
      if (!action.payload) return;

      (action.payload as BackendNotification[]).forEach((notification, index) => {
        if (!state.backendNotificationList.find(toFind => toFind.id === notification.id)) {
          state.backendNotificationList.push(notification);
        }
      });
    });
  }
});

export const {
  clearCurrentNotification,
  setBackendNotificationsPanelOpen,
  setCurrentNotification
} = notificationSlice.actions;

const inputSelectNotification = (state: RootState) => state.notification;

export const selectCurrentNotification = createSelector(
  [inputSelectNotification],
  notification => notification.currentNotification
);

export const selectBackendNotifications = createSelector(
  [inputSelectNotification],
  notification => notification.backendNotificationList
);

export const selectBackendNotificationsPanelOpen = createSelector(
  [inputSelectNotification],
  notification => notification.backendNotificationsPanelOpen
);

export default notificationSlice.reducer;
