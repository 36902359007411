import { FC, useEffect, useMemo } from "react";
import { Alert, Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectRecordTableBackendRowValues,
  setRecordTableRowValues
} from "store/slices/recordTableSlice";
import { useDisclosure } from "utils/hooks/useDisclosure";
import { BoldTranslation, EdisonTypography } from "enada-components";
import { selectAllCalendars } from "store/slices/calendarsSlice";
import { SystemFieldType } from "pages/admin/tableconfig/TableConfig";
import {
  useGetResourcesQuery,
  useGetTablesQuery,
  useUpdateTableRowValuesMutation
} from "services/api";
import { selectCanEditRecord, selectRecord, selectRecordAuth } from "store/slices/recordSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import { OperationType, ResourceType } from "enada-common";
import { useGetEntities } from "utils/hooks/useGetEntities";

export const EffectiveCalendarChangedDialog: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const calendars = useAppSelector(selectAllCalendars);
  const canEditRecord = useAppSelector(selectCanEditRecord);
  const recordTableRows = useAppSelector(selectRecordTableBackendRowValues);

  const { data: tables = [] } = useGetTablesQuery();
  const { data: resources = [] } = useGetResourcesQuery();
  const { entities } = useGetEntities();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateEffectiveCalendarChangedValue, { isLoading }] = useUpdateTableRowValuesMutation();

  // Rows that have effective calendar working time changed
  const effectiveCalendarChangedRows = useMemo(() => {
    return recordTableRows.filter(row => row.effectiveCalendarWorkingTimeChanged);
  }, [recordTableRows]);

  const changedEffectiveCalendars = useMemo(() => {
    const calendarMap = new Map();

    effectiveCalendarChangedRows.forEach(row => {
      const calendarId = row.tableRowFieldValues?.find(
        field => field.fieldId === SystemFieldType.ExtendedCalendarId
      )?.decimalValue;

      if (calendarId) {
        if (!calendarMap.has(calendarId)) {
          calendarMap.set(calendarId, new Set());
        }
        calendarMap.get(calendarId).add(row.tableId);
      }
    });

    const uniqueCalendars = Array.from(calendarMap, ([calendarId, tableIdsSet]) => ({
      calendarId,
      tableIds: Array.from(tableIdsSet)
    }));

    const calendarAndTables = uniqueCalendars.map(item => ({
      calendarName: calendars.find(calendar => calendar.id === item.calendarId)
        ?.displayName as string,
      tableNames: (
        item.tableIds.map(id => tables.find(table => table.id === id)?.displayName) as string[]
      )?.join(", ")
    }));

    return calendarAndTables;
  }, [calendars, effectiveCalendarChangedRows, tables]);

  // Rows that have resource calendar changed
  const resourceCalendarChangedRows = useMemo(() => {
    return recordTableRows.filter(row => row.resourceCalendarChanged);
  }, [recordTableRows]);

  const changedResourceCalendars = useMemo(() => {
    const resourceMap = new Map<string, Map<string, Set<string | number>>>();

    resourceCalendarChangedRows.forEach(row => {
      const tableName = tables.find(table => table.id === row.tableId)?.displayName;

      const changedResources = row.tableRowFieldValues
        ?.find(field => field.fieldId === SystemFieldType.AssignedResource)
        ?.extendedValue?.value?.filter(resource => resource.calendarChanged);

      if (changedResources) {
        const foundResources = resources.filter(resource =>
          changedResources.find(value => value.resourceId === resource.id)
        );
        foundResources.forEach(resource => {
          const resourceName = (
            resource.type === ResourceType.AAD
              ? entities[resource.userId ?? ""]?.displayName
              : resource.displayName
          ) as string;

          if (!resourceMap.has(resourceName)) {
            resourceMap.set(resourceName, new Map());
          }

          const tableMap = resourceMap.get(resourceName);
          if (!tableMap || !tableName) return;
          if (!tableMap.has(tableName)) {
            tableMap.set(tableName, new Set());
          }

          const tableSet = tableMap.get(tableName);
          if (tableSet) {
            const uniqueId = row.tableRowFieldValues?.find(
              value => value.fieldId === SystemFieldType.UniqueId
            )?.stringValue;
            tableSet.add(uniqueId ?? row.id ?? "");
          }
        });
      }
    });

    const formattedChangedResources = Array.from(resourceMap.entries()).map(
      ([resourceName, tableMap]) => ({
        resourceName,
        tables: Array.from(tableMap.entries()).map(([tableName, rowIds]) => ({
          tableName,
          rowIds: Array.from(rowIds)
        }))
      })
    );

    return formattedChangedResources;
  }, [entities, resourceCalendarChangedRows, resources, tables]);

  useEffect(() => {
    if (canEditRecord && (changedEffectiveCalendars.length || changedResourceCalendars.length))
      onOpen();
    else onClose();
  }, [onOpen, changedEffectiveCalendars, canEditRecord, changedResourceCalendars, onClose]);

  const onUpdateChangedValue = async () => {
    if (!recordAuth || !record) return;
    try {
      await updateEffectiveCalendarChangedValue({
        recordAuth,
        recordType: record?.recordType,
        body: [
          {
            operationType: OperationType.AddOrPatch,
            tableRows: [...effectiveCalendarChangedRows, ...resourceCalendarChangedRows].map(
              row => ({
                ...row,
                effectiveCalendarWorkingTimeChanged: false,
                resourceCalendarChanged: false
              })
            )
          }
        ],
        invalidateTags: "RecordTableRows"
      }).unwrap();

      dispatch(
        setRecordTableRowValues({
          rows: recordTableRows.map(row => ({
            ...row,
            effectiveCalendarWorkingTimeChanged: false
          })),
          isRefetching: false
        })
      );
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{t("effectiveCalendars.updated")}</DialogTitle>
      <Stack spacing={2} padding="1em">
        <Alert severity="warning">
          <Stack spacing={1} direction="column" minWidth="450px">
            {changedEffectiveCalendars.length > 0 && (
              <>
                <EdisonTypography
                  title={t("effectiveCalendars.effectiveCalendarsUpdated")}
                  variant="alerttitle"
                />
                <EdisonTypography
                  title={t("effectiveCalendars.calendarUpdatedMessage")}
                  variant="data2"
                />
                <Stack spacing={1} direction="column" width="full" flexWrap="wrap">
                  {changedEffectiveCalendars.map(row => (
                    <BoldTranslation
                      i18nKey="tablesUsingCalendar"
                      values={{
                        calendar: row.calendarName,
                        tables: row.tableNames
                      }}
                      variant="data2"
                    />
                  ))}
                </Stack>
              </>
            )}
            {changedResourceCalendars.length > 0 && changedEffectiveCalendars.length > 0 && (
              <Divider />
            )}
            {changedResourceCalendars.length > 0 && (
              <>
                <EdisonTypography
                  title={t("effectiveCalendars.resourceCalendarsUpdateed")}
                  variant="alerttitle"
                />
                <EdisonTypography
                  title={t("effectiveCalendars.resourceCalendarMessage")}
                  variant="data2"
                />
                {changedResourceCalendars.map(resource => (
                  <Stack key={resource.resourceName} spacing={1} direction="column" width="full">
                    <EdisonTypography title={resource.resourceName} variant="tableheader" />

                    <ul>
                      <li>
                        <EdisonTypography
                          title={t("effectiveCalendars.affectedTables", {
                            count: resource.tables.length
                          })}
                          variant="data2"
                        />

                        <ul>
                          {resource.tables.map(table => (
                            <li key={table.tableName}>
                              <BoldTranslation
                                i18nKey="effectiveCalendars.tablesUsingResource"
                                values={{
                                  table: table.tableName,
                                  rows: table.rowIds.join(", ")
                                }}
                                variant="data2"
                                display="block"
                              />
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </Stack>
                ))}
              </>
            )}
            <EdisonTypography title={t("effectiveCalendars.reviewTasks")} variant="data2" />
          </Stack>
        </Alert>
        <Stack direction="row" spacing={1} justifyContent="end">
          <LoadingButton onClick={onUpdateChangedValue} loading={isLoading} variant="outlined">
            {t("close")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
