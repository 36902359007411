import { Box, Stack, Button } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React from "react";
import UnauthorizedImageLight from "../../assets/UnauthorizedImage.png";
import UnauthorizedImageDark from "../../assets/UnauthorizedImageDarkMode.png";
import E365Logo from "../../assets/edison365logo.png";
import E365LogoDark from "../../assets/edison365logodark.png";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import "./error.scss";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";

type Props = { onLogout?: any };

const UnlicensedUser = ({ onLogout }: Props) => {
  const themeName = useAppSelector(selectThemeName);

  const unlicensedImg = themeName === "dark" ? UnauthorizedImageDark : UnauthorizedImageLight;

  const edison365Logo = themeName === "dark" ? E365LogoDark : E365Logo;

  const { t } = useTranslation(["common"]);
  return (
    <div className="unlicensed-user-container">
      <img className="e365-logo" src={edison365Logo} />
      <Stack
        className="error-page"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, minHeight: "80vh" }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img className="unauthorized-logo" src={unlicensedImg} />

          <Box className="description">
            <EdisonTypography title={t("licensedAccessOnly")} variant="h1" />
          </Box>
          <Box className="description">
            <EdisonTypography title={t("unlicensedAccessDescription")} variant="data" />
          </Box>

          {onLogout && (
            <Box className="homepage-button">
              <Button variant="contained" onClick={onLogout}>
                <LogoutOutlinedIcon />
                {t("logout")}
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default UnlicensedUser;
