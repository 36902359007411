import { EdisonPeoplePickerFieldProps } from "enada-common";
import { FormHelperText, Stack } from "@mui/material";
import { FC } from "react";
import PeoplePickerWith from "../peoplePickerWith/PeoplePickerWith";

export interface PickerWithDescriptionProps extends EdisonPeoplePickerFieldProps {
  description: string;
}
const PickerWithDescription: FC<PickerWithDescriptionProps> = props => {
  return (
    <Stack spacing={1}>
      <PeoplePickerWith {...props} />
      <FormHelperText>{props.description}</FormHelperText>
    </Stack>
  );
};

export default PickerWithDescription;
