import { apiConfig, graphRequest } from "../config/authConfig";
import { execute } from "../utils/requestHandler";
import {
  deleteEndpoint,
  fetchFields,
  fetchSingleElement,
  postHttpRequest,
  recycleModuleEndpoint,
  saveFields
} from "./fieldsService";
import { callMsGraphGet, callMsGraphPost } from "./graphService";
import { BaseRecord, RecordType } from "enada-common";
import { msalInstance } from "./AuthenticationService";
import { ODataApiResponse } from "../types/ApiResponse";
import { RecordAssociationMapping } from "../types/createRecordModal";
import { RecordProgramConfiguration } from "../types/programs";

export const getApiForRecord = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCasesEndpoint;
    case RecordType.Programs:
      return apiConfig.programsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengesEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideasEndpoint;
    default:
      return apiConfig.projectsEndpoint;
  }
};

export const getApiForTables = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectTablesEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseTablesEndpoint;
    case RecordType.Programs:
      return apiConfig.programTablesEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeTablesEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaTablesEndpoint;
    default:
      return apiConfig.projectTablesEndpoint;
  }
};
export const getApiForReviews = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectReviewsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseReviewsEndpoint;
    case RecordType.Programs:
      return apiConfig.programReviewsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeReviewsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaReviewsEndpoint;
    default:
      return apiConfig.projectReviewsEndpoint;
  }
};

export const getApiForVersions = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectVersionsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseVersionsEndpoint;
    case RecordType.Programs:
      return apiConfig.programVersionsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeVersionsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaVersionsEndpoint;
    default:
      return apiConfig.projectVersionsEndpoint;
  }
};

export const getApiForRecordPermissions = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectPermissionsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCasePermissionsEndpoint;
    case RecordType.Programs:
      return apiConfig.programPermissionsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengePermissionsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaPermissionsEndpoint;
    default:
      return apiConfig.projectPermissionsEndpoint;
  }
};

export const getApiForRecordAssociations = (type: RecordType) => {
  switch (type) {
    case RecordType.Projects:
      return apiConfig.projectAssociationsEndpoint;
    case RecordType.BusinessCase:
      return apiConfig.businessCaseAssociationsEnpoint;
    case RecordType.Programs:
      return apiConfig.programAssociationsEndpoint;
    case RecordType.Challenges:
      return apiConfig.challengeAssociationsEndpoint;
    case RecordType.Ideas:
      return apiConfig.ideaAssociationsEndpoint;
    default:
      return apiConfig.projectAssociationsEndpoint;
  }
};

export function getIndividualCard(id: number): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubCardsEndpoint}`,
    null,
    id
  );
}

export function postWorkflowPopulationValues(
  data: unknown,
  populationId: number
): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/values`,
    data
  );
}

export function getWorkflowPopulationValues(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/values`
  );
}

export function postWorkflowPopulationRows(data: unknown, populationId: number): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/rows`,
    data
  );
}

export function getWorkflowPopulationRows(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/rows?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`
  );
}

export function postWorkflowPopulationTableConfigs(
  data: unknown,
  populationId: number
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/configurations`,
    data
  );
}

export function getWorkflowPopulationTableConfigs(populationId: number): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/configurations`
  );
}

export function getWorkflowPopulationTableTotals(
  populationId: number,
  tableId: number
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}/data-population/${populationId}/table/${tableId}/totals`
  );
}

export function getRecords(queryString: string): Promise<ODataApiResponse<BaseRecord[]>> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.recordsODataEndpoint}${decodeURIComponent(queryString)}`
  );
}

export function getAccessToken(id: number, recordType: RecordType, headers: Headers): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForRecordPermissions(recordType)}/${id}/access-token`,
    null,
    undefined,
    null,
    headers
  );
}

export function getRecordViewValues(
  recordId: number,
  recordType: RecordType,
  viewId: number,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForRecord(recordType)}/${recordId}/values/${viewId}`,
    null,
    undefined,
    null,
    headers
  );
}

export function getIndividualRecord(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<any> {
  return execute(
    msalInstance,
    fetchSingleElement,
    getApiForRecord(recordType),
    null,
    recordId,
    null,
    headers
  );
}

export function getRecordVersion(versionId: number, recordType: RecordType): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${getApiForVersions(recordType)}/${versionId}`,
    null,
    undefined,
    null
  );
}

export function postRecordUserLike(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<unknown> {
  return execute(
    msalInstance,
    postHttpRequest,
    `${getApiForRecord(recordType)}/${recordId}/favourite`,
    headers
  );
}

export function deleteRecordUserLike(
  recordId: number,
  recordType: RecordType,
  headers: Headers
): Promise<unknown> {
  return execute(
    msalInstance,
    deleteEndpoint,
    `${getApiForRecord(recordType)}/${recordId}/favourite`,
    headers
  );
}

export function postRecord(data: unknown, recordType: RecordType): Promise<unknown> {
  return execute(msalInstance, saveFields, getApiForRecord(recordType), data);
}

export function postProgramAssociation(data: unknown, recordId: number): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.programAssociationsEndpoint}/${recordId}`,
    data
  );
}

export function recycleRecord(id: number, recordType: RecordType, headers: Headers): Promise<any> {
  return execute(
    msalInstance,
    recycleModuleEndpoint,
    getApiForRecord(recordType),
    null,
    id,
    null,
    headers
  );
}

export function getTemplateBCAssociationMappings(
  recordId: number,
  templateId: number
): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.businessCaseAssociationsEnpoint}/${recordId}/mapping/${templateId}`,
    null,
    undefined,
    null
  );
}

export function getProgramsConfiguration(
  queryString: string
): Promise<ODataApiResponse<RecordProgramConfiguration[]>> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.programConfigurationODataEndpoint}${decodeURIComponent(queryString)}`,
    null,
    undefined,
    null
  );
}

export function postChallengeAssociation(data: unknown, recordId: number): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.challengeAssociationsEndpoint}/${recordId}`,
    data
  );
}

export function postBusinessCaseAssociation(
  recordId: number,
  data: RecordAssociationMapping
): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.businessCaseAssociationsEnpoint}/${recordId}`,
    data,
    undefined,
    null
  );
}

export function postIdeaAssociation(recordId: number, data: BaseRecord): Promise<any> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.ideaAssociationsEndpoint}/${recordId}`,
    data,
    undefined,
    null
  );
}

export function getRequestMsGraph(queryString: string, headers?: Headers): Promise<any> {
  return execute(msalInstance, callMsGraphGet, queryString, null, undefined, graphRequest, headers);
}

export function postRequestMsGraph(queryString: string, body: string): Promise<any> {
  return execute(msalInstance, callMsGraphPost, queryString, body, undefined, graphRequest);
}

export function getMyRolesApi(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.rolesEndpoint}/my-roles`);
}

export function getAIEnabledOnInstance(): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.managementApi}/tenants/instances/ai-enabled`
  );
}
