import { Button, Skeleton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SyncOutlined } from "@mui/icons-material";
import { NotificationLevel } from "enada-common";
import { useAppDispatch } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { useGetUserDetailsSyncStatusQuery, useSyncUserDetailsStatusMutation } from "services/api";

type SyncStates = "notStarted" | "inProgress" | "done" | null;

const PersonalDataSync: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const [syncStatus, setSyncStatus] = useState<SyncStates>("notStarted");
  const { data: status } = useGetUserDetailsSyncStatusQuery(undefined, {
    skip: syncStatus !== "inProgress",
    pollingInterval: 10000
  });
  const [syncUserDetails] = useSyncUserDetailsStatusMutation();

  useEffect(() => {
    if (status?.syncUserDetails === false && syncStatus === "inProgress") {
      setSyncStatus("done");
      dispatch(
        setCurrentNotification({
          title: "userDetails.syncComplete",
          message: "",
          level: NotificationLevel.Success
        })
      );
    }
  }, [dispatch, status?.syncUserDetails, syncStatus]);

  const syncData = async () => {
    await syncUserDetails(); // kick off the sync. We don;t get anything back from this request
    setSyncStatus("inProgress");
  };

  // Before we know the state of the sync, we display a skeleton loader. This is replaced by a button
  // once we know the state of the sync
  return syncStatus === "notStarted" || syncStatus === "done" ? (
    <Button variant="text" size="medium" endIcon={<SyncOutlined />} onClick={() => syncData()}>
      {t("userDetails.syncButton.notStarted")}
    </Button>
  ) : syncStatus !== null ? (
    <Button variant="text" size="medium" disabled>
      {t("userDetails.syncButton.inProgress")}
    </Button>
  ) : (
    <Skeleton>
      <Button variant="text" size="medium" sx={{ visibility: "hidden" }}>
        SYNC IN PROGRESS
      </Button>
    </Skeleton>
  );
};

export default PersonalDataSync;
