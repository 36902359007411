import {
  recordTypeConfiguration,
  NotificationLevel,
  RecordType,
  Form,
  Category,
  ApiError
} from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { RollupTable } from "enada-components";
import { resetLiveForm } from "../../../store/slices/formDesignerSlice";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { useHasModule } from "../../../utils/hooks/useHasModule";
import {
  useDeleteFormsMutation,
  useGetFormsQuery,
  useRecycleFormsMutation,
  useRestoreFormsMutation
} from "services/api";
import { selectRollupRowsFromData } from "utils/rtkQuery";

const selectFormRollupRows = selectRollupRowsFromData<Form[]>(data => {
  return data?.map(form => ({
    id: form.id ?? 0,
    displayName: form.displayName,
    modified: form.modified,
    modifiedBy: form.modifiedBy,
    type: form.dataType,
    isDeleted: form.isDeleted,
    category: form.category,
    no_delete_e365: form.category === Category.Default
  }));
});

const Forms: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const { rollupRows = [] } = useGetFormsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectFormRollupRows(result)
    })
  });
  const [deleteForms] = useDeleteFormsMutation();
  const [restoreForms] = useRestoreFormsMutation();
  const [recycleForms] = useRecycleFormsMutation();

  const navigate = useNavigate();
  const { isModuleEnabled } = useHasModule();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteForms(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "formDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e: any) {
      const error = e?.data as ApiError;

      if (error.detail) {
        const errorArr = JSON.parse(error.detail);
        const errorMessage = errorArr.reduce((acc: string, curr: any) => {
          const workflowNames = curr.Workflows.map((workflow: any) => workflow.WorkflowName).join(
            ", "
          );
          const formatted = `${t("formInUseError", {
            forms: curr.FormName,
            workflows: workflowNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");
        dispatch(
          setCurrentNotification({
            title: error.title ?? "formDeletedError",
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      }
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleForms(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "formRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "formRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreForms(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "formRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "formRestoredError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate("editform", {
          state: {
            id,
            title: "forms"
          }
        });
      }}
      addAction={() => {
        dispatch(resetLiveForm());
        navigate("newform", { state: { title: "forms" } });
      }}
      columns={rollupTableColumnsWithType(
        t,
        (value: any) => recordTypeConfiguration(value.type as RecordType).color
      )}
      rows={rollupRows.filter(form => !form.isDeleted && isModuleEnabled(form.type as RecordType))}
      deletedRows={rollupRows.filter(form => form.isDeleted)}
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
      adminType="Form"
    />
  );
};

export default Forms;
