import { BaseUserFieldProps, fieldConfiguration, FieldDataType } from "enada-common";
import { TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 } from "uuid";

export interface UserDatePickerFieldProps extends BaseUserFieldProps {
  invalidDateMessage?: string;
  isIconOn: boolean;
  textFieldVariant?: "filled" | "outlined" | "standard";
  minDate?: any;
  maxDate?: any;
}

const UserDatePicker: FC<UserDatePickerFieldProps> = ({
  label,
  disabled,
  readOnly,
  value,
  onChange,
  isInTable,
  isIconOn,
  setReadOnly,
  textFieldVariant,
  autoFocus,
  minDate,
  maxDate,
  required,
  dataTestidValue,
  outlineTextField = false
}) => {
  const onBlurId = v4();
  const [internalState, setInternalState] = React.useState<Date | null>(value || null);

  const [readyToClose, setReadyToClose] = useState(false);
  useEffect(() => {
    if (!readyToClose) return;

    setReadOnly && setReadOnly(true);
    setReadyToClose(false);
    if (!isInTable) return;
    if (!onChange) return;
    onChange(internalState ? new Date(internalState) : internalState);
  }, [readyToClose]);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  return outlineTextField || !readOnly ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        data-testid={dataTestidValue}
        minDate={minDate}
        maxDate={maxDate}
        label={!isInTable && label}
        onChange={changedValue => {
          setInternalState(changedValue);
          if (isInTable) return;
          if (!onChange) return;
          onChange(changedValue ? new Date(changedValue) : changedValue);
        }}
        value={internalState}
        views={["year", "month", "day"]}
        disabled={disabled}
        inputFormat="DD/MM/YYYY"
        onClose={() => {
          setReadyToClose(true);
        }}
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              data-testid="user-datepicker-field-input"
              required={required}
              id={onBlurId}
              onBlur={e => {
                if (e.relatedTarget?.parentElement?.previousElementSibling?.id === onBlurId) return;
                setReadOnly && setReadOnly(true);
                if (!isInTable) return;
                if (!onChange) return;
                onChange(internalState ? new Date(internalState) : internalState);
              }}
              variant={textFieldVariant ?? outlineTextField ? "outlined" : "standard"}
              autoFocus={autoFocus}
              onFocus={
                isInTable
                  ? event => {
                      event.target.select();
                    }
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
                sx: { borderRadius: outlineTextField ? "8px" : "0px" }
              }}
              disabled={readOnly && (disabled || outlineTextField)}
            />
          );
        }}
      />
    </LocalizationProvider>
  ) : (
    <EdisonFieldIcon
      title={label}
      subtitle={internalState ? dayjs(internalState).format("ll") : ""}
      icon={fieldConfiguration(FieldDataType.Date).icon}
      color={fieldConfiguration(FieldDataType.Date).color}
      isIconOn={isIconOn}
      isTableModeOn={!!isInTable}
      required={required}
      error={required && !value}
    />
  );
};

export default UserDatePicker;
export { UserDatePicker };
