import { ExpressionEntity, ExpressionOperator } from "./../backend/Workflow.model";
export type ExpressionUnitType = "entity" | "operator" | "number" | "text";

export interface CalculatedExpressionUnit {
  id?: string;
  type: ExpressionUnitType;
  value: ExpressionEntity | ExpressionOperator | number | string;
}

export interface ConditionBlock {
  if: CalculatedExpressionUnit[];
  else?: CalculatedExpressionUnit[];
  then?: CalculatedExpressionUnit[];
  conditionalThen?: ConditionBlock;
  conditionalElse?: ConditionBlock;
}

export interface FrontendToBackendOperatorMap {
  id?: string;
  backend: ExpressionOperator | "(" | ")";
  frontend: string;
}
export const operatorMapList: FrontendToBackendOperatorMap[] = [
  {
    id: "1",
    backend: ExpressionOperator.Or,
    frontend: "||"
  },
  {
    id: "2",
    backend: ExpressionOperator.And,
    frontend: "&&"
  },
  {
    id: "3",
    backend: ExpressionOperator.Sum,
    frontend: "+"
  },
  {
    id: "4",
    backend: ExpressionOperator.GreaterThan,
    frontend: ">"
  },
  {
    id: "5",
    backend: ExpressionOperator.LessThan,
    frontend: "<"
  },
  {
    id: "6",
    backend: ExpressionOperator.Subtract,
    frontend: "-"
  },
  {
    id: "7",
    backend: ExpressionOperator.Multiply,
    frontend: "*"
  },
  {
    id: "8",
    backend: ExpressionOperator.Divide,
    frontend: "/"
  },
  {
    id: "9",
    backend: ExpressionOperator.Equals,
    frontend: "=="
  },
  {
    id: "10",
    backend: ExpressionOperator.NotEquals,
    frontend: "!="
  }
  //TODO: re-add non number operators once we have found a safe package to evaluate user input.
  // {
  //   backend: ExpressionOperator.StartsWith,
  //   frontend: "StartsWith",
  // },
  // {
  //   backend: ExpressionOperator.EndsWith,
  //   frontend: "EndsWith",
  // },
  // {
  //   backend: ExpressionOperator.Contains,
  //   frontend: "Contains",
  // },
];

export const ParentheseOperators: FrontendToBackendOperatorMap[] = [
  {
    id: "1",
    backend: "(",
    frontend: "("
  },
  {
    id: "2",
    backend: ")",
    frontend: ")"
  }
];

export const fullOperatorList = [...operatorMapList, ...ParentheseOperators];
