import { ApiError, License, NotificationLevel } from "enada-common";
import { Alert, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/hooks";
import { useDeleteLicenseMutation } from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

export interface DeleteLicenseModalProps {
  licenseToDelete?: License;
  onClose: () => void;
  tenantId: string;
}
const DeleteLicenseModal: FC<DeleteLicenseModalProps> = ({
  licenseToDelete,
  onClose,
  tenantId
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [deleteLicense] = useDeleteLicenseMutation();

  const onDeleteLicense = async () => {
    try {
      await deleteLicense({
        licenseId: licenseToDelete?.id as string,
        tenantId
      });

      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Success,
          title: "licenseDeleted",
          message: ""
        })
      );
      onClose();
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Error,
          message: error.data.detail ?? "licenseDeletedError",
          title: "Error deleting License."
        })
      );
    }
  };

  return (
    <Dialog open={Boolean(licenseToDelete)}>
      <DialogTitle> {t("delete")}</DialogTitle>
      <Stack spacing={2} padding={"1em"}>
        <Alert severity="warning"> {t("licenseDeleteWarning")}</Alert>
        <Stack direction="row" spacing={1} justifyContent={"end"}>
          <Button variant="contained" color="error" onClick={onDeleteLicense}>
            {t("delete")}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteLicenseModal;
