import { apiConfig } from "config/authConfig";
import { Image, News } from "enada-common";
import { baseAPI } from "services/baseAPI";

export const newsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getLiveNews: builder.query<News[], void>({
      query: () => `${apiConfig.contentHubNewsEndpoint}/live`,
      transformResponse: (response: { data: News[] }) => response.data,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: "News" as const, id })), "News"] : ["News"]
    }),
    getNews: builder.query<News[], void>({
      query: () => `${apiConfig.contentHubNewsEndpoint}`,
      transformResponse: (response: { data }) => response.data,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: "News" as const, id })), "News"] : ["News"]
    }),
    getDetailsNews: builder.query<News, number>({
      query: id => `${apiConfig.contentHubNewsEndpoint}/${id}/details`,
      transformResponse: (response: { data }) => response.data,
      providesTags: (result, error, id: any) => [{ type: "News", id }]
    }),
    deleteNews: builder.mutation<void, number[]>({
      query: ids => ({
        url: apiConfig.contentHubNewsEndpoint,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "News", id }))
    }),
    recycleNews: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubNewsEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "News", id }))
    }),
    restoreNews: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubNewsEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "News", id }))
    }),
    updateNews: builder.mutation<News, Partial<News>>({
      query: body => ({
        url: apiConfig.contentHubNewsEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: News }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "News", id: body.id }]
    }),
    createNews: builder.mutation<News, Partial<News>>({
      query: body => ({
        url: apiConfig.contentHubNewsEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: News }) => response.data,
      invalidatesTags: ["News"]
    }),
    createNewsImage: builder.mutation<any, { image: Image; newsId: string }>({
      query: ({ image, newsId }) => {
        const formData = new FormData();
        formData.append("file", image as File);

        return {
          url: `${apiConfig.contentHubNewsEndpoint}/${newsId}/image`,
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: (result, error, { newsId }) => [{ type: "News", id: newsId }]
    }),
    updateOrderNews: builder.mutation<any, object>({
      query: body => ({
        url: `${apiConfig.contentHubNewsEndpoint}/order`,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: News }) => response.data,
      invalidatesTags: ["News"]
    })
  })
});
