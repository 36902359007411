import { FC, useEffect } from "react";
import {
  ColumnTable,
  RecordRole,
  RowOperationType,
  Table,
  WorkflowStageViewFieldState
} from "enada-common";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectAllCalendars } from "../../../../store/slices/calendarsSlice";
import {
  selectCurrentVersion,
  selectEditor,
  selectRecord,
  selectRecordPermissions,
  selectRecordReadOnly,
  selectRecordVersionPeriodicGrandTotals,
  selectRecordVersionTableConfigurations,
  selectWorkflowStage
} from "../../../../store/slices/recordSlice";
import {
  selectRecordTableBackendRowValues,
  selectRecordTablePeriodicGrandTotals,
  selectRecordTableStatus,
  selectRecordTables,
  selectTableConfigs,
  setTableOperations,
  selectRecordRowsToUpdate,
  removeRowToUpdate,
  selectRefreshTables,
  setRefreshTables
} from "../../../../store/slices/recordTableSlice";
import { Loading } from "enada-components";
import TableMapper from "../../../../utils/mappers/TableMapper";
import { selectAiEnabled } from "../../../../store/slices/tenantSlice";
import {
  useGetFieldsQuery,
  useGetMyResourcesQuery,
  useGetMyRolesQuery,
  useGetUserQuery
} from "services/api";

export interface ProjectTableProps {
  columnTable: ColumnTable;
}
const ProjectTable: FC<ProjectTableProps> = ({ columnTable }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery();
  const { data: userRoles = [] } = useGetMyRolesQuery();
  const { data: resources, isLoading: myResourcesIsLoading } = useGetMyResourcesQuery("");
  const { data: fields = [] } = useGetFieldsQuery();

  const projectEditor = useAppSelector(selectEditor);
  const keepReadOnly = useAppSelector(selectRecordReadOnly);
  const fullProject = useAppSelector(selectRecord);
  const rowsToUpdate = useAppSelector(selectRecordRowsToUpdate);
  const projectTableRows = useAppSelector(selectRecordTableBackendRowValues);
  const periodicGrandTotals = useAppSelector(selectRecordTablePeriodicGrandTotals);
  const projectTableRowsStatus = useAppSelector(selectRecordTableStatus);
  const tables = useAppSelector(selectRecordTables);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const aiEnabled = useAppSelector(selectAiEnabled);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const recordVersionTableConfig = useAppSelector(selectRecordVersionTableConfigurations);
  const recordVersionPeriodicGrandTotals = useAppSelector(selectRecordVersionPeriodicGrandTotals);
  const refreshTables = useAppSelector(selectRefreshTables);

  const calendars = useAppSelector(selectAllCalendars);

  const tableConfigs = useAppSelector(selectTableConfigs);
  const recordPermissions = useAppSelector(selectRecordPermissions);

  const stageView =
    workflowStage?.views && workflowStage?.views?.length !== 0
      ? (workflowStage?.views)[0] // TODO : review this when we can add multiple views to the a workflow
      : null;

  const isEditor = user?.id === projectEditor?.oid;

  let fullTable: Table | undefined = undefined;
  if (tables) {
    fullTable = tables.find(table => table.id === columnTable.tableId);
  }

  const isReadOnly = () => {
    if (keepReadOnly) return true;
    if (isEditor) {
      const isAdmin = userRoles.some(role =>
        ["Tenant Admin", "Instance Admin"].includes(role?.value ?? "")
      );
      const isProcessManager = recordPermissions.some(
        permission => permission.entityId === user?.id && permission.role === RecordRole.Manager
      );
      if (workflowStage?.allowReadOnlyOverride && (isProcessManager || isAdmin)) return false;

      return Boolean(
        stageView?.viewTables &&
          stageView.viewTables.some(
            viewTable =>
              viewTable.tableId === columnTable.tableId &&
              viewTable.state === WorkflowStageViewFieldState.ReadOnly
          )
      );
    }
    return true;
  };

  useEffect(() => {
    if (refreshTables) {
      dispatch(setRefreshTables(false));
    }
  }, [refreshTables, dispatch]);

  if (!fullTable) {
    return <></>;
  }

  return (
    <div>
      {fullTable && tableConfigs && !myResourcesIsLoading && projectTableRowsStatus === "idle" ? (
        <TableMapper
          table={fullTable}
          fields={fields}
          t={t}
          project={fullProject ?? undefined}
          rowsToUpdate={rowsToUpdate.filter(row => row.tableId === columnTable.tableId)}
          removeUpdatedRowAction={(uniqueId: string) => dispatch(removeRowToUpdate(uniqueId))}
          rowsInTable={projectTableRows.filter(row => row.tableId === columnTable.tableId)}
          periodicGrandTotals={
            !currentVersion || currentVersion.id === 0
              ? periodicGrandTotals.find(totals => totals.tableId === fullTable?.id)?.totals
              : recordVersionPeriodicGrandTotals?.find(totals => totals.tableId === fullTable?.id)
                  ?.totals
          }
          readOnly={isReadOnly()}
          resources={resources?.value ?? []}
          calendars={calendars}
          tableConfig={
            !currentVersion || currentVersion.id === 0
              ? tableConfigs.find(t => t.tableId === fullTable?.id)
              : recordVersionTableConfig?.find(t => t.tableId === fullTable?.id)
          }
          onChange={(tableOperations: RowOperationType[]) => {
            dispatch(setTableOperations({ tableOperations }));
          }}
          deleteAction={(value: any) => {
            console.log("delete", value);
          }}
          updateAction={(value: any) => {
            // dispatch(updateProjectRowValues(value));
          }}
          required={stageView?.viewTables?.some(
            viewTable =>
              viewTable.state === WorkflowStageViewFieldState.Required &&
              viewTable.tableId === columnTable.tableId
          )}
          hideEdi={(!aiEnabled && (!currentVersion || currentVersion.id === 0)) || isReadOnly()}
          refreshTables={refreshTables}
        />
      ) : (
        <Loading size={70} />
      )}
    </div>
  );
};
export default ProjectTable;
