import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import React from "react";
import { WorkflowStageViewFieldState } from "enada-common";
export const VisibilityIcon = (state: string | undefined) => {
  switch (state) {
    case WorkflowStageViewFieldState.Hidden:
      return <VisibilityOffOutlinedIcon fontSize="small" />;
    case WorkflowStageViewFieldState.ReadOnly:
      return <LockOutlinedIcon fontSize="small" />;
    case WorkflowStageViewFieldState.Required:
      return <StarBorderOutlinedIcon fontSize="small" />;
    case "Visible":
    case undefined:
      return <VisibilityOutlinedIcon fontSize="small" />;
  }
};
