import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from "react";
import { ProjectModel } from "@bryntum/gantt-thin";
import { BryntumGantt, BryntumGanttProps } from "@bryntum/gantt-react-thin";
import { BaseRecord, PersonaEntity, RecordType, toCamelCase } from "enada-common";
import { Box, Button, Chip, Rating, Stack } from "@mui/material";
import { differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import "./discovertable.scss";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import "@bryntum/gantt-thin/gantt.material.css";
import "@bryntum/scheduler-thin/scheduler.material.css";
import "@bryntum/schedulerpro-thin/schedulerpro.material.css";
import { useTranslation } from "react-i18next";
import { EdisonPeopleGroup, EdisonTypography, Loading } from "enada-components";
import WorkflowStageChip from "../workflowStageChip/WorkflowStageChip";
import ModuleLabel from "../moduleLabel/ModuleLabel";
import { useGetWorkflowStagesQuery } from "services/api";

export interface ProjectDiscoverTableProps {
  projects: BaseRecord[];
  getMore: () => void;
  hasMore: boolean;
  loading: boolean;
  ganttRef: MutableRefObject<any>;
  expandable: boolean;
  recordId?: string;
}

function mapRecord(record: BaseRecord): any {
  return {
    startDate: record.startDate ? new Date(record.startDate) : undefined,
    endDate: record.endDate ? new Date(record.endDate) : undefined,
    percentDone: record.percentDone,
    name: record.displayName,
    id: record.id,
    rating: record.averageRating,
    owners: record.owners,
    stage: record.workflowStage,
    daysInStage: {
      workflowStage: record.workflowStage,
      workflowStageChanged: record.workflowStageChanged
    },
    stageType: record.workflowStage,
    approvals: {
      workflowStage: record.workflowStage,
      approvedCount: record.approvedCount,
      rejectedCount: record.rejectedCount,
      requiredApproversCount: record.requiredApproversCount
    },
    manuallyScheduled: true,
    duration: getProjectDuration(record.startDate, record.endDate ?? undefined),
    description: record.shortDescription,
    recordType: record.recordType,
    children: record.recordAssociations?.map((associatedRecord: BaseRecord) =>
      mapRecord(associatedRecord)
    )
  };
}

const DiscoverTable: FC<ProjectDiscoverTableProps> = ({
  projects,
  getMore,
  hasMore,
  loading,
  ganttRef,
  expandable,
  recordId
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { data: stages = [] } = useGetWorkflowStagesQuery();

  const getStage = useCallback(
    (stageID: number | undefined) => {
      return stages.find(stage => stage.id === stageID);
    },
    [stages]
  );

  const columnsRef = useRef([
    {
      field: "name",
      text: t("name"),
      id: 1,
      type: "name",
      cellCls: "name-column"
    },
    { field: "id", text: "Id", id: 2 },
    {
      field: "recordType",
      text: "Type",
      id: 3,
      autoWidth: true,
      align: "center",
      renderer: (args: { value: Pick<BaseRecord, "recordType"> }) => {
        const recordType = String(args.value);
        return <ModuleLabel recordType={recordType as RecordType} />;
      },
      hidden: !expandable
    },
    {
      field: "percentDone",
      text: t("percentDone"),
      id: 4,
      type: "percentdone",
      mode: "circle",
      width: 150
    },
    {
      field: "owners",
      text: t("owners"),
      id: 5,
      renderer: (args: { value: Pick<BaseRecord, "owners"> }) => {
        return (
          <Box display="flex" width="100%" justifyContent="center">
            <EdisonPeopleGroup
              value={args.value as PersonaEntity[]}
              maxAvatars={2}
              avatarSize="large"
            />
          </Box>
        );
      },
      showAvatars: true
    },

    { field: "endDate", text: t("endDate"), id: 6, type: "enddate" },
    { field: "startDate", text: t("startDate"), id: 7, type: "startdate" },
    {
      field: "stage",
      text: t("stage"),
      id: 8,
      width: 150,
      renderer: (args: { value: number }) => {
        const foundStage = getStage(args.value);
        return (
          <Box display="flex" width="100%" justifyContent="center">
            <WorkflowStageChip
              displayName={foundStage?.displayName}
              color={foundStage?.configuration?.color}
            />
          </Box>
        );
      }
    },
    {
      field: "daysInStage",
      text: t("daysInStage"),
      id: 9,
      width: 150,
      renderer: (args: { value: Pick<BaseRecord, "workflowStage" | "workflowStageChanged"> }) => {
        const todaysDate = new Date();
        const daysDifference = args.value.workflowStageChanged
          ? differenceInDays(
              todaysDate,
              new Date(
                args.value.workflowStageChanged ? args.value.workflowStageChanged : Date.now()
              )
            )
          : 0;

        const foundStage = getStage(args.value.workflowStage ?? 0);
        const exceedsTargetDuration =
          foundStage?.targetDuration && foundStage.targetDuration < daysDifference;

        return (
          <Box display="flex" width="100%" justifyContent="center">
            <Chip
              label={daysDifference ?? "N/A"}
              size="small"
              color={exceedsTargetDuration ? "error" : "default"}
            />
          </Box>
        );
      }
    },
    {
      field: "stageType",
      text: t("stageType"),
      id: 10,
      width: 150,
      renderer: (args: { value: number }) => {
        const foundStage = getStage(args.value);

        const label =
          foundStage?.configuration.type === "StageReview" ? t("StageReview") : t("Stage");

        return <p>{label}</p>;
      }
    },
    {
      field: "approvals",
      text: t("approvals"),
      id: 11,
      width: 150,
      renderer: (args: {
        value: Pick<
          BaseRecord,
          "workflowStage" | "approvedCount" | "rejectedCount" | "requiredApproversCount"
        >;
      }) => {
        const { workflowStage, approvedCount, rejectedCount, requiredApproversCount } = args.value;
        const foundStage = getStage(workflowStage);

        if (foundStage?.configuration.type === "Stage") return <p>N/A</p>;

        return (
          <Stack spacing={0}>
            <EdisonTypography
              title={t("approvedCount", { count: approvedCount })}
              variant="data"
              sx={{ fontSize: 10 }}
            />
            <EdisonTypography
              title={t("rejectedCount", { count: rejectedCount })}
              variant="data"
              sx={{ fontSize: 10 }}
            />
            <EdisonTypography
              title={t("requireToProgressCount", {
                count: requiredApproversCount
              })}
              variant="data"
              sx={{ fontSize: 10 }}
            />
          </Stack>
        );
      }
    },
    { field: "description", text: t("shortDescription"), type: "note", id: 12 },
    {
      field: "rating",
      text: t("starRating"),
      id: 13,
      renderer: (args: { value: number }) => {
        return <Rating value={args.value} precision={0.5} readOnly />;
      }
    }
  ]);

  const projectRef = useRef<ProjectModel>(
    new ProjectModel({
      assignmentStore: {
        data: []
      },
      taskStore: {
        fields: columnsRef.current.map(column => column.field),
        data: projects.map(project => mapRecord(project))
      }
    })
  );

  useEffect(() => {
    if (projectRef.current === undefined) return;
    const data = projects.map(project => mapRecord(project));
    projectRef.current.taskStore.data = data;
  }, [projects]);

  return (
    <Stack className="projects-discover-table-root">
      <div>
        <BryntumGantt
          {...ganttConfig}
          columns={columnsRef.current}
          ref={ganttRef}
          project={projectRef.current}
          readOnly={true}
          onCellClick={(args: any) => {
            const { column, record } = args;
            if (column.type === "name") {
              if (args.target.classList.contains("b-tree-cell-value")) {
                let initialPath = "../";
                if (recordId) initialPath += "../";
                navigate(`${initialPath}${record.recordType.toLocaleLowerCase()}/${record.id}`, {
                  state: { title: toCamelCase(record.recordType) }
                });
              }
            }
          }}
        />
      </div>
      {loading ? (
        <Loading size={70} sx={{ margin: "auto", marginTop: "10px" }} />
      ) : (
        <Button
          disabled={!hasMore}
          onClick={() => {
            if (hasMore) {
              getMore();
            }
          }}
        >
          {t("more")}
        </Button>
      )}
    </Stack>
  );
};

const ganttConfig: BryntumGanttProps = {
  barMargin: 10,
  autoHeight: true,
  columnLines: true,
  sortFeature: false,
  taskMenuFeature: false,
  projectLinesFeature: false
};

const getProjectDuration = (start?: string | null, end?: string | null) => {
  if (!start) return 0;
  if (!end) return 0;
  const timeDiff = new Date(end).getTime() - new Date(start).getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);
  return dayDiff;
};
export default DiscoverTable;
