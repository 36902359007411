import { Model } from "@bryntum/core-thin";
import { CellCopyPasteConfig, Column, RowCopyPasteConfig } from "@bryntum/grid-thin";
import isValid, {
  fieldsWithOnBlurIssue,
  fieldsWithValidator,
  FieldsWithValidator
} from "./validation/Validator";
import { FieldDataType } from "enada-common";

const CopyPasteSharedConfig = {
  cellCopyPasteFeature: {
    toCopyString(data: { currentValue: string; column: Column; record: Model }) {
      const fieldDataType: FieldDataType = (data.column as any)?.data?.fieldDataType;
      if (fieldsWithValidator.includes(fieldDataType)) {
        return JSON.stringify(data.record.getData(data.column.field));
      }
      return data.currentValue;
    },
    toPasteValue(data: { currentValue: string; column: Column; record: Model }) {
      const { currentValue, column } = data;

      // TODO: remove this when the name field database column type has been updated to nvarchar(max)
      if (column.type === "name") {
        column.grid.selectedCells.forEach(cell => {
          if (cell.column?.type === "name") {
            column.grid.startEditing(cell).then(() => {
              const editor = cell?.column?.editor;
              editor.trigger("input", {
                source: editor,
                value: currentValue
              });
            });
          }
        });

        return currentValue.substring(0, 254);
      }

      // Do not paste into read only columns
      if ((column as any)?.data?.fieldReadOnly) {
        return;
      }

      const fieldDataType: FieldDataType = (column as any)?.data?.fieldDataType;
      if (fieldsWithOnBlurIssue.includes(fieldDataType)) {
        return; // Do not paste into these types of fields. The on blur means the components is lost before the paste can be done.
      }

      if (fieldsWithValidator.includes(fieldDataType)) {
        if (isValid(fieldDataType as FieldsWithValidator, currentValue)) {
          return JSON.parse(currentValue);
        }
        return;
      }

      // TODO: remove this when the name field database column type has been updated to nvarchar(max)
      if (
        fieldDataType === FieldDataType.TextBox &&
        currentValue.length > 254 &&
        (column as any)?.data?.text === "Name"
      ) {
        return currentValue.substring(0, 254);
      }

      return currentValue;
    },
    disabled: false
  } as CellCopyPasteConfig,
  rowCopyPasteFeature: {
    rowOptionsOnCellContextMenu: true // Needs to have cellCopyPasteFeature: true and selectionMode: { cell: true } to work
  } as RowCopyPasteConfig
};

export default CopyPasteSharedConfig;
