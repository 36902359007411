import { apiConfig } from "config/authConfig";
import { baseAPI } from "services/baseAPI";
import { ODataApiResponse } from "types/ApiResponse";
import { PersonalData } from "types/personalData";

export const personalDataAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getUserDetails: builder.query<ODataApiResponse<PersonalData[]>, void>({
      query: () => `${apiConfig.contentHubUserDetailsODataEndpoint}`,
      providesTags: result => {
        return result && result?.value
          ? [
              ...result.value.map(item => ({ type: "PersonalData" as const, id: item.id })),
              "PersonalData"
            ]
          : ["PersonalData"];
      }
    }),

    recycleUserDetails: builder.mutation<ODataApiResponse<PersonalData[]>, string>({
      query: id => ({
        url: `${apiConfig.contentHubUserDetailsEndpoint}/${id}/recycle`,
        method: "DELETE"
      }),
      invalidatesTags: ["PersonalData"]
    }),

    restoreUserDetails: builder.mutation<ODataApiResponse<PersonalData[]>, string>({
      query: queryString => ({
        url: `${apiConfig.contentHubUserDetailsEndpoint}/restore?${decodeURIComponent(
          queryString
        )}`,
        method: "POST"
      }),
      invalidatesTags: ["PersonalData"]
    }),

    getUserDetailsSyncStatus: builder.query<{ syncUserDetails: boolean }, void>({
      query: () => `${apiConfig.managementApi}/tenants/instances/processes`,
      transformResponse: (response: { data: { syncUserDetails: boolean } }) => response.data,
      providesTags: ["PersonalData"]
    }),

    syncUserDetailsStatus: builder.mutation<string, void>({
      query: () => ({
        url: `${apiConfig.contentHubUserDetailsEndpoint}/all`,
        method: "POST"
      }),
      invalidatesTags: ["PersonalData"]
    })
  })
});
