import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UnpluggedImgLight from "../../assets/unpluggedImgLight.png";
import UnpluggedImgDark from "../../assets/unpluggedImgDark.png";
import E365Logo from "../../assets/edison365logo.png";
import E365LogoDark from "../../assets/edison365logodark.png";
import { EdisonTypography } from "enada-components";
import { selectThemeName } from "../../store/slices/userSlice";
import "./error.scss";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const TenantHome = () => {
  const themeName = useAppSelector(selectThemeName);

  const noTenantImg = themeName === "dark" ? UnpluggedImgDark : UnpluggedImgLight;

  const edison365Logo = themeName === "dark" ? E365LogoDark : E365Logo;

  const { t } = useTranslation(["common"]);

  const url = window.location.origin;

  return (
    <>
      <img className="e365-logo" src={edison365Logo} />
      <Stack
        className="error-page"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "90vh" }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img className="no-tenant-logo" src={noTenantImg} />
          <Box className="description">
            <EdisonTypography title={t("noCompleteUrl")} variant="h1" />
          </Box>
          <Box className="description">
            <EdisonTypography
              title={`${t("noCompleteUrlDescription1")}\u00A0${url}/${t(
                "noCompleteUrlDescription2"
              )}`}
              variant="data"
            />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
export default TenantHome;
