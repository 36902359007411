import { GridColumnConfig } from "@bryntum/grid-thin";
import { Field, TableDataType } from "enada-common";
import { nameColumnConfig } from "../../columns/SharedColumns";
import { generateBaseBryntumColumn } from "../../tableutils/generateBaseBryntumColumn";
import getBryntumColumn from "./ScheduleTableColumnMapper";

const ScheduleTableColumns = (columns: Field[]): any => {
  const cols: any[] = [];
  columns.forEach(column => {
    if (column.name === "e365-Baselines") return; // currently Bryntum don't support baselines but have plans to in a later release
    if (column.name === "e365-Name") return; // ignore the name column as it is handled separately

    if (column.name.endsWith("-e365")) {
      cols.push({
        ...generateBaseBryntumColumn(column, [], undefined, TableDataType.Schedule),
        cls: "gridHeader",
        htmlEncodeHeaderText: false
      });
    } else {
      let baseColumn: any = {
        text: column.displayName,
        id: column.id
      };

      const bryntumColumn = getBryntumColumn(column.name);
      if (bryntumColumn) {
        baseColumn = {
          ...baseColumn,
          ...bryntumColumn.definition
        };
        // ootb Bryntum fields wth "special" column types should not have "field" set in column def.
        // This breaks base functionality of column type
        if (bryntumColumn.setField) {
          baseColumn.field = bryntumColumn.bryntumName ?? column.name;
        }
      }

      cols.push(baseColumn);
    }
  });
  return [...cols, nameColumnConfig(false, false)] as GridColumnConfig[];
};

export default ScheduleTableColumns;
