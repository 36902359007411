import { FC, useState, useCallback, useEffect, useMemo } from "react";
import { NotificationLevel, RecordAccessRole, RecordType } from "enada-common";
import { Paper, Stack } from "@mui/material";
import { useBlocker, useParams } from "react-router-dom";
import ProjectTabs from "../../../components/projects/projecttabs/ProjectTabs";
import EditCardImage from "../../../components/recordsettings/EditCardImage";
import ManageAccess from "../../../components/recordsettings/ManageAccess";
import Properties from "../../../components/recordsettings/Properties";
import HistoryEvents from "../../../components/recordsettings/HistoryEvents";
import StageApprovalTabs from "../../../components/stageapproval/stageapprovaltabs/StageApprovalTabs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordSettings,
  selectCurrentVersion,
  selectUserAccessRoles,
  selectRecordAuth,
  RecordAuth,
  selectFlyoutId,
  selectRecordFullyInitialised,
  setRecordReadOnly,
  selectHasChanges,
  setRecordVersion,
  setAuthentication,
  selectEditor,
  selectKeepConnectionAlive,
  setKeepConnectionAlive
} from "../../../store/slices/recordSlice";
import { Loading } from "enada-components";
import { setRecordVersionResources } from "../../../store/slices/resourcesSlice";
import { setRecordVersionCalendars } from "../../../store/slices/calendarsSlice";
import { hasAccessRole } from "../../../utils/hasAccessRole";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import EdiRowGeneration from "../../../components/edirowgeneration/EdiRowGeneration";
import { RecordErrorSnackbar } from "../../../components/projects/recorderrorsnackbar/RecordErrorSnackbar";
import ProjectTableRowReview from "../../../components/projects/projecttablerowreview/ProjectTableRowReview";
import { generateApprovedRowsNotification } from "../../../utils/generateApprovedRowsNotification";
import { t } from "i18next";
import AssociatedRecords from "../../../components/projects/associatedrecords/AssociatedRecords";
import {
  selectRecordTables,
  selectRecordRowsToUpdate,
  setRecordVersionViewTableRows,
  selectEdiTableId,
  setRecordTables
} from "../../../store/slices/recordTableSlice";
import { default as ProgramAssociatedRecords } from "../../../components/programs/associatedrecords/AssociatedRecords";
import RecordVersions from "../../../components/recordVersions/RecordVersions";
import { getRecordVersion } from "../../../services/APIService";
import { getAIEnabledAsync } from "../../../store/slices/tenantSlice";
import { useSetRecordData } from "utils/hooks/useSetRecordData";
import { useRecordSignalRConnection } from "./useRecordSignalRConnection";
import { useGetUserQuery, useLazyGetRecordAccessTokenQuery } from "services/api";
import useRecordPageSessionChecker from "../../../utils/hooks/useRecordPageSessionChecker";
import { EffectiveCalendarChangedDialog } from "../../../components/effectiveCalendar/EffectiveCalendarChangedDialog";

export interface RecordPageProps {
  scope: RecordType;
}

const RecordPage: FC<RecordPageProps> = ({ scope }) => {
  const dispatch = useAppDispatch();
  const accessRoles = useAppSelector(selectUserAccessRoles);
  const flyoutId = useAppSelector(selectFlyoutId);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordDataInitialised = useAppSelector(selectRecordFullyInitialised);
  const ediTableId = useAppSelector(selectEdiTableId);
  const recordEditor = useAppSelector(selectEditor);
  const tables = useAppSelector(selectRecordTables);
  const rowsToUpdate = useAppSelector(selectRecordRowsToUpdate);
  const hasChanges = useAppSelector(selectHasChanges);
  const keepConnectionAlive = useAppSelector(selectKeepConnectionAlive);

  const [getAccessToken] = useLazyGetRecordAccessTokenQuery();
  const { data: user } = useGetUserQuery();

  const isEditor = user?.id === recordEditor?.oid;
  const sessionTimeout = process.env.NX_PUBLIC_SESSION_DURATION
    ? parseInt(process.env.NX_PUBLIC_SESSION_DURATION as string)
    : 5;
  const { isExpired, sessionNeedsRenewing } = useRecordPageSessionChecker(
    sessionTimeout,
    recordAuth?.expiresOnISO,
    isEditor
  );

  const [recordErrorLabel, setRecordErrorLabel] = useState<string | null>(null);

  const params = useParams();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const handleConnectionError = useCallback(
    (label: string) => {
      dispatch(setRecordReadOnly(true));
      setRecordErrorLabel(label);
    },
    [dispatch]
  );

  const { connection, closeConnection } = useRecordSignalRConnection(scope, handleConnectionError);

  const skipNetworkRequests = useMemo(() => {
    if (!recordAuth?.details.AccessRoles) return true;
    return !connection || !hasAccessRole(RecordAccessRole.Read, recordAuth?.details.AccessRoles);
  }, [connection, recordAuth?.details.AccessRoles]);

  const { isLoading, refetchData } = useSetRecordData(
    recordAuth,
    scope,
    skipNetworkRequests,
    parseInt(params.recordId as string)
  );

  useEffect(() => {
    const keepAlive = async () => {
      try {
        const coAuthoringAccessDto = {
          recordId: params.recordId as any,
          recordType: scope
        };
        if (connection?.state !== "Connected") return;
        await connection.invoke("KeepAlive", coAuthoringAccessDto);
      } catch {
        handleConnectionError("recordSignalRError");
      } finally {
        dispatch(setKeepConnectionAlive(false));
      }
    };

    if (keepConnectionAlive) keepAlive();
  }, [connection, dispatch, handleConnectionError, keepConnectionAlive, params.recordId, scope]);

  const fetchTokenAndKeepAlive = useCallback(async () => {
    if (connection?.state !== "Connected") return;
    const coAuthoringAccessDto = {
      recordId: params.recordId as any,
      recordType: scope
    };

    // Fetch a new access token to update the expiry time
    const accessToken = await getAccessToken(coAuthoringAccessDto).unwrap();
    if (accessToken) {
      dispatch(setAuthentication(accessToken));
    }

    // Call KeepAlive to update the expiry time in table storage to retain editor access
    await connection.invoke("KeepAlive", coAuthoringAccessDto);
  }, [connection, dispatch, getAccessToken, params.recordId, scope]);

  useEffect(() => {
    if (!isEditor) return;
    if (isExpired) {
      handleConnectionError("tokenExpiredMessage");
      closeConnection();
    }
    if (sessionNeedsRenewing) {
      fetchTokenAndKeepAlive();
    }
  }, [
    closeConnection,
    dispatch,
    fetchTokenAndKeepAlive,
    handleConnectionError,
    isEditor,
    isExpired,
    sessionNeedsRenewing
  ]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!isEditor && new Date(recordAuth?.expiresOnISO ?? "") < new Date(Date.now() + 60000)) {
        fetchTokenAndKeepAlive();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchTokenAndKeepAlive, isEditor, recordAuth?.expiresOnISO]);

  const getFlyoutData = useMemo(() => {
    if (!accessRoles) return;
    switch (flyoutId) {
      case RecordSettings.properties:
        return (
          <Properties
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles) || !!currentVersion}
            scope={scope}
          />
        );
      case RecordSettings.historyEvents:
        return (
          <HistoryEvents
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles)}
            scope={scope}
          />
        );
      case RecordSettings.editCardContent:
        return (
          <EditCardImage
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles) || !!currentVersion}
          />
        );
      case RecordSettings.stageApproval:
        return <StageApprovalTabs />;
      case RecordSettings.manageAccess:
        return (
          <ManageAccess
            readOnly={!hasAccessRole(RecordAccessRole.UpdatePermissions, accessRoles)}
          />
        );
      case RecordSettings.ediRowGeneration:
        return <EdiRowGeneration tableId={ediTableId} />;

      case RecordSettings.tableRowReview:
        return <ProjectTableRowReview />;
      case RecordSettings.associatedRecords:
        return <AssociatedRecords />;
      case RecordSettings.programAssociations:
        return <ProgramAssociatedRecords />;
      case RecordSettings.versionHistory:
        return <RecordVersions />;
    }
  }, [flyoutId, accessRoles, ediTableId]);

  useEffect(() => {
    dispatch(getAIEnabledAsync());
  }, []);

  const getVersionData = useCallback(async () => {
    const authObject = recordAuth as RecordAuth;
    // const authObject = await getAuthObject();
    if (!hasAccessRole(RecordAccessRole.Read, authObject.details.AccessRoles)) return;

    if (currentVersion?.id && currentVersion?.id > 0) {
      dispatch(setRecordReadOnly(true));
      const response = await getRecordVersion(currentVersion.id, scope);
      const recordVersion = response.data;
      dispatch(setRecordVersion(recordVersion));
      dispatch(setRecordVersionCalendars(recordVersion.versionData.calendars));
      dispatch(setRecordVersionResources(recordVersion.versionData?.resources));
      dispatch(setRecordVersionViewTableRows(recordVersion.versionData.record.tableRows));
      dispatch(setRecordTables(recordVersion.versionData.tables));
    } else {
      dispatch(setRecordReadOnly(false));
      refetchData();
    }
  }, [currentVersion?.id, dispatch, recordAuth, refetchData, scope]);

  useEffect(() => {
    if (rowsToUpdate.length === 0) return;
    const notifications = generateApprovedRowsNotification(rowsToUpdate, tables, t);
    dispatch(
      setCurrentNotification({
        level: NotificationLevel.Information,
        title: t("rowsUpdated"),
        message: notifications
      })
    );
  }, [rowsToUpdate]);

  useEffect(() => {
    if (!currentVersion) return;
    getVersionData();
  }, [currentVersion]);

  const MemoizedProjectTabs = useMemo(
    () => <ProjectTabs scope={scope} recordId={params.recordId} />,
    [params.recordId, scope]
  );

  useEffect(() => {
    //Show a warning if the user tries to leave the page with unsaved changes
    if (!recordDataInitialised) return;
    if (blocker.state !== "blocked") return;

    const text = `${t("savingInProgress")} \n${t("unsavedChanges")} \n${t("leavePageQuestion")}`;
    const result = window.confirm(text);
    if (result) {
      blocker.proceed();
    }
  }, [blocker.state]);

  return (
    <Stack spacing={1} className="project-record-root">
      <RecordErrorSnackbar label={recordErrorLabel} onClose={() => setRecordErrorLabel(null)} />
      {getFlyoutData}
      <Paper sx={{ p: 2 }}>
        {!isLoading && params.recordId ? (
          MemoizedProjectTabs
        ) : (
          <div className="circular-progress-container">
            <Loading size={70} />
          </div>
        )}
      </Paper>
      <EffectiveCalendarChangedDialog />
    </Stack>
  );
};

export default RecordPage;
