import { Button, Stack, Tooltip } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export interface LicenseInfoProps {
  totalLicenses: number | "infinite";
  usedLicences: number;
  title: string;
  buyLicenseClick?: () => void;
  titleModule?: ReactNode;
  tooltipText?: string;
  noBuyOption?: boolean;
}
const LicenseInfo: FC<LicenseInfoProps> = ({
  totalLicenses,
  usedLicences,
  title,
  buyLicenseClick,
  titleModule,
  tooltipText,
  noBuyOption
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Stack spacing={2} direction="column" alignItems={"center"}>
      <Stack spacing={1} direction={"row"} alignItems={"center"}>
        <EdisonTypography title={title} variant="h2" />
        <Tooltip title={tooltipText}>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>{" "}
        {titleModule}
      </Stack>
      <EdisonTypography
        variant="h2"
        title={`${usedLicences}/${totalLicenses === "infinite" ? "∞" : totalLicenses}`}
      />
      <EdisonTypography
        variant="data2"
        title={
          totalLicenses === usedLicences
            ? totalLicenses === 0
              ? `0 ${t("licensesLeft")}`
              : t("full")
            : isNaN(totalLicenses as any)
            ? "∞" + " " + t("licensesLeft")
            : `${
                (totalLicenses as number) - usedLicences < 0
                  ? 0
                  : (totalLicenses as number) - usedLicences
              } ${t("licensesLeft")}`
        }
      />
      {!Boolean(noBuyOption) && (
        <Button endIcon={<OpenInNewIcon />} onClick={buyLicenseClick}>
          {t("buyAdditionalLicenses")}
        </Button>
      )}
    </Stack>
  );
};

export default LicenseInfo;
