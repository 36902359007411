import { apiConfig } from "config/authConfig";
import {
  PendingRecordTableRow,
  RecordsTableRowsPendingOperations,
  RecordTableConfiguration,
  RecordTableRow,
  RecordType,
  ReviewResponse,
  RowOperationType
} from "enada-common";
import { getApiForRecord, getApiForTables } from "services/APIService";
import { baseAPI } from "services/baseAPI";
import { RecordAuth } from "store/slices/recordSlice";
import { ApiResponse } from "types/ApiResponse";

export const recordTablesAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getRecordViewPeriodicGrandTotals: builder.query<
      Record<number, number>,
      { recordId: number; tableId: number; recordType: RecordType; viewId: number }
    >({
      query: ({ recordId, tableId, recordType, viewId }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/table/${tableId}/totals/${viewId}`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: Record<number, number> }) => response.data,
      providesTags: ["RecordTables"]
    }),

    postRecordViews: builder.mutation<any, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/view`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        }
      })
    }),

    getRecordViewTableRowValues: builder.query<
      RecordTableRow[],
      { recordId: number; recordType: RecordType; viewId: number }
    >({
      query: ({ recordId, recordType, viewId }) => ({
        url: `${getApiForTables(
          recordType
        )}/${recordId}/rows/${viewId}?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: RecordTableRow[] }) => response.data,
      providesTags: ["RecordTableRows"]
    }),

    getRecordViewPendingTableRowValues: builder.query<
      PendingRecordTableRow[],
      { recordId: number; recordType: RecordType; viewId: number }
    >({
      query: ({ recordId, recordType, viewId }) => ({
        url: `${getApiForTables(
          recordType
        )}/${recordId}/pending/rows/${viewId}?startYear=0&endYear=5000&startPeriod=0&endPeriod=5000`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: PendingRecordTableRow[] }) => response.data,
      providesTags: ["RecordTables"]
    }),

    getTableConfig: builder.query<
      RecordTableConfiguration[],
      { recordId: number; recordType: RecordType }
    >({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/configurations`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: RecordTableConfiguration[] }) => response.data,
      providesTags: ["RecordTables"]
    }),

    updateRecordTableConfig: builder.mutation<
      ApiResponse<RecordTableConfiguration[]>,
      { recordId: number; recordType: RecordType; body: RecordTableConfiguration[] }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/configurations`,
        method: "POST",
        body,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      invalidatesTags: ["RecordAccessToken"]
    }),

    updateTableRowValues: builder.mutation<
      ApiResponse<RowOperationType[]>,
      {
        recordAuth: RecordAuth;
        recordType: RecordType;
        body: RowOperationType[];
        invalidateTags?: string;
      }
    >({
      query: ({ recordAuth, recordType, body }) => ({
        url: `${getApiForTables(recordType)}/${recordAuth.details.RecordId}/rows/${
          recordAuth.details.Views[0]
        }`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend",
          "edison365-permission-token": recordAuth.permissionToken
        },
        body
      }),
      invalidatesTags: (result, error, { invalidateTags }) =>
        invalidateTags ? [invalidateTags] : ["RecordAccessToken"]
    }),

    convertMpp: builder.mutation<
      ApiResponse<string>,
      { body: File; recordId: number; tableId: number }
    >({
      query: ({ recordId, tableId, body }) => {
        const formData = new FormData();
        formData.append("file", body as File);

        return {
          url: `${apiConfig.commonConvertMppEndpoint}/${recordId}/${tableId}`,
          method: "POST",
          body: formData,
          formData: true
        };
      }
    }),

    reviewRecordTableRows: builder.mutation<
      ApiResponse<ReviewResponse>,
      { recordId: number; recordType: RecordType; body: RecordsTableRowsPendingOperations[] }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/pending/rows/approval`,
        method: "POST",
        body
      }),
      invalidatesTags: ["RecordTables", "Records", "Personal"]
    })
  })
});
