import {
  FilterOperation,
  FilterPropertyType,
  FilterType,
  getDefaultFilterTypeDisplayName,
  getFilterTypes,
  FilterOperationOptions
} from "enada-common";
import { MenuItem, Select, TextField, Stack } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import React, { FC } from "react";
import UserDatePicker from "../../user/datepicker/UserDatePickerField";
import "./basefilterrow.scss";
import AutocompleteElement, { AutocompleteElementType } from "./AutocompleteElement";

export interface EdisonBaseFilterRowProps {
  filterOperation: FilterOperation;
  allFilterOperations: FilterOperation[];
  propertyDisplayName: string;
  operatorDisplayName: string;
  valueDisplayName: string;

  deleteFilter: (filterOp: FilterOperation) => void;
  addFilterValueChanged: (newValue: any, filterOp: FilterOperation, propName: string) => void;
  getFilterTypeDisplayName?: (filterType: FilterType) => string;
  showDeleteButton: boolean;
}

const BaseFilterRow: FC<EdisonBaseFilterRowProps> = ({
  filterOperation,
  allFilterOperations,
  propertyDisplayName,
  operatorDisplayName,
  valueDisplayName,
  deleteFilter,
  addFilterValueChanged,
  getFilterTypeDisplayName,
  showDeleteButton
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      className="filterSelectorRow"
      data-testid={"edison-base-filters"}
    >
      {filterOperation.id !== undefined && showDeleteButton && (
        <IconButton
          data-testid={"edison-base-filters-buttons-delete"}
          className="deleteAction"
          color="primary"
          onClick={() => deleteFilter(filterOperation)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      )}
      <FormControl className="propertyName" fullWidth>
        <InputLabel id={`property-select-label-${filterOperation.id ?? -1}`}>
          {propertyDisplayName}
        </InputLabel>
        <Select
          value={filterOperation.property}
          labelId={`property-select-label-${filterOperation.id ?? -1}`}
          label={propertyDisplayName}
          onChange={e => addFilterValueChanged(e.target.value, filterOperation, "property")}
        >
          {allFilterOperations.map((f, index) => {
            return (
              <MenuItem key={index + f.property} value={f.property}>
                {f.displayName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className="innerOperation" fullWidth>
        <InputLabel id={`inner-operator-label-${filterOperation.id ?? -1}`}>
          {operatorDisplayName}
        </InputLabel>
        <Select
          value={filterOperation.operator}
          labelId={`inner-operator-label-${filterOperation.id ?? -1}`}
          label={operatorDisplayName}
          onChange={e => addFilterValueChanged(e.target.value, filterOperation, "operator")}
        >
          {getFilterTypes(filterOperation.propertyType).map((op, index) => {
            return (
              <MenuItem key={index + op} value={op}>
                {getFilterTypeDisplayName
                  ? getFilterTypeDisplayName(op)
                  : getDefaultFilterTypeDisplayName(op)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {Array.isArray(filterOperation.options) &&
        filterOperation.propertyType !== FilterPropertyType.MultiChoice &&
        filterOperation.propertyType !== FilterPropertyType.PeoplePicker &&
        filterOperation.propertyType !== FilterPropertyType.ResourcePicker && (
          <FormControl className="selectedValue" fullWidth>
            <InputLabel id={`selected-value-label-${filterOperation.id ?? -1}`}>
              {valueDisplayName}
            </InputLabel>
            <Select
              labelId={`selected-value-label-${filterOperation.id ?? -1}`}
              label={valueDisplayName}
              value={filterOperation.value ?? ""}
              onChange={e => addFilterValueChanged(e.target.value, filterOperation, "value")}
            >
              {filterOperation?.options.map((op, index) => {
                return (
                  <MenuItem key={index} value={op.value}>
                    {op.displayName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      {Array.isArray(filterOperation.options) &&
        filterOperation.propertyType === FilterPropertyType.MultiChoice && (
          <AutocompleteElement
            filterOperation={filterOperation}
            valueDisplayName={valueDisplayName}
            onChange={(changeValue: FilterOperationOptions[]) =>
              addFilterValueChanged(changeValue, filterOperation, "value")
            }
          />
        )}
      {Array.isArray(filterOperation.options) &&
        (filterOperation.propertyType === FilterPropertyType.PeoplePicker ||
          filterOperation.propertyType === FilterPropertyType.ResourcePicker) && (
          <AutocompleteElement
            filterOperation={filterOperation}
            valueDisplayName={valueDisplayName}
            type={
              filterOperation.propertyType === FilterPropertyType.PeoplePicker
                ? AutocompleteElementType.PeoplePicker
                : AutocompleteElementType.ResourcePicker
            }
            tagLimit={1}
            onChange={(changeValue: FilterOperationOptions[]) =>
              addFilterValueChanged(changeValue, filterOperation, "value")
            }
          />
        )}
      {!Array.isArray(filterOperation.options) &&
        filterOperation.propertyType !== FilterPropertyType.Date && (
          <TextField
            id={`selected-value-label-${filterOperation.id ?? -1}`}
            label={valueDisplayName}
            className="selectedValueTextField"
            value={filterOperation.value !== undefined ? filterOperation.value : ""}
            variant="outlined"
            onChange={e => {
              let parsedValue: number | null = null;
              if (filterOperation.propertyType === FilterPropertyType.Number) {
                parsedValue = parseFloat(e.target.value);
              }
              addFilterValueChanged(
                parsedValue !== null && !isNaN(parsedValue) ? parsedValue : e.target.value,
                filterOperation,
                "value"
              );
              e.preventDefault();
            }}
          />
        )}
      {!Array.isArray(filterOperation.options) &&
        filterOperation.propertyType === FilterPropertyType.Date && (
          <div className="dateWrapper">
            <UserDatePicker
              label={valueDisplayName}
              value={filterOperation.value ? filterOperation.value : new Date()}
              isIconOn
              useInternalState={false}
              textFieldVariant="outlined"
              onChange={(value: Date) => {
                addFilterValueChanged(value, filterOperation, "value");
              }}
            />
          </div>
        )}
    </Stack>
  );
};

export default BaseFilterRow;
