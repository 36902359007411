import { FC, PropsWithChildren } from "react";
import { RecordAccessRole } from "enada-common";
import useHasRecordAccessRole from "../../utils/hooks/useHasRecordAccessRole";

type HasAccessRolesProps = {
  roles: RecordAccessRole[];
  noMatchElement?: JSX.Element;
  id?: string;
};

const HasAccessRoles: FC<PropsWithChildren<HasAccessRolesProps>> = ({
  children,
  roles,
  noMatchElement
}) => {
  const { hasRecordAccessRole } = useHasRecordAccessRole();
  if (!hasRecordAccessRole(roles)) {
    return noMatchElement;
  }
  return children;
};

export default HasAccessRoles;
