import { Alert, Button, Divider, Stack } from "@mui/material";
import React, { FC } from "react";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import "./createrecordconfirmclosure.scss";
export interface CreateRecordConfirmClosureProps {
  onGoBack: () => void;
  onConfirm: () => void;
}

const CreateRecordConfirmClosure: FC<CreateRecordConfirmClosureProps> = ({
  onConfirm,
  onGoBack
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Stack spacing={1} className="create-record-confirm-closure-root">
      <EdisonTypography
        title={t("closeCreateExperienceMessage")}
        variant="h4"
        sx={{ fontWeight: "bold" }}
      />
      <Divider flexItem />
      <Alert severity="info">{t("closeCreateExperienceAlert")}</Alert>
      <Stack direction={"row"} spacing={1} className="button-container">
        <Button variant="contained" onClick={onConfirm}>
          {t("confirm")}
        </Button>
        <Button variant="outlined" onClick={onGoBack}>
          {t("cancel")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CreateRecordConfirmClosure;
