import { RecordAccessRole, RecordRole, RecordType, RoleType } from "enada-common";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EdisonTypography, UserTextField } from "enada-components";
import Share from "../share/Share";
import { useTranslation } from "react-i18next";
import {
  RecordSettings,
  getBCAssociationsAsync,
  getChallengeAssociationsAsync,
  getIdeaAssociationsAsync,
  getProgramAssociationsAsync,
  getProjectAssociationsAsync,
  getRecordUserLikedAsync,
  getRecordUserRatingAsync,
  postRecordUserLikeAsync,
  postRecordUserRatingAsync,
  selectCurrentVersion,
  selectEditor,
  selectHasChanges,
  selectHasUserLiked,
  selectRecord,
  selectRecordAssociations,
  selectRecordAuth,
  selectRecordSaveStatus,
  selectUserAccessRoles,
  selectUserRating,
  selectWorkflowStage,
  setFlyoutId,
  setIsFlyoutOpen,
  updateSystemFields
} from "../../store/slices/recordSlice";
import { hasAccessRole } from "../../utils/hasAccessRole";
import HasAccessRoles from "../hasAccessRoles/HasAccessRoles";
import RecordSettingsMenu from "../recordsettingsmenu/RecordSettingsMenu";
import WorkflowStageChip from "../workflowStageChip/WorkflowStageChip";
import ParentChip from "../ParentChip/ParentChip";
import useHasRecordRole from "../../utils/hooks/useHasRecordRole";
import useHasRole from "../../utils/hooks/useHasRole";
import { Link } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import dayjs from "dayjs";
import { useGetUserQuery } from "services/api";

const ItemsHeader: FC = () => {
  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery();

  const recordInfo = useAppSelector(selectRecord);
  const accessRoles = useAppSelector(selectUserAccessRoles);
  const likeInfo = useAppSelector(selectHasUserLiked);
  const ratingInfo = useAppSelector(selectUserRating);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const authentication = useAppSelector(selectRecordAuth);
  const associations = useAppSelector(selectRecordAssociations);
  const projectSaveStatus = useAppSelector(selectRecordSaveStatus);
  const projectEditor = useAppSelector(selectEditor);
  const hasChanges = useAppSelector(selectHasChanges);
  const { t } = useTranslation(["common"]);
  const { hasRecordRole } = useHasRecordRole();
  const { hasRole } = useHasRole();

  const currentVersion = useAppSelector(selectCurrentVersion);

  const [gotAssociations, setGotAssociations] = useState(false);
  const [editDisplayName, setEditDisplayName] = useState(false);
  const [hasChangesState, setHasChangesState] = useState(false);
  const hasRolesToRenderAssociatedRecords =
    hasRecordRole([RecordRole.Manager, RecordRole.Owner]) ||
    hasRole([RoleType.InstanceAdmin]) ||
    recordInfo?.recordType === RecordType.Programs;
  const isEditor = user?.id === projectEditor?.oid;

  useEffect(() => {
    if (!authentication || !hasRolesToRenderAssociatedRecords || !recordInfo || gotAssociations)
      return;
    if (recordInfo.recordType === RecordType.BusinessCase) {
      dispatch(getBCAssociationsAsync(authentication));
    }
    if (recordInfo.recordType === RecordType.Projects) {
      dispatch(getProjectAssociationsAsync(authentication));
    }
    if (recordInfo.recordType === RecordType.Programs) {
      dispatch(getProgramAssociationsAsync(authentication));
    }
    if (recordInfo.recordType === RecordType.Ideas) {
      dispatch(getIdeaAssociationsAsync(authentication));
    }
    if (recordInfo.recordType === RecordType.Challenges) {
      dispatch(getChallengeAssociationsAsync(authentication));
    }

    setGotAssociations(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordInfo, authentication, hasRolesToRenderAssociatedRecords]);

  useEffect(() => {
    if (hasChangesState !== hasChanges) {
      hasChanges
        ? (window.onbeforeunload = function () {
            return t("unsavedChanges");
          })
        : (window.onbeforeunload = null);

      setHasChangesState(hasChanges);
    }
    // Clear the refresh event listener when the component is unmounted
    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges]);

  const parentItem = useMemo(() => {
    if (gotAssociations) {
      //Parent of an idea is challenge, every other record type has a parent type of program
      return associations.find(el =>
        recordInfo?.recordType === RecordType.Ideas
          ? el.recordType === RecordType.Challenges
          : el.recordType === RecordType.Programs
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associations, gotAssociations]);

  const handleDisplayNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setEditDisplayName(false);

    dispatch(
      updateSystemFields({
        key: "displayName",
        changeValue: event.target.value
      })
    );
  };

  const canUpdate = useMemo(
    () => accessRoles && hasAccessRole(RecordAccessRole.Update, accessRoles) && isEditor,
    [accessRoles, isEditor]
  );

  return (
    <Stack direction="column">
      {canUpdate && editDisplayName && (!currentVersion || currentVersion.id === 0) ? (
        <div onBlur={handleDisplayNameBlur}>
          <UserTextField
            value={recordInfo?.displayName}
            readOnly={!editDisplayName}
            isIconOn={false}
            label={""}
            useInternalState={false}
          />
        </div>
      ) : (
        <div
          className={canUpdate ? "underline-on-hover" : ""}
          onClick={canUpdate ? () => setEditDisplayName(true) : undefined}
        >
          <EdisonTypography title={recordInfo?.displayName || "Record Information"} variant="h1" />
        </div>
      )}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: "center",
          lineHeight: "10px"
        }}
      >
        <Rating
          name="size-medium"
          size="small"
          value={ratingInfo ?? 0}
          onChange={async (event, newValue) => {
            await dispatch(
              postRecordUserRatingAsync({
                recordAuth: authentication,
                recordType: recordInfo?.recordType,
                rating: newValue
              })
            );
            dispatch(
              getRecordUserRatingAsync({
                recordAuth: authentication,

                recordType: recordInfo?.recordType
              })
            );
          }}
        />
        <Rating
          sx={theme => ({
            color: theme.palette.Like?.main,
            "&:hover": {
              color: theme.palette.LikeHover?.main
            }
          })}
          name="customized-color"
          max={1}
          value={likeInfo ? 1 : 0}
          onChange={async (event, newValue) => {
            await dispatch(
              postRecordUserLikeAsync({
                recordAuth: authentication,
                recordType: recordInfo?.recordType,
                hasLiked: newValue
              })
            );
            dispatch(
              getRecordUserLikedAsync({
                recordAuth: authentication,
                recordType: recordInfo?.recordType
              })
            );
          }}
          icon={<FavoriteIcon fontSize="small" />}
          emptyIcon={<FavoriteBorderIcon fontSize="small" />}
        />
        <Share />
        <RecordSettingsMenu />
        <WorkflowStageChip
          displayName={workflowStage?.displayName}
          color={workflowStage?.configuration?.color as string}
        />
        {parentItem && (
          <Link
            to={`./${parentItem.recordType?.toLowerCase()}/${parentItem.recordId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ParentChip
              displayName={parentItem.displayName}
              type={parentItem.recordType ?? RecordType.Programs}
            />
          </Link>
        )}
        <HasAccessRoles roles={[RecordAccessRole.ReadVersion]}>
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={
              currentVersion && currentVersion.id !== 0
                ? `${currentVersion?.versionName} (${dayjs(currentVersion?.versionCreated).format(
                    "DD/MM/YY HH:mm"
                  )})`
                : t("recordVersions.liveVersionChip")
            }
            onClick={() => {
              dispatch(setIsFlyoutOpen(true));
              dispatch(setFlyoutId(RecordSettings.versionHistory));
            }}
            data-testid={"record-versions-chip"}
          />
        </HasAccessRoles>
        {(!currentVersion || currentVersion.id === 0) && (
          <HasAccessRoles roles={[RecordAccessRole.Update]}>
            <>
              {projectSaveStatus === "idle" && hasChanges && (
                <CloudUploadOutlinedIcon color="action" />
              )}
              {projectSaveStatus === "idle" && !hasChanges && (
                <CloudDoneOutlinedIcon color="action" />
              )}
              {projectSaveStatus !== "idle" && <CloudSyncIcon color="action" />}
            </>
          </HasAccessRoles>
        )}
        <HasAccessRoles roles={[RecordAccessRole.CreateAssociation]}>
          {recordInfo?.recordType === RecordType.Programs &&
          (!currentVersion || currentVersion.id === 0) ? (
            <Button
              variant="text"
              onClick={() => {
                dispatch(setIsFlyoutOpen(true));
                dispatch(setFlyoutId(RecordSettings.programAssociations));
              }}
            >
              {t("programsSection.recordPage.configure")}
            </Button>
          ) : null}
        </HasAccessRoles>
      </Stack>
    </Stack>
  );
};

export default ItemsHeader;
