import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  createRecordVersionAsync,
  selectRecord,
  selectRecordAuth
} from "../../store/slices/recordSlice";
import { Loading } from "enada-components";

interface Props {
  toggleNewVersion: () => void;
}

const CreateVersion: React.FC<Props> = ({ toggleNewVersion }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const auth = useAppSelector(selectRecordAuth);
  const currentRecord = useAppSelector(selectRecord);

  const [versionName, setVersionName] = useState<string>("");
  const [versionNameError, setVersionNameError] = useState(false);
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const createNewVersion = async () => {
    setLoading(true);
    if (versionName) {
      await dispatch(
        createRecordVersionAsync({
          recordAuth: auth,
          recordType: currentRecord?.recordType,
          data: {
            versionName: versionName,
            versionDescription: description || ""
          }
        })
      );
      toggleNewVersion();
    } else {
      setVersionNameError(true);
    }

    setLoading(false);
  };

  return (
    <Stack direction="column" height={"100%"} justifyContent={"space-between"}>
      {loading ? (
        <Box className="circular-progress-container">
          <Loading size={70} />
        </Box>
      ) : (
        <>
          <Stack direction="column" spacing={1}>
            <TextField
              variant="standard"
              type="text"
              fullWidth
              value={versionName}
              error={versionNameError}
              placeholder={t("recordVersions.createVersion.versionName")}
              onChange={e => {
                setVersionNameError(false);
                setVersionName(e.target.value);
              }}
              label={t("recordVersions.createVersion.versionName")}
              data-testid={"record-version-name-input"}
              required
            />
            <TextField
              variant="standard"
              type="text"
              value={description}
              fullWidth
              multiline
              rows={4}
              placeholder={t("recordVersions.createVersion.description")}
              onChange={e => {
                setDescription(e.target.value);
              }}
              label={t("recordVersions.createVersion.description")}
            />
          </Stack>
          <Stack direction="column" spacing={2}>
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={createNewVersion}
                data-testid={"record-save-version-btn"}
              >
                {t("recordVersions.createVersion.save")}
              </Button>
              <Button variant="outlined" onClick={toggleNewVersion}>
                {t("recordVersions.createVersion.back")}
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CreateVersion;
