import { RollupTableColumnModel } from "enada-common";
import { Checkbox, Stack, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { FC } from "react";
import EdisonTypography from "../../typography/EdisonTypography";
import { SortOrder } from "../row/RollupTableRow";
import "./rolluptableheader.scss";
import { BorderedTableCell } from "../singletable/SingleTable";

export interface RollupTableHeaderProps {
  columns: RollupTableColumnModel[];
  onToggleCheckAll: (checked: boolean) => void;
  orderBy: string;
  order: SortOrder;
  onSortClicked: (columnName: string) => void;
  allChecked: boolean;
  rowActionsName?: string;
  disableColumnSort?: boolean;
  disableSelection?: boolean;
}
const RollupTableHeader: FC<RollupTableHeaderProps> = ({
  columns,
  onToggleCheckAll,
  allChecked,
  orderBy,
  order,
  onSortClicked,
  rowActionsName,
  disableColumnSort,
  disableSelection
}) => {
  return (
    <TableHead className="rollup-table-header-root" data-testid={"edison-rollup-table-header"}>
      <TableRow>
        {!disableSelection && (
          <BorderedTableCell>
            <Checkbox checked={allChecked} onChange={e => onToggleCheckAll(e.target.checked)} />
          </BorderedTableCell>
        )}
        {columns.map((column, index: number) => (
          <BorderedTableCell key={index} align="left" width={column.width}>
            <Stack direction="row" spacing={2}>
              {!disableColumnSort && (
                <TableSortLabel
                  active={orderBy === column.name}
                  direction={orderBy === column.name ? order : "asc"}
                  onClick={_e => onSortClicked(column.name)}
                >
                  <EdisonTypography variant="tableheader" title={column.displayName} />
                </TableSortLabel>
              )}
              {disableColumnSort && column.displayName}
            </Stack>
          </BorderedTableCell>
        ))}
        {rowActionsName && <BorderedTableCell align="right">{rowActionsName}</BorderedTableCell>}
      </TableRow>
    </TableHead>
  );
};

export default RollupTableHeader;
