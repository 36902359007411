import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig, loginRequest, apiGateway } from "../config/authConfig";
import { getHeaders } from "../utils/getHeaders";

const msalInstance = new PublicClientApplication(msalConfig);

type AuthenticatedBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const authenticatedBaseQuery: AuthenticatedBaseQuery = async (args, api, extraOptions) => {
  const currentAccount = msalInstance.getAllAccounts();
  const request = {
    ...loginRequest,
    account: currentAccount[0]
  };

  try {
    const response: AuthenticationResult = await msalInstance.acquireTokenSilent(request);

    if (!response) {
      await msalInstance.acquireTokenRedirect(request);
      return { error: { status: 401, data: "Token acquisition failed" } };
    }

    const accessToken = response.accessToken;

    const modifiedArgs = typeof args === "string" ? { url: args } : { ...args };

    const excludeContentTypeHeader =
      typeof modifiedArgs.body !== "undefined" && modifiedArgs.body instanceof FormData;

    modifiedArgs.headers = getHeaders(
      accessToken,
      modifiedArgs.url,
      undefined,
      excludeContentTypeHeader
    );

    const result = await fetchBaseQuery({
      baseUrl: apiGateway
    })(modifiedArgs, api, extraOptions);

    return result;
  } catch (error) {
    return { error: { status: 500, data: "Request failed" } };
  }
};
