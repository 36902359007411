import { createSelector } from "@reduxjs/toolkit";
import { RollupTableRowModel } from "enada-common";

// eslint-disable-next-line
export function selectInData<T = any>(callback: (data?: T) => T | undefined) {
  return createSelector(
    result => result.data,
    // eslint-disable-next-line
    data => callback(data as any)
  );
}

// eslint-disable-next-line
export function selectRollupRowsFromData<T = any>(
  callback: (data?: T) => RollupTableRowModel[] | undefined
) {
  return createSelector(
    result => result.data,
    // eslint-disable-next-line
    data => callback(data as any)
  );
}
