import { Field, PersonaEntity, RecordTableRow, TableField, TableRowValue } from "enada-common";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { getUniqueIdFromRow, getValueByFieldType } from "../tableHelpers";

const parseTableDataToFrontend = (
  rows: RecordTableRow[],
  fields: Field[],
  tableFields?: TableField[] | null
) => {
  const result = rows.map(row => parseRowToBryntumRow(row, fields, tableFields));
  return result;
};

const parseRowToBryntumRow = (
  row: RecordTableRow,
  fields: Field[],
  tableFields?: TableField[] | null
) => {
  if (!tableFields) {
    const data: { [key: string]: any } = {};
    row.tableRowFieldValues?.forEach((value: TableRowValue) => {
      const linkedField = fields.find(field => field.id === value.fieldId);
      if (!linkedField) return;
      data[
        linkedField.id === SystemFieldType.RowId || linkedField.id === SystemFieldType.UniqueId
          ? linkedField.name
          : `${linkedField.name}-e365`
      ] = getValueByFieldType(linkedField, value);
    });
    return { ...data };
  }
  const data: { [key: string]: any } = {};
  tableFields.forEach(tableField => {
    const value = row.tableRowFieldValues?.find(
      (value: TableRowValue) => value.fieldId === tableField.fieldId
    );
    const linkedField = fields.find(field => field.id === tableField.fieldId) as Field;

    data[
      linkedField.id === SystemFieldType.RowId || linkedField.id === SystemFieldType.UniqueId
        ? linkedField.name
        : `${linkedField.name}-e365`
    ] = value ? getValueByFieldType(linkedField, value) : undefined;
  });

  //Needed to prepare table to accept live updates
  data["pendingUpdate-e365"] = false;

  return { ...data, id: getUniqueIdFromRow(row) };
};
export default parseTableDataToFrontend;
