import { createSlice } from "@reduxjs/toolkit";

const firewallSlice = createSlice({
  name: "firewall",
  initialState: {
    isOpen: false
  },
  reducers: {
    onOpen: state => {
      state.isOpen = true;
    },
    onClose: state => {
      state.isOpen = false;
    }
  }
});

export const { onClose, onOpen } = firewallSlice.actions;
export default firewallSlice.reducer;
