import { Field, Table, WorkflowStageViewField, WorkflowStageViewTable } from "enada-common";
import React, { FC, useEffect, useState, useTransition } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { selectRecordFields, selectRecordForm } from "../../../../store/slices/recordSlice";
import { Alert, AlertTitle, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectRecordTables } from "../../../../store/slices/recordTableSlice";
export interface ProgressionBlockAlertProps {
  missingFields: WorkflowStageViewField[];
  missingTables: WorkflowStageViewTable[];
  type: "Review" | "Progress";
}

const ProgressionBlockAlert: FC<ProgressionBlockAlertProps> = ({
  missingFields,
  missingTables,
  type
}) => {
  const { t } = useTranslation(["common"]);
  const projectFields = useAppSelector(selectRecordFields);
  const projectTables = useAppSelector(selectRecordTables);
  const projectForm = useAppSelector(selectRecordForm);
  const [fieldToTabMap, setFieldToTabMap] = useState(new Map());

  const [tableToTabMap, setTableToTabMap] = useState(new Map());

  useEffect(() => {
    const interimMap = new Map();
    const tableInterimMap = new Map();
    projectForm?.containers?.forEach(container =>
      container.zones?.forEach(zone =>
        zone.columns?.forEach(column => {
          column.fields?.forEach(columField =>
            interimMap.set(columField.fieldId, container.displayName ?? container.id)
          );
          column.tables?.forEach(columnTable =>
            tableInterimMap.set(columnTable.tableId, container.displayName ?? container.id)
          );
        })
      )
    );
    setFieldToTabMap(interimMap);
    setTableToTabMap(tableInterimMap);
  }, []);

  const fields = missingFields.map(
    missingField => projectFields.find(field => field.id === missingField.fieldId) as Field
  );
  const tables = missingTables.map(
    missingTable => projectTables.find(table => table.id === missingTable.tableId) as Table
  );
  return (
    <Alert severity="error">
      <Stack>
        <AlertTitle>{t(`unableTo${type}`)}</AlertTitle>
        {t(`populateMandatoryMessage${type}`)}
        <br />
        {fields.map((field, index) => (
          <Stack key={index} direction="row">
            {index + 1}. &nbsp;
            <strong>{field.displayName ?? field.name}</strong> &nbsp;
            {t("within")} &nbsp;
            <strong>{fieldToTabMap.get(field.id)}</strong>.
          </Stack>
        ))}
        {tables.map((table, index) => (
          <Stack key={index} direction="row">
            {index + 1 + fields.length}. &nbsp;
            <strong>{table.displayName ?? table.name}</strong> &nbsp;
            {t("within")} &nbsp;
            <strong>{tableToTabMap.get(table.id)}</strong>.
          </Stack>
        ))}
      </Stack>
    </Alert>
  );
};

export default ProgressionBlockAlert;
