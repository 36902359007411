import React, { FC, useState } from "react";
import Popover from "@mui/material/Popover";
import { Chip, Grid, SxProps, useTheme } from "@mui/material";
import {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from "@mui/material/colors";
import EdisonTypography from "../typography/EdisonTypography";
import "./edisoncolourpicker.scss";
import { EdisonColourPickerProps } from "enada-common";

const EdisonColourPicker: FC<EdisonColourPickerProps> = ({
  onChange,
  label,
  colour,
  t,
  disabled
}) => {
  const availableColours = [
    amber[200],
    amber[800],
    blue[200],
    blue[800],
    blueGrey[200],
    blueGrey[800],
    brown[200],
    brown[800],
    cyan[200],
    cyan[800],
    deepOrange[200],
    deepOrange[800],
    deepPurple[200],
    deepPurple[800],
    green[200],
    green[800],
    grey[200],
    grey[800],
    indigo[200],
    indigo[800],
    lightBlue[200],
    lightBlue[800],
    lightGreen[200],
    lightGreen[800],
    lime[200],
    lime[800],
    orange[200],
    orange[800],
    pink[200],
    pink[800],
    purple[200],
    purple[800],
    red[200],
    red[800],
    teal[200],
    teal[800],
    yellow[200],
    yellow[800]
  ];

  const [pickerState, setPickerState] = useState<{
    anchorElement: HTMLElement | null;
    open: boolean;
  }>({ anchorElement: null, open: false });

  const theme = useTheme();
  const currentColour = colour ?? common.white;
  const getChip = (colour: string, index?: number) => {
    const baseStyles: SxProps = {
      width: "40px",
      height: "24px"
    };
    const styles: SxProps =
      currentColour === colour
        ? {
            ...baseStyles,
            border: `2px solid ${colour}`,
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3),0px 1px 3px rgba(0, 0, 0, 0.15)"
          }
        : {
            ...baseStyles,
            bgcolor: colour,
            color: theme.palette.getContrastText(colour),
            ":hover": {
              bgcolor: colour,
              filter: `drop-shadow(0 0 3px ${theme.palette.primary.main})`
            }
          };
    return (
      <Grid key={index} xs={3} item>
        <Chip
          data-testid={"edison-colour-picker-chip"}
          className={`chip ${currentColour === colour ? "selected" : ""}`}
          sx={styles}
          label="Aa"
          onClick={() => {
            onChange(colour);
          }}
        />
      </Grid>
    );
  };

  return (
    <>
      <Chip
        data-testid={"edison-colour-picker-chip"}
        onClick={event => {
          if (disabled) return;

          setPickerState({ anchorElement: event.currentTarget, open: true });
          setPickerState({ anchorElement: event.currentTarget, open: true });
          setPickerState({ anchorElement: event.currentTarget, open: true });
        }}
        disabled={disabled}
        size="small"
        sx={{
          bgcolor: currentColour,
          ":hover": {
            bgcolor: currentColour
          },
          color: currentColour ? theme.palette.getContrastText(currentColour) : "",
          border: currentColour === common.white ? `1px solid ${grey[500]}` : ""
        }}
        label={label}
      />
      <Popover
        data-testid={"edison-colour-picker-popover"}
        open={pickerState?.open}
        anchorEl={pickerState?.anchorElement}
        onBlur={() => setPickerState({ anchorElement: null, open: false })}
        className="colour-picker-popover"
      >
        <Grid container spacing={1} className="inner">
          <Grid xs={12} item>
            <EdisonTypography title={t("colourOptions")} variant="dropdownsubheader" />
          </Grid>
          {getChip(common.white)}
          {getChip(common.black)}
          {availableColours
            .sort(colour => (theme.palette.getContrastText(colour) === common.white ? -1 : 1))
            .map((colour, index) => {
              return getChip(colour, index);
            })}
        </Grid>
      </Popover>
    </>
  );
};

export default EdisonColourPicker;
export { EdisonColourPicker };
