import { Stack, TextField, Box } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Image } from "enada-common";
import "./editcardimage.scss";
import {
  RecordSettings,
  getHeadersFromAuth,
  getIndividualRecordAsync,
  selectRecordAuth,
  selectRecord,
  updateSystemFields,
  selectRecordType
} from "../../store/slices/recordSlice";
import { EdisonImageField } from "enada-components";
import { postRecordImage } from "../../services/APIService";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { selectRegion } from "../../store/slices/configSlice";
import { getImagePath } from "../../utils/imagePathManager";
import { SYSTEM_FILE_SIZE_LIMIT } from "../../config/authConfig";
import { useGetCdnTokenQuery } from "services/api";

const EditCardImage: FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation(["common"]);
  const { data: cdnToken } = useGetCdnTokenQuery();
  const record = useAppSelector(selectRecord);
  const region = useAppSelector(selectRegion);
  const dispatch = useAppDispatch();

  const [cardImage, setCardImage] = useState<Image>();

  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const [cleared, setCleared] = useState(false);
  // left side padding needs to be added individually by class because of the dividers
  const getImage = () => {
    if (cleared) return undefined;
    let image;
    if (cardImage?.preview) {
      image = cardImage;
    } else if (record?.imageUrl) {
      image = {
        preview: getImagePath(record.imageUrl, region, cdnToken)
      };
    }
    return image;
  };

  return (
    <ProjectRecordFlyout title={t(!readOnly ? RecordSettings.editCardContent : "viewCardContent")}>
      <Stack direction="column" className="card-image-root">
        {readOnly ? (
          <Box
            sx={{
              textAlign: "center"
            }}
          >
            <img src={record?.imageUrl ?? ""} alt="Record" />
          </Box>
        ) : (
          <EdisonImageField
            accept="image/png, image/jpg, image/jpeg"
            label=""
            invalidMediaTypeMessage={t("invalidMediaType")}
            invalidFileSizeMessage={t("invalidFileSize")}
            maxFileSize={SYSTEM_FILE_SIZE_LIMIT}
            useInternalState={false}
            value={getImage()}
            onChange={async (image: any) => {
              setCleared(image === null);
              if (image === null) return;
              if (!recordAuth) return;
              setCardImage(image);
              await postRecordImage(
                recordAuth.details.RecordId,
                recordType,
                getHeadersFromAuth(recordAuth),
                image
              );
              // Get project with updated image url once the image has saved
              dispatch(getIndividualRecordAsync(recordAuth));
            }}
          />
        )}
        <TextField
          type="text"
          className="short-description"
          id="short-description"
          disabled={readOnly}
          label={t("shortDescription")}
          variant="outlined"
          value={record?.shortDescription}
          rows={4}
          multiline
          onChange={e => {
            dispatch(
              updateSystemFields({
                key: "shortDescription",
                changeValue: e.target.value
              })
            );
          }}
          inputProps={{
            maxLength: 2048
          }}
        />
        <Stack
          direction="row"
          sx={{ marginTop: "100px" }}
          spacing={1}
          justifyContent={"flex-end"}
        ></Stack>
      </Stack>
    </ProjectRecordFlyout>
  );
};

export default EditCardImage;
