import { News } from "enada-common";
import { Button, Card, CardActions, CardContent, CardMedia, Theme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { EdisonTypography } from "enada-components";
import "./newsItemCard.scss";
import { getImagePath } from "../../utils/imagePathManager";
import { selectRegion } from "../../store/slices/configSlice";
import { useGetCdnTokenQuery } from "services/api";

export interface NewsItemCardProps {
  newsItem: News;
}

const NewsItemCard: FC<NewsItemCardProps> = ({ newsItem }) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { data: cdnToken } = useGetCdnTokenQuery();
  const region = useAppSelector(selectRegion);

  const onNewsClick = () => {
    navigate("news", {
      state: {
        newsItem: newsItem,
        title: "news"
      }
    });
  };

  const getExpiryText = () => {
    if (!newsItem.endDate) {
      return "";
    }
    const endDate = new Date(newsItem.endDate);

    if (endDate.getTime() > new Date().getTime()) {
      return endDate.toLocaleDateString("en-GB");
    }
    return t("expiresOn") + " " + endDate.toLocaleDateString("en-GB");
  };

  return (
    <Card className="news-card-root double">
      {newsItem.imageUrl && (
        <CardMedia
          className="news-image"
          image={getImagePath(newsItem.imageUrl, region, cdnToken)}
        />
      )}
      <CardContent className="news-card-container" sx={{ mt: !newsItem.imageUrl ? 5 : 0 }}>
        <EdisonTypography
          title={newsItem.title}
          variant="h2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            maxHeight: "54px"
          }}
        />
        <p />
        <EdisonTypography
          sx={(theme: Theme) => ({
            color: theme.palette.Secondary.main,
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: "2",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical"
          })}
          title={newsItem.description || ""}
          variant="data2"
        />
      </CardContent>
      <CardActions className="news-actions">
        <EdisonTypography title={getExpiryText()} variant="data" />
        <Button onClick={onNewsClick}> {t("readMore")}</Button>
      </CardActions>
    </Card>
  );
};

export default NewsItemCard;
