import { FC, useEffect, useMemo, useRef, useState } from "react";
import { BryntumGantt } from "@bryntum/gantt-react-thin";
import { Button, Paper, Stack } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import "./edisonchoiceeditor.scss";
import Searchbar from "../searchbar/Searchbar";
import { DeleteOutline, DoubleArrow } from "@mui/icons-material";
import { Choice, EdisonChoiceEditorProps } from "enada-common";
import EdisonColourPicker from "../colourpicker/EdisonColourPicker";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { common } from "@mui/material/colors";
import { TaskModel } from "@bryntum/gantt-thin";

const EdisonChoiceEditor: FC<EdisonChoiceEditorProps> = ({ data, enableTree, onChange, t }) => {
  const gantt = useRef<any>();

  const [selectedRows, setSelectedRows] = useState<Partial<TaskModel>[]>([]);
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if (searchFilter === "") {
      gantt.current?.instance?.taskStore.clearFilters();
    } else {
      gantt.current?.instance?.taskStore.filter({
        filters: (r: Choice) => r.label.toLowerCase().includes(searchFilter.toLowerCase()),
        replace: true
      });
    }
  }, [searchFilter]);

  useEffect(() => {
    if (gantt.current?.instance?.taskStore) {
      gantt.current.instance.taskStore.data =
        data?.map((choice: Choice) => {
          return { ...choice, expanded: true };
        }) ?? [];
    }
  }, [enableTree]);

  useEffect(() => {
    gantt.current?.instance?.expandAll();
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) {
      if (gantt.current?.instance?.taskStore) {
        gantt.current.instance.taskStore.data = [];
      }
    }
  }, [data]);

  const getGanttConfig: any = useMemo(() => {
    return {
      autoHeight: true,
      subGridConfigs: {
        normal: { collapsed: true },
        locked: { flex: 1 }
      },
      selectionMode: {
        checkbox: {
          type: "check",
          text: "",
          showCheckAll: true,
          enableHeaderContextMenu: false
        },
        rowCheckboxSelection: true
      },
      taskStore: {
        wbsMode: "auto",
        tree: true,
        transformFlatData: true
      },
      width: "100%",
      taskMenuFeature: false,
      regionResizeFeature: false,
      treeFeature: true,
      cellEditFeature: {
        triggerEvent: "cellclick"
      },

      onSelectionChange: ({ selection }: { selection: TaskModel[] }) =>
        setSelectedRows([...selection]),
      onDataChange: () => {
        const records = gantt.current?.instance.tasks;
        if (records?.length) {
          onChange(
            records.map((r: any) => {
              const id = r.hasGeneratedId ? uuidv4() : r.id;
              const record = {
                id: id,
                label: r.label,
                colour: r.colour,
                wbs: r.wbsCode
              } as any;

              if (r.parent) {
                record["parentId"] = r.parentId;
              }

              return record;
            })
          );
        }
      },

      columns: [
        {
          type: "wbs",
          sortable: false,
          text: "Nº",
          width: "5%",
          enableHeaderContextMenu: false
        },
        {
          type: "name",
          field: "label",
          width: "10%",
          text: t("label"),
          indentSize: 1,
          enableHeaderContextMenu: false,
          sortable: false,
          editor: {
            type: "text",
            minLength: 1,
            required: true
          }
        },
        {
          field: "colour",
          text: t("colour"),
          width: "77%",
          enableHeaderContextMenu: false,
          sortable: false,
          editor: false,
          renderer: ({ record }: { record: any }) => {
            return (
              <EdisonColourPicker
                t={t}
                key={record.id}
                label={record["label"]}
                colour={record["colour"]}
                onChange={(backgroundColour: string) => {
                  record["colour"] = backgroundColour;
                }}
              />
            );
          }
        },
        {
          cellCls: "drag-handle",
          autoWidth: true,
          editor: false,
          renderer: () => <DragHandleIcon className="drag-handle-icon" />
        }
      ]
    };
  }, []);

  return (
    <Stack className="choice-editor-root" data-testid={"edison-choice-editor"}>
      <Stack
        className="choice-editor-toolbar"
        direction="row"
        spacing={1}
        sx={{ paddingBottom: "10px" }}
      >
        <div className="searchbar-container">
          <Searchbar
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            placeholder={t("search")}
          ></Searchbar>
        </div>
        <Button
          data-testid={"edison-choice-editor-buttons-delete"}
          variant="contained"
          color="secondary"
          size="small"
          className="button"
          title={t("delete")}
          disabled={!selectedRows.length}
          onClick={() => {
            const nodes = gantt.current?.instance.selectedRecords as TaskModel[];
            gantt.current?.instance.taskStore.remove(nodes);
          }}
        >
          <DeleteOutline fontSize="small" />
        </Button>
        {enableTree && (
          <>
            <Button
              data-testid={"edison-choice-editor-buttons-outdent"}
              variant="contained"
              color="secondary"
              size="small"
              className="button"
              title={t("outdent")}
              disabled={!selectedRows.length}
              onClick={() => {
                const nodes = gantt.current?.instance.selectedRecords as TaskModel[];
                gantt.current?.instance.outdent(nodes);
              }}
            >
              <DoubleArrow
                fontSize="small"
                sx={{
                  transform: "scaleX(-1)"
                }}
              />
            </Button>
            <Button
              data-testid={"edison-choice-editor-buttons-indent"}
              variant="contained"
              color="secondary"
              size="small"
              className="button"
              title={t("indent")}
              disabled={!selectedRows.length}
              onClick={() => {
                const nodes = gantt.current?.instance.selectedRecords as TaskModel[];
                gantt.current?.instance.indent(nodes);
              }}
            >
              <DoubleArrow fontSize="small" />
            </Button>
          </>
        )}
        <Button
          data-testid={"edison-choice-editor-buttons-create"}
          variant="contained"
          size="small"
          className="button"
          title={t("create")}
          onClick={() => {
            gantt.current?.instance.taskStore.add({
              id: uuidv4(),
              label: t("newChoice"),
              colour: common.white
            });
          }}
        >
          {t("create")}
        </Button>
      </Stack>
      <Paper>
        <Stack direction="row" className="choice-editor-gantt enada-bryntum-grid">
          <BryntumGantt
            data-testid={"edison-choice-editor-buttons-gantt"}
            ref={gantt}
            {...getGanttConfig}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default EdisonChoiceEditor;
export { EdisonChoiceEditor };
