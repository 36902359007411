import {
  BaseUserFieldProps,
  ConditionalExpression,
  Expression,
  parseConditionBlockToFrontend,
  parseExpressionToFrontend
} from "enada-common";
import { Stack } from "@mui/material";
import React, { FC, useMemo } from "react";
import { FrontendRecordValue } from "../../../../store/slices/recordSlice";
import {
  calculateConditionValue,
  calculateExpressionValue
} from "../utils/calculation/calculateExpressionValues";

import { EdisonTypography } from "enada-components";

export interface UserCalculatedFieldProps extends BaseUserFieldProps {
  fieldValues: FrontendRecordValue[];
  expression?: Expression;
  calculatedExpression?: ConditionalExpression;
}
const UserCalculatedField: FC<UserCalculatedFieldProps> = ({
  expression,
  calculatedExpression,
  fieldValues,
  label
}) => {
  const conditionBlock = useMemo(
    () => (calculatedExpression ? parseConditionBlockToFrontend(calculatedExpression) : undefined),
    [calculatedExpression]
  );

  const flatExpression = useMemo(
    () => (expression ? parseExpressionToFrontend(expression) : undefined),
    [expression]
  );

  const calculation = flatExpression
    ? calculateExpressionValue(fieldValues, flatExpression)
    : calculateConditionValue(fieldValues, conditionBlock);

  return (
    <Stack style={{ padding: "8px", marginTop: "8px" }}>
      <EdisonTypography title={label} variant="fieldtitle" />
      <div style={{ fontSize: 14, fontWeight: 400, letterSpacing: 0.17 }}>{calculation}</div>
    </Stack>
  );
};

export default UserCalculatedField;
