import { FC } from "react";
import { useTranslation } from "react-i18next";
import { EdisonPeoplePickerField, Loading } from "enada-components";
import {
  PersonaEntity,
  RecordPermission,
  RecordRole,
  TemplatePermission,
  PermissionType
} from "enada-common";
import { FormHelperText, Stack } from "@mui/material";

export interface CreateRecordIdeatorsProps {
  recordIdeators?: RecordPermission[] | TemplatePermission[] | null | undefined;
  onChange: (updatedPermissions?: RecordPermission[]) => void;
  isLoading: boolean;
}

const CreateRecordIdeators: FC<CreateRecordIdeatorsProps> = ({
  recordIdeators = [],
  onChange,
  isLoading
}) => {
  const { t } = useTranslation(["common"]);
  const value =
    recordIdeators?.map(entity => ({
      entityId: entity.entityId,
      type: PermissionType.User,
      userName: "",
      presence: ""
    })) ?? [];

  return isLoading ? (
    <Loading size={30} />
  ) : (
    <Stack>
      <EdisonPeoplePickerField
        multiple
        label={t("assignIdeators")}
        value={value}
        onChange={(entities: PersonaEntity[]) => {
          onChange(
            entities.map(
              entity =>
                ({
                  entityId: entity.entityId,
                  permissionType: entity.type,
                  role: RecordRole.Ideator
                } as RecordPermission)
            )
          );
        }}
        useInternalState={false}
      />
      <FormHelperText>{t("pleaseAssignIdeatorMessage")}</FormHelperText>
    </Stack>
  );
};

export default CreateRecordIdeators;
