import { News } from "enada-common";
import { Button, Divider, IconButton, Paper, Stack, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { EdisonRichText, EdisonTypography, Loading } from "enada-components";
import { useTranslation } from "react-i18next";
import "./fullnews.scss";
import { useGetDetailsNewsQuery } from "../../../services/api";

const FullNews = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1500px)");
  const newsItem = (location.state as any).newsItem as News;
  const getDaysSince = () => {
    if (!newsItem.modified) return "";
    const difference = new Date().getTime() - new Date(newsItem.modified).getTime();
    const daysSince = Math.floor(difference / (1000 * 3600 * 24));
    return daysSince === 0 ? t("today") : `${daysSince} ${t("daysAgo")}`;
  };

  const { data: individualNews, isLoading } = useGetDetailsNewsQuery(
    parseInt(newsItem?.id ?? "0"),
    {
      skip: !newsItem.id
    }
  );

  return isLoading ? (
    <Loading size={150} sx={{ marginTop: "100px" }} />
  ) : (
    <Paper>
      <Stack className="full-news-root" spacing={1}>
        <Stack direction="row" className="title-container">
          <Stack direction="row" className="title-aligner" spacing={4} maxWidth="80%">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <EdisonTypography title={newsItem.title} variant="admintile" />
          </Stack>
          <Stack direction={isSmallScreen ? "column" : "row"} className="title-aligner" spacing={2}>
            <EdisonTypography title={getDaysSince()} variant="data2" />
            {newsItem.readMoreText && (
              <Button
                onClick={() => window.open(newsItem.readMoreUrl, "_blank", "noopener,noreferrer")}
                variant="contained"
                sx={{ maxWidth: "200px" }}
              >
                {newsItem.readMoreText}
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider flexItem />
        <Stack className="content-container">
          {!isLoading && individualNews?.content ? (
            <EdisonRichText
              initialValue={[]}
              label={""}
              useInternalState={false}
              readOnly={true}
              onChange={() => void 0}
              value={individualNews.content ? JSON.parse(individualNews.content) : undefined}
              placeholder=""
            />
          ) : (
            <div>{newsItem.description}</div>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default FullNews;
