import { Box, Grid, IconButton, Paper, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { FC, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Column from "./Column";
import DropZone from "./DropZone";
import EditableHeader from "./EditableHeader";
import "./row.scss";
import { addNewColumn, setRowTitle } from "../../store/slices/formDesignerSlice";
import { useAppDispatch } from "../../store/hooks";
import { ROW } from "enada-common";
import { useTranslation } from "react-i18next";

export interface RowProps {
  data: any;
  handleDrop: any;
  path: string;
  removeRow: any;
  removeColumn: any;
  removeComponent: any;
}

const style = {
  //   display: "flex",
  //   padding: "20px 0",
};
const Row: FC<RowProps> = ({
  data,
  handleDrop,
  path,
  removeRow,
  removeColumn,
  removeComponent
}) => {
  const { t } = useTranslation(["common"]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: ROW,
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column: any, currentPath: string) => {
    return (
      <Column
        key={column.id}
        data={column}
        handleDrop={handleDrop}
        path={currentPath}
        removeColumn={removeColumn}
        removeComponent={removeComponent}
      />
    );
  };

  return (
    <Box className={`base draggable row`} ref={ref} style={{ ...style, opacity }}>
      <Stack direction="row" alignItems={"center"}>
        <Paper sx={{ flex: 1, width: "70vw", overflowX: "auto" }}>
          <div className={`row_header`}>
            <Grid container justifyContent="flex-start">
              <EditableHeader
                placeholder={t("defaultRowTitlePlaceholder")}
                datatestid="row-editableheader"
                title={data.title ?? ""}
                onText={(title: string) => dispatch(setRowTitle({ title, path }))}
              />
            </Grid>
            <Grid item>
              {isCollapsed ? (
                <ExpandMoreIcon onClick={() => setIsCollapsed(false)} className={`pointer`} />
              ) : (
                <ExpandLessIcon onClick={() => setIsCollapsed(true)} className={`pointer`} />
              )}
            </Grid>
          </div>
          {!isCollapsed && (
            <div className="columns">
              {data.children.map((column: any, index: number) => {
                const currentPath = `${path}-${index}`;
                return (
                  <React.Fragment key={column.id}>
                    <DropZone
                      data={{
                        path: currentPath,
                        childrenCount: data.children.length
                      }}
                      onDrop={handleDrop}
                      className="horizontalDrag"
                    />
                    {renderColumn(column, currentPath)}
                  </React.Fragment>
                );
              })}
              <DropZone
                data={{
                  path: `${path}-${data.children.length}`,
                  childrenCount: data.children.length
                }}
                onDrop={handleDrop}
                className="horizontalDrag"
                isLast
              />

              {/* Add new column functionality - Might be needed in the future */}
              {/* <AddIcon
            style={{ cursor: "pointer", margin: "auto", marginRight: "10px" }}
            onClick={() => {
              // handleNewColumn(`${path}-${data.children.length}`, data);
              dispatch(addNewColumn(path));
            }}
          /> */}
            </div>
          )}
        </Paper>
        <IconButton onClick={() => removeRow(path)}>
          <DeleteOutlineOutlinedIcon className={`pointer`} fontSize="small" color="error" />
        </IconButton>
      </Stack>
    </Box>
  );
};
export default Row;
