import { Table, TableDataType, Field } from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SYSTEM_FIELD_ID_LIMIT } from "../../config/authConfig";
import {
  listTableSystemFields,
  scheduleTableSystemFields
} from "../../pages/admin/tableconfig/TableConfig";

import { RootState } from "../store";

export interface CalculatedFieldAlert {
  missingFields: number[];
  closed: boolean;
  calculatedField: Field;
}
const defaultTable: Table = {
  id: 0,
  name: "",
  displayName: "",
  description: "",
  configuration: {},
  isDeleted: false,
  fields: listTableSystemFields,
  dataType: TableDataType.List
};
export interface tablesState {
  value: number;
  tables: Array<Table>;
  individualTable: Table | Partial<Table>;
  lastModifiedUpdated: boolean;
  calculatedFieldAlerts: Record<number, CalculatedFieldAlert>;
}

const initialState: tablesState = {
  value: 0,
  tables: [],
  individualTable: defaultTable,
  lastModifiedUpdated: false,
  calculatedFieldAlerts: {}
};

export const tablesSlice = createSlice({
  name: "tables",
  initialState,
  // The `reducers` table lets us define reducers and generate associated actions
  reducers: {
    clearIndividualTable: state => {
      state.individualTable = defaultTable;
    },
    setIndividualTable: (state, action: PayloadAction<Table>) => {
      state.individualTable = action.payload;
    },
    updateIndividualTableProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Table }>
    ) => {
      state.individualTable = {
        ...state.individualTable,
        [action.payload.key]: action.payload.value
      };
    },
    updateIndividualTableType: (state, action: PayloadAction<TableDataType>) => {
      state.individualTable.dataType = action.payload;
      if (action.payload === TableDataType.Schedule) {
        state.individualTable.fields = [
          ...(state?.individualTable?.fields ?? []),
          ...scheduleTableSystemFields.filter(
            systemField =>
              !state?.individualTable?.fields?.map(x => x.fieldId).includes(systemField?.fieldId)
          )
        ];
      } else {
        state.individualTable.fields = state.individualTable.fields?.filter(
          field =>
            listTableSystemFields.map(x => x.fieldId).includes(field?.fieldId) ||
            (field?.fieldId as number) > SYSTEM_FIELD_ID_LIMIT
        );
      }
    },
    updateTableCalculatedAlert: (
      state,
      action: PayloadAction<{ id?: number; alert: CalculatedFieldAlert }>
    ) => {
      state.calculatedFieldAlerts[action.payload.id as number] = action.payload.alert;
    },
    removeTableCalculatedAlert: (state, action: PayloadAction<number>) => {
      //Used to delete an entry in the Record
      delete state.calculatedFieldAlerts[action.payload];
    }
  }
});

const inputSelectTables = (state: RootState) => state.tables;

export const selectIndividualTable = createSelector(
  [inputSelectTables],
  tables => tables.individualTable
);

export const selectTableCalculatedFieldAlerts = createSelector(
  [inputSelectTables],
  tables => tables.calculatedFieldAlerts
);

export const {
  clearIndividualTable,
  updateIndividualTableProperty,
  updateIndividualTableType,
  updateTableCalculatedAlert,
  removeTableCalculatedAlert,
  setIndividualTable
} = tablesSlice.actions;

export default tablesSlice.reducer;
