import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BackendNotification, Notification } from "enada-common";
import { RootState } from "../../store/store";

export interface NotificationState {
  backendNotificationList: BackendNotification[];
  currentNotification: Notification | null;
  backendNotificationsPanelOpen: boolean;
}

const initialState: NotificationState = {
  currentNotification: null,
  backendNotificationList: [],
  backendNotificationsPanelOpen: false
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCurrentNotification: (state, action: PayloadAction<Notification>) => {
      state.currentNotification = action.payload;
    },
    clearCurrentNotification: state => {
      state.currentNotification = null;
    },
    setBackendNotificationsPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.backendNotificationsPanelOpen = action.payload;
    }
  }
});

export const {
  clearCurrentNotification,
  setBackendNotificationsPanelOpen,
  setCurrentNotification
} = notificationSlice.actions;

const inputSelectNotification = (state: RootState) => state.notification;

export const selectCurrentNotification = createSelector(
  [inputSelectNotification],
  notification => notification.currentNotification
);

export const selectBackendNotifications = createSelector(
  [inputSelectNotification],
  notification => notification.backendNotificationList
);

export const selectBackendNotificationsPanelOpen = createSelector(
  [inputSelectNotification],
  notification => notification.backendNotificationsPanelOpen
);

export default notificationSlice.reducer;
