import { BaseUserFieldProps } from "enada-common";
import React, { FC, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export interface DynamicHeightWrapperProps<T extends BaseUserFieldProps> {
  labelToSearchFor: string;
  Component: FC<T>;
  props: T;
}
// This wrapper is used for components in tables that need to access the tables current  dataWrapping/ dynamicHeight value
// currently only used in UserMultiChoiceField and UserMultiLevelChoiceField
const DynamicHeightWrapper = <T extends BaseUserFieldProps>({
  labelToSearchFor,
  props,
  Component
}: DynamicHeightWrapperProps<T>) => {
  const [setLabel, setSetLabel] = useState<string>(labelToSearchFor ?? "test");
  const [autoHeight, setAutoHeight] = useLocalStorage(setLabel, false);

  useEffect(() => {
    if (labelToSearchFor === undefined) return;
    if (setLabel == labelToSearchFor) return;
    setSetLabel(labelToSearchFor);
  }, [labelToSearchFor]);

  return (
    <div className="dynamic-height-wrapper-root">
      <Component {...props} dynamicHeight={autoHeight} />
    </div>
  );
};

export default DynamicHeightWrapper;
