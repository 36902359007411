import {
  BaselineConfig,
  PendingRecordTableRow,
  PendingTableRowIdentifier,
  RecordTableConfiguration,
  RecordTableRow,
  RecordType,
  RecordsTableRowsPendingOperations,
  Table,
  TableRowPeriodicGrandTotal,
  reducerStatus
} from "enada-common";
import { RowOperationType } from "../../utils/parsing/parseTableChangeToBackend";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  convertMpp,
  getIndividualTable,
  getRecordTablesConfiguration,
  getRecordViewPendingTableRowValues,
  getRecordViewTablePeriodicGrandTotals,
  getRecordViewTableRowValues,
  postPendingRecordTableRowApproval,
  postRecordTablesConfiguration,
  postRecordViewTableRowValues
} from "../../services/APIService";
import { RootState } from "../store";
import { OperationType, RecordAuth } from "./recordSlice";
import { RecordDataFlags, clearMultipleBitwiseFlags, setBitwiseFlag } from "../../utils/bitwiseops";
import {
  getPopulationRowsAsync,
  getPopulationTableConfigsAsync,
  savePopulationRowsAsync
} from "./dataPopulationSlice";
import {
  mapPopulationToRecordRows,
  mapPopulationToRecordTableConfigs
} from "../../utils/mappers/populationToRecordMappers";
import { getUniqueIdFromRow, mergeTableOperationTypes } from "../../utils/tableHelpers";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { EdiTask } from "../../components/edirowgeneration/EdiRowGeneration";
import dayjs from "dayjs";

export enum SaveSlot {
  Slot1,
  Slot2
}
export const getHeadersFromAuth = (recordAuth: RecordAuth): Headers => {
  const headers = new Headers();
  headers.append("edison365-sessionid", "frontend");

  headers.append("edison365-permission-token", recordAuth.permissionToken);
  return headers;
};
export const RecordTableDataInitialised =
  RecordDataFlags.recordTableValues | RecordDataFlags.recordTableConfig;

type ConvertMppStatus = "loading" | "idle" | "fulfilled" | "rejected";
export type ResourceNameToIdMap = [name: string, resourceId: number, userId?: string];

export interface MppResourceMap {
  name: string;
  resourceId: number;
  ignoreResourceCalendar: boolean;
  userId?: string;
}

export interface RecordTableState {
  recordId?: number;
  status: reducerStatus;
  backendRecordRowValues: RecordTableRow[];
  periodicGrandTotals: TableRowPeriodicGrandTotal[];
  tables: Table[];
  activeSaveSlot: SaveSlot;
  tableOperations1: RowOperationType[];
  tableOperations2: RowOperationType[];
  hasTableConfigurationChanges: boolean;
  tableConfigs: RecordTableConfiguration[];
  // Represents all the rows awaiting approval from the record owner.
  pendingTableRows: PendingRecordTableRow[];
  // Represents any rows that have pending live updates, currently these live updates come from the signalr Approved callback
  // Once the update has been merged into the current row the entry should be removed.
  rowsToUpdate: RecordTableRow[];
  ediTableId: number;
  ediGeneratedRows: { add?: EdiTask[]; remove?: EdiTask[] };
  loadedData: number;
  //TODO: Move these to TableMapper
  convertMppStatus: ConvertMppStatus;
  convertedMppRows: RecordTableRow[];
  mppResponseType: string;
  mppEarliestStartDate: string;
  mppTableId?: number;
  tableRowsLoadedFromVersion: boolean;
}

export const initialState: RecordTableState = {
  status: "idle",
  backendRecordRowValues: [],
  periodicGrandTotals: [],
  tables: [],
  activeSaveSlot: SaveSlot.Slot1,
  tableOperations1: [],
  tableOperations2: [],
  hasTableConfigurationChanges: false,
  tableConfigs: [],
  pendingTableRows: [],
  rowsToUpdate: [],
  ediTableId: -1,
  ediGeneratedRows: {},
  loadedData: 0,
  convertMppStatus: "idle",
  convertedMppRows: [],
  mppResponseType: "",
  mppEarliestStartDate: "",
  mppTableId: 0,
  tableRowsLoadedFromVersion: false
};

export const getIndividualTableAsync = createAsyncThunk(
  "recordTable/getIndividualTable",
  async (tableId: number) => {
    const response: any = await getIndividualTable(tableId);
    return response.data;
  }
);
export const getRecordViewTableRowsAsync = createAsyncThunk(
  "recordTable/getRecordTableRowValues",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordViewTableRowValues(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      getHeadersFromAuth(recordAuth)
    );

    return response.data;
  }
);
export const saveRecordTableRowValuesAsync = createAsyncThunk(
  "recordTable/saveRecordTableRowValues",
  async (
    {
      recordAuth,
      recordType,
      activeSaveSlot
    }: {
      recordAuth: RecordAuth;
      recordType: RecordType;
      activeSaveSlot: SaveSlot;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;

    let activeTableOperations: RowOperationType[] = [];

    // Work out which table operation array to save using the activeSaveSlot variable
    // (which hasn't been updated by the swapActiveSaveSlot method called in useSaveProject)
    switch (activeSaveSlot) {
      case SaveSlot.Slot1:
        activeTableOperations = state.recordTable.tableOperations1;
        break;
      case SaveSlot.Slot2:
        activeTableOperations = state.recordTable.tableOperations2;
        break;
    }

    const response = await postRecordViewTableRowValues(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      activeTableOperations,
      getHeadersFromAuth(recordAuth)
    );
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const convertMppAsync = createAsyncThunk(
  "recordTable/convertMpp",
  async (data: { file: File; recordId: number; tableId: number }) => {
    return await convertMpp(data);
  }
);
export const getRecordViewPeriodicGrandTotalsAsync = createAsyncThunk(
  "recordTable/getRecordViewPeriodicGrandTotals",
  async ({
    recordAuth,
    recordType,
    tableId
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    tableId: number;
  }) => {
    const response = await getRecordViewTablePeriodicGrandTotals(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      tableId,
      getHeadersFromAuth(recordAuth)
    );

    return {
      tableId: tableId,
      totals: response.data
    } as TableRowPeriodicGrandTotal;
  }
);

export const saveRecordTablesConfigurationAsync = createAsyncThunk(
  "recordTable/saveRecordTablesConfigurationAsync",
  async ({
    recordAuth,
    recordType,
    tableConfigs
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    tableConfigs: RecordTableConfiguration[];
  }) => {
    return await postRecordTablesConfiguration(
      recordAuth.details.RecordId,
      recordType,
      tableConfigs,
      getHeadersFromAuth(recordAuth)
    );
  }
);
export const getRecordTablesConfigurationAsync = createAsyncThunk(
  "recordTable/getRecordTablesConfiguration",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response: any = await getRecordTablesConfiguration(
      recordAuth.details.RecordId,
      recordType,
      getHeadersFromAuth(recordAuth)
    );
    return response.data;
  }
);

export const getRecordViewPendingTableRowsAsync = createAsyncThunk(
  "recordTable/getRecordPendingTableRowValues",
  async ({ recordAuth, recordType }: { recordAuth: RecordAuth; recordType: RecordType }) => {
    const response = await getRecordViewPendingTableRowValues(
      recordAuth.details.RecordId,
      recordType,
      recordAuth.details.Views[0],
      getHeadersFromAuth(recordAuth)
    );

    return response.data;
  }
);
export const postRecordTableRowsApprovalAsync = createAsyncThunk(
  "recordTable/postRecordTableRowApprovalAsync",
  async ({
    recordAuth,
    recordType,
    operations
  }: {
    recordAuth: RecordAuth;
    recordType: RecordType;
    operations: RecordsTableRowsPendingOperations[];
  }) => {
    return await postPendingRecordTableRowApproval(
      recordAuth.details.RecordId,
      recordType,
      operations,
      getHeadersFromAuth(recordAuth)
    );
  }
);

const recordTableSlice = createSlice({
  name: "recordTable",
  initialState,
  reducers: {
    resetRecordTableSlice: () => initialState,
    addNewRows: (state, action: PayloadAction<{ rows: RecordTableRow[] }>) => {
      state.backendRecordRowValues.push(...action.payload.rows);
    },
    setRecordTableSliceRecordId: (state, action: PayloadAction<number>) => {
      state.recordId = action.payload;
    },
    swapActiveSaveSlot: state => {
      // Swap the active save slot
      switch (state.activeSaveSlot) {
        case SaveSlot.Slot1:
          state.activeSaveSlot = SaveSlot.Slot2;
          break;
        case SaveSlot.Slot2:
          state.activeSaveSlot = SaveSlot.Slot1;
          break;
      }
    },
    setTableOperations: (state, action: PayloadAction<{ tableOperations: RowOperationType[] }>) => {
      switch (state.activeSaveSlot) {
        case SaveSlot.Slot1:
          state.tableOperations1 = mergeTableOperationTypes(
            state.tableOperations1,
            action.payload.tableOperations
          );
          break;
        case SaveSlot.Slot2:
          state.tableOperations2 = mergeTableOperationTypes(
            state.tableOperations2,
            action.payload.tableOperations
          );
          break;
      }
    },
    saveConvertedMppRows: (state, action) => {
      state.convertedMppRows = state.convertedMppRows.map(row => ({
        ...row,
        tableRowFieldValues: row.tableRowFieldValues?.map(field => {
          if (field.fieldId != SystemFieldType.AssignedResource) {
            return field;
          }

          const value = !action.payload
            ? []
            : (field?.extendedValue?.value as any[])
                ?.map(mppResource => {
                  const newResource = getResource(action.payload as MppResourceMap[], mppResource);

                  if (!newResource.length) {
                    return;
                  }

                  if (newResource[0].userId) {
                    return {
                      resourceId: newResource[0].resourceId,
                      entityId: newResource[0].userId,
                      units: mppResource.units
                    };
                  } else {
                    return {
                      resourceId: newResource[0].resourceId,
                      units: mppResource.units
                    };
                  }
                })
                .filter(x => x !== undefined);

          return {
            ...field,
            extendedValue: {
              value: value
            }
          };
        })
      }));

      recordTableSlice.caseReducers.setTableOperations(state, {
        payload: {
          tableOperations: [
            {
              operationType: OperationType.AddOrPatch,
              tableRows: state.convertedMppRows
            }
          ] as RowOperationType[]
        },
        type: "recordTable/setTableOperations"
      });

      state.convertMppStatus = "idle";
    },
    setHasTableConfigurationChanges: (state, action: PayloadAction<boolean>) => {
      state.hasTableConfigurationChanges = action.payload;
    },
    updateTableConfiguration: (
      state,
      action: PayloadAction<{
        tableConfiguration: Pick<
          RecordTableConfiguration,
          | "tableId"
          | "recordId"
          | "schedulingDirection"
          | "taskTableCalendarId"
          | "baselines"
          | "startDate"
          | "endDate"
        >;
      }>
    ) => {
      if (!action.payload?.tableConfiguration?.tableId) return;

      const toUpdateIndex = state.tableConfigs.findIndex(
        c => c.tableId === action.payload.tableConfiguration.tableId
      );

      if (toUpdateIndex >= 0) {
        state.tableConfigs = [
          ...state.tableConfigs.filter(
            tc => tc.tableId !== action.payload.tableConfiguration.tableId
          ),
          {
            ...action.payload.tableConfiguration,
            recordId: state.recordId as number
          }
        ];
      } else {
        state.tableConfigs = [
          ...state.tableConfigs,
          {
            ...action.payload.tableConfiguration,
            recordId: state.recordId as number
          }
        ];
      }
      state.hasTableConfigurationChanges = true;
    },
    upsertBaseline: (
      state,
      action: PayloadAction<{
        recordId: number;
        tableId: number;
        baselineConfig: BaselineConfig;
      }>
    ) => {
      let configToUpdate = state.tableConfigs.find(
        tc => tc.recordId === action.payload.recordId && tc.tableId === action.payload.tableId
      );
      if (configToUpdate) {
        const baselineToUpdate = configToUpdate.baselines?.find(
          b => b.index === action.payload.baselineConfig.index
        );
        let updatedBaselines: BaselineConfig[] = [];
        if (!baselineToUpdate) {
          updatedBaselines = [...(configToUpdate.baselines ?? []), action.payload.baselineConfig];
        } else {
          updatedBaselines = [
            ...(configToUpdate.baselines?.filter(
              b => b.index !== action.payload.baselineConfig.index
            ) ?? []),
            action.payload.baselineConfig
          ];
        }
        configToUpdate = {
          ...configToUpdate,
          baselines: updatedBaselines
        };

        state.tableConfigs = [
          ...state.tableConfigs.filter(
            tc => tc.recordId !== action.payload.recordId && tc.tableId !== action.payload.tableId
          ),
          configToUpdate
        ];
      } else {
        // Add
        configToUpdate = {
          recordId: action.payload.recordId,
          tableId: action.payload.tableId,
          baselines: [action.payload.baselineConfig]
        };
        state.tableConfigs = [...state.tableConfigs, configToUpdate];
      }
      state.hasTableConfigurationChanges = true;
    },
    removeBaseline: (
      state,
      action: PayloadAction<{
        recordId: number;
        tableId: number;
        baselineConfig: BaselineConfig;
      }>
    ) => {
      let configToUpdate = state.tableConfigs.find(
        tc => tc.recordId === action.payload.recordId && tc.tableId === action.payload.tableId
      );
      if (!configToUpdate) {
        return;
      }
      const baselineIndexToRemove = configToUpdate.baselines?.findIndex(
        b => b.index === action.payload.baselineConfig.index
      );
      if (baselineIndexToRemove === undefined || baselineIndexToRemove < 0) {
        return;
      }
      configToUpdate = {
        ...configToUpdate,
        baselines:
          configToUpdate.baselines?.filter(b => b.index !== action.payload.baselineConfig.index) ??
          []
      };
      state.tableConfigs = [
        ...state.tableConfigs.filter(
          tc => tc.recordId !== action.payload.recordId && tc.tableId !== action.payload.tableId
        ),
        configToUpdate
      ];
      state.hasTableConfigurationChanges = true;
    },
    setEdiGeneratedRows: (
      state,
      action: PayloadAction<{ add?: EdiTask[]; remove?: EdiTask[] }>
    ) => {
      state.ediGeneratedRows = action.payload;
    },
    setEdiTableId: (state, action: PayloadAction<number>) => {
      if (state.ediTableId !== action.payload) {
        state.ediTableId = action.payload;
        state.ediGeneratedRows = {};
      }
    },
    addRowsToUpdate: (state, action: PayloadAction<RecordTableRow[]>) => {
      state.rowsToUpdate = [...state.rowsToUpdate, ...action.payload];
    },
    removeRowToUpdate: (state, action: PayloadAction<string>) => {
      state.rowsToUpdate = state.rowsToUpdate.filter(
        row => getUniqueIdFromRow(row) !== action.payload
      );
    },
    clearRecordTableDataLoadedBits: (state, action: PayloadAction<RecordDataFlags[]>) => {
      state.loadedData = clearMultipleBitwiseFlags(state.loadedData, action.payload);
    },
    setRecordVersionViewTableRows: (state, action: PayloadAction<RecordTableRow[]>) => {
      if (action.payload) {
        state.backendRecordRowValues = action.payload.filter(
          (row: RecordTableRow) => !row.recordId || row.recordId === state.recordId
        );
        state.tableRowsLoadedFromVersion = true;
      }
    },
    setRecordTables: (state, action: PayloadAction<Table[]>) => {
      state.tables = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getIndividualTableAsync.fulfilled, (state, action) => {
        if (!state.tables.some(table => table.id === action.payload?.id)) {
          state.tables.push(action.payload);
        } else {
          state.tables = state.tables.map(table =>
            table.id === action.payload.id ? action.payload : table
          );
        }
      })
      .addCase(getRecordViewTableRowsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getRecordViewTableRowsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.tableRowsLoadedFromVersion = false;
          state.backendRecordRowValues = action.payload.filter(
            (row: RecordTableRow) => !row.recordId || row.recordId === state.recordId
          );

          state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordTableValues);
          state.status = "idle";
        }
      })
      .addCase(saveRecordTableRowValuesAsync.fulfilled, state => {
        // Use activeSaveSlot to work out which table operation array to reset.
        // The table operations associated to the inactive save slot contains
        // all operations added while the save is being processed.
        switch (state.activeSaveSlot) {
          case SaveSlot.Slot1:
            state.tableOperations2 = [];
            break;
          case SaveSlot.Slot2:
            state.tableOperations1 = [];
            break;
        }
      })
      .addCase(getRecordViewPeriodicGrandTotalsAsync.fulfilled, (state, action) => {
        const existingTotal = state.periodicGrandTotals.find(
          t => t.tableId === action.payload.tableId
        );
        if (!existingTotal) {
          state.periodicGrandTotals.push(action.payload);
        } else {
          state.periodicGrandTotals = state.periodicGrandTotals.map(t =>
            t.tableId === action.payload.tableId ? action.payload : t
          );
        }
      })
      .addCase(getRecordTablesConfigurationAsync.fulfilled, (state, action) => {
        state.tableConfigs = action.payload;
        state.loadedData = setBitwiseFlag(state.loadedData, RecordDataFlags.recordTableConfig);
      })
      .addCase(getRecordViewPendingTableRowsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.pendingTableRows = action.payload;
        }
      })
      .addCase(postRecordTableRowsApprovalAsync.fulfilled, (state, action) => {
        const savedRows = action.meta.arg.operations.reduce(
          (acc, curr) => [...acc, ...(curr?.pendingTableRows ?? [])],

          [] as PendingTableRowIdentifier[]
        );

        // remove pending rows that have been reviewed
        state.pendingTableRows = state.pendingTableRows.filter(
          row =>
            !savedRows.some(
              saved =>
                saved.recordTableRowId === row.recordTableRowId &&
                saved.submittedEntityId === row.submittedEntityId
            )
        );
      })
      .addCase(convertMppAsync.pending, state => {
        state.convertMppStatus = "loading";
        state.mppResponseType = "running";
        state.mppEarliestStartDate = "";
        state.convertedMppRows = [];
      })
      .addCase(convertMppAsync.fulfilled, (state, action) => {
        const root = JSON.parse(action.payload.data);
        const convertedRows = root.tasks as RecordTableRow[];
        if (!convertedRows.length) return;

        const startDates = convertedRows.map(row => {
          return row.tableRowFieldValues?.find(field => field.fieldId === SystemFieldType.StartDate)
            ?.dateTimeValue;
        });

        if (!startDates.length) return;
        const earliestStartDate = dayjs(
          Math.min(...startDates.map(startDate => dayjs(startDate).valueOf()))
        );

        state.mppEarliestStartDate = earliestStartDate.utc().format();
        state.mppTableId = convertedRows.find(row => row.tableId)?.tableId;
        state.convertedMppRows = convertedRows;
        state.mppResponseType = "success";

        state.convertMppStatus = "fulfilled";
      })
      .addCase(convertMppAsync.rejected, state => {
        state.convertedMppRows = [];
        state.mppEarliestStartDate = "";
        state.mppResponseType = "failed";
        state.convertMppStatus = "rejected";
      })
      ///////////Workflow data population cases////
      // Temporarily moved the workflow data population table row data to the record slice
      // to use the recordsPage row management system(e.g. mergeRows process), this is needed atm
      // becasue the TableMapper (which is used by both the record page and the population) is currently coupled to
      // the recordSlice.
      //TODO: Move these cases back to the population slice once we have decoupled the table mapper from the recordSlice
      .addCase(getPopulationRowsAsync.fulfilled, (state, action) => {
        //WorkflowPopulation
        if (action.payload) {
          state.backendRecordRowValues = mapPopulationToRecordRows(action.payload);
        }
      })
      .addCase(getPopulationTableConfigsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.tableConfigs = mapPopulationToRecordTableConfigs(action.payload);
        }
      })
      .addCase(savePopulationRowsAsync.fulfilled, state => {
        switch (state.activeSaveSlot) {
          case SaveSlot.Slot1:
            state.tableOperations2 = [];
            break;
          case SaveSlot.Slot2:
            state.tableOperations1 = [];
            break;
        }
      });
  }
});

export const {
  resetRecordTableSlice,
  addNewRows,
  setRecordTableSliceRecordId,
  swapActiveSaveSlot,
  setTableOperations,
  saveConvertedMppRows,
  setHasTableConfigurationChanges,
  removeBaseline,
  upsertBaseline,
  updateTableConfiguration,
  setEdiTableId,
  setEdiGeneratedRows,
  addRowsToUpdate,
  removeRowToUpdate,
  clearRecordTableDataLoadedBits,
  setRecordVersionViewTableRows,
  setRecordTables
} = recordTableSlice.actions;

const inputSelectRecordTable = (state: RootState) => state.recordTable;

export const selectRecordTables = createSelector([inputSelectRecordTable], state => state.tables);

export const selectRecordTableLoadedData = (state: RootState) => state.recordTable.loadedData;

export const selectRecordTableStatus = (state: RootState) => state.recordTable.status;

export const selectRecordTableDataInitalised = createSelector(
  [inputSelectRecordTable],
  state => state.loadedData === RecordTableDataInitialised
);
export const selectRecordTableBackendRowValues = (state: RootState) =>
  state.recordTable.backendRecordRowValues;

export const selectTableOperations = createSelector([inputSelectRecordTable], state =>
  state.activeSaveSlot === SaveSlot.Slot1 ? state.tableOperations1 : state.tableOperations2
);
export const selectActiveSaveSlot = (state: RootState) => state.recordTable.activeSaveSlot;

export const selectConvertedMppRows = (state: RootState) => state.recordTable.convertedMppRows;

export const selectConvertMppStatus = (state: RootState) => state.recordTable.convertMppStatus;

export const selectMppResponseType = (state: RootState) => state.recordTable.mppResponseType;
export const selectMppTableId = (state: RootState) => state.recordTable.mppTableId;
export const selectMppEarliestStartDate = (state: RootState) =>
  state.recordTable.mppEarliestStartDate;

export const selectRecordTablePeriodicGrandTotals = (state: RootState) =>
  state.recordTable.periodicGrandTotals;

export const selectHasTableConfigurationChanges = (state: RootState) =>
  state.recordTable.hasTableConfigurationChanges;

export const selectTableConfigs = (state: RootState) => state.recordTable.tableConfigs;

export const selectEdiTableId = (state: RootState) => state.recordTable.ediTableId;

export const selectEdiGeneratedTableRows = (state: RootState) => state.recordTable.ediGeneratedRows;

export const selectRecordPendingRecordRows = (state: RootState) =>
  state.recordTable.pendingTableRows;

export const selectRecordRowsToUpdate = (state: RootState) => state.recordTable.rowsToUpdate;
export const selectRowsLoadedFromVersion = (state: RootState) =>
  state.recordTable.tableRowsLoadedFromVersion;

export default recordTableSlice.reducer;

function getResource(map: MppResourceMap[], a: any) {
  return map.filter((nameId: MppResourceMap) => {
    if (a?.name) {
      return (a.name as string).includes(nameId.name);
    }
  });
}
