import { RecordType } from "enada-common";
import React, { FC } from "react";
import Discover from "../../components/discover/Discover";
import {
  getIdeasQuickFilters,
  getIdeasQuickOrderOps
} from "../../components/discover/quickFilters";
const IdeasDiscover: FC<object> = () => {
  return (
    <Discover
      hideFilters={false}
      propQuery={{
        select:
          "id,favourited,workflowStage,recordType,startDate,endDate,displayName,shortDescription,imageUrl,owners,averageRating,percentDone,workflowStageChanged,created,approvedCount,rejectedCount,requiredApproversCount",
        expand: "owners",
        orderBy: "modified desc",
        filter: {
          recordType: { in: [RecordType.Ideas] }
        }
      }}
      recordType={RecordType.Ideas}
      getQuickFilterOverrides={getIdeasQuickFilters}
      getQuickOrderOverrides={getIdeasQuickOrderOps}
      hideViewSelector={true}
    />
  );
};
export default IdeasDiscover;
