import { TableRowSchedulingMode } from "./BaseRecord.model";
import { Category } from "./Category.model";

export interface Table {
  /** Format: date-time */
  modified?: string;
  modifiedBy?: string | null;
  /** Format: int32 */
  id?: number;
  name: string;
  displayName?: string | null;
  dataType: TableDataType;
  description?: string | null;
  periodicUnits?: TablePeriodicUnits;
  defaultView?: TableDefaultView;
  enableBoardView?: boolean;
  /** Format: int32 */
  boardColumnFieldId?: number | null;
  /** Format: int32 */
  boardSwimlaneFieldId?: number | null;
  /** Format: int32 */
  boardTitleFieldId?: number | null;
  isDeleted?: boolean;
  configuration?: { [key: string]: unknown } | null;
  fields?: TableField[] | null;
  rows?: TableRow[] | null;
  views?: TableView[];
  category?: Category;
}

export enum TableDataType {
  List = "List",
  Periodic = "Periodic",
  Schedule = "Schedule"
}

export enum TablePeriodicUnits {
  Weeks = "Weeks",
  Months = "Months",
  Quarters = "Quarters",
  Years = "Years"
}

export enum TableDefaultView {
  Default = "Default",
  Details = "Details"
}

export interface TableField {
  id?: number;
  tableId?: number;
  fieldId?: number;
  taskField?: TaskFields;
  personalPageState?: PersonalPageState;
  category?: Category;
  configuration?: { [key: string]: unknown } | null;
}

export interface TableRow {
  /** Format: int32 */
  id?: number;
  /** Format: int32 */
  tableId?: number;
  /** Format: int32 */
  referenceTableId?: number | null;
  type: string;
  /** Format: int32 */
  order?: number;
  configuration?: { [key: string]: unknown } | null;
}

export interface TableView {
  id?: number;
  tableId?: number;
  name: string;
  displayName?: string;
  description?: string;
  viewType?: TableViewType;
  taskViewType?: TaskTableViewType;
  taskFields?: TaskFields;
  boardColumnFieldId?: number;
  boardSwimlaneFieldId?: number;
  boardTitleFieldId?: number;
  configuration?: { [key: string]: unknown } | null;
  fields?: TableViewField[];
  category?: Category;
}
export interface TableViewField {
  id?: number;
  tableViewId?: number;
  fieldId?: number;
  configuration?: { [key: string]: unknown } | null;
}

export enum TableViewType {
  None = "None",
  Grid = "Grid",
  Board = "Board"
}

export enum TaskTableViewType {
  None = "None",
  Gantt = "Gantt",
  Scheduler = "Scheduler",
  Board = "Board",
  Timeline = "Timeline",
  All = "All"
}

export enum TaskFields {
  None = "None",
  Name = "Name",
  Note = "Note",
  RollUp = "Rollup",
  Constraint = "Constraint",
  Critical = "Critical",
  Deadline = "Deadline",
  Duration = "Duration",
  StartDate = "StartDate",
  EndDate = "EndDate",
  EarlyStartDate = "EarlyStartDate",
  EarlyEndDate = "EarlyEndDate",
  LateStartDate = "LateStartDate",
  LateEndDate = "LateEndDate",
  Effort = "Effort",
  EffortDriven = "EffortDriven",
  Inactive = "Inactive",
  ManuallyScheduled = "ManuallyScheduled",
  PercentDone = "PercentDone",
  SchedulingMode = "SchedulingMode",
  SlackUnit = "SlackUnit",
  TotalSlack = "TotalSlack",
  WBS = "WBS",
  Successors = "Successors",
  Predecessors = "Predecessors",
  AssignedResource = "AssignedResource"
}

export enum PersonalPageState {
  ReadOnly = "ReadOnly",
  Editable = "Editable",
  Hidden = "Hidden"
}

export enum SchedulingDirection {
  Forward = "Forward",
  Backward = "Backward"
}

export interface BaselineConfig {
  index: number;
  baselineSet: string;
}

export interface RecordTableConfiguration {
  id?: number;
  recordId: number;
  tableId: number;
  taskTableCalendarId?: number;
  schedulingDirection?: SchedulingDirection;
  baselines?: BaselineConfig[];
  startDate?: string;
  endDate?: string;
  schedulingMode?: TableRowSchedulingMode;
}
