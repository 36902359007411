import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { GraphQueryType, batchGetEntitiesById } from "../../services/graphService";
import { Group, User } from "@microsoft/microsoft-graph-types";

export interface EdisonPerson {
  displayName: string;
  id: string;
  email: string;
}
export interface PeopleState {
  people: Record<string, User>;
  groups: Record<string, Group>;
}
const initialState: PeopleState = {
  people: {},
  groups: {}
};

export const batchGetUsersByIdAsync = createAsyncThunk(
  "entities/getUsersById",
  async (ids: string[]) => {
    const response = await batchGetEntitiesById(ids, GraphQueryType.Users);
    return response;
  }
);
export const batchGetGroupsByIdAsync = createAsyncThunk(
  "entities/getGroupsById",
  async (ids: string[]) => {
    const response = await batchGetEntitiesById(ids, GraphQueryType.Groups);
    return response;
  }
);
const entitiesSlice = createSlice({
  name: "people",
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(batchGetUsersByIdAsync.fulfilled, (state, action) => {
        state.people = {
          ...state.people,
          ...(action.payload as Record<string, User>)
        };
      })
      .addCase(batchGetGroupsByIdAsync.fulfilled, (state, action) => {
        state.groups = {
          ...state.groups,
          ...(action.payload as Record<string, Group>)
        };
      })
});

export default entitiesSlice.reducer;

const inputSelector = (state: RootState) => state.entities;

export const selectPeople = createSelector([inputSelector], entities => entities.people);
export const selectGroups = createSelector([inputSelector], entities => entities.groups);
