import { apiConfig } from "config/authConfig";
import { Calendar } from "enada-common";
import { baseAPI } from "services/baseAPI";

export const calendarsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getCalendars: builder.query<Calendar[], void>({
      query: () => `${apiConfig.contentHubCalendarsEndpoint}`,
      transformResponse: (response: { data: Calendar[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Calendars" as const, id })), "Calendars"]
          : ["Calendars"]
    }),

    getCalendar: builder.query<Calendar, number>({
      query: id => `${apiConfig.contentHubCalendarsEndpoint}/${id}`,
      transformResponse: (response: { data: Calendar }) => response.data,
      providesTags: (result, error, id) => [{ type: "Calendars", id }]
    }),

    createCalendar: builder.mutation<Calendar, Partial<Calendar>>({
      query: body => ({
        url: apiConfig.contentHubCalendarsEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Calendar }) => response.data,
      invalidatesTags: ["Calendars"]
    }),

    updateCalendar: builder.mutation<Calendar, Partial<Calendar>>({
      query: body => ({
        url: apiConfig.contentHubCalendarsEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Calendar }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Calendars", id: body.id }]
    }),

    deleteCalendars: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCalendarsEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Calendars", id }))
    }),

    restoreCalendars: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCalendarsEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Calendars", id }))
    }),

    recycleCalendars: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCalendarsEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Calendars", id }))
    })
  })
});
