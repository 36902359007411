import { apiConfig } from "config/authConfig";
import { Field } from "enada-common";
import { baseAPI } from "services/baseAPI";

export const fieldsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getFields: builder.query<Field[], void>({
      query: () => `${apiConfig.contentHubFieldsEndpoint}`,
      transformResponse: (response: { data: Field[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Fields" as const, id })), "Fields"]
          : ["Fields"]
    }),

    getField: builder.query<Field, number>({
      query: id => `${apiConfig.contentHubFieldsEndpoint}/${id}`,
      transformResponse: (response: { data: Field }) => response.data,
      providesTags: (result, error, id) => [{ type: "Fields", id }]
    }),

    createField: builder.mutation<Field, Partial<Field>>({
      query: body => ({
        url: apiConfig.contentHubFieldsEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Field }) => response.data,
      invalidatesTags: ["Fields"]
    }),

    updateField: builder.mutation<Field, Partial<Field>>({
      query: body => ({
        url: apiConfig.contentHubFieldsEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Field }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Fields", id: body.id }]
    }),

    deleteFields: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFieldsEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Fields", id }))
    }),

    restoreFields: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFieldsEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Fields", id }))
    }),

    recycleFields: builder.mutation<void, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubFieldsEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Fields", id }))
    })
  })
});
