import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  dateDifferenceInDays,
  News as NewsModel,
  RollupTableRowModel,
  Category,
  NotificationLevel,
  useLocalisation
} from "enada-common";
import { newsRollupTableColumns } from "../../../config/rollupTableColumns";
import { useAppDispatch } from "../../../store/hooks";
import { Loading, RollupTable } from "enada-components";
import { selectRollupRowsFromData } from "../../../utils/rtkQuery";
import { sanitizeHtml } from "../../../utils/sanitizeHtml";
import {
  useDeleteNewsMutation,
  useGetNewsQuery,
  useRecycleNewsMutation,
  useRestoreNewsMutation,
  useUpdateOrderNewsMutation
} from "../../../services/api";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";

const News: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { formatDate } = useLocalisation();

  const selectNewsRollupRows = useMemo(
    () =>
      selectRollupRowsFromData<NewsModel[]>(data => {
        return data?.map(news => {
          let expiresIn: string | undefined = "Permanent";
          if (news.endDate) {
            const dateTime = formatDate(news.endDate, "LL LT");
            expiresIn = `${dateTime} (${dateDifferenceInDays(new Date(news.endDate))} days)`;
          }
          return {
            id: news.id ?? (0 as any),
            displayName: sanitizeHtml(news.title ?? "") as string,
            modified: news.modified,
            modifiedBy: news.modifiedBy,
            endDate: news?.endDate,
            type: expiresIn,
            isDeleted: news.isDeleted,
            order: news.order,
            category: news.category,
            disabled_e365: news.category === Category.Default
          };
        });
      }),
    [formatDate]
  );

  const { data: newsList = [], isLoading } = useGetNewsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      data: selectNewsRollupRows(result)?.sort(
        (n1, n2) => (Number(n1?.order) ?? 0) - (Number(n2?.order) ?? 0)
      )
    })
  });
  const [deleteNews] = useDeleteNewsMutation();
  const [recycleNews] = useRecycleNewsMutation();
  const [restoreNews] = useRestoreNewsMutation();
  const [updateOrderNews] = useUpdateOrderNewsMutation();

  const navigate = useNavigate();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteNews(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "newsArticleDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "newsArticleDeletionError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleNews(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "newsArticleRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "newsArticleRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreNews(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "newsArticleRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "newsArticleRestoringError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onRowOrderChange = async (activeItemId: string | number, overItemId?: string | number) => {
    const oldIndex = newsList.findIndex(rt => rt.id === activeItemId);
    const newIndex = newsList.findIndex(rt => rt.id === overItemId);
    const newArray = newsList.filter(n => n.id !== activeItemId);

    newArray.splice(newIndex, 0, newsList[oldIndex]);
    let order = 1;
    newArray.forEach(n => {
      if (!n.isDeleted) {
        n.order = order;
        order++;
      }
    });
    const postData = newArray.map(n => {
      if (!n.isDeleted) {
        n = {
          ...n,
          order: n.order
        };
      }
      return n;
    });
    const postDataObject = Object.fromEntries(postData.map(n => [n.id, n.order]));
    try {
      await updateOrderNews(postDataObject).unwrap();
    } catch {
      dispatch(
        setCurrentNotification({
          title: "newsArticleOrderingError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return isLoading ? (
    <Loading size={150} sx={{ marginTop: "100px" }} />
  ) : (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`editnews`, {
          state: {
            news: newsList.find(item => item.id?.toString() === id),
            title: "news"
          }
        });
      }}
      addAction={() => {
        navigate("newnews", {
          state: {
            title: "news"
          }
        });
      }}
      columns={newsRollupTableColumns(t, getNewsBadgeColor)}
      rows={newsList.filter(n => !n.isDeleted) as unknown as RollupTableRowModel[]}
      deletedRows={newsList.filter(n => n.isDeleted) as unknown as RollupTableRowModel[]}
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
      onRowOrderChange={onRowOrderChange}
      disableColumnSort={true}
      order="asc"
      orderBy="order"
      adminType="News"
    />
  );
};

export default News;

const getNewsBadgeColor = (value: NewsModel) => {
  if (!value.endDate) {
    return "#0D9588";
  }
  const days = dateDifferenceInDays(new Date(value.endDate));
  if (days !== undefined && days <= 3) {
    return "#950D0D";
  }
  return "#950D77";
};
