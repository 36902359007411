import React, { FC, ReactNode, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./scheduletabletoolbar.scss";
import { Popover, Stack } from "@mui/material";
import { Field, RecordTableConfiguration, Calendar } from "enada-common";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ScheduleTableSettingsModal from "./settingsModal/ScheduleTableSettingsModal";
import ScheduleTableCalendarModal from "./calendarModal/ScheduleTableCalendarModal";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { DomClassList } from "@bryntum/core-thin";
import { ProjectModel } from "@bryntum/gantt-thin";
import EdiIcon from "../../../../edison/icons/EdiIcon";
import TableRowDeletionDialog from "../../tableutils/deletiondialog/TableRowDeletionDialog";
import EdisonIconButton from "../../../../edison/iconbutton/EdisonIconButton";
import { TFunction } from "i18next";

export interface ToolbarProps {
  t: TFunction;
  gantt: any;
  project: ProjectModel;
  readOnly?: boolean;
  columns: Field[];
  showTimeline: boolean;
  setShowTimeline: (value: boolean) => void;
  taskAddCallback: () => void;
  toolbarModule?: ReactNode;
  onChange?: (event: any) => void;
  onImportMpp?: (fileList: FileList) => FileList | undefined;
  convertingMpp: boolean;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  tableConfig?: RecordTableConfiguration;
  calendars: Calendar[];
  titleModule?: ReactNode;
  onEdiClick?: () => void;
  mppResponseType: string;
  hasSelectedData: boolean;
  hideEdi?: boolean;
  hideTableConfigModal?: boolean;
  hideAddBtn?: boolean;
  label: string;
  onDataChange: (event: any) => Promise<void>;
  hasChanges: boolean;
}

const ScheduleTableToolbar: FC<ToolbarProps> = ({
  t,
  gantt,
  project,
  readOnly,
  showTimeline,
  setShowTimeline,
  toolbarModule,
  taskAddCallback,
  onChange,
  onImportMpp,
  convertingMpp,
  fullScreen,
  setFullScreen,
  tableConfig,
  calendars,
  titleModule,
  onEdiClick,
  mppResponseType,
  hasSelectedData,
  hideTableConfigModal,
  columns,
  label,
  hideEdi,
  onDataChange,
  hasChanges,
  hideAddBtn
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rowDeleteOpen, setRowDeleteOpen] = useState(false);
  const [anchorElCal, setAnchorElCal] = useState<HTMLButtonElement | null>(null);
  useEffect(() => {
    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (!gantt.current.instance) {
      return;
    }
    const clsList = new DomClassList(gantt.current.instance.cls);
    fullScreen ? clsList.add("gantt-fullscreen") : clsList.remove("gantt-fullscreen");
    gantt.current.instance.cls = clsList.value;
  }, [fullScreen]);

  return (
    <Stack direction="row" className="toolbar-root" spacing={1}>
      <div>{titleModule}</div>
      <Stack direction="row" spacing={1}>
        {/* <Stack direction="row" spacing={1}>
         <IconButton
          size="small"
          className="icon-button"
          disabled={!canUndo}
          onClick={() => gantt.current.instance.project.stm.undo()}>
          <UndoIcon />
        </IconButton>
        <IconButton
          size="small"
          className="icon-button"
          disabled={!canRedo}
          onClick={() => gantt.current.instance.project.stm.redo()}>
          <RedoIcon />
        </IconButton>
      </Stack> */}
        {toolbarModule}
        <EdisonIconButton
          testId=""
          variant="outlined"
          icon={<ZoomInIcon />}
          onClick={() => gantt.current.instance.zoomIn()}
        />
        {/* <Button
        size="small"
        variant="outlined"
        color="primary"
        className="icon-button"
        onClick={() => gantt.current.instance.zoomIn()}>
        <ZoomInIcon />
      </Button> */}
        <EdisonIconButton
          testId=""
          variant="outlined"
          icon={<ZoomOutIcon />}
          onClick={() => gantt.current.instance.zoomOut()}
        />
        <EdisonIconButton
          testId=""
          variant="outlined"
          icon={<ZoomOutMapIcon />}
          onClick={() =>
            gantt.current.instance.zoomToFit({
              leftMargin: 50,
              rightMargin: 50
            })
          }
        />
        <EdisonIconButton
          testId=""
          variant="outlined"
          icon={fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onClick={() => {
            setFullScreen(prev => !prev);
            window.scrollTo(0, 0);
            fullScreen ? document.exitFullscreen() : document.documentElement.requestFullscreen();
          }}
        />
        {!hideTableConfigModal && !readOnly && (
          <EdisonIconButton
            testId=""
            variant="outlined"
            icon={<CalendarMonthIcon color="primary" />}
            onClick={e => setAnchorElCal(e.currentTarget)}
            disabled={readOnly}
            isSelected={Boolean(anchorElCal)}
          />
        )}
        <Popover
          disableEnforceFocus={true}
          open={Boolean(anchorElCal)}
          onClose={() => setAnchorElCal(null)}
          anchorEl={anchorElCal}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <ScheduleTableCalendarModal
            project={project}
            calendars={calendars}
            tableConfig={tableConfig}
            t={t}
          />
        </Popover>
        {!hideTableConfigModal && (
          <EdisonIconButton
            testId=""
            variant="outlined"
            icon={<SettingsOutlinedIcon color="primary" />}
            onClick={e => setAnchorEl(e.currentTarget)}
            // disabled={readOnly}
            isSelected={Boolean(anchorEl)}
          />
        )}
        <Popover
          disableEnforceFocus={true}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <ScheduleTableSettingsModal
            gantt={gantt}
            taskStore={project.taskStore}
            project={project}
            showTimeline={showTimeline}
            setShowTimeline={setShowTimeline}
            onChange={onChange}
            onImportMpp={onImportMpp}
            convertingMpp={convertingMpp}
            mppResponseType={mppResponseType}
            tableConfig={tableConfig}
            t={t}
            columns={columns}
            label={label}
            onDataChange={onDataChange}
            hasChanges={hasChanges}
            readOnly={readOnly ?? false}
          />
        </Popover>
        {!readOnly && (
          <EdisonIconButton
            testId=""
            variant="outlined"
            icon={<DeleteOutlineIcon color={!hasSelectedData ? "disabled" : "primary"} />}
            disabled={!hasSelectedData}
            onClick={() => {
              setRowDeleteOpen(true);
            }}
          />
        )}
        {!hideEdi && (
          <EdisonIconButton
            testId=""
            variant="text"
            icon={<EdiIcon />}
            onClick={() => (onEdiClick ? onEdiClick() : undefined)}
          />
        )}
        {!readOnly && !hideAddBtn && (
          <EdisonIconButton
            testId=""
            variant="contained"
            icon={<AddIcon />}
            onClick={taskAddCallback}
          />
        )}
      </Stack>
      <TableRowDeletionDialog
        open={rowDeleteOpen}
        onDelete={() => {
          gantt.current.instance.taskStore.remove(gantt.current.instance.selectedRecords);
          setRowDeleteOpen(false);
        }}
        onClose={() => setRowDeleteOpen(false)}
        t={t}
      />
    </Stack>
  );
};

export default ScheduleTableToolbar;
