import { LicenseType } from "enada-common";

export enum LicenseBoltOn {
  None = 0,
  AdditionalInstances = 1 << 0,
  Auditing = 1 << 1,
  BringYourOwnDB = 1 << 2,
  ReducedRateLimit = 1 << 3,
  ReducedQuota = 1 << 4,
  AiFeatures = 1 << 5
}
export interface LicenseSettings {
  liteLicenses?: number;
  creatorLicenses?: number;
  licenseType?: LicenseType;
  licenseBoltOns?: LicenseBoltOn;
  autoAssignLicenses?: boolean;
  autoAssignLicenseType?: FrontendLicenseType;
  subscriptionEndDate?: string;
}
export enum FrontendLicenseType {
  None = "None",
  Full = "Full",
  Lite = "Lite",
  ReadOnly = "ReadOnly"
}
