import { BryntumGrid } from "@bryntum/grid-react-thin";
import { ColumnStore } from "@bryntum/grid-thin";
import { ProjectModel as TaskBoardProjectModel } from "@bryntum/taskboard-thin";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TableDataType, TablePeriodicUnits, UserPeriodicTableProps } from "enada-common";
import dayjs from "dayjs";
import * as dayOfYear from "dayjs/plugin/dayOfYear";
import * as isoWeek from "dayjs/plugin/isoWeek";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as quarterOfYear from "dayjs/plugin/quarterOfYear";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Grid, Divider, Stack, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import EdisonTypography from "../../../edison/typography/EdisonTypography";
import "./userperiodictable.scss";
import PeriodicTableToolbar from "./toolbar/PeriodicTableToolbar";
import { CalendarPickerView } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { generateBaseBryntumColumn } from "../tableutils/generateBaseBryntumColumn";
import hideColumns from "../tableutils/hideColumns";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Store } from "@bryntum/core-thin";
import { useToggleAutoHeight } from "../tableutils/useToggleAutoHeight";
import PeriodicTableContext from "./context/PeriodicTableContext";
import PeriodicTableGridConfig from "./config/PeriodicTableGridConfig";
import UserTableBoard from "../board/UserTableBoard";
import { ProjectModel, ProjectModelConfig } from "@bryntum/gantt-thin";

const UserPeriodicTable: FC<UserPeriodicTableProps> = ({
  periodicUnit,
  rows,
  grandTotals,
  detailFields,
  label,
  periodicStartDate,
  onEdiClick,
  onDataChanged,
  readOnly,
  hideEdi,
  toolbarModule,
  hiddenColumns,
  required,
  description,
  t,
  rowsToUpdate,
  refreshRequired,
  showBoardView,
  boardColumns,
  columnField,
  cardItems
}) => {
  const gridRef = useRef<BryntumGrid | null>(null);

  dayjs.extend(dayOfYear as any);
  dayjs.extend(isoWeek as any);
  dayjs.extend(advancedFormat as any);
  dayjs.extend(quarterOfYear as any);
  dayjs.extend(localizedFormat as any);

  const [startDate, setStartDate] = useState<Date>(periodicStartDate ?? new Date());
  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);
  const { toggleAutoHeight, autoHeight, averageRowHeight, maxHeight } = useToggleAutoHeight(
    detailFields,
    gridRef.current?.instance,
    label,
    (rows?.length ?? 0) - deletedRows.length,
    fullScreen
  );

  const project = useRef<Partial<ProjectModelConfig>>(
    new ProjectModel({
      onDataReady: () => setLoaded(true)
    }) as Partial<ProjectModelConfig>
  );

  const periodProperties: {
    period: string;
    numberOfPeriodColumns: number;
    displayNameFormat: string;
    datePickerFormat: string;
    datePickerViews?: CalendarPickerView[];
    fieldNameFormat?: string;
  } = useMemo(() => {
    switch (periodicUnit) {
      case TablePeriodicUnits.Weeks:
        return {
          period: "week",
          fieldNameFormat: "W-YYYY",
          displayNameFormat: "DD MMM",
          datePickerFormat: "LL",
          numberOfPeriodColumns: 16
        };
      case TablePeriodicUnits.Months:
        return {
          period: "month",
          fieldNameFormat: "M-YYYY",
          displayNameFormat: "MMM",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["month", "year"]
        };
      case TablePeriodicUnits.Quarters:
        return {
          period: "quarter",
          fieldNameFormat: "Q-YYYY",
          displayNameFormat: "[Q]Q YYYY",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["month", "year"]
        };
      case TablePeriodicUnits.Years:
        return {
          period: "year",
          fieldNameFormat: "YYYY",
          displayNameFormat: "YYYY",
          datePickerFormat: "YYYY",
          numberOfPeriodColumns: 12,
          datePickerViews: ["year"]
        };
      default:
        return {
          period: "day",
          displayNameFormat: "DD MMM",
          datePickerFormat: "LL",
          numberOfPeriodColumns: 16
        };
    }
  }, []);

  useEffect(() => {
    if (rows?.length) {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (refreshRequired) {
      const store = gridRef.current?.instance.store as Store;
      if (store.records.length) {
        store.removeAll();
      }
      loadData();
    }
  }, [refreshRequired]);

  useEffect(() => {
    const store = gridRef.current?.instance.store as Store;
    rowsToUpdate?.forEach(row => {
      const { rowId, ...rest } = row;
      const toUpdate = store.getById(rowId);
      toUpdate?.set({ ...rest });
    });
  }, [rowsToUpdate]);

  useEffect(() => {
    if (!grandTotals) return;
    Object.keys(grandTotals).forEach((totalId: any) => {
      const row = (gridRef.current?.instance.store as Store).find(
        (d: any) => d.dbId.toString() === totalId
      );
      if (row) {
        row.set("_grandTotal", grandTotals[totalId]);
      }
    });
  }, [grandTotals]);

  const getPeriodicColumns = useMemo(() => {
    const columns: any[] = [];
    for (let index = 0; index < periodProperties.numberOfPeriodColumns; index++) {
      const nextPeriod = dayjs(startDate).add(index, periodProperties.period as any);
      let fieldName = `${nextPeriod.format(periodProperties.fieldNameFormat)}`;

      if (periodicUnit === TablePeriodicUnits.Years) {
        fieldName = `${nextPeriod.year()}`;
      }
      columns.push({
        field: fieldName,
        width: `${100 / periodProperties.numberOfPeriodColumns}%`,
        type: "number",
        text: nextPeriod.format(periodProperties.displayNameFormat).toUpperCase(),
        hideable: false,
        searchable: false,
        filterable: false,
        sum: true,
        isPeriodic: true,
        region: "periodic",
        summaryRenderer: ({ sum }: any) => {
          return sum.toLocaleString();
        }
      });
    }

    return columns;
  }, [periodProperties, periodicUnit, startDate]);

  useEffect(() => {
    const columnStore = gridRef.current?.instance.columns as ColumnStore;
    const cols = columnStore.query((col: any) => col.data.isPeriodic);
    if (cols) {
      columnStore.remove(cols as any);
    }
    columnStore.add(getPeriodicColumns);
  }, [startDate, periodProperties, getPeriodicColumns]);

  const loadData = () => {
    const formatData = () => {
      if (!rows?.length) {
        return [];
      }
      return rows?.map(row => {
        const formattedRow: any = {
          id: row.id,
          dbId: row.dbId // temporarily assign the grand total a value
        };

        row.detailValues.forEach(value => {
          formattedRow[value.name] = value.value;
        });

        row.periodicValues.forEach(value => {
          formattedRow[
            periodicUnit !== TablePeriodicUnits.Years
              ? `${value.period}-${value.year}`
              : `${value.year}`
          ] = value.value;
        });
        return formattedRow;
      });
    };
    (gridRef.current?.instance.store as Store).add(formatData());
  };

  const setNextDate = (increment: boolean) => {
    setStartDate(
      dayjs(startDate)
        .add(
          increment
            ? periodProperties.numberOfPeriodColumns
            : -periodProperties.numberOfPeriodColumns,
          periodProperties.period as any
        )
        .toDate()
    );
  };

  const dateRangeDisplayName = () => {
    let displayDateFormat = dayjs(startDate).format(periodProperties.datePickerFormat);
    const endDateFormat = dayjs(startDate)
      .add(periodProperties.numberOfPeriodColumns - 1, periodProperties.period as any)
      .format(periodProperties.datePickerFormat);

    if (periodicUnit === TablePeriodicUnits.Quarters || periodicUnit === TablePeriodicUnits.Years) {
      displayDateFormat = `${displayDateFormat} - ${endDateFormat}`;
    }
    return displayDateFormat;
  };

  useEffect(() => {
    hideColumns(gridRef.current?.instance?.columns as ColumnStore, hiddenColumns);
  }, [hiddenColumns]);

  const getDetailColumns = () => {
    const columns = detailFields.map(column => {
      return generateBaseBryntumColumn(
        column,
        detailFields,
        { region: "details", isDetail: true },
        TableDataType.Periodic,
        `tableAutoHeight-${label.replace(" ", "-")}`
      );
    });

    return columns;
  };

  const columnsRef = useRef({
    autoAddField: true,
    data: [
      ...getDetailColumns(),
      ...getPeriodicColumns,
      {
        type: "template",
        text: "Total",
        field: "total",
        region: "totals",
        align: "right",
        sortable: false,
        fieldReadOnly: true,
        sum: (total: any, current: any) => {
          const columnStore = gridRef.current?.instance.columns as ColumnStore;
          const cols = columnStore.query((col: any) => col.data.isPeriodic);
          cols.forEach((col: any) => {
            if (current.data[col.field]) {
              total = total + current.data[col.field];
            }
          });

          return total;
        },
        hideable: false,
        editor: false,
        template: (cell: any) => {
          const columnStore = gridRef.current?.instance.columns as ColumnStore;
          const cols = columnStore.query((col: any) => col.data.isPeriodic);
          let total = 0;

          cols.forEach((col: any) => {
            if (cell.record[col.field]) {
              total = total + cell.record[col.field];
            }
          });
          return total.toLocaleString();
        },
        summaryRenderer: ({ sum }: any) => {
          return sum.toLocaleString();
        }
      },
      {
        type: "template",
        text: "Grand total",
        field: "_grandTotal",
        region: "totals",
        align: "right",
        sortable: false,
        fieldReadOnly: true,
        sum: true,
        hideable: false,
        editor: false,
        template: (cell: any) => cell.record._grandTotal.toLocaleString(),
        summaryRenderer: ({ sum }: any) => {
          return sum.toLocaleString();
        }
      }
    ]
  });

  useEffect(() => {
    // Once the grid is loaded, synchronize the tables autoheight with the one in local storage
    if (!loaded) return;
    if (!autoHeight) return;

    toggleAutoHeight(autoHeight);
  }, [autoHeight, loaded, toggleAutoHeight]);

  return (
    <Grid container className={`periodic-table-root ${fullScreen && "fullscreen"}`}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={`${fullScreen && "sticky-toolbar"}`}>
            <PeriodicTableToolbar
              t={t}
              toolbarModule={toolbarModule}
              fields={detailFields}
              grid={gridRef}
              columns={detailFields}
              readOnly={readOnly}
              hideEdi={hideEdi}
              onEdiClick={onEdiClick}
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              label={label}
              hasSelectedData={hasSelectedData}
              setDeletedRows={setDeletedRows}
              titleModule={
                <Stack direction="row" spacing={1}>
                  <EdisonTypography
                    title={label}
                    sx={{
                      color:
                        required && (rows === undefined || rows.length === 0) ? "red" : undefined
                    }}
                    variant={"data"}
                  />
                  {description && (
                    <Tooltip
                      title={description}
                      arrow
                      placement="right"
                      className="table-info-icon"
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                  {readOnly && (
                    <LockOutlinedIcon fontSize="small" className="table-readonly-icon" />
                  )}
                  {required && (
                    <Stack style={{ color: "red", alignItems: "center" }}>&nbsp; *</Stack>
                  )}
                </Stack>
              }
            />
          </div>
          <Divider />
          <div className={`${showBoardView ? "periodic-table-hidden" : "periodic-table-visible"}`}>
            <Grid container justifyContent="center">
              <IconButton onClick={() => setNextDate(false)}>
                <ArrowBack />
              </IconButton>
              <DatePicker
                value={startDate}
                shouldDisableDate={(day: Date) => {
                  if (periodicUnit === TablePeriodicUnits.Weeks && dayjs(day).day() !== 1) {
                    return true;
                  }
                  return false;
                }}
                views={periodProperties.datePickerViews}
                onChange={date => {
                  if (date) setStartDate(date);
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <Box ref={inputRef} sx={{ display: "flex", alignItems: "center" }}>
                    <EdisonTypography title={dateRangeDisplayName()} variant="fieldtitle" />
                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
              <IconButton onClick={() => setNextDate(true)}>
                <ArrowForward />
              </IconButton>
            </Grid>
            <div
              className={`${showBoardView ? "standard-table-visible" : "standard-table-hidden"}`}
            >
              {boardColumns?.length && (
                <PeriodicTableContext.Provider value={Boolean(readOnly)}>
                  <UserTableBoard
                    readOnly={readOnly}
                    project={project.current as TaskBoardProjectModel}
                    boardColumns={boardColumns}
                    tableColumns={getDetailColumns()}
                    columnField={columnField}
                    cardItems={cardItems}
                    source={TableDataType.Periodic}
                  />
                </PeriodicTableContext.Provider>
              )}
            </div>
            <div className="periodic-table-container enada-bryntum-grid">
              <PeriodicTableContext.Provider value={Boolean(readOnly)}>
                <BryntumGrid
                  ref={gridRef}
                  {...PeriodicTableGridConfig}
                  columns={columnsRef.current}
                  rowHeight={averageRowHeight}
                  maxHeight={maxHeight}
                  height={maxHeight}
                  readOnly={readOnly}
                  onSelectionChange={e => setHasSelectedData(e.selection.length > 0)}
                  onRenderRow={() => {
                    if (loaded) return;
                    setLoaded(true);
                  }}
                  onFocusOut={() =>
                    gridRef.current?.instance.deselectCells(gridRef.current?.instance.selectedCells)
                  }
                  onDataChange={(data: any) => {
                    let key = "";
                    if (data.changes) {
                      key = Object.entries(data.changes)[0][0];
                    }

                    if (key !== "_grandTotal") {
                      if (
                        onDataChanged &&
                        (data.action === "add" ||
                          data.action === "update" ||
                          data.action === "remove")
                      ) {
                        onDataChanged(data);
                      }

                      if (data.action === "update") {
                        const columnStore = gridRef.current?.instance.columns as ColumnStore;
                        const periodicCols = columnStore.query((col: any) => col.data.isPeriodic);
                        const periodicKeys = Object.entries<{
                          value: number;
                          oldValue: number;
                        }>(data.changes).filter(
                          entry => periodicCols.filter((c: any) => c.field === entry[0]).length
                        );
                        periodicKeys.forEach(entry => {
                          data.record._grandTotal =
                            entry[1].value -
                            (entry[1].oldValue ? entry[1].oldValue : 0) +
                            data.record._grandTotal;
                        });
                      }
                    }
                  }}
                />
              </PeriodicTableContext.Provider>
            </div>
          </div>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default UserPeriodicTable;
export { UserPeriodicTable };
