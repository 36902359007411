import { FC, Fragment, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordAuth,
  RecordSettings,
  selectCurrentVersion,
  selectEditor,
  setFlyoutId,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import { EdisonTypography } from "enada-components";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SupervisedUserCircleOutlined from "@mui/icons-material/SupervisedUserCircleOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import HasAccessRoles from "../../hasAccessRoles/HasAccessRoles";
import "./recordsettingsmenuitems.scss";
import { recycleRecordAsync } from "../../../store/slices/recordsSlice";
import { BaseRecord, NotificationLevel, RecordAccessRole } from "enada-common";
import { setCurrentNotification } from "store/slices/notificationSlice";
import { useGetUserQuery } from "services/api";

const settingsMenu = [
  {
    id: RecordSettings.editCardContent,
    image: <ImageOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: true
  },
  {
    id: RecordSettings.historyEvents,
    image: <HistoryOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: false
  },
  {
    id: RecordSettings.manageAccess,
    image: <SupervisedUserCircleOutlined />,
    requiredRole: RecordAccessRole.UpdatePermissions,
    requiresEditor: true
  },
  {
    id: RecordSettings.properties,
    image: <InfoOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: false
  },
  {
    id: RecordSettings.versionHistory,
    image: <AutoAwesomeMotionOutlinedIcon sx={{ transform: "rotateY(180deg) rotate(180deg)" }} />,
    requiredRole: RecordAccessRole.ReadVersion,
    requiresEditor: false
  },
  {
    id: RecordSettings.delete,
    image: <DeleteOutlineIcon fontSize="small" color="error" />,
    requiredRole: RecordAccessRole.Delete,
    requiresEditor: true
  }
];

export interface RecordSettingsMenuItemsProps {
  record: BaseRecord | null;
  recordAuth: RecordAuth | null;
  onClose: () => void;
}

const RecordSettingsMenuItems: FC<RecordSettingsMenuItemsProps> = ({
  record,
  recordAuth,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation("common");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { data: user } = useGetUserQuery();

  const projectEditor = useAppSelector(selectEditor);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const isEditor = user?.id === projectEditor?.oid;
  const navigate = useNavigate();

  const selectSetting = useCallback(
    async (id: string) => {
      if (id === RecordSettings.delete) {
        setDeleteDialogOpen(true);
      } else {
        dispatch(setIsFlyoutOpen(true));
        dispatch(setFlyoutId(id));
        onClose();
      }
    },
    [dispatch, onClose]
  );

  const handleDeleteConfirm = useCallback(async () => {
    const response = await dispatch(
      recycleRecordAsync({
        recordAuth,
        recordType: record?.recordType
      })
    );

    if (response.payload && !(response?.payload?.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: response.payload.title,
          message: response.payload.detail,
          level: NotificationLevel.Error
        })
      );
    } else {
      const navigateTo = record?.recordType?.toLowerCase() ?? "projects";

      navigate(navigateTo, {
        state: {
          title: t(navigateTo)
        }
      });
    }
    setDeleteDialogOpen(false);
  }, [dispatch, record, recordAuth, navigate, t]);

  const renderMenuItems = () => {
    return settingsMenu.map(settings => {
      const shouldRender = settings.requiresEditor ? isEditor : true;
      const isSettingsHidden =
        currentVersion &&
        [RecordSettings.delete, RecordSettings.historyEvents, RecordSettings.manageAccess].includes(
          settings.id
        );

      if (isSettingsHidden) return null;

      return (
        shouldRender && (
          <HasAccessRoles
            key={settings.id}
            roles={[settings.requiredRole]}
            noMatchElement={<MenuItem sx={{ display: "none" }} />}
          >
            <Fragment>
              {settings.id === RecordSettings.delete && <Divider />}
              <MenuItem
                onClick={() => selectSetting(settings.id)}
                data-testid={`record-menu-settings-${settings.id}`}
              >
                <ListItemIcon>{settings.image}</ListItemIcon>
                <ListItemText>
                  <EdisonTypography
                    variant="data"
                    title={t(
                      settings.id === RecordSettings.editCardContent && currentVersion
                        ? "viewCardContent"
                        : settings.id
                    )}
                    sx={{
                      color: settings.id === RecordSettings.delete ? "error.main" : "inherit"
                    }}
                  />
                </ListItemText>
              </MenuItem>
            </Fragment>
          </HasAccessRoles>
        )
      );
    });
  };

  return (
    <>
      {renderMenuItems()}
      <Dialog
        className="record-delete-modal-root"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{t("delete")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Alert severity="error" icon={false} className="top-delete-alert">
            {t("confirmProjectDeleteMessage")}
          </Alert>
          <Alert severity="error" className="bottom-delete-alert">
            <AlertTitle>{record?.displayName ?? record?.id?.toString()}</AlertTitle>
            {t("proceedMessage")}
          </Alert>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteConfirm}
            data-testid="record-delete-modal-root-delete"
          >
            {t("delete")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setDeleteDialogOpen(false)}
            data-testid="record-delete-modal-root-cancel"
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecordSettingsMenuItems;
