import { Tag } from "enada-common";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getRoles, getSkills } from "../../services/APIService";
import { RootState } from "../store";

export interface tagsState {
  skills: Tag[];
  roles: Tag[];
  status: "idle" | "loading" | "failed";
}

const initialState: tagsState = {
  skills: [],
  roles: [],
  status: "idle"
};

export const getRolesAsync: any = createAsyncThunk(
  "tags/getRoles",
  async (_, { rejectWithValue }) => {
    const response: any = await getRoles();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getSkillsAsync: any = createAsyncThunk(
  "resources/getSkills",
  async (_, { rejectWithValue }) => {
    const response: any = await getSkills();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRolesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getRolesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roles = action.payload;
      })
      .addCase(getSkillsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getSkillsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.skills = action.payload;
      });
  }
});

const inputSelectTags = (state: RootState) => state.tags;

export const selectSkills = createSelector([inputSelectTags], tags => tags.skills);

export const selectRoles = createSelector([inputSelectTags], tags => tags.roles);

export default tagsSlice.reducer;
