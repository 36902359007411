import { FC } from "react";
import { useTranslation } from "react-i18next";
import { EdisonPeoplePickerField, Loading } from "enada-components";
import {
  PersonaEntity,
  RecordPermission,
  RecordRole,
  TemplatePermission,
  PermissionType
} from "enada-common";
import { FormHelperText, Stack } from "@mui/material";
import { t } from "i18next";

export interface CreateRecordIdeatorsProps {
  recordIdeators?: RecordPermission[] | TemplatePermission[] | null | undefined;
  onChange: (updatedPermissions?: RecordPermission[]) => void;
  ideatorsModified: boolean;
  setIdeatorsModified: (value: boolean) => void;
  isLoading: boolean;
  templatePermissions?: TemplatePermission[];
}

const CreateRecordIdeators: FC<CreateRecordIdeatorsProps> = ({
  recordIdeators = [],
  onChange,
  ideatorsModified,
  setIdeatorsModified,
  isLoading,
  templatePermissions
}) => {
  const { t } = useTranslation(["common"]);

  if (!isLoading && !ideatorsModified) {
    // If the user has not modified the new records ideators,
    // then they should be set  by default to the ideators of the template
    onChange(
      templatePermissions
        ?.filter(perm => perm.role === RecordRole.Ideator)
        .map(entity => ({
          entityId: entity.entityId,
          permissionType: entity.permissionType,
          role: RecordRole.Ideator
        }))
    );
  }

  const value =
    recordIdeators?.map(entity => ({
      entityId: entity.entityId,
      type: PermissionType.User,
      userName: "",
      presence: ""
    })) ?? [];
  return isLoading ? (
    <Loading size={30} />
  ) : (
    <Stack>
      <EdisonPeoplePickerField
        multiple
        label={t("assignIdeators")}
        value={value}
        onChange={(entities: PersonaEntity[]) => {
          if (!ideatorsModified) {
            setIdeatorsModified(true);
          }
          onChange(
            entities.map(
              entity =>
                ({
                  entityId: entity.entityId,
                  permissionType: entity.type,
                  role: RecordRole.Ideator
                } as RecordPermission)
            )
          );
        }}
        useInternalState={false}
      />
      <FormHelperText>{t("pleaseAssignIdeatorMessage")}</FormHelperText>
    </Stack>
  );
};

export default CreateRecordIdeators;
