import { Edge, Node } from "reactflow";
import { defaultWorkflowConnection } from "./FrontendWorkflow.constants";
import { WorkFlowNode } from "./FrontendWorkflow.models";

const getValidConnection = (
  nodes: Node[],
  edges: Edge[],
  params: any
): { edge: Edge | null; errorMessage?: string } => {
  const source = nodes.find(node => node.id === params.source);
  if ((source?.data as WorkFlowNode).configuration.type === "Condition") {
    const branches = edges.filter(elem => elem.source === source?.id);
    if (branches.length === 0) {
      return {
        edge: { ...defaultWorkflowConnection, ...params, label: "true" }
      };
    }
    if (branches.length === 1) {
      if (branches[0].label === "true") {
        return {
          edge: {
            ...defaultWorkflowConnection,
            ...params,
            label: "false"
          }
        };
      }
      return {
        edge: {
          ...defaultWorkflowConnection,
          ...params,
          label: "true"
        }
      };
    }
    return { edge: null, errorMessage: "conditionBranchCountErrorMessage" };
  }

  return edges.filter(elem => elem.source === source?.id).length === 0
    ? { edge: { ...defaultWorkflowConnection, ...params } }
    : { edge: null, errorMessage: "stageBranchCountErrorMessage" };
};
export default getValidConnection;
