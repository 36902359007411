import {
  BaseWorkItem,
  RecordType,
  recordTypeConfiguration,
  WorkItemType,
  useLocalisation
} from "enada-common";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { EdisonTypography } from "enada-components";
import { BorderedTableCell } from "../../approvalstable/ApprovalsTable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./dashboarditemstable.scss";
import { StringHelper } from "@bryntum/core-thin";

export interface DashboardItemsTableProps {
  title: string;
  subtitle: string;
  items: BaseWorkItem[];
  overdue: boolean;
  onActionClick: (itemType: WorkItemType) => void;
}

const DashboardItemsTable: FC<DashboardItemsTableProps> = ({
  title,
  subtitle,
  items,
  overdue,
  onActionClick
}) => {
  const { t } = useTranslation(["common"]);

  const { formatDate } = useLocalisation();

  return (
    <Stack className="dashboard-items-table-root" spacing={1}>
      <Stack spacing={1} direction="row">
        <EdisonTypography title={title} variant="h1" sx={{ fontWeight: "700" }} />
        <div>{`(${items.length})`}</div>
      </Stack>
      <EdisonTypography title={subtitle} variant="data2" />
      <TableContainer sx={{ maxHeight: 280 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className="dashboard-header">
              <BorderedTableCell>{t("name")}</BorderedTableCell>
              <BorderedTableCell> {t("record")}</BorderedTableCell>
              <BorderedTableCell> {t("dueDate")}</BorderedTableCell>
              <BorderedTableCell> {t("type")}</BorderedTableCell>
              <BorderedTableCell> {t("action")}</BorderedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <BorderedTableCell className={`${overdue && "overdue"}`}>
                    {StringHelper.decodeHtml(item.itemName ?? "")}
                  </BorderedTableCell>
                  <BorderedTableCell className={`${overdue && "overdue"}`}>
                    <Stack direction={"row"} spacing={1}>
                      {recordTypeConfiguration(item.recordType as RecordType).icon}
                      <Box>{item.recordName ?? item.recordId}</Box>
                    </Stack>
                  </BorderedTableCell>
                  <BorderedTableCell className={`${overdue && "overdue"}`}>
                    {!item.dueDate ? "" : formatDate(item.dueDate)}
                  </BorderedTableCell>
                  <BorderedTableCell className={`${overdue && "overdue"}`}>
                    {item.itemType}
                  </BorderedTableCell>
                  {item.itemType && (
                    <BorderedTableCell>
                      <IconButton onClick={() => onActionClick(item.itemType as WorkItemType)}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </BorderedTableCell>
                  )}
                </TableRow>
              );
            })}
            {items.length === 0 && (
              <TableRow>
                <BorderedTableCell colSpan={5} align="center">
                  {t("noData")}
                </BorderedTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DashboardItemsTable;
