import React, { FC, ReactNode, useMemo, useState } from "react";
import {
  BaseRecord,
  CardField,
  Field,
  PersonaEntity,
  RecordFieldValue,
  RecordType,
  getAllRowsPath,
  recordTypeConfiguration
} from "enada-common";
import { Box, Grid, Rating, Stack, useTheme } from "@mui/material";
import {
  EdisonContentCard,
  EdisonLinearProgress,
  EdisonSkeletonText,
  EdisonTypography
} from "enada-components";
import { useTranslation } from "react-i18next";
import {
  getIndividualCard,
  getAccessToken,
  getRecordViewValues,
  getRequestMsGraph,
  postRecordUserLike,
  deleteRecordUserLike
} from "../../services/APIService";
import { getHeadersFromAuth } from "../../store/slices/recordSlice";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import "./recordcontentcard.scss";
import ComponentMapper from "../../utils/componentMapper";
import { BaseWorkflowStage } from "../../store/slices/workflowSlice";
import { selectRegion } from "../../store/slices/configSlice";
import { getImagePath } from "../../utils/imagePathManager";
import { selectAllFields } from "../../store/slices/fieldsSlice";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { addDays, isSameDay } from "../../utils/timeManipulation";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { parseISO } from "date-fns";
import colors from "./../../theme/styleVariables.module.scss";
import ChallengeContentCardFooterModule from "./footermodule/ChallengeContentCardFooterModule";
import { useGetCdnTokenQuery, useGetFieldsQuery } from "services/api";

export interface RecordContentCardInterface {
  stages: BaseWorkflowStage[];
  record: BaseRecord;
  setExpandedRecord: (expanded: number | undefined) => void;
  isExpanded: boolean;
  onRecordClick?: (record: BaseRecord) => void;
  isSelected?: boolean;
  hideFooterFeatures?: boolean;
}

const RecordContentCard: FC<RecordContentCardInterface> = ({
  stages,
  record,
  isExpanded,
  setExpandedRecord,
  onRecordClick,
  isSelected,
  hideFooterFeatures
}) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const themeName = useAppSelector(selectThemeName);
  const [recordLiked, setRecordLiked] = useState(record.favourited);
  const isDarkTheme = themeName === "dark";

  const currentStage = useMemo(
    () => stages.find(stage => stage.id === record.workflowStage),
    [stages, record]
  );

  dayjs.extend(relativeTime);
  dayjs.extend(utc);

  const region = useAppSelector(selectRegion);
  const [fieldValues, setFieldValues] = useState<RecordFieldValue[]>([]);
  const [rows, setRows] = useState<CardField[]>([]);
  const [loading, setLoading] = useState(false);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const { data: cdnToken } = useGetCdnTokenQuery();

  const allRowsPath: number[] = useMemo(() => getAllRowsPath(rows), [rows]);

  const getFieldValue = (field?: Field) => {
    if (!field) return;

    const fieldValue = fieldValues.find(value => value.fieldId === field.id);

    if (!fieldValue) return null;

    const possibleValues = (({
      extendedValue,
      decimalValue,
      stringValue,
      boolValue,
      dateTimeValue
    }) => ({
      extendedValue,
      decimalValue,
      stringValue,
      boolValue,
      dateTimeValue
    }))(fieldValue);

    const keyValue = Object.entries(possibleValues).find(
      ([_, value]) => value !== undefined && value !== ""
    ) as any;

    if (keyValue === undefined) return null;

    return keyValue[0] === "extendedValue" ? keyValue[1].value : keyValue[1];
  };

  const challengeImageBadge = useMemo(() => {
    if (record.recordType !== RecordType.Challenges) return;

    const recordConfig = recordTypeConfiguration(RecordType.Challenges);
    const today = new Date();
    const startDate = record.startDate ? parseISO(record.startDate) : null;
    const endDate = record.endDate ? parseISO(record.endDate) : null;

    const remainingStyles = {
      color: isDarkTheme ? recordConfig.secondaryColor : recordConfig.color,
      bgColor: isDarkTheme ? recordConfig.color : recordConfig.secondaryColor
    };

    if (startDate && today < startDate) {
      const daysUntilStart = Math.ceil(
        (startDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      );
      return {
        label: t("countStartsInDays", { count: daysUntilStart }),
        ...remainingStyles
      };
    }

    if (!startDate && !endDate) {
      return { label: t("infiniteDaysRemaining"), ...remainingStyles };
    }

    if (!endDate) {
      return { label: t("infiniteDaysRemaining"), ...remainingStyles };
    }

    const timeDifference = endDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysRemaining >= 0) {
      return { label: t("countDaysRemaining", { count: daysRemaining }), ...remainingStyles };
    }

    return {
      label: t("closed"),
      color: colors.ChipTextError,
      bgColor: colors.ChipBackgroundError
    };
  }, [isDarkTheme, record.endDate, record.recordType, record.startDate, t]);

  return (
    <Grid item className="record-content-card">
      <EdisonContentCard
        title={record.displayName ? (record.displayName as string) : ""}
        description={record.shortDescription ? (record.shortDescription as string) : ""}
        type={record.recordType}
        associatedRecords={
          record.recordType === RecordType.Programs ? `${record?.childRecordCount}` : null
        }
        cardImageSource={getImagePath(record.imageUrl, region, cdnToken)}
        t={t}
        cardPersonas={(record.owners ? record.owners : []) as PersonaEntity[]}
        onClick={() => onRecordClick?.(record)}
        HeaderModule={() => getHeaderModule(record.recordType)}
        FooterModule={getFooterModule(record, hideFooterFeatures)}
        imageBadge={challengeImageBadge?.label ?? currentStage?.displayName ?? record.workflowStage}
        imageBadgeColour={challengeImageBadge?.bgColor ?? currentStage?.configuration?.color}
        secondaryImageBadgeColour={challengeImageBadge?.color}
        chipIcon={
          challengeImageBadge ? (
            <TimerOutlinedIcon sx={{ color: challengeImageBadge?.color }} />
          ) : undefined
        }
        isExpanded={isExpanded}
        getGroupDetailsAsync={(groupId: string) => getRequestMsGraph(`groups/${groupId}`)}
        onExpandClick={async (expanded: boolean) => {
          setExpandedRecord(expanded ? record.id : undefined);
          setLoading(true);
          if (!expanded) return;
          if (!currentStage) return;
          const card = (await getIndividualCard(currentStage?.cardId)).data;
          setRows(card?.fields);

          const additionalHeaders = new Headers();

          additionalHeaders.append("edison365-sessionid", "frontend");
          const tokenResult = (
            await getAccessToken(record?.id as number, record.recordType, additionalHeaders)
          ).data;
          const projectAuth = {
            permissionToken: tokenResult.permissionToken,
            sessionId: "frontend",
            details: tokenResult.details
          };
          const values = (
            await getRecordViewValues(
              projectAuth.details.RecordId,
              record.recordType,
              projectAuth.details.Views[0],
              getHeadersFromAuth(projectAuth)
            )
          ).data;

          values && setFieldValues(values);
          setLoading(false);
        }}
        isCompact={false}
        isSelected={isSelected}
      >
        {rows && isExpanded && !loading ? (
          <Stack
            className="card-field-root"
            spacing={1}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {allRowsPath.map((rowPath: number) => (
              <Stack key={rowPath} direction="row" spacing={1} className="row-container">
                {rows
                  .filter(el => el.configuration.path === rowPath.toString())
                  .filter(({ fieldId }) => fieldValues.some(value => value.fieldId === fieldId))
                  .map(row => (
                    <Stack key={row.configuration.item_id}>
                      <ComponentMapper
                        component={fields.filter(field => field.id === row?.fieldId)?.[0]}
                        useReadOnlyWrapper={false}
                        readOnly={true}
                        useInternalState={true}
                        isIconOn={false}
                        value={getFieldValue(
                          fields.filter(field => field.id === row?.fieldId)?.[0]
                        )}
                      />
                    </Stack>
                  ))}
              </Stack>
            ))}
          </Stack>
        ) : (
          <EdisonSkeletonText rows={1} />
        )}
      </EdisonContentCard>
    </Grid>
  );

  async function handleRecordLike(hasLiked: number | null) {
    const additionalHeaders = new Headers();

    additionalHeaders.append("edison365-sessionid", "frontend");
    const tokenResult = (
      await getAccessToken(record?.id as number, record.recordType, additionalHeaders)
    ).data;
    const projectAuth = {
      permissionToken: tokenResult.permissionToken,
      sessionId: "frontend",
      details: tokenResult.details
    };

    setRecordLiked(hasLiked === 1);

    hasLiked
      ? await postRecordUserLike(
          record.id as number,
          record.recordType,
          getHeadersFromAuth(projectAuth)
        )
      : await deleteRecordUserLike(
          record.id as number,
          record.recordType,
          getHeadersFromAuth(projectAuth)
        );
  }

  function getHeaderModule(type: RecordType): ReactNode | undefined {
    switch (type) {
      case RecordType.Ideas:
        return (
          <Box display="flex" alignItems="center">
            <Rating value={record.averageRating} precision={0.5} readOnly />
            <Rating
              sx={theme => ({
                color: theme.palette.Like?.main,
                "&:hover": {
                  color: theme.palette.LikeHover?.main
                },
                marginLeft: 1
              })}
              max={1}
              value={recordLiked ? 1 : 0}
              onClick={event => {
                event.nativeEvent.stopImmediatePropagation();
                event.stopPropagation();
              }}
              onChange={async (event, newValue) => {
                handleRecordLike(newValue);
              }}
              icon={<FavoriteIcon fontSize="small" />}
              emptyIcon={<FavoriteBorderIcon fontSize="small" />}
            />
          </Box>
        );
      case RecordType.Challenges:
        return (
          <Box display="flex" alignItems="center">
            <Stack direction="row" alignItems="center">
              <LightbulbOutlinedIcon sx={{ color: theme.palette.text.secondary, height: "15px" }} />

              <EdisonTypography
                variant="h5"
                sx={{
                  color: theme.palette.text.secondary
                }}
                title={record.childRecordCount?.toString() ?? "0"}
              />
            </Stack>
            <Rating
              sx={theme => ({
                color: theme.palette.Like?.main,
                "&:hover": {
                  color: theme.palette.LikeHover?.main
                },
                marginLeft: 1
              })}
              max={1}
              value={recordLiked ? 1 : 0}
              onClick={event => {
                event.nativeEvent.stopImmediatePropagation();
                event.stopPropagation();
              }}
              onChange={async (event, newValue) => {
                handleRecordLike(newValue);
              }}
              icon={<FavoriteIcon fontSize="small" />}
              emptyIcon={<FavoriteBorderIcon fontSize="small" />}
            />
          </Box>
        );
      case RecordType.Programs: // fall through
      case RecordType.Projects:
        return <EdisonLinearProgress value={record.percentDone ?? 0} renderLabel={true} />;
      case RecordType.BusinessCase: {
        if (currentStage?.targetDuration === undefined) {
          return (
            <Stack direction="row" sx={{ color: "green" }}>
              <TimerOutlinedIcon className="bc-timer-icon" fontSize="small" />∞
            </Stack>
          );
        }

        const startDate = new Date(record?.workflowStageChanged ?? record?.created ?? "");
        const dueDate = addDays(startDate, currentStage.targetDuration as number);
        if (isSameDay(dueDate, new Date())) {
          return (
            <Stack direction="row" sx={{ color: "red" }}>
              <TimerOutlinedIcon className="bc-timer-icon" fontSize="small" />
              {t("today")}
            </Stack>
          );
        }
        return dueDate.getTime() < new Date().getTime() ? (
          <Stack direction="row" sx={{ color: "red" }}>
            <TimerOutlinedIcon className="bc-timer-icon" fontSize="small" />
            {dayjs(new Date()).to(dueDate, true)}
          </Stack>
        ) : (
          <Stack direction="row" sx={{ color: "green" }}>
            <TimerOutlinedIcon className="bc-timer-icon" fontSize="small" />
            {dayjs(dueDate).to(new Date(), true)}
          </Stack>
        );
      }
      default:
        return undefined;
    }
  }
};

const getFooterModule = (record: BaseRecord, hideFooterFeatures?: boolean) => {
  if (hideFooterFeatures) return;
  if (record.recordType === RecordType.Challenges) {
    return <ChallengeContentCardFooterModule record={record} />;
  }
  return;
};

export default React.memo(RecordContentCard);
