import { CDNToken } from "../../../../libs/common/src";
import { apiConfig } from "../config/authConfig";
import { getTenantAndInstanceFromURL } from "./urlHelpers";

export const getCdnPath = (region: string) => {
  // Example CDN Image Path "https://cdn-dev{region}.edison365.com/images";
  const cdnImagePath = apiConfig.cdnImagePath;

  if (region) {
    return cdnImagePath.replace("{region}", "-" + region);
  }

  return cdnImagePath.replace("{region}", "");
};

export const getImagePath = (
  imageUrl: string | null | undefined,
  region: string,
  cdnToken?: CDNToken
) => {
  if (!imageUrl) return;

  const updateImageUrl = (url: string, tenant: string, instance: string): string => {
    const isPublic = url.indexOf("public/") > -1;
    const regex = /^https?:\/\/[^/]+\/images\//;
    const regexTLDWithTenant = RegExp(`^https?:\\/\\/[^/]+\\/images\\/${tenant}\\/${instance}\\/`);
    const regexTLDWithInstanceAndTenant = RegExp(`^https?:\\/\\/[^/]+\\/images\\/${tenant}\\/`);


    // to preserve some legacy values we need to strip out the tenant,instance and hostname from the url.
    // These aren't present in all urls so we need to check for them first
    if (isPublic) {
      if (regex.test(url)) {
        url = url.replace(regex, "");
      }
    }
    else {
      if (regexTLDWithInstanceAndTenant.test(url)) {
        url = url.replace(regexTLDWithInstanceAndTenant, "");
      } else if (regexTLDWithTenant.test(url)) {
        url = url.replace(regexTLDWithTenant, "");
      }
      if (url.startsWith(`/${tenant}/`) || url.startsWith(`${tenant}/`)) {
        url = url.replace(new RegExp(`^\/?${tenant}\/?`), "");
      }
      if (url.startsWith(`/${instance}/`) || url.startsWith(`${instance}/`)) {
        url = url.replace(new RegExp(`^\/?${instance}\/?`), "");
      }
    }

    if (url.startsWith("/images/") || url.startsWith("images/")) {
      url = url.replace(/^\/?images\/?/, "");
    }

    const baseUrl = isPublic ? getCdnPath(region) : `${getCdnPath(region)}${tenant}/${instance}/`;
    return `${baseUrl}${url}`;
  }

  const appendToken = (imageUrl: string, cdnToken: CDNToken | undefined): string => {
    imageUrl = imageUrl.indexOf("?") > -1 ? imageUrl.split("?")[0] : imageUrl; // some old images have the token appended already - this is legacy

    let cdnTokenString = `${cdnToken?.instanceImagesToken}`;
    if (imageUrl.toLowerCase().indexOf("public/") > -1) {
      cdnTokenString = `${cdnToken?.publicImagesToken}`;
    }
    return `${imageUrl}${cdnTokenString}`;
  }

  const { tenant, instance } = getTenantAndInstanceFromURL();
  imageUrl = updateImageUrl(imageUrl, tenant, instance);
  imageUrl = appendToken(imageUrl, cdnToken);

  return imageUrl;
};


