import { Box, Stack } from "@mui/material";
import React from "react";
import UnauthorizedImageLight from "../../assets/UnauthorizedImage.png";
import UnauthorizedImageDarkMode from "../../assets/UnauthorizedImageDarkMode.png";
import E365Logo from "../../assets/edison365logo.png";
import E365LogoDark from "../../assets/edison365logodark.png";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import "./error.scss";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";

const AccessDenied = () => {
  const themeName = useAppSelector(selectThemeName);
  const unauthorizedImg = themeName === "dark" ? UnauthorizedImageDarkMode : UnauthorizedImageLight;
  const edison365Logo = themeName === "dark" ? E365LogoDark : E365Logo;
  const { t } = useTranslation(["common"]);

  return (
    <>
      <img className="e365-logo" src={edison365Logo} />
      <Stack
        className="error-page"
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "90vh" }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <img className="unauthorized-logo" src={unauthorizedImg} />

          <Box className="description">
            <EdisonTypography title={t("authorisedAccessOnly")} variant="h1" />
          </Box>
          <Box className="description">
            <EdisonTypography title={t("noAccessDescription")} variant="data" />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default AccessDenied;
