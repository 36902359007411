import { ApiError, NotificationLevel, Permission, PersonaEntity, RoleType } from "enada-common";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { inputSelectInstance, inputSelectTenant } from "../../../store/slices/userSlice";
import { ConfigLayout, Loading } from "enada-components";
import { useNavigate } from "react-router-dom";
import PickerWithDescription from "../../../components/pickerwithdescription/PickerWithDescription";
import { FormControlLabel, Stack, Switch } from "@mui/material";
import {
  useGetAIEnabledQuery,
  useGetTenantAdminsQuery,
  useGetEdisonAccessToInstanceQuery,
  useGetPermissionsByRoleQuery,
  useUpdateAIEnabledOnInstanceMutation,
  useUpdateEdisonAllowedOnInstanceMutation,
  useUpdatePermissionsMutation,
  useUpdateTenantAdminsMutation,
  useGetUserQuery
} from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

const getPermissionByType = (type: RoleType, userPermissions: Permission[]) => {
  return userPermissions.filter((permission: Permission) =>
    permission.roles?.some(role => role.value?.includes(type))
  );
};

const ManageUsers: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { data: tenants = [], isLoading: tenantsLoading } = useGetTenantAdminsQuery();
  const { data: edisonHasPermission, isLoading: edisionPermissionLoading } =
    useGetEdisonAccessToInstanceQuery();
  const { data: aiEnabled, isLoading: aiIsLoading } = useGetAIEnabledQuery();
  const { data: userPermissions = [], isLoading: userPermissionsLoading } =
    useGetPermissionsByRoleQuery([
      RoleType.InstanceAdmin,
      RoleType.InstanceExec,
      RoleType.InstanceResourceManager
    ]);

  const { data: currentUser } = useGetUserQuery();

  const [updateAIEnabledOnInstance] = useUpdateAIEnabledOnInstanceMutation();
  const [updateEdisonAllowedOnInstance] = useUpdateEdisonAllowedOnInstanceMutation();
  const [updatePermissions] = useUpdatePermissionsMutation();
  const [updateTenantAdmins] = useUpdateTenantAdminsMutation();

  const isLoading = useMemo(
    () => tenantsLoading || edisionPermissionLoading || aiIsLoading || userPermissionsLoading,
    [tenantsLoading, edisionPermissionLoading, aiIsLoading, userPermissionsLoading]
  );

  const tenant = useAppSelector(inputSelectTenant);
  const instance = useAppSelector(inputSelectInstance);

  const navigate = useNavigate();

  const [userRoles, setUserRoles] = useState<Permission[]>([]);

  const [tenantAdmins, setTenantAdmins] = useState<Permission[]>([]);
  const [tenantAdminsLoaded, setTenantAdminsLoaded] = useState(false);
  const [adminRoles, setAdminRoles] = useState<Permission[]>([]);
  const [adminRolesLoaded, setAdminRolesLoaded] = useState(false);
  const [execRoles, setExecRoles] = useState<Permission[]>([]);
  const [execRolesLoaded, setExecRolesLoaded] = useState(false);
  const [resourceManagerRoles, setResourceManagerRoles] = useState<Permission[]>([]);
  const [resourceManagerRolesLoaded, setResourceManagerRolesLoaded] = useState(false);
  const [typeToUpdate, setTypeToUpdate] = useState<RoleType>();
  const [allowedOnTenant, setAllowedOnTenant] = useState({
    ai: { original: false, current: false },
    edison: { original: false, current: false }
  });

  const allUserRoles = useMemo(
    () => [...adminRoles, ...execRoles, ...resourceManagerRoles],
    [adminRoles, execRoles, resourceManagerRoles]
  );

  const updateRolesByType = useCallback(() => {
    switch (typeToUpdate) {
      case RoleType.InstanceAdmin:
        setAdminRoles(getPermissionByType(RoleType.InstanceAdmin, userRoles));
        setAdminRolesLoaded(true);
        break;
      case RoleType.InstanceExec:
        setExecRoles(getPermissionByType(RoleType.InstanceExec, userRoles));
        setExecRolesLoaded(true);
        break;
      case RoleType.InstanceResourceManager:
        setResourceManagerRoles(getPermissionByType(RoleType.InstanceResourceManager, userRoles));
        setResourceManagerRolesLoaded(true);
        break;
      default:
        // Handle cases where no specific role type is set
        setAdminRoles(getPermissionByType(RoleType.InstanceAdmin, userRoles));
        setAdminRolesLoaded(true);
        setExecRoles(getPermissionByType(RoleType.InstanceExec, userRoles));
        setExecRolesLoaded(true);
        setResourceManagerRoles(getPermissionByType(RoleType.InstanceResourceManager, userRoles));
        setResourceManagerRolesLoaded(true);
        break;
    }
  }, [typeToUpdate, userRoles]);

  useEffect(() => {
    if (tenants.length > 0 && !tenantAdmins.length) {
      setTenantAdmins(tenants);
      setTenantAdminsLoaded(true);
    }

    if (userPermissions.length > 0 && !allUserRoles.length) {
      setUserRoles(userPermissions);
    }

    if (edisonHasPermission || aiEnabled) {
      setAllowedOnTenant(prev => ({
        ...prev,
        edison: edisonHasPermission
          ? { original: edisonHasPermission, current: edisonHasPermission }
          : prev.edison,
        ai: aiEnabled ? { original: aiEnabled, current: aiEnabled } : prev.ai
      }));
    }

    if (userRoles.length > 0) {
      updateRolesByType();
    }
  }, [
    aiEnabled,
    allUserRoles.length,
    edisonHasPermission,
    tenantAdmins.length,
    tenants,
    updateRolesByType,
    userPermissions,
    userRoles
  ]);

  const toggleAllowedOnTenant = (type: "ai" | "edison", value: boolean) => {
    setAllowedOnTenant(prev => ({
      ...prev,
      [type]: { ...prev[type], current: value }
    }));
  };

  const isTenantAdmin = tenants.some(admin => admin.oid === currentUser?.id);
  const isReadonly = false; //TODO- not all users should have access to edit who has access

  const getUpdatedUsersRolesByType = useCallback(
    (type: RoleType, personas: PersonaEntity[]): Permission[] => {
      const addedPermissions: Permission[] = personas.map(persona => {
        const found = userRoles.find(
          (permission: Permission) => permission.oid === persona.entityId
        );
        if (found) {
          const allReadyHasRole = found.roles?.some(role => role.value?.includes(type));
          if (allReadyHasRole) return found;
          const updatedPermission = { ...found, roles: [...(found.roles ?? []), { value: type }] };
          return updatedPermission;
        }
        const newPermission = {
          tenantId: tenant,
          instanceId: instance,
          type: persona.type as any,
          oid: persona.entityId,
          roles: [{ value: type }]
        };

        return newPermission;
      });

      const removedPermissions = userRoles
        .filter(
          (permission: Permission) => !personas.some(persona => persona.entityId === permission.oid)
        )
        .map((permission: Permission) => {
          const filteredPermissions = {
            ...permission,
            roles: permission.roles?.filter(role => !role.value?.includes(type))
          };
          return filteredPermissions;
        });
      setTypeToUpdate(type);
      return [...addedPermissions, ...removedPermissions];
    },
    [userRoles, tenant, instance]
  );

  const mapToPersonaObject = useCallback(
    (permissions: Permission[]): PersonaEntity[] =>
      permissions.map(
        (admin: any) =>
          ({
            entityId: admin.oid,
            type: admin.type
          } as PersonaEntity)
      ),
    []
  );

  const onChangeTenantAdmins = useCallback((personas: PersonaEntity[]) => {
    setTenantAdmins(
      personas.map(persona => ({
        oid: persona.entityId,
        type: persona.type
      }))
    );
  }, []);
  const mappedTenantAdmins = useMemo(
    () => mapToPersonaObject(tenantAdmins),
    [mapToPersonaObject, tenantAdmins]
  );

  const onChangeAdmins = useCallback(
    (personas: PersonaEntity[]) => {
      setUserRoles(getUpdatedUsersRolesByType(RoleType.InstanceAdmin, personas));
    },
    [isLoading, getUpdatedUsersRolesByType]
  );
  const mappedAdminRoles = useMemo(
    () => mapToPersonaObject(getPermissionByType(RoleType.InstanceAdmin, adminRoles)),
    [adminRoles, mapToPersonaObject]
  );

  const onChangeExecs = useCallback(
    (personas: PersonaEntity[]) => {
      setUserRoles(getUpdatedUsersRolesByType(RoleType.InstanceExec, personas));
    },
    [getUpdatedUsersRolesByType, isLoading]
  );
  const mappedExecRoles = useMemo(
    () => mapToPersonaObject(getPermissionByType(RoleType.InstanceExec, execRoles)),
    [mapToPersonaObject, execRoles]
  );

  const onChangeResourceManagers = useCallback(
    (personas: PersonaEntity[]) => {
      setUserRoles(getUpdatedUsersRolesByType(RoleType.InstanceResourceManager, personas));
    },
    [getUpdatedUsersRolesByType, isLoading]
  );
  const mappedResourceManagerRoles = useMemo(
    () =>
      mapToPersonaObject(
        getPermissionByType(RoleType.InstanceResourceManager, resourceManagerRoles)
      ),
    [mapToPersonaObject, resourceManagerRoles]
  );

  const onUpdatePermissions = async () => {
    try {
      const response = await updatePermissions(userRoles).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newPermissionCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "newPermissionError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateTenantAdmins = async () => {
    await updateTenantAdmins(tenantAdmins).unwrap();
  };

  const onSave = async () => {
    onUpdatePermissions();
    if (isTenantAdmin) onUpdateTenantAdmins();
    if (allowedOnTenant.edison.current !== allowedOnTenant.edison.original) {
      await updateEdisonAllowedOnInstance(allowedOnTenant.edison.current);
    }

    if (allowedOnTenant.ai.original !== allowedOnTenant.ai.current) {
      await updateAIEnabledOnInstance(allowedOnTenant.ai.current);
    }

    navigate("../");
  };
  return (
    <ConfigLayout
      t={(param: string) => {
        return param;
      }}
      title={t("specifyUsers")}
      onSaveCallback={onSave}
      onCancelCallback={() => navigate("../")}
      topPanelDirection="column"
      topPanelContent={
        isLoading ? (
          <Loading size={30} isFullPage />
        ) : (
          <>
            {tenantAdminsLoaded && (
              <PickerWithDescription
                description={t("manageUsersTenantAdminsMessage")}
                readOnly={isReadonly || !isTenantAdmin}
                disabled={isReadonly || !isTenantAdmin}
                multiple={true}
                label={t("tenantAdmins")}
                onChange={onChangeTenantAdmins}
                value={mappedTenantAdmins}
              />
            )}
            {adminRolesLoaded && (
              <PickerWithDescription
                description={t("manageUsersAdminsMessage")}
                disabled={isReadonly}
                multiple={true}
                label={t("admins")}
                onChange={onChangeAdmins}
                value={mappedAdminRoles}
              />
            )}
            {execRolesLoaded && (
              <PickerWithDescription
                description={t("manageUsersExecutivesMessage")}
                disabled={isReadonly}
                label={t("executives")}
                multiple={true}
                onChange={onChangeExecs}
                value={mappedExecRoles}
              />
            )}
            {resourceManagerRolesLoaded && (
              <PickerWithDescription
                description={t("manageUsersResourceManagersMessage")}
                disabled={isReadonly}
                multiple={true}
                label={t("resourceManagers")}
                onChange={onChangeResourceManagers}
                value={mappedResourceManagerRoles}
              />
            )}
            <Stack alignItems={"flex-start"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={allowedOnTenant.edison.current}
                    color="primary"
                    onChange={(_, checked) => toggleAllowedOnTenant("edison", checked)}
                  />
                }
                label={t("edisonAllowedOnTenantMessage")}
                labelPlacement="start"
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={allowedOnTenant.ai.current}
                    color="primary"
                    onChange={(_, checked) => toggleAllowedOnTenant("ai", checked)}
                  />
                }
                label={t("aiAllowedOnTenantMessage")}
                labelPlacement="start"
              />
            </Stack>
          </>
        )
      }
    />
  );
};

export default ManageUsers;
