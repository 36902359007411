import { Box, Popover, Stack } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserPeoplePickerProps, PersonaEntity, PermissionType } from "enada-common";
import "./userpeoplepickerfield.scss";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import { PeoplePicker } from "@microsoft/mgt-react";
import UserPeopleGroup from "../peoplegroup/UserPeopleGroup";
import { ClickAwayAndTabListener } from "../../edison/clickAndTabAwayListener/ClickAwayAndTabListener";

const UserPeoplePickerField: FC<UserPeoplePickerProps> = ({
  readOnly,
  label,
  multiple,
  maxAvatars = 6,
  allowGroups,
  value,
  onChange,
  disabled,
  isInTable,
  setReadOnly,
  dataTestidValue
}) => {
  const popOverAnchor = useRef();
  const [internalState, setInternalState] = useState<PersonaEntity[] | null | undefined>(
    value && value.length > 0 ? value : undefined
  );
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  useEffect(() => {
    if (!isInTable) return;
    if (readOnly) return;
    setAnchorEl(popOverAnchor.current);
    setOpen(Boolean(popOverAnchor));
  }, [readOnly, isInTable]);

  const handleSelectionChanged = (e: any) => {
    const personas = e.detail.map((p: any) => {
      return {
        entityId: p.id,
        type: Object.prototype.hasOwnProperty.call(p, "groupTypes")
          ? PermissionType.Group
          : PermissionType.User
      } as PersonaEntity;
    });

    personas && personas.length > 0 ? setInternalState(personas) : setInternalState(null);

    if (!onChange || isInTable) return;

    personas && personas.length > 0 ? onChange(personas) : onChange(null);
  };

  // The memoization will only work if the initial values of the field are loaded before the component itself is loaded
  // People picker needs seperate collections for users and groups
  const memoedPeople = useMemo(
    () =>
      value
        ?.filter(
          persona =>
            persona.entityId !== undefined &&
            [PermissionType.User, "0"].includes(persona.type.toString())
        )
        .map(persona => {
          return persona.entityId;
        }),
    [open]
  );

  const memoedGroups = useMemo(
    () =>
      value
        ?.filter(persona => persona.entityId !== undefined && persona.type === PermissionType.Group)
        .map(persona => {
          return persona.entityId;
        }),
    [open]
  );

  const Picker = useCallback(
    () => (
      <PeoplePicker
        data-testid={dataTestidValue}
        type={allowGroups ? "any" : "person"}
        userType={"user"}
        selectionChanged={handleSelectionChanged}
        selectionMode={multiple ? "multiple" : "single"}
        showMax={maxAvatars}
        disabled={disabled || readOnly}
        defaultSelectedUserIds={memoedPeople?.length ? memoedPeople : []}
        defaultSelectedGroupIds={memoedGroups?.length ? memoedGroups : []}
      />
    ),
    [open]
  );

  const IsInTable = (
    <Box
      className={`edison-people-picker-root in-table`}
      ref={popOverAnchor}
      onClick={() => {
        if (readOnly) return;
        if (open) return;
        setAnchorEl(popOverAnchor.current);
        setOpen(true);
      }}
    >
      {internalState && (
        <UserPeopleGroup value={internalState} expandGroups avatarSize={"auto"} maxAvatars={3} />
      )}
      {anchorEl && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={() => {
            setAnchorEl(null);
            setOpen(false);
            if (!setReadOnly) return;
            setReadOnly(true);

            if (!onChange) return;

            internalState && internalState.length > 0 ? onChange(internalState) : onChange(null);
          }}
        >
          <Box padding="1em">
            <Picker />
          </Box>
        </Popover>
      )}
    </Box>
  );

  const IsNotInTable = open ? (
    <ClickAwayAndTabListener
      onClickAway={() => {
        setOpen?.(false);
        setReadOnly?.(true);
      }}
    >
      <Box className={`edison-people-picker-root`}>
        <EdisonTypography title={label} variant="fieldtitle" />
        <Picker />
      </Box>
    </ClickAwayAndTabListener>
  ) : (
    <Stack
      className={`edison-people-picker-root readonly-not-in-table `}
      onClick={() => {
        if (!disabled && !readOnly) {
          if (open) return;
          setOpen(true);
        }
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          if (!disabled && !readOnly) {
            if (open) return;
            setOpen(true);
          }
        }
      }}
    >
      <Stack direction="row">
        <EdisonTypography title={label} variant="fieldtitle" />
      </Stack>
      <UserPeopleGroup
        value={internalState ? internalState : []}
        expandGroups
        avatarSize={"large"}
        maxAvatars={3}
      />
    </Stack>
  );

  return isInTable ? IsInTable : IsNotInTable;
};

export default UserPeoplePickerField;
export { UserPeoplePickerField };
