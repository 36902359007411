import { RecordAccessRole } from "enada-common";
import { useAppSelector } from "../../store/hooks";
import { selectUserAccessRoles } from "../../store/slices/recordSlice";

const useHasRecordAccessRole = () => {
  const userRoles = useAppSelector(selectUserAccessRoles);
  const userRolesValues = userRoles?.map((role: RecordAccessRole) => role);

  const hasRecordAccessRole = (roles: RecordAccessRole[]) =>
    roles.some(r => userRolesValues?.includes(r));

  return { hasRecordAccessRole };
};

export default useHasRecordAccessRole;
