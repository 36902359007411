import { nanoid } from "nanoid";
import { COLUMN, COMPONENT, ROW } from "../constants/form.constant";
import { ItemDNDType } from "../models/frontend/ItemDNDType.model";
import { CardConfigurationField, CardField } from "../models/backend/Card.model";

export const splitPath = (fullPath: string): Array<string> => fullPath.split("-");

export const moveDNDObjectInArray = (
  array: any[],
  dragIndex: number,
  hoverIndex: number
): any[] => {
  const dragExpression = array[dragIndex];
  const expressionCopy = array.slice();
  expressionCopy.splice(dragIndex, 1);
  expressionCopy.splice(hoverIndex, 0, dragExpression);
  return expressionCopy;
};

// a little function to help us with reordering the result
export const reorder = (
  list: Array<ItemDNDType>,
  startIndex: number,
  endIndex: number
): Array<ItemDNDType> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed); // inserting task in new index

  return result;
};

export const remove = (arr: Array<ItemDNDType>, index: number): Array<ItemDNDType> => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // part of the array after the specified index
  ...arr.slice(index + 1)
];

export const insert = (
  arr: Array<ItemDNDType>,
  index: number,
  newItem: ItemDNDType
): Array<ItemDNDType> => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

export const reorderChildren = (
  children: Array<ItemDNDType>,
  splitDropZonePath: string,
  splitItemPath: string
): Array<ItemDNDType> => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: reorderChildren(
      nodeChildren.children || [],
      splitDropZoneChildrenPath,
      splitItemChildrenPath
    )
  };

  return updatedChildren;
};

export const removeChildFromChildren = (
  children: ItemDNDType[],
  splitItemPath: string
): Array<ItemDNDType> => {
  if (splitItemPath.length === 1) {
    const itemIndex = Number(splitItemPath[0]);
    return remove(children, itemIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitItemPath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: removeChildFromChildren(nodeChildren.children || [], splitItemChildrenPath)
  };

  return updatedChildren;
};

export const addChildToChildren = (
  children: ItemDNDType[],
  splitDropZonePath: string,
  item: ItemDNDType
): Array<ItemDNDType> => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(children, dropZoneIndex, item);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  // Update the specific node's children
  const splitItemChildrenPath = splitDropZonePath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(nodeChildren.children || [], splitItemChildrenPath, item)
  };

  return updatedChildren;
};

export const handleMoveWithinParent = (
  layout: any,
  splitDropZonePath: string,
  splitItemPath: string
): any => {
  return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddColumDataToRow = (layout: any): any => {
  const layoutCopy = [...layout];
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: nanoid(),
    children: []
  };

  return layoutCopy.map(row => {
    if (!row.children.length) {
      row.children = [COLUMN_STRUCTURE];
    }
    return row;
  });
};

export const addEmptyColumn = (layout: any, currentPath: string): any => {
  const tempLayout = layout;
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: nanoid(),
    children: []
  };

  const splitItemPath = currentPath.split("-");
  tempLayout[splitItemPath[0]].children = [...layout[splitItemPath[0]].children, COLUMN_STRUCTURE];

  return tempLayout;
};

// export const addEmptyRowAndEmptyColumn = (layout: any): any => {
//   const COLUMN_STRUCTURE = {
//     type: COLUMN,
//     id: nanoid(),
//     children: [],
//   };

//   const ROW_STRUCTURE = {
//     type: ROW,
//     id: nanoid(),
//   };
//   const newLayoutStructure = {
//     ...ROW_STRUCTURE,
//     children: [COLUMN_STRUCTURE],
//   };
//   return [...layout, newLayoutStructure];
// };

export const handleRemoveColumn = (layout: any[], path: string): any => {
  const copy = layout.slice();
  const paths = path.split("-");
  const rowIndex = Number(paths[0]);

  copy[rowIndex].children = copy[rowIndex].children.filter(
    (_: any, index: number) => index.toString() !== paths[1]
  );
  return copy;
};
export const handleMoveToDifferentParent = (
  layout: any,
  splitDropZonePath: string,
  splitItemPath: string,
  item: ItemDNDType
): any => {
  let newLayoutStructure;
  const COLUMN_STRUCTURE = {
    type: COLUMN,
    id: nanoid(),
    children: [item]
  };

  const ROW_STRUCTURE = {
    type: ROW,
    id: nanoid()
  };
  switch (splitDropZonePath.length) {
    case 1: {
      // moving column outside into new row made on the fly
      if (item.type === COLUMN) {
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [item]
        };
      } else {
        // moving component outside into new row made on the fly
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [COLUMN_STRUCTURE]
        };
      }
      break;
    }
    case 2: {
      // moving component outside into a row which creates column
      if (item.type === COMPONENT) {
        newLayoutStructure = COLUMN_STRUCTURE;
      } else {
        // moving column into existing row
        newLayoutStructure = item;
      }

      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }
  let updatedLayout = layout;
  updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
  updatedLayout = handleAddColumDataToRow(updatedLayout);
  updatedLayout = addChildToChildren(updatedLayout, splitDropZonePath, newLayoutStructure);
  return updatedLayout;
};

export const handleMoveSidebarComponentIntoParent = (
  layout: any,
  splitDropZonePath: string,
  item: ItemDNDType
): any => {
  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 1: {
      newLayoutStructure = {
        type: ROW,
        id: nanoid(),
        children: [{ type: COLUMN, id: nanoid(), children: [item] }]
      };
      break;
    }
    case 2: {
      newLayoutStructure = {
        type: COLUMN,
        id: nanoid(),
        children: [item]
      };
      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }

  return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};

export const handleRemoveItemFromLayout = (
  layout: any,
  splitItemPath: string
): Array<ItemDNDType> => {
  return removeChildFromChildren(layout, splitItemPath);
};

export const removeEmptyRows = (rows: ItemDNDType[]): ItemDNDType[] => {
  return rows.filter(row => row.children && row.children.length !== 0);
};

export const sortRowsAndSetPath = (rows: CardField[]): CardField[] => {
  let sortedFields: any = [...rows];
  let finalArray: CardField[] = [];
  let newPath: number = 0;
  let prevPath: string = "";
  //Order fields by path row (that is the id of the row)
  sortedFields.sort((a, b) => parseInt(a.configuration.path) - parseInt(b.configuration.path));
  sortedFields.forEach(item => {
    if (item.configuration.path != prevPath) {
      const fieldsSameRow = rows.filter(row => row.configuration.path == item.configuration.path);
      if (fieldsSameRow.length == 2) {
        // Row with two elements: we change the path, the item path for every element, and put the elements in the correct row order.
        let rowObjA;
        let rowObjB;
        fieldsSameRow.forEach(el => {
          if (el.configuration.item_path == `${item.configuration.path}-0`) {
            rowObjA = {
              ...el,
              configuration: {
                ...el.configuration,
                path: newPath.toString(),
                item_path: `${newPath.toString()}-0`
              }
            };
          } else {
            rowObjB = {
              ...el,
              configuration: {
                ...el.configuration,
                path: newPath.toString(),
                item_path: `${newPath.toString()}-1`
              }
            };
          }
        });
        finalArray.push(rowObjA, rowObjB);
      } else {
        // Row with a single element: we change the path, the item path and return the row.
        let rowObj = {
          ...item,
          configuration: {
            ...item.configuration,
            path: newPath.toString(),
            item_path: `${newPath.toString()}-0`
          }
        };
        finalArray.push(rowObj);
      }

      prevPath = item.configuration.path;
      newPath++;
    }
  });
  return finalArray;
};

export const incrementRowsPath = (rows: CardField[], increment: number) => {
  return rows.map((item: CardField) => {
    const updatedRootPath = parseInt(item.configuration.path as string) + increment;
    const itemIndex = item.configuration.item_path!.split("-")?.[1];
    return {
      ...item,
      configuration: {
        ...item.configuration,
        path: updatedRootPath.toString(),
        item_path: `${updatedRootPath.toString()}-${itemIndex ?? "0"}`
      }
    };
  });
};

export const getItemFromPath = (data: ItemDNDType[], path: string): ItemDNDType => {
  const rowIndex = parseInt(path[0]);
  const childIndex = parseInt(path[2]);
  const children = data[rowIndex].children || [];
  return children[childIndex] as unknown as ItemDNDType;
};

export const removeFromRow = (rows: CardField[], pathToRemove: string): CardField[] => {
  const updated: CardField[] = [];
  const indexes = pathToRemove.split("-");
  const rowIndex = indexes[0];
  rows.forEach(row => {
    //Check every row and returns only the rows that we want to mantain.
    if (row.configuration.path == rowIndex) {
      // Retrieves the field of the same row of the field to remove and fix the item path
      if (row.configuration.item_path !== pathToRemove) {
        let rowUpdated = {
          ...row,
          configuration: { ...row.configuration, item_path: `${row.configuration.path}-0` }
        };
        updated.push(rowUpdated);
      }
    } else {
      updated.push(row);
    }
  });
  return updated;
};

export const addNewRow = (
  value: any,
  rootPath: number,
  index: number,
  rowId?: string
): CardField => {
  const childId = nanoid();
  const parentId = nanoid();
  const configurationObj: CardConfigurationField = {
    id: index < 1 ? parentId : rowId!,
    title: index < 1 ? parentId : rowId!,
    type: ROW,
    path: rootPath.toString(),
    item_id: childId,
    item_title: childId,
    item_type: COMPONENT,
    item_path: `${rootPath}-${index}`
  };
  const rowObj: CardField = {
    configuration: configurationObj,
    fieldId: value.component?.id ?? value.fieldId
  };

  return rowObj;
};

export const getAllRowsPath = (allRows: CardField[]): number[] => {
  const pathArray = allRows.map(el => parseInt(el.configuration.path));
  const uniquePathsArray = pathArray.filter(function (item, pos) {
    return pathArray.indexOf(item) == pos;
  });
  uniquePathsArray.sort((a, b) => {
    return a - b;
  });

  return uniquePathsArray;
};
