import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RollupTableColumnModel, RollupTableRowModel, RollupTableActions } from "enada-common";
import { Checkbox, Chip, IconButton, Skeleton, TableRow } from "@mui/material";
import { FC, ReactNode } from "react";
import "./rolluptablerow.scss";
import { BorderedTableCell } from "../singletable/SingleTable";

export interface RollupTableRowProps {
  columns: RollupTableColumnModel[];
  onToggleRowChecked: (checked: boolean) => void;
  row: RollupTableRowModel;
  isSelected: boolean;
  rowActions?: RollupTableActions[];
  rowOrderingEnable?: boolean;
  disableSelection?: boolean;
  t: ((key: string) => string) | undefined;
}

export type SortOrder = "asc" | "desc";
export interface TypeToColor {
  [type: string]: string | undefined;
}
const RollupTableRow: FC<RollupTableRowProps> = ({
  columns,
  onToggleRowChecked,
  row,
  isSelected,
  rowActions,
  rowOrderingEnable,
  disableSelection,
  t
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id as UniqueIdentifier,
    disabled: !(rowOrderingEnable ?? false)
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <TableRow
      data-testid={"edison-rollup-table-row"}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      hover={!row.disabled_e365}
      onClick={() => !row.disabled_e365 && onToggleRowChecked(!isSelected)}
      className={`rollup-table-row-root  ${isSelected ? "selected" : ""}`}
    >
      {!disableSelection && (
        <BorderedTableCell>
          <Checkbox
            data-testid={"edison-rollup-table-row-checkbox"}
            disabled={row.disabled_e365}
            checked={isSelected}
            onChange={e => !row.disabled_e365 && onToggleRowChecked(e.target.checked)}
          />
        </BorderedTableCell>
      )}
      {columns.map(column => (
        <BorderedTableCell key={row.id + column.name}>
          {column.isAsync && !row[column.name] ? <Skeleton /> : getFieldFromColumn(row, column, t)}
        </BorderedTableCell>
      ))}
      {rowActions && (
        <BorderedTableCell align="right">
          {rowActions.map((action, index) => (
            <IconButton
              className={`${row.disabled_e365 && "disabled"}`}
              disabled={row.disabled_e365}
              key={index}
              onClick={e => {
                e.stopPropagation();
                action.onClick(row);
              }}
            >
              {action.getIcon(row)}
            </IconButton>
          ))}
        </BorderedTableCell>
      )}
    </TableRow>
  );
};

const getFieldFromColumn = (
  row: RollupTableRowModel,
  column: RollupTableColumnModel,
  t: ((key: string) => string) | undefined
): string | ReactNode => {
  const cellValue = row[column.name];

  if (column.multipleChips) {
    return column.multipleChips(row).map((chip: any) => (
      <Chip
        key={chip.id}
        style={{
          backgroundColor: chip.colour,
          color: "black"
        }}
        label={t && chip.label ? t(chip.label) : ""}
      />
    ));
  }
  if (column.badgeColor) {
    return (
      <Chip
        style={{
          backgroundColor: column.badgeColor(row),
          color: "white"
        }}
        label={t && cellValue ? t(cellValue?.toString()) : ""}
      />
    );
  }

  if (column.componentRenderer) {
    return column.componentRenderer(cellValue, row);
  }

  if (!cellValue) {
    return null;
  }

  return cellValue;
};

function descendingComparator<T>(a: T, b: T) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}
export const sortData = <DataType extends object, KeyType extends keyof DataType>(
  data: DataType[],
  order: SortOrder,
  orderBy: KeyType
): DataType[] => {
  if (!data) return [];
  return [...data].sort((a: DataType, b: DataType) => {
    return order === "desc"
      ? descendingComparator(a[orderBy], b[orderBy])
      : -descendingComparator(a[orderBy], b[orderBy]);
  });
};
export default RollupTableRow;
