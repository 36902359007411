import {
  recordTypeConfiguration,
  NotificationLevel,
  RollupTableRowModel,
  RecordTemplate,
  Category,
  ApiError
} from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RollupTable } from "enada-components";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import {
  useGetTemplatesQuery,
  useDeleteTemplatesMutation,
  useRestoreTemplatesMutation,
  useRecycleTemplatesMutation
} from "../../../services/api";
import { useAppDispatch } from "../../../store/hooks";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { selectRollupRowsFromData } from "../../../utils/rtkQuery";

const selectTemplateRollupRows = selectRollupRowsFromData<RecordTemplate[]>(data => {
  return data?.map(template => ({
    id: template.id as number,
    displayName: template.displayName,
    modified: template.modified,
    modifiedBy: template.modifiedBy,
    type: template.recordType,
    isDeleted: template.isDeleted,
    category: template.category,
    no_delete_e365: template.category === Category.Default
  }));
});

const Templates: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { templateRollupRows = [] } = useGetTemplatesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      templateRollupRows: selectTemplateRollupRows(result)
    })
  });
  const [deleteTemplates] = useDeleteTemplatesMutation();
  const [restoreTemplates] = useRestoreTemplatesMutation();
  const [recycleTemplates] = useRecycleTemplatesMutation();

  const navigate = useNavigate();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteTemplates(ids.map(id => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "templateDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e: unknown) {
      const error = e as ApiError;
      if (error?.detail) {
        const errorArr = JSON.parse(error.detail);
        const errorMessage = errorArr.reduce((acc: string, curr) => {
          const recordNames = curr.Records.map(record => record.RecordName).join(", ");
          const formatted = `${t("typeInUseError", {
            templates: curr.RecordTemplateName,
            records: recordNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");

        dispatch(
          setCurrentNotification({
            title: error.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      }
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleTemplates(ids.map(id => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "templateRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "templateRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreTemplates(ids.map(id => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "templateRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "templateRestoredError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={id => {
        navigate("edittemplate", {
          state: {
            title: "templates",
            typeId: id
          }
        });
      }}
      addAction={() => {
        navigate("newtemplate", {
          state: {
            title: "templates"
          }
        });
      }}
      columns={rollupTableColumnsWithType(t, value => recordTypeConfiguration(value.type).color)}
      rows={templateRollupRows.filter(type => !type.isDeleted) as unknown as RollupTableRowModel[]}
      deletedRows={
        templateRollupRows.filter(type => type.isDeleted) as unknown as RollupTableRowModel[]
      }
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Templates;
