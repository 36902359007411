import React, { FC } from "react";
import { EdisonResourceComponentProps } from "../resourceutilization/ResourceUtilization";
import "../../theme/bryntumcustomtheme.scss";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import "@bryntum/gantt-thin/gantt.material.css";
import "@bryntum/scheduler-thin/scheduler.material.css";
import "@bryntum/schedulerpro-thin/schedulerpro.material.css";
import { BryntumResourceHistogram } from "@bryntum/schedulerpro-react-thin";

const ResourceHistogram: FC<EdisonResourceComponentProps> = ({
  project,
  partner,
  columns,
  disabled,
  readOnly,
  startDate,
  endDate,
  showTooltip
}) => {
  return (
    <div className="enada-bryntum-grid">
      <BryntumResourceHistogram
        data-testid={"edison-resource-histogram"}
        project={project}
        columns={columns}
        startDate={startDate}
        endDate={endDate}
        partner={partner}
        hideHeaders={true}
        rowHeight={70}
        showBarTip={showTooltip}
        showMaxEffort={true}
        //eventTooltipFeature={showTooltip}
        cellTooltipFeature={showTooltip}
        // Need to figure out how to import this feature see
        // https://www.bryntum.com/products/scheduler/docs/guide/Scheduler/basics/features#importing-features-from-sources
        scheduleTooltipFeature={false}
        disabled={disabled}
        readOnly={readOnly}
        autoHeight={true}
        // infiniteScroll={false}
        // height={(project.resourceStore.count ?? 1) * 70 + 70}
      />
    </div>
  );
};

export default ResourceHistogram;
