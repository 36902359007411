import { IconButton, Stack } from "@mui/material";
import { FC, useRef } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { selectAllFields } from "../../store/slices/fieldsSlice";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import {
  Table,
  fieldConfiguration,
  tablesConfiguration,
  COMPONENT,
  isTableType
} from "enada-common";

import "./usercomponent.scss";
import ComponentMapper from "../../utils/componentMapper";
import UserComponentMenu from "./UserComponentMenu";
import TableMapper from "../../utils/mappers/TableMapper";
import { Loading } from "enada-components";
import { useGetFieldsQuery } from "services/api";
export interface UserComponentProps {
  data: any;
  path: string;
  removeComponent?: any;
  readOnly: boolean;
  tableList?: Table[];
  source: "cardDesigner" | "formDesigner";
}

const UserComponent: FC<UserComponentProps> = ({
  data,
  path,
  removeComponent,
  readOnly,
  tableList = []
}) => {
  const ref = useRef(null);
  const { t } = useTranslation(["common"]);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, path, children: data },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const componentIcon = !isTableType(data.component?.dataType)
    ? fieldConfiguration(data.component.dataType).icon
    : tablesConfiguration(data.component.dataType).icon;

  return (
    <Stack
      ref={ref}
      sx={{
        opacity,
        bgcolor: "background.default",
        padding: "0px 5px 10px 10px",
        borderRadius: "8px",

        width: "100%",
        overflowX: "auto",
        minWidth: "50%"
      }}
      className="usercomponent draggable"
      direction={"row"}
      spacing={1}
    >
      <Stack direction="row" spacing={1}>
        <UserComponentMenu
          removeObject={{
            name: t("remove"),
            color: "error",
            icon: <DeleteOutlineOutlinedIcon fontSize="small" color="error" />,
            function: () => removeComponent(path, data.id)
          }}
        />
        <Stack direction="column" justifyContent="center" alignItems="center">
          <IconButton
            sx={{
              padding: "5px",
              margin: "0px",
              cursor: "inherit",
              "&:hover": { backgroundColor: "transparent" }
            }}
          >
            {componentIcon}
          </IconButton>
        </Stack>
      </Stack>
      {isTableType(data.component?.dataType) ? (
        tableList?.find(table => table.id === data.component.id) ? (
          <TableMapper
            table={tableList?.find(table => table.id === data.component.id) as Table}
            fields={fields}
            rowsInTable={[]}
            readOnly={readOnly}
            t={t}
          />
        ) : (
          <Loading size={30} />
        )
      ) : (
        <ComponentMapper
          readOnly={true}
          isIconOn={false}
          component={data.component}
          isInTable={false}
          useInternalState={true}
          useReadOnlyWrapper={true}
          t={t}
        />
      )}
    </Stack>
  );
};

export default UserComponent;
