import { PermissionType } from "./PermissionType.model";

export enum RoleType {
  TenantAdmin = "Tenant Admin",
  TenantInstanceAdmin = "Tenant Instance Admin",
  TenantBillingAdmin = "Tenant Billing Admin",
  InstanceAdmin = "Instance Admin",
  InstanceExec = "Instance Exec",
  InstanceResourceManager = "Resource Manager",
  StageReviewer = "Stage Reviewer",
  StageEditor = "Stage Editor",
  TemplateProcessManager = "Template Process Manager",
  TemplateOwner = "Template Owner",
  TemplateReader = "Template Reader",
  RecordProcessManager = "Record Process Manager",
  RecordOwner = "Record Owner",
  RecordReader = "Record Reader",
  RecordReviewer = "Record Reviewer",
  RecordEditor = "Record Editor",
  TableRowOwner = "Table Row Owner",
  RecordIdeator = "Record Ideator",
  TemplateIdeator = "Template Ideator"
}

export interface Permission {
  tenantId?: string;
  instanceId?: string;
  type: PermissionType;
  oid: string;
  roles?: Role[];
}

export interface Role {
  value?: RoleType;
  isOwned?: boolean;
  containedGroups?: string[];
}
