import React, { memo } from "react";
import { RecordType, recordTypeConfiguration } from "enada-common";
import { Box } from "@mui/material";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName } from "../../store/slices/userSlice";

const ModuleLabel = memo(function ModuleLabel({ recordType }: { recordType: RecordType }) {
  const { t } = useTranslation(["common"]);
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";
  const moduleConfig = recordTypeConfiguration(recordType);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {getModuleIcon(recordType, isDarkTheme)}
      <EdisonTypography
        title={t(moduleConfig.displayName ?? "")}
        variant="data"
        sx={{
          color: isDarkTheme
            ? recordTypeConfiguration(recordType).secondaryColor
            : recordTypeConfiguration(recordType).color
        }}
      />
    </Box>
  );
});

const getModuleIcon = (type?: RecordType, isDarkTheme?: boolean) => {
  if (type === undefined) return <></>;

  const moduleProperties = recordTypeConfiguration(type, {
    fontSize: "1.2em",
    color: isDarkTheme
      ? recordTypeConfiguration(type).secondaryColor
      : recordTypeConfiguration(type).color
  });

  return <>{moduleProperties.icon}</>;
};

export { getModuleIcon };
export default ModuleLabel;
