import { CalculatedExpressionUnit, ExpressionEntity, ExpressionOperator } from "enada-common";
import { Stack, TextField } from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import {
  ExpressionLocation,
  removeUnitFromConditionBlock,
  removeUnitFromSimpleExpression,
  setBarLocation,
  setSelectedUnit,
  updateUnitFixedValue
} from "../../../../store/slices/calculatedFieldSlice";
import { selectAllFields } from "../../../../store/slices/fieldsSlice";
import { CalculatedMoveAction } from "../utils/calculated.model";
import { getFrontendOperator } from "../utils/FrontendToBackendOperatorMap";
import "./expressionunit.scss";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import useConditionDrop from "../utils/hooks/useConditionDrop";
import useSimpleDrop from "../utils/hooks/useSimpleDrop";
import { useGetFieldsQuery } from "services/api";

export interface ExpressionUnitProps {
  unit: CalculatedExpressionUnit;
  index: number;
  path: ExpressionLocation | null;
  selected: boolean;
}
const ExpressionUnit: FC<ExpressionUnitProps> = ({ unit, index, path, selected }) => {
  const dispatch = useAppDispatch();

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const simpleDrop = useSimpleDrop(index);
  const conditionDrop = useConditionDrop({ index, ...path });

  const onValidDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "move";
  };
  const onInvalidDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "none";
  };

  const onDropSimple = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const action = event.dataTransfer.getData("application/calculatedField");
    const parsed: CalculatedMoveAction = JSON.parse(action);
    simpleDrop(parsed);
  };
  const onDropCondition = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const action = event.dataTransfer.getData("application/calculatedField");
    const parsed: CalculatedMoveAction = JSON.parse(action);
    conditionDrop(parsed);
  };
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const moveAction: CalculatedMoveAction = {
      unit,
      sourceLocation: { index, ...path }
    };
    event.dataTransfer.setData("application/calculatedField", JSON.stringify(moveAction));
    event.dataTransfer.effectAllowed = "move";
  };

  const getUnitValue = (): string | number => {
    switch (unit.type) {
      case "entity":
        return (
          fields.find(field => field.id === (unit.value as ExpressionEntity).entityId)
            ?.displayName ?? ""
        );
      case "operator":
        return getFrontendOperator(unit.value as ExpressionOperator) ?? "";
      case "number":
        return unit.value as number;
      default:
        return unit.value as string;
    }
  };
  const deleteUnit = () =>
    path === null
      ? dispatch(removeUnitFromSimpleExpression(index))
      : dispatch(
          removeUnitFromConditionBlock({
            path,
            index
          })
        );

  return (
    <Stack className="expression-unit-root" direction="row" onDragOver={onInvalidDragOver}>
      <div
        onClick={() => dispatch(setBarLocation({ index, ...path }))}
        onDragOver={onValidDragOver}
        onDrop={e => (path === null ? onDropSimple(e) : onDropCondition(e))}
        className="dropzone"
      ></div>
      <Stack draggable className="entity-container" onDragStart={onDragStart} direction="row">
        {["operator", "entity"].includes(unit.type) ? (
          <Stack direction="row">
            <div
              className={`unit ${unit.type === "operator" ? "operator" : "operand"} ${
                selected && "selected"
              }`}
              onClick={() => dispatch(setSelectedUnit({ index, ...path }))}
            >
              {getUnitValue()}
            </div>
            <div
              className={`delete-container ${unit.type === "operator" ? "operator" : "operand"}`}
            >
              <CancelOutlinedIcon
                className={`delete-icon ${selected ? "visible" : "hidden"}`}
                fontSize="small"
                onClick={deleteUnit}
              />
            </div>
          </Stack>
        ) : selected ? (
          <Stack direction="row">
            <TextField
              onChange={e => dispatch(updateUnitFixedValue(e.target.value))}
              className="custom-value selected"
              size="small"
              value={getUnitValue()}
              type={unit.type === "text" ? "text" : "number"}
            />
            <CancelOutlinedIcon
              className={`delete-icon visible custom`}
              fontSize="small"
              onClick={deleteUnit}
            />
          </Stack>
        ) : (
          <div
            className={`custom-value`}
            onClick={() => dispatch(setSelectedUnit({ index, ...path }))}
          >
            {getUnitValue()}
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default ExpressionUnit;
