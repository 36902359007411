import { apiConfig } from "config/authConfig";
import {
  BaseRecord,
  BaseWorkItem,
  PendingRecordTableRow,
  RecordTableRow,
  RecordType,
  WorkflowTask,
  WorkItem,
  WorkItemApproval,
  WorkItemTask
} from "enada-common";
import { nanoid } from "nanoid";
import { getApiForReviews, getApiForTables } from "services/APIService";
import { baseAPI } from "services/baseAPI";
import { getHeadersFromAuth, RecordAuth } from "store/slices/recordSlice";
import { ApiResponse, ODataApiResponse } from "types/ApiResponse";
import { RowOperationType } from "utils/parsing/parseTableChangeToBackend";

export const personalAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getFavourites: builder.query<ODataApiResponse<BaseRecord[]>, string>({
      query: query =>
        `${apiConfig.oDataEndpoint}/records?$filter=Favourited eq true${decodeURIComponent(
          query.replace("?", "&")
        )}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        const currentItems = currentCache?.value || [];
        const newItemsValue = newItems?.value || [];
        currentItems.push(...newItemsValue);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["Personal"]
    }),

    getApprovals: builder.query<WorkItemApproval[], void>({
      query: () => `${apiConfig.recordsMyWorkEndpoint}/approvals`,
      transformResponse: (response: { data: WorkItemApproval[] }) => response.data,
      providesTags: ["Personal"]
    }),

    getDashboardItems: builder.query<BaseWorkItem[], void>({
      query: () => `${apiConfig.recordsMyWorkEndpoint}/dashboard`,
      transformResponse: (response: { data: BaseWorkItem[] }) => response.data,
      providesTags: ["Personal"]
    }),

    getPersonalTasks: builder.query<WorkItemTask[], void>({
      query: () => `${apiConfig.recordsMyWorkEndpoint}/tasks`,
      transformResponse: (response: { data: WorkItemTask[] }) => response.data,
      providesTags: ["Personal"]
    }),

    getOwnedItems: builder.query<WorkItem[], void>({
      query: () => `${apiConfig.recordsMyWorkEndpoint}/items`,
      transformResponse: (response: { data: WorkItem[] }) => response.data,
      providesTags: ["Personal"]
    }),

    getMyWorkItem: builder.query<RecordTableRow, number>({
      query: itemId => `${apiConfig.recordsMyWorkEndpoint}/items/${itemId}`,
      transformResponse: (response: { data: RecordTableRow }) => response.data,
      providesTags: ["Personal"]
    }),

    getMyWorkSavedItem: builder.query<PendingRecordTableRow, number>({
      query: itemId => `${apiConfig.recordsMyWorkEndpoint}/items/saved/${itemId}`,
      transformResponse: (response: { data: PendingRecordTableRow }) => response.data,
      providesTags: ["Personal"]
    }),

    getMyWorkTask: builder.query<RecordTableRow, number>({
      query: itemId => `${apiConfig.recordsMyWorkEndpoint}/tasks/${itemId}`,
      transformResponse: (response: { data: RecordTableRow }) => response.data,
      providesTags: ["Personal"]
    }),

    getMyWorkSavedTask: builder.query<PendingRecordTableRow, number>({
      query: itemId => `${apiConfig.recordsMyWorkEndpoint}/tasks/saved/${itemId}`,
      transformResponse: (response: { data: PendingRecordTableRow }) => response.data,
      providesTags: ["Personal"]
    }),

    savePendingRecordViewTableValues: builder.mutation<
      void,
      {
        body: RowOperationType[];
        recordId: number;
        recordType: RecordType;
        viewId: number;
        auth: RecordAuth;
      }
    >({
      query: ({ body, recordId, recordType, viewId, auth }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/pending/rows/${viewId}`,
        method: "POST",
        body,
        headers: getHeadersFromAuth(auth)
      }),
      invalidatesTags: ["Personal"]
    }),

    recallPendingRow: builder.mutation<
      { Recalled: PendingRecordTableRow },
      {
        body: { recordTableRowId: number | undefined };
        recordId: number;
        recordType: RecordType;
        auth: RecordAuth;
      }
    >({
      query: ({ body, recordId, recordType, auth }) => ({
        url: `${getApiForTables(recordType)}/${recordId}/pending/rows/recall`,
        method: "POST",
        body,
        headers: getHeadersFromAuth(auth)
      }),
      invalidatesTags: ["Personal"]
    }),

    updateTaskChecklistReview: builder.mutation<
      ApiResponse<WorkflowTask>,
      { body: WorkflowTask; recordId: number; recordType: RecordType }
    >({
      query: ({ body, recordId, recordType }) => {
        const sessionId = nanoid();
        return {
          url: `${getApiForReviews(recordType)}/${recordId}/task`,
          method: "POST",
          body,
          headers: {
            "edison365-sessionid": sessionId // Set headers as an object
          }
        };
      },
      invalidatesTags: ["Personal"]
    })
  })
});
