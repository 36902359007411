import React, { FC, useState } from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

const EdiErrorPrompt: FC = () => {
  const { t } = useTranslation(["common"]);

  return (
    <Alert color="error" icon={false} sx={{ width: "100%" }} data-testid="edi-error-prompt">
      {t("ediGenerateRows.requestError")}
    </Alert>
  );
};

export default EdiErrorPrompt;
