import { useCallback, useEffect, useState } from "react";
import dayjs, { ConfigType } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Extend dayjs with the localizedFormat plugin
dayjs.extend(localizedFormat);

export const useLocalisation = () => {
  const [locale, setLocale] = useState<string>("en");

  useEffect(() => {
    const setLocaleCode = async () => {
      const userLocale = navigator.language; // e.g., "en-GB"
      const localeCode = userLocale.toLowerCase(); // e.g., "en-gb"

      try {
        // Import the locale file
        await import(`dayjs/locale/${localeCode}.js`);

        // Set the locale
        dayjs.locale(localeCode);
        setLocale(localeCode);
      } catch (error) {
        dayjs.locale("en"); // Fallback to en
        setLocale("en");
      }
    };

    setLocaleCode();
  }, []);

  /**
   * Formats a date using a localized format.
   * @see https://day.js.org/docs/en/display/format#list-of-localized-formats
   */

  const formatDate = useCallback((date: ConfigType, template?: string) => {
    return dayjs(date).format(template ?? "LL");
  }, []);

  return { locale, formatDate };
};
