import React, { useState } from "react";
import ProjectRecordFlyout from "../projectrecordflyout/ProjectRecordFlyout";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle, Button, Divider, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectRecord, selectRecordAuth, setIsFlyoutOpen } from "../../../store/slices/recordSlice";
import { EdisonAlert, EdisonTypography, Loading } from "enada-components";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import "./projecttablerowreview.scss";
import { Person } from "@microsoft/mgt-react";
import RowReviewChangesTable from "./rowreviewchangestable/RowReviewChangesTable";
import { ApprovalOperationType, RecordType, RecordsTableRowsPendingOperations } from "enada-common";
import { SystemFieldType } from "../../../pages/admin/tableconfig/TableConfig";
import {
  selectRecordPendingRecordRows,
  updatePendingTableRows
} from "../../../store/slices/recordTableSlice";
import { useReviewRecordTableRowsMutation } from "services/api";

const ProjectTableRowReview = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const pendingRows = useAppSelector(selectRecordPendingRecordRows);
  const record = useAppSelector(selectRecord);
  const auth = useAppSelector(selectRecordAuth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const currentPendingRow = pendingRows[currentIndex];

  const [reviewRecordTableRows, { isLoading }] = useReviewRecordTableRowsMutation();

  const onReview = async (type: ApprovalOperationType) => {
    if (!auth) return;
    const operation: RecordsTableRowsPendingOperations = {
      operationType: type,
      pendingTableRows: [
        {
          recordTableRowId: currentPendingRow.recordTableRowId,
          submittedEntityId: currentPendingRow.submittedEntityId
        }
      ]
    };

    const response = await reviewRecordTableRows({
      recordId: auth.details.RecordId,
      recordType: record?.recordType as RecordType,
      body: [operation]
    }).unwrap();

    if (response?.data?.Approved) dispatch(updatePendingTableRows(response.data.Approved));

    if (currentIndex === pendingRows.length - 1) {
      setShowConfirmation(true);
    }
  };

  const _getRowIdentifier = () => {
    const name = currentPendingRow?.pendingRecordTableRowValue?.tableRowFieldValues?.find(
      value => value.fieldId === SystemFieldType.Name
    )?.stringValue;
    const uniqueId = currentPendingRow?.pendingRecordTableRowValue?.tableRowFieldValues?.find(
      value => value.fieldId === SystemFieldType.UniqueId
    )?.stringValue;
    return name ?? uniqueId ?? "";
  };

  return (
    <ProjectRecordFlyout
      title={
        showConfirmation ? (
          <EdisonTypography variant="h2" title={t("reviewChanges")} />
        ) : (
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <EdisonTypography variant="h2" title={t("reviewChanges")} />
            <div>{`${currentIndex + 1} ${t("of")} ${pendingRows.length}`}</div>
          </Stack>
        )
      }
    >
      {isLoading ? (
        <Loading size={50} />
      ) : showConfirmation ? (
        <Stack className="project-table-row-review-root">
          <Alert severity="info">
            <AlertTitle>{t("thankYouForReviewing")}</AlertTitle>
            {t("weHaveNotifiedUsersOfTheirDecision")}
          </Alert>
          <Stack spacing={1}>
            <Divider flexItem />
            <Stack>
              <Button
                className="close-button"
                variant="outlined"
                onClick={() => {
                  dispatch(setIsFlyoutOpen(false));
                  setShowConfirmation(false);
                }}
              >
                {t("close")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack className="project-table-row-review-root" spacing={1}>
          <Stack spacing={1}>
            <EdisonAlert
              severity="info"
              heading={t("submittingReview")}
              subHeading={t("approveOrRejectMessage")}
            />
            {currentPendingRow?.submittedEntityId && (
              <Stack spacing={1}>
                <EdisonTypography
                  title={t("submittedBy")}
                  variant="h3"
                  sx={{ fontWeight: "bold" }}
                />
                <Person
                  userId={currentPendingRow.submittedEntityId}
                  avatarSize="large"
                  view={"twolines"}
                  showPresence
                />
              </Stack>
            )}

            <Divider flexItem />
            <EdisonTypography
              title={`${t("changes")}: `}
              variant="h3"
              sx={{ fontWeight: "bold" }}
            />
            {currentPendingRow && <RowReviewChangesTable pendingRow={currentPendingRow} />}
          </Stack>
          <Stack spacing={1}>
            <Divider flexItem />
            <Stack direction="row" className="button-container">
              <Button
                disabled={pendingRows.length === currentIndex + 1}
                onClick={() => setCurrentIndex(prev => prev + 1)}
              >
                {t("skip")}
              </Button>
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() => onReview(ApprovalOperationType.Approve)}
                  variant="contained"
                  color="success"
                  startIcon={<DoneIcon />}
                >
                  {t("approve")}
                </Button>
                <Button
                  onClick={() => onReview(ApprovalOperationType.Reject)}
                  variant="outlined"
                  color="error"
                  startIcon={<CloseIcon />}
                >
                  {t("reject")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </ProjectRecordFlyout>
  );
};

export default ProjectTableRowReview;
