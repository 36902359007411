import {
  BaseRecord,
  Category,
  FieldDataType,
  RecordType,
  ResourceType,
  RollupTableColumnModel,
  currencies,
  toCamelCase
} from "enada-common";
import { MgtTemplateProps, People, Person } from "@microsoft/mgt-react";
import { Avatar, Chip, IconButton, Stack, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { FC, ReactNode, SetStateAction } from "react";
import colors from "./../theme/styleVariables.module.scss";
import { SYSTEM_FIELD_ID_LIMIT } from "./authConfig";
import { TFunction } from "i18next";
import { RecycleBinRow } from "pages/admin/recycleBin/RecycleBin";
import HistoryIcon from "@mui/icons-material/History";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const loadingChip = <Chip sx={{ width: "100px" }} />;

interface CategoryChipProps {
  label: string;
  category?: Category;
}

const CategoryChip: FC<CategoryChipProps> = ({ label, category }) => {
  const theme = useTheme();

  let style: any = {
    color: theme.palette.getContrastText(theme.palette.CustomAdminEntity.main)
  };
  switch (category) {
    case Category.System:
      style = {
        ...style,
        backgroundImage: `linear-gradient(${colors.SystemGradient})`
      };
      break;
    case Category.Default:
      style = { ...style, backgroundColor: theme.palette.grey[400] };
      break;
    default:
      style = {
        ...style,
        backgroundColor: theme.palette.CustomAdminEntity.main
      };
      break;
  }

  return <Chip label={label} style={style} />;
};

export const modifiedColumnRenderer: any = (val: string, row: any) => {
  return <>{!isNaN(Date.parse(val)) ? dayjs(val).format("LLL") : ""}</>;
};

export const resourceRollupTableColumnsWithType = (
  t: (value: string) => string
): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("label"),
    componentRenderer: (val, row) => {
      if (row["type"] === t(toCamelCase(ResourceType.AAD))) {
        return <Person showPresence userId={row["userId"]} view={"oneline"} />;
      }
      return val;
    }
  },
  {
    name: "isActive",
    displayName: t("status"),
    componentRenderer: val => <Chip label={t(val)} color={val === "active" ? "success" : "error"} />
  },
  {
    name: "type",
    displayName: t("type"),
    componentRenderer: val => val
  },
  {
    name: "calendar",
    displayName: t("calendar"),
    // isAsync: true,
    componentRenderer: (val, row) => val
  },
  {
    name: "costRate",
    displayName: t("costRatePerDay"),
    componentRenderer: (val, row) => {
      if (currencies) {
        const currencySymbol =
          currencies[row["costRateCurrency"] as keyof typeof currencies]?.currencySymbol;
        return `${currencySymbol ? currencySymbol + " " : ""}${row["costRate"]}`;
      }
      return row["costRate"];
    }
  },
  {
    name: "managers",
    displayName: t("resourceManagers"),
    componentRenderer: (val, row) => {
      if (!row["resourceManagers"]) return;
      return row["resourceManagers"].length > 1 ? (
        <People
          className="resource-managers"
          userIds={row["resourceManagers"].map((manager: any) => manager.entityId)}
          showPresence
          showMax={3}
        />
      ) : (
        <Person
          showPresence
          userId={row["resourceManagers"].map((manager: any) => manager.entityId)}
          view={"oneline"}
        />
      );
    }
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    componentRenderer: (cellValue, row) => (
      <People key={row.userId} showMax={1} userIds={[cellValue]}>
        <AvatarsTemplate template="default" />
      </People>
    )
  },
  {
    name: "modified",
    displayName: t("modified"),
    componentRenderer: modifiedColumnRenderer
  }
];

export const personalDataRollupTable = (t: (value: string) => string): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("displayName"),
    componentRenderer: (cellValue, row) => {
      return (
        <People key={row.id} showMax={1} userIds={[row.id]}>
          <AvatarsTemplate template="default" />
        </People>
      );
    }
  },
  {
    name: "id",
    displayName: t("azureid"),
    componentRenderer: val => val
  },
  {
    name: "email",
    displayName: t("email"),
    componentRenderer: val => val
  }
];
export const personalDataDeletedRollupTable = (
  t: (value: string) => string
): RollupTableColumnModel[] => [
  {
    name: "id",
    displayName: t("azureid"),
    componentRenderer: val => val
  },
  {
    name: "modified",
    displayName: t("dateDeleted"),
    componentRenderer: modifiedColumnRenderer
  }
];

export const rollupTableColumnsWithType = (
  t: (value: string) => string,
  badgeColor: (value: any) => string
): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("label"),
    width: "25%"
  },
  {
    name: "type",
    displayName: t("fieldType"),
    loadingComponent: loadingChip,
    badgeColor
  },
  {
    name: "category",
    displayName: t("category"),
    componentRenderer: (cellValue, row) => {
      return <CategoryChip label={t(row.category.toLowerCase())} category={cellValue} />;
    }
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    componentRenderer(cellValue, row) {
      const rowIsField =
        row.data &&
        row.data.dataType &&
        [...Object.values(FieldDataType), "Custom"].includes(row?.data?.dataType);
      const rowIsSystemField = rowIsField && row.data.id < SYSTEM_FIELD_ID_LIMIT;

      return cellValue ? (
        rowIsSystemField ? (
          t("system")
        ) : (
          <People key={row.userId} showMax={1} userIds={[cellValue]}>
            <AvatarsTemplate template="default" />
          </People>
        )
      ) : null;
    }
  },
  {
    name: "modified",
    displayName: t("modified"),
    componentRenderer: modifiedColumnRenderer
  }
];

export const rollupTableViewColumnsWithType = (
  t: (value: string) => string,
  badgeColor: (value: any) => string
): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("label"),
    width: "25%"
  },
  {
    name: "viewType",
    displayName: t("type"),
    loadingComponent: loadingChip,
    badgeColor
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    componentRenderer(cellValue, row) {
      return cellValue ? (
        <People key={row.userId} showMax={1} userIds={[cellValue]}>
          <AvatarsTemplate template="default" />
        </People>
      ) : (
        <p>System</p> //needs fixing
      );
    }
  }
];

export const calendarRollupTableColumns = (
  t: (value: string) => string,
  multipleChips: (value: any) => any
): RollupTableColumnModel[] => [
  {
    name: "displayName",
    displayName: t("label"),
    width: "25%"
  },
  {
    name: "category",
    displayName: t("category"),
    componentRenderer: (cellValue, row) => (
      <CategoryChip label={t(row.category.toLowerCase())} category={cellValue} />
    )
  },
  {
    name: "workingDays",
    displayName: t("workingDays"),
    multipleChips: multipleChips
  },
  {
    name: "hoursPerDay",
    displayName: t("hoursPerDay")
  },
  {
    name: "hoursPerWeek",
    displayName: t("hoursPerWeek")
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    componentRenderer(cellValue, row) {
      return cellValue ? (
        <People key={row.userId} showMax={1} userIds={[cellValue]}>
          <AvatarsTemplate template="default" />
        </People>
      ) : null;
    }
  },
  {
    name: "modified",
    displayName: t("modified"),
    componentRenderer: modifiedColumnRenderer
  }
];

export const newsRollupTableColumns = (
  t: (value: string) => string,
  badgeColor: (value: any) => string
): RollupTableColumnModel[] => [
  { name: "order", displayName: t("displayOrder") },
  { name: "displayName", displayName: t("heading") },

  {
    name: "type",
    displayName: t("expiresOn"),
    badgeColor
  },
  {
    name: "category",
    displayName: t("category"),
    componentRenderer: (cellValue, row) => (
      <CategoryChip label={t(row.category.toLowerCase())} category={cellValue} />
    )
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    componentRenderer(cellValue, row) {
      return cellValue ? (
        <People key={row.userId} showMax={1} userIds={[cellValue]}>
          <AvatarsTemplate template="default" />
        </People>
      ) : null;
    }
  },
  {
    name: "modified",
    displayName: t("modified"),
    componentRenderer: modifiedColumnRenderer
  }
];

export const recycleBinRollupRows = (
  t: TFunction,
  typeMap: Map<RecordType, Record<string, string | undefined>>,
  setSingleRow: (value: SetStateAction<number | undefined>) => void,
  onToggleRestore: () => void,
  onToggleDelete: () => void,
  getIcon: (type: RecordType) => ReactNode
) => [
  {
    name: "name",
    displayName: t("name"),
    width: "25%"
  },
  {
    name: "template",
    displayName: t("template"),
    width: "25%"
  },
  {
    name: "type",
    width: "25%",
    displayName: t("type"),
    componentRenderer: (cellValue: RecycleBinRow["type"]) => (
      <Stack direction="row" alignItems="center" color={typeMap.get(cellValue)}>
        <Avatar sx={{ bgcolor: "transparent" }}>{getIcon(cellValue)}</Avatar>
        {typeMap.get(cellValue)?.label}
      </Stack>
    )
  },
  {
    name: "modifiedBy",
    displayName: t("modifiedBy"),
    width: "25%",
    componentRenderer: (cellValue: RecycleBinRow["modifiedBy"]) => (
      <People key={cellValue} showMax={1} userIds={[cellValue]}>
        <AvatarsTemplate template="default" />
      </People>
    )
  },
  {
    name: "modified",
    displayName: t("modified"),
    componentRenderer: modifiedColumnRenderer,
    width: "25%"
  },
  {
    name: "actions",
    displayName: t("actions"),
    componentRenderer: (cellValue: BaseRecord["id"]) => (
      <Stack direction="row">
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setSingleRow(cellValue);
            onToggleRestore();
          }}
        >
          <HistoryIcon color="disabled" />
        </IconButton>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setSingleRow(cellValue);
            onToggleDelete();
          }}
        >
          <DeleteOutlineOutlinedIcon color="error" />
        </IconButton>
      </Stack>
    )
  }
];

export const AvatarsTemplate = (props: MgtTemplateProps) => {
  const { people } = props.dataContext;
  return people.map((person: any, index: any) => (
    <Person
      key={index}
      userId={person?.id}
      showPresence
      view={"oneline"}
      fetchImage
      personCardInteraction={"click"}
      onClick={e => e.stopPropagation()}
      avatarSize={"small"}
    ></Person>
  ));
};
