import { FC, useState, useCallback, useEffect, useMemo } from "react";
import {
  NotificationLevel,
  RecordAccessRole,
  RecordTableRow,
  RecordType,
  WorkflowStage,
  WorkflowStageViewFieldState
} from "enada-common";
import { Paper, Stack } from "@mui/material";
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectTabs from "../../../components/projects/projecttabs/ProjectTabs";
import EditCardImage from "../../../components/recordsettings/EditCardImage";
import ManageAccess from "../../../components/recordsettings/ManageAccess";
import Properties from "../../../components/recordsettings/Properties";
import HistoryEvents from "../../../components/recordsettings/HistoryEvents";
import StageApprovalTabs from "../../../components/stageapproval/stageapprovaltabs/StageApprovalTabs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getIndividualRecordAsync,
  getRecordAccessTokenAsync,
  getRecordFieldsAsync,
  getRecordFormAsync,
  getRecordUserLikedAsync,
  getRecordUserRatingAsync,
  getRecordViewValuesAsync,
  getRecordWorkflowAsync,
  getRecordWorkflowStageAsync,
  postRecordViewsAsync,
  RecordSettings,
  selectCurrentVersion,
  selectRefreshRecord,
  setRefreshRecord,
  resetRecordSlice,
  selectUserAccessRoles,
  resetCoAuth,
  selectRecordAuth,
  CoAuthUser,
  setRecordEditor,
  addViewers,
  removeViewer,
  addViewer,
  RecordAuth,
  getRecordWorkflowReviewAsync,
  getRecordWorkflowReviewersAsync,
  selectFlyoutId,
  clearRecordDataLoadedBits,
  selectRecordFullyInitialised,
  setRecordReadOnly,
  selectHasChanges,
  setRecordVersion,
  selectLastRecordChange,
  selectEditor
} from "../../../store/slices/recordSlice";
import { Loading } from "enada-components";
import { enumerateTables } from "../../../utils/enumerateTables";
import {
  getMyResourcesAsync,
  setRecordVersionResources
} from "../../../store/slices/resourcesSlice";
import { getCalendarsAsync, setRecordVersionCalendars } from "../../../store/slices/calendarsSlice";
import { hasAccessRole } from "../../../utils/hasAccessRole";
import { apiConfig, loginRequest, msalConfig } from "../../../config/authConfig";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { AccountInfo } from "@azure/msal-browser";
import { inputSelectInstance, inputSelectTenant } from "../../../store/slices/userSlice";
import { PublicClientApplication } from "@azure/msal-browser";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import EdiRowGeneration from "../../../components/edirowgeneration/EdiRowGeneration";
import RecordExpiredSnackbar from "../../../components/projects/recordexpiredsnackbar/RecordExpiredSnackbar";
import { selectRegion } from "../../../store/slices/configSlice";
import ProjectTableRowReview from "../../../components/projects/projecttablerowreview/ProjectTableRowReview";
import { generateApprovedRowsNotification } from "../../../utils/generateApprovedRowsNotification";
import { t } from "i18next";
import AssociatedRecords from "../../../components/projects/associatedrecords/AssociatedRecords";
import { sleep } from "../../../utils/sleep";
import {
  getIndividualTableAsync,
  getRecordTablesConfigurationAsync,
  getRecordViewPendingTableRowsAsync,
  getRecordViewPeriodicGrandTotalsAsync,
  getRecordViewTableRowsAsync,
  resetRecordTableSlice,
  selectRecordTableDataInitalised,
  selectRecordTables,
  setRecordTableSliceRecordId,
  selectRecordRowsToUpdate,
  addRowsToUpdate,
  clearRecordTableDataLoadedBits,
  setRecordVersionViewTableRows,
  selectEdiTableId,
  setRecordTables
} from "../../../store/slices/recordTableSlice";
import { RecordDataFlags } from "../../../utils/bitwiseops";
import { default as ProgramAssociatedRecords } from "../../../components/programs/associatedrecords/AssociatedRecords";
import RecordVersions from "../../../components/recordVersions/RecordVersions";
import { getRecordVersion } from "../../../services/APIService";
import { getAIEnabledAsync } from "../../../store/slices/tenantSlice";
import { useMsal } from "@azure/msal-react";
import { useGetUserQuery } from "services/api";

export interface RecordPageProps {
  scope: RecordType;
}

const RecordPage: FC<RecordPageProps> = ({ scope }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { instance: msalInstance } = useMsal();

  const { data: user } = useGetUserQuery();

  const accessRoles = useAppSelector(selectUserAccessRoles);
  const flyoutId = useAppSelector(selectFlyoutId);
  const refreshProject = useAppSelector(selectRefreshRecord);
  const tenant = useAppSelector(inputSelectTenant);
  const instance = useAppSelector(inputSelectInstance);
  const region = useAppSelector(selectRegion);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordDataInitialised = useAppSelector(selectRecordFullyInitialised);
  const recordTableDataInitialised = useAppSelector(selectRecordTableDataInitalised);
  const ediTableId = useAppSelector(selectEdiTableId);
  const lastRecordChange = useAppSelector(selectLastRecordChange);

  const tables = useAppSelector(selectRecordTables);
  const rowsToUpdate = useAppSelector(selectRecordRowsToUpdate);
  const hasChanges = useAppSelector(selectHasChanges);
  const [connection, setConnection] = useState<any>(null);
  const [expiredTokenSnackbarOpen, setExpiredTokenSnackbarOpen] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const params = useParams();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasChanges && currentLocation.pathname !== nextLocation.pathname
  );
  // const { connectToSignalR, startEditingSession, connection, closeConnection } =
  //   useProjectSignalRConnection(params.projectId as any);
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!recordAuth?.expiresOnISO) {
        return;
      }
      const lastChange = new Date(lastRecordChange);
      const sessionDuration = parseInt(process.env.NX_PUBLIC_SESSION_DURATION as string);
      lastChange.setMinutes(lastChange.getMinutes() + sessionDuration);
      const diff = (lastChange.getTime() - new Date(recordAuth?.expiresOnISO).getTime()) / 1000;

      if (
        new Date(recordAuth?.expiresOnISO) < new Date(Date.now() + 60000) &&
        ((lastRecordChange && diff > -1) ||
          !accessRoles ||
          !hasAccessRole(RecordAccessRole.Update, accessRoles))
      ) {
        if (connection?.state !== "Connected") return;
        const coAuthoringAccessDto = {
          recordId: params.recordId as any,
          recordType: scope
        };

        // Fetch a new access token to update the expiry time
        await dispatch(getRecordAccessTokenAsync(coAuthoringAccessDto)).unwrap();

        // Call KeepAlive to update the expiry time in table storage to retain editor access
        await connection.invoke("KeepAlive", coAuthoringAccessDto);
      }

      if (new Date(recordAuth?.expiresOnISO as string) < new Date()) {
        dispatch(setRecordReadOnly(true));
        setExpiredTokenSnackbarOpen(true);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [connection, recordAuth, params.recordId, scope, lastRecordChange]);

  const currentAccount = msalInstance.getActiveAccount();
  const requestedScope = loginRequest;
  const request = useMemo(
    () => ({
      ...requestedScope,
      account: currentAccount as AccountInfo
    }),
    [currentAccount, requestedScope]
  );

  const getFlyoutData = useMemo(() => {
    if (!accessRoles) return;
    switch (flyoutId) {
      case RecordSettings.properties:
        return (
          <Properties
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles) || !!currentVersion}
            scope={scope}
          />
        );
      case RecordSettings.historyEvents:
        return (
          <HistoryEvents
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles)}
            scope={scope}
          />
        );
      case RecordSettings.editCardContent:
        return (
          <EditCardImage
            readOnly={!hasAccessRole(RecordAccessRole.Update, accessRoles) || !!currentVersion}
          />
        );
      case RecordSettings.stageApproval:
        return <StageApprovalTabs />;
      case RecordSettings.manageAccess:
        return (
          <ManageAccess
            readOnly={!hasAccessRole(RecordAccessRole.UpdatePermissions, accessRoles)}
          />
        );
      case RecordSettings.ediRowGeneration:
        return <EdiRowGeneration tableId={ediTableId} />;

      case RecordSettings.tableRowReview:
        return <ProjectTableRowReview />;
      case RecordSettings.associatedRecords:
        return <AssociatedRecords />;
      case RecordSettings.programAssociations:
        return <ProgramAssociatedRecords />;
      case RecordSettings.versionHistory:
        return <RecordVersions />;
    }
  }, [flyoutId, accessRoles, ediTableId]);

  const connectToSignalR = useCallback(async () => {
    const newUrl = `${apiConfig.recordsCoauthoringEndpoint}?edison365-tenant=${tenant}&edison365-instance=${instance}&edison365-sessionid=frontend`;
    const newConnection = new HubConnectionBuilder()
      .withUrl(newUrl, {
        accessTokenFactory: async () => {
          const tokenResponse = await msalInstance.acquireTokenSilent(request);
          return tokenResponse.accessToken;
        },
        headers: {
          "edison365-tenant": tenant,
          "edison365-instance": instance,
          "edison365-region": region,
          "edison365-sessionid": "frontend"
        }
      })
      .withServerTimeout(60000)
      // Warning: Keep alive interval must match the the server setting do not change.
      .withKeepAliveInterval(30000)
      .withStatefulReconnect()
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [instance, msalInstance, request, tenant]);

  const setupEditorCallbacks = useCallback(async () => {
    if (!connection) return;
    connection.on("ReceiveCurrentRecordState", (message: any) => {
      if (message.connectedUsers.length > 0) {
        const connectedUsers: Array<CoAuthUser> = message.connectedUsers;
        const activeEditor = connectedUsers.find(
          (user: CoAuthUser) => user.isEditor && user.isConnected && user.isTokenValid
        );
        const activeViewers = connectedUsers.filter(
          (user: CoAuthUser) => user.isConnected && user.isTokenValid
        );

        const currentUser: CoAuthUser | undefined = {
          oid: user?.id ?? "",
          name: user?.displayName ?? "",
          recordId: message,
          isConnected: true,
          isEditor: true,
          isTokenValid: true
        };
        const activeViewers1 = [...activeViewers.reverse(), currentUser];

        if (activeEditor) dispatch(setRecordEditor(activeEditor));
        dispatch(addViewers(activeViewers1));
      }
    });

    connection.on("GiveRecordControl", (message: any) => {
      if (!user) return;

      dispatch(
        setRecordEditor({
          oid: user?.id ?? "",
          name: user?.displayName ?? "",
          recordId: message,
          isConnected: true,
          isEditor: true,
          isTokenValid: true
        })
      );

      console.log("GiveRecordControl", message);
      dispatch(
        setCurrentNotification({
          title: `youAreNow${scope}Editor`,
          message: "",
          level: NotificationLevel.Success
        })
      );

      if (recordAuth) {
        dispatch(clearRecordDataLoadedBits([RecordDataFlags.recordFieldValues]));
        dispatch(clearRecordTableDataLoadedBits([RecordDataFlags.recordTableValues]));
        dispatch(
          getRecordViewValuesAsync({
            recordAuth,
            recordType: scope
          })
        );
        dispatch(
          getRecordViewTableRowsAsync({
            recordAuth,
            recordType: scope
          })
        );
      }
    });

    connection.on("ReceiveNewRecordAuthor", (author: any) => {
      if (author.isEditor) {
        dispatch(setRecordEditor(author));

        dispatch(
          setCurrentNotification({
            title: `youAreNow${scope}Editor`,
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
      dispatch(addViewer(author));
    });

    connection.on("ReceiveNewRecordEditor", (editor: any) => {
      if (editor !== null) {
        dispatch(setRecordEditor(editor));

        if (editor.oid === user?.id) {
          dispatch(
            setCurrentNotification({
              title: `youAreNow${scope}Editor`,
              message: "",
              level: NotificationLevel.Success
            })
          );
        }
      }
    });

    connection.on("ReceiveRecordAuthorsWhoLeft", (authors: any) => {
      const author = authors[0]; //We know only one user comes at a time
      dispatch(removeViewer(author));
    });

    connection.on("TableRowsApproved", (approvedRows: RecordTableRow[]) => {
      dispatch(addRowsToUpdate(approvedRows));
    });
  }, [user, connection, dispatch, recordAuth]);

  const setupReaderCallbacks = useCallback(async () => {
    if (!connection) return;
    connection.on("StepChange", async () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "manualStageChange"
        })
      );
      await sleep(500);
      dispatch(setRefreshRecord(true));
    });

    connection.on("ContinueWorkflow", () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "projectProgressedToNextStage"
        })
      );
      dispatch(setRefreshRecord(true));
    });

    connection.on("RollBackWorkflow", () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "projectRolledBackToPreviousStage"
        })
      );
      dispatch(setRefreshRecord(true));
    });

    connection.on("WorkflowReviewed", () => {
      if (!recordAuth) return;
      dispatch(
        getRecordWorkflowReviewAsync({
          recordAuth,
          recordType: scope
        })
      );

      dispatch(
        getRecordWorkflowReviewersAsync({
          recordAuth,
          recordType: scope
        })
      );
    });

    connection.on("ProjectRecycled", () => {
      dispatch(
        setCurrentNotification({
          level: NotificationLevel.Information,
          message: "",
          title: "projectDeleted"
        })
      );
      navigate("../../");
    });
  }, [user, connection, dispatch]);

  const startConnection = useCallback(
    async (canEdit: boolean) => {
      if (!recordAuth || !connection) return;
      connection
        .start()
        .then(async (result: any) => {
          const coAuthoringAccessDto = {
            recordId: params.recordId,
            recordType: scope
          };
          if (canEdit) {
            dispatch(resetCoAuth());
            try {
              connection.invoke("StartRecordEdit", coAuthoringAccessDto);
            } catch (e) {
              console.log("error", e);
            }
          } else {
            try {
              connection.invoke("StartRecordReadOnly", coAuthoringAccessDto);
            } catch (e) {
              console.log("error", e);
            }
          }
        })
        .catch((e: any) => {
          console.log("Connection failed: ", e);
        });
    },
    [dispatch, connection, recordAuth, params.recordId, scope]
  );

  const closeConnection = useCallback(() => {
    if (connection?.state !== "Connected") return;
    connection.invoke("CleanUp").then(() => {
      connection.stop();
    });
  }, [connection]);

  useEffect(() => {
    dispatch(resetCoAuth());
    // connectToSignalR();
    return () => {
      resetCoAuth();
    };
  }, []);

  useEffect(() => {
    dispatch(getAIEnabledAsync());
  }, []);

  useEffect(() => {
    if (connection && user && !hasInitialized) {
      const canConnect = !!accessRoles && hasAccessRole(RecordAccessRole.Read, accessRoles);
      const canEdit =
        canConnect && !!accessRoles && hasAccessRole(RecordAccessRole.Update, accessRoles);

      if (canConnect) {
        if (canEdit) {
          setupEditorCallbacks();
        }
        setupReaderCallbacks();
        startConnection(canEdit);
      }
      setHasInitialized(true);
    }
    return () => {
      if (connection?.state !== "Connected") return;
      closeConnection();
    };
  }, [connection, user, hasInitialized]);

  const getProjectData = useCallback(
    async (refresAuthToken: boolean) => {
      dispatch(setRecordReadOnly(false));
      dispatch(setRecordTableSliceRecordId(parseInt(params.recordId as string)));
      try {
        const authObject = refresAuthToken
          ? await dispatch(
              getRecordAccessTokenAsync({
                recordId: params.recordId as any,
                recordType: scope
              })
            ).unwrap()
          : (recordAuth as RecordAuth);

        if (!hasAccessRole(RecordAccessRole.Read, authObject.details.AccessRoles)) return;

        // Any dispatch calls that we don't need to wait for go here
        dispatch(getRecordFieldsAsync());
        dispatch(
          getRecordUserLikedAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          getRecordUserRatingAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          getRecordViewValuesAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          getRecordViewTableRowsAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          getRecordViewPendingTableRowsAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          getRecordTablesConfigurationAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(
          postRecordViewsAsync({
            recordAuth: authObject,
            recordType: scope
          })
        );
        dispatch(getMyResourcesAsync());
        dispatch(getCalendarsAsync());

        const record = await dispatch(
          getIndividualRecordAsync({
            recordAuth: authObject,
            recordType: scope
          })
        ).unwrap();

        if (record?.isDeleted) {
          const tenantStorage = localStorage.getItem("tenant");
          const instanceStorage = localStorage.getItem("instance");
          const path = `/${tenantStorage}/${instanceStorage}/recorddeleted`;
          navigate(path);
          return;
        }

        if (!location?.state?.record) {
          location.state = { record: record, title: record.recordType };
        }

        const projectWorkflow = await dispatch(getRecordWorkflowAsync(record.workflow)).unwrap();
        const currentStage = projectWorkflow.stages.find(
          (stage: WorkflowStage) => stage.id === record.workflowStage
        ) as WorkflowStage;

        const projectForm = await dispatch(getRecordFormAsync(currentStage.formId)).unwrap();

        const view = currentStage?.views?.[0];
        enumerateTables(projectForm, table => {
          const isHiddenInView = view?.viewTables?.find(
            viewTable =>
              viewTable.tableId === table.tableId &&
              viewTable.state === WorkflowStageViewFieldState.Hidden
          );
          if (isHiddenInView) return;
          dispatch(getIndividualTableAsync(table.tableId as number));
          dispatch(
            getRecordViewPeriodicGrandTotalsAsync({
              recordAuth: authObject,
              recordType: scope,
              tableId: table.tableId as number
            })
          );
        });

        dispatch(getRecordWorkflowStageAsync(authObject, record.workflowStage));
      } catch (rejectedValueOrSerializedError) {
        /* empty */
      }
    },
    [dispatch, params.recordId, scope, recordAuth, location]
  );

  const getVersionData = useCallback(async () => {
    const authObject = recordAuth as RecordAuth;
    // const authObject = await getAuthObject();
    if (!hasAccessRole(RecordAccessRole.Read, authObject.details.AccessRoles)) return;

    if (currentVersion?.id === 0 || !currentVersion) {
      getProjectData(false);
    } else if (currentVersion?.id && currentVersion?.id > 0) {
      dispatch(setRecordReadOnly(true));
      const response = await getRecordVersion(currentVersion.id, scope);
      const recordVersion = response.data;
      dispatch(setRecordVersion(recordVersion));
      dispatch(setRecordVersionCalendars(recordVersion.versionData.calendars));
      dispatch(setRecordVersionResources(recordVersion.versionData?.resources));
      dispatch(setRecordVersionViewTableRows(recordVersion.versionData.record.tableRows));
      dispatch(setRecordTables(recordVersion.versionData.tables));
    }
  }, [currentVersion?.id, dispatch, recordAuth]);

  const initialise = async () => {
    await connectToSignalR();
    getProjectData(true);
  };

  useEffect(() => {
    initialise();

    //unmount
    return () => {
      dispatch(resetRecordTableSlice());
      dispatch(resetRecordSlice());
    };
  }, [location.pathname]);

  useEffect(() => {
    const refresh = async () => {
      if (!refreshProject) return;
      getProjectData(true);
      dispatch(setRefreshRecord(false));
    };

    refresh();
  }, [refreshProject]);

  useEffect(() => {
    if (rowsToUpdate.length === 0) return;
    const notifications = generateApprovedRowsNotification(rowsToUpdate, tables, t);
    dispatch(
      setCurrentNotification({
        level: NotificationLevel.Information,
        title: t("rowsUpdated"),
        message: notifications
      })
    );
  }, [rowsToUpdate]);

  useEffect(() => {
    if (!currentVersion) return;
    getVersionData();
  }, [currentVersion]);

  const MemoizedProjectTabs = useMemo(
    () => <ProjectTabs scope={scope} recordId={params.recordId} />,
    [params.recordId, scope]
  );

  useEffect(() => {
    //Show a warning if the user tries to leave the page with unsaved changes
    if (!recordDataInitialised) return;
    if (blocker.state !== "blocked") return;

    const text = `${t("savingInProgress")} \n${t("unsavedChanges")} \n${t("leavePageQuestion")}`;
    const result = window.confirm(text);
    if (result) {
      blocker.proceed();
    }
  }, [blocker.state]);

  return (
    <Stack spacing={1} className="project-record-root">
      <RecordExpiredSnackbar
        open={expiredTokenSnackbarOpen}
        onClose={() => setExpiredTokenSnackbarOpen(false)}
      />
      {getFlyoutData}
      <Paper sx={{ p: 2 }}>
        {recordDataInitialised && recordTableDataInitialised && params.recordId ? (
          MemoizedProjectTabs
        ) : (
          <div className="circular-progress-container">
            <Loading size={70} />
          </div>
        )}
      </Paper>
    </Stack>
  );
};

export default RecordPage;
