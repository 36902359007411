import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PersonaEntity, RecordRole, RecordType, TemplatePermission } from "enada-common";
import { useGetTemplateQuery } from "../../../../services/api";
import { useAppDispatch } from "store/hooks";
import {
  clearIndividualTemplate,
  updateAssignedRoles,
  updateIndividualTemplate
} from "store/slices/templatesSlice";

const convertToPersonEntity = (permissions: TemplatePermission[], role: RecordRole) =>
  permissions
    .filter(p => p.role === role)
    .map(
      owner =>
        ({
          entityId: owner.entityId,
          type: owner.permissionType
        } as PersonaEntity)
    );

export const useGetTemplatePeople = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetTemplateQuery(state.typeId, {
    skip: !state.typeId
  });

  const owners = useMemo(
    () => convertToPersonEntity(data?.templatePermissions ?? [], RecordRole.Owner),
    [data?.templatePermissions]
  );
  const managers = useMemo(
    () => convertToPersonEntity(data?.templatePermissions ?? [], RecordRole.Manager),
    [data?.templatePermissions]
  );
  const readers = useMemo(
    () => convertToPersonEntity(data?.templatePermissions ?? [], RecordRole.Reader),
    [data?.templatePermissions]
  );
  const ideators = useMemo(
    () => convertToPersonEntity(data?.templatePermissions ?? [], RecordRole.Ideator),
    [data?.templatePermissions]
  );

  useEffect(() => {
    if (data) {
      dispatch(updateIndividualTemplate(data));
    } else {
      dispatch(
        updateIndividualTemplate({
          name: "",
          recordType: RecordType.Projects,
          templatePermissions: [],
          workflow: -1
        })
      );
    }

    if (owners.length > 0)
      dispatch(updateAssignedRoles({ role: RecordRole.Owner, personas: owners }));

    if (managers.length > 0)
      dispatch(updateAssignedRoles({ role: RecordRole.Manager, personas: managers }));

    if (readers.length > 0)
      dispatch(updateAssignedRoles({ role: RecordRole.Reader, personas: readers }));

    if (ideators.length > 0)
      dispatch(updateAssignedRoles({ role: RecordRole.Ideator, personas: ideators }));

    return () => {
      dispatch(clearIndividualTemplate());
    };
  }, [dispatch, data, owners, managers, readers, ideators]);

  return { isLoading, owners, managers, readers, ideators };
};
