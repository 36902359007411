import {
  PopulationOperationType,
  RecordTableConfiguration,
  RecordTableRow,
  RowOperationType,
  TableRowPeriodicValues,
  TableRowValue,
  WorkflowDataPopulationTableConfiguration,
  WorkflowDataPopulationTableRowFieldValue,
  WorkflowDataPopulationTableRowPeriodicValue,
  WorkflowPopulationTableRow
} from "enada-common";
import { getUniqueIdFromRow, mergePopulationTableOperationTypes } from "../tableHelpers";

export const mergeTableOperations = (
  existingOperations: PopulationOperationType[],
  newOperations: RowOperationType[]
): PopulationOperationType[] => {
  const mappedOperations: PopulationOperationType[] = newOperations.map(newOperation => ({
    operationType: newOperation.operationType,
    tableRows: mapRecordRowsToPopulation(newOperation.tableRows)
  }));

  const mergedOperations = mergePopulationTableOperationTypes(existingOperations, mappedOperations);

  return mergedOperations;
};

export const mapRecordToPopulationTableOperations = (
  operations: RowOperationType[],
  dataPopulationId: number,
  originalRows: RecordTableRow[]
): PopulationOperationType[] => {
  const mapped: PopulationOperationType[] = operations.map(operation => ({
    operationType: operation.operationType,
    tableRows: operation.tableRows.map(row => {
      const result = {
        ...row,
        id: originalRows.find(
          rowToFind => getUniqueIdFromRow(rowToFind) === getUniqueIdFromRow(row)
        )?.id,
        dataPopulationId,
        tableRowFieldValues: row?.tableRowFieldValues?.map(fieldValue => ({
          ...fieldValue,
          tableRowId: fieldValue.recordTableRowId
        })),

        tableRowPeriodicValues: row.tableRowPeriodicValues?.map(fieldValue => ({
          ...fieldValue,
          tableRowId: fieldValue.recordTableRowId
        }))
      };
      return result;
    })
  }));
  return mapped;
};

export const mapPopulationToRecordRows = (tempRows: WorkflowPopulationTableRow[]) => {
  const result: RecordTableRow[] = tempRows.map(row => ({
    ...row,
    recordId: row.dataPopulationId as number,
    tableRowFieldValues: row.tableRowFieldValues?.map(
      fieldValue =>
        ({
          ...fieldValue,
          recordTableRowId: fieldValue.tableRowId
        } as TableRowValue)
    ),
    tableRowPeriodicValues: row.tableRowPeriodicValues?.map(
      fieldValue =>
        ({
          ...fieldValue,
          recordTableRowId: fieldValue.tableRowId
        } as TableRowPeriodicValues)
    )
  }));
  return result;
};

export const mapRecordRowsToPopulation = (tempRows: RecordTableRow[]) => {
  const result: WorkflowPopulationTableRow[] = tempRows.map(row => ({
    ...row,
    dataPopulationId: row.recordId,
    tableRowFieldValues: row.tableRowFieldValues?.map(
      fieldValue =>
        ({
          ...fieldValue,
          tableRowId: fieldValue.recordTableRowId
        } as WorkflowDataPopulationTableRowFieldValue)
    ),
    tableRowPeriodicValues: row.tableRowPeriodicValues?.map(
      fieldValue =>
        ({
          ...fieldValue,
          tableRowId: fieldValue.recordTableRowId
        } as WorkflowDataPopulationTableRowPeriodicValue)
    )
  }));
  return result;
};

export const mapPopulationToRecordTableConfigs = (
  populationConfigs: WorkflowDataPopulationTableConfiguration[]
) => {
  const result: RecordTableConfiguration[] = populationConfigs.map(config => ({
    ...config,
    recordId: config.dataPopulationId as number,
    tableId: config.tableId as number
  }));
  return result;
};
export const mapRecordToPopulationTableConfigs = (tableConfigs: RecordTableConfiguration[]) => {
  const result: WorkflowDataPopulationTableConfiguration[] = tableConfigs.map(config => ({
    ...config,
    dataPopulationId: config.recordId
  }));
  return result;
};
