import { currencies, Field, FieldDataType } from "enada-common";

export const getDefaultFieldByDataType = (type: FieldDataType): Partial<Field> => {
  const baseConfig = { displayAsIcon: false };
  switch (type) {
    case FieldDataType.Number:
      return {
        min: null,
        max: null,
        configuration: { ...baseConfig, decimalPlaces: 1 }
      };
    case FieldDataType.Percentage:
      return {
        min: null,
        max: null,
        configuration: { ...baseConfig, decimalPlaces: 1 }
      };
    case FieldDataType.Currency:
      return {
        min: null,
        max: null,
        currency: Object.keys(currencies)[0],
        configuration: { ...baseConfig, decimalPlaces: 1 }
      };
    case FieldDataType.TextBox:
      return {
        configuration: {
          ...baseConfig,
          minimumLength: null,
          maximumLength: null
        }
      };
    default:
      return { configuration: { ...baseConfig } };
  }
};
