import {
  BaseRecord,
  LicenseType,
  PermissionType,
  RecordPermission,
  RecordRole,
  RecordTemplate,
  RecordType,
  toCamelCase
} from "enada-common";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  postRecord,
  postProgramAssociation,
  postBusinessCaseAssociation,
  postChallengeAssociation,
  postIdeaAssociation,
  getIndividualRecord,
  getAccessToken
} from "../../services/APIService";
import { Loading } from "enada-components";
import "./createrecordmodal.scss";
import CreateRecordConfirmClosure from "./steps/confirmclosure/CreateRecordConfirmClosure";
import CreateRecordCopyData from "./steps/copydata/CreateRecordCopyData";
import { CreateRecordCreated } from "./steps/created/CreateRecordCreated";
import CreateRecordError from "./steps/error/CreateRecordError";
import CreateRecordOwners from "./steps/owners/CreateRecordOwners";
import CreateRecordTemplate from "./steps/template/CreateRecordTemplate";
import CreateRecordType from "./steps/type/CreateRecordType";
import CreateRecordIdeators from "./steps/ideators/CreateRecordIdeators";
import CreateRecordSelectChallenge from "./steps/challenges/CreateRecordSelectChallenge";
import CreateRecordIdeaSelect from "./steps/ideaselect/CreateRecordIdeaSelect";
import { finishingSteps, standardSteps, Steps, stepsFactory } from "./stepFactory/stepFactory";
import { getAllowedRecordTypes } from "./getAllowedRecordTypes";
import { getHeadersFromAuth, selectWorkflowStage } from "../../store/slices/recordSlice";
import { useSelectTemplatesByRecordType } from "../../utils/hooks/useSelectTemplatesByRecordType";
import { useGetMyLicenseQuery, useGetTemplateQuery, useGetUserQuery } from "../../services/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearIndividualTemplate } from "../../store/slices/templatesSlice";
import { RecordAssociationMapping, RenderStep } from "../../types/createRecordModal";
import { inputSelectInstance, inputSelectTenant } from "../../store/slices/userSlice";

const baseRecord = {
  timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone
};

export interface CreateProjectModalProps {
  open: boolean;
  onClose: () => void;
  sourceRecord?: BaseRecord | null;
  recordTypeToCreate?: RecordType;
}

const CreateRecordModal: FC<CreateProjectModalProps> = ({
  open,
  onClose,
  sourceRecord,
  recordTypeToCreate
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const instance = useAppSelector(inputSelectInstance);
  const tenant = useAppSelector(inputSelectTenant);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const { data: user } = useGetUserQuery();
  const { data: myLicense } = useGetMyLicenseQuery();

  const [recordTypeSelectedInWizard, setRecordTypeSelectedInWizard] = useState<boolean>(false);
  const [parentSelectedInWizard, setParentSelectedInWizard] = useState<boolean>(false);
  const [allowedRecordTypes, setAllowedRecordTypes] = useState<RecordType[] | undefined>();

  const [steps, setSteps] = useState<Steps[]>(standardSteps);
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.Type); // Default the first step to selecting the record type to create
  const [record, setRecord] = useState<Partial<BaseRecord>>(baseRecord);
  const [parentRecord, setParentRecord] = useState<Partial<BaseRecord>>(baseRecord);
  const [association, setAssociation] = useState<RecordAssociationMapping>({});
  const [createdError, setCreatedError] = useState();
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);

  const { data: currentTemplate, isLoading: templateIsLoading } = useGetTemplateQuery(
    record.recordTemplateId ?? 0,
    {
      skip: !record.recordTemplateId
    }
  );

  const projectTemplates = useSelectTemplatesByRecordType(RecordType.Projects);

  const moveStep = (direction: number) => {
    const index = steps.indexOf(currentStep);
    if (index >= 0 && index < steps.length - 1) {
      setCurrentStep(steps[index + direction]);
    }
  };

  const addIdeaPermissions = (permissions: RecordPermission[] = []) => {
    //Adding the permissions for the creator of the idea

    const recordPermissions = permissions
      .filter(e => e.role === RecordRole.Ideator)
      .filter(e => e.entityId != user?.id);
    return recordPermissions;
  };

  const isStepIndex = (index: number) => {
    return currentStep === steps[index];
  };

  const isCurrentStep = (step: Steps) => {
    return currentStep === step;
  };

  const isFirstStep = () => {
    return isStepIndex(0);
  };

  const isLastStep = () => {
    return isStepIndex(steps.length - finishingSteps.length - 1);
  };

  const isVeryLastStep = () => {
    return isStepIndex(steps.length - 1);
  };

  const stepToFirst = () => {
    setCurrentStep(steps[0]);
  };

  const stepToLast = () => {
    setCurrentStep(steps[steps.length - 1]);
  };

  const stepForward = () => {
    moveStep(+1);
  };

  const stepBackward = () => {
    moveStep(-1);
  };

  useEffect(() => {
    if (recordTypeSelectedInWizard && steps[0] === Steps.Type) setCurrentStep(steps[1]);
    else setCurrentStep(steps[0]);
  }, [steps, recordTypeSelectedInWizard]);

  useEffect(() => {
    if (parentSelectedInWizard) return;
    const newSteps = stepsFactory(record.recordType, parentRecord.recordType);
    if (
      !sourceRecord &&
      currentStep === 1 &&
      myLicense?.licenseType &&
      myLicense.licenseType === LicenseType.Lite
    ) {
      //Lite users can only create ideas so we change states to skip the type selection step
      setRecord(prev => ({ ...prev, recordType: RecordType.Ideas }));
      setSteps(newSteps.filter(el => el !== Steps.Type));
    } else {
      setSteps(newSteps);
      setCurrentStep(newSteps[0]);
    }
  }, [record.recordType, parentRecord.recordType, parentSelectedInWizard]);

  useEffect(() => {
    if (sourceRecord) {
      const fetchData = async () => {
        dispatch(clearIndividualTemplate());

        const additionalHeaders = new Headers();

        additionalHeaders.append("edison365-sessionid", "frontend");
        const tokenResult = (
          await getAccessToken(
            sourceRecord?.id as number,
            sourceRecord.recordType,
            additionalHeaders
          )
        ).data;

        const projectAuth = {
          permissionToken: tokenResult.permissionToken,
          sessionId: "frontend",
          details: tokenResult.details
        };

        const response = await getIndividualRecord(
          sourceRecord.id as number,
          sourceRecord.recordType,
          getHeadersFromAuth(projectAuth)
        );
        let recordPermissions = [...response.data.recordPermissions];
        if (recordTypeToCreate === RecordType.Ideas)
          recordPermissions = addIdeaPermissions(recordPermissions);
        setRecord(prev => ({
          ...prev,
          recordTemplateId: response.data.recordTemplateId,
          recordPermissions: recordPermissions
        }));

        setParentRecord(response.data);
      };

      fetchData();
    }
  }, [sourceRecord, dispatch]);

  useEffect(() => {
    if (currentStep === Steps.Name && record.recordType === RecordType.Ideas) {
      let recordPermissions = record.recordPermissions;
      recordPermissions = addIdeaPermissions(recordPermissions);
      setRecord(prev => ({
        ...prev,
        recordPermissions: recordPermissions
      }));
    }
  }, [currentStep]);

  useEffect(() => {
    if (recordTypeToCreate) setRecord(prev => ({ ...prev, recordType: recordTypeToCreate }));
  }, [recordTypeToCreate]);

  useEffect(() => {
    // If we have a sourceRecord we need to wait until we have retireved the full record and set it parentRecord before we can set the allowedRecordTypes
    if (sourceRecord !== undefined && parentRecord.recordType === undefined) return;

    const allowedRecordTypes = getAllowedRecordTypes(
      parentRecord.recordType,
      workflowStage?.allowBusinessCaseCreation,
      workflowStage?.allowProjectCreation,
      workflowStage?.allowRecordCreation
    );

    if (allowedRecordTypes.length === 1) {
      setRecord(prev => ({ ...prev, recordType: allowedRecordTypes[0] }));
    }

    setAllowedRecordTypes(allowedRecordTypes);
  }, [parentRecord.recordType, workflowStage]);

  const onModalClose = () => {
    stepToFirst();
    setRecord(baseRecord);
    setAssociation({});
    onClose();
  };

  const recordTypeSingular = t(
    toCamelCase(
      record?.recordType === RecordType.BusinessCase
        ? record?.recordType
        : record.recordType?.slice(0, record?.recordType.length - 1) ?? "record"
    )
  );

  const renderStep = (): RenderStep => {
    // This stops unnecessary rendering
    if (!open) return { title: "", body: undefined };

    // Only start the creation wizard once we have set the allowed record types to create
    if (allowedRecordTypes === undefined) return { title: "", body: null };

    switch (currentStep) {
      case Steps.IdeaSelect:
        return {
          title: t("selectIdeaToAssociate"),
          body: (
            <CreateRecordIdeaSelect
              setIdea={idea => {
                setParentRecord(idea);
              }}
              parentRecordId={parentRecord.id}
            />
          )
        };
      case Steps.Type: {
        if (!sourceRecord && myLicense?.licenseType && myLicense.licenseType === LicenseType.Lite) {
          return { title: "", body: null };
        } else
          return {
            title: t("whatWouldYouLikeToCreate"),
            body: (
              <CreateRecordType
                onRecordSelection={(type: RecordType) => {
                  setRecord(prev => ({ ...prev, recordType: type }));
                  setRecordTypeSelectedInWizard(true);
                  stepForward();
                }}
                allowedRecordTypes={allowedRecordTypes}
              />
            )
          };
      }
      case Steps.Challenge:
        return {
          title: t("whatChallengeIsThisIdeaFor"),
          body: (
            <CreateRecordSelectChallenge
              setChallenge={(challenge?: BaseRecord): void => {
                if (challenge) {
                  setParentSelectedInWizard(true);
                  dispatch(clearIndividualTemplate());
                  const recordPermissions = addIdeaPermissions(challenge.recordPermissions);
                  setRecord(prev => ({
                    ...prev,
                    recordTemplateId: challenge.recordTemplateId,
                    recordPermissions: recordPermissions,
                    creatorAddedToOwners: false // We reset creatorAddedToOwners in case user returns back to this step
                  }));

                  setParentRecord(challenge);
                }
              }}
            />
          )
        };
      case Steps.Name:
        return {
          title:
            record.recordType === RecordType.Ideas
              ? t("whatIsYourIdea")
              : `${t("giveYour")} ${recordTypeSingular} ${t("aName")}`,
          body: (
            <TextField
              fullWidth
              data-testid="create-record-enter-name"
              label={t("pleaseEnterATitle")}
              value={record.displayName ?? ""}
              onChange={e => setRecord(prev => ({ ...prev, displayName: e.target.value }))}
            />
          )
        };
      case Steps.Description:
        return {
          title:
            record.recordType === RecordType.Ideas
              ? t("giveYourIdeaAShortDescription")
              : `${t("giveYour")} ${recordTypeSingular} ${t("aDescription")}`,
          body: (
            <TextField
              fullWidth
              data-testid="create-record-enter-description"
              label={t("createRecordDescriptionMessage")}
              value={record.shortDescription ?? ""}
              onChange={e =>
                setRecord(prev => ({
                  ...prev,
                  shortDescription: e.target.value
                }))
              }
              helperText={t("createRecordDescriptionHelperMessage")}
            />
          )
        };
      case Steps.Template:
        return {
          title: `${t("selectATemplateForYour")} ${recordTypeSingular}`,
          body: (
            <CreateRecordTemplate
              templateId={record.recordTemplateId}
              setTemplate={(id, templatePermissions) => {
                dispatch(clearIndividualTemplate());
                setRecord(prev => ({
                  ...prev,
                  recordTemplateId: id,
                  creatorAddedToOwners: false, // We reset creatorAddedToOwners in case user returns back to this step
                  recordPermissions: templatePermissions as RecordPermission[]
                }));
              }}
              recordType={record.recordType}
            />
          )
        };
      case Steps.Owners:
        return {
          title:
            record.recordType === RecordType.Ideas
              ? t("thisIdeaIsYoursAddAnyoneElse")
              : `${t("assignOwnersToYour")} ${recordTypeSingular}`,
          body: (
            <CreateRecordOwners
              recordPermissions={
                // We check if the creator has been added to the owners list, if not we add them
                record.creatorAddedToOwners
                  ? record.recordPermissions
                  : [
                      ...(record.recordPermissions ?? []),
                      {
                        entityId: user?.id ?? "",
                        permissionType: PermissionType.User,
                        role: RecordRole.Owner
                      }
                    ]
              }
              currentTemplate={currentTemplate as RecordTemplate}
              recordType={record.recordType}
              ownerRoleType={
                record.recordType === RecordType.Ideas ? RecordRole.Ideator : RecordRole.Owner
              }
              onChange={(updatedPermissions?: RecordPermission[]) =>
                setRecord(prev => ({
                  ...prev,
                  recordPermissions: updatedPermissions,
                  creatorAddedToOwners: true
                }))
              }
              isLoading={templateIsLoading}
            />
          )
        };
      case Steps.Ideators: {
        // Default the ideators to the template ideators if the user has not modified them
        if (!record.recordIdeatorsPermissions?.length) {
          const defaultIdeatorsFromTemplate = currentTemplate?.templatePermissions
            ?.filter(perm => perm.role === RecordRole.Ideator)
            .map(entity => ({
              entityId: entity.entityId,
              permissionType: entity.permissionType,
              role: RecordRole.Ideator
            }));
          setRecord(prev => ({
            ...prev,
            recordIdeatorsPermissions: defaultIdeatorsFromTemplate
          }));
        }

        return {
          title: `${t("assignIdeatorsToYour")} ${recordTypeSingular}`,
          body: (
            <CreateRecordIdeators
              recordIdeators={record.recordIdeatorsPermissions}
              onChange={(updatedPermissions?: RecordPermission[]) =>
                setRecord(prev => ({
                  ...prev,
                  recordIdeatorsPermissions: updatedPermissions
                }))
              }
              isLoading={templateIsLoading}
            />
          )
        };
      }
      case Steps.CopyData:
        return {
          title: t("copyYourData"),
          body: (
            <CreateRecordCopyData
              templateId={record.recordTemplateId as number}
              recordId={parentRecord?.id as number}
              baseAssociation={association}
              setFields={fields => setAssociation(prev => ({ ...prev, fields: fields }))}
              setTables={tables => setAssociation(prev => ({ ...prev, tables: tables }))}
            />
          )
        };
      case Steps.Loading:
        return {
          title: `${t("weAreBusyCraftingYour")} ${recordTypeSingular}. ${t(
            "thisMayTakeAFewMoments"
          )}`,
          body: (
            <Stack width={"100%"} alignItems={"center"}>
              <Loading size={80} />
            </Stack>
          )
        };
      case Steps.Created:
        return {
          title: `${t("done!")} ${t("your")} ${recordTypeSingular} ${t("isCreated")}.`,
          body: (
            <CreateRecordCreated
              association={
                parentRecord.recordType === RecordType.BusinessCase ? association : undefined
              }
              type={recordTypeSingular}
              createAnotherAction={() => {
                setRecordTypeSelectedInWizard(false);
                setRecord(
                  recordTypeToCreate
                    ? { ...baseRecord, recordType: recordTypeToCreate }
                    : baseRecord
                );
                stepToFirst();
                setAssociation({});
              }}
              openAction={() => {
                const path = `/${tenant}/${instance}/${record.recordType?.toLowerCase()}/${
                  record.id
                }`;

                navigate(path, {
                  replace: true,
                  state: {
                    record
                  }
                });
                onModalClose();
              }}
            />
          )
        };
      case Steps.Error:
        return {
          title: `${t("oopsYour")} ${recordTypeSingular} ${t("wasNotCreated")}`,
          body: (
            <CreateRecordError
              error={createdError}
              type={recordTypeSingular}
              onRetry={() => {
                stepToLast();
              }}
            />
          )
        };
      default:
        return { title: "", body: undefined };
    }
  };

  const progressAction = async () => {
    stepForward();

    if (!isLastStep()) {
      return;
    }

    let response: any;
    const recordData = { ...record };

    switch (parentRecord.recordType) {
      case RecordType.Programs:
        response = await postProgramAssociation(record, parentRecord.id as number);
        break;
      case RecordType.Challenges:
        response = await postChallengeAssociation(
          { ...record, recordTemplateId: parentRecord.recordTemplateId },
          parentRecord.id as number
        );
        break;
      case RecordType.BusinessCase:
        response = await postBusinessCaseAssociation(parentRecord?.id as number, {
          ...association,
          ...baseRecord,
          recordPermissions: record.recordPermissions,
          recordType: record.recordType,
          recordTemplateId: record.recordTemplateId,
          displayName: record?.displayName ?? "",
          shortDescription: record?.shortDescription ?? ""
        });
        break;
      case RecordType.Ideas:
        response = await postIdeaAssociation(parentRecord.id as number, record as BaseRecord);
        break;
      default:
        if (record.recordType === RecordType.Challenges)
          recordData.recordPermissions = [
            ...(record.recordPermissions ?? []),
            ...(record.recordIdeatorsPermissions ?? [])
          ];
        response = await postRecord(recordData as BaseRecord, record.recordType as RecordType);
        break;
    }
    handleCreationResponse(response);
  };

  const handleCreationResponse = (response: unknown) => {
    if ((response as any).status.toString().startsWith("20")) {
      setCurrentStep(Steps.Created);
      setRecord((response as any).data as BaseRecord);
    } else {
      setCurrentStep(Steps.Error);
      const errors: any[] = (response as any)?.errors;
      setCreatedError(errors?.at(0) ?? (response as any).detail);
    }
  };

  const getProgressLength = () => {
    if (finishingSteps.includes(currentStep)) return "100%";
    const percent = (steps.indexOf(currentStep) / (steps.length - finishingSteps.length)) * 100;
    return `${percent}%`;
  };

  const disableNextButton =
    (isCurrentStep(Steps.Name) && !record.displayName) ||
    (isCurrentStep(Steps.Owners) && templateIsLoading) ||
    (isCurrentStep(Steps.Template) && record?.recordTemplateId === undefined) ||
    (isCurrentStep(Steps.Owners) &&
      !record?.recordPermissions?.some(x => x.role === RecordRole.Owner)) ||
    (isCurrentStep(Steps.IdeaSelect) && parentRecord.recordType !== RecordType.Ideas);

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {confirmCloseModal === true ? (
        <CreateRecordConfirmClosure
          onConfirm={onModalClose}
          onGoBack={() => setConfirmCloseModal(false)}
        />
      ) : (
        <>
          <DialogTitle>{renderStep().title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (isFirstStep() || isCurrentStep(Steps.Created) || isCurrentStep(Steps.Error)) {
                onClose();
                return;
              }
              setConfirmCloseModal(true);
            }}
            sx={theme => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon />
          </IconButton>
          <Box
            height={"5px"}
            width={getProgressLength()}
            marginBottom={"-5px"}
            sx={{
              background: "linear-gradient(90deg, #1E92D3 0%, #FA00FF 49.48%, #FFB800 98.44%)"
            }}
          />
          <DialogContent
            dividers
            className={`create-record-modal-root ${
              [Steps.Template, Steps.Challenge].includes(currentStep) && "wider"
            }`}
          >
            {projectTemplates.length < 1 && record?.recordType === RecordType.Projects ? (
              <Box className="msg-no-templates">{t("noTemplatesPermission")}</Box>
            ) : (
              renderStep().body
            )}
          </DialogContent>
          {[
            Steps.IdeaSelect,
            Steps.Challenge,
            Steps.Name,
            Steps.Description,
            Steps.Template,
            Steps.Owners,
            Steps.Ideators,
            Steps.CopyData
          ].includes(currentStep) && (
            <DialogActions>
              <Button
                disabled={disableNextButton}
                variant="contained"
                onClick={progressAction}
                data-testid="create-record-progress-action-button"
              >
                {isLastStep() ? t("create") : t("next")}
              </Button>
              <Button
                disabled={isFirstStep()}
                data-testid="create-record-progress-back-button"
                variant="outlined"
                onClick={() => (isVeryLastStep() ? onModalClose() : stepBackward())}
              >
                {isVeryLastStep() ? t("close") : t("back")}
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default CreateRecordModal;
