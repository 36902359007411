import { Paper, Skeleton, Stack } from "@mui/material";
import { BaseRecord, RecordType } from "enada-common";
import { EdisonCarousel, EdisonTypography } from "enada-components";
import buildQuery from "odata-query";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RecordContentCard from "../../../components/recordcontentcard/RecordContentCard";
import RecordTypeChip from "../../../components/recordtypechip/RecordTypeChip";
import { getRecords } from "../../../services/APIService";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./homerecordcarousel.scss";
import { useNavigate } from "react-router-dom";
import {
  defaultHomePageFilterOrder,
  HomePageFilterKey,
  selectHomePageFiltersbyKey,
  toggleHomePageFilter
} from "../../../store/slices/discoverFiltersSlice";
import { useHasModule } from "../../../utils/hooks/useHasModule";
import { inputSelectInstance, inputSelectTenant } from "../../../store/slices/userSlice";
import { useGetWorkflowStagesQuery } from "services/api";

export interface HomeRecordCarouselProps {
  filterKey: HomePageFilterKey;
  orderBy: string;
}
const HomeRecordCarousel: FC<HomeRecordCarouselProps> = ({ orderBy, filterKey }) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: stages = [] } = useGetWorkflowStagesQuery();

  const tenant = useAppSelector(inputSelectTenant);
  const instance = useAppSelector(inputSelectInstance);
  const types = useAppSelector(state => selectHomePageFiltersbyKey(state, filterKey));
  const { isModuleEnabled } = useHasModule();
  const [expandedRecord, setExpandedRecord] = useState<number | undefined>(undefined);
  const [data, setData] = useState<BaseRecord[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const filter = { recordType: { in: types } };
      const fullFilter = {
        filter,
        orderBy,
        expand: "owners",
        select:
          "id,favourited,workflowStage,recordType,startDate,endDate,displayName,shortDescription,imageUrl,owners,averageRating,percentDone,workflowStageChanged,created,approvedCount,rejectedCount,requiredApproversCount,canCreateAssociations,childRecordCount"
      };

      const response = await getRecords(buildQuery(fullFilter));
      if (response.value) {
        setData(response.value);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  const getRecordCard = (record: BaseRecord) => {
    return (
      <RecordContentCard
        stages={stages}
        record={record}
        isExpanded={expandedRecord ? expandedRecord === record.id : false}
        setExpandedRecord={setExpandedRecord}
        onRecordClick={() => {
          navigate(`/${tenant}/${instance}/${record.recordType.toLocaleLowerCase()}/${record.id}`);
        }}
      />
    );
  };

  return (
    <Paper>
      <Stack className="home-record-carousel-root">
        <EdisonCarousel
          topBarModule={
            <Stack direction="row" spacing={1} className="top-bar-module">
              <Stack direction="row" className="title-container" spacing={0}>
                <EdisonTypography title={t(filterKey)} variant="h1" />
              </Stack>
              <Stack direction="row" spacing={1}>
                {defaultHomePageFilterOrder
                  .filter(type => isModuleEnabled(type as RecordType))
                  .map(type => (
                    <RecordTypeChip
                      key={type}
                      recordType={type as RecordType}
                      onClick={() => {
                        dispatch(
                          toggleHomePageFilter({ key: filterKey, value: type as RecordType })
                        );
                      }}
                      active={types?.includes(type as RecordType)}
                    />
                  ))}
              </Stack>
            </Stack>
          }
          data={data}
          getCard={getRecordCard}
          noDataModule={
            <Stack direction={"row"} spacing={4} px={4} py={2}>
              <Skeleton variant="rounded" height={380} width={280} sx={{ borderRadius: "12px" }} />
              <Skeleton variant="rounded" height={380} width={280} sx={{ borderRadius: "12px" }} />
              <Skeleton variant="rounded" height={380} width={280} sx={{ borderRadius: "12px" }} />
              <Skeleton variant="rounded" height={380} width={280} sx={{ borderRadius: "12px" }} />
            </Stack>
          }
        />
      </Stack>
    </Paper>
  );
};
export default HomeRecordCarousel;
