import { EdisonPeoplePickerFieldProps, PermissionType, PersonaEntity } from "enada-common";
import { FC, useEffect, useState } from "react";
import { EdisonPeoplePickerField } from "enada-components";
import { batchGetSingleEntitiesById, GraphQueryType } from "services/graphService";
import { IDynamicPerson } from "@microsoft/mgt-components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PeoplePickerWithProps extends EdisonPeoplePickerFieldProps {}

const PeoplePickerWith: FC<PeoplePickerWithProps> = ({ value, onChange, ...props }) => {
  const [inivisbleUsers, setInvisibleUsers] = useState<PersonaEntity[] | undefined>([]);
  const [selectedUsers, setSelectedUsers] = useState<PersonaEntity[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // split value into user and groups returning array of Ids

  useEffect(() => {
    const fetchData = async () => {
      const userIds: string[] = [];
      const groupIds: string[] = [];
      value?.forEach(person => {
        if (person.type === PermissionType.User) {
          userIds.push(person.entityId);
        } else if (person.type === PermissionType.Group) {
          groupIds.push(person.entityId);
        }
      });

      let usersList: IDynamicPerson[] = [];
      let groupsList: IDynamicPerson[] = [];
      if (userIds.length > 0) {
        usersList = await batchGetSingleEntitiesById(userIds, GraphQueryType.Users);
      }
      if (groupIds.length > 0) {
        groupsList = await batchGetSingleEntitiesById(groupIds, GraphQueryType.Groups);
      }

      const merged = usersList.concat(groupsList);
      // compare returned users and groups to the ids passed in
      // any that exist in the input value but not in the returned data will be deleted or invisible users
      // store these in the state
      const inivisbleUsers = value?.filter(
        ({ entityId: id1 }) => !merged.some(({ id: id2 }) => id2 === id1)
      );
      const genuineUsers = value?.filter(({ entityId: id1 }) =>
        merged.some(({ id: id2 }) => id2 === id1)
      );
      setInvisibleUsers(inivisbleUsers);
      setSelectedUsers(genuineUsers);
      setIsLoading(false);
    };

    fetchData();
  }, []); // No dependency array means this effect runs once when the component mounts

  const handleChange = (personas: PersonaEntity[]) => {
    // concatente the value of the people picker with the invisible users data
    // then return that value to the on change handler prop
    onChange(inivisbleUsers ? personas.concat(inivisbleUsers) : personas);
  };

  // implement on chage handler on people picker to concatente the value of the people picker with the invisible users data
  // then return that value to the on change handler prop
  return (
    !isLoading && (
      <EdisonPeoplePickerField {...props} value={selectedUsers} onChange={handleChange} />
    )
  );
};

export default PeoplePickerWith;
