import { RecordType } from "enada-common";
import React, { FC } from "react";
import Discover from "../../components/discover/Discover";
const BCDiscover: FC<object> = () => {
  return (
    <Discover
      hideFilters={false}
      propQuery={{
        select:
          "id,favourited,workflowStage,recordType,startDate,endDate,displayName,shortDescription,imageUrl,owners,averageRating,percentDone,workflowStageChanged,created,approvedCount,rejectedCount,requiredApproversCount",
        expand: "owners",
        orderBy: "modified desc",
        filter: {
          recordType: { in: [RecordType.BusinessCase] }
        }
      }}
      recordType={RecordType.BusinessCase}
    />
  );
};
export default BCDiscover;
