import React, { FC, useEffect, useState } from "react";
import { PersonaEntity, PermissionType } from "enada-common";
import "./peoplegroup.scss";
import {
  MgtTemplateProps,
  PersonCard,
  People,
  Person,
  ViewType,
  AvatarSize
} from "@microsoft/mgt-react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";

export interface EdisonPeopleGroupProps {
  value: PersonaEntity[];
  expandGroups?: boolean;
  avatarSize?: AvatarSize;
  maxAvatars?: number;
  isInCard?: boolean;
  internalState?: PersonaEntity[];
  viewType?: ViewType;
  getGroupMembersAsync?: (groupId: string) => Promise<any>;
  getGroupDetailsAsync?: (groupId: string) => Promise<any>;
}

interface EdisonMgtTemplateProps extends MgtTemplateProps {
  id?: string;
  currentIndex: number;
}
interface GroupMemberMap {
  id?: string;
  members: string[];
}

const EdisonPeopleGroup: FC<EdisonPeopleGroupProps> = ({
  avatarSize,
  maxAvatars,
  expandGroups,
  isInCard,
  internalState,
  value,
  viewType = "image",
  getGroupMembersAsync,
  getGroupDetailsAsync
}) => {
  const maximumAvatars = maxAvatars ? (maxAvatars < 2 ? 2 : maxAvatars) : 2;
  const groups = !!value && value.filter(entity => entity.type === PermissionType.Group);
  const users = !!value && value.filter(entity => entity.type === PermissionType.User);

  const [expandedGroups, setExpandedGroups] = useState<GroupMemberMap[]>([]);
  const [groupDetails, setGroupDetails] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [cardPosition, setCardPosition] = useState<"LEFT" | "RIGHT" | null>(null);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [selectedEntityID, setSelectedEntityID] = useState<string | null>();
  const theme = useTheme();

  const open = Boolean(anchorEl);

  useEffect(() => {
    const getMembers = async () => {
      if (!expandGroups) return;
      if (!getGroupMembersAsync) return;
      const newGroups = groups.filter(
        group => !expandedGroups.some(expandedGroup => expandedGroup.id === group.entityId)
      );
      for (const group of newGroups) {
        const result = await getGroupMembersAsync(group.entityId);
        if (result.status === 200) {
          const data = await result.json();
          setExpandedGroups(prev => [
            ...prev,
            {
              id: group.entityId,
              members: data.value.map((user: any) => user.id)
            }
          ]);
        }
      }
    };

    getMembers();
  }, [groups.length]);

  const getPeople = () => {
    // Concatenates and deduplicates all users and groupMembers
    if (!expandGroups) return (value ?? []).map(p => p.entityId);

    const allGroupsMembers: string[] = groups.reduce((acc: string[], group) => {
      const found = expandedGroups.find(expandedGroup => expandedGroup.id === group.entityId);
      if (!found) return acc;
      return [...acc, ...found.members];
    }, []);

    const merged = [...new Set([...users.map(user => user.entityId), ...allGroupsMembers])];
    return merged;
  };

  const AvatarsTemplate = (props: MgtTemplateProps) => {
    const { person } = props.dataContext;
    const isGroup = !Object.hasOwn(person, "surname");
    return (
      <Person
        className={`avatar avatar-${avatarSize}`}
        userId={person.id}
        showPresence={!isGroup}
        fetchImage
        view={viewType}
        onMouseOver={(e: React.MouseEvent<HTMLElement>) => {
          const rightMouseDistance = window.innerWidth - e.pageX;
          if (rightMouseDistance >= 350) setCardPosition("RIGHT");
          else setCardPosition("LEFT");
          if (isGroup) return;
          setAnchorEl(e.currentTarget);
          setSelectedEntityID(person.id);
        }}
        personCardInteraction="none"
        onClick={e => {
          e.stopPropagation();
          setExpanded(true);
          setCardPosition(null);
          if (isGroup) return;
          setAnchorEl(e.currentTarget);
          setSelectedEntityID(person.id);
        }}
        avatarSize={avatarSize ?? "large"}
      ></Person>
    );
  };

  const OverflowTemplate = (props: MgtTemplateProps) => {
    const { extra } = props.dataContext;
    const getOverflowAvatrSize = () => {
      switch (avatarSize) {
        case "large":
          return "40px";

        case "small":
          return "24px";

        default:
          return "32px";
      }
    };
    const getOverflowTextSize = () => {
      if (avatarSize === "small") {
        return "12px";
      }
    };
    return (
      <Avatar
        className={`avatar avatar-${avatarSize}`}
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.getContrastText(theme.palette.primary.main),
          height: getOverflowAvatrSize(),
          width: getOverflowAvatrSize(),
          fontSize: getOverflowTextSize()
        }}
      >
        +{extra}
      </Avatar>
    );
  };

  //In the case where we dont want to expand the groups, we need to get each passed groups details (e.g displayName etc...)
  //manually as the mgt component only gets a person's details automatically
  useEffect(() => {
    if (expandGroups) return;
    getGroupFallbackDetails();
  }, [expandGroups]);

  const getGroupFallbackDetails = async () => {
    if (!getGroupDetailsAsync) return;
    const groups = (value ?? []).filter(
      group => (group as any).permissionType === PermissionType.Group
    );
    for (const group of groups) {
      if (!groupDetails?.some(groupToCheck => groupToCheck.id === group.entityId)) {
        const result = await getGroupDetailsAsync(group.entityId);
        if (result.status === 200) {
          const data = await result.json();
          setGroupDetails(prev => [...prev, data]);
        }
      }
    }
  };
  //fix taken from https://github.com/microsoftgraph/microsoft-graph-toolkit/issues/3144
  // Needed because the change in value is not automatically triggering a rerender of the component
  // which leads to incorrect data render
  const uniqueKey = getPeople()?.reduce((acc, curr) => `${acc}-${curr}`, "") ?? "";

  return value ? (
    <>
      <Box className="edison-peoplegroup-root" data-testid="edison-people-group">
        <People
          key={uniqueKey}
          showMax={maximumAvatars}
          userIds={getPeople()}
          fallbackDetails={groupDetails}
        >
          <AvatarsTemplate template="person" />
          <OverflowTemplate template="overflow" />
        </People>

        {anchorEl && selectedEntityID && (!isInCard || (isInCard && expanded)) && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClick={e => {
              e.stopPropagation();
              setAnchorEl(null);
              setExpanded(false);
            }}
            slotProps={{
              paper: {
                className: expanded ? "popover-paper" : ""
              }
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <PersonCard
              userId={selectedEntityID}
              showPresence
              fetchImage
              isExpanded={expanded}
              onClick={e => {
                e.stopPropagation();
              }}
              expanded={() => {
                setExpanded(true);
              }}
              style={{ height: "full" }}
            />
          </Popover>
        )}
      </Box>
      {isInCard && anchorEl && selectedEntityID && cardPosition && (
        <PersonCard
          userId={selectedEntityID}
          showPresence
          fetchImage
          isExpanded={false}
          onClick={e => {
            e.stopPropagation();
          }}
          expanded={() => {
            setCardPosition(null);
            setExpanded(true);
          }}
          className={`personCardHover ${cardPosition == "LEFT" ? "personCardHoverLeft" : ""}`}
          onMouseOut={() => setCardPosition(null)}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default EdisonPeopleGroup;
export { EdisonPeopleGroup };
