import { useEffect, useRef } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import type { FC, PropsWithChildren } from "react";

interface ClickAwayAndTabListenerProps {
  onClickAway: (event: Event) => void;
}

export const ClickAwayAndTabListener: FC<PropsWithChildren<ClickAwayAndTabListenerProps>> = ({
  children,
  onClickAway
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleFocusOut = (event: FocusEvent) => {
      if (ref.current && !ref.current.contains(event.relatedTarget as Node)) {
        onClickAway(event);
      }
    };

    const node = ref.current;
    if (node) {
      node.addEventListener("focusout", handleFocusOut);
    }

    return () => {
      if (node) {
        node.removeEventListener("focusout", handleFocusOut);
      }
    };
  }, [onClickAway]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div ref={ref}>{children}</div>
    </ClickAwayListener>
  );
};
