import React, { FC } from "react";
import { EdisonTabs } from "enada-components";
import NotificationDrawer from "../notificationdrawer/NotificationDrawer";
import AllNotificationTab from "../notificationtabs/AllNotificationTab";
import NewNotificationTab from "../notificationtabs/NewNotificationTab";
import { selectBackendNotifications } from "../../../store/slices/notificationSlice";
import { useAppSelector } from "../../../store/hooks";
import { useTranslation } from "react-i18next";

const NotificationFlyout: FC<{ readOnly: boolean }> = () => {
  const { t } = useTranslation(["common"]);
  const notifications = useAppSelector(selectBackendNotifications);

  const getStatusMessage = () => {
    const newNotifications = notifications.filter(notification => !notification.isRead);

    let statusMessage = t("notificationFlyoutAllClear");

    if (notifications && notifications.length && newNotifications && newNotifications.length)
      statusMessage = newNotifications.length + t("notificationFlyoutNew");

    if (notifications && notifications.length && (!newNotifications || !newNotifications.length))
      statusMessage = t("notificationFlyoutAllCaughtUp");

    return statusMessage;
  };

  const tabsContent = [
    {
      id: "new",
      label: t("notificationFlyoutTabNew"),
      children: <NewNotificationTab />
    },
    {
      id: "all",
      label: t("notificationFlyoutTabAll"),
      children: <AllNotificationTab />
    }
  ];

  return (
    <NotificationDrawer title={t("notificationFlyoutTitle")} status={getStatusMessage()}>
      <EdisonTabs
        tabs={tabsContent}
        height="90%"
        scrollable={true}
        indicatorColour={"primary"}
        tabTitleBoxStyling={{
          borderBottom: 1,
          borderColor: "divider",
          padding: "0px"
        }}
      />
    </NotificationDrawer>
  );
};
export default NotificationFlyout;
