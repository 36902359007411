import {
  FieldDataType,
  removeWhitespaces,
  RollupTableColumnModel,
  TableField,
  TableView,
  TableViewType
} from "enada-common";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  selectAllNonDeletedFields,
  selectAllNonDeletedSystemFields
} from "../../store/slices/fieldsSlice";
import { EdisonEnumSelect, SingleTable } from "enada-components";
import "./tableviewmodal.scss";
import { useGetFieldsQuery } from "services/api";

export interface TableViewModalProps {
  isOpen: boolean;
  selectedView: TableView | null | undefined;
  tableFields: TableField[] | null | undefined;
  onSave: (view: TableView) => void;
  onClose: () => void;
  isDefault: boolean;
}

export interface RowOrder {
  fieldId?: number;
  order?: number;
}
const TableViewModal: FC<TableViewModalProps> = ({
  isOpen,
  selectedView,
  tableFields,
  onSave,
  onClose,
  isDefault
}) => {
  const { t } = useTranslation(["common"]);
  const [view, setView] = useState<TableView>({
    name: "",
    viewType: TableViewType.Grid
  });

  const { nonDeletedFields = [], nonDeletedSystemFields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      nonDeletedFields: selectAllNonDeletedFields(result),
      nonDeletedSystemFields: selectAllNonDeletedSystemFields(result)
    })
  });

  const allFields = nonDeletedFields.concat(nonDeletedSystemFields);
  const choiceTableFields = allFields.filter(
    field =>
      tableFields?.some(tableField => tableField.fieldId === field.id) &&
      field.dataType === FieldDataType.Choice
  );
  const textTableFields = allFields.filter(
    field =>
      tableFields?.some(tableField => tableField.fieldId === field.id) &&
      field.dataType === FieldDataType.TextBox
  );

  const getUpdatedRowOrder = (tableFields: TableField[], initRowOrder?: RowOrder[]): RowOrder[] => {
    if (initRowOrder === undefined) {
      return tableFields?.map((field, index) => ({
        fieldId: field.fieldId,
        order: index
      }));
    }

    const newFields = tableFields.filter(
      tableField => !initRowOrder.some(row => row.fieldId === tableField.fieldId)
    );
    return [
      ...initRowOrder,
      ...newFields.map((newField, index) => ({
        fieldId: newField.fieldId,
        order: initRowOrder.length + index
      }))
    ];
  };

  useEffect(() => {
    if (!isOpen) return;
    if (selectedView) {
      setView({
        ...selectedView,
        fields: tableFields
          ?.filter(tableField =>
            selectedView?.fields?.some(viewField => viewField.fieldId === tableField.fieldId)
          )
          .map(field => ({
            tableViewId: selectedView.id,
            fieldId: field.fieldId
          })),
        configuration: {
          ...selectedView.configuration,
          rowOrder: getUpdatedRowOrder(
            tableFields ?? [],
            selectedView?.configuration?.rowOrder as any
          )
        }
      });
    } else {
      setView({
        name: "",
        fields: tableFields?.map(field => ({ fieldId: field.fieldId })),
        viewType: TableViewType.Grid,
        configuration: {
          rowOrder: tableFields?.map((field, index) => ({
            fieldId: field.fieldId,
            order: index
          }))
        }
      });
    }
  }, [isOpen]);

  const getRowOrder = (index: number, fieldId?: number): number => {
    if (!view?.configuration?.rowOrder) return index;
    const rowOrder: RowOrder[] = view.configuration.rowOrder as RowOrder[];
    return rowOrder.find(row => row.fieldId === fieldId)?.order ?? (view.fields?.length as number);
  };

  const onRowOrderChange = (activeItemId: string | number, overItemId?: string | number) => {
    const rowOrder = [...((view?.configuration?.rowOrder ?? []) as RowOrder[])];
    const indexToInsertAt = rowOrder.findIndex(row => row.fieldId === overItemId);
    const indexToRemoveAt = rowOrder.findIndex(row => row.fieldId === activeItemId);
    const [removed] = rowOrder.splice(indexToRemoveAt, 1);

    rowOrder.splice(indexToInsertAt, 0, removed);
    const updated = rowOrder.map((row, index) => ({ ...row, order: index }));
    setView(prev => ({
      ...prev,
      configuration: { ...prev.configuration, rowOrder: updated }
    }));
  };
  useEffect(() => {
    if (view.viewType !== TableViewType.Board) return;
    if (view.boardColumnFieldId !== undefined) return;
    setView({
      ...view,
      boardColumnFieldId: choiceTableFields[0].id,
      boardTitleFieldId: textTableFields[0].id
    });
  }, [view.viewType]);

  const columns: RollupTableColumnModel[] = [
    { name: "id", displayName: t("id") },
    { name: "displayName", displayName: t("fieldName") },
    { name: "dataType", displayName: t("fieldType") },
    {
      name: "visibility",
      displayName: t("visibility"),
      componentRenderer: (cellValue, row) => {
        return (
          <IconButton
            onClick={() => {
              setView(prev => ({
                ...prev,
                fields: prev?.fields?.some(field => field.fieldId === row.id)
                  ? prev?.fields?.filter(field => field.fieldId !== row.id)
                  : [...(prev?.fields ?? []), { fieldId: row.id }]
              }));
            }}
          >
            {view?.fields?.some(field => field.fieldId === row.id) ? (
              <VisibilityOutlinedIcon color="primary" />
            ) : (
              <VisibilityOffIcon />
            )}
          </IconButton>
        );
      }
    }
  ];
  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth={true}>
      <DialogTitle>{t("tables")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={theme => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500]
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className="table-view-modal-root">
        <Stack direction={"column"} spacing={2} divider={<Divider flexItem />}>
          <Stack direction="row" className="input-container">
            <TextField
              data-testid="tableviewmodal-displayname"
              disabled={isDefault}
              className="input"
              variant="standard"
              value={view?.displayName ?? ""}
              onChange={e =>
                setView(prev => ({
                  ...prev,
                  displayName: e.target.value,
                  name: selectedView ? selectedView.name : removeWhitespaces(e.target.value)
                }))
              }
              label={t("name")}
              required
            />
            <TextField
              data-testid="tableviewmodal-internalname"
              className="input"
              variant="standard"
              value={removeWhitespaces(view?.name ?? "")}
              label={t("internalName")}
              required
              disabled
            />
            <TextField
              data-testid="tableviewmodal-description"
              disabled={isDefault}
              className="input"
              variant="standard"
              value={view?.description}
              label={t("description")}
              onChange={e => setView(prev => ({ ...prev, description: e.target.value }))}
            />

            <EdisonEnumSelect
              data-testid="tableviewmodal-viewtype"
              enumDef={TableViewType}
              value={(view.viewType as any) ?? TableViewType.None}
              t={t}
              disabled={choiceTableFields.length === 0 || textTableFields.length === 0 || isDefault}
              label={t("viewType")}
              onChange={changeValue => {
                setView(prev => ({ ...prev, viewType: changeValue }));
              }}
              className="input"
            />
          </Stack>
          {view.viewType === TableViewType.Board && (
            <Stack direction={"column"} spacing={2}>
              <Alert severity="info">{t("tableViewModal.boardViewInfo")}</Alert>
              <FormControl className="input">
                <InputLabel id="table-view-define-column-label">{t("defineColumn")}</InputLabel>
                <Select
                  variant="standard"
                  label={t("defineColumn")}
                  labelId="table-view-define-column-label"
                  size="small"
                  value={view.boardColumnFieldId ?? choiceTableFields[0].id}
                  onChange={e =>
                    setView(prev => ({
                      ...prev,
                      boardColumnFieldId: e.target.value
                    }))
                  }
                >
                  {choiceTableFields.map((field, index) => (
                    <MenuItem key={index} value={field.id}>
                      {field.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack direction={"column"} spacing={2}>
                <Alert severity="info">{t("tableViewModal.boardViewConfigurationInfo")}</Alert>
                <TextField
                  data-testid="tableviewmodal-boardCardPrimary"
                  disabled={isDefault}
                  className="input"
                  variant="standard"
                  value={view?.displayName ?? ""}
                  onChange={e =>
                    setView(prev => ({
                      ...prev,
                      displayName: e.target.value,
                      name: selectedView ? selectedView.name : removeWhitespaces(e.target.value)
                    }))
                  }
                  label={t("name")}
                  required
                />
                <TextField
                  data-testid="tableviewmodal-boardCardSecondary"
                  disabled={isDefault}
                  className="input"
                  variant="standard"
                  value={view?.displayName ?? ""}
                  onChange={e =>
                    setView(prev => ({
                      ...prev,
                      displayName: e.target.value,
                      name: selectedView ? selectedView.name : removeWhitespaces(e.target.value)
                    }))
                  }
                  label={t("name")}
                  required
                />
                {/* <FormControl className="input">
                <InputLabel id="table-view-board-title-label">{t("boardTitle")}</InputLabel>
                <Select
                  variant="standard"
                  label={t("boardTitle")}
                  labelId="table-view-board-title-label"
                  size="small"
                  value={view.boardTitleFieldId ?? textTableFields[0].id}
                  onChange={e =>
                    setView(prev => ({
                      ...prev,
                      boardTitleFieldId: e.target.value
                    }))
                  }
                >
                  {textTableFields.map((field, index) => (
                    <MenuItem key={index} value={field.id}>
                      {field.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              </Stack>
            </Stack>
          )}
          {view.viewType === TableViewType.Grid && (
            <SingleTable
              order="asc"
              disableColumnSort
              selectedRowIds={[]}
              setSelectedRowIds={() => console.log("chees")}
              searchFilter=""
              rows={
                allFields
                  .filter(field => tableFields?.find(tableField => tableField.fieldId === field.id))
                  .map((field, index) => ({
                    ...field,
                    disabled_e365: isDefault,
                    order: getRowOrder(index, field.id)
                  })) as any
              }
              columns={columns}
              t={t}
              onRowOrderChange={onRowOrderChange}
              orderBy="order"
              disableSelection={true}
              fullScreen={false}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="tableviewmodal-save-button"
          variant="contained"
          disabled={!view.displayName || isDefault}
          onClick={() => {
            onSave(view);
          }}
        >
          {t("save")}
        </Button>
        <Button data-testid="tableviewmodal-cancel-button" variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableViewModal;
