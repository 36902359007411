import { Alert, Button, Dialog, Divider, Paper, Stack } from "@mui/material";
import React, { FC } from "react";
import EdisonTypography from "../../../../edison/typography/EdisonTypography";
export interface TableRowDeletionDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  t: (value: string) => string;
}

const TableRowDeletionDialog: FC<TableRowDeletionDialogProps> = ({
  open,
  onClose,
  onDelete,
  t
}) => {
  return (
    <Dialog open={open}>
      <Paper>
        <Stack>
          <Stack padding={"1em"}>
            <EdisonTypography variant="h3" title={t("deleteData")} />
          </Stack>
          <Divider flexItem />
          <Stack padding={"1em"}>
            <Alert severity="warning">
              {t("deleteTableDataMessage1")}
              <strong> {t("permanent")} </strong>
              {t("deleteTableDataMessage2")}
              <br />
              <br />
              {t("wouldYouLikeToProceed")}
            </Alert>
          </Stack>
          <Divider flexItem />
          <Stack direction={"row"} padding={"1em"} spacing={1} justifyContent={"end"}>
            <Button variant="contained" color="error" onClick={onDelete}>
              {t("delete")}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {t("cancel")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default TableRowDeletionDialog;
