import { Category } from "./Category.model";
import { PermissionType } from "./PermissionType.model";
import { CommonTableRow, RecordType } from "./BaseRecord.model";
//--------------------------Models ------------------------
export interface Workflow {
  /** Format: date-time */
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  name: string;
  displayName?: string | null;
  description?: string | null;
  type: RecordType;
  allowStageSkip?: boolean;
  isDeleted?: boolean;
  configuration?: { [key: string]: unknown } | null;
  steps?: WorkflowStep[] | null;
  stages?: WorkflowStage[] | null;
  conditions?: WorkflowCondition[] | null;
  category?: Category;
}

export interface WorkflowStep {
  id?: number;
  name: string;
  displayName?: string | null;
  nextSteps?: WorkflowStepLink[];
  previousSteps?: WorkflowStepLink[];
  type: WorkflowStepType;
  description?: string | null;
  configuration?: { [key: string]: unknown } | null;
  workflowId?: number;
  workflow?: Workflow;
}

export interface WorkflowStepLink {
  stepName?: string;
  isConditionResult1?: boolean;
  isConditionResult2?: boolean;
}

export interface WorkflowNodeConfiguration {
  color: string;
  type: WorkflowNodeType;
  recordType?: RecordType;
  enabled?: (recordType: RecordType) => boolean;
  id?: number;
  [key: string]: any;
}

export type WorkflowNodeType = "Stage" | "StageReview" | "Action" | "Condition" | "Event";

export interface WorkflowStage {
  id?: number;
  name: string;
  displayName?: string | null;
  readOnly?: boolean;
  editAccess?: WorkflowStageEditAccess;
  viewState?: WorkflowStageViewState;
  workflowId?: number;
  workflow?: Workflow;
  formId?: number;
  cardId?: number;
  events?: WorkflowStageEvent[] | null;
  views?: WorkflowStageView[] | null;
  editors?: WorkflowStageEditor[] | null;
  tasks?: WorkflowStageTask[] | null;
  configuration?: WorkflowNodeConfiguration | null;
  hasReview?: boolean;
  requiredApproversCount?: number;
  reviewers?: WorkflowStageReviewer[] | null;
  targetDuration?: number;
  description?: string;
  allowReadOnlyOverride?: boolean;
  maxReviewersCount?: number;
  allowRecordCreation?: boolean;
  allowProjectCreation?: boolean;
  allowBusinessCaseCreation?: boolean;
}

export interface WorkflowStageEvent {
  id?: number;
  type?: WorkflowEventType;
  action?: WorkflowEventAction;
  version?: number;
  workflowStageId?: number;
  workflowStage?: WorkflowStage;
  triggerOnStageRepeat?: boolean;
  workflowDataPopulation?: WorkflowDataPopulation;
  configuration?: { [key: string]: unknown } | null;
}
export interface WorkflowDataPopulation {
  id?: number;
  stageEventId?: number; // Required in the swagger but should be populated in the backend;
  stageEvent?: unknown;
  startDate?: Date;
  endDate?: Date;
  description?: string;
  shortDescription?: string;
  overrideData?: boolean;
  fieldValues?: WorkflowDataPopulation[];
}

export interface WorkflowDataPopulationFieldValue {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  fieldId: number;
  stringValue?: string | null;
  decimalValue?: number | null;
  boolValue?: boolean | null;
  dateTimeValue?: string | null;
  extendedValue?: { [key: string]: unknown } | null;
  dataPopulationId?: number;
  dataPopulation?: unknown;
}

export interface WorkflowPopulationTableRow extends CommonTableRow {
  dataPopulationId?: number;
  dataPopulation?: unknown;
  tableRowFieldValues?: WorkflowDataPopulationTableRowFieldValue[];
  tableRowPeriodicValues?: WorkflowDataPopulationTableRowPeriodicValue[];
}

export interface WorkflowDataPopulationTableRowFieldValue {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  fieldId: number;
  stringValue?: string | null;
  decimalValue?: number | null;
  boolValue?: boolean | null;
  dateTimeValue?: string | null;
  extendedValue?: { [key: string]: unknown } | null;
  tableRowId?: number;
}
export interface WorkflowDataPopulationTableRowPeriodicValue {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  year?: number;
  period?: number;
  decimalValue?: number;
  tableRowId?: number;
}
export interface WorkflowDataPopulationTableConfiguration {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  tableId?: number;
  dataPopulationId?: number;
  taskTableCalendarId?: number;
  schedulingDirection?: any;
  baselines?: any;
  startDate?: string;
  endDate?: string;
}
export interface WorkflowStageView {
  id?: number;
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
  workflowStageId?: number;
  viewPermissions?: WorkflowStageViewPermission[] | null;
  viewFields?: WorkflowStageViewField[] | null;
  viewTables?: WorkflowStageViewTable[] | null;
  configuration?: { [key: string]: unknown } | null;
}

export interface WorkflowStageViewPermission {
  id?: number;
  workflowStageViewId?: number;
  entityId?: string | null;
  permissionType?: PermissionType;
}

export interface WorkflowStageViewField {
  id?: number;
  state?: WorkflowStageViewFieldState;
  workflowStageViewId?: number;
  fieldId?: number;
  configuration?: { [key: string]: unknown } | null;
}

export interface WorkflowStageViewTable {
  id?: number;
  state?: WorkflowStageViewFieldState;
  workflowStageViewId?: number;
  tableId?: number;
  configuration?: { [key: string]: unknown } | null;
}

export interface WorkflowStageEditor {
  id?: number;
  permissionType?: PermissionType;
  entityId: string;
  workflowStageId?: number;
}

export interface WorkflowStageTask {
  id?: number;
  description: string;
  workflowStageId?: number;
}

export interface WorkflowStageReviewer {
  id?: number;
  permissionType?: PermissionType;
  entityId: string;
  workflowStageId?: number;
}

export interface WorkflowCondition {
  id?: number;
  name: string;
  displayName?: string | null;
  type?: WorkflowStageConditionType;
  expression1: Expression;
  version?: number;
  workflowId?: number;
  workflow?: Workflow;
  configuration?: { [key: string]: unknown } | null;
}

export interface Expression {
  resultType?: ExpressionDataType;
  expressionBlocks?: ExpressionBlock[] | null;
}

export interface ExpressionBlock {
  joinOperator?: ExpressionOperator;
  expressionParts?: ExpressionPart[] | null;
}

export interface ExpressionPart {
  singleEntity?: ExpressionEntity;
  compareFromEntity?: ExpressionEntity;
  compareToEntity?: ExpressionEntity;
  compareOperator?: ExpressionOperator;
  dataType?: ExpressionDataType;
  fixedValue?: unknown | null;
  joinOperator?: ExpressionOperator;
  expressionBlock?: ExpressionBlock;
}

export interface ExpressionEntity {
  entityId?: number;
  entityType?: ExpressionEntityType;
  entityDataId?: string;
}

export interface WorkflowConditionResult {
  targetId?: string | null;
  target?: WorkflowConditionResultTarget;
}
//----------------------------Enums-----------------------------------------

export enum WorkflowStepType {
  Stage = "Stage",
  StageReview = "StageReview",
  ConditionIf = "ConditionIf"
}
export enum ExpressionDataType {
  Bool = "Bool",
  Decimal = "Decimal",
  Text = "Text",
  DateTime = "DateTime"
}
export enum ExpressionEntityType {
  Field = "Field",
  Project = "Project"
}

export enum ExpressionOperator {
  Or = "Or",
  And = "And",
  Equals = "Equals",
  NotEquals = "NotEquals",
  Contains = "Contains",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  GreaterThan = "GreaterThan",
  LessThan = "LessThan",
  Sum = "Sum",
  Subtract = "Subtract",
  Multiply = "Multiply",
  Divide = "Divide"
}
export enum WorkflowStageEditAccess {
  None = 0,
  Owners = 1 << 0,
  Reviewers = 1 << 1,
  Editors = 1 << 2
}

export enum WorkflowStageViewState {
  Draft = "Draft", // Only visible to owners, managers and admins
  Visible = "Visible", // Readable by everyone with permisisons
  Hidden = "Hidden" // Hidden everywhere except for search
}

export enum WorkflowStageProgressState {
  None = "None",
  Submitted = "Submitted",
  Approved = "Approved"
}

export enum WorkflowEventType {
  OnStart = "OnStart",
  OnComplete = "OnComplete"
}

export enum WorkflowEventAction {
  CreateVersion = "CreateVersion",
  PopulateData = "PopulateData"
}

export enum WorkflowStageViewFieldState {
  Required = "Required",
  ReadOnly = "ReadOnly",
  Hidden = "Hidden"
}

export enum WorkflowStageConditionType {
  If = "If"
}

export enum WorkflowConditionResultTarget {
  Step = "Step"
}
