import { FC, ReactNode } from "react";
import {
  Alert,
  AlertProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

type AlertDialogProps = {
  content: ReactNode | string;
  title: string;
  buttonText: string;
  severity: AlertProps["severity"];
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
};

export const AlertDialog: FC<AlertDialogProps> = ({
  content,
  title,
  buttonText,
  severity,
  isLoading,
  isOpen,
  onClose,
  onClick
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert severity={severity}>{content}</Alert>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" loading={isLoading} color={severity} onClick={onClick}>
          {buttonText}
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
