import { Column, ColumnStore } from "@bryntum/grid-thin";
import { Field } from "enada-common";

const hideColumns = (columnStore: ColumnStore, hiddenColumns: Field[]) => {
  if (columnStore === undefined) return;
  if (hiddenColumns === undefined) return;
  if (hiddenColumns.length < 1) return;

  columnStore.beginBatch();

  columnStore.forEach((column: Column) => {
    column.hidden = hiddenColumns?.some(hiddenColumn => hiddenColumn.id === column.id);
  });

  columnStore.endBatch();
};

export default hideColumns;
