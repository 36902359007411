import { WorkflowStageViewFieldState, getEnumValues, toCamelCase } from "enada-common";
import { IconButton, ListItemButton, Popover, Stack } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { VisibilityIcon } from "./VisibilityIcon";
import "./visibilitytoggle.scss";

export interface VisibilityToggleProps {
  value: WorkflowStageViewFieldState | undefined;
  onChange: (changeValue: WorkflowStageViewFieldState | undefined) => void;
  toggleDisabled?: boolean;
}

const VisibilityToggle: FC<VisibilityToggleProps> = ({ value, onChange, toggleDisabled }) => {
  const { t } = useTranslation(["common"]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const onClose = () => setAnchorEl(null);

  return (
    <Stack className="visibility-toggle-root">
      <IconButton
        className="toggle-button"
        disabled={toggleDisabled}
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        {VisibilityIcon(value)}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{ zIndex: 1500 }}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
      >
        <Stack
          sx={theme => ({
            backgroundColor: theme.palette.Contrast.main
          })}
        >
          {[...getEnumValues(WorkflowStageViewFieldState), "Visible"].map((fieldState, index) => (
            <ListItemButton
              selected={fieldState == value}
              key={index}
              onClick={() => {
                onChange(
                  fieldState === "Visible" ? undefined : (fieldState as WorkflowStageViewFieldState)
                );
                onClose();
              }}
            >
              <Stack direction="row" spacing={3}>
                {VisibilityIcon(fieldState)}
                <div>{t(toCamelCase(fieldState))}</div>
              </Stack>
            </ListItemButton>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default VisibilityToggle;
