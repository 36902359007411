import { Paper, Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { Handle, HandleType, Position } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectWorkflowOrientation,
  selectWorkflowType,
  updateWorkflowStage
} from "../../../store/slices/workflowSlice";
import { ValidationError, workflowOrientation } from "../utils/FrontendWorkflow.models";
import StageNodeInfo from "./stagenodeinfo/StageNodeInfo";
import NodeTitle from "./nodetitle/NodeTitle";
import "./customnode.scss";
import { WorkflowCondition, WorkflowStage } from "enada-common";
import ConditionNodeInfo from "./conditionnodeinfo/ConditionNodeInfo";
import { getEditAccess } from "../utils/getNewNode";

export interface CustomNodeProps {
  data: WorkflowStage;
  selected: boolean;
  selectable?: boolean;
}

const CustomNode: FC<CustomNodeProps> = ({ data, selected, selectable }) => {
  const dispatch = useAppDispatch();
  const orientation = useAppSelector(selectWorkflowOrientation);
  const recordType = useAppSelector(selectWorkflowType);

  useEffect(() => {
    // If the node does not have a recordType, then set it and update the editAccess
    if (data.configuration && !data.configuration.recordType)
      dispatch(
        updateWorkflowStage({
          ...data,
          editAccess: getEditAccess({ type: data.configuration?.type, recordType }),
          configuration: { ...data.configuration, recordType }
        })
      );
  }, [data, dispatch, recordType]);

  return (
    <Paper
      className={`custom-workflow-node-root ${getCssState(
        selected && (selectable === undefined ? true : selectable)
      )}`}
      style={{
        border: `2px solid ${data.configuration?.color}`,
        borderRadius: "9px"
      }}
    >
      <Handle type="target" position={getHandlePosition("target", orientation)} />
      <Stack className={`node-metadata `} spacing={1}>
        <NodeTitle data={data} />
        {data.configuration?.type.includes("Stage") ? (
          <StageNodeInfo stage={data} hasReview={data.configuration.type === "StageReview"} />
        ) : (
          <ConditionNodeInfo conditionData={data as WorkflowCondition} />
        )}
      </Stack>
      <Handle type="source" position={getHandlePosition("source", orientation)} />
    </Paper>
  );
};

const getCssState = (selected: boolean, validationErrors?: ValidationError): string => {
  if (validationErrors && validationErrors.errors.length > 0) {
    return "error";
  }
  if (selected) {
    return "outlined";
  }
  return "";
};
const getHandlePosition = (type: HandleType, orientation: workflowOrientation): Position => {
  if (orientation === "vertical") {
    ////[ TYPE, ORIENTATION]
    if (type === "source") {
      return Position.Bottom; //[source, vertical]
    } else {
      return Position.Top; //[target, vertical]
    }
  } else {
    if (type === "source") {
      return Position.Right; //[source,horizontal]
    } else {
      return Position.Left; //[target,horizontal]
    }
  }
};
export default CustomNode;
