import "@bryntum/core-thin/core.material.css";
import "@bryntum/taskboard-thin/taskboard.material.css";
import "./userboard.scss";
import { ProjectModel, ColumnModel } from "@bryntum/taskboard-thin";
import { BryntumTaskBoard } from "@bryntum/taskboard-react-thin";
import { BoardColumn, Choice, Field } from "enada-common";
import { FC, useRef } from "react";
import { v4 } from "uuid";
import { userTableBoardConfig } from "./UserTableBoardConfig";

export type BryntumBoardItemType =
  | "image"
  | "progress"
  | "rating"
  | "resourcesAvatar"
  | "seperator"
  | "tags"
  | "text"
  | "todoList"
  | "date";
export interface UserTableBoardCardItem {
  location: "header" | "body" | "footer";
  type: BryntumBoardItemType;
  field: string;
}
export interface UserTableBoardProps {
  //   t: (value: string) => string;
  tableColumns: Field[];
  boardColumns: Choice[];
  columnField: string;
  project: ProjectModel;
  getDefaultRow?: (id: string) => any;
  cardItems?: UserTableBoardCardItem[];
}
const UserTableBoard: FC<UserTableBoardProps> = ({
  tableColumns,
  boardColumns,
  columnField,
  project,
  getDefaultRow,
  cardItems
}) => {
  const boardRef = useRef<any>();
  const addTaskToTable = (columnData: BoardColumn) => {
    const newId = v4();
    const newRow = tableColumns
      .filter(column => column.name.endsWith("-e365"))
      .reduce(
        (next, current) => ({
          ...next,
          [current.name]: null
        }),
        {}
      );
    const defaultRow = getDefaultRow ? getDefaultRow(newId) : {};
    project.taskStore?.add({
      ...defaultRow,
      ...newRow,
      id: newId,
      [columnField]: columnData.id
    });
  };
  const taskboardColumns: ColumnModel[] = boardColumns.map(choice => {
    const model = new ColumnModel({ id: choice.id, text: choice.label });
    return model;
  });
  const taskRenderer = useRef(({ taskRecord, cardConfig }: any) => {
    const theme = taskRecord.theme ?? "Theme1";
    cardConfig.children.header.class[`taskBoardHeader${theme}`] = true;
    cardConfig.children.body.class[`taskBoardBody${theme}`] = true;
    cardConfig.children.footer.class[`taskBoardBody${theme}`] = true;
  });
  return (
    <div className="standard-table-board-view taskBoard">
      <BryntumTaskBoard
        {...userTableBoardConfig(addTaskToTable, cardItems)}
        ref={boardRef}
        columnField={columnField}
        columns={taskboardColumns}
        project={project}
        columnHeaderMenuFeature={{
          items: {
            addTask: {
              text: "Add Row",
              onItem({ columnRecord }: any) {
                addTaskToTable(columnRecord);
              }
            }
          }
        }}
        taskRenderer={taskRenderer.current}
      />
    </div>
  );
};

export default UserTableBoard;
