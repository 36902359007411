import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./i18n/en/common.json";
import common_pt from "./i18n/pt/common.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: common_en
    },
    pt: {
      common: common_pt
    }
  },
  interpolation: { escapeValue: false, skipOnVariables: false }, // React already does escaping
  lng: "en", // language to use

  ns: ["common"],
  fallbackLng: "en",
  debug: false // marked as false to remove messages from browser console
});
