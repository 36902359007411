import { BaseUserFieldProps, TableDataType } from "enada-common";
import React, { FC, useContext, useState } from "react";
import TaskReadOnlyContext from "../../user/tables/task/table/TaskContext";
import PeriodicReadOnlyContext from "../../user/tables/periodic/PeriodicContext";
import { StandardReadOnlyContext } from "../../user/tables/standard/StandardContext";
import DynamicHeightWrapper from "../../user/tables/tableutils/dynamicheightwrapper/DynamicHeightWrapper";
export interface ReadOnlyWrapperProps<T extends BaseUserFieldProps> {
  Component: FC<T>;
  props: T;
  initKeepReadOnly: boolean;
  source?: TableDataType;
  useDynamicHeightWrapper?: boolean;
  dynamicHeightLabel?: string;
}

const ReadOnlyWrapper = <T extends BaseUserFieldProps>({
  Component,
  props,
  initKeepReadOnly,
  source,
  useDynamicHeightWrapper,
  dynamicHeightLabel
}: ReadOnlyWrapperProps<T>) => {
  const [isReadOnly, setIsReadOnly] = useState(true);
  // TODO :  Revisit reading of dynamic readOnly value for custom fields after release 22/06/2023
  // Investigate further if Bryntum has a manner or passing readonnly for custom (maybe removing and adding columns)
  // At the very least investigate moving reading of context up to the component mapper
  //-------------------
  const standardReadOnly = useContext(StandardReadOnlyContext);
  const taskReadOnly = useContext(TaskReadOnlyContext);
  const periodicReadOnly = useContext(PeriodicReadOnlyContext);
  const keepReadOnly = getReadOnlyValue(
    initKeepReadOnly,
    taskReadOnly,
    standardReadOnly,
    periodicReadOnly,
    source
  );
  ///---------------------
  return (
    <div
      style={{
        flexGrow: 1,
        width: "100%",
        display: "flex",
        alignItems: "center"
      }}
      onClick={() => {
        if (isReadOnly && !keepReadOnly) setIsReadOnly(false);
      }}
    >
      {useDynamicHeightWrapper ? (
        <DynamicHeightWrapper
          props={{ ...props, readOnly: isReadOnly, setReadOnly: setIsReadOnly, autoFocus: true }}
          Component={Component}
          labelToSearchFor={dynamicHeightLabel as string}
        />
      ) : (
        <Component {...props} autoFocus readOnly={isReadOnly} setReadOnly={setIsReadOnly} />
      )}
    </div>
  );
};
const getReadOnlyValue = (
  initKeepReadOnly: boolean,
  taskReadOnly: boolean,
  standardReadOnly: boolean,
  periodicReadOnly: boolean,
  source?: TableDataType
) => {
  switch (source) {
    case TableDataType.List:
      return initKeepReadOnly === false ? standardReadOnly : true;
    case TableDataType.Schedule:
      return initKeepReadOnly === false ? taskReadOnly : true;
    case TableDataType.Periodic:
      return initKeepReadOnly === false ? periodicReadOnly : true;
  }
};

export default ReadOnlyWrapper;
