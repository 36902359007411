import { Stack, Switch, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualField,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import { EdisonTypography } from "enada-components";
import "./peopleconfig.scss";

const PeopleConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualField = useAppSelector(selectIndividualField);
  const allowGroups = (individualField?.configuration?.allowGroups as boolean) ?? false;
  const updateConfiguration = (key: string, value: number | string | boolean) => {
    dispatch(
      updateIndividualFieldProperty({
        key: "configuration",
        value: {
          ...individualField?.configuration,
          [key]: value
        }
      })
    );
  };
  return (
    <Stack spacing={3} className="people-config-root">
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} className="multiple-values-container">
          <EdisonTypography title={t("allowGroups")} variant="h4" />
          <Switch
            value={allowGroups}
            checked={allowGroups}
            onChange={e => updateConfiguration("allowGroups", e.target.checked)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PeopleConfig;
