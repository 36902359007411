import { Alert, Button, Paper, Stack } from "@mui/material";
import { Node } from "reactflow";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
import ManageFormQuickActions from "./manageformquickactions/ManageFormQuickActions";
import "./workflowmanageformview.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  WorkflowDesignerView,
  selectCurrentElement,
  setWorkflowDesignerView,
  updateElement
} from "../../../store/slices/workflowSlice";
import WorkflowLivePreview from "../workflowlivepreview/WorkflowLivePreview";
import {
  Form,
  WorkflowStageView,
  WorkflowStageViewField,
  WorkflowStageViewFieldState,
  WorkflowStageViewTable
} from "enada-common";
import { nanoid } from "nanoid";
import { updateNodeCustomData } from "../utils/updateNodeCustomData";
import WorkflowLivePreviewConfirmation from "../workflowlivepreviewconfirmation/WorkflowLivePreviewConfirmation";
import { useGetFormQuery } from "services/api";
import { setTableIds } from "store/slices/formDesignerSlice";

const WorkflowManageFormView = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const currentNode = useAppSelector(selectCurrentElement);
  const { data: form } = useGetFormQuery(currentNode?.data?.formId, {
    skip: !currentNode?.data?.formId
  });
  const stageReadOnly = currentNode?.data?.readOnly;
  const views: WorkflowStageView[] = useMemo(
    () => currentNode?.data.views ?? [],
    [currentNode?.data.views]
  );

  const [viewFields, setViewFields] = useState<WorkflowStageViewField[]>([]);
  const [viewTables, setViewTables] = useState<WorkflowStageViewTable[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const getComponentVisibility = useCallback(
    (state?: WorkflowStageViewFieldState) => {
      return stageReadOnly
        ? state === WorkflowStageViewFieldState.Hidden
          ? WorkflowStageViewFieldState.Hidden
          : WorkflowStageViewFieldState.ReadOnly
        : state;
    },
    [stageReadOnly]
  );

  useEffect(() => {
    if (!form) {
      return;
    }
    setViewFields([]);
    setViewTables([]);

    const currentViewFields = views[0] ? views[0].viewFields : undefined;
    const currentViewTables = views[0] ? views[0].viewTables : undefined;

    form.containers?.forEach(container =>
      container.zones?.forEach(zone =>
        zone.columns?.forEach(column => {
          if (column.tables) {
            for (const table of column.tables) {
              table.tableId && dispatch(setTableIds(table.tableId));
            }
          }
          setViewFields(prev => [
            ...prev,
            ...(column.fields?.map(field => ({
              fieldId: field.fieldId,
              state: getComponentVisibility(
                currentViewFields?.find(viewField => viewField.fieldId === field.fieldId)?.state
              )
            })) ?? [])
          ]);
          setViewTables(prev => [
            ...prev,
            ...(column.tables?.map(table => ({
              tableId: table.tableId,
              state: getComponentVisibility(
                currentViewTables?.find(viewTable => viewTable.tableId === table.tableId)?.state
              )
            })) ?? [])
          ]);
        })
      )
    );
  }, [dispatch, form, getComponentVisibility, views]);

  const onClose = () => {
    dispatch(setWorkflowDesignerView(WorkflowDesignerView.StageView));
  };
  return (
    <Stack spacing={3} className="workflow-manage-form-view-root">
      <Paper>
        <Stack className="top-bar" spacing={1}>
          <Stack direction={"row"} className="title-container">
            <EdisonTypography title={t("manageForm")} variant="h3" />

            <Stack direction={"row"} spacing={1}>
              <ManageFormQuickActions
                form={form as Form}
                viewFields={viewFields}
                viewTables={viewTables}
                setViewFields={setViewFields}
                setViewTables={setViewTables}
              />

              <Button
                variant={"contained"}
                onClick={() => {
                  const currentView = currentNode?.data.views
                    ? (currentNode?.data.views[0] as WorkflowStageView)
                    : null;
                  const baseView: WorkflowStageView = {
                    viewFields: viewFields.filter(field => field.state !== undefined),
                    viewTables: viewTables.filter(table => table.state !== undefined)
                  };

                  const updated = currentView
                    ? { ...currentView, ...baseView }
                    : { name: nanoid(), ...baseView };
                  dispatch(
                    updateElement(updateNodeCustomData("views", [updated], currentNode as Node))
                  );
                  onClose();
                }}
              >
                {t("save")}
              </Button>
              <Button variant={"outlined"} onClick={() => setConfirmOpen(true)}>
                {t("cancel")}
              </Button>
            </Stack>
          </Stack>
          <Alert severity="info">{t("manageFormMessage")}</Alert>
          {stageReadOnly && (
            <Alert severity="warning">
              <Stack direction="row">
                {t("manageFormMessagePart1")}
                <strong> &nbsp; {t("readOnly")}</strong> &nbsp;
                {t("manageFormMessagePart2")} &nbsp;
                <strong> {t("readOnly")}</strong>&nbsp;
                {t("manageFormMessagePart3")}
              </Stack>
            </Alert>
          )}
        </Stack>
      </Paper>
      <WorkflowLivePreview
        tabs={form?.containers ?? []}
        viewFields={viewFields}
        setViewFields={setViewFields}
        viewTables={viewTables}
        setViewTables={setViewTables}
        source="form"
        toggleDisabled={stageReadOnly}
      />
      <WorkflowLivePreviewConfirmation
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title={t("discardUnsavedChanges")}
        alertMessage={t("confirmUnsavedChangesDiscardMessage")}
        actionText={t("discardChanges")}
        action={() => {
          setConfirmOpen(false);
          onClose();
        }}
      />
    </Stack>
  );
};

export default WorkflowManageFormView;
