import { Stack } from "@mui/material";
import React, { FC, MutableRefObject } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BryntumGantt } from "@bryntum/gantt-react-thin";
import { EdisonIconButton } from "enada-components";
export interface DiscoverTableToolbarProps {
  ganttRef?: MutableRefObject<BryntumGantt | undefined>;
}
const DiscoverTableToolbar: FC<DiscoverTableToolbarProps> = ({ ganttRef }) => {
  return (
    <Stack direction={"row"} spacing={1}>
      <EdisonIconButton
        variant="outlined"
        onClick={() => {
          const gantt = ganttRef?.current?.instance;
          if (!gantt) return;
          gantt.zoomIn();
        }}
        icon={<ZoomInIcon />}
      />
      <EdisonIconButton
        variant="outlined"
        onClick={() => {
          const gantt = ganttRef?.current?.instance;
          if (!gantt) return;
          gantt.zoomOut();
        }}
        icon={<ZoomOutIcon />}
      />
      <EdisonIconButton
        variant="outlined"
        onClick={() => {
          const gantt = ganttRef?.current?.instance;
          if (!gantt) return;
          gantt.zoomToFit();
        }}
        icon={<ZoomOutMapIcon />}
      />
      <EdisonIconButton
        variant="outlined"
        onClick={() => {
          const gantt = ganttRef?.current?.instance;
          if (!gantt) return;
          gantt.shiftPrevious();
        }}
        icon={<ArrowBackIcon />}
      />
      <EdisonIconButton
        variant="outlined"
        onClick={() => {
          const gantt = ganttRef?.current?.instance;
          if (!gantt) return;
          gantt.shiftNext();
        }}
        icon={<ArrowForwardIcon />}
      />
    </Stack>
  );
};

export default DiscoverTableToolbar;
