import { Checkbox, ListItem, ListItemText } from "@mui/material";
import React, { FC } from "react";
export interface WorkflowFlagProps {
  checked: boolean;
  primaryLabel: string;
  secondaryLabel: string;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;
  disabled?: boolean;
}
const WorkflowFlag: FC<WorkflowFlagProps> = ({
  checked,
  primaryLabel,
  secondaryLabel,
  onChange,
  disabled
}) => {
  return (
    <ListItem sx={{ paddingLeft: 0 }}>
      <Checkbox disabled={disabled} checked={checked} onChange={onChange} />
      <ListItemText primary={primaryLabel} secondary={secondaryLabel} />
    </ListItem>
  );
};

export default WorkflowFlag;
