import React, { forwardRef } from "react";
import "./loading.scss";
import loadingImg from "../../assets/loading-webp.webp";
import { Backdrop, Box, SxProps } from "@mui/material";

interface LoadingProps {
  size: number;
  isFullPage?: boolean;
  sx?: SxProps;
}

const Loading = forwardRef<HTMLDivElement, LoadingProps>(({ size, isFullPage, sx }, ref) => {
  return isFullPage ? (
    <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
      <Box ref={ref}>
        <img
          src={loadingImg}
          data-testid="loading"
          alt="Loading"
          style={{ width: "150px", height: "150px" }}
        />
      </Box>
    </Backdrop>
  ) : (
    <Box sx={{ ...sx, display: "flex" }} ref={ref}>
      <img
        data-testid="loading"
        src={loadingImg}
        alt="Loading"
        style={{ margin: "auto", width: `${size}px`, height: `${size}px` }}
      />
    </Box>
  );
});

Loading.displayName = "Loading";

export default Loading;
export { Loading };
