import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import fieldsSlice from "./slices/fieldsSlice";
import tablesSlice from "./slices/tablesSlice";
import userSlice from "./slices/userSlice";
import templatesSlice from "./slices/templatesSlice";
import workflowSlice from "./slices/workflowSlice";
import calculatedFieldSlice from "./slices/calculatedFieldSlice";
import notificationSlice from "./slices/notificationSlice";
import formDesignerSlice from "./slices/formDesignerSlice";
import newsSlice from "./slices/newsSlice";
import cardDesignerSlice from "./slices/cardDesignerSlice";
import dataPopulationSlice from "./slices/dataPopulationSlice";
import resourcesSlice from "./slices/resourcesSlice";
import calendarsSlice from "./slices/calendarsSlice";
import resourceFieldsSlice from "./slices/resourceFieldsSlice";
import personalSlice from "./slices/personalSlice";
import recordSlice from "./slices/recordSlice";
import recordsSlice from "./slices/recordsSlice";
import configSlice from "./slices/configSlice";
import recordTableSlice from "./slices/recordTableSlice";
import tenantSlice from "./slices/tenantSlice";
import discoverFiltersSlice from "./slices/discoverFiltersSlice";
import firewallSlice from "./slices/firewallSlice";
import { omitSkipTransform } from "./reduxPersist";
import { baseAPI } from "../services/baseAPI";
import { graphAPI } from "services/graphAPI";
import { getTenantAndInstanceFromURL } from "../utils/urlHelpers";

// get tenant and instance from url as there's no
// guarantee they will exist in localstorage at this point
const { tenant, instance } = getTenantAndInstanceFromURL();
const persistConfig = {
  key: `${tenant}-${instance}-root`, // Key for local
  storage,
  whitelist: ["discoverFilters"], // Only these reducers will be persistent,
  transforms: [omitSkipTransform] as any, //  set to any because of a bug in redux-persist typings
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  user: userSlice,
  fields: fieldsSlice,
  calendars: calendarsSlice,
  workflow: workflowSlice,
  tables: tablesSlice,
  templates: templatesSlice,
  formdesigner: formDesignerSlice,
  carddesigner: cardDesignerSlice,
  calculated: calculatedFieldSlice,
  notification: notificationSlice,
  news: newsSlice,
  config: configSlice,
  dataPopulation: dataPopulationSlice,
  resourceFields: resourceFieldsSlice,
  resources: resourcesSlice,
  personal: personalSlice,
  records: recordsSlice,
  record: recordSlice,
  recordTable: recordTableSlice,
  tenant: tenantSlice,
  discoverFilters: discoverFiltersSlice,
  firewall: firewallSlice,
  [baseAPI.reducerPath]: baseAPI.reducer,
  [graphAPI.reducerPath]: graphAPI.reducer
});

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "discoverFilters/setDiscoverFilters",
          "discoverFilters/setODataOperations"
        ],
        ignoredPaths: ["discoverFilters.records.filters.value"]
      }
    })
      .concat(baseAPI.middleware)
      .concat(graphAPI.middleware)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
