import React, { FC, ReactElement } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export interface EdisonChipProps {
  id: number | string;
  label?: string;
  isOutlined?: boolean;
  isSmall?: boolean;
  icon?: ReactElement;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  onClick?: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  deleteIcon?: ReactElement;
  disabled?: boolean;
}

const EdisonChip: FC<EdisonChipProps> = ({
  id,
  label,
  isOutlined,
  isSmall,
  icon,
  color,
  onClick,
  onDelete,
  deleteIcon,
  disabled
}) => {
  const clickCallback = () => {
    if (onClick) {
      onClick(id);
    }
  };
  const deleteCallback = () => {
    if (onDelete) {
      onDelete(id);
    }
  };
  return (
    <Stack direction="row" spacing="1">
      <Chip
        data-testid={"edison-chip"}
        label={label}
        variant={isOutlined ? "outlined" : undefined}
        size={isSmall ? "small" : undefined}
        icon={icon ?? undefined}
        color={color ?? undefined}
        onClick={onClick ? clickCallback : undefined}
        onDelete={onDelete ? deleteCallback : undefined}
        deleteIcon={deleteIcon ?? undefined}
        disabled={disabled}
      />
    </Stack>
  );
};

export default EdisonChip;
export { EdisonChip };
