import { Stack, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EdisonTypography } from "enada-components";
import { BorderedTableCell } from "../approvalstable/ApprovalsTable";
import CollapsibleRow from "../collapsiblerow/CollapsibleRow";
import TasksRow from "./tasksRow/TasksRow";
import "./personaltaskstable.scss";
import "./tasksRow/taskrow.scss";
import dayjs from "dayjs";
import HasMinimumLicense from "../../hasMinimumLicense/HasMinimumLicense";
import { LicenseType } from "enada-common";
import { isDateInWeek } from "../../../utils/timeManipulation";
import { useGetPersonalTasksQuery } from "services/api";

const PersonalTasksTable = () => {
  const { t } = useTranslation(["common"]);
  const { data: tasks = [] } = useGetPersonalTasksQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const overdueTasks = tasks.filter(
    task => task.dueDate && dayjs(task.dueDate).isBefore(dayjs(), "day")
  );
  const todayTasks = tasks.filter(task => task.dueDate && dayjs().isSame(task.dueDate, "day"));

  const thisWeekTasks = tasks.filter(
    task =>
      task.dueDate &&
      isDateInWeek(task.dueDate) &&
      !dayjs().isSame(task.dueDate, "day") &&
      new Date(task.dueDate) >= new Date()
  );

  const noDateTasks = tasks.filter(task => task.dueDate === undefined);
  const nextWeekTasks = tasks.filter(task => task.dueDate && isDateInWeek(task.dueDate, 1));

  const upcomingTasks = tasks.filter(
    task =>
      task.dueDate &&
      new Date(task.dueDate) > new Date() &&
      !isDateInWeek(task.dueDate) &&
      !isDateInWeek(task.dueDate, 1)
  );

  return (
    <Stack className="personal-tasks-table-root">
      <Table>
        <TableHead>
          <TableRow className="tasks-row-root">
            <BorderedTableCell>{t("tasks")}</BorderedTableCell>
            <BorderedTableCell className="description"> {t("description")}</BorderedTableCell>
            <BorderedTableCell className="card">{t("card")}</BorderedTableCell>
            <BorderedTableCell className="end-date">{t("endDate")}</BorderedTableCell>
            <BorderedTableCell className="percentage-complete">{t("%Complete")}</BorderedTableCell>
            <BorderedTableCell className="effort">{t("actualEffort")}</BorderedTableCell>
            <BorderedTableCell>{t("status")}</BorderedTableCell>
            <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
              <BorderedTableCell className="action">{t("action")}</BorderedTableCell>
            </HasMinimumLicense>
          </TableRow>
        </TableHead>
        <TableBody>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("overdue")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${overdueTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {overdueTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography title={t("today")} variant="data2" sx={{ fontWeight: "bold" }} />
                <div>{`(${todayTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {todayTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("thisWeek")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${thisWeekTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {thisWeekTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("nextWeek")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${nextWeekTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {nextWeekTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("upcoming")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${upcomingTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {upcomingTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={8}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography title={t("noDate")} variant="data2" sx={{ fontWeight: "bold" }} />
                <div>{`(${noDateTasks.length} ${t("tasks")})`}</div>
              </Stack>
            }
          >
            {noDateTasks.map(task => (
              <TasksRow key={task.itemId} task={task} />
            ))}
          </CollapsibleRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default PersonalTasksTable;
