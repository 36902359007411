import {
  Field,
  BaseRecord,
  RecordTableRow,
  TableRowValue,
  Table,
  TableRowType,
  TableRowPeriodicValues
} from "enada-common";
import { v4 as uuidv4 } from "uuid";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { OperationType } from "../../store/slices/recordSlice";
import { getBackendMappedCellValue, getFieldByKey, getRow } from "../tableHelpers";

export interface RowOperationType {
  operationType: OperationType;
  tableRows: RecordTableRow[];
}
const parsePeriodicTableChangeToBackend = (
  changeEvent: any,
  table: Table,
  project: BaseRecord,
  fields: Field[],
  tableRows: RecordTableRow[]
): RowOperationType[] | null => {
  const tableValues: TableRowValue[] = [];
  const baseTableRow: RecordTableRow = {
    recordId: project.id as number,
    tableId: table.id,
    rowType: TableRowType.Periodic
  };

  switch (changeEvent.action) {
    case "add": {
      const newId = uuidv4();
      changeEvent.records[0].id = newId;
      const recordToAdd = Object.entries(changeEvent.records[0].data).filter(([key, _]) =>
        key.endsWith("-e365")
      );
      recordToAdd.forEach(([key, value]) => {
        const found = getFieldByKey(
          key.endsWith("-e365") ? key.substring(0, key.length - 5) : key,
          fields
        );
        if (!found) return;
        tableValues.push({
          ...getBackendMappedCellValue(found, value, 0),
          fieldId: found.id as number,
          recordTableRowId: 0
        });
      });
      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: [
            {
              ...baseTableRow,
              id: 0,
              uniqueId: newId,
              tableRowFieldValues: [
                ...tableValues,
                {
                  fieldId: SystemFieldType.UniqueId,
                  stringValue: newId,
                  recordTableRowId: 0
                }
              ],
              tableRowPeriodicValues: []
            }
          ]
        }
      ];
    }
    case "update": {
      const [key, change]: any[] = Object.entries(changeEvent.changes)[0];

      const field = getFieldByKey(
        key.endsWith("-e365") ? key.substring(0, key.length - 5) : key,
        fields
      );

      const currentRow = getRow(tableRows, changeEvent.record);

      if (!currentRow) break;

      const periodicRowValues = currentRow.tableRowPeriodicValues
        ? [...currentRow.tableRowPeriodicValues]
        : [];
      let periodicRowToSave = [] as TableRowPeriodicValues[];
      if (!field) {
        const periodicFieldName = key.split("-").map(Number);
        const period = periodicFieldName[0];
        const year = periodicFieldName[1] || 0;
        const periodicFieldNameFound = periodicRowValues?.some(
          field => field.period === period && field.year === year
        );

        if (periodicFieldNameFound) {
          const rowValue = periodicRowValues.find(
            field => field.period === period && field.year === year
          );
          if (rowValue) {
            periodicRowToSave = [{ ...rowValue, decimalValue: change?.value }];
          }
        } else {
          periodicRowToSave = [
            {
              recordTableRowId: currentRow.id as number,
              decimalValue: change?.value,
              period: period,
              year: year
            }
          ];
        }
      }

      const currentField = currentRow.tableRowFieldValues?.find(
        (curField: TableRowValue) => curField.fieldId === field?.id
      );
      const recordTableRowValues = field
        ? [getBackendMappedCellValue(field, change.value, currentRow.id as number)]
        : currentField
        ? [currentField]
        : [];

      return [
        {
          operationType: OperationType.AddOrPatch,
          tableRows: [
            {
              ...baseTableRow,
              id: currentRow.id,
              uniqueId: changeEvent.record.id,
              tableRowPeriodicValues: periodicRowToSave || [],
              tableRowFieldValues: [
                ...recordTableRowValues,
                {
                  fieldId: SystemFieldType.UniqueId,
                  stringValue: changeEvent.record.id,
                  recordTableRowId: currentRow.id as number
                }
              ]
            }
          ]
        }
      ];
    }
    case "remove":
      return [
        {
          operationType: OperationType.Delete,
          tableRows: changeEvent.records.map((record: any) => ({
            ...baseTableRow,
            id: getRow(tableRows, record)?.id,
            uniqueId: record.id,
            tableRowFieldValues: [
              {
                fieldId: SystemFieldType.UniqueId,
                stringValue: record.id,
                recordTableRowId: getRow(tableRows, record)?.id
              }
            ]
          }))
        }
      ];
    default:
      break;
  }

  return null;
};

export default parsePeriodicTableChangeToBackend;
