import { Table } from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectPopulationObject,
  selectPopulationPeriodicGrandTotals
} from "../../../../store/slices/dataPopulationSlice";
import { selectAllFields } from "../../../../store/slices/fieldsSlice";
import { Loading } from "enada-components";
import TableMapper from "../../../../utils/mappers/TableMapper";
import { RowOperationType } from "../../../../utils/parsing/parseTableChangeToBackend";
import { WorkflowLivePreviewSource } from "../WorkflowLivePreview";
import {
  selectRecordTableBackendRowValues,
  setTableOperations
} from "../../../../store/slices/recordTableSlice";
import { useGetCalendarsQuery, useGetFieldsQuery, useGetMyResourcesQuery } from "services/api";

export interface PopulationTableProps {
  fullTable: Table;
  source: WorkflowLivePreviewSource;
}
const PopulationTable: FC<PopulationTableProps> = ({ fullTable, source }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const { data: calendars = [], isLoading: calendarsIsLoading } = useGetCalendarsQuery();
  const { data: resources, isLoading: resourcesIsLoading } = useGetMyResourcesQuery("");
  const dataPopulation = useAppSelector(selectPopulationObject);
  const rows = useAppSelector(selectRecordTableBackendRowValues);
  const periodicGrandTotals = useAppSelector(selectPopulationPeriodicGrandTotals);

  return (
    <div>
      {fullTable && !resourcesIsLoading && !calendarsIsLoading ? (
        <TableMapper
          table={fullTable}
          fields={fields}
          t={t}
          project={{ id: dataPopulation?.id as number } as any}
          rowsToUpdate={[]}
          rowsInTable={rows.filter(row => row.tableId === fullTable.id)}
          readOnly={source === "form"}
          resources={resources?.value ?? []}
          calendars={calendars}
          onChange={(tableOperations: RowOperationType[]) => {
            if (source === "form") return;
            dispatch(setTableOperations({ tableOperations }));
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          deleteAction={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          updateAction={() => {}}
          noDatesFromConfig={true}
          hideTableConfigModal={true}
          periodicGrandTotals={
            periodicGrandTotals.find(totals => totals.tableId === fullTable.id)?.totals
          }
          hideEdi
        />
      ) : (
        <Loading size={70} />
      )}
    </div>
  );
};

export default PopulationTable;
