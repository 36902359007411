import {
  CalculatedExpressionUnit,
  ExpressionEntityType,
  ParentheseOperators,
  operatorMapList
} from "enada-common";
import {
  Autocomplete,
  Button,
  Divider,
  ListSubheader,
  MenuItem,
  Stack,
  TextField
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import {
  clearConditionBlock,
  insertIf,
  insertUnitIntoConditionBlock,
  insertUnitIntoSimpleExpression,
  selectCalculatedConditionBlock,
  selectIsCondition,
  setIsCondition,
  setSimpleExpression
} from "../../../../store/slices/calculatedFieldSlice";
import { selectFieldsValidForCalculation } from "../../../../store/slices/fieldsSlice";
import { EdisonTypography } from "enada-components";
import ConditionZone from "../conditionzone/ConditionZone";
import ExpressionZone from "../expressionzone/ExpressionZone";
import Operator from "../operator/Operator";
import { CompareOperators, NumericalOperators } from "../utils/FrontendToBackendOperatorMap";
import { CalculatedFunctionList } from "../utils/calculated.model";

import "./expressionbuilder.scss";
import { useGetFieldsQuery } from "services/api";
export interface NewBuilderProps {
  allowConditions: boolean;
}

const ExpressionBuilder: FC<NewBuilderProps> = ({ allowConditions }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectFieldsValidForCalculation(result)
    })
  });

  const conditionExpression = useAppSelector(selectCalculatedConditionBlock);
  const isCondition = useAppSelector(selectIsCondition);

  const insertUnitIntoExpression = (unit: CalculatedExpressionUnit) =>
    isCondition
      ? dispatch(insertUnitIntoConditionBlock({ unit }))
      : dispatch(insertUnitIntoSimpleExpression(unit));

  return (
    <Stack className="expression-builder-root" spacing={2}>
      <Stack
        direction="row"
        spacing={3}
        className="options-container"
        data-testid="expressionbuilder-fields-dropdown"
      >
        <EdisonTypography variant="h3" title={`${t("insert")}:`} />
        <Autocomplete
          className="calculated-select fields"
          getOptionLabel={() => ""}
          value={null}
          onChange={(e, field) =>
            field &&
            insertUnitIntoExpression({
              type: "entity",
              value: {
                entityDataId: "",
                entityId: field.id,
                entityType: ExpressionEntityType.Field
              }
            })
          }
          options={fields}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id} data-testid={"expression-builder-MenuItem"}>
              <div> {option.displayName} </div>
            </MenuItem>
          )}
          groupBy={field => field.dataType}
          renderGroup={({ key, group, children }) => (
            <Stack key={key}>
              <ListSubheader>{group}</ListSubheader>
              <Divider flexItem />
              <Stack>{children}</Stack>
            </Stack>
          )}
          renderInput={params => <TextField {...params} label={t("fields")} size="small" />}
          filterOptions={(options, state) => {
            if (state.inputValue === "") return options;
            return options.filter(field =>
              field.displayName?.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          }}
        />
        {allowConditions && (
          <Autocomplete
            className="calculated-select functions"
            options={CalculatedFunctionList}
            getOptionLabel={() => ""}
            renderInput={params => {
              return <TextField {...params} label={t("functions")} size="small" />;
            }}
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                key={props.id}
                onClick={e => {
                  if (option === "IF" && isCondition) {
                    dispatch(insertIf());
                  } else {
                    dispatch(setIsCondition(option === "IF"));
                  }
                  props.onClick && props.onClick(e);
                }}
              >
                <div>{option}</div>
              </MenuItem>
            )}
            filterOptions={(options, state) => {
              if (state.inputValue === "") return options;
              return options.filter(func =>
                func?.toLowerCase().includes(state.inputValue.toLowerCase())
              );
            }}
          />
        )}
        <Autocomplete
          className="calculated-select operators"
          sx={{ width: 300 }}
          getOptionLabel={() => ""}
          options={operatorMapList}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id}>
              <Stack direction="row" spacing={2}>
                <div>{option.backend}</div>
                {!["StartsWith", "EndsWith", "Contains"].includes(option.frontend) && (
                  <div>{option.frontend}</div>
                )}
              </Stack>
            </MenuItem>
          )}
          renderInput={params => <TextField {...params} label={t("operators")} size="small" />}
          onChange={(_, pair) =>
            pair &&
            insertUnitIntoExpression({
              id: pair.id as any,
              type: "operator",
              value: pair.backend
            })
          }
          filterOptions={(options, state) => {
            if (state.inputValue === "") return options;
            return options.filter(
              pair =>
                pair.backend.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                pair.frontend.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          }}
        />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Button
          className="operator"
          size="small"
          variant="outlined"
          onClick={() =>
            isCondition ? dispatch(clearConditionBlock(null)) : dispatch(setSimpleExpression([]))
          }
        >
          {t("clearAll")}
        </Button>
        <Divider flexItem orientation="vertical" />
        <Stack direction="row" spacing={1}>
          {NumericalOperators.map(pair => (
            <Operator key={pair.id} pair={pair} />
          ))}
        </Stack>
        <Stack direction="row" spacing={1}>
          {CompareOperators.map(pair => (
            <Operator key={pair.id} pair={pair} />
          ))}
        </Stack>
        <Stack direction="row" spacing={1}>
          {ParentheseOperators.map(pair => (
            <Operator key={pair.id} pair={pair} />
          ))}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            className="operator custom"
            size="small"
            variant="outlined"
            onClick={() =>
              insertUnitIntoExpression({
                type: "number",
                value: 0
              })
            }
          >
            {t("number")}
          </Button>
          {/* TODO: Re-add text operator once we have found a safe package to evaluate user input */}
          {/* <Button
            className="operator custom"
            size="small"
            variant="outlined"
            onClick={() =>
              insertUnitIntoExpression({
                type: "text",
                value: t("textHere"),
              })
            }>
            {t("text")}
          </Button> */}
        </Stack>
      </Stack>
      <div className="expression-zone-container" data-testid={"expression-zone-container"}>
        {isCondition && conditionExpression ? (
          <Stack direction="row" spacing={2}>
            <div className="equals-container">= </div>
            <ConditionZone block={conditionExpression} path={null} />
          </Stack>
        ) : (
          <ExpressionZone />
        )}
      </div>
    </Stack>
  );
};

export default ExpressionBuilder;
