import { FieldDataType } from "enada-common";
import * as schema_choice from "./schemas/choice.js";
import * as schema_multichoice from "./schemas/multichoice.js";
import * as schema_multipeople from "./schemas/multipeople.js";
import * as schema_people from "./schemas/people.js";
import * as schema_richtext from "./schemas/richtext.js";
import * as schema_url from "./schemas/url.js";

export const fieldsWithOnBlurIssue: FieldDataType[] = [
  FieldDataType.Switch,
  FieldDataType.MultiLevelChoice,
  FieldDataType.MultiMultiLevelChoice
];

export const fieldsWithValidator: FieldDataType[] = [
  FieldDataType.Choice,
  FieldDataType.People,
  FieldDataType.RichText,
  FieldDataType.MultiChoice,
  FieldDataType.MultiPeople,
  FieldDataType.Url
];

export type FieldsWithValidator = Extract<
  FieldDataType,
  | FieldDataType.Choice
  | FieldDataType.People
  | FieldDataType.RichText
  | FieldDataType.MultiChoice
  | FieldDataType.MultiPeople
  | FieldDataType.Url
>;

type FieldToSchemaMap = Record<FieldsWithValidator, any>;

const lookup: FieldToSchemaMap = {
  [FieldDataType.Choice]: schema_choice,
  [FieldDataType.People]: schema_people,
  [FieldDataType.RichText]: schema_richtext,
  [FieldDataType.MultiChoice]: schema_multichoice,
  [FieldDataType.MultiPeople]: schema_multipeople,
  [FieldDataType.Url]: schema_url
};

const isValid = (fieldDataType: FieldsWithValidator, currentValue: string) => {
  let jsonValue: any;
  try {
    jsonValue = JSON.parse(currentValue);
  } catch (e) {
    /* empty */
  }

  if (!jsonValue) {
    return false;
  }

  const validate = lookup[fieldDataType];
  const isv = validate(jsonValue);

  console.log("JOBIE: isv", isv);

  return isv;
};

export default isValid;
