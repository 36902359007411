import {
  BaseUserFieldProps,
  ConditionalExpression,
  Expression,
  Field,
  parseConditionBlockToFrontend,
  parseExpressionToFrontend
} from "enada-common";
import { Stack } from "@mui/material";
import React, { FC, useMemo } from "react";
import { calculateConditionValue, calculateExpressionValue } from "./calculateValue";

export interface UserCalculatedFieldProps extends BaseUserFieldProps {
  record: any;
  expression?: Expression;
  calculatedExpression?: ConditionalExpression;
  fieldsInTable: Field[];
}
const UserCalculatedField: FC<UserCalculatedFieldProps> = ({
  expression,
  calculatedExpression,
  record,
  fieldsInTable
}) => {
  const conditionBlock = useMemo(
    () => (calculatedExpression ? parseConditionBlockToFrontend(calculatedExpression) : undefined),
    [calculatedExpression]
  );

  const flatExpression = useMemo(
    () => (expression ? parseExpressionToFrontend(expression) : undefined),
    [expression]
  );

  const calculation = flatExpression
    ? calculateExpressionValue(record, fieldsInTable, flatExpression)
    : calculateConditionValue(record, fieldsInTable, conditionBlock);

  return <Stack style={{ padding: "8px", marginTop: "8px" }}>{calculation}</Stack>;
};
export default UserCalculatedField;
