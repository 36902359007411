import { getHeaders } from "../utils/getHeaders";

export function saveFile(
  accessToken: string,
  endpoint: string,
  file: File,
  headers?: Headers
): Promise<unknown> {
  const formData = new FormData();
  formData.append("file", file);

  const options: RequestInit = {
    method: "POST",
    headers: getHeaders(accessToken, endpoint, headers, true),
    body: formData
  };

  return fetch(`${endpoint}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}
