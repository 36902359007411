import {
  PersonaEntity,
  WorkflowReviewState,
  WorkflowStageView,
  WorkflowTaskState
} from "enada-common";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/hooks";
import {
  selectRecordAuth,
  selectRecordFieldValues,
  selectRecordType,
  selectWorkflowStage
} from "../../../../store/slices/recordSlice";
import { selectRecordTableBackendRowValues } from "../../../../store/slices/recordTableSlice";
import { EdisonPeopleGroup, EdisonTypography } from "enada-components";
import { BorderedTableCell } from "../../../personal/approvalstable/ApprovalsTable";
import { getMissingRequiredValues } from "../normalstageapproval/NormalStageApproval";
import ReviewsMet from "./reviewsmet/ReviewsMet";
import "./stagereviewstatus.scss";
import { useGetRecordWorkflowReviewersQuery } from "services/api";

const StageReviewStatus = () => {
  const { t } = useTranslation(["common"]);

  const currentStage = useAppSelector(selectWorkflowStage);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const fieldValues = useAppSelector(selectRecordFieldValues);
  const rowValues = useAppSelector(selectRecordTableBackendRowValues);

  const { data: stageReviewerList = [] } = useGetRecordWorkflowReviewersQuery(
    {
      recordId: recordAuth?.details.RecordId as number,
      recordType
    },
    { skip: !recordAuth || !currentStage?.hasReview }
  );

  const approvedCount = stageReviewerList?.filter(
    ({ workflowReview }) => workflowReview?.reviewState === WorkflowReviewState.Approved
  ).length;
  const rejectedCount = stageReviewerList?.filter(
    ({ workflowReview }) => workflowReview?.reviewState === WorkflowReviewState.Rejected
  ).length;

  const stageView =
    currentStage?.views && currentStage?.views?.length !== 0
      ? (currentStage?.views as WorkflowStageView[])[0] // TODO : review this when we can add multiple views to the a workflow
      : undefined;

  const { missingFields, missingTables } = getMissingRequiredValues(
    fieldValues,
    rowValues,
    stageView
  );

  const getApprovedRejectedPercentage = (primaryCount?: number, secondaryCount?: number) => {
    if (primaryCount === undefined) return 0;
    if (secondaryCount === undefined) return 0;
    const percentage = (primaryCount / (primaryCount + secondaryCount)) * 100;
    if (isNaN(percentage)) return 0;
    return percentage;
  };

  return (
    <Stack className="manager-override-root" spacing={2}>
      <ReviewsMet
        currentMet={approvedCount + rejectedCount}
        primaryText={t("maxNumberOfReviewsNotMet")}
        secondaryText={t("maxNumberOfReviewsNotMetMessage")}
        total={currentStage?.maxReviewersCount}
      />
      <ReviewsMet
        currentMet={approvedCount}
        primaryText={t("requiredeNumberOfReviewsNotMet")}
        secondaryText={t("requiredeNumberOfReviewsNotMetMessage")}
        total={currentStage?.requiredApproversCount}
      />
      <Stack direction="row" className="approved-progress-container" spacing={1}>
        <EdisonTypography
          title={`${approvedCount} ${t("approvals")} (${getApprovedRejectedPercentage(
            approvedCount,
            rejectedCount
          )} %)`}
          variant="helpertext"
        />
        <LinearProgress
          variant="determinate"
          value={getApprovedRejectedPercentage(approvedCount, rejectedCount)}
        />

        <EdisonTypography
          title={`${rejectedCount} ${t("rejections")} (${getApprovedRejectedPercentage(
            rejectedCount,
            approvedCount
          )} %)`}
          variant="helpertext"
        />
      </Stack>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <BorderedTableCell> {t("reviewer")}</BorderedTableCell>
            {Boolean(currentStage?.tasks?.length) && (
              <BorderedTableCell> {t("checklist")}</BorderedTableCell>
            )}
            <BorderedTableCell> {t("decision")}</BorderedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stageReviewerList && stageReviewerList.length < 1 && (
            <TableRow>
              <BorderedTableCell colSpan={4}>
                <EdisonTypography title={t("noTableResultsMessage")} variant="data2" />
              </BorderedTableCell>
            </TableRow>
          )}
          {stageReviewerList?.map((reviewer, index) => (
            <TableRow key={index}>
              <BorderedTableCell>
                <EdisonPeopleGroup
                  maxAvatars={1}
                  // readOnly={true} property doesn't exist in EdisonPeopleGroup
                  value={[
                    {
                      entityId: reviewer.userId ? reviewer.userId.toLocaleString() : ""
                    } as PersonaEntity
                  ]}
                />
              </BorderedTableCell>
              {Boolean(currentStage?.tasks?.length) && (
                <BorderedTableCell>
                  <Tooltip
                    title={
                      <Stack>
                        {currentStage?.tasks?.map((task, index) => (
                          <Stack key={index} direction="row" spacing={1}>
                            {reviewer.workflowTasks?.some(
                              reviewTask =>
                                reviewTask.taskState === WorkflowTaskState.Completed &&
                                reviewTask?.task === task.id
                            ) ? (
                              <DoneIcon />
                            ) : (
                              <CloseIcon />
                            )}
                            <EdisonTypography title={task.description} variant="data2" />
                          </Stack>
                        ))}
                      </Stack>
                    }
                  >
                    <div>
                      {reviewer.workflowTasks?.filter(
                        task => task.taskState === WorkflowTaskState.Completed
                      ).length ?? 0}{" "}
                      {t("of")} {currentStage?.tasks?.length} &nbsp;{"("}
                      {((reviewer.workflowTasks?.filter(
                        task => task.taskState === WorkflowTaskState.Completed
                      ).length ?? 0) /
                        (currentStage?.tasks?.length ?? 1)) *
                        100}
                      {"%)"}
                    </div>
                  </Tooltip>
                </BorderedTableCell>
              )}
              <BorderedTableCell>
                {reviewer?.workflowReview?.reviewState ?? t("inProgress")}
              </BorderedTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default StageReviewStatus;
