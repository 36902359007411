import { baseAPI } from "services/baseAPI";
import { apiConfig } from "../../config/authConfig";
import { Card } from "enada-common";

export const cardsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getCards: builder.query<Card[], void>({
      query: () => `${apiConfig.contentHubODataApiCards}`,
      transformResponse: (response: { value: Card[] }) => response.value,
      providesTags: result =>
        result ? [...result.map(({ id }) => ({ type: "Cards" as const, id })), "Cards"] : ["Cards"]
    }),

    getCard: builder.query<Card, number>({
      query: id => `${apiConfig.contentHubCardsEndpoint}/${id}`,
      transformResponse: (response: { data: Card }) => response.data,
      providesTags: (result, error, id) => [{ type: "Cards", id }]
    }),

    createCard: builder.mutation<Card, Partial<Card>>({
      query: body => ({
        url: apiConfig.contentHubCardsEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Card }) => response.data,
      invalidatesTags: ["Cards"]
    }),

    updateCard: builder.mutation<Card, Partial<Card>>({
      query: body => ({
        url: apiConfig.contentHubCardsEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Card }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Cards", id: body.id }]
    }),

    deleteCards: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCardsEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Cards", id }))
    }),

    recycleCards: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCardsEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Cards", id }))
    }),

    restoreCards: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubCardsEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Cards", id }))
    })
  })
});
