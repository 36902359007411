export function dateDifferenceInDays(date?: Date, date2?: Date): number | undefined {
  if (!date) {
    return undefined;
  }
  const diff = Math.floor(date.getTime() - (date2 ?? new Date()).getTime());
  const day = 1000 * 60 * 60 * 24;

  return Math.floor(diff / day);
}

export function formatDateTimeLocal(date: Date): string {
  return new Intl.DateTimeFormat(["en-GB"], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  }).format(new Date(date));
}
