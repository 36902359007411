import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { FC, useEffect, useState } from "react";
import { Choice, UserChoiceFieldProps } from "enada-common";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import ChoiceChip from "../choicechip/ChoiceChip";
import "./userchoicefield.scss";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
export interface UserSingleUserChoiceFieldProps extends Omit<UserChoiceFieldProps, "value"> {
  value: Choice;
}
const UserSingleChoiceField: FC<UserSingleUserChoiceFieldProps> = ({
  value,
  onChange,
  label,
  readOnly,
  autoFocus,
  disabled,
  choices,
  setReadOnly,
  isInTable,
  required,
  error,
  helperText
}) => {
  const [inputValue, setInputValue] = useState("");
  const [internalState, setInternalState] = useState<Choice | null>(value);

  const currentChoice = choices.find(choice => choice.id === internalState?.id);
  useEffect(() => {
    setInternalState(value);
  }, [value]);

  return (
    <>
      {readOnly ? (
        <Stack maxWidth={"100%"}>
          {Boolean(label) && !isInTable && (
            <EdisonTypography
              title={label}
              variant="fieldtitle"
              noWrap={false}
              sx={{
                display: "block"
              }}
            />
          )}
          <Stack
            maxWidth="100%"
            sx={{
              display: "inline-block"
            }}
          >
            {currentChoice && (
              <ChoiceChip
                label={currentChoice.label}
                colour={currentChoice.colour}
                readOnly={readOnly}
                disabled={disabled}
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <Autocomplete
          onBlur={() => {
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState);
          }}
          fullWidth
          openOnFocus
          ListboxProps={{ style: { padding: 0 } }}
          componentsProps={
            isInTable
              ? {
                  popper: {
                    placement: "bottom-start",
                    sx: { width: "inherit!important" }
                  }
                }
              : undefined
          }
          className={isInTable ? "in-table" : ""}
          size={"small"}
          disableCloseOnSelect
          options={choices}
          disabled={disabled}
          getOptionLabel={option => option?.label ?? ""}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          inputValue={inputValue}
          value={currentChoice}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Box key={option.id} width="100%" marginRight="3px">
                <ChoiceChip
                  label={option.label}
                  colour={option.colour}
                  readOnly={readOnly}
                  disabled={disabled}
                  additionalProps={getTagProps({ index })}
                  tooltip={internalState?.label}
                />
              </Box>
            ))
          }
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_event, changeValue: Choice | null) => {
            setInternalState(changeValue);
            if (isInTable) return;
            if (onChange) onChange(changeValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              label={!isInTable && label}
              helperText={error ? helperText : ""}
              required={required && !isInTable}
              variant="standard"
              autoFocus={autoFocus}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Stack flexDirection={"row"}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  sx={{
                    marginRight: "8px",
                    marginLeft: "-2px",
                    padding: "0px",
                    "& .MuiSvgIcon-root": { fontSize: 24 }
                  }}
                  checked={selected}
                />
                <Box>
                  <EdisonTypography variant={readOnly ? "data2" : "data"} title={option.label} />
                </Box>
              </Stack>
            </li>
          )}
        />
      )}
    </>
  );
};

export default UserSingleChoiceField;
export { UserSingleChoiceField };
