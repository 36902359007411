import { Stack, TextField, Box, Alert } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./editcardimage.scss";
import {
  RecordSettings,
  selectRecordAuth,
  selectRecord,
  updateSystemFields,
  selectRecordType
} from "../../store/slices/recordSlice";
import { EdisonImageField } from "enada-components";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { selectRegion } from "../../store/slices/configSlice";
import { getImagePath } from "../../utils/imagePathManager";
import { SYSTEM_FILE_SIZE_LIMIT } from "../../config/authConfig";
import { useGetCdnTokenQuery, useUpdateRecordImageMutation } from "services/api";

const SHORT_DESCRIPTION_LIMIT = 2048;

const EditCardImage: FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation(["common"]);
  const { data: cdnToken } = useGetCdnTokenQuery();
  const record = useAppSelector(selectRecord);
  const region = useAppSelector(selectRegion);
  const dispatch = useAppDispatch();
  const [updateRecordImage] = useUpdateRecordImageMutation();

  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);

  const image = useMemo(() => {
    if (!record) return;
    return {
      preview: getImagePath(record.imageUrl, region, cdnToken)
    };
  }, [cdnToken, record, region]);

  const onChangeImage = useCallback(
    async (image: File) => {
      if (!recordAuth) return;
      await updateRecordImage({
        recordId: recordAuth.details.RecordId,
        recordType: recordType,
        image: image
      });
    },
    [recordAuth, recordType, updateRecordImage]
  );

  return (
    <ProjectRecordFlyout title={t(!readOnly ? RecordSettings.editCardContent : "viewCardContent")}>
      <Stack direction="column" className="card-image-root">
        {readOnly ? (
          <Box
            sx={{
              textAlign: "center"
            }}
          >
            <img src={image?.preview ?? record?.imageUrl ?? ""} alt="Record" />
          </Box>
        ) : (
          <EdisonImageField
            accept="image/png, image/jpg, image/jpeg"
            label=""
            invalidMediaTypeMessage={t("invalidMediaType")}
            invalidFileSizeMessage={t("invalidFileSize")}
            maxFileSize={SYSTEM_FILE_SIZE_LIMIT}
            useInternalState={false}
            value={image}
            onChange={onChangeImage}
          />
        )}
        <Stack direction="column" spacing={2} marginTop={2}>
          <Alert severity="info" sx={{ marginBottom: "1em" }}>
            {t("shortDescriptionInfoMessage")}
          </Alert>
          <TextField
            type="text"
            className="short-description"
            id="short-description"
            disabled={readOnly}
            label={t("shortDescription")}
            variant="outlined"
            value={record?.shortDescription}
            rows={4}
            multiline
            helperText={`${record?.shortDescription?.length ?? 0}/${SHORT_DESCRIPTION_LIMIT}`}
            onChange={e => {
              dispatch(
                updateSystemFields({
                  key: "shortDescription",
                  changeValue: e.target.value
                })
              );
            }}
            inputProps={{
              maxLength: SHORT_DESCRIPTION_LIMIT
            }}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{ marginTop: "100px" }}
          spacing={1}
          justifyContent={"flex-end"}
        ></Stack>
      </Stack>
    </ProjectRecordFlyout>
  );
};

export default EditCardImage;
