import { TextField } from "@bryntum/core-thin";
import { Box } from "@mui/material";
import { t } from "i18next";
import EdisonTypography from "../../../edison/typography/EdisonTypography";
import { GanttColumnConfig } from "@bryntum/gantt-thin";
import { GridColumnConfig } from "@bryntum/grid-thin";

export const nameColumnConfig = (hideLeafIcon = true, useRegion = true) =>
  ({
    id: 1,
    type: "name",
    field: "e365-Name",
    name: "e365-Name",
    text: "Name",
    width: 250,
    ...(useRegion && { region: "details" }),
    ...(hideLeafIcon && { leafIconCls: "" }),
    renderer: ({ value }) => {
      return (
        <Box className="user-text-field-root" width={"100%"}>
          <EdisonTypography title={value} variant="data2" sx={{ textWrap: "balance" }} />
        </Box>
      );
    },
    editor: {
      maxLength: 254,
      listeners: {
        input: (event: { source: TextField; value: any }) => {
          if (event.source.value?.length >= 254) {
            event.source.setError(t("nameFieldWarning"), false, true);
          } else {
            event.source.clearError();
          }
        }
      }
    }
  } as GridColumnConfig | GanttColumnConfig);
