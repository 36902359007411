import { useEffect } from "react";
import { LicenseType, RoleType } from "enada-common";
import { Navigate, useLocation } from "react-router-dom";
import AccessDenied from "../pages/error/AccessDenied";
import { inputSelectTenant, setUserHasRequiredRole } from "../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Loading } from "enada-components";
import UnlicensedUser from "../pages/error/UnlicensedUser";
import { useGetMyLicenseQuery, useGetMyRolesQuery, useGetUserQuery } from "services/api";
import { useGetTenantLicenseQuery } from "services/api";

interface PrivateRouteProps {
  children: JSX.Element;
  roles?: RoleType[];
  minimumUserLicenseType?: LicenseType;
  minimumTenantLicenseType?: LicenseType;
}

const PrivateRoute = ({
  children,
  roles,
  minimumTenantLicenseType,
  minimumUserLicenseType
}: PrivateRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const tenant = useAppSelector(inputSelectTenant);
  const { data: tenantLicense, isLoading: tenantLicenseIsLoading } =
    useGetTenantLicenseQuery(tenant);
  const { data: user, isLoading: userIsLoading } = useGetUserQuery();
  const { data: userRoles = [], isLoading: userRolesIsLoading } = useGetMyRolesQuery();
  const { data: myLicense, isLoading: myLicenseIsLoading } = useGetMyLicenseQuery();

  const userRolesValues = userRoles.map((role: any) => role.value);
  const userHasRequiredRole = roles ? roles.some(r => userRolesValues.includes(r)) : true;

  useEffect(() => {
    dispatch(setUserHasRequiredRole(userHasRequiredRole));
    return () => {
      // We force the has required roles to true, to make sure that non-private routes can be accessed (eg: Homepage, personal)
      dispatch(setUserHasRequiredRole(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasRequiredRole]);

  if (userIsLoading || userRolesIsLoading || myLicenseIsLoading || tenantLicenseIsLoading) {
    return <Loading size={27} />;
  }

  if (user && !userHasRequiredRole) {
    return <AccessDenied />;
  }

  if (
    minimumUserLicenseType &&
    myLicense?.licenseType &&
    myLicense?.licenseType < minimumUserLicenseType
  ) {
    return <UnlicensedUser />;
  }

  if (
    minimumTenantLicenseType &&
    tenantLicense?.licenseType &&
    tenantLicense?.licenseType < minimumTenantLicenseType
  ) {
    return <UnlicensedUser />;
  }

  return !user ? <Navigate to="/login" state={{ from: location }} /> : children;
};

export default PrivateRoute;
