import { Box, Divider, Drawer, Stack } from "@mui/material";
import React, { FC, ReactNode } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectBackendNotificationsPanelOpen,
  setBackendNotificationsPanelOpen
} from "../../../store/slices/notificationSlice";
import { EdisonTypography } from "enada-components";

export interface NotificationDrawerProps {
  title: string;
  status: string;
  children: ReactNode;
  topBarModule?: ReactNode;
}
const NotificationDrawer: FC<NotificationDrawerProps> = ({
  title,
  status,
  children,
  topBarModule
}) => {
  const dispatch = useAppDispatch();
  const isNotificationDrawerOpen = useAppSelector(selectBackendNotificationsPanelOpen);

  return (
    <Drawer
      anchor={"right"}
      open={isNotificationDrawerOpen}
      ModalProps={{
        slotProps: { backdrop: { invisible: true } }
      }}
      PaperProps={{
        sx: {
          minWidth: "33em",
          maxWidth: "540px",
          width: "100%"
        }
      }}
      onClose={() => dispatch(setBackendNotificationsPanelOpen(false))}
    >
      <Box sx={{ padding: "20px" }}>
        <Stack direction={"column"}>
          <>
            <Stack direction={"row"} justifyContent="space-between" alignItems="center" spacing={2}>
              <Stack direction={"column"} sx={{ paddingBottom: "16px" }}>
                <EdisonTypography variant="h2" title={title} />
                <EdisonTypography variant="helpertext" title={status} />
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                {topBarModule}
                <CloseOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => dispatch(setBackendNotificationsPanelOpen(false))}
                />
              </Stack>
            </Stack>
            <Divider sx={{ marginBottom: 2 }} />
            {children}
          </>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default NotificationDrawer;
