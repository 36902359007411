import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualField,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import { EdisonChoiceEditor } from "enada-components";
import { Choice, FieldDataFormat } from "enada-common";

interface ChoiceConfigProps {
  multiLevel?: boolean;
}
const ChoiceConfig: FC<ChoiceConfigProps> = ({ multiLevel }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualField = useAppSelector(selectIndividualField);

  useEffect(() => {
    dispatch(
      updateIndividualFieldProperty({
        key: "dataFormat",
        value: multiLevel ? FieldDataFormat.WBS_01 : FieldDataFormat.None
      })
    );
  }, [dispatch, multiLevel]);

  return (
    <EdisonChoiceEditor
      enableTree={multiLevel}
      t={t}
      onChange={choices => {
        const invalidChoices = choices.filter(c => !c.label || c.label === "");
        if (!invalidChoices.length && choices.length) {
          dispatch(
            updateIndividualFieldProperty({
              key: "dataStructure",
              value: {
                ...(individualField?.dataStructure as any),
                choices
              }
            })
          );
        }
      }}
      data={(individualField?.dataStructure as any)?.choices as Choice[]}
    />
  );
};

export default ChoiceConfig;
