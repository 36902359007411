import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi
} from "@reduxjs/toolkit/query/react";
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { graphConfig, graphRequest } from "config/authConfig";
import { User } from "@microsoft/microsoft-graph-types";
import { msalInstance } from "./AuthenticationService";

type GraphBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const graphBaseQuery: GraphBaseQuery = async (args, api, extraOptions) => {
  const currentAccount = msalInstance.getActiveAccount();
  const requestedScope = graphRequest;
  const request = {
    ...requestedScope,
    account: currentAccount as AccountInfo
  };

  try {
    const response: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
    if (!response) {
      await msalInstance.acquireTokenRedirect(request);
      return { error: { status: 401, data: "Token acquisition failed" } };
    }

    const accessToken = response.accessToken;

    const headers = new Headers((args as any)?.extraHeaders || {});
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("Content-Type", "application/json");

    const modifiedArgs = typeof args === "string" ? { url: args } : { ...args };
    modifiedArgs.headers = headers;

    const result = await fetchBaseQuery({
      baseUrl: `${graphConfig.graphEndpoint}`
    })(modifiedArgs, api, extraOptions);

    return result;
  } catch (error) {
    return { error: { status: 500, data: "Request failed" } };
  }
};

export const graphAPI = createApi({
  reducerPath: "graphAPI",
  baseQuery: graphBaseQuery,
  tagTypes: ["User"],

  endpoints: builder => ({
    getUser: builder.query<User, void>({
      query: () => `/me`,
      providesTags: ["User"]
    }),

    getUserPhoto: builder.query<any, void>({
      query: () => ({
        url: `me/photo/$value`,
        responseHandler: response => {
          if (!response.ok) {
            return { error: response.statusText, status: response.status };
          }
          return response.blob() as any; // Handle response as blob
        }
      }),
      transformResponse: (response: Blob) => {
        if (response instanceof Blob) {
          const win = window.URL || window.webkitURL;
          return win.createObjectURL(response); // Convert blob to object URL
        }

        return response;
      },
      providesTags: ["User"]
    }),

    requestMsGraph: builder.mutation<any, string>({
      query: body => ({
        url: "/$batch",
        method: "POST",
        body
      })
      // invalidatesTags: ["User"]
    })
  })
});
