import { Box } from "@mui/material";
import { RecordType } from "enada-common";
import { FC, PropsWithChildren } from "react";

type RecordFlagProps = {
  recordType: RecordType;
  hideComponent?: boolean;
  hiddenRecordTypes?: RecordType[];
};

export const RecordFlag: FC<PropsWithChildren<RecordFlagProps>> = ({
  recordType,
  hiddenRecordTypes = [],
  hideComponent = false,
  children
}) => {
  const isHidden = hideComponent || hiddenRecordTypes.includes(recordType);

  if (isHidden) return null;

  return <Box width="full">{children}</Box>;
};
