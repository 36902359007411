import { Avatar, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";

import React, { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiConfig } from "../../config/authConfig";
import { EdisonTypography } from "enada-components";

import { useMsal } from "@azure/msal-react";
import dayjs from "dayjs";
import { localeObject } from "../../i18n/dayjsTranslations";
import LanguageSelectMenu, { availableLanguages } from "../languageSelectMenu/LanguageSelectMenu";
import NestedMenuItem from "../nestedmenuitem/NestedMenuItem";
import ThemeSelectMenu from "../themeselectmenu/ThemeSelectMenu";
import { User } from "@microsoft/microsoft-graph-types";

interface AvatarMenuProps {
  userPhoto: any;
  user: User | undefined;
}

const AvatarMenu: FC<AvatarMenuProps> = ({ userPhoto, user }): ReactElement => {
  const { t } = useTranslation(["common"]);
  const { instance } = useMsal();

  const [menuPosition, setMenuPosition] = useState<any>(null);
  const handleMenuClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleLogout = (instance: any) => {
    instance.logoutRedirect().catch((e: unknown) => {
      console.error(e);
    });
  };

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };
  const closeMenu = () => {
    setMenuPosition(null);
  };

  useEffect(() => {
    const currentLanguage = availableLanguages[0];
    dayjs.locale(localeObject[currentLanguage.id]);
  }, []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        display="flex"
        sx={{ cursor: "pointer" }}
        onClick={handleMenuClick}
      >
        <Avatar src={userPhoto} className="avatar" />
      </Stack>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        sx={{ padding: "20px", marginTop: "20px" }}
        anchorPosition={menuPosition}
      >
        <MenuItem onClick={handleItemClick}>
          <Stack direction="row" alignItems="center" width="100%" spacing={1}>
            <Avatar src={userPhoto} className="avatar" />
            <EdisonTypography title={user?.displayName ?? ""} variant="h4" />
          </Stack>
        </MenuItem>
        <Divider />
        <NestedMenuItem
          label={<EdisonTypography variant="h4" title={t("language")} />}
          icon={<LanguageOutlinedIcon />}
          parentMenuOpen={!!menuPosition}
          onClick={() => closeMenu()}
        >
          <LanguageSelectMenu closeMenu={closeMenu} />
        </NestedMenuItem>

        <NestedMenuItem
          label={<EdisonTypography variant="h4" title={t("theme")} />}
          icon={<PaletteOutlinedIcon />}
          parentMenuOpen={!!menuPosition}
          onClick={() => closeMenu()}
        >
          <ThemeSelectMenu closeMenu={closeMenu} />
        </NestedMenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            window.open(apiConfig.supportEndpoint, "_blank");
          }}
        >
          <>
            <ListItemIcon>{<OpenInBrowserOutlinedIcon />}</ListItemIcon>
            <ListItemText>
              {<EdisonTypography variant="h4" title={t("thirdPartyNotice")} />}
            </ListItemText>
          </>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            closeMenu();
            handleLogout(instance);
          }}
        >
          <>
            <ListItemIcon>{<LogoutOutlinedIcon />}</ListItemIcon>
            <ListItemText>{<EdisonTypography variant="h4" title={t("logout")} />}</ListItemText>
          </>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
