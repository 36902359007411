import {
  selectHasChanges,
  selectHasRecordChanges,
  selectRecordFieldValues,
  selectRecord,
  setIsSaving,
  selectRecordAuth,
  setHasRecordChanges,
  setClearChangedFields
} from "../../store/slices/recordSlice";
import { parseFormFieldsToBackend } from "../../store/slices/parseFormData";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { hasAccessRole } from "../hasAccessRole";
import { BaseRecord, RecordAccessRole, RecordType, useDebounce } from "enada-common";
import { getImagePath } from "../imagePathManager";
import { selectRegion } from "../../store/slices/configSlice";
import {
  selectHasTableConfigurationChanges,
  selectTableConfigs,
  selectTableOperations,
  setHasTableConfigurationChanges,
  clearTableOperations,
  swapActiveSaveSlot
} from "../../store/slices/recordTableSlice";
import {
  useGetCdnTokenQuery,
  useSaveRecordViewValuesMutation,
  useUpdateRecordMutation,
  useUpdateRecordTableConfigMutation,
  useUpdateTableRowValuesMutation
} from "services/api";

function useSaveRecord() {
  const dispatch = useAppDispatch();
  const region = useAppSelector(selectRegion);
  const { data: cdnToken } = useGetCdnTokenQuery(undefined, { skip: !region });

  const [updateRecord, { isLoading: updateRecordIsLoading }] = useUpdateRecordMutation();
  const [saveRecordViewValues, { isLoading: recordViewValuesIsLoading }] =
    useSaveRecordViewValuesMutation();
  const [updateTableRowValues, { isLoading: tableOperationsIsLoading }] =
    useUpdateTableRowValuesMutation();
  const [updateRecordTableConfig, { isLoading: tableConfigIsLoading }] =
    useUpdateRecordTableConfigMutation();

  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const hasChanges = useAppSelector(selectHasChanges);
  const hasTableConfigurationChanges = useAppSelector(selectHasTableConfigurationChanges);
  const hasProjectChanges = useAppSelector(selectHasRecordChanges);
  const projectFieldValues = useAppSelector(selectRecordFieldValues);
  const tableOperations = useAppSelector(selectTableOperations);
  const tableConfigs = useAppSelector(selectTableConfigs);

  const usePreviousLocation = (location: any) => {
    const prevLocRef = useRef(location);
    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);
    return prevLocRef.current;
  };
  const location = useLocation();
  const previousLocation = usePreviousLocation(location);

  useEffect(() => {
    const isLoading =
      tableOperationsIsLoading ||
      recordViewValuesIsLoading ||
      tableConfigIsLoading ||
      updateRecordIsLoading;
    dispatch(setIsSaving(isLoading));
  }, [
    dispatch,
    recordViewValuesIsLoading,
    tableConfigIsLoading,
    tableOperationsIsLoading,
    updateRecordIsLoading
  ]);

  const getImageUrl = useCallback(() => {
    return getImagePath(record?.imageUrl, region, cdnToken);
  }, [cdnToken, record?.imageUrl, region]);

  const parsed = useMemo(() => {
    if (!recordAuth) return [];
    return parseFormFieldsToBackend(projectFieldValues, recordAuth.details.RecordId, "recordId");
  }, [projectFieldValues, recordAuth]);

  const saveProject = useCallback(async () => {
    if (!recordAuth) return;

    if (!hasAccessRole(RecordAccessRole.Update, recordAuth.details.AccessRoles)) return;

    if (parsed?.length) {
      await saveRecordViewValues({
        recordId: recordAuth.details.RecordId,
        recordType: record?.recordType as RecordType,
        viewId: recordAuth.details.Views[0],
        body: parsed
      });

      dispatch(setClearChangedFields());
    }

    if (tableOperations?.length) {
      dispatch(swapActiveSaveSlot());

      await updateTableRowValues({
        recordAuth: recordAuth,
        recordType: record?.recordType as RecordType,
        body: tableOperations
      }).unwrap();

      dispatch(clearTableOperations());
    }

    if (hasTableConfigurationChanges) {
      await updateRecordTableConfig({
        recordId: recordAuth.details.RecordId,
        recordType: record?.recordType as RecordType,
        body: tableConfigs
      }).unwrap();

      dispatch(setHasTableConfigurationChanges(false));
    }

    if (hasProjectChanges && record) {
      await updateRecord({
        recordType: record?.recordType,
        record: {
          ...(record as BaseRecord),
          timeZone: record?.timeZone || new Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      });

      dispatch(setHasRecordChanges(false));
    }
  }, [
    recordAuth,
    parsed,
    tableOperations,
    hasTableConfigurationChanges,
    hasProjectChanges,
    record,
    dispatch,
    saveRecordViewValues,
    updateTableRowValues,
    updateRecordTableConfig,
    tableConfigs,
    updateRecord,
    getImageUrl
  ]);

  useEffect(() => {
    if (hasChanges && previousLocation.state?.record && !location.state?.record) {
      saveProject();
    }
  }, [
    hasChanges,
    location.pathname,
    location?.state?.record,
    previousLocation.state?.record,
    saveProject
  ]);

  useDebounce(
    () => {
      if (hasChanges && location.state?.record) {
        saveProject();
      }
    },
    5000,
    [projectFieldValues, tableOperations, record, tableConfigs]
  );
}

export default useSaveRecord;
