import { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Alert, Button, Stack, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EdisonSearchBar, EdisonTypography } from "enada-components";
import CloseIcon from "@mui/icons-material/Close";
import CollapsibleRow from "../collapsiblerow/CollapsibleRow";
import { ApprovalState, BaseFieldValue, BaseWorkItem, TableRowType } from "enada-common";
import OwnedItemsRow from "./owneditemsrow/OwnedItemsRow";
import { BorderedTableCell } from "../approvalstable/ApprovalsTable";
import { Link } from "react-router-dom";
import "./owneditems.scss";
import { getModuleIcon } from "../../moduleLabel/ModuleLabel";
import { selectThemeName } from "../../../store/slices/userSlice";
import { useGetOwnedItemsQuery } from "services/api";

export interface WorkItem extends BaseWorkItem {
  tableId?: number;
  rowType?: TableRowType;
  fieldValues?: BaseFieldValue[];
  approvalState?: ApprovalState;
}

const OwnedItems = () => {
  const { t } = useTranslation(["common"]);
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";
  const { data: ownedItems = [] } = useGetOwnedItemsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const [search, setSearch] = useState("");

  const [alertOpen, setAlertOpen] = useState(true);

  const getRowsGroupedByProject = () => {
    const grouped: Record<string, WorkItem[]> = ownedItems
      .filter(
        item =>
          search === "" ||
          item?.itemName?.includes(search) ||
          item.itemId?.toString().includes(search) ||
          item.recordName?.includes(search)
      )
      .reduce((acc, curr) => {
        if (Object.hasOwn(acc, curr?.recordName as string)) {
          return {
            ...acc,
            [curr?.recordName as string]: [...acc[curr.recordName as string], curr]
          };
        } else {
          return { ...acc, [curr?.recordName as string]: [curr] };
        }
      }, {} as Record<string, WorkItem[]>);
    return grouped;
  };

  return (
    <Stack className="owned-items-root" spacing={1}>
      {alertOpen && (
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              endIcon={<CloseIcon />}
              onClick={() => setAlertOpen(prev => !prev)}
            >
              {t("dismiss").toUpperCase()}
            </Button>
          }
        >
          <EdisonTypography
            sx={{ whiteSpace: "normal" }}
            title={t("ownedItemsMessage")}
            variant="data2"
          ></EdisonTypography>
        </Alert>
      )}
      <Stack direction={"row"}>
        <EdisonSearchBar
          placeholder={t("search")}
          searchFilter={search}
          setSearchFilter={setSearch}
        />
      </Stack>
      <Table className="owned-items-table">
        <TableHead>
          <TableRow>
            <BorderedTableCell> {t("name")}</BorderedTableCell>
            <BorderedTableCell> {t("tableName")}</BorderedTableCell>
            <BorderedTableCell> {t("status")}</BorderedTableCell>
            <BorderedTableCell className="action"> {t("actions")}</BorderedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(getRowsGroupedByProject()).map(([key, value]) => (
            <CollapsibleRow
              key={key}
              title={
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  {getModuleIcon(value[0].recordType, isDarkTheme)}{" "}
                  <Link
                    className="tasks-link"
                    to={`../${value[0].recordType.toLocaleLowerCase()}/${value[0].recordId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {key}
                  </Link>
                  <div>{`(${value.length ?? 0})`}</div>
                </Stack>
              }
              columnsCount={4}
            >
              {value.map((item, index) => (
                <OwnedItemsRow key={index} ownedItem={item} />
              ))}
            </CollapsibleRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};

export default OwnedItems;
