import { EnumSelectProps, getEnumValues } from "enada-common";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const EnumSelect = <T extends { [key: string]: string }>({
  t,
  value,
  enumDef,
  onChange,
  label,
  className,
  disabled,
  menuItemRenderer,
  sx
}: EnumSelectProps<T>) => {
  return (
    <FormControl sx={sx} variant="standard" className={className} size="small" disabled={disabled}>
      {Boolean(label) && <InputLabel id={`${label}-select-label`}>{label}</InputLabel>}
      <Select
        labelId={`${label}-select-label`}
        value={value}
        onChange={e => onChange(e.target.value as keyof typeof enumDef)}
      >
        {getEnumValues(enumDef).map((key, index) => {
          return menuItemRenderer ? (
            menuItemRenderer(key as any)
          ) : (
            <MenuItem key={`${index}-${key as string}`} value={key as string}>
              {t(key as string)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default EnumSelect;
export { EnumSelect };
