import {
  CalculatedExpressionUnit,
  ConditionBlock,
  ConditionalExpression,
  Expression,
  ExpressionDataType,
  ExpressionEntity,
  ExpressionOperator,
  ExpressionPart,
  Field,
  FieldDataType
} from "enada-common";
import { CompareOperators, StringComparators } from "../FrontendToBackendOperatorMap";

export const parseConditionBlockToBackend = (
  block: ConditionBlock,
  resultType: ExpressionDataType,
  fields: Field[]
): ConditionalExpression => {
  const baseConditionalExpression: ConditionalExpression = {
    conditionExpression: parseExpressionToBackend(block.if, fields, ExpressionDataType.Bool)
  };
  if (!block.then) {
    if (!block.conditionalThen) {
      throw new Error("Parsing error block must have  either a non empty then or conditional then");
    }
    baseConditionalExpression.successConditionalExpression = parseConditionBlockToBackend(
      block.conditionalThen,
      resultType,
      fields
    );
  } else {
    baseConditionalExpression.successExpression = parseExpressionToBackend(
      block.then,
      fields,
      resultType
    );
  }
  if (!block.else) {
    if (!block.conditionalElse) {
      throw new Error("Parsing error block must have either a non empty else or conditional else.");
    }
    baseConditionalExpression.failureConditionalExpression = parseConditionBlockToBackend(
      block.conditionalElse,
      resultType,
      fields
    );
  } else {
    baseConditionalExpression.failureExpression = parseExpressionToBackend(
      block.else,
      fields,
      resultType
    );
  }

  return baseConditionalExpression;
};

export const parseExpressionToBackend = (
  unitList: CalculatedExpressionUnit[],
  fields: Field[],
  resultType: ExpressionDataType
): Expression => {
  const openCount = unitList.filter(unit => isOpenOperator(unit)).length;
  const closedCount = unitList.filter(unit => isCloseOperator(unit)).length;
  if (openCount !== closedCount) throw new Error("Parenthese count mismatch error");
  const result = recursivelyParseNestedList(unitList, fields);
  return {
    resultType: resultType,
    expressionBlocks: [{ expressionParts: result }]
  };
};

const recursivelyParseNestedList = (
  unitList: CalculatedExpressionUnit[],
  fields: Field[]
): ExpressionPart[] => {
  let parts: ExpressionPart[] = [];
  const openCount = unitList.filter(unit => isOpenOperator(unit)).length;
  const closedCount = unitList.filter(unit => isCloseOperator(unit)).length;
  if (openCount === 0 && closedCount === 0) {
    return parseFlatUnitList(unitList, fields);
  }
  splitNestedList(unitList).forEach((subList, index) => {
    if (subList.isSimple) {
      parts = parts.concat(parseFlatUnitList(subList.list, fields));
      return;
    }
    if (subList.list.length < 3) throw new Error("Block must have at least 3 units");
    if (index === 0) {
      parts.push({
        expressionBlock: {
          expressionParts: recursivelyParseNestedList(subList.list.slice(1, -1), fields)
        }
      });
    } else {
      if (subList.list[0].type !== "operator") throw new Error("Must be operator before block");

      parts.push({
        expressionBlock: {
          joinOperator: subList.list[0].value as ExpressionOperator,
          expressionParts: recursivelyParseNestedList(subList.list.slice(2, -1), fields)
        }
      });
    }
  });

  return parts;
};
const parseFlatUnitList = (
  unitList: CalculatedExpressionUnit[],
  fields: Field[]
): ExpressionPart[] => {
  const parts = splitFlatListIntoParts(unitList, fields);
  return parts.map(part =>
    isDataTypeSimple(part.dataType) ? parseSimplePart(part) : parseComparePart(part)
  );
};
interface FrontendPart {
  dataType: ExpressionDataType;
  part: CalculatedExpressionUnit[];
}
const parseComparePart = ({ part, dataType }: FrontendPart): ExpressionPart => {
  let backendPart: Partial<ExpressionPart> = { dataType: dataType };
  const partCopy = part.slice();
  if (part.length > 4 || part.length < 3)
    throw new Error("Parsing error compare part must have 3 or 4 units");

  if (part.length === 4) {
    const operator = partCopy.shift();
    if (!operator || operator.type !== "operator")
      throw new Error("Parsing error compare part of length 4 must start with operator.");

    backendPart = { ...backendPart, ...getUnitValue(operator) };
  }

  const [compareFrom, comparator, compareTo] = partCopy;

  if (dataType === ExpressionDataType.Text && !isTextComparator(comparator))
    throw new Error("Parsing error comparator invalid for part");

  if (dataType === ExpressionDataType.DateTime && !isDateComparators(comparator))
    throw new Error("Parsing error comparator invalid for part");

  if (compareFrom.type !== "entity" && compareTo.type !== "entity")
    throw new Error("Parsing error cannot compare two fixed values");

  backendPart.compareOperator = comparator.value as ExpressionOperator;
  backendPart = {
    ...backendPart,
    ...getCompareValue(compareFrom, "compareFromEntity"),
    ...getCompareValue(compareTo, "compareToEntity")
  };
  return backendPart;
};
const parseSimplePart = ({ part, dataType }: FrontendPart): ExpressionPart => {
  if (part.length > 2) throw new Error("Parsing error part cannot have more than two units");

  if (part.length === 2) {
    if (part[0].type !== "operator") throw new Error("Parsing error part must start with operator");

    if (part[1].type === "operator") throw new Error("Parsing error part must finish with value");

    return {
      dataType: dataType,
      ...getUnitValue(part[0]),
      ...getUnitValue(part[1])
    };
  }
  return { dataType, ...getUnitValue(part[0]) };
};

const splitFlatListIntoParts = (
  unitList: CalculatedExpressionUnit[],
  fields: Field[]
): FrontendPart[] => {
  const splitList: FrontendPart[] = [];
  let startIndex = 0;
  let firstHalf: CalculatedExpressionUnit | null = null;
  unitList.forEach((unit, index) => {
    if (unit.type === "operator") return;
    const dataType = getUnitDataType(unit, fields);
    if (isDataTypeSimple(dataType)) {
      splitList.push({
        dataType,
        part: unitList.slice(startIndex, index + 1)
      });
      startIndex = index + 1;
      return;
    }
    if (firstHalf) {
      if (getUnitDataType(firstHalf, fields) !== dataType) throw new Error("Compare mismatch");
      splitList.push({
        dataType,
        part: unitList.slice(startIndex, index + 1)
      });
      firstHalf = null;
      startIndex = index + 1;
    } else {
      firstHalf = unit;
    }
  });
  if (firstHalf) throw new Error("Parsing error missing half of compare part");
  return splitList;
};

const splitNestedList = (
  unitList: CalculatedExpressionUnit[]
): {
  isSimple: boolean;
  list: CalculatedExpressionUnit[];
}[] => {
  const splits: { isSimple: boolean; list: CalculatedExpressionUnit[] }[] = [];
  let openCount = 0;
  let startIndex = 0;
  unitList.forEach((unit, index) => {
    if (isOpenOperator(unit)) {
      if (openCount === 0 && index !== 0) {
        const endIndex = index === 0 ? 0 : index - 1;
        splits.push({
          isSimple: true,
          list: unitList.slice(startIndex, endIndex)
        });
        startIndex = endIndex;
      }
      openCount += 1;
    }
    if (isCloseOperator(unit)) {
      openCount -= 1;
      if (openCount === 0) {
        splits.push({
          isSimple: false,
          list: unitList.slice(startIndex, index + 1)
        });
        startIndex = index + 1;
      }
    }

    if (index === unitList.length - 1 && startIndex < index) {
      splits.push({
        isSimple: true,
        list: unitList.slice(startIndex, index + 1)
      });
    }
  });

  return splits.filter(split => split.list.length > 0);
};

const getEntityFieldType = (entityId: number | undefined, fields: Field[]): FieldDataType => {
  const result = fields.find(field => field.id === entityId)?.dataType;
  if (!result) {
    throw new Error(`Could not find field with id ${entityId}`);
  }
  return result;
};

const getUnitDataType = (unit: CalculatedExpressionUnit, fields: Field[]): ExpressionDataType => {
  if (unit.type === "number") {
    return ExpressionDataType.Decimal;
  }
  if (unit.type === "text") {
    return ExpressionDataType.Text;
  }
  const fieldDataType = getEntityFieldType((unit.value as ExpressionEntity).entityId, fields);
  if (
    [FieldDataType.Currency, FieldDataType.Number, FieldDataType.Percentage].includes(fieldDataType)
  ) {
    return ExpressionDataType.Decimal;
  }
  if ([FieldDataType.Date, FieldDataType.DateTime].includes(fieldDataType)) {
    return ExpressionDataType.DateTime;
  }
  if (FieldDataType.TextBox === fieldDataType) {
    return ExpressionDataType.Text;
  }
  if (FieldDataType.Switch === fieldDataType) {
    return ExpressionDataType.Bool;
  }

  throw new Error("Invalid type added to calculated fields");
};

export const getUnitValue = (unit: CalculatedExpressionUnit): ExpressionPart => {
  if (unit.type === "entity") {
    return { singleEntity: unit.value as ExpressionEntity };
  }
  if (unit.type === "operator") {
    return { joinOperator: unit.value as ExpressionOperator };
  }
  return { fixedValue: unit.value };
};

const isOpenOperator = (unit: CalculatedExpressionUnit) =>
  unit.type === "operator" && unit.value === "(";
const isCloseOperator = (unit: CalculatedExpressionUnit) =>
  unit.type === "operator" && unit.value === ")";
const isDataTypeSimple = (type: ExpressionDataType) =>
  [ExpressionDataType.Bool, ExpressionDataType.Decimal].includes(type);
const isTextComparator = (unit: CalculatedExpressionUnit) => {
  if (unit.type !== "operator") return false;
  return StringComparators.some(pair => pair.backend === unit.value);
};
const isDateComparators = (unit: CalculatedExpressionUnit) => {
  if (unit.type !== "operator") return false;
  return CompareOperators.some(pair => pair.backend === unit.value);
};

const getCompareValue = (
  unit: CalculatedExpressionUnit,
  key: keyof ExpressionPart
): ExpressionPart => {
  if (unit.type === "entity") {
    return { [key]: unit.value as ExpressionEntity };
  }
  return { fixedValue: unit.value };
};
