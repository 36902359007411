import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { localeObject } from "../../i18n/dayjsTranslations";
import { EdisonTypography } from "enada-components";
import "./languageSelectMenu.scss";

export const availableLanguages = [
  {
    id: "en",
    name: "English"
  },
  { id: "pt", name: "Portugues" }
];

interface LanguageSelectMenuProps {
  closeMenu: any;
}

const LanguageSelectMenu: FC<LanguageSelectMenuProps> = ({ closeMenu }): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [t, i18n] = useTranslation("common");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    dayjs.locale(localeObject[lng]);
  };

  return (
    <>
      {availableLanguages.map(language => (
        <MenuItem
          key={language.id}
          onClick={() => {
            changeLanguage(language.id);
            closeMenu();
          }}
        >
          <EdisonTypography variant="h4" title={language.name} />
        </MenuItem>
      ))}
    </>
  );
};

export default LanguageSelectMenu;
