import { FC, useEffect, useState } from "react";
import { BaseUserFieldProps, fieldConfiguration, FieldDataType, useDebounce } from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import "./UserTextField.scss";
import { Box } from "@mui/material";

export interface UserTextFieldProps extends BaseUserFieldProps {
  isIconOn: boolean;
  minimumLength?: number;
  maximumLength?: number;
  setReadOnly?: (value: boolean) => void;
}

const UserTextField: FC<UserTextFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  minimumLength,
  maximumLength,
  setReadOnly,
  autoFocus,
  required,
  dataTestidValue
}) => {
  const { t } = useTranslation(["common"]);
  const getIsValid = (value: string) => {
    if (minimumLength && value) {
      return value.length >= minimumLength;
    }
    return true;
  };

  const [internalState, setInternalState] = useState<string>(value || "");
  const [isValid, setIsValid] = useState<boolean>(getIsValid(value));
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  const handleChange = (value: string) => {
    setInternalState(value);
    setIsValid(getIsValid(value));
    if (maximumLength && label === "Name" && value?.length === 254) {
      // If is the name field  we display a tooltip when the user reaches the maximum length
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }
  };

  useDebounce(
    () => {
      if (!isValid) return;
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState, isValid);
    },
    1000,
    [internalState]
  );

  return (
    <Box
      className="user-text-field-root"
      data-testid={`user-${dataTestidValue}`}
      width={isInTable ? "100%" : "auto"}
    >
      {!readOnly ? (
        <Tooltip
          title={t("nameFieldWarning")}
          placement="right-end"
          onClose={() => setOpenTooltip(false)}
          open={openTooltip}
          disableHoverListener
          disableFocusListener
          disableTouchListener
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#fff",
                color: "#ff0000",
                boxShadow: "none",
                border: "1px solid #ff0000",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "16px",
                maxWidth: "420px",
                "& span.MuiTooltip-arrow ": {
                  color: "#fff",
                  transform: "none !important",
                  top: "25px !important"
                },
                "& span.MuiTooltip-arrow::before": {
                  border: "1px solid #ff0000"
                }
              }
            }
          }}
        >
          <TextField
            required={required}
            variant="standard"
            type="text"
            multiline={maximumLength && label === "Name" ? false : isInTable}
            label={!isInTable && label}
            fullWidth
            className={maximumLength && label === "Name" ? "user-text-field" : ""}
            autoFocus={autoFocus}
            onFocus={
              isInTable
                ? event => {
                    event.target.select();
                  }
                : undefined
            }
            value={internalState ?? ""}
            onChange={e => {
              handleChange(e.target.value);
            }}
            inputProps={{
              minLength: minimumLength,
              maxLength: maximumLength
            }}
            helperText=""
            size="small"
            error={error || !isValid}
            onBlur={() => {
              if (!isValid) return;
              if (!setReadOnly) return;
              setReadOnly(true);
              if (!onChange) return;
              if (!isInTable) return;
              onChange(internalState);
            }}
          />
        </Tooltip>
      ) : (
        <EdisonFieldIcon
          title={label}
          subtitle={internalState}
          additionalStyles={{
            wordBreak: "break-word"
          }}
          icon={fieldConfiguration(FieldDataType.TextBox).icon}
          color={fieldConfiguration(FieldDataType.TextBox).color}
          isIconOn={isIconOn}
          isTableModeOn={!!isInTable}
          required={required}
          error={required && internalState === ""}
        />
      )}
    </Box>
  );
};

export default UserTextField;
export { UserTextField };
