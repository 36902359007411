import { Group, User } from "@microsoft/microsoft-graph-types";
import { useRequestMsGraphMutation } from "services/api";
import { GraphQueryType } from "services/graphService";

interface SingleRequest {
  method: string;
  id: string;
  url: string;
}

export const useMsGraphRequests = () => {
  const [requestMsGraph] = useRequestMsGraphMutation();

  const batchGetEntitiesById = async (
    ids: string[],
    queryType: GraphQueryType
  ): Promise<Record<string, Group | User>> => {
    const batchRequests: SingleRequest[][] = [];
    const requestsPerBatch = 20;
    const entitiesPerRequest = 15;
    const totalPerBatch = requestsPerBatch * entitiesPerRequest;

    const uniqueUserIds = new Set(ids);
    const seperated = [...uniqueUserIds].reduce((acc, curr, index) => {
      if (index % totalPerBatch === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push(curr);
      return acc;
    }, [] as Array<Array<string>>);

    //Seperate ids into batches of requests,
    seperated.forEach(batch => {
      const currentRequest: SingleRequest[] = [];

      batch.forEach((_, index) => {
        if (index % entitiesPerRequest === 0) {
          currentRequest.push({
            id: index.toString(),
            method: "GET",
            url: `/${queryType}?$filter=Id in (${[
              ...batch.slice(
                index,
                index + entitiesPerRequest > batch.length
                  ? batch.length
                  : index + entitiesPerRequest
              )
            ]
              .map(id => `'${id}'`)
              .join(",")})`
          });
        }
      });
      batchRequests.push(currentRequest);
    });

    const normalised: Record<string, User | Group> = await batchRequests.reduce(
      async (acc, req) => {
        const batchedResponse = await requestMsGraph(
          JSON.stringify({
            requests: req
          })
        ).unwrap();

        const people = batchedResponse.responses
          .filter((response: any) => response.status === 200)
          .map((response: any) => [...response.body.value])
          .flat()
          .reduce(
            (acc: any, curr: User | Group) => ({
              ...acc,
              [curr?.id ?? ""]: curr
            }),
            {}
          );
        return { ...(await acc), ...people };
      },
      {}
    );

    return normalised;
  };

  return { batchGetEntitiesById };
};
