import React from "react";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import CalendarViewWeekOutlinedIcon from "@mui/icons-material/CalendarViewWeekOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import CalculateIcon from "@mui/icons-material/Calculate";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import { DeviceHub } from "@mui/icons-material";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextFormatOutlinedIcon from "@mui/icons-material/TextFormatOutlined";
import TextFieldsOutlinedIcon from "@mui/icons-material/TextFieldsOutlined";
import HttpOutlinedIcon from "@mui/icons-material/HttpOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import { FeatureConfiguration } from "../models/frontend/FeatureConfiguration.model";
import { FieldDataType } from "../models/backend/Field.model";

import { TableDataType, TableViewType } from "../models/backend/Table.model";
import { RecordType, toCamelCase } from "..";
import { SxProps } from "@mui/material";

export const fieldConfiguration = (type: FieldDataType): FeatureConfiguration => {
  switch (type) {
    case FieldDataType.TextBox:
      return {
        displayName: toCamelCase(type),
        fieldName: "textFields",
        configName: "textFieldConfiguration",
        icon: <TextFieldsOutlinedIcon />,
        color: "#0C786E"
      };
    case FieldDataType.RichText:
      return {
        displayName: toCamelCase(type),
        fieldName: "richTextFields",
        configName: "richTextConfiguration",
        icon: <TextFormatOutlinedIcon />,
        color: "#0D5C95"
      };
    case FieldDataType.Currency:
      return {
        displayName: toCamelCase(type),
        fieldName: "currencyFields",
        configName: "currencyFieldConfiguration",
        icon: <CurrencyExchangeOutlinedIcon />,
        color: "#1A7D21"
      };
    case FieldDataType.Percentage:
      return {
        displayName: toCamelCase(type),
        fieldName: "percentageFields",
        configName: "percentageFieldConfiguration",
        icon: <PercentOutlinedIcon />,
        color: "#C72E0C"
      };
    case FieldDataType.Number:
      return {
        displayName: toCamelCase(type),
        fieldName: "numberFields",
        configName: "numberFieldConfiguration",
        icon: <NumbersOutlinedIcon />,
        color: "#590D95"
      };
    case FieldDataType.People:
      return {
        displayName: toCamelCase(type),
        fieldName: "peoplePickerFields",
        configName: "peopleFieldConfiguration",
        icon: <PersonAddOutlinedIcon />,
        color: "#A11A1A"
      };
    case FieldDataType.Switch:
      return {
        displayName: toCamelCase(type),
        fieldName: "switchFields",
        configName: "switchFieldConfiguration",
        icon: <ToggleOffOutlinedIcon />,
        color: "#B45233"
      };
    case FieldDataType.MultiPeople:
      return {
        displayName: toCamelCase(type),
        fieldName: "multiPeopleFields",
        configName: "multiPeopleFieldConfiguration",
        icon: <GroupAddOutlinedIcon />,
        color: "#E40606"
      };
    case FieldDataType.Calculated:
      return {
        displayName: toCamelCase(type),
        fieldName: "calculatedFields",
        configName: "",
        icon: <CalculateIcon />,
        color: "#950D4E"
      };
    // case FieldDataType.Image:
    //   return {
    //     displayName: toCamelCase(type),
    //     fieldName: "imageFields",
    //     configName: "",
    //     icon: <ImageOutlinedIcon />,
    //     color: "#950D0D",
    //   };
    case FieldDataType.Choice:
      return {
        displayName: toCamelCase(type),
        fieldName: "choiceFields",
        configName: "",
        icon: <CheckBoxIcon />,
        color: "#207F9D"
      };
    case FieldDataType.Date:
      return {
        displayName: toCamelCase(type),
        fieldName: "dateFields",
        configName: "",
        icon: <DateRangeOutlinedIcon />,
        color: "#1B8919"
      };
    case FieldDataType.Url:
      return {
        displayName: toCamelCase(type),
        fieldName: "urlFields",
        configName: "",
        icon: <HttpOutlinedIcon />,
        color: "#86076A"
      };
    case FieldDataType.Phone:
      return {
        displayName: toCamelCase(type),
        fieldName: "phoneFields",
        configName: "",
        icon: <PhoneOutlinedIcon />,
        color: "#5d0d96"
      };
    case FieldDataType.DateTime:
      return {
        displayName: toCamelCase(type),
        fieldName: "dateTimeFields",
        configName: "",
        icon: <EventOutlinedIcon />,
        color: "#960d5d"
      };
    case FieldDataType.MultiChoice:
      return {
        displayName: toCamelCase(type),
        fieldName: "multiChoiceFields",
        configName: "",
        icon: <LibraryAddCheckOutlinedIcon />,
        color: "#72551D"
      };
    case FieldDataType.Location:
      return {
        displayName: toCamelCase(type),
        fieldName: "locationFields",
        configName: "",
        icon: <PinDropOutlinedIcon />,
        color: "#385C08"
      };
    case FieldDataType.MultiLevelChoice:
      return {
        displayName: toCamelCase(type),
        fieldName: "multiLevelChoiceFields",
        configName: "",
        icon: <LibraryAddCheckOutlinedIcon />,
        color: "#72551d"
      };
    case FieldDataType.Email:
      return {
        displayName: toCamelCase(type),
        fieldName: "emailFields",
        configName: "",
        icon: <MailOutlineOutlinedIcon />,
        color: "#0F3AAA"
      };
    default:
      return {
        displayName: toCamelCase(type),
        fieldName: "defaultFields",
        configName: "",
        icon: <MailOutlineOutlinedIcon />,
        color: "#0F3AAA"
      };
  }
};

export const tablesConfiguration = (type: TableDataType): FeatureConfiguration => {
  switch (type) {
    case TableDataType.List:
      return {
        displayName: toCamelCase(type),
        fieldName: "listTables",
        configName: "",
        icon: <TableRowsOutlinedIcon />,
        color: "#0D9588"
      };
    case TableDataType.Schedule:
      return {
        displayName: toCamelCase(type),
        fieldName: "scheduleTables",
        configName: "",
        icon: <PlaylistAddCheckOutlinedIcon />,
        color: "#950D77"
      };
    case TableDataType.Periodic:
      return {
        displayName: toCamelCase(type),
        fieldName: "periodicTables",
        configName: "",
        icon: <CalendarViewWeekOutlinedIcon />,
        color: "#950D0D"
      };
    default:
      return {
        displayName: toCamelCase(type),
        fieldName: "defaultTables",
        configName: "",
        icon: <MailOutlineOutlinedIcon />,
        color: "#0F3AAA"
      };
  }
};

export const tablesViewConfiguration = (type: TableViewType): FeatureConfiguration => {
  switch (type) {
    case TableViewType.Grid:
      return {
        displayName: toCamelCase(type),
        fieldName: "gridTableViewType",
        configName: "",
        icon: <TableChartOutlinedIcon />,
        color: "#0D9588"
      };
    case TableViewType.Board:
      return {
        displayName: toCamelCase(type),
        fieldName: "boardTableViewType",
        configName: "",
        icon: <PlaylistAddCheckOutlinedIcon />,
        color: "#950D77"
      };
    default:
      return {
        displayName: toCamelCase(type),
        fieldName: "defaultTableViewType",
        configName: "",
        icon: <TableChartOutlinedIcon />,
        color: "#0F3AAA"
      };
  }
};

export const recordTypeConfiguration = (type: RecordType, sx?: SxProps): FeatureConfiguration => {
  switch (type) {
    case RecordType.Projects:
      return {
        displayName: toCamelCase(type),
        fieldName: "projects",
        configName: "",
        icon: <RocketLaunchOutlinedIcon sx={sx} />,
        color: "#C4213A",
        secondaryColor: "#FFEBEE"
      };
    case RecordType.BusinessCase:
      return {
        displayName: toCamelCase(type),
        fieldName: "businessCase",
        configName: "",
        icon: <WorkOutlineIcon sx={sx} />,
        color: "#B25B0A",
        secondaryColor: "#FEF7F4"
      };
    case RecordType.Programs:
      return {
        displayName: toCamelCase(type),
        fieldName: "programs",
        configName: "",
        icon: <DeviceHub sx={sx} />, // TODO: use correct icon!
        color: "#137160",
        secondaryColor: "#E6FBF7"
      };
    case RecordType.Ideas:
      return {
        displayName: toCamelCase(type),
        fieldName: "ideas",
        configName: "",
        icon: <LightbulbOutlinedIcon sx={sx} />, // TODO: use correct icon!
        color: "#0E7495",
        secondaryColor: "#E0F0FF"
      };
    case RecordType.Challenges:
      return {
        displayName: toCamelCase(type),
        fieldName: "challenges",
        configName: "",
        icon: <SportsScoreOutlinedIcon sx={sx} />,
        color: "#077836",
        secondaryColor: "#DCECE3"
      };
    default:
      return {
        displayName: toCamelCase(type),
        fieldName: "defaultModules",
        configName: "",
        icon: <MailOutlineOutlinedIcon sx={sx} />,
        color: "#C4213A",
        secondaryColor: "#FFEBEE"
      };
  }
};
