import { Button, Popper, Paper, ClickAwayListener, Stack, Badge } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import React, { FC, ReactElement, cloneElement, useEffect, useRef } from "react";
import { FilterOperation, FilterType, getFilterTypes } from "enada-common";
import BaseFilterRow from "../BaseFilterRow";
import { addFilterValueChange } from "../filterutils";
import "./edisonfilterselector.scss";

export interface EdisonFilterSelectorProps {
  disabled?: boolean;
  filterOperations: FilterOperation[];
  activeFilterOperations?: FilterOperation[];
  onChangeFilters: (filters: FilterOperation[]) => void;
  addFilterDisplayName: string;
  applyDisplayName: string;
  clearDisplayName: string;
  propertyDisplayName?: string;
  operatorDisplayName?: string;
  valueDisplayName?: string;
  getFilterTypeDisplayName?: (filterType: FilterType) => string;
  customTrigger?: ReactElement;
}

const EdisonFilterSelector: FC<EdisonFilterSelectorProps> = ({
  disabled,
  filterOperations,
  activeFilterOperations,
  onChangeFilters,
  addFilterDisplayName,
  applyDisplayName,
  clearDisplayName,
  propertyDisplayName = "",
  operatorDisplayName = "",
  valueDisplayName = "",
  getFilterTypeDisplayName,
  customTrigger
}) => {
  const [open, setOpen] = React.useState(false);
  const filterBtn = useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedFilters, setSelectedFilters] = React.useState<FilterOperation[] | undefined>();

  useEffect(() => {
    if (open === true) {
      if (activeFilterOperations && activeFilterOperations.length > 0) {
        setSelectedFilters(
          activeFilterOperations?.map((f, index) => {
            return { ...f, id: index };
          })
        );
      } else {
        addFilter();
      }
    }
  }, [activeFilterOperations, open]);

  const toggleClick = () => {
    setAnchorEl(filterBtn.current);
    setOpen(prev => !prev);
  };

  const handleClick = (event: any) => {
    if (event.target.localName === "body") {
      event.preventDefault();
      return;
    }
    if (open) {
      setSelectedFilters([]);
    }
    toggleClick();
  };

  const addFilter = () => {
    let newId = 0;
    if (selectedFilters && selectedFilters.length > 0) {
      newId = (selectedFilters[selectedFilters.length - 1].id ?? 0) + 1;
    }
    setSelectedFilters([
      ...(selectedFilters ?? []),
      {
        ...filterOperations[0],
        id: newId,
        operator: getFilterTypes(filterOperations[0].propertyType)[0]
      }
    ]);
  };

  const deleteFilter = (filterOp: FilterOperation) => {
    setSelectedFilters(selectedFilters?.filter(sf => sf.id !== filterOp.id));
  };

  const applyFilters = () => {
    onChangeFilters(selectedFilters ?? []);
    setSelectedFilters([]);
    toggleClick();
  };

  const clearAll = () => {
    onChangeFilters([]);
    setSelectedFilters([]);
    toggleClick();
  };

  const addFilterValueChanged = (newValue: any, filterOp: FilterOperation, propName: string) => {
    addFilterValueChange(
      newValue,
      filterOp,
      propName,
      filterOperations,
      selectedFilters,
      setSelectedFilters
    );
  };

  const iconButtonProps = {
    className: `iconButton ${open ? "active" : ""}`,
    ref: filterBtn,
    disabled,
    onClick: handleClick
  };

  return (
    <>
      {customTrigger ? (
        cloneElement(customTrigger, iconButtonProps)
      ) : (
        <Badge className="filterBadgeButton" badgeContent={activeFilterOperations?.length ?? 0}>
          <IconButton {...iconButtonProps}>
            <FilterListIcon />
          </IconButton>
        </Badge>
      )}
      <Popper
        className="filterWrapper"
        disablePortal
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
      >
        <ClickAwayListener onClickAway={handleClick}>
          <Paper className="popperContent">
            <Stack spacing={2}>
              {selectedFilters &&
                selectedFilters.map((sf: FilterOperation, index: number) => (
                  <Paper elevation={0} key={index} style={{ backgroundColor: "transparent" }}>
                    <BaseFilterRow
                      filterOperation={sf}
                      allFilterOperations={filterOperations}
                      deleteFilter={deleteFilter}
                      addFilterValueChanged={addFilterValueChanged}
                      getFilterTypeDisplayName={getFilterTypeDisplayName}
                      propertyDisplayName={propertyDisplayName}
                      operatorDisplayName={operatorDisplayName}
                      valueDisplayName={valueDisplayName}
                      showDeleteButton={selectedFilters.length > 1}
                    />
                  </Paper>
                ))}
              <Paper
                elevation={0}
                key={(selectedFilters?.length ?? 0) + 1}
                style={{ backgroundColor: "transparent" }}
              >
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Button
                    className="addFilterButton"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={addFilter}
                  >
                    {addFilterDisplayName}
                  </Button>
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button color="primary" variant="outlined" onClick={applyFilters}>
                      {applyDisplayName}
                    </Button>
                    <Button color="error" variant="outlined" onClick={clearAll}>
                      {clearDisplayName}
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default EdisonFilterSelector;
export { EdisonFilterSelector };
