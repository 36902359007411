import { BaseRecord, NotificationLevel, reducerStatus, RecordType } from "enada-common";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getRecords, recycleRecord } from "../../services/APIService";
import { RootState } from "../store";
import { setCurrentNotification } from "./notificationSlice";
import { RecordAuth, getHeadersFromAuth } from "./recordSlice";

export interface RecordsState {
  status: reducerStatus;
  distinctProperties: any[];
  records: Record<number, BaseRecord>;
  ordereredIds: number[];
  refreshRecords: boolean;
}

const initialState: RecordsState = {
  status: "idle",
  distinctProperties: [],
  records: {},
  ordereredIds: [],
  refreshRecords: false
};

export const getRecordsAsync: any = createAsyncThunk(
  "projects/getRecords",
  async (
    {
      queryString,
      recordType
    }: { queryString: string | undefined; recordType: string | undefined },
    { rejectWithValue }
  ) => {
    const response = queryString
      ? await getRecords(`${queryString}`)
      : ((await getRecords("")) as any);

    if (!response.value) {
      return rejectWithValue(response);
    }

    return response.value;
  }
);

export const getDistinctValuesAsync: any = createAsyncThunk(
  "projects/getDistinctValues",
  async (propertyName: string, { rejectWithValue }) => {
    const response = (await getRecords(`?$apply=groupby((${propertyName}))`)) as any;

    if (!response.value) {
      return rejectWithValue(response);
    }

    return response.value.map((v: any) => v[propertyName]);
  }
);

const recordsSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    resetRecords: state => {
      state.records = {};
      state.ordereredIds = [];
    },
    setRefreshRecords: (state, action: PayloadAction<boolean>) => {
      state.refreshRecords = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getRecordsAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getRecordsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getRecordsAsync.fulfilled, (state, action) => {
        state.status = "idle";

        //Remove duplicate records from
        const ids = new Set([
          ...state.ordereredIds,
          ...action.payload.map((record: any) => record.id)
        ]);
        state.ordereredIds = [...ids];
        state.records = action.payload.reduce((acc: any, curr: any) => {
          return { ...acc, [curr.id]: curr };
        }, state.records);
      })
      .addCase(getDistinctValuesAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(getDistinctValuesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getDistinctValuesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.distinctProperties = action.payload;
      });
  }
});
export const { resetRecords, setRefreshRecords } = recordsSlice.actions;

export const selectRecordsList = (state: RootState) =>
  state.records.ordereredIds.map(id => state.records.records[id]);

export const selectRecordById = createSelector(
  (state: RootState) => state.records.records,
  (_state: RootState, id: string) => id,
  (records, id) => records[id]
);
export const selectDistinctProperties = (state: RootState) => state.records.distinctProperties;

export const selectRefreshRecords = (state: RootState) => state.records.refreshRecords;
export const selectRecordsStatus = (state: RootState) => state.records.status;

export default recordsSlice.reducer;
