import { Paper } from "@mui/material";
import React, { CSSProperties, FC, ReactNode } from "react";
import "./card.scss";
export interface EdisonCardProps {
  children: ReactNode;
  className?: any;
  padding?: string;
  backgroundColor?: string;
}
const EdisonCard: FC<EdisonCardProps> = ({ children, className, padding, backgroundColor }) => {
  return (
    <Paper className="card" style={getStyles(padding, backgroundColor)} data-testid={"edison-card"}>
      <div>{children}</div>
    </Paper>
  );
};
const getStyles = (padding?: string, backgroundColor?: string): CSSProperties => {
  if (padding) {
    if (backgroundColor) {
      return { padding: padding, backgroundColor: backgroundColor };
    }
    return { padding: padding };
  }
  if (backgroundColor) {
    return { backgroundColor: backgroundColor };
  }
  return {};
};
export default EdisonCard;
export { EdisonCard };
