import { FilterPropertyType, OdataOperations, OrderOperation } from "enada-common";
import { TFunction } from "i18next";
import { getStartAndEndOfMonth, getStartAndEndOfWeek } from "../../utils/filterUtils";

export const getIdeasQuickFilters = (currentUserId: string, t: TFunction): OdataOperations => {
  return {
    skip: 0,
    filters: [
      {
        id: 1001,
        displayName: t("ownByMe"),
        property: "isOwner",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true,
      },
      {
        id: 1002,
        displayName: t("favourited"),
        property: "favourited",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true
      }
    ]
  };
};

export const getChallengeQuickFilters = (currentUserId: string, t: TFunction): OdataOperations => {
  return {
    skip: 0,
    filters: [
      {
        id: 1002,
        displayName: t("favourited"),
        property: "favourited",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true
      }
    ]
  };
};

export const getIdeasQuickOrderOps = (t: TFunction): OrderOperation[] => {
  return [
    {
      id: 2001,
      displayName: t("newest"),
      property: "created",
      orderType: "desc",
      isQuickFilter: true
    },
    {
      id: 2002,
      displayName: t("oldest"),
      property: "created",
      orderType: "asc",
      isQuickFilter: true
    },
    {
      id: 2003,
      displayName: t("highestRating"),
      property: "averageRating",
      orderType: "desc",
      isQuickFilter: true
    },
    {
      id: 2004,
      displayName: t("lowestRating"),
      property: "averageRating",
      orderType: "asc",
      isQuickFilter: true
    },
    {
      id: 2005,
      displayName: t("mostViewed"),
      property: "viewCount",
      orderType: "desc",
      isQuickFilter: true
    }
  ];
};

export const getProgramQuickFilters = (currentUserId: string, t: TFunction): OdataOperations => {
  return {
    skip: 0,
    filters: [
      {
        id: 1002,
        displayName: t("favourited"),
        property: "favourited",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1003,
        displayName: t("inProgress"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 0,
        operator: "gt",
        isQuickFilter: true,
        quickFilterExtraOps: [
          {
            value: 100,
            operator: "lt",
            propertyType: FilterPropertyType.Number
          }
        ]
      },
      {
        id: 1004,
        displayName: t("overdue"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 100,
        operator: "ne",
        isQuickFilter: true,
        quickFilterSiblingOps: [
          {
            property: "endDate",
            propertyType: FilterPropertyType.Date,
            value: new Date(),
            operator: "lt",
            parentComparator: "and"
          }
        ]
      },

      {
        id: 1005,
        displayName: t("upcoming"),
        property: "startDate",
        propertyType: FilterPropertyType.Date,
        value: new Date(),
        operator: "gt",
        isQuickFilter: true
      },
      {
        id: 1006,
        displayName: t("complete"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 100,
        operator: "eq",
        isQuickFilter: true
      },

      {
        id: 1007,
        displayName: t("finishesThisWeek"),
        property: "endDate",
        propertyType: FilterPropertyType.Date,
        value: getStartAndEndOfWeek().end,
        operator: "le",
        isQuickFilter: true,
        quickFilterExtraOps: [
          {
            value: getStartAndEndOfWeek().start,
            operator: "ge",
            propertyType: FilterPropertyType.Date
          }
        ]
      },
      {
        id: 1008,
        displayName: t("finishesThisMonth"),
        property: "endDate",
        propertyType: FilterPropertyType.Date,
        value: getStartAndEndOfMonth().end,
        operator: "le",
        isQuickFilter: true,
        quickFilterSiblingOps: [],
        quickFilterExtraOps: [
          {
            value: getStartAndEndOfMonth().start,
            operator: "ge",
            propertyType: FilterPropertyType.Date
          }
        ]
      }
    ]
  };
};

export const getQuickFilters = (currentUserId: string, t: TFunction): OdataOperations => {
  return {
    skip: 0,
    filters: [
      {
        id: 1001,
        displayName: t("ownByMe"),
        property: "owners",
        propertyType: FilterPropertyType.ObjectArray,
        value: currentUserId,
        operator: "eq",
        isQuickFilter: true,
        objectArrayProperty: "entityId"
      },
      {
        id: 1002,
        displayName: t("favourited"),
        property: "favourited",
        propertyType: FilterPropertyType.Bool,
        value: true,
        operator: "eq",
        isQuickFilter: true
      },
      {
        id: 1003,
        displayName: t("inProgress"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 0,
        operator: "gt",
        isQuickFilter: true,
        quickFilterExtraOps: [
          {
            value: 100,
            operator: "lt",
            propertyType: FilterPropertyType.Number
          }
        ]
      },
      {
        id: 1004,
        displayName: t("overdue"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 100,
        operator: "ne",
        isQuickFilter: true,
        quickFilterSiblingOps: [
          {
            property: "endDate",
            propertyType: FilterPropertyType.Date,
            value: new Date(),
            operator: "lt",
            parentComparator: "and"
          }
        ]
      },

      {
        id: 1005,
        displayName: t("upcoming"),
        property: "startDate",
        propertyType: FilterPropertyType.Date,
        value: new Date(),
        operator: "gt",
        isQuickFilter: true
      },
      {
        id: 1006,
        displayName: t("complete"),
        property: "percentDone",
        propertyType: FilterPropertyType.Number,
        value: 100,
        operator: "eq",
        isQuickFilter: true
      },

      {
        id: 1007,
        displayName: t("finishesThisWeek"),
        property: "endDate",
        propertyType: FilterPropertyType.Date,
        value: getStartAndEndOfWeek().end,
        operator: "le",
        isQuickFilter: true,
        quickFilterExtraOps: [
          {
            value: getStartAndEndOfWeek().start,
            operator: "ge",
            propertyType: FilterPropertyType.Date
          }
        ]
      },
      {
        id: 1008,
        displayName: t("finishesThisMonth"),
        property: "endDate",
        propertyType: FilterPropertyType.Date,
        value: getStartAndEndOfMonth().end,
        operator: "le",
        isQuickFilter: true,
        quickFilterSiblingOps: [],
        quickFilterExtraOps: [
          {
            value: getStartAndEndOfMonth().start,
            operator: "ge",
            propertyType: FilterPropertyType.Date
          }
        ]
      }
    ]
  };
};
