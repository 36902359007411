import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  personalDataDeletedRollupTable,
  personalDataRollupTable
} from "../../../config/rollupTableColumns";
import { useAppDispatch } from "../../../store/hooks";
import { DeleteDialog, EdisonTypography, RestoreDialog, RollupTable } from "enada-components";
import PersonalDataSync from "./PersonalDataSync";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { Box, Paper, Stack } from "@mui/material";
import {
  useGetUserDetailsQuery,
  useRecycleUserDetailsMutation,
  useRestoreUserDetailsMutation
} from "services/api";
import { selectRollupRowsFromData } from "utils/rtkQuery";
import { PersonalData as PersonalDataType } from "types/personalData";
import { ODataApiResponse } from "types/ApiResponse";
import { setCurrentNotification } from "store/slices/notificationSlice";
import { ApiError, NotificationLevel } from "enada-common";

export interface ButtonToHide {
  tab: "active" | "deleted";
  button: "main" | "icon";
}

const selectPersonalDataRollUp = selectRollupRowsFromData<ODataApiResponse<PersonalDataType[]>>(
  data => {
    return data?.value?.map(userInfo => ({
      id: userInfo.id as any,
      displayName: userInfo.displayName,
      modified: userInfo.modified,
      modifiedBy: userInfo.modifiedBy,
      type: userInfo.type,
      department: userInfo.department,
      email: userInfo.email,
      jobTitle: userInfo.jobTitle,
      isDeleted: userInfo.isDeleted
    }));
  }
);

const PersonalData: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [recyclingUserIds, setRecyclingUserIds] = useState<string[]>([]);

  const { rollupRows = [] } = useGetUserDetailsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectPersonalDataRollUp(result ?? [])
    })
  });
  const [recycleUserDetails] = useRecycleUserDetailsMutation();
  const [restoreUserDetails] = useRestoreUserDetailsMutation();

  const recycleRows = async (ids: string[]) => {
    try {
      await ids.forEach(id => recycleUserDetails(id).unwrap());

      dispatch(
        setCurrentNotification({
          title: "userRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e) {
      const error = e as { data: ApiError };

      dispatch(
        setCurrentNotification({
          title: "userRecycledError",
          message: error.data.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    if (!ids?.length) return;

    try {
      const queryString = ids
        .map((id, index) => `oids=${id}${index < ids.length - 1 ? "&" : ""}`)
        .join("");

      await restoreUserDetails(queryString).unwrap();

      dispatch(
        setCurrentNotification({
          title: "userDetails.restored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e) {
      const error = e as { data: ApiError };

      dispatch(
        setCurrentNotification({
          title: "userDetails.restoredError",
          message: error.data.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Paper>
          <Stack spacing={1} padding={1}>
            <EdisonTypography
              title={t("managePersonalDataHeaderNote")}
              variant={"data2"}
              sx={(theme: any) => ({
                color: theme.palette.Secondary.main
              })}
            ></EdisonTypography>
          </Stack>
        </Paper>
      </Stack>
      <RollupTable
        fullScreen={false}
        setFullScreen={() => {
          /*nothing*/
        }}
        order="desc"
        orderBy="modified"
        renderEmptyTable={true}
        buttonsToHide={[
          { tab: "deleted", button: "icon" },
          // { tab: "deleted", button: "main" },
          { tab: "active", button: "main" }
        ]}
        editAction={() => {
          console.log("no edit action");
        }}
        addAction={() => {
          console.log("no add action");
        }}
        columns={personalDataRollupTable(t)}
        deletedTabDifferentColumns={true}
        deletedTabColumns={personalDataDeletedRollupTable(t)}
        rows={rollupRows.filter(field => !field.isDeleted)}
        deletedRows={rollupRows.filter(field => field.isDeleted)}
        t={t}
        deleteAction={() => console.log("waiting to be implemented?")}
        recycleAction={(ids: string[]) => {
          setRecyclingUserIds(ids);
          setOpenDeleteDialog(true);
          setSelectedRowIds(ids);
        }}
        restoreAction={(ids: string[]) => {
          setOpenRestoreDialog(true);
          setSelectedRowIds(ids);
        }}
        hideCreateButton={true}
        additionalButtonsLeft={<PersonalDataSync />}
        customTabIcons={[
          { tab: "active", icon: <PersonOutlineOutlinedIcon /> },
          { tab: "deleted", icon: <PersonOffOutlinedIcon /> }
        ]}
      />
      {openDeleteDialog && recyclingUserIds.length && (
        <DeleteDialog
          title={t("userDetails.deleteDialog.title")}
          content={
            <>
              {t("userDetails.deleteDialog.deleteContentOne")}
              &nbsp;
              <Box fontWeight="bold">
                {recyclingUserIds.map(
                  (id, index) =>
                    rollupRows.find((row: any) => row.id === id)?.displayName +
                    (index < recyclingUserIds.length - 1 ? ", " : " ")
                )}{" "}
              </Box>
              {t("userDetails.deleteDialog.deleteContentTwo")}
              <br />
              <br />
              {t("userDetails.deleteDialog.deleteContentThree")}
              <br />
              <br />
              {t("userDetails.deleteDialog.deleteContentFour")}
            </>
          }
          onClose={function (agreed: boolean): void {
            agreed && recycleRows(selectedRowIds);
            setOpenDeleteDialog(false);
            setSelectedRowIds([]);
          }}
        />
      )}
      {openRestoreDialog && (
        <RestoreDialog
          title={t("userDetails.restoreDialog.title")}
          content={
            <>
              {t("userDetails.restoreDialog.contentStart")}
              &nbsp;
              <EdisonTypography
                sx={{ fontWeight: 700 }}
                variant="data2"
                title={t("userDetails.restoreDialog.contentUserIds", {
                  userIds: selectedRowIds,
                  count: selectedRowIds.length
                })}
              />
              &nbsp;
              {t("userDetails.restoreDialog.contentUserIdsEnd")}
              <br />
              <br />
              {t("userDetails.restoreDialog.contentEnd")}
            </>
          }
          onClose={function (agreed: boolean): void {
            agreed && restoreRows(selectedRowIds);
            setOpenRestoreDialog(false);
            setSelectedRowIds([]);
          }}
        />
      )}
    </>
  );
};

export default PersonalData;
