import { FC } from "react";
import "./typography.scss";
import clsx from "clsx";
import { EdisonTypographyProps } from "enada-common";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import { useTypographyFromVariant } from "../../utils/hooks/useTypographyFromVariant";

const EdisonTypography: FC<EdisonTypographyProps> = ({
  title,
  variant,
  enableUppercase,
  sx,
  noWrap
}) => {
  const { variantData } = useTypographyFromVariant(variant);

  return (
    <Typography
      data-testid={"edison-typography"}
      noWrap={noWrap}
      component="span"
      sx={sx}
      whiteSpace={noWrap ? "nowrap" : "inherit"}
      letterSpacing={variantData.letterSpacing}
      fontSize={variantData.fontSize}
      fontWeight={variantData.fontWeight}
      className={clsx("edison-typography", variantData.variantName, {
        uppercase: enableUppercase
      })}
    >
      {parse(sanitizeHtml(title).toString())}
    </Typography>
  );
};
export default EdisonTypography;
export { EdisonTypography };
