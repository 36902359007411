import {
  ApiError,
  NotificationLevel,
  ResourceType,
  RollupTableRowModel,
  toCamelCase
} from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resourceRollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { useAppDispatch } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import "./resources.scss";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import {
  useDeleteResourcesMutation,
  useGetCalendarsQuery,
  useGetResourcesQuery,
  useRecycleResourcesMutation,
  useRestoreResourcesMutation
} from "services/api";
import { useGetAADResources } from "utils/hooks/useGetAADResources";

const Resources: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const { aadResources } = useGetAADResources();

  const { data: resources = [] } = useGetResourcesQuery();
  const { data: calendars = [] } = useGetCalendarsQuery();
  const [restoreResources] = useRestoreResourcesMutation();
  const [deleteResources] = useDeleteResourcesMutation();
  const [recycleResources] = useRecycleResourcesMutation();

  const getError = (error: ApiError) => {
    if (error?.detail) {
      const errorArr = JSON.parse(error.detail);
      const errorMessage = errorArr.reduce((acc: string, curr: any) => {
        let formatted = "";
        if (error.title === "ResourceAssignedAsManager") {
          const resources = curr.ManagedResources.map(
            (resource: any) =>
              resource.ResourceName ??
              aadResources[resource.ResourceEntityId]?.displayName ??
              resource.ResourceEntityId
          ).join(", ");
          formatted = `${t("resourceIsResourceManagerError", {
            resource: aadResources[curr.ResourceEntityId]?.displayName,
            resources: resources
          })}\n`;
        } else if (error.title === "ResourceAssignedToTask") {
          const recordNames = curr.Records.map((record: any) => record.RecordName).join(", ");
          formatted = `${t("resourceAssignedToTaskError", {
            resource: aadResources[curr.ResourceEntityId]?.displayName,
            records: recordNames
          })}\n`;
        }

        return acc + formatted + "\n";
      }, "");
      dispatch(
        setCurrentNotification({
          title: error.title,
          message: errorMessage,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const navigate = useNavigate();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteResources(ids.map((id: string) => parseInt(id))).unwrap();
      dispatch(
        setCurrentNotification({
          title: "resourceDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e: any) {
      const error = e.data as ApiError;

      getError(error);
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleResources(ids.map((id: string) => parseInt(id))).unwrap();
      dispatch(
        setCurrentNotification({
          title: "resourceRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e) {
      const error = e as { data: ApiError };

      getError(error.data);
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreResources(ids.map((id: string) => parseInt(id))).unwrap();
      dispatch(
        setCurrentNotification({
          title: "resourceRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch (e) {
      const error = e as { data: ApiError };

      getError(error.data);
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`editresource`, {
          state: {
            resourceId: id,
            title: "resources"
          }
        });
      }}
      addAction={() => {
        navigate("newresource", {
          state: {
            title: "resources"
          }
        });
      }}
      columns={resourceRollupTableColumnsWithType(t)}
      rows={
        resources
          .filter(resource => !resource.isDeleted)
          .map((resource: any) => ({
            ...resource,
            isActive: resource.isActive ? "active" : "inactive",
            calendar: calendars.find(cal => cal.id === resource.calendarId)?.displayName,
            type: t(toCamelCase(resource.type)),
            displayName:
              resource.type === ResourceType.AAD
                ? aadResources[resource.userId]?.displayName
                : resource.displayName
          })) as unknown as RollupTableRowModel[]
      }
      deletedRows={
        resources.filter(resource => resource.isDeleted) as unknown as RollupTableRowModel[]
      }
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Resources;
