import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ConfigState {
  region: string;
}

const initialState: ConfigState = {
  region: ""
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
    }
  }
});

export const { setRegion } = configSlice.actions;

const inputSelectConfig = (state: RootState) => state.config;

export const selectRegion = createSelector([inputSelectConfig], config => config.region);

export default configSlice.reducer;
