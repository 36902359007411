import { Avatar, Box, Stack } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { RecordSettings, RecordEvents, selectRecord } from "../../store/slices/recordSlice";
import dayjs from "dayjs";
import { People } from "@microsoft/mgt-react";
import "./historyEvents.scss";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { RecordType, toCamelCase } from "enada-common";
import { AvatarsTemplate } from "../../config/rollupTableColumns";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Loading } from "enada-components";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CachedIcon from "@mui/icons-material/Cached";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import { useGetHistoryEventsQuery } from "services/api";

const HistoryEvents: FC<{ readOnly: boolean; scope: RecordType }> = ({ scope }) => {
  const { t } = useTranslation(["common"]);

  const record = useAppSelector(selectRecord);

  const { data: historyEvents = [] } = useGetHistoryEventsQuery(
    { recordType: scope, recordId: record?.id ?? 0 },
    { skip: !record }
  );

  return (
    <ProjectRecordFlyout
      title={t(RecordSettings.historyEvents)}
      titleIcon={<FilterListIcon className="filterIconHistory" />}
    >
      <Stack direction="column">
        <Box sx={{ padding: "10px 10px" }}>
          {Array.isArray(historyEvents) ? (
            historyEvents.map((el: ItemProps) => {
              let recordTypeKey = toCamelCase(String(scope));
              if (recordTypeKey.charAt(recordTypeKey.length - 1) == "s") {
                recordTypeKey = recordTypeKey.slice(0, -1);
              }
              return (
                <Item
                  key={el.id}
                  recordEvent={el.recordEvent}
                  recordEventText={t(RecordEvents[el.recordEvent as keyof unknown], {
                    projectName: el.attributes?.DisplayName,
                    recordType: `$t(${recordTypeKey})`
                  })}
                  modified={el.modified}
                  modifiedBy={el.modifiedBy}
                  additionalClass="history-item-container"
                />
              );
            })
          ) : (
            <Loading size={30} />
          )}
        </Box>
      </Stack>
    </ProjectRecordFlyout>
  );
};

interface ItemProps {
  id?: any;
  additionalClass?: string;
  recordEventText?: any;
  recordEvent: string;
  modified: string;
  modifiedBy: string;
  attributes?: any;
}

const Item: FC<ItemProps> = ({
  additionalClass,
  recordEventText,
  recordEvent,
  modified,
  modifiedBy
}) => {
  let HistoryIcon;
  switch (recordEvent) {
    case "Create":
      HistoryIcon = <AddOutlinedIcon fontSize="small" />;
      break;
    case "Update":
      HistoryIcon = <CachedIcon fontSize="small" />;
      break;
    case "UpdatePermission":
      HistoryIcon = <PersonOutlineIcon fontSize="small" />;
      break;
    case "Delete":
      HistoryIcon = <DeleteOutlinedIcon fontSize="small" />;
      break;
    case "Restore":
      HistoryIcon = <RestoreOutlinedIcon fontSize="small" />;
      break;
    case "VersionCreate":
      HistoryIcon = <InsertDriveFileOutlinedIcon fontSize="small" />;
      break;
    case "StageChange":
      HistoryIcon = <CachedIcon fontSize="small" />;
      break;
    case "ApproveReview":
      HistoryIcon = <RateReviewOutlinedIcon fontSize="small" />;
      break;
    case "RejectReview":
      HistoryIcon = <DeleteOutlinedIcon fontSize="small" />;
      break;
    case "EditSession":
      HistoryIcon = <CachedIcon fontSize="small" />;
      break;
    case "CreateAssociation":
      HistoryIcon = <LinkOutlinedIcon fontSize="small" />;
      break;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ padding: "10px 0px 23px 0px" }}
      className={additionalClass}
    >
      <Avatar className="history-item-icon">{HistoryIcon}</Avatar>
      <Box paddingLeft={3} display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        <People className="user" key={1} showMax={1} userIds={[modifiedBy]}>
          <AvatarsTemplate template="default" />
        </People>
        <Box
          display="flex"
          alignItems="center"
          fontSize="0.87rem"
          data-testid="history-item-event-text"
        >
          {recordEventText}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          fontSize="0.87rem"
          paddingLeft="5px"
          sx={{
            opacity: "0.65"
          }}
        >
          {dayjs(modified).format("DD MMMM YYYY HH:mm")}
        </Box>
      </Box>
    </Box>
  );
};

export default HistoryEvents;
