import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { selectThemeName, selectUserHasRequiredRole } from "../../store/slices/userSlice";

import { useTranslation } from "react-i18next";
import { moduleRoutes } from "../../config/routes";

import { Divider, useTheme } from "@mui/material";
import { selectEditor, selectUserAccessRoles, selectViewers } from "../../store/slices/recordSlice";
import { EdisonPeopleGroup, EdisonTypography } from "enada-components";
import "./header.scss";

import useDocumentTitle from "../../utils/hooks/useDocumentTitle";
import ItemsHeader from "./ItemsHeader";
import HasAccessRoles from "../hasAccessRoles/HasAccessRoles";
import { RecordAccessRole, recordTypeConfiguration, RecordType, PersonaEntity } from "enada-common";
import NotificationFlyout from "../notification/notificationflyout/NotificationFlyout";
import { hasAccessRole } from "../../utils/hasAccessRole";
import { imageMapping } from "../../utils/mappers/imageMapping";
import IdeaFromChallengeButton from "../ideafromchallengebutton/IdeaFromChallengeButton";
import { useGetUserQuery } from "services/api";

const Header = () => {
  const location = useLocation();
  const params = useParams();
  const { data: user } = useGetUserQuery();
  const locationState = location.state;
  const { t } = useTranslation(["common"]);
  const userHasRequiredRole = useAppSelector(selectUserHasRequiredRole);
  const accessRoles = useAppSelector(selectUserAccessRoles);
  const projectEditor = useAppSelector(selectEditor);
  const projectViewers = useAppSelector(selectViewers);
  const title =
    locationState?.title === RecordType.BusinessCase ? "businessCases" : locationState?.title;
  useDocumentTitle(`edison365 - ${t(title) || t("home")}`);
  const theme = useTheme();
  const themeName = useAppSelector(selectThemeName);
  const isDarkTheme = themeName === "dark";

  // TODO: Uncomment once backend have completed notifications apis
  // useDebounce(
  //   async () => {
  //     await fetchNotifications();
  //   },
  //   10000,
  //   [notifications]
  // );

  // const fetchNotifications = async () => {
  //   setNotifications(await dispatch(getNotificationsAsync()).unwrap());
  // };

  const currentModule = useMemo(() => {
    const path = (location.pathname.split("/").at(3) as RecordType)?.toLowerCase();
    const foundModule = moduleRoutes.find(route => route.key.toLocaleLowerCase() === path);

    return recordTypeConfiguration((foundModule?.key as RecordType) ?? "");
  }, [location.pathname]);

  const iconStyles = useMemo(() => {
    const defaultStyles = { borderRadius: "10px", width: 40, height: 40, padding: 1 };
    if (!currentModule.displayName) return { ...defaultStyles, bgcolor: "p12.main" }; // styles for Home, Admin, Resources & Settings

    return {
      // Styles for Business Cases & Projects
      ...defaultStyles,
      bgcolor: isDarkTheme ? currentModule.color : currentModule.secondaryColor,
      color: isDarkTheme ? theme.palette.text.primary : currentModule.color
    };
  }, [isDarkTheme, currentModule, theme]);

  return (
    <Stack
      className="edison-header"
      direction="row"
      sx={{ padding: "10px" }}
      spacing={2}
      justifyContent="space-between"
      data-testid="edison-header"
    >
      {!userHasRequiredRole ? null : (
        <>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Box
              component="span"
              sx={{
                p: "7px 9px 3px 9px",
                marginRight: "10px"
              }}
            >
              {imageMapping(
                currentModule.displayName || locationState?.title || "home", // When more modules are added, we can just use locationPath with a Regex to determine the module
                !currentModule.displayName ? "primary" : undefined, // currentModule.displayName will be undefined if not in a module so we can use the primary colour
                undefined,
                iconStyles
              )}
            </Box>
            {params.recordId ? (
              <HasAccessRoles roles={[RecordAccessRole.Read]}>
                <ItemsHeader />
              </HasAccessRoles>
            ) : (
              <EdisonTypography title={t(title) || t("home")} variant="h1" />
            )}
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            {!!params.recordId && (
              <>
                {projectEditor?.oid !== undefined && (
                  <Box className="project-editor" sx={{ margin: "0px" }}>
                    <EdisonPeopleGroup
                      value={[{ entityId: projectEditor.oid }] as PersonaEntity[]}
                      avatarSize="auto"
                      maxAvatars={1}
                    />
                  </Box>
                )}
                {projectViewers.length > 0 && (
                  <Box className="project-viewers" sx={{ margin: "0px" }}>
                    <EdisonPeopleGroup
                      value={
                        projectViewers
                          .filter(x => x.oid !== undefined)
                          .map(viewer => ({
                            entityId: viewer?.oid
                          })) as PersonaEntity[]
                      }
                      avatarSize="auto"
                      maxAvatars={5}
                    />
                  </Box>
                )}

                <i
                  className={`material-icons ${
                    user?.id === projectEditor?.oid ? "edison-gradient" : "edison-action"
                  }`}
                >
                  edit
                </i>

                <Divider orientation="vertical" variant="middle" sx={{ height: "30px" }} />
                {(location.pathname.split("/").at(-2) as RecordType).toLowerCase() ===
                  RecordType.Challenges.toLowerCase() && <IdeaFromChallengeButton />}
              </>
            )}
          </Stack>
        </>
      )}
      {
        <NotificationFlyout
          readOnly={accessRoles ? !hasAccessRole(RecordAccessRole.Update, accessRoles) : true}
        />
      }
    </Stack>
  );
};

export default Header;
