export const hexToRgba = (hex: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbValues = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
  return `rgba(${rgbValues?.r},${rgbValues?.g},${rgbValues?.b},${opacity} )`;
};

export const rgbaToHex = (color: string) => {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  return `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
    .toString(16)
    .slice(1)}`;
};
