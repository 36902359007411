import { FC, Fragment, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  RecordSettings,
  selectCurrentVersion,
  selectEditor,
  setFlyoutId,
  setIsFlyoutOpen
} from "../../../store/slices/recordSlice";
import { EdisonTypography } from "enada-components";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SupervisedUserCircleOutlined from "@mui/icons-material/SupervisedUserCircleOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import HasAccessRoles from "../../hasAccessRoles/HasAccessRoles";
import "./recordsettingsmenuitems.scss";

import { ApiError, BaseRecord, NotificationLevel, RecordAccessRole } from "enada-common";
import { setCurrentNotification } from "store/slices/notificationSlice";
import { useGetUserQuery, useRecycleRecordMutation } from "services/api";
import { AlertDialog } from "../../../components/alertdialog/AlertDialog";

const settingsMenu = [
  {
    id: RecordSettings.editCardContent,
    image: <ImageOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: true
  },
  {
    id: RecordSettings.historyEvents,
    image: <HistoryOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: false
  },
  {
    id: RecordSettings.manageAccess,
    image: <SupervisedUserCircleOutlined />,
    requiredRole: RecordAccessRole.UpdatePermissions,
    requiresEditor: true
  },
  {
    id: RecordSettings.properties,
    image: <InfoOutlinedIcon />,
    requiredRole: RecordAccessRole.Read,
    requiresEditor: false
  },
  {
    id: RecordSettings.versionHistory,
    image: <AutoAwesomeMotionOutlinedIcon sx={{ transform: "rotateY(180deg) rotate(180deg)" }} />,
    requiredRole: RecordAccessRole.ReadVersion,
    requiresEditor: false
  },
  {
    id: RecordSettings.delete,
    image: <DeleteOutlineIcon fontSize="small" color="error" />,
    requiredRole: RecordAccessRole.Delete,
    requiresEditor: true
  }
];

export interface RecordSettingsMenuItemsProps {
  record: BaseRecord | null;
  onClose: () => void;
}

const RecordSettingsMenuItems: FC<RecordSettingsMenuItemsProps> = ({ record, onClose }) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation("common");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { data: user } = useGetUserQuery();
  const [recycleRecord, { isLoading }] = useRecycleRecordMutation();

  const projectEditor = useAppSelector(selectEditor);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const isEditor = user?.id === projectEditor?.oid;
  const navigate = useNavigate();

  const selectSetting = useCallback(
    async (id: string) => {
      if (id === RecordSettings.delete) {
        setDeleteDialogOpen(true);
      } else {
        dispatch(setIsFlyoutOpen(true));
        dispatch(setFlyoutId(id));
        onClose();
      }
    },
    [dispatch, onClose]
  );

  const handleDeleteConfirm = useCallback(async () => {
    if (!record) return;
    try {
      await recycleRecord({
        recordId: record?.id ?? 0,
        recordType: record?.recordType
      }).unwrap();

      dispatch(
        setCurrentNotification({
          title: `${record?.recordType}Recycled`,
          message: "",
          level: NotificationLevel.Success
        })
      );

      const navigateTo = record?.recordType?.toLowerCase() ?? "projects";

      navigate(navigateTo, {
        state: {
          title: t(navigateTo)
        }
      });
    } catch (e: any) {
      const error = e?.data as ApiError;
      dispatch(
        setCurrentNotification({
          title: error.title,
          message: error.detail,
          level: NotificationLevel.Error
        })
      );
    }

    setDeleteDialogOpen(false);
  }, [record, recycleRecord, navigate, t, dispatch]);

  const renderMenuItems = () => {
    return settingsMenu.map(settings => {
      const shouldRender = settings.requiresEditor ? isEditor : true;
      const isSettingsHidden =
        currentVersion &&
        currentVersion.id &&
        currentVersion.id > 0 &&
        [RecordSettings.delete, RecordSettings.historyEvents, RecordSettings.manageAccess].includes(
          settings.id
        );

      if (isSettingsHidden) return null;

      return (
        shouldRender && (
          <HasAccessRoles
            key={settings.id}
            roles={[settings.requiredRole]}
            noMatchElement={<MenuItem sx={{ display: "none" }} />}
          >
            <Fragment>
              {settings.id === RecordSettings.delete && <Divider />}
              <MenuItem
                onClick={() => selectSetting(settings.id)}
                data-testid={`record-menu-settings-${settings.id}`}
              >
                <ListItemIcon>{settings.image}</ListItemIcon>
                <ListItemText>
                  <EdisonTypography
                    variant="data"
                    title={t(
                      settings.id === RecordSettings.editCardContent &&
                        currentVersion &&
                        currentVersion.id &&
                        currentVersion.id > 0
                        ? "viewCardContent"
                        : settings.id
                    )}
                    sx={{
                      color: settings.id === RecordSettings.delete ? "error.main" : "inherit"
                    }}
                  />
                </ListItemText>
              </MenuItem>
            </Fragment>
          </HasAccessRoles>
        )
      );
    });
  };

  return (
    <>
      {renderMenuItems()}

      <AlertDialog
        isOpen={deleteDialogOpen}
        title={t("deleteRecord")}
        buttonText={t("delete")}
        severity="error"
        onClose={() => setDeleteDialogOpen(false)}
        onClick={handleDeleteConfirm}
        content={t("deleteRecordMessage")}
        isLoading={isLoading}
      />
    </>
  );
};

export default RecordSettingsMenuItems;
