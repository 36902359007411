import { Alert, Button, Dialog, Divider, Stack } from "@mui/material";
import React, { FC } from "react";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";
export interface WorkflowLivePreviewConfirmationProps {
  open: boolean;
  onClose: () => void;
  action: () => void;
  actionText: string;
  title: string;
  alertMessage: string;
}
const WorkflowLivePreviewConfirmation: FC<WorkflowLivePreviewConfirmationProps> = ({
  open,
  onClose,
  action,
  actionText,
  title,
  alertMessage
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Dialog open={open}>
      <Stack>
        <Stack padding={1}>
          <EdisonTypography title={title} variant="h3" />
        </Stack>
        <Divider flexItem />
        <Stack padding={1}>
          <Alert severity={"warning"}>{alertMessage}</Alert>
        </Stack>
        <Divider flexItem />
        <Stack padding={1} direction={"row"} justifyContent={"end"} spacing={1}>
          <Button
            variant="contained"
            onClick={() => {
              action();
              onClose();
            }}
          >
            {actionText}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default WorkflowLivePreviewConfirmation;
