import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { EdisonImageField, ConfigLayout, Loading } from "enada-components";
import { Image, NotificationLevel, RecordType } from "enada-common";
import "./templateConfig.scss";
import { RecordConfig } from "./RecordConfig";
import { ChallengeConfig } from "./ChallengeConfig";
import { TemplateDetails } from "../TemplateDetails";
import { useGetTemplatePeople } from "./useGetTemplatePeople";
import { SYSTEM_FILE_SIZE_LIMIT } from "../../../../config/authConfig";
import {
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useCreateTemplateImageMutation,
  useUpdateTemplateMutation,
  useGetCdnTokenQuery
} from "../../../../services/api";
import { batchGetEntitiesById, GraphQueryType } from "../../../../services/graphService";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectRegion } from "../../../../store/slices/configSlice";
import { setCurrentNotification } from "../../../../store/slices/notificationSlice";
import {
  selectIndividualTemplate,
  updateIndividualTemplateProperty
} from "../../../../store/slices/templatesSlice";
import { getImagePath } from "../../../../utils/imagePathManager";

const TemplateConfig: FC = () => {
  const location = useLocation();
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const template = useAppSelector(selectIndividualTemplate);
  const { data: cdnToken } = useGetCdnTokenQuery();
  const { isLoading: templateIsLoading } = useGetTemplateQuery(state.typeId, {
    skip: !state.typeId
  });

  useGetTemplatePeople();
  const [createTemplate, { isLoading: createIsLoading }] = useCreateTemplateMutation();
  const [createTemplateImage, { isLoading: createImageIsLoading }] =
    useCreateTemplateImageMutation();
  const [updateTemplate, { isLoading: updateIsLoading }] = useUpdateTemplateMutation();

  const isLoading = createIsLoading || createImageIsLoading || updateIsLoading;

  const region = useAppSelector(selectRegion);

  const [templateImage, setTemplateImage] = useState<Image>();

  const locationState = location.state;
  const isEdit = !!locationState && !!locationState.typeId;

  const getImage = () => {
    let image;
    if (templateImage?.preview) {
      image = templateImage;
    } else if (template?.imageUrl) {
      image = {
        preview: getImagePath(template.imageUrl, region, cdnToken)
      };
    }
    return image;
  };

  const onCreateForm = async () => {
    if (!template) return;

    const response = await createTemplate({
      ...template
    }).unwrap();

    if (response && templateImage) {
      await createTemplateImage({
        image: templateImage as File,
        typeId: response.id as number
      });
    }

    if (response) {
      dispatch(
        setCurrentNotification({
          title: "templateCreated",
          message: "",
          level: NotificationLevel.Success
        })
      );
    }
  };

  const onUpdateTemplate = async () => {
    if (!template) return;

    const response = await updateTemplate({
      ...template,
      imageUrl: getImagePath(template.imageUrl, region)
    }).unwrap();

    if (response.id && templateImage) {
      await createTemplateImage({
        image: templateImage as File,
        typeId: response.id
      });
    }

    if (response) {
      dispatch(
        setCurrentNotification({
          title: "templateUpdated",
          message: "",
          level: NotificationLevel.Success
        })
      );
    }
  };

  const onSave = async () => {
    try {
      if (isEdit) {
        await onUpdateTemplate();
      } else {
        await onCreateForm();
      }
    } catch (e: any) {
      if (e.title === "LastManagerDeletedFromRecord" || e.title === "LastOwnerDeletedFromRecord") {
        const errorArr = JSON.parse(e.detail);
        const errorMessage = await errorArr.reduce(async (acc: string, curr) => {
          const recordNames = curr.Records.map(record => record.RecordName).join(", ");
          const user = await batchGetEntitiesById([curr.EntityId], GraphQueryType.Users);
          let msgKey = "";
          if (e.title === "LastOwnerDeletedFromRecord") {
            msgKey = "lastOwnerDeletedFromRecordError";
          }
          const formatted = `${t(msgKey, {
            user: user[curr.EntityId].displayName,
            records: recordNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");
        dispatch(
          setCurrentNotification({
            title: e.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      } else {
        dispatch(
          setCurrentNotification({
            title: t("templateError"),
            message: t(e.detail ?? (e as string)),
            level: NotificationLevel.Error
          })
        );
      }
    } finally {
      navigate(-1);
    }
  };

  return (
    <div>
      {!isLoading && template ? (
        <ConfigLayout
          title={isEdit ? "Edit Template" : "New Template"}
          t={(param: string) => {
            return param;
          }}
          secondaryTitle="Template Configuration"
          saveDisabled={!template?.displayName || !template.workflow}
          onSaveCallback={onSave}
          onCancelCallback={() => {
            navigate(-1);
          }}
          topPanelContent={<TemplateDetails template={template} isEdit={isEdit} />}
        >
          <Stack direction="row" spacing={4} className="bottom-container">
            <div className="image-wrapper">
              <EdisonImageField
                accept="image/png, image/jpg, image/jpeg"
                label=""
                invalidMediaTypeMessage={t("invalidMediaType")}
                invalidFileSizeMessage={t("invalidFileSize")}
                maxFileSize={SYSTEM_FILE_SIZE_LIMIT}
                useInternalState={false}
                value={getImage()}
                onChange={image => {
                  setTemplateImage(image);
                  if (!image && template?.imageUrl) {
                    dispatch(
                      updateIndividualTemplateProperty({
                        key: "imageUrl",
                        value: null
                      })
                    );
                  }
                }}
              />
            </div>

            {template?.recordType === RecordType.Challenges ? (
              <ChallengeConfig template={template} templateIsLoading={templateIsLoading} />
            ) : (
              <RecordConfig template={template} templateIsLoading={templateIsLoading} />
            )}
          </Stack>
        </ConfigLayout>
      ) : (
        <Loading size={150} sx={{ marginTop: "100px" }} />
      )}
    </div>
  );
};

export default TemplateConfig;
