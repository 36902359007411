import { EdisonPeoplePickerFieldProps } from "enada-common";
import { FormHelperText, Stack } from "@mui/material";
import { FC } from "react";
import { EdisonPeoplePickerField } from "enada-components";

export interface PickerWithDescriptionProps extends EdisonPeoplePickerFieldProps {
  description: string;
}
const PickerWithDescription: FC<PickerWithDescriptionProps> = props => {
  return (
    <Stack spacing={1}>
      <EdisonPeoplePickerField {...props} />
      <FormHelperText>{props.description}</FormHelperText>
    </Stack>
  );
};

export default PickerWithDescription;
