export enum LicenseType {
  None = 0,
  ReadOnly = 10,
  Lite = 20,
  Base = 30,
  Standard = 40,
  Professional = 50,

  ////
  // future licenses
  ////
  Premium = 60,
  Enterprise = 70
}

export interface License {
  id: string;
  tenantId: string;
  licenseType?: LicenseType;
  isDisabled?: boolean;
}
