import React, { FC, ReactElement, useEffect, useState } from "react";
import { Loading } from "enada-components";

export interface DeferredRenderProps {
  children: ReactElement;
  idleTimeout: number;
}
// Use this component when initial render of a component tree is taking too long and leading to lag in the ui
// For reference:
// https://stackoverflow.com/questions/35193867/react-non-blocking-rendering-of-big-chunks-of-data
// https://itnext.io/improving-slow-mounts-in-react-apps-cff5117696dc
// https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback

const DeferredRender: FC<DeferredRenderProps> = ({ children, idleTimeout }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (render) setRender(false);
    const id = requestIdleCallback(() => setRender(true), {
      timeout: idleTimeout
    });

    return () => cancelIdleCallback(id);
  }, [idleTimeout]);

  if (!render) return <Loading size={20} />;

  return children;
};

export default DeferredRender;
