import {
  ApiError,
  NotificationLevel,
  RecordTableRow,
  WorkflowStageView,
  WorkflowStageViewFieldState
} from "enada-common";
import { Alert, AlertTitle, Button, Divider, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  FrontendRecordValue,
  selectRecordAuth,
  selectRecordFieldValues,
  selectRecordType,
  selectRecordWorkflow,
  selectWorkflowStage,
  setIsFlyoutOpen
} from "../../../../store/slices/recordSlice";
import "./normalstageapproval.scss";
import ReadOnlyWorkflowZone from "../../../workflowdesigner/readonlyworkflowzone/ReadOnlyWorkflowZone";
import { selectRecordTableBackendRowValues } from "../../../../store/slices/recordTableSlice";
import ProgressionBlockAlert from "../progressionblockalert/ProgressionBlockAlert";
import { useContinueRecordWorkflowMutation } from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

const NormalStageApproval = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const [continueRecord] = useContinueRecordWorkflowMutation();

  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const currentStage = useAppSelector(selectWorkflowStage);
  const workflow = useAppSelector(selectRecordWorkflow);
  const fieldValues = useAppSelector(selectRecordFieldValues);
  const rowValues = useAppSelector(selectRecordTableBackendRowValues);
  const recordWorkflow = useAppSelector(selectRecordWorkflow);
  const stageView =
    currentStage?.views && currentStage?.views?.length !== 0
      ? (currentStage?.views as WorkflowStageView[])[0] // TODO : review this when we can add multiple views to the a workflow
      : undefined;

  const { missingFields, missingTables } = getMissingRequiredValues(
    fieldValues,
    rowValues,
    stageView
  );
  const blockProgression = missingFields.length !== 0 || missingTables.length !== 0;

  const currentStep = recordWorkflow?.steps?.find(step => step.name === currentStage?.name);
  const isLastStage = currentStep?.nextSteps === undefined;

  const onProgress = async () => {
    try {
      await continueRecord({
        recordId: recordAuth?.details.RecordId as number,
        recordType: recordType
      });
      dispatch(setIsFlyoutOpen(false));
    } catch (e) {
      const error = e as ApiError;
      dispatch(
        setCurrentNotification({
          title: "Failed to Progress Project",
          message: error.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <Stack className="normal-stage-approval-root">
      <Stack spacing={2}>
        <Alert severity="info">
          <AlertTitle>{t("progressToNextStage")}</AlertTitle>
          {t("progressToNextStageMessage")}
        </Alert>
        {blockProgression && (
          <Stack>
            <ProgressionBlockAlert
              missingFields={missingFields}
              missingTables={missingTables}
              type={"Progress"}
            />
            <br />
          </Stack>
        )}
        <TextField
          disabled
          size="small"
          variant="outlined"
          label={t("currentStage")}
          value={currentStage?.displayName ?? currentStage?.name}
        />
        <Divider flexItem />
        <div className="stage-canvas-container">
          <ReadOnlyWorkflowZone
            workflow={workflow}
            selectedStage={currentStage?.name}
            selectableStages={currentStage ? [currentStage] : []}
            id={"normal-stage-approval"}
          />
        </div>
        <Divider flexItem />
      </Stack>
      <Stack direction="row" spacing={1} className="button-container">
        <Button disabled={blockProgression || isLastStage} variant="contained" onClick={onProgress}>
          {t("progressToNextStage")}
        </Button>
        <Button variant="outlined" onClick={() => dispatch(setIsFlyoutOpen(false))}>
          {t("cancel")}
        </Button>
      </Stack>
    </Stack>
  );
};

export const getMissingRequiredValues = (
  fieldValues: FrontendRecordValue[],
  rowValues: RecordTableRow[],
  view?: WorkflowStageView
) => {
  if (!view) return { missingFields: [], missingTables: [] };

  const requiredFields = view.viewFields?.filter(
    viewField => viewField.state === WorkflowStageViewFieldState.Required
  );
  const requiredTables = view.viewTables?.filter(
    viewTable => viewTable.state === WorkflowStageViewFieldState.Required
  );
  const missingFields =
    requiredFields?.filter(requiredField =>
      [undefined, "", null].includes(
        fieldValues.find(fieldValue => fieldValue.fieldId === requiredField.fieldId)?.value
      )
    ) ?? [];

  const missingTables =
    requiredTables?.filter(
      requiredTable => !rowValues.some(rowValue => rowValue.tableId === requiredTable.tableId)
    ) ?? [];
  return { missingFields, missingTables };
};
export default NormalStageApproval;
