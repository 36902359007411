import { Stack, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { FC, ReactNode, useState } from "react";
import "./collapsiblerow.scss";

export interface CollapsibleRowProps {
  title: ReactNode;
  columnsCount: number;
  children: ReactNode[];
}
const CollapsibleRow: FC<CollapsibleRowProps> = ({ title, columnsCount, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow className="collapsible-row-root" onClick={() => setOpen(prev => !prev)}>
        <TableCell colSpan={columnsCount} className="collapsible-row-cell">
          <Stack>
            <Stack direction="row" spacing={2} className="row-title">
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              {title}
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
      {open && children}
    </>
  );
};

export default CollapsibleRow;
