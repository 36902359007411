import { FC, useState } from "react";
import { Table, TableView, tablesViewConfiguration } from "enada-common";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteDialog, EdisonSearchBar, SingleTable } from "enada-components";
import CloseIcon from "@mui/icons-material/Close";
import "./tableviewsmodal.scss";
import { rollupTableViewColumnsWithType } from "../../config/rollupTableColumns";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export interface TableViewsModalProps {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
  table: Partial<Table>;
  onAdd(): void;
  onEdit(id: number): void;
  onDelete(ids: number[]): void;
  defaultTableViews: TableView[];
}

const TableViewsModal: FC<TableViewsModalProps> = ({
  isOpen,
  toggleModal,
  table,
  onAdd,
  onEdit,
  onDelete,
  defaultTableViews
}) => {
  const { t } = useTranslation(["common"]);
  const onClose = () => toggleModal(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth={true} data-testid="tables-views-dialog">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {t("tableViewsModalHeader")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={theme => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500]
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          <Stack direction="row" className="table-views-modal-toolbar" spacing={1}>
            <div className="searchbar-container">
              <EdisonSearchBar
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                placeholder={t("search")}
              />
            </div>
            {onDelete !== undefined && (
              <>
                {openDeleteDialog && (
                  <DeleteDialog
                    title={t("deleteDialogTitle")}
                    content={t("deleteDialogContent")}
                    onClose={function (agreed: boolean): void {
                      agreed && onDelete(selectedRowIds);
                      setOpenDeleteDialog(false);
                      setSelectedRowIds([]);
                    }}
                  />
                )}

                <Button
                  data-testid="delete-button"
                  variant="outlined"
                  size="small"
                  className="delete-button"
                  disabled={
                    selectedRowIds.length === 0 ||
                    defaultTableViews.some(view => selectedRowIds.some(id => id === view.id))
                  }
                  onClick={() => {
                    if (selectedRowIds.length > 0) {
                      setOpenDeleteDialog(true);
                    }
                  }}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </Button>
              </>
            )}
            <Button
              data-testid="create-button"
              variant="contained"
              size="small"
              className="create-button"
              //disabled={selectedRowIds.length === 0 ? true : false}
              onClick={() => {
                selectedRowIds.length === 1 ? onEdit(selectedRowIds[0]) : onAdd();
              }}
            >
              {selectedRowIds.length === 1
                ? defaultTableViews.some(view => view.id === selectedRowIds[0])
                  ? t("view")
                  : t("edit")
                : t("create")}
            </Button>
          </Stack>

          <SingleTable
            order="desc"
            orderBy="displayName"
            renderEmptyTable={true}
            columns={rollupTableViewColumnsWithType(t, (value: any) => {
              return tablesViewConfiguration(value.viewType).color;
            })}
            rows={
              (table.views?.map(view => ({
                ...view
              })) as any) ?? []
            }
            searchFilter={searchFilter}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            t={t}
            fullScreen={false}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TableViewsModal;
