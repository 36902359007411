import React, { useEffect, useMemo } from "react";
import RecordPage from "./RecordPage";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  resetRecordSlice,
  selectRecord,
  selectRecordAuth,
  setAuthentication
} from "../../../store/slices/recordSlice";
import { useLocation, useParams } from "react-router-dom";
import { RecordAccessRole, RecordType } from "enada-common";
import AccessDenied from "../../error/AccessDenied";
import { Loading } from "enada-components";
import { useGetRecordAccessTokenQuery } from "services/api";
import PageNotFound from "pages/error/PageNotFound";
import { resetRecordTableSlice } from "store/slices/recordTableSlice";

const RecordAccess = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation();
  const scope = getRecordType(location);
  const record = useAppSelector(selectRecord);
  const recordsAuth = useAppSelector(selectRecordAuth);
  const hasAccess = recordsAuth?.details?.AccessRoles?.includes(RecordAccessRole.Read);

  const { data: accessToken } = useGetRecordAccessTokenQuery({
    recordId: params.recordId as any,
    recordType: scope
  });

  useEffect(() => {
    if (accessToken) {
      dispatch(setAuthentication(accessToken));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetRecordTableSlice());
      dispatch(resetRecordSlice());
    };
  }, [dispatch]);

  const recordPage = useMemo(() => {
    return <RecordPage scope={scope} />;
  }, [params.recordId]);

  if (recordsAuth) {
    if (hasAccess) {
      return recordPage;
    } else if (record) {
      return <AccessDenied />;
    } else return <PageNotFound />;
  } else {
    return <Loading size={70} />;
  }
};

export default RecordAccess;

export const getRecordType = (location: any) => {
  let recordType = RecordType.Projects;
  if (location.pathname.indexOf("businesscase") > -1) {
    recordType = RecordType.BusinessCase;
  } else if (location.pathname.indexOf("programs") > -1) {
    recordType = RecordType.Programs;
  } else if (location.pathname.indexOf("challenges") > -1) {
    recordType = RecordType.Challenges;
  } else if (location.pathname.indexOf("ideas") > -1) {
    recordType = RecordType.Ideas;
  }
  return recordType;
};
