import { useMemo } from "react";

const generateUniqueId = (prefix: string): string => {
  return `${prefix}_${Math.random().toString(36)}`;
};

export const useUniqueGradientIds = (count: number) => {
  return useMemo(() => {
    const ids: Record<string, string> = {};
    for (let i = 1; i <= count; i++) {
      ids[`gradient${i}`] = generateUniqueId(`paint_linear_${i}`);
    }
    return ids;
  }, [count]);
};
