import { RecordType, WorkflowStage, WorkflowStageViewFieldState } from "enada-common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetCalendarsQuery,
  useGetFieldsQuery,
  useGetFormQuery,
  useGetRecordAssociationsQuery,
  useGetRecordQuery,
  useGetRecordUserRatingQuery,
  useGetRecordViewPendingTableRowValuesQuery,
  useGetRecordViewTableRowValuesQuery,
  useGetRecordViewValuesQuery,
  useGetRecordWorkflowStageQuery,
  useGetTableConfigQuery,
  useGetWorkflowQuery,
  useLazyGetRecordViewPeriodicGrandTotalsQuery,
  useLazyGetTableQuery,
  usePostRecordViewsMutation
} from "services/api";
import { useAppDispatch } from "store/hooks";
import { setCalendars } from "store/slices/calendarsSlice";
import {
  RecordAuth,
  setFields,
  setForm,
  setRecordAssociations,
  setRecordInfo,
  setRecordViewValues,
  setUserRating,
  setWorkflow,
  setWorkflowStage
} from "store/slices/recordSlice";
import {
  setPendingTableRows,
  setPeriodicGrandTotals,
  setRecordTableRowValues,
  setRecordTableSliceRecordId,
  setTableConfig,
  setTables
} from "store/slices/recordTableSlice";
import { enumerateTables } from "utils/enumerateTables";
import { getTenantAndInstanceFromURL } from "utils/urlHelpers";

export const useSetRecordData = (
  recordAuth: RecordAuth | null,
  recordType: RecordType,
  skipRequest: boolean,
  recordId: number
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isRefetching, setIsRefetching] = useState(false);

  const [postRecordViewsMutation] = usePostRecordViewsMutation();

  const {
    data: record,
    isLoading: recordIsLoading,
    isFetching: recordIsFetching,
    refetch: refetchRecord
  } = useGetRecordQuery(
    {
      recordId,
      recordType
    },
    { skip: skipRequest || !recordAuth || !recordId }
  );
  const {
    data: recordWorkflow,
    isLoading: workflowIsLoading,
    isFetching: workflowIsFetching,
    refetch: refetchWorkflow
  } = useGetWorkflowQuery(record?.workflow ?? 0, {
    skip: skipRequest || !record
  });

  const {
    data: workflowStage,
    isFetching: workflowStageIsFetching,
    refetch: refetchWorkflowStage
  } = useGetRecordWorkflowStageQuery(record?.workflowStage ?? 0, {
    skip: skipRequest || !record
  });
  const [getTable, { isLoading: tableIsLoading }] = useLazyGetTableQuery();
  const [getPeriodicGrandTotals, { isLoading: grandTotalsIsLoading }] =
    useLazyGetRecordViewPeriodicGrandTotalsQuery();

  const currentStage = useMemo(
    () =>
      recordWorkflow?.stages?.find((stage: WorkflowStage) => stage.id === record?.workflowStage),
    [record?.workflowStage, recordWorkflow?.stages]
  );
  const view = useMemo(() => currentStage?.views?.[0], [currentStage?.views]);

  const {
    data: recordForm,
    isFetching: formIsFetching,
    refetch: refetchForm
  } = useGetFormQuery(currentStage?.formId ?? 0, {
    skip: !currentStage
  });
  const {
    data: fields = [],
    isLoading: fieldsIsLoading,
    isFetching: fieldsIsFetching,
    refetch: refetchFields
  } = useGetFieldsQuery(undefined, {
    skip: skipRequest
  });

  const {
    data: userRating,
    isFetching: ratingIsFetching,
    refetch: refetchUserRating
  } = useGetRecordUserRatingQuery(
    { recordId: record?.id ?? 0, recordType },
    { skip: skipRequest || !record?.id }
  );

  const {
    data: recordViewValues = [],
    isLoading: recordViewValuesIsLoading,
    isFetching: recordViewValuesIsFetching,
    refetch: refetchViewValues
  } = useGetRecordViewValuesQuery(
    {
      recordId: record?.id ?? 0,
      recordType,
      viewId: view?.id ?? 0
    },
    { skip: skipRequest || !record?.id || !view?.id }
  );
  const {
    data: recordTableRowValues = [],
    isSuccess: tableRowValuesIsSuccess,
    isLoading: tableRowValuesIsLoading,
    isFetching: tableRowValuesIsFetching,
    refetch: refetchTableRowValues
  } = useGetRecordViewTableRowValuesQuery(
    {
      recordId: record?.id ?? 0,
      recordType,
      viewId: view?.id ?? 0
    },
    { skip: skipRequest || !record?.id || !view?.id }
  );
  const {
    data: recordPendingTableRows = [],
    isLoading: recordPendingTableRowsIsLoading,
    isFetching: recordPendingTableRowsIsFetching,
    refetch: refetchPendingTableRows
  } = useGetRecordViewPendingTableRowValuesQuery(
    {
      recordId: record?.id ?? 0,
      recordType,
      viewId: view?.id ?? 0
    },
    { skip: skipRequest || !record?.id || !view?.id }
  );
  const {
    data: tableConfig = [],
    isLoading: tableConfigIsLoading,
    isFetching: tableConfigIsFetching,
    refetch: refetchTableConfig
  } = useGetTableConfigQuery(
    { recordId: record?.id ?? 0, recordType },
    { skip: skipRequest || !record?.id }
  );
  const { data: calendars = [], refetch: refetchCalendars } = useGetCalendarsQuery(undefined, {
    skip: skipRequest
  });

  const {
    data: associations = [],
    isFetching: associationsIsFetching,
    refetch: refetchAssociations
  } = useGetRecordAssociationsQuery(
    {
      recordId: record?.id ?? 0,
      recordType
    },
    { skip: skipRequest || !record?.id }
  );

  useEffect(() => {
    if (record?.isDeleted) {
      const { instance, tenant } = getTenantAndInstanceFromURL();

      const path = `/${tenant}/${instance}/recorddeleted`;
      navigate(path);
    }
  }, [navigate, record?.isDeleted]);

  useEffect(() => {
    postRecordViewsMutation({ recordId, recordType });
  }, [postRecordViewsMutation, recordId, recordType]);

  useEffect(() => {
    if (!record || recordIsFetching) return;

    if (!location?.state?.record) {
      location.state = { record: record, title: record.recordType };
    }

    dispatch(setRecordInfo(record));
    dispatch(setRecordTableSliceRecordId(parseInt(record.id?.toString() ?? "")));
  }, [dispatch, location, record, recordIsFetching]);

  useEffect(() => {
    if (!recordWorkflow || workflowIsFetching) return;

    dispatch(setWorkflow(recordWorkflow));
  }, [dispatch, recordWorkflow, workflowIsFetching]);

  useEffect(() => {
    if (!workflowStage || workflowStageIsFetching) return;

    dispatch(setWorkflowStage(workflowStage));
  }, [dispatch, workflowStage, workflowStageIsFetching]);

  useEffect(() => {
    if (!recordForm || formIsFetching) return;

    dispatch(setForm(recordForm));
  }, [dispatch, formIsFetching, recordForm]);

  useEffect(() => {
    if (!fields.length || fieldsIsFetching) return;

    dispatch(setFields(fields));
  }, [dispatch, fields, fieldsIsFetching]);

  useEffect(() => {
    if (ratingIsFetching) return;

    dispatch(setUserRating(userRating ?? 0));
  }, [dispatch, ratingIsFetching, userRating]);

  useEffect(() => {
    if (recordViewValuesIsFetching || !recordViewValues.length) return;

    dispatch(setRecordViewValues(recordViewValues));
  }, [dispatch, recordViewValues, recordViewValuesIsFetching]);

  useEffect(() => {
    if (!recordTableRowValues.length || !tableRowValuesIsSuccess || tableRowValuesIsFetching)
      return;

    dispatch(
      setRecordTableRowValues({
        rows: recordTableRowValues,
        isRefetching: tableRowValuesIsSuccess
      })
    );
  }, [dispatch, tableRowValuesIsSuccess, recordTableRowValues, tableRowValuesIsFetching]);

  useEffect(() => {
    if (recordPendingTableRowsIsFetching) return;

    dispatch(setPendingTableRows(recordPendingTableRows));
  }, [dispatch, recordPendingTableRows, recordPendingTableRowsIsFetching]);

  useEffect(() => {
    if (tableConfigIsFetching) return;

    dispatch(setTableConfig(tableConfig));
  }, [dispatch, tableConfig, tableConfigIsFetching]);

  useEffect(() => {
    if (!calendars.length) return;

    dispatch(setCalendars(calendars));
  }, [dispatch, calendars]);

  useEffect(() => {
    if (!associations.length || associationsIsFetching) return;

    dispatch(setRecordAssociations(associations));
  }, [dispatch, associations, associationsIsFetching]);

  useEffect(() => {
    if (skipRequest || !recordForm || formIsFetching || recordIsFetching) return;

    enumerateTables(recordForm, async table => {
      const isHiddenInView = view?.viewTables?.find(
        viewTable =>
          viewTable.tableId === table.tableId &&
          viewTable.state === WorkflowStageViewFieldState.Hidden
      );

      if (isHiddenInView) return;

      const tableResponse = await getTable(table.tableId?.toString() ?? "").unwrap();

      if (tableResponse) dispatch(setTables(tableResponse));

      if (tableResponse?.dataType === "Periodic") {
        const grandTotalsResponse = await getPeriodicGrandTotals({
          recordType,
          recordId,
          tableId: table.tableId ?? 0,
          viewId: view?.id ?? 0
        }).unwrap();

        if (grandTotalsResponse) {
          dispatch(
            setPeriodicGrandTotals({
              tableId: table.tableId ?? 0,
              totals: grandTotalsResponse
            })
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skipRequest,
    dispatch,
    getPeriodicGrandTotals,
    getTable,
    recordId,
    recordType,
    recordForm,
    formIsFetching,
    recordIsFetching
  ]);

  const refetchData = useCallback(async () => {
    setIsRefetching(true);
    await refetchRecord();
    await refetchWorkflow();
    refetchWorkflowStage();
    refetchForm();
    refetchFields();
    refetchUserRating();
    refetchViewValues();
    refetchTableRowValues();
    refetchPendingTableRows();
    refetchTableConfig();
    refetchCalendars();
    refetchAssociations();
    setIsRefetching(false);
  }, [
    refetchAssociations,
    refetchCalendars,
    refetchFields,
    refetchForm,
    refetchPendingTableRows,
    refetchRecord,
    refetchTableConfig,
    refetchTableRowValues,
    refetchUserRating,
    refetchViewValues,
    refetchWorkflow,
    refetchWorkflowStage
  ]);

  const isLoading = useMemo(() => {
    if (isRefetching) return false;
    return (
      recordIsLoading ||
      workflowIsLoading ||
      tableIsLoading ||
      grandTotalsIsLoading ||
      fieldsIsLoading ||
      recordViewValuesIsLoading ||
      tableRowValuesIsLoading ||
      recordPendingTableRowsIsLoading ||
      tableConfigIsLoading
    );
  }, [
    fieldsIsLoading,
    grandTotalsIsLoading,
    recordIsLoading,
    recordPendingTableRowsIsLoading,
    recordViewValuesIsLoading,
    isRefetching,
    tableConfigIsLoading,
    tableIsLoading,
    tableRowValuesIsLoading,
    workflowIsLoading
  ]);

  return { isLoading, refetchData };
};
