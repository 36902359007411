import { Field, RecordTableRow } from "enada-common";
import { getUniqueIdFromRow, getValueByFieldType } from "../tableHelpers";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";

const parsePeriodicTableChangeToFrontend = (
  rows: RecordTableRow[],
  fields: Field[],
  isYear: boolean
) => {
  const result = rows.map(row => parseRowToBryntumRow(row, fields, isYear));
  return result;
};

const parseRowToBryntumRow = (row: RecordTableRow, fields: Field[], isYear: boolean) => {
  const data: { [key: string]: any } = {
    id: row.uniqueId,
    dbId: row.id,
    detailValues: [],
    periodicValues: [],
    grandTotal: 0
  };
  row.tableRowFieldValues?.forEach(value => {
    const linkedField = fields.find(field => field.id === value.fieldId);
    if (!linkedField) return;
    data.detailValues.push({
      name:
        linkedField.id &&
        [SystemFieldType.RowId, SystemFieldType.UniqueId, SystemFieldType.Name].includes(
          linkedField.id
        )
          ? linkedField.name
          : `${linkedField.name}-e365`,
      value: getValueByFieldType(linkedField, value)
    });
  });
  row.tableRowPeriodicValues?.forEach(value => {
    data.periodicValues.push({
      period: value.period,
      year: isYear ? value.period : value.year, // TODO: Temp fix for production. Fix data and then update code.
      value: value.decimalValue
    });
  });

  //Needed to prepare table to accept live updates
  data["pendingUpdate-e365"] = false;

  return { ...data, id: getUniqueIdFromRow(row) };
};
export default parsePeriodicTableChangeToFrontend;
