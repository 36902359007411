import {
  Expression,
  RecordType,
  Workflow,
  WorkflowReview,
  WorkflowReviewer,
  WorkflowStage,
  WorkflowTask,
  WorkflowUserReview
} from "enada-common";
import { apiConfig } from "../../config/authConfig";
import { baseAPI } from "../baseAPI";
import { BaseWorkflowStage } from "store/slices/workflowSlice";
import { getApiForRecord, getApiForReviews } from "services/APIService";
import { ApiResponse } from "types/ApiResponse";

export const workflowsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getWorkflows: builder.query<Workflow[], void>({
      query: () => `${apiConfig.contentHubWorkflowEndpoint}`,
      transformResponse: (response: { data: Workflow[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Workflows" as const, id })), "Workflows"]
          : ["Workflows"]
    }),

    getWorkflowStages: builder.query<BaseWorkflowStage[], void>({
      query: () => `${apiConfig.contentHubApi}/stages`,
      transformResponse: (response: { data: BaseWorkflowStage[] }) => response.data,
      providesTags: ["Workflows"]
    }),

    getWorkflow: builder.query<Workflow, number>({
      query: id => `${apiConfig.contentHubWorkflowEndpoint}/${id}`,
      transformResponse: (response: { data: Workflow }) => response.data,
      providesTags: (result, error, id) => [{ type: "Workflows", id }]
    }),

    createWorkflow: builder.mutation<Workflow, Partial<Workflow>>({
      query: body => ({
        url: apiConfig.contentHubWorkflowEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Workflow }) => response.data,
      invalidatesTags: ["Workflows"]
    }),

    updateWorkflow: builder.mutation<Workflow, Partial<Workflow>>({
      query: body => ({
        url: apiConfig.contentHubWorkflowEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Workflow }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Workflows", id: body.id }]
    }),

    deleteWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    }),

    restoreWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    }),

    recycleWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    }),

    simulateExpression: builder.mutation<boolean, Expression>({
      query: expression => ({
        url: `${apiConfig.commonExpressionEndpoint}/simulate`,
        method: "POST",
        body: { expression }
      })
    }),

    getRecordWorkflowStage: builder.query<WorkflowStage, number>({
      query: id => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}/stage/${id}`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: WorkflowStage }) => response.data,
      providesTags: (result, error, id) => [{ type: "WorkflowStage", id }]
    }),

    getRecordWorkflowReview: builder.query<
      WorkflowUserReview,
      { recordId: number; recordType: RecordType }
    >({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForReviews(recordType)}/${recordId}`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: WorkflowUserReview }) => response.data,
      providesTags: ["WorkflowReview"]
    }),

    getRecordWorkflowReviewers: builder.query<
      WorkflowReviewer[],
      { recordId: number; recordType: RecordType }
    >({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForReviews(recordType)}/${recordId}/reviewers`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: WorkflowReviewer[] }) => response.data,
      providesTags: ["WorkflowReviewers"]
    }),

    continueRecordWorkflow: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; comment?: string }
    >({
      query: ({ recordId, recordType, comment }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/workflow/continue`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body: { comment }
      }),
      invalidatesTags: [
        "WorkflowReviewers",
        "WorkflowReview",
        "RecordAccessToken",
        "Forms",
        "WorkflowStage",
        "Personal",
        "RecordTableRows",
        "RecordViewValues",
        "Record"
      ]
    }),

    goToStep: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; stepId: number; comment?: string }
    >({
      query: ({ recordId, recordType, stepId, comment }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/workflow/go-to-step/${stepId}`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body: { comment }
      }),
      invalidatesTags: [
        "WorkflowReviewers",
        "WorkflowReview",
        "RecordAccessToken",
        "Forms",
        "WorkflowStage",
        "Personal",
        "RecordTableRows",
        "RecordViewValues",
        "Record"
      ]
    }),

    submitReview: builder.mutation<
      ApiResponse<WorkflowReview>,
      { recordId: number; recordType: RecordType; body: WorkflowReview }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForReviews(recordType)}/${recordId}`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body
      }),
      invalidatesTags: [
        "Workflows",
        "WorkflowReviewers",
        "WorkflowReview",
        "RecordAccessToken",
        "RecordTables",
        "WorkflowStage",
        "Personal",
        "RecordTableRows",
        "RecordViewValues",
        "Record"
      ]
    }),

    updateRecordWorkflowTask: builder.mutation<
      WorkflowTask,
      { recordId: number; recordType: RecordType; body: WorkflowTask }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForReviews(recordType)}/${recordId}/task`,
        method: "POST",
        body
      }),
      invalidatesTags: [
        "WorkflowReviewers",
        "WorkflowReview",
        "Record",
        "RecordAccessToken",
        "Personal"
      ]
    })
  })
});
