import { ReactNode } from "react";
import { SxProps } from "@mui/system";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TerrainOutlineIcon from "@mui/icons-material/TerrainOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import GridOnOutlined from "@mui/icons-material/GridOnOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import CasesOutlinedIcon from "@mui/icons-material/CasesOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";

interface MaterialColorOptions {
  color:
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

export const imageMapping = (
  imageName: string,
  materialColor: MaterialColorOptions["color"],
  fontSize?: "inherit" | "small" | "large" | "medium" | undefined,
  sx?: SxProps
): ReactNode => {
  switch (imageName) {
    case "home":
      return <HomeOutlinedIcon color={materialColor} sx={sx} />;
    case "reports":
      return <AssessmentOutlinedIcon color={materialColor} sx={sx} />;
    case "personal":
      return <PersonOutlineIcon color={materialColor} sx={sx} />;
    case "resources":
      return <TerrainOutlineIcon color={materialColor} sx={sx} />;
    case "support":
      return <HelpOutlineOutlinedIcon color={materialColor} sx={sx} />;
    case "resourceFields":
      return <GridOnOutlined color={materialColor} fontSize="small" sx={sx} />;
    case "discover":
      return <ExploreOutlinedIcon color={materialColor} sx={sx} />;
    case "programs":
      return <DeviceHubIcon color={materialColor} fontSize={fontSize ?? "medium"} sx={sx} />;
    case "projects":
      return (
        <RocketLaunchOutlinedIcon color={materialColor} fontSize={fontSize ?? "medium"} sx={sx} />
      );
    case "ideas":
      return <LightbulbOutlinedIcon color={materialColor} fontSize="medium" sx={sx} />;
    case "challenges":
      return <SportsScoreOutlinedIcon color={materialColor} fontSize="medium" sx={sx} />;
    case "businessCase":
      return <WorkOutlineIcon color={materialColor} fontSize="medium" sx={sx} />;
    case "admin":
      return <SettingsOutlinedIcon color={materialColor} sx={sx} />;
    case "fields":
      return <GridOnOutlined color={materialColor} fontSize="small" sx={sx} />;
    case "tables":
      return <BackupTableOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "forms":
      return <ListAltOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "cards":
      return <ContactMailOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "workflows":
    case "workflowdesigner":
      return <PolylineOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "templates":
      return <CasesOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "news":
      return <NewspaperOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "recycleBin":
      return <DeleteSweepOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "calendars":
      return <DateRangeOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "languages":
      return <LanguageOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "manageAccess":
      return <GroupsOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "licenses":
      return <BadgeOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    case "doneall":
      return <DoneAllIcon color={materialColor} fontSize="small" sx={sx} />;
    case "personaldata":
      return <PeopleAltOutlinedIcon color={materialColor} fontSize="small" sx={sx} />;
    default:
      return null;
  }
};
