import { RecordRole } from "enada-common";
import { useAppSelector } from "../../store/hooks";
import { selectUserRecordRoles } from "../../store/slices/recordSlice";

const useHasRecordRole = () => {
  const userRoles = useAppSelector(selectUserRecordRoles);
  const userRolesValues = userRoles?.map((role: RecordRole) => role);

  const hasRecordRole = (roles: RecordRole[]) => roles.some(r => userRolesValues?.includes(r));

  return { hasRecordRole };
};

export default useHasRecordRole;
