import { RecordAccessRole, RecordRole, RecordType, RoleType, useLocalisation } from "enada-common";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EdisonTypography, UserTextField } from "enada-components";
import Share from "../share/Share";
import { useTranslation } from "react-i18next";
import {
  RecordSettings,
  selectCurrentVersion,
  selectEditor,
  selectHasChanges,
  selectIsRecordSaving,
  selectRecord,
  selectRecordAssociations,
  selectRecordType,
  selectUserAccessRoles,
  selectUserRating,
  selectWorkflowStage,
  setFlyoutId,
  setIsFlyoutOpen,
  updateSystemFields
} from "../../store/slices/recordSlice";
import { hasAccessRole } from "../../utils/hasAccessRole";
import HasAccessRoles from "../hasAccessRoles/HasAccessRoles";
import RecordSettingsMenu from "../recordsettingsmenu/RecordSettingsMenu";
import WorkflowStageChip from "../workflowStageChip/WorkflowStageChip";
import ParentChip from "../ParentChip/ParentChip";
import useHasRecordRole from "../../utils/hooks/useHasRecordRole";
import useHasRole from "../../utils/hooks/useHasRole";
import { Link } from "react-router-dom";
import { Button, Chip } from "@mui/material";

import {
  useDeleteFavouriteRecordMutation,
  useGetHasUserFavouritedQuery,
  useGetUserQuery,
  useUpdateFavouriteRecordMutation,
  useUpdateRecordUserRatingMutation
} from "services/api";

const ItemsHeader: FC = () => {
  const dispatch = useAppDispatch();

  const record = useAppSelector(selectRecord);
  const recordType = useAppSelector(selectRecordType);
  const accessRoles = useAppSelector(selectUserAccessRoles);
  const ratingInfo = useAppSelector(selectUserRating);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const isRecordSaving = useAppSelector(selectIsRecordSaving);
  const projectEditor = useAppSelector(selectEditor);
  const hasChanges = useAppSelector(selectHasChanges);
  const associations = useAppSelector(selectRecordAssociations);
  const { t } = useTranslation(["common"]);
  const { hasRecordRole } = useHasRecordRole();
  const { hasRole } = useHasRole();
  const { formatDate } = useLocalisation();

  const currentVersion = useAppSelector(selectCurrentVersion);

  const hasRolesToRenderAssociatedRecords = useMemo(
    () =>
      hasRecordRole([RecordRole.Manager, RecordRole.Owner]) ||
      hasRole([RoleType.InstanceAdmin]) ||
      record?.recordType === RecordType.Programs,
    [hasRecordRole, hasRole, record?.recordType]
  );

  const { data: user } = useGetUserQuery();
  const { data: likeInfo } = useGetHasUserFavouritedQuery(
    { recordId: record?.id ?? 0, recordType },
    { skip: !record?.id }
  );
  const [rateRecord] = useUpdateRecordUserRatingMutation();
  const [favouriteRecord] = useUpdateFavouriteRecordMutation();
  const [unfavouriteRecord] = useDeleteFavouriteRecordMutation();

  const [editDisplayName, setEditDisplayName] = useState(false);
  const [hasChangesState, setHasChangesState] = useState(false);

  const isEditor = user?.id === projectEditor?.oid;

  useEffect(() => {
    if (hasChangesState !== hasChanges) {
      hasChanges
        ? (window.onbeforeunload = function () {
            return t("unsavedChanges");
          })
        : (window.onbeforeunload = null);

      setHasChangesState(hasChanges);
    }
    // Clear the refresh event listener when the component is unmounted
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasChanges, hasChangesState, t]);

  const parentItem = useMemo(() => {
    if (associations.length > 0) {
      //Parent of an idea is challenge, every other record type has a parent type of program
      return associations.find(el =>
        record?.recordType === RecordType.Ideas
          ? el.recordType === RecordType.Challenges
          : el.recordType === RecordType.Programs
      );
    }
  }, [associations, record?.recordType]);

  const handleDisplayNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setEditDisplayName(false);

    dispatch(
      updateSystemFields({
        key: "displayName",
        changeValue: event.target.value
      })
    );
  };

  const canUpdate = useMemo(
    () => accessRoles && hasAccessRole(RecordAccessRole.Update, accessRoles) && isEditor,
    [accessRoles, isEditor]
  );

  const onRateRecord = async (newValue: number | null) => {
    await rateRecord({
      recordId: record?.id as number,
      recordType: record?.recordType as RecordType,
      rating: newValue as number
    });
  };

  const onFavourite = async (newValue: number | null) => {
    if (newValue) {
      await favouriteRecord({
        recordId: record?.id as number,
        recordType: record?.recordType as RecordType
      });
    } else {
      await unfavouriteRecord({
        recordId: record?.id as number,
        recordType: record?.recordType as RecordType
      });
    }
  };

  return (
    <Stack direction="column">
      {canUpdate && editDisplayName && (!currentVersion || currentVersion.id === 0) ? (
        <div onBlur={handleDisplayNameBlur}>
          <UserTextField
            value={record?.displayName}
            readOnly={!editDisplayName}
            isIconOn={false}
            label={""}
            useInternalState={false}
            minimumLength={1}
            maximumLength={255}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px"
              }
            }}
          />
        </div>
      ) : (
        <div
          className={canUpdate ? "underline-on-hover" : ""}
          onClick={canUpdate ? () => setEditDisplayName(true) : undefined}
        >
          <EdisonTypography title={record?.displayName || "Record Information"} variant="h1" />
        </div>
      )}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: "center",
          lineHeight: "10px"
        }}
      >
        <Rating
          name="size-medium"
          size="small"
          value={ratingInfo ?? 0}
          onChange={(_e, newValue) => onRateRecord(newValue)}
        />
        <Rating
          sx={theme => ({
            color: theme.palette.Like?.main,
            "&:hover": {
              color: theme.palette.LikeHover?.main
            }
          })}
          name="customized-color"
          max={1}
          value={likeInfo ? 1 : 0}
          onChange={(_e, newValue) => onFavourite(newValue)}
          icon={<FavoriteIcon fontSize="small" />}
          emptyIcon={<FavoriteBorderIcon fontSize="small" />}
        />
        <Share />
        <RecordSettingsMenu />
        <WorkflowStageChip
          displayName={workflowStage?.displayName}
          color={workflowStage?.configuration?.color as string}
        />
        {parentItem && (
          <Link
            to={`./${parentItem.recordType?.toLowerCase()}/${parentItem.recordId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ParentChip
              displayName={parentItem.displayName}
              type={parentItem.recordType ?? RecordType.Programs}
            />
          </Link>
        )}
        <HasAccessRoles roles={[RecordAccessRole.ReadVersion]}>
          <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={
              currentVersion && currentVersion.id !== 0
                ? `${currentVersion?.versionName} (${formatDate(
                    currentVersion?.versionCreated,
                    "LL LT"
                  )})`
                : t("recordVersions.liveVersionChip")
            }
            onClick={() => {
              dispatch(setIsFlyoutOpen(true));
              dispatch(setFlyoutId(RecordSettings.versionHistory));
            }}
            data-testid={"record-versions-chip"}
          />
        </HasAccessRoles>
        {(!currentVersion || currentVersion.id === 0) && (
          <HasAccessRoles roles={[RecordAccessRole.Update]}>
            {isRecordSaving && hasChanges ? (
              <CloudSyncIcon color="action" />
            ) : isRecordSaving || hasChanges ? (
              <CloudUploadOutlinedIcon color="action" />
            ) : (
              <CloudDoneOutlinedIcon color="action" />
            )}
          </HasAccessRoles>
        )}
        <HasAccessRoles roles={[RecordAccessRole.CreateAssociation]}>
          {record?.recordType === RecordType.Programs &&
          (!currentVersion || currentVersion.id === 0) ? (
            <Button
              variant="text"
              onClick={() => {
                dispatch(setIsFlyoutOpen(true));
                dispatch(setFlyoutId(RecordSettings.programAssociations));
              }}
            >
              {t("programsSection.recordPage.configure")}
            </Button>
          ) : null}
        </HasAccessRoles>
      </Stack>
    </Stack>
  );
};

export default ItemsHeader;
