import React, { FC, ReactNode } from "react";

import "./edisoniconbutton.scss";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTheme } from "@mui/material";
export interface EdisonIconButtonProps {
  variant: "contained" | "outlined" | "text";
  icon: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  id?: string;
  testId?: string;
  isSelected?: boolean;
  color?: ButtonProps["color"];
}
const EdisonIconButton: FC<EdisonIconButtonProps> = ({
  variant,
  icon,
  onClick,
  testId,
  disabled,
  id,
  isSelected,
  color
}) => {
  const theme = useTheme();
  return (
    <Button
      id={id}
      data-testid={testId}
      className="edison-icon-button-root"
      color={color as any}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      sx={{
        ...(isSelected && {
          backgroundColor: theme.palette.action.hover,
          color:
            variant === "contained"
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main,
          borderColor: theme.palette.primary.main
        })
      }}
    >
      {icon}
    </Button>
  );
};

export default EdisonIconButton;

export { EdisonIconButton };
