import {
  convertBitwiseEnumValueToArray,
  PersonaEntity,
  WorkflowStage,
  WorkflowStageEditAccess,
  WorkflowStageViewState
} from "enada-common";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Chip, Stack } from "@mui/material";
import { FC } from "react";
import { EdisonPeopleGroup, EdisonTypography } from "enada-components";
import "./stagenodeinfo.scss";
import EditIcon from "@mui/icons-material/Edit";
import { getRequestMsGraph } from "../../../../services/APIService";
import { useGetFormsQuery } from "services/api";

export interface StageNodeInfoProps {
  stage: WorkflowStage;
  hasReview: boolean;
}
const StageNodeInfo: FC<StageNodeInfoProps> = ({ stage, hasReview }) => {
  const { data: forms = [] } = useGetFormsQuery();

  return (
    <Stack spacing={1} className="stage-node-info-root">
      <Stack direction="row" spacing={1} className="form-name-container">
        <FeedOutlinedIcon />
        <EdisonTypography
          title={forms.find(form => form.id === stage.formId)?.displayName ?? ""}
          variant="h4"
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <VisibilityOutlinedIcon />
        <EdisonTypography title={stage.viewState ?? WorkflowStageViewState.Visible} variant="h4" />
      </Stack>
      {(stage.editAccess as unknown as number) > 0 && (
        <Stack direction="row" spacing={1}>
          <SupervisedUserCircleIcon />
          {stage.editAccess &&
            convertBitwiseEnumValueToArray(
              WorkflowStageEditAccess,
              stage.editAccess as unknown as number
            ).map((accessType, index) => (
              <Chip
                key={index}
                color="primary"
                size="small"
                label={accessType}
                variant="outlined"
              />
            ))}
        </Stack>
      )}
      {hasReview && (
        <Stack spacing={1}>
          {stage.reviewers && stage.reviewers.length > 0 && (
            <Stack direction="row" spacing={1} className="picker-container">
              <GroupOutlinedIcon />
              <EdisonPeopleGroup
                expandGroups
                avatarSize="small"
                maxAvatars={5}
                value={
                  stage.reviewers
                    ? stage.reviewers.map(
                        (reviewer: any) =>
                          ({
                            entityId: reviewer.entityId,
                            type: reviewer.permissionType
                          } as PersonaEntity)
                      )
                    : ([] as PersonaEntity[])
                }
                getGroupMembersAsync={(groupId: string) =>
                  getRequestMsGraph(`groups/${groupId}/members`)
                }
              />
            </Stack>
          )}
          {stage.editors && stage.editors.length > 0 && (
            <Stack direction="row" spacing={1} className="picker-container">
              <EditIcon />
              <EdisonPeopleGroup
                expandGroups
                avatarSize="small"
                maxAvatars={5}
                value={
                  stage.editors
                    ? stage.editors.map(
                        (editor: any) =>
                          ({
                            entityId: editor.entityId,
                            type: editor.permissionType
                          } as PersonaEntity)
                      )
                    : ([] as PersonaEntity[])
                }
                getGroupMembersAsync={(groupId: string) =>
                  getRequestMsGraph(`groups/${groupId}/members`)
                }
              />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default StageNodeInfo;
