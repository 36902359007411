import { RecordTemplate, RecordType, BasePermission } from "enada-common";
import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EdisonCarousel, EdisonSearchBar, EdisonTypography, TypeCard } from "enada-components";
import "./createrecordtemplate.scss";
import { useAppSelector } from "../../../../store/hooks";
import { useSelectTemplatesByRecordType } from "../../../../utils/hooks/useSelectTemplatesByRecordType";
import { getImagePath } from "../../../../utils/imagePathManager";
import { selectRegion } from "../../../../store/slices/configSlice";
import { useGetCdnTokenQuery } from "services/api";

export interface CreateRecordTemplateProps {
  templateId?: number;
  setTemplate: (value?: number, templatePermissions?: BasePermission[] | null) => void;
  recordType?: RecordType;
}

const CreateRecordTemplate: FC<CreateRecordTemplateProps> = ({
  templateId,
  setTemplate,
  recordType
}) => {
  const { t } = useTranslation(["common"]);
  const [search, setSearch] = useState("");
  const templates = useSelectTemplatesByRecordType(recordType);

  const { data: cdnToken } = useGetCdnTokenQuery();

  const region = useAppSelector(selectRegion);

  const getBorder = (id?: number) => {
    if (templateId === id) {
      return {
        borderImage: "transparent, linear-gradient(#f6b73c, #4d9f0c) ",
        borderWidth: "4px",
        borderStyle: "solid",
        borderRadus: "16px",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box"
      };
    }
    return { borderWidth: "4px", borderColor: "transparent" };
  };
  const getTemplateCard = (template: RecordTemplate) => {
    return (
      <TypeCard
        onClick={() => setTemplate(template.id, template.templatePermissions)}
        sx={{
          cursor: "pointer",
          ...getBorder(template.id)
        }}
        title={template.displayName ? template.displayName : ""}
        description={template.description ? template.description : ""}
        cardImageSource={getImagePath(template.imageUrl, region, cdnToken)}
        t={t}
        className="extended-description"
      />
    );
  };

  return (
    <Stack className="create-record-template-root">
      <EdisonCarousel
        data={templates.filter(
          template =>
            template.displayName?.toLowerCase().includes(search.toLowerCase()) ||
            template.name.toLowerCase().includes(search.toLowerCase())
        )}
        isMoreActive={false}
        getCard={getTemplateCard}
        topBarModule={
          <Stack direction={"row"} className="title-container">
            <EdisonTypography title={t("templates")} variant="h5" sx={{ fontWeight: "bold" }} />
            <Stack direction="row" spacing={1}>
              <EdisonSearchBar
                placeholder={t("search")}
                searchFilter={search}
                setSearchFilter={setSearch}
              />
            </Stack>
          </Stack>
        }
        noDataModule={<div style={{ height: "15em" }}> {t("noData")}</div>}
        showScroll={true}
      />
    </Stack>
  );
};

export default CreateRecordTemplate;
