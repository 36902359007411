import { PersonaEntity } from "./../frontend/shared/PersonaEntity.model";
export enum ResourceType {
  AAD = "AAD",
  Generic = "Generic",
  Named = "Named"
}

export interface ResourceSkill {
  id: string;
  skill: string;
  resourceId?: string;
}

export interface Resource {
  isDeleted: boolean;
  resourceManagers: PersonaEntity[];
  calendarId: number;
  type: ResourceType;
  isActive: boolean;

  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  userId?: string | null;
  name?: string;
  displayName?: string;
  email?: string;
  availability?: number;
  availableFrom?: string;
  availableTo?: string;
  costRate?: number;
  costRateCurrency?: string;
  department?: string;
  role?: string;
  resourceSkills?: ResourceSkill[];
  calendar?: number;
}
