import {
  BaseRecord,
  OperationType,
  RecordFieldValue,
  RecordPermission,
  RecordType
} from "enada-common";
import {
  getApiForRecord,
  getApiForRecordAssociations,
  getApiForRecordPermissions
} from "services/APIService";
import { baseAPI } from "services/baseAPI";
import { HistoryEventsType, RecordAuth } from "store/slices/recordSlice";
import { RecordAssociation, RecordAssociationMerge } from "../../types/createRecordModal";
import { EdiResponse } from "components/edirowgeneration/EdiRowGeneration";
import { apiConfig } from "config/authConfig";

export const recordAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getRecordAccessToken: builder.query<RecordAuth, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecordPermissions(recordType)}/${recordId}/access-token`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: RecordAuth }) => response.data,
      providesTags: ["RecordAccessToken"]
    }),

    getRecord: builder.query<BaseRecord, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: BaseRecord }) => response.data,
      providesTags: ["Record"]
    }),

    updateRecord: builder.mutation<BaseRecord, { recordType: RecordType; record: BaseRecord }>({
      query: ({ recordType, record }) => ({
        url: getApiForRecord(recordType),
        method: "PUT",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body: record
      })
    }),

    recycleRecord: builder.mutation<string, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/recycle`,
        method: "DELETE",
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      invalidatesTags: ["Record", "RecycledRecords"]
    }),

    getHasUserFavourited: builder.query<boolean, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/has-user-favourited`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: boolean }) => response.data,
      providesTags: ["RecordFavourited"]
    }),

    updateFavouriteRecord: builder.mutation<void, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/favourite`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      invalidatesTags: ["RecordFavourited"]
    }),

    deleteFavouriteRecord: builder.mutation<void, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/favourite`,
        method: "DELETE",
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      invalidatesTags: ["RecordFavourited"]
    }),

    getRecordUserRating: builder.query<number, { recordId: number; recordType: RecordType }>({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/user-rating`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: number }) => response.data,
      providesTags: ["RecordUserRating"]
    }),

    updateRecordUserRating: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; rating: number }
    >({
      query: ({ recordId, recordType, rating }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/rating/${rating}`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      invalidatesTags: ["RecordUserRating"]
    }),

    getRecordViewValues: builder.query<
      RecordFieldValue[],
      { recordId: number; recordType: RecordType; viewId: number }
    >({
      query: ({ recordId, recordType, viewId }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/values/${viewId}`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: RecordFieldValue[] }) => response.data,
      providesTags: ["RecordViewValues"]
    }),

    saveRecordViewValues: builder.mutation<
      void,
      {
        recordId: number;
        recordType: RecordType;
        viewId: number;
        body: {
          operationType: OperationType;
          fieldValues: any[];
        }[];
      }
    >({
      query: ({ recordId, recordType, viewId, body }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/values/${viewId}`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body
      })
    }),

    updateRecordImage: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; image: File }
    >({
      query: ({ recordId, recordType, image }) => {
        const formData = new FormData();
        formData.append("file", image as File);

        return {
          url: `${getApiForRecord(recordType)}/${recordId}/image`,
          method: "POST",
          formData: true,
          body: formData
        };
      },
      invalidatesTags: ["Record"]
    }),

    getHistoryEvents: builder.query<
      HistoryEventsType[],
      { recordId: number; recordType: RecordType }
    >({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/history`,
        headers: {
          "edison365-sessionid": "frontend"
        }
      }),
      transformResponse: (response: { data: HistoryEventsType[] }) => response.data,
      providesTags: ["HistoryEvents"]
    }),

    saveRecordPermissions: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; body: RecordPermission[] }
    >({
      query: ({ recordId, recordType, body }) => ({
        url: `${getApiForRecordPermissions(recordType)}/${recordId}`,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body
      }),
      invalidatesTags: ["RecordPermissions"]
    }),

    getRecordAssociations: builder.query<
      RecordAssociation[],
      { recordId: number; recordType: RecordType }
    >({
      query: ({ recordId, recordType }) => ({
        url: `${getApiForRecordAssociations(recordType)}/${recordId}`
      }),
      transformResponse: (response: { data: RecordAssociation[] }) => response.data,
      providesTags: ["RecordAssociations"]
    }),

    mergeProgramAssociations: builder.mutation<
      RecordAssociation[],
      { recordId: number; body: RecordAssociationMerge[] }
    >({
      query: ({ recordId, body }) => ({
        url: `${getApiForRecordAssociations(RecordType.Programs)}/${recordId}/merge`,
        method: "POST",
        body
      }),
      invalidatesTags: ["RecordAssociations"]
    }),

    generateTasks: builder.mutation<EdiResponse, string>({
      query: body => ({
        url: apiConfig.commonGenerateTasksEndpoint,
        method: "POST",
        headers: {
          "edison365-sessionid": "frontend"
        },
        body: { description: body }
      }),
      invalidatesTags: ["Record"]
    })
  })
});
