import { IconButton, Menu } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useCallback, useState } from "react";
import RecordActionMenuItems from "./recordactionmenuitems/RecordActionMenuItems";
import RecordSettingsMenuItems from "./recordsettingmenuitems/RecordSettingsMenuItems";
import CreateRecordModal from "../createrecordmodal/CreateRecordModal";
import { useAppSelector } from "../../store/hooks";
import { selectRecord, selectRecordAuth } from "../../store/slices/recordSlice";
import { useDisclosure } from "../../utils/hooks/useDisclosure";

const RecordSettingsMenu = () => {
  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const { isOpen, onToggle } = useDisclosure();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onCloseAnchorEl = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreHorizIcon fontSize="small" color="action" />
      </IconButton>
      <Menu
        slotProps={{ paper: { sx: { borderRadius: "4px" } } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseAnchorEl}
      >
        <RecordActionMenuItems
          record={record}
          recordAuth={recordAuth}
          onClose={onCloseAnchorEl}
          onOpenCreateProjectModal={onToggle}
        />
        <RecordSettingsMenuItems
          record={record}
          recordAuth={recordAuth}
          onClose={onCloseAnchorEl}
        />
      </Menu>
      {isOpen && <CreateRecordModal sourceRecord={record} open={isOpen} onClose={onToggle} />}
    </>
  );
};

export default RecordSettingsMenu;
