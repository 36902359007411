import { ProjectModel, ProjectModelConfig } from "@bryntum/gantt-thin";
import { ProjectModel as TaskBoardProjectModel } from "@bryntum/taskboard-thin";
import { BryntumGantt } from "@bryntum/gantt-react-thin";
import { Choice, Field, StandardTableProps, TableDataType } from "enada-common";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React, { FC, useEffect, useRef, useState } from "react";
import EdisonTypography from "../../../edison/typography/EdisonTypography";
import "../../../theme/bryntumcustomtheme.scss";
import UserTableBoard from "../board/UserTableBoard";
import ListTableContext from "./context/ListTableContext";
import ListTableToolbar from "./toolbar/ListTableToolbar";
import "./userlisttable.scss";
import listTableConfig from "./config/ListTableConfig";
import ListTableColumns from "./config/ListTableColumns";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useToggleAutoHeight } from "../tableutils/useToggleAutoHeight";
import { ColumnStore } from "@bryntum/grid-thin";

export interface NewListTableProps extends StandardTableProps {
  showBoardView?: boolean;
  boardColumns?: Choice[];
  columnField: string;
  toolbarModule: React.ReactNode;
  cardItems?: Field[];
  hiddenColumns: Field[];
  onEdiClick?: () => void;
  required?: boolean;
  rowsToUpdate: any[];
  t: (value: string) => string;
  refreshRequired?: boolean;
  hideEdi?: boolean;
}

const UserListTable: FC<NewListTableProps> = ({
  data,
  columns,
  onDataChanged,
  onEdiClick,
  hideExportBtn = false,
  hideDeleteBtn = false,
  hideAddBtn = false,
  label,
  readOnly,
  hideEdi,
  showBoardView,
  boardColumns,
  columnField,
  toolbarModule,
  cardItems,
  hiddenColumns,
  required,
  description,
  rowsToUpdate,
  refreshRequired,
  t
}) => {
  const ganttRef = useRef<any>();
  const dynamicHeightLabel = `tableAutoHeight-${label.replace(" ", "-")}`;
  const columnsRef = useRef(ListTableColumns(columns, dynamicHeightLabel));
  const project = useRef<Partial<ProjectModelConfig>>(
    new ProjectModel({
      onDataReady: () => setLoaded(true)
    }) as Partial<ProjectModelConfig>
  );

  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const { toggleAutoHeight, autoHeight, averageRowHeight, maxHeight } = useToggleAutoHeight(
    columns,
    ganttRef.current?.instance,
    label,
    (data.length ?? 0) - deletedRows.length,
    fullScreen
  );

  const columnOrder = columns.reduce((acc: string, curr) => {
    return `${acc}-${curr.id}`;
  }, "");

  useEffect(() => {
    (project.current as ProjectModel).taskStore.data = data;
  }, []);

  useEffect(() => {
    if (refreshRequired) {
      const store = (project.current as ProjectModel).taskStore;
      if (store.records.length) {
        store.removeAll();
      }
      loadData();
    }
  }, [refreshRequired]);

  const loadData = () => {
    (project.current as ProjectModel).taskStore.data = data;
  };

  useEffect(() => {
    if (!ganttRef?.current) return;
    ganttRef.current.instance.selectionMode.checkbox = false;
    (ganttRef.current.instance.columns as ColumnStore).removeAll(true);
    (ganttRef.current.instance.columns as ColumnStore).add(
      ListTableColumns(columns, dynamicHeightLabel),
      true
    );
    ganttRef.current.instance.renderContents();
    ganttRef.current.instance.selectionMode.checkbox = true;
  }, [columnOrder]);

  useEffect(() => {
    // Use to control the columns visibility from the views
    if (!ganttRef?.current) return;
    const columnStore = ganttRef.current.instance.columns as ColumnStore;
    columns.forEach(column => {
      const bryntumColumn = columnStore.get(column.name);
      bryntumColumn.hidden = hiddenColumns?.some(hiddenColumn => hiddenColumn.id === column.id);
    });
  }, [hiddenColumns]);

  useEffect(() => {
    //Live update values in row
    rowsToUpdate.forEach(row => {
      const { rowId, ...rest } = row;
      const task = (project.current as ProjectModel).taskStore.getById(rowId);
      task?.set({ ...rest });
    });
  }, [rowsToUpdate]);

  useEffect(() => {
    // Once the gantt is loaded, synchronize the tables autoheight with the one in local storage
    if (!loaded) return;
    if (!autoHeight) return;
    toggleAutoHeight(autoHeight);
  }, [autoHeight, loaded, toggleAutoHeight]);

  const getNewRowEdisonFieldsWithDefaults = () =>
    columns
      .filter(column => column.name.endsWith("-e365"))
      .reduce(
        (next, current) => ({
          ...next,
          [current.name]: null
        }),
        {}
      );

  const onTaskAdd = async () => {
    if (!ganttRef?.current) return;
    const gantt = ganttRef?.current?.instance;

    const edisonFields = getNewRowEdisonFieldsWithDefaults();
    // const bryntumFields = getNewRowBryntumFieldsWithDefaults();
    const added = gantt.taskStore.add({ ...edisonFields });

    // run propagation to calculate new task fields
    await gantt.project.commitAsync();

    // scroll to the added task
    await gantt.scrollRowIntoView(added);

    gantt.features.cellEdit.startEditing({
      record: added,
      field: "name"
    });
  };

  return (
    <div className={`standard-table-root ${fullScreen && "fullscreen"} `}>
      <div className={`${fullScreen && "sticky"}`}>
        <ListTableToolbar
          taskAddCallback={onTaskAdd}
          grid={ganttRef}
          columns={columns}
          hideExportBtn={hideExportBtn}
          hideDeleteBtn={hideDeleteBtn}
          hideAddBtn={hideAddBtn || Boolean(showBoardView)}
          toolbarModule={toolbarModule}
          onEdiClick={onEdiClick}
          readOnly={readOnly}
          hideEdi={hideEdi}
          hasSelectedData={hasSelectedData}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          t={t}
          label={label}
          setDeletedRows={setDeletedRows}
          titleModule={
            <Stack direction="row" spacing={1}>
              <EdisonTypography
                title={label}
                sx={{
                  color: required && data.length === 0 ? "red" : undefined
                }}
                variant="data"
              />

              {description && (
                <Tooltip title={description} arrow placement="right">
                  <InfoOutlinedIcon fontSize="small" className="table-info-icon" />
                </Tooltip>
              )}
              {readOnly && <LockOutlinedIcon fontSize="small" className="table-readonly-icon" />}
            </Stack>
          }
        />
      </div>
      <Divider />
      <div
        className={`enada-bryntum-grid ${
          showBoardView ? "standard-table-visible" : "standard-table-hidden"
        }`}
      >
        {boardColumns?.length && (
          <ListTableContext.Provider value={Boolean(readOnly)}>
            <UserTableBoard
              readOnly={readOnly}
              project={project.current as TaskBoardProjectModel}
              boardColumns={boardColumns}
              tableColumns={columns}
              columnField={columnField}
              cardItems={cardItems}
              source={TableDataType.List}
            />
          </ListTableContext.Provider>
        )}
      </div>
      <div
        className={`standard-table-container enada-bryntum-grid ${
          showBoardView ? "standard-table-hidden" : "standard-table-visible"
        }`}
      >
        <ListTableContext.Provider value={Boolean(readOnly)}>
          <BryntumGantt
            {...listTableConfig}
            columns={columnsRef.current}
            project={project.current}
            ref={ganttRef}
            onFocusOut={() =>
              ganttRef.current?.instance.deselectCells(ganttRef.current?.instance.selectedCells)
            }
            onDataChange={onDataChanged}
            readOnly={readOnly}
            maxHeight={maxHeight}
            height={maxHeight}
            rowHeight={averageRowHeight}
            onSelectionChange={e => setHasSelectedData(e.selection.length > 0)}
          />
        </ListTableContext.Provider>
      </div>
    </div>
  );
};

export default UserListTable;
export { UserListTable };
