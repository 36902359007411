import { Divider, Drawer, Stack, SxProps, Theme } from "@mui/material";
import { FC, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectIsRecordFlyoutOpen, setIsFlyoutOpen } from "../../../store/slices/recordSlice";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { EdisonTypography } from "enada-components";
export interface ProjectRecordFlyoutProps {
  title: string | ReactNode;
  children: ReactNode;
  topBarModule?: ReactNode;
  titleIcon?: ReactNode;
  onClose?: any;
  sx?: SxProps<Theme>;
}

const ProjectRecordFlyout: FC<ProjectRecordFlyoutProps> = ({
  title,
  children,
  topBarModule,
  titleIcon,
  onClose,
  sx
}) => {
  const dispatch = useAppDispatch();
  const isFlyoutOpen = useAppSelector(selectIsRecordFlyoutOpen);
  return (
    <Drawer
      data-testid={"record-flyout-root"}
      anchor={"right"}
      open={isFlyoutOpen}
      PaperProps={{
        sx: {
          minWidth: "33em",
          maxWidth: "540px",
          width: "100%",
          ...sx
        }
      }}
      onClose={() => {
        dispatch(setIsFlyoutOpen(false));
        if (onClose) {
          onClose();
        }
      }}
    >
      <Stack direction={"column"} height={"100%"} padding={"1em"}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          paddingBottom="16px"
        >
          <Stack
            direction="row"
            alignItems="center"
            width="90%"
            data-testid={"record-flyout-title"}
          >
            {titleIcon}

            {typeof title === "string" ? (
              <EdisonTypography
                variant="h2"
                title={title}
                sx={titleIcon ? { textIndent: "10px" } : undefined}
              />
            ) : (
              title
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {topBarModule}
            <CloseOutlinedIcon
              data-testid={"record-flyout-close"}
              sx={{ cursor: "pointer" }}
              onClick={() => dispatch(setIsFlyoutOpen(false))}
            />
          </Stack>
        </Stack>
        <Divider sx={{ marginBottom: 2 }} />
        {children}
      </Stack>
    </Drawer>
  );
};

export default ProjectRecordFlyout;
