import { ApiError, Calendar, NotificationLevel } from "enada-common";
import { Autocomplete, Box, Chip, Paper, Stack, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ConfigLayout, Loading } from "enada-components";
import { sanitiseInternalFieldName } from "../../../utils/sanitiseInternalFieldName";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import {
  clearIndividualCalendar,
  selectIndividualCalendar,
  updateIndividualCalendar,
  updateIndividualCalendarProperty
} from "../../../store/slices/calendarsSlice";
import "./calendarconfig.scss";
import { choicesList } from "../../../utils/constants";
import { addTimeToHour } from "../../../utils/timeManipulation";
import {
  useCreateCalendarMutation,
  useGetCalendarQuery,
  useUpdateCalendarMutation
} from "services/api";

const CalendarConfig = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const [hoursPerWeek, setHoursPerWeek] = useState(0);
  const [workingDays, setWorkingDays] = useState([]);
  const [hoursPerDay, setHoursPerDay] = useState(0);
  const [nameError, setNameError] = useState(false);
  const startHour = 9; //TODO: hardcoded to 9AM for now, will change in the future
  const startMinute = 0; //TODO: hardcoded to 0 for now, will change in the future

  const locationState = location.state as any;
  const isEdit = !!locationState && !!locationState.calendarId;

  const { data: currentCalendar } = useGetCalendarQuery(locationState.calendarId, {
    skip: !isEdit
  });

  const [createCalendar, { isLoading: createIsLoading }] = useCreateCalendarMutation();
  const [updateCalendar, { isLoading: updateIsLoading }] = useUpdateCalendarMutation();

  const formCalendar = useAppSelector(selectIndividualCalendar);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoading = createIsLoading || updateIsLoading;

  //TODO: Add a configuration update when we can actually send it to the API
  const _updateConfiguration = (key: string, value: number | string | boolean) => {
    dispatch(
      updateIndividualCalendarProperty({
        key: "configuration",
        value: {
          //   ...individualField?.configuration,
          [key]: value
        }
      })
    );
  };

  const theme = useTheme();

  useEffect(() => {
    if (isEdit && currentCalendar) {
      dispatch(updateIndividualCalendar(currentCalendar));

      setWorkingDays(!currentCalendar.configuration.days ? [] : currentCalendar.configuration.days);
      setHoursPerWeek(currentCalendar.configuration.hoursPerWeek);
      setHoursPerDay(currentCalendar.configuration.hoursPerDay);
    } else {
      dispatch(clearIndividualCalendar());
    }
  }, [isEdit, currentCalendar, dispatch]);

  useEffect(() => {
    setHoursPerDay(hoursPerWeek / workingDays.length);
  }, [workingDays, hoursPerWeek]);

  const tomorrow = (today: string) => {
    const daysOfTheWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday"
    ];
    // Returns tomorrow given today
    // Uses modulo operator (%) to handle the case where today is sunday (returns monday)
    return daysOfTheWeek[(daysOfTheWeek.indexOf(today) + 1) % 7];
  };

  const onCreateCalendar = async (calendar: Partial<Calendar>) => {
    try {
      const response = await createCalendar(calendar).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newCalendarCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e: any) {
      const error = e?.data as ApiError;

      dispatch(
        setCurrentNotification({
          title: "calendarCreationError",
          message: Array.isArray(error.errors) ? error.errors.join("\n") : error.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateCalendar = async (calendar: Partial<Calendar>) => {
    try {
      const response = await updateCalendar(calendar).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "calendarUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e: any) {
      const error = e?.data as ApiError;

      dispatch(
        setCurrentNotification({
          title: "calendarEditError",
          message: Array.isArray(error.errors) ? error.errors.join("\n") : error.detail,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onSave = () => {
    const calendarToSave: Partial<Calendar> = {
      ...formCalendar,
      configuration: {
        hoursPerWeek: hoursPerWeek,
        hoursPerDay: hoursPerDay,
        days: workingDays
      },
      intervals:
        workingDays.length === 0
          ? []
          : workingDays.map((workingDay: any) => ({
              recurrentStartDate: `on ${workingDay.label} at 9:00`,
              recurrentEndDate: `on ${
                hoursPerDay > 24 - startHour ? tomorrow(workingDay.label) : workingDay.label
              } at ${addTimeToHour(startHour, startMinute, hoursPerDay)}`,
              isWorking: true
            }))
    };
    isEdit ? onUpdateCalendar(calendarToSave) : onCreateCalendar(calendarToSave);
  };

  return (
    <div>
      {!isLoading ? (
        <ConfigLayout
          t={(param: string) => {
            return param;
          }}
          saveDisabled={
            !formCalendar?.displayName || hoursPerWeek < 0 || hoursPerWeek > workingDays.length * 24
          }
          title={!isEdit ? t("newCalendar") : t("editCalendar")}
          onSaveCallback={onSave}
          onCancelCallback={() => {
            dispatch(clearIndividualCalendar());
            navigate(-1);
          }}
          topPanelContent={
            <Stack direction="row" spacing={4} className="calendar-top-panel-container">
              <TextField
                fullWidth
                className="input"
                variant="standard"
                label={t("calendarName")}
                value={formCalendar?.displayName ?? ""}
                helperText={nameError ? t("internalNameError", { field: "Field" }) : ""}
                error={nameError}
                onChange={e => {
                  dispatch(
                    updateIndividualCalendarProperty({
                      key: "displayName",
                      value: e.target.value
                    })
                  );

                  if (!isEdit) {
                    const sanitised = sanitiseInternalFieldName(e.target.value);
                    if (sanitised !== "") {
                      dispatch(
                        updateIndividualCalendarProperty({
                          key: "name",
                          value: sanitised
                        })
                      );
                      setNameError(false);
                    } else {
                      setNameError(true);
                    }
                  }
                }}
              />
              <TextField
                fullWidth
                className="input"
                label={t("internalName")}
                variant="standard"
                value={formCalendar?.name ?? ""}
                disabled
              />
              {/* Removed until this feature is available on the backend */}
              {/* <Stack direction="row" alignItems="center">
                <EdisonTypography title={t("useAsDefault")} variant="h4" />
                <Tooltip title={t("ifEnabled")} arrow placement="top">
                  <InfoOutlinedIcon sx={{ paddingLeft: "5px" }} />
                </Tooltip>
                <Switch
                  value={calendar.isDefault}
                  checked={calendar.isDefault}
                  onChange={(e) =>
                    updateIndividualCalendarProperty({
                      key: "isDefault",
                      value: e.target.checked,
                    })
                  }
                />
              </Stack> */}
            </Stack>
          }
        >
          <Paper sx={{ p: 2 }}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Autocomplete
                fullWidth
                multiple
                id="tags-standard"
                options={choicesList}
                limitTags={5}
                getOptionLabel={(option: any) => t(option.label)}
                value={workingDays}
                freeSolo
                onChange={(e, newValue: any) => setWorkingDays(newValue)}
                renderInput={params => (
                  <TextField {...params} variant="standard" label={t("workingDays")} />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Box key={index} marginRight="3px">
                      <Chip
                        {...getTagProps({ index })}
                        label={t(option.label)}
                        key={index}
                        id={option.id}
                        sx={{
                          bgcolor: option.colour,
                          ":hover": {
                            bgcolor: option.colour
                          },
                          color: option.colour ? theme.palette.getContrastText(option.colour) : ""
                        }}
                      />
                    </Box>
                  ))
                }
              />

              <TextField
                fullWidth
                className="input"
                size="small"
                label={t("hoursPerWeek")}
                type="number"
                variant="standard"
                value={hoursPerWeek}
                helperText={
                  hoursPerWeek < 0 || hoursPerWeek > workingDays.length * 24
                    ? "Value must be between 0 and " +
                      workingDays.length * 24 +
                      " (number of working days multiplied by 24)"
                    : ""
                }
                error={hoursPerWeek < 0 || hoursPerWeek > workingDays.length * 24}
                onChange={e => setHoursPerWeek(e.target.value as unknown as number)}
              />
            </Stack>
          </Paper>
        </ConfigLayout>
      ) : (
        <Loading size={150} sx={{ marginTop: "100px" }} />
      )}
    </div>
  );
};

export default CalendarConfig;
