import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { resourceRoutes } from "../../config/routes";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { EdisonTypography } from "enada-components";
import HasRoles from "../../components/hasRoles/HasRoles";
import { imageMapping } from "../../utils/mappers/imageMapping";

const ResourceManagement = () => {
  const { t } = useTranslation(["common"]);

  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, xl: 12 }}>
        {resourceRoutes.map(route =>
          route.requiredRoles ? (
            <HasRoles key={route.key} roles={route.requiredRoles}>
              <Grid item xs={12} sm={4} md={4} xl={3} key={route.key}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={route.path}
                  state={{ title: route.key }}
                >
                  <Paper sx={{ p: 2, borderRadius: 2, height: "110px" }}>
                    <Stack
                      sx={{ height: "100%" }}
                      direction="column"
                      justifyContent={"space-between"}
                    >
                      <Stack direction="row" sx={{ alignItems: "center" }}>
                        <Avatar
                          sizes="2"
                          sx={{
                            color: "white",
                            backgroundColor: route.color,
                            height: 32,
                            width: 32,
                            marginRight: 2
                          }}
                        >
                          {imageMapping(route.key || "home", "inherit")}
                        </Avatar>
                        <EdisonTypography title={t(route.key)} variant="h1" />
                      </Stack>
                      <Box data-testid="{route.description}">
                        <EdisonTypography title={t(route.description || "")} variant="h5" />
                      </Box>
                    </Stack>
                  </Paper>
                </NavLink>
              </Grid>
            </HasRoles>
          ) : (
            <Grid item xs={12} sm={4} md={4} xl={3} key={route.key}>
              <NavLink
                style={{ textDecoration: "none" }}
                to={route.path}
                state={{ title: route.key }}
              >
                <Paper sx={{ p: 2, borderRadius: 2, height: "110px" }}>
                  <Stack
                    sx={{ height: "100%" }}
                    direction="column"
                    justifyContent={"space-between"}
                  >
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <Avatar
                        sizes="2"
                        sx={{
                          color: "white",
                          backgroundColor: route.color,
                          height: 32,
                          width: 32,
                          marginRight: 2
                        }}
                      >
                        {imageMapping(route.key || "home", "inherit")}
                      </Avatar>
                      <EdisonTypography title={t(route.key)} variant="h1" />
                    </Stack>
                    <Box data-testid={route.description}>
                      <EdisonTypography title={t(route.description || "")} variant="h5" />
                    </Box>
                  </Stack>
                </Paper>
              </NavLink>
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default ResourceManagement;
