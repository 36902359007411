import AddIcon from "@mui/icons-material/Add";
import { BaseRecord, RecordType } from "enada-common";
import { EdisonIconButton } from "enada-components";
import { FC } from "react";
import { useDisclosure } from "../../../utils/hooks/useDisclosure";
import CreateRecordModal from "../../createrecordmodal/CreateRecordModal";

export interface RecordContentCardFooterModuleProps {
  record: BaseRecord;
}

const ChallengeContentCardFooterModule: FC<RecordContentCardFooterModuleProps> = ({ record }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <EdisonIconButton
        disabled={!record.canCreateAssociations}
        icon={<AddIcon />}
        variant="contained"
        color="success"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onOpen();
        }}
      ></EdisonIconButton>
      {isOpen && (
        <CreateRecordModal
          sourceRecord={record}
          recordTypeToCreate={RecordType.Ideas}
          open={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ChallengeContentCardFooterModule;
