import { apiConfig } from "config/authConfig";
import { License, Role } from "enada-common";
import { baseAPI } from "services/baseAPI";
import { EdisonModules } from "store/slices/userSlice";

export const userAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getMyRoles: builder.query<Role[], void>({
      query: () => `${apiConfig.rolesEndpoint}/my-roles`,
      transformResponse: (response: { data: Role[] }) => response.data,
      providesTags: ["User"]
    }),

    getCdnToken: builder.query<string, void>({
      query: () => `${apiConfig.commonApi}cdntoken`,
      transformResponse: (response: { data: string }) => response.data,
      providesTags: ["User"]
    }),

    getModules: builder.query<EdisonModules, void>({
      query: () => `${apiConfig.managementApi}/tenants/instances/modules`,
      transformResponse: (response: { data: EdisonModules }) => response.data,
      providesTags: ["User"]
    }),

    getMyLicense: builder.query<License, void>({
      query: () => `${apiConfig.managementLicensesEndpoint}/my-license`,
      transformResponse: (response: { data: License }) => response.data,
      providesTags: ["User"]
    }),

    getRegion: builder.query<string, void>({
      query: () => `${apiConfig.managementApi}/tenants/instances/region`,
      transformResponse: (response: { data: string }) => response.data,
      providesTags: ["User"]
    }),

    postUserDetails: builder.mutation<void, void>({
      query: () => ({
        url: `${apiConfig.contentHubUserDetailsEndpoint}/my-profile`,
        method: "POST"
      }),
      invalidatesTags: ["User"]
    })
  })
});
