import { createTheme, Palette, PaletteOptions, Theme } from "@mui/material/styles";

import darkPallette from "./pallettes/darkpallette.json";
import lightPallette from "./pallettes/lightpallette.json";
import fieldColors from "./pallettes/fieldcolors.json";

const getPalletteCustomColors = (pallette: {
  [key in keyof PaletteOptions]: string;
}): PaletteOptions => {
  return Object.entries(pallette).reduce((previous, [key, value]) => {
    return { ...previous, [key]: { main: value } };
  }, {});
};

export const BaseTheme = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(",")
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow:
            "0px 3px 3px -2px rgba(122, 176, 254, 0.1), 0px 3px 4px rgba(122, 176, 254, 0.07), 0px 1px 8px rgba(122, 176, 254, 0.06);"
          // boxShadow: "3px 3px 4px rgba(76, 144, 242, 0.1)",
        }
      }
    }
  }
  //Taken from here https://mui.com/material-ui/customization/theme-components/
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: ({ ownerState }) => ({
  //         ...(ownerState.variant === "contained" && {
  //             backgroundColor: lightPallette.ButtonBackground
  //           }),
  //       }),
  //     },
  //   },
  // },
});

export const lightTheme: Theme = createTheme({
  ...BaseTheme,
  palette: {
    mode: "light",
    primary: {
      main: lightPallette.Main,
      dark: lightPallette.Dark,
      light: lightPallette.Light
    },
    action: {
      active: lightPallette.ActionActive,
      hover: lightPallette.ActionHover,
      selected: lightPallette.ActionSelected,
      disabled: lightPallette.ActionDisabled,
      disabledBackground: lightPallette.ActionDisabledBackground,
      focus: lightPallette.ActionFocus
    },
    background: { default: lightPallette.BackdropOverlay },
    text: {
      primary: lightPallette.Primary,
      secondary: lightPallette.Secondary,
      disabled: lightPallette.Disabled
    },
    ...getPalletteCustomColors(lightPallette),
    ...getPalletteCustomColors(fieldColors)
  }
});

export const darkTheme: Theme = createTheme({
  ...BaseTheme,
  palette: {
    mode: "dark",
    primary: {
      main: darkPallette.Main,
      dark: darkPallette.Dark,
      light: darkPallette.Light
    },
    action: {
      active: darkPallette.ActionActive,
      hover: darkPallette.ActionHover,
      selected: darkPallette.ActionSelected,
      disabled: darkPallette.ActionDisabled,
      disabledBackground: darkPallette.ActionDisabledBackground,
      focus: darkPallette.ActionFocus
    },
    background: { default: darkPallette.BackdropOverlay, paper: "#32363E" },
    text: {
      primary: darkPallette.Primary,
      secondary: darkPallette.Secondary,
      disabled: darkPallette.Disabled
    },
    ...getPalletteCustomColors(darkPallette),
    ...getPalletteCustomColors(fieldColors)
  }
});

// // end theme definition

export function getThemeByName(theme: string): Theme {
  const selectedTheme = themeMap[theme];

  document.documentElement.style.setProperty("--primary-color", selectedTheme.palette.primary.main);
  document.documentElement.style.setProperty(
    "--secondary-color",
    selectedTheme.palette.secondary.main
  );
  return selectedTheme;
}

export const getPalletteByThemeName = (themeName: string) => {
  if (themeName === "dark") {
    return darkPallette;
  }
  return lightPallette;
};
const themeMap: { [key: string]: Theme } = {
  light: lightTheme,
  dark: darkTheme
};
