export const formatDecimals = (originalDecimal: string, maxDecimalPlaces: number) => {
  if (originalDecimal === null || originalDecimal === undefined) return;

  const decimalSplit = originalDecimal.toString().split(".");
  if (decimalSplit.length === 1 || decimalSplit[1].length < maxDecimalPlaces) {
    return originalDecimal;
  }
  const newDecimalString = decimalSplit[0] + "." + decimalSplit[1].substring(0, maxDecimalPlaces);

  return Number.parseFloat(newDecimalString).toFixed(maxDecimalPlaces);
};
