import { Person } from "@microsoft/mgt-react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EdisonTypography, Loading } from "enada-components";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  RecordVersion,
  selectCurrentVersion,
  selectRecord,
  selectRecordAuth,
  setCurrentVersion,
  setIsFlyoutOpen
} from "../../store/slices/recordSlice";
import { getRecordVersions } from "../../services/APIService";
import dayjs from "dayjs";
import HasAccessRoles from "../../components/hasAccessRoles/HasAccessRoles";
import { RecordAccessRole } from "enada-common";

const dateFormat = "DD/MM/YY HH:mm";

interface Props {
  toggleNewVersion: () => void;
}

const VersionsTable: React.FC<Props> = ({ toggleNewVersion }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const [versions, setVersions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false); // TODO: spinner

  const auth = useAppSelector(selectRecordAuth);
  const currentRecord = useAppSelector(selectRecord);
  const currentVersion = useAppSelector(selectCurrentVersion);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentRecord || !currentRecord.id) return;
      setLoading(true);
      const response = await getRecordVersions(currentRecord.id, currentRecord.recordType);
      setVersions(response?.data?.reverse() || []);
      setLoading(false);
    };

    fetchData();
  }, [currentRecord, auth, dispatch]);

  const showVersion = (version: RecordVersion | null) => {
    dispatch(setCurrentVersion(version));
    dispatch(setIsFlyoutOpen(false));
  };

  return (
    <>
      <HasAccessRoles roles={[RecordAccessRole.CreateVersion]}>
        <Tooltip
          disableHoverListener={!currentVersion || currentVersion.id === 0}
          disableTouchListener={!currentVersion || currentVersion.id === 0}
          disableFocusListener={!currentVersion || currentVersion.id === 0}
          title={t("recordVersions.createNewVersionDisabledTooltip")}
        >
          <span>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={toggleNewVersion}
              disabled={!(!currentVersion || currentVersion.id === 0)}
              data-testid={"record-create-version-button"}
            >
              {t("recordVersions.createNewVersion")}
            </Button>
          </span>
        </Tooltip>
      </HasAccessRoles>
      <Box display="flex" flexDirection="column" paddingTop={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" paddingBottom={2}>
          <EdisonTypography title={t("recordVersions.currentlyViewing")} variant="subtitle2" />

          <Button
            disabled={!currentVersion || currentVersion.id === 0}
            variant="text"
            onClick={() => {
              showVersion({ id: 0 } as RecordVersion);
            }}
          >
            {t("recordVersions.viewLatest")}
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            {currentVersion ? (
              <>
                <EdisonTypography title={currentVersion.versionName} variant="subtitle2" />
                <EdisonTypography
                  title={currentVersion.versionDescription || ""}
                  variant="subtitle2"
                  sx={{ opacity: "0.65" }}
                />
              </>
            ) : (
              <>
                <EdisonTypography title={t("recordVersions.liveVersion")} variant="subtitle2" />
                {versions && versions?.length > 0 && !loading && (
                  <EdisonTypography
                    title={t("recordVersions.currentVersion")}
                    variant="subtitle2"
                    sx={{ opacity: "0.65" }}
                  />
                )}
              </>
            )}
          </Box>
          {currentVersion ? (
            <EdisonTypography
              title={dayjs(currentVersion.versionCreated).format(dateFormat)}
              variant="subtitle2"
              sx={{ opacity: "0.65" }}
            />
          ) : (
            <EdisonTypography
              title={t("recordVersions.latest")}
              variant="subtitle2"
              sx={{ opacity: "0.65" }}
            />
          )}
        </Box>
      </Box>
      <Divider sx={{ margin: "24px 0" }} />
      {loading ? (
        <Box className="circular-progress-container">
          <Loading size={70} />
        </Box>
      ) : (
        <Table data-testid={"version-table"}>
          <TableHead>
            <TableRow
              sx={theme => ({
                backgroundColor: theme.palette.background.default
              })}
            >
              <TableCell>{t("recordVersions.versionTable.name")}</TableCell>
              <TableCell>{t("recordVersions.versionTable.description")}</TableCell>
              <TableCell>{t("recordVersions.versionTable.dateCreated")}</TableCell>
              <TableCell>{t("recordVersions.versionTable.createdBy")}</TableCell>
              <TableCell>{t("recordVersions.versionTable.view")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions && versions?.length > 0 ? (
              <>
                <TableRow>
                  <TableCell>{t("recordVersions.liveVersion")}</TableCell>
                  <TableCell>{t("recordVersions.currentVersion")}</TableCell>
                  <TableCell>{t("recordVersions.latest")}</TableCell>
                  <TableCell>
                    <Person showPresence={true} userId={currentRecord?.createdBy ?? ""} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={!currentVersion || currentVersion.id === 0}
                      onClick={() => {
                        showVersion({ id: 0 } as RecordVersion);
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {versions.map(version => (
                  <TableRow key={version.id}>
                    <TableCell data-testid={"version-table-name"}>{version.versionName}</TableCell>
                    <TableCell>{version.versionDescription}</TableCell>
                    <TableCell>{dayjs(version.versionCreated).format(dateFormat)}</TableCell>
                    <TableCell>
                      <Person showPresence={true} userId={version.versionCreatedBy ?? ""} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        disabled={version.id === currentVersion?.id}
                        onClick={() => {
                          showVersion(version);
                        }}
                      >
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5}>{t("recordVersions.versionTable.noVersions")}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default VersionsTable;
