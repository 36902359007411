import { Tooltip, TooltipProps } from "@mui/material";
import { forwardRef } from "react";

type EdisonTooltipProps = TooltipProps & {
  isDisabled?: boolean;
};

export const EdisonTooltip = forwardRef<HTMLDivElement, EdisonTooltipProps>(
  ({ isDisabled, ...props }, ref) => {
    return (
      <Tooltip
        disableFocusListener={isDisabled}
        disableHoverListener={isDisabled}
        disableInteractive={isDisabled}
        ref={ref}
        {...props}
      />
    );
  }
);
