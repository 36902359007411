import { BryntumGanttProps } from "@bryntum/gantt-react-thin";
import SharedConfig from "../../config/SharedConfig";

const listTableConfig: Partial<BryntumGanttProps> = {
  ...SharedConfig,

  taskCopyPasteFeature: true, // Not shared because it doesn't exist in BryntumGridProps

  taskMenuFeature: false,
  cellMenuFeature: {
    menu: {
      cls: "enada-bryntum-popup-menu"
    }
  },
  cellEditFeature: {
    triggerEvent: "cellclick",
    addNewAtEnd: false
  },
  subGridConfigs: {
    normal: { collapsed: true }
  },
  filterFeature: true,
  regionResizeFeature: false,
  fixedRowHeight: false
};
export default listTableConfig;
