import { Box, LinearProgress, Typography } from "@mui/material";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { FC } from "react";
export interface EdisonLinearProgressProps {
  value: number;
  renderLabel?: boolean;
}
// Taken from https://mui.com/material-ui/react-progress/
const EdisonLinearProgress: FC<EdisonLinearProgressProps & LinearProgressProps> = ({
  value,
  renderLabel,
  ...rest
}) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", width: "100%" }}
      data-testid={"edison-linear-progress-container"}
    >
      <Box sx={{ width: "100%", mr: renderLabel ? "4px" : 0 }}>
        <LinearProgress variant="determinate" value={value} {...rest} />
      </Box>
      {Boolean(renderLabel) && (
        <Box sx={{ minWidth: 35, textAlign: "right" }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default EdisonLinearProgress;
export { EdisonLinearProgress };
