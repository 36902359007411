import { BryntumTaskBoardProps } from "@bryntum/taskboard-react-thin";
import moment from "moment";
import { UserTableBoardCardItem } from "./UserTableBoard";

export const userTableBoardConfig = (
  addTaskAction: (columnRecord: any) => void,
  cardItems?: UserTableBoardCardItem[]
): BryntumTaskBoardProps => {
  const config = {
    headerItems: generateBryntumCardRegion("header", cardItems),
    bodyItems: generateBryntumCardRegion("body", cardItems),
    footerItems: generateBryntumCardRegion("footer", cardItems),
    columnToolbarsFeature: {
      bottomItems: {
        newTask: {
          text: "Add Row",
          icon: "b-fa-fw b-fa-plus",
          onClick({ source }: any) {
            addTaskAction(source.columnRecord.data);
          },
          cls: "taskBoardToolbar"
        },
        addTask: false
      }
    },
    taskDragFeature: true,
    taskEditFeature: {
      items: {
        description: null,
        color: null
      }
    }
  };

  return config;
};

const generateBryntumCardRegion = (
  region: "header" | "footer" | "body",
  cardItems?: UserTableBoardCardItem[]
) => {
  if (!cardItems) return;

  //https://bryntum.com/products/taskboard/docs/guide/TaskBoard/customization/taskcontents
  const cardRegion = cardItems
    .filter(item => item.location === region)
    .reduce((prev, curr) => {
      const cardItem = {
        ...prev,
        [curr.field]:
          curr.type === "date"
            ? {
                type: "template",
                field: curr.field,
                template: ({ value }: any) =>
                  `<span class="b-custom-date">${moment(value).format("DD/MM/YYYY")}</span>`
              }
            : curr.field === "duration"
            ? {
                type: "template",
                field: curr.field,
                template: ({ value }: any) => `<span class="b-custom-duration">${value} days</span>`
              }
            : {
                field: curr.field,
                type: curr.type,
                cls: `b-custom-${curr.type}`, // Added class to each widget to customize it in the userboard.scss
                textProperty: "label" // Needed to link a multchoice to a tag
              }
      };
      return cardItem;
    }, {});

  return cardRegion;
};
