import { RecordType, WorkflowReview, WorkflowReviewState } from "enada-common";
import { Alert, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import { nanoid } from "nanoid";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { updateApprovalReviewState } from "../../../../store/slices/personalSlice";
import { useAppDispatch } from "../../../../store/hooks";

import "./submitapprovaldialog.scss";
import { useUpdateReviewApprovalMutation } from "services/api";
export interface SubmitApprovalDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
  projectId?: number;
  isApproved?: boolean;
  recordType: RecordType;
}
const SubmitApprovalDialog: FC<SubmitApprovalDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  projectId,
  isApproved,
  recordType
}) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [updateReviewApproval] = useUpdateReviewApprovalMutation();

  const submitReview = async (reviewState: WorkflowReviewState) => {
    if (!projectId) return;
    const additionalHeaders = new Headers();
    const sessionId = nanoid();
    additionalHeaders.append("edison365-sessionid", sessionId);
    const review: WorkflowReview = {
      reviewState,
      comments: "",
      recordId: projectId
    };

    const response = await updateReviewApproval({
      body: review,
      recordId: projectId,
      recordType
    }).unwrap();

    if (response.data) {
      dispatch(
        updateApprovalReviewState({
          recordId: projectId,
          newState: response.data.reviewState,
          comments: response.data.comments ?? ""
        })
      );
      setDialogOpen(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogTitle>{isApproved ? t("approveItem") : t("rejectItem")}</DialogTitle>
      <Stack spacing={2} className="submit-approval-dialog-root">
        <Alert severity="warning">{isApproved ? t("approveAction") : t("rejectAction")}</Alert>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {isApproved ? (
            <Button
              variant="contained"
              color="success"
              onClick={() => submitReview(WorkflowReviewState.Approved)}
            >
              {t("approve")}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={() => submitReview(WorkflowReviewState.Rejected)}
            >
              {t("reject")}
            </Button>
          )}
          <Button variant="outlined" onClick={() => setDialogOpen(false)}>
            {t("cancel")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SubmitApprovalDialog;
