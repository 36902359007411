import { ApiError, NotificationLevel, RecordAccessRole, WorkflowStage } from "enada-common";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Divider
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectRecordAuth,
  selectRecordType,
  selectRecordWorkflow,
  selectWorkflowStage,
  setIsFlyoutOpen
} from "../../../../store/slices/recordSlice";
import "./stagechange.scss";
import useHasRecordAccessRole from "../../../../utils/hooks/useHasRecordAccessRole";
import ReadOnlyWorkflowZone from "../../../workflowdesigner/readonlyworkflowzone/ReadOnlyWorkflowZone";
import { ReviewUserType } from "../../../../types/stageReview";
import { useContinueRecordWorkflowMutation, useGoToStepMutation } from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

type StageChangeProps = {
  reviewCurrentView: ReviewUserType;
};

const StageChange: FC<StageChangeProps> = ({ reviewCurrentView }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const [continueRecord] = useContinueRecordWorkflowMutation();
  const [goToStep] = useGoToStepMutation();

  const { hasRecordAccessRole } = useHasRecordAccessRole();
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const workflow = useAppSelector(selectRecordWorkflow);
  const currentStage = useAppSelector(selectWorkflowStage);
  const [stageToGoTo, setStageToGoTo] = useState<string>(
    (workflow?.stages?.filter(stage => stage.id !== currentStage?.id) as WorkflowStage[])[0].name
  );
  const [comment, _setComment] = useState<string>("");

  const onUpdateStage = async () => {
    if (!recordAuth) return;

    if (reviewCurrentView === ReviewUserType.Owner) {
      try {
        await continueRecord({
          recordId: recordAuth?.details.RecordId as number,
          recordType: recordType
        });
      } catch (e) {
        const error = e as ApiError;
        dispatch(
          setCurrentNotification({
            title: "Failed to Progress Project",
            message: error.detail,
            level: NotificationLevel.Error
          })
        );
      }
    } else {
      await goToStep({
        recordId: recordAuth?.details.RecordId as number,
        recordType: recordType,
        stepId: workflow?.steps?.find(step => step.name === stageToGoTo)?.id as number,
        comment: comment
      });
    }

    dispatch(setIsFlyoutOpen(false));
  };

  return (
    <Stack className="manual-review-root">
      <Stack spacing={2}>
        <Alert severity="warning">
          <AlertTitle> {t("projectchangeStateTitle")}</AlertTitle>
          {t("changeStateMessage")}
        </Alert>
        <Box data-testid="current-stage">
          <TextField
            disabled
            size="small"
            variant="outlined"
            label={t("currentStage")}
            value={currentStage?.displayName ?? currentStage?.name}
          />
        </Box>
        <Stack className="chevron-container">
          <KeyboardDoubleArrowDownIcon />
        </Stack>

        <FormControl disabled={!hasRecordAccessRole([RecordAccessRole.SkipStep])}>
          <InputLabel id="change-state-select-label">{t("changeStageTo")}</InputLabel>
          <Select
            label={t("changeStageTo")}
            labelId="change-state-select-label"
            data-testid="change-state-select-next-stage"
            size="small"
            value={stageToGoTo}
            onChange={e => setStageToGoTo(e.target.value)}
          >
            {workflow?.stages
              ?.filter(stage => stage.id !== currentStage?.id)
              ?.map(({ displayName, name }, index) => (
                <MenuItem key={index} value={name} data-testid="select-stage-item">
                  {displayName ?? name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Divider flexItem />
        <div className="stage-canvas-container">
          <ReadOnlyWorkflowZone
            workflow={workflow}
            selectedStage={stageToGoTo}
            onStageClick={(newStage: string) => {
              setStageToGoTo(newStage);
            }}
            selectableStages={(workflow?.stages ?? []).filter(
              stage => stage.name !== currentStage?.name
            )}
            id={"manual-review"}
          />
        </div>

        <Divider flexItem />
        {/* Removed while we wait for the BE to accept comments */}
        {/* <TextField
          label={t("comments")}
          multiline
          value={comment}
          onChange={e => setComment(e.target.value)}
        /> */}
      </Stack>
      <Stack direction="row" spacing={1} className="button-container">
        <Button variant="contained" onClick={onUpdateStage} data-testid="update-stage-button">
          {t("update")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(setIsFlyoutOpen(false));
          }}
        >
          {t("cancel")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default StageChange;
