import { SkeletonProps } from "enada-common";
import { Skeleton } from "@mui/material";
import { FC } from "react";
import "./skeletontext.scss";

const EdisonSkeletonText: FC<SkeletonProps> = ({ rows }) => {
  const skeletonRows = Array.from({ length: rows }, (_, i) => i);

  return (
    <div className="skeleton-text" data-testid={"edison-skeletons-text"}>
      {skeletonRows.map(row => (
        <Skeleton key={row} animation="wave" />
      ))}
    </div>
  );
};

export default EdisonSkeletonText;
export { EdisonSkeletonText };
