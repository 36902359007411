import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updateRecordRoles,
  selectWorkflowStage,
  setIsFlyoutOpen,
  selectRecordAuth,
  selectRecordPermissions,
  RecordSettings,
  selectRecordType,
  selectRecord,
  selectRecordAssociations
} from "../../store/slices/recordSlice";
import { BoldTranslation } from "enada-components";
import {
  PermissionType,
  PersonaEntity,
  RecordAccessRole,
  RecordPermission,
  RecordRole,
  RecordType
} from "enada-common";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { RecordFlag } from "../recordFlag/RecordFlag";
import {
  useGetRecordQuery,
  useGetTemplateQuery,
  useSaveRecordPermissionsMutation
} from "../../services/api";
import { useGetDynamicPersons } from "../../utils/hooks/useGetDynamicPersons";
import useHasRecordAccessRole from "utils/hooks/useHasRecordAccessRole";
import PeoplePickerWith from "../peoplePickerWith/PeoplePickerWith";

const ManageAccess: FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const recordPermissions = useAppSelector(selectRecordPermissions);
  const associations = useAppSelector(selectRecordAssociations);

  const { hasRecordAccessRole } = useHasRecordAccessRole();

  const { data: challengeRecord } = useGetRecordQuery(
    {
      recordId: record?.id ?? 0,
      recordType: RecordType.Challenges
    },
    {
      skip: recordType !== RecordType.Ideas && !associations.length
    }
  );
  const { data: recordTemplate } = useGetTemplateQuery(record?.recordTemplateId ?? 0, {
    skip: !record?.recordTemplateId
  });
  const [saveRecordPermissions] = useSaveRecordPermissionsMutation();

  const [parentPermissions, setParentPermissions] = useState<{
    owners: RecordPermission[];
    managers: RecordPermission[];
  }>({ owners: [], managers: [] });

  useEffect(() => {
    // We get the permissions from the parent record and we set the owners as managers and the ideators as owners to pass them to the useGetDynamicPersons hook.
    if (challengeRecord?.recordPermissions) {
      const ideatorsParent = [...challengeRecord.recordPermissions]
        .filter(e => e.role === RecordRole.Ideator)
        .map(e => {
          return { ...e, role: RecordRole.Owner };
        });
      const ownersParent = [...challengeRecord.recordPermissions]
        .filter(e => e.role === RecordRole.Owner)
        .map(e => {
          return { ...e, role: RecordRole.Manager };
        });
      setParentPermissions({ owners: ideatorsParent, managers: ownersParent });
    }
  }, [challengeRecord]);

  const {
    filteredPersons: filteredOwners,
    selectedPeople: selectedOwners,
    hasLoaded: ownersLoaded
  } = useGetDynamicPersons(
    RecordRole.Owner,
    recordTemplate,
    recordPermissions,
    recordType,
    undefined,
    undefined,
    recordType === RecordType.Ideas ? parentPermissions.owners : undefined
  );

  const {
    filteredPersons: filteredManagers,
    peoplePickerValue: selectedManagers,
    hasLoaded: managersLoaded
  } = useGetDynamicPersons(
    RecordRole.Manager,
    recordTemplate,
    recordPermissions,
    recordType,
    undefined,
    undefined,
    recordType === RecordType.Ideas ? parentPermissions.managers : undefined
  );

  const owners = recordPermissions
    .filter(permission => permission.role === RecordRole.Owner)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const managers = recordPermissions
    .filter(permission => permission.role === RecordRole.Manager)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const readers = recordPermissions
    .filter(permission => permission.role === RecordRole.Reader)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const editors = recordPermissions
    .filter(permission => permission.role === RecordRole.Editor)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const reviewers = recordPermissions
    .filter(permission => permission.role === RecordRole.Reviewer)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const ideators = recordPermissions
    .filter(permission => permission.role === RecordRole.Ideator)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const readOnlyAccess = recordPermissions
    .filter(permission => permission.role === RecordRole.ReadOnly)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const currentWorkflowStage = useAppSelector(selectWorkflowStage);

  const tooFewReviewers = useMemo(
    () =>
      Boolean(
        currentWorkflowStage?.requiredApproversCount &&
          reviewers?.length < currentWorkflowStage?.requiredApproversCount
      ),
    [currentWorkflowStage?.requiredApproversCount, reviewers?.length]
  );

  return (
    <ProjectRecordFlyout title={t(RecordSettings.manageAccess)}>
      <>
        <Stack spacing={2}>
          {readOnly && <Alert severity="info">{t("manageAccessReadOnlyMessage")}</Alert>}
          <Box data-testid={"manageaccess-processmanagers-people-picker"}>
            <PeoplePickerWith
              multiple
              disabled={readOnly}
              label={t("processManagers")}
              value={managers}
              filteredPeople={filteredManagers ?? []}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Manager, entities }));
              }}
            />
          </Box>
          <Box data-testid={"manageaccess-owners-people-picker"}>
            <PeoplePickerWith
              multiple
              disabled={readOnly}
              label={t("owners")}
              value={owners}
              filteredPeople={filteredOwners ?? []}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Owner, entities }));
              }}
            />
          </Box>
          <RecordFlag recordType={recordType} hiddenRecordTypes={[RecordType.Challenges]}>
            <Box data-testid={"manageaccess-editors-people-picker"}>
              <PeoplePickerWith
                multiple
                disabled={readOnly}
                label={t("editors")}
                value={editors}
                onChange={(entities: PersonaEntity[]) => {
                  dispatch(updateRecordRoles({ role: RecordRole.Editor, entities }));
                }}
              />
            </Box>
          </RecordFlag>
          <Box data-testid={"manageaccess-readers-people-picker"}>
            <PeoplePickerWith
              multiple
              disabled={readOnly}
              label={t("readers")}
              value={readers}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Reader, entities }));
              }}
            />
          </Box>

          <RecordFlag recordType={recordType} hideComponent={recordType !== RecordType.Challenges}>
            <PeoplePickerWith
              multiple
              disabled={readOnly}
              label={t("ideators")}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Ideator, entities }));
              }}
              value={ideators}
            />
          </RecordFlag>

          {currentWorkflowStage?.hasReview && (
            <>
              {tooFewReviewers && (
                <Alert severity="warning">{t("manageAccessMinimumReviewersWarning")}</Alert>
              )}
              <PeoplePickerWith
                multiple
                disabled={!hasRecordAccessRole([RecordAccessRole.UpdateReviewers]) || readOnly}
                label={t("reviewers")}
                value={reviewers}
                onChange={(entities: PersonaEntity[]) => {
                  dispatch(updateRecordRoles({ role: RecordRole.Reviewer, entities }));
                }}
              />

              <TextField
                type="number"
                variant="outlined"
                label={t("requiredNumberOfApprovals")}
                helperText={t("reviewersCountMessage")}
                value={currentWorkflowStage?.requiredApproversCount ?? ""}
                disabled
              />

              <TextField
                type="number"
                variant="outlined"
                label={t("maxNumberOfReviews")}
                helperText={t("maxReviewersCountMessage")}
                value={currentWorkflowStage?.maxReviewersCount ?? ""}
                disabled
              />

              <BoldTranslation i18nKey="requiredNumberOfApprovalsIsNotMet" />
            </>
          )}

          {readOnlyAccess?.length > 0 && (
            <RecordFlag recordType={recordType} hideComponent={recordType === RecordType.Programs}>
              <PeoplePickerWith
                multiple
                disabled
                label={t("readOnlyAccess")}
                value={readOnlyAccess}
              />
            </RecordFlag>
          )}
        </Stack>
        {!readOnly && (
          <Stack
            direction="row"
            sx={{ marginTop: "100px" }}
            spacing={1}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              disabled={(owners.length === 0 && managers.length === 0) || tooFewReviewers}
              onClick={async () => {
                if (!recordAuth) return;

                await saveRecordPermissions({
                  recordId: recordAuth.details.RecordId as number,
                  body: recordPermissions,
                  recordType
                });

                dispatch(setIsFlyoutOpen(false));
              }}
              data-testid={"manageaccess-save-and-close-btn"}
            >
              {t("save&close")}
            </Button>
          </Stack>
        )}
      </>
    </ProjectRecordFlyout>
  );
};

export const permissionToPersona = (entityId: string, permissionType: PermissionType) =>
  ({
    entityId,
    type: permissionType,
    userName: "",
    presence: ""
  } as PersonaEntity);
export default ManageAccess;
