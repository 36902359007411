import { formatModifiedDate, RecordTemplate, RecordType } from "enada-common";
import { useMemo } from "react";
import { useGetTemplatesQuery } from "../../services/api";
import { selectInData } from "../rtkQuery";

export const useSelectTemplatesByRecordType = (recordType: RecordType | undefined) => {
  const selectedRecordTemplates = useMemo(
    () =>
      selectInData<RecordTemplate[]>(data => {
        if (!recordType) return data;
        return data
          ?.filter(template => template.recordType === recordType)
          .map(dataToMap => {
            return {
              ...dataToMap,
              modified: formatModifiedDate(new Date(dataToMap?.modified ?? ""))
            };
          })
          .filter(res => !res.isDeleted);
      }),
    [recordType]
  );

  const { selectedTemplates = [] } = useGetTemplatesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      selectedTemplates: selectedRecordTemplates(result)
    })
  });

  return selectedTemplates;
};
