import { useAppDispatch } from "../../../../../store/hooks";
import {
  BarLocation,
  insertUnitIntoConditionBlock,
  moveUnitInConditionBlockExpression,
  ExpressionLocation,
  removeUnitFromConditionBlock
} from "../../../../../store/slices/calculatedFieldSlice";
import { CalculatedMoveAction } from "../calculated.model";

const useConditionDrop = (targetLocation: BarLocation) => {
  const dispatch = useAppDispatch();
  return (moveAction: CalculatedMoveAction) => {
    if (moveAction.sourceLocation === undefined) {
      return;
      //To be used if we want to drag fields into expression
    } else {
      const { path: sourcePath, index: sourceIndex, target } = moveAction.sourceLocation;
      if (sourcePath === undefined || target === undefined) {
        return;
      }
      if (target === targetLocation.target && sourcePath === targetLocation.path) {
        dispatch(
          moveUnitInConditionBlockExpression({
            target: targetLocation.target,
            path: targetLocation.path ?? "",
            targetIndex: targetLocation.index,
            sourceIndex: sourceIndex
          })
        );
      } else {
        dispatch(
          insertUnitIntoConditionBlock({
            location: targetLocation,
            unit: moveAction.unit
          })
        );
        dispatch(
          removeUnitFromConditionBlock({
            path: { path: sourcePath, target },
            index: sourceIndex
          })
        );
      }
    }
  };
};
export default useConditionDrop;
