import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetNodesFormsAndCards,
  selectIsWorkflowDefault,
  selectFormWorkflow,
  selectWorkflowIsEdit,
  selectWorkflowLevelValidationErrors,
  toggleAllStagesAllowRecordCreation,
  updateWorkflowDescription,
  updateWorkflowName,
  updateWorkflowScope,
  updateWorkflowStageSkip
} from "../../../../store/slices/workflowSlice";
import { EdisonTypography } from "enada-components";
import WorkflowFlag from "../workflowflag/WorkflowFlag";
import { RecordType } from "enada-common";
import { useHasModule } from "../../../../utils/hooks/useHasModule";

const WorkflowInfo = () => {
  const { t } = useTranslation(["common"]);
  const { isModuleEnabled } = useHasModule();
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectFormWorkflow);
  const errors = useAppSelector(selectWorkflowLevelValidationErrors);
  const isEdit = useAppSelector(selectWorkflowIsEdit);
  const isDefault = useAppSelector(selectIsWorkflowDefault);

  const onChangeScope = (e: SelectChangeEvent<RecordType>) => {
    dispatch(resetNodesFormsAndCards(e.target.value as RecordType));
    dispatch(updateWorkflowScope(e.target.value as RecordType));
    // If the workflow is a challenge, we want to by default allow idea creation in all stages
    dispatch(toggleAllStagesAllowRecordCreation(e.target.value === RecordType.Challenges));
  };

  return (
    <Stack spacing={2}>
      <EdisonTypography title={t("editYourWorkflowProperties")} variant="menuitem" />
      <TextField
        variant="standard"
        size="small"
        label={t("workflowName")}
        disabled={isDefault}
        value={workflow.displayName ?? ""}
        onChange={e => dispatch(updateWorkflowName(e.target.value))}
        error={!!errors?.find(error => error.property === "workflowName")}
        helperText={
          errors.some(error => error.property === "workflowName")
            ? t("emptyWorkflowNameErrorMessage")
            : undefined
        }
        data-testid={"workflow-name-input"}
      />
      <TextField
        variant="standard"
        size="small"
        label={t("workflowDescription")}
        value={workflow.description ?? ""}
        disabled={isDefault}
        multiline
        onChange={e => dispatch(updateWorkflowDescription(e.target.value))}
      />
      <FormControl className="input" variant="standard" required disabled={isEdit}>
        <InputLabel id="type-select">{t("scope")}</InputLabel>
        <Select
          labelId="type-select"
          variant="standard"
          value={workflow.type ?? RecordType.Projects}
          onChange={onChangeScope}
        >
          {Object.keys(RecordType)
            .filter(type => isModuleEnabled(type as RecordType))
            .map(module => (
              <MenuItem key={module} value={module}>
                {t(module)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <WorkflowFlag
        disabled={isDefault}
        checked={workflow.allowStageSkip === undefined ? false : workflow.allowStageSkip}
        onChange={e => {
          dispatch(updateWorkflowStageSkip(e.target.checked));
        }}
        primaryLabel={t("allowManualStageChanges")}
        secondaryLabel={t("allowManualStageChangesMessage")}
      />
    </Stack>
  );
};

export default WorkflowInfo;
