import { WorkflowStage } from "enada-common";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Theme
} from "@mui/material";
import { Node } from "reactflow";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectCurrentElement,
  updateElement,
  selectWorklowValidationErrorsByNode,
  clearWorkflowError,
  selectIsWorkflowDefault
} from "../../../../store/slices/workflowSlice";
import { EdisonTypography } from "enada-components";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";

const StageTasks = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const isDefault = useAppSelector(selectIsWorkflowDefault);
  const errors = useAppSelector(state =>
    selectWorklowValidationErrorsByNode(state, currentNode?.data?.name)
  );

  const tasksError = errors.find(error => error.property === "stageItems");
  return (
    <Stack spacing={1}>
      <EdisonTypography
        sx={(theme: Theme) => ({
          color: tasksError !== undefined ? theme.palette.error.main : theme.palette.text.primary
        })}
        title={t("checklist")}
        variant="dropdownsubheader"
      />
      <Stack spacing={2}>
        <Stack direction="column" spacing={2}>
          {(currentNode?.data as WorkflowStage).tasks?.map((task, index) => (
            <TextField
              size="small"
              className="no-label-input"
              variant="standard"
              key={index}
              disabled={isDefault}
              value={task.description}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        const newList = (currentNode?.data as WorkflowStage).tasks?.filter(
                          (task, filterIndex: number) => filterIndex !== index
                        );
                        dispatch(
                          updateElement(updateNodeCustomData("tasks", newList, currentNode as Node))
                        );
                      }}
                    >
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={e => {
                const current = (currentNode?.data as WorkflowStage).tasks;

                if (current && currentNode) {
                  const updated = current.map((task, mapIndex) => {
                    if (mapIndex === index) {
                      return { description: e.target.value };
                    }
                    return task;
                  });
                  dispatch(
                    updateElement(updateNodeCustomData("tasks", updated, currentNode as Node))
                  );
                }
              }}
            />
          ))}
        </Stack>
        <Button
          variant="text"
          disabled={isDefault}
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            const current = (currentNode?.data as WorkflowStage).tasks;
            if (current) {
              dispatch(
                updateElement(
                  updateNodeCustomData(
                    "tasks",
                    [...current, { description: "" }],
                    currentNode as Node
                  )
                )
              );
            } else {
              dispatch(
                updateElement(
                  updateNodeCustomData("tasks", [{ description: "" }], currentNode as Node)
                )
              );
            }

            if (tasksError === undefined) return;
            dispatch(clearWorkflowError(tasksError));
          }}
        >
          {t("addItem")}
        </Button>
      </Stack>
      {errors.some(error => error.property === "stageItems") && (
        <FormHelperText error={true}>{t("reviewStageTaskError")}</FormHelperText>
      )}
    </Stack>
  );
};

export default StageTasks;
