import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  reducerStatus,
  Table,
  ItemDNDType,
  NonSelectedItem,
  Card,
  CardField,
  RecordType
} from "enada-common";

export interface LivePreviewState {
  cardData: ItemDNDType[];
  cardFieldsData: CardField[];
  cardName: string;
  cardInternalName: string;
  cardDescription: string;
  cardScope: string;
  isEdit: boolean;
  isVisibilityMode: boolean;
  usedComponents: string[];
  tablesList: Table[];
  currentCard: Card | null | Partial<Card>;
  status: reducerStatus;
  readOnly: boolean;
}

const initialState: LivePreviewState = {
  cardName: "",
  cardInternalName: "",
  cardDescription: "",
  cardScope: RecordType.Projects,
  cardData: [],
  cardFieldsData: [],
  usedComponents: [],
  isEdit: false,
  isVisibilityMode: false,
  tablesList: [],
  currentCard: null,
  status: "idle",
  readOnly: false
};

export const cardDesignerSlice: any = createSlice({
  name: "carddesigner",
  initialState,
  reducers: {
    resetLiveCard: state => {
      state.cardData = initialState.cardData;
      state.cardFieldsData = initialState.cardFieldsData;
      state.cardInternalName = initialState.cardInternalName;
      state.cardName = initialState.cardName;
      state.cardDescription = initialState.cardDescription;
      state.cardScope = initialState.cardScope;
      state.isEdit = initialState.isEdit;
      state.isVisibilityMode = initialState.isVisibilityMode;
      state.usedComponents = initialState.usedComponents;
      state.cardScope = initialState.cardScope;
      state.currentCard = initialState.currentCard;
    },

    setCardName: (state, action: PayloadAction<string>) => {
      state.cardName = action.payload;
    },
    setInternalCardName: (state, action: PayloadAction<string>) => {
      state.cardInternalName = action.payload;
    },
    setCardDescription: (state, action: PayloadAction<string>) => {
      state.cardDescription = action.payload;
    },
    setCardScope: (state, action: PayloadAction<string>) => {
      state.cardScope = action.payload;
    },

    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setCardData: (state, action: PayloadAction<ItemDNDType[]>) => {
      state.cardData = action.payload;
    },
    setCardFieldsData: (state, action: PayloadAction<CardField[]>) => {
      state.cardFieldsData = action.payload;
    },
    setCurrentCard: (state, action: PayloadAction<Card>) => {
      state.currentCard = action.payload;
    }
  }
});

export const {
  resetLiveCard,
  setCardName,
  setInternalCardName,
  setCardDescription,
  setCardScope,
  setIsEdit,
  setCardData,
  setCardFieldsData,
  setCurrentCard
} = cardDesignerSlice.actions;

const inputSelectCardDesigner = (state: RootState) => state.carddesigner;

export const selectCardData = createSelector([inputSelectCardDesigner], card => card.cardData);

export const selectCardFieldsData = createSelector(
  [inputSelectCardDesigner],
  card => card.cardFieldsData
);

export const selectCardName = createSelector([inputSelectCardDesigner], card => card.cardName);

export const selectCardInternalName = createSelector(
  [inputSelectCardDesigner],
  card => card.cardInternalName
);

export const selectCardDescription = createSelector(
  [inputSelectCardDesigner],
  card => card.cardDescription
);

export const selectCardScope = createSelector([inputSelectCardDesigner], card => card.cardScope);

export const selectCardDesignerIsEdit = createSelector(
  [inputSelectCardDesigner],
  card => card.isEdit
);

export const selectCardDisabledItemList = createSelector([selectCardFieldsData], cardFieldsData => {
  const result: NonSelectedItem[] = [];
  cardFieldsData.forEach(row => {
    result.push({
      id: row.fieldId,
      type: row.field?.dataType
    });
  });
  return result;
});

export const selectCardTableList = createSelector(
  [inputSelectCardDesigner],
  card => card.tablesList
);

export const selectCardLivePreviewStatus = createSelector(
  [inputSelectCardDesigner],
  card => card.status
);

export const selectLivePreviewCurrentCard = createSelector(
  [inputSelectCardDesigner],
  card => card.currentCard
);

export default cardDesignerSlice.reducer;
