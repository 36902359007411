import { RecordType } from "enada-common";
import { apiConfig } from "../../config/authConfig";
import { baseAPI } from "../baseAPI";
import { getApiForRecord } from "services/APIService";
import { getHeadersFromAuth, RecordAuth } from "store/slices/recordSlice";

export const recordsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getDistinctValues: builder.query<string[], { propertyName: string }>({
      query: ({ propertyName }) =>
        `${apiConfig.recordsODataEndpoint}${decodeURIComponent(
          `?$apply=groupby((${propertyName}))`
        )}`,
      transformResponse: (response: { data: string[] }) => response.data
    }),

    continueRecordWorkflow: builder.mutation<
      void,
      { recordId: number; recordType: RecordType; sessionId: string; auth: RecordAuth }
    >({
      query: ({ recordId, recordType, auth, sessionId }) => ({
        url: `${getApiForRecord(recordType)}/${recordId}/workflow/continue`,
        method: "POST",
        headers: getHeadersFromAuth({ ...auth, sessionId })
      }),
      invalidatesTags: ["Personal"]
    })
  })
});
