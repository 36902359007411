import React from "react";
import { RecordType } from "enada-common";
import { useHasModule } from "../../utils/hooks/useHasModule";

const HasModule = ({
  children,
  recordType,
  noMatchElement = <></>
}: {
  children: JSX.Element;
  recordType: RecordType;
  noMatchElement?: JSX.Element;
}) => {
  const { isModuleEnabled } = useHasModule();
  if (!isModuleEnabled(recordType)) {
    return noMatchElement;
  }
  return children;
};

export default HasModule;
