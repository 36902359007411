import { Column, WorkflowStageView, WorkflowStageViewFieldState, Zone } from "enada-common";
import { Stack } from "@mui/material";
import React, { FC, useMemo } from "react";
import FormColumn from "./FormColumn";
import { EdisonTypography } from "enada-components";
import { useAppSelector } from "../../../store/hooks";
import { selectWorkflowStage } from "../../../store/slices/recordSlice";
import { getRowVisibility } from "../../workflowdesigner/workflowlivepreview/utils/handleFormVisibility";
import { sortFormElements } from "../../../store/slices/parseFormData";

export interface RowProps {
  zone: Zone;
}
const Row: FC<RowProps> = ({ zone }) => {
  const workflowStage = useAppSelector(selectWorkflowStage);
  const stageView =
    workflowStage?.views && workflowStage?.views?.length !== 0
      ? (workflowStage?.views as WorkflowStageView[])[0] // TODO : review this when we can add multiple views to the a workflow
      : undefined;
  const orderedColumns = useMemo(() => {
    const ordered = sortFormElements([...(zone.columns ?? [])]);
    return ordered;
  }, [zone]);
  return (
    <>
      {zone.displayName &&
        getRowVisibility(zone, stageView?.viewFields, stageView?.viewTables) !==
          WorkflowStageViewFieldState.Hidden && (
          <Stack direction="row" width="100%">
            <EdisonTypography variant="h2" title={zone.displayName} />
          </Stack>
        )}

      <Stack direction="row" spacing={2} width="100%">
        {orderedColumns?.map((column: Column, index: number) => {
          const nOfColumns = zone.columns?.length || 0;
          return (
            <div
              key={index}
              style={{
                width: `${100 / nOfColumns}%`
              }}
            >
              <FormColumn column={column} />
            </div>
          );
        })}
      </Stack>
    </>
  );
};

export default Row;
