import { TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { UserNumberFieldProps, fieldConfiguration, FieldDataType, useDebounce } from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import { formatDecimals } from "../../utils/formatDecimals";

const UserNumberField: FC<UserNumberFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  maxDecimalPlaces,
  min,
  max,
  setReadOnly,
  autoFocus,
  required
}) => {
  const [internalState, setInternalState] = useState(value || "");

  const isLessThanMin = (value: string) => {
    return value && min && Number(value) < min;
  };
  const isMoreThanMax = (value: string) => {
    return value && max && Number(value) > max;
  };
  const getIsValid = (value: string) => {
    return isLessThanMin(value) || isMoreThanMax(value) ? false : true;
  };

  const [isValid, setIsValid] = useState<boolean>(getIsValid(value));

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  useDebounce(
    () => {
      if (!isValid) return;
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState, isValid);
    },
    1000,
    [internalState]
  );

  const handleChange = (value: string) => {
    setInternalState(formatDecimals(value.toString(), maxDecimalPlaces));
    setIsValid(getIsValid(value));
  };

  const placeholder = () => {
    if (min && max) return `${min}-${max}`;
    return "";
  };

  return (
    <>
      {!readOnly ? (
        <TextField
          required={required}
          placeholder={placeholder()}
          variant="standard"
          type="number"
          label={!isInTable && label}
          fullWidth
          autoFocus={autoFocus}
          onBlur={() => {
            if (!isValid) return;
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!isInTable) return;
            if (!onChange) return;
            onChange(internalState, isValid);
          }}
          onFocus={
            isInTable
              ? event => {
                  event.target.select();
                }
              : undefined
          }
          value={internalState}
          onChange={e => {
            handleChange(e.target.value);
          }}
          inputProps={{
            min,
            max
          }}
          helperText=""
          size="small"
          error={error || !isValid}
        />
      ) : (
        <EdisonFieldIcon
          title={label}
          subtitle={Number(internalState).toLocaleString()}
          icon={fieldConfiguration(FieldDataType.Number).icon}
          color={fieldConfiguration(FieldDataType.Number).color}
          isIconOn={isIconOn}
          isTableModeOn={!!isInTable}
          additionalStyles={isInTable ? { textAlign: "right" } : undefined}
          required={required}
          error={required && internalState === ""}
        />
      )}
    </>
  );
};

export default UserNumberField;
export { UserNumberField };
