import { useTheme } from "@mui/material";
import { use } from "i18next";
import React from "react";
import { NodeBaseProps } from "../nodeProps.model";
export interface LeafProps extends NodeBaseProps {
  leaf: any;
  isInTable?: boolean;
}
const Leaf = ({ attributes, children, leaf, isInTable }: LeafProps): JSX.Element => {
  const theme = useTheme();

  const fontFamily = leaf.fontFamily ?? theme.typography.fontFamily;

  if (leaf.bold) {
    children = (
      <strong
        style={{
          fontSize: `${leaf.fontSize}px`,
          color: leaf.fontColour,
          fontFamily: fontFamily
        }}
      >
        {children}
      </strong>
    );
  }

  if (leaf.code) {
    children = (
      <code
        style={{
          fontSize: `${leaf.fontSize}px`,
          color: leaf.fontColour,
          fontFamily: fontFamily
        }}
      >
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = (
      <em
        style={{
          fontSize: `${leaf.fontSize}px`,
          color: leaf.fontColour,
          fontFamily: fontFamily
        }}
      >
        {children}
      </em>
    );
  }

  if (leaf.underline) {
    children = (
      <u
        style={{
          fontSize: `${leaf.fontSize}px`,
          color: leaf.fontColour,
          fontFamily: fontFamily
        }}
      >
        {children}
      </u>
    );
  }

  return (
    <span
      {...attributes}
      style={{
        textWrap:"balance",
        fontSize: `${leaf.fontSize}px`,
        color: leaf.fontColour,
        fontFamily: fontFamily
      }}
    >
      {children}
    </span>
  );
};
export default Leaf;
