import { FC, PropsWithChildren, useState } from "react";
import EdisonIconButton from "../../../../edison/iconbutton/EdisonIconButton";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Popover } from "@mui/material";

const UserTableSettingsModal: FC<PropsWithChildren> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  return (
    <>
      <EdisonIconButton
        testId="user-table-settings-modal"
        variant="outlined"
        icon={<SettingsOutlinedIcon color="primary" />}
        onClick={e => setAnchorEl(e.currentTarget)}
      />
      <Popover
        disableEnforceFocus={true}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {children}
      </Popover>
    </>
  );
};

export default UserTableSettingsModal;
