import { Field, TableDataType } from "enada-common";
import { generateBaseBryntumColumn } from "../../tableutils/generateBaseBryntumColumn";
import { nameColumnConfig } from "../../columns/SharedColumns";
import { GridColumnConfig } from "@bryntum/grid-thin";

const ListTableColumns = (columns: Field[], labelToSearchFor: string) => {
  const filteredColumns = columns.filter(column => column.name !== "e365-Name");
  const baseColumns = filteredColumns.map(column => {
    return {
      ...generateBaseBryntumColumn(
        column,
        filteredColumns,
        undefined,
        TableDataType.List,
        labelToSearchFor
      ),
      cls: "gridHeader",
      htmlEncodeHeaderText: false
    };
  });

  return [nameColumnConfig(true, false), ...baseColumns] as GridColumnConfig[];
};
export default ListTableColumns;
