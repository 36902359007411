import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { adminRoutes } from "../../config/routes";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { EdisonTypography } from "enada-components";
import { imageMapping } from "../../utils/mappers/imageMapping";

// const SharedButton = React.lazy(() => import("shared/Button"));

const Admin = () => {
  const { t } = useTranslation(["common"]);
  return (
    <Box sx={{ p: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, xl: 12 }}>
        {adminRoutes
          .filter(route => route.isAdminOption)
          .map(route => (
            <Grid item xs={12} sm={4} md={4} xl={3} key={route.key}>
              <NavLink
                style={{ textDecoration: "none" }}
                to={route.path}
                state={{ title: route.key }}
              >
                <Paper sx={{ p: 2, borderRadius: 2, height: "110px" }}>
                  <Stack
                    sx={{ height: "100%" }}
                    direction="column"
                    justifyContent={"space-between"}
                  >
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <Avatar
                        sizes="2"
                        sx={{
                          color: "white",
                          backgroundColor: route.color,
                          height: 32,
                          width: 32,
                          marginRight: 2
                        }}
                      >
                        {imageMapping(route.key || "home", "inherit")}
                      </Avatar>
                      <EdisonTypography title={t(route.key)} variant="h1" />
                    </Stack>
                    <EdisonTypography title={t(route.description || "")} variant="h5" />
                  </Stack>
                </Paper>
              </NavLink>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Admin;
