import { AssignmentStore, DependencyStore, ProjectModel, TaskStore } from "@bryntum/gantt-thin";

import { RecordTableRow, TableDataType } from "enada-common";

export const isChangeEventValid = (
  changeEvent: any,
  tableRows: RecordTableRow[],
  tableType: string
) => {
  return tableType === TableDataType.Schedule
    ? isTaskChangeEventValid(changeEvent)
    : isStandardChangeEventValid(changeEvent, tableRows);
};

const isStandardChangeEventValid = (changeEvent: any, tableRows: RecordTableRow[]) => {
  if (changeEvent.index === 0 && tableRows.length > 0 && changeEvent.action === "add") {
    return false; // Needed to stop api call on initial data load
  }
  if (["add", "remove"].includes(changeEvent.action)) {
    return true;
  }

  if (!changeEvent.changes) return false;
  const [key, { value, oldValue }] = Object.entries(changeEvent.changes)[0] as any;
  //If creating a new value for cell therefore event is Valid
  if (oldValue === undefined) {
    return true;
  }

  // Check for custom components
  if ((key as string).endsWith("-e365")) {
    // check if value changed if not then readOnly has change therefore not valid
    return oldValue !== value;
  }

  // Check for bryntum components
  if (value !== oldValue) {
    return true;
  }

  // catch
  return false;
};

const isTaskChangeEventValid = (changeEvent: any) => {
  if (changeEvent.record instanceof ProjectModel) {
    return false;
  }

  if (
    changeEvent.store instanceof DependencyStore &&
    changeEvent.records[0] &&
    changeEvent.records[0].fromTask &&
    changeEvent.records[0].toTask
  ) {
    // Valid Event if we are handling the chaining of two tasks together
    // Used To Add successors to the dependencies object
    return true;
  }

  if (!(changeEvent.store instanceof TaskStore || changeEvent.store instanceof AssignmentStore)) {
    // If store is not a TaskStore then the events are comming from the time ranges table
    // TODO: Handle time ranges events
    return false;
  }

  if (changeEvent.isExpand || changeEvent.isCollapse) {
    return false;
  }
  if (["add", "remove", "indent", "outdent"].includes(changeEvent.action)) {
    return true;
  }
  let isValid = false;
  if (!changeEvent.changes) return false;
  const keys = Object.keys(changeEvent.changes);
  keys.forEach((key: string) => {
    const component = changeEvent.changes[key];
    if (component.value !== component.oldValue) {
      isValid = true;
    }
  });

  return isValid;
};
