import React, { memo } from "react";
import { Chip, useTheme } from "@mui/material";
import { Loading } from "enada-components";

const WorkflowStageChip = memo(function WorkflowStageChip({
  displayName,
  color,
  width = "auto"
}: {
  displayName?: string | null;
  color?: string | null;
  width?: string;
}) {
  const theme = useTheme();

  return (
    <Chip
      color="primary"
      size="small"
      label={displayName || <Loading size={27} />}
      sx={{
        lineHeight: "20px",
        backgroundColor: color ?? theme.palette.common.white,
        color: theme.palette.getContrastText(color ?? theme.palette.common.white),
        width: width,
        ...(width !== "auto" && { textOverflow: "ellipsis" })
      }}
    />
  );
});

export default WorkflowStageChip;
