import { Expression } from "enada-common";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Node } from "reactflow";
import { selectCurrentElement, updateElement } from "../../../../store/slices/workflowSlice";
import CalculatedModal from "../../calculatedmodal/CalculatedModal";
import { updateNodeCustomData } from "../../utils/updateNodeCustomData";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import { useDisclosure } from "../../../../utils/hooks/useDisclosure";

const ConditionOptions = () => {
  const { t } = useTranslation(["common"]);

  const { isOpen, onToggle } = useDisclosure();

  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);

  return (
    <Stack>
      <Button
        variant="contained"
        onClick={onToggle}
        data-testid={"workflow-expressionbuilder-btn"}
        startIcon={<PolylineOutlinedIcon />}
      >
        {t("expressionBuilder")}
      </Button>
      <CalculatedModal
        isOpen={isOpen}
        toggleModal={onToggle}
        expression={currentNode?.data.expression1}
        onSave={(expression: Expression) =>
          dispatch(
            updateElement(updateNodeCustomData("expression1", expression, currentNode as Node))
          )
        }
      />
    </Stack>
  );
};

export default ConditionOptions;
