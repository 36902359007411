import { FC } from "react";
import EdiIconSmall from "./EdiIconSmall";
import EdiIconLarge from "./EdiIconLarge";

const EdiIcon: FC<{ size?: "small" | "Large" }> = ({ size = "small" }) => {
  return size === "small" ? <EdiIconSmall /> : <EdiIconLarge />;
};

export default EdiIcon;
export { EdiIcon };
