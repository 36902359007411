import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import React, { FC, ReactNode, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
export interface ImageCropperProps {
  isOpen: boolean;
  title: string;
  image: any;
  onClose: () => void;
  onSave: (file: File) => void;
}
export const ImageCropper: FC<ImageCropperProps> = ({ isOpen, title, image, onClose, onSave }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const getCropperJs = () => {
    const imageElement: any = cropperRef?.current;
    const cropperjs: any = imageElement?.cropper;
    return cropperjs;
  };
  const getFile = () => {
    getCropperJs()
      .getCroppedCanvas()
      .toBlob(
        (blob: any) => {
          onSave(new File([blob], image.name, { type: image.type }));
        },
        image.type,
        1
      );
  };
  const iconButton = (action: () => void, icon: ReactNode, ariaLabel: string): ReactNode => {
    return (
      <IconButton color="primary" size="large" aria-label={ariaLabel} onClick={action}>
        {icon}
      </IconButton>
    );
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Cropper
            ref={cropperRef}
            style={{ height: 400, width: "100%" }}
            src={image.preview as string}
          />
        </DialogContent>
        <DialogActions>
          {iconButton(() => getCropperJs().zoom(0.1), <ZoomInIcon fontSize="inherit" />, "Zoom in")}
          {iconButton(
            () => getCropperJs().zoom(-0.1),
            <ZoomOutIcon fontSize="inherit" />,
            "Zoom out"
          )}
          {iconButton(
            () => getCropperJs().move(-10, 0),
            <ArrowBackIcon fontSize="inherit" />,
            "Move left"
          )}
          {iconButton(
            () => getCropperJs().move(10, 0),
            <ArrowForwardIcon fontSize="inherit" />,
            "Move right"
          )}
          {iconButton(
            () => getCropperJs().move(0, -10),
            <ArrowUpwardIcon fontSize="inherit" />,
            "Move up"
          )}
          {iconButton(
            () => getCropperJs().move(0, 10),
            <ArrowDownwardIcon fontSize="inherit" />,
            "Move down"
          )}
          {iconButton(
            () => getCropperJs().rotate(-45),
            <RotateLeftIcon fontSize="inherit" />,
            "Rotate left"
          )}
          {iconButton(
            () => getCropperJs().rotate(45),
            <RotateRightIcon fontSize="inherit" />,
            "Rotate right"
          )}
        </DialogActions>
        <DialogActions>
          <Button onClick={() => onClose()} color="secondary">
            Close
          </Button>
          <Button onClick={() => getFile()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ImageCropper;
