import { useCallback, useEffect, useMemo } from "react";
import "./config/mgtGlobalConfig";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ContainerRouter } from "./config/routes";
import "./i18n";
import AuthenticationService from "./services/AuthenticationService";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  inputSelectInstance,
  inputSelectTenant,
  selectThemeName,
  setThemeName
} from "./store/slices/userSlice";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary
} from "@microsoft/applicationinsights-react-js";
import "./app.scss";
import NotificationSnackbar from "./components/notificationsnackbar/NotificationSnackar";
import { getPalletteByThemeName, getThemeByName } from "./theme/appTheme";
import useSetPropertyColors from "./theme/useSetPropertyColors";
import { Box, useMediaQuery } from "@mui/material";
import { getThemeName } from "./utils/getThemeName";
import ErrorPage from "./pages/error/ErrorPage";
import { ThemeToggle } from "@microsoft/mgt-react";
import { selectRegion, setRegion } from "./store/slices/configSlice";
import { Loading } from "enada-components";
import { reactPlugin } from "./appInsights";
import { useGetRegionQuery, usePostUserDetailsMutation } from "services/api";
import {
  DISCOVER_SLICE_VERSION,
  selectDiscoverFilterVersion
} from "./store/slices/discoverFiltersSlice";
import { persistor } from "./store/store";

function App(): JSX.Element {
  const dispatch = useAppDispatch();

  const [postUserDetails] = usePostUserDetailsMutation();
  const setPropertyColors = useSetPropertyColors();
  const themeName = useAppSelector(selectThemeName);
  const tenant = useAppSelector(inputSelectTenant);
  const instance = useAppSelector(inputSelectInstance);
  const themeNameStorage = localStorage.getItem("themeName");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const storedInstance = localStorage.getItem("instance");
  const instanceName = storedInstance ?? "frontend";
  const discoverSliceLocalVersion = useAppSelector(selectDiscoverFilterVersion);
  localStorage.setItem("instance", instanceName.toLowerCase()); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point

  const storedTenant = localStorage.getItem("tenant");
  const tenantName = storedTenant ?? "development";
  localStorage.setItem("tenant", tenantName.toLowerCase()); // tolowercase is a temorary fix for case sensitive url, backend to fix on their side at a later point

  const paletteName = getThemeName(
    !themeNameStorage ? themeName : themeNameStorage,
    prefersDarkMode
  );

  const region = useAppSelector(selectRegion);
  const { data: fetchedRegion } = useGetRegionQuery(undefined, {
    skip: Boolean(region) || !tenant
  });

  useEffect(() => {
    if (fetchedRegion) {
      dispatch(setRegion(fetchedRegion));
    }
  }, [dispatch, fetchedRegion]);

  useEffect(() => {
    if (!themeNameStorage) {
      localStorage.setItem("themeName", themeName);
    } else {
      dispatch(setThemeName(paletteName));
    }
    setPropertyColors(getPalletteByThemeName(paletteName));
  }, [dispatch, paletteName, setPropertyColors, themeName, themeNameStorage]);

  const fetchData = useCallback(async () => {
    // wait for region to ensure all subsequent api requests include it in the header

    if (region) await postUserDetails().unwrap();
  }, [postUserDetails, region]);

  useEffect(() => {
    if (!tenant) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, instance, tenant, fetchData]);

  useEffect(() => {
    setPropertyColors(getPalletteByThemeName(paletteName));
    const els = document.getElementsByTagName("mgt-theme-toggle");
    const toggle = els.item(0);
    if (!toggle) return;
    (toggle as any).darkModeActive = paletteName === "dark";
  }, [paletteName, setPropertyColors, themeName]);

  const router = useMemo(
    () => createBrowserRouter(createRoutesFromElements(ContainerRouter())),
    []
  );
  useEffect(() => {
    if (DISCOVER_SLICE_VERSION !== discoverSliceLocalVersion) {
      //If there is a mismatch between the version of the discover slice in
      //local storage and the current version then the data in local storage is outdated
      persistor.purge();
    }
  }, []);

  return (
    <AuthenticationService>
      <div style={{ display: "none" }}>
        <ThemeToggle darkModeActive={paletteName === "dark"} />
      </div>
      <ThemeProvider theme={getThemeByName(paletteName)}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
            <Box className={paletteName === "dark" ? "mgt-dark e365-dark" : ""}>
              <CssBaseline />

              <RouterProvider router={router} fallbackElement={<Loading size={0} />} />

              <NotificationSnackbar />
            </Box>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </AuthenticationService>
  );
}

export default App;
