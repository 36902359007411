import {
  CalculatedExpressionUnit,
  ConditionBlock,
  ExpressionEntity,
  fullOperatorList
} from "enada-common";
import { FrontendRecordValue } from "../../../../../store/slices/recordSlice";
import { evaluate } from "mathjs";

export const calculateExpressionValue = (
  fieldValues: FrontendRecordValue[],
  expression?: CalculatedExpressionUnit[]
) => {
  if (!expression) return 0;
  try {
    const stringToEval = parseFlatExpressionToEvalString(expression, fieldValues);
    return evaluate(stringToEval);
  } catch (error) {}
};

export const calculateConditionValue = (
  fieldValues: FrontendRecordValue[],
  conditionBlock?: ConditionBlock
) => {
  if (!conditionBlock) return 0;
  try {
    const stringToEval = parseConditionBlockToEvalString(conditionBlock, fieldValues);
    return evaluate(stringToEval);
  } catch (error) {}
};

const parseFlatExpressionToEvalString = (
  expression: CalculatedExpressionUnit[],
  fieldValues: FrontendRecordValue[]
) => {
  const frontendExpression = expression.map(unit =>
    unit.type === "entity"
      ? {
          ...unit,
          value: fieldValues.find(
            fieldValue => fieldValue.fieldId === (unit.value as ExpressionEntity).entityId
          )?.value
        }
      : unit
  );

  const stringToEval = frontendExpression.reduce((acc, current) => {
    let unitString: string =
      current.type === "operator"
        ? fullOperatorList.find(pair => pair.backend === current.value)?.frontend
        : current.value;

    if (unitString && typeof unitString === "string") {
      unitString = unitString.replace("||", " or ");
    }

    return `${acc}${unitString}`;
  }, "");

  return stringToEval;
};

const parseConditionBlockToEvalString = (
  block: ConditionBlock,
  fieldValues: FrontendRecordValue[]
) => {
  let stringToEval = "";
  if (block.if) {
    stringToEval += `(${evaluate(parseFlatExpressionToEvalString(block.if, fieldValues))})`;
  }
  if (block.then && (block.else || block.conditionalElse)) {
    stringToEval += ` ? ${evaluate(parseFlatExpressionToEvalString(block.then, fieldValues))}`;
  }
  if (block.then && !block.else && !block.conditionalElse) {
    stringToEval += ` ?? ${evaluate(parseFlatExpressionToEvalString(block.then, fieldValues))}`;
  }
  if (block.conditionalThen) {
    stringToEval += ` ? (${evaluate(
      parseConditionBlockToEvalString(block.conditionalThen, fieldValues)
    )})`;
  }
  if (block.else) {
    stringToEval += ` : ${evaluate(parseFlatExpressionToEvalString(block.else, fieldValues))}`;
  }
  if (block.conditionalElse) {
    stringToEval += ` : (${evaluate(
      parseConditionBlockToEvalString(block.conditionalElse, fieldValues)
    )})`;
  }

  return stringToEval;
};
