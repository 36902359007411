import { createApi } from "@reduxjs/toolkit/query/react";
import { authenticatedBaseQuery } from "./authenticatedBaseQuery";

export const recordTags = [
  "Record",
  "RecordAccessToken",
  "RecordAssociations",
  "RecordFavourited",
  "RecordUserRating",
  "RecordViewValues",
  "RecordTableRows"
];

export const baseAPI = createApi({
  reducerPath: "baseAPI",
  baseQuery: authenticatedBaseQuery,
  tagTypes: [
    "Calendars",
    "Cards",
    "Fields",
    "Forms",
    "HistoryEvents",
    "Licenses",
    "ManageUsers",
    "News",
    "Personal",
    "PersonalData",
    "Records",
    "Resources",
    "RecycledRecords",
    "RecordTables",
    "Tables",
    "Templates",
    "User",
    "Versions",
    "Workflows",
    "WorkflowReview",
    "WorkflowReviewers",
    "WorkflowStage",
    ...recordTags
  ],
  // need to keep this as is for TS to not complain and endpoints to inject correctly
  endpoints: () => ({})
});
