import { FC } from "react";
import {
  Category,
  NotificationLevel,
  RollupTableRowModel,
  Table,
  tablesConfiguration
} from "enada-common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import {
  useDeleteTablesMutation,
  useGetTablesQuery,
  useRecycleTablesMutation,
  useRestoreTablesMutation
} from "services/api";
import { selectRollupRowsFromData } from "utils/rtkQuery";
import { setCurrentNotification } from "store/slices/notificationSlice";

const selectTableRollupRows = selectRollupRowsFromData<Table[]>(data => {
  return data?.map(table => ({
    id: table.id ?? 0,
    displayName: table.displayName,
    modified: table.modified,
    modifiedBy: table.modifiedBy,
    type: table.dataType,
    isDeleted: table.isDeleted,
    category: table.category,
    no_delete_e365: table.category === Category.System || table.category === Category.Default
  }));
});

const Tables: FC = () => {
  const { t } = useTranslation(["common"]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { rollupRows = [] } = useGetTablesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      rollupRows: selectTableRollupRows(result)
    })
  });
  const [deleteTables] = useDeleteTablesMutation();
  const [recycleTables] = useRecycleTablesMutation();
  const [restoreTables] = useRestoreTablesMutation();

  const deleteRows = async (ids: string[]) => {
    try {
      await deleteTables(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "tableDeleted",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "tableDeletedError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      await recycleTables(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "tableRecycled",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "tableRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      await restoreTables(ids.map((id: string) => parseInt(id))).unwrap();

      dispatch(
        setCurrentNotification({
          title: "tableRestored",
          message: "",
          level: NotificationLevel.Success
        })
      );
    } catch {
      dispatch(
        setCurrentNotification({
          title: "tableRestoredError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`edittable`, {
          state: {
            id,
            title: "tables"
          }
        });
      }}
      addAction={() => {
        navigate("newtable", {
          state: {
            title: "tables"
          }
        });
      }}
      columns={rollupTableColumnsWithType(t, (value: any) => tablesConfiguration(value.type).color)}
      rows={rollupRows.filter(field => !field.isDeleted) as unknown as RollupTableRowModel[]}
      deletedRows={rollupRows.filter(field => field.isDeleted) as unknown as RollupTableRowModel[]}
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Tables;
