import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  clearCurrentNotification,
  selectCurrentNotification
} from "../../store/slices/notificationSlice";
import { useTranslation } from "react-i18next";
import { EdisonTypography } from "enada-components";

const NotificationSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const currentNotification = useAppSelector(selectCurrentNotification);

  const { t } = useTranslation(["common"]);

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearCurrentNotification());
    setOpen(false);
  };
  useEffect(() => {
    currentNotification ? setOpen(true) : setOpen(false);
  }, [currentNotification]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={nanoid()}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={currentNotification?.level} sx={{ width: "100%" }}>
          <AlertTitle>
            {t(currentNotification?.title || "")}
            {currentNotification?.message !== "" && (
              <Button
                color="inherit"
                size="small"
                sx={{ marginLeft: "20px" }}
                onClick={() => {
                  setDetailsOpen(prevState => !prevState);
                }}
              >
                {`${detailsOpen ? "Hide" : "View"} Details`}
              </Button>
            )}
          </AlertTitle>
          {currentNotification?.message !== "" && detailsOpen && (
            <EdisonTypography
              sx={{ marginTop: "20px", whiteSpace: "break-spaces" }}
              title={t(currentNotification?.message || "")}
              variant="data2"
            ></EdisonTypography>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NotificationSnackbar;
