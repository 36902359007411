import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RecordTemplate,
  PersonaEntity,
  RecordRole,
  PermissionType,
  RecordType
} from "enada-common";
import { RootState } from "../store";

export interface TemplatesState {
  individualTemplate: RecordTemplate | Partial<RecordTemplate> | null;
  assignedOwners: PersonaEntity[];
  assignedManagers: PersonaEntity[];
  assignedReaders: PersonaEntity[];
  assignedIdeators: PersonaEntity[];
}

const initialState: TemplatesState = {
  individualTemplate: null,
  assignedManagers: [],
  assignedOwners: [],
  assignedReaders: [],
  assignedIdeators: []
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    clearIndividualTemplate: state => {
      state.individualTemplate = { templatePermissions: [], recordType: RecordType.Projects };
      state.assignedManagers = [];
      state.assignedOwners = [];
      state.assignedReaders = [];
      state.assignedIdeators = [];
    },
    updateIndividualTemplate: (state, action: PayloadAction<RecordTemplate>) => {
      state.individualTemplate = {
        ...state.individualTemplate,
        ...action.payload
      };
    },
    updateIndividualTemplateProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof RecordTemplate }>
    ) => {
      state.individualTemplate = {
        ...state.individualTemplate,
        [action.payload.key]: action.payload.value
      };
    },
    updateAssignedRoles: (
      state,
      action: PayloadAction<{ role: RecordRole; personas: PersonaEntity[] }>
    ) => {
      if (action.payload.role === RecordRole.Owner) {
        state.assignedOwners = action.payload.personas;
      } else if (action.payload.role === RecordRole.Manager) {
        state.assignedManagers = action.payload.personas;
      } else if (action.payload.role === RecordRole.Ideator) {
        state.assignedIdeators = action.payload.personas;
      } else {
        state.assignedReaders = action.payload.personas;
      }

      if (state.individualTemplate) {
        if (state.individualTemplate.templatePermissions) {
          state.individualTemplate.templatePermissions =
            state.individualTemplate.templatePermissions?.filter(
              typePermission => typePermission.role !== action.payload.role
            );
        }

        action.payload.personas.forEach(persona => {
          state.individualTemplate?.templatePermissions?.push({
            entityId: persona.entityId,
            permissionType:
              persona.type === PermissionType.User ? PermissionType.User : PermissionType.Group,
            role: action.payload.role
          });
        });
      }
    }
  }
});

export const {
  updateIndividualTemplate,
  updateIndividualTemplateProperty,
  updateAssignedRoles,
  clearIndividualTemplate
} = templatesSlice.actions;

const inputSelectTemplates = (state: RootState) => state.templates;

export const selectIndividualTemplate = createSelector(
  [inputSelectTemplates],
  templates => templates.individualTemplate
);

export const selectAssignedOwners = createSelector(
  [inputSelectTemplates],
  templates => templates.assignedOwners
);

export const selectAssignedManagers = createSelector(
  [inputSelectTemplates],
  templates => templates.assignedManagers
);

export const selectAssignedReaders = createSelector(
  [inputSelectTemplates],
  templates => templates.assignedReaders
);

export const selectAssignedIdeators = createSelector(
  [inputSelectTemplates],
  templates => templates.assignedIdeators
);

export default templatesSlice.reducer;
