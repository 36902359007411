import { Field, FieldDataType, formatModifiedDate, RequiredInType } from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDefaultFieldByDataType } from "../../utils/getDefaultFieldByDataType";
import { RootState } from "../store";
import { selectInData } from "utils/rtkQuery";

export interface fieldsState {
  value: number;
  individualField: Field | null | Partial<Field>;
  status: "idle" | "loading" | "failed";
}

const initialState: fieldsState = {
  value: 0,
  status: "idle",
  individualField: {
    ...getDefaultFieldByDataType(FieldDataType.TextBox),
    dataType: FieldDataType.TextBox
  }
};

export const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearIndividualField: state => {
      state.individualField = {
        ...getDefaultFieldByDataType(FieldDataType.TextBox),
        dataType: FieldDataType.TextBox
      };
    },
    updateIndividualField: (state, action) => {
      state.individualField = action.payload;
    },
    updateIndividualFieldProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Field }>
    ) => {
      state.individualField = {
        ...state.individualField,
        [action.payload.key]: action.payload.value
      };
    },
    updateIndividualFieldDataType: (state, action: PayloadAction<FieldDataType>) => {
      state.individualField = {
        ...getDefaultFieldByDataType(action.payload),
        ...state.individualField,
        dataType: action.payload,
        dataStructure: { choices: [] }
      };
    }
  }
});

export const {
  clearIndividualField,
  updateIndividualField,
  updateIndividualFieldProperty,
  updateIndividualFieldDataType
} = fieldsSlice.actions;

export const selectAllNonDeletedFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res => res.systemFieldType === "None" && !res.isDeleted && (res.dataType as any) !== "Button"
    );
});

export const selectAllNonDeletedRecordSystemFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res =>
        res.systemFieldType !== "None" &&
        (res.requiredIn?.includes(RequiredInType.AllRecords) ||
          res.appliesTo?.includes(RequiredInType.All) ||
          res.appliesTo?.includes(RequiredInType.AllRecords)) &&
        !res.isDeleted &&
        res.dataType !== FieldDataType.Button
    );
});

export const selectAllNonDeletedSystemFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res => res.systemFieldType !== "None" && !res.isDeleted && (res.dataType as any) !== "Button"
    );
});

export const selectAllNonDeletedTaskTableRequiredSystemFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res =>
        res.systemFieldType !== "None" &&
        (res.requiredIn?.includes(RequiredInType.TaskTable) ||
          res.requiredIn?.includes(RequiredInType.AllTables)) &&
        !res.isDeleted &&
        res.dataType !== FieldDataType.Button
    );
});

export const selectAllNonDeletedTableSystemFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res =>
        res.systemFieldType !== "None" &&
        (res.appliesTo?.includes(RequiredInType.AllTables) ||
          res.appliesTo?.includes(RequiredInType.All)) &&
        !res.requiredIn?.includes(RequiredInType.AllTables) &&
        !res.isDeleted &&
        res.dataType !== FieldDataType.Button
    );
});

export const selectAllNonDeletedTableRequiredSystemFields = selectInData<Field[]>(data => {
  return data
    ?.map(field => {
      return {
        ...field,
        modified: formatModifiedDate(new Date(field.modified ?? ""))
      };
    })
    .filter(
      res =>
        res.systemFieldType !== "None" &&
        res.requiredIn?.includes(RequiredInType.AllTables) &&
        !res.isDeleted &&
        res.dataType !== FieldDataType.Button
    );
});

export const selectAllFields = selectInData<Field[]>(data => {
  return data?.map(formatDateInObjectList);
});

export const selectFieldsValidForCalculation = selectInData<Field[]>(data => {
  return data
    ?.map(formatDateInObjectList)
    .filter(
      field =>
        !field.isDeleted &&
        [
          //TODO: Re-add non number fields once we have found a safe package to evaluate user input.
          // FieldDataType.DateTime,
          FieldDataType.Currency,
          // FieldDataType.Date,
          FieldDataType.Number,
          FieldDataType.Percentage
          // FieldDataType.TextBox,
        ].includes(field.dataType)
    )
    .sort((a, b) => (a.dataType > b.dataType ? 1 : b.dataType > a.dataType ? -1 : 0));
});

const inputSelectFields = (state: RootState) => state.fields;

export const selectIndividualField = createSelector(
  [inputSelectFields],
  fields => fields.individualField
);

const formatDateInObjectList = (field: Field) => ({
  ...field,
  modified: formatModifiedDate(new Date(field.modified ?? ""))
});

export default fieldsSlice.reducer;
