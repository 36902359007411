import { Permission, PermissionType, RoleType } from "enada-common";
import { getGraphDynamicPersonByIds } from "./getGraphDynamicPersonByIds";

export const getGraphPeopleByUserPermission = async (
  roleType: RoleType,
  Permissions: Permission[]
) => {
  const users = await getPeopleByUserPermission(roleType, PermissionType.User, Permissions);

  const groups = await getPeopleByUserPermission(roleType, PermissionType.Group, Permissions);

  if (users && groups) return [...users, ...groups];
  else if (users) return users;
  else if (groups) return groups;
};

const getPeopleByUserPermission = async (
  roleType: RoleType,
  permissionType: PermissionType,
  Permissions: Permission[]
) => {
  if (!Permissions) return;

  const ids = Permissions.filter(
    permission =>
      permission.type === permissionType && permission.roles?.map(x => x.value).includes(roleType)
  )
    .map(permission => `'${permission.oid}'`)
    .join(",");

  return await getGraphDynamicPersonByIds(
    ids,
    permissionType === PermissionType.Group ? "groups" : "users"
  );
};
