import { RollupTableColumnModel, RollupTableRowModel, SingleTableProps } from "enada-common";
import {
  DndContext,
  closestCenter,
  UniqueIdentifier,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  styled
} from "@mui/material";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import RollupTableHeader from "../header/RollupTableHeader";
import RollupTableRow, { sortData, SortOrder } from "../row/RollupTableRow";
import "./singletable.scss";

export interface ExtendedSingleTableProps extends SingleTableProps {
  fullScreen: boolean;
  disableSelection?: boolean;
}

const SingleTable: FC<ExtendedSingleTableProps> = ({
  columns,
  rows,
  searchFilter,
  setSelectedRowIds,
  selectedRowIds,
  rowActionsName,
  rowActions,
  onRowOrderChange,
  order,
  orderBy,
  disableColumnSort,
  renderEmptyTable,
  fullScreen,
  disableSelection = false,
  t,
  renderPagination = true,
  currentSkip,
  itemsCount,
  rowsPerPageCount,
  onPageChange
}) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(order || "desc");
  const [sortOrderBy, setSortOrderBy] = useState<string>(orderBy || columns[0].name);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageCount || 25);
  const onSortClicked = (itemKey: string) => {
    const isAsc = sortOrderBy === itemKey && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortOrderBy(itemKey);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    if (onPageChange && currentSkip !== undefined && rowsPerPageCount) {
      // This is if we are using a OData endpoint as we only get a select number of items at a time, mainly 20. So the parent component has to handle the pagination as well as MUI
      const skipValue =
        newPage <= page ? currentSkip - rowsPerPageCount : currentSkip + rowsPerPageCount;

      onPageChange(rows.length < rowsPerPageCount ? 0 : skipValue);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [rows?.length]);

  useEffect(() => {
    if (!renderPagination) {
      setRowsPerPage(rows.length);
    }
  }, [rows.length, renderPagination]);

  function handleDragEnd({ active, over }: any) {
    if (active.id !== over?.id && onRowOrderChange !== undefined) {
      onRowOrderChange(active.id, over?.id);
    }
  }

  const usingODataEndpoint = useMemo(
    () => onPageChange && currentSkip !== undefined && rowsPerPageCount,
    [onPageChange, currentSkip, rowsPerPageCount]
  );

  const currentRows = useMemo(() => {
    let rowsCount = rowsPerPage;
    if (rowsPerPage === -1) rowsCount = rows.length;

    const sortedData = sortData(
      filterDataBySearch(rows, searchFilter, columns),
      sortOrder,
      sortOrderBy
    );

    if (usingODataEndpoint) return sortedData; // OData will handle pagination

    return sortedData.slice(page * rowsCount, page * rowsCount + rowsCount);
  }, [columns, page, rows, rowsPerPage, searchFilter, sortOrder, sortOrderBy, usingODataEndpoint]);

  const isAllChecked =
    rows &&
    rows.length > 0 &&
    selectedRowIds &&
    currentRows &&
    selectedRowIds.length === currentRows.filter(row => !row.disabled_e365).length;

  return (
    <Stack
      className={`single-table-root ${fullScreen && "fullscreen"}`}
      data-testid={"edison-single-table"}
    >
      <TableContainer className="container">
        <DndContext collisionDetection={closestCenter} sensors={sensors} onDragEnd={handleDragEnd}>
          <Table stickyHeader>
            <RollupTableHeader
              columns={columns}
              onSortClicked={onSortClicked}
              order={sortOrder}
              orderBy={sortOrderBy}
              onToggleCheckAll={(checked: boolean) => {
                checked
                  ? setSelectedRowIds(
                      currentRows.filter(row => !row.disabled_e365).map(row => row.id)
                    )
                  : setSelectedRowIds([]);
              }}
              allChecked={isAllChecked}
              rowActionsName={rowActionsName}
              disableColumnSort={disableColumnSort}
              disableSelection={disableSelection}
            />

            <TableBody>
              <SortableContext
                items={currentRows.map(r => {
                  return { id: r.id as UniqueIdentifier };
                })}
                strategy={verticalListSortingStrategy}
              >
                {rows && !rows.length && !renderEmptyTable
                  ? [...Array(rowsPerPage)].map((row, rowIndex) => (
                      <TableRow key={rowIndex} className="singletable-loading-row">
                        {!disableSelection && (
                          <BorderedTableCell>
                            <Skeleton height="22px" width="22px" variant="rounded" />
                          </BorderedTableCell>
                        )}
                        {columns.map((cell, cellIndex) => (
                          <BorderedTableCell key={cellIndex}>
                            <Skeleton>{cell.loadingComponent}</Skeleton>
                          </BorderedTableCell>
                        ))}
                        {rowActions && (
                          <BorderedTableCell>
                            <Skeleton />
                          </BorderedTableCell>
                        )}
                      </TableRow>
                    ))
                  : currentRows.map((row, index) =>
                      row.row_alert_e365 ? (
                        <Fragment key={index}>
                          <RollupTableRow
                            t={t}
                            row={row}
                            columns={columns}
                            isSelected={selectedRowIds.some(id => id === row.id)}
                            onToggleRowChecked={(checked: boolean) =>
                              checked
                                ? setSelectedRowIds([...selectedRowIds, row.id])
                                : setSelectedRowIds(prev => prev.filter(id => id !== row.id))
                            }
                            rowActions={rowActions}
                            rowOrderingEnable={onRowOrderChange !== undefined}
                            disableSelection={disableSelection}
                          />
                          <TableRow>
                            <TableCell
                              className="row-alert-cell"
                              colSpan={
                                // Plus one to to take into account the checkbox column
                                columns.length + (rowActions?.length ?? 0) + 1
                              }
                            >
                              {row.row_alert_e365}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ) : (
                        <RollupTableRow
                          t={t}
                          key={index}
                          row={row}
                          columns={columns}
                          isSelected={selectedRowIds.some(id => id === row.id)}
                          onToggleRowChecked={(checked: boolean) =>
                            checked
                              ? setSelectedRowIds([...selectedRowIds, row.id])
                              : setSelectedRowIds(prev => prev.filter(id => id !== row.id))
                          }
                          rowActions={rowActions}
                          rowOrderingEnable={onRowOrderChange !== undefined}
                          disableSelection={disableSelection}
                        />
                      )
                    )}
              </SortableContext>
            </TableBody>
          </Table>
        </DndContext>
      </TableContainer>
      {renderPagination && (
        <TablePagination
          data-testid={"edison-single-table-pagination"}
          classes={{
            displayedRows: "no-margin-bottom",
            selectLabel: "no-margin-bottom",
            menuItem: "edison-single-table-pagination-menu"
          }}
          rowsPerPageOptions={
            usingODataEndpoint
              ? [rows.length ?? 25]
              : [
                  5,
                  10,
                  25,
                  {
                    value: rows ? -1 : 0,
                    label: "All"
                  }
                ]
          }
          component="div"
          count={itemsCount ?? (rows ? rows.length : 0)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );
};

const filterDataBySearch = (
  rows: RollupTableRowModel[],
  searchFilter: string,
  columns: RollupTableColumnModel[]
) => {
  if (searchFilter.length === 0) {
    return rows;
  }

  return rows.filter(row => {
    let count = 0;
    columns.forEach(column => {
      if (row[column.name]?.toString().toLowerCase().includes(searchFilter.toLowerCase())) {
        count += 1;
        return;
      }
    });

    return count !== 0;
  });
};
export default SingleTable;
export { SingleTable };

export const BorderedTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`
}));
