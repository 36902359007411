import { useAppDispatch } from "../../../../../store/hooks";
import {
  insertUnitIntoSimpleExpression,
  moveUnitInSimpleExpression
} from "../../../../../store/slices/calculatedFieldSlice";
import { CalculatedMoveAction } from "../calculated.model";

const useSimpleDrop = (index: number) => {
  const dispatch = useAppDispatch();
  return (parsed: CalculatedMoveAction) => {
    if (parsed.sourceLocation === undefined) {
      dispatch(insertUnitIntoSimpleExpression(parsed.unit));
    } else {
      const sourceIndex = parsed.sourceLocation.index;
      const targetIndex = index;
      if (sourceIndex + 1 == targetIndex) {
        // Units are allready in the correct position
        //If source Index is end then something has gone wrong.
        return;
      }
      dispatch(
        moveUnitInSimpleExpression({
          sourceIndex: sourceIndex,
          targetIndex: index
        })
      );
    }
  };
};

export default useSimpleDrop;
