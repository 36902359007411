import React, { FC } from "react";
import { useDrop } from "react-dnd";
import clsx from "clsx";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import "./dropzone.scss";
import { Box, Tooltip } from "@mui/material";
import { COLUMN, COMPONENT, ItemDNDType, ROW, SIDEBAR_ITEM } from "enada-common";
import { EdisonTypography } from "enada-components";
import { useTranslation } from "react-i18next";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

export interface DropZoneProps {
  data: any;
  onDrop: any;
  checkDrop?: any;
  isLast?: boolean;
  className?: string;
}

const DropZone: FC<DropZoneProps> = ({ data, onDrop, checkDrop, isLast, className }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item: ItemDNDType, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item: ItemDNDType, monitor) => {
      if (checkDrop) {
        return checkDrop(item, monitor);
      }
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;

      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        if (data.childrenCount >= 5) {
          return false;
        }
        return true;
      }

      const splitItemPath = itemPath.split("-");

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      if (diffRow && splitDropZonePath.length === 2 && data.childrenCount >= 5) {
        return false;
      }

      // Invalid (Can't drop a parent element (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      // Current item can't possible move to it's own location
      if (itemPath === dropZonePath) return false;

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const { t } = useTranslation(["common"]);

  const isActive = isOver && canDrop;

  const dropZoneDivider = () =>
    className !== "horizontalDrag" ? (
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "primary.main"
        }}
        className="hr-line"
      />
    ) : (
      <Box
        sx={{
          borderLeft: "1px solid",
          borderColor: "primary.main"
        }}
        className={`hr-line ${className}`}
      />
    );
  return (
    <Tooltip
      open={isOver && !canDrop}
      placement="top"
      title={<EdisonTypography title={t("rowFull")} variant={"tooltip"} />}
    >
      <div
        data-testid="dropzone"
        className={clsx("dropzone", { active: isActive, isLast }, className)}
        ref={drop}
      >
        {isActive && (
          <div className={`hr-theme-slash-2 ${className}`}>
            {dropZoneDivider()}
            <div className="hr-icon">
              <AddCircleOutlinedIcon color="primary" fontSize="small" />
            </div>
            {dropZoneDivider()}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
export default DropZone;
