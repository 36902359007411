import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiError, Field, FieldValue, reducerStatus, Resource, ResourceType } from "enada-common";
import { formatFieldValueForBackend } from "../../utils/fieldHelpers";
import { RootState } from "../store";

export interface ResourcesState {
  resources: Array<Resource>;
  resourceManagers: any;
  myResources: Array<Resource>;
  individualResource: Partial<Resource> | null;
  IndividualResourceValues: FieldValue[];
  IndividualResourceValuesChanges: FieldValue[];
  filteredResourceReport?: any;
  status: reducerStatus;
  resourceValueStatus: reducerStatus;
  error: ApiError | null;
}

const initialState: ResourcesState = {
  resources: [],
  resourceManagers: [],
  myResources: [],
  individualResource: {
    type: ResourceType.AAD,
    isActive: true
  },
  IndividualResourceValues: [],
  IndividualResourceValuesChanges: [],
  filteredResourceReport: null,
  status: "idle",
  resourceValueStatus: "idle",
  error: null
};

const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearError: state => {
      state.error = null;
      state.status = "idle";
    },
    clearFilteredReport: state => {
      state.filteredResourceReport = null;
    },
    clearIndividualResource: state => {
      state.individualResource = {
        type: ResourceType.AAD,
        isActive: true
      };
    },
    setIndividualResource: (state, action: PayloadAction<Resource>) => {
      state.individualResource = action.payload;
    },
    setIndividualResourceValues: (state, action: PayloadAction<FieldValue[]>) => {
      state.IndividualResourceValues = action.payload;
    },
    updateIndividualResourceProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Resource }>
    ) => {
      state.individualResource = {
        ...state.individualResource,
        [action.payload.key]: action.payload.value
      };
    },
    updateIndividualResourceType: (state, action: PayloadAction<ResourceType>) => {
      state.individualResource = {
        ...state.individualResource,
        type: action.payload,
        isActive: true,
        name: "",
        userId: null,
        department: "",
        email: ""
      };
    },
    updateResourceField: (state, action: PayloadAction<{ field: Field; value: any }>) => {
      const { field, value } = action.payload;
      // update field value collection mapped to compoenents on FE
      const existingFieldValueIndex = state.IndividualResourceValues.findIndex(
        rf => rf.fieldId === field.id
      );

      const newValue = {
        fieldId: field.id,
        ...formatFieldValueForBackend(field.dataType, value),
        resourceId: state.individualResource?.id
      };

      const updatedFieldValues = [...state.IndividualResourceValues];
      if (existingFieldValueIndex > -1) {
        updatedFieldValues[existingFieldValueIndex] = newValue;
      } else {
        updatedFieldValues.push(newValue);
      }

      state.IndividualResourceValues = updatedFieldValues;

      // update field value that will be saved to be
      const existingFieldValuesForBEIndex = state.IndividualResourceValuesChanges.findIndex(
        rf => rf.fieldId === field.id
      );

      const updatedFieldValuesForBE = [...state.IndividualResourceValuesChanges];
      if (existingFieldValuesForBEIndex > -1) {
        updatedFieldValuesForBE[existingFieldValuesForBEIndex] = newValue;
      } else {
        updatedFieldValuesForBE.push(newValue);
      }

      state.IndividualResourceValuesChanges = updatedFieldValuesForBE;
    },
    setRecordVersionResources: (state, action: PayloadAction<Resource[]>) => {
      state.myResources = action.payload;
    }
  }
});

export const {
  clearError,
  clearIndividualResource,
  clearFilteredReport,
  updateIndividualResourceProperty,
  updateIndividualResourceType,
  updateResourceField,
  setRecordVersionResources,
  setIndividualResource,
  setIndividualResourceValues
} = resourcesSlice.actions;

export const selectIndividualResource = (state: RootState) => state.resources.individualResource;
export const selectIndividualResourceValues = (state: RootState) =>
  state.resources.IndividualResourceValues;
export const selectIndividualResourceValuesChanges = (state: RootState) =>
  state.resources.IndividualResourceValuesChanges;
export const selectFilteredResourceReport = (state: RootState) =>
  state.resources.filteredResourceReport;

export default resourcesSlice.reducer;
