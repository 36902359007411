import { ResourceField } from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";

export interface resourceFieldState {
  resourceFields: ResourceField[];
  status: "idle" | "loading" | "failed";
}
const initialState: resourceFieldState = {
  resourceFields: [],
  status: "idle"
};

const resourceFieldsSlice = createSlice({
  name: "resourceFields",
  initialState,
  reducers: {
    updateResourceFields: (state, action: PayloadAction<ResourceField[]>) => {
      state.resourceFields = action.payload;
    },
    resetResourceFields: state => {
      state.resourceFields = [];
    }
  }
});

export const { updateResourceFields, resetResourceFields } = resourceFieldsSlice.actions;

const inputSelectResourceFields = (state: RootState) => state.resourceFields;

export const selectResourceFields = createSelector(
  [inputSelectResourceFields],
  (resource): ResourceField[] => resource.resourceFields
);

export default resourceFieldsSlice.reducer;
