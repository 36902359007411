import { BaseUserFieldProps } from "enada-common";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FC, useState } from "react";
import "./readonlywrapper.scss";
import { Stack, Tooltip } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { EdisonTypography } from "enada-components";
import colors from "./../../theme/styleVariables.module.scss";
export interface ReadOnlyWrapperProps<T extends BaseUserFieldProps> {
  Component: FC<T>;
  props: T;
  keepReadOnly: boolean;
  permisssions?: any; // TODO: control accessbility with the permissions
}

const ReadOnlyWrapper = <T extends BaseUserFieldProps>({
  Component,
  props,
  keepReadOnly
}: ReadOnlyWrapperProps<T>) => {
  const [isReadOnly, setIsReadOnly] = useState(true);

  return (
    <div
      data-testid="readonly-wrapper"
      role="button"
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (isReadOnly && !keepReadOnly) setIsReadOnly(false);
      }}
      onKeyDown={e => {
        if (e.key === "Enter" && isReadOnly && !keepReadOnly) setIsReadOnly(false);
      }}
      tabIndex={0}
    >
      <fieldset
        data-testid={`${props.dataTestidValue}-fieldset`}
        className={`read-only-wrapper-root ${!keepReadOnly && "allowed"} ${
          props.required && props.value && props.value.length === 0 && "required"
        }`}
      >
        <legend>
          <Stack direction={"row"} spacing={2}>
            <EdisonTypography
              title={props.label}
              variant="inputlabel"
              sx={() => ({
                color:
                  props.required && props.value && props.value.length === 0
                    ? colors.Error
                    : "inherit"
              })}
            />
            {props.description && (
              <Tooltip title={props.description} arrow placement="right">
                <InfoOutlinedIcon fontSize="small" sx={{ color: colors.InformationIconColor }} />
              </Tooltip>
            )}
            {props.required && <div className="required-icon">*</div>}
            {keepReadOnly && (
              <LockOutlinedIcon fontSize="small" sx={{ color: colors.ReadOnlyIconColor }} />
            )}
          </Stack>
        </legend>

        <Component
          {...props}
          label=""
          autoFocus
          readOnly={isReadOnly}
          setReadOnly={setIsReadOnly}
        />
      </fieldset>
    </div>
  );
};

export default ReadOnlyWrapper;
