import {
  WorkflowStageViewField,
  WorkflowStageViewFieldState,
  WorkflowStageViewTable
} from "enada-common";
import { IconButton, Paper, Stack } from "@mui/material";
import React, { Dispatch, FC } from "react";
import { getRowVisibility, updateRowVisibility } from "../utils/handleFormVisibility";
import VisibilityToggle from "../visibilitytoggle/VisibilityToggle";
import { WorkflowLivePreviewProps } from "../WorkflowLivePreview";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import "./workflowlivepreviewtabs.scss";
export interface WorkflowLivePreviewTabProps extends WorkflowLivePreviewProps {
  currentTabIndex: number;
  setCurrentTabIndex: Dispatch<React.SetStateAction<number>>;
  toggleDisabled?: boolean;
}
const WorkflowLivePreviewTab: FC<WorkflowLivePreviewTabProps> = ({
  tabs,
  viewFields,
  viewTables,
  setViewFields,
  setViewTables,
  currentTabIndex,
  setCurrentTabIndex,
  toggleDisabled
}) => {
  const getTabVisibility = (index: number) => {
    const rowVisibilities = tabs[index].zones?.map(row =>
      getRowVisibility(row, viewFields, viewTables)
    );
    if (rowVisibilities?.every(visibility => rowVisibilities[0] === visibility)) {
      return rowVisibilities[0];
    }
    return undefined;
  };
  return (
    <Paper className="workflow-live-preview-tabs-root">
      <Stack direction="row" className="workflow-tab-container">
        <Stack direction="row" spacing={2}>
          {tabs.map((tab, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={theme => ({
                backgroundColor:
                  currentTabIndex === index ? theme.palette.primary.main : theme.palette.p12.main,
                borderRadius: "24px",
                color: theme.palette.getContrastText(
                  currentTabIndex === index ? theme.palette.primary.main : theme.palette.p12.main
                )
              })}
            >
              <Stack
                direction="row"
                spacing={1}
                className="single-tab"
                onClick={() => setCurrentTabIndex(index)}
              >
                <div>{tab.displayName}</div>
                {viewFields && viewTables && setViewFields && setViewTables && (
                  <VisibilityToggle
                    toggleDisabled={toggleDisabled}
                    value={getTabVisibility(index)}
                    onChange={changeValue => {
                      let fields: WorkflowStageViewField[] = [];
                      let tables: WorkflowStageViewTable[] = [];
                      tabs[index].zones?.forEach(row => {
                        const updated = updateRowVisibility(
                          changeValue,
                          row,
                          viewFields,
                          viewTables
                        );
                        fields = [...fields, ...updated.updatedViewFields];
                        tables = [...tables, ...updated.updatedViewTables];
                      });
                      setViewFields(prev =>
                        prev.map(
                          prevField =>
                            fields.find(field => field.fieldId === prevField.fieldId) ?? prevField
                        )
                      );
                      setViewTables(prev =>
                        prev.map(
                          prevTable =>
                            tables.find(table => table.tableId === prevTable.tableId) ?? prevTable
                        )
                      );
                    }}
                  />
                )}
              </Stack>
            </Paper>
          ))}
        </Stack>
        <Stack direction="row" spacing={1} className="tab-navigation-container">
          <IconButton
            disabled={currentTabIndex === 0}
            className={`arrow-button ${currentTabIndex !== 0 && "active"}`}
            onClick={() => setCurrentTabIndex(prev => prev - 1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
          <IconButton
            disabled={currentTabIndex === tabs.length - 1}
            className={`arrow-button ${currentTabIndex !== tabs.length - 1 && "active"}`}
            onClick={() => setCurrentTabIndex(prev => prev + 1)}
          >
            <ArrowForwardOutlinedIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default WorkflowLivePreviewTab;
