//taken from https://day.js.org/docs/en/customization/customization

export const localeObject: Record<string, any> = {
  en: {
    name: "en", // name String
    weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), // weekdays Array,
    months:
      "January_February_March_April_May_June_July_August_September_October_November_December".split(
        "_"
      ),
    ordinal: (n: any) => `${n}º`, // ordinal Function (number) => return number + output,
    relativeTime: {
      // relative time format strings, keep %s %d as the same
      future: "in %s", // e.g. in 2 hours, %s been replaced with 2hours
      past: "%s ago",
      s: "a few seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours", // e.g. 2 hours, %d been replaced with 2
      d: "1 d",
      dd: "%d d",
      M: "1 mos",
      MM: "%d mos",
      y: "1 y",
      yy: "%d ys"
    },
    formats: {
      // abbreviated format options allowing localization
      LTS: "h:mm:ss A",
      LT: "h:mm A",
      L: "MM/DD/YYYY",
      LL: "MMMM D, YYYY",
      LLL: "MMMM D, YYYY h:mm A",
      LLLL: "dddd, MMMM D, YYYY h:mm A",
      // lowercase/short, optional formats for localization
      l: "D/M/YYYY",
      ll: "D MMM, YYYY",
      lll: "D MMM, YYYY h:mm A",
      llll: "ddd, MMM D, YYYY h:mm A"
    }
  },
  pt: {
    name: "pt", // name String
    weekdays:
      "Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sabado".split("_"),
    months:
      "Janeiro_Feveiro_Marco_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro".split(
        "_"
      ),
    formats: {
      LTS: "h:mm:ss A",
      LT: "h:mm A",
      L: "MM/DD/YYYY",
      LL: "MMMM D, YYYY",
      LLL: "MMMM D, YYYY h:mm A",
      LLLL: "dddd, MMMM D, YYYY h:mm A",
      l: "D/M/YYYY",
      ll: "D MMM, YYYY",
      lll: "D MMM, YYYY h:mm A",
      llll: "ddd, MMM D, YYYY h:mm A"
    },
    ordinal: (n: any) => `${n}º`,
    relativeTime: {
      future: "en %s",
      past: "%s pasado",
      s: "1 s",
      m: "1 m",
      mm: "%d ms",
      h: "1 h",
      hh: "%d hs",
      d: "1 d",
      dd: "%d ds",
      M: "1 m",
      MM: "%d ms",
      y: "1 a",
      yy: "%d as"
    }
  }
};
