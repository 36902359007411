import React, { FC, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FilterOperation, FilterType, getFilterTypes, useLocalisation } from "enada-common";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  TextField,
  Typography
} from "@mui/material";
import BaseFilterRow from "../BaseFilterRow";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { addFilterValueChange, getDefaultValueFromOptions } from "../filterutils";
import "./edisonresourcefilter.scss";

export interface EdisonResourceFilterProps {
  filterOperations: FilterOperation[];
  activeFilterOperations?: FilterOperation[];
  viewStartDate?: Date;
  viewEndDate?: Date;
  onClose: (filters: FilterOperation[], viewStartDate: Date, viewEndDate: Date) => void;
  addFilterDisplayName: string;
  editFiltersDisplayName: string;
  applyDisplayName: string;
  propertyDisplayName: string;
  operatorDisplayName: string;
  valueDisplayName: string;
  dateRangeDisplayName: string;
  filterInfoDisplayName: string;
  filterInfoEmptyMessage: string;
  viewStartDisplayName: string;
  viewEndDisplayName: string;
  getFilterTypeDisplayName?: (filterType: FilterType) => string;
}

const EdisonResourceFilter: FC<EdisonResourceFilterProps> = ({
  filterOperations,
  activeFilterOperations,
  viewStartDate,
  viewEndDate,
  onClose,
  addFilterDisplayName,
  editFiltersDisplayName,
  applyDisplayName,
  propertyDisplayName,
  operatorDisplayName,
  valueDisplayName,
  dateRangeDisplayName,
  filterInfoDisplayName,
  filterInfoEmptyMessage,
  viewStartDisplayName,
  viewEndDisplayName,
  getFilterTypeDisplayName
}) => {
  const [open, setOpen] = React.useState(false);
  const [viewStart, setViewStart] = React.useState<Date | undefined>();
  const [viewEnd, setViewEnd] = React.useState<Date | undefined>();
  const [selectedFilters, setSelectedFilters] = React.useState<FilterOperation[] | undefined>();
  const { locale } = useLocalisation();

  useEffect(() => {
    if (activeFilterOperations && activeFilterOperations.length > 0) {
      setSelectedFilters(
        activeFilterOperations?.map((f, index) => {
          return { ...f, id: index };
        })
      );
    } else {
      addFilter();
    }
  }, [activeFilterOperations]);

  useEffect(() => {
    setViewStart(viewStartDate ?? new Date());
    setViewEnd(viewEndDate ?? new Date(new Date().setMonth(new Date().getMonth() + 3)));
  }, [viewStartDate, viewEndDate]);

  const toggleClick = () => {
    setOpen(prev => !prev);
  };

  const addFilter = () => {
    if (!open) {
      return;
    }
    let newId = 0;
    if (selectedFilters && selectedFilters.length > 0) {
      newId = (selectedFilters[selectedFilters.length - 1].id ?? 0) + 1;
    }
    setSelectedFilters([
      ...(selectedFilters ?? []),
      {
        ...filterOperations[0],
        id: newId,
        operator: getFilterTypes(filterOperations[0].propertyType)[0],
        value: getDefaultValueFromOptions(filterOperations[0])?.value
      }
    ]);
  };

  const deleteFilter = (filterOp: FilterOperation) => {
    setSelectedFilters(selectedFilters?.filter(sf => sf.id !== filterOp.id));
  };

  const applyFilters = () => {
    if (!open) {
      return;
    }
    onClose(
      selectedFilters ?? [],
      viewStart ?? new Date(),
      viewEnd ?? new Date(new Date().setMonth(new Date().getMonth() + 3))
    );
    toggleClick();
  };

  const addFilterValueChanged = (newValue: any, filterOp: FilterOperation, propName: string) => {
    addFilterValueChange(
      newValue,
      filterOp,
      propName,
      filterOperations,
      selectedFilters,
      setSelectedFilters
    );
  };

  return (
    <div className="filterWrapper">
      <Accordion expanded={open}>
        <AccordionSummary expandIcon={undefined}>
          <Stack
            className="accordionSummary"
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
              <Button
                className="editFilterButton"
                variant="outlined"
                endIcon={open ? <ArrowForwardIosIcon /> : <ExpandMoreIcon />}
                onClick={() => setOpen(prev => !prev)}
              >
                {editFiltersDisplayName}
              </Button>
              <Alert severity="info">
                {(activeFilterOperations?.length ?? 0) > 0
                  ? `${activeFilterOperations?.length} ${filterInfoDisplayName}`
                  : `${filterInfoEmptyMessage}`}
              </Alert>
            </Stack>

            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                className="generateReport"
                color="primary"
                variant="contained"
                disabled={!open}
                onClick={applyFilters}
              >
                {applyDisplayName}
              </Button>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          {selectedFilters &&
            selectedFilters.map((sf: FilterOperation, index: number) => (
              <div key={index}>
                <BaseFilterRow
                  filterOperation={sf}
                  allFilterOperations={filterOperations}
                  deleteFilter={deleteFilter}
                  addFilterValueChanged={addFilterValueChanged}
                  getFilterTypeDisplayName={getFilterTypeDisplayName}
                  propertyDisplayName={propertyDisplayName}
                  operatorDisplayName={operatorDisplayName}
                  valueDisplayName={valueDisplayName}
                  showDeleteButton={selectedFilters.length > 1}
                />
              </div>
            ))}
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Button
              className="addFilterButton"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addFilter}
            >
              {addFilterDisplayName}
            </Button>
            <Stack direction="row" spacing={1} alignItems="baseline">
              <Typography className="date-range-text" variant="body1">
                {dateRangeDisplayName}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DesktopDatePicker
                  label={viewStartDisplayName}
                  onChange={changedValue => {
                    changedValue && setViewStart(changedValue);
                  }}
                  value={viewStart}
                  views={["year", "month", "day"]}
                  renderInput={params => <TextField size="small" {...params} />}
                />
                <DesktopDatePicker
                  label={viewEndDisplayName}
                  onChange={changedValue => {
                    changedValue && setViewEnd(changedValue);
                  }}
                  value={viewEnd}
                  views={["year", "month", "day"]}
                  renderInput={params => <TextField size="small" {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EdisonResourceFilter;
export { EdisonResourceFilter };
