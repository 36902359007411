import { PersonaEntity } from "enada-common";
import { MenuItem, Select } from "@mui/material";
import React, { ReactNode } from "react";
import UserMultiChoiceField from "../user/choice/UserMultiChoiceField";
import UserDatePicker from "../user/datepicker/UserDatePickerField";
import UserPeoplePicker from "../user/peoplepicker/UserPeoplePickerField";
import UserRichTextField from "../user/richtext/UserRichTextField";

const SharedComponentMapper = (
  component: any,
  readOnly: boolean,
  isInTable: boolean,
  value?: any,
  onChange?: any,
  peopleList?: PersonaEntity[],

  useInternalState = false ///TODO Add logic depending on if we want to use internal state or not
): ReactNode => {
  switch (component.dataType) {
    // case "Switch":
    //   return (
    //     <UserSwitchField
    //       {...component.configuration}
    //       name={component.displayName}
    //       label={component.displayName}
    //       useInternalState={useInternalState}
    //       readOnly={readOnly}
    //       value={value}
    //       onChange={onChange}
    //       isInTable={isInTable}
    //     />
    //   );
    case "MultiChoice":
      return (
        <UserMultiChoiceField
          label={component.displayName}
          choices={component.configuration.choices}
          //multiLevel={true}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : [component.configuration.choices[0].value]}
          onChange={onChange}
          isInTable={isInTable}
          isMultiChoice={false}
          helperText={""}
        />
      );

    case "Date":
      return (
        <UserDatePicker
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value ? value : new Date()}
          onChange={onChange}
          isInTable={isInTable}
        />
      );
    // case "Image":
    //   return (
    //     <UserImage
    //       {...component.configuration}
    //       name={component.displayName}
    //       label={component.displayName}
    //       useInternalState={useInternalState}
    //       readOnly={readOnly}
    //       value={
    //         value ? value : { preview: "https://via.placeholder.com/150/" }
    //       }
    //       onChange={onChange}
    //       isInTable={isInTable}
    //     />
    //   );

    case "People":
      return (
        <UserPeoplePicker
          multiple={true}
          label={component.displayName}
          //placeholder={component.displayName}
          //noOptionsText={"a"}
          maxAvatars={4}
          //maxDropdownCount={10}
          //peopleList={peopleList ?? []}
          useInternalState={useInternalState}
          readOnly={readOnly}
          value={value && value[0] ? value : peopleList?.slice(0, 1)}
          isInTable={isInTable}
          onChange={onChange}
        />
      );
    case "RichText":
      return (
        <UserRichTextField
          {...component.configuration}
          name={component.displayName}
          label={component.displayName}
          useInternalState={useInternalState}
          initialValue={component.configuration.initialValue}
          readOnly={readOnly}
          value={
            value
              ? value
              : [
                  {
                    type: "paragraph",

                    children: [{ text: "A line of text in a paragraph." }]
                  }
                ]
          }
          onChange={onChange}
          isInTable={isInTable}
        />
      );

    case "Choice":
      return (
        <>
          {!readOnly ? (
            <Select
              variant="standard"
              size="medium"
              label="A"
              sx={{ height: "55px", marginLeft: "5px" }}
              // labelId="demo-simple-select-label"
              value={value ? value : 0}
              onChange={e => onChange(e.target.value)}
            >
              {component.configuration.choice.split("\n").map((column: any, index: number) => (
                <MenuItem value={index} key={index}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div>
              {isInTable ?? <h2>{component.displayName}</h2>}
              <p className="choice-readonly-text">
                {component.configuration.choice.split("\n")[0]}
              </p>
            </div>
          )}
        </>
      );
    default:
      //console.log("component", component);
      return <div> Not Implemented</div>;
  }
};

export default SharedComponentMapper;
export { SharedComponentMapper };
