import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin: any = new ReactPlugin();
//do not delete, might be needed to add click analytics
// Add the Click Analytics plug-in.
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */

const connectionString = process.env.NX_PUBLIC_INSIGHTS;

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
    // If you're adding the Click Analytics plug-in, delete the next line.
    extensions: [reactPlugin],
    // Add the Click Analytics plug-in.
    // extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      // [reactPlugin.identifier]: { history: browserHistory }
      // Add the Click Analytics plug-in.
      // [clickPluginInstance.identifier]: clickPluginConfig
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    // Setting this to true breaks location field
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();
