import { useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { apiConfig, loginRequest, msalConfig } from "../config/authConfig";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Providers } from "@microsoft/mgt-react";
import { getTenantAndInstanceFromURL } from "../utils/urlHelpers";
import UnlicensedUser from "../pages/error/UnlicensedUser";

// Initialize msalInstance once outside of the component to avoid recreation on every render
const msalInstance = new PublicClientApplication(msalConfig);

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance
}) as any;

const AuthenticationService = ({ children }) => {
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { tenant, instance } = getTenantAndInstanceFromURL();
  const [showResponse, setShowResponse] = useState(false); // when this is true, we can show an error message.

  const getTId = async () => {
    if (!isAuthenticated) {
      const accounts = msalInstance.getAllAccounts();
      console.log("Accounts:", accounts);
      for (let index = 0; index < accounts.length; index++) {
        const account = accounts[index];
        msalInstance.setActiveAccount(account);

        const tenantAuthority = `https://login.microsoftonline.com/${account.tenantId}`;
        console.log("Acquiring token silently for tenant:", tenantAuthority);
        let silentTokenResponse: any = null;
        try {
          msalInstance.getConfiguration().auth.authority = tenantAuthority;
          silentTokenResponse = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            authority: tenantAuthority,
            forceRefresh: true
          });
        } catch (error) {
          console.log("Silent token acquisition failed:", error);
          msalInstance.acquireTokenRedirect({ ...loginRequest, authority: tenantAuthority });
        }

        if (silentTokenResponse) {
          console.log("silentTokenResponse", silentTokenResponse.tenantId);

          const fetchRes = await fetch(`${apiConfig.managementApi}/tenants/microsofttenantid`, {
            headers: {
              "Access-Control-Max-Age": "86400",
              Authorization: `Bearer ${silentTokenResponse.accessToken}`,
              "edison365-tenant": tenant,
              "edison365-instance": instance,
              "Cache-Control": "no-cache"
            }
          });

          if (fetchRes.ok) {
            const data = await fetchRes.json();
            if (data) {
              return data.data;
            }
          }
        }
      }
    }

    console.log("No tenantId found");
    return null;
  };

  useEffect(() => {
    setShowResponse(false);
    msalInstance
      .handleRedirectPromise()
      .then(async response => {
        if (!isAuthInProgress) {
          if (!tenantId) {
            setIsAuthInProgress(true); // Start tenant-specific authentication
            const tenantId = await getTId();
            if (tenantId) {
              console.log("Authenticated with tenantId:", tenantId);
              setIsAuthenticated(true);
              setTenantId(tenantId);
              setIsAuthInProgress(false); // Authentication completed
            } else {
              if (msalInstance.getActiveAccount() === null)
                msalInstance.loginRedirect({
                  ...loginRequest,
                  prompt: "login"
                });
              else setShowResponse(true);
            }
          }
        }
      })
      .catch(error => {
        console.error("Error handling redirect:", error);
        setIsAuthInProgress(false);
      });
  }, []);

  if (isAuthenticated && tenantId) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else if (showResponse && !tenantId) {
    return <UnlicensedUser />;
  } else {
    return null;
  }
};

export { msalInstance };
export default AuthenticationService;
