import { Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectIndividualField,
  updateIndividualFieldProperty
} from "../../../store/slices/fieldsSlice";
import "./textconfig.scss";

const TextBoxConfig = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const individualField = useAppSelector(selectIndividualField);

  const updateConfiguration = (key: string, value: number | string | boolean) => {
    dispatch(
      updateIndividualFieldProperty({
        key: "configuration",
        value: {
          ...individualField?.configuration,
          [key]: value
        }
      })
    );
  };

  return (
    <Stack spacing={3} className="text-config-root">
      <Stack spacing={1}>
        <Stack direction="row" className="bounds-container">
          <TextField
            className="input"
            size="small"
            placeholder={t("minimumLength")}
            type="number"
            inputProps={{
              min: 0
            }}
            variant="outlined"
            value={individualField?.configuration?.minimumLength ?? null}
            onChange={e => updateConfiguration("minimumLength", e.target.value)}
          />
          <TextField
            className="input"
            size="small"
            placeholder={t("maximumLength")}
            type="number"
            variant="outlined"
            value={individualField?.configuration?.maximumLength ?? null}
            inputProps={{
              min: 0
            }}
            onChange={e => updateConfiguration("maximumLength", e.target.value)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TextBoxConfig;
