import { b64ToBlob, BatchRequest, PersonaEntity, PermissionType } from "enada-common";
import { Group, User } from "@microsoft/microsoft-graph-types";
import { t } from "i18next";
import { graphConfig } from "../config/authConfig";
import { mapGraphPersonaToPersonaEntity } from "../utils/mapGraphPersonaToPersonaEntity";
import { postRequestMsGraph } from "./APIService";

export interface GraphPersonaCollection {
  type: PermissionType;
  graphPersonas: User[] | Group[];
}

export interface GraphPersonaImage {
  id: string;
  value: string;
}

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callMsGraphGet(
  accessToken: any,
  queryString: string,
  extraHeaders?: Headers
) {
  const headers = new Headers(extraHeaders);
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(`${graphConfig.graphEndpoint}/${queryString}`, options)
    .then(response => response)
    .catch(error => console.log(error));
}

export async function callMsGraphPost(accessToken: any, queryString: string, body: any) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: body
  };

  return fetch(`${graphConfig.graphEndpoint}/${queryString}`, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export const getUsersAndGroupsByDisplayName = async (searchString: string) => {
  const batchedResponse = await postRequestMsGraph(
    "$batch",
    JSON.stringify({
      requests: [
        {
          id: "Users",
          method: "GET",
          url: `/Users?$top=5&$select=displayName,userPrincipalName,id,userType&$search="displayName:${searchString}"`,
          headers: { consistencyLevel: "eventual" }
        },
        {
          id: "Groups",
          method: "GET",
          url: `/Groups?$top=5&$select=displayName,id&$search="displayName:${searchString}"&$filter=groupTypes/any(c:c eq 'Unified') OR securityEnabled eq true`,
          headers: { consistencyLevel: "eventual" }
        }
      ]
    })
  );

  const personas: GraphPersonaCollection[] = batchedResponse.responses
    .filter((response: any) => response.status === 200)
    .map((singleResponse: any) => {
      return {
        graphPersonas: singleResponse.body.value,
        type: singleResponse.id === "Users" ? PermissionType.User : PermissionType.Group
      };
    });

  return personas;
};

export const mapPersonas = (graphPersonaCollection: GraphPersonaCollection[]) => {
  const groupsTitle = t("groups");
  const usersTitle = t("users");
  const personas: PersonaEntity[] = [];
  graphPersonaCollection.forEach((personaCollection: GraphPersonaCollection) => {
    personas.push(
      ...personaCollection.graphPersonas.map(persona => {
        return mapGraphPersonaToPersonaEntity(
          persona,
          personaCollection.type,
          usersTitle,
          groupsTitle
        );
      })
    );
  });

  return personas.sort(p => (p.type === PermissionType.Group ? 1 : -1));
};

export const getPersonaImages = async (personas: PersonaEntity[]) => {
  if (personas.length < 1) return [];
  const photosRequests: BatchRequest[] = [];
  personas.forEach((persona: PersonaEntity) => {
    photosRequests.push({
      id: persona.entityId,
      method: "GET",
      url: `/${persona.type === PermissionType.User ? "Users" : "Groups"}/${persona.entityId
        }/photos/48x48/$value`
    });
  });

  const batchedResponse = await postRequestMsGraph(
    "$batch",
    JSON.stringify({ requests: photosRequests })
  );

  const win = window.URL || window.webkitURL;
  return batchedResponse.responses
    .filter((response: any) => response.status === 200)
    .map((single: any) => {
      const blob = b64ToBlob(`data:image/png;base64,${single.body}`);
      const url = win.createObjectURL(blob);
      return { id: single.id, value: url } as GraphPersonaImage;
    }) as GraphPersonaImage[];
};

export const getUsersAndGroupsByIds = async (usersIds?: string[], groupsIds?: string[]) => {
  const requests: SingleRequest[] = [];

  if (groupsIds?.length) {
    const uniqueGroupsIds = new Set(usersIds);
    requests.push({
      id: "Groups",
      method: "GET",
      url: `/Groups?$select=displayName,id&$filter=Id in (${[...uniqueGroupsIds]
        .map(id => `'${id}'`)
        .join(",")})`
    });
  }

  if (usersIds?.length) {
    const uniqueUserIds = new Set(usersIds);
    requests.push({
      id: "Users",
      method: "GET",
      url: `/Users?$select=displayName,userPrincipalName,id,userType&$filter=Id in (${[
        ...uniqueUserIds
      ]
        .map(id => `'${id}'`)
        .join(",")})`
    });
  }

  if (!requests.length) return;

  const batchedResponse = await postRequestMsGraph(
    "$batch",
    JSON.stringify({
      requests
    })
  );

  const personas: GraphPersonaCollection[] = batchedResponse.responses
    .filter((response: any) => response.status === 200)
    .map((singleResponse: any) => {
      return {
        graphPersonas: singleResponse.body.value,
        type: singleResponse.id === "Users" ? PermissionType.User : PermissionType.Group
      };
    });

  return personas;
};

interface SingleRequest {
  method: string;
  id: string;
  url: string;
}
export enum GraphQueryType {
  Users = "Users",
  Groups = "Groups"
}
export const batchGetSingleEntitiesById = async (
  ids: string[],
  queryType: GraphQueryType
) => {
  //Function that retrieves user info one by one by id (useful for guest users that are not allowed to filter multiple users by id)
  const batchRequests: SingleRequest[][] = [];
  const requestsPerBatch = 20;
  const uniqueUserIds = new Set(ids);
  const seperated = [...uniqueUserIds].reduce((acc, curr, index) => {
    if (index % requestsPerBatch === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(curr);
    return acc;
  }, [] as Array<Array<string>>);

  //Seperate ids into batches of requests,
  seperated.forEach(batch => {
    const currentRequest: SingleRequest[] = [];

    batch.forEach((el, index) => {
      if (index < requestsPerBatch) {
        currentRequest.push({
          id: index.toString(),
          method: "GET",
          url: `/${queryType}/${el}`
        });
      }
    });
    batchRequests.push(currentRequest);
  });
  const results = batchRequests.map(async req => {
    const batchedResponse = await postRequestMsGraph(
      "$batch",
      JSON.stringify({
        requests: req
      })
    );

    const people = batchedResponse.responses
      .filter((response: any) => response.status === 200)
      .map((response: any) => response?.body)
      .flat();
    return people;
  });
  const finalResults = await Promise.all(results);
  return finalResults?.flat() || [];
};

export const batchGetEntitiesById = async (
  ids: string[],
  queryType: GraphQueryType
): Promise<Record<string, Group | User>> => {
  const batchRequests: SingleRequest[][] = [];
  const requestsPerBatch = 20;
  const entitiesPerRequest = 15;
  const totalPerBatch = requestsPerBatch * entitiesPerRequest;

  const uniqueUserIds = new Set(ids);
  const seperated = [...uniqueUserIds].reduce((acc, curr, index) => {
    if (index % totalPerBatch === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(curr);
    return acc;
  }, [] as Array<Array<string>>);

  //Seperate ids into batches of requests,
  seperated.forEach(batch => {
    const currentRequest: SingleRequest[] = [];

    batch.forEach((_, index) => {
      if (index % entitiesPerRequest === 0) {
        currentRequest.push({
          id: index.toString(),
          method: "GET",
          url: `/${queryType}?$filter=Id in (${[
            ...batch.slice(
              index,
              index + entitiesPerRequest > batch.length ? batch.length : index + entitiesPerRequest
            )
          ]
            .map(id => `'${id}'`)
            .join(",")})`
        });
      }
    });
    batchRequests.push(currentRequest);
  });

  const normalised: Record<string, User | Group> = await batchRequests.reduce(async (acc, req) => {
    const batchedResponse = await postRequestMsGraph(
      "$batch",
      JSON.stringify({
        requests: req
      })
    );

    const people = batchedResponse.responses
      .filter((response: any) => response.status === 200)
      .map((response: any) => [...response.body.value])
      .flat()
      .reduce(
        (acc: any, curr: User | Group) => ({
          ...acc,
          [curr?.id ?? ""]: curr
        }),
        {}
      );
    return { ...(await acc), ...people };
  }, {});
  return normalised;
};

export function setPersonasImage(personas: PersonaEntity[], personaImages: GraphPersonaImage[]) {
  personas = personas.map((persona: PersonaEntity) => {
    const image = personaImages.find(i => i.id === persona.entityId);
    if (image) {
      persona.imageUrl = image?.value;
    }
    return persona;
  });

  return personas;
}
