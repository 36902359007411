import { graphAPI } from "services/graphAPI";
import { calendarsAPI } from "./calendars";
import { cardsAPI } from "./cards";
import { fieldsAPI } from "./fields";
import { formsAPI } from "./forms";
import { licensesAPI } from "./licenses";
import { manageUsersAPI } from "./manageUsers";
import { personalDataAPI } from "./personalData";
import { recordsAPI } from "./records";
import { recycledRecordsAPI } from "./recycledRecords";
import { tablesAPI } from "./tables";
import { templatesAPI } from "./templates";
import { userAPI } from "./user";
import { workflowsAPI } from "./workflows";
import { newsAPI } from "./news";
import { personalAPI } from "./personal";
import { resourcesAPI } from "./resources";
import { recordAPI } from "./record";

export const {
  useCreateCalendarMutation,
  useDeleteCalendarsMutation,
  useGetCalendarQuery,
  useGetCalendarsQuery,
  useUpdateCalendarMutation,
  useRecycleCalendarsMutation,
  useRestoreCalendarsMutation
} = calendarsAPI;

// Cards endpoints
export const {
  useGetCardsQuery,
  useGetCardQuery,
  useCreateCardMutation,
  useUpdateCardMutation,
  useDeleteCardsMutation,
  useRecycleCardsMutation,
  useRestoreCardsMutation
} = cardsAPI;

// Fields endpoints
export const {
  useCreateFieldMutation,
  useDeleteFieldsMutation,
  useGetFieldQuery,
  useGetFieldsQuery,
  useUpdateFieldMutation,
  useRecycleFieldsMutation,
  useRestoreFieldsMutation
} = fieldsAPI;

/// Forms endpoints
export const {
  useCreateFormMutation,
  useDeleteFormsMutation,
  useGetFormQuery,
  useGetFormsQuery,
  useUpdateFormMutation,
  useRecycleFormsMutation,
  useRestoreFormsMutation
} = formsAPI;

// Licenses endpoints
export const {
  useDeleteLicenseMutation,
  useGetLicenseSettingsQuery,
  useGetLicensesQuery,
  useGetTenantLicenseQuery,
  useUpdateLicensesMutation
} = licensesAPI;

// ManageUsers endpoints
export const {
  useGetAIEnabledQuery,
  useGetEdisonAccessToInstanceQuery,
  useGetPermissionsByRoleQuery,
  useGetTenantAdminsQuery,
  useUpdateAIEnabledOnInstanceMutation,
  useUpdateEdisonAllowedOnInstanceMutation,
  useUpdatePermissionsMutation,
  useUpdateTenantAdminsMutation
} = manageUsersAPI;

// Personal endpoints
export const {
  useGetApprovalsQuery,
  useGetDashboardItemsQuery,
  useGetFavouritesQuery,
  useGetOwnedItemsQuery,
  useGetMyWorkItemQuery,
  useGetMyWorkSavedItemQuery,
  useGetMyWorkSavedTaskQuery,
  useGetMyWorkTaskQuery,
  useGetPersonalTasksQuery,
  useSavePendingRecordViewTableValuesMutation,
  useRecallPendingRowMutation,
  useUpdateTaskChecklistReviewMutation
} = personalAPI;

// Personal Data endpoints
export const {
  useGetUserDetailsQuery,
  useGetUserDetailsSyncStatusQuery,
  useSyncUserDetailsStatusMutation,
  useRecycleUserDetailsMutation,
  useRestoreUserDetailsMutation
} = personalDataAPI;

// Record endpoints
export const { useGetRecordAccessTokenQuery } = recordAPI;

// Records endpoints
export const { useContinueRecordWorkflowMutation, useGetDistinctValuesQuery } = recordsAPI;

// Resources endpoints
export const {
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useUpdateResourceFieldsMutation,
  useUpdateResourceFieldValuesMutation,
  useGetResourceQuery,
  useGetResourceFieldsQuery,
  useGetResourceValuesQuery,
  useGetResourcesQuery,
  useGetResourceManagersQuery,
  useRecycleResourcesMutation,
  useDeleteResourcesMutation,
  useRestoreResourcesMutation,
  useGetMyResourcesQuery,
  useGetTasksQuery
} = resourcesAPI;

// RecycleBin endpoints
export const {
  useDeleteRecycledRecordsMutation,
  useGetRecycledRecordsQuery,
  useGetRecycledRecordsCountQuery,
  useRestoreRecyledRecordsMutation
} = recycledRecordsAPI;

// Tables endpoint
export const {
  useCreateTableMutation,
  useDeleteTablesMutation,
  useGetTableQuery,
  useGetTablesQuery,
  useUpdateTableMutation,
  useRecycleTablesMutation,
  useRestoreTablesMutation
} = tablesAPI;

// Templates endpoints
export const {
  useCreateTemplateMutation,
  useCreateTemplateImageMutation,
  useDeleteTemplatesMutation,
  useUpdateTemplateMutation,
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useRecycleTemplatesMutation,
  useRestoreTemplatesMutation
} = templatesAPI;

// User endpoints
export const {
  useGetCdnTokenQuery,
  useGetModulesQuery,
  useGetMyRolesQuery,
  useGetMyLicenseQuery,
  useGetRegionQuery,
  usePostUserDetailsMutation
} = userAPI;

// Workflow endpoints
export const {
  useCreateWorkflowMutation,
  useDeleteWorkflowsMutation,
  useGetWorkflowQuery,
  useGetWorkflowsQuery,
  useGetWorkflowStagesQuery,
  useUpdateWorkflowMutation,
  useRecycleWorkflowsMutation,
  useRestoreWorkflowsMutation,
  useSimulateExpressionMutation
} = workflowsAPI;

// Graph API
export const { useGetUserQuery, useGetUserPhotoQuery, useRequestMsGraphMutation } = graphAPI;

// News endpoints
export const {
  useGetLiveNewsQuery,
  useGetNewsQuery,
  useGetDetailsNewsQuery,
  useDeleteNewsMutation,
  useRecycleNewsMutation,
  useRestoreNewsMutation,
  useUpdateNewsMutation,
  useCreateNewsMutation,
  useCreateNewsImageMutation,
  useUpdateOrderNewsMutation
} = newsAPI;
