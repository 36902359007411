import { ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import React, { FC, ReactNode, useState } from "react";
import "./expandablemenu.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { ListSection, ListSectionItem } from "enada-common";
import EdisonTypography from "../typography/EdisonTypography";

export interface EdisonExpandableMenuProps extends ListSection {
  children?: ReactNode;
  sticky: boolean;
  onItemClick?: (item: ListSectionItem) => void;
  noSidePadding?: boolean;
}
const EdisonExpandableMenu: FC<EdisonExpandableMenuProps> = ({
  name,
  icon,
  sticky,
  children,
  data,
  onItemClick,
  noSidePadding
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Stack className="expandable-menu-root" data-testid={"edison-expandable-menu"}>
      <ListItemButton
        data-testid={"edison-expandable-menu-buttons-item"}
        className={`expandable-menu ${noSidePadding && "no-side-padding"}`}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        sx={{
          pt: 1
        }}
      >
        {icon && <ListItemIcon className="item-icon-header">{icon}</ListItemIcon>}
        <ListItemText
          primary={<EdisonTypography variant="dropdownsubheader" title={name} />}
          className="item-text-header"
        />
        <ArrowDropDownIcon
          className="item-icon-header"
          sx={{
            transform: isExpanded ? "rotate(-180deg)" : "rotate(0)"
          }}
        />
      </ListItemButton>

      {isExpanded &&
        (children ||
          data?.map((item: ListSectionItem) => (
            <ListItemButton
              data-testid={"edison-expandable-menu-buttons-item"}
              key={item.label}
              disabled={item.disabled}
              className="item-container"
              onClick={() => onItemClick && onItemClick(item)}
            >
              <ListItemText
                primary={<EdisonTypography variant="data2" title={item.label} />}
                className="item-text"
              />
              {item.icon && <ListItemIcon className="item-icon">{item.icon}</ListItemIcon>}
            </ListItemButton>
          )))}
    </Stack>
  );
};

export default EdisonExpandableMenu;
export { EdisonExpandableMenu };
