import { useTheme } from "@mui/material";

import Images from "../image/Image";
import { NodeBaseProps } from "../nodeProps.model";
import "./block.scss";

export interface BlockProps extends NodeBaseProps {
  element: any;
}

const Block = (props: BlockProps): JSX.Element => {
  const { attributes, children, element } = props;
  const theme = useTheme();

  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          className={theme.palette.mode === "dark" ? "rich-text-link-dark-mode" : ""}
        >
          {children}
        </a>
      );
    case "indented":
      return (
        <div {...attributes} className="rich-text-block-indent">
          {children}
        </div>
      );
    case "image":
      return <Images {...props}></Images>;
    case "div":
      return <div {...attributes}>{children}</div>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Block;
