import { nanoid } from "nanoid";
import { Node, XYPosition } from "reactflow";
import { maxNodeWidth, minNodeWidth } from "./FrontendWorkflow.constants";
import { RecordType, WorkflowNodeConfiguration, WorkflowStageEditAccess } from "enada-common";

export const getNewNode = (
  option: WorkflowNodeConfiguration,
  position: XYPosition,
  index: number
): Node => {
  const id = `dndnode_${index}`;

  return {
    id,
    type: option.type,
    position,
    data: {
      id,
      displayName: `${option.type} node ${index}`,
      configuration: option,
      name: nanoid(),
      editAccess: getEditAccess(option),
      allowRecordCreation: option.recordType === RecordType.Challenges,
    },
    style: getNodeStyles()
  };
};

export const getNodeStyles = () => ({
  minWidth: minNodeWidth,
  maxWidth: maxNodeWidth
});

export const getEditAccess = (option: Partial<WorkflowNodeConfiguration>) => {
  if (!option.type) return;
  if (["Stage", "StageReview"].includes(option.type)) {
    if (option.recordType === RecordType.Ideas || option.recordType === RecordType.Challenges) {
      return WorkflowStageEditAccess.None; // 0
    } else {
      return WorkflowStageEditAccess.Editors | WorkflowStageEditAccess.Owners; // 5
    }
  }
};
