import { RecordType } from "enada-common";
import { TFunction } from "i18next";

export function getNameForModule(moduleType: string, t: TFunction) {
  switch (moduleType) {
    case "projects":
      return t("project");
    case "businessCase":
      return t("businessCase");
    default:
      return moduleType;
  }
}

export function getNameForRecordType(recordType: RecordType, t: TFunction) {
  switch (recordType) {
    case RecordType.Projects:
      return t("project");
    case RecordType.BusinessCase:
      return t("businessCase");
    case RecordType.Ideas:
      return t("idea");
    case RecordType.Challenges:
      return t("challenge");
    case RecordType.Programs:
      return t("program");
    default:
      return recordType;
  }
}
