import { WorkItemApproval, WorkflowReviewState, WorkflowTaskState } from "enada-common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface personalState {
  approvals: WorkItemApproval[];
}

const initialState: personalState = {
  approvals: []
};

export const personalSlice = createSlice({
  name: "personal",
  initialState,
  reducers: {
    setApprovals: (state, action: PayloadAction<WorkItemApproval[]>) => {
      state.approvals = action.payload;
    },
    updateApprovalTask: (
      state,
      action: PayloadAction<{
        recordId?: number;
        taskId?: number;
        newState?: WorkflowTaskState;
      }>
    ) => {
      const { recordId, taskId, newState } = action.payload;
      state.approvals = state.approvals.map(approval =>
        approval.recordId === recordId
          ? {
              ...approval,
              reviewTasks: approval.reviewTasks?.map(task =>
                task.taskId === taskId ? { ...task, taskState: newState } : task
              )
            }
          : approval
      );
    },
    updateApprovalReviewState: (
      state,
      action: PayloadAction<{
        recordId?: number;
        newState: WorkflowReviewState;
        comments: string;
      }>
    ) => {
      const { recordId, newState, comments } = action.payload;
      state.approvals = state.approvals.map(approval =>
        approval.recordId === recordId ? { ...approval, reviewState: newState, comments } : approval
      );
    },
    removeApproval: (state, action: PayloadAction<WorkItemApproval>) => {
      if (action.payload === undefined) return;
      const { recordId, itemType, stageId } = action.payload;
      state.approvals = state.approvals.filter(
        approval =>
          approval.itemType === itemType &&
          approval.recordId === recordId &&
          approval.stageId === stageId
      );
    }
  }
});

export const { setApprovals, updateApprovalTask, updateApprovalReviewState, removeApproval } =
  personalSlice.actions;

export const selectApprovals = (state: { personal: personalState }) => state.personal.approvals;

export default personalSlice.reducer;
