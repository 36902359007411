import { Calendar } from "enada-common";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const defaultCalendar: Calendar = {
  id: 0,
  name: "",
  displayName: ""
};

export interface calendarsState {
  value: number;
  calendars: Array<Calendar>;
  individualCalendar: Calendar | Partial<Calendar>;
  lastModifiedUpdated: boolean;
}

const initialState: calendarsState = {
  value: 0,
  calendars: [],
  individualCalendar: defaultCalendar,
  lastModifiedUpdated: false
};

export const calendarsSlice = createSlice({
  name: "calendars",
  initialState,

  reducers: {
    setCalendars: (state, action: PayloadAction<Calendar[]>) => {
      state.calendars = action.payload;
    },
    clearIndividualCalendar: state => {
      state.individualCalendar = defaultCalendar;
    },

    updateIndividualCalendar: (state, action) => {
      state.individualCalendar = action.payload;
    },
    updateIndividualCalendarProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Calendar }>
    ) => {
      state.individualCalendar = {
        ...state.individualCalendar,
        [action.payload.key]: action.payload.value
      };
    },
    setRecordVersionCalendars: (state, action: PayloadAction<Calendar[]>) => {
      if (!action.payload) {
        state.calendars = [];
        return;
      }
      state.calendars = action.payload;
    }
  }
});

const inputSelectCalendars = (state: RootState) => state.calendars;

export const selectAllCalendars = createSelector(
  [inputSelectCalendars],
  (calendar): Calendar[] => calendar.calendars
);

export const selectIndividualCalendar = createSelector(
  [inputSelectCalendars],
  calendar => calendar.individualCalendar
);

export const {
  setCalendars,
  clearIndividualCalendar,
  updateIndividualCalendar,
  updateIndividualCalendarProperty,
  setRecordVersionCalendars
} = calendarsSlice.actions;

export default calendarsSlice.reducer;
