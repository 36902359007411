import React, { FC, useState, useEffect } from "react";
import "./edisonparenttabs.scss";
import { EdisonTabsProps } from "enada-common";
import { Stack } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EdisonTypography from "../typography/EdisonTypography";
export interface EdisonParentTabProps {
  data: EdisonParentTab[];
}

interface EdisonParentTab {
  id: number;
  tabTitle: string;
  tabContent: React.ReactNode;
}

const EdisonParentTabs: FC<EdisonTabsProps> = ({ tabs, triggerUnfocus = false, endTab }) => {
  const [visibleTab, setVisibleTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setVisibleTab(newValue);
  };
  const removeFocus = () => setVisibleTab(0);

  useEffect(() => {
    if (triggerUnfocus) removeFocus();
  }, [triggerUnfocus]);

  const listTitles = tabs?.map((item, index) => (
    <Tab
      key={index}
      onClick={() => setVisibleTab(index)}
      label={
        <span className="tab-title-label" title={(item?.label as string).toLowerCase()}>
          {item?.required ? (
            <Stack direction="row">
              <EdisonTypography title={item.label as string} variant="data" enableUppercase />
              <div style={{ color: "red" }}> &nbsp; *</div>
            </Stack>
          ) : (
            item?.label
          )}
        </span>
      }
      className="tab-title"
    />
  ));

  const listContent = (endTab ? [...tabs, endTab] : tabs).map((item, index) => {
    if (item?.includesTable) {
      return visibleTab === index ? <div key={index}>{item.children}</div> : null;
    } else {
      return (
        <div key={index} style={visibleTab === index ? {} : { display: "none" }}>
          {item.children}
        </div>
      );
    }
  });

  return (
    <div className="tabs">
      <Stack direction={"row"} className="tab-titles-container">
        <Tabs
          value={visibleTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable navigation tabs"
          className="tabs-titles"
        >
          {listTitles}
        </Tabs>
        {endTab && (
          <Stack direction="row">
            <Stack
              className="end-tab"
              sx={theme => ({
                color:
                  visibleTab === tabs.length
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                borderBottom:
                  visibleTab === tabs.length
                    ? `2px solid ${theme.palette.primary.main}`
                    : `2px solid transparent`
              })}
              onClick={() => {
                setVisibleTab(tabs.length);
              }}
            >
              {endTab.label}
            </Stack>
          </Stack>
        )}
      </Stack>
      <div className="tab-content">{listContent}</div>
    </div>
  );
};

export default EdisonParentTabs;
export { EdisonParentTabs };
