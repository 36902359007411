import { getRequestMsGraph } from "../../services/APIService";
import { IDynamicPerson } from "@microsoft/mgt-react";

export const getGraphDynamicPersonByIds = async (ids: string, graphQueryType: string) => {
  if (!ids) return;

  //The below MS Graph API call has a maximum number of ids that can be passed in the filter
  //When using the OR operator, the maximum is 15

  //START logic to split ids
  const idsArray = ids.split(",");
  const maxIds = 15;

  const res = idsArray.reduce((acc: any, curr, i: any) => {
    if (!(i % maxIds)) acc.push(idsArray.slice(i, i + maxIds));
    return acc;
  }, []);
  //END logic to split ids

  // Send a request for each array of ids
  const userResults = res.map((idArray: any) =>
    getRequestMsGraph(`${graphQueryType}?$filter=id in (${idArray.join(",")})`)
  );

  // Wait for all requests to finish
  const resultArray = await Promise.all(userResults);

  // If any of the requests failed, return
  if (resultArray[0].status !== 200) return;

  // Combine the results of each array of ids returned by the requests
  const combinedGraphResult = await resultArray.reduce(async (acc, result) => {
    const people = await result.json();

    const jointPeople = await acc;
    return jointPeople.concat(people.value as IDynamicPerson[]);
  }, []);

  return combinedGraphResult;
};
