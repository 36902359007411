import { Box, Popover, Stack } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createEditor, Descendant } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { BaseUserFieldProps, useDebounce } from "enada-common";
import Block, { BlockProps } from "./nodes/block/Block";
import Leaf, { LeafProps } from "./nodes/leaf/Leaf";
import RichTextFieldToolbar from "./toolbar/UserRichTextFieldToolbar";
import { indent, outdent, toggleMark } from "../../utils/richtext/richTextUtils";
import withImages from "../../utils/richtext/withImages";
import withLinks from "../../utils/richtext/withLink";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import "./userrichtextfield.scss";
import { v4 } from "uuid";

const UserRichTextField: FC<BaseUserFieldProps> = ({
  value,
  label,
  onChange,
  readOnly,
  isInTable,
  setReadOnly,
  autoFocus
}) => {
  const [blurId, setBlurId] = useState(v4());
  const renderElement = useCallback((props: BlockProps) => <Block {...props} />, []);
  const renderLeaf = useCallback(
    (props: LeafProps) => <Leaf {...props} isInTable={isInTable} />,
    []
  );
  const editor = useMemo(() => withLinks(withImages(withHistory(withReact(createEditor())))), []);
  const initialState =
    value && value.length
      ? value
      : [
          {
            type: "div",
            children: [{ text: " " }]
          }
        ];

  const [internalState, setInternalState] = useState<Descendant[]>(initialState);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const popOverAnchor = useRef<any>();
  const editorRef = useRef<any>();

  useEffect(() => {
    setInternalState(
      value && value.length
        ? value
        : [
            {
              type: "div",
              children: [{ text: " " }]
            }
          ]
    );
    setBlurId(v4());
  }, [value]);

  useDebounce(
    () => {
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState);
    },
    1000,
    [internalState]
  );

  useEffect(() => {
    if (!isInTable) return;
    if (readOnly) return;
    setAnchorEl(popOverAnchor.current);
  }, [readOnly]);

  const getEditor = () => {
    return (
      <Stack
        ref={editorRef}
        onBlur={e => {
          if (!setReadOnly) return;
          if (
            e.relatedTarget?.parentElement?.id &&
            (e.relatedTarget.id.includes(blurId) ||
              e.relatedTarget.parentElement.id.includes(blurId))
          ) {
            return;
          }

          if (e.relatedTarget?.id && e.relatedTarget.id.includes(blurId)) {
            ReactEditor.focus(editor);
            return;
          }

          if (!e.target?.id || !e.target.id.includes(blurId)) {
            // Needed to pass focus back to editor after a dialog is opened and then closed
            // (editorRef.current?.lastChild as HTMLInputElement).focus();
            ReactEditor.focus(editor);
            return;
          }
          setReadOnly(true);
          if (!isInTable) return;
          if (!onChange) return;
          onChange(internalState);
        }}
      >
        <RichTextFieldToolbar blurId={blurId} />
        {!isInTable && <EdisonTypography title={label} variant={"fieldtitle"} />}
        <Editable
          id={blurId}
          autoFocus={isInTable ? false : autoFocus}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          className={`slate-editor ${isInTable ? "intable-editor" : ""}`}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault();
              e.shiftKey ? outdent(editor) : indent(editor, "indented");
              return;
            }
            if (e.key === "Escape") {
              e.preventDefault();
              setReadOnly?.(true);
            }
            if (!e.ctrlKey) {
              return;
            }
            switch (e.key) {
              case "b":
                e.preventDefault();
                toggleMark(editor, "bold");
                break;
              case "i":
                e.preventDefault();
                toggleMark(editor, "italic");
                break;
              case "u":
                e.preventDefault();
                toggleMark(editor, "underline");
                break;
              default:
                break;
            }
          }}
          onPaste={e => {
            e.preventDefault();
            editor.insertText(e.clipboardData.getData("Text"));
          }}
        />
      </Stack>
    );
  };

  return (
    <Slate
      key={blurId}
      editor={editor}
      initialValue={internalState}
      onValueChange={newValue => {
        const isAstChange = editor.operations.some(op => "set_selection" !== op.type);

        if (isAstChange) setInternalState(newValue);
      }}
    >
      <div ref={popOverAnchor} />
      {!readOnly ? (
        <>
          {isInTable ? (
            <>
              <Box className="popover-anchor">
                <Editable
                  readOnly
                  className=" slate-editor richtext-output"
                  style={{ whiteSpace: "nowrap" }}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  onPaste={e => {
                    e.preventDefault();
                    editor.insertText(e.clipboardData.getData("Text"));
                  }}
                />
              </Box>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                onClose={() => {
                  setAnchorEl(null);
                  setReadOnly && setReadOnly(true);
                }}
              >
                {getEditor()}
              </Popover>
            </>
          ) : (
            getEditor()
          )}
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center"
          }}
        >
          {!isInTable && (
            <Stack direction="row">
              <EdisonTypography title={label} variant="fieldtitle" />
            </Stack>
          )}
          <Editable
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            className={`slate-editor ${isInTable ? "richtext-output" : undefined}`}
            style={isInTable ? { whiteSpace: "nowrap" } : undefined}
            onPaste={e => {
              e.preventDefault();
              editor.insertText(e.clipboardData.getData("Text"));
            }}
            onKeyDown={e => {
              console.log("NKJBLK");
            }}
          />
        </Box>
      )}
    </Slate>
  );
};

export default UserRichTextField;
export { UserRichTextField };
