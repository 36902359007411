import { useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Images from "../image/Image";
import { NodeBaseProps } from "../nodeProps.model";
import "./block.scss";
export interface BlockProps extends NodeBaseProps {
  element: any;
}
const Block = (props: BlockProps): JSX.Element => {
  const { attributes, children, element } = props;
  const theme = useTheme();

  const getFirstLeafFontSize = (child?: any): number | undefined => {
    if (child?.children === undefined) return undefined;

    const firstChild = child.children[0];
    if (firstChild === undefined) return undefined;

    if (Object.hasOwn(firstChild, "fontSize")) {
      return firstChild.fontSize;
    }

    // Get the second child because when adding a bulleted list or numbered list for the first time
    // the first child is an empty string e.g. {text: " "}
    if (firstChild.text === " ") {
      const secondChild = child.children[1];
      if (secondChild === undefined) return undefined;
      if (Object.hasOwn(secondChild, "fontSize")) {
        return secondChild.fontSize;
      }
    }

    if (firstChild.children === undefined) return undefined;

    // Recursively search for first leaf because blocks can be nested within each other
    return getFirstLeafFontSize(firstChild);
  };

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={{ fontSize: `${getFirstLeafFontSize(element)}px` }} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={{ fontSize: `${getFirstLeafFontSize(element)}px` }} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return (
        <li style={{ fontSize: `${getFirstLeafFontSize(element)}px` }} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={{ fontSize: `${getFirstLeafFontSize(element)}px` }} {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          className={theme.palette.mode === "dark" ? "rich-text-link-dark-mode" : ""}
        >
          {children}
        </a>
      );
    case "indented":
      return (
        <div {...attributes} className="rich-text-block-indent">
          {children}
        </div>
      );
    case "image":
      return <Images {...props}></Images>;
    case "div":
      return <div {...attributes}>{children}</div>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
export default Block;
