import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  Chip,
  FilterOptionsState,
  TextField
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React, { FC, useMemo, useState } from "react";
import EdisonTypography from "../typography/EdisonTypography";
import { FilterOperation, FilterOperationOptions } from "enada-common";
import { Get, MgtTemplateProps, Person } from "@microsoft/mgt-react";
import { filterPeopleOptions } from "./filterutils";
import "./autocompleteelement.scss";

export enum AutocompleteElementType {
  Default,
  PeoplePicker,
  ResourcePicker
}

interface AutocompleteElementProps {
  filterOperation: FilterOperation;
  valueDisplayName: string;
  type?: AutocompleteElementType;
  tagLimit?: number;
  onChange: (value: FilterOperationOptions[]) => void;
}

const AutocompleteElement: FC<AutocompleteElementProps> = ({
  filterOperation,
  valueDisplayName,
  type,
  tagLimit = -1,
  onChange
}) => {
  const [users, setUsers] = useState<FilterOperationOptions[]>([]);

  const filteredOperationOptionsSorted = useMemo(() => {
    if (users.length === 0) {
      return filterOperation.options;
    }

    const toBeSorted = [...(filterOperation.options ?? [])];
    return toBeSorted.sort((l, r) => {
      let lName = l.displayName;
      if (!lName) {
        const userId = l.value;
        lName = users.find(u => u.value === userId)?.displayName ?? "";
      }
      let rName = r.displayName;
      if (!rName) {
        const userId = r.value;
        rName = users.find(u => u.value === userId)?.displayName ?? "";
      }
      return lName.localeCompare(rName);
    });
  }, [users, filterOperation.options]);

  const PersonTemplate = (props: MgtTemplateProps) => {
    const user = users.find(u => u.value === props.dataContext.id);
    if (!user) {
      setUsers(prev => [
        ...prev,
        {
          displayName: props.dataContext.displayName,
          value: props.dataContext.id
        }
      ]);
    }
    return (
      <Person
        personDetails={props.dataContext}
        avatarType="photo"
        fetchImage
        view={"oneline"}
        line1Property="displayName"
        avatarSize="small"
      />
    );
  };

  const resourceTagTemplate = (tagProps: any, name?: string) => {
    // return <Chip avatar={avatar} label={name} {...tagProps} color="primary" />;
    return <EdisonTypography title={`${name}, ` as string} variant="data" />;
  };

  const PersonTagTemplate = (props: MgtTemplateProps) => {
    return resourceTagTemplate(props.dataContext.tagProps, props.dataContext.displayName);
  };

  return (
    <Autocomplete
      className={`${
        type === AutocompleteElementType.PeoplePicker ||
        type === AutocompleteElementType.ResourcePicker
          ? "selectedMultiValuePeoplePicker"
          : "selectedMultiValue"
      }`}
      multiple
      disableCloseOnSelect
      limitTags={tagLimit}
      getLimitTagsText={(more: number) => `+${more}`}
      id="multiselect-values"
      size="small"
      value={filterOperation.value ?? []}
      options={filteredOperationOptionsSorted ?? []}
      getOptionLabel={(option: FilterOperationOptions) => option?.displayName ?? ""}
      isOptionEqualToValue={(option: FilterOperationOptions, value: FilterOperationOptions) =>
        option.value === value?.value
      }
      filterOptions={
        type === undefined || type === AutocompleteElementType.Default
          ? undefined
          : (
              options: FilterOperationOptions[],
              state: FilterOptionsState<FilterOperationOptions>
            ) => filterPeopleOptions(options, state, users)
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={valueDisplayName} />
      )}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: FilterOperationOptions,
        { selected }
      ) => (
        <li {...props} key={option.value} style={{ padding: "2px" }}>
          {(type === undefined || type === AutocompleteElementType.Default) && (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ padding: 0, marginRight: "8px", marginLeft: 0 }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 24 }
                }}
                checked={selected}
              />
              <Box>
                <EdisonTypography variant="data" title={option.displayName} />
              </Box>
            </>
          )}
          {type && type === AutocompleteElementType.PeoplePicker && (
            <Get cacheEnabled cacheInvalidationPeriod={3600000} resource={`users/${option.value}`}>
              <PersonTemplate template="default" />
            </Get>
          )}
          {type === AutocompleteElementType.ResourcePicker &&
            option?.extraProps?.type === "AAD" && (
              <Get
                cacheEnabled
                cacheInvalidationPeriod={3600000}
                resource={`users/${option.extraProps.userId}`}
              >
                <PersonTemplate template="default" />
              </Get>
            )}
          {type === AutocompleteElementType.ResourcePicker &&
            option?.extraProps?.type !== "AAD" && (
              <Person
                personDetails={{ displayName: option?.displayName ?? "" }}
                avatarType="photo"
                view={"oneline"}
                line1Property="displayName"
                avatarSize="small"
              />
            )}
        </li>
      )}
      renderTags={(tagValue, getTagProps, ownerState) => (
        <div className="tags-container">
          {tagValue
            .slice(
              0,
              !ownerState.limitTags || ownerState.limitTags === -1 || ownerState.focused
                ? undefined
                : ownerState.limitTags + 1
            )
            .map((option: FilterOperationOptions, index: number) => {
              if (ownerState.limitTags === index && !ownerState.focused) {
                return (
                  <div key={index} className="tag-limit">
                    {ownerState.getLimitTagsText &&
                      ownerState.getLimitTagsText(tagValue.length - ownerState.limitTags)}
                  </div>
                );
              }
              if (type === undefined || type === AutocompleteElementType.Default) {
                return (
                  <Box key={index} className="autocomplete-dropdown-chip-wrapper">
                    <Chip
                      label={option.displayName}
                      {...getTagProps({ index })}
                      title={filterOperation.value
                        .map((o: FilterOperationOptions) => o.displayName)
                        .join(", ")}
                    />
                  </Box>
                );
              } else if (
                type === AutocompleteElementType.PeoplePicker ||
                (type === AutocompleteElementType.ResourcePicker &&
                  option?.extraProps?.type === "AAD")
              ) {
                return (
                  <Get
                    key={index}
                    cacheEnabled
                    cacheInvalidationPeriod={3600000}
                    templateContext={{ tagProps: getTagProps({ index }) }}
                    resource={`users/${option?.extraProps?.userId ?? option.value}`}
                  >
                    <PersonTagTemplate template="default" />
                  </Get>
                );
              } else {
                return resourceTagTemplate(getTagProps({ index }), option.displayName);
              }
            })}
        </div>
      )}
      onChange={(_, changeValue: FilterOperationOptions[]) => onChange(changeValue.filter(o => o))}
    />
  );
};

export default AutocompleteElement;
