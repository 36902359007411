import React, { FC } from "react";
import { Divider, FormControl, InputLabel, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Calendar, RecordTableConfiguration } from "enada-common";
import { CalendarModel, ProjectModel } from "@bryntum/gantt-thin";
import UserDateTimePicker from "../../../../datetime/UserDateTimeField";
import { TFunction } from "i18next";
import colors from "../../../../../theme/styleVariables.module.scss";

export interface ScheduleTableCalendarModalProps {
  t: TFunction;
  project: ProjectModel;
  calendars: Calendar[];
  tableConfig?: RecordTableConfiguration;
}

const ScheduleTableCalendarModal: FC<ScheduleTableCalendarModalProps> = ({
  t,
  project,
  calendars,
  tableConfig
}) => {
  return (
    <Stack direction="column" className="task-table-calendar-modal-root" spacing={2} pt={3}>
      <Stack direction="row" justifyContent={"space-between"} spacing={1} className="align-center">
        <FormControl fullWidth>
          <InputLabel id="task-table-calendar-label">{t("calendar")}</InputLabel>
          <Select
            fullWidth={true}
            labelId="task-table-calendar-label"
            label={t("calendar")}
            value={project?.calendar?.id ?? tableConfig?.taskTableCalendarId}
            onChange={e => {
              const selectedCalendar = project.calendars.find(c => c.id === e.target.value);
              project.setCalendar(selectedCalendar as CalendarModel);
            }}
            sx={{ borderRadius: "8px" }}
          >
            {calendars.map((value, index) => (
              <MenuItem key={index} value={value.id}>
                {value.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title={t("taskTableCalendarInfo")}>
          <InfoOutlinedIcon sx={{ color: colors.InformationIconColor }} />
        </Tooltip>
      </Stack>
      <Divider flexItem />
      <Stack direction="row" justifyContent={"space-between"} spacing={1} className="align-center">
        <FormControl fullWidth>
          <InputLabel id="scheduling-direction-label">{t("schedulingDirection")}</InputLabel>
          <Select
            fullWidth={true}
            labelId="scheduling-direction-label"
            label={t("schedulingDirection")}
            value={project?.direction ?? tableConfig?.schedulingDirection}
            onChange={e => {
              project.direction = e.target.value === "Forward" ? "Forward" : "Backward";
            }}
            sx={{ borderRadius: "8px" }}
          >
            <MenuItem key={1} value={"Forward"}>
              {t("forwardDefault")}
            </MenuItem>
            <MenuItem key={2} value={"Backward"}>
              {t("backward")}
            </MenuItem>
          </Select>
        </FormControl>
        <Tooltip title={t("schedulingDirectionInformation")}>
          <InfoOutlinedIcon sx={{ color: colors.InformationIconColor }} />
        </Tooltip>
      </Stack>
      <Divider flexItem />
      <Stack direction="row" justifyContent={"space-between"} spacing={1} className="align-center">
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <UserDateTimePicker
              key="startDate"
              label={t("startDate")}
              value={project.startDate ?? tableConfig?.startDate}
              isIconOn={false}
              readOnly={project.direction === "Backward"}
              useInternalState={false}
              onChange={(value: any) => (project.startDate = value)}
              outlineTextField
            />
          </LocalizationProvider>
        </FormControl>
        <Tooltip title={t("startDateInfo")}>
          <InfoOutlinedIcon sx={{ color: colors.InformationIconColor }} />
        </Tooltip>
      </Stack>
      <Divider flexItem />
      <Stack direction="row" justifyContent={"space-between"} spacing={1} className="align-center">
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <UserDateTimePicker
              key="endDate"
              label={t("endDate")}
              value={project.endDate ?? tableConfig?.endDate}
              isIconOn={false}
              readOnly={project.direction === "Forward"}
              useInternalState={false}
              onChange={(value: any) => (project.endDate = value)}
              outlineTextField
            />
          </LocalizationProvider>
        </FormControl>
        <Tooltip title={t("endDateInfo")}>
          <InfoOutlinedIcon sx={{ color: colors.InformationIconColor }} />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default ScheduleTableCalendarModal;
