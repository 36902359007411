import { BryntumTimelineProps } from "@bryntum/schedulerpro-react-thin";

const timelineConfig: Partial<BryntumTimelineProps> = {
  eventStyle: "plain",
  eventColor: "gantt-green",
  height: "",
  minHeight: "",
  columnLinesFeature: false
};

export default timelineConfig;
