import { Container, WorkflowStageViewField, WorkflowStageViewTable } from "enada-common";
import { IconButton, Paper, Stack } from "@mui/material";
import React, { Dispatch, FC, useEffect, useState } from "react";
import { EdisonTypography } from "enada-components";
import WorkflowLivePreviewColumn from "./column/WorkflowLivePreviewColumn";
import WorkflowLivePreviewTabs from "./tabs/WorkflowLivePreviewTabs";
import { getRowVisibility, updateRowVisibility } from "./utils/handleFormVisibility";
import VisibilityToggle from "./visibilitytoggle/VisibilityToggle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./workflowlivepreview.scss";
import { useTranslation } from "react-i18next";

export type WorkflowLivePreviewSource = "form" | "dataPrepopulation";
export interface WorkflowLivePreviewProps {
  tabs: Container[];
  viewFields?: WorkflowStageViewField[];
  viewTables?: WorkflowStageViewTable[];
  setViewFields?: Dispatch<React.SetStateAction<WorkflowStageViewField[]>>;
  setViewTables?: Dispatch<React.SetStateAction<WorkflowStageViewTable[]>>;
  source: WorkflowLivePreviewSource;
  toggleDisabled?: boolean;
}
//Renders the assoicated Form in the correct layout
// Handles both the data prepopulation and the component visability
const WorkflowLivePreview: FC<WorkflowLivePreviewProps> = ({
  tabs,
  viewFields,
  viewTables,
  setViewFields,
  setViewTables,
  source,
  toggleDisabled
}) => {
  const { t } = useTranslation(["common"]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [closedRows, setClosedRows] = useState<number[]>([]);
  useEffect(() => {
    setClosedRows([]);
  }, [currentTabIndex]);

  const getOrderedTabs = () => {
    return [...tabs].sort((first, second) => {
      if (first.order === undefined || second.order === undefined) return 0;
      if (first.order < second.order) {
        return -1;
      }
      return 1;
    });
  };
  return (
    <Stack className="workflow-live-preview-root" spacing={2}>
      <WorkflowLivePreviewTabs
        tabs={getOrderedTabs()}
        viewFields={viewFields}
        setViewFields={setViewFields}
        viewTables={viewTables}
        setViewTables={setViewTables}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        source={source}
        toggleDisabled={toggleDisabled}
      />
      {getOrderedTabs().map((tab, index) => (
        <Stack
          spacing={2}
          key={tab.id}
          className={`tab-content-container ${currentTabIndex !== index && "hidden"}`}
        >
          {tab.zones?.map((zone, index) => (
            <Stack key={index} direction="row" className="row-container" spacing={2}>
              <Paper key={index} className="row-content">
                <Stack spacing={2}>
                  <Stack direction="row" className="row-title-container">
                    <EdisonTypography title={zone.displayName ?? ``} variant="tabtitle" />
                    <IconButton
                      onClick={() =>
                        setClosedRows(prev =>
                          prev.includes(index)
                            ? prev.filter(rowIndex => rowIndex !== index)
                            : [...prev, index]
                        )
                      }
                    >
                      {closedRows.includes(index) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </IconButton>
                  </Stack>
                  {!closedRows.includes(index) && (
                    <Stack direction="row" className="columns-container" spacing={2}>
                      {zone?.columns?.map((column, columnIndex) => (
                        <WorkflowLivePreviewColumn
                          key={columnIndex}
                          column={column}
                          viewFields={viewFields}
                          viewTables={viewTables}
                          setViewFields={setViewFields}
                          setViewTables={setViewTables}
                          source={source}
                          toggleDisabled={toggleDisabled}
                        />
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Paper>
              {viewFields && viewTables && setViewFields && setViewTables && (
                <VisibilityToggle
                  toggleDisabled={toggleDisabled}
                  value={getRowVisibility(zone, viewFields, viewTables)}
                  onChange={changeValue => {
                    const updated = updateRowVisibility(changeValue, zone, viewFields, viewTables);
                    setViewFields(prev =>
                      prev.map(
                        prevField =>
                          updated?.updatedViewFields.find(
                            field => field.fieldId === prevField.fieldId
                          ) ?? prevField
                      )
                    );
                    setViewTables(prev =>
                      prev.map(
                        prevTable =>
                          updated?.updatedViewTables.find(
                            table => table.tableId === prevTable.tableId
                          ) ?? prevTable
                      )
                    );
                  }}
                />
              )}
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default WorkflowLivePreview;
