import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlined from "@mui/icons-material/KeyboardArrowRightOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import React, { FC, useEffect, useMemo, useState } from "react";
import "./baselinesdropdown.scss";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EdisonTypography from "../../../../../../edison/typography/EdisonTypography";
import { BaselineConfig, formatDateTimeLocal } from "enada-common";
import { Gantt, TaskStore } from "@bryntum/gantt-thin";
import { DomClassList, Store } from "@bryntum/core-thin";

//TODO: Resolve issue with prop drilling for gantt and taskStore objects=> maybe use Redux
export interface BaselinesDropdownProps {
  t: (key: string) => string;
  gantt: Gantt;
  taskStore: any;
  onChange?: (event: any) => void;
  baselines?: BaselineConfig[];
}

const columns = [
  <EdisonTypography variant="data2" title="Baseline" key="title1" />,
  <EdisonTypography variant="data2" title="Baseline Set" key="title2" />,
  <EdisonTypography variant="data2" title="Reset" key="title3" />,
  <RemoveRedEyeOutlinedIcon fontSize="small" key="removedEye" />
];
const BaselinesDropdown: FC<BaselinesDropdownProps> = ({
  t,
  gantt,
  taskStore,
  onChange,
  baselines
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [baseLinesDisabled, setBaseLinesDisabled] = useState(gantt.features.baselines.disabled);

  const sortedBaselines = useMemo(() => {
    return [...(baselines ?? [])].sort((bl, br) => {
      return bl.index > br.index ? 1 : bl.index < br.index ? -1 : 0;
    });
  }, [baselines]);

  const nextBaseLinesIndex = useMemo(() => {
    if (!sortedBaselines || sortedBaselines.length === 0) {
      return 1;
    }
    return sortedBaselines[sortedBaselines.length - 1].index + 1;
  }, [sortedBaselines]);

  const [hiddenBaseLines, setHiddenBaseLines] = useState<number[]>([]);

  const toggleBaselinesDisabled = (disabled?: boolean) => {
    const newValue = disabled !== undefined ? disabled : !gantt.features.baselines.disabled;
    setBaseLinesDisabled(newValue);
    gantt.features.baselines.disabled = newValue;
  };

  const foreachBaseline = (callback: (baseline: any) => void) => {
    taskStore.forEach((task: any) => {
      task.baselines.forEach((b: any) => {
        callback(b);
      });
    });
  };
  useEffect(() => {
    //On load initialize the hidden baselines fromt the store
    const set = new Set<number>();
    foreachBaseline((b: any) => {
      const clsList = new DomClassList(b.cls);
      if (clsList.values.length > 0) {
        set.add(b.id);
      }
    });
    setHiddenBaseLines([...set]);
  }, []);

  return (
    <Stack className="baselines-dropdown-root">
      <Stack direction="row" className="dropdown-container">
        <Stack direction="row" className="title-container">
          <FormControlLabel
            control={
              <Checkbox
                checked={!baseLinesDisabled}
                onChange={_ => {
                  toggleBaselinesDisabled();
                }}
              />
            }
            label={t("showBaselines")}
          />
          <Tooltip title={t("baselinesMessage")} arrow placement="right">
            <InfoOutlinedIcon />
          </Tooltip>
        </Stack>
        <IconButton onClick={() => setDropdownOpen(prev => !prev)}>
          {dropdownOpen ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRightOutlined />}
        </IconButton>
      </Stack>
      {dropdownOpen && (
        <Stack>
          {
            <Table
              className="baselines-table"
              sx={theme => ({
                "td:not(:last-child)": {
                  borderRight: `1px solid ${theme.palette.divider}`
                },
                "th:not(:last-child)": {
                  borderRight: `1px solid ${theme.palette.divider}`
                }
              })}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index} align="center" className="header">
                      {column}
                      <Divider />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBaselines?.map((baseline: BaselineConfig, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <EdisonTypography
                        enableUppercase
                        variant="tableheader"
                        title={index === 0 ? `Baseline` : `Baseline ${index}`}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <EdisonTypography
                        variant="fieldtitle"
                        title={formatDateTimeLocal(new Date(baseline.baselineSet))}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          onChange &&
                            onChange({
                              type: "custom",
                              action: "update",
                              name: "baseline",
                              payload: {
                                index: baseline.index,
                                baselineSet: new Date().toISOString()
                              }
                            });
                          taskStore.setBaseline(baseline.index);
                        }}
                      >
                        <RefreshOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={
                          !hiddenBaseLines.some(baseLineIndex => baseLineIndex === baseline.index)
                            ? true
                            : false
                        }
                        onChange={e => {
                          setHiddenBaseLines(prev =>
                            e.target.checked
                              ? prev.filter(value => value !== baseline.index)
                              : [...prev, baseline.index]
                          );
                          foreachBaseline((b: any) => {
                            if (b.id === baseline.index) {
                              const clsList = new DomClassList(b.cls);
                              e.target.checked
                                ? clsList.remove("b-hide-baseline")
                                : clsList.add("b-hide-baseline");
                              b.cls = clsList.value;
                            }
                          });

                          gantt.refreshRows();
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          if (taskStore === undefined) return;
                          foreachBaseline((b: any) => {
                            if (b.id === baseline.index) {
                              b.task.baselines.remove(b);
                            }
                          });
                          onChange &&
                            onChange({
                              type: "custom",
                              action: "remove",
                              name: "baseline",
                              payload: baseline
                            });
                        }}
                      >
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          <Button
            startIcon={<AddIcon />}
            disabled={nextBaseLinesIndex === 12} // we want to limit it to 10 max baselines. bryntum starts at 1 and we want the first baseline to be 0, so 12 will be 10
            onClick={() => {
              const bIndex = nextBaseLinesIndex;
              taskStore.setBaseline(bIndex);
              onChange &&
                onChange({
                  type: "custom",
                  action: "add",
                  name: "baseline",
                  payload: {
                    index: bIndex,
                    baselineSet: new Date().toISOString()
                  }
                });
            }}
          >
            add BaseLine
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default BaselinesDropdown;
