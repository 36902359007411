import { BaseRecord, ColumnField, RecordRole, WorkflowStageViewFieldState } from "enada-common";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectEditor,
  selectFieldValueById,
  selectRecordFieldValues,
  selectRecordFields,
  selectRecordReadOnly,
  selectRecord,
  selectRecordStatus,
  selectWorkflowStage,
  updateRecordFieldValue,
  updateSystemFields,
  selectRecordTaskTableId,
  selectRecordPermissions,
  selectRecordUseChildDates
} from "../../../../store/slices/recordSlice";
import ComponentMapper from "../../../../utils/componentMapper";
import { SystemFieldType } from "../../../../pages/admin/tableconfig/TableConfig";
import { Box } from "@mui/system";
import { useGetMyRolesQuery, useGetUserQuery } from "services/api";

interface ProjectFieldProps {
  columnField: ColumnField;
}
const ProjectField: FC<ProjectFieldProps> = ({ columnField }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery();
  const { data: userRoles = [] } = useGetMyRolesQuery();

  const projectFieldValues = useAppSelector(selectRecordFieldValues);
  const keepReadOnly = useAppSelector(selectRecordReadOnly);
  const projectEditor = useAppSelector(selectEditor);
  const recordTaskTableId = useAppSelector(selectRecordTaskTableId);
  const fieldValue = useAppSelector(state => selectFieldValueById(state, columnField.fieldId));
  const fields = useAppSelector(selectRecordFields);
  const fullProject = useAppSelector(selectRecord);
  const workflowStage = useAppSelector(selectWorkflowStage);

  const projectRecordStatus = useAppSelector(selectRecordStatus);
  const recordPermissions = useAppSelector(selectRecordPermissions);
  const canUseChildDateRanges = useAppSelector(selectRecordUseChildDates);

  const stageView =
    Array.isArray(workflowStage?.views) && workflowStage.views.length > 0
      ? workflowStage?.views[0] // TODO : review this when we can add multiple views to the a workflow
      : null;

  const isEditor = user?.id === projectEditor?.oid;

  const isFieldSystem = (columField: ColumnField) => {
    return columField.fieldId === undefined && columField.configuration !== undefined;
  };
  const getSystemFieldValue = (columnField: ColumnField) => {
    if (columnField?.configuration?.name === undefined || !fullProject) return null;
    return fullProject[columnField.configuration.name as unknown as keyof BaseRecord];
  };
  const thisField =
    fields.find(field => field.id === columnField.fieldId) || columnField.configuration;

  const isReadOnly = () => {
    if (keepReadOnly) return true;
    // if we are using a rollup table the start date and end date fields should be readOnly
    if (
      (recordTaskTableId !== undefined || canUseChildDateRanges) &&
      [SystemFieldType.StartDate, SystemFieldType.EndDate, SystemFieldType.PercentDone].includes(
        columnField?.fieldId as number
      )
    )
      return true;

    if (isEditor) {
      const isAdmin = userRoles.some(role =>
        ["Tenant Admin", "Instance Admin"].includes(role?.value ?? "")
      );
      const isProcessManager = recordPermissions.some(
        permission => permission.entityId === user?.id && permission.role === RecordRole.Manager
      );
      if (workflowStage?.allowReadOnlyOverride && (isProcessManager || isAdmin)) return false;

      return Boolean(
        stageView?.viewFields &&
          stageView.viewFields.some(
            viewField =>
              viewField.fieldId === columnField.fieldId &&
              viewField.state === WorkflowStageViewFieldState.ReadOnly
          )
      );
    }

    return true;
  };
  return (
    <Box data-testid={`record-field-${thisField?.name}`}>
      <ComponentMapper
        status={projectRecordStatus}
        component={thisField}
        readOnly={isReadOnly()}
        isInTable={false}
        isInRecord={true}
        useInternalState={false}
        useReadOnlyWrapper={true}
        t={t}
        value={isFieldSystem(columnField) ? getSystemFieldValue(columnField) : fieldValue?.value}
        onChange={(changeValue: any) => {
          // Only save if value has changed. Prevents unnecessary save on load
          if (fieldValue?.value === changeValue) return;
          !isFieldSystem(columnField)
            ? dispatch(
                updateRecordFieldValue({
                  id: columnField.fieldId,
                  changeValue: changeValue
                })
              )
            : dispatch(
                updateSystemFields({
                  key: columnField?.configuration?.name as keyof BaseRecord,
                  changeValue
                })
              );
        }}
        fieldValues={projectFieldValues}
        required={stageView?.viewFields?.some(
          viewField =>
            viewField.state === WorkflowStageViewFieldState.Required &&
            viewField.fieldId === columnField.fieldId
        )}
      />
    </Box>
  );
};
export default ProjectField;
