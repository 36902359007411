import { Input } from "@mui/material";
import React, { FC, useState } from "react";
import "./row.scss";

export interface EditableHeaderProps {
  onText: (title: string) => void;
  title: string;
  placeholder: string;
  datatestid?: string;
  isSelected?: boolean;
}

const EditableHeader: FC<EditableHeaderProps> = ({
  onText,
  title,
  placeholder,
  datatestid,
  isSelected
}) => {
  // Underline has been disabled as a design test, might need it later
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Input
      color="primary"
      value={title ?? null}
      placeholder={placeholder ?? null}
      data-testid={datatestid}
      disableUnderline={!isFocused}
      onFocus={() => setIsFocused(false)}
      onBlur={() => setIsFocused(false)}
      size="small"
      onChange={e => {
        onText(e.target.value);
      }}
      inputProps={{ tabIndex: 0 }}
    />
  );
};
export default EditableHeader;
