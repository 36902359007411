import { Grid, Stack } from "@mui/material";
import { DragEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  moveUnitInSimpleExpression,
  selectBarLocation,
  selectCalculatedSelectedUnit,
  selectCalculatedSimpleExpression,
  setBarLocation,
  setSelectedUnit
} from "../../../../store/slices/calculatedFieldSlice";
import ExpressionUnit from "../expressionunit/ExpressionUnit";
import { CalculatedMoveAction } from "../utils/calculated.model";
import "./expressionzone.scss";
import CalculatedDivider from "../divider/CalculatedDivider";

const ExpressionZone = () => {
  const dispatch = useAppDispatch();
  const expression = useAppSelector(selectCalculatedSimpleExpression);
  const barLocation = useAppSelector(selectBarLocation);
  const selectedUnit = useAppSelector(selectCalculatedSelectedUnit);

  const onDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    const action = event.dataTransfer.getData("application/calculatedField");
    const parsed: CalculatedMoveAction = JSON.parse(action);
    if (parsed.sourceLocation === undefined) {
      return;
    } else {
      dispatch(
        moveUnitInSimpleExpression({
          sourceIndex: parsed.sourceLocation.index,
          targetIndex: "end"
        })
      );
    }
  };

  return (
    <Grid container direction="row" className="expression-zone-root" rowSpacing={0}>
      <Grid item className="equals-container">
        =
      </Grid>

      {expression.map((part, index) => (
        <Grid item key={`${index}-${part.type}`} className="expression-part-container">
          <Stack direction="row" style={{ height: "100%" }}>
            {barLocation.index === index && <CalculatedDivider />}
            <ExpressionUnit
              unit={part}
              index={index}
              path={null}
              selected={index === selectedUnit?.index}
            />
          </Stack>
        </Grid>
      ))}
      {barLocation.index === "end" && (
        <Grid item>
          <CalculatedDivider />
        </Grid>
      )}
      <div
        onClick={() => {
          dispatch(setBarLocation({ index: "end" }));
          dispatch(setSelectedUnit(null));
        }}
        className="end-dropzone"
        onDragOver={onDragOver}
        onDrop={onDrop}
      ></div>
    </Grid>
  );
};

export default ExpressionZone;
