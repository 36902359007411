import { Box, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React from "react";
import { FC } from "react";
import { useDrag } from "react-dnd";

export interface FeatureItemProps {
  // name: string;
  data: any;
  type: string;
  children?: any;
  disabled?: boolean;
}

const FeatureItem: FC<FeatureItemProps> = ({
  // name,
  data,
  type,
  children,
  disabled
}) => {
  const [{ opacity, cursor }, drag] = useDrag({
    type: type,
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      cursor: "pointer"
    })
  });

  return (
    <Box
      className="sideBarItem"
      ref={disabled ? null : drag}
      sx={{ opacity, cursor, p: "0px 20px" }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <div>{children}</div>
        {disabled && <CheckCircleOutlineIcon fontSize="small" color="disabled" />}
      </Stack>
    </Box>
  );
};

export default FeatureItem;
