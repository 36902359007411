import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import ApprovalsTable from "../../components/personal/approvalstable/ApprovalsTable";
import DashboardTab from "../../components/personal/dashboardtab/DashboardTab";
import CardsTab from "../../components/personal/cardstab/CardsTab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EdisonTabs } from "enada-components";
import PersonalTasksTable from "../../components/personal/tasksTable/PersonalTasksTable";
import OwnedItems from "../../components/personal/owneditems/OwnedItems";
import { WorkItemType } from "enada-common";

const Personal = () => {
  const { t } = useTranslation(["common"]);
  const [activeTab, setActiveTab] = useState("dashboard");

  type TabId = "dashboard" | "tasks" | "cards" | "reviews" | "items";

  const getTabId = (itemType: WorkItemType): TabId => {
    switch (itemType) {
      case WorkItemType.Review:
        return "reviews";
      case WorkItemType.Task:
        return "tasks";
      case WorkItemType.ItemList:
      case WorkItemType.ItemPeriodic:
        return "items";
      default:
        return "dashboard";
    }
  };

  const personalPageTabs: {
    id: TabId;
    label: string;
    children: JSX.Element;
  }[] = useMemo(
    () => [
      {
        id: "dashboard",
        label: t("dashboard"),
        children: (
          <DashboardTab
            onActionClick={(itemType: WorkItemType) => setActiveTab(getTabId(itemType))}
          />
        )
      },
      {
        id: "tasks",
        label: t("tasks"),
        children: <PersonalTasksTable />
      },
      {
        id: "cards",
        label: t("cards"),
        children: <CardsTab />
      },
      {
        id: "reviews",
        label: t("reviews"),
        children: <ApprovalsTable />
      },
      {
        id: "items",
        label: t("items"),
        children: <OwnedItems />
      }
      // {
      //   label: t("lumensClub"),
      //   children: <div> lumensClub tab comming soon</div>,
      // },
    ],
    []
  );

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <EdisonTabs
        tabs={personalPageTabs}
        height="100%"
        indicatorColour={"primary"}
        scrollable={true}
        tabPanelStyles={{
          height: "100%"
        }}
        activeTabId={activeTab ? activeTab : undefined}
        onActiveTabChange={(activeTabId: string) => setActiveTab(activeTabId)}
        wideHeaders
      />
    </Paper>
  );
};

export default Personal;
