import { PersonaEntity } from "enada-common";
import { useMemo } from "react";
import { useGetMyResourcesQuery, useGetResourcesQuery, useGetTasksQuery } from "services/api";

export const useGetResourceReport = (odataQuery: string, odataTaskQuery: string) => {
  const { data: resources = [] } = useGetResourcesQuery();
  const { data: filteredResources, isLoading: filteredResourcesIsLoading } = useGetMyResourcesQuery(
    odataQuery,
    {
      skip: !odataQuery
    }
  );
  const { data: tasks, isLoading: tasksIsLoading } = useGetTasksQuery(odataTaskQuery, {
    skip: !odataQuery
  });

  const filteredResourceReport = useMemo(() => {
    if (!odataQuery || !odataTaskQuery) return;
    const assignments: any[] = [];
    const owners: any[] = [];
    let index = 0;
    let startDate = "";
    let endDate = "";
    tasks?.value?.forEach((t: any) => {
      if (startDate === "") {
        startDate = t.startDate;
      }
      if (new Date(startDate).getTime() > new Date(t.startDate).getTime()) {
        startDate = t.startDate;
      }
      if (endDate === "") {
        endDate = t.endDate;
      }
      if (new Date(endDate).getTime() < new Date(t.endDate).getTime()) {
        endDate = t.endDate;
      }
      t.recordTableRowAssignments.forEach((a: any) => {
        index += 1;
        const userId = resources.find(x => a.resource === x.id)?.userId;
        assignments.push({
          ...a,
          event: t.id,
          id: index,
          entityId: userId
        });
      });
      t.recordOwners.forEach((owner: PersonaEntity) => {
        owners.push({ ...owner, event: t.id });
      });
    });

    return {
      resources: filteredResources?.value ?? [],
      assignments: assignments,
      recordOwners: owners,
      tasks:
        tasks?.value?.map((t: any) => {
          return {
            ...t,
            manuallyScheduled: true
            //constraintType: "startnoearlierthan",
          };
        }) ?? [],
      startDate, // Maybe use zoom to fit on scheduler pro instead?
      endDate
    };
  }, [filteredResources, odataQuery, odataTaskQuery, resources, tasks]);

  const isLoading = filteredResourcesIsLoading || tasksIsLoading;

  return { filteredResourceReport, isLoading };
};
