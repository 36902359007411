import { BaseUserFieldProps } from "enada-common";
import { Box, Link, Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import EdisonTypography from "../../edison/typography/EdisonTypography";
import "./userphonefield.scss";
import internal from "stream";

export interface UserPhoneFieldProps extends BaseUserFieldProps {
  enableSearch: boolean;
}

const UserPhoneField: FC<UserPhoneFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  enableSearch,
  autoFocus,
  setReadOnly,
  required
}) => {
  const [internalState, setInternalState] = useState(value || null);

  useEffect(() => {
    setInternalState(value);
  }, [value]);
  return (
    <Stack className={`user-phone-field-root ${isInTable && "in-table"}`}>
      {!isInTable && (
        <Stack direction="row">
          <EdisonTypography title={label} variant="fieldtitle" />
        </Stack>
      )}

      {readOnly && internalState && (
        <Box className="readonly">
          <Link
            sx={{ fontSize: 14, fontWeight: 400, letterSpacing: 0.17 }}
            href={"tel:" + internalState}
          >
            {internalState}
          </Link>
        </Box>
      )}
      {!readOnly && (
        <ReactPhoneInput
          onBlur={e => {
            if (e.relatedTarget?.className === "selected-flag") return;
            setReadOnly && setReadOnly(true);
            if (!isInTable) return;
            onChange && onChange(internalState);
          }}
          country="gb"
          specialLabel={""}
          value={internalState}
          dropdownClass="phone-drop"
          buttonClass={readOnly ? "user-phone user-phone-readonly" : "user-phone"}
          inputProps={{
            autoFocus: autoFocus,
            onFocus: isInTable
              ? event => {
                  event.target.select();
                  setReadOnly && setReadOnly(false);
                }
              : undefined
          }}
          inputStyle={{ fontSize: 16, fontWeight: 400, letterSpacing: 0.15 }}
          inputClass={
            readOnly
              ? "user-phone user-phone-full-width user-phone-readonly"
              : "user-phone user-phone-full-width"
          }
          containerClass={
            readOnly && isInTable ? "user-phone-container-hide" : "user-phone-container"
          }
          onChange={(phone, _data, _event, formattedValue) => {
            setInternalState(formattedValue);
            if (onChange && !isInTable) onChange(formattedValue);
          }}
          onMount={(_phone, _data, formattedValue) => {
            setInternalState(formattedValue);
          }}
          disableDropdown={isInTable}
          enableSearch={enableSearch}
        />
      )}
    </Stack>
  );
};

export default UserPhoneField;
export { UserPhoneField };
