import { User } from "@microsoft/microsoft-graph-types";
import { ResourceType } from "enada-common";
import { useEffect, useState } from "react";
import { useGetResourcesQuery } from "services/api";
import { useMsGraphRequests } from "./useMsGraphRequests";
import { GraphQueryType } from "services/graphService";

export const useGetAADResources = (fetchImages = false) => {
  const [aadResources, setAadResources] = useState<Record<string, User>>({});

  const { data: resources = [] } = useGetResourcesQuery();
  const { batchGetEntitiesById, isLoading } = useMsGraphRequests(fetchImages);

  useEffect(() => {
    if (!resources.length) return;
    const newIds = resources
      .filter(
        row => row.type === ResourceType.AAD && aadResources[row?.userId as string] === undefined
      )
      .map(row => row.userId);

    const fetchaadResources = async () => {
      const result = await batchGetEntitiesById(newIds as string[], GraphQueryType.Users);

      setAadResources(result as Record<string, User>);
    };

    fetchaadResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  return { aadResources, isLoading };
};
