import { Category } from "./Category.model";

export interface News {
  id?: string;

  startDate: string;
  endDate?: string;

  title: string;
  description?: string;
  imageUrl?: string;

  readMoreText?: string;
  readMoreUrl?: string;
  content?: string;

  order?: number;
  isDeleted: boolean;

  modified?: string;
  modifiedBy?: string | null;

  scope: Scope;
  category?: Category;
}

export enum Scope {
  Instance = "Instance",
  Tenant = "Tenant",
  Global = "Global"
}
