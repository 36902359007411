import { Card, Form, NotificationLevel, WorkflowStageViewState, getEnumValues } from "enada-common";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Node } from "reactflow";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCurrentNotification } from "../../../../store/slices/notificationSlice";
import {
  WorkflowDesignerView,
  clearWorkflowError,
  selectCurrentElement,
  selectIsWorkflowDefault,
  selectWorkflowIsEdit,
  selectWorkflowScope,
  selectWorklowValidationErrorsByNode,
  setWorkflowDesignerView,
  updateElement
} from "../../../../store/slices/workflowSlice";
import {
  updateMultipleNodeProperties,
  updateNodeCustomData
} from "../../utils/updateNodeCustomData";
import { useGetCardsQuery } from "../../../../services/api";
import { selectInData } from "utils/rtkQuery";
import { useGetFormsQuery } from "services/api";

const StageOptions = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);
  const currentScope = useAppSelector(selectWorkflowScope);
  const isDefault = useAppSelector(selectIsWorkflowDefault);
  const isEdit = useAppSelector(selectWorkflowIsEdit);

  const { data: cards = [], isSuccess: cardsIsSuccess } = useGetCardsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      data: result.data
        ?.filter(card => card.cardType === currentScope)
        .sort(
          (a: Card, b: Card) => String(a?.displayName)?.localeCompare(String(b?.displayName)) ?? 0
        )
    })
  });

  const selectFormsByRecordType = selectInData<Form[]>(data => {
    const filteredForms = data?.filter(
      (form: Form) => form.dataType && form.dataType === currentScope
    );
    return filteredForms?.sort(
      (a: Form, b: Form) => String(a?.displayName)?.localeCompare(String(b?.displayName)) ?? 0
    );
  });

  const { forms = [] } = useGetFormsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      forms: selectFormsByRecordType(result)
    })
  });

  const errors = useAppSelector(state =>
    selectWorklowValidationErrorsByNode(state, currentNode?.data?.name)
  );

  const stageCardError = errors.find(error => error.property === "stageCard");
  const formError = errors.find(error => error.property === "stageForm");

  useEffect(() => {
    if (isEdit && !cardsIsSuccess) return; // Make sure that the cards have been fetched when editing before working out if the WF has cards or forms
    if (cards && cards.length && forms && forms.length) return;

    dispatch(
      setCurrentNotification({
        title: t("workflowErrorMissingCardsOrForms"),
        message: currentNode?.data.displayName,
        level: NotificationLevel.Error
      })
    );
  }, [cards, cardsIsSuccess, currentNode?.data.displayName, dispatch, forms, isEdit, t]);

  return (
    <Stack spacing={2}>
      <TextField
        type="number"
        label={t("optionalTargetDuration")}
        size="small"
        variant="standard"
        InputProps={{ inputProps: { min: 0 } }}
        placeholder="-"
        value={
          currentNode?.data.targetDuration !== undefined ? currentNode.data.targetDuration : "-"
        }
        onChange={e => {
          if (!currentNode) return;
          if (e.target.value.includes("-")) return;
          dispatch(
            updateElement(
              updateNodeCustomData("targetDuration", e.target.value, currentNode as Node)
            )
          );
        }}
      />

      <FormControl variant="standard" size="small">
        <InputLabel id={`visibility-select-label`}>{t("visibility")}</InputLabel>
        <Select
          disabled={isDefault}
          labelId={`visibility-select-label`}
          value={
            currentNode?.data.viewState
              ? (currentNode.data.viewState as WorkflowStageViewState)
              : WorkflowStageViewState.Visible
          }
          defaultValue={WorkflowStageViewState.Visible}
          onChange={e => {
            if (!currentNode) return;
            dispatch(
              updateElement(updateNodeCustomData("viewState", e.target.value, currentNode as Node))
            );
          }}
        >
          {getEnumValues(WorkflowStageViewState).map((key, index) => (
            <MenuItem key={index} value={key as string}>
              {t(("workflowDescription" + key) as string)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        variant="standard"
        required
        error={stageCardError !== undefined}
        data-testid={"workflow-cardname-formcontrol"}
      >
        <InputLabel id="card-select-label" data-testid={"workflow-cardname-label"}>
          {t("displayCard")}
        </InputLabel>
        <Select
          disabled={isDefault}
          error={stageCardError !== undefined}
          labelId="card-select-label"
          value={currentNode?.data.cardId ? currentNode.data.cardId : ""}
          onChange={e => {
            dispatch(
              updateElement(updateNodeCustomData("cardId", e.target.value, currentNode as Node))
            );

            if (stageCardError === undefined) return;
            dispatch(clearWorkflowError(stageCardError));
          }}
          data-testid={"workflow-cardname-input"}
        >
          {cards?.map(
            card =>
              // If the card marked as isDeleted or the card is the current card, hide it.
              // Also, if the card isDeleted we still need to add it to the list, so it can be displayed as the current value
              (!card.isDeleted || card.id === currentNode?.data.cardId) && (
                <MenuItem
                  sx={card.id === currentNode?.data.cardId ? { display: "none" } : {}}
                  key={card.id}
                  value={card.id}
                  data-testid={"workflow-stage-display-card-item"}
                >
                  {card.displayName ?? card.name}
                </MenuItem>
              )
          )}
        </Select>
        {stageCardError !== undefined && <FormHelperText> {t("missingCardError")}</FormHelperText>}
      </FormControl>
      <Stack spacing={1}>
        <FormControl variant="standard" required error={formError !== undefined}>
          <InputLabel id="form-select-label">{t("form")}</InputLabel>
          <Select
            disabled={isDefault}
            labelId="form-select-label"
            value={currentNode?.data.formId ? currentNode.data.formId : ""}
            onChange={e => {
              dispatch(
                updateElement(
                  updateMultipleNodeProperties(
                    {
                      formId: e.target.value,
                      views: [
                        {
                          name: nanoid(),
                          viewTables: [],
                          viewFields: []
                        }
                      ]
                    },
                    currentNode as Node
                  )
                )
              );

              if (formError === undefined) return;
              dispatch(clearWorkflowError(formError));
            }}
            error={formError !== undefined}
            data-testid={"workflow-formname-input"}
          >
            {forms.map(
              form =>
                // If the form marked as isDeleted or the form is the current form, hide it.
                // Also, if the form isDeleted we still need to add it to the list, so it can be displayed as the current value
                (!form.isDeleted || form.id === currentNode?.data.formId) && (
                  <MenuItem
                    sx={form.id === currentNode?.data.formId ? { display: "none" } : {}}
                    key={form.id}
                    value={form.id}
                    data-testid={"workflow-stage-form-item"}
                  >
                    {form.displayName}
                  </MenuItem>
                )
            )}
          </Select>
          {formError !== undefined && <FormHelperText> {t("missingFormError")}</FormHelperText>}
        </FormControl>
        <Button
          disabled={isDefault || currentNode?.data.formId === undefined}
          variant="text"
          startIcon={<ListAltIcon />}
          onClick={() => {
            if (currentNode?.data?.formId === undefined) return;
            dispatch(setWorkflowDesignerView(WorkflowDesignerView.ManageFormView));
          }}
        >
          {t("manageForm")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default StageOptions;
