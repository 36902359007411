export type FilterType = "eq" | "ne" | "gt" | "ge" | "lt" | "le" | "in" | "contains";

export enum FilterPropertyType {
  String,
  Number,
  Bool,
  Date,
  MultiChoice,
  PeoplePicker,
  ResourcePicker,
  ObjectArray
}

export interface OrderOperation {
  id: number;
  property: string;
  displayName: string;
  isActive?: boolean;
  isQuickFilter?: boolean;
  orderType?: "asc" | "desc";
}
export interface FilterOperation {
  id?: number;
  property: string;
  propertyType: FilterPropertyType;
  displayName: string;
  operator?: FilterType;
  options?: FilterOperationOptions[];
  value?: any;
  isActive?: boolean;
  isQuickFilter?: boolean;
  quickFilterSiblingOps?: FilterSiblingOperation[];
  quickFilterExtraOps?: FilterSubOperation[];
  // Define this property if we want to compare against a property in an object in an array
  objectArrayProperty?: string;
}

/// QuickFilterExtraOps allow for the creation of quick filters that evaluate the same property multiple times eg : percentDone ge 0 && percentDone le 100
export interface FilterSubOperation {
  operator?: FilterType;
  value?: any;
  propertyType: FilterPropertyType;
}
/// QuickFilterSiblingOps  allow for the creation of quick filters that combine different properties eg: percentDone ge 0 && dueDate le today
export interface FilterSiblingOperation {
  parentComparator?: any;
  property: string;
  propertyType: FilterPropertyType;
  operator?: FilterType;
  options?: FilterOperationOptions[];
  quickFilterExtraOps?: FilterSubOperation[];
  value?: any;
}
export interface FilterOperationOptions {
  value: any;
  displayName: string;
  extraProps?: any;
}
export interface OdataOperations {
  skip: number;
  filters?: FilterOperation[];
  orderOperations?: OrderOperation[];
}
