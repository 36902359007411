import { Table } from "enada-common";
import { baseAPI } from "../baseAPI";
import { apiConfig } from "config/authConfig";

export const tablesAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getTables: builder.query<Table[], { includeViews: boolean } | void>({
      query: args =>
        `${apiConfig.contentHubTablesEndpoint}${
          args?.includeViews ? "?includeDependencies=true" : ""
        }`,
      transformResponse: (response: { data: Table[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Tables" as const, id })), "Tables"]
          : ["Tables"]
    }),

    getTable: builder.query<Table, string>({
      query: id => `${apiConfig.contentHubTablesEndpoint}/${id}`,
      transformResponse: (response: { data: Table }) => response.data,
      providesTags: (result, error, id) => [{ type: "Tables", id }]
    }),

    createTable: builder.mutation<Table, Partial<Table>>({
      query: body => ({
        url: apiConfig.contentHubTablesEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Table }) => response.data,
      invalidatesTags: ["Tables"]
    }),

    updateTable: builder.mutation<Table, Partial<Table>>({
      query: body => ({
        url: apiConfig.contentHubTablesEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Table }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Tables", id: body.id }]
    }),

    deleteTables: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubTablesEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Tables", id }))
    }),

    restoreTables: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubTablesEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Tables", id }))
    }),

    recycleTables: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubTablesEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Tables", id }))
    })
  })
});
