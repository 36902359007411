import { RecordType } from "enada-common";
import { unCapitalize } from "../unCapitalize";
import { useGetModulesQuery } from "services/api";

export const useHasModule = () => {
  const { modules } = useGetModulesQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      modules: {
        ...result.data,
        challenges: result.data?.ideas
      }
    })
  });

  const isModuleEnabled = (recordType: RecordType) => {
    const selectedModule = modules?.[unCapitalize(recordType)];
    return selectedModule;
  };

  return { isModuleEnabled };
};
