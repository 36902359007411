import { User } from "@microsoft/microsoft-graph-types";
import { BatchRequest } from "../models/frontend/batching/BatchRequest.model";

const chunkArray = <T>(chunkSize: number, arrayToChunk: T[]): T[][] => {
  if (chunkSize === 0) {
    throw new Error("Chunk size must be larger than 0");
  }

  const chunked: T[][] = [];
  for (let i = 0; i < arrayToChunk.length; i += chunkSize) {
    chunked.push(arrayToChunk.slice(i, i + chunkSize));
  }
  return chunked;
};

export const chunkMembersRequests = (
  query: string,
  members: User[],
  method: "GET",
  chunkSize: number
): BatchRequest[][] => {
  const request: BatchRequest[] = members.map((member, index) => ({
    method: method,
    url: `/users/${member.id}/${query}`,
    id: member.id
  }));

  return chunkArray(chunkSize, request);
};
export default chunkArray;
