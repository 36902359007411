import {
  ApprovalState,
  PendingRecordTableRow,
  PersonalPageState,
  RecordTableRow,
  WorkItem,
  WorkItemTask,
  WorkItemType
} from "enada-common";
import { useMemo } from "react";

import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import {
  useGetMyWorkItemQuery,
  useGetMyWorkSavedItemQuery,
  useGetMyWorkSavedTaskQuery,
  useGetMyWorkTaskQuery,
  useGetTableQuery
} from "services/api";

export const useGetMyWorkRows = (item: WorkItem | WorkItemTask, isOpen: boolean) => {
  const isSaved = useMemo(
    () =>
      item.approvalState === ApprovalState.Saved ||
      item.approvalState === ApprovalState.Submitted ||
      item.approvalState === ApprovalState.Rejected,
    [item.approvalState]
  );

  // Here we fetch dependdent on the item/task. The skip boolean will prevent the query from running if the fetch is not needed.
  const { data: myWorkItem, isLoading: myWorkItemIsLoading } = useGetMyWorkItemQuery(
    item.itemId ?? 0,
    {
      skip: !isOpen || !item.itemId || isSaved || item.itemType === WorkItemType.Task
    }
  );
  const { data: myWorkSavedItem, isLoading: myWorkSavedItemIsLoading } = useGetMyWorkSavedItemQuery(
    item.itemId ?? 0,
    {
      skip: !isOpen || !item.itemId || !isSaved || item.itemType === WorkItemType.Task
    }
  );
  const { data: myWorkTask, isLoading: myWorkTaskIsLoading } = useGetMyWorkTaskQuery(
    item.itemId ?? 0,
    {
      skip: !isOpen || !item.itemId || isSaved || item.itemType !== WorkItemType.Task
    }
  );
  const { data: myWorkSavedTask, isLoading: myWorkSavedTaskIsLoading } = useGetMyWorkSavedTaskQuery(
    item.itemId ?? 0,
    {
      skip: !isOpen || !item.itemId || !isSaved || item.itemType !== WorkItemType.Task
    }
  );

  const isLoading = useMemo(
    () =>
      myWorkItemIsLoading ||
      myWorkSavedItemIsLoading ||
      myWorkTaskIsLoading ||
      myWorkSavedTaskIsLoading,
    [myWorkItemIsLoading, myWorkSavedItemIsLoading, myWorkTaskIsLoading, myWorkSavedTaskIsLoading]
  );

  const data = useMemo(
    () => myWorkItem ?? myWorkSavedItem ?? myWorkTask ?? myWorkSavedTask,
    [myWorkItem, myWorkSavedItem, myWorkSavedTask, myWorkTask]
  );

  const { data: table } = useGetTableQuery((item.tableId ?? 0)?.toString(), {
    skip: !isOpen || !item?.tableId
  });

  const recordTableRow = useMemo(() => {
    const row = isSaved
      ? (data as PendingRecordTableRow)?.pendingRecordTableRowValue
      : (data as RecordTableRow);
    const filteredRows = row?.tableRowFieldValues?.filter(
      fieldValue =>
        !table?.fields?.some(
          field =>
            field.fieldId === fieldValue.fieldId &&
            field.fieldId !== SystemFieldType.UniqueId && // always need to include the uniqueId as its used to match the row on the backend
            [PersonalPageState.Hidden, undefined].includes(field.personalPageState)
        )
    );

    // if(isSaved) return { ...data.pendingRecordTableRowValue, tableRowFieldValues: filteredRows };

    return { ...data, tableRowFieldValues: filteredRows } as RecordTableRow;
  }, [data, isSaved, table?.fields]);

  return { recordTableRow, table, isLoading };
};
